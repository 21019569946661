import { RootState } from "../../../../../store";
import { resourceExplorerByIdLoadingSelector } from "../../../../../resource-explorer/selectors/resource-explorer-by-id/resourceExplorerByIdLoadingSelector";
import { billingExplorerSetupPropertyByKeySelector } from "../../../setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { billingExplorerWidgetSetupDataGettingLoadingSelector } from "../loading/billingExplorerWidgetSetupDataGettingLoadingSelector";

export const billingExplorerWidgetSetupLoadingSelector = (
  state: RootState,
): boolean => {
  const viewId = billingExplorerSetupPropertyByKeySelector("viewId")(state);
  const dataGettingLoading =
    billingExplorerWidgetSetupDataGettingLoadingSelector(state);

  const viewChangeLoading =
    billingExplorerSetupPropertyByKeySelector("loading")(state);

  const resourceExplorerViewLoading = resourceExplorerByIdLoadingSelector(
    state,
    viewId,
  );
  return dataGettingLoading || viewChangeLoading || resourceExplorerViewLoading;
};
