import { FC } from "react";
import { ForecastedCostHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/ForecastedCostHeaderCell";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { billingExplorerWidgetSetupForecastedCostsSumSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupForecastedCostsSumSelector";
import { billingExplorerWidgetSetupForecastedOptionSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupForecastedOptionSelector";

export const BillingExplorerWidgetSetupTableForecastedCostHeaderCell: FC =
  () => {
    const forecastedCost = useAppSelector(
      billingExplorerWidgetSetupForecastedCostsSumSelector,
    );
    const forecastedOption = useAppSelector(
      billingExplorerWidgetSetupForecastedOptionSelector,
    );

    if (!forecastedOption) {
      return null;
    }
    return (
      <ForecastedCostHeaderCell
        forecastOption={forecastedOption}
        forecastedCost={forecastedCost}
      />
    );
  };
