import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { azureReservationDetailsDataSelector } from "./azureReservationDetailsDataSelector";
import { AzureReservationsCoverageDataWithId } from "../../../../../../../components/pages/commtiments/common/utils/types";
import { addIdInCoverageData } from "../../../../../utils/helpers/addIdInCoverageData";
import { AzureCommitmentCoverageData } from "../../../../../../../services/cloudchipr.api";

export const azureReservationDetailsCoverageDataSelector =
  createDraftSafeSelector(
    [azureReservationDetailsDataSelector],
    (data): AzureReservationsCoverageDataWithId[] | undefined => {
      return addIdInCoverageData<AzureCommitmentCoverageData>(
        data?.coverage_data,
      );
    },
  );
