import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { azureCoveredUsageColumnDef } from "./common/azureCoveredUsageColumnDef";
import { azureUncoveredUsageColumnDef } from "./common/azureUncoveredUsageColumnDef";
import { azureNetSavingsColumnDef } from "./common/azureNetSavingsColumnDef";
import { getAzureCoverageColumnDef } from "./common/getAzureCoverageColumnDef";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import { CommitmentsTableName } from "../../../../../../../../common/components/data-grid/cells/CommitmentsTableName";
import { AzureSavingsPlanCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const azureCoverageGroupedByResourceTypeColumnDefs: ColDef<AzureSavingsPlanCoverageDataWithId>[] =
  [
    {
      field: "resource_type.resource_type",
      headerName: "Resource Type",
      suppressKeyboardEvent: () => true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      width: 230,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => (
        <CommitmentsTableName
          type="resource_type"
          id={data?.id ?? ""}
          name={
            <TypographyWithTooltip
              title={data?.resource_type?.resource_type ?? ""}
              variant="body2"
              align="left"
            />
          }
        />
      ),
    },
    {
      field: "resource_type.product_name",
      headerName: "Product Name",
      width: 240,
      flex: 240,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => {
        return data?.resource_type?.product_name ?? "-";
      },
    },
    {
      field: "resource_type.region",
      headerName: "Region",
      width: 230,
      flex: 230,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => {
        return data?.resource_type?.region ?? null;
      },
    },
    azureCoveredUsageColumnDef,
    azureUncoveredUsageColumnDef,
    azureNetSavingsColumnDef,
    getAzureCoverageColumnDef("resource_type"),
    agGridEmptyColumnToFitEmptySpace,

    // azureTotalCoverageColumnDef, // todo: Add this column after the backend fix
  ];
