import { ChangeEvent, FC, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { updateDashboardHierarchyItemThunk } from "../../../../../../../../store/dashboards/thunks/common/updateDashboardHierarchyItemThunk";
import { getPressEnterHandler } from "../../../../../../../utils/helpers/getPressEnterHandler";
import { navigationHierarchyItemNoDndKey } from "../../../../../sortable-tree/utils/constants";
import { DialogTitleClosable } from "../../../../../../../common/dialog-utils/DialogTitleClosable";
import { patchDashboardVisibilityHierarchyByDashboardIdLoadingSelector } from "../../../../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/patchDashboardVisibilityHierarchyByDashboardIdLoadingSelector";

interface DashboardV2ItemRenameDialogProps {
  open: boolean;
  id: string;
  name: string;
  closeMenu(): void;
}

export const DashboardV2ItemRenameDialog: FC<
  DashboardV2ItemRenameDialogProps
> = ({ open, closeMenu, id, name }) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(name);

  const { dashboardId: activeDashboardId } = useParams<{
    dashboardId: string;
  }>();

  const loading = useAppSelector(
    patchDashboardVisibilityHierarchyByDashboardIdLoadingSelector,
  );

  const renameHandler = useCallback(async () => {
    await dispatch(
      updateDashboardHierarchyItemThunk({
        dashboardId: id,
        body: {
          name: value,
        },
        activeDashboardId,
      }),
    );
    closeMenu();
  }, [dispatch, closeMenu, id, value, activeDashboardId]);

  const inputChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [],
  );

  const disabled = value === name || !value;
  const handlePressEnter = getPressEnterHandler(
    renameHandler,
    disabled || loading,
  );

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={closeMenu}
      {...navigationHierarchyItemNoDndKey}
    >
      <DialogTitleClosable title="Rename Dashboard" onClose={closeMenu} />

      <DialogContent dividers sx={{ py: 3 }}>
        <TextField
          autoFocus
          fullWidth
          type="text"
          value={value}
          size="small"
          variant="outlined"
          onChange={inputChangeHandler}
          onKeyDown={handlePressEnter}
          placeholder="Dashboard Name"
          label="Dashboard Name"
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button color="tertiary" onClick={closeMenu}>
          Cancel
        </Button>
        <LoadingButton
          disabled={disabled}
          onClick={renameHandler}
          loading={loading}
          variant="contained"
        >
          Rename
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
