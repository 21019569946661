import { RootState } from "../../../../../store";
import { GetUsersMeOrganisationsCurrentWidgetsCoverageApiArg } from "../../../../../../services/cloudchipr.api";
import { coverageWidgetSetupSelector } from "../../../setups/coverage/coverageWidgetSetupSelector";

export const coverageWidgetSetupDataPayloadSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentWidgetsCoverageApiArg | undefined => {
  const setup = coverageWidgetSetupSelector(state);
  if (!setup?.providerOrganisationId) {
    return;
  }

  return {
    providerOrganisationId: setup.providerOrganisationId,
    dateLabel: setup.dates.label ?? undefined,
    dateFrom: setup.dates.from ?? undefined,
    dateTo: setup.dates.to ?? undefined,
    dateType: setup.dates.type ?? undefined,
    dateValue: setup.dates.value ?? undefined,
    dateUnit: setup.dates.unit ?? undefined,
    grouping: setup.grouping,
    commitmentType: setup.commitmentType,
    trendType: setup.trendType,
  };
};
