import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { billingSummaryWidgetSetupSelector } from "../../../../selectors/setups/billing-summary/billingSummaryWidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { Dates } from "../../../../../../services/cloudchipr.api";
import { getBillingSummaryWidgetDataBySetupThunk } from "../data-get/getBillingSummaryWidgetDataBySetupThunk";

export const billingSummaryDateChangeThunk = createAsyncThunk(
  "dashboards/billingSummaryDateChange",
  async (dates: Dates, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = billingSummaryWidgetSetupSelector(state);

    if (!setup) {
      return;
    }

    dispatch(setWidgetSetup({ ...setup, dates }));
    dispatch(getBillingSummaryWidgetDataBySetupThunk());
  },
);
