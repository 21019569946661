import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { azureOverviewRealCostSelector } from "../../../../../../../store/commitments/selectors/azure/overview/azureOverviewRealCostSelector";
import { azureOverviewTotalCommitmentsSelector } from "../../../../../../../store/commitments/selectors/azure/overview/azureOverviewTotalCommitmentsSelector";
import { azureOverviewTotalNotCoveredSelector } from "../../../../../../../store/commitments/selectors/azure/overview/azureOverviewTotalNotCoveredSelector";
import { azureOverviewSavingsPlanTotalCommitmentsSelector } from "../../../../../../../store/commitments/selectors/azure/overview/azureOverviewSavingsPlanTotalCommitmentsSelector";
import { azureOverviewReservationTotalCostSelector } from "../../../../../../../store/commitments/selectors/azure/overview/azureOverviewReservationTotalCostSelector";
import { azureOverviewLoadingSelector } from "../../../../../../../store/commitments/selectors/azure/overview/loading/azureOverviewLoadingSelector";
import { CommitmentsOverviewMetadataLeftSide } from "../../../../overview/metadata/components/common/CommitmentsOverviewMetadataLeftSide";

export const AzureCommitmentsOverviewMetadataLeftSide: FC = () => {
  const realCost = useAppSelector(azureOverviewRealCostSelector);
  const totalCommitments = useAppSelector(
    azureOverviewTotalCommitmentsSelector,
  );
  const totalNotCovered = useAppSelector(azureOverviewTotalNotCoveredSelector);
  const savingsPlanTotalCommitments = useAppSelector(
    azureOverviewSavingsPlanTotalCommitmentsSelector,
  );
  const reservationTotalCommitments = useAppSelector(
    azureOverviewReservationTotalCostSelector,
  );
  const loading = useAppSelector(azureOverviewLoadingSelector);

  return (
    <CommitmentsOverviewMetadataLeftSide
      realCost={realCost}
      totalCommitments={totalCommitments}
      totalNotCovered={totalNotCovered}
      savingsPlanTotalCommitments={savingsPlanTotalCommitments}
      reservationTotalCommitments={reservationTotalCommitments}
      loading={loading}
    />
  );
};
