import { FC } from "react";
import { Stack } from "@mui/material";
import { SlackConversationIcon } from "./SlackConversationIcon";
import { SlackConversation } from "../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

interface SlackConversationLabelProps {
  type: SlackConversation["type"];

  name: string;
  maxWidth?: number;
}

export const SlackConversationLabel: FC<SlackConversationLabelProps> = ({
  type,
  name,
  maxWidth,
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {type && <SlackConversationIcon type={type} />}

      <TypographyWithTooltip
        title={name}
        fontSize="inherit"
        maxWidth={maxWidth}
      />
    </Stack>
  );
};
