import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

function TreeSvgIcon({ color, ...props }: SvgIconProps) {
  const { palette } = useTheme();

  const fillColor =
    color === "primary" ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon {...props}>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20H18V13H15V9H10V7H13V0H5V7H8V9H3V13H0V20H8V13H5V11H13V13H10V20ZM7 5V2H11V5H7ZM6 15V18H2V15H6ZM16 15V18H12V15H16Z"
          fill={fillColor}
        />
      </svg>
    </SvgIcon>
  );
}

TreeSvgIcon.muiName = "TreeSvgIcon";

export default TreeSvgIcon;
