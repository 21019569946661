import { FC } from "react";
import { Button } from "@mui/material";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

export const ViewsHierarchyMoreTabsDropdownTrigger: FC<
  FilterTriggerComponentProps
> = ({ onClick, label }) => {
  return (
    <Button variant="text" size="small" onClick={onClick} sx={buttonStyles}>
      {label}
    </Button>
  );
};

const buttonStyles = {
  ml: 1,
  color: "text.primary",
  fontWeigh: "medium",
  textTransform: "none",
  whiteSpace: "nowrap",
};
