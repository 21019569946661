import { FC, useCallback, useEffect } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, TextField } from "@mui/material";
import { AutocompleteChangeReason } from "@mui/base/useAutocomplete/useAutocomplete";
import { useDebounce } from "rooks";
import {
  SlackAutocompleteRenderTags,
  SlackAutocompleteRenderTagsProps,
} from "./SlackAutocompleteRenderTags";

interface SlackAutocompleteTriggerProps
  extends SlackAutocompleteRenderTagsProps {
  searchHandler(keyword: string): void;
}

export const SlackAutocompleteTrigger: FC<SlackAutocompleteTriggerProps> = ({
  triggerProps,
  onChange,
  loading,
  searchHandler,
}) => {
  const { open, onClick, selectedValues, onCloseAndSubmit, keyword } =
    triggerProps;

  const debouncedHandler = useDebounce(searchHandler, 300);

  const renderInput = useCallback(
    (params: any) => (
      <TextField
        {...params}
        value=""
        size="small"
        variant="outlined"
        label={triggerProps.label ?? "Select Conversations*"}
      />
    ),
    [triggerProps.label],
  );

  const renderTags = useCallback(
    () => (
      <SlackAutocompleteRenderTags
        onChange={onChange}
        triggerProps={triggerProps}
        loading={loading}
      />
    ),
    [onChange, triggerProps, loading],
  );

  const changeHandler = useCallback(
    (event: any, _: any, reason: AutocompleteChangeReason) => {
      if (reason !== "clear") {
        return;
      }

      onCloseAndSubmit([]);
    },
    [onCloseAndSubmit],
  );

  useEffect(() => {
    debouncedHandler(keyword);
  }, [keyword, debouncedHandler]);

  const icon = open ? (
    <ArrowDropUpIcon color="action" />
  ) : (
    <ArrowDropDownIcon color="action" />
  );

  return (
    <Box onClick={onClick}>
      <Autocomplete
        multiple
        open={false}
        size="small"
        onChange={changeHandler}
        options={[] as string[]}
        popupIcon={icon}
        value={selectedValues}
        renderTags={renderTags}
        renderInput={renderInput}
      />
    </Box>
  );
};
