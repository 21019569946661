import { FC } from "react";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { SettingsNavigationListItems } from "./SettingsNavigationListItems";
import { useAppSelector } from "../../../../../../store/hooks";
import { isSubscriptionCancelledSelector } from "../../../../../../store/subscriptions/subscriptionsSelectors";

export const SettingsPageNavigation: FC = () => {
  const isSubscriptionDisabled = useAppSelector(
    isSubscriptionCancelledSelector,
  );

  return (
    <Stack spacing={2} pt={1} height="100%">
      <Typography variant="h5" pl={1}>
        Settings
      </Typography>

      <SettingsNavigationListItems disabled={isSubscriptionDisabled} />
    </Stack>
  );
};
