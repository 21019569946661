import { FC } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { useDidMount } from "rooks";
import { InternalDashboardLayout } from "./components/InternalDashboardLayout";
import { useDashboardStateGlobalVar } from "./utils/hooks/useDashboardStateGlobalVar.hook";
import { InternalPageHeader } from "../internal-pages-common/components/InternalPageHeader";
import { useGetDashboardsByDashboardIdFromAccountServiceQuery } from "../../../services/cloudchipr-account-service.api";
import { EmptyDashboard } from "../../pages/dashboard/components/corner-components/EmptyDashboard";
import { initiateInternalDashboardThunk } from "../../../store/dashboards/thunks/internal/initiateInternalDashboardThunk";
import { useAppDispatch } from "../../../store/hooks";

export const InternalDashboard: FC = () => {
  const { dashboardId } = useParams<{
    dashboardId: string;
  }>();
  const [searchParams] = useSearchParams();
  const organisationId = searchParams.get("organisation_id") ?? null;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { data, isLoading, isError, isSuccess } =
    useGetDashboardsByDashboardIdFromAccountServiceQuery(
      { dashboardId: dashboardId ?? "", organisationId: organisationId ?? "" },
      { refetchOnMountOrArgChange: true, skip: !organisationId },
    );

  const { registerRequestState } = useDashboardStateGlobalVar(
    isSuccess,
    data?.widgets,
  );

  useDidMount(() => {
    if (organisationId) {
      dispatch(initiateInternalDashboardThunk(organisationId));
    }
  });

  if (isError) {
    navigate("/dashboards", { replace: true });
  }

  if ((isLoading && !data) || !organisationId) {
    return null;
  }

  return (
    <Stack>
      <InternalPageHeader name={data?.name ?? ""} type="dashboard" />
      {!data?.widgets?.length ? (
        <EmptyDashboard hideAddButton />
      ) : (
        <InternalDashboardLayout
          registerRequestState={registerRequestState}
          dashboard={data}
          organisationId={organisationId}
        />
      )}
    </Stack>
  );
};
