import { ValueGetterParams } from "ag-grid-community";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../../store/alerts/utils/types/types";

export const alertsNotificationMethodValueGetter = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>({
  data,
}: ValueGetterParams<T>) => {
  const integrationName = data?.integrations?.reduce((acc, cur) => {
    return acc + " " + cur.name;
  }, "");
  const email = data?.emails?.to?.join(" ");
  return `${integrationName} ${email ?? ""} `;
};
