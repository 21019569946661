import { RootState } from "../../../../../../../store";
import { azureReservationsDataForGranularSelector } from "../../azureReservationsDataForGranularSelector";

export const azureReservationsGranularDataUncoveredUsageSelector = (
  state: RootState,
) => {
  const data = azureReservationsDataForGranularSelector(state);

  return data?.date_granular_data?.uncovered_usage;
};
