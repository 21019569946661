import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { NavigationItemsVisibilityType } from "../../../../../../../../store/navigation/utils/types";
import { ConfirmationDialog } from "../../../../../../../common/modals/ConfirmationDialog";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { updateResourceExplorerVisibilityLoadingSelector } from "../../../../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/updateResourceExplorerVisibilityHierarchyLoadingSelector";

interface BillingExplorerFolderMoveActionMenuConfirmationDialogProps {
  name: string;
  onClose(): void;
  onMove(): Promise<void>;
  visibility: NavigationItemsVisibilityType;
}

export const BillingExplorerFolderMoveActionMenuConfirmationDialog: FC<
  BillingExplorerFolderMoveActionMenuConfirmationDialogProps
> = ({ onMove, onClose, visibility, name }) => {
  const loading = useAppSelector(
    updateResourceExplorerVisibilityLoadingSelector,
  );

  return (
    <ConfirmationDialog
      open
      title="Move Folder?"
      dialogContent={
        <Stack spacing={3}>
          {visibility === "visible_to_everyone" && (
            <Typography variant="inherit">
              Changing the folder visibility will also change the visibility of
              all views inside and deactivate all connected dashboard widgets.
              This action cannot be undone.
            </Typography>
          )}

          <Typography variant="inherit">
            Please confirm if you want to move the{" "}
            <Typography variant="inherit" fontWeight="bold" component="span">
              {name}
            </Typography>{" "}
            folder.
          </Typography>
        </Stack>
      }
      CancelButtonProps={{
        onClick: onClose,
      }}
      ConfirmButtonProps={{
        children: "Move",
        onClick: onMove,
        loading,
      }}
      onClose={onClose}
    />
  );
};
