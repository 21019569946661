import { ColDef } from "ag-grid-community";
import { savingsPlanGroupedByResourceTypeColumnDefs } from "./savings-plan/savingsPlanGroupedByResourceTypeColumnDefs";
import { savingsPlanGroupedByAccountColumnDefs } from "./savings-plan/savingsPlanGroupedByAccountColumnDefs";
import { coverageGroupedByCommitmentColumnDefs } from "./coverage/coverageGroupedByCommitmentColumnDefs";
import { coverageGroupedByAccountColumnDefs } from "./coverage/coverageGroupedByAccountColumnDefs";
import { coverageGroupedByResourceTypeColumnDefs } from "./coverage/coverageGroupedByResourceTypeColumnDefs";
import { SavingsPlanCoverageDataWithId } from "../../../../../../common/utils/types";
import { DataViewTab } from "../../../../../../../../../store/commitments/utils/types/types";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../services/cloudchipr.api";

type Columns = Partial<
  Record<SavingsPlanCoverageGrouping, ColDef<SavingsPlanCoverageDataWithId>[]>
>;

export const utilizationAndCoverageDrawerDataGridColumnDefs: Partial<
  Record<DataViewTab, Columns>
> = {
  coverage: {
    account: coverageGroupedByAccountColumnDefs,
    commitment: coverageGroupedByCommitmentColumnDefs,
    resource_type: coverageGroupedByResourceTypeColumnDefs,
  },
  commitment: {
    account: savingsPlanGroupedByAccountColumnDefs,
    resource_type: savingsPlanGroupedByResourceTypeColumnDefs,
  },
};
