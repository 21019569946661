import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { accountsDividedToStatusesByOrgIdSelector } from "./accountsDividedToStatusesByOrgIdSelector";

export const activeAccountsIdsSelector = createDraftSafeSelector(
  [accountsDividedToStatusesByOrgIdSelector],
  (accounts) => {
    const activeAccountIds = accounts?.active
      ?.filter(({ status }) => status === "connected")
      ?.map((account) => account.id);

    if (!activeAccountIds) {
      return;
    }

    return activeAccountIds;
  },
);
