import { FC, useCallback } from "react";
import { useAppDispatch } from "../../../../../../../../../store/hooks";
import { Dates } from "../../../../../../../../../services/cloudchipr.api";
import { WidgetSetupDateRangeSelect } from "../../common/toolbar/WidgetSetupDateRangeSelect";
import { costAnomaliesSetupChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-anomalies/widget-change/costAnomaliesSetupChangeThunk";

export const CostAnomaliesDateRangeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const dateRangeChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(costAnomaliesSetupChangeThunk({ dates }));
    },
    [dispatch],
  );

  return <WidgetSetupDateRangeSelect onDateChange={dateRangeChangeHandler} />;
};
