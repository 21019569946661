import { coverageWidgetByWidgetIdDataSelector } from "./coverageWidgetByWidgetIdDataSelector";
import { RootState } from "../../../../../store";

export const coverageWidgetByWidgetIdEmptyDataSelector = (
  state: RootState,
  widgetId: string,
) => {
  const data = coverageWidgetByWidgetIdDataSelector(state, widgetId);

  return !data?.groups.length;
};
