import { FC, Fragment, memo, useCallback } from "react";
import { FormikHandlers, FormikHelpers } from "formik";
import { Box, SelectChangeEvent } from "@mui/material";
import { KubernetesTagFilterValues } from "./KubernetesTagFilterValues";
import { KubernetesTagFilterKeys } from "./KubernetesTagFilterKeys";
import { FilterItemOperatorType } from "../../../../filters-selection/FilterItemOperatorType";
import { multipleValueSelectOperators } from "../../../../utils/constants/filter-types/autocomplete-multipe-selection-operator-types";
import {
  FilterItem,
  Operator,
  ResourceType,
} from "../../../../../../../../services/cloudchipr.api";

interface KubernetesTagFilterProps {
  operators?: Operator[];
  filter: FilterItem;
  error?: any;
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
  onChange: FormikHandlers["handleChange"];
  accountIds?: string[];
  resourceType: ResourceType;
}

export const KubernetesTagFilter: FC<KubernetesTagFilterProps> = memo(
  ({
    onChange,
    resourceType,
    accountIds,
    operators,
    filter,
    setFieldValue,
    error,
  }) => {
    const filterValue = filter.value as any; // TODO: change `Tag` type in whole app

    const isValuesFilterHidden = ["exists", "does_not_exist"].includes(
      filter.operator,
    );

    const operatorChangeHandler = useCallback(
      (event: SelectChangeEvent) => {
        const operator = event.target.value;

        if (
          !multipleValueSelectOperators.includes(filter.operator) ||
          !multipleValueSelectOperators.includes(operator)
        ) {
          setFieldValue("value.value", "");
        }

        onChange(event);
      },
      [onChange, setFieldValue, filter.operator],
    );

    return (
      <Fragment>
        <FilterItemOperatorType
          value={filter.operator || ""}
          options={operators}
          onChange={operatorChangeHandler}
        />

        <Box minWidth={150}>
          <KubernetesTagFilterKeys
            accountIds={accountIds}
            resourceType={resourceType}
            setFieldValue={setFieldValue}
            value={filterValue?.key || ""}
            error={!filterValue?.key?.length}
            tagValueVisible={!isValuesFilterHidden}
            disabled={!filter.key || !filter.operator}
          />
        </Box>

        {!isValuesFilterHidden && (
          <Box minWidth={150}>
            <KubernetesTagFilterValues
              key={filterValue?.key}
              accountIds={accountIds}
              tagKey={filterValue?.key}
              value={filterValue?.value}
              resourceType={resourceType}
              setFieldValue={setFieldValue}
              operator={filter.operator || ""}
              error={!!error?.value?.value?.length}
              disabled={!filter.key || !filter.operator}
            />
          </Box>
        )}
      </Fragment>
    );
  },
);
