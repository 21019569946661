import { FC, useCallback } from "react";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { azureUtilizationAndCoverageDetailsGranularDataGroupedByDateSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/details-drawer/azureUtilizationAndCoverageDetailsGranularDataGroupedByDateSelector";
import { commitmentsDetailsDrawerDateGranularitySelector } from "../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerDateGranularitySelector";
import { azureUtilizationAndCoverageDetailsLoadingSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/details-drawer/data/azureUtilizationAndCoverageDetailsLoadingSelector";
import { formatDateByFrequencyType } from "../../../../../../../../utils/helpers/date-time/datetime-format";
import { CommitmentsChart } from "../../../../../common/components/chart/CommitmentsChart";

interface AzureUtilizationAndCoverageDrawerChartProps {
  ChartHeader?: FC;
}

export const AzureUtilizationAndCoverageDrawerChart: FC<
  AzureUtilizationAndCoverageDrawerChartProps
> = ({ ChartHeader }) => {
  const dataGroupedByDate = useAppSelector(
    azureUtilizationAndCoverageDetailsGranularDataGroupedByDateSelector,
  );
  const dataPoint = useAppSelector(
    commitmentsDetailsDrawerDateGranularitySelector,
  );
  const loading = useAppSelector(
    azureUtilizationAndCoverageDetailsLoadingSelector,
  );

  const labelFormatter = useCallback(
    (value: string) => formatDateByFrequencyType(dataPoint, value),
    [dataPoint],
  );

  return (
    <CommitmentsChart
      loading={loading}
      availableTypes={["area"]}
      labelFormatter={labelFormatter}
      dataGroupedByDate={dataGroupedByDate}
      ChartHeader={ChartHeader}
    />
  );
};
