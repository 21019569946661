import { FC, useEffect } from "react";
import { Stack } from "@mui/material";
import { useDidMount } from "rooks";
import DataUsageOutlinedIcon from "@mui/icons-material/DataUsageOutlined";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { ResourceDetailsCostsContentChart } from "./ResourceDetailsCostsContentChart";
import { ResourceDetailsCostsContentChartType } from "./ResourceDetailsCostsContentChartType";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { setResourceDetailsBillingDataProcesses } from "../../../../../../../store/resource-details/resourceDetailsSlice";
import {
  resourceDetailsCostsTabAllProcessesKeys,
  resourceDetailsCostsTabItemDescriptionProcessKey,
  resourceDetailsCostsTabProductFamilyProcessKey,
  resourceDetailsCostsTabUsageTypeProcessKey,
} from "../../../../../../../store/resource-details/utils/constants/processesKeys";
import { ResourceDetailsContentCollapsableCard } from "../ResourceDetailsContentCollapsableCard";
import { getResourceDetailsResourceBillingDataThunk } from "../../../../../../../store/resource-details/thunks/data/getResourceDetailsResourceBillingDataThunk";
import { resourceDetailsTargetResourceIdSelector } from "../../../../../../../store/resource-details/selectors/target-resource/resourceDetailsTargetResourceIdSelector";

export const ResourceDetailsCostsContent: FC = () => {
  const dispatch = useAppDispatch();
  const resourceId = useAppSelector(resourceDetailsTargetResourceIdSelector);

  useDidMount(() => {
    dispatch(
      setResourceDetailsBillingDataProcesses({
        [resourceDetailsCostsTabProductFamilyProcessKey]: {
          groupBy: "product_family",
          chartType: "column",
        },
        [resourceDetailsCostsTabUsageTypeProcessKey]: {
          groupBy: "usage_type",
          chartType: "line",
        },
        [resourceDetailsCostsTabItemDescriptionProcessKey]: {
          groupBy: "description",
          chartType: "table",
        },
      }),
    );
  });

  useEffect(() => {
    if (resourceId) {
      dispatch(getResourceDetailsResourceBillingDataThunk());
    }
  }, [dispatch, resourceId]);

  return (
    <Stack spacing={1.5}>
      {resourceDetailsCostsTabAllProcessesKeys.map((processKey) => {
        const data = dataByProcessKey[processKey];
        return (
          <ResourceDetailsContentCollapsableCard
            key={processKey}
            title={data.title}
            Icon={data.Icon}
            HeaderAction={
              <ResourceDetailsCostsContentChartType processKey={processKey} />
            }
          >
            <ResourceDetailsCostsContentChart processKey={processKey} />
          </ResourceDetailsContentCollapsableCard>
        );
      })}
    </Stack>
  );
};

const dataByProcessKey = {
  [resourceDetailsCostsTabProductFamilyProcessKey]: {
    title: "Cost by Product Family",
    Icon: <BubbleChartOutlinedIcon fontSize="small" />,
  },
  [resourceDetailsCostsTabUsageTypeProcessKey]: {
    title: "Cost by Usage Type",
    Icon: <DataUsageOutlinedIcon fontSize="small" />,
  },
  [resourceDetailsCostsTabItemDescriptionProcessKey]: {
    title: "Cost by Item Description",
    Icon: <DescriptionOutlinedIcon fontSize="small" />,
  },
};
