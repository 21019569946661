import { FC, useCallback } from "react";
import { AxisLabelsFormatterContextObject, Point } from "highcharts";
import { Box } from "@mui/material";
import { MultiTypeHighcharts } from "../../../../../../../storybook/c8r-highcharts/MultiTypeHighcharts";
import { useAppSelector } from "../../../../../../../store/hooks";
import { moneyFormatter } from "../../../../../../../utils/numeral/moneyFormatter";
import { TooltipValueFormatterType } from "../../../../../../../storybook/c8r-highcharts/common/utils/tooltip/getHighchartsTooltipHTML";
import { money } from "../../../../../../../utils/numeral/money";
import { HighchartsProvider } from "../../../../../../../storybook/c8r-highcharts/common/components/HighchartsProvider";
import { resourceDetailsResourceBillingDataLoadingSelector } from "../../../../../../../store/resource-details/selectors/billing-data/loadings/resourceDetailsResourceBillingDataLoadingSelector";
import { HighchartsChartType } from "../../../../../../../storybook/c8r-highcharts/common/components/HighchartsChartTypeSelect";
import { HighchartsChartDataType } from "../../../../../../../storybook/c8r-highcharts/common/utils/types/common";

interface ResourceDetailsCostsChartProps {
  chartType: HighchartsChartType;
  chartData?: HighchartsChartDataType | null;
}

export const ResourceDetailsCostsChart: FC<ResourceDetailsCostsChartProps> = ({
  chartType,
  chartData,
}) => {
  const loading = useAppSelector(
    resourceDetailsResourceBillingDataLoadingSelector,
  );

  const tooltipValueFormatter: TooltipValueFormatterType = useCallback(
    (point: Point) => {
      const costBasedData = chartData?.series.find(
        (series) => series.name === point.series.name,
      )?.data;

      const value = costBasedData?.[point.index];

      return {
        value: value ?? 0,
        markup: `<span style="font-weight: bold; font-size: 13px; ">
            ${money(value)}
          </span>`,
      };
    },
    [chartData],
  );

  return (
    <Box height={260}>
      <HighchartsProvider>
        <MultiTypeHighcharts
          showLegends
          showTotalValueInTooltip
          chartData={chartData}
          chartType={chartType}
          loading={loading ? loadingProps : false}
          yAxisLabelsFormatter={yAxisLabelsFormatter}
          tooltipValueFormatter={tooltipValueFormatter}
          noDataMessage="No data by your selected date range and filters."
          stackedChartHeadingLabelFormatter={stackedChartHeadingLabelFormatter}
        />
      </HighchartsProvider>
    </Box>
  );
};

const stackedChartHeadingLabelFormatter = (value: number) =>
  `${moneyFormatter(value) ?? value}`;
const yAxisLabelsFormatter = (params: AxisLabelsFormatterContextObject) =>
  `${moneyFormatter(+params.value)}`;

const loadingProps = { showLoader: true };
