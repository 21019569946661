import {
  FC,
  ReactNode,
  useCallback,
  useMemo,
  MouseEvent,
  useEffect,
  useState,
  useRef,
} from "react";
import { Backdrop, Card, Popover, PopoverProps } from "@mui/material";
import { createPortal } from "react-dom";

export interface PopoverWrapperProps {
  open: boolean;
  wrapperVariant: "popover" | "dropdown";
  onClose(): void;
  anchor: HTMLElement | null;
  PopoverProps?: Partial<PopoverProps> & { extendAnchorWidth?: boolean };
  listWidth?: number;
  placement?: "left" | "right" | "center";
  children?: ReactNode;
}

export const PopoverWrapper: FC<PopoverWrapperProps> = ({
  open,
  wrapperVariant,
  children,
  onClose,
  anchor,
  PopoverProps,
  listWidth,
  placement,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [hiddenPixels, setHiddenPixels] = useState(0);

  const cardClickHandler = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  const { top, left, height, clientWidth } = useMemo(() => {
    const clientWidth = anchor?.clientWidth ?? 0;
    const top = anchor?.getBoundingClientRect()?.top ?? 0;
    const height = anchor?.getBoundingClientRect()?.height ?? 0;
    let left = anchor?.getBoundingClientRect()?.left ?? 0;

    if (placement === "left") {
      left =
        (anchor?.getBoundingClientRect()?.right ?? 0) -
        (listWidth ?? clientWidth);
    }
    if (placement === "center") {
      left =
        (anchor?.getBoundingClientRect()?.left ?? 0) -
        ((listWidth ?? clientWidth) - clientWidth) / 2;
    }

    return {
      clientWidth,
      top,
      height,
      left,
    };
  }, [anchor, listWidth, placement]);

  const onDropDownClose = useCallback(() => {
    onClose();
    setHiddenPixels(0);
  }, [onClose]);

  useEffect(() => {
    if (wrapperVariant !== "popover" && open) {
      setTimeout(() => {
        if (!containerRef?.current) {
          return;
        }

        const rect = containerRef.current.getBoundingClientRect();
        if (rect.bottom > window.innerHeight) {
          setHiddenPixels(rect.bottom - window.innerHeight + 10);
        } else {
          setHiddenPixels(0);
        }
      }, 300);
    }
  }, [wrapperVariant, open]);

  if (wrapperVariant === "popover") {
    const { extendAnchorWidth, ...popoverProps } = PopoverProps ?? {};

    return (
      <Popover
        disableAutoFocus
        open={open}
        anchorEl={anchor}
        onClose={onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        slotProps={{
          paper:
            extendAnchorWidth && open
              ? { style: { width: clientWidth } }
              : undefined,
        }}
        {...popoverProps}
      >
        {children}
      </Popover>
    );
  }

  if (!open) {
    return null;
  }

  return createPortal(
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 1,
        bgcolor: "transparent",
      }}
      onClick={onDropDownClose}
      open={open}
    >
      <Card
        variant="elevation"
        elevation={3}
        ref={containerRef}
        sx={{
          left,
          mt: 0.5,
          bgcolor: "white",
          top: top + height - hiddenPixels,
          position: "absolute",
          width: listWidth || clientWidth || "unset",
          zIndex: (theme) => theme.zIndex.modal,
        }}
        onClick={cardClickHandler}
      >
        {children}
      </Card>
    </Backdrop>,
    document.body,
  );
};
