import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { SlackConversationLabel } from "../../../../slack/components/SlackConversationLabel";
import { CommunicationIntegrationsGridRowType } from "../../../utils/types/common";

export const IntegrationsDefaultConversationCellRenderer: FC<
  ICellRendererParams<CommunicationIntegrationsGridRowType>
> = ({ data }) => {
  const type = data?.metadata?.conversation?.type;
  const name = data?.metadata?.conversation?.name;

  if (!type || !name) {
    return "-";
  }

  return <SlackConversationLabel type={type} name={name} />;
};
