import { RootState } from "../../../../store";
import { billingSummarySetupWidgetDataLoadingSelector } from "../billing-summary/data/billingSummarySetupWidgetDataLoadingSelector";
import { billingExplorerWidgetSetupLoadingSelector } from "../../widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupLoadingSelector";
import { costAnomaliesSetupWidgetDataLoadingSelector } from "../cost-anomalies/data/costAnomaliesSetupWidgetDataLoadingSelector";
import { commitmentUtilizationWidgetSetupDataLoadingSelector } from "../../widgets/commitment-utilization/setup/commitmentUtilizationWidgetSetupDataLoadingSelector";

export const widgetDataLoadingSelector = (state: RootState) => {
  const billingSummaryLoading =
    billingSummarySetupWidgetDataLoadingSelector(state);
  const billingExplorerWidgetLoading =
    billingExplorerWidgetSetupLoadingSelector(state);
  const costAnomaliesChanges =
    costAnomaliesSetupWidgetDataLoadingSelector(state);
  const commitmentUtilizationLoading =
    commitmentUtilizationWidgetSetupDataLoadingSelector(state);

  return (
    billingSummaryLoading ||
    billingExplorerWidgetLoading ||
    costAnomaliesChanges ||
    commitmentUtilizationLoading
  );
};
