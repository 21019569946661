import { FC } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { MultiTypeChartProvider } from "../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { BillingExplorerWidgetSetupContentCosts } from "../components/BillingExplorerWidgetSetupContentCosts";
import { BillingExplorerWidgetChart } from "../components/chart-view/BillingExplorerWidgetChart";
import { billingExplorerSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";
import { billingExplorerSetupHasAggregationSelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupHasAggregationSelector";
import { billingExplorerWidgetSetupChartDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupChartDataSelector";
import { billingExplorerWidgetSetupCombinedChartDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupCombinedChartDataSelector";
import { billingExplorerWidgetSetupTotalCostDateDetailsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupTotalCostDateDetailsSelector";
import { billingExplorerWidgetSetupLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupLoadingSelector";

export const BillingExplorerWidgetSetupChartView: FC = () => {
  const data = useAppSelector(billingExplorerWidgetSetupChartDataSelector);
  const dateDataPoint = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("frequency"),
  );

  const combinedChartData = useAppSelector(
    billingExplorerWidgetSetupCombinedChartDataSelector,
  );

  const dateDetails = useAppSelector(
    billingExplorerWidgetSetupTotalCostDateDetailsSelector,
  );
  const loading = useAppSelector(billingExplorerWidgetSetupLoadingSelector);

  const chartType =
    useAppSelector(
      billingExplorerSetupVisualizationPropertyByKeySelector("chart_type"),
    ) ?? "line";

  const chartBase = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("chart_base_type"),
  );

  const hasAggregations = useAppSelector(
    billingExplorerSetupHasAggregationSelector,
  );

  return (
    <Stack flex={1} p={2} justifyContent={hasAggregations ? "start" : "center"}>
      <MultiTypeChartProvider
        value={{
          data: data ?? [],
          initialChartType: chartType,
        }}
      >
        <BillingExplorerWidgetSetupContentCosts />
        <BillingExplorerWidgetChart
          dateDataPoint={dateDataPoint}
          chartType={chartType}
          dateDetails={dateDetails}
          loading={!data.length && loading}
          chartBase={chartBase}
          combinedChartData={combinedChartData}
        />
      </MultiTypeChartProvider>
    </Stack>
  );
};
