import { FC, ReactNode } from "react";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { blue, green, red } from "@mui/material/colors";
import { IconProps } from "@mui/material/Icon/Icon";

interface AccountCardLabelProps {
  label: string;
  description?: string;
  value?: number | ReactNode;
  color?: IconProps["color"] | null;
}

export const AccountCardLabel: FC<AccountCardLabelProps> = ({
  label,
  description,
  value,
  color,
}) => {
  const styles =
    color !== null
      ? {
          color: SquareRoundedIconColor.get(color),
        }
      : undefined;
  return (
    <Stack direction="row">
      {color !== null && (
        <SquareRoundedIcon
          color={color}
          sx={{
            fontSize: 12,
            mr: 1,
            mt: 0.4,
            color: styles?.color,
          }}
        />
      )}

      <div>
        <Typography color="grey.700" fontWeight="regular" fontSize="small">
          {label}
          {description && (
            <Tooltip arrow title={description} placement="top">
              <InfoIcon
                fontSize="small"
                sx={{ verticalAlign: "middle", ml: 1, color: "grey.400" }}
              />
            </Tooltip>
          )}
        </Typography>
        <Typography variant="body2" fontWeight="medium">
          {value}/
          <Typography variant="caption" component="span" color="grey.600">
            mo
          </Typography>
        </Typography>
      </div>
    </Stack>
  );
};

const SquareRoundedIconColor = new Map<IconProps["color"], string>([
  ["primary", green[500]],
  ["error", red[500]],
  ["info", blue[500]],
]);
