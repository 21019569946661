import { FC, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import {
  Box,
  IconButton,
  Popper,
  useTheme,
  ListItemTextProps,
  ListItemProps,
  ListItemIcon,
  ListItemText,
  ListItem,
  ListItemButton,
  Divider,
  Tooltip,
  ClickAwayListener,
} from "@mui/material";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { SvgIconComponent } from "@mui/icons-material";
import { AppNavItemWrapperPopperHeader } from "./AppNavItemWrapperPopperHeader";
import { TypographyWithTooltip } from "../../../common/TypographyWithTooltip";
import { navbarCollapsedDefaultSize } from "../../../layout/utils/hooks/useLayoutConfigHook";
import { AppNavOverlayContext } from "../../utils/useAppNavOverlay.context";
import { useAppSelector } from "../../../../store/hooks";
import { isSubscriptionCancelledSelector } from "../../../../store/subscriptions/subscriptionsSelectors";

interface AppNavItemProps {
  to: string;
  selected?: boolean;
  children?: ReactNode;
  navCollapsed?: boolean;
  Icon: SvgIconComponent;
  primary: ListItemTextProps["primary"];
  secondaryAction?: ListItemProps["secondaryAction"];
  popperHeaderClickable?: boolean;
  divider?: boolean;
  disabled?: boolean;
}

export const AppNavItemWrapper: FC<AppNavItemProps> = ({
  to,
  primary,
  children,
  selected,
  secondaryAction,
  navCollapsed,
  Icon,
  divider,
  disabled: disabledByProps,
  popperHeaderClickable,
}) => {
  const { zIndex } = useTheme();
  const ref = useRef<HTMLElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const resolvedPath = useResolvedPath(to || "");
  const match = useMatch({ path: resolvedPath.pathname });
  selected = (resolvedPath.pathname !== "/" && !!match) || selected;
  const disabled =
    useAppSelector(isSubscriptionCancelledSelector) || disabledByProps;

  const maxHeight = `calc(95vh - ${ref.current?.getBoundingClientRect().top || 0}px)`;
  const [hovered, setHovered] = useState(false);

  const onMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setHovered(true);
    }, 100);
  };

  const onMouseLeave = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setHovered(false);
    }, 100);
  }, []);

  const context = useMemo(() => {
    return { onClose: onMouseLeave };
  }, [onMouseLeave]);

  const bgColor = hovered || selected ? "grey.200" : undefined;
  const iconColor = selected
    ? "primary.main"
    : (hovered && "black") || undefined;
  const textColor = selected
    ? "primary"
    : (hovered && "text.primary") || "text.secondary";

  return (
    <ClickAwayListener
      onClickAway={onMouseLeave}
      //@ts-expect-error: mouseEvent doesn't have onMouseEnter but works well with it
      mouseEvent="onMouseEnter"
    >
      <Box
        ref={ref}
        textAlign="center"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {navCollapsed ? (
          <Tooltip arrow placement="right" title={children ? "" : primary}>
            <span>
              <IconButton
                to={to}
                onClick={onMouseLeave}
                component={NavLink}
                sx={{
                  width: navbarCollapsedDefaultSize,
                  height: navbarCollapsedDefaultSize,
                  borderRadius: 0,
                  opacity: disabled ? 0.4 : undefined,
                  bgcolor: bgColor,
                }}
                disabled={disabled}
              >
                <Icon fontSize="small" sx={{ color: iconColor }} />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <ListItem sx={{ p: 0 }} secondaryAction={secondaryAction}>
            <ListItemButton
              to={to}
              onClick={onMouseLeave}
              component={NavLink}
              disabled={disabled}
              sx={{ p: 1.25, pr: 3, bgcolor: bgColor }}
            >
              <ListItemIcon sx={{ minWidth: 30 }}>
                <Icon fontSize="small" sx={{ color: iconColor }} />
              </ListItemIcon>

              <ListItemText
                primary={
                  <TypographyWithTooltip
                    title={primary}
                    variant="inherit"
                    fontWeight={
                      selected ? "bold" : (hovered && "medium") || "normal"
                    }
                    color={textColor}
                  />
                }
              />
            </ListItemButton>
          </ListItem>
        )}

        {children && !disabled && (
          <Popper
            open={hovered}
            anchorEl={ref.current}
            sx={{ zIndex: zIndex.drawer, ...popperContentStyles }}
            modifiers={modifiers}
            placement="right-start"
          >
            <Box sx={{ maxHeight, ...boxStyles }}>
              <AppNavItemWrapperPopperHeader
                to={to}
                Icon={Icon}
                primary={primary}
                onClose={onMouseLeave}
                secondaryAction={secondaryAction}
                popperHeaderClickable={popperHeaderClickable}
              />

              <Box borderLeft={1} pb={1} borderColor="grey.300">
                <AppNavOverlayContext.Provider value={context}>
                  {children}
                </AppNavOverlayContext.Provider>
              </Box>
            </Box>
          </Popper>
        )}

        {divider && <Divider />}
      </Box>
    </ClickAwayListener>
  );
};

const modifiers = [
  {
    name: "offset",
    options: {
      offset: [-1, 0],
    },
  },
];

const popperContentStyles = {
  bgcolor: "grey.200",
  boxShadow: "8px 8px 12px -1px rgba(0, 0, 0, 0.2)",
  border: 1,
  borderColor: "grey.300",
  borderLeft: 0,
  overflow: "hidden",
};

const boxStyles = {
  width: 340,
  overflow: "auto",
  paddingTop: 6,
};
