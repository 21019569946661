import { getUsersMeOrganisationsCurrentWidgetsCostAnomaliesSelector } from "./getUsersMeOrganisationsCurrentWidgetsCostAnomaliesSelector";
import { RootState } from "../../../../../store";
import { costAnomaliesSetupWidgetPayloadDataSelector } from "../costAnomaliesSetupWidgetPayloadDataSelector";

export const costAnomaliesSetupWidgetDataResponseSelector = (
  state: RootState,
) => {
  const payload = costAnomaliesSetupWidgetPayloadDataSelector(state);

  if (!payload) {
    return;
  }

  return getUsersMeOrganisationsCurrentWidgetsCostAnomaliesSelector(payload)(
    state,
  );
};
