import { FC, Fragment } from "react";
import { useDidMount } from "rooks";
import { Stack } from "@mui/material";
import { CoverageWidgetCardHeader } from "./components/CoverageWidgetCardHeader";
import { CoverageWidgetContent } from "./components/CoverageWidgetContent";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { getCoverageWidgetDataByWidgetIdThunk } from "../../../../../../store/dashboards/thunks/widgets/coverage-widget/getCoverageWidgetDataByWidgetIdThunk";
import { coverageWidgetByWidgetIdLoadingSelector } from "../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetByWidgetIdLoadingSelector";
import { WidgetLoadingState } from "../common/WidgetLoadingState";

interface DashboardCoverageWidgetProps {
  widgetId: string;
}

export const DashboardCoverageWidget: FC<DashboardCoverageWidgetProps> = ({
  widgetId,
}) => {
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();

  const loading = useAppSelector((state) =>
    coverageWidgetByWidgetIdLoadingSelector(state, widgetId),
  );

  useDidMount(() => {
    dispatch(getCoverageWidgetDataByWidgetIdThunk(widgetId));
  });

  return (
    <Stack ref={ref} flexGrow={1} overflow="auto">
      {loading ? (
        <WidgetLoadingState />
      ) : (
        <Fragment>
          <CoverageWidgetCardHeader widgetId={widgetId} hovered={hovered} />
          <CoverageWidgetContent widgetId={widgetId} />
        </Fragment>
      )}
    </Stack>
  );
};
