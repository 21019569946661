import { resourceDetailsResourceFirstProcessKeySelector } from "./resourceDetailsResourceFirstProcessKeySelector";
import { resourceDetailsResourceBillingDataSelector } from "../resourceDetailsResourceBillingDataSelector";
import { RootState } from "../../../../store";

export const resourceDetailsResourceBillingDataPreviousPeriodTotalCostSelector =
  (state: RootState) => {
    const firstProcessKey =
      resourceDetailsResourceFirstProcessKeySelector(state);

    return resourceDetailsResourceBillingDataSelector(state, firstProcessKey)
      ?.previous_period_total_cost;
  };
