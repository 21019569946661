import { resourceExplorerViewDataByIdSelector } from "./resourceExplorerViewDataByIdSelector";
import { RootState } from "../../../store";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";

export const resourceExplorerViewVisibilityByIdSelector = (
  state: RootState,
  viewId: string,
): NavigationItemsVisibilityType | undefined => {
  return resourceExplorerViewDataByIdSelector(state, viewId)?.visibility;
};
