import { resourceDetailsOptionsSelector } from "./resourceDetailsOptionsSelector";
import { RootState } from "../../../store";

export const resourceDetailsCostsForecastOptionSelector = (
  state: RootState,
) => {
  const options = resourceDetailsOptionsSelector(state);

  return options.billingRelated.forecast_option;
};
