import { FC, useCallback } from "react";
import { useToggle } from "rooks";
import { IconButton, Stack } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useFlag } from "@unleash/proxy-client-react";
import { BillingExplorerGroupBySelector } from "./components/BillingExplorerGroupBySelector";
import { ResourceExplorerDateRange } from "./components/ResourceExplorerDateRange";
import { ResourceExplorerFilterTree } from "./components/filter-tree/ResourceExplorerFilterTree";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { isBillingAvailableSelector } from "../../../../../../../store/common/selectors/billing-status/isBillingAvailableSelector";
import { ViewSettingsDrawer } from "../../../../../../app-navigation/components/page-components/billing-explorer/components/actions-menu/action-items/report-settings/components/ViewSettingsDrawer";
import { ExploreWithAIButtonV2 } from "../../../../../../../storybook/explore-with-AI-button/ExploreWithAIButtonV2";
import { billingExplorerExplainWithAiOpenSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiOpenSelector";
import { setBillingExplorerSideBarPanelContentType } from "../../../../../../../store/resource-explorer/resourceExplorerSlice";

export const ResourceExplorerToolbar: FC = () => {
  const [settingDrawerOpen, toggleSettingsDrawerOpen] = useToggle();
  const dispatch = useAppDispatch();
  const enableResourcesExplorerExplainWithAI = useFlag(
    "EnableResourcesExplorerExplainWithAI",
  );
  const enableResourcesExplorerExplainWithAIWithExplanation = useFlag(
    "EnableResourcesExplorerExplainWithAIWithExplanation",
  );
  const isResourceExplorerAvailable = useAppSelector(
    isBillingAvailableSelector,
  );

  const isOpenExplainWithAiDrawer = useAppSelector(
    billingExplorerExplainWithAiOpenSelector,
  );
  const explainWithAiButtonCLickHandler = useCallback(() => {
    dispatch(setBillingExplorerSideBarPanelContentType("explainWithAI"));
  }, [dispatch]);
  if (!isResourceExplorerAvailable) {
    return null;
  }

  return (
    <Stack
      p={1}
      direction="row"
      borderBottom={1}
      justifyContent="space-between"
      borderColor="grey.300"
      alignItems="center"
    >
      <Stack direction="row" spacing={1}>
        <BillingExplorerGroupBySelector />

        <ResourceExplorerDateRange />

        <ResourceExplorerFilterTree />
      </Stack>
      <Stack direction="row" spacing={1}>
        {enableResourcesExplorerExplainWithAI &&
          enableResourcesExplorerExplainWithAIWithExplanation && (
            <ExploreWithAIButtonV2
              onClick={explainWithAiButtonCLickHandler}
              state={isOpenExplainWithAiDrawer ? "focused" : "initial"}
            />
          )}

        <IconButton onClick={toggleSettingsDrawerOpen}>
          <SettingsOutlinedIcon fontSize="small" />
        </IconButton>
      </Stack>

      <ViewSettingsDrawer
        open={settingDrawerOpen}
        onClose={toggleSettingsDrawerOpen}
      />
    </Stack>
  );
};
