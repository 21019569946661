import { FC, Fragment, memo, useCallback, useMemo } from "react";
import { FormikHandlers } from "formik";
import { capitalize } from "@mui/material";
import {
  AutocompleteFilterBody,
  AutocompleteFilterBodyProps,
} from "../AutocompleteFilterBody";
import {
  FilterItem,
  Operator,
  useGetUsersMeAccountsResourceTypesByResourceTypeSupportTypesQuery,
} from "../../../../../../../../services/cloudchipr.api";
import { FilterItemOperatorType } from "../../../FilterItemOperatorType";

interface SupportTypeFilterProps extends AutocompleteFilterBodyProps {
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
}

export const SupportTypeFilter: FC<SupportTypeFilterProps> = memo(
  ({ operators, filter, onChange, ...props }) => {
    const { data, isLoading } =
      useGetUsersMeAccountsResourceTypesByResourceTypeSupportTypesQuery({
        resourceType: "eks",
      });

    const selectedValue = useMemo(() => {
      const value = filter?.value;

      const stringValue = value && typeof value === "string" ? [value] : [];
      return Array.isArray(value) ? value : stringValue;
    }, [filter?.value]);

    const filterValueChangeHandler = useCallback(
      (_: any, values: any) => {
        const value = values[0];
        props.setFieldValue("value", value);
        return props.setFieldValue("value", value);
      },
      [props],
    );

    const renderOptions = useMemo(() => {
      return data?.map((item) => {
        return {
          value: item,
          label: capitalize(item),
        };
      });
    }, [data]);

    return (
      <Fragment>
        <FilterItemOperatorType
          onChange={onChange}
          options={operators}
          value={filter.operator || "in"}
        />

        <AutocompleteFilterBody
          singleSelect
          {...props}
          options={renderOptions ?? []}
          isLoading={isLoading}
          label="Support Type"
          selectedValue={selectedValue}
          setFieldValue={filterValueChangeHandler}
          formatSelectedLabel={formatSelectedLabel}
        />
      </Fragment>
    );
  },
);

const formatSelectedLabel = (value: string) => capitalize(value);
