import { FC, ReactNode } from "react";
import { CreateIntegrationButton } from "../../../common/components/CreateIntegrationButton";
import { integrationDetails } from "../../common/utils/constants/integrationDetails";

interface EmailIntegrationConnectButtonProps {
  withStartIcon?: boolean;
  variant?: "outlined" | "contained";
  children?: ReactNode;
}

export const EmailIntegrationConnectButton: FC<
  EmailIntegrationConnectButtonProps
> = ({ withStartIcon, variant, children }) => {
  const { label } = integrationDetails["email"];

  return (
    <CreateIntegrationButton
      type="email"
      withStartIcon={withStartIcon}
      integrationLabel={label}
      variant={variant}
    >
      {children}
    </CreateIntegrationButton>
  );
};
