import { resourceExplorerDataSelector } from "./resourceExplorerDataSelector";
import { RootState } from "../../../../store";
import { ResourceExplorerGroupingNullable } from "../../../../../services/cloudchipr.api";

export const resourceExplorerGroupingSelector = (
  state: RootState,
): ResourceExplorerGroupingNullable => {
  const data = resourceExplorerDataSelector(state);

  return data?.grouping ?? null;
};
