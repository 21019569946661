import { FC, useCallback } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { CategoryNameField } from "../../common/CategoryNameField";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { DimensionCategory } from "../../../../../../../../store/dimensions/utils/types/common";
import { dimensionDrawerModeSelector } from "../../../../../../../../store/dimensions/selectors/slice-data/dimensionDrawerModeSelector";
import { deleteCategory } from "../../../../../../../../store/dimensions/dimensionsSlice";
import { OpenInResourceExplorerButton } from "../../../../data-grid/common/OpenInResourceExplorerButton";
import { dimensionIdSelector } from "../../../../../../../../store/dimensions/selectors/slice-data/dimensionIdSelector";
import { useAppAbility } from "../../../../../../../../services/permissions";

interface CategoryCollapseHeaderProps {
  collapsed: boolean;
  deleteDisabled: boolean;
  title: string;
  toggleCollapsed(): void;
  category: DimensionCategory;
}

export const CategoryCollapseHeader: FC<CategoryCollapseHeaderProps> = ({
  toggleCollapsed,
  collapsed,
  category,
  title,
  deleteDisabled,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotDeleteCategory = cannot("delete", "category");

  const mode = useAppSelector(dimensionDrawerModeSelector);
  const dimensionId = useAppSelector(dimensionIdSelector);

  const deleteHandler = useCallback(
    () => dispatch(deleteCategory(category.id)),
    [category.id, dispatch],
  );

  if (mode === "categoryCreate" || mode === "categoryEdit") {
    return null;
  }

  return (
    <Stack p={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <CategoryNameField name={category.name} id={category.id} />

        {!deleteDisabled && (
          <IconButton
            onClick={deleteHandler}
            disabled={canNotDeleteCategory}
            size="small"
          >
            <DeleteOutlineIcon />
          </IconButton>
        )}
      </Stack>

      <Stack
        p={1}
        direction="row"
        borderBottom={collapsed ? 0 : 1}
        alignItems="center"
        borderColor="grey.300"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton size="small" onClick={toggleCollapsed}>
            {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>

          <Typography variant="body2" fontWeight="medium">
            {title}
          </Typography>
        </Stack>

        {mode === "dimensionEdit" && (
          <Stack direction="row" alignItems="center" spacing={1}>
            {dimensionId && (
              <OpenInResourceExplorerButton
                dimensionId={dimensionId}
                categoryId={category.id}
              />
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
