import { getAzureUsersMeOrganisationsCurrentReservationsSelector } from "./getAzureUsersMeOrganisationsCurrentReservationsSelector";
import { RootState } from "../../../../../../store";
import { commitmentsGranularPayloadDataSelector } from "../../../../common/payload/commitmentsGranularPayloadDataSelector";

export const azureReservationsForGranularSelector = (state: RootState) => {
  const payload = commitmentsGranularPayloadDataSelector(state);
  return getAzureUsersMeOrganisationsCurrentReservationsSelector(payload)(
    state,
  );
};
