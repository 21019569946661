import { FC } from "react";
import { Stack } from "@mui/material";
import { VerifiedUserOutlined } from "@mui/icons-material";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { HierarchyViewActionButton } from "../actions/HierarchyViewActionButton";
import { View } from "../../../../../../services/cloudchipr.api";

interface ViewsHierarchyMoreTabsDropdownLabelProps {
  view: View;
  onClose?(): void;
  selected?: boolean;
}

export const ViewsHierarchyMoreTabsDropdownLabel: FC<
  ViewsHierarchyMoreTabsDropdownLabelProps
> = ({ onClose, selected, view }) => {
  const { ref, hovered } = useHover();

  return (
    <Stack
      ref={ref}
      direction="row"
      flex={1}
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {!!view?.protection_details?.is_protected && (
          <VerifiedUserOutlined sx={{ fontSize: 16 }} />
        )}

        <TypographyWithTooltip
          title={view.name}
          maxWidth={300}
          variant="inherit"
        />
      </Stack>
      <HierarchyViewActionButton
        view={view}
        onClose={onClose}
        visible={hovered || !!selected}
      />
    </Stack>
  );
};
