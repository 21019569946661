import { FC, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { Box } from "@mui/material";
import { agGridWrapperStyles } from "../../components/pages/common/ag-grid/utils/constants/agGridStyles";

interface PoorAgGridProps {
  data: any;
}

export const PoorAgGrid: FC<PoorAgGridProps> = ({ data }) => {
  const mainWrapperStyles = useMemo(() => {
    return {
      width: "100%",
      height: "100%",
      "& ": { ...agGridWrapperStyles },
    };
  }, []);

  return (
    <Box sx={mainWrapperStyles} className="ag-theme-quartz">
      <AgGridReact {...data} defaultColDef={{ flex: 1 }} />
    </Box>
  );
};
