import { FC, useCallback, useMemo } from "react";
import { Link, Stack, Tooltip } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { TypographyWithCopy } from "../../../../../common/TypographyWithCopy";
import { toggleAlertsOpenDrawer } from "../../../../../../store/alerts/alertsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { AlertTabType } from "../../../../../../store/alerts/utils/types/types";
import { setCostAnomalyAlertEditDataThunk } from "../../../../../../store/alerts/thunks/cost-anomaly-alerts/setCostAnomalyAlertEditDataThunk";
import { setUtilizationAlertEditDataThunk } from "../../../../../../store/alerts/thunks/utilization-alerts/setUtilizationAlertEditDataThunk";
import { organizationsForCommitmentsSelector } from "../../../../../../store/commitments/selectors/organisations/organizationsForCommitmentsSelector";

interface AlertsDataGridNameCellProps {
  alertName: string;
  alertId: string;
  alertType: AlertTabType;
  disabled?: boolean;
  disabledTooltipTitle?: string;
  orgId?: string;
}

export const AlertsDataGridNameCell: FC<AlertsDataGridNameCellProps> = ({
  alertId,
  alertType,
  alertName,
  disabled,
  disabledTooltipTitle,
  orgId,
}) => {
  const dispatch = useAppDispatch();
  const organizations = useAppSelector(organizationsForCommitmentsSelector);

  const noCommitment = useMemo(() => {
    const org = organizations?.find((org) => org.organization_id === orgId);

    return !org && alertType === "utilization";
  }, [organizations, alertType, orgId]);

  const handleClick = useCallback(() => {
    dispatch(toggleAlertsOpenDrawer());

    if (alertType === "costAnomaly") {
      dispatch(setCostAnomalyAlertEditDataThunk(alertId));
    } else {
      dispatch(setUtilizationAlertEditDataThunk(alertId));
    }
  }, [dispatch, alertType, alertId]);

  return (
    <Stack direction="row" spacing={0.5}>
      {(disabled || noCommitment) && (
        <Tooltip
          arrow
          placement="top"
          title={
            noCommitment
              ? "You don’t have any commitments in the selected organization."
              : disabledTooltipTitle
          }
        >
          <WarningAmberIcon color="warning" fontSize="small" />
        </Tooltip>
      )}

      <TypographyWithCopy
        fontSize="inherit"
        value={alertName}
        component={Link}
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
      />
    </Stack>
  );
};
