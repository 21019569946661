import { Container, Divider, Stack } from "@mui/material";
import { FC, ReactNode } from "react";
import { CommunicationIntegrationDrawer } from "./drawer/CommunicationIntegrationDrawer";
import { PageHeader } from "../../../../../common/PageHeader";
import { IntegrationPageOverview } from "../../../common/components/IntegrationPageOverview";
import { BreadcrumbsType } from "../../../../../common/breadcrumbs/Breadcrumbs";
import { IntegrationType } from "../../../../../../../store/integrations/integrationsSlice";

interface CommunicationIntegrationsPageWrapperProps {
  type: IntegrationType;
  breadcrumbs?: BreadcrumbsType[];
  children?: ReactNode;
}

export const CommunicationIntegrationsPageWrapper: FC<
  CommunicationIntegrationsPageWrapperProps
> = ({ type, breadcrumbs, children }) => {
  return (
    <Stack bgcolor="white" height="100%">
      <PageHeader breadcrumbs={breadcrumbs} />
      <Container sx={{ p: 2, bgcolor: "white", height: "100%" }}>
        <Stack height="100%">
          <IntegrationPageOverview type={type} />
          <Divider sx={{ my: 1.5 }} />
          {children}
          <CommunicationIntegrationDrawer />
        </Stack>
      </Container>
    </Stack>
  );
};
