import { FC, Fragment, useCallback, useEffect, useRef } from "react";
import { Drawer, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import { AskAiInputArea } from "./components/suggestions/AskAiInputArea";
import { AskAiFloatingButton } from "./components/AskAiFloatingButton";
import { askAiExcludedPages } from "./components/utils/constnats";
import { AskAiHeader } from "./components/AskAiHeader";
import { AskAiConversation } from "./components/conversation/AskAiConversation";
import { AskAiSuggestions } from "./components/suggestions/AskAiSuggestions";
import { AskAIDragHandler } from "./components/AskAIDragHandler";
import { usePanelHorizontalResize } from "../../../utils/hooks/usePanelHorizontalResize";
import { useLocalStorageHook } from "../../../../utils/hooks/useLocalStorage.hook";
import { useAppSelector } from "../../../../store/hooks";
import { chunkedAskAiConversationsSelector } from "../../../../store/ask-ai/selectors/chunkedAskAiConversationsSelector";

export const AskAi: FC = () => {
  const { width, handleMouseDown, setResizingPanelWidth } =
    usePanelHorizontalResize(drawerDefaults);

  const location = useLocation();
  const pathname = location.pathname;
  const { getItem } = useLocalStorageHook();
  const chunkedConversation = useAppSelector(chunkedAskAiConversationsSelector);

  const conversationEndRef = useRef<HTMLDivElement | null>(null);
  const drawerWidthFromStorage = getItem(drawerDefaults.key);

  const scrollIntoConversationBottom = useCallback(() => {
    requestAnimationFrame(() => {
      conversationEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    });
  }, [conversationEndRef]);

  const drawerWidthSetHandler = useCallback(() => {
    const width =
      typeof drawerWidthFromStorage === "number" ? drawerWidthFromStorage : 40;
    setResizingPanelWidth(width);
    scrollIntoConversationBottom();
  }, [
    setResizingPanelWidth,
    scrollIntoConversationBottom,
    drawerWidthFromStorage,
  ]);

  const closeDrawer = useCallback(() => {
    setResizingPanelWidth(0);
  }, [setResizingPanelWidth]);

  useEffect(() => {
    if (!chunkedConversation) {
      return;
    }
    setTimeout(() => {
      scrollIntoConversationBottom();
    }, 100);
  }, [chunkedConversation, scrollIntoConversationBottom]);

  if (askAiExcludedPages.some((page) => pathname.includes(page))) {
    return null;
  }

  return (
    <Fragment>
      <AskAiFloatingButton onClick={drawerWidthSetHandler} />

      <Drawer
        open={true}
        anchor="right"
        variant="permanent"
        PaperProps={{
          sx: { width, height: "100vh", overflow: "hidden" },
        }}
      >
        <Stack flex={1} height="100%">
          <Stack direction="row" flex={1} overflow="hidden">
            <AskAIDragHandler onMouseDown={handleMouseDown} />

            <Stack flex={1} spacing={1} sx={{ overflowY: "auto" }}>
              <AskAiHeader onClose={closeDrawer} />
              <AskAiConversation />
              <AskAiSuggestions />
              <div ref={conversationEndRef} />
            </Stack>
          </Stack>

          <AskAiInputArea
            scrollIntoConversationBottom={scrollIntoConversationBottom}
          />
        </Stack>
      </Drawer>
    </Fragment>
  );
};

const drawerDefaults = {
  defaultWidthPercent: 0,
  minWidthPercent: 20,
  key: "ask_ai_drawer",
};
