import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { KubernetesLiveResourceFiltersContentCard } from "./KubernetesLiveResourceFiltersContentCard";
import { useAppSelector } from "../../../../../store/hooks";
import { kubernetesLiveUsageMgmtAppliedAllFiltersCountSelector } from "../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtAppliedAllFiltersCountSelector";
import { kubernetesLiveUsageMgmtAppliedFiltersSelector } from "../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtAppliedFiltersSelector";
import { allResourcesByProvider } from "../../../../../utils/constants/resources/resourceGroupsByProvider";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../live-usage-mgmt/utils/helpers/getIsResourceTypeHiddenByFeatureToggle";
import { isStringResourceType } from "../../../../../utils/helpers/resources/isStringResourceType";
import { LiveResourceFiltersContentProps } from "../../../../common/resource-filters/components/content/LiveResourceFiltersContent";

interface KubernetesLiveResourceFiltersContentProps
  extends LiveResourceFiltersContentProps {}

export const KubernetesLiveResourceFiltersContent: FC<
  KubernetesLiveResourceFiltersContentProps
> = ({ setRefFromKey, provider, onClose, activeResourceType }) => {
  const filtersCount = useAppSelector(
    kubernetesLiveUsageMgmtAppliedAllFiltersCountSelector,
  );
  const filters = useAppSelector(kubernetesLiveUsageMgmtAppliedFiltersSelector);
  const resources = allResourcesByProvider.get(provider);

  const notAllResources = isStringResourceType(activeResourceType);

  const singleResourceFilter = notAllResources
    ? (filters[activeResourceType] ?? {
        filter_groups: [],
        filter_items: [],
        operators: [],
        type: activeResourceType,
      })
    : null;
  const singleResourceItem = singleResourceFilter && (
    <KubernetesLiveResourceFiltersContentCard
      onClose={onClose}
      key={activeResourceType}
      setRefFromKey={setRefFromKey}
      filter={singleResourceFilter}
    />
  );

  const count = notAllResources
    ? singleResourceFilter?.filter_items?.length
    : filtersCount;

  return (
    <Stack flex={1}>
      <Typography variant="subtitle1" fontWeight="medium" p={1} pb={2} pl={2}>
        Filters ({count})
      </Typography>

      <Stack overflow="auto" px={1} spacing={2}>
        {notAllResources
          ? singleResourceItem
          : resources?.map((resourceType) => {
              const filter = filters[resourceType];

              if (getIsResourceTypeHiddenByFeatureToggle(resourceType)) {
                return null;
              }

              return (
                <KubernetesLiveResourceFiltersContentCard
                  filter={
                    filter ?? {
                      filter_groups: [],
                      filter_items: [],
                      operators: [],
                      type: resourceType,
                    }
                  }
                  onClose={onClose}
                  key={resourceType}
                  setRefFromKey={setRefFromKey}
                />
              );
            })}
      </Stack>
    </Stack>
  );
};
