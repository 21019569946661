import { kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector } from "./kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { kubernetesLiveUsageMgmtResourceTypeDataSelector } from "./kubernetesLiveUsageMgmtResourceTypeDataSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { kubernetesLiveUsagePageStateSelector } from "../store-selectors/kubernetesLiveUsagePageStateSelector";
import { kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector } from "../store-selectors/kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector";
import { getKubernetesResourceTypeDataWithoutFiltersFixedCacheKey } from "../../thunks/resources/getKubernetesLiveUsageMgmtResourcesWithOutFiltersByResourceTypeThunk";

export const kubernetesLiveUsageMgmtResourceTypeDataSpendingSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const pageState = kubernetesLiveUsagePageStateSelector(state);

  if (pageState === "pending") {
    return 0;
  }
  const withoutFilters =
    kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector(
      state,
      resourceType,
    );

  const filterTemplatesEnabled =
    kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector(state);

  const fixedCacheKey =
    withoutFilters || !filterTemplatesEnabled
      ? getKubernetesResourceTypeDataWithoutFiltersFixedCacheKey(resourceType)
      : resourceType;

  return (
    kubernetesLiveUsageMgmtResourceTypeDataSelector(state, fixedCacheKey)
      ?.total_monthly_spending ?? 0
  );
};
