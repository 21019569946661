import { FC, useCallback } from "react";
import { Button, ButtonProps, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAppAbility } from "../../../../services/permissions";
import { useAppDispatch } from "../../../../store/hooks";
import { setDimensionsDrawerMode } from "../../../../store/dimensions/dimensionsSlice";
import { getUserPermissionInfoText } from "../../utils/helpers/getUserPermissionInfoText";

interface AddDimensionButtonProps {
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
}

export const AddDimensionButton: FC<AddDimensionButtonProps> = ({
  variant = "contained",
  size = "small",
}) => {
  const { cannot } = useAppAbility();
  const canNotCreateCategory = cannot("create", "category");
  const dispatch = useAppDispatch();

  const dimensionDrawerOpenHandler = useCallback(() => {
    dispatch(setDimensionsDrawerMode("dimensionCreate"));
  }, [dispatch]);

  const userPermissionTooltipText = canNotCreateCategory
    ? getUserPermissionInfoText("Add Dimensions")
    : "";

  return (
    <Tooltip title={userPermissionTooltipText}>
      <span>
        <Button
          sx={sx}
          size={size}
          variant={variant}
          disabled={canNotCreateCategory}
          onClick={dimensionDrawerOpenHandler}
          startIcon={<AddIcon fontSize="small" />}
        >
          Add Dimension
        </Button>
      </span>
    </Tooltip>
  );
};

const sx = { whiteSpace: "nowrap", textTransform: "none" };
