import { FC } from "react";
import {
  ChartType,
  WidgetCommitmentsUtilizationVisualization,
} from "../../../../../../../../services/cloudchipr.api";
import { CommitmentUtilizationWidgetChartBaseSelect } from "../../../../adding-widget/widget-create/widget-setups/commitment-utilization /components/sidebar/components/configurations/visualization/components/CommitmentUtilizationWidgetChartBaseSelect";
import { CommitmentUtilizationWidgetVisualizationUtilization } from "../../../../adding-widget/widget-create/widget-setups/commitment-utilization /components/sidebar/components/configurations/visualization/components/CommitmentUtilizationWidgetVisualizationUtilization";
import { CommitmentUtilizationWidgetVisualizationCommitment } from "../../../../adding-widget/widget-create/widget-setups/commitment-utilization /components/sidebar/components/configurations/visualization/components/CommitmentUtilizationWidgetVisualizationCommitment";
import { CommitmentUtilizationWidgetVisualizationNetSavings } from "../../../../adding-widget/widget-create/widget-setups/commitment-utilization /components/sidebar/components/configurations/visualization/components/CommitmentUtilizationWidgetVisualizationNetSavings";

export const commitmentUtilizationVisualizationDetailsByChartType = new Map<
  ChartType,
  (keyof WidgetCommitmentsUtilizationVisualization)[]
>([
  ["stack", ["chart_base_type"]],
  ["horizontal_stack_bar", ["chart_base_type"]],
  ["table", ["utilization", "commitment", "net_savings"]],
]);

export const commitmentUtilizationVisualizationDetailsComponentByType = new Map<
  keyof WidgetCommitmentsUtilizationVisualization,
  FC
>([
  ["chart_base_type", CommitmentUtilizationWidgetChartBaseSelect],
  ["utilization", CommitmentUtilizationWidgetVisualizationUtilization],
  ["commitment", CommitmentUtilizationWidgetVisualizationCommitment],
  ["net_savings", CommitmentUtilizationWidgetVisualizationNetSavings],
]);
