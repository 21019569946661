import { RootState } from "../../../store";
import { resourceDetailsSliceSelector } from "../resourceDetailsSliceSelector";
import { ResourceDetailsInterface } from "../../utils/types/common";

export const resourceDetailsTargetSelector = (
  state: RootState,
): ResourceDetailsInterface["target"] => {
  const slice = resourceDetailsSliceSelector(state);

  return slice.target;
};
