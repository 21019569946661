import { ColDef } from "ag-grid-community";
import { UtilizationAlertDataGridDataType } from "../../../../../../../../store/alerts/utils/types/types";
import { utilizationAlertTypeTitles } from "../../../../drawer/components/utilization/utils/constants/constants";

export const utilizationAlertTypeValueGetter: ColDef<UtilizationAlertDataGridDataType>["valueGetter"] =
  ({ data }) => {
    if (!data) {
      return;
    }

    return utilizationAlertTypeTitles.get(data.based_on);
  };
