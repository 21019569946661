import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Typography } from "@mui/material";
import { ReservationUtilizationRecommendation } from "../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { CommitmentsProgressBar } from "../../../../../common/components/CommitmentsProgressBar";
import { DatesCell } from "../../../../../common/components/data-grid/cells/DatesCell";
import { money } from "../../../../../../../../utils/numeral/money";
import { CommitmentsRecommendationsEstimatedMonthlySavingCell } from "../../../content/recomendations/cells/CommitmentsRecommendationsEstimatedMonthlySavingCell";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const reservedInstanceDrawerRecommendationsGridColumnDefs: ColDef<ReservationUtilizationRecommendation>[] =
  [
    {
      minWidth: 200,
      field: "current_instance_count",
      lockVisible: true,
      suppressColumnsToolPanel: true,
      headerName: "Current Instance Count",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationUtilizationRecommendation>) => (
        <TypographyWithTooltip
          title={data?.current_instance_count ?? ""}
          variant="body2"
        />
      ),
    },
    {
      minWidth: 240,
      field: "recommended_instance_count",
      headerName: "Recommended Instance Count",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationUtilizationRecommendation>) => (
        <TypographyWithTooltip
          title={data?.recommended_instance_count ?? ""}
          variant="body2"
        />
      ),
    },
    {
      minWidth: 160,
      width: 200,
      field: "platform",
      headerName: "Platform",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationUtilizationRecommendation>) => (
        <TypographyWithTooltip
          variant="inherit"
          title={data?.platform ?? "-"}
        />
      ),
    },
    {
      minWidth: 220,
      field: "current_utilization",
      headerName: "Current Utilization",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationUtilizationRecommendation>) => (
        <CommitmentsProgressBar value={data?.current_utilization ?? 0} />
      ),
    },
    {
      minWidth: 220,
      field: "estimated_utilization",
      headerName: "Estimated Utilization",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationUtilizationRecommendation>) => (
        <CommitmentsProgressBar value={data?.estimated_utilization ?? 0} />
      ),
    },
    {
      minWidth: 240,
      field: "reservation_end_date",
      headerName: "Current Commitment End Date",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationUtilizationRecommendation>) => (
        <DatesCell date={data?.reservation_end_date ?? ""} />
      ),
    },
    {
      minWidth: 220,
      field: "recommended_start_date",
      headerName: "Recommended Start Date",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationUtilizationRecommendation>) => (
        <DatesCell date={data?.recommended_start_date ?? ""} />
      ),
    },
    {
      minWidth: 220,
      field: "estimated_monthly_savings",
      headerName: "Estimated Monthly Savings",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationUtilizationRecommendation>) => (
        <Typography variant="body2" color="success.light">
          {money(data?.estimated_monthly_savings ?? 0)}
        </Typography>
      ),
      valueGetter: ({ data }) => money(data?.estimated_monthly_savings ?? 0),
    },
    {
      minWidth: 215,
      pinned: "right",
      lockVisible: true,
      suppressColumnsToolPanel: true,
      field: "estimated_monthly_savings",
      colId: "estimated_monthly_savings_pinned_cell",
      headerName: "Estimated Monthly Savings",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationUtilizationRecommendation>) => (
        <CommitmentsRecommendationsEstimatedMonthlySavingCell
          value={data?.estimated_monthly_savings ?? 0}
          link={data?.link ?? ""}
        />
      ),
    },
    agGridEmptyColumnToFitEmptySpace,
  ];
