import { FC, useCallback, useMemo, useState } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { AxisLabelsFormatterContextObject } from "highcharts";
import { ResourceDetailsMetricsContentMetricStatsSelect } from "./ResourceDetailsMetricsContentMetricStatsSelect";
import { ResourceMetricTypeObject } from "../../../../../../../../services/cloudchipr.api";
import { MultiTypeHighcharts } from "../../../../../../../../storybook/c8r-highcharts/MultiTypeHighcharts";
import { HighchartsProvider } from "../../../../../../../../storybook/c8r-highcharts/common/components/HighchartsProvider";
import { HighchartsChartDataType } from "../../../../../../../../storybook/c8r-highcharts/common/utils/types/common";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";
import {
  colorsByMetricKey,
  metricKeyLabels,
  metricsChartsPeriods,
} from "../../../../utils/constants/metrics";
import { convertByteToSize } from "../../../../../../../utils/helpers/convertByteToSize";
import { TooltipValueFormatterType } from "../../../../../../../../storybook/c8r-highcharts/common/utils/tooltip/getHighchartsTooltipHTML";
import {
  MetricKey,
  MetricPeriods,
  MetricsStatsType,
} from "../../../../utils/types/metrics";

interface ResourceDetailsMetricsContentMetricCardProps {
  metricKey: MetricKey;
  loading: boolean;
  period: MetricPeriods;
  metrics: ResourceMetricTypeObject;
}

export const ResourceDetailsMetricsContentMetricCard: FC<
  ResourceDetailsMetricsContentMetricCardProps
> = ({ metricKey, metrics, loading, period }) => {
  const [metricsStats, setMetricsStats] = useState<MetricsStatsType>("maximum");

  const frequency = metricsChartsPeriods[period]?.frequency;
  const frequencyValue = metricsChartsPeriods[period]?.value;
  const color = colorsByMetricKey[metricKey];
  const label = metricKeyLabels[metricKey];
  const percentBased = metricKey === "cpu";

  const chartData = useMemo(() => {
    const defaultData = {
      categories: [],
      series: [{ data: [], name: label, color: color }],
    };

    const metricsData = metrics?.[frequency]
      ?.toReversed()
      ?.slice(0, frequencyValue);

    return metricsData?.reduce((acc, metric) => {
      acc.categories.push(formatDate(metric.timestamp, { type: "date" }));

      if (acc?.series?.at(0)?.data) {
        acc.series?.at(0)?.data?.push(metric[metricsStats]);
      }

      return acc;
    }, defaultData as HighchartsChartDataType);
  }, [metrics, metricsStats, label, frequency, color, frequencyValue]);

  const xAxisLabelsFormatter = useCallback(
    (params: AxisLabelsFormatterContextObject) =>
      formatDate(`${params.value}`, {
        type: frequency === "hourly" ? "time" : "dayMonthName",
      }),
    [frequency],
  );

  const tooltipValueFormatter: TooltipValueFormatterType = useCallback(
    (point) => {
      const value = +(point.y ?? 0).toFixed(2);

      const valueWithPercent = `${value}%`;

      return {
        value: value,
        markup: `<span style="font-weight: 500">${percentBased ? valueWithPercent : convertByteToSize(value, 0)}</span>`,
      };
    },
    [percentBased],
  );

  return (
    <Card variant="outlined" sx={{ borderRadius: 3 }}>
      <Stack
        p={1}
        spacing={1}
        direction="row"
        alignItems="center"
        borderBottom={1}
        borderColor="grey.300"
      >
        <Typography
          variant="body1"
          fontWeight="medium"
          color="text.secondary"
          pl={1}
        >
          {label}
        </Typography>

        <ResourceDetailsMetricsContentMetricStatsSelect
          metricsStats={metricsStats}
          setMetricsStats={setMetricsStats}
        />
      </Stack>

      <Box p={1} height={180}>
        <HighchartsProvider>
          <MultiTypeHighcharts
            loading={loading ? { showLoader: true } : false}
            chartType="area"
            percentBased={percentBased}
            showTotalValueInTooltip={false}
            chartData={chartData}
            xAxisLabelsFormatter={xAxisLabelsFormatter}
            yAxisLabelsFormatter={
              percentBased ? undefined : yAxisLabelsFormatter
            }
            tooltipValueFormatter={tooltipValueFormatter}
            noDataMessage={`No ${label} Metrics`}
          />
        </HighchartsProvider>
      </Box>
    </Card>
  );
};

const yAxisLabelsFormatter = (params: AxisLabelsFormatterContextObject) => {
  const converted = convertByteToSize(params.value, 0);

  return `<span style="font-weight: normal">${params.value ? converted : 0}</span>`;
};
