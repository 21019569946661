import { coverageWidgetSetupDataPayloadSelector } from "./coverageWidgetSetupDataPayloadSelector";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";

export const endpoint =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentWidgetsCoverage.select;

export const coverageWidgetSetupEndpointSelector = (state: RootState) => {
  const payload = coverageWidgetSetupDataPayloadSelector(state);
  if (!payload) {
    return;
  }

  return endpoint(payload)(state);
};
