import { FC } from "react";
import { useAppSelector } from "../../../../../store/hooks";
import { commitmentsCurrentOrgIdSelector } from "../../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { commitmentsTypeTabSelector } from "../../../../../store/commitments/selectors/common/commitmentsTypeTabSelector";
import {
  ScreenSplitButtons,
  ScreenSplitButtonsProps,
} from "../../../common/ScreenSplitButtons";
import { ProviderType } from "../../../../../services/cloudchipr.api";

interface CommitmentsScreenSplitButtonsProps
  extends Omit<ScreenSplitButtonsProps, "localStorageKey"> {
  provider: ProviderType;
}

export const CommitmentsScreenSplitButtons: FC<
  CommitmentsScreenSplitButtonsProps
> = ({ provider, ...props }) => {
  const commitmentType = useAppSelector(commitmentsTypeTabSelector);
  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);

  const key = `c8r:${orgId}-${provider}-commitments-${commitmentType}:ScreenVisibility`;

  return <ScreenSplitButtons localStorageKey={key} {...props} />;
};
