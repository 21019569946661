import { FC, useCallback } from "react";
import Stack from "@mui/material/Stack";
import { IconButton, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TaskCompleteActionMenuItem } from "./TaskCompleteActionMenuItem";
import { TaskDeleteActionMenuItem } from "./delete-action/TaskDeleteActionMenuItem";
import { TaskEditActionMenuItem } from "./TaskEditActionMenuItem";
import { TaskCopyLinkActionMenuItem } from "./TaskCopyLinkActionMenuItem";
import { Task } from "../../../../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";

interface TaskGridRowActionsMenuProps {
  data?: Task;
  onClose?(): void;
  hiddenActions?: ("edit" | "delete" | "copy" | "complete")[];
}

export const TaskGridRowActionsMenu: FC<TaskGridRowActionsMenuProps> = ({
  data,
  onClose,
  hiddenActions = [],
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const closeHandler = useCallback(() => {
    onClose?.();
    closeMenu();
  }, [onClose, closeMenu]);

  const completed = data?.status.category === "completed";

  if (!data) {
    return;
  }

  return (
    <Stack alignItems="center" height="100%" justifyContent="center">
      <IconButton onClick={openMenu} size="small">
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        {!hiddenActions.includes("edit") && (
          <TaskEditActionMenuItem taskId={data.id} onClose={closeHandler} />
        )}

        {!completed && !hiddenActions.includes("complete") && (
          <TaskCompleteActionMenuItem taskId={data.id} onClose={closeHandler} />
        )}

        {!hiddenActions.includes("copy") && (
          <TaskCopyLinkActionMenuItem taskId={data.id} onClose={closeMenu} />
        )}

        {!hiddenActions.includes("delete") && (
          <TaskDeleteActionMenuItem task={data} onClose={closeHandler} />
        )}
      </Menu>
    </Stack>
  );
};
