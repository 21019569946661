import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { utilizationAlertsSelector } from "./utilizationAlertsSelector";
import { integrationsDataAsObjectSelector } from "../../../integrations/selectors/integrationsDataAsObjectSelector";
import { UtilizationAlertDataGridDataType } from "../../utils/types/types";

export const utilizationAlertsDataGridDataSelector = createDraftSafeSelector(
  [utilizationAlertsSelector, integrationsDataAsObjectSelector],
  (data, integrations): UtilizationAlertDataGridDataType[] | null => {
    if (!data) {
      return null;
    }

    return data.map((utilizationAlert) => {
      const usedIntegrations = (
        utilizationAlert?.notifications?.map(
          (notification) =>
            notification.integration_id &&
            integrations[notification.integration_id],
        ) ?? []
      ).filter((integration) => !!integration);

      return {
        ...utilizationAlert,
        integrations: usedIntegrations,
      };
    });
  },
);
