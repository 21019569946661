import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceDetailsCostsTabBillingGranularDataSelector } from "./resourceDetailsCostsTabBillingGranularDataSelector";

import { ResourceDetailsGridDataType } from "../../../utils/types/common";

export const resourceDetailsCostsTabBillingGridDataSelector =
  createDraftSafeSelector(
    [resourceDetailsCostsTabBillingGranularDataSelector],
    (data): undefined | ResourceDetailsGridDataType[] => {
      return data?.data?.map((item) => {
        const newItem = { ...item } as ResourceDetailsGridDataType;

        item.dates.forEach((dateItem) => {
          newItem[dateItem.date] = dateItem;
        });

        return newItem;
      });
    },
  );
