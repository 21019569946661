import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { GridApi } from "ag-grid-community";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { hierarchyViewIdSearchParamKey } from "../../../common/views-hierarchy-tabs/utils/constants/constants";
import { createTasksViewLoadingSelector } from "../../../../../store/task-management/selectors/views-hierarchy/loadings/createTasksViewLoadingSelector";
import { tasksCurrentViewSelector } from "../../../../../store/task-management/selectors/views-hierarchy/current-view/tasksCurrentViewSelector";
import { createTasksViewThunk } from "../../../../../store/task-management/thunks/views-hierarchy/actions/createTasksViewThunk";
import { setTaskManagementCurrentViewId } from "../../../../../store/task-management/taskManagementSlice";
import { taskManagementPathName } from "../../utils/constants/common";
import { ViewsHierarchySaveAsNewSnackBar } from "../../../common/views-hierarchy-tabs/components/ViewsHierarchySaveAsNewSnackBar";

interface TaskManagementViewsHierarchySaverSnackbarActionsProps {
  gridApi: GridApi | null;
  onClose(): void;
}

const TaskManagementViewsHierarchySaverSnackbarActions: FC<
  TaskManagementViewsHierarchySaverSnackbarActionsProps
> = ({ onClose, gridApi }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loading = useAppSelector(createTasksViewLoadingSelector);
  const currentView = useAppSelector(tasksCurrentViewSelector);

  const submitHandler = useCallback(
    async (viewName: string) => {
      const gridConfig = JSON.stringify(gridApi?.getState());

      const response = await dispatch(
        createTasksViewThunk({
          body: {
            name: viewName,
            config: { gridConfig },
          },
        }),
      ).unwrap();

      if (response) {
        navigate(
          `${taskManagementPathName}?${hierarchyViewIdSearchParamKey}=${response?.id}`,
        );
        dispatch(setTaskManagementCurrentViewId(response?.id));
      }

      return response;
    },
    [dispatch, navigate, gridApi],
  );

  return (
    <ViewsHierarchySaveAsNewSnackBar
      currentView={currentView}
      onClose={onClose}
      loading={loading}
      onSubmit={submitHandler}
    />
  );
};

export const getTasksViewsHierarchySaverSnackbarActions = (
  gridApi: GridApi | null,
  onClose: () => void,
) => {
  return (
    <TaskManagementViewsHierarchySaverSnackbarActions
      gridApi={gridApi}
      onClose={onClose}
    />
  );
};
