import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import { ResourceExplorerPossibleFilterV2 } from "../../../../../services/cloudchipr.api";
import { DropdownSelectOption } from "../../../../common/select/dropdown-select/utils/types/types";

interface FilterTreeContextType {
  withIncludeAllProviderAccountsOption?: boolean;
  possibleFilters: ResourceExplorerPossibleFilterV2[];
  renderFilterValueOption?: FC<DropdownSelectOption>;
}

const FilterTreeContext = createContext({} as FilterTreeContextType);

interface FilterTreeProviderProps extends FilterTreeContextType {
  children?: ReactNode;
}

export const FilterTreeProvider: FC<FilterTreeProviderProps> = ({
  children,
  possibleFilters,
  renderFilterValueOption,
  withIncludeAllProviderAccountsOption,
}) => {
  const contextValue = useMemo(() => {
    return {
      possibleFilters,
      renderFilterValueOption,
      withIncludeAllProviderAccountsOption,
    };
  }, [
    possibleFilters,
    withIncludeAllProviderAccountsOption,
    renderFilterValueOption,
  ]);

  return (
    <FilterTreeContext.Provider value={contextValue}>
      {children}
    </FilterTreeContext.Provider>
  );
};

export const useFilterTreeContext = () => {
  return useContext(FilterTreeContext);
};
