import { FC, useCallback, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useToggle } from "rooks";
import { CommentEditor } from "./comment-editor/CommentEditor";
import { CommentContent } from "./CommentContent";
import { CommentUser } from "./CommentUser";
import { useHover } from "../../../../../../../../utils/hooks/useHover.hook";
import { ActivityComponentProps } from "../../../../../../common/task-management/components/form/content/activities-section/utils/types/types";

interface CommentProps extends ActivityComponentProps {
  comment: string;
  taskId: string;
  commentId: string;
}

export const Comment: FC<CommentProps> = ({
  comment,
  createdBy,
  createdAt,
  taskId,
  commentId,
}) => {
  const { ref, hovered } = useHover();
  const [edit, toggleEdit] = useToggle(false);
  const [newComment, setNewComment] = useState(comment ?? "");

  const handleCancel = useCallback(() => {
    toggleEdit();
    setNewComment(comment);
  }, [toggleEdit, comment]);

  return (
    <Box component="span" ref={ref}>
      {edit ? (
        <CommentEditor
          taskId={taskId}
          commentId={commentId}
          comment={newComment}
          onSend={toggleEdit}
          onCancel={handleCancel}
          onChange={setNewComment}
          slotBefore={
            <Stack p={1} pb={0}>
              <CommentUser user={createdBy} />
            </Stack>
          }
        />
      ) : (
        <CommentContent
          commentId={commentId}
          createdAt={createdAt}
          createdBy={createdBy}
          comment={newComment}
          hovered={hovered}
          taskId={taskId}
          onEditClick={toggleEdit}
        />
      )}
    </Box>
  );
};
