import { FC } from "react";
import { JiraIntegrationGrid } from "./components/grid/JiraIntegrationGrid";
import { CommunicationIntegrationsPageWrapper } from "../common/components/CommunicationIntegrationsPageWrapper";

export const JiraIntegration: FC = () => {
  return (
    <CommunicationIntegrationsPageWrapper type="jira" breadcrumbs={breadcrumbs}>
      <JiraIntegrationGrid />
    </CommunicationIntegrationsPageWrapper>
  );
};

const breadcrumbs = [
  { title: "Integrations", to: "/integrations" },
  { title: "Jira" },
];
