import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { PrevPeriodCostColumnHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/PrevPeriodCostColumnHeaderCell";
import { billingExplorerWidgetSetupPrevTotalCostSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupPrevTotalCostSelector";

export const BillingExplorerWidgetSetupTablePrevTotalCostHeaderCell: FC =
  () => {
    const previousPeriodTotal = useAppSelector(
      billingExplorerWidgetSetupPrevTotalCostSelector,
    );

    return (
      <PrevPeriodCostColumnHeaderCell
        sum={previousPeriodTotal?.cost ?? 0}
        dateDetails={previousPeriodTotal?.dateDetails}
      />
    );
  };
