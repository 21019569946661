import { FC } from "react";
import { Divider, Drawer, Stack } from "@mui/material";
import { KubernetesClusterAddDrawerHeader } from "./KubernetesClusterAddDrawerHeader";
import { KubernetesClusterAddDrawerContent } from "./content/KubernetesClusterAddDrawerContent";

interface KubernetesClusterAddDrawerProps {
  open: boolean;
  onClose(): void;
}

export const KubernetesClusterAddDrawer: FC<
  KubernetesClusterAddDrawerProps
> = ({ open, onClose }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      slotProps={slotProps}
      PaperProps={paperProps}
    >
      <Stack>
        <KubernetesClusterAddDrawerHeader onClose={onClose} />
        <Divider />
        <KubernetesClusterAddDrawerContent />
      </Stack>
    </Drawer>
  );
};

const paperProps = {
  sx: {
    width: 640,
  },
};

const slotProps = {
  backdrop: { invisible: true },
};
