import { resourceExplorerPathSelector } from "./resourceExplorerPathSelector";
import { RootState } from "../../../../store";
import { resourceExplorerGroupingSelector } from "../data/resourceExplorerGroupingSelector";
import { ResourceExplorerFilterProvider } from "../../../../../services/cloudchipr.api";
import { resourceExplorerGroupingNextGroupingByProviderSelector } from "../../possible-groupings/resourceExplorerGroupingNextGroupingByProviderSelector";

export const canRowDrillDownMoreSelector = (
  state: RootState,
  provider: ResourceExplorerFilterProvider,
) => {
  const path = resourceExplorerPathSelector(state);
  const groupedBy = resourceExplorerGroupingSelector(state);

  if (!groupedBy) {
    return false;
  }

  if (groupedBy === "cost_allocation_tag_value" && path.length === 1) {
    return false;
  }

  const nextGrouping = resourceExplorerGroupingNextGroupingByProviderSelector(
    state,
    groupedBy,
    provider,
  );

  const nextGroupingAlreadyExist = path?.some(
    (pathItem, index) =>
      pathItem?.groupedBy === nextGrouping && index !== path.length - 1,
  );

  return !nextGroupingAlreadyExist;
};
