import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { useDidMount, useEffectOnceWhen } from "rooks";
import { GridApi } from "ag-grid-community";
import { ViewsHierarchy } from "../../../common/views-hierarchy-tabs/ViewsHierarchy";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { savingsOpportunitiesViewsHierarchySelector } from "../../../../../store/savings-opportunities/selectors/views-hierarchy/savingsOpportunitiesViewsHierarchySelector";
import { getSavingsOpportunitiesViewsHierarchyThunk } from "../../../../../store/savings-opportunities/thunks/views-hierarchy/getSavingsOpportunitiesViewsHierarchyThunk";
import { createSavingsOpportunityViewLoadingSelector } from "../../../../../store/savings-opportunities/selectors/views-hierarchy/loadings/createSavingsOpportunityViewLoadingSelector";
import { setSavingsOpportunitiesCurrentViewId } from "../../../../../store/savings-opportunities/savingsOpportunitiesSlice";
import { hierarchyViewIdSearchParamKey } from "../../../common/views-hierarchy-tabs/utils/constants/constants";
import { deleteSavingsOpportunityViewLoadingSelector } from "../../../../../store/savings-opportunities/selectors/views-hierarchy/loadings/deleteSavingsOpportunityViewLoadingSelector";
import { updateSavingsOpportunityViewLoadingSelector } from "../../../../../store/savings-opportunities/selectors/views-hierarchy/loadings/updateSavingsOpportunityViewLoadingSelector";
import { savingsOpportunitiesViewsHierarchyLoadingSelector } from "../../../../../store/savings-opportunities/selectors/views-hierarchy/loadings/savingsOpportunitiesViewsHierarchyLoadingSelector";
import { useAppAbility } from "../../../../../services/permissions";
import { useSavingsOpportunitiesViewActions } from "../../utils/hooks/useSavingsOpportunitiesViewActions";
import { savingsOpportunitiesPathName } from "../../utils/constants/common";
import { savingsOpportunitiesCurrentViewIdSelector } from "../../../../../store/savings-opportunities/selectors/views-hierarchy/current-view/savingsOpportunitiesCurrentViewIdSelector";
import { getOrganisationCurrentFilterTemplatesThunk } from "../../../../../store/live-usage-mgmt/thunks/getOrganisationCurrentFilterTemplatesThunk";

interface SavingsOpportunitiesViewsHierarchyProps {
  gridApi: GridApi | null;
}

export const SavingsOpportunitiesViewsHierarchy: FC<
  SavingsOpportunitiesViewsHierarchyProps
> = ({ gridApi }) => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const viewIdFromPath = searchParams.get(hierarchyViewIdSearchParamKey);

  const { cannot } = useAppAbility();
  const canNotEditRecommendation = cannot("edit", "recommendation");

  const views = useAppSelector(savingsOpportunitiesViewsHierarchySelector);
  const currentViewId = useAppSelector(
    savingsOpportunitiesCurrentViewIdSelector,
  );

  const createLoading = useAppSelector(
    createSavingsOpportunityViewLoadingSelector,
  );
  const deleteLoading = useAppSelector(
    deleteSavingsOpportunityViewLoadingSelector,
  );
  const updateLoading = useAppSelector(
    updateSavingsOpportunityViewLoadingSelector,
  );
  const getLoading = useAppSelector(
    savingsOpportunitiesViewsHierarchyLoadingSelector,
  );

  const {
    onTabChange,
    onViewCreate,
    onViewRename,
    onViewDelete,
    onViewDuplicate,
    onViewProtect,
  } = useSavingsOpportunitiesViewActions(gridApi);

  useDidMount(() => {
    dispatch(getSavingsOpportunitiesViewsHierarchyThunk());
    dispatch(getOrganisationCurrentFilterTemplatesThunk());
  });

  useEffectOnceWhen(() => {
    dispatch(setSavingsOpportunitiesCurrentViewId(viewIdFromPath ?? ""));
  }, !!viewIdFromPath);

  useEffectOnceWhen(() => {
    dispatch(setSavingsOpportunitiesCurrentViewId(""));
  }, !viewIdFromPath && !!currentViewId);

  if (!views) {
    return null;
  }

  return (
    <ViewsHierarchy
      views={views}
      onTabChange={onTabChange}
      allItemsLabel="All Opportunities"
      pathname={savingsOpportunitiesPathName}
      actions={{
        viewCreate: {
          disabled: canNotEditRecommendation || getLoading,
          onSubmit: onViewCreate,
          loading: createLoading,
        },
        viewDelete: { onDelete: onViewDelete, loading: deleteLoading },
        viewDuplicate: {
          onDuplicate: onViewDuplicate,
          loading: createLoading,
        },
        viewRename: { onRename: onViewRename, loading: updateLoading },
        viewProtect: { onProtect: onViewProtect, loading: updateLoading },
      }}
    />
  );
};
