import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import { useAppSelector } from "../../../../../../store/hooks";
import { currentDashboardFolderSelector } from "../../../../../../store/dashboards/selectors/dashboard-hierarchy/currentDashboardFolderSelector";
import { currentDashboardNameSelector } from "../../../../../../store/dashboards/selectors/dashboard/currentDashboardNameSelector";
import { DashboardV2ItemActionButton } from "../../../../../app-navigation/components/page-components/dashboard/components/item-actions-menu/DashboardV2ItemActionButton";
import { currentDashboardIdSelector } from "../../../../../../store/dashboards/selectors/dashboard/currentDashboardIdSelector";
import { HierarchicalBreadcrumbs } from "../../../../common/breadcrumbs/hierarchical-breadcrumbs/HierarchicalBreadcrumbs";
import { HierarchyDashboardSelectionWrapper } from "../../../../common/hierarchy-dashboard-selection/HierarchyDashboardSelectionWrapper";
import { BreadcrumbNavigationTrigger } from "../../../../resource-explorer/components/app-bar/components/breadcrumb-navigation/BreadcrumbNavigationTrigger";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { Breadcrumbs } from "../../../../common/breadcrumbs/Breadcrumbs";
import { currentDashboardSelector } from "../../../../../../store/dashboards/selectors/dashboard/currentDashboardSelector";

interface DashboardBreadcrumbsProps {
  loading: boolean;
}

export const DashboardAppBarNavigation: FC<DashboardBreadcrumbsProps> = ({
  loading,
}) => {
  const navigate = useNavigate();

  const name = useAppSelector(currentDashboardNameSelector);
  const currentFolder = useAppSelector(currentDashboardFolderSelector);
  const dashboardId = useAppSelector(currentDashboardIdSelector);

  const { visibility, protection_details, protected_by } =
    useAppSelector(currentDashboardSelector) ?? {};

  const changeNavigationHandler = useCallback(
    (id: string) => {
      navigate(`/dashboards/${id}`);
    },
    [navigate],
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Breadcrumbs breadcrumbs={allItemsBreadcrumbs} />
      <Typography variant="body1" color="text.secondary">
        /
      </Typography>

      <HierarchicalBreadcrumbs
        folderName={currentFolder?.name}
        loading={!name || loading}
      >
        <HierarchyDashboardSelectionWrapper
          selectedItemId={dashboardId ?? ""}
          CustomTriggerComponent={DashboardBreadcrumbNavigationTrigger}
          onChange={changeNavigationHandler}
        />
      </HierarchicalBreadcrumbs>
      <DashboardV2ItemActionButton
        id={dashboardId ?? ""}
        name={name ?? ""}
        folderId={currentFolder?.id}
        visibility={visibility ?? "visible_to_everyone"}
        protectionDetails={protection_details}
        protectedBy={protected_by}
      />
    </Stack>
  );
};

const allItemsBreadcrumbs = [
  {
    title: "Dashboards",
    icon: <DashboardCustomizeOutlinedIcon fontSize="small" />,
    to: "/dashboards",
  },
];

const DashboardBreadcrumbNavigationTrigger = (
  props: FilterTriggerComponentProps,
) => {
  return <BreadcrumbNavigationTrigger {...props} icon={GridViewOutlinedIcon} />;
};
