import { Row } from "@tanstack/react-table";
import { ColumnSetupType } from "../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { KubernetesNode } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { ResourceMetricsHeaderCell } from "../../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/metrics-cells/components/ResourceMetricsHeaderCell";
const cellStyles = (row: Row<any>) => {
  return {
    bgcolor: row.original.state === "expires_soon" ? "#ed6c0216" : undefined,
  };
};

export const kubernetesNodesColumn: ColumnSetupType<KubernetesNode>[] = [
  {
    accessorKey: "name",
    header: "Node Name",
    size: 280,
    type: "identifier",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "total_monthly_cost",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "idle_monthly_cost",
    header: "Idle Monthly Price",
    type: "money",
  },
  {
    accessorKey: "cluster",
    header: "Cluster",
    type: "cluster",
  },
  {
    id: "cpu_percent",
    size: 270,
    minSize: 260,
    maxSize: 350,
    meta: {
      cellStyles: { p: 0 },
      headerTitle: "CPU Utilization Max",
      customHeaderTooltip:
        "Displays the maximum CPU usage of all scheduled pods on this node over the selected time period, relative to their total requested CPU resources.",
    },
    accessorKey: "inline_metrics",
    type: "computeMetricsChart",
    header: ResourceMetricsHeaderCell,
  },
  {
    id: "memory_used_percent",
    size: 300,
    minSize: 290,
    maxSize: 350,
    meta: {
      cellStyles: { p: 0 },
      headerTitle: "Memory Utilization Max",
      customHeaderTooltip:
        "Displays the maximum Memory usage of all scheduled pods on this node over the selected time period, relative to their total requested Memory resources.",
    },
    accessorKey: "inline_metrics",
    type: "memoryUsedMetricsChart",
    header: ResourceMetricsHeaderCell,
  },
  {
    size: 150,
    minSize: 130,
    accessorKey: "instance_type",
    header: "Instance Type",
    meta: { cellStyles },
    type: "withCopy",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  {
    accessorKey: "labels",
    header: "Kubernetes Label",
    type: "tag",
    enableSorting: false,
  },
];
