import { FC, useCallback, useMemo } from "react";
import { Box, Stack, Typography } from "@mui/material";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import {
  AssigneeSelectionUserAvatar,
  assigneeSelectionUserAvatarStyles,
} from "./AssigneeSelectionUserAvatar";
import { FilterTriggerComponentProps } from "../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { User } from "../../../../../../../../services/cloudchipr.api";
import { useHover } from "../../../../../../../../utils/hooks/useHover.hook";
import { TaskPropertyTriggerWrapper } from "../TaskPropertyTriggerWrapper";

const visibleUsersCount = 3;

interface AssigneeSelectionTrigger extends FilterTriggerComponentProps {
  inCell?: boolean;
}

export const AssigneeSelectionTrigger: FC<AssigneeSelectionTrigger> = ({
  onClick,
  selectedValues,
  options,
  onCloseAndSubmit,
  disabled,
  inCell,
}) => {
  const { ref, hovered } = useHover();

  const selectedUsersByIndex = useMemo(() => {
    return (
      options?.reduce(
        (acc, option, index) => {
          if (selectedValues?.includes(option.value)) {
            acc[index] = option.rawValue;
          }

          return acc;
        },
        {} as Record<string, User>,
      ) ?? {}
    );
  }, [options, selectedValues]);

  const usersArray = Object.keys(selectedUsersByIndex);
  const length = usersArray.length;

  const removeSingleUserHandler = useCallback(
    (userId: string) => {
      onCloseAndSubmit(selectedValues.filter((value) => value !== userId));
    },
    [onCloseAndSubmit, selectedValues],
  );

  return (
    <TaskPropertyTriggerWrapper
      inCell={inCell}
      refToPass={ref}
      hovered={hovered}
      onClick={onClick}
      disabled={disabled}
    >
      {length ? (
        <Box position="relative" height={22}>
          {usersArray.slice(0, visibleUsersCount).map((userIndex, index) => {
            const user = selectedUsersByIndex[userIndex];

            return (
              <AssigneeSelectionUserAvatar
                index={index}
                key={user.id}
                user={user}
                onRemove={removeSingleUserHandler}
                visibleUsersCount={visibleUsersCount}
              />
            );
          })}

          {length > visibleUsersCount && (
            <Stack
              bgcolor="grey.400"
              sx={{
                ...assigneeSelectionUserAvatarStyles,
                left: visibleUsersCount * 20,
              }}
            >
              <Typography variant="caption">
                +{length - visibleUsersCount}
              </Typography>
            </Stack>
          )}
        </Box>
      ) : (
        <Box
          p={0.5}
          lineHeight={0}
          borderRadius="50%"
          border="1px dashed"
          borderColor="grey.400"
          sx={{ cursor: "pointer" }}
        >
          <PersonAddAltOutlinedIcon sx={{ fontSize: 13 }} color="action" />
        </Box>
      )}
    </TaskPropertyTriggerWrapper>
  );
};
