import { FC, Fragment, useCallback } from "react";
import { Button, Container, LinearProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import AddIcon from "@mui/icons-material/Add";
import { useToggle } from "rooks";
import { UsersList } from "./components/UsersList";
import { UserInviteDialog } from "./components/UserInviteDialog";
import { EmptyUsersList } from "./components/EmptyUsersList";
import { UsersRequestList } from "./components/users-request/UsersRequestList";
import { getInviteButtonTooltipText } from "./utils/helpers/getInviteButtonTooltipText";
import { PageHeader } from "../../../common/PageHeader";
import {
  useGetUsersMeOrganisationsCurrentQuery,
  useGetV2UsersMeOrganisationsCurrentUsersQuery,
} from "../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../services/permissions";

export const Users: FC = () => {
  const {
    data,
    refetch,
    isLoading: loading,
  } = useGetV2UsersMeOrganisationsCurrentUsersQuery({});

  const { cannot } = useAppAbility();
  const cannotCreateUser = cannot("create", "user");

  const { data: org } = useGetUsersMeOrganisationsCurrentQuery();

  const [dialogOpen, toggleDialogOpen] = useToggle();
  const { enqueueSnackbar } = useSnackbar();

  const snackbarOpenHandler = useCallback(
    (type: "success" | "error", message: string) => {
      enqueueSnackbar(message, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: type,
        },
      });
    },
    [enqueueSnackbar],
  );

  const dialogCloseSuccessHandler = useCallback(() => {
    toggleDialogOpen();
    refetch();
    snackbarOpenHandler("success", "Invitation has been sent successfully!");
  }, [refetch, snackbarOpenHandler, toggleDialogOpen]);

  const length = data?.length ? `(${data.length})` : "";
  const inviteButtonTooltipText = getInviteButtonTooltipText({
    cannotCreateUser,
    hasSsoSetup: org?.has_sso_setup,
  });

  return (
    <Fragment>
      <PageHeader
        title={`Users ${length}`}
        actions={
          <Tooltip title={inviteButtonTooltipText}>
            <span>
              <Button
                disabled={loading || cannotCreateUser || org?.has_sso_setup}
                variant="contained"
                onClick={toggleDialogOpen}
                startIcon={<AddIcon />}
              >
                Invite user
              </Button>
            </span>
          </Tooltip>
        }
      />

      {loading && <LinearProgress />}

      <Container sx={{ p: 2 }} maxWidth={false} disableGutters>
        {!data?.length && !loading ? (
          <EmptyUsersList onActionClick={toggleDialogOpen} />
        ) : (
          <Fragment>
            {!cannotCreateUser && <UsersRequestList />}
            <UsersList />
          </Fragment>
        )}
      </Container>

      <UserInviteDialog
        open={dialogOpen}
        onClose={toggleDialogOpen}
        onSuccess={dialogCloseSuccessHandler}
      />
    </Fragment>
  );
};
