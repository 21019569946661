import { createAsyncThunk } from "@reduxjs/toolkit";
import { getKubernetesLiveUsageMgmtAllResourceTypesDataThunk } from "./resources/getKubernetesLiveUsageMgmtAllResourceTypesDataThunk";
import { getKubernetesLiveUsageMgmtResourcesWithOutFiltersThunk } from "./resources/getKubernetesLiveUsageMgmtResourcesWithOutFiltersThunk";
import {
  setKubernetesLiveUsageMgmtClusterIds,
  setKubernetesLiveUsageMgmtIsPageLoading,
} from "../kubernetesLiveUsageMgmtSlice";

export const kubernetesLiveUsageMgmtAccountsChangeThunk = createAsyncThunk(
  "liveUsageMgmt/kubernetesLiveUsageMgmtAccountsChange",
  async (clusterIds: string[], { dispatch }) => {
    dispatch(setKubernetesLiveUsageMgmtIsPageLoading(true));
    dispatch(setKubernetesLiveUsageMgmtClusterIds(clusterIds ?? []));

    dispatch(setKubernetesLiveUsageMgmtIsPageLoading(false));
    dispatch(getKubernetesLiveUsageMgmtAllResourceTypesDataThunk());
    dispatch(getKubernetesLiveUsageMgmtResourcesWithOutFiltersThunk());
  },
);
