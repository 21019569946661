import { FC, ReactNode, useCallback } from "react";
import { FormControl, InputLabel, Select, Stack } from "@mui/material";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { VerifiedUserOutlined } from "@mui/icons-material";
import { FilterTriggerComponentProps } from "../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

export const BudgetsAndAlertsHierarchySelectTrigger: FC<
  FilterTriggerComponentProps
> = ({ onClick, open, label, isProtected }) => {
  const renderValue = useCallback(
    (value: ReactNode) => {
      if (value) {
        return (
          <Stack direction="row" alignItems="center">
            <InsertChartOutlinedOutlinedIcon fontSize="small" sx={{ pr: 1 }} />
            {isProtected && (
              <VerifiedUserOutlined sx={{ fontSize: 16, pr: 1 }} />
            )}
            {value}
          </Stack>
        );
      }
    },
    [isProtected],
  );

  return (
    <FormControl size="small" fullWidth>
      <InputLabel>Select Billing Report</InputLabel>

      <Select
        onClick={onClick}
        size="small"
        open={false}
        label="Select Billing Report"
        value={label}
        IconComponent={open ? ArrowDropUpIcon : ArrowDropDownIcon}
        renderValue={renderValue}
      />
    </FormControl>
  );
};
