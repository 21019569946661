import { kubernetesLiveUsageMgmtSliceSelector } from "./kubernetesLiveUsageMgmtSliceSelector";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const kubernetesLiveUsageMgmtSelectedGroupedRowsByResourceTypeSelector =
  (
    state: RootState,
    resourceType?: ResourceType,
  ): Record<string, Record<string, boolean>> | undefined => {
    if (!resourceType) {
      return;
    }
    return kubernetesLiveUsageMgmtSliceSelector(state).selected[resourceType];
  };
