import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { setAlertsData } from "../../alertsSlice";
import { alertsCostAnomalyDataGroupingSelector } from "../../selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataGroupingSelector";
import { resourceExplorerPossibleGroupingKeysSelector } from "../../../resource-explorer/selectors/possible-groupings/resourceExplorerPossibleGroupingKeysSelector";

export const changeCostAnomalyAlertGroupingByViewIdThunk = createAsyncThunk(
  "alert/changeCostAnomalyAlertGroupingByViewId",
  (viewId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const currentGroupings = alertsCostAnomalyDataGroupingSelector(state);
    const allGroupings = resourceExplorerPossibleGroupingKeysSelector(state);

    dispatch(
      setAlertsData({
        grouping:
          currentGroupings && allGroupings?.includes(currentGroupings)
            ? currentGroupings
            : "service",
      }),
    );
  },
);
