import { FC, useCallback, useMemo } from "react";
import Stack from "@mui/material/Stack";
import { Chart } from "highcharts";
import { Cube } from "../../../../charts/multi-type-chart/components/common/Cube";
import { TypographyWithTooltip } from "../../../../../components/common/TypographyWithTooltip";
import { chartSeriesHighlightFunctions } from "../../utils/helpers/chartSeriesHighlightFunctions";

interface LegendItemProps {
  chartApi: Chart;
  legend: string;
  enabled: boolean;
}

export const LegendItem: FC<LegendItemProps> = ({
  chartApi,
  legend,
  enabled,
}) => {
  const onMouseEnter = useCallback(() => {
    chartSeriesHighlightFunctions.onHoverIn(chartApi, legend);
  }, [chartApi, legend]);

  const onMouseLeave = useCallback(() => {
    chartSeriesHighlightFunctions.onHoverOut(chartApi);
  }, [chartApi]);

  const toggleSeries = useCallback(() => {
    chartApi.series.forEach((series) => {
      if (series.name === legend) {
        const isVisible = series.visible;
        series.setVisible(!isVisible);

        const label = document.querySelector(
          `[data-series='${legend}']`,
        ) as HTMLElement;

        if (label) {
          label.style.opacity = isVisible ? "0.5" : "1";
        }
      }
    });
  }, [chartApi, legend]);

  const color = useMemo(() => {
    return chartApi?.series?.find((series) => {
      return series.name === legend;
    })?.color;
  }, [chartApi, legend]);

  return (
    <Stack
      px={0.5}
      direction="row"
      alignItems="center"
      onClick={toggleSeries}
      sx={{ cursor: "pointer" }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Cube
        width={10}
        height={10}
        color={enabled ? `${color}` : "transparent"}
        border={enabled ? undefined : `1px solid ${color}`}
      />

      <TypographyWithTooltip
        title={legend}
        maxWidth={150}
        variant="caption"
        color="text.secondary"
        sx={enabled ? undefined : { textDecoration: "line-through" }}
      />
    </Stack>
  );
};
