import { FC, ReactNode, useCallback, useState } from "react";
import { Stack } from "@mui/material";
import { GridApi } from "ag-grid-community";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";

interface CommunicationIntegrationsDataGridToolbarProps {
  gridApi: GridApi | null;
  children?: ReactNode;
}

export const CommunicationIntegrationsDataGridToolbar: FC<
  CommunicationIntegrationsDataGridToolbarProps
> = ({ gridApi, children }) => {
  const [searchValue, setSearchValue] = useState("");

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridApi?.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [gridApi],
  );

  return (
    <Stack
      pb={1.5}
      direction="row"
      alignItems="center"
      justifyContent="end"
      spacing={1}
    >
      <GlobalFilter
        globalFilter={searchValue}
        setGlobalFilter={searchChangeHandler}
        size="xsmall"
      />

      {children}
    </Stack>
  );
};
