import { kubernetesLiveUsageMgmtResourceTypeDataResourcesSelector } from "./kubernetesLiveUsageMgmtResourceTypeDataResourcesSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { KubernetesResourceDataType } from "../../utils/types/types";

export const kubernetesLiveUsageMgmtResourceTypeDataResourceByIdSelector = (
  state: RootState,
  resourceType: ResourceType,
  resourceId: string,
): KubernetesResourceDataType | null => {
  const resources = kubernetesLiveUsageMgmtResourceTypeDataResourcesSelector(
    state,
    resourceType,
  );

  return (
    resources?.find((resource) => resource?.resource?.id === resourceId) ?? null
  );
};
