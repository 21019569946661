import { FC, Fragment, ReactNode } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useMatch } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { authTokenSelector } from "../../../store/auth/authSelectors";
import { disableByRestrictionViolationSelector } from "../../../store/organizations/organizationsSelectors";
import { isSubscriptionCancelledSelector } from "../../../store/subscriptions/subscriptionsSelectors";

export const ProtectedRoute: FC<{ children?: ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const matchBillingRoute = useMatch("/settings/billing");
  const authToken = useAppSelector(authTokenSelector);
  const isSubscriptionPlanDisabled = useAppSelector(
    isSubscriptionCancelledSelector,
  );
  const disableByRestriction = useAppSelector(
    disableByRestrictionViolationSelector,
  );

  if (isAuthenticated && !authToken) {
    return null;
  }

  if (!isAuthenticated || !authToken) {
    return <Navigate to="/" />;
  }

  if (
    (isSubscriptionPlanDisabled || disableByRestriction) &&
    matchBillingRoute === null
  ) {
    return <Navigate to="/settings/billing" state={{ disabled: true }} />;
  }

  return <Fragment>{children}</Fragment>;
};
