import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { getCoveredUsageColumnDef } from "./common/getCoveredUsageColumnDef";
import { coveredHoursColumnDef } from "./common/coveredHoursColumnDef";
import { getCoverageColumnDef } from "./common/getCoverageColumnDef";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import { ReservationsCoverageDataWithId } from "../../../../../../../common/utils/types";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const reservationCoverageGroupedByCommitmentColumnDefs: ColDef<ReservationsCoverageDataWithId>[] =
  [
    {
      flex: 1.5,
      field: "reservation.provider_id",
      minWidth: 200,
      headerName: "Reservation ID",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
        <TypographyWithTooltip
          variant="body2"
          title={data?.reservation?.provider_id ?? ""}
        />
      ),
    },

    getCoveredUsageColumnDef("commitment"),

    coveredHoursColumnDef,

    getCoverageColumnDef("commitment"),

    agGridEmptyColumnToFitEmptySpace,
  ];
