import { FC, useCallback, useState } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAppSelector } from "../../../../../../../store/hooks";
import { TaskGridRowActionsMenu } from "../../../../../task-management/components/grid/cell-renderers/row-actions/TaskGridRowActionsMenu";
import { tasksListDataByIdSelector } from "../../../../../../../store/task-management/selectors/data/tasksListDataByIdSelector";
import { taskManagementTaskIdSelector } from "../../../../../../../store/task-management/selectors/form/properties/taskManagementTaskIdSelector";

interface TaskCreationDrawerHeaderEditActionsProps {
  onClose(): void;
}

export const TaskCreationDrawerHeaderEditActions: FC<
  TaskCreationDrawerHeaderEditActionsProps
> = ({ onClose }) => {
  const [copied, setCopied] = useState(false);

  const editingTaskId = useAppSelector(taskManagementTaskIdSelector);
  const task = useAppSelector((state) =>
    tasksListDataByIdSelector(state, editingTaskId),
  );

  const copyHandler = useCallback(() => {
    setCopied(true);

    setTimeout(() => setCopied(false), 3000);
  }, []);

  const url = `${window.location.origin}/task-mgmt?taskId=${editingTaskId}`;

  if (!editingTaskId) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Tooltip arrow title={copied ? "Copied!" : "Copy Link"}>
        <span>
          <CopyToClipboard onCopy={copyHandler} text={url}>
            <IconButton size="small" disabled={copied}>
              <LinkIcon color="action" />
            </IconButton>
          </CopyToClipboard>
        </span>
      </Tooltip>

      <TaskGridRowActionsMenu
        data={task}
        onClose={onClose}
        hiddenActions={["edit"]}
      />
    </Stack>
  );
};
