import { getFlatGroupingOptions } from "./flat-grouping-options/getFlatGroupingOptions";
import { getResourceExplorerGroupingOptionWithParentLabel } from "./grouped-by-parent/getResourceExplorerGroupingOptionWithParentLabel";
import { getDimensionGroupingOptions } from "./dimension-grouping-options/getDimensionGroupingOptions";
import { resourceExplorerHasNoParentKey } from "../../utils/constants/constants";
import { DropdownSelectOption } from "../../../../../common/select/dropdown-select/utils/types/types";
import {
  GroupingChangeHandler,
  ResourceExplorerGroupedPossibleGroupings,
} from "../../utils/types/types";
import {
  PossibleFilterValues,
  ResourceExplorerGroupingNullable,
} from "../../../../../../services/cloudchipr.api";

export const getResourceExplorerGroupingOptions = (
  value: ResourceExplorerGroupingNullable,
  onChange: GroupingChangeHandler,
  onClose: () => void,
  orderedParentLabels: string[],
  groupedPossibleGroupings: ResourceExplorerGroupedPossibleGroupings,
  dimensionsGroupingValues?: PossibleFilterValues,
  groupValues?: string[],
  hiddenGroupings?: ResourceExplorerGroupingNullable[],
) => {
  return orderedParentLabels.reduce((result, parentLabel) => {
    const flatOption = parentLabel === resourceExplorerHasNoParentKey;
    if (flatOption) {
      const flatOptions = getFlatGroupingOptions(
        groupedPossibleGroupings,
        parentLabel,
        value,
        onChange,
        onClose,
        groupValues,
        hiddenGroupings,
      );

      result.push(...flatOptions);
    }

    const dimensionOption =
      groupedPossibleGroupings?.[parentLabel]?.at(0)?.key === "category" &&
      dimensionsGroupingValues?.length;

    if (dimensionOption) {
      result.push(getDimensionGroupingOptions(dimensionsGroupingValues));
    }

    if (!flatOption && !dimensionOption) {
      result.push(
        getResourceExplorerGroupingOptionWithParentLabel(
          groupedPossibleGroupings,
          parentLabel,
        ),
      );
    }

    return result;
  }, [] as DropdownSelectOption[]);
};
