import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import {
  CoverageWidgetHeaderInfo,
  CoverageWidgetHeaderInfoProps,
} from "../../../../../../pages/dashboard/components/widgets/coverage/components/CoverageWidgetHeaderInfo";

interface InternalDashboardCoverageWidgetHeaderProps
  extends CoverageWidgetHeaderInfoProps {
  widgetName?: string;
}

export const InternalDashboardCoverageWidgetHeader: FC<
  InternalDashboardCoverageWidgetHeaderProps
> = ({ widgetName, ...props }) => {
  return (
    <Stack
      pl={2}
      pr={0}
      py={1}
      borderBottom={1}
      position="sticky"
      top={0}
      bgcolor="white"
      zIndex={2}
      spacing={0.5}
      borderColor="grey.300"
    >
      <Typography variant="subtitle1" fontWeight="medium">
        {widgetName}
      </Typography>
      <CoverageWidgetHeaderInfo {...props} />
    </Stack>
  );
};
