import { RootState } from "../../../store";
import { resourceExplorerStateSelector } from "../resourceExplorerStateSelector";
import { BillingExplorerSideBarPanelContentType } from "../../utils/types/resourceExplorer";

export const currentBillingExplorerSideBarPanelContentTypeSelector = (
  state: RootState,
): BillingExplorerSideBarPanelContentType | null => {
  const slice = resourceExplorerStateSelector(state);

  return slice.sideBarPanelContentType;
};
