import { costAnomaliesWidgetDataResponseSelector } from "./costAnomaliesWidgetDataResponseSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridTotal } from "../../../../../../services/cloudchipr.api";

export const costAnomaliesWidgetDataTotalSelector = (
  state: RootState,
  widgetId: string,
): ResourceExplorerGridTotal[] | undefined => {
  const response = costAnomaliesWidgetDataResponseSelector(state, widgetId);

  return response?.data?.total;
};
