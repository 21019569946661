import { FC } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import EmptyStateImage from "./utils/assets/images/empty_budgets.svg";
import { CreateBudgetOrAlertButton } from "../../CreateBudgetOrAlertButton";
import { useAppAbility } from "../../../../../../services/permissions";
import { getUserPermissionInfoText } from "../../../../utils/helpers/getUserPermissionInfoText";

export const BudgetsEmptyState: FC = () => {
  const { cannot } = useAppAbility();
  const cannotCreateBudget = cannot("create", "budget");

  const userPermissionTooltipText = cannotCreateBudget
    ? getUserPermissionInfoText("Create Budgets")
    : "";

  return (
    <Stack justifyContent="center" alignItems="center" mt={12}>
      <img src={EmptyStateImage} alt="EmptyStateImage" />
      <Typography
        component="h4"
        variant="h5"
        textAlign="center"
        fontWeight="bold"
        mt={5}
      >
        You do not have any budgets yet.
      </Typography>

      <Typography
        variant="h6"
        color="text.secondary"
        textAlign="center"
        fontWeight="normal"
        mt={2}
      >
        Monitor cloud spend by creating budgets and enabling alerts for exceeded
        limits.
      </Typography>

      <Tooltip title={userPermissionTooltipText}>
        <span>
          <CreateBudgetOrAlertButton
            disabled={cannotCreateBudget}
            variant="outlined"
            sx={{ mt: 4 }}
          >
            Create Budget
          </CreateBudgetOrAlertButton>
        </span>
      </Tooltip>
    </Stack>
  );
};
