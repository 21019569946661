import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import {
  cloudChiprApi,
  PostUsersMeOrganisationsCurrentOpportunityViewsApiArg,
  View,
} from "../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { createSavingsOpportunityViewFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";
import { getSavingsOpportunitiesViewsHierarchyThunk } from "../getSavingsOpportunitiesViewsHierarchyThunk";

interface CreateSavingsOpportunityViewThunkArgs {
  body: PostUsersMeOrganisationsCurrentOpportunityViewsApiArg["body"];
  disableSnackbar?: boolean;
}

export const createSavingsOpportunityViewThunk = createAsyncThunk(
  "savingsOpportunities/createSavingsOpportunityView",
  async (
    { body, disableSnackbar }: CreateSavingsOpportunityViewThunkArgs,
    { dispatch },
  ): Promise<View | undefined> => {
    const { postUsersMeOrganisationsCurrentOpportunityViews } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentOpportunityViews.initiate(
          { body },
          { fixedCacheKey: createSavingsOpportunityViewFixedCacheKey },
        ),
      ).unwrap();

      if (!disableSnackbar) {
        const snackbarId: SnackbarKey = enqueueSnackbar(
          "View successfully created.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }

      dispatch(getSavingsOpportunitiesViewsHierarchyThunk());
      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
      return;
    }
  },
);
