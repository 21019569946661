import { isCommitmentAccount } from "./isCommitmentAccount";
import { CoverageGroupResponse } from "../../../../../../../../services/cloudchipr.api";

export const getCoverageRowDataFromChartData = (
  identifier?: string,
  groups?: CoverageGroupResponse[],
) => {
  return groups?.find((group) => {
    if (isCommitmentAccount(group.name)) {
      return group.name?.name === identifier || group.name?.id === identifier;
    }

    return group.name === identifier;
  });
};
