import { IntegrationType } from "../../../../../../../../store/integrations/integrationsSlice";

export const integrationDetails: Record<
  IntegrationType,
  Record<string, string>
> = {
  slack: {
    label: "Slack",
    description:
      "Connect Slack to receive alerts and updates in your preferred channels, keeping your team informed and responsive.",
    noDataDescription: "No Slack integration connected",
    connectionType: "workspace",
  },
  email: {
    label: "Email",
    description:
      "Set up email groups for your teams and get notified of important alerts right in your inbox.",
    noDataDescription: "No Email integration has been set up yet",
    connectionType: "integration",
  },
  webhook: {
    label: "Webhook",
    description:
      "Automate notifications by sending real-time web hooks to external systems, enabling custom workflows and integrations.",
    noDataDescription: "No Webhook integration connected",
    connectionType: "integration",
  },
  jira: {
    label: "Jira",
    description:
      "Create, assign, and track tickets with a single click, automatically including all resource metadata.",
    noDataDescription: "No Jira integration connected",
    connectionType: "workspace",
  },
};
