import { billingExplorerSetupPropertyByKeySelector } from "./billingExplorerSetupPropertyByKeySelector";
import { RootState } from "../../../../store";
import { billingExplorerWidgetEditChangesExistSelector } from "../../widgets/billing-explorer-widget/setup/billingExplorerWidgetEditChangesExistSelector";

export const billingExplorerSubmitButtonDisabledSelector = (
  state: RootState,
): boolean => {
  const editDisabledByChange =
    billingExplorerWidgetEditChangesExistSelector(state);
  const viewId = billingExplorerSetupPropertyByKeySelector("viewId")(state);
  const name = billingExplorerSetupPropertyByKeySelector("name")(state);
  const id = billingExplorerSetupPropertyByKeySelector("id")(state);

  return (id && !editDisabledByChange) || !name || !viewId;
};
