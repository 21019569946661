import { FC, Fragment, MouseEvent, useCallback } from "react";
import { Button, Fade, Theme } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { SxProps } from "@mui/system";
import { WidgetCardHeaderEditPopover } from "./WidgetCardHeaderEditPopover";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { useMenuHook } from "../../../../../../../../utils/hooks/useMenu.hook";
import { editWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/common/editWidgetThunk";
import { WidgetType } from "../../../../../../../../services/cloudchipr.api";
import { currentDashboardProtectedSelector } from "../../../../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/currentDashboardProtectedSelector";

interface WidgetCardHeaderEditButtonProps {
  hovered: boolean;
  widgetId: string;
  widgetType: WidgetType;
}

export const WidgetCardHeaderEditButton: FC<
  WidgetCardHeaderEditButtonProps
> = ({ hovered, widgetType, widgetId }) => {
  const dispatch = useAppDispatch();
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const isProtected = useAppSelector(currentDashboardProtectedSelector);

  const clickHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      openMenu(event);
      dispatch(
        editWidgetThunk({
          widgetId,
          widgetType,
          doNotSetWidgetType: true,
        }),
      );
    },
    [openMenu, widgetId, widgetType, dispatch],
  );

  if (isProtected) {
    return null;
  }

  return (
    <Fragment>
      <Fade in={hovered || open}>
        <Button
          size="xsmall"
          color="tertiary"
          variant="contained"
          onClick={clickHandler}
          sx={widgetEditButtonStyles}
        >
          <EditOutlined fontSize="small" sx={{ color: "grey.600" }} />
        </Button>
      </Fade>

      {open && (
        <WidgetCardHeaderEditPopover
          open
          anchor={anchor}
          closeMenu={closeMenu}
          widgetType={widgetType}
        />
      )}
    </Fragment>
  );
};

const widgetEditButtonStyles: SxProps<Theme> = {
  p: 0,
  minWidth: 24,
  minHeight: 24,
  bgcolor: "grey.200",
  boxShadow: "none",
  "&:hover": {
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
  },
};
