import { FC, useCallback } from "react";
import { InternalDashboardCommitmentUtilizationWidgetWrapper } from "./widgets/commitments-utilization/InternalDashboardCommitmentUtilizationWidgetWrapper";
import { InternalDashboardTextWidgetWrapper } from "./widgets/text/InternalDashboardTextWidgetWrapper";
import { InternalDashboardCoverageWidgetWrapper } from "./widgets/coverage/InternalDashboardCoverageWidgetWrapper";
import {
  GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse,
  WidgetType,
} from "../../../../services/cloudchipr.api";
import { UnauthorizedDashboardBillingExplorerWidgetWrapper } from "../../common/components/billing-explorer-widget/UnauthorizedDashboardBillingExplorerWidgetWrapper";
import { UnauthorizedCostAnomaliesWidgetWrapper } from "../../common/components/cost-anomalies/UnauthorizedCostAnomaliesWidgetWrapper";
import { UnauthorizedDashboardBillingSummaryWidgetWrapper } from "../../common/components/billing-summary/UnauthorizedDashboardBillingSummaryWidgetWrapper";
import { RegisterRequestState } from "../utils/hooks/useDashboardStateGlobalVar.hook";

interface InternalWidgetComponentByWidgetTypeProps {
  widget: GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse["widgets"][0];
  organisationId: string;
  registerRequestState: RegisterRequestState;
}

export const InternalWidgetComponentByWidgetType: FC<
  InternalWidgetComponentByWidgetTypeProps
> = ({ widget, registerRequestState, organisationId }) => {
  const Component = widgetsComponents[widget.type];
  const onWidgetMount = useCallback(() => {
    registerRequestState(widget.type, widget.id, true);
  }, [widget.type, widget.id, registerRequestState]);

  if (!Component) {
    return null;
  }

  return (
    <Component
      {...widget}
      organisationId={organisationId}
      onWidgetMount={onWidgetMount}
    />
  );
};

const widgetsComponents: Partial<Record<WidgetType, FC<any>>> = {
  cost_breakdown: UnauthorizedDashboardBillingExplorerWidgetWrapper,
  largest_cost_changes: UnauthorizedCostAnomaliesWidgetWrapper,
  cost_and_usage_summary: UnauthorizedDashboardBillingSummaryWidgetWrapper,
  commitments_utilization: InternalDashboardCommitmentUtilizationWidgetWrapper,
  coverage: InternalDashboardCoverageWidgetWrapper,
  text: InternalDashboardTextWidgetWrapper,
};
