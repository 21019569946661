import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAppSelector } from "../../store/hooks";
import { awsOrganizationsSelector } from "../../store/commitments/selectors/organisations/awsOrganizationsSelector";

import { noOrgAccountOrgId } from "../pages/accounts-group/utils/helpers/noOrganizationConstants";

interface AwsOrganizationSelectProps {
  selectedOrgId: string;
  onChange(orgId: string): void;
}

export const AwsOrganizationSelect: FC<AwsOrganizationSelectProps> = ({
  selectedOrgId,
  onChange,
}) => {
  const options = useAppSelector(awsOrganizationsSelector);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const orgId = event.target.value;

      const option = options?.find((org) => org.organization_id === orgId);

      if (!option?.has_savings_plan) {
        return;
      }

      if (orgId !== selectedOrgId) {
        onChange(orgId);
      }
    },
    [selectedOrgId, options, onChange],
  );

  return (
    <FormControl fullWidth size="small" disabled={!options}>
      <InputLabel>Select Organization</InputLabel>

      <Select
        fullWidth
        size="small"
        label="Select Organization"
        onChange={handleChange}
        value={selectedOrgId}
      >
        {options?.map((org) => {
          const orgId = org.organization_id || noOrgAccountOrgId;
          const disabled = !org.has_savings_plan;

          return (
            <MenuItem
              key={orgId}
              value={orgId}
              disableRipple={disabled}
              sx={{
                opacity: disabled ? 0.5 : 1,
                cursor: disabled ? "default" : "pointer",
                "&:hover": disabled ? { bgcolor: "white" } : undefined,
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                {disabled && (
                  <Tooltip
                    arrow
                    placement="top"
                    title="This organization has no active commitments."
                  >
                    <InfoOutlinedIcon fontSize="small" color="action" />
                  </Tooltip>
                )}

                <ListItemText
                  primary={org.organization_id || org.name || "Other Accounts"}
                  primaryTypographyProps={{ variant: "body1" }}
                />
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
