import { FC } from "react";
import {
  CardHeader,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LiveFilteredResourceCardHeaderPriceChip } from "./LiveFilteredResourceCardHeaderPriceChip";
import { ResourceType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { getProviderTypeByResourceType } from "../../../../../../../../../../../../../utils/helpers/providers/getProviderTypeByResourceType";
import { ProviderIcon } from "../../../../../../../../../../../../common/ProviderIcon";
import { getResourceTypeName } from "../../../../../../../../../../../../../utils/helpers/resources/getResourceTypeName";

interface LiveFilteredResourceCardHeaderProps {
  resourceType: ResourceType;
  dataCount: number;
  totalMonthlyPrice: number | null;
  collapsed: boolean;
  onCollapse(): void;
  loading?: boolean;
}
export const LiveFilteredResourceCardHeader: FC<
  LiveFilteredResourceCardHeaderProps
> = ({
  resourceType,
  dataCount,
  totalMonthlyPrice,
  collapsed,
  onCollapse,
  loading,
}) => {
  const showPriceChip = collapsed && !loading;

  return (
    <CardHeader
      title={
        <Stack direction="row" alignItems="center" spacing={1}>
          <ProviderIcon
            provider={getProviderTypeByResourceType(resourceType)}
          />
          <Stack spacing={0.25} direction="row" alignItems="center">
            <Typography variant="body2" fontWeight="medium" color="primary">
              {getResourceTypeName(resourceType, {
                type: "long",
              })}{" "}
            </Typography>
            <Typography variant="body2" color="grey.500">
              {loading ? (
                <Skeleton variant="text" height={24} width={24} />
              ) : (
                `(${dataCount})`
              )}
            </Typography>
          </Stack>
        </Stack>
      }
      avatar={
        <IconButton
          onClick={onCollapse}
          disabled={loading}
          color="primary"
          size="small"
          sx={{
            transform: collapsed ? "rotate(-90deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      }
      sx={cardHeaderSx}
      action={
        showPriceChip && (
          <LiveFilteredResourceCardHeaderPriceChip price={totalMonthlyPrice} />
        )
      }
    />
  );
};

const cardHeaderSx = {
  p: 1,
  pr: 2,
  bgcolor: "primary.light",
  ".MuiCardHeader-action": {
    minWidth: 34,
    alignSelf: "center",
  },
  ".MuiCardHeader-avatar": { m: 0, mr: 0.5 },
};
