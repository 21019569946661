import { FC } from "react";
import { Chip } from "@mui/material";
import { green } from "@mui/material/colors";

interface ExistCellProps {
  data: boolean;
}

export const ExistCell: FC<ExistCellProps> = ({ data }) => {
  if (data) {
    return (
      <Chip
        label="Available"
        size="small"
        variant="outlined"
        sx={{
          color: "success.light",
          borderColor: green[200],
        }}
      />
    );
  }

  return (
    <Chip label="Deleted" size="small" variant="outlined" color="default" />
  );
};
