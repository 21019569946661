import { createAsyncThunk } from "@reduxjs/toolkit";
import { DateDataPointNullable } from "../../../../../../services/cloudchipr.api";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { billingExplorerWidgetSetupSelector } from "../../../../selectors/setups/billing-explorer/billingExplorerWidgetSetupSelector";
import { getBillingExplorerWidgetSetupDataThunk } from "../getBillingExplorerWidgetSetupDataThunk";

export const billingExplorerFrequencyChangeThunk = createAsyncThunk(
  "dashboards/billingExplorerFrequencyChange",
  async (frequency: DateDataPointNullable, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = billingExplorerWidgetSetupSelector(state);

    if (!existingSetup || !frequency) {
      return;
    }
    dispatch(
      setWidgetSetup({
        ...existingSetup,
        frequency,
      }),
    );

    dispatch(getBillingExplorerWidgetSetupDataThunk());
  },
);
