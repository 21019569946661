import { GracePeriod } from "../../../../services/cloudchipr.api";
import { formatNotificationsFromHtmlToSlack } from "../../../../components/utils/helpers/slack-formatters/formatNotificationsFromHtmlToSlack";

export const editGracePeriodForPayload = (gracePeriod?: GracePeriod) => {
  const updatedGracePeriod = formatNotificationsFromHtmlToSlack(
    gracePeriod?.notifications,
  );

  return gracePeriod
    ? {
        ...gracePeriod,
        notifications: updatedGracePeriod,
      }
    : undefined;
};
