import { FC, useMemo } from "react";
import { ColDef } from "ag-grid-community";
import { Box, LinearProgress, Stack } from "@mui/material";
import { GetRowIdFunc } from "ag-grid-community/dist/types/src/entities/gridOptions";
import { getResourceDetailsCostsTableMainFieldInnerHeader } from "./getResourceDetailsCostsTableMainFieldInnerHeader";
import { getResourceDetailsCostsTableDateColumnsInnerHeader } from "./getResourceDetailsCostsTableDateColumnsInnerHeader";
import {
  getResourceDetailsCostsTableCostCellRenderer,
  ResourceDetailsCostsTableTotalCostCellRenderer,
} from "./ResourceDetailsCostsTableCostCellRenderer";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { AgDataGrid } from "../../../../../ag-grid/AgDataGrid";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";
import { ResourceExplorerGroupingNullable } from "../../../../../../../../services/cloudchipr.api";
import { resourceDetailsResourceBillingGranularDataLoadingSelector } from "../../../../../../../../store/resource-details/selectors/billing-data/loadings/resourceDetailsResourceBillingGranularDataLoadingSelector";
import { ResourceDetailsGridDataType } from "../../../../../../../../store/resource-details/utils/types/common";

interface ResourceDetailsCostsTableProps {
  gridData?: ResourceDetailsGridDataType[] | null;
  columns?: string[] | null;
  groupBy: ResourceExplorerGroupingNullable;
}

export const ResourceDetailsCostsTable: FC<ResourceDetailsCostsTableProps> = ({
  gridData,
  columns,
  groupBy,
}) => {
  const loading = useAppSelector(
    resourceDetailsResourceBillingGranularDataLoadingSelector,
  );

  const columnDefs = useMemo(() => {
    const columnDefs: ColDef[] = [
      {
        resizable: true,
        width: 180,
        maxWidth: 200,
        pinned: "left",
        headerComponentParams: {
          innerHeaderComponent:
            getResourceDetailsCostsTableMainFieldInnerHeader(groupBy),
        },
      },
      {
        pinned: "left",
        field: "total_cost",
        headerName: "Total Cost",
        headerComponentParams: {
          innerHeaderComponent:
            getResourceDetailsCostsTableDateColumnsInnerHeader(true),
        },
        cellRenderer: ResourceDetailsCostsTableTotalCostCellRenderer,
      },
    ];

    columns?.forEach((column) => {
      const date = formatDate(column, { type: "date" });

      if (!date) {
        return;
      }

      columnDefs.push({
        field: `${column}.total_cost`,
        headerName: date,
        headerComponentParams: {
          innerHeaderComponent:
            getResourceDetailsCostsTableDateColumnsInnerHeader(false),
        },
        cellRenderer: getResourceDetailsCostsTableCostCellRenderer(column),
      });
    });

    return columnDefs;
  }, [columns, groupBy]);

  return (
    <Box minHeight={160} key={`${groupBy} ${columns?.length}`}>
      {loading && <LinearProgress />}

      <AgDataGrid
        wrapperStyles={wrapperStyles}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={gridData ?? []}
        getRowId={getRowId}
        rowHeight={50}
        domLayout={loading ? undefined : "autoHeight"}
        suppressMovableColumns
        noRowsOverlayComponent={noRowsOverlayComponent}
      />
    </Box>
  );
};

const defaultColDef = {
  flex: 1,
  minWidth: 135,
  sortable: true,
  resizable: false,
  suppressHeaderMenuButton: true,
};

const wrapperStyles = {
  minHeight: 200,
  ".ag-header-cell-text": { width: "100%" },
  ".ag-root-wrapper": { overflow: "visible !important" },
  ".ag-root": { overflow: "visible !important" },
};

const getRowId: GetRowIdFunc = ({ data }) => data?.field;
const noRowsOverlayComponent = () => <Stack pt={7}>No Data To Show</Stack>;
