import { resourceDetailsResourceFirstProcessKeySelector } from "./resourceDetailsResourceFirstProcessKeySelector";
import { resourceDetailsResourceBillingDataSelector } from "../resourceDetailsResourceBillingDataSelector";
import { RootState } from "../../../../store";

export const resourceDetailsResourceBillingDataForecastedCostsSelector = (
  state: RootState,
) => {
  const firstProcessKey = resourceDetailsResourceFirstProcessKeySelector(state);

  return resourceDetailsResourceBillingDataSelector(state, firstProcessKey)
    ?.forecasted_costs;
};
