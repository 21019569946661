import { kubernetesLiveUsageMgmtAppliedFiltersSelector } from "./store-selectors/kubernetesLiveUsageMgmtAppliedFiltersSelector";
import { ResourceType } from "../../../services/cloudchipr.api";
import { RootState } from "../../store";

export const kubernetesLiveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector =
  (state: RootState): Map<ResourceType, number> => {
    const appliedFilters = kubernetesLiveUsageMgmtAppliedFiltersSelector(state);

    const resourceTypes = Object.keys(appliedFilters) as ResourceType[];

    return resourceTypes.reduce(
      (result, resourceType) => {
        result.set(
          resourceType,
          appliedFilters?.[resourceType]?.filter_items?.length ?? 0,
        );
        return result;
      },
      new Map() as Map<ResourceType, number>,
    );
  };
