import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { getAzureSavingsPlanCommonColumnDefs } from "./getAzureSavingsPlanCommonColumnDefs";
import { AzureSavingsPlanCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import { azureNetSavingsColumnDef } from "../coverage/common/azureNetSavingsColumnDef";
import { azureTotalCoverageColumnDef } from "../coverage/common/azureTotalCoverageColumnDef";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const azureSavingsPlanGroupedByResourceTypeColumnDefs: ColDef<AzureSavingsPlanCoverageDataWithId>[] =
  [
    {
      field: "resource_type.resource_type",
      headerName: "Resource Type",
      suppressKeyboardEvent: () => true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      width: 230,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => (
        <TypographyWithTooltip
          title={data?.resource_type?.resource_type ?? ""}
          variant="body2"
          align="left"
        />
      ),
    },
    {
      field: "resource_type.product_name",
      headerName: "Product Name",
      width: 240,
      flex: 240,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => {
        return data?.resource_type?.product_name ?? "-";
      },
    },
    {
      field: "resource_type.region",
      headerName: "Region",
      width: 230,
      flex: 230,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => {
        return data?.resource_type?.region ?? null;
      },
    },
    azureNetSavingsColumnDef,
    ...getAzureSavingsPlanCommonColumnDefs("resource_type"),
    azureTotalCoverageColumnDef,
    agGridEmptyColumnToFitEmptySpace,
  ];
