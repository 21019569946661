import { ColDef, type ICellRendererParams } from "ag-grid-community";
import { Stack, Typography } from "@mui/material";
import {
  ResourceTarget,
  ResourceType,
} from "../../../../../../../../../../../../services/cloudchipr.api";
import { resourceIdentifierLabels } from "../../../../../../../../../../../../utils/constants/resources/resourceTypeNames/resourceIdentifierLabels";
import { TaskTargetTableIdentifierRow } from "../../common/TaskTargetTableIdentifierRow";
import { money } from "../../../../../../../../../../../../utils/numeral/money";
import { TargetResourceTableStateRow } from "../TargetResourceTableStateRow";
import { TagCell } from "../../../../../../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { getResourceTypeName } from "../../../../../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { CreatedByCell } from "../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/created-by-cell/CreatedByCell";
import { AccountInfoData } from "../../../../../../../../../../../common/AccountInfoData";
import { TaskTargetTableRegionRow } from "../../common/TaskTargetTableRegionRow";
import { taskTargetAccountFilterText } from "../../../../../../../../../../task-management/utils/helpers/quick-filter-text-functions/taskTargetAccountFilterText";
import { targetAccountComparatorFn } from "../../../../../../../../../../task-management/utils/helpers/comparator-functions/targetAccountComparatorFn";
import { ProviderIcon } from "../../../../../../../../../../../common/ProviderIcon";
import { getProviderName } from "../../../../../../../../../../../../utils/helpers/providers/getProviderName";
import { createdByComparatorFn } from "../../../../../../../../utils/helpers/comparator-fns/createdByComparatorFn";
import { cloudProviderComparatorFn } from "../../../../../../../../utils/helpers/comparator-fns/cloudProviderComparatorFn";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../../ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const getTargetMultipleResourcesGridColumns = (
  resourceType: ResourceType,
  edit?: boolean,
) => {
  const columns: ColDef[] = [
    {
      field: "provider_identifier",
      unSortIcon: true,
      minWidth: 300,
      headerName: resourceIdentifierLabels.get(resourceType) ?? "Instance ID",
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        if (!data) {
          return null;
        }

        return (
          <TaskTargetTableIdentifierRow
            cloudLink
            target={data}
            copyButton="hover"
            liveUsageLink="hover"
          />
        );
      },
    },
    {
      field: "price_per_month",
      unSortIcon: true,
      minWidth: 100,
      headerName: "Monthly Price",
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        if (!data) {
          return null;
        }

        return money(data.price_per_month);
      },
    },
    {
      field: "creation_data",
      unSortIcon: true,
      headerName: "Created By",
      comparator: createdByComparatorFn,
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        if (!data) {
          return null;
        }

        return <CreatedByCell data={data.creation_data} />;
      },
    },
  ];

  if (edit) {
    columns.push({
      field: "state",
      unSortIcon: true,
      headerName: "State",
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        if (!data) {
          return null;
        }

        return <TargetResourceTableStateRow state={data.state} />;
      },
    });
  }

  columns.push(
    {
      field: "region",
      unSortIcon: true,
      headerName: "Region",
      minWidth: 100,
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => (
        <TaskTargetTableRegionRow region={data?.region} />
      ),
    },
    {
      field: "resource_type",
      unSortIcon: true,
      headerName: "Resource Type",
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        if (!data) {
          return null;
        }

        return getResourceTypeName(data.resource_type, {
          singular: true,
          type: "long",
        });
      },
    },

    {
      colId: "provider",
      headerName: "Cloud Provider",
      getQuickFilterText: taskTargetAccountFilterText,
      comparator: cloudProviderComparatorFn,
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        const account = data?.account;

        if (!account) {
          return null;
        }

        return (
          <Stack direction="row" spacing={0.5}>
            <ProviderIcon provider={account.provider} />
            <Typography variant="body2">
              {getProviderName(account.provider, {
                title: true,
              })}
            </Typography>
          </Stack>
        );
      },
    },
    {
      colId: "account",
      headerName: "Account",
      getQuickFilterText: taskTargetAccountFilterText,
      comparator: targetAccountComparatorFn,
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        const account = data?.account;

        if (!account) {
          return null;
        }

        return (
          <AccountInfoData
            nameIdCopiable
            nameTypographyProps={{ variant: "body2" }}
            idTypographyProps={{ variant: "tiny" }}
            accountName={account.provider_account_name}
            accountId={account.provider_account_id}
          />
        );
      },
    },

    {
      field: "tags",
      unSortIcon: true,
      headerName: "Tags",
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        if (!data) {
          return null;
        }

        return data.tags?.length ? <TagCell tags={data.tags} /> : "-";
      },
    },
    agGridEmptyColumnToFitEmptySpace,
  );

  return columns;
};
