import { FC } from "react";
import {
  Link,
  Stack,
  Typography,
  Collapse,
  Card,
  CardHeader,
  CardContent,
  Button,
  Box,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useToggle } from "rooks";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { AwsMissingPermissionsApplyButton } from "./AwsMissingPermissionsApplyButton";
import {
  MessagesDialog,
  MessagesDialogProps,
} from "../../common/MessagesDialog";
import AwsPermissionsVideo from "../../../../../../../../../assets/feature-videos/aws-permissions.mp4";
import { MissingPermissionsRecheck } from "../../common/MissingPermissionsRecheck";
import { useGetUsersMeProvidersAwsAccountsByAccountIdMissingPermissionsQuery } from "../../../../../../../../../services/cloudchipr.api";
import { generateLiveResourcesPath } from "../../../../../../../accounts-group/utils/helpers/generateLiveResourcesPath";

export interface AwsMissingPermissionsProps extends MessagesDialogProps {
  accountId: string;
  isSubAccount?: boolean;
  parentId?: string;
}
export const AwsMissingPermissions: FC<AwsMissingPermissionsProps> = ({
  onClose,
  open,
  accountId,
  isSubAccount,
  parentId,
}) => {
  const [collapsed, toggleCollapsed] = useToggle();

  const { data, refetch } =
    useGetUsersMeProvidersAwsAccountsByAccountIdMissingPermissionsQuery(
      { accountId: accountId || "" },
      { skip: !accountId },
    );

  return (
    <MessagesDialog
      name="Permissions"
      width="md"
      open={open}
      onClose={onClose}
      actions={
        <Button onClick={onClose} color="tertiary">
          Close
        </Button>
      }
    >
      <Stack gap={2}>
        <Box>
          <Typography fontWeight="medium" pb={1}>
            Cloudchipr now supports new AWS services. Execute the provided
            change set on your existing CloudFormation template in the AWS Web
            Console to add permissions.
          </Typography>
          <video autoPlay loop height={370}>
            <track
              label="Aws missing permissions"
              kind="captions"
              src="../../../../../../../../../assets/feature-videos/captions/aws-permissions.vtt"
            />
            <source src={AwsPermissionsVideo} type="video/mp4" />
          </video>
        </Box>

        <Card>
          <CardHeader
            onClick={toggleCollapsed}
            sx={{
              p: 3,
              px: 2,
              cursor: "pointer",
              bgcolor: "grey.100",
              "& .MuiCardHeader-action": {
                alignSelf: "center",
              },
            }}
            title={
              <Typography variant="body1" fontWeight="medium">
                Missing permissions
              </Typography>
            }
            action={
              collapsed ? (
                <KeyboardArrowUpOutlinedIcon />
              ) : (
                <KeyboardArrowDownOutlinedIcon />
              )
            }
          />

          <Collapse in={collapsed}>
            <CardContent
              sx={{
                p: 0,
                maxHeight: 100,
                overflow: "auto",
                bgcolor: "grey.100",
              }}
            >
              <ul style={{ margin: 0 }}>
                {data?.map((p) => <li key={p.name}>{p.name}</li>)}
              </ul>
            </CardContent>
          </Collapse>
        </Card>

        {isSubAccount ? (
          parentId && (
            <Link
              component={RouterLink}
              to={generateLiveResourcesPath({
                provider: "aws",
                activeTab: "live-resources",
                accountIds: parentId,
              })}
            >
              Enable it from your Management Account
            </Link>
          )
        ) : (
          <AwsMissingPermissionsApplyButton accountId={accountId} />
        )}

        <MissingPermissionsRecheck refetch={refetch} accountId={accountId} />
      </Stack>
    </MessagesDialog>
  );
};
