import { AskAiConversationItem } from "../../../utils/types";

export const generateConversationItemByAskAIResponse = (
  data: any,
): AskAiConversationItem => {
  return {
    type: "answer",
    text: data.answer,
    charts: data?.charts,
    csv_paths: data?.csv_paths,
    tables: data?.tables,
  };
};
