import { FC, useEffect } from "react";
import { useFormik } from "formik";
import { Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { CommunicationIntegrationForm } from "./form/CommunicationIntegrationForm";
import { CommunicationIntegrationFormActions } from "./form/CommunicationIntegrationFormActions";
import { HelperGuideByType } from "./HelperGuideByType";
import {
  Integration,
  useGetUsersMeIntegrationsQuery,
  usePutUsersMeByTypeIntegrationsAndIntegrationIdMutation,
  usePostUsersMeByTypeIntegrationsMutation,
} from "../../../../../../../../services/cloudchipr.api";
import {
  generateCreatePayloadData,
  generateUpdatePayloadData,
} from "../../../../../../../../utils/helpers/integrations";
import { enqueueSnackbarErrorAlert } from "../../../../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { integrationsValidationSchema } from "../../utils/validation";
import { IntegrationType } from "../../../../../../../../store/integrations/integrationsSlice";
import { getAllIntegrationsThunk } from "../../../../../../../../store/integrations/thunks/getAllIntegrationsThunk";
import { useAppDispatch } from "../../../../../../../../store/hooks";

const initialValues: Integration = {
  id: "",
  user_id: "",
  organisation_id: "",

  name: "",
  type: "" as IntegrationType,
  emails: null,
  token: null,
  url: null,
  content_type: null,
  api_token: null,
  jira_email: null,
  default_issue_type_id: null,
  default_project_key: null,
  metadata: null,
  conversation: null,
  conversations: null,
  created_at: null,
};

interface IntegrationCreationProps {
  integration?: Integration;
  type?: IntegrationType;
  onNewIntegrationCreate?(type: IntegrationType, integrationId: string): void;
  onCancel(): void;
  onSubmit(): void;
}

export const CommunicationIntegrationCreation: FC<IntegrationCreationProps> = ({
  integration,
  type,
  onCancel,
  onNewIntegrationCreate,
  onSubmit,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const [createIntegration] = usePostUsersMeByTypeIntegrationsMutation({});
  const [updateIntegration] =
    usePutUsersMeByTypeIntegrationsAndIntegrationIdMutation({});
  const { refetch } = useGetUsersMeIntegrationsQuery({ type });

  const formik = useFormik({
    initialValues,
    validationSchema: integrationsValidationSchema,
    onSubmit: async (values) => {
      try {
        if (values.id) {
          await updateIntegration(generateUpdatePayloadData(values)).unwrap();

          enqueueSnackbar("Integration successfully updated", {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
            },
          });

          onNewIntegrationCreate?.(values.type, values.id);
        } else {
          const { id } = await createIntegration(
            generateCreatePayloadData(values),
          ).unwrap();

          onNewIntegrationCreate?.(values.type, id);
          enqueueSnackbar("Integration successfully created", {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
            },
          });
        }

        await refetch();
        onSubmit();
        dispatch(getAllIntegrationsThunk());
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  });

  const {
    isValid,
    values,
    errors,
    handleChange,
    touched,
    submitForm,
    setFieldValue,
    isSubmitting,
    setValues,
  } = formik;

  useEffect(() => {
    if (integration && type) {
      setValues({ ...integration, type });
    } else if (type) {
      setFieldValue("type", type);
    }
  }, [integration, type, setValues, setFieldValue]);

  return (
    <Stack p={2} spacing={2}>
      <CommunicationIntegrationForm
        id={values.id}
        workspaceId={values.workspace_id}
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />

      <CommunicationIntegrationFormActions
        edit={!!values.id}
        disabled={!values.type || !isValid}
        type={values.type}
        loading={isSubmitting}
        onSubmit={submitForm}
        onCancel={onCancel}
      />

      <HelperGuideByType type={values.type} />
    </Stack>
  );
};
