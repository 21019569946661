import { FC } from "react";
import { Box, Collapse, Stack } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { AskAiConversationItem } from "../../../../../../../store/ask-ai/utils/types";
import { PoorHighChart } from "../../../../../../../storybook/poor-high-chart/PoorHighChart";
import { PoorAgGrid } from "../../../../../../../storybook/poor-ag-grid/PoorAgGrid";

interface AskAICollapsableConversationItemBodyProps
  extends AskAiConversationItem {
  collapsed: boolean;
}

export const AskAICollapsableConversationItemBody: FC<
  AskAICollapsableConversationItemBodyProps
> = ({ collapsed, text, charts, tables }) => {
  return (
    <Collapse
      in={collapsed}
      sx={{
        pb: 1,
      }}
    >
      <Stack direction="row" spacing={1} pt={0.5}>
        <Box pt={1}>
          <Stack
            sx={{
              background: "linear-gradient(100deg, #1443EA, #7334F1)",
              borderColor: "linear-gradient(100deg, #1443EA, #7334F1)",
            }}
            height={32}
            width={32}
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
          >
            <AutoAwesomeIcon
              fontSize="small"
              sx={{
                color: "white",
              }}
            />
          </Stack>
        </Box>
        <Box dangerouslySetInnerHTML={{ __html: text ?? "" }} />
      </Stack>

      <Stack spacing={1}>
        {charts?.map((item, index) => {
          return <PoorHighChart key={index + item?.chart?.type} data={item} />;
        })}
        {tables?.map((item, index) => {
          if (!item) {
            return null;
          }
          return (
            <Box
              key={index + item?.columnDefs?.[0]?.field}
              height={`calc(${rowHeight * (item?.rowData?.length ?? 0)}px + ${headerHeight}px)`}
              maxHeight={320}
              minHeight={80}
            >
              <PoorAgGrid data={item} />
            </Box>
          );
        })}
      </Stack>
    </Collapse>
  );
};

const headerHeight = 49;
const rowHeight = 42;
