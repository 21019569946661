export const commitmentsGridDefaultColDef = {
  unSortIcon: true,
  minWidth: 150,
  filter: "agMultiColumnFilter",
};

export const commitmentsGridWrapperStyles = {
  border: 1,
  borderRadius: 2,
  "& .ag-root-wrapper": { borderRadius: 2 },
  borderColor: "grey.300",
};
