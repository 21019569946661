import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { capitalize } from "@mui/material";
import { coveredUsageColumnDef } from "./common/coveredUsageColumnDef";
import { uncoveredUsageColumnDef } from "./common/uncoveredUsageColumnDef";
import { netSavingsColumnDef } from "./common/netSavingsColumnDef";
import { getCoverageColumnDef } from "./common/getCoverageColumnDef";
import { commitmentsResourceTypeNames } from "../../../../../../../common/utils/constants/labels";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../common/utils/types";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const coverageGroupedByResourceTypeColumnDefs: ColDef<SavingsPlanCoverageDataWithId>[] =
  [
    {
      flex: 1.5,
      field: "resource_type.resource_type",
      headerName: "Resource Type",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => {
        const rt = data?.resource_type?.resource_type;

        if (!rt) {
          return;
        }

        return (
          <TypographyWithTooltip
            title={capitalize(commitmentsResourceTypeNames[rt] ?? "")}
            variant="body2"
          />
        );
      },
      valueGetter: ({ data }) =>
        data?.resource_type?.resource_type
          ? commitmentsResourceTypeNames[data.resource_type.resource_type]
          : "",
    },
    {
      flex: 1.5,
      field: "resource_type.instance_family",
      headerName: "Instance Family",
      valueGetter: ({ data }) => data?.resource_type?.instance_family ?? "",
    },
    {
      flex: 1.5,
      field: "resource_type.region",
      headerName: "Region",
    },
    coveredUsageColumnDef,
    uncoveredUsageColumnDef,
    netSavingsColumnDef,
    getCoverageColumnDef("resource_type"),
    agGridEmptyColumnToFitEmptySpace,
  ];
