import { PlotColumnDataLabelsOptions } from "highcharts";
import { billingExplorerChartValuesPercentFormater } from "./billingExplorerChartValuesPercentFormater";
import { ChartBaseType } from "../../../../../../../../../services/cloudchipr.api";
import { moneyFormatter } from "../../../../../../../../../utils/numeral/moneyFormatter";
import { HighchartsChartType } from "../../../../../../../../../storybook/c8r-highcharts/common/components/HighchartsChartTypeSelect";

const singleChartWidth = 5;

export const getBillingExplorerColumnDataLabels = (
  chartType: HighchartsChartType,
  chartBase?: ChartBaseType,
): PlotColumnDataLabelsOptions => {
  const additionalProps =
    chartType === "column" ? columnChartProps : stackedColumnChartProps;

  return {
    ...additionalProps,
    formatter: function () {
      if (this.shapeArgs?.height < 20) {
        return null;
      }

      const percent = billingExplorerChartValuesPercentFormater(this.y ?? 0);
      const money = `${moneyFormatter(this.y ?? 0)}`;
      const value = chartBase === "percent" ? percent : money;

      if ((this.shapeArgs?.width ?? 0) < value.length * singleChartWidth) {
        return null;
      }

      return `<span style="color: ${this.color};">${value}</span>`;
    },
  };
};

const stackedColumnChartProps = {
  padding: 2,
  enabled: true,
  borderRadius: 4,
  backgroundColor: "white",
  style: {
    color: "#000",
    fontSize: "10px",
    textOutline: "none",
    backgroundColor: "white",
  },
};

const columnChartProps = {
  y: -20,
  enabled: true,
  strokeWidth: 2,
  verticalAlign: "top",
  style: {
    strokeWidth: 2,
    fontSize: "10px",
    textOutline: "none",
  },
};
