import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { v4 as uuid } from "uuid";
import { savingsPlanDrawerRecommendationsGridColumnDefs } from "./savingsPlanDrawerRecommendationsGridColumnDefs";
import { useGetUsersMeOrganisationsCurrentSavingsPlansUtilizationRecommendationsQuery } from "../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { CommitmentsDrawerContent } from "../../../../../common/components/drawer/CommitmentsDrawerContent";
import { selectedSavingsPlanUUIdSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/savings-plan/selectedSavingsPlanUUIdSelector";
import { CommitmentsAgGrid } from "../../../../../common/components/CommitmentsAgGrid";

export const SavingsPlanDrawerRecommendationsSection: FC = () => {
  const savingsPlanId = useAppSelector(selectedSavingsPlanUUIdSelector);

  const { data } =
    useGetUsersMeOrganisationsCurrentSavingsPlansUtilizationRecommendationsQuery(
      { savingsPlanId: savingsPlanId ?? "" },
      {
        skip: !savingsPlanId,
        selectFromResult: ({ data }) => {
          return {
            data: data?.map((recommendation) => ({
              ...recommendation,
              id: uuid(),
            })),
          };
        },
      },
    );

  return (
    <CommitmentsDrawerContent title="Recommendations" icon="recommendation">
      {!data?.length ? (
        <Stack alignItems="center" p={3}>
          <Typography
            variant="subtitle1"
            fontWeight="medium"
            color="text.secondary"
          >
            No Recommendations
          </Typography>
        </Stack>
      ) : (
        <CommitmentsAgGrid
          columnDefs={savingsPlanDrawerRecommendationsGridColumnDefs}
          data={data}
          localStorageUniqueKey="SavingsPlanDrawerRecommendationsSection"
        />
      )}
    </CommitmentsDrawerContent>
  );
};
