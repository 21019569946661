import { FC } from "react";
import { ResourceDetailsCostsContentGrid } from "./ResourceDetailsCostsContentGrid";
import { useAppSelector } from "../../../../../../../store/hooks";
import { ResourceDetailsCostsChart } from "../common/ResourceDetailsCostsChart";
import { resourceDetailsChartTypeSelector } from "../../../../../../../store/resource-details/selectors/options/resourceDetailsChartTypeSelector";
import { resourceDetailsCostsTabBillingChartDataSelector } from "../../../../../../../store/resource-details/selectors/costs-tab/chart-data/resourceDetailsCostsTabBillingChartDataSelector";

interface ResourceDetailsCostsContentChartProps {
  processKey: string;
}

export const ResourceDetailsCostsContentChart: FC<
  ResourceDetailsCostsContentChartProps
> = ({ processKey }) => {
  const chartType = useAppSelector((state) =>
    resourceDetailsChartTypeSelector(state, processKey),
  );

  const chartData = useAppSelector((state) =>
    resourceDetailsCostsTabBillingChartDataSelector(state, processKey),
  );

  if (chartType === "table") {
    return <ResourceDetailsCostsContentGrid processKey={processKey} />;
  }

  return (
    <ResourceDetailsCostsChart chartType={chartType} chartData={chartData} />
  );
};
