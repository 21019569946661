import { FC, useMemo } from "react";
import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import { Navigate } from "react-router-dom";
import { PermissionsStep } from "./steps/PermissionsStep";
import { AccessToAccountStep } from "./steps/AccessToAccountStep";
import { ProvidersStep } from "./steps/ProvidersStep";
import { DataCollectionStep } from "./steps/DataCollectionStep";
import { AzureServicePrincipalStep } from "./steps/AzureServicePrincipalStep";
import { AzureAssignRolesStep } from "./steps/AzureAssignRolesStep";
import { AccountSetupUserInvitation } from "./AccountSetupUserInvitation";
import { useAppSelector } from "../../../store/hooks";
import { currentStepSelector } from "../../../store/account-setup/selectors/currentStepSelector";
import { providerSelector } from "../../../store/account-setup/selectors/providerSelector";
import { noProviderConnectedSelector } from "../../../store/accounts/selectors/noProviderConnectedSelector";

const defaultSteps = [
  {
    title: "Choose your cloud provider",
    component: <ProvidersStep />,
  },
  {
    title: "Choose Access",
    component: <PermissionsStep />,
  },
  {
    title: "Configure Permissions",
    component: <AccessToAccountStep />,
  },
  {
    title: "Collecting Information",
    component: <DataCollectionStep />,
  },
];

const azureSteps = [
  {
    title: "Choose your cloud provider",
    component: <ProvidersStep />,
  },
  {
    title: "Choose Access",
    component: <PermissionsStep />,
  },
  {
    title: "Service Principal",
    component: <AzureServicePrincipalStep />,
  },
  {
    title: "Assign Roles",
    component: <AzureAssignRolesStep />,
  },
  {
    title: "Collecting Information",
    component: <DataCollectionStep />,
  },
];

export const SetupSteps: FC = () => {
  const currentStep = useAppSelector(currentStepSelector);
  const currentProvider = useAppSelector(providerSelector);
  const noProviderConnected = useAppSelector(noProviderConnectedSelector);

  const steps = useMemo(() => {
    if (currentProvider === "azure") {
      return azureSteps;
    }

    return defaultSteps;
  }, [currentProvider]);

  if (!noProviderConnected && currentStep === 0) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box p={8}>
      <Stepper orientation="vertical" activeStep={currentStep}>
        {steps.map((step) => (
          <Step
            key={step.title}
            sx={{
              "& .Mui-completed": {
                fill: indigo["500"],
              },
            }}
          >
            <StepLabel>{step.title}</StepLabel>
            <StepContent>
              <Box sx={{ pl: 3, py: 2 }}>{step.component}</Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Divider sx={{ mt: 5 }}>
        <Typography variant="body1" fontWeight="medium" color="text.secondary">
          OR
        </Typography>
      </Divider>
      <AccountSetupUserInvitation />
    </Box>
  );
};
