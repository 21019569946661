import { FC } from "react";
import { AzureUtilizationAndCoverageDataViewTabs } from "./components/AzureUtilizationAndCoverageDataViewTabs";
import { azureSavingsPlansTableColumnDefs } from "./columns/azureSavingsPlansTableColumnDefs";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { azureSavingsPlansDataSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/azureSavingsPlansDataSelector";
import { azureSavingsPlansForTableLoadingSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/loading/azureSavingsPlansForTableLoadingSelector";
import { CommitmentsAGGridToolbar } from "../../../../../common/components/data-grid/CommitmentsAGGridToolbar";
import {
  CommitmentsAgGrid,
  CommitmentsToolbar,
} from "../../../../../common/components/CommitmentsAgGrid";

export const AzureSavingsPlansDataGrid: FC = () => {
  const data = useAppSelector(azureSavingsPlansDataSelector);
  const loading = useAppSelector(azureSavingsPlansForTableLoadingSelector);

  return (
    <CommitmentsAgGrid
      Toolbar={toolbar}
      loading={loading}
      columnDefs={azureSavingsPlansTableColumnDefs}
      data={data}
      grouping="commitment"
      localStorageUniqueKey="azureSavingsPlanDataGrid"
    />
  );
};

const toolbar: CommitmentsToolbar = ({ gridApi }) => (
  <CommitmentsAGGridToolbar gridApi={gridApi}>
    <AzureUtilizationAndCoverageDataViewTabs />
  </CommitmentsAGGridToolbar>
);
