import { FC, useState } from "react";
import { Box, Stack, LinearProgress } from "@mui/material";
import { useDidMount } from "rooks";
import { GridApi } from "ag-grid-community";
import { SavingsOpportunitiesDataGrid } from "./components/SavingsOpportunitiesDataGrid";
import { SavingsOpportunitiesTableToolbar } from "./components/toolbar/SavingsOpportunitiesTableToolbar";
import { SavingsOpportunitiesMetadata } from "./components/metadata/SavingsOpportunitiesMetadata";
import { SavingsOpportunitiesViewsHierarchy } from "./components/views/SavingsOpportunitiesViewsHierarchy";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { savingsOpportunitiesLoadingSelector } from "../../../store/savings-opportunities/selectors/savingsOpportunitiesLoadingSelector";
import { savingsOpportunitiesViewsHierarchyLoadingSelector } from "../../../store/savings-opportunities/selectors/views-hierarchy/loadings/savingsOpportunitiesViewsHierarchyLoadingSelector";
import { getSavingsOpportunitiesThunk } from "../../../store/savings-opportunities/thunks/getSavingsOpportunitiesThunk";
import { savingsOpportunitiesDataSelector } from "../../../store/savings-opportunities/selectors/savingsOpportunitiesDataSelector";

export const SavingsOpportunities: FC = () => {
  const dispatch = useAppDispatch();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const data = useAppSelector(savingsOpportunitiesDataSelector);

  const opportunitiesLoading = useAppSelector(
    savingsOpportunitiesLoadingSelector,
  );
  const viewsLoading = useAppSelector(
    savingsOpportunitiesViewsHierarchyLoadingSelector,
  );
  const loading = opportunitiesLoading || viewsLoading;

  useDidMount(() => {
    dispatch(getSavingsOpportunitiesThunk());
  });

  return (
    <Stack bgcolor="white" height="100%">
      <SavingsOpportunitiesViewsHierarchy gridApi={gridApi} />

      {loading && (
        <Box position="absolute" top={0} left={0} right={0}>
          <LinearProgress />
        </Box>
      )}

      <SavingsOpportunitiesMetadata />

      <SavingsOpportunitiesTableToolbar gridApi={gridApi} />
      <SavingsOpportunitiesDataGrid
        data={data}
        gridApi={gridApi}
        setGridApi={setGridApi}
      />
    </Stack>
  );
};
