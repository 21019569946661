import { RootState } from "../../store";
import { filterTemplatesSelector } from "../../filters/selectors/filter-set/filterTemplatesSelector";
import { ProviderType } from "../../../services/cloudchipr.api";

export const filterTemplateByIdAndProviderSelector = (
  state: RootState,
  filterSetId?: string,
  cloudProvider?: ProviderType,
) => {
  if (!filterSetId || !cloudProvider) {
    return;
  }

  const filterSetsList = filterTemplatesSelector(state, cloudProvider);

  return filterSetsList?.find((filter_set) => filter_set?.id === filterSetId);
};
