import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { capitalize, Stack, Typography } from "@mui/material";
import { ReservationRecommendation } from "../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import {
  commitmentsPaymentOptionLabels,
  commitmentsResourceTypeNames,
  savingsPlanLockBackPeriodLabels,
  savingsPlanTermLabels,
} from "../../../../../common/utils/constants/labels";
import { EMSCell } from "../../../../../common/components/data-grid/cells/EMSCell";
import { CommitmentsRecommendationsEstimatedMonthlySavingCell } from "../cells/CommitmentsRecommendationsEstimatedMonthlySavingCell";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const reservationsRecommendationsGridColumnsDefs: ColDef<ReservationRecommendation>[] =
  [
    {
      minWidth: 200,
      field: "reservation_type",
      headerName: "Type",
      suppressKeyboardEvent: () => true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationRecommendation>) => {
        const type = data
          ? commitmentsResourceTypeNames[data.reservation_type]
          : "";

        return (
          <TypographyWithTooltip
            title={type}
            variant="body2"
            textTransform="capitalize"
          />
        );
      },
      valueGetter: ({ data }) =>
        data ? commitmentsResourceTypeNames[data.reservation_type] : "",
    },
    {
      minWidth: 100,
      width: 200,
      field: "term_in_years",
      headerName: "Reservations Term",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationRecommendation>) =>
        data ? savingsPlanTermLabels[data.term_in_years] : "",
      valueGetter: ({ data }) =>
        data ? savingsPlanTermLabels[data.term_in_years] : "",
    },
    {
      minWidth: 100,
      width: 250,
      field: "account_scope",
      headerName: "Recommendation Level",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationRecommendation>) => (
        <Typography variant="body2" textTransform="capitalize">
          {data?.account_scope ?? ""}
        </Typography>
      ),
    },
    {
      minWidth: 150,
      width: 200,
      field: "account",
      headerName: "Account",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationRecommendation>) => {
        if (!data) {
          return;
        }

        return (
          <Stack>
            <TypographyWithTooltip
              variant="body2"
              title={data.account.provider_account_name}
            />
            <TypographyWithTooltip
              variant="body2"
              color="text.secondary"
              title={data.account.provider_account_id}
            />
          </Stack>
        );
      },
      valueGetter: ({ data }) =>
        data
          ? `${data.account.provider_account_name} ${data.account.provider_account_id}`
          : "",
    },
    {
      minWidth: 150,
      width: 200,
      field: "instance_type",
      headerName: "Instance Type",
    },
    {
      minWidth: 150,
      width: 200,
      field: "instance_count",
      headerName: "Instance Count",
    },
    {
      minWidth: 150,
      width: 160,
      field: "platform",
      headerName: "Platform",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationRecommendation>) => (
        <TypographyWithTooltip
          variant="inherit"
          title={data?.platform ?? "-"}
        />
      ),
    },
    {
      minWidth: 125,
      width: 150,
      field: "region",
      headerName: "Region",
    },
    {
      minWidth: 125,
      width: 190,
      field: "offering_class",
      headerName: "Offering Class",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationRecommendation>) =>
        capitalize(data?.offering_class ?? "-"),
    },
    {
      minWidth: 150,
      width: 200,
      field: "payment_option",
      headerName: "Payment Option",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationRecommendation>) =>
        data ? commitmentsPaymentOptionLabels[data.payment_option] : "",
      valueGetter: ({ data }) =>
        data ? commitmentsPaymentOptionLabels[data.payment_option] : "",
    },
    {
      minWidth: 150,
      width: 190,
      field: "lookback_period",
      headerName: "Based On Past",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationRecommendation>) =>
        data ? savingsPlanLockBackPeriodLabels[data.lookback_period] : "",
      valueGetter: ({ data }) =>
        data ? savingsPlanLockBackPeriodLabels[data.lookback_period] : "",
    },
    {
      width: 155,
      suppressKeyboardEvent: () => true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      resizable: false,
      pinned: "right",
      headerName: "EMS",
      field: "estimated_monthly_savings",
      headerComponentParams: {
        innerHeaderComponent: EMSCell,
      },
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationRecommendation>) => {
        if (!data) {
          return;
        }

        return (
          <CommitmentsRecommendationsEstimatedMonthlySavingCell
            value={data.estimated_monthly_savings}
          />
        );
      },
    },
    agGridEmptyColumnToFitEmptySpace,
  ];
