import { FC, Fragment, useCallback } from "react";
import { Divider, Menu } from "@mui/material";
import { useToggle } from "rooks";
import { ResourceExplorerViewMoveAction } from "../action-items/move/ResourceExplorerViewMoveAction";
import { navigationHierarchyItemNoDndKey } from "../../../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../../../store/navigation/utils/types";
import { CopyURLAction } from "../../../../../../../common/copy-url/CopyURLAction";
import { ResourceExplorerRenameActionItem } from "../action-items/rename/ResourceExplorerRenameActionItem";
import { ResourceExplorerDeleteActionItem } from "../action-items/delete/ResourceExplorerDeleteActionItem";
import { ResourceExplorerDuplicateActionItem } from "../action-items/duplicate/ResourceExplorerDuplicateActionItem";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { resourceExplorerHierarchyVisibilityIsDefaultSelector } from "../../../../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilityIsDefaultSelector";
import { ResourceExplorerCsvDownloader } from "../../../../../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/csv/ResourceExplorerCSVDownloader";
import { ResourceExplorerSettingsActionItem } from "../action-items/report-settings/ResourceExplorerSettingsActionItem";
import { SendReportActionItem } from "../../../../../../../pages/common/send-report/SendReportActionItem";
import { ReportsBySourceActionItem } from "../../../../../../../pages/common/reports-by-source/ReportsBySourceActionItem";
import { ReportsBySourceDrawer } from "../../../../../../../pages/common/reports-by-source/ReportsBySourceDrawer";
import { ProtectAction } from "../../../../../protect/ProtectAction";
import {
  EmbeddedUser,
  ProtectionDetails,
} from "../../../../../../../../services/cloudchipr.api";
import { ViewSettingsDrawer } from "../action-items/report-settings/components/ViewSettingsDrawer";

interface CurrentResourceExplorerActionsMenuProps {
  id: string;
  name: string;
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  visibility: NavigationItemsVisibilityType;
  folderId?: string;
  protectedBy?: EmbeddedUser;
  protectionDetails?: ProtectionDetails;
}

export const CurrentResourceExplorerActionsMenu: FC<
  CurrentResourceExplorerActionsMenuProps
> = ({
  name,
  id,
  open,
  anchor,
  closeMenu,
  folderId,
  visibility,
  protectedBy,
  protectionDetails,
}) => {
  const [settingDrawerOpen, toggleSettingsDrawerOpen] = useToggle();
  const [reportsDrawerOpen, toggleReportsDrawerOpen] = useToggle(false);

  const isVisibilityItemDefault = useAppSelector((state) =>
    resourceExplorerHierarchyVisibilityIsDefaultSelector(state, id),
  );

  const settingsDrawerOpenHandler = useCallback(() => {
    toggleSettingsDrawerOpen();
    closeMenu();
  }, [closeMenu, toggleSettingsDrawerOpen]);
  const reportsDrawerOpenHandler = useCallback(() => {
    toggleReportsDrawerOpen();
    closeMenu();
  }, [closeMenu, toggleReportsDrawerOpen]);

  return (
    <Fragment>
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        sx={{ "& li": { my: 0.5 } }}
        {...navigationHierarchyItemNoDndKey}
      >
        <SendReportActionItem
          sourceType="view"
          sourceId={id}
          onClose={closeMenu}
        />

        <CopyURLAction
          link={`${window.location.host}/resource-explorer/${id}`}
          onClose={closeMenu}
        />

        <ReportsBySourceActionItem
          sourceId={id}
          sourceType="view"
          onClick={reportsDrawerOpenHandler}
          menuItem
        />

        <ResourceExplorerCsvDownloader
          trigger="menuItem"
          onDownload={closeMenu}
        />

        <Divider sx={dividerSx} />

        {!isVisibilityItemDefault && (
          <ResourceExplorerRenameActionItem
            id={id}
            name={name}
            onClose={closeMenu}
            isProtected={!!protectionDetails?.is_protected}
          />
        )}

        <ResourceExplorerSettingsActionItem
          onClick={settingsDrawerOpenHandler}
        />

        <Divider sx={dividerSx} />

        {!isVisibilityItemDefault && visibility === "visible_to_everyone" && (
          <ProtectAction
            id={id}
            source="view"
            onClose={closeMenu}
            protectedBy={protectedBy}
            protectionDetails={protectionDetails}
          />
        )}

        {!isVisibilityItemDefault && (
          <ResourceExplorerViewMoveAction
            id={id}
            name={name}
            folderId={folderId}
            onClose={closeMenu}
            visibility={visibility}
            isProtected={!!protectionDetails?.is_protected}
          />
        )}

        <ResourceExplorerDuplicateActionItem
          id={id}
          name={name}
          onClose={closeMenu}
          folderId={folderId}
          visibility={visibility}
        />

        <Divider sx={dividerSx} />

        {!isVisibilityItemDefault && (
          <ResourceExplorerDeleteActionItem
            id={id}
            name={name}
            onClose={closeMenu}
            isProtected={!!protectionDetails?.is_protected}
          />
        )}
      </Menu>

      <ViewSettingsDrawer
        open={settingDrawerOpen}
        onClose={toggleSettingsDrawerOpen}
      />

      <ReportsBySourceDrawer
        sourceId={id}
        open={reportsDrawerOpen}
        sourceType="view"
        toggleOpen={toggleReportsDrawerOpen}
      />
    </Fragment>
  );
};

const dividerSx = { m: "0px !important" };
