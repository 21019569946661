import { FC } from "react";
import { Stack } from "@mui/material";
import { CommitmentUtilizationWidgetChartTypeSelect } from "./components/CommitmentUtilizationWidgetChartTypeSelect";
import VisualizationIcon from "../../../../../../../../../../../../../assets/images/icons/visualization_icon.svg";
import { WidgetConfigSection } from "../../../../../../common/widget-configurations/WidgetConfigSection";

import { useAppSelector } from "../../../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupVisualizationPropertyByKeySelector";
import {
  commitmentUtilizationVisualizationDetailsByChartType,
  commitmentUtilizationVisualizationDetailsComponentByType,
} from "../../../../../../../../../widgets/commitment-utlization/utils/constants/constants";

export const CommitmentUtilizationWidgetVisualizationSection: FC = () => {
  const chartType = useAppSelector(
    commitmentUtilizationSetupVisualizationPropertyByKeySelector("chart_type"),
  );

  return (
    <WidgetConfigSection
      initialExpanded
      title="Visualization"
      icon={<img src={VisualizationIcon} alt="Visualization Icon" />}
    >
      <Stack spacing={1.5}>
        <CommitmentUtilizationWidgetChartTypeSelect />

        {commitmentUtilizationVisualizationDetailsByChartType
          .get(chartType)
          ?.map((type) => {
            const Component =
              commitmentUtilizationVisualizationDetailsComponentByType.get(
                type,
              );

            if (!Component) {
              return;
            }

            return <Component key={type} />;
          })}
      </Stack>
    </WidgetConfigSection>
  );
};
