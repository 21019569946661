import { FC, useCallback } from "react";
import { useLocation, useMatch, useResolvedPath } from "react-router-dom";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { NestedNavItem } from "../../app-nav-components/nested-nav-item/NestedNavItem";
import { useAppSelector } from "../../../../../store/hooks";
import { useAccountStorageMemoizedTabHook } from "../../../../pages/accounts-group/utils/hooks/useAccountStorageMemoizedTabHook";
import { useAppNavOverlayContext } from "../../../utils/useAppNavOverlay.context";
import { generateLiveResourcesPath } from "../../../../pages/accounts-group/utils/helpers/generateLiveResourcesPath";
import { biggestOrgByProviderSelector } from "../../../../../store/accounts/selectors/all-providers/biggestOrgByProviderSelector";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";
import { navigationProviderIconsMap } from "../../../utils/constants/constants";
import { useLiveUsageNavigationHook } from "../../../utils/hooks/useLiveUsageNavigation.hook";

interface LiveUsageNavigationItemProps {
  provider: ProviderType;
  selectedProvider?: ProviderType | null;
}

export const LiveUsageNavigationItem: FC<LiveUsageNavigationItemProps> = ({
  provider,
  selectedProvider,
}) => {
  const { onClose } = useAppNavOverlayContext();
  const { getAccountTab } = useAccountStorageMemoizedTabHook();

  const location = useLocation();
  const biggestOrgByProvider = useAppSelector((state) =>
    biggestOrgByProviderSelector(state, provider),
  );
  const accountIds = biggestOrgByProvider?.accounts.map(
    (account) => account.id,
  );
  const orgId = biggestOrgByProvider?.orgId;

  const accountIdsAsString = accountIds?.join(",") ?? "";
  const activePath = location.pathname;
  const accountsPath = generateLiveResourcesPath({ provider, orgId });
  const liveUsagePath = generateLiveResourcesPath({
    provider,
    activeTab: "live-resources",
    accountIds: accountIdsAsString,
  });

  const accountTab = getAccountTab();
  const path = accountTab === "live-resources" ? liveUsagePath : accountsPath;

  const resolvedPath = useResolvedPath(accountsPath || "");
  const match = useMatch({ path: resolvedPath.pathname });
  const accountsOrLiveUsageActive =
    accountsPath.includes(activePath) || liveUsagePath.includes(activePath);
  const accountsPageActive = accountsPath.includes(
    `/${selectedProvider ?? ""}/accounts/`,
  );

  const selected =
    (resolvedPath.pathname !== "/" && !!match) ||
    (accountsPageActive && accountsOrLiveUsageActive);

  const liveUsageNavigationHandler = useLiveUsageNavigationHook(provider);

  const handleClick = useCallback(() => {
    liveUsageNavigationHandler();
    onClose?.();
  }, [onClose, liveUsageNavigationHandler]);

  if (!biggestOrgByProvider) {
    return;
  }

  return (
    <NestedNavItem
      nested
      onButtonClick={handleClick}
      key={path}
      primary={getProviderName(provider, {
        nameAndTitle: true,
        capitalize: true,
        plural: true,
      })}
      Icon={navigationProviderIconsMap.get(provider)}
      selected={selected}
    />
  );
};
