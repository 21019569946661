import { FC, Fragment, useMemo } from "react";
import { grey } from "@mui/material/colors";
import { Box, Theme } from "@mui/material";
import { getBillingExplorerWidgetSetupTableViewColumns } from "./getBillingExplorerWidgetSetupTableViewColumns";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { DataGrid } from "../../../../../../../../../../storybook/data-grid/DataGrid";
import { widgetSetupContentChartHeight } from "../../../../../../../utils/constants/stylings";
import { billingExplorerSetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { BillingExplorerWidgetSetupContentCosts } from "../BillingExplorerWidgetSetupContentCosts";
import { getColumnVisibilityByVisualizationAndForecastedOption } from "../../../../../../widgets/billing-explorer-widget/components/chart/table/utils/helpers/getColumnVisibilityByVisualizationAndForecastedOption";
import { billingExplorerSetupHasAggregationSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupHasAggregationSelector";
import { billingExplorerWidgetSetupNoChartViewDataSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupNoChartViewDataSelector";

export const BillingExplorerWidgetSetupTableView: FC = () => {
  const gridData = useAppSelector(
    billingExplorerWidgetSetupNoChartViewDataSelector,
  );
  const grouping = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("grouping"),
  );

  const forecastOption = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("forecastOption"),
  );

  const visualization = useAppSelector((state) =>
    billingExplorerSetupPropertyByKeySelector("visualization")(state),
  );

  const hasAggregations = useAppSelector(
    billingExplorerSetupHasAggregationSelector,
  );

  const columnVisibility = useMemo(
    () =>
      getColumnVisibilityByVisualizationAndForecastedOption(
        visualization ?? {},
        forecastOption,
      ),
    [visualization, forecastOption],
  );

  const columns = useMemo(() => {
    return getBillingExplorerWidgetSetupTableViewColumns({
      forecastOption,
      grouping,
    });
  }, [forecastOption, grouping]);

  return (
    <Fragment>
      {hasAggregations && (
        <Box p={2}>
          <BillingExplorerWidgetSetupContentCosts />
        </Box>
      )}
      <DataGrid
        styles={styles}
        columns={columns ?? []}
        columnSorting
        data={!gridData ? [] : gridData}
        columnVisibility={{
          initialVisibility: columnVisibility,
        }}
        enableRowsVirtualization={gridData?.length > 30}
      />
    </Fragment>
  );
};

const styles = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& tr:hover td": { bgcolor: grey[100] },
    "& tr td": { borderColor: grey[100], py: 0.5, pl: 2 },
  },

  tableHeaderRow: {
    "& th": {
      p: 1,
      verticalAlign: "inherit",
      bgcolor: grey[100],
    },
    top: 0,
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
  },
  tableRow: {
    height: 40,
  },

  tableContainer: {
    border: 1,
    borderColor: grey[200],
    maxHeight: `${widgetSetupContentChartHeight}px`,
  },
};
