import { Task } from "../../../../../services/cloudchipr.api";
import { getCSVValueGetterAndTitleByColId } from "../constants/getCSVValueGetterAndTitleByColId";

export const getTasksCSVData = (
  tasks: Task[],
  visibleColumns?: string[],
): Record<string, any>[] | undefined => {
  return tasks.map((task) => {
    const data: Record<string, any> = {};

    visibleColumns?.map((colId) => {
      const valueGetterAndTitle = getCSVValueGetterAndTitleByColId[colId];

      valueGetterAndTitle?.map(({ title, getValue }) => {
        data[title] = getValue(task);
      });
    });

    return data;
  });
};
