import { FC } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface KubernetesClusterAddDrawerHeaderProps {
  onClose(): void;
}

export const KubernetesClusterAddDrawerHeader: FC<
  KubernetesClusterAddDrawerHeaderProps
> = ({ onClose }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      px={2}
      py={1}
      alignItems="center"
    >
      <Typography variant="subtitle1">Connect Cluster</Typography>
      <IconButton onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
