import { RootState } from "../../../../../store";
import { billingExplorerWidgetSetupDataPayloadSelector } from "../setup/billingExplorerWidgetSetupDataPayloadSelector";
import { billingExplorerWidgetSetupSelector } from "../setup/billingExplorerWidgetSetupSelector";

export const billingExplorerWidgetSetupDataGettingLoadingSelector = (
  state: RootState,
): boolean => {
  const payload = billingExplorerWidgetSetupDataPayloadSelector(state);
  if (!payload) {
    return false;
  }
  const response = billingExplorerWidgetSetupSelector(payload)(state);

  return response.isLoading;
};
