import { FC } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { CreateBudgetOrAlertButton } from "../../CreateBudgetOrAlertButton";
import AlertsEmptyState from "../../../../../../assets/images/alerts_empty_state.png";
import { useAppAbility } from "../../../../../../services/permissions";
import { getUserPermissionInfoText } from "../../../../utils/helpers/getUserPermissionInfoText";

export const AlertsEmptyPage: FC = () => {
  const { cannot } = useAppAbility();
  const cannotCreateAlert = cannot("create", "alert");

  const userPermissionTooltipText = cannotCreateAlert
    ? getUserPermissionInfoText("Create Alerts")
    : "";

  return (
    <Stack alignItems="center" justifyContent="center" py={15} gap={4}>
      <img src={AlertsEmptyState} alt="AlertsEmptyState" width={160} />

      <Stack gap={3} alignItems="center">
        <Typography variant="h6" fontWeight="bold">
          You haven't created any alerts yet.
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Start by creating a new alert to get notified about any significant
          cost changes.
        </Typography>
        <Tooltip title={userPermissionTooltipText}>
          <span>
            <CreateBudgetOrAlertButton
              variant="outlined"
              disabled={cannotCreateAlert}
            >
              Create Alert
            </CreateBudgetOrAlertButton>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
