import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAlertsData } from "../../alertsSlice";
import { RootState } from "../../../store";
import { awsOrganizationWithCommitmentsSelector } from "../../../commitments/selectors/organisations/awsOrganizationWithCommitmentsSelector";
import { alertsDrawerEditModeSelector } from "../../selectors/alertsDrawerEditModeSelector";

export const initiateUtilizationAlertThunk = createAsyncThunk(
  "alerts/initiateUtilizationAlert",
  (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const awsOrganizations = awsOrganizationWithCommitmentsSelector(state);
    const firstOrgId = awsOrganizations?.at(0)?.organization_id;
    const editMode = alertsDrawerEditModeSelector(state);

    dispatch(
      setAlertsData({
        threshold: {
          threshold_items: [
            {
              unit: "day",
              amount: "1",
            },
          ],
        },
      }),
    );

    if (!editMode && firstOrgId) {
      dispatch(setAlertsData({ providerOrganisationId: firstOrgId }));
    }
  },
);
