import { FC, ReactNode } from "react";
import { TableCell, TableRow, Typography } from "@mui/material";

interface TaskSingleTargetTableRowProps {
  highlight?: boolean;
  label: string;
  children?: ReactNode;
}

export const TaskSingleTargetTableRow: FC<TaskSingleTargetTableRowProps> = ({
  children,
  label,
  highlight,
}) => {
  return (
    <TableRow>
      <TableCell
        colSpan={1}
        sx={{
          px: 2,
          py: 1,
          border: "none",
          minWidth: 180,
          bgcolor: highlight ? "grey.50" : undefined,
        }}
      >
        <Typography variant="body2" fontWeight="medium" color="text.secondary">
          {label}
        </Typography>
      </TableCell>

      <TableCell
        colSpan={3}
        sx={{
          px: 2,
          py: 1,
          border: "none",
          bgcolor: highlight ? "grey.50" : undefined,
        }}
      >
        {children}
      </TableCell>
    </TableRow>
  );
};
