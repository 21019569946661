import { v4 as uuid } from "uuid";
import { isTreeNodeFilterGroup } from "./isTreeNodeFilterGroup";
import {
  FilterItemNode,
  FilterOperatorType,
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
} from "../../../../../../services/cloudchipr.api";
import {
  FilterGroupNodeWithId,
  FilterItemNodeWithId,
  FilterTreeNodeWithId,
} from "../types/common";

export const addFilterOnFirstLevel = (
  provider: ResourceExplorerFilterProvider,
  key: ResourceExplorerDynamicFilterItemType,
  operator: FilterOperatorType,
  filterTree?: FilterGroupNodeWithId,
  value?: FilterItemNode["value"],
): FilterGroupNodeWithId => {
  const tree = filterTree ?? defaultFilterTree;

  const newFilter: FilterItemNodeWithId = {
    id: uuid(),
    node_type: "item",
    type: key,
    filter_provider: provider,
    value: value ?? null,
    operator,
  };

  let providerExist = false;

  const filterNewTree: FilterGroupNodeWithId = {
    ...tree,
    items: tree.items.map((node) => {
      if (!isTreeNodeFilterGroup(node)) {
        return node;
      }

      if (foundOwnProviderGroup(provider, node)) {
        providerExist = true;

        return { ...node, items: [...node.items, newFilter] };
      }

      return node;
    }),
  };

  if (!providerExist) {
    filterNewTree.items.push({
      node_type: "group",
      operator: "and",
      items: [newFilter],
    });
  }

  return filterNewTree;
};

const foundOwnProviderGroup = (
  provider: ResourceExplorerFilterProvider,
  filter?: FilterTreeNodeWithId,
): boolean => {
  if (!filter) {
    return false;
  }

  if (!isTreeNodeFilterGroup(filter)) {
    return filter.filter_provider === provider;
  }

  return foundOwnProviderGroup(provider, filter.items.at(0));
};

const defaultFilterTree: FilterGroupNodeWithId = {
  node_type: "group",
  operator: "and",
  items: [],
};
