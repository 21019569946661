import { FC } from "react";
import { Stack } from "@mui/material";
import { AzureCommitmentsOverviewCoverage } from "./AzureCommitmentsOverviewCoverage";
import { AzureCommitmentsOverviewCommitments } from "./AzureCommitmentsOverviewCommitments";

export const AzureCommitmentsOverviewContent: FC = () => {
  return (
    <Stack direction="row" spacing={1} flex={1} justifyContent={"center"}>
      <AzureCommitmentsOverviewCoverage />
      <AzureCommitmentsOverviewCommitments />
    </Stack>
  );
};
