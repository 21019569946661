import { FC, memo, useCallback, useEffect, useMemo } from "react";
import { FormikHelpers } from "formik";
import {
  FilterItem,
  ResourceType,
  useGetUsersMeOrganisationsCurrentKubernetesByResourceTypeFiltersAndFilterTypeValuesQuery,
} from "../../../../../../../../services/cloudchipr.api";
import { multipleValueSelectOperators } from "../../../../utils/constants/filter-types/autocomplete-multipe-selection-operator-types";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { AutocompleteSelect } from "../../../../../../../common/select/autocomplete-select/AutocompleteSelect";

interface KubernetesTagFilterValuesProps {
  error?: boolean;
  operator: string;
  accountIds?: string[];
  resourceType: ResourceType;
  tagKey: string;
  disabled?: boolean;
  value: string[];
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
  loading?: boolean;
}

export const KubernetesTagFilterValues: FC<KubernetesTagFilterValuesProps> =
  memo(
    ({
      setFieldValue,
      disabled,
      value,
      tagKey,
      resourceType,
      accountIds,
      error,
      operator,
      loading,
    }) => {
      const singleSelect = !multipleValueSelectOperators.includes(operator);

      const { data, refetch, isLoading } =
        useGetUsersMeOrganisationsCurrentKubernetesByResourceTypeFiltersAndFilterTypeValuesQuery(
          {
            resourceType: resourceType,
            filterType: "tag_value",
            key: tagKey,
          },
          {
            skip: !tagKey,
          },
        );

      const autoCompleteSelectChangeHandler = useCallback(
        (selectedVaule: any) => {
          const data = singleSelect ? selectedVaule[0] : selectedVaule;
          const value = data?.length ? data : null;

          setFieldValue("value.value", value);
        },
        [singleSelect, setFieldValue],
      );

      const values = useMemo(() => {
        const stringValue = value && typeof value === "string" ? [value] : [];
        return Array.isArray(value) ? value : stringValue;
      }, [value]);

      const options = useMemo(() => {
        return data?.map((item) => {
          return {
            label: <TypographyWithTooltip title={item.title ?? item.value} />,
            value: item.value,
          };
        });
      }, [data]);

      const renderOption = useCallback((option: any) => {
        return <TypographyWithTooltip title={option.value} />;
      }, []);

      useEffect(() => {
        if (!tagKey) {
          return;
        }

        refetch();
      }, [tagKey, refetch]);

      return (
        <AutocompleteSelect
          size="xsmall"
          freeSolo
          creatable
          singleSelect={singleSelect}
          label="Value"
          placeholder="Value"
          options={options ?? []}
          error={error}
          initialSelectedValues={data ? values : undefined}
          onChange={autoCompleteSelectChangeHandler}
          disabled={disabled}
          values={values ?? []}
          optionsLoading={isLoading || loading}
          renderOption={renderOption}
        />
      );
    },
  );
