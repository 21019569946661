import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAzureSavingsPlansForDataGridThunk } from "./getAzureSavingsPlansForDataGridThunk";
import { getAzureSavingsPlansForGranularThunk } from "./getAzureSavingsPlansForGranularThunk";

export const getAzureSavingsPlansThunk = createAsyncThunk(
  "commitments/getAzureSavingsPlans",
  (_: void, { dispatch }) => {
    dispatch(getAzureSavingsPlansForDataGridThunk());
    dispatch(getAzureSavingsPlansForGranularThunk());
  },
);
