import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { commitmentUtilizationWidgetByIdSelector } from "../../../../selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetByIdSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { CommitmentUtilizationWidgetSetupType } from "../../../../utils/types/setups/commitmentUtilizationWidgetSetupType";
import { getCommitmentUtilizationWidgetSetupDataThunk } from "../getCommitmentUtilizationWidgetSetupDataThunk";

interface EditCommitmentUtilizationChangesWidgetArgs {
  widgetId: string;
  doNotSetWidgetType?: boolean;
}

export const editCommitmentUtilizationChangesWidgetThunk = createAsyncThunk(
  "dashboards/editCommitmentUtilizationChangesWidget",
  async (
    {
      widgetId,
      doNotSetWidgetType,
    }: EditCommitmentUtilizationChangesWidgetArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = commitmentUtilizationWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    dispatch(
      setWidgetSetup({
        widgetType: doNotSetWidgetType ? undefined : "commitments_utilization",
        id: widget.id,
        name: widget.name,
        dates: widget.dates,
        grouping: widget.grouping,
        visualization: widget.visualization,
        aggregation: widget.aggregation,
        trendType: widget.trend_type,
        commitmentType: widget.commitment_type,
        providerOrganisationId: widget.provider_organisation_id,
      } as CommitmentUtilizationWidgetSetupType),
    );

    dispatch(getCommitmentUtilizationWidgetSetupDataThunk());
  },
);
