import { createAsyncThunk } from "@reduxjs/toolkit";
import { getKubernetesResourceDefaultFiltersByResourceType } from "./utils/helpers/getKubernetesResourceDefaultFiltersByResourceType";
import { resourceTypesByProvider } from "../../../../utils/helpers/providers/resourceTypesByProvider";
import { setKubernetesLiveUsageMgmtAppliedFilters } from "../../kubernetesLiveUsageMgmtSlice";

export const setKubernetesResourcesDefaultFilterThunk = createAsyncThunk(
  "liveUsageMgmt/setKubernetesResourcesDefaultFilter",
  async (_, { dispatch }) => {
    const resourceTypes = resourceTypesByProvider("kubernetes");

    resourceTypes?.forEach((resourceType) => {
      const defaultFilter =
        getKubernetesResourceDefaultFiltersByResourceType(resourceType);
      if (defaultFilter) {
        dispatch(setKubernetesLiveUsageMgmtAppliedFilters(defaultFilter));
      }
    });
  },
);
