import { Alert, Typography } from "@mui/material";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { billingExplorerSummaryAnomaliesCountSelector } from "../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/billingExplorerSummaryAnomaliesCountSelector";

interface BillingExplorerExplainWithAiAnomaliesCountProps {
  index: number;
}
export const BillingExplorerExplainWithAiAnomaliesCount: FC<
  BillingExplorerExplainWithAiAnomaliesCountProps
> = ({ index }) => {
  const anomaliesCount = useAppSelector((state) =>
    billingExplorerSummaryAnomaliesCountSelector(state, index),
  );

  if (typeof anomaliesCount !== "number") {
    return null;
  }

  return (
    <Alert
      icon={<TroubleshootIcon sx={{ color: "warning.light" }} />}
      severity="warning"
      sx={{
        border: 1,
        borderColor: "warning.light",
        bgcolor: "white",
        color: "text.primary",
      }}
    >
      <Typography variant="body1">
        Cost Anomalies Detected{" "}
        <Typography color="text.secondary" component="span">
          ({anomaliesCount})
        </Typography>
      </Typography>
    </Alert>
  );
};
