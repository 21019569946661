import { FC, Fragment, ReactNode } from "react";
import {
  Chip,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  Stack,
  SvgIconProps,
} from "@mui/material";
import { SvgIconComponent, VerifiedUserOutlined } from "@mui/icons-material";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";

export interface NestedNavItemContentProps {
  selected?: boolean;
  isProtected?: boolean;
  Icon?: SvgIconComponent;
  iconProps?: SvgIconProps;
  comingSoon?: boolean | ReactNode;
  primary: ListItemTextProps["primary"];
  primaryTypographyProps?: ListItemTextProps["primaryTypographyProps"];
}

export const NestedNavItemContent: FC<NestedNavItemContentProps> = ({
  primary,
  Icon,
  selected,
  iconProps,
  primaryTypographyProps,
  comingSoon,
  isProtected,
}) => {
  const comingSoonComponent =
    typeof comingSoon === "boolean" ? comingSoonChipByDefault : comingSoon;

  return (
    <Fragment>
      <ListItemIcon
        sx={isProtected ? listItemProtectionIconSx : listItemIconSx}
      >
        {Icon && (
          <Icon
            fontSize="small"
            sx={{ color: selected ? "primary.main" : "black" }}
            {...iconProps}
          />
        )}
      </ListItemIcon>

      {isProtected && (
        <ListItemIcon sx={{ minWidth: 24 }}>
          <VerifiedUserOutlined
            sx={{ fontSize: 16 }}
            color={selected ? "primary" : undefined}
            {...iconProps}
          />
        </ListItemIcon>
      )}

      <ListItemText
        primary={
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <TypographyWithTooltip
              title={primary}
              variant="inherit"
              fontWeight={selected ? "bold" : undefined}
            />

            {comingSoon && comingSoonComponent}
          </Stack>
        }
        primaryTypographyProps={{
          ...primaryTypographyPropsOverride,
          ...primaryTypographyProps,
          color: selected ? "primary" : undefined,
        }}
      />
    </Fragment>
  );
};

const primaryTypographyPropsOverride = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const listItemIconSx = { minWidth: 30 };
const listItemProtectionIconSx = { minWidth: 28 };

const comingSoonChipByDefault = (
  <Chip
    variant="filled"
    color="success"
    label="Coming Soon"
    size="small"
    sx={{ fontSize: 10, height: 16, borderRadius: 1 }}
  />
);
