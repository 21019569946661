import { FC, memo } from "react";
import { Box, IconButton, Link, Stack, Tooltip } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { PlayCircleOutlined } from "@mui/icons-material";
import { Chart, Series } from "highcharts";
import { ResourceTags } from "./components/ResourceTags";
import { RowSelectionButton } from "./components/RowSelectionButton";
import { LabelColumnCellLabel } from "./components/LabelColumnCellLabel";
import { useMultiTypeChartContext } from "../../../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { resourceExplorerChartDataOthersKey } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/resourceExplorerChartDataSelector";
import { useHover } from "../../../../../../../../../../../utils/hooks/useHover.hook";
import {
  ProviderType,
  ResourceExplorerFilterProvider,
  ResourceExplorerGroupingNullable,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { getAzureTagsFeatureToggleFromStorage } from "../../../../../../../../utils/helpers/azure-tags/getAzureTagsFeatureToggleFromStorage";
import { ProviderIcon } from "../../../../../../../../../../common/ProviderIcon";
import { generateLiveResourcesPath } from "../../../../../../../../../accounts-group/utils/helpers/generateLiveResourcesPath";
import { useHighchartsContext } from "../../../../../../../../../../../storybook/c8r-highcharts/common/components/HighchartsProvider";
import { resourceExplorerChartKeyGenerator } from "../../../../../../../../../common/billing-explorer/utils/resourceExplorerChartKeyGenerator";
import { ResourceExplorerGridDataWithId } from "../../../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr-authless.api";

interface LabelColumnCellProps {
  data: ResourceExplorerGridDataWithId;
}

export const LabelColumnCell: FC<LabelColumnCellProps> = memo(({ data }) => {
  const provider = data.cloud_provider;
  const label = data.label;
  const field = data.field;
  const grouping = data.resourceExplorerGrouping;
  const accountId = data.metadata?.account_id;
  const liveAccountId = data.metadata?.live_account_id;
  const isTracked = data.metadata?.is_tracked;
  const aggregatedByValue = data.metadata?.aggregated_by_value;
  const filterValue = data.metadata?.filter_value;
  const region = data.metadata?.region;
  const providerAccountId = data.metadata?.provider_account_id;
  const service = data.metadata?.service as ResourceType;
  const providerAccountName = data.metadata?.provider_account_name;
  const clickable = data.metadata?.clickable as unknown as boolean;
  const rowNextGrouping = data?.metadata
    ?.next_grouping as ResourceExplorerGroupingNullable;
  const accountExistInLiveUsage =
    !!data.accountExistInLiveUsage &&
    data.resourceExplorerGrouping === "account";

  const enableAzureTags = getAzureTagsFeatureToggleFromStorage();
  const enableHighcharts = useFlag("EnableHighchartsOnBillingExplorer");

  const { ref, hovered } = useHover();
  const { colors } = useMultiTypeChartContext();
  const { chartApi } = useHighchartsContext();

  const title = label || field;
  const resourceId = field ?? undefined;
  const tooltip = !label ? "" : field;
  const key = resourceExplorerChartKeyGenerator(resourceId, label);
  let color =
    colors?.[key] ?? colors?.[resourceExplorerChartDataOthersKey] ?? "";

  if (enableHighcharts) {
    color = getColorFromChartByKey(chartApi, key);
  }

  const tagsAvailable = accountId && grouping === "resource";
  const tagsAvailableWithAzure = enableAzureTags
    ? tagsAvailable
    : tagsAvailable && provider !== "azure";

  const liveUsageProvider =
    provider in liveUsageProviderByProvider
      ? (liveUsageProviderByProvider[provider] as ProviderType)
      : undefined;

  const showLiveResourcesLink =
    accountExistInLiveUsage && liveAccountId && liveUsageProvider && hovered;

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      width="100%"
      minHeight={42}
      ref={ref}
    >
      {color && (
        <Box minWidth={4} borderRadius={0.5} height={35} bgcolor={color} />
      )}

      <RowSelectionButton
        field={field}
        provider={provider}
        clickable={clickable}
        aggregatedByValue={aggregatedByValue}
        filterValue={filterValue}
        rowNextGrouping={rowNextGrouping}
      />

      {provider && <ProviderIcon provider={provider} />}

      <Stack
        direction="row"
        alignItems="center"
        gap={0.3}
        overflow="hidden"
        ref={ref}
      >
        <LabelColumnCellLabel
          title={title}
          region={region}
          hovered={hovered}
          tooltip={tooltip}
          service={service}
          label={label ?? ""}
          accountId={accountId}
          resourceId={resourceId}
          isTracked={!!isTracked}
          filterValue={filterValue}
          liveAccountId={liveAccountId}
          liveUsageProvider={liveUsageProvider}
          providerAccountId={providerAccountId}
          providerAccountName={providerAccountName}
        />

        {showLiveResourcesLink && (
          <Tooltip title="View in Live Resources." placement="top" arrow>
            <IconButton
              component={Link}
              href={generateLiveResourcesPath({
                provider: liveUsageProvider,
                activeTab: "live-resources",
                accountIds: liveAccountId,
              })}
              target="_blank"
              size="small"
            >
              <PlayCircleOutlined color="action" fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {tagsAvailableWithAzure && (
        <ResourceTags
          resourceId={resourceId}
          accountId={accountId}
          provider={provider as ProviderType}
        />
      )}
    </Stack>
  );
});

const liveUsageProviderByProvider: Partial<
  Record<ResourceExplorerFilterProvider, ResourceExplorerFilterProvider>
> = {
  aws: "aws",
  azure: "azure",
  gcp: "gcp",
  eks: "aws",
};

export const getColorFromChartByKey = (chartApi: Chart | null, key: string) => {
  let foundByKey: Series | undefined;
  let underOthers: Series | undefined;

  chartApi?.series?.forEach((s) => {
    if (!s) {
      return;
    }

    if (s.name === key) {
      foundByKey = s;
    }

    if (s.name === resourceExplorerChartDataOthersKey) {
      underOthers = s;
    }
  });

  return foundByKey?.color?.toString() ?? underOthers?.color?.toString() ?? "";
};
