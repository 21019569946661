import { FC, useCallback, useMemo } from "react";
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { CostAnomaliesWidgetSetupResourceCostItem } from "./CostAnomaliesWidgetSetupResourceCostItem";
import { ProviderIcon } from "../../../../../../../../../common/ProviderIcon";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { TrendChip } from "../../../../../../../../../common/chips/TrendChip";
import {
  Dates,
  ProviderType,
  ResourceExplorerCostsDateDetails,
  ResourceExplorerGroupingNullable,
} from "../../../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import { getNumeralViewItemsPaddings } from "../../../common/view-type/utils/helpers/getNumeralViewItemsPaddings";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { generateDatesCompareData } from "../../../../../../../../../common/date-range-picker/utils/helpers/date-formatter/generateDatesCompareData";
import { objectToUrl } from "../../../../../../../../../utils/helpers/dataToUrlToDataConvertors";

interface CostAnomaliesWidgetSetupResourceItemProps {
  date?: Dates;
  label: string;
  viewId?: string;
  totalCost: number;
  itemsCount: number;
  trend: number | null;
  groupValues?: string[];
  provider: ProviderType;
  difference: number | null;
  previousPeriodTotalCost: number | null;
  grouping?: ResourceExplorerGroupingNullable;
  previousPeriodDate?: ResourceExplorerCostsDateDetails;
}
export const CostAnomaliesWidgetSetupResourceItem: FC<
  CostAnomaliesWidgetSetupResourceItemProps
> = ({
  date,
  trend,
  label,
  viewId,
  provider,
  grouping,
  totalCost,
  itemsCount,
  difference,
  groupValues,
  previousPeriodDate,
  previousPeriodTotalCost,
}) => {
  const isBig = itemsCount < 5;
  const style = styles.get(isBig ? "big" : "small");

  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dateInfo = useMemo(() => {
    return generateDatesCompareData({
      date,
      quarterStartMonth,
      prevDate: previousPeriodDate,
    });
  }, [quarterStartMonth, previousPeriodDate, date]);

  const path = objectToUrl([
    {
      dates: date,
      search: label,
      groupedBy: grouping,
      filterTree: undefined,
      groupValues: groupValues,
    },
  ]);

  const url = `/resource-explorer/${viewId}?path=${path}`;

  const clickHandler = useCallback(() => {
    window.open(url, "_blank");
  }, [url]);

  const startDateText = getDateText(
    dateInfo.startDateLabel,
    dateInfo.startDateRange,
  );
  const endDateText = getDateText(dateInfo.endDateLabel, dateInfo.endDateRange);
  const trendMainText = `change in cost comparing ${startDateText.toLowerCase()} to ${endDateText.toLowerCase()}`;

  return (
    <Box
      flexGrow="1"
      boxSizing="border-box"
      width={itemsCount > 6 ? "50%" : "100%"}
      p={getNumeralViewItemsPaddings(itemsCount)}
    >
      <Stack
        spacing={1}
        height="100%"
        sx={cardStyles}
        direction="row"
        borderRadius={1}
        bgcolor="grey.50"
        alignItems="center"
        px={style?.padding}
        onClick={clickHandler}
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          flex="2 1 0"
          overflow="hidden"
        >
          <ProviderIcon provider={provider} />
          <TypographyWithTooltip title={label} {...style?.resourceName} />
        </Stack>

        <CostAnomaliesWidgetSetupResourceCostItem
          cost={totalCost}
          title="Total Cost"
          totalCostTypographyProps={style?.totalCost}
          priceCostTypographyProps={style?.price}
          tooltipTitle={startDateText}
        />

        <CostAnomaliesWidgetSetupResourceCostItem
          cost={previousPeriodTotalCost}
          title="Preceding Cost"
          totalCostTypographyProps={style?.totalCost}
          priceCostTypographyProps={style?.price}
          tooltipTitle={endDateText}
        />

        <Stack spacing={1} alignItems="flex-end" flex="1 1 0">
          <Tooltip
            arrow
            placement="top"
            title={`Dollar amount ${trendMainText}`}
          >
            <Stack
              spacing={isBig ? 0.2 : 0.1}
              direction="row"
              alignItems="center"
            >
              {!!trend &&
                (trend > 0 ? (
                  <Typography
                    color={red[700]}
                    fontSize={isBig ? 24 : 14}
                    fontWeight="medium"
                  >
                    +
                  </Typography>
                ) : (
                  <Typography
                    color={green[700]}
                    fontSize={isBig ? 24 : 14}
                    fontWeight="medium"
                  >
                    -
                  </Typography>
                ))}

              {difference !== null && (
                <Typography fontWeight="medium" {...style?.price}>
                  {money(difference)}
                </Typography>
              )}
            </Stack>
          </Tooltip>

          <TrendChip
            value={trend}
            tooltipTitle={`Percentage ${trendMainText}`}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

const getDateText = (label?: string | null, range?: string) =>
  range ? `${label} (${range})` : `${label}`;

const cardStyles = { cursor: "pointer", "&:hover": { bgcolor: "grey.100" } };

const styles = new Map<
  "big" | "small",
  {
    resourceName: TypographyProps;
    price: TypographyProps;
    totalCost: TypographyProps;
    padding: number;
  }
>([
  [
    "big",
    {
      resourceName: { variant: "h6", fontWeight: 400 },
      totalCost: { variant: "body1" },
      price: { variant: "h5" },
      padding: 3,
    },
  ],
  [
    "small",
    {
      resourceName: { variant: "body1" },
      totalCost: { variant: "caption" },
      price: { variant: "body2" },
      padding: 1,
    },
  ],
]);
