import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IntegrationCardWrapper } from "./IntegrationCardWrapper";
import { IntegrationType } from "../../../../../../store/integrations/integrationsSlice";
import { useGetUsersMeIntegrationsQuery } from "../../../../../../services/cloudchipr.api";
import { getConnectionCounts } from "../../../../integrations/components/communications/common/utils/helpers/getConnectionCounts";
import { CommunicationIntegrationCard } from "../../../../integrations/components/communications/grid-view/components/communication-integration-card/CommunicationIntegrationCard";

interface CommunicationIntegrationsGeneralDrawerProps {
  open: boolean;
  onClose?: () => void;
  disabledIntegrationTypes?: IntegrationType[];
}

export const IntegrationsGridViewDrawer: FC<
  CommunicationIntegrationsGeneralDrawerProps
> = ({ open, onClose, disabledIntegrationTypes }) => {
  const { data: integrations } = useGetUsersMeIntegrationsQuery({});

  const filteredIntegrationItems = useMemo(
    () =>
      getConnectionCounts(integrations).filter(
        (item) => !disabledIntegrationTypes?.includes(item.type),
      ),
    [integrations, disabledIntegrationTypes],
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={paperProps}
      slotProps={slotProps}
    >
      <Stack
        py={1.25}
        px={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body1">Create Integration</Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Divider />

      <Box p={2}>
        <Stack pl={4}>
          <Typography variant="body1" fontWeight="medium" pb={1}>
            Communications
          </Typography>

          <Grid
            container
            spacing={2}
            pr={4}
            pb={2}
            columns={{ xs: 2, lg: 3, xl: 3, xxl: 3 }}
          >
            {filteredIntegrationItems.map((item) => (
              <IntegrationCardWrapper item={item} key={item.type}>
                <CommunicationIntegrationCard
                  key={item.type}
                  type={item.type}
                  connections={item.connections}
                />
              </IntegrationCardWrapper>
            ))}
          </Grid>
        </Stack>
      </Box>
    </Drawer>
  );
};

const slotProps = {
  backdrop: {
    invisible: true,
  },
};

const paperProps = {
  sx: {
    width: "50dvw",
    minWidth: 670,
    maxWidth: 1000,
  },
};
