import { useCallback, useState } from "react";
import { Stack } from "@mui/material";
import { CommitmentsToolbar } from "../../../../../common/components/CommitmentsAgGrid";
import { CommitmentsRecommendationsFilters } from "../../../content/recommendations/filters/CommitmentsRecommendationsFilters";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";

export const CoverageDrawerRecommendationsGridToolbar: CommitmentsToolbar = ({
  gridApi,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridApi?.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [gridApi],
  );

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={2}
      justifyContent="space-between"
      py={1}
    >
      <CommitmentsRecommendationsFilters type="savings_plan" />

      <GlobalFilter
        globalFilter={searchValue}
        setGlobalFilter={searchChangeHandler}
        size="xsmall"
      />
    </Stack>
  );
};
