import { FC, useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { TaskActivityWrapper } from "./TaskActivityWrapper";
import { EmbeddedUser } from "../../../../../../../../../../services/cloudchipr.api";
import { CommentUser } from "../../../../../../../../task-management/components/grid/cell-renderers/comment-cell/components/CommentUser";
import { ActivityComponentProps } from "../../utils/types/types";

interface AssigneeActivityProps extends ActivityComponentProps {
  assignee?: EmbeddedUser;
  prevAssignee?: EmbeddedUser;
}

export const AssigneeActivity: FC<AssigneeActivityProps> = ({
  createdAt,
  createdBy,
  assignee,
  prevAssignee,
}) => {
  const createdByRef = useRef<HTMLDivElement>(null);
  const assigneeRef = useRef<HTMLDivElement>(null);
  const [isWrapped, setIsWrapped] = useState(false);

  useEffect(() => {
    const checkWrapping = () => {
      if (createdByRef.current && assigneeRef.current) {
        const createdByTop = createdByRef.current.getBoundingClientRect().top;
        const assigneeRefTop = assigneeRef.current.getBoundingClientRect().top;
        setIsWrapped(assigneeRefTop > createdByTop);
      }
    };

    checkWrapping();

    window.addEventListener("resize", checkWrapping);
    return () => window.removeEventListener("resize", checkWrapping);
  }, [createdBy, prevAssignee]);

  return (
    <TaskActivityWrapper
      createdAt={createdAt}
      content={
        <Stack
          direction="row"
          spacing={0.75}
          flexWrap="wrap"
          alignItems="center"
        >
          <Box ref={createdByRef}>
            <CommentUser
              user={createdBy}
              additionalText={assignee ? "assigned to" : "removed assignee"}
            />
          </Box>
          <Box ref={assigneeRef} sx={{ pl: isWrapped ? 2.5 : 0 }}>
            {assignee ? (
              <CommentUser user={assignee} />
            ) : (
              prevAssignee && <CommentUser user={prevAssignee} />
            )}
          </Box>
        </Stack>
      }
    />
  );
};
