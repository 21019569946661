import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Typography } from "@mui/material";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../../../services/cloudchipr.api";
import { ReservationsCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { money } from "../../../../../../../../../../../utils/numeral/money";

export const getCoveredUsageColumnDef = (
  grouping: SavingsPlanCoverageGrouping,
): ColDef<ReservationsCoverageDataWithId> => {
  const headerName =
    grouping !== "commitment"
      ? "Spend Covered by this RI"
      : " Spend Covered by RIs";

  return {
    flex: 1.5,
    minWidth: 200,
    headerName,
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
      <Typography variant="body2">{money(data?.covered_usage ?? 0)}</Typography>
    ),
    valueGetter: ({ data }) => money(data?.covered_usage ?? 0),
  };
};
