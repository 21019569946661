import { FC, useCallback } from "react";
import { Editor } from "../../../../../../../../../storybook/editor/Editor";
import { CommandName } from "../../../../../../../../../storybook/editor/utils/types/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { textWidgetSetupTextSelector } from "../../../../../../../../../store/dashboards/selectors/setups/text/textWidgetSetupTextSelector";
import { textWidgetSetupTextChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/text-widget/setup/textWidgetSetupTextChangeThunk";

export const TextWidgetSetupSidebar: FC = () => {
  const dispatch = useAppDispatch();
  const text = useAppSelector(textWidgetSetupTextSelector);

  const changeHandler = useCallback(
    (text: string) => {
      dispatch(textWidgetSetupTextChangeThunk(text));
    },
    [dispatch],
  );

  return (
    <Editor
      content={text}
      onChange={changeHandler}
      minHeight={70}
      placeholder="Write anything you want here..."
      commands={textWidgetEditorCommands}
    />
  );
};

export const textWidgetEditorCommands: CommandName[] = [
  "heading",
  "bold",
  "italic",
  "strike",
  "code",
  "codeBlock",
  "bulletList",
  "orderedList",
  "blockquote",
  "link",
  "underline",
  "textAlign",
  "color",
];
