import { CSSProperties } from "react";
import { Box, Skeleton } from "@mui/material";

export const DotsLoading = () => {
  return (
    <Box sx={boxStyles}>
      <style>{keyframesStyle}</style>
      <Skeleton
        variant="rectangular"
        width={60}
        height={35}
        sx={{ borderRadius: "8px" }}
      />

      <div style={loaderStyle}></div>
    </Box>
  );
};

const keyframesStyle = `
    @keyframes dots {
      0% { box-shadow: 6.5px 0 currentColor, -6.5px 0 currentColor; }
      33% { box-shadow: 6.5x 0 currentColor, -6.5px 0 transparent; }
      66% { box-shadow: 6.5px 0 transparent, -6.5px 0 currentColor; }
      100% { box-shadow: 6.5px 0 transparent, -6.5px 0 transparent; }
    }
  `;

const loaderStyle: CSSProperties = {
  width: "4.5px",
  height: "4.5px",
  borderRadius: "50%",
  animation: "dots 1s infinite linear alternate",
  position: "absolute",
  backgroundColor: "currentColor",
  boxShadow: "6.5px 0 currentColor, -6.5px 0 currentColor",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const boxStyles = {
  position: "relative",
  display: "inline-block",
  width: 60,
  height: 35,
};
