import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetCoverageVisualization } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";

import { setWidgetSetup } from "../../../../dashboardsSlice";
import { coverageWidgetSetupSelector } from "../../../../selectors/setups/coverage/coverageWidgetSetupSelector";

export const coverageWidgetVisualizationChangeThunk = createAsyncThunk(
  "dashboard/coverageWidgetVisualizationChange",
  async (
    visualization: WidgetCoverageVisualization,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const existingSetup = coverageWidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }

    dispatch(
      setWidgetSetup({
        ...existingSetup,
        visualization: {
          ...existingSetup.visualization,
          ...visualization,
        },
      }),
    );
  },
);
