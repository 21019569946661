import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { currentDashboardIdSelector } from "../../../../selectors/dashboard/currentDashboardIdSelector";
import { coverageWidgetSetupSelector } from "../../../../selectors/setups/coverage/coverageWidgetSetupSelector";
import { DashboardWidgetCoverageRequest } from "../../../../../../services/cloudchipr.api";
import { buildWidgetThunk } from "../../common/buildWidgetThunk";
import { enqueueSnackbarErrorAlert } from "../../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { getCoverageWidgetDataByWidgetIdThunk } from "../getCoverageWidgetDataByWidgetIdThunk";

export const buildCoverageWidgetThunk = createAsyncThunk(
  "dashboards/buildCoverageWidget",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const dashboardId = currentDashboardIdSelector(state);
    const setup = coverageWidgetSetupSelector(state);

    if (!dashboardId || !setup?.providerOrganisationId) {
      return;
    }

    const widget: DashboardWidgetCoverageRequest = {
      name: setup.name,
      provider_organisation_id: setup.providerOrganisationId,
      grouping: setup.grouping,
      type: "coverage",
      commitment_type: setup.commitmentType,
      dates: setup.dates,
      visualization: setup.visualization,
      aggregation: setup.aggregation,
      trend_type: setup.trendType,
      layout: null,
    };

    try {
      const response = await dispatch(
        buildWidgetThunk({
          widgetId: setup?.id,
          widget,
        }),
      ).unwrap();

      if (response && setup?.id) {
        await dispatch(getCoverageWidgetDataByWidgetIdThunk(response.id));
      }
      return response?.id;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
