import { FC } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../store/hooks";
import { resourceDetailsTargetResourceSelector } from "../../../../../../store/resource-details/selectors/target-resource/resourceDetailsTargetResourceSelector";
import { KeyValueSplitChip } from "../../../../../common/chips/KeyValueSplitChip";

export const ResourceDetailsHeaderK8SMetaBar: FC = () => {
  const resource = useAppSelector(resourceDetailsTargetResourceSelector);

  return (
    <Stack gap={1} direction="row" px={1.5} py={1} flexWrap="wrap">
      {resource?.providerMetadata?.k8s?.headerChips?.map((chip) => {
        return (
          <KeyValueSplitChip
            key={chip.title}
            title={chip.title}
            value={chip.value}
          />
        );
      })}
    </Stack>
  );
};
