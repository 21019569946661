import { FC, Fragment } from "react";
import { NoCoverage } from "./NoCoverage";
import { CoverageWidgetContentCosts } from "./CoverageWidgetContentCosts";
import { CoverageWidgetNumeralView } from "./CoverageWidgetNumeralView";
import { CoverageWidgetContentTableView } from "./CoverageWidgetContentTableView";
import { CoverageWidgetContentChartView } from "./CoverageWidgetContentChartView";
import { useAppSelector } from "../../../../../../../store/hooks";
import { coverageChangesWidgetNotFoundSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageChangesWidgetNotFoundSelector";
import { coverageWidgetVisualizationChartTypeSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetVisualizationChartTypeSelector";
import { coverageWidgetHasAggregationSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetHasAggregationSelector";
import { coverageProviderOrganizationIdSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageProviderOrganizationIdSelector";
import { coverageWidgetByWidgetIdLoadingSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetByWidgetIdLoadingSelector";
import { coverageWidgetByWidgetIdEmptyDataSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetByWidgetIdEmptyDataSelector";
import { DashboardNotFoundWidget } from "../../common/DashboardNotFoundWidget";
import { coverageWidgetByWidgetIdGroupsSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetByWidgetIdGroupsSelector";

interface CoverageWidgetContentProps {
  widgetId: string;
}

export const CoverageWidgetContent: FC<CoverageWidgetContentProps> = ({
  widgetId,
}) => {
  const widgetNotFound = useAppSelector((state) =>
    coverageChangesWidgetNotFoundSelector(state, widgetId),
  );
  const chartType = useAppSelector((state) =>
    coverageWidgetVisualizationChartTypeSelector(state, widgetId),
  );
  const groups = useAppSelector((state) =>
    coverageWidgetByWidgetIdGroupsSelector(state, widgetId),
  );
  const hasAggregations = useAppSelector((state) =>
    coverageWidgetHasAggregationSelector(state, widgetId),
  );
  const providerOrgId = useAppSelector((state) =>
    coverageProviderOrganizationIdSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    coverageWidgetByWidgetIdLoadingSelector(state, widgetId),
  );

  const emptyData = useAppSelector((state) =>
    coverageWidgetByWidgetIdEmptyDataSelector(state, widgetId),
  );

  if (widgetNotFound) {
    return (
      <DashboardNotFoundWidget
        name={providerOrgId ?? ""}
        label="organization"
      />
    );
  }

  if (emptyData && !loading) {
    return <NoCoverage />;
  }

  return (
    <Fragment>
      {hasAggregations && <CoverageWidgetContentCosts widgetId={widgetId} />}

      {chartType === "numeral" && (
        <CoverageWidgetNumeralView groups={groups} loading={false} />
      )}

      {chartType === "table" && (
        <CoverageWidgetContentTableView widgetId={widgetId} />
      )}

      {!["numeral", "table"].includes(chartType ?? "stack") && (
        <CoverageWidgetContentChartView widgetId={widgetId} />
      )}
    </Fragment>
  );
};
