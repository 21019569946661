import { FC, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import { Cube } from "./Cube";
import { money } from "../../../../../utils/numeral/money";

type ChartsTooltipContentItemProps = {
  mainColor?: string;
  data: Payload<any, any>;
  tooltipValueFormatter?(data: Payload<any, any>): ReactNode;
  tooltipValueNameFormatter?(name: string): ReactNode;
};

export const ChartsTooltipContentItem: FC<ChartsTooltipContentItemProps> = ({
  mainColor,
  tooltipValueFormatter,
  tooltipValueNameFormatter,
  data,
}) => {
  const name = data.name;
  const value = data.value;
  const color = data.color ?? mainColor;

  const formattedName =
    name.length > 128
      ? `${name.substring(0, 80)}...${name.substring(
          name.length - 44,
          name.length,
        )}`
      : name;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
    >
      <Stack
        direction="row"
        py={0.5}
        overflow="hidden"
        minWidth={200}
        maxWidth={350}
      >
        <Cube radius={0.5} color={color} height="auto" width={3} />

        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ wordBreak: "break-all" }}
        >
          {tooltipValueNameFormatter
            ? tooltipValueNameFormatter(name)
            : formattedName}
        </Typography>
      </Stack>
      <Typography ml={2} variant="caption" color="text.primary">
        {tooltipValueFormatter ? tooltipValueFormatter(data) : money(value)}
      </Typography>
    </Stack>
  );
};
