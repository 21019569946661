import { generateKubernetesExportedResourceDataByColumns } from "./generateKubernetesExportedResourceDataByColumns";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { ExportedResourceDataOptions } from "../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/types";
import { getKubernetesResourceTypeColumns } from "../data-grid/columns-setup/getKubernetesResourceTypeColumns";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import {
  KubernetesResourceDataType,
  KubernetesResourceItemType,
} from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/utils/types/types";

export const generateKubernetesExportedResourceData = (
  resourceType: ResourceType,
  resourceData: KubernetesResourceDataType[],
  disabledColumns: string[],
  options?: ExportedResourceDataOptions,
) => {
  const columns = getKubernetesResourceTypeColumns(
    resourceType,
    disabledColumns,
  ) as ColumnSetupType<KubernetesResourceItemType>[];

  return generateKubernetesExportedResourceDataByColumns(
    resourceData,
    columns,
    options,
  );
};
