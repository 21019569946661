import { FC } from "react";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { taskManagementTaskIdSelector } from "../../../../../../../../../store/task-management/selectors/form/properties/taskManagementTaskIdSelector";
import { getTaskActivitiesLoadingSelector } from "../../../../../../../../../store/task-management/selectors/activiities/getTaskActivitiesLoadingSelector";

export const TaskCreationDrawerActivitiesHeader: FC = () => {
  const taskId = useAppSelector(taskManagementTaskIdSelector);

  const loading = useAppSelector((state) =>
    getTaskActivitiesLoadingSelector(state, taskId ?? ""),
  );

  if (!taskId) {
    return;
  }

  return (
    <Stack width="100%" position="relative">
      <Stack p={2} width="100%">
        <Typography variant="body1" fontWeight="medium">
          Activity
        </Typography>
      </Stack>
      {loading && (
        <Box position="absolute" bottom={0} left={0} right={0}>
          <LinearProgress />
        </Box>
      )}
    </Stack>
  );
};
