import { tasksListDataByIdSelector } from "./tasksListDataByIdSelector";
import { RootState } from "../../../store";
import {
  PatchUsersMeOrganisationsCurrentTasksApiArg,
  Task,
} from "../../../../services/cloudchipr.api";
import { generatePayloadFromTask } from "../utils/helpers/generatePayloadFromTask";

export const tasksUpdatePayloadDataByIdSelector = (
  state: RootState,
  taskId: string,
): PatchUsersMeOrganisationsCurrentTasksApiArg["body"] => {
  const task: Task | undefined = tasksListDataByIdSelector(state, taskId);

  return {
    ...generatePayloadFromTask(task),
    task_ids: [taskId],
  };
};
