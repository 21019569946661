import { FC } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CloseIcon from "@mui/icons-material/Close";

interface BillingExplorerExplainWithAiPanelHeaderProps {
  onClose(): void;
}

export const BillingExplorerExplainWithAiPanelHeader: FC<
  BillingExplorerExplainWithAiPanelHeaderProps
> = ({ onClose }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py={1.5}
      position="sticky"
      top={0}
      bgcolor="white"
      zIndex={(theme) => theme.zIndex.drawer}
    >
      <Stack direction="row" spacing={1}>
        <AutoAwesomeIcon fontSize="small" color="primary" />
        <Typography variant="body1">Cloudchipr AI</Typography>
      </Stack>
      <IconButton onClick={onClose} sx={{ p: 0.5 }}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
