import { FC, useCallback } from "react";
import { Chip, MenuItem, Stack } from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

interface FloatingPropertyActionItemProps {
  id?: string;
  name?: string;
  color?: string;
  onClick(id: string | null): void;
}

export const FloatingPropertyActionItem: FC<
  FloatingPropertyActionItemProps
> = ({ id, name, color, onClick }) => {
  const clickHandler = useCallback(() => {
    onClick(id || null);
  }, [id, onClick]);

  return (
    <MenuItem value={id} onClick={clickHandler}>
      {id ? (
        <Chip
          size="small"
          variant="filled"
          label={<TypographyWithTooltip title={name} variant="inherit" />}
          sx={{ bgcolor: color, color: "white" }}
        />
      ) : (
        <Stack justifyContent="center">
          <HorizontalRuleIcon color="action" fontSize="small" />
        </Stack>
      )}
    </MenuItem>
  );
};
