import { FilterFn } from "@tanstack/react-table";
import { normalizeString } from "./common";
import { Tags } from "../../../../services/cloudchipr.api";
import { isSmartTag } from "../../../../components/pages/common/filters/utils/helpers/isSmartTag";
import { concatTagsForFiltering } from "../helpers";

export const tagFilter: FilterFn<any> = (row, columnId, value) => {
  const tags = (row.original?.tags ??
    row.original?.resource?.tags ??
    row.original?.resource?.labels) as Tags;

  if (!tags) {
    return false;
  }

  return commonTagFilter(columnId, value, tags);
};

export const commonTagFilter = (
  columnId: string,
  value: string,
  data: Tags,
) => {
  let tags = data;

  if (columnId === "smart_tags") {
    tags = tags.filter((tag) => isSmartTag(tag.key));
  }

  if (!tags || typeof tags !== "object") {
    return false;
  }

  const searchData = concatTagsForFiltering(tags);

  return searchData.some((tag) =>
    normalizeString(tag).includes(normalizeString(value)),
  );
};
