import { BillingSummaryWidgetFilterType } from "../../../../../utils/constants/billingSummarySetupDefaultData";

export const billingSummaryTitlesByFilter = new Map<
  BillingSummaryWidgetFilterType,
  string
>([
  ["total_cost", "Total Cost"],
  ["previous_period_total_cost", "Previous Period Cost"],
  ["average_monthly_cost_details", "Average Monthly Cost"],
  ["average_daily_cost_details", "Average Daily Cost"],
  ["forecasted_month_end_cost", "Forecasted Month-end Cost"],
  ["forecasted_quarter_end_cost", "Forecasted Quarter-end Cost"],
  ["live_usage_total_cost", "Live Filtered"],
]);
