import { FC, useCallback } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { SavingsPlanCoverageGrouping } from "../../../../../../../services/cloudchipr.api";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerCoverageGroupingSelector";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { getDrawerDataGridPossibleGroupings } from "../../../utils/constants/drawerDataGridPossibleGroupings";
import { commitmentsTypeTabSelector } from "../../../../../../../store/commitments/selectors/common/commitmentsTypeTabSelector";
import { commitmentsCurrentOrgProviderSelector } from "../../../../../../../store/commitments/selectors/common/commitmentsCurrentOrgProviderSelector";
import { changeCommitmentDrawerDataGridGroupingThunk } from "../../../../../../../store/commitments/thunks/common/changeCommitmentDrawerDataGridGroupingThunk";

export const CommitmentsDrawerDataGridGrouping: FC = () => {
  const dispatch = useAppDispatch();
  const type = useAppSelector(commitmentsDetailsDrawerTargetTypeSelector);
  const grouping = useAppSelector(
    commitmentsDetailsDrawerCoverageGroupingSelector,
  );
  const activeTab = useAppSelector(commitmentsTypeTabSelector);
  const provider = useAppSelector(commitmentsCurrentOrgProviderSelector);
  const tabChangeHandler = useCallback(
    (_: unknown, grouping: SavingsPlanCoverageGrouping) => {
      if (!grouping) {
        return;
      }
      dispatch(changeCommitmentDrawerDataGridGroupingThunk(grouping));
    },
    [dispatch],
  );

  if (!type) {
    return null;
  }

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      value={grouping}
      color="primary"
      onChange={tabChangeHandler}
    >
      {provider &&
        getDrawerDataGridPossibleGroupings(provider)?.[type]?.map(
          ({ value, spLabel, riLabel }) => {
            return (
              <ToggleButton key={value} value={value} sx={toggleButtonSx}>
                {activeTab === "savings_plan" ? spLabel : riLabel}
              </ToggleButton>
            );
          },
        )}
    </ToggleButtonGroup>
  );
};

const toggleButtonSx = {
  textTransform: "none",
  borderRadius: 2,
  fontSize: 14,
  px: 2,
};
