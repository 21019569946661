import { FC } from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import NoFilteredDataImage from "../../../../../assets/images/no-filtered-data.png";

export const NoFilteredDataOverlay: FC = () => {
  return (
    <Stack pt={12} spacing={4} alignItems="center" justifyContent="center">
      <img src={NoFilteredDataImage} alt="NoFilteredData" width={140} />
      <Typography variant="h6" fontWeight="normal">
        No matching results found.
      </Typography>
    </Stack>
  );
};
