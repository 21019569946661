import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { BillingExplorerWidgetAdvancedSettingsTrendSetting } from "./BillingExplorerWidgetAdvancedSettingsTrendSetting";
import AdvancedSettingsIcon from "../../../../../../../../../../../assets/images/icons/advanced_settings_icon.svg";
import { WidgetConfigSection } from "../../../../common/widget-configurations/WidgetConfigSection";
import { WidgetForecastSelect } from "../../../../common/toolbar/WidgetForecastSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { billingExplorerSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { billingExplorerWidgetSetupForecastedOptionsSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupForecastedOptionsSelector";
import { billingExplorerForecastChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerForecastChangeThunk";

export const BillingExplorerWidgetAdvancedSettingsSection: FC = () => {
  const dispatch = useAppDispatch();
  const forecastOption = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("forecastOption"),
  );

  const forecastedCostsOptions = useAppSelector(
    billingExplorerWidgetSetupForecastedOptionsSelector,
  );

  const forecastChangeHandler = useCallback(
    (event: any) => {
      dispatch(billingExplorerForecastChangeThunk(event.target.value));
    },
    [dispatch],
  );

  return (
    <WidgetConfigSection
      title="Advanced Settings"
      icon={<img src={AdvancedSettingsIcon} alt="Advanced Settings Icon" />}
    >
      <Stack spacing={1.5}>
        <WidgetForecastSelect
          forecast={forecastOption}
          onChange={forecastChangeHandler}
          options={forecastedCostsOptions}
        />
        <BillingExplorerWidgetAdvancedSettingsTrendSetting />
      </Stack>
    </WidgetConfigSection>
  );
};
