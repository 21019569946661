import { ColDef, type ICellRendererParams } from "ag-grid-community";
import { OpportunityTarget } from "../../../../../../../../../../../../services/cloudchipr.api";
import { TaskTargetTableIdentifierRow } from "../../common/TaskTargetTableIdentifierRow";
import { getResourceTypeName } from "../../../../../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { TaskTargetTableRegionRow } from "../../common/TaskTargetTableRegionRow";
import { taskTargetAccountFilterText } from "../../../../../../../../../../task-management/utils/helpers/quick-filter-text-functions/taskTargetAccountFilterText";
import { targetAccountComparatorFn } from "../../../../../../../../../../task-management/utils/helpers/comparator-functions/targetAccountComparatorFn";
import { cloudProviderComparatorFn } from "../../../../../../../../utils/helpers/comparator-fns/cloudProviderComparatorFn";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../../ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { SavingsOpportunityImplementationEffortCellRenderer } from "../../../../../../../../../../savings-opportunities/components/cell-renderers/SavingsOpportunityImplementationEffortCellRenderer";
import { SavingsOpportunityTagsCellRenderer } from "../../../../../../../../../../savings-opportunities/components/cell-renderers/SavingsOpportunityTagsCellRenderer";
import { SavingsOpportunityCloudProviderCellRenderer } from "../../../../../../../../../../savings-opportunities/components/cell-renderers/SavingsOpportunityCloudProviderCellRenderer";
import { SavingsOpportunityAccountCellRenderer } from "../../../../../../../../../../savings-opportunities/components/cell-renderers/SavingsOpportunityAccountCellRenderer";
import { SavingsOpportunityActionTypeCellRenderer } from "../../../../../../../../../../savings-opportunities/components/cell-renderers/SavingsOpportunityActionTypeCellRenderer";
import { getSimpleTypographyCellRenderer } from "../../../../../../../../../../savings-opportunities/components/cell-renderers/getSimpleTypographyCellRenderer";
import { SavingsOpportunityBasedOnPastCellRenderer } from "../../../../../../../../../../savings-opportunities/components/cell-renderers/SavingsOpportunityBasedOnPastCellRenderer";
import { money } from "../../../../../../../../../../../../utils/numeral/money";

export const targetMultipleOpportunitiesGridColumns: ColDef[] = [
  {
    field: "provider_identifier",
    unSortIcon: true,
    minWidth: 300,
    headerName: "Name",
    cellRenderer: ({ data }: ICellRendererParams<OpportunityTarget>) => {
      if (!data) {
        return null;
      }

      return (
        <TaskTargetTableIdentifierRow
          cloudLink
          target={data}
          copyButton="hover"
          liveUsageLink="hover"
        />
      );
    },
  },

  {
    field: "opportunity_type",
    unSortIcon: true,
    headerName: "Opportunity Type",
    cellRenderer: SavingsOpportunityActionTypeCellRenderer,
  },

  {
    field: "resource_type",
    unSortIcon: true,
    headerName: "Resource Type",
    cellRenderer: ({ data }: ICellRendererParams<OpportunityTarget>) => {
      if (!data?.resource_type) {
        return null;
      }

      return getResourceTypeName(data.resource_type, {
        singular: true,
        type: "long",
      });
    },
  },

  {
    field: "description",
    unSortIcon: true,
    headerName: "Description",
    cellRenderer: getSimpleTypographyCellRenderer("description"),
  },

  {
    field: "current_type",
    unSortIcon: true,
    headerName: "Current Type",
    cellRenderer: ({ data }: ICellRendererParams<OpportunityTarget>) => {
      if (!data) {
        return null;
      }

      return data.current_type;
    },
  },

  {
    field: "recommended_type",
    unSortIcon: true,
    headerName: "Recommended Type",
    cellRenderer: getSimpleTypographyCellRenderer("recommended_type"),
  },

  {
    colId: "account",
    headerName: "Account",
    getQuickFilterText: taskTargetAccountFilterText,
    comparator: targetAccountComparatorFn,
    cellRenderer: SavingsOpportunityAccountCellRenderer,
  },

  {
    colId: "provider",
    headerName: "Cloud Provider",
    getQuickFilterText: taskTargetAccountFilterText,
    comparator: cloudProviderComparatorFn,
    cellRenderer: SavingsOpportunityCloudProviderCellRenderer,
  },

  {
    field: "price_per_month",
    unSortIcon: true,
    headerName: "Current Monthly Price",
    cellRenderer: ({ data }: ICellRendererParams<OpportunityTarget>) => {
      if (!data) {
        return null;
      }

      return money(data.price_per_month);
    },
  },
  {
    field: "estimated_monthly_price",
    unSortIcon: true,
    headerName: "Estimated Monthly Price",
    cellRenderer: ({ data }: ICellRendererParams<OpportunityTarget>) => {
      if (!data) {
        return null;
      }

      return money(data.estimated_monthly_price);
    },
  },
  {
    field: "monthly_savings",
    unSortIcon: true,
    headerName: "Monthly Savings",
    cellRenderer: ({ data }: ICellRendererParams<OpportunityTarget>) => {
      if (!data) {
        return null;
      }

      return money(data.monthly_savings);
    },
  },

  {
    field: "implementation_effort",
    unSortIcon: true,
    headerName: "Implementation Effort",
    cellRenderer: SavingsOpportunityImplementationEffortCellRenderer,
  },

  {
    field: "based_on_past",
    unSortIcon: true,
    headerName: "Based On Past",
    cellRenderer: SavingsOpportunityBasedOnPastCellRenderer,
  },

  {
    field: "region",
    unSortIcon: true,
    headerName: "Region",
    minWidth: 100,
    cellRenderer: ({ data }: ICellRendererParams<OpportunityTarget>) => (
      <TaskTargetTableRegionRow region={data?.region} />
    ),
  },

  {
    field: "tags",
    unSortIcon: true,
    headerName: "Tags",
    cellRenderer: SavingsOpportunityTagsCellRenderer,
  },

  agGridEmptyColumnToFitEmptySpace,
];
