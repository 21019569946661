import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getTextWidgetDataByWidgetIdThunk = createAsyncThunk(
  "dashboards/getTextWidgetDataByWidgetId",
  async (widgetId: string, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentWidgetsTextByWidgetId } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentWidgetsTextByWidgetId.initiate(
          {
            widgetId,
          },
          {
            forceRefetch: true,
          },
        ),
      );
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
