import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { utilizationAndCoverageDrawerDataGridColumnDefs } from "./columns/utilizationAndCoverageDrawerDataGridColumnDefs";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { utilizationAndCoverageDetailsCoverageDataSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/data/utilizationAndCoverageDetailsCoverageDataSelector";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerCoverageGroupingSelector";
import { utilizationAndCoverageDetailsLoadingSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/data/utilizationAndCoverageDetailsLoadingSelector";
import { CommitmentsGridLoadingLayer } from "../../../../../common/components/data-grid/CommitmentsGridLoadingLayer";
import { DataViewTab } from "../../../../../../../../store/commitments/utils/types/types";
import { CommitmentsDrawerAGGridToolbar } from "../../../../../common/components/drawer/data-grid/CommitmentsDrawerAGGridToolbar";

import { CommitmentsAgGrid } from "../../../../../common/components/CommitmentsAgGrid";

interface UtilizationAndCoverageDrawerDataGridProps {
  viewTab: DataViewTab;
}

export const UtilizationAndCoverageDrawerDataGrid: FC<
  UtilizationAndCoverageDrawerDataGridProps
> = ({ viewTab }) => {
  const data = useAppSelector(
    utilizationAndCoverageDetailsCoverageDataSelector,
  );
  const loading = useAppSelector(utilizationAndCoverageDetailsLoadingSelector);
  const grouping = useAppSelector(
    commitmentsDetailsDrawerCoverageGroupingSelector,
  );

  const columnDefs = useMemo(() => {
    return (
      utilizationAndCoverageDrawerDataGridColumnDefs?.[viewTab]?.[grouping] ??
      []
    );
  }, [grouping, viewTab]);

  if (!columnDefs) {
    return null;
  }

  return (
    <Stack mt={1.5} position="relative" zIndex={1} height={400}>
      {loading && <CommitmentsGridLoadingLayer />}

      <CommitmentsAgGrid
        Toolbar={CommitmentsDrawerAGGridToolbar}
        loading={loading}
        columnDefs={columnDefs}
        data={data}
        grouping={grouping}
        localStorageUniqueKey={`savingsPlanDataGrid-${viewTab}`}
      />
    </Stack>
  );
};
