import { FC, useCallback, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ColDef, GridApi } from "ag-grid-community";
import { useEffectOnceWhen, usePreviousDifferent } from "rooks";
import { AgGridReactProps } from "ag-grid-react";
import { SavingsPlanCoverageGrouping } from "../../../../../services/cloudchipr.api";
import { AgDataGrid } from "../../../common/ag-grid/AgDataGrid";
import {
  commitmentsGridDefaultColDef,
  commitmentsGridWrapperStyles,
} from "../utils/constants/gridConstants";
import { coverageGroupingNames } from "../utils/constants/labels";
import { setCommitmentsTotals } from "../../../../../store/commitments/commitmentsSlice";
import { commitmentsTotalsFromGridApi } from "../utils/helpers/commitmentsTotalsFromGridApi";
import { useAppDispatch } from "../../../../../store/hooks";

export type CommitmentsToolbar = FC<{ gridApi: GridApi | null }>;

interface CommitmentsAgGridProps {
  data: any[] | undefined;
  localStorageUniqueKey: string;
  grouping?: SavingsPlanCoverageGrouping;
  Toolbar?: CommitmentsToolbar;
  columnDefs: ColDef[];
  loading?: boolean;
  overrideDefaultColDef?: AgGridReactProps["defaultColDef"];
  noRowOverlayComponent?: AgGridReactProps["noRowsOverlayComponent"];
}

export const CommitmentsAgGrid: FC<CommitmentsAgGridProps> = ({
  loading,
  data,
  Toolbar,
  localStorageUniqueKey,
  grouping,
  columnDefs,
  overrideDefaultColDef,
  noRowOverlayComponent: customNoRowOverlayComponent,
}) => {
  const dispatch = useAppDispatch();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const prevData = usePreviousDifferent(data);
  const gridData = data ?? prevData;

  const configToRememberStateInStorage = {
    uniqueNameAsKey: `${localStorageUniqueKey}-${grouping}`,
  };

  const noRowOverlayComponent = useCallback(() => {
    if (!grouping) {
      return;
    }

    return (
      <Typography variant="body2">
        No {coverageGroupingNames[grouping]} found
      </Typography>
    );
  }, [grouping]);

  const onFilterChange = useCallback(() => {
    const totals = commitmentsTotalsFromGridApi(gridApi);

    if (!totals) {
      return;
    }

    dispatch(setCommitmentsTotals(totals));
  }, [gridApi, dispatch]);

  useEffectOnceWhen(() => {
    const totals = commitmentsTotalsFromGridApi(gridApi);

    if (!totals) {
      return;
    }

    dispatch(setCommitmentsTotals(totals));
  }, !!gridApi);

  return (
    <Stack minHeight={400} height="100%">
      {Toolbar && <Toolbar gridApi={gridApi} />}

      <AgDataGrid
        rowHeight={60}
        headerHeight={60}
        rowData={gridData ?? []}
        columnDefs={columnDefs}
        defaultColDef={{
          ...commitmentsGridDefaultColDef,
          ...overrideDefaultColDef,
        }}
        onFilterChanged={onFilterChange}
        wrapperStyles={commitmentsGridWrapperStyles}
        gridApiSetter={setGridApi}
        loading={loading}
        noRowsOverlayComponent={
          customNoRowOverlayComponent ?? noRowOverlayComponent
        }
        configToRememberStateInStorage={configToRememberStateInStorage}
      />
    </Stack>
  );
};
