import { FC, useCallback } from "react";
import { Typography } from "@mui/material";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { DeleteConfirmationDialog } from "../../../../../../common/modals/DeleteConfirmationDialog";
import { useViewsHierarchyContext } from "../../ViewsHierarchyProvider";

export interface HierarchyViewDeleteActionGlobalProps {
  loading: boolean;
  onDelete(viewId: string): Promise<void>;
}

interface HierarchyViewDeleteActionDialogProps {
  open: boolean;
  viewId: string;
  viewName: string;
  onClose(): void;
}

export const HierarchyViewDeleteActionDialog: FC<
  HierarchyViewDeleteActionDialogProps
> = ({ open, viewName, viewId, onClose }) => {
  const { loading, onDelete } = useViewsHierarchyContext().actions.viewDelete;

  const deleteHandler = useCallback(async () => {
    await onDelete(viewId);
    onClose();

    const snackbarId: SnackbarKey = enqueueSnackbar(
      "View successfully deleted.",
      {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
          onClose: () => closeSnackbar(snackbarId),
        },
      },
    );
  }, [onDelete, viewId, onClose]);

  return (
    <DeleteConfirmationDialog
      open={open}
      onClose={onClose}
      onDelete={deleteHandler}
      loading={loading}
      title="Delete View?"
      content={
        <Typography>
          Please confirm if you want to delete{" "}
          <Typography component="span" fontWeight="medium">
            {viewName}
          </Typography>{" "}
          view.
        </Typography>
      }
    />
  );
};
