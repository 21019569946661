import { FC } from "react";
import { Stack } from "@mui/material";
import { useDidMount } from "rooks";
import { DashboardAllItemsHeader } from "./components/header/DashbaordAllItemsHeader";
import { DashboardAllItemsTable } from "./components/table/DashboardAllItemsTable";
import { HierarchyAllItemsFiltersProvider } from "../common/hierarchy-all-items/components/HierarchyAllItemsFiltersProvider";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { dashboardHierarchyVisibilitiesCreatedByDataSelector } from "../../../store/dashboards/selectors/dashboard-hierarchy-visibility/dashboardHierarchyMixedVisibilitiesCreatedByDataSelector";
import { getDashboardsVisibilityHierarchyThunk } from "../../../store/dashboards/thunks/dashboard-hierarchy-visibility/getDashboardsVisibilityHierarchyThunk";

export const DashboardAllItems: FC = () => {
  const dispatch = useAppDispatch();

  const createdByes = useAppSelector(
    dashboardHierarchyVisibilitiesCreatedByDataSelector,
  );

  useDidMount(() => {
    dispatch(getDashboardsVisibilityHierarchyThunk());
  });

  return (
    <Stack bgcolor="white" height="100dvh" overflow="hidden">
      <DashboardAllItemsHeader />

      <HierarchyAllItemsFiltersProvider createdByOptions={createdByes}>
        <DashboardAllItemsTable />
      </HierarchyAllItemsFiltersProvider>
    </Stack>
  );
};
