import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AccountId,
  ResourceType,
  ResourceFilters,
} from "../../services/cloudchipr.api";

export type AppliedFilterType = Record<ResourceType, ResourceFilters>;
type AppliedFiltersType = Record<AccountId, AppliedFilterType>;

interface FiltersInterface {
  appliedFilterSetId: string | null;
  filterChangedManually: boolean;
  allFiltersAreValid: boolean;
  appliedFilters: AppliedFiltersType;
}

const initialState: FiltersInterface = {
  appliedFilterSetId: null,
  filterChangedManually: false,
  allFiltersAreValid: true,
  appliedFilters: {},
};

// TODO: ReThink the overall functionality of filters.
// TODO: rename this slice and file/folder to `account-filters`, as this is only about accounts, and change our `filters-selection-nf` to `filters-selection`
export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setAppliedFilters: (
      state,
      action: PayloadAction<{
        accountId: string;
        filters: ResourceFilters;
      }>,
    ) => {
      const { accountId, filters } = action.payload;
      state.appliedFilters[accountId] = state.appliedFilters[accountId] || {};
      /* eslint-disable */
      // @ts-ignore todo: this is because of filter_groups type in the ResourceFilters
      state.appliedFilters[accountId][filters.type] = filters;
      /* eslint-enable */
    },
    setAppliedFilterSetId: (state, action: PayloadAction<string | null>) => {
      state.appliedFilterSetId = action.payload;
      state.filterChangedManually = !action.payload;
    },
    setFilterChangedManually: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      if (action.payload === undefined) {
        state.filterChangedManually = true;
      } else {
        state.filterChangedManually = action.payload;
      }
    },
    setAllFiltersAreValid: (state, action: PayloadAction<boolean>) => {
      state.allFiltersAreValid = action.payload;
    },
  },
});

export const {
  setAppliedFilterSetId,
  setFilterChangedManually,
  setAllFiltersAreValid,
  setAppliedFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;
