import { FC, Fragment } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useToggle } from "rooks";
import { KubernetesClusterAddDrawer } from "./drawer/KubernetesClusterAddDrawer";
import { useAppAbility } from "../../../../../../../../services/permissions";

export const KubernetesClusterAddButton: FC = () => {
  const { cannot } = useAppAbility();
  const [drawerOpen, toggleDrawerOpen] = useToggle();

  const canNotCreateAccount = cannot("create", "account");

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={toggleDrawerOpen}
        disabled={canNotCreateAccount}
        sx={{ textTransform: "none" }}
        startIcon={<AddIcon />}
      >
        Add Cluster
      </Button>

      <KubernetesClusterAddDrawer
        open={drawerOpen}
        onClose={toggleDrawerOpen}
      />
    </Fragment>
  );
};
