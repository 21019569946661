import { FC } from "react";
import { Stack } from "@mui/material";
import { ColumnsInnerHeaderComponent } from "../../../../common/ag-grid/components/ColumnsInnerHeaderComponent";
import { money } from "../../../../../../utils/numeral/money";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { Task } from "../../../../../../services/cloudchipr.api";
import { useTotalInHeaderCalculator } from "../../../../common/ag-grid/utils/hooks/useTotalInHeaderCalculator.hook";
import type { CustomHeaderProps } from "ag-grid-react";

export const MonthlyPriceInnerHeader: FC<CustomHeaderProps> = (props) => {
  const total = useTotalInHeaderCalculator<Task>(
    props.api,
    (data) => +(data?.target?.price_per_month ?? 0),
  );

  return (
    <Stack position="relative">
      <ColumnsInnerHeaderComponent {...props} />

      <TypographyWithTooltip title={money(total)} variant="caption" />
    </Stack>
  );
};
