import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { kubernetesClustersSelector } from "./kubernetesClustersSelector";

export const kubernetesClustersIdsSelector = createDraftSafeSelector(
  [kubernetesClustersSelector],
  (clusters) => {
    const clusterIds = clusters?.map((cluster) => cluster.id);

    if (!clusterIds) {
      return;
    }

    return clusterIds;
  },
);
