export const reOrderArrayByGivenSet = (arr: string[], set: Set<string>) => {
  const setArray = Array.from(set);

  const inSet: string[] = [];
  const notInSet: string[] = [];

  arr.forEach((item) => {
    if (set.has(item)) {
      inSet.push(item);
    } else {
      notInSet.push(item);
    }
  });

  const orderedInSet = setArray.filter((item) => inSet.includes(item));

  return [...orderedInSet, ...notInSet];
};
