import { FC, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Divider, Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { ResourceExplorerAppBar } from "./components/app-bar/ResourceExplorerAppBar";
import { ResourceExplorerCard } from "./components/resource-explorer-card/ResourceExplorerCard";
import { setAzureTagsFeatureToggleToStorage } from "./utils/helpers/azure-tags/setAzureTagsFeatureToggleToStorage";
import { BillingExplorerSideBarPanel } from "./components/sidebar/BillingExplorerSideBarPanel";
import { useAppSelector } from "../../../store/hooks";
import { resourceExplorerViewByIdLoadingSelector } from "../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { initiateResourceExplorerThunk } from "../../../store/resource-explorer/thunks/resource-explorer/initiateResourceExplorerThunk";
import { pathKeyInURL } from "../../../store/resource-explorer/thunks/widgets/path/setResourceExplorerPathThunk";
import { isBillingStatusesGettingFulfilledSelector } from "../../../store/common/selectors/billing-status/isBillingStatusesGettingFulfilledSelector";
import { resourceExplorerUnMountThunk } from "../../../store/resource-explorer/thunks/resource-explorer/resourceExplorerUnMountThunk";

export const ResourceExplorer: FC = () => {
  const { viewId = "" } = useParams<{
    viewId: string;
  }>();
  const enableAzureTags = useFlag("EnableAzureBillingExportProcessing");

  const [searchParams] = useSearchParams();
  const path = searchParams.get?.(pathKeyInURL);
  const dimensionId = searchParams.get("dimensionId") as string;
  const categoryId = searchParams.get("categoryId") as string;

  const dispatch = useDispatch();

  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );
  const isBillingStatusesGettingFulfilled = useAppSelector(
    isBillingStatusesGettingFulfilledSelector,
  );

  useEffect(() => {
    if (viewId) {
      dispatch(
        initiateResourceExplorerThunk({
          path,
          viewId,
          dimensionId,
          categoryId,
        }),
      );
    }

    return () => {
      dispatch(resourceExplorerUnMountThunk());
    };
  }, [viewId, dispatch, path, dimensionId, categoryId]);

  useEffect(() => {
    setAzureTagsFeatureToggleToStorage(enableAzureTags);
  }, [enableAzureTags]);

  if (!viewId || !isBillingStatusesGettingFulfilled) {
    return null;
  }

  return (
    <Stack height="100dvh" overflow="hidden">
      <ResourceExplorerAppBar viewId={viewId} />
      <PanelGroup
        direction="horizontal"
        autoSaveId="biling-explorer-page-navigation"
      >
        <Panel
          style={{ overflowY: "auto" }}
          id="billing-explorer-main-panel"
          order={1}
        >
          {!viewGettingLoading && <ResourceExplorerCard viewId={viewId} />}
        </Panel>

        <PanelResizeHandle>
          <Divider orientation="vertical" />
        </PanelResizeHandle>

        <BillingExplorerSideBarPanel />
      </PanelGroup>
    </Stack>
  );
};
