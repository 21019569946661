import { FC } from "react";
import { Stack } from "@mui/material";
import { AzureCommitmentsOverviewMetadataLeftSide } from "./components/AzureCommitmentsOverviewMetadataLeftSide";
import { AzureOverviewEffectiveSavingsRate } from "./components/AzureOverviewEffectiveSavingsRate";
import { AzureCommitmentsOverviewMetadataRightSide } from "./components/AzureCommitmentsOverviewMetadataRightSide";

export const AzureCommitmentsOverviewMetadata: FC = () => {
  return (
    <Stack
      spacing={1}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <Stack flex={1}>
        <AzureCommitmentsOverviewMetadataLeftSide />
      </Stack>
      <Stack flex={1}>
        <AzureOverviewEffectiveSavingsRate />
      </Stack>
      <Stack flex={1}>
        <AzureCommitmentsOverviewMetadataRightSide />
      </Stack>
    </Stack>
  );
};
