import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getTaskActivitiesThunk = createAsyncThunk(
  "taskManagement/getTaskActivities",
  async (taskId: string, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentTasksByTaskIdActivities } =
      cloudChiprApi.endpoints;

    if (!taskId) {
      return;
    }

    return await dispatch(
      getUsersMeOrganisationsCurrentTasksByTaskIdActivities.initiate(
        {
          taskId,
        },
        { forceRefetch: true },
      ),
    ).unwrap();
  },
);
