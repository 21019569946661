import moment from "moment/moment";

export const dueDateComparatorFn = (
  filterLocalDateAtMidnight: Date,
  cellValue: string,
  nodeA: any,
  nodeB: any,
  isDescending: boolean,
) => {
  if (!cellValue) {
    return isDescending ? 1 : -1;
  }

  if (!filterLocalDateAtMidnight) {
    return isDescending ? -1 : 1;
  }

  const current = moment(filterLocalDateAtMidnight);
  const next = moment(cellValue);

  if (current.isBefore(next)) {
    return -1;
  }

  if (current.isAfter(next)) {
    return 1;
  }

  return 0;
};
