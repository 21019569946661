import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetType } from "../../../../../services/cloudchipr.api";
import { scrollPageToBottom } from "../../../../../components/pages/utils/helpers/page-scrolling-functions";
import { duplicateBillingSummaryWidgetThunk } from "../billing-summary/actions/duplicateBillingSummaryWidgetThunk";
import { duplicateCostAnomaliesWidgetThunk } from "../cost-anomalies/actions/duplicateCostAnomaliesWidgetThunk";
import { duplicateCommitmentUtilizationWidgetThunk } from "../commitment-utilization/actions/duplicateCommitmentUtilizationWidgetThunk";
import { duplicateBillingExplorerWidgetThunk } from "../billing-explorer-widget/actions/duplicateBillingExplorerWidgetThunk";
import { duplicateTextWidgetThunk } from "../text-widget/actions/duplicateTextWidgetThunk";
import { duplicateCoverageWidgetThunk } from "../coverage-widget/actions/duplicateCoverageWidgetThunk";

interface EditWidgetThunkArgs {
  widgetId: string;
  widgetType: WidgetType;
  widgetName: string;
}

export const duplicateWidgetThunk = createAsyncThunk(
  "dashboards/duplicateWidgetThunk",
  async (
    { widgetId, widgetType, widgetName }: EditWidgetThunkArgs,
    { dispatch },
  ) => {
    let response;

    if (widgetType === "cost_breakdown") {
      response = await dispatch(
        duplicateBillingExplorerWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (widgetType === "largest_cost_changes") {
      response = await dispatch(
        duplicateCostAnomaliesWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (widgetType === "cost_and_usage_summary") {
      response = await dispatch(
        duplicateBillingSummaryWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (widgetType === "commitments_utilization") {
      response = await dispatch(
        duplicateCommitmentUtilizationWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (widgetType === "text") {
      response = await dispatch(
        duplicateTextWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (widgetType === "coverage") {
      response = await dispatch(
        duplicateCoverageWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (response?.id) {
      scrollPageToBottom();
    }
  },
);
