import { RootState } from "../../../../store";
import { widgetSetupSelector } from "../widgetSetupSelector";
import { CostAnomaliesSetupType } from "../../../utils/types/setups/costAnomaliesSetupType";

export const costAnomaliesSetupSelector = (
  state: RootState,
): CostAnomaliesSetupType | undefined => {
  if (!widgetSetupSelector(state)) {
    return;
  }
  return widgetSetupSelector(state) as CostAnomaliesSetupType;
};
