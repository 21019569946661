import { FC, useCallback, useEffect } from "react";

import { ReservationsRecommendationsDataGridFooter } from "./ReservationsRecommendationsDataGridFooter";
import { ReservationsRecommendationsAGGridToolbar } from "./data-grid/ReservationsRecommendationsAGGridToolbar";
import { reservationsRecommendationsGridColumnsDefs } from "./data-grid/reservationsRecommendationsGridColumnsDefs";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { commitmentsCurrentOrgIdSelector } from "../../../../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { getReservationsRecommendationsThunk } from "../../../../../../../store/commitments/thunks/recommendations/reservations/getReservationsRecommendationsThunk";
import { reservationsRecommendationsFilteredDataSelector } from "../../../../../../../store/commitments/selectors/recommendations/reservations/reservationsRecommendationsFilteredDataSelector";
import { reservationsRecommendationsLoadingSelector } from "../../../../../../../store/commitments/selectors/recommendations/reservations/reservationsRecommendationsLoadingSelector";
import { setCommitmentsRecommendationsFilters } from "../../../../../../../store/commitments/commitmentsSlice";

import { setReservationsRecommendationsInitialFiltersByPotentialSavingsThunk } from "../../../../../../../store/commitments/thunks/recommendations/reservations/setReservationsRecommendationsInitialFiltersByPotentialSavingsThunk";
import {
  CommitmentsAgGrid,
  CommitmentsToolbar,
} from "../../../../common/components/CommitmentsAgGrid";

interface ReservationsRecommendationsDataGridProps {
  overview?: boolean;
}

export const ReservationsRecommendationsDataGrid: FC<
  ReservationsRecommendationsDataGridProps
> = ({ overview }) => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(reservationsRecommendationsFilteredDataSelector);
  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);
  const loading = useAppSelector(reservationsRecommendationsLoadingSelector);

  const empty = !loading && !data?.length;

  const noRowOverlayComponent = useCallback(() => {
    if (empty) {
      return <ReservationsRecommendationsDataGridFooter option="emptyData" />;
    }

    if (data?.length || loading) {
      return null;
    }

    return (
      <ReservationsRecommendationsDataGridFooter option="noFilteredData" />
    );
  }, [data?.length, loading, empty]);

  const toolbar: CommitmentsToolbar = useCallback(
    ({ gridApi }) => (
      <ReservationsRecommendationsAGGridToolbar
        overview={overview}
        gridApi={gridApi}
      />
    ),
    [overview],
  );

  useEffect(() => {
    dispatch(setCommitmentsRecommendationsFilters(null));
    dispatch(getReservationsRecommendationsThunk());
    dispatch(
      setReservationsRecommendationsInitialFiltersByPotentialSavingsThunk(),
    );
  }, [dispatch, orgId]);

  return (
    <CommitmentsAgGrid
      Toolbar={toolbar}
      loading={loading}
      columnDefs={reservationsRecommendationsGridColumnsDefs}
      data={data}
      localStorageUniqueKey="reservationRecommendationsDataGrid"
      overrideDefaultColDef={reservationRecommendationsDataDefaultColDef}
      noRowOverlayComponent={noRowOverlayComponent}
    />
  );
};

const reservationRecommendationsDataDefaultColDef = {
  filter: false,
};
