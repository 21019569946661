import { ColDef, ColDefField } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { AlertsDataGridThresholdCell } from "../AlertsDataGridThresholdCell";
import { alertsThresholdComparator } from "../utils/helpers/comparators/alertsThresholdComparator";
import { alertsThresholdGetter } from "../utils/helpers/valueGetters/alertsThresholdGetter";
import { isCostAnomalyAlertsDataGridDataType } from "../../cost-anomaly/utils/helpers/isCostAnomalyAlertsDataGridDataType";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../store/alerts/utils/types/types";

export const getAlertsThresholdColumnDef = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(): ColDef<T> => ({
  field: "threshold" as ColDefField<T>,
  headerName: "Threshold",
  minWidth: 120,
  width: 180,
  cellRenderer: ({
    data,
  }: CustomCellRendererProps<
    CostAnomalyAlertsDataGridDataType | UtilizationAlertDataGridDataType
  >) => {
    if (!data) {
      return;
    }

    const isCostAnomaly = isCostAnomalyAlertsDataGridDataType(data);

    return (
      <AlertsDataGridThresholdCell
        thresholds={
          isCostAnomaly
            ? (data.threshold?.threshold_items ?? [])
            : data.thresholds
        }
        alertType={isCostAnomaly ? "costAnomaly" : "utilization"}
        operator={isCostAnomaly ? data?.threshold?.operator : undefined}
      />
    );
  },
  comparator: alertsThresholdComparator,
  valueGetter: alertsThresholdGetter,
});
