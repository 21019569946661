import { FC } from "react";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Stack } from "@mui/material";
import { ResourceDetailsGeneralContentGrouping } from "./ResourceDetailsGeneralContentGrouping";
import { ResourceDetailsGeneralContentChart } from "./ResourceDetailsGeneralContentChart";
import { ResourceDetailsGeneralContentCostsChartType } from "../../common/ResourceDetailsGeneralContentCostsChartType";
import { ResourceDetailsContentCollapsableCard } from "../../ResourceDetailsContentCollapsableCard";
import { ResourceDetailsBillingMetadata } from "../../common/ResourceDetailsBillingMetadata";
import { ResourceDetailsDataPointSelector } from "../../common/ResourceDetailsDataPointSelector";
import { ResourceDetailsDateRange } from "../../common/ResourceDetailsDateRange";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { resourceDetailsGeneralTabProcessKey } from "../../../../../../../../store/resource-details/utils/constants/processesKeys";
import { resourceDetailsChartTypeSelector } from "../../../../../../../../store/resource-details/selectors/options/resourceDetailsChartTypeSelector";

export const ResourceDetailsGeneralContentCosts: FC = () => {
  const chartType = useAppSelector((state) =>
    resourceDetailsChartTypeSelector(
      state,
      resourceDetailsGeneralTabProcessKey,
    ),
  );

  return (
    <ResourceDetailsContentCollapsableCard
      title="Costs"
      Icon={<ShowChartIcon fontSize="small" />}
    >
      <Stack spacing={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <ResourceDetailsGeneralContentGrouping />
            <ResourceDetailsDateRange />
            <ResourceDetailsDataPointSelector />
          </Stack>

          {chartType && (
            <ResourceDetailsGeneralContentCostsChartType
              chartType={chartType}
              processKey={resourceDetailsGeneralTabProcessKey}
            />
          )}
        </Stack>
        <ResourceDetailsBillingMetadata />

        <ResourceDetailsGeneralContentChart />
      </Stack>
    </ResourceDetailsContentCollapsableCard>
  );
};
