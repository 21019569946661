import { ResourceType } from "../../../../services/cloudchipr.api";
import { resourceGroupsByProvider } from "../../../../utils/constants/resources/resourceGroupsByProvider";

export const kubernetesLiveUsageMgmtResourceTypesHierarchySelector = (
  resourceType: ResourceType,
): ResourceType[] => {
  if (!resourceGroupsByProvider.get("kubernetes")) {
    return [];
  }
  const groups = resourceGroupsByProvider.get("kubernetes");
  return (
    groups?.find(
      (item) =>
        item.value === resourceType ||
        item.resourceTypes.includes(resourceType),
    )?.hierarchy?.[resourceType] ?? [resourceType]
  );
};
