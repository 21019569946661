import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCommitmentsOverviewDataThunk } from "./overview/getCommitmentsOverviewDataThunk";
import { getAzureReservationsThunk } from "./azure/reservations/getAzureReservationsThunk";
import { getReservationsThunk } from "./reservations/getReservationsThunk";
import { getSavingsPlansThunk } from "./savings-plans/getSavingsPlansThunk";
import { getAzureSavingsPlansThunk } from "./azure/saving-plans/getAzureSavingsPlansThunk";
import { getAzureCommitmentsOverviewThunk } from "./azure/overview/getAzureCommitmentsOverviewThunk";
import { Dates } from "../../../services/cloudchipr.api";
import { setCommitmentsDates } from "../commitmentsSlice";
import { commitmentsTypeTabSelector } from "../selectors/common/commitmentsTypeTabSelector";
import { RootState } from "../../store";
import { commitmentsCurrentOrgProviderSelector } from "../selectors/common/commitmentsCurrentOrgProviderSelector";

interface ChangeCommitmentDateRangeThunkArgs {
  dates: Dates;
}
export const changeCommitmentDateRangeThunk = createAsyncThunk(
  "commitments/changeCommitmentDateRange",
  ({ dates }: ChangeCommitmentDateRangeThunkArgs, { dispatch, getState }) => {
    const state = getState() as RootState;

    const activeTab = commitmentsTypeTabSelector(state);
    const provider = commitmentsCurrentOrgProviderSelector(state);

    dispatch(setCommitmentsDates(dates));

    if (activeTab === "overview") {
      if (provider === "azure") {
        dispatch(getAzureCommitmentsOverviewThunk());
      } else {
        dispatch(getCommitmentsOverviewDataThunk());
      }
    } else if (activeTab === "reservation") {
      if (provider === "azure") {
        dispatch(getAzureReservationsThunk());
      } else {
        dispatch(getReservationsThunk());
      }
    } else {
      if (provider === "azure") {
        dispatch(getAzureSavingsPlansThunk());
      } else {
        dispatch(getSavingsPlansThunk());
      }
    }
  },
);
