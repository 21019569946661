import { memo } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../store/hooks";
import { kubernetesClustersCashesSelector } from "../../../../../../store/accounts/selectors/kubernetes/kubernetesClustersCashesSelector";
import { StatisticCard } from "../../../../../common/statisticCard/StatisticCard";
import { money } from "../../../../../../utils/numeral/money";

export const KubernetesClustersCashs = memo(() => {
  const costs = useAppSelector(kubernetesClustersCashesSelector);

  const idleMonthlyCost = costs?.idleMonthlyCost ?? 0;
  const totalMonthlyCost = costs?.totalMonthlyCost ?? 0;

  return (
    <Stack spacing={1} direction="row" width="100%">
      <StatisticCard
        title="Live costs for all Idle resources"
        value={money(idleMonthlyCost)}
        color="red"
        size="large"
        sx={{
          borderWidth: 4,
        }}
      />
      <StatisticCard
        title="Live costs for all tracked resources"
        value={money(totalMonthlyCost)}
        color="default"
        size="large"
        sx={{
          borderWidth: 4,
        }}
      />
    </Stack>
  );
});
