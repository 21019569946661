import { FC, Fragment, ReactElement } from "react";
import { Chip } from "@mui/material";
import { TaskActivityWrapper } from "./TaskActivityWrapper";
import { ActivityComponentProps } from "../../utils/types/types";
import {
  ActionActivityValue,
  EnvironmentActivityValue,
  OwnerActivityValue,
  PriorityActivityValue,
  StatusActivityValue,
} from "../../../../../../../../../../services/cloudchipr.api";
import { CommentUser } from "../../../../../../../../task-management/components/grid/cell-renderers/comment-cell/components/CommentUser";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";

type PropertyAction =
  | StatusActivityValue
  | ActionActivityValue
  | OwnerActivityValue
  | EnvironmentActivityValue
  | PriorityActivityValue;

interface PropertyActivityProps extends ActivityComponentProps {
  updatedValue?: PropertyAction;
  prevValue?: PropertyAction;
  optionAvatar?: ReactElement;
}

export const PropertyActivity: FC<PropertyActivityProps> = ({
  updatedValue,
  prevValue,
  createdBy,
  createdAt,
  optionAvatar,
}) => {
  const type = updatedValue?.type || prevValue?.type;

  let additionalText = (
    <Fragment>
      changed {type} from{" "}
      <Chip
        size="small"
        variant="filled"
        label={
          <TypographyWithTooltip
            variant="inherit"
            maxWidth="5dvw"
            title={prevValue?.name}
          />
        }
        avatar={optionAvatar}
        sx={{
          color: "white",
          overflow: "hidden",
          bgcolor: prevValue?.color,
        }}
      />{" "}
      to{" "}
      <Chip
        size="small"
        variant="filled"
        label={
          <TypographyWithTooltip
            variant="inherit"
            maxWidth="5dvw"
            title={updatedValue?.name}
          />
        }
        avatar={optionAvatar}
        sx={{
          color: "white",
          overflow: "hidden",
          bgcolor: updatedValue?.color,
        }}
      />
    </Fragment>
  );

  if (!updatedValue || !prevValue) {
    additionalText = (
      <Fragment>
        {!updatedValue ? `removed ${type}` : `set ${type} to`}{" "}
        <Chip
          size="small"
          variant="filled"
          label={
            <TypographyWithTooltip
              variant="inherit"
              maxWidth="5dvw"
              title={prevValue?.name ?? updatedValue?.name}
            />
          }
          avatar={optionAvatar}
          sx={{
            color: "white",
            overflow: "hidden",
            bgcolor: prevValue?.color ?? updatedValue?.color,
          }}
        />
      </Fragment>
    );
  }

  return (
    <TaskActivityWrapper
      content={<CommentUser user={createdBy} additionalText={additionalText} />}
      createdAt={createdAt}
    />
  );
};
