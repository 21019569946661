import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { getTasksViewsHierarchyThunk } from "../getTasksViewsHierarchyThunk";
import { deleteTaskViewFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

export const deleteTasksViewThunk = createAsyncThunk(
  "taskManagement/deleteTasksView",
  async (viewId: string, { dispatch }) => {
    const { deleteUsersMeOrganisationsCurrentTaskViewsByViewId } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        deleteUsersMeOrganisationsCurrentTaskViewsByViewId.initiate(
          { viewId },
          { fixedCacheKey: deleteTaskViewFixedCacheKey },
        ),
      ).unwrap();
      dispatch(getTasksViewsHierarchyThunk());

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
