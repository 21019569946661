import { FC, Fragment, useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { CreateJiraTicketDialogV2 } from "./jira/CreateJiraTicketDialogV2";
import { CreateEmailMessageDialogWrapper } from "./email/CreateEmailMessageDialogWrapper";
import { CreateSlackMessageDialogWrapper } from "./slack/CreateSlackMessageDialogWrapper";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { integrationByTypeExistSelector } from "../../../../store/integrations/selectors/integrationByTypeExistSelector";
import { getAllIntegrationsThunk } from "../../../../store/integrations/thunks/getAllIntegrationsThunk";
import { DialogTitleClosable } from "../../dialog-utils/DialogTitleClosable";
import { IntegrationCreation } from "../../../pages/integrations/components/integrations-create/IntegrationCreation";
import { IntegrationType } from "../../../pages/integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import {
  ProviderType,
  ResourceType,
} from "../../../../services/cloudchipr.api";

interface DialogsContainerProps {
  integrationType: IntegrationType;
  resourceType: ResourceType;
  onClose(): void;
  provider?: ProviderType | null;
}

export const DialogsContainer: FC<DialogsContainerProps> = ({
  integrationType,
  resourceType,
  onClose,
  provider,
}) => {
  const dispatch = useAppDispatch();

  const [dialogType, setDialogType] = useState<
    IntegrationType | "integration" | null
  >(null);

  const integrationsExist = useAppSelector((state) =>
    integrationByTypeExistSelector(state, integrationType),
  );

  const closeDialog = useCallback(() => {
    setDialogType(null);
    onClose();
  }, [onClose]);

  const afterIntegrationCreateHandler = useCallback(() => {
    setDialogType(integrationType);
    dispatch(getAllIntegrationsThunk());
  }, [dispatch, integrationType]);

  useEffect(() => {
    if (integrationsExist === null) {
      return;
    }

    // if integration is email, no need to open "Integration Create" dialog, we will remove this logic soon
    if (integrationsExist || integrationType === "email") {
      setDialogType(integrationType);
    } else {
      setDialogType("integration");
    }
  }, [integrationsExist, integrationType]);

  if (!dialogType) {
    return null;
  }

  return (
    <Fragment>
      {dialogType === "integration" && (
        <Dialog open={true} onClose={closeDialog} maxWidth="sm" fullWidth>
          <DialogTitleClosable
            title={`Create ${integrationType.toUpperCase()} integration`}
            onClose={closeDialog}
          />

          <IntegrationCreation
            inDialog
            type={integrationType}
            onCancel={closeDialog}
            onSubmit={afterIntegrationCreateHandler}
          />
        </Dialog>
      )}

      {dialogType === "jira" && (
        <CreateJiraTicketDialogV2
          onClose={closeDialog}
          resourceType={resourceType}
        />
      )}

      {dialogType === "slack" && (
        <CreateSlackMessageDialogWrapper
          provider={provider}
          onClose={closeDialog}
          resourceType={resourceType}
        />
      )}

      {dialogType === "email" && (
        <CreateEmailMessageDialogWrapper
          provider={provider}
          onClose={closeDialog}
          resourceType={resourceType}
        />
      )}
    </Fragment>
  );
};
