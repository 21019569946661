import { FC, useMemo } from "react";
import { Stack, TypographyProps } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import {
  commitmentsPaymentOptionLabels,
  commitmentsStateLabels,
} from "../../../../../common/utils/constants/labels";
import { DatesCell } from "../../../../../common/components/data-grid/cells/DatesCell";
import { azureSelectedReservationDataSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/details-drawer/reserved-instance/azureSelectedReservationDataSelector";
import { scopeLabels } from "../../../../../common/components/data-grid/cells/ScopeCell";
import { KeyValueSplitChip } from "../../../../../../../common/chips/KeyValueSplitChip";

export const AzureReservedInstanceDrawerContentHeaderInfoCards: FC = () => {
  const reservation = useAppSelector(azureSelectedReservationDataSelector);

  const data = useMemo(() => {
    const type = reservation?.type;
    const status = reservation
      ? commitmentsStateLabels[reservation?.state]
      : "";
    const paymentOption = reservation
      ? commitmentsPaymentOptionLabels[reservation.payment_option]
      : "";

    return {
      Type: type,
      "Product Name": reservation?.product_name,
      Scope: reservation?.scope?.type
        ? scopeLabels?.[reservation.scope.type]
        : "",
      Quantity: reservation?.instance_count,
      "Payment Option": paymentOption,
      "Start Date": (
        <DatesCell
          TypographyProps={typographyProps}
          date={reservation?.start_date ?? ""}
        />
      ),
      "End Date": (
        <DatesCell
          TypographyProps={typographyProps}
          date={reservation?.end_date ?? ""}
        />
      ),
      Status: status,
      "Region/AZ": reservation?.region,
    };
  }, [reservation]);

  if (!data) {
    return null;
  }

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {Object.entries(data).map(([title, value]) => {
        const props = {
          title,
          key: title,
          valueSx: value === "Active" ? statusActiveStyles : undefined,
        };

        if (typeof value === "string" || typeof value === "number") {
          return <KeyValueSplitChip {...props} value={value} />;
        }

        return <KeyValueSplitChip {...props}>{value}</KeyValueSplitChip>;
      })}
    </Stack>
  );
};

const typographyProps: TypographyProps = {
  variant: "body2",
  fontWeight: "medium",
};

const statusActiveStyles = {
  color: "white",
  bgcolor: "success.light",
};
