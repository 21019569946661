import { FC, useCallback } from "react";
import { Stack, Tab, TabProps, Tooltip } from "@mui/material";
import { VerifiedUserOutlined } from "@mui/icons-material";
import { HierarchyViewActionButton } from "./actions/HierarchyViewActionButton";
import { useHover } from "../../../../../utils/hooks/useHover.hook";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { viewTabMaxWidth } from "../utils/constants/constants";
import { View } from "../../../../../services/cloudchipr.api";

interface ViewsHierarchyTabProps extends TabProps {
  view: View;
  selected: boolean;
  onTabChange(viewId: string): void;
}

export const ViewsHierarchyTab: FC<ViewsHierarchyTabProps> = ({
  view,
  value,
  selected,
  onTabChange,
  ...tabProps
}) => {
  const { ref: hoverRef, hovered } = useHover();
  const actionVisible = selected || hovered;

  const clickHandler = useCallback(() => {
    onTabChange(value);
  }, [value, onTabChange]);

  return (
    <Tab
      {...tabProps}
      value={value}
      ref={hoverRef}
      sx={tabStyles}
      onClick={clickHandler}
      label={
        <Stack
          spacing={0.5}
          direction="row"
          alignItems="center"
          maxWidth={viewTabMaxWidth}
          width="100%"
          justifyContent="space-between"
        >
          {!!view.protection_details?.is_protected && (
            <Tooltip
              arrow
              placement="top"
              title="View is protected, all changes will not be autosaved"
            >
              <VerifiedUserOutlined
                fontSize="small"
                color={selected ? "primary" : "action"}
              />
            </Tooltip>
          )}

          <TypographyWithTooltip
            placement="top"
            title={view.name}
            variant="body2"
            fontWeight="medium"
            sx={{ textTransform: "none" }}
            color={selected ? "primary" : "text.primary"}
          />

          <HierarchyViewActionButton view={view} visible={actionVisible} />
        </Stack>
      }
    />
  );
};

const tabStyles = {
  py: 1.75,
  pr: 0.5,
  pl: 1,
  maxWidth: viewTabMaxWidth,
  minWidth: 60,
};
