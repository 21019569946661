import { CoverageGroupResponse } from "../../../../../../../../services/cloudchipr.api";

export const coverageDataKeyByChartDataKey: Record<
  string,
  keyof CoverageGroupResponse
> = {
  SP: "savings_plan",
  RI: "reservation",
  "On-Demand": "on_demand",
};
