import { generateKubernetesNonPrimitiveHeaderKey } from "./generateKubernetesNonPrimitiveHeaderKey";
import { kubernetesGenerateValue } from "./valueGenerators/kubernetesValueGenerator";
import { ResourceItemType } from "../../../../../../../../../../store/live-usage-mgmt/utils/types/types";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import {
  KubernetesResourceDataType,
  KubernetesResourceItemType,
} from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/utils/types/types";
import { ExportedResourceDataOptions } from "../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/types";
import { sizeGenerator } from "../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/sizeGenerator";
import { EmbeddedTask } from "../../../../../../../../../../services/cloudchipr.api";

export const generateKubernetesExportedResourceDataByColumns = (
  resourceData: KubernetesResourceDataType[],
  columns: ColumnSetupType<KubernetesResourceItemType>[],
  options?: ExportedResourceDataOptions,
) => {
  return resourceData?.reduce(
    (acc, { resource }) => {
      const resourceRowInfo = columns.reduce(
        (acc, col, index) => {
          if (col.id === "smart_tag" || !col.accessorKey) {
            return acc;
          }

          if (
            col.accessorKey === ("cpu_value" as keyof ResourceItemType) &&
            columns.find((col) => col.id === "cpu_percent")
          ) {
            return acc;
          }

          let key = col.header;
          if (typeof key !== "string") {
            key = generateKubernetesNonPrimitiveHeaderKey(
              col,
              options?.metricsFrequencies,
            );
          }

          if (options?.headerPrefix) {
            key = options.headerPrefix + key;
          }

          const isSize =
            // as "size" key not includes in all resources
            col.accessorKey.includes("size") &&
            col.accessorKey.length === "size".length;

          if (isSize && "size" in resource) {
            acc = {
              ...acc,
              ...sizeGenerator(`${resource?.size ?? ""}`),
            };

            return acc;
          }

          if (col.type === "taskStatus") {
            const task = resource?.[col?.accessorKey ?? ""] as
              | EmbeddedTask
              | undefined;

            acc = {
              ...acc,
              [key]: kubernetesGenerateValue(resource, col, options),
              "Task Link": task?.id
                ? `${window.location.origin}/task-mgmt?taskId=${task?.id}`
                : "",
            };

            return acc;
          }

          acc[key] = kubernetesGenerateValue(resource, col, options);

          return acc;
        },
        {} as Record<string, string | number>,
      );

      acc.push(resourceRowInfo);

      return acc;
    },
    [] as Record<string, string | number>[],
  );
};
