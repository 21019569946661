import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { BillingExplorerGranularCostCell } from "../../../../../billing-explorer/components/BillingExplorerGranularCostCell";

export const getResourceDetailsCostsTableCostCellRenderer =
  (column: string): FC<ICellRendererParams> =>
  ({ data }: ICellRendererParams) => {
    return (
      <BillingExplorerGranularCostCell
        pr={0}
        cost={data[column]?.total_cost}
        trend={data[column]?.trend}
      />
    );
  };

export const ResourceDetailsCostsTableTotalCostCellRenderer: FC<
  ICellRendererParams
> = ({ data }: ICellRendererParams) => {
  return (
    <BillingExplorerGranularCostCell
      pr={2.5}
      cost={data?.total_cost}
      trend={data?.trend}
    />
  );
};
