import { FC, useCallback, useState } from "react";
import { useFormik } from "formik";
import { useEffectOnceWhen } from "rooks";
import { Box, Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { BudgetName } from "./components/BudgetName";
import { BudgetViewSelectionWrapper } from "./components/BudgetViewSelectionWrapper";
import { PeriodAndDateSelection } from "./components/PeriodAndDateSelection";
import { BudgetAmount } from "./components/BudgetAmount";
import { ThresholdsSection } from "./components/threshold/ThresholdsSection";
import { BudgetsNotifications } from "./components/BudgetsNotifications";
import { validationSchema } from "./utils/constants/formikValidations";
import { FormInitialValues } from "./utils/types/formInitialValues";
import { ReceiveUpdatesCheckbox } from "./components/ReceiveUpdatesCheckbox";
import { fixNumber } from "./utils/helpers/common";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { formDataSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/forms/formDataSelector";
import { setCurrentBudgetData } from "../../../../../../../../../store/budgets/budgetsSlice";
import {
  Threshold,
  useGetUsersMeOrganisationsCurrentBudgetsQuery,
} from "../../../../../../../../../services/cloudchipr.api";
import { isCurrentBudgetEditingSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/isCurrentBudgetEditingSelector";
import { getResourceExplorerDataByBudgetThunk } from "../../../../../../../../../store/budgets/thunks/getResourceExplorerDataByBudgetThunk";
import { toggleAlertsOpenDrawer } from "../../../../../../../../../store/alerts/alertsSlice";
import { updateBudgetFromCurrentThunk } from "../../../../../../../../../store/budgets/thunks/updateBudgetFromCurrentThunk";
import { createBudgetFromCurrentThunk } from "../../../../../../../../../store/budgets/thunks/createBudgetFromCurrentThunk";
import { EmailsSelectionErrors } from "../../../../../../../../common/integration-dialogs/components/email/components/email-selection/EmailsSelection";
import { getResourceExplorerViewByIdThunk } from "../../../../../../../../../store/common/thunks/resource-explorer/getResourceExplorerViewByIdThunk";
import { currentBudgetViewIdSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/currentBudgetViewIdSelector";

export const BudgetsForm: FC = () => {
  const dispatch = useAppDispatch();
  const formInitialData = useAppSelector(formDataSelector);

  const {
    values,
    handleSubmit,
    setFieldValue,
    isValid,
    setValues,
    handleChange,
    errors,
  } = useFormik({
    initialValues: {} as FormInitialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      const formattedThresholds = values.thresholds.map((th) => {
        return {
          ...th,
          percent: fixNumber(th.percent, 2),
        };
      });
      dispatch(
        setCurrentBudgetData({
          name: values.name,
          amount: +values.amount,
          viewId: values.viewId,
          period: values.period,
          start_date: values.startDate,
          email_to: values.email_to,
          thresholds: formattedThresholds,
          notifications: values.notifications,
          receive_updates: values.receive_updates,
        }),
      );
    },
  });

  const [saveBudgetLoading, setSaveBudgetLoading] = useState(false);
  const { refetch } = useGetUsersMeOrganisationsCurrentBudgetsQuery();
  const isEdit = useAppSelector(isCurrentBudgetEditingSelector);
  const currentViewId = useAppSelector(currentBudgetViewIdSelector);

  const viewChaneHandler = useCallback(
    (viewId: string) => {
      if (values.viewId === viewId) {
        return;
      }

      setFieldValue("viewId", viewId);
      dispatch(setCurrentBudgetData({ viewId }));
      dispatch(getResourceExplorerDataByBudgetThunk());
    },
    [dispatch, setFieldValue, values.viewId],
  );

  const thresholdsChangeHandler = useCallback(
    (thresholds: Threshold[]) => {
      setFieldValue("thresholds", thresholds);
      dispatch(setCurrentBudgetData({ thresholds }));
    },
    [dispatch, setFieldValue],
  );

  const closeBudgetDrawerHandler = useCallback(() => {
    dispatch(toggleAlertsOpenDrawer());
  }, [dispatch]);

  const submitBudgetHandler = useCallback(async () => {
    setSaveBudgetLoading(true);

    const thunk = isEdit
      ? updateBudgetFromCurrentThunk
      : createBudgetFromCurrentThunk;

    const response = await dispatch(thunk());

    if (response.payload === "success") {
      await refetch();
      setSaveBudgetLoading(false);

      if (currentViewId) {
        dispatch(
          getResourceExplorerViewByIdThunk({
            viewId: currentViewId,
            forceRefetch: true,
          }),
        );
      }
      closeBudgetDrawerHandler();
    } else {
      setSaveBudgetLoading(false);
    }
  }, [dispatch, refetch, isEdit, closeBudgetDrawerHandler, currentViewId]);

  useEffectOnceWhen(() => {
    formInitialData && setValues(formInitialData);
  }, !!formInitialData);

  if (
    values.viewId === undefined ||
    values.notifications === undefined ||
    values.thresholds === undefined
  ) {
    return null;
  }

  return (
    <Box flex={1} overflow="auto">
      <form onSubmit={handleSubmit}>
        <Stack p={2} spacing={3}>
          <Stack spacing={2}>
            <Typography variant="body1" fontWeight="medium">
              Configure budget
            </Typography>

            <BudgetName name={values.name} handleChange={handleChange} />

            <BudgetViewSelectionWrapper
              selectedItemId={values.viewId}
              onChange={viewChaneHandler}
            />

            <PeriodAndDateSelection
              period={values.period}
              startDate={values.startDate}
              setFieldValue={setFieldValue}
              thresholds={values.thresholds ?? []}
              onThresholdChange={thresholdsChangeHandler}
            />

            <BudgetAmount
              error={errors.amount}
              amount={values.amount}
              handleChange={handleChange}
            />
          </Stack>

          <Stack spacing={2}>
            <ThresholdsSection
              thresholds={values.thresholds ?? []}
              onThresholdChange={thresholdsChangeHandler}
            />
          </Stack>

          <Stack spacing={2}>
            <BudgetsNotifications
              emailsErrors={errors.email_to as EmailsSelectionErrors}
              emails={values.email_to}
              setFieldValue={setFieldValue}
              integrations={values.notifications}
            />
          </Stack>

          <ReceiveUpdatesCheckbox
            receiveUpdates={values.receive_updates}
            period={values.period}
            handleChange={handleChange}
          />

          <Stack direction="row" spacing={2.5} pt={2}>
            <LoadingButton
              disabled={!isValid}
              loading={saveBudgetLoading}
              variant="contained"
              onClick={submitBudgetHandler}
              sx={{ textTransform: "none" }}
            >
              {isEdit ? "Save" : "Create"}
            </LoadingButton>
            <Button
              color="tertiary"
              onClick={closeBudgetDrawerHandler}
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};
