import { ChangeEvent, FC, useCallback } from "react";
import {
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { TimeUnit } from "../../../../../utils/helpers/date-time/time";

interface InputWithTimeUnitProps {
  unit: TimeUnit;
  inputLabel: string;
  value: string | number;
  onChange(e: string): void;
  onUnitChange(unit: TimeUnit): void;
  error?: boolean;
  onBlur?(e: ChangeEvent): void;
}

export const InputWithTimeUnit: FC<InputWithTimeUnitProps> = ({
  unit,
  onUnitChange,
  onChange,
  value,
  onBlur,
  error,
  inputLabel,
}) => {
  const unitChangeHandler = useCallback(
    (event: SelectChangeEvent) => {
      onUnitChange(event.target.value as TimeUnit);
    },
    [onUnitChange],
  );

  const changeHandler = useCallback(
    (e: ChangeEvent<any>) => {
      const value = e.target.value;
      if (isNaN(value)) {
        return;
      }

      onChange(value);
    },
    [onChange],
  );

  return (
    <TextField
      size="xsmall"
      label={inputLabel}
      onChange={changeHandler}
      value={value}
      onBlur={onBlur}
      error={!!error}
      sx={{ width: 150 }}
      InputProps={{
        sx: { pr: 0 },
        endAdornment: (
          <InputAdornment position="end" sx={{ ml: 0 }}>
            <FormControl size="xsmall">
              <Select
                variant="outlined"
                sx={{
                  marginLeft: 0,
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  ".MuiSelect-select": {
                    pl: 0.5,
                    pr: 3.5,
                  },
                }}
                value={unit}
                onChange={unitChangeHandler}
              >
                <MenuItem value="h">Hour</MenuItem>
                <MenuItem value="d">Day</MenuItem>
              </Select>
            </FormControl>
          </InputAdornment>
        ),
      }}
    />
  );
};
