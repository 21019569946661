import { kubernetesNodesColumn } from "./columns/kubernetesNodesColumn";
import { kubernetesPodsColumn } from "./columns/kubernetesPodsColumn";
import { kubernetesDeploymentsColumn } from "./columns/kubernetesDeploymentsColumn";
import { kubernetesStatefulSetsColumn } from "./columns/kubernetesStatefulSetsColumn";
import { kubernetesDaemonSetsColumn } from "./columns/kubernetesDaemonSetsColumn";
import { kubernetesReplicaSetsColumns } from "./columns/kubernetesReplicaSetsColumns";
import { kubernetesJobsColumns } from "./columns/kubernetesJobsColumns";
import { kubernetesNamespacesColumn } from "./columns/kubernetesNamespacesColumn";
import { ResourceType } from "../../../../../../../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../../../../../../../storybook/data-grid/utils/types/types";

export const kubernetesColumnsMap = new Map<
  ResourceType,
  ColumnSetupType<any>[]
>([
  ["kubernetes_node", kubernetesNodesColumn],
  ["kubernetes_namespace", kubernetesNamespacesColumn],
  ["kubernetes_pod", kubernetesPodsColumn],
  ["kubernetes_deployment", kubernetesDeploymentsColumn],
  ["kubernetes_daemon_set", kubernetesDaemonSetsColumn],
  ["kubernetes_stateful_set", kubernetesStatefulSetsColumn],
  ["kubernetes_replica_set", kubernetesReplicaSetsColumns],
  ["kubernetes_job", kubernetesJobsColumns],
]);
