import { FC, useEffect, useState } from "react";
import { Skeleton, Stack, Box, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

interface BillingExplorerExplainWithAiLoadingProps {
  variant?: "dark" | "light";
  hideIcon?: boolean;
}
export const BillingExplorerExplainWithAiLoading: FC<
  BillingExplorerExplainWithAiLoadingProps
> = ({ variant, hideIcon }) => {
  const [index, setIndex] = useState(0);
  const [dots, setDots] = useState("");
  useEffect(() => {
    if (index >= messages.length - 1) return;

    const timer = setTimeout(() => {
      setIndex((prevIndex) => prevIndex + 1);
    }, 4000);

    return () => clearTimeout(timer);
  }, [index]);

  useEffect(() => {
    const dotsTimer = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? prev + "." : ""));
    }, 500);
    return () => clearInterval(dotsTimer);
  }, []);

  return (
    <Stack spacing={1} direction="row" alignItems="center" position="relative">
      {!hideIcon && <AutoAwesomeIcon fontSize="small" color="primary" />}

      <Box
        position="relative"
        width={260}
        height={40}
        display="flex"
        alignItems="center"
      >
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{
            bgcolor: variant === "dark" ? "grey.100" : "white",
            borderRadius: 3,
          }}
        />
        <Stack
          direction="row"
          sx={{
            position: "absolute",
            whiteSpace: "nowrap",
          }}
          pl={2}
        >
          <Typography variant="subtitle1">{messages[index]}</Typography>
          <Typography variant="subtitle1" minWidth={15}>
            {dots}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

const messages = [
  "Gathering Cost Records",
  "Identifying Cost Contributors",
  "Analyzing Cost Changes",
  "Delivering Actionable Insights",
];
