import {
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  useCallback,
  useMemo,
} from "react";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import {
  type GridApi,
  GridReadyEvent,
  StateUpdatedEvent,
} from "ag-grid-community";
import { GetRowIdFunc } from "ag-grid-community/dist/types/src/entities/gridOptions";
import { agGridWrapperStyles } from "./utils/constants/agGridStyles";
import { agGridThemeCustomization } from "./utils/constants/agGridThemeCustomization";
import { agGridOverwrittenIcons } from "./utils/constants/agGridOverwrittenIcons";
import { agGridCustomLocale } from "./utils/constants/agGridCustomLocale";
import { ColumnsInnerHeaderComponent } from "./components/ColumnsInnerHeaderComponent";
import { ConfigToRememberStateInStorage } from "./utils/types/gridProps";
import { useGridStateMemoization } from "./utils/hooks/useGridStateMemoization.hook";
import type { IsGroupOpenByDefaultParams } from "ag-grid-community/dist/types/src/interfaces/iCallbackParams";

interface AgDataGridProps extends Omit<AgGridReactProps, "setGridApi"> {
  wrapperStyles?: SxProps<Theme>;
  gridApiSetter?(api: GridApi): void;
  configToRememberStateInStorage?: ConfigToRememberStateInStorage;
}

export const AgDataGrid: ForwardRefExoticComponent<
  PropsWithoutRef<AgDataGridProps> & RefAttributes<any>
> = forwardRef(
  (
    {
      wrapperStyles,
      gridApiSetter,
      onGridReady,
      defaultColDef,
      configToRememberStateInStorage,
      ...props
    },
    ref,
  ) => {
    const { onStateUpdated, initialState } = useGridStateMemoization(
      configToRememberStateInStorage,
    );

    const mainWrapperStyles = useMemo(() => {
      return {
        width: "100%",
        height: "100%",
        "& ": { ...agGridWrapperStyles, ...wrapperStyles },
      };
    }, [wrapperStyles]);

    const defaultColumnDef = useMemo(() => {
      return {
        headerComponentParams: {
          innerHeaderComponent: ColumnsInnerHeaderComponent,
        },
        ...defaultColDef,
      };
    }, [defaultColDef]);

    const gridReadyHandler = useCallback(
      (event: GridReadyEvent) => {
        gridApiSetter?.(event.api);
        onGridReady?.(event);
      },
      [gridApiSetter, onGridReady],
    );

    const isGroupOpenByDefault = useCallback(
      (params: IsGroupOpenByDefaultParams) => {
        return !!initialState?.rowGroupExpansion?.expandedRowGroupIds?.includes(
          params?.rowNode?.id ?? "",
        );
      },
      [initialState?.rowGroupExpansion?.expandedRowGroupIds],
    );

    const handleOnStateUpdated = useCallback(
      (event: StateUpdatedEvent) => {
        if (onStateUpdated) {
          onStateUpdated(event);
        }

        if (props.onStateUpdated) {
          props.onStateUpdated(event);
        }
      },
      [onStateUpdated, props],
    );

    return (
      <Box sx={mainWrapperStyles} className="ag-theme-quartz">
        <AgGridReact
          ref={ref}
          //
          // styling ======================================================================
          theme={agGridThemeCustomization}
          //
          // texts ======================================================================
          localeText={agGridCustomLocale}
          //
          // icons ======================================================================
          icons={agGridOverwrittenIcons}
          //
          // row selection ======================================================================
          selectionColumnDef={selectionColumnDef}
          //
          // row group ======================================================================
          isGroupOpenByDefault={isGroupOpenByDefault}
          //
          // enableness ======================================================================
          enableCellTextSelection
          //
          // context menu ======================================================================
          allowContextMenuWithControlKey
          getContextMenuItems={getContextMenuItems}
          //
          // miscellaneous ======================================================================
          getRowId={getRowId}
          onGridReady={gridReadyHandler}
          defaultColDef={defaultColumnDef}
          {...props}
          //
          // can't be overridden ======================================================================
          onStateUpdated={handleOnStateUpdated}
          initialState={props.initialState ?? initialState}
        />
      </Box>
    );
  },
);

const getContextMenuItems = () => []; // to disable cells context menu
const getRowId: GetRowIdFunc = ({ data }) => data?.id;

const selectionColumnDef: AgGridReactProps["selectionColumnDef"] = {
  pinned: "left",
};
