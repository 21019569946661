export const getTargetsFormattedValue = (value: string): string => {
  if (!value.toLowerCase().includes("app")) {
    return value;
  }

  const seperated = value.split("/");
  const index =
    seperated.findIndex((value) => value.toLowerCase().includes("app")) + 1;
  const newValue = seperated.toSpliced(0, index).join("/");

  return `...${newValue}`;
};
