import { FC, useCallback } from "react";
import { Divider, Stack } from "@mui/material";
import { TotalCost } from "../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/TotalCost";
import { AverageCost } from "../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/AverageCost";
import { ResourceExplorerForecastedCosts } from "../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/forecasted/ResourceExplorerForecastedCosts";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceDetailsCostsDatesSelector } from "../../../../../../../store/resource-details/selectors/options/resourceDetailsCostsDatesSelector";
import { resourceDetailsCostsDataPointSelector } from "../../../../../../../store/resource-details/selectors/options/resourceDetailsCostsDataPointSelector";
import { resourceDetailsCostsForecastOptionSelector } from "../../../../../../../store/resource-details/selectors/options/resourceDetailsCostsForecastOptionSelector";
import { setResourceDetailsForecastOption } from "../../../../../../../store/resource-details/resourceDetailsSlice";
import { ForecastOption } from "../../../../../../../services/cloudchipr.api";
import { resourceDetailsResourceBillingDataForecastedCostsSelector } from "../../../../../../../store/resource-details/selectors/billing-data/meta/resourceDetailsResourceBillingDataForecastedCostsSelector";
import { resourceDetailsResourceBillingDataTotalCostSelector } from "../../../../../../../store/resource-details/selectors/billing-data/meta/resourceDetailsResourceBillingDataTotalCostSelector";
import { resourceDetailsResourceBillingDataTotalCostDateDetailsSelector } from "../../../../../../../store/resource-details/selectors/billing-data/meta/resourceDetailsResourceBillingDataTotalCostDateDetailsSelector";
import { resourceDetailsResourceBillingDataPreviousPeriodDateDetailsSelector } from "../../../../../../../store/resource-details/selectors/billing-data/meta/resourceDetailsResourceBillingDataPreviousPeriodDateDetailsSelector";
import { resourceDetailsResourceBillingDataTotalTrendSelector } from "../../../../../../../store/resource-details/selectors/billing-data/meta/resourceDetailsResourceBillingDataTotalTrendSelector";
import { resourceDetailsResourceBillingDataAverageData } from "../../../../../../../store/resource-details/selectors/billing-data/meta/resourceDetailsResourceBillingDataAverageData";
import { resourceDetailsResourceBillingDataLoadingSelector } from "../../../../../../../store/resource-details/selectors/billing-data/loadings/resourceDetailsResourceBillingDataLoadingSelector";

export const ResourceDetailsBillingMetadata: FC = () => {
  const dispatch = useAppDispatch();

  const averageData = useAppSelector(
    resourceDetailsResourceBillingDataAverageData,
  );
  const trend = useAppSelector(
    resourceDetailsResourceBillingDataTotalTrendSelector,
  );

  const previousPeriodCostDates = useAppSelector(
    resourceDetailsResourceBillingDataPreviousPeriodDateDetailsSelector,
  );
  const totalCost = useAppSelector(
    resourceDetailsResourceBillingDataTotalCostSelector,
  );
  const totalCostDates = useAppSelector(
    resourceDetailsResourceBillingDataTotalCostDateDetailsSelector,
  );
  const forecastedCosts = useAppSelector(
    resourceDetailsResourceBillingDataForecastedCostsSelector,
  );
  const forecastOption = useAppSelector(
    resourceDetailsCostsForecastOptionSelector,
  );
  const dataPoint = useAppSelector(resourceDetailsCostsDataPointSelector);
  const dates = useAppSelector(resourceDetailsCostsDatesSelector);

  const loading = useAppSelector(
    resourceDetailsResourceBillingDataLoadingSelector,
  );

  const forecastChangeHandler = useCallback(
    (option: ForecastOption) => {
      dispatch(setResourceDetailsForecastOption(option));
    },
    [dispatch],
  );

  return (
    <Stack
      py={1}
      spacing={1}
      flex={1}
      direction="row"
      justifyContent="space-between"
    >
      <Stack
        px={1}
        border={1}
        spacing={2}
        direction="row"
        borderRadius={2}
        borderColor="grey.300"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <TotalCost
          loading={loading}
          totalCost={totalCost}
          totalCostTrend={trend}
          totalCostDates={totalCostDates}
          previousPeriodCostDates={previousPeriodCostDates}
          dateLabel={dates?.label}
        />

        {averageData?.cost !== null && (
          <AverageCost
            loading={loading}
            average={averageData?.cost}
            averageTrend={averageData?.trend}
            dateDetails={totalCostDates}
            previousPeriodCostDates={previousPeriodCostDates}
            dateLabel={dates?.label}
            dataPoint={dataPoint ?? "monthly"}
          />
        )}
      </Stack>

      <Stack direction="row" border={1} borderRadius={2} borderColor="grey.300">
        <ResourceExplorerForecastedCosts
          loading={loading}
          forecastedCosts={forecastedCosts}
          selectedForecastOption={forecastOption}
          onForecastChange={forecastChangeHandler}
        />
      </Stack>
    </Stack>
  );
};
