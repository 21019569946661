import { FC } from "react";
import { useContextMonths } from "@rehookify/datepicker";
import { Stack } from "@mui/material";
import { DateRangeCalendarMonth } from "./DateRangeCalendarMonth";

interface DateRangeCalendarMonthSelectionProps {
  onSelect(): void;
  setOffsetDate(date?: Date): void;
}

export const DateRangeCalendarMonthSelection: FC<
  DateRangeCalendarMonthSelectionProps
> = ({ onSelect, setOffsetDate }) => {
  const { months } = useContextMonths();

  return (
    <Stack
      direction="row"
      gap={1}
      px={3}
      flexWrap="wrap"
      justifyContent="center"
    >
      {months.map((dpMonth) => {
        return (
          <DateRangeCalendarMonth
            key={dpMonth.month}
            dpMonth={dpMonth}
            onSelect={onSelect}
            setOffsetDate={setOffsetDate}
          />
        );
      })}
    </Stack>
  );
};
