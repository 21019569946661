import { createAsyncThunk } from "@reduxjs/toolkit";
import { taskManagementGetTasksThunk } from "./taskManagementGetTasksThunk";
import {
  cloudChiprApi,
  PatchUsersMeOrganisationsCurrentTasksApiArg,
  Task,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { selectedTasksSelector } from "../../selectors/selection/selectedTasksSelector";
import { taskUpdateFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { generatePayloadFromTask } from "../../selectors/utils/helpers/generatePayloadFromTask";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { setTaskManagementSelectedTasks } from "../../taskManagementSlice";

export const taskManagementUpdateSelectedTasksThunk = createAsyncThunk(
  "taskManagement/taskManagementUpdateSelectedTasks",
  async (
    payload: Partial<PatchUsersMeOrganisationsCurrentTasksApiArg["body"]>,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const { patchUsersMeOrganisationsCurrentTasks } = cloudChiprApi.endpoints;
    const selectedTasks: Task[] = selectedTasksSelector(state);

    try {
      const requests = selectedTasks.map((task) => {
        return dispatch(
          patchUsersMeOrganisationsCurrentTasks.initiate(
            {
              body: {
                ...generatePayloadFromTask(task),
                ...payload,
                task_ids: [task.id],
              },
            },
            { fixedCacheKey: taskUpdateFixedCacheKey },
          ),
        ).unwrap();
      });

      await Promise.all(requests);
      const response = await dispatch(taskManagementGetTasksThunk()).unwrap();

      const updatedSelectedTasks: Task[] = selectedTasks
        .map(({ id }) => response.find((task) => task.id === id))
        .filter((task) => !!task);

      dispatch(setTaskManagementSelectedTasks(updatedSelectedTasks));
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
