import { FC } from "react";
import { Popover, PopoverOrigin, Stack, Typography } from "@mui/material";
import { ElbTargetTypography } from "./ElbTargetTypography";

interface TargetsPopoverProps {
  targets: string[];
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
}

export const ElbTargetsPopover: FC<TargetsPopoverProps> = ({
  targets,
  open,
  anchor,
  closeMenu,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={closeMenu}
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
    >
      <Stack py={1} px={2} borderBottom={1} borderColor="grey.300">
        <Typography variant="body1" fontWeight="medium">
          Targets ({targets.length})
        </Typography>
      </Stack>

      {targets.map((target, index) => (
        <Stack
          py={1}
          px={2}
          borderBottom={index !== targets.length - 1 ? 1 : 0}
          borderColor="grey.300"
          key={target}
        >
          <ElbTargetTypography
            target={target}
            typographyProps={{ variant: "body2" }}
          />
        </Stack>
      ))}
    </Popover>
  );
};

const anchorOrigin: PopoverOrigin = {
  horizontal: "center",
  vertical: "bottom",
};

const transformOrigin: PopoverOrigin = {
  horizontal: "center",
  vertical: "top",
};
