import { FC, Fragment } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { CommitmentUtilizationWidgetSetupTypeSelect } from "../../../adding-widget/widget-create/widget-setups/commitment-utilization /components/sidebar/components/CommitmentUtilizationWidgetSetupTypeSelect";
import { CommitmentUtilizationWidgetSetupGroupBySelect } from "../../../adding-widget/widget-create/widget-setups/commitment-utilization /components/sidebar/components/CommitmentUtilizationWidgetSetupGroupBySelect";
import { CommitmentUtilizationWidgetSetupOrgSelect } from "../../../adding-widget/widget-create/widget-setups/commitment-utilization /components/sidebar/components/CommitmentUtilizationWidgetSetupOrgSelect";
import { CommitmentUtilizationWidgetSetupDateRangeSelect } from "../../../adding-widget/widget-create/widget-setups/commitment-utilization /components/sidebar/components/CommitmentUtilizationWidgetSetupDateRangeSelect";
import { commitmentUtilizationSetupVisualizationPropertyByKeySelector } from "../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupVisualizationPropertyByKeySelector";
import { CommitmentUtilizationWidgetChartTypeSelect } from "../../../adding-widget/widget-create/widget-setups/commitment-utilization /components/sidebar/components/configurations/visualization/components/CommitmentUtilizationWidgetChartTypeSelect";
import {
  commitmentUtilizationVisualizationDetailsByChartType,
  commitmentUtilizationVisualizationDetailsComponentByType,
} from "../utils/constants/constants";

export const CommitmentUtilizationWidgetEditPopoverContent: FC = () => {
  const chartType = useAppSelector(
    commitmentUtilizationSetupVisualizationPropertyByKeySelector("chart_type"),
  );

  return (
    <Fragment>
      <CommitmentUtilizationWidgetSetupTypeSelect />
      <CommitmentUtilizationWidgetSetupGroupBySelect />
      <CommitmentUtilizationWidgetSetupOrgSelect />
      <CommitmentUtilizationWidgetSetupDateRangeSelect />

      <CommitmentUtilizationWidgetChartTypeSelect />

      {commitmentUtilizationVisualizationDetailsByChartType
        .get(chartType)
        ?.map((type) => {
          const Component =
            commitmentUtilizationVisualizationDetailsComponentByType.get(type);

          if (!Component) {
            return;
          }

          return <Component key={type} />;
        })}
    </Fragment>
  );
};
