import { FC } from "react";

import { Stack, Typography } from "@mui/material";
import { KubernetesClusterCardFooterProgress } from "./KubernetesClusterCardFooterProgress";
import { KubernetesClusterCardFooterProgressDescription } from "./KubernetesClusterCardFooterProgressDescription";
import { KubernetesClusterCardFooterMetricInfoTooltip } from "./tooltip/KubernetesClusterCardFooterMetricInfoTooltip";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";

interface KubernetesClusterCardFooterMetricInfoProps {
  title: string;
  total: number;
  usage: number;
  requested: number;
  unit: "gib" | "core";
}
export const KubernetesClusterCardFooterMetricInfo: FC<
  KubernetesClusterCardFooterMetricInfoProps
> = ({ title, total, usage, requested, unit }) => {
  return (
    <Stack spacing={1} flex={0.75} minWidth={100} overflow="hidden">
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={0.3}
        flex={1}
      >
        <Typography variant="subtitle2" fontWeight="bold">
          {title}
        </Typography>

        <Stack direction="row" spacing={0.3} overflow="hidden">
          <TypographyWithTooltip
            title={total}
            variant="body2"
            fontWeight="medium"
          />
          <Typography variant="body2" fontWeight="medium">
            {unit === "core" ? "Core" : "GiB"}
          </Typography>
        </Stack>
      </Stack>

      <KubernetesClusterCardFooterMetricInfoTooltip
        title={title}
        total={total}
        usage={usage}
        requested={requested}
        unit={unit}
      >
        <Stack spacing={0.75}>
          <KubernetesClusterCardFooterProgress
            total={total}
            usage={usage}
            requested={requested}
          />
          <KubernetesClusterCardFooterProgressDescription />
        </Stack>
      </KubernetesClusterCardFooterMetricInfoTooltip>
    </Stack>
  );
};
