import { FC, Fragment } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { CoverageWidgetHeaderInfo } from "./CoverageWidgetHeaderInfo";
import {
  CoverageGrouping,
  CommitmentUtilizationType,
  Dates,
} from "../../../../../../../services/cloudchipr.api";

interface CoverageWidgetSubHeaderProps {
  name: string;
  providerOrganisationId?: string;
  commitmentType?: CommitmentUtilizationType;
  grouping?: CoverageGrouping;
  dates?: Dates;
}

export const CoverageWidgetSubHeader: FC<CoverageWidgetSubHeaderProps> = ({
  name,
  commitmentType,
  providerOrganisationId,
  dates,
  grouping,
}) => {
  return (
    <Fragment>
      <Stack px={2} py={1}>
        <Typography variant="body1" fontWeight="bold" minHeight={24}>
          {name}
        </Typography>

        <CoverageWidgetHeaderInfo
          providerOrganisationId={providerOrganisationId}
          commitmentType={commitmentType}
          grouping={grouping}
          dates={dates}
        />
      </Stack>

      <Divider />
    </Fragment>
  );
};
