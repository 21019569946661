import { GetQuickFilterTextParams } from "ag-grid-community";
import { Schedule } from "../../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";

export const quickFilterModifiedByText = (
  params: GetQuickFilterTextParams<Schedule>,
) => {
  const details = params.data.modified_by;
  const date = params.data.modified_at;
  const target = `${details?.name} ${details?.email} ${formatDate(date ?? "", { type: "dateTime" })}`;

  return target.toLowerCase();
};
