import { ColDef } from "ag-grid-community";

export const integrationsInUseComparator: ColDef["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const currentUsageName = nodeA.data.usages?.at(0)?.name;
  const nextUsageName = nodeB.data.usages?.at(0)?.name;

  const current = (currentUsageName ?? "").toLowerCase();
  const next = (nextUsageName ?? "").toLowerCase();

  return current > next ? 1 : -1;
};
