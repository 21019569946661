import { CommandName, CommandsArray, SimpleCommands } from "../types/types";

export const mentionTagClassName = "mention";

export const commandsArray: CommandsArray = [
  ["heading", "textAlign"],
  ["bold", "italic", "underline", "strike"],
  ["link", "code", "codeBlock", "blockquote"],
  ["bulletList", "orderedList"],
  "color",
  "clearNode",
] as CommandsArray;

export const simpleCommandByCommandName = new Map<CommandName, SimpleCommands>([
  ["bold", "toggleBold"],
  ["italic", "toggleItalic"],
  ["strike", "toggleStrike"],
  ["code", "toggleCode"],
  ["codeBlock", "toggleCodeBlock"],
  ["bulletList", "toggleBulletList"],
  ["orderedList", "toggleOrderedList"],
  ["blockquote", "toggleBlockquote"],
  ["clearNode", "clearNodes"],
  ["underline", "toggleUnderline"],
  ["paragraph", "setParagraph"],
]);
