import {
  ValueGetterParams,
  ColDef,
  ICellRendererParams,
  IMultiFilterParams,
  IDateFilterParams,
} from "ag-grid-community";
import { Typography } from "@mui/material";
import moment from "moment";
import { TaskGridRowActionsMenu } from "./cell-renderers/row-actions/TaskGridRowActionsMenu";
import { DueDateCellRenderer } from "./cell-renderers/DueDateCellRenderer";
import { StatusCellRenderer } from "./cell-renderers/StatusCellRenderer";
import { EnvironmentCellRenderer } from "./cell-renderers/EnvironmentCellRenderer";
import { PriorityCellRenderer } from "./cell-renderers/PriorityCellRenderer";
import { ActionCellRenderer } from "./cell-renderers/ActionCellRenderer";
import { AssigneeCellRenderer } from "./cell-renderers/AssigneeCellRenderer";
import { NameCellRenderer } from "./cell-renderers/name-cell/NameCellRenderer";
import { ResourceIdCellRenderer } from "./cell-renderers/ResourceIdCellRenderer";
import { MonthlyPriceInnerHeader } from "./inner-header-components/MonthlyPriceInnerHeader";
import { AssigneeFilter } from "./custom-filters/AssigneeFilter";
import { CommentCellRenderer } from "./cell-renderers/comment-cell/CommentCellRenderer";
import { OwnerCellRenderer } from "./cell-renderers/OwnerCellRenderer";
import {
  GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse,
  Task,
} from "../../../../../services/cloudchipr.api";
import { AccountInfoData } from "../../../../common/AccountInfoData";
import { TagCell } from "../../../../common/data-grid-cells/tag-cell/TagCell";
import { TargetResourceTableStateRow } from "../../../common/task-management/components/form/content/details-section/components/target-section/resources/TargetResourceTableStateRow";
import { money } from "../../../../../utils/numeral/money";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";
import {
  taskSourceLabels,
  taskTargetStateLabels,
} from "../../utils/constants/labels";
import { taskAssigneesFilterText } from "../../utils/helpers/quick-filter-text-functions/taskAssigneesFilterText";
import { taskDueDateFilterText } from "../../utils/helpers/quick-filter-text-functions/taskDueDateFilterText";
import { taskTargetAccountFilterText } from "../../utils/helpers/quick-filter-text-functions/taskTargetAccountFilterText";
import { taskTargetTagFilterText } from "../../utils/helpers/quick-filter-text-functions/taskTargetTagFilterText";
import { actionComparatorFn } from "../../utils/helpers/comparator-functions/actionComparatorFn";
import { assigneesComparatorFn } from "../../utils/helpers/comparator-functions/assigneesComparatorFn";
import { priorityComparatorFn } from "../../utils/helpers/comparator-functions/priorityComparatorFn";
import { environmentComparatorFn } from "../../utils/helpers/comparator-functions/environmentComparatorFn";
import { statusComparatorFn } from "../../utils/helpers/comparator-functions/statusComparatorFn";
import { targetAccountComparatorFn } from "../../utils/helpers/comparator-functions/targetAccountComparatorFn";
import { targetTagComparatorFn } from "../../utils/helpers/comparator-functions/targetTagComparatorFn";
import { UserActivityCell } from "../../../../common/data-grid-cells/UserActivityCell";
import { createdAtComparatorFn } from "../../utils/helpers/comparator-functions/createdAtComparatorFn";
import { modifiedAtComparatorFn } from "../../utils/helpers/comparator-functions/modifiedAtComparatorFn";
import { agGridEmptyColumnToFitEmptySpace } from "../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { dueDateComparatorFn } from "../../utils/helpers/comparator-functions/dueDateComparatorFn";
import { keyInsensitiveComparatorFn } from "../../../common/ag-grid/utils/helpers/sortingFunctions/keyInsensitiveComparatorFn";
import { ServiceCell } from "../../../../common/data-grid-cells/ServiceCell";
import { taskCreatedByFilterValueGetter } from "../../utils/helpers/filter-value-getters/taskCreatedByFilterValueGetter";
import { taskModifiedByFilterValueGetter } from "../../utils/helpers/filter-value-getters/taskModifiedByFilterValueGetter";
import { ownerComparatorFn } from "../../utils/helpers/comparator-functions/ownerComparatorFn";

const nameColumn: ColDef = {
  colId: "name",
  headerName: "Task Name",
  suppressKeyboardEvent: () => true,
  lockVisible: true,
  pinned: "left",
  lockPinned: true,
  suppressColumnsToolPanel: true,
  cellStyle: { padding: 0 },
  cellRenderer: NameCellRenderer,
  comparator: keyInsensitiveComparatorFn,
  valueGetter: ({ data }) => data?.name,
};

const resourceIdColumn: ColDef = {
  field: "target.provider_identifier",
  headerName: "Resource ID/Name",
  maxWidth: 400,
  cellRenderer: ResourceIdCellRenderer,
  comparator: keyInsensitiveComparatorFn,
};

const getTaskRelatedColumns = (
  properties?: GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse,
): ColDef[] => [
  {
    field: "action",
    headerName: "Action",
    cellStyle: { paddingLeft: 0 },
    cellRenderer: ActionCellRenderer,
    comparator: actionComparatorFn(properties?.actions),
    getQuickFilterText: ({ data }) => `${data.action?.name}`,
    valueGetter: ({ data }) => data?.action?.name,
  },

  {
    field: "assignees",
    filterParams: {
      filters: [{ filter: "agTextColumnFilter" }, { filter: AssigneeFilter }],
    } as IMultiFilterParams,
    headerName: "Assignee",
    cellStyle: { paddingLeft: 0 },
    comparator: assigneesComparatorFn,
    cellRenderer: AssigneeCellRenderer,
    getQuickFilterText: taskAssigneesFilterText,
    valueGetter: taskAssigneesFilterText,
  },

  {
    field: "priority",
    headerName: "Priority",
    cellStyle: { paddingLeft: 0 },
    comparator: priorityComparatorFn(properties?.priorities),
    cellRenderer: PriorityCellRenderer,
    getQuickFilterText: ({ data }) => `${data.priority?.name}`,
    valueGetter: ({ data }: ValueGetterParams<Task>) => data?.priority?.name,
  },

  {
    field: "owner",
    headerName: "Owner Domain",
    cellStyle: { paddingLeft: 0 },
    comparator: ownerComparatorFn(properties?.owners),
    cellRenderer: OwnerCellRenderer,
    getQuickFilterText: ({ data }) => `${data.owner?.name}`,
    valueGetter: ({ data }: ValueGetterParams<Task>) => data?.owner?.name,
  },

  {
    field: "due_date",
    headerName: "Due Date",
    cellStyle: { paddingLeft: 0 },
    filter: "agDateColumnFilter",
    filterParams: {
      minValidYear: 2000,
      maxValidYear: moment().year(),
      comparator: dueDateComparatorFn,
      inRangeFloatingFilterDateFormat: "DD MMM YYYY",
    } as IDateFilterParams,
    comparator: dueDateComparatorFn,
    cellRenderer: DueDateCellRenderer,
    getQuickFilterText: taskDueDateFilterText,
    valueGetter: taskDueDateFilterText,
  },

  {
    field: "environment",
    headerName: "Environment",
    cellStyle: { paddingLeft: 0 },
    comparator: environmentComparatorFn(properties?.environments),
    cellRenderer: EnvironmentCellRenderer,
    getQuickFilterText: ({ data }) => `${data.environment?.name}`,
    valueGetter: ({ data }: ValueGetterParams<Task>) => data?.environment?.name,
  },

  {
    field: "status",
    headerName: "Status",
    cellStyle: { paddingLeft: 0 },
    cellRenderer: StatusCellRenderer,
    comparator: statusComparatorFn(properties?.statuses),
    valueGetter: ({ data }: ValueGetterParams<Task>) => data?.status?.name,
    getQuickFilterText: ({ data }) =>
      `${data.status?.name} ${data.status?.category}`,
  },
];

const taskTargetRelatedColumns: ColDef[] = [
  {
    filter: "agSetColumnFilter",
    valueGetter: ({ data }: ValueGetterParams<Task>) =>
      data?.comments_count ? "Exists" : "Doesn't Exist",
    filterParams: { values: ["Exists", "Doesn't Exist"] },
    field: "comments_count",
    headerName: "Comments",
    cellRenderer: CommentCellRenderer,
  },
  {
    valueGetter: ({ data }: ValueGetterParams<Task>) =>
      taskTargetStateLabels[data?.target?.state ?? "live"],
    filter: "agSetColumnFilter",
    field: "target.state",
    headerName: "Resource State",
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      if (!data?.target?.state) {
        return "-";
      }

      return <TargetResourceTableStateRow state={data.target.state} />;
    },
  },

  {
    field: "target.price_per_month",
    headerComponentParams: {
      innerHeaderComponent: MonthlyPriceInnerHeader,
    },
    filter: "agNumberColumnFilter",
    headerName: "Monthly Price",
    valueGetter: ({ data }: ValueGetterParams<Task>) =>
      data?.target?.price_per_month,
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      if (!data?.target) {
        return null;
      }

      return (
        <Typography variant="body2">
          {money(data.target.price_per_month)}
        </Typography>
      );
    },
  },

  {
    field: "target.account",
    headerName: "Account",
    valueGetter: taskTargetAccountFilterText,
    getQuickFilterText: taskTargetAccountFilterText,
    comparator: targetAccountComparatorFn,
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      const account = data?.target?.account;

      if (!account) {
        return null;
      }

      return (
        <AccountInfoData
          accountName={account.provider_account_name}
          accountId={account.provider_account_id}
          provider={account.provider}
          nameTypographyProps={{ variant: "body2" }}
          idTypographyProps={{ variant: "caption" }}
        />
      );
    },
  },

  {
    field: "target.resource_type",
    headerName: "Service",
    valueGetter: ({ data }: ValueGetterParams<Task>) =>
      data?.target?.resource_type &&
      getResourceTypeName(data?.target?.resource_type, {
        type: "long",
        singular: true,
      }),
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      const target = data?.target;
      const resourceType = target?.resource_type;

      if (!resourceType) {
        return null;
      }

      return <ServiceCell service={resourceType} />;
    },
  },

  {
    field: "target.tags",
    maxWidth: 300,
    filter: "agTextColumnFilter",
    headerName: "Tags / Labels",
    comparator: targetTagComparatorFn,
    getQuickFilterText: taskTargetTagFilterText,
    valueGetter: taskTargetTagFilterText,
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      if (!data) {
        return null;
      }

      if (!data?.target?.tags) {
        return "-";
      }

      return <TagCell tags={data.target.tags} />;
    },
  },

  {
    field: "source",
    valueGetter: ({ data }: ValueGetterParams<Task>) =>
      data?.source && taskSourceLabels[data?.source],
    headerName: "Source",
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      if (!data) {
        return null;
      }

      if (!data?.source) {
        return "-";
      }

      return (
        <Typography variant="body2">{taskSourceLabels[data.source]}</Typography>
      );
    },
  },

  {
    field: "created_by",
    width: 300,
    maxWidth: 300,
    headerName: "Task Created By",
    filterValueGetter: taskCreatedByFilterValueGetter,
    comparator: createdAtComparatorFn,
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      if (!data) {
        return null;
      }

      const details = data?.created_by;

      if (!details) {
        return "-";
      }

      return (
        <UserActivityCell
          date={data.created_at}
          name={details?.name ?? details?.email ?? ""}
          deleted={details?.status === "deleted"}
          profile={details?.profile}
        />
      );
    },
  },

  {
    field: "modified_by",
    valueGetter: ({ data }: ValueGetterParams<Task>) =>
      data?.modified_by?.name ?? data?.modified_by?.email,
    width: 300,
    maxWidth: 300,
    headerName: "Last Modified By",
    filterValueGetter: taskModifiedByFilterValueGetter,
    comparator: modifiedAtComparatorFn,
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      if (!data) {
        return null;
      }

      const details = data?.modified_by;

      if (!details) {
        return "-";
      }

      return (
        <UserActivityCell
          date={data.modified_at ?? undefined}
          name={details?.name ?? details?.email ?? ""}
          deleted={details?.status === "deleted"}
          profile={details?.profile}
        />
      );
    },
  },
];

const endColumns: ColDef[] = [
  agGridEmptyColumnToFitEmptySpace,
  {
    colId: "actions",
    maxWidth: 70,
    width: 70,
    minWidth: 70,
    pinned: "right",
    lockPinned: true,
    sortable: false,
    filter: false,
    enableRowGroup: false,
    cellDataType: false,
    resizable: false,
    unSortIcon: false,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
    cellRenderer: TaskGridRowActionsMenu,
  },
];

export const getTasksGridColumns = (
  properties?: GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse,
): ColDef[] => {
  return [
    nameColumn,
    resourceIdColumn,
    ...getTaskRelatedColumns(properties),
    ...taskTargetRelatedColumns,
    ...endColumns,
  ];
};
