import { FC } from "react";
import { CostAnomalyPreviewTableHeaderCell } from "./CostAnomalyPreviewTableHeaderCell";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { alertsCostAnomalyDataGroupingSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataGroupingSelector";
import { costAnomalyPreviewDataGridDataLengthSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyPreviewDataGridDataLengthSelector";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";

export const CostAnomalyPreviewFieldHeaderCell: FC = () => {
  const count = useAppSelector(costAnomalyPreviewDataGridDataLengthSelector);
  const groupBy = useAppSelector(alertsCostAnomalyDataGroupingSelector);
  const groupingLabel = useAppSelector((state) =>
    resourceExplorerGroupingLabelByGroupingSelector(state, groupBy),
  );

  return (
    <CostAnomalyPreviewTableHeaderCell
      title={groupingLabel?.plural ?? ""}
      description={count?.toString() ?? "0"}
    />
  );
};
