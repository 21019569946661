import { createAsyncThunk } from "@reduxjs/toolkit";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { getResourceExplorerPreferencesKey } from "../../../../../../components/pages/resource-explorer/utils/hooks/useResourceExplorerPreferences.hook";
import { billingExplorerWidgetSetupDefaultData } from "../../../../utils/constants/billingExplorerWidgetSetupDefaultData";
import { billingExplorerWidgetSetupSelector } from "../../../../selectors/setups/billing-explorer/billingExplorerWidgetSetupSelector";
import { getResourceExplorerViewByIdThunk } from "../../../../../common/thunks/resource-explorer/getResourceExplorerViewByIdThunk";
import { getBillingExplorerWidgetSetupDataThunk } from "../getBillingExplorerWidgetSetupDataThunk";

export const billingExplorerREViewChangeThunk = createAsyncThunk(
  "dashboards/billingExplorerWidgetREViewChange",
  async (viewId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = billingExplorerWidgetSetupSelector(state);

    if (!setup) {
      return;
    }

    dispatch(setWidgetSetup({ ...setup, viewId, loading: true }));

    const { getUsersMeCurrentPreferenceByKey } = cloudChiprApi.endpoints;

    const response = await dispatch(
      getResourceExplorerViewByIdThunk({ viewId }),
    ).unwrap();

    const key = getResourceExplorerPreferencesKey(viewId);

    let preference;
    try {
      preference = await dispatch(
        getUsersMeCurrentPreferenceByKey.initiate({ key }),
      ).unwrap();
    } catch (e) {
      preference = null;
    }

    const viewType = preference?.value
      ? JSON.parse(preference?.value)?.chart?.type
      : "stack";

    if (!response) {
      return;
    }

    const { data } = response;

    dispatch(
      setWidgetSetup({
        ...(setup ?? billingExplorerWidgetSetupDefaultData),
        loading: true,
        grouping: data.grouping === "resource" ? "service" : data.grouping,
        group_values: data.group_values,
        frequency: data.data_point,
        viewId: viewId,
        //TODO: EnableWidgetsSideBarConfigurations, remove viewType
        viewType:
          viewType ??
          billingExplorerWidgetSetupDefaultData.visualization.chart_type,
        visualization: {
          ...billingExplorerWidgetSetupDefaultData.visualization,
          chart_type:
            viewType ??
            billingExplorerWidgetSetupDefaultData.visualization.chart_type,
        },
        trendType: data.trend_type ?? "month_over_month",
        dates: data.dates ?? {},
      }),
    );

    await dispatch(getBillingExplorerWidgetSetupDataThunk());

    const updatedSetup = billingExplorerWidgetSetupSelector(
      getState() as RootState,
    );
    if (updatedSetup) {
      dispatch(setWidgetSetup({ ...updatedSetup, loading: false }));
    }
  },
);
