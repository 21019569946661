import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import moment from "moment/moment";

import { ProviderIcon } from "../../../../../../../common/ProviderIcon";
import {
  TrendChip,
  trendColors,
  TrendTypes,
} from "../../../../../../../common/chips/TrendChip";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { money } from "../../../../../../../../utils/numeral/money";
import AnomalySvgIcon from "../../../../../../../../assets/images/icons/AnomalySpikeSvgIcon";
import {
  ProviderType,
  RepresentsASingleCostSpike,
} from "../../../../../../../../services/cloudchipr.api";

interface BillingExplorerExplainWithAiAnomaliesSpikeHeaderTitleProps
  extends RepresentsASingleCostSpike {
  provider: ProviderType;
}

export const BillingExplorerExplainWithAiAnomaliesSpikeHeaderTitle: FC<
  BillingExplorerExplainWithAiAnomaliesSpikeHeaderTitleProps
> = ({ provider, spike_date, change, trend_percentage, id }) => {
  let trend: TrendTypes = "negative";
  if ((trend_percentage ?? 0) < 0) {
    trend = "positive";
  }

  if ((trend_percentage ?? 0) === 0) {
    trend = "neutral";
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="row" spacing={1}>
        <AnomalySvgIcon color="error" />
        <ProviderIcon provider={provider} />
        <Typography variant="subtitle2" fontWeight="normal">
          Spike on{" "}
          <Typography variant="subtitle2" fontWeight="bold" component="span">
            {" "}
            {moment(spike_date).format("MMMM D, YYYY")}
          </Typography>
        </Typography>
      </Stack>

      <Stack
        sx={{
          maxWidth: "40%",
        }}
      >
        <TypographyWithTooltip
          title={money(change)}
          variant="body2"
          fontWeight="medium"
        >
          <Typography
            component="span"
            color={trendColors.get(trend)?.color}
            variant="inherit"
            fontWeight="inherit"
          >
            {trend === "positive" && "- "}
            {trend === "negative" && "+ "}
          </Typography>
          {money(Math.abs(change))}
        </TypographyWithTooltip>
        <TrendChip value={trend_percentage} />
      </Stack>
    </Stack>
  );
};
