import { ChangeEvent, FC, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getPressEnterHandler } from "../../../../../utils/helpers/getPressEnterHandler";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";

interface AddViewInHierarchyDialogProps {
  open: boolean;
  loading: boolean;
  onClose(): void;
  onSubmit(viewName: string): Promise<any>;
}

export const AddViewInHierarchyDialog: FC<AddViewInHierarchyDialogProps> = ({
  onClose,
  open,
  loading,
  onSubmit,
}) => {
  const [viewName, setViewName] = useState<string>("");

  const changeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setViewName(event.target.value);
  }, []);

  const saveHandler = useCallback(async () => {
    if (!viewName) {
      return;
    }

    await onSubmit(viewName);
    onClose();
  }, [onClose, viewName, onSubmit]);

  const pressEnterHandler = getPressEnterHandler(
    saveHandler,
    (!viewName || loading) && open,
  );

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      onKeyDown={pressEnterHandler}
    >
      <DialogTitleClosable title="Add New View" onClose={onClose} />

      <DialogContent sx={{ color: "text.secondary", p: 2 }} dividers>
        <TextField
          fullWidth
          label="View Name"
          size="small"
          value={viewName}
          onChange={changeHandler}
        />
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button size="small" onClick={onClose} color="tertiary">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          disabled={!viewName}
          loading={loading}
          onClick={saveHandler}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
