import { billingExplorerWidgetSetupDataSelector } from "./billingExplorerWidgetSetupDataSelector";
import { RootState } from "../../../../../store";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { billingExplorerSetupPropertyByKeySelector } from "../../../setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { convertCostToPercent } from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/convertCostToPercent";
import {
  FormatedData,
  generateCombinatedChartData,
} from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/generateCombinatedChartData";
import { organisationQuarterStartMonthSelector } from "../../../../../common/selectors/org-current-settings/organisationQuarterStartMonthSelector";

export const billingExplorerWidgetSetupCombinedChartDataSelector = (
  state: RootState,
): FormatedData | null => {
  const data = billingExplorerWidgetSetupDataSelector(state)?.data;
  const frequency =
    billingExplorerSetupPropertyByKeySelector("frequency")(state);
  const quarterStartMonth = organisationQuarterStartMonthSelector(state);

  if (!data) {
    return null;
  }

  const formatedData = formatResourceExplorerChartData(
    data,
    frequency,
    quarterStartMonth,
  );
  const percentData = convertCostToPercent(formatedData);

  return generateCombinatedChartData(formatedData, percentData);
};
