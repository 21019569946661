import { FC, Fragment, useCallback } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { HierarchyViewDuplicateActionDialog } from "./HierarchyViewDuplicateActionDialog";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";

interface HierarchyViewDuplicateActionProps {
  onClose(): void;
  viewId: string;
  viewName: string;
}

export const HierarchyViewDuplicateAction: FC<
  HierarchyViewDuplicateActionProps
> = ({ viewId, onClose, viewName }) => {
  const { open, openDialog, closeDialog } = useDialog();

  const closeHandler = useCallback(() => {
    closeDialog();
    onClose();
  }, [onClose, closeDialog]);

  return (
    <Fragment>
      <MenuItem onClick={openDialog}>
        <ListItemIcon>
          <ContentCopyIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2">Duplicate</Typography>
        </ListItemText>
      </MenuItem>

      <HierarchyViewDuplicateActionDialog
        viewId={viewId}
        open={open}
        onClose={closeHandler}
        viewName={viewName}
      />
    </Fragment>
  );
};
