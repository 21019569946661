import { FC, useMemo } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { CostAllocationTagItem } from "./CostAllocationTagItem";
import {
  ProviderType,
  useGetUsersMeOrganisationsCurrentProvidersByProviderTagKeysQuery,
} from "../../../../../../../../../../../services/cloudchipr.api";

interface CostAllocationTagsDrawerBodyProps {
  provider: ProviderType;
  searchValue: string;
}

export const CostAllocationTagsDrawerBody: FC<
  CostAllocationTagsDrawerBodyProps
> = ({ searchValue, provider }) => {
  const { data, isLoading } =
    useGetUsersMeOrganisationsCurrentProvidersByProviderTagKeysQuery(
      {
        provider,
      },
      { refetchOnMountOrArgChange: true },
    );

  const tags = useMemo(() => {
    return [...(data ?? [])].sort((a, b) => {
      if (a.is_active !== b.is_active) {
        return a.is_active ? -1 : 1;
      }

      return a.key.localeCompare(b.key);
    });
  }, [data]);

  const filteredTags = useMemo(() => {
    return tags.filter((tag) =>
      tag.key.toLowerCase().includes(searchValue.trim().toLowerCase()),
    );
  }, [tags, searchValue]);

  return (
    <Box borderRadius={1} overflow="auto">
      <Stack border={1} borderColor="grey.300">
        <Typography bgcolor="grey.100" fontWeight="medium" px={2} py={1.5}>
          Tag Key
        </Typography>

        {isLoading ? (
          <Stack alignItems="center" p={4}>
            <CircularProgress size={24} />
          </Stack>
        ) : (
          filteredTags?.map((tag) => (
            <CostAllocationTagItem
              tag={tag}
              key={tag.key}
              provider={provider}
            />
          ))
        )}

        {!!tags?.length && !filteredTags.length && (
          <Typography p={2} align="center">
            No tag keys found for “{searchValue}”
          </Typography>
        )}
      </Stack>
    </Box>
  );
};
