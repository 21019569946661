import { IconButton, Stack } from "@mui/material";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { FC } from "react";
import { ConnectDragSource } from "react-dnd";
import { ProviderType } from "../../../../../../../../services/cloudchipr.api";
import { ProviderIcon } from "../../../../../../../common/ProviderIcon";
import { AccountCardHeaderTitle } from "../../../../accounts/account-card/AccountCardHeaderTitle";

interface KubernetesClusterCardHeaderTitleProps {
  clusterId?: string;
  provider: ProviderType;
  dragRef: ConnectDragSource | null;
  disableDnD?: boolean;
  name: string;
}

export const KubernetesClusterCardHeaderTitle: FC<
  KubernetesClusterCardHeaderTitleProps
> = ({ name, clusterId, provider, disableDnD, dragRef }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <IconButton
        ref={dragRef}
        sx={{ cursor: "grab" }}
        disabled={disableDnD}
        size="small"
      >
        <DragIndicatorIcon color="action" fontSize="small" />
      </IconButton>
      <ProviderIcon width={24} provider="kubernetes" />
      <AccountCardHeaderTitle name={name || ""} accountId={clusterId || ""} />
    </Stack>
  );
};
