import { FC, useCallback } from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";

import { MetricsStatsType } from "../../../../utils/types/metrics";

interface ResourceDetailsMetricsContentMetricStatsSelectProps {
  metricsStats: MetricsStatsType;
  setMetricsStats(m: MetricsStatsType): void;
}

export const ResourceDetailsMetricsContentMetricStatsSelect: FC<
  ResourceDetailsMetricsContentMetricStatsSelectProps
> = ({ metricsStats, setMetricsStats }) => {
  const chartTypeChangeHandler = useCallback(
    (event: any) => {
      setMetricsStats(event.target.value as MetricsStatsType);
    },
    [setMetricsStats],
  );

  return (
    <FormControl size="small" sx={selectStyles}>
      <Select
        size="small"
        value={metricsStats}
        onChange={chartTypeChangeHandler}
        MenuProps={{ disablePortal: true }}
      >
        <MenuItem value="minimum">
          <Typography variant="body2" pl={1} pt={0.25}>
            Min
          </Typography>
        </MenuItem>
        <MenuItem value="maximum">
          <Typography variant="body2" pl={1} pt={0.25}>
            Max
          </Typography>
        </MenuItem>
        <MenuItem value="average">
          <Typography variant="body2" pl={1} pt={0.25}>
            Average
          </Typography>
        </MenuItem>
        <MenuItem value="sum">
          <Typography variant="body2" pl={1} pt={0.25}>
            Sum
          </Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const selectStyles = {
  minWidth: 95,
  border: 1,
  borderRadius: 1,
  color: "grey.400",
  bgcolor: "white",
  "& .MuiSelect-select": { p: 0.5 },
  "& fieldset": { border: "none" },
  "&:hover fieldset": { border: "none" },
  "&.Mui-focused fieldset": { border: "none" },
};
