import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { FC, Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useFlag } from "@unleash/proxy-client-react";
import { IntegrationRemoveAction } from "./IntegrationsRemoveAction";
import {
  Integration,
  useGetUsersMeIntegrationsQuery,
} from "../../../../../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../../../../../services/permissions";
import { useMenuHook } from "../../../../../../../../../../utils/hooks/useMenu.hook";
import { setActiveIntegration } from "../../../../../../../../../../store/integrations/integrationsSlice";
import { useAppDispatch } from "../../../../../../../../../../store/hooks";

interface IntegrationsListActionCellProps {
  integrationId: string;
  type: Integration["type"];
  name: string;
}

export const IntegrationsActionCell: FC<IntegrationsListActionCellProps> = ({
  integrationId,
  type,
  name,
}) => {
  const enableRedesignedIntegrations = useFlag("EnableRedesignedIntegrations");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { cannot } = useAppAbility();
  const canNotEditIntegration = cannot("edit", "integration");
  const { refetch: refetchAll } = useGetUsersMeIntegrationsQuery({});

  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const editHandler = useCallback(() => {
    if (enableRedesignedIntegrations) {
      dispatch(setActiveIntegration({ type, id: integrationId }));
      closeMenu();
    } else {
      navigate(`/integrations/edit/${type}/${integrationId}`);
    }
  }, [
    enableRedesignedIntegrations,
    type,
    integrationId,
    dispatch,
    closeMenu,
    navigate,
  ]);

  const closeHandler = useCallback(() => {
    closeMenu();
    refetchAll();
  }, [closeMenu, refetchAll]);

  return (
    <Fragment>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchor} open={open} onClose={closeHandler}>
        <MenuItem
          dense
          sx={{ minWidth: 180 }}
          onClick={editHandler}
          disabled={canNotEditIntegration}
        >
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>

        <IntegrationRemoveAction
          type={type}
          name={name}
          integrationId={integrationId}
          closeMenu={closeHandler}
        />
      </Menu>
    </Fragment>
  );
};
