import { CommitmentCoverageMetadata } from "../../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../../store";
import { azureUtilizationAndCoverageDetailsDataSelector } from "../data/azureUtilizationAndCoverageDetailsDataSelector";

export const azureSavingsPlanCoverageDetailsMetaDataSelector = (
  state: RootState,
): CommitmentCoverageMetadata | undefined => {
  return azureUtilizationAndCoverageDetailsDataSelector(state)
    ?.metadata as CommitmentCoverageMetadata;
};
