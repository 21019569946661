import { FC } from "react";
import { Skeleton, Typography, TypographyProps } from "@mui/material";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector } from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector";
import { getResourceTypeName } from "../../../../../../../../../../utils/helpers/resources/getResourceTypeName";

interface KubernetesLiveUsageMgmtResourcesCountProps {
  resourceType: ResourceType;
  dataCount?: number;
  isLoading?: boolean;
  totalDataCount?: number;
  filtersApplied?: boolean;
  color?: TypographyProps["color"];
}

export const KubernetesLiveUsageMgmtResourcesCount: FC<
  KubernetesLiveUsageMgmtResourcesCountProps
> = ({
  resourceType,
  dataCount,
  isLoading,
  totalDataCount,
  filtersApplied,
  color,
}) => {
  const withoutFilters = useAppSelector((state) =>
    kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector(
      state,
      resourceType,
    ),
  );
  const filterTemplatesEnabled = useAppSelector(
    kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector,
  );

  if (!isLoading && (totalDataCount === undefined || dataCount === undefined)) {
    return null;
  }

  if (!isLoading && !totalDataCount) {
    return (
      <Typography variant="body2" color={color}>
        No available {getResourceTypeName(resourceType)} found
      </Typography>
    );
  }

  if (!filtersApplied || withoutFilters || !filterTemplatesEnabled) {
    return (
      <Typography variant="body2" display="flex" color={color}>
        Showing all{" "}
        <Typography
          mx={0.5}
          component="span"
          fontWeight="bold"
          fontSize="inherit"
        >
          {isLoading ? LoadingState : totalDataCount}
        </Typography>{" "}
        available{" "}
        {getResourceTypeName(resourceType, {
          singular: totalDataCount === 1,
        })}
      </Typography>
    );
  }

  return (
    <Typography variant="body2" display="flex" color={color}>
      Showing
      <Typography
        mx={0.5}
        component="span"
        fontWeight="bold"
        fontSize="inherit"
      >
        {isLoading ? LoadingState : dataCount}
      </Typography>
      of
      <Typography
        mx={0.5}
        component="span"
        fontWeight="bold"
        fontSize="inherit"
      >
        {isLoading ? LoadingState : totalDataCount}
      </Typography>
      {`available ${getResourceTypeName(resourceType, {
        singular: totalDataCount === 1,
      })}`}
    </Typography>
  );
};

const LoadingState = (
  <Skeleton
    variant="text"
    height={20}
    width={30}
    sx={{ display: "inline-block" }}
  />
);
