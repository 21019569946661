import { RootState } from "../../../../../../store";
import { AzureReservation } from "../../../../../../../services/cloudchipr.api";
import { azureReservationsDataSelector } from "../../data/azureReservationsDataSelector";
import { commitmentsDetailsDrawerTargetIdSelector } from "../../../../common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";

export const azureSelectedReservationDataSelector = (
  state: RootState,
): AzureReservation | undefined => {
  const data = azureReservationsDataSelector(state);
  const id = commitmentsDetailsDrawerTargetIdSelector(state);

  return data?.find((sp) => sp.provider_unique_id === id);
};
