import { resourceDetailsResourceBillingRequestSelector } from "./requests/resourceDetailsResourceBillingRequestSelector";
import { ResourceExplorerWidget } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const resourceDetailsResourceBillingDataSelector = (
  state: RootState,
  processKey: string,
): ResourceExplorerWidget | undefined => {
  return resourceDetailsResourceBillingRequestSelector(state, processKey)?.data;
};
