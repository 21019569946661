import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { azureReservationsGranularDataSelector } from "./azureReservationsGranularDataSelector";
import { CommitmentDateGranularDataPoint } from "../../../../../../../services/cloudchipr.api";

export const azureReservationsGranularDataGroupedByDateSelector =
  createDraftSafeSelector(
    [azureReservationsGranularDataSelector],
    (data): Record<string, CommitmentDateGranularDataPoint> | undefined => {
      return data?.reduce(
        (acc, data) => {
          acc[data.date] = data;

          return acc;
        },
        {} as Record<string, CommitmentDateGranularDataPoint>,
      );
    },
  );
