import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { AzureCommitmentsOverviewMetadata } from "./metadata/AzureCommitmentsOverviewMetadata";
import { AzureCommitmentsOverviewContent } from "./content/AzureCommitmentsOverviewContent";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { commitmentsCurrentOrgIdSelector } from "../../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { getAzureCommitmentsOverviewThunk } from "../../../../../store/commitments/thunks/azure/overview/getAzureCommitmentsOverviewThunk";

export const AzureCommitmentsOverview: FC = () => {
  const dispatch = useAppDispatch();

  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);

  useEffect(() => {
    dispatch(getAzureCommitmentsOverviewThunk());
  }, [dispatch, orgId]);

  return (
    <Stack spacing={1}>
      <AzureCommitmentsOverviewMetadata />
      <AzureCommitmentsOverviewContent />
    </Stack>
  );
};
