import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getResourceExplorerPossibleGroupingsThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerPossibleGroupings",
  async (_, { dispatch, getState }) => {
    const { getUsersMeOrganisationsCurrentResourceExplorerPossibleGroupings } =
      cloudChiprApi.endpoints;

    try {
      dispatch(
        getUsersMeOrganisationsCurrentResourceExplorerPossibleGroupings.initiate(),
      );
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
