import { FC, Fragment, useCallback } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { Budget } from "../../../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { toggleAlertsOpenDrawer } from "../../../../../../../../store/alerts/alertsSlice";
import { initiateBudgetByIdThunk } from "../../../../../../../../store/budgets/thunks/initiateBudgetByIdThunk";

export const BudgetsNameCell: FC<ICellRendererParams<Budget>> = ({
  data: budget,
}) => {
  const id = budget?.id;
  const name = budget?.name;

  const dispatch = useAppDispatch();

  const { can } = useAppAbility();
  const canEditBudget = can("edit", "budget");

  const handleClick = useCallback(() => {
    if (!id) {
      return;
    }

    dispatch(toggleAlertsOpenDrawer());
    dispatch(initiateBudgetByIdThunk(id));
  }, [dispatch, id]);

  if (!id || !name) {
    return;
  }

  return (
    <Fragment>
      {canEditBudget ? (
        <TypographyWithTooltip
          fontWeight="regular"
          color="primary"
          display="block"
          width="fit-content"
          onClick={handleClick}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
          variant="body2"
          title={name}
        />
      ) : (
        <TypographyWithTooltip maxWidth={200} variant="body2" title={name} />
      )}
    </Fragment>
  );
};
