import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setResourceDetailsAvailableTabs,
  setResourceDetailsOpenedFrom,
  setResourceDetailsTarget,
} from "../resourceDetailsSlice";
import {
  PossibleSourcesForResourceDetails,
  ResourceDetailsActiveTab,
  ResourceDetailsInterface,
} from "../utils/types/common";

type ThunkArgs = {
  openedFrom: PossibleSourcesForResourceDetails;
  availableTabs: ResourceDetailsActiveTab[];
} & ResourceDetailsInterface["target"];

export const initiateResourceDetailsDrawerThunk = createAsyncThunk(
  "resourceDetails/initiateResourceDetailsDrawer",
  async (
    {
      openedFrom,
      resource,
      resourceAdditionalDetails,
      availableTabs,
    }: ThunkArgs,
    { dispatch },
  ) => {
    dispatch(setResourceDetailsOpenedFrom(openedFrom));
    dispatch(setResourceDetailsAvailableTabs(availableTabs));
    dispatch(setResourceDetailsTarget({ resource, resourceAdditionalDetails }));
  },
);
