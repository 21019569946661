import { FC, Fragment } from "react";
import AddIcon from "@mui/icons-material/Add";
import { PopoverOrigin } from "@mui/material";
import { NestedNavItem } from "../../../../app-nav-components/nested-nav-item/NestedNavItem";
import { useAppAbility } from "../../../../../../../services/permissions";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { BillingExplorerNavigationAddActionMenu } from "../BillingExplorerNavigationAddActionMenu";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";

interface AddBillingExplorerItemNavItemProps {
  visibility?: NavigationItemsVisibilityType;
}

export const AddBillingExplorerItemNavItem: FC<
  AddBillingExplorerItemNavItemProps
> = ({ visibility }) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const { cannot } = useAppAbility();

  const cannotCreateView = cannot("create", "resource-explorer");

  return (
    <Fragment>
      <NestedNavItem
        nested
        primary="Add"
        primaryTypographyProps={{ color: "primary" }}
        onButtonClick={openMenu}
        disabled={cannotCreateView}
        Icon={AddIcon}
        iconProps={{ sx: { color: "primary.main" } }}
      />

      <BillingExplorerNavigationAddActionMenu
        open={open}
        onCloseMenu={closeMenu}
        anchorEl={anchor}
        visibility={visibility}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      />
    </Fragment>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
