import {
  ResourceFilters,
  ResourceType,
} from "../../../../../../services/cloudchipr.api";
import { kubernetesResourcesHasMetricFilter } from "../constants";

export const getKubernetesResourceDefaultFiltersByResourceType = (
  resourceType: ResourceType,
): ResourceFilters | undefined => {
  if (!kubernetesResourcesHasMetricFilter.has(resourceType)) {
    return;
  }
  if (resourceType === "kubernetes_node") {
    return generateMetricFilter(resourceType, "20");
  }
  return generateMetricFilter(resourceType, "70");
};

const generateMetricFilter = (
  resourceType: ResourceType,
  metricValue: string,
): ResourceFilters => {
  return {
    filter_groups: [0, 1],
    filter_items: [
      {
        filter: {
          key: "cpu_utilization",
          metric: {
            since: {
              unit: "days",
              value: 7,
            },
            statistics_type: "maximum",
          },
          operator: "less_than_equal_to",
          value: metricValue,
        },
        type: resourceType,
      },
      {
        filter: {
          key: "memory_utilization",
          metric: {
            since: {
              unit: "days",
              value: 7,
            },
            statistics_type: "maximum",
          },
          operator: "less_than_equal_to",
          value: metricValue,
        },
        type: resourceType,
      },
    ],
    operators: ["or"],
    type: resourceType,
  };
};
