import { FC } from "react";
import { Divider, Stack, StackProps } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { LiveUsageMgmtRegionsSelect } from "./regions/LiveUsageMgmtRegionsSelect";
import { LiveUsageMissingPermissions } from "./missing-permissions/LiveUsageMissingPermissions";
import { CreateWorkflowButton } from "./create-workflow/CreateWorkflowButton";
import { LiveResourceFilters } from "../../../../common/resource-filters/LiveResourceFilters";
import { useAppSelector } from "../../../../../store/hooks";
import { pageHeaderHeight } from "../../../common/PageHeader";
import { liveUsageMgmtProviderSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { LiveUsageMgmtSelectedAccountNavigation } from "../navigation/components/selected-account/LiveUsageMgmtSelectedAccountNavigation";
import { LiveUsageFilterTemplatesSwitcher } from "../filter-templates/LiveUsageFilterTemplatesSwitcher";
import { LiveUsageFilterTemplateView } from "../filter-templates/LiveUsageFilterTemplateView";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";
import { resourceTypeSearchParam } from "../../utils/constants/searchParams";
import { LiveUsageNavigationResourceType } from "../navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { liveUsageMgmtAppliedAllFiltersCountSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAppliedAllFiltersCountSelector";
import { liveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector } from "../../../../../store/live-usage-mgmt/selectors/liveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector";
import { LiveResourceFiltersContent } from "../../../../common/resource-filters/components/content/LiveResourceFiltersContent";

export const LiveUsageHeader: FC = () => {
  const [searchParams] = useSearchParams();
  const searchParamsResourceType = searchParams.get(
    resourceTypeSearchParam,
  ) as LiveUsageNavigationResourceType;

  const provider = useAppSelector(liveUsageMgmtProviderSelector);
  const filterTemplatesEnabled = useAppSelector(
    liveUsageMgmtFilterTemplatesEnabledSelector,
  );

  const filtersCount = useAppSelector(
    liveUsageMgmtAppliedAllFiltersCountSelector,
  );

  const resourceTypeFiltersCountMap = useAppSelector(
    liveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector,
  );

  if (!provider) {
    return null;
  }

  return (
    <Stack
      zIndex="appBar"
      bgcolor="white"
      direction="row"
      spacing={0.5}
      pr={0.5}
      borderBottom={1}
      alignItems="center"
      borderColor="grey.300"
      height={pageHeaderHeight}
    >
      <LiveUsageMgmtSelectedAccountNavigation />

      <Stack
        direction="row"
        flex={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          border={1}
          pr={1}
          direction="row"
          borderRadius={2}
          alignItems="center"
          borderColor="grey.100"
        >
          <LiveUsageFilterTemplatesSwitcher />

          {divider}

          <Stack
            {...flexProps}
            divider={divider}
            overflow="hidden"
            spacing={1}
            sx={{
              pointerEvents: filterTemplatesEnabled ? "inherit" : "none",
              opacity: filterTemplatesEnabled ? 1 : 0.5,
            }}
          >
            <LiveUsageFilterTemplateView />

            <LiveResourceFilters
              provider={provider}
              resourceType={searchParamsResourceType}
              filtersCount={filtersCount}
              resourceTypeFiltersCountMap={resourceTypeFiltersCountMap}
              FiltersContent={LiveResourceFiltersContent}
            />
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <LiveUsageMissingPermissions />

          <LiveUsageMgmtRegionsSelect />

          <CreateWorkflowButton />
        </Stack>
      </Stack>
    </Stack>
  );
};

const divider = (
  <Divider orientation="vertical" flexItem sx={{ borderColor: "grey.100" }} />
);

const flexProps: StackProps = {
  direction: "row",
  alignItems: "center",
  justifyContent: "space-between",
};
