import { FC, useCallback, useMemo, useState } from "react";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { ResourceDetailsContentCollapsableCard } from "../../ResourceDetailsContentCollapsableCard";
import { TypographyWithCopy } from "../../../../../../../common/TypographyWithCopy";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { resourceDetailsTargetResourceSelector } from "../../../../../../../../store/resource-details/selectors/target-resource/resourceDetailsTargetResourceSelector";
import { Tags } from "../../../../../../../../services/cloudchipr.api";

interface ResourceDetailsGeneralContentTagsProps {
  label: string;
  tags?: Tags;
}

export const ResourceDetailsGeneralContentTags: FC<
  ResourceDetailsGeneralContentTagsProps
> = ({ label, tags }) => {
  const [value, setValue] = useState("");

  const resource = useAppSelector(resourceDetailsTargetResourceSelector);
  const provider = resource?.provider;

  const filteredTags = useMemo(() => {
    return tags?.filter((tag) =>
      `${tag.key} ${tag.value}`.toLowerCase().includes(value.toLowerCase()),
    );
  }, [value, tags]);

  const handleChange = useCallback((event: any) => {
    setValue(event.target.value);
  }, []);

  return (
    <ResourceDetailsContentCollapsableCard
      title={label}
      Icon={<DiscountOutlinedIcon fontSize="small" />}
    >
      <TextField
        fullWidth
        size="small"
        placeholder="Search Tags"
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <TableContainer
        sx={{ border: 1, borderColor: "grey.300", borderRadius: 2, mt: 1.5 }}
      >
        <Table size="small" sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow sx={{ bgcolor: "grey.100" }}>
              <TableCell width="50%">Key</TableCell>
              <TableCell width="50%">Value</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!filteredTags?.length && provider && (
              <TableRow>
                <TableCell colSpan={2} align="center" sx={{ py: 2 }}>
                  No {label}
                </TableCell>
              </TableRow>
            )}

            {filteredTags?.map((tag) => {
              return (
                <TableRow key={tag.key}>
                  <TableCell>
                    <TypographyWithCopy value={tag.key} variant="body2" />
                  </TableCell>

                  <TableCell>
                    <TypographyWithCopy value={tag.value} variant="body2" />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ResourceDetailsContentCollapsableCard>
  );
};
