import { FC, ReactNode, useMemo } from "react";
import { TooltipProps } from "recharts";
import { Stack, Typography } from "@mui/material";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import { ChartsTooltipContentItem } from "./ChartsTooltipContentItem";
import { money } from "../../../../../utils/numeral/money";
import { ChartDataType, ReferenceLineType } from "../../utils/types/types";

export type ChartsTooltipProps = {
  hideTooltip?: boolean;
  showTotal?: boolean;
  showReferenceLinesInTooltip?: boolean;
  totalTitle?: string;
  tooltipValueFormatter?(data: Payload<any, any>): ReactNode;
  tooltipValueNameFormatter?(name: string): ReactNode;
  tooltipLabelFormatter?(label: string): ReactNode;
  tooltipTotalCostFormatter?(data?: ChartDataType): ReactNode;
};

type ChartsTooltipContentProps = {
  hoveredKey?: string;
  referenceLines?: ReferenceLineType[];
} & ChartsTooltipProps &
  TooltipProps<any, any>;

export const ChartsTooltipContent: FC<ChartsTooltipContentProps> = ({
  label,
  payload,
  hoveredKey,
  showTotal,
  totalTitle,
  hideTooltip,
  referenceLines,
  showReferenceLinesInTooltip,
  tooltipValueFormatter,
  tooltipLabelFormatter,
  tooltipValueNameFormatter,
  tooltipTotalCostFormatter,
}) => {
  const data = payload?.[0]?.payload as ChartDataType;

  const mappingData = useMemo(() => {
    return hoveredKey
      ? payload?.filter((item) => item.name === hoveredKey)
      : payload;
  }, [hoveredKey, payload]);

  if (!data || hideTooltip) {
    return null;
  }

  const color = payload?.find(({ dataKey }) => dataKey === hoveredKey)?.color;
  const total = Object.values(data).reduce((sum, val) => {
    if (typeof val === "number" && typeof sum === "number") {
      sum += val;
    }

    return sum;
  }, 0);

  return (
    <Stack sx={ChartsTooltipContentStyles}>
      <Typography variant="caption" pl={0.5} color="text.secondary">
        {tooltipLabelFormatter ? tooltipLabelFormatter(label) : label}
      </Typography>

      {mappingData?.map((data) => {
        return (
          <ChartsTooltipContentItem
            key={data.name}
            data={data}
            mainColor={color}
            tooltipValueFormatter={tooltipValueFormatter}
            tooltipValueNameFormatter={tooltipValueNameFormatter}
          />
        );
      })}

      {showTotal && (
        <Stack direction="row" justifyContent="space-between" pt={1}>
          <Typography
            variant="caption"
            color="text.primary"
            fontWeight="medium"
          >
            {totalTitle || "Total Cost"}
          </Typography>
          <Typography
            variant="caption"
            color="text.primary"
            fontWeight="medium"
          >
            {tooltipTotalCostFormatter
              ? tooltipTotalCostFormatter(data)
              : money(total)}
          </Typography>
        </Stack>
      )}

      {showReferenceLinesInTooltip &&
        !hoveredKey &&
        referenceLines?.map((referenceLine) => {
          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              key={(referenceLine.name ?? "") + referenceLine.y}
            >
              <Typography variant="caption" color={referenceLine.stroke}>
                {referenceLine.name}:
              </Typography>
              <Typography
                variant="caption"
                color={referenceLine.stroke}
                fontWeight="bold"
              >
                {money(referenceLine.y)}
              </Typography>
            </Stack>
          );
        })}
    </Stack>
  );
};

export const ChartsTooltipContentStyles = {
  p: 1,
  border: 1,
  borderRadius: 3,
  color: "grey.600",
  bgcolor: "grey.50",
  position: "relative",
  borderColor: "grey.300",
};
