import { navigationDashboardPrivateDataSelector } from "./navigationDashboardPrivateDataSelector";
import { RootState } from "../../../store";
import { TreeItem } from "../../../../components/app-navigation/components/sortable-tree/utils/types";

export const navigationDashboardPrivateDataItemsSelector = (
  state: RootState,
): TreeItem[] => {
  const data = navigationDashboardPrivateDataSelector(state);
  return (
    data?.reduce((result, hierarchyItem) => {
      if (hierarchyItem.type === "item") {
        result.push(hierarchyItem);
      } else if (hierarchyItem.items) {
        result = result.concat(hierarchyItem.items);
      }
      return result;
    }, [] as TreeItem[]) ?? []
  );
};
