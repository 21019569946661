import { formatBillingExplorerSummaryDataGettingPayload } from "./utils/helpers";
import { addFilterTreePathToResourceExplorerPayload } from "../../utils/helpers/addFilterTreePathToResourceExplorerPayload";
import { RootState } from "../../../store";
import { resourceExplorerPayloadDataSelector } from "../current-resource-explorer/resource-explorer-widget/resourceExplorerPayloadDataSelector";

export const getBillingExplorerSummaryFixedCashKeySelector = (
  state: RootState,
): string | undefined => {
  const resourceExplorerDataGettingPayload =
    resourceExplorerPayloadDataSelector(state);
  const payload = addFilterTreePathToResourceExplorerPayload(
    resourceExplorerDataGettingPayload,
  );

  return JSON.stringify(
    formatBillingExplorerSummaryDataGettingPayload(payload),
  );
};
