import { MetricChartDataType, MetricType } from "../types";

const getMaxMinPoint = (type: "max" | "min", data?: MetricChartDataType) => {
  return (
    data?.reduce((max: number | null, point) => {
      const value = point.value === null ? null : +point.value;

      if (value === null) {
        return max;
      }

      if (!max) {
        return value;
      }

      return type === "max" ? Math.max(value, max) : Math.min(value, max);
    }, null) ?? null
  );
};

const getMaxPoint = (data?: MetricChartDataType) => getMaxMinPoint("max", data);
const getMinPoint = (data?: MetricChartDataType) => getMaxMinPoint("min", data);

const getSumPoint = (data?: MetricChartDataType) => {
  const containsNotNullValues = data?.some(({ value }) => value !== null);

  if (!containsNotNullValues) {
    return null;
  }

  return (
    data?.reduce((sum, point) => {
      const value = point.value === null ? null : +point.value;

      if (value === null) {
        return sum;
      }

      return value + sum;
    }, 0) ?? null
  );
};

export const pointGetterByType: Record<
  MetricType,
  (data?: MetricChartDataType) => number | null
> = {
  compute: getMaxPoint,
  database: getSumPoint,
  storage: getSumPoint,
  memory_used: getMaxPoint,
  processed_bytes: getSumPoint,
  memory_available: getMinPoint,
};
