import { FC, Fragment } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { Button } from "@mui/material";
import { CommentCellPopover } from "./components/CommentCellPopover";
import { Task } from "../../../../../../../services/cloudchipr.api";
import CommentSvgIcon from "../../../../../../../assets/images/icons/CommentSvgIcon";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";

export const CommentCellRenderer: FC<CustomCellRendererProps<Task>> = ({
  data,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  return (
    <Fragment>
      <Button
        variant="text"
        color="tertiary"
        sx={{ buttonStyles, bgcolor: open ? "grey.200" : undefined }}
        onClick={openMenu}
        startIcon={<CommentSvgIcon fontSize="small" />}
      >
        {data?.comments_count ?? 0}
      </Button>
      {open && (
        <CommentCellPopover
          open
          anchor={anchor}
          closeMenu={closeMenu}
          taskId={data?.id ?? ""}
          commentsCount={data?.comments_count ?? 0}
        />
      )}
    </Fragment>
  );
};

const buttonStyles = { color: "text.primary" };
