import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getExecutionLogThunk } from "../../../../store/execution-log/thunks/getExecutionLogThunk";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  resetExecutionLogs,
  setExecutionLogPagination,
} from "../../../../store/execution-log/executionLogSlice";
import { useExecutionLogPathIds } from "../utils/hooks/useExecutionLogPathIds.hook";
import { isExecutionLogDataFilteredSelector } from "../../../../store/execution-log/selectors/isExecutionLogDataFilteredSelector";

export const ExecutionLogsShowAll: FC = () => {
  const dispatch = useAppDispatch();
  const executionLogIds = useExecutionLogPathIds();
  const navigate = useNavigate();

  const isExecutionLogDataFiltered = useAppSelector(
    isExecutionLogDataFilteredSelector,
  );

  const handleClick = useCallback(() => {
    if (executionLogIds) {
      navigate("/execution-log", {
        replace: true,
      });
    }
    dispatch(resetExecutionLogs());
    dispatch(setExecutionLogPagination({ page: 0 }));
    dispatch(getExecutionLogThunk({}));
  }, [dispatch, navigate, executionLogIds]);

  if (!isExecutionLogDataFiltered && !executionLogIds) {
    return;
  }

  return (
    <Button
      size="small"
      variant="text"
      sx={buttonStyles}
      onClick={handleClick}
      startIcon={<Refresh />}
    >
      Show All
    </Button>
  );
};

const buttonStyles = {
  textTransform: "none",
  "& .MuiButton-startIcon": {
    mr: 0.5,
  },
};
