import { FC } from "react";
import { CoverageWidgetCosts } from "./CoverageWidgetCosts";
import { useAppSelector } from "../../../../../../../store/hooks";
import { coverageWidgetAggregationSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetAggregationSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { coverageWidgetDatesSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetDatesSelector";
import { coverageWidgetByWidgetIdDataSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetByWidgetIdDataSelector";

interface CoverageWidgetContentCostsProps {
  widgetId: string;
}

export const CoverageWidgetContentCosts: FC<
  CoverageWidgetContentCostsProps
> = ({ widgetId }) => {
  const aggregation = useAppSelector((state) =>
    coverageWidgetAggregationSelector(state, widgetId),
  );
  const dates = useAppSelector((state) =>
    coverageWidgetDatesSelector(state, widgetId),
  );
  const data = useAppSelector((state) =>
    coverageWidgetByWidgetIdDataSelector(state, widgetId),
  );
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  return (
    <CoverageWidgetCosts
      data={data}
      dates={dates}
      quarterStartMonth={quarterStartMonth}
      aggregation={aggregation}
    />
  );
};
