import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { IntegrationsInUseCell } from "./IntegrationsInUseCell";
import { CommunicationIntegrationsGridRowType } from "../../../../utils/types/common";

export const IntegrationsInUseCellRenderer: FC<
  ICellRendererParams<CommunicationIntegrationsGridRowType>
> = ({ data }) => {
  const usages = data?.usages;

  if (!usages) {
    return "-";
  }

  return <IntegrationsInUseCell usages={usages} />;
};
