import React, { FC } from "react";
import { Link, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Chip from "@mui/material/Chip";
import { KubernetesClusterAddDrawerContentInfoAlert } from "./KubernetesClusterAddDrawerContentInfoAlert";
import { KubernetesClusterAddDrawerContentFirstStep } from "./KubernetesClusterAddDrawerContentFirstStep";
import { KubernetesClusterAddDrawerContentSecondStep } from "./KubernetesClusterAddDrawerContentSecondStep";
import { KubernetesClusterAddDrawerContentThirdStep } from "./KubernetesClusterAddDrawerContentThirdStep";

export const KubernetesClusterAddDrawerContent: FC = () => {
  return (
    <Stack px={2} py={1}>
      <Stack direction="row" spacing={2}>
        <Typography variant="body1" color="text.secondary">
          Prerequisites
        </Typography>

        <Chip
          label="Helm v3"
          component={Link}
          size="small"
          target="_blank"
          href={helmDocLink}
          color="primary"
          sx={chipStyles}
        />
      </Stack>

      <Stack component="ol" sx={{ pl: 3 }} spacing={1}>
        <li style={liStyle}>
          <KubernetesClusterAddDrawerContentFirstStep />
        </li>
        <li style={liStyle}>
          <KubernetesClusterAddDrawerContentSecondStep />
        </li>
        <li style={liStyle}>
          <KubernetesClusterAddDrawerContentThirdStep />
        </li>
      </Stack>

      <KubernetesClusterAddDrawerContentInfoAlert />
    </Stack>
  );
};

const helmDocLink = "https://helm.sh/docs/intro/install/";
const chipStyles = { cursor: "pointer", bgcolor: "info.light" };

const liStyle = {
  color: grey[600],
};
