import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { kubernetesClustersSelector } from "./kubernetesClustersSelector";
import { KubernetesClustersGroupedByOrgType } from "../../utils/types/types";
import { noOrgAccountOrgId } from "../../../../components/pages/accounts-group/utils/helpers/noOrganizationConstants";

export const kubernetesClustersGroupedByOrgSelector = createDraftSafeSelector(
  [kubernetesClustersSelector],
  (data): KubernetesClustersGroupedByOrgType | undefined => {
    if (!data) {
      return;
    }
    return {
      [noOrgAccountOrgId]: data,
    };
  },
);
