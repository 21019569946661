import { FC } from "react";
import { Chip, Stack } from "@mui/material";
import { ElbTargetsPopover } from "./ElbTargetsPopover";
import { ElbTargetTypography } from "./ElbTargetTypography";
import { useMenuHook } from "../../../../../../../../../../../utils/hooks/useMenu.hook";

interface TargetsCellProps {
  targets?: string[];
}

export const ElbTargetsCell: FC<TargetsCellProps> = ({ targets }) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  if (!targets?.length) {
    return "No Targets";
  }

  return (
    <Stack direction="row" alignItems="center" width="100%" overflow="hidden">
      <Chip
        size="small"
        onClick={openMenu}
        label={<ElbTargetTypography target={targets.at(0) ?? ""} />}
        sx={{ overflow: "hidden", width: "fit-content" }}
        variant="outlined"
      />

      {targets.length > 1 && (
        <Chip
          label={`+${targets.length - 1}`}
          onClick={openMenu}
          variant="outlined"
          size="small"
          sx={{ ml: 0.5 }}
        />
      )}

      {targets.length > 1 && (
        <ElbTargetsPopover
          targets={targets}
          open={open}
          closeMenu={closeMenu}
          anchor={anchor}
        />
      )}
    </Stack>
  );
};
