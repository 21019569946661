import { FC, ReactNode, RefObject } from "react";
import { Stack, StackProps } from "@mui/material";
import { UseMenuHookOpenMenu } from "../../../../../../../utils/hooks/useMenu.hook";

interface TaskPropertyTriggerWrapperProps extends StackProps {
  hovered: boolean;
  inCell?: boolean;
  disabled?: boolean;
  onClick: UseMenuHookOpenMenu;
  refToPass: RefObject<HTMLDivElement>;
  children: ReactNode;
}

export const TaskPropertyTriggerWrapper: FC<
  TaskPropertyTriggerWrapperProps
> = ({ hovered, inCell, disabled, onClick, children, refToPass, ...props }) => {
  return (
    <Stack
      px={2}
      py={1.25}
      border={1}
      height="100%"
      minHeight={40}
      minWidth={100}
      direction="row"
      borderRadius={2}
      alignItems="center"
      justifyContent="space-between"
      width={inCell ? "100%" : "unset"}
      ref={disabled ? undefined : refToPass}
      onClick={disabled ? undefined : onClick}
      borderColor={hovered ? "grey.400" : "transparent"}
      {...props}
      sx={{ cursor: "pointer", ...props.sx }}
    >
      {children}
    </Stack>
  );
};
