import { costAnomaliesWidgetDataTotalSelector } from "./costAnomaliesWidgetDataTotalSelector";
import { RootState } from "../../../../../store";

export const costAnomaliesWidgetDataExistSelector = (
  state: RootState,
  widgetId: string,
) => {
  const data = costAnomaliesWidgetDataTotalSelector(state, widgetId);

  return !!data?.length;
};
