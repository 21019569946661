import { FC, Fragment } from "react";
import { Stack, Typography } from "@mui/material";
import NoRecommendations from "../../../../../../../assets/images/no-recommendations.png";
import NoFilteredRecommendations from "../../../../../../../assets/images/no-filtered-recommendations.png";

interface CommitmentsRecommendationsDataGridFooterProps {
  option: "noFilteredData" | "emptyData";
}

export const SavingPlansRecommendationsDataGridFooter: FC<
  CommitmentsRecommendationsDataGridFooterProps
> = ({ option }) => {
  return (
    <Fragment>
      <img
        src={
          option === "noFilteredData"
            ? NoFilteredRecommendations
            : NoRecommendations
        }
        alt="No Recommendations"
        width={option === "noFilteredData" ? 200 : 170}
      />

      {option === "noFilteredData" ? (
        <Stack spacing={0.5} justifyContent="center" alignItems="center">
          <Typography variant="body1">No recommendations</Typography>
          <Typography variant="body2" color="text.secondary">
            Adjust your filters.
          </Typography>
        </Stack>
      ) : (
        <Typography variant="body2" color="text.secondary">
          Currently, you have no Savings Plan recommendations.
        </Typography>
      )}
    </Fragment>
  );
};
