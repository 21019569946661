import { FC, useMemo } from "react";
import { Divider, Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { AverageCost } from "../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/AverageCost";
import { ResourceExplorerForecastedCosts } from "../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/forecasted/ResourceExplorerForecastedCosts";
import {
  DateDataPoint,
  DateLabelNullable,
  ForecastOption,
  WidgetCostBreakdownAggregation,
} from "../../../../../../services/cloudchipr.api";
import { WidgetCosts } from "../../../../../../store/dashboards/utils/types/types";
import { ResourceExplorerLiveFiltered } from "../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/live-usage/ResourceExplorerLiveFiltered";
import { TotalCost } from "../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/TotalCost";

interface WidgetTotalsSummaryProps {
  costsData: WidgetCosts;
  frequency: DateDataPoint;
  forecastOption: ForecastOption;
  dateLabel?: DateLabelNullable;
  aggregations?: WidgetCostBreakdownAggregation | boolean;
  showTrends?: boolean;
  billingExplorerUrl?: string;
}

export const WidgetTotalsSummary: FC<WidgetTotalsSummaryProps> = ({
  costsData,
  frequency,
  forecastOption,
  dateLabel,
  showTrends = true,
  aggregations = true,
  billingExplorerUrl,
}) => {
  const enableLiveUsageOnResourceExplorer = useFlag(
    "EnableLiveUsageOnResourceExplorer",
  );

  const {
    total_cost,
    live_filtered,
    daily_average,
    forecasted_cost,
  }: WidgetCostBreakdownAggregation = useMemo(() => {
    if (typeof aggregations === "object") {
      return aggregations;
    }

    return {
      total_cost: aggregations,
      live_filtered: aggregations,
      daily_average: aggregations,
      forecasted_cost: aggregations,
    };
  }, [aggregations]);

  return (
    <Stack
      gap={1}
      flexWrap="wrap"
      direction="row"
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={2}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {total_cost && (
          <TotalCost
            totalCost={costsData.total.cost}
            totalCostTrend={showTrends ? costsData.total.trend : null}
            totalCostDates={costsData.total.dateDetails}
            previousPeriodCostDates={
              costsData?.previousPeriodTotal?.dateDetails
            }
            dateLabel={dateLabel}
            url={billingExplorerUrl}
            costType={costsData.total.costType}
          />
        )}

        {daily_average && (
          <AverageCost
            dataPoint={frequency}
            average={costsData.average.cost}
            dateDetails={costsData.total.dateDetails}
            previousPeriodCostDates={
              costsData?.previousPeriodTotal?.dateDetails
            }
            dateLabel={dateLabel}
            averageTrend={showTrends ? costsData.average.trend : undefined}
          />
        )}
      </Stack>

      <Stack direction="row" gap={2} flexWrap="wrap">
        {forecasted_cost && (
          <ResourceExplorerForecastedCosts
            forecastedCosts={costsData.forecasted_costs}
            selectedForecastOption={forecastOption ?? "month"}
            showTrend={showTrends}
          />
        )}
        {enableLiveUsageOnResourceExplorer && live_filtered && (
          <ResourceExplorerLiveFiltered
            totalCost={costsData.liveUsageTotal.cost}
            tooltip={costsData.liveUsageTotal.tooltip}
            loadFrom="widget"
          />
        )}
      </Stack>
    </Stack>
  );
};
