import { SvgIcon, SvgIconProps } from "@mui/material";

export const ScreenSplitSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9545 17.5H2.04546C1.19546 17.5 0.5 16.65 0.5 15.6111V2.38889C0.5 1.35 1.19546 0.5 2.04546 0.5H15.9545C16.8045 0.5 17.5 1.35 17.5 2.38889V15.6111C17.5 16.65 16.8045 17.5 15.9545 17.5ZM15.9545 2.6C15.9545 2.32386 15.7307 2.1 15.4545 2.1H2.54546C2.26931 2.1 2.04546 2.32386 2.04546 2.6V4.5H15.9545V2.6ZM15.9545 6.16667V15.3264C15.9545 15.6025 15.7307 15.8264 15.4545 15.8264H2.54546C2.26931 15.8264 2.04546 15.6025 2.04546 15.3264V6.16667H15.9545Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};

ScreenSplitSvgIcon.muiName = "ScreenSplitSvgIcon";

export default ScreenSplitSvgIcon;
