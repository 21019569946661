import { cloudChiprApiAccountService } from "../../../../services/cloudchipr-account-service.api";
import { RootState } from "../../../store";

const selector =
  cloudChiprApiAccountService.endpoints
    .getResourceExplorerPossibleGroupingsFromAccountService.select;

export const getResourceExplorerPossibleGroupingsFromAccountServiceSelector = (
  state: RootState,
  organisationId: string,
) => {
  return selector({
    organisationId,
  })(state);
};
