import { FC, Fragment, useMemo } from "react";
import { grey } from "@mui/material/colors";
import { Box, Theme } from "@mui/material";

import { getBillingExplorerWidgetTableViewColumns } from "./getBillingExplorerWidgetTableViewColumns";
import { getColumnVisibilityByVisualizationAndForecastedOption } from "./utils/helpers/getColumnVisibilityByVisualizationAndForecastedOption";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { DataGrid } from "../../../../../../../../../storybook/data-grid/DataGrid";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";
import { DateLabelNullable } from "../../../../../../../../../services/cloudchipr.api";
import { BillingExplorerWidgetContentCosts } from "../../BillingExplorerWidgetContentCosts";
import { billingExplorerWidgetTableViewDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetTableViewDataSelector";
import { billingExplorerWidgetVisualizationSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetVisualizationSelector";
import { billingExplorerWidgetGroupingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetGroupingSelector";
import { billingExplorerWidgetForecastOptionSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetForecastOptionSelector";
import { billingExplorerWidgetHasAggregationSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetHasAggregationSelector";

interface BillingExplorerWidgetTableViewProps {
  widgetId: string;
  dateLabel?: DateLabelNullable;
}

export const BillingExplorerWidgetTableView: FC<
  BillingExplorerWidgetTableViewProps
> = ({ widgetId, dateLabel }) => {
  const gridData =
    useAppSelector((state) =>
      billingExplorerWidgetTableViewDataSelector(state, widgetId),
    ) ?? [];
  const chartVisualization = useAppSelector((state) =>
    billingExplorerWidgetVisualizationSelector(state, widgetId),
  );
  const grouping = useAppSelector((state) =>
    billingExplorerWidgetGroupingSelector(state, widgetId),
  );

  const forecastOption = useAppSelector((state) =>
    billingExplorerWidgetForecastOptionSelector(state, widgetId),
  );

  const columnVisibility = useMemo(
    () =>
      getColumnVisibilityByVisualizationAndForecastedOption(
        chartVisualization,
        forecastOption,
      ),
    [chartVisualization, forecastOption],
  );

  const hasAggregations = useAppSelector((state) =>
    billingExplorerWidgetHasAggregationSelector(state, widgetId),
  );

  const columns = useMemo(() => {
    return getBillingExplorerWidgetTableViewColumns({
      widgetId,
      forecastOption,
      grouping,
    });
  }, [widgetId, forecastOption, grouping]);

  return (
    <Fragment>
      {hasAggregations && (
        <Box p={2}>
          <BillingExplorerWidgetContentCosts
            widgetId={widgetId}
            dateLabel={dateLabel}
          />
        </Box>
      )}
      <DataGrid
        styles={styles}
        columnSorting
        columns={columns}
        data={gridData ?? []}
        enableRowsVirtualization={gridData.length > 30}
        columnVisibility={{
          initialVisibility: columnVisibility,
        }}
        columnResizeMode="onEnd"
      />
    </Fragment>
  );
};

const styles = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& tr:hover td": { bgcolor: grey[100] },
    "& tr td": { borderColor: grey[100], py: 0.5, pl: 2 },
  },

  tableHeaderRow: {
    "& th": {
      p: 1,
      verticalAlign: "inherit",
      bgcolor: grey[100],
    },
    top: 0,
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
  },
  tableRow: {
    height: 40,
  },

  tableContainer: {
    border: 1,
    borderColor: grey[200],
    maxHeight: `${widgetSetupContentChartHeight}px`,
  },
};
