import { ResourceType } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector } from "../kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector } from "../../store-selectors/kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector";
import { getKubernetesResourceTypeDataWithoutFiltersFixedCacheKey } from "../../../thunks/resources/getKubernetesLiveUsageMgmtResourcesWithOutFiltersByResourceTypeThunk";
import { kubernetesLiveUsageMgmtResourceTypeDataSelector } from "../kubernetesLiveUsageMgmtResourceTypeDataSelector";

export const kubernetesLiveUsageMgmtResourceTypeDataTotalCountSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const withoutFilters =
    kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector(
      state,
      resourceType,
    );

  const filterTemplatesEnabled =
    kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector(state);

  const fixedCacheKey =
    withoutFilters || !filterTemplatesEnabled
      ? getKubernetesResourceTypeDataWithoutFiltersFixedCacheKey(resourceType)
      : resourceType;

  return kubernetesLiveUsageMgmtResourceTypeDataSelector(state, fixedCacheKey)
    ?.total_resources;
};
