import { FC, useCallback, useMemo, useRef } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import SunEditorCore from "suneditor/src/lib/core";
import { SunEditorOptions } from "suneditor/src/options";

interface OldSunEditorProps {
  value: string;
  toolbarOptions?: SunEditorOptions;
  onChange(val: string): void;
  placeholder?: string;
}

export const OldSunEditor: FC<OldSunEditorProps> = ({
  value,
  onChange,
  toolbarOptions,
  placeholder,
}) => {
  const editor = useRef<SunEditorCore>();
  const options = useMemo(
    () => ({ ...defaultOptions, ...toolbarOptions }),
    [toolbarOptions],
  );

  const getSunEditorInstance = useCallback((sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  }, []);

  const editorChangeHandler = useCallback(
    (value: string) => {
      if (!editor.current?.getText()) {
        onChange("");
      } else {
        onChange(value);
      }
    },
    [onChange],
  );

  return (
    <SunEditor
      setContents={value}
      onChange={editorChangeHandler}
      setOptions={options}
      placeholder={placeholder}
      getSunEditorInstance={getSunEditorInstance}
    />
  );
};

const defaultOptions = {
  buttonList: [["bold", "italic", "strike"], ["table"], ["fullScreen"]],
  defaultTag: "p",
  minHeight: "300px",
  showPathLabel: false,
};
