import { useCallback } from "react";
import { Button, Tooltip } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { billingExplorerSummaryLoadingByIndexSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/loading/billingExplorerSummaryLoadingByIndexSelector";
import { getBillingExplorerSummaryThunk } from "../../../../../../../../store/resource-explorer/thunks/resource-explorer-summary/getBillingExplorerSummaryThunk";
import { isBillingExplorerDatesOutRangesForSummarySelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/isBillingExplorerDatesOutRangesForSummarySelector";
import { addBillingExplorerSummaryMetaDataThunk } from "../../../../../../../../store/resource-explorer/thunks/resource-explorer-summary/addBillingExplorerSummaryMetaDataThunk";

export const BillingExplorerExplainWithAiREMetaDataExplainButton = () => {
  const dispatch = useAppDispatch();

  const firstItemLoading = useAppSelector((state) =>
    billingExplorerSummaryLoadingByIndexSelector(state, 0),
  );
  const isOutRangesDates = useAppSelector(
    isBillingExplorerDatesOutRangesForSummarySelector,
  );

  const buttonClickHandler = useCallback(() => {
    dispatch(addBillingExplorerSummaryMetaDataThunk());
    dispatch(getBillingExplorerSummaryThunk());
  }, [dispatch]);

  return (
    <Tooltip
      title={
        isOutRangesDates
          ? "The selected date range exceeds the allowed limit. Please adjust it to be within 60 days to use the Explain AI feature."
          : ""
      }
      arrow
      placement="top"
    >
      <span>
        <Button
          disabled={isOutRangesDates || firstItemLoading}
          variant="contained"
          onClick={buttonClickHandler}
          sx={explainButtonStyle}
          size="small"
          startIcon={
            <AutoAwesomeIcon
              fontSize="small"
              sx={{
                color: "white",
              }}
            />
          }
        >
          Explain
        </Button>
      </span>
    </Tooltip>
  );
};

const explainButtonStyle = {
  px: 1,
  textTransform: "none",
  borderRadius: "24px",
  background: "linear-gradient(100deg, #1443EA, #7334F1)",
  color: "white",
  "&.Mui-disabled": {
    backgroundColor: "linear-gradient(100deg, #1443EA, #7334F1)",
    color: "white",
    opacity: 0.5,
  },
};
