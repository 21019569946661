import { FC, useState } from "react";
import { Box, Card } from "@mui/material";
import { ReservationsMultiTypeChart } from "./chart/multi-type-chart/ReservationsMultiTypeChart";
import { ReservationsGaugeChart } from "./chart/ReservationsGaugeChart";
import { ReservationsDataGrid } from "./data-grid/ReservationsDataGrid";
import { ReservationsMultiTypeChartMetadata } from "./chart/multi-type-chart/ReservationsMultiTypeChartMetadata";
import { PossibleVisibilities } from "../../../../common/ScreenSplitButtons";
import { useAppSelector } from "../../../../../../store/hooks";
import { commitmentsChartTypeSelector } from "../../../../../../store/commitments/selectors/common/view-options/commitmentsChartTypeSelector";
import { CommitmentsScreenSplitButtons } from "../../../common/components/CommitmentsScreenSplitButtons";

export const ReservationsContent: FC = () => {
  const [visibleContent, setVisibleContent] =
    useState<PossibleVisibilities>("chart_grid");
  const chartType = useAppSelector(commitmentsChartTypeSelector);

  return (
    <Card
      sx={{ p: 2, display: "flex", flex: 1, flexDirection: "column" }}
      variant="outlined"
    >
      {(chartType !== "gauge" || visibleContent === "grid") && (
        <ReservationsMultiTypeChartMetadata />
      )}

      {visibleContent !== "grid" && (
        <Box position="relative" zIndex={2} mb={2}>
          <ReservationsMultiTypeChart />
          <ReservationsGaugeChart />
        </Box>
      )}

      <CommitmentsScreenSplitButtons
        provider="aws"
        visibleContent={visibleContent}
        setVisibleContent={setVisibleContent}
      />

      {visibleContent !== "chart" && (
        <Box mt={2} display="flex" flex={1}>
          <ReservationsDataGrid />
        </Box>
      )}
    </Card>
  );
};
