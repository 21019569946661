import numeral from "numeral";
import { accountValueGenerator } from "./accountValueGenerator";
import { getMetricsGeneratorFn } from "./getMetricsGeneratorFn";
import {
  ConvertorKeysType,
  ConvertorsType,
  ExportedResourceDataOptions,
} from "../types";
import {
  CapacityMode,
  DynamoDbTable,
  EmbeddedTask,
  ResourceCreationData,
  ResourceIssues,
  Tags,
  UnusedSince,
} from "../../../../../../../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { formatDate } from "../../../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { ResourceItemType } from "../../../../../../../../../../../../store/live-usage-mgmt/utils/types/types";
import { formatSecondsToFormattedDate } from "../../helpers/formatSecondsToFormattedDate";

export const generateValue = (
  resource: ResourceItemType,
  col: ColumnSetupType<ResourceItemType>,
  options?: ExportedResourceDataOptions,
) => {
  const key = col.accessorKey;
  const type = col.type as ConvertorKeysType;

  if (!key || !resource) {
    return "";
  }

  const converter = valuesConverterByType[type];

  const value = converter
    ? converter(key, resource, { ...options, columnId: col.id })
    : resource[key];

  return value?.toString() || "";
};

const valuesConverterByType: ConvertorsType = {
  instances: (key, resource) => {
    const instances = resource[key] as string[];

    return instances?.length?.toString();
  },
  secondsToFormattedDate: (key, resource) => {
    const data = resource?.[key];

    if (typeof data !== "number") {
      return "-";
    }

    return formatSecondsToFormattedDate(data);
  },
  createdBy: (key, resource) => {
    const value = resource[key] as ResourceCreationData;
    return value?.creator ?? "";
  },
  state: (key, resource) => {
    const value = resource[key] as UnusedSince;
    return value?.is_used ? "Running" : "Stopped";
  },
  money: (key, resource) => {
    const value = resource[key];

    return numeral(value)?.format("0.000");
  },
  cpuStatistic: (key, resource) => {
    const value = resource[key];
    return numeral(value)?.format("0.000");
  },
  unusedSince: (key, resource) => {
    const data = resource[key] as UnusedSince;

    if (data?.is_used || !data?.timestamp) {
      return "No Data";
    } else {
      return data?.timestamp;
    }
  },
  jiraTicketStatus: (key, resource) => {
    const issues = resource[key] as ResourceIssues;

    return issues
      ?.map(({ key, status }) => `"${key} _ ${status.name}"`)
      ?.join(" | ");
  },
  taskStatus: (key, resource, { taskStatuses } = {}) => {
    const task = resource[key] as EmbeddedTask;
    const status = taskStatuses?.find(
      (status) => status.id === task?.status_id,
    )?.name;

    return status ?? "";
  },
  tag: (
    key,
    resource: ResourceItemType,
    options?: ExportedResourceDataOptions,
  ) => {
    const tags = resource[key] as Tags;

    if (options?.onlyNameTag) {
      const nameTag = tags?.find(({ key }) => key.toLowerCase() === "name");

      return nameTag ? `${nameTag?.key}: ${nameTag?.value}` : "";
    } else {
      const t = tags?.map((tag) => `${tag?.key}:${tag?.value}`).join(" , ");
      return `"${t}"`;
    }
  },
  date: (key, resource) => {
    const value = resource[key] as string;

    return formatDate(value, { type: "dateTime" });
  },
  capacityMode: (key, resource) => {
    const capacityModeData = resource[key] as CapacityMode;
    const table = resource as DynamoDbTable;

    if (!table.capacity_mode) {
      return "";
    }

    return `${table.capacity_mode} (${capacityModeData.current_provisioned_units})`;
  },
  ebsAttachmentsCell: (key, resource) =>
    resource[key] ? "Attached" : "Detached",
  account: accountValueGenerator,
  liveFilteredAccount: accountValueGenerator,
  memoryUsedMetricsChart: getMetricsGeneratorFn("memory_max"),
  memoryAvailableMetricsChart: getMetricsGeneratorFn("unused_memory"),
  computeMetricsChart: getMetricsGeneratorFn("cpu_max"),
  dbMetricsChart: getMetricsGeneratorFn("total_connection_sum"),
  storageMetricsChart: getMetricsGeneratorFn("all_request_sum"),
  trafficMetricsChart: getMetricsGeneratorFn("processed_bytes"),
};
