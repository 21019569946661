import { azureOverviewDataSelector } from "./azureOverviewDataSelector";
import { RootState } from "../../../../store";
import { EffectiveSavingsRate } from "../../../../../services/cloudchipr.api";

export const azureOverviewEffectiveSavingRateSelector = (
  state: RootState,
): EffectiveSavingsRate | null => {
  const data = azureOverviewDataSelector(state);

  return data?.effective_savings_rate ?? null;
};
