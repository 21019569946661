import { BillingExplorerWidgetSetupType } from "../types/setups/billingExplorerSetupType";

export const billingExplorerWidgetSetupDefaultData: BillingExplorerWidgetSetupType =
  {
    widgetType: "cost_breakdown",
    //TODO: EnableWidgetsSideBarConfigurations, remove viewType
    viewType: "stack",
    visualization: {
      chart_type: "stack",
      chart_base_type: "cost",
      trend: true,
      forecasted_cost: true,
      account_column: true,
      region_column: true,
      previous_period_cost: true,
    },
    aggregation: {
      total_cost: true,
      daily_average: true,
      forecasted_cost: true,
      live_filtered: true,
    },
    trendType: "month_over_month",
    frequency: "monthly",
    name: "Billing Explorer",
    dates: { label: "past_fourteen_days" },
    grouping: "service",
    group_values: null,
    viewId: undefined,
    forecastOption: "month",
  };
