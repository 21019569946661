import { FC } from "react";
import { CommitmentsOverviewEffectiveSavingsRate } from "./common/CommitmentsOverviewEffectiveSavingsRate";
import { useAppSelector } from "../../../../../../store/hooks";
import { overviewEffectiveSavingRateSelector } from "../../../../../../store/commitments/selectors/overview/overviewEffectiveSavingRateSelector";
import { overviewLoadingSelector } from "../../../../../../store/commitments/selectors/overview/loading/overviewLoadingSelector";
import { overviewPreviousDateDetailsSelector } from "../../../../../../store/commitments/selectors/overview/overviewPreviousDateDetailsSelector";

export const AwsOverviewEffectiveSavingsRate: FC = () => {
  const effectiveSavingRate = useAppSelector(
    overviewEffectiveSavingRateSelector,
  );

  const prevDateDetails = useAppSelector(overviewPreviousDateDetailsSelector);
  const loading = useAppSelector(overviewLoadingSelector);

  return (
    <CommitmentsOverviewEffectiveSavingsRate
      effectiveSavingRate={effectiveSavingRate}
      loading={loading}
      prevDateDetails={prevDateDetails}
    />
  );
};
