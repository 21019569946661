import { FC } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { azureSavingsPlanDetailsMetaDataSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/details-drawer/saving-plan/azureSavingsPlanDetailsMetaDataSelector";
import { azureUtilizationAndCoverageDetailsLoadingSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/details-drawer/data/azureUtilizationAndCoverageDetailsLoadingSelector";
import { CommitmentsDrawerChartHeader } from "../../../../../common/components/drawer/CommitmentsDrawerChartHeader";
import { CommitmentsDrawerContentInfoCard } from "../../../../../common/components/drawer/CommitmentsDrawerContentInfoCard";
import { CommitmentsProgressBar } from "../../../../../common/components/CommitmentsProgressBar";
import { money } from "../../../../../../../../utils/numeral/money";

export const AzureSavingsPlanDrawerChartHeader: FC = () => {
  const metadata = useAppSelector(azureSavingsPlanDetailsMetaDataSelector);
  const isLoading = useAppSelector(
    azureUtilizationAndCoverageDetailsLoadingSelector,
  );

  return (
    <CommitmentsDrawerChartHeader>
      <Stack direction="row" flexWrap="wrap" gap={1} mt={1.5}>
        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Utilization"
        >
          <CommitmentsProgressBar
            width="100%"
            value={metadata?.utilization ?? 0}
          />
        </CommitmentsDrawerContentInfoCard>

        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Used Commitment"
          value={money(metadata?.used_commitment)}
        />

        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="On-demand Spend Equivalent"
          value={money(metadata?.covered_usage)}
        />

        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Net Savings"
          value={money(metadata?.net_savings)}
        />
      </Stack>
    </CommitmentsDrawerChartHeader>
  );
};

const cardSx = { minWidth: 256, flex: 1 };
