import React, { FC } from "react";
import { Alert } from "@mui/material";

export const KubernetesClusterAddDrawerContentInfoAlert: FC = () => {
  return (
    <Alert variant="outlined" severity="info">
      After connecting, the cluster will appear in your list.
    </Alert>
  );
};
