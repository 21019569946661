import { FC, useCallback } from "react";
import { PersonAddOutlined } from "@mui/icons-material";
import { PopoverProps } from "@mui/material";
import { FloatingActionButton } from "./FloatingActionButton";
import { AssigneeSelection } from "../../../../common/task-management/components/common/properties/assignee/AssigneeSelection";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { useAppDispatch } from "../../../../../../store/hooks";
import { taskManagementUpdateSelectedTasksThunk } from "../../../../../../store/task-management/thunks/actions/taskManagementUpdateSelectedTasksThunk";
import { useAppAbility } from "../../../../../../services/permissions";

export const FloatingAssigneeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const changeHandler = useCallback(
    (value: string[] | null) => {
      if (!value?.length) {
        return;
      }

      dispatch(
        taskManagementUpdateSelectedTasksThunk({
          assignees: { value },
        }),
      );
    },
    [dispatch],
  );

  return (
    <AssigneeSelection
      onChange={changeHandler}
      Trigger={Trigger}
      PopoverProps={popoverProps as PopoverProps}
    />
  );
};

const Trigger: FC<FilterTriggerComponentProps> = ({ onClick }) => {
  const { can } = useAppAbility();
  const canEditTask = can("edit", "task");

  return (
    <FloatingActionButton
      icon={PersonAddOutlined}
      disabled={!canEditTask}
      onClick={onClick}
    >
      Assignees
    </FloatingActionButton>
  );
};

const popoverProps = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  slotProps: {
    paper: { sx: { mt: -2 } },
  },
};
