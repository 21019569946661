import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { Budget } from "../../../../../../../../services/cloudchipr.api";
import { UserActivityCell } from "../../../../../../../common/data-grid-cells/UserActivityCell";

export const BudgetsLastModifiedByCell: FC<ICellRendererParams<Budget>> = ({
  data: budget,
}) => {
  const details = budget?.modified_by;
  const date = budget?.modified_at;

  if (!details || !date) {
    return "-";
  }

  return (
    <UserActivityCell
      date={date}
      name={details?.name ?? details?.email ?? ""}
      deleted={details?.status === "deleted"}
      profile={details?.profile}
    />
  );
};
