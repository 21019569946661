import { getBillingExplorerSummarySelector } from "./api-select/getBillingExplorerSummarySelector";
import { RootState } from "../../../store";
import { resourceExplorerPayloadDataSelector } from "../current-resource-explorer/resource-explorer-widget/resourceExplorerPayloadDataSelector";
import { billingExplorerExplainWithAiDataSelector } from "../current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiDataSelector";

export const billingExplorerSummaryDataSelector = (
  state: RootState,
  index: number,
) => {
  const resourceExplorerDataGettingPayload =
    resourceExplorerPayloadDataSelector(state);

  if (!resourceExplorerDataGettingPayload.group_by) {
    return;
  }

  const explainWithAiData = billingExplorerExplainWithAiDataSelector(state);
  const key = explainWithAiData.at(index)?.key ?? "";

  return getBillingExplorerSummarySelector(key)(state)?.data?.summary;
};
