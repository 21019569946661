import { Stack } from "@mui/material";
import { FC } from "react";
import { KubernetesLiveUsageMgmtResourcesCount } from "./KubernetesLiveUsageMgmtResourcesCount";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { isKubernetesLiveUsageMgmtFilterAppliedToResourceSelector } from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/isKubernetesLiveUsageMgmtFilterAppliedToResourceSelector";
import { kubernetesLiveUsageMgmtResourceTypeDataLoadingSelector } from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/loading/kubernetesLiveUsageMgmtResourceTypeDataLoadingSelector";
import { kubernetesLiveUsageMgmtResourceTypeDataTotalCountSelector } from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/count/kubernetesLiveUsageMgmtResourceTypeDataTotalCountSelector";
import { kubernetesLiveUsageMgmtFilteredResourcesCountSelector } from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/count/kubernetesLiveUsageMgmtFilteredResourcesCountSelector";
import { kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector } from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector";
import { KubernetesLiveUsageResourceFilter } from "../../../../../../filters/KubernetesLiveUsageResourceFilter";

interface KubernetesLiveUsageMgmtResourceCardFilterContentProps {
  resourceType: ResourceType;
}
export const KubernetesLiveUsageMgmtResourceCardFilterContent: FC<
  KubernetesLiveUsageMgmtResourceCardFilterContentProps
> = ({ resourceType }) => {
  const filtersApplied = useAppSelector((state) =>
    isKubernetesLiveUsageMgmtFilterAppliedToResourceSelector(
      state,
      resourceType,
    ),
  );

  const viewIsWithoutFilters = useAppSelector((state) =>
    kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector(
      state,
      resourceType,
    ),
  );
  const isLoading = useAppSelector((state) =>
    kubernetesLiveUsageMgmtResourceTypeDataLoadingSelector(state, resourceType),
  );
  const filterTemplatesEnabled = useAppSelector(
    kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector,
  );

  const resourcesTotalCount = useAppSelector((state) =>
    kubernetesLiveUsageMgmtResourceTypeDataTotalCountSelector(
      state,
      resourceType,
    ),
  );
  const resourcesCount = useAppSelector((state) =>
    kubernetesLiveUsageMgmtFilteredResourcesCountSelector(state, resourceType),
  );

  return (
    <Stack direction="row" spacing={0.5}>
      {!viewIsWithoutFilters && filterTemplatesEnabled && (
        <KubernetesLiveUsageResourceFilter resourceType={resourceType} />
      )}
      <KubernetesLiveUsageMgmtResourcesCount
        resourceType={resourceType}
        dataCount={resourcesCount}
        isLoading={isLoading}
        totalDataCount={resourcesTotalCount}
        filtersApplied={filtersApplied}
      />
    </Stack>
  );
};
