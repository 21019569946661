import { FC, Fragment, memo, useMemo } from "react";
import { FormikHandlers } from "formik";
import {
  AutocompleteFilterBody,
  AutocompleteFilterBodyProps,
} from "../AutocompleteFilterBody";
import {
  FilterItem,
  Operator,
  useGetProvidersByProviderRegionsQuery,
} from "../../../../../../../../services/cloudchipr.api";
import { FilterItemOperatorType } from "../../../FilterItemOperatorType";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { liveUsageMgmtProviderSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { CloudProviderType } from "../../../../../../../common/types/types";

interface RegionFilterProps extends AutocompleteFilterBodyProps {
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
}

export const RegionFilter: FC<RegionFilterProps> = memo(
  ({ operators, filter, onChange, ...props }) => {
    const provider = useAppSelector(liveUsageMgmtProviderSelector);

    const { data, isLoading } = useGetProvidersByProviderRegionsQuery(
      { provider: provider as CloudProviderType },
      { skip: !provider },
    );

    const renderOptions = useMemo(() => {
      return data?.map((region) => {
        return {
          value: region.key,
          label: region.name,
        };
      });
    }, [data]);

    return (
      <Fragment>
        <FilterItemOperatorType
          onChange={onChange}
          options={operators}
          value={filter.operator || "in"}
        />

        <AutocompleteFilterBody
          {...props}
          isLoading={isLoading}
          label="Region"
          options={renderOptions ?? []}
          selectedValue={Array.isArray(filter?.value) ? filter.value : []}
        />
      </Fragment>
    );
  },
);
