import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import { blue } from "@mui/material/colors";

const KubernetesNamespaceSvgIcon = ({
  primary,
}: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? blue["700"] : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3783 1.64656C11.5446 1.55902 11.7372 1.51087 11.9298 1.50211C12.1706 1.48898 12.4113 1.53713 12.6258 1.64656L20.1504 5.23157C20.5444 5.42417 20.8333 5.78311 20.9296 6.20333L22.7899 14.2794C22.8862 14.6997 22.7768 15.1461 22.5141 15.4788L17.3052 21.9616C17.0294 22.2943 16.6092 22.5 16.1802 22.5H7.82831C7.39495 22.5 6.97474 22.2943 6.69897 21.9616L1.48998 15.4919C1.4681 15.4438 1.44183 15.4088 1.40681 15.3738C1.20108 15.0498 1.13104 14.6515 1.21421 14.2794L3.07456 6.20333C3.17086 5.78311 3.45977 5.42417 3.85372 5.23157L11.3783 1.64656ZM11.4177 2.26368C11.5753 2.18489 11.7547 2.14112 11.9342 2.13236C12.1618 2.11923 12.3851 2.16301 12.5908 2.26368L19.6601 5.63858C20.0322 5.81805 20.3036 6.1551 20.3955 6.55343L22.1421 14.1437C22.234 14.5376 22.1333 14.9535 21.8838 15.273L16.99 21.3618C16.7273 21.677 16.3334 21.8696 15.9263 21.8696H8.07781C7.67072 21.8696 7.27677 21.677 7.01851 21.3618L2.12031 15.2818C2.09843 15.238 2.07654 15.203 2.04152 15.168C1.8533 14.8659 1.78326 14.4938 1.86205 14.1437L3.61297 6.55343C3.70052 6.1551 3.97191 5.81805 4.34398 5.63858L11.4177 2.26368Z"
          fill={color}
        />
        <path
          d="M6.26999 6.46159V7.03501H5.70532V8.28254H6.33127V7.08316H7.51752V6.46159H6.26999ZM8.1391 6.46159V7.08316H9.391V6.46159H8.1391ZM10.017 6.46159V7.08316H11.2645V6.46159H10.017ZM11.8904 6.46159V7.08316H13.138V6.46159H11.8904ZM13.7639 6.46159V7.08316H15.0115V6.46159H13.7639ZM15.6374 6.46159V7.08316H16.8849V6.46159H15.6374ZM17.5109 6.46159V7.08316H17.686V7.53402H18.3119V6.77237C18.3119 6.60166 18.1719 6.46159 18.0011 6.46159H17.5109ZM17.686 8.1556V9.40751H18.3119V8.1556H17.686ZM5.70532 8.9085V10.156H6.33127V8.9085H5.70532ZM17.686 10.0291V11.2766H18.3119V10.0291H17.686ZM5.70532 10.782V12.0295H6.33127V10.782H5.70532ZM17.686 11.9026V13.1545H18.3119V11.9026H17.686ZM5.70532 12.6555V13.903H6.33127V12.6555H5.70532ZM17.686 13.7804V15.028H18.3119V13.7804H17.686ZM5.70532 14.5289V15.7765H6.33127V14.5289H5.70532ZM17.686 15.6539V16.9014H18.3119V15.6539H17.686ZM5.70532 16.4024V17.2166C5.70532 17.2998 5.74034 17.3786 5.79724 17.4398C5.85853 17.4967 5.93732 17.5274 6.02049 17.5274H6.45384V16.9058H6.33127V16.4024H5.70532ZM7.07979 16.9058V17.5274H8.32732V16.9058H7.07979ZM8.9489 16.9058V17.5274H10.2008V16.9058H8.9489ZM10.8224 16.9058V17.5274H12.0743V16.9058H10.8224ZM12.6959 16.9058V17.5274H13.9434V16.9058H12.6959ZM14.5693 16.9058V17.5274H15.8213V16.9058H14.5693ZM16.4428 16.9058V17.5274H17.6904V16.9058H16.4428Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

KubernetesNamespaceSvgIcon.muiName = "KubernetesNamespaceSvgIcon";

export default KubernetesNamespaceSvgIcon;
