import { ColDef, ColDefField } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../store/alerts/utils/types/types";
import { NotificationsCell } from "../../../../../../common/data-grid-cells/notifications-cell/NotificationsCell";
import { alertsNotificationMethodComparator } from "../utils/helpers/comparators/alertsNotificationMethodComparator";
import { alertsNotificationMethodValueGetter } from "../utils/helpers/valueGetters/alertsNotificationMethodValueGetter";

export const getAlertsNotificationMethodColumnDef = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(): ColDef<T> => ({
  field: "notifications" as ColDefField<T>,
  headerName: "Notification Method",
  minWidth: 150,
  width: 230,
  filter: false,
  cellRenderer: ({ data }: CustomCellRendererProps<T>) => {
    const integrations = data?.integrations;
    const emails = data?.emails;

    return (
      <NotificationsCell emails={emails} integrations={integrations ?? []} />
    );
  },
  comparator: alertsNotificationMethodComparator,
  valueGetter: alertsNotificationMethodValueGetter,
});
