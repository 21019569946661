import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { executionLogFiltersSelector } from "./executionLogFiltersSelector";

export const isExecutionLogDataFilteredSelector = createDraftSafeSelector(
  [executionLogFiltersSelector],
  (filters) => {
    if (!filters) {
      return false;
    }

    return Object.values(filters).some((filter) => {
      if (!filter) {
        return false;
      }
      if (Array.isArray(filter)) {
        return filter.length > 0;
      }
      return true;
    });
  },
);
