import { useMemo } from "react";
import {
  ProviderType,
  ResourceType,
  ResourceVisibility,
  useGetUsersMeProvidersByProviderAccountsResourcesVisibilitiesQuery,
} from "../../../../../../services/cloudchipr.api";
import { CloudProviderType } from "../../../../../common/types/types";

type ReturnedDataValue = {
  show: boolean;
  actions: ResourceVisibility["visibilities"]["actions"];
};

export type HookResourcesVisibilityReturnedDataType = Record<
  string,
  Record<ResourceType, ReturnedDataValue>
>;

// todo: Visibility can be moved into thunk/selector
export function useAllResourcesVisibility({
  accountIds,
  provider,
}: {
  accountIds?: string | null;
  provider?: ProviderType | null;
}): HookResourcesVisibilityReturnedDataType {
  const { data } =
    useGetUsersMeProvidersByProviderAccountsResourcesVisibilitiesQuery(
      {
        provider: (provider ?? "aws") as CloudProviderType,
        accountIds: accountIds ?? "",
      },
      { skip: !accountIds || !provider },
    );

  return useMemo(() => {
    return (
      data?.reduce(
        (acc, { account_id, accounts_visibilities }) => {
          acc[account_id] = accounts_visibilities?.reduce(
            (acc, resource) => ({
              ...acc,
              [resource.type]: {
                show: resource.visibilities.show,
                actions: resource.visibilities.actions,
              },
            }),
            {},
          );

          return acc;
        },
        {} as Record<string, any>,
      ) || {}
    );
  }, [data]);
}
