import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { updateSavingsOpportunityViewFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.patchUsersMeOrganisationsCurrentOpportunityViewsByViewId.select(
    updateSavingsOpportunityViewFixedCacheKey,
  );

export const updateSavingsOpportunityViewLoadingSelector = (
  state: RootState,
) => {
  return endpoint(state)?.isLoading;
};
