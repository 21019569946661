import { Schedule } from "../../../../services/cloudchipr.api";

export const formatNotificationsFromSlackToHtml = (
  notifications: Schedule["notifications"],
) => {
  return notifications?.map((item) => {
    if (item.type === "slack") {
      item = {
        ...item,
        custom_message: item.custom_message
          ? slackMdToHtml(item.custom_message)
          : "",
      };
      return item;
    }
    return item;
  });
};

const slackMdToHtml = (slackMd: string) => {
  let html = slackMd;

  html = html.replace(/<([^|>]+)\|([^>]+)>/g, (_, url, text) => {
    const safeUrl = url.replace(/"/g, "&quot;"); // Sanitize quotes in URLs
    return `<a href="${safeUrl}">${text}</a>`;
  });

  html = html.replace(/\*([^*]+)\*/g, "<strong>$1</strong>");

  html = html.replace(/_([^_]+)_/g, "<em>$1</em>");

  html = html.replace(/\n/g, "<br>");

  html = html.replace(/~(.*?)~/g, "<del>$1</del>");

  return `<div>${html}</div>`;
};
