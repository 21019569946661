import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ResourceDetailsInterface,
  ResourceDetailsActiveTab,
  PossibleSourcesForResourceDetails,
  ResourceDetailsBillingDataMeta,
} from "./utils/types/common";
import {
  DateDataPoint,
  Dates,
  ForecastOption,
} from "../../services/cloudchipr.api";

const initialState: ResourceDetailsInterface = {
  options: {
    activeTab: "general",
    availableTabs: [],
    openedFrom: null,
    billingRelated: {
      data_point: "daily",
      forecast_option: "month",
      dates: { label: "past_fourteen_days" },
    },
  },

  target: null,

  billingDataProcesses: {},
};

export const resourceDetailsSlice = createSlice({
  name: "resourceDetails",
  initialState: initialState,
  reducers: {
    setResourceDetailsActiveTab: (
      state,
      action: PayloadAction<ResourceDetailsActiveTab>,
    ) => {
      state.options.activeTab = action.payload;
    },
    resetResourceDetailsSetup: () => {
      return initialState;
    },
    setResourceDetailsOpenedFrom: (
      state,
      action: PayloadAction<PossibleSourcesForResourceDetails | null>,
    ) => {
      state.options.openedFrom = action.payload;
    },
    setResourceDetailsDataPoint: (
      state,
      action: PayloadAction<DateDataPoint>,
    ) => {
      state.options.billingRelated.data_point = action.payload;
    },
    setResourceDetailsDates: (state, action: PayloadAction<Dates>) => {
      state.options.billingRelated.dates = action.payload;
    },
    setResourceDetailsForecastOption: (
      state,
      action: PayloadAction<ForecastOption>,
    ) => {
      state.options.billingRelated.forecast_option = action.payload;
    },
    setResourceDetailsAvailableTabs: (
      state,
      action: PayloadAction<ResourceDetailsActiveTab[]>,
    ) => {
      state.options.availableTabs = action.payload;
    },

    // target
    setResourceDetailsTarget: (
      state,
      action: PayloadAction<ResourceDetailsInterface["target"]>,
    ) => {
      state.target = action.payload;
    },

    // processes
    setResourceDetailsBillingDataProcesses: (
      state,
      action: PayloadAction<Record<string, ResourceDetailsBillingDataMeta>>,
    ) => {
      state.billingDataProcesses = action.payload;
    },
    setResourceDetailsBillingDataProcess: (
      state,
      action: PayloadAction<
        { key: string } & Partial<ResourceDetailsBillingDataMeta>
      >,
    ) => {
      const { key, ...data } = action.payload;

      const process = state.billingDataProcesses[key];

      if (!process) {
        return;
      }

      state.billingDataProcesses[key] = { ...process, ...data };
    },
  },
});

export const {
  resetResourceDetailsSetup,

  setResourceDetailsActiveTab,
  setResourceDetailsOpenedFrom,
  setResourceDetailsDataPoint,
  setResourceDetailsDates,
  setResourceDetailsForecastOption,
  setResourceDetailsAvailableTabs,

  //target
  setResourceDetailsTarget,

  // general tab
  setResourceDetailsBillingDataProcess,
  setResourceDetailsBillingDataProcesses,
} = resourceDetailsSlice.actions;

export default resourceDetailsSlice.reducer;
