import { coverageWidgetByWidgetIdGroupsSelector } from "./coverageWidgetByWidgetIdGroupsSelector";
import { coverageWidgetVisualizationChartBaseSelector } from "./coverageWidgetVisualizationChartBaseSelector";
import { generateCoverageWidgetChartData } from "../utils/helpers/generateCoverageWidgetChartData";
import { RootState } from "../../../../../store";

export const coverageWidgetByWidgetIdChartDataSelector = (
  state: RootState,
  widgetId: string,
) => {
  const groups = coverageWidgetByWidgetIdGroupsSelector(state, widgetId);
  const chartBase = coverageWidgetVisualizationChartBaseSelector(
    state,
    widgetId,
  );

  return generateCoverageWidgetChartData(groups, chartBase);
};
