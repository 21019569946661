import {
  ResourceExplorerGrouping,
  ResourceExplorerGroupingNullable,
} from "../../../../services/cloudchipr.api";
import { ResourceExplorerPath } from "../types/resourceExplorer";

export const generateDefaultPath = (
  grouping?: ResourceExplorerGroupingNullable,
  groupValues?: string[],
  label?: string,
  allGroupings?: ResourceExplorerGrouping[],
): ResourceExplorerPath[] => {
  return [
    {
      name: grouping && label ? label : "Services",
      // as this is not dynamic there is no need to keep it in the path
      groupingOptions: allGroupings ?? [],
      groupValues: groupValues,
      groupedBy: grouping ?? "service",
      filterTree: undefined,
    },
  ];
};
