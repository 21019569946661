import { FC, memo, useEffect, useMemo } from "react";
import { Card, CardContent, CardHeader, Divider, Stack } from "@mui/material";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { KubernetesClusterCardHeaderTitle } from "./KubernetesClusterCardHeaderTitle";
import { KubernetesClusterCardContent } from "./KubernetesClusterCardContent";
import { KubernetesClusterCardFooter } from "./footer/KubernetesClusterCardFooter";
import { KubernetesCluster } from "../../../../../../../../services/cloudchipr.api";
import { scrollingOnDnD } from "../../../../../utils/helpers/dnd-helpers";
import { useHover } from "../../../../../../../../utils/hooks/useHover.hook";
import { AccountCardRouterWrapper } from "../../../../accounts/account-card/AccountCardRouterWrapper";
import { AccountCardHeaderInfo } from "../../../../accounts/account-card/AccountCardHeaderInfo";
import { generateLiveResourcesPath } from "../../../../../utils/helpers/generateLiveResourcesPath";
import { ProviderIcon } from "../../../../../../../common/ProviderIcon";
import { KubernetesClusterActions } from "../../actions/KubernetesClusterActions";

interface KubernetesClusterCardProps extends KubernetesCluster {
  index: number;
  disableDnD: boolean;
  onCardMove: (dragIndex: number, hoverIndex: number) => void;
  onCardDrop: (dragIndex: number, hoverIndex: number, drop: boolean) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const KubernetesClusterCard: FC<KubernetesClusterCardProps> = memo(
  ({
    id,
    cluster_id,
    cpu_usage,
    cpu_request,
    cpu_total,
    memory_usage,
    memory_request,
    memory_total,
    name,
    provider,
    total_monthly_cost,
    idle_monthly_cost,
    reserved_monthly_cost,
    index,
    onCardMove,
    onCardDrop,
    disableDnD,
    last_updated_date,
  }) => {
    const { ref, hovered } = useHover();

    const accountId = id;

    const [, dropRef] = useDrop({
      accept: "card",
      hover: (item: DragItem, monitor) => {
        const dragIndex = item.index;

        scrollingOnDnD(20, monitor.getSourceClientOffset()?.y);

        if (dragIndex === index) {
          return;
        }

        onCardMove(dragIndex, index);
        item.index = index;
      },
      drop: (draggedRow) => {
        onCardDrop(draggedRow.index, index, true);
      },
    });

    const [{ isDragging }, dragRef, previewRef] = useDrag({
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: () => ({ id: accountId, index }),
      type: "card",
      end: (item, monitor) => {
        if (!monitor.didDrop()) {
          onCardDrop(item.index, index, false);
        }
      },
    });

    const navigateTo = useMemo(() => {
      return generateLiveResourcesPath({
        provider: "kubernetes",
        activeTab: "live-resources",
        accountIds: id,
      });
    }, [id]);

    useEffect(() => {
      previewRef(getEmptyImage());
    }, [previewRef]);

    return (
      <Stack id={id} sx={{ opacity: isDragging ? 0.3 : 1 }} height="100%">
        <AccountCardRouterWrapper status="connected" navigateTo={navigateTo}>
          <Card
            sx={{
              position: "relative",
              borderRadius: 3,
              flex: 1,
              boxShadow: (theme) => {
                return hovered ? theme.shadows[5] : theme.shadows[0];
              },
            }}
            variant="outlined"
            ref={ref}
          >
            <Stack ref={dropRef} height="100%">
              <CardHeader
                action={<KubernetesClusterActions />}
                sx={{
                  "& .MuiCardHeader-content": { overflow: "hidden" },
                  py: 1,
                  pr: 1,
                  pl: 0.5,
                }}
                title={
                  <KubernetesClusterCardHeaderTitle
                    dragRef={dragRef}
                    name={name}
                    clusterId={cluster_id}
                    disableDnD={disableDnD}
                    provider={provider}
                  />
                }
              />
              <AccountCardHeaderInfo
                accountId={cluster_id || ""}
                accessType="read"
                provider="kubernetes"
                providerLogo={<ProviderIcon provider={provider} />}
              />
              <Divider />
              <CardContent sx={{ flex: 1 }}>
                <KubernetesClusterCardContent
                  total_monthly_cost={total_monthly_cost}
                  idle_monthly_cost={idle_monthly_cost}
                  reserved_monthly_cost={reserved_monthly_cost}
                  last_updated_date={last_updated_date}
                />
              </CardContent>
              <Stack spacing={2}>
                <Divider />
                <KubernetesClusterCardFooter
                  cpuTotal={cpu_total}
                  cpuRequest={cpu_request}
                  cpuUsage={cpu_usage}
                  memoryRequest={memory_request}
                  memoryTotal={memory_total}
                  memoryUsage={memory_usage}
                />
              </Stack>
            </Stack>
          </Card>
        </AccountCardRouterWrapper>
      </Stack>
    );
  },
);
