import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { IntegrationLogo } from "../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import { integrationDetails } from "../../communications/common/utils/constants/integrationDetails";
import { IntegrationType } from "../../../../../../store/integrations/integrationsSlice";

interface IntegrationPageOverviewProps {
  type: IntegrationType;
}

export const IntegrationPageOverview: FC<IntegrationPageOverviewProps> = ({
  type,
}) => {
  const details = integrationDetails[type];

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Box p={2} pb={1.5} border={1} borderColor="grey.300" borderRadius={3}>
        <IntegrationLogo variant="colored" type={type} width={40} />
      </Box>
      <Stack>
        <Typography fontWeight="medium">{details.label}</Typography>
        <Typography variant="caption">{details.description}</Typography>
      </Stack>
    </Stack>
  );
};
