import { FC, useCallback } from "react";
import { Chip, CircularProgress, Stack, Typography } from "@mui/material";
import { FilterTriggerComponentProps } from "../../../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { SlackConversationLabel } from "../../../SlackConversationLabel";

export interface SlackAutocompleteRenderTagsProps {
  triggerProps: FilterTriggerComponentProps;
  onChange(values: string[]): void;
  loading?: boolean;
}

export const SlackAutocompleteRenderTags: FC<
  SlackAutocompleteRenderTagsProps
> = ({ triggerProps, onChange, loading }) => {
  const { options, selectedValues, setSelectedValues } = triggerProps;

  const changeHandler = useCallback(
    (values: string[]) => {
      setSelectedValues(values);
      onChange(values);
    },
    [setSelectedValues, onChange],
  );

  const deleteHandler = useCallback(
    (id: string) => () => {
      const values = selectedValues.filter((val: string) => id !== val);

      changeHandler(values);
    },
    [selectedValues, changeHandler],
  );

  const renderValues = selectedValues.slice(0, 3);

  if (loading) {
    return (
      <Stack direction="row" spacing={2} pl={1} alignItems="center">
        <Typography color="text.secondary">Loading</Typography>
        <Typography color="text.secondary">
          <CircularProgress size={16} color="inherit" />
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing={1} overflow="hidden">
      {renderValues.map((val) => {
        const option = options.find((option) => option.value === val)?.rawValue;

        return (
          <Chip
            key={val}
            size="small"
            variant="outlined"
            onDelete={deleteHandler(val)}
            label={
              <SlackConversationLabel
                type={option?.type}
                name={option?.name}
                maxWidth={330 / renderValues.length}
              />
            }
          />
        );
      })}

      {selectedValues.length > renderValues.length && (
        <Typography color="text.secondary">
          + {selectedValues.length - renderValues.length}
        </Typography>
      )}
    </Stack>
  );
};
