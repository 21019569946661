import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  KubernetesLiveUsageMgmtSlice,
  KubernetesLiveUsagePageStateType,
} from "./utils/types";
import { ResourceFilters, ResourceType } from "../../services/cloudchipr.api";

const initialState: KubernetesLiveUsageMgmtSlice = {
  clusterIds: null,
  state: "uninitialized",
  isPageLoading: false,
  regions: null,
  selected: {},
  viewWithoutFiltersResourceTypes: {},
  filterTemplatesEnabled: true,
  appliedFilterTemplateId: null,
  filters: {
    appliedFilters: {},
    filterChangedManually: false,
  },
};

const kubernetesLiveUsageMgmtSlice = createSlice({
  name: "kubernetesLiveUsageMgmt",
  initialState,
  reducers: {
    setKubernetesLiveUsageMgmtAppliedFilterTemplateId: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.appliedFilterTemplateId = action.payload;
      state.filters.filterChangedManually = !action.payload;
    },

    setKubernetesLiveUsageMgmtAppliedFilters: (
      state,
      action: PayloadAction<ResourceFilters>,
    ) => {
      const filters = action.payload;

      state.filters = {
        ...state.filters,
        appliedFilters: {
          ...state.filters.appliedFilters,
          [filters.type]: filters,
        },
      };
    },
    setKubernetesLiveUsageMgmtFilterTemplatesEnabled: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.filterTemplatesEnabled = action.payload;
    },

    setKubernetesLiveUsageMgmtFilterChangedManually: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      if (action.payload === undefined) {
        state.filters.filterChangedManually = true;
      } else {
        state.filters.filterChangedManually = action.payload;
      }
    },
    setKubernetesLiveUsageMgmtPageState: (
      state,
      action: PayloadAction<KubernetesLiveUsagePageStateType>,
    ) => {
      state.state = action.payload;
    },
    setKubernetesLiveUsageMgmtIsPageLoading: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isPageLoading = action.payload;
    },
    setKubernetesLiveUsageMgmtViewWithoutFiltersResourceTypes: (
      state,
      action: PayloadAction<{
        resourceType: ResourceType;
        value: boolean;
      }>,
    ) => {
      const { resourceType, value } = action.payload;
      state.viewWithoutFiltersResourceTypes[resourceType] = value;
    },

    resetLiveUsageMgmtViewWithoutFiltersResourceTypes: (state) => {
      state.viewWithoutFiltersResourceTypes = {};
    },

    setLiveUsageMgmtRegion: (state, action: PayloadAction<string[] | null>) => {
      state.regions = action.payload;
    },

    setKubernetesLiveUsageMgmtClusterIds: (
      state,
      action: PayloadAction<KubernetesLiveUsageMgmtSlice["clusterIds"]>,
    ) => {
      state.clusterIds = action.payload;
    },

    setKubernetesLiveUsageMgmtSelectedResources: (
      state,
      action: PayloadAction<{
        resourceType: ResourceType;
        selectedResources: Record<string, boolean>;
        parentSource: string;
      }>,
    ) => {
      const { resourceType, selectedResources, parentSource } = action.payload;

      state.selected[resourceType] = {
        ...state.selected[resourceType],
        [parentSource]: { ...selectedResources },
      };
    },
    resetKubernetesLiveUsageMgmtSelectedResources: (
      state,
      action: PayloadAction<ResourceType>,
    ) => {
      state.selected[action.payload] = {};
    },
    resetKubernetesLiveUsageMgmtAllSelectedResources: (state) => {
      state.selected = {};
    },

    resetKubernetesLiveUsageMgmtSlice: () => initialState,
  },
});

export const {
  setKubernetesLiveUsageMgmtClusterIds,
  setKubernetesLiveUsageMgmtPageState,
  setKubernetesLiveUsageMgmtFilterTemplatesEnabled,
  setKubernetesLiveUsageMgmtViewWithoutFiltersResourceTypes,
  setKubernetesLiveUsageMgmtAppliedFilterTemplateId,
  setLiveUsageMgmtRegion,
  resetLiveUsageMgmtViewWithoutFiltersResourceTypes,
  setKubernetesLiveUsageMgmtSelectedResources,
  resetKubernetesLiveUsageMgmtSlice,
  setKubernetesLiveUsageMgmtFilterChangedManually,
  setKubernetesLiveUsageMgmtAppliedFilters,
  resetKubernetesLiveUsageMgmtSelectedResources,
  resetKubernetesLiveUsageMgmtAllSelectedResources,
  setKubernetesLiveUsageMgmtIsPageLoading,
} = kubernetesLiveUsageMgmtSlice.actions;

export default kubernetesLiveUsageMgmtSlice.reducer;
