import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { BarChart } from "../../../../../../../../storybook/charts/bar-chart/BarChart";
import { money } from "../../../../../../../../utils/numeral/money";

interface KubernetesClusterClusterIdleTotalCostsColumnProps {
  idleMonthlyCost?: number;
  reservedMonthlyCost?: number;
  totalMonthlyCost?: number;
}

export const KubernetesClusterClusterIdleTotalCostsColumn: FC<
  KubernetesClusterClusterIdleTotalCostsColumnProps
> = ({ idleMonthlyCost, reservedMonthlyCost, totalMonthlyCost }) => {
  return (
    <Stack>
      <Typography variant="body2">
        <Typography variant="inherit" component="span" color="error">
          {money(idleMonthlyCost)}
        </Typography>{" "}
        / {money(reservedMonthlyCost)}
      </Typography>
      <BarChart
        partial={idleMonthlyCost ?? 0}
        total={totalMonthlyCost ?? 0}
        empty={!idleMonthlyCost && !reservedMonthlyCost}
        colors={{
          total: grey["400"],
          partial: red[500],
        }}
      />
    </Stack>
  );
};
