import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../store/hooks";
import { TrendType } from "../../../../../../../../../../../../services/cloudchipr.api";
import { coverageWidgetSetupTrendTypeSelector } from "../../../../../../../../../../../../store/dashboards/selectors/setups/coverage/coverageWidgetSetupTrendTypeSelector";
import { WidgetSetupTrendSettingSelect } from "../../../../../common/toolbar/WidgetSetupTrendSettingSelect";
import { coverageWidgetSetupDataChangeThunk } from "../../../../../../../../../../../../store/dashboards/thunks/widgets/coverage-widget/setup-change/coverageWidgetSetupDataChangeThunk";

export const CoverageWidgetAdvancedSettingsTrendSetting: FC = () => {
  const dispatch = useAppDispatch();
  const trendType: TrendType = useAppSelector(
    coverageWidgetSetupTrendTypeSelector,
  );

  const handleTrendTypeChange = useCallback(
    (trendType: TrendType) => {
      dispatch(coverageWidgetSetupDataChangeThunk({ trendType }));
    },
    [dispatch],
  );

  return (
    <WidgetSetupTrendSettingSelect
      trendType={trendType}
      onTrendTypeChange={handleTrendTypeChange}
    />
  );
};
