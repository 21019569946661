import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { orgsByProviderSelector } from "../../../../../../store/accounts/selectors/all-providers/orgsByProviderSelector";
import { useAppSelector } from "../../../../../../store/hooks";
import { accountOrganisationIdSelector } from "../../../../../../store/accounts/selectors/slice-data/accountOrganisationIdSelector";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import { generateLiveResourcesPath } from "../../../utils/helpers/generateLiveResourcesPath";
import { ProviderIcon } from "../../../../../common/ProviderIcon";
import { useLiveUsageLastVisitedOrgHook } from "../../../../../app-navigation/utils/hooks/useLiveUsageLastVisitedOrg.hook";
import {
  noOrgAccountOrgId,
  noOrgAccountOrgName,
} from "../../../utils/helpers/noOrganizationConstants";

interface AccountsOrgIdSelectProps {
  provider: ProviderType;
}

export const AccountsOrgIdSelect: FC<AccountsOrgIdSelectProps> = ({
  provider,
}) => {
  const navigate = useNavigate();
  const orgs = useAppSelector((state) =>
    orgsByProviderSelector(state, provider),
  );
  const selectedOrg = useAppSelector(accountOrganisationIdSelector);
  const { saveOrgId } = useLiveUsageLastVisitedOrgHook(provider);

  const changeHandler = useCallback(
    (event: SelectChangeEvent) => {
      navigate(
        generateLiveResourcesPath({
          provider: provider,
          orgId: event.target.value,
        }),
      );
      saveOrgId(event.target.value);
    },
    [navigate, provider, saveOrgId],
  );

  const renderValue = useCallback(
    (orgId: string) =>
      orgId === noOrgAccountOrgId ? noOrgAccountOrgName.get(provider) : orgId,
    [provider],
  );

  if (!orgs.length || orgs.length === 1) {
    return null;
  }

  return (
    <FormControl size="small" sx={{ width: 210 }}>
      <InputLabel>Organizations</InputLabel>
      <Select
        label="Organizations"
        value={selectedOrg}
        renderValue={renderValue}
        onChange={changeHandler}
      >
        {orgs.map(({ orgId, accounts }) => (
          <MenuItem value={orgId} key={orgId}>
            <Stack direction="row" spacing={1} alignItems="center">
              <ProviderIcon provider={provider} />
              <Stack direction="column" spacing={0.25}>
                <Typography variant="body2" lineHeight={1.1}>
                  {orgId === noOrgAccountOrgId
                    ? noOrgAccountOrgName.get(provider)
                    : orgId}
                </Typography>
                <Typography
                  variant="caption"
                  lineHeight={1.1}
                  color="text.secondary"
                >
                  {accounts.length}{" "}
                  {getProviderName(provider, { plural: accounts.length > 1 })}
                </Typography>
              </Stack>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
