import { resourceDetailsResourceFirstProcessKeySelector } from "./resourceDetailsResourceFirstProcessKeySelector";
import { resourceDetailsResourceBillingDataSelector } from "../resourceDetailsResourceBillingDataSelector";
import { RootState } from "../../../../store";

export const resourceDetailsResourceBillingDataPreviousPeriodDateDetailsSelector =
  (state: RootState) => {
    const firstProcessKey =
      resourceDetailsResourceFirstProcessKeySelector(state);

    return resourceDetailsResourceBillingDataSelector(state, firstProcessKey)
      ?.previous_period_cost_date_details;
  };
