import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { BudgetsAndAlertsHierarchySelectTrigger } from "../../common/BudgetsAndAlertsHierarchySelectTrigger";
import { HierarchyViewSelectionWrapper } from "../../../../../../common/hierarchy-view-selection/HierarchyViewSelectionWrapper";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { alertsCostAnomalyDataViewIdSelector } from "../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataViewIdSelector";
import { setAlertsData } from "../../../../../../../../store/alerts/alertsSlice";
import { getCostAnomalyPreviewDataGridDataThunk } from "../../../../../../../../store/alerts/thunks/cost-anomaly-alerts/getCostAnomalyPreviewDataGridDataThunk";
import { changeCostAnomalyAlertGroupingByViewIdThunk } from "../../../../../../../../store/alerts/thunks/cost-anomaly-alerts/changeCostAnomalyAlertGroupingByViewIdThunk";

interface AlertsCostsAnomalyViewSelectProps {
  averageCostCollapsed: boolean;
}

export const AlertsCostsAnomalyViewSelect: FC<
  AlertsCostsAnomalyViewSelectProps
> = ({ averageCostCollapsed }) => {
  const dispatch = useAppDispatch();
  const selectedViewId = useAppSelector(alertsCostAnomalyDataViewIdSelector);

  const handleChange = useCallback(
    (viewId: string) => {
      if (viewId !== selectedViewId) {
        dispatch(
          setAlertsData({
            viewId,
          }),
        );
        dispatch(changeCostAnomalyAlertGroupingByViewIdThunk(viewId));

        if (averageCostCollapsed) {
          dispatch(getCostAnomalyPreviewDataGridDataThunk());
        }
      }
    },
    [dispatch, selectedViewId, averageCostCollapsed],
  );

  return (
    <Stack flex={1}>
      <HierarchyViewSelectionWrapper
        selectedItemId={selectedViewId ?? ""}
        onChange={handleChange}
        CustomTriggerComponent={BudgetsAndAlertsHierarchySelectTrigger}
        visibility="visible_to_everyone"
      />
    </Stack>
  );
};
