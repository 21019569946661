import { FC, Fragment } from "react";
import { Box, Divider } from "@mui/material";
import {
  NotificationsEmailSelection,
  NotificationsEmailSelectionProps,
} from "./NotificationsEmailSelection";

export const NotificationsEmailSelectionWrapper: FC<
  NotificationsEmailSelectionProps
> = ({
  emailsChangeHandler,
  selectedEmails,
  emailsErrors,
  customMessageConfigs,
  selectedIntegrations,
  disabledIntegrationTypes,
  onAddNewIntegration,
  onCreateDialogOpen,
}) => {
  if (!selectedEmails) {
    return null;
  }

  return (
    <Fragment>
      <NotificationsEmailSelection
        emailsErrors={emailsErrors}
        selectedEmails={selectedEmails}
        emailsChangeHandler={emailsChangeHandler}
        selectedIntegrations={selectedIntegrations}
        disabledIntegrationTypes={disabledIntegrationTypes}
        onAddNewIntegration={onAddNewIntegration}
        onCreateDialogOpen={onCreateDialogOpen}
        customMessageConfigs={customMessageConfigs}
      />

      {!!selectedIntegrations?.length && (
        <Box my={2}>
          <Divider>AND</Divider>
        </Box>
      )}
    </Fragment>
  );
};
