import { FC, useMemo } from "react";
import { AzureUtilizationAndCoverageDataViewTabs } from "./components/AzureUtilizationAndCoverageDataViewTabs";
import { azureSavingsPlanCoverageTableColumnDefs } from "./columns/azureSavingsPlanCoverageTableColumnDefs";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { azureSavingsPlansCoverageDataSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/azureSavingsPlansCoverageDataSelector";
import { azureSavingsPlansForTableLoadingSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/loading/azureSavingsPlansForTableLoadingSelector";
import { commitmentsCoverageGroupingSelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsCoverageGroupingSelector";
import { CommitmentsAGGridToolbar } from "../../../../../common/components/data-grid/CommitmentsAGGridToolbar";
import {
  CommitmentsAgGrid,
  CommitmentsToolbar,
} from "../../../../../common/components/CommitmentsAgGrid";

export const AzureSavingsPlanCoverageDataGrid: FC = () => {
  const data = useAppSelector(azureSavingsPlansCoverageDataSelector);
  const loading = useAppSelector(azureSavingsPlansForTableLoadingSelector);
  const grouping = useAppSelector(commitmentsCoverageGroupingSelector);

  const columnDefs = useMemo(
    () => azureSavingsPlanCoverageTableColumnDefs(grouping),
    [grouping],
  );

  return (
    <CommitmentsAgGrid
      key={grouping}
      Toolbar={toolbar}
      loading={loading}
      columnDefs={columnDefs}
      data={data}
      grouping={grouping}
      localStorageUniqueKey="azureSavingsPlanDataGrid"
    />
  );
};

const toolbar: CommitmentsToolbar = ({ gridApi }) => (
  <CommitmentsAGGridToolbar gridApi={gridApi}>
    <AzureUtilizationAndCoverageDataViewTabs />
  </CommitmentsAGGridToolbar>
);
