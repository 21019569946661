import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useGetUsersMeIntegrationsQuery } from "../../../../../services/cloudchipr.api";
import EmptyStateImage from "../../../../../assets/images/empty_integrations_list.png";
import { useAppAbility } from "../../../../../services/permissions";
import { getUserPermissionInfoText } from "../../../utils/helpers/getUserPermissionInfoText";

export const IntegrationsEmptyState = () => {
  const { data, isLoading } = useGetUsersMeIntegrationsQuery({});
  const { cannot } = useAppAbility();
  const canNotCreateIntegration = cannot("create", "integration");

  const userPermissionTooltipText = canNotCreateIntegration
    ? getUserPermissionInfoText("Create Integration")
    : "";

  if (isLoading || data?.length) {
    return null;
  }

  return (
    <Stack justifyContent="center" alignItems="center" mt={12}>
      <img src={EmptyStateImage} alt="EmptyStateImage" />
      <Typography component="h5" variant="h5" fontWeight="bold" mt={6}>
        It’s time to plan your first Integration!
      </Typography>
      <Typography
        variant="h6"
        fontWeight="regular"
        color="text.secondary"
        mt={2}
      >
        Choose between our list of supported providers!
      </Typography>
      <Tooltip title={userPermissionTooltipText}>
        <span>
          <Button
            sx={{ mt: 4 }}
            disabled={canNotCreateIntegration}
            variant="contained"
            color="primary"
            size="large"
            component={RouterLink}
            to="/integrations/create"
          >
            Create Integration
          </Button>
        </span>
      </Tooltip>
    </Stack>
  );
};
