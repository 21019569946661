import { Stack } from "@mui/material";
import { ExplainWithAiBillingExplorerMetaData } from "./body/re-meta-data/ExplainWithAiBillingExplorerMetaData";
import { BillingExplorerExplainWithAiCollapsableContent } from "./body/collapsed/BillingExplorerExplainWithAiCollapsableContent";
import { useAppSelector } from "../../../../../../store/hooks";
import { resourceExplorerDatesSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDatesSelector";
import { resourceExplorerGroupingSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { resourceExplorerTrendTypeSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerTrendTypeSelector";
import { billingExplorerExplainWithAiNewDataNeededSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiNewDataNeededSelector";

export const BillingExplorerExplainWithAiPanelBody = () => {
  const newDataNeeded = useAppSelector(
    billingExplorerExplainWithAiNewDataNeededSelector,
  );

  const dates = useAppSelector(resourceExplorerDatesSelector);
  const grouping = useAppSelector(resourceExplorerGroupingSelector);
  const trendType = useAppSelector(resourceExplorerTrendTypeSelector);

  return (
    <Stack spacing={1.5}>
      {newDataNeeded && (
        <Stack bgcolor="grey.200" borderRadius={2} p={1.5} pb={0}>
          <ExplainWithAiBillingExplorerMetaData
            grouping={grouping}
            trendType={trendType}
            dates={dates}
            showExplainButton
            index={0}
            showOutDateRanges
          />
        </Stack>
      )}

      <BillingExplorerExplainWithAiCollapsableContent />
    </Stack>
  );
};
