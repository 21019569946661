import { FC, useCallback, useMemo } from "react";
import { GridApi } from "ag-grid-community";
import { IconButton, Tooltip } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import moment from "moment/moment";
import { useAppSelector } from "../../../../../../store/hooks";
import { alertsActiveTabSelector } from "../../../../../../store/alerts/selectors/alertsActiveTabSelector";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { AlertTabType } from "../../../../../../store/alerts/utils/types/types";

interface BudgetAndAlertsCsvExportProps {
  gridApi: GridApi | null;
}

export const BudgetAndAlertsCsvExport: FC<BudgetAndAlertsCsvExportProps> = ({
  gridApi,
}) => {
  const alertsActiveTab = useAppSelector(alertsActiveTabSelector);
  const date = formatDate(moment(), { type: "date" });
  const columnKeys = useMemo(
    () =>
      gridApi
        ?.getAllGridColumns()
        ?.map((col) => col.getColId())
        ?.filter((id) => id !== "actions" && id !== "toFitEmptySpace"),
    [gridApi],
  );

  const fileName = `${date}_${csvFileNameByType[alertsActiveTab]}`;

  const exportDataAsCsv = useCallback(() => {
    gridApi?.exportDataAsCsv({
      fileName,
      columnKeys,
    });
  }, [gridApi, fileName, columnKeys]);

  return (
    <Tooltip title="Export CSV" arrow placement="top">
      <IconButton size="small" onClick={exportDataAsCsv}>
        <DownloadOutlinedIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

const csvFileNameByType: Record<AlertTabType, string> = {
  costAnomaly: "cost_anomaly_alerts",
  utilization: "commitment_utilization_alerts",
  budget: "budget",
};
