import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { ProvidersFiltersTree } from "./provider-dynamic-filters/ProvidersFiltersTree";
import { FilterTreeProvider } from "./FilterTreeProvider";
import { FiltersMainActions } from "./new-filter-creator/filters-actions/FiltersMainActions";
import { RevertButtonProps } from "./new-filter-creator/filters-actions/RevertButton";
import { NewFilterAddFn } from "./new-filter-creator/popover/FilterItemSelectMenuItem";
import {
  FilterItemNode,
  FilterOperatorType,
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
  ResourceExplorerPossibleFilterV2,
} from "../../../../../services/cloudchipr.api";
import { addFilterOnFirstLevel } from "../utils/helpers/addFilterOnFirstLevel";
import {
  FilterGroupNodeWithId,
  FilterTreeNodeWithId,
} from "../utils/types/common";
import { isTreeNodeFilterGroup } from "../utils/helpers/isTreeNodeFilterGroup";
import { DropdownSelectOption } from "../../../../common/select/dropdown-select/utils/types/types";

interface FilterTreeProps extends RevertButtonProps {
  possibleFilters: ResourceExplorerPossibleFilterV2[];
  filterTree?: FilterTreeNodeWithId;
  showSelectOnFirstOpening?: boolean;
  withIncludeAllProviderAccountsOption?: boolean;
  setFilters(filters: FilterGroupNodeWithId): void;
  renderFilterValueOption?: FC<DropdownSelectOption>;
}

export const FilterTree: FC<FilterTreeProps> = ({
  filterTree,
  setFilters,
  possibleFilters,
  showSelectOnFirstOpening,
  onRevertFilters,
  renderFilterValueOption,
  withIncludeAllProviderAccountsOption,
}) => {
  const wrongStructure = filterTree && !isTreeNodeFilterGroup(filterTree);

  const addNewFiltersHandler = useCallback<NewFilterAddFn>(
    (
      provider: ResourceExplorerFilterProvider,
      key: ResourceExplorerDynamicFilterItemType,
      operator: FilterOperatorType,
      value?: FilterItemNode["value"],
    ) => {
      if (wrongStructure) {
        return;
      }

      const filterNewTree = addFilterOnFirstLevel(
        provider,
        key,
        operator,
        filterTree,
        value,
      );

      setFilters(filterNewTree);
    },
    [setFilters, filterTree, wrongStructure],
  );

  if (wrongStructure) {
    return null;
  }

  return (
    <FilterTreeProvider
      possibleFilters={possibleFilters}
      renderFilterValueOption={renderFilterValueOption}
      withIncludeAllProviderAccountsOption={
        withIncludeAllProviderAccountsOption
      }
    >
      <Stack spacing={2}>
        {filterTree && (
          <ProvidersFiltersTree
            filterTree={filterTree}
            setFilters={setFilters}
          />
        )}

        <FiltersMainActions
          onRevertFilters={onRevertFilters}
          onAddNewFilter={addNewFiltersHandler}
          showSelectOnFirstOpening={showSelectOnFirstOpening}
        />
      </Stack>
    </FilterTreeProvider>
  );
};
