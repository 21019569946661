import {
  ResourceExplorerFilterProvider,
  ResourceExplorerGroupingNullable,
} from "../../../../../../services/cloudchipr.api";
import { getAzureTagsFeatureToggleFromStorage } from "../../../../resource-explorer/utils/helpers/azure-tags/getAzureTagsFeatureToggleFromStorage";

const providersByGrouping = new Map<
  ResourceExplorerGroupingNullable,
  ResourceExplorerFilterProvider[]
>([
  ["region", ["aws", "gcp", "azure"]],
  ["resource", ["aws", "gcp", "azure", "confluent"]],
  ["cost_allocation_tag", ["aws", "gcp", "mongo"]],
  ["cost_allocation_tag_value", ["aws", "gcp", "mongo"]],
  ["charge_type", ["aws", "gcp", "azure"]],
  ["instance_family", ["aws"]],
  ["instance_type", ["aws"]],
  ["environment", ["confluent"]],
  ["marketplace_service", ["aws", "azure"]],
  ["usage_type", ["aws"]],
  ["description", ["aws"]],
  ["pricing_term", ["aws"]],
  ["cluster", ["mongo", "eks"]],
  ["resource_group", ["azure"]],
]);

export const availableCloudProvidersByGrouping = (
  grouping: ResourceExplorerGroupingNullable,
): ResourceExplorerFilterProvider[] | undefined => {
  const enableAzureBillingExportProcessing =
    getAzureTagsFeatureToggleFromStorage();

  const providers = providersByGrouping.get(grouping);

  const needToShowAzureForTags =
    enableAzureBillingExportProcessing &&
    providers &&
    grouping?.includes("tag");

  if (needToShowAzureForTags) {
    return [...providers, "azure"];
  }

  return providers;
};

export const resourceExplorerHasNoParentKey = "has-no-parent";

export const resourceExplorerGroupingsSequence = new Set([
  "none",
  "account",
  "service",
  "region",
  "resource",
  "product_family",
  "cloud_provider",
  "tag_key",
  "tag_value",
  "charge_type",
  "instance_family",
  "instance_type",
  "environment",
  "marketplace_service",
  "usage_type",
  "pricing_term",
  "description",
  "cluster",
  "resource_group",
]);

export const resourceExplorerGroupingsParentsSequence = new Set([
  resourceExplorerHasNoParentKey,
  "Mongo",
  "EKS (SCAD)",
  "category",
]);
