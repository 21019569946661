import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { deleteSavingsOpportunityViewFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";
import { getSavingsOpportunitiesViewsHierarchyThunk } from "../getSavingsOpportunitiesViewsHierarchyThunk";

export const deleteSavingsOpportunityViewThunk = createAsyncThunk(
  "savingsOpportunities/deleteSavingsOpportunityView",
  async (viewId: string, { dispatch }) => {
    const { deleteUsersMeOrganisationsCurrentOpportunityViewsByViewId } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        deleteUsersMeOrganisationsCurrentOpportunityViewsByViewId.initiate(
          { viewId },
          { fixedCacheKey: deleteSavingsOpportunityViewFixedCacheKey },
        ),
      ).unwrap();
      dispatch(getSavingsOpportunitiesViewsHierarchyThunk());

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
