import { billingSummarySetupWidgetDataResponseSelector } from "./billingSummarySetupWidgetDataResponseSelector";
import { RootState } from "../../../../../store";
import { GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryApiResponse } from "../../../../../../services/cloudchipr.api";

export const billingSummarySetupWidgetDataSelector = (
  state: RootState,
):
  | GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryApiResponse
  | undefined => {
  const response = billingSummarySetupWidgetDataResponseSelector(state);

  return response?.data;
};
