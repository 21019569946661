import { FC, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import { Box, LinearProgress, Stack } from "@mui/material";
import { chartDefaultConfigs } from "../utils/constants/chartDefaultConfigs";
import { chartXAxisConfigs } from "../utils/chart-axises/chartXAxis";
import { chartYAxisConfigs } from "../utils/chart-axises/chartYAxis";

export type HighchartsLoadingType = boolean | { showLoader: boolean };

interface HighchartsLoadingChartMarkupProps {
  loading?: HighchartsLoadingType;
  noDataMessage?: string;
}

export const HighchartsEmptyChartMarkup: FC<
  HighchartsLoadingChartMarkupProps
> = ({ loading, noDataMessage }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    // @ts-expect-error  | Something wrong with types, this is waiting for "Options" but it should be an "Element"
    const chart = Highcharts.chart(chartRef.current, {
      chart: {
        type: "line",
        events: {
          load: noDataMessage
            ? function () {
                this.renderer
                  .text(
                    noDataMessage,
                    this.plotLeft + this.plotWidth / 2,
                    this.plotTop + this.plotHeight / 2,
                  )
                  .css({ color: "#333", fontSize: "16px", textAlign: "center" })
                  .attr({ align: "center" })
                  .add();
              }
            : undefined,
        },
      },
      title: { text: "" },
      xAxis: {
        categories: highchartsLoadingChartData.categories,
        ...chartXAxisConfigs,
      },
      yAxis: { title: { text: "" }, ...chartYAxisConfigs },
      tooltip: { enabled: false },
      series: highchartsLoadingChartData.series,
      noData: {
        text: "No Data Available",
        position: { align: "center", verticalAlign: "middle" },
        style: { fontSize: "16px", color: "#606060" },
      },
      ...chartDefaultConfigs,
    });

    return () => {
      chart.destroy();
    };
  }, [noDataMessage]);

  return (
    <Stack mt={1} height="100%">
      {typeof loading !== "boolean" && loading?.showLoader && (
        <LinearProgress />
      )}

      <Box ref={chartRef} flex={1} width="100%" margin="auto" />
    </Stack>
  );
};

export const highchartsLoadingChartData = {
  categories: [""],
  series: [{ name: "", data: [] }],
};
