import { createAsyncThunk } from "@reduxjs/toolkit";
import { taskManagementUpdateEditingTaskThunk } from "./taskManagementUpdateEditingTaskThunk";
import { taskEditSaveButtonDisabledSelector } from "../../selectors/edit/taskEditSaveButtonDisabledSelector";
import { taskManagementTaskIdSelector } from "../../selectors/form/properties/taskManagementTaskIdSelector";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { RootState } from "../../../store";
import { getTaskActivitiesThunk } from "../activities/getTaskActivitiesThunk";

export const taskManagementUpdateEditingTaskWithActivitiesThunk =
  createAsyncThunk(
    "taskManagement/taskManagementUpdateEditingTaskWithActivities",
    async (_: void, { dispatch, getState }) => {
      const state = getState() as RootState;

      const isDisabled = taskEditSaveButtonDisabledSelector(state);
      const id = taskManagementTaskIdSelector(state);

      if (!id || isDisabled) {
        return;
      }

      try {
        await dispatch(taskManagementUpdateEditingTaskThunk()).unwrap();
        dispatch(getTaskActivitiesThunk(id ?? ""));
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  );
