// todo: use numeral formatting instead of this functions

export const convertByteToGB = (data: number): number => {
  return data / (1024 * 1024 * 1024);
};

export const convertGBToByte = (data: number): number => {
  return data * 1024 * 1024 * 1024;
};

export const convertBytesToMB = (data: number): number => {
  return data / (1024 * 1024);
};

export const convertMbToBytes = (data: number): number => {
  return data * (1024 * 1024);
};
