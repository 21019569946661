import { billingExplorerWidgetSetupDataSelector } from "./billingExplorerWidgetSetupDataSelector";
import { RootState } from "../../../../../store";

export const billingExplorerWidgetSetupForecastedOptionsSelector = (
  state: RootState,
) => {
  const widgetData = billingExplorerWidgetSetupDataSelector(state);

  return widgetData?.forecasted_costs;
};
