import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { DaxCluster } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const daxClusterColumns: ColumnSetupType<DaxCluster>[] = [
  {
    accessorKey: "name",
    header: "Cluster Name",
    type: "identifier",
    size: 210,
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "created_at",
    header: "Date Created",
    type: "date",
  },
  {
    accessorKey: "node_type",
    header: "Node type",
  },
  {
    accessorKey: "node_count",
    header: "Nodes",
  },
  {
    accessorKey: "cpu_utilization_value",
    header: "CPU",
    headerTooltip: "Max CPU for the last 7 days.",
    type: "cpuStatistic",
  },
  {
    accessorKey: "cpu_reservation",
    header: "CPU Reservation",
    headerTooltip: "Current CPU reservation.",
    type: "vCpuReservation",
  },
  {
    accessorKey: "cache_memory_utilization_value",
    header: "Memory",
    headerTooltip: "Max memory for the last 7 days.",
    type: "cpuStatistic",
  },
  {
    accessorKey: "memory_reservation",
    header: "Memory Reservation",
    headerTooltip: "Current Memory reservation.",
    minSize: 210,
    type: "memoryReservation",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
  },
  {
    accessorKey: "total_request_count_value",
    header: "Total Requests",
  },
  {
    accessorKey: "network_bytes_in_value",
    header: "Network In/Out",
    type: "networkInOut",
  },

  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },

  {
    accessorKey: "task",
    header: "Task Status",
    type: "taskStatus",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "JIRA Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
