import { taskManagementTargetSelector } from "../common/taskManagementTargetSelector";
import { RootState } from "../../../../../store";
import { OpportunityTarget } from "../../../../../../services/cloudchipr.api";
import { TaskTargetOpportunity } from "../../../../utils/types/types";
import { tasksListResourceIdsSelector } from "../../../data/tasksListResourceIdsSelector";
import { isTaskManagementDrawerEditModeSelector } from "../../options/isTaskManagementDrawerEditModeSelector";

export const taskManagementTargetOpportunitiesSelector = (
  state: RootState,
): OpportunityTarget[] | undefined => {
  const target = taskManagementTargetSelector(state) as TaskTargetOpportunity;
  const resourceIdsInTasks = tasksListResourceIdsSelector(state);
  const editMode = isTaskManagementDrawerEditModeSelector(state);

  if (editMode) {
    return target?.opportunities;
  }

  return target?.opportunities?.filter(
    (opportunity) => !resourceIdsInTasks[opportunity.provider_identifier],
  );
};
