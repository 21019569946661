import { FC } from "react";
import { FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { costAnomaliesWidgetSortingOrderLabels } from "./utils/constants";
import { PriceDirectionNullable } from "../../../../../../../../../services/cloudchipr.api";

interface CostAnomaliesPriceDirectionItemProps {
  value: PriceDirectionNullable;
  checked: boolean;
}

export const CostAnomaliesPriceDirectionItem: FC<
  CostAnomaliesPriceDirectionItemProps
> = ({ value, checked }) => {
  return (
    <Stack
      flex={1}
      px={1}
      py={0.6}
      border={1}
      borderColor="grey.300"
      borderRadius={1}
    >
      <FormControlLabel
        value={value}
        control={<Radio checked={checked} sx={{ p: 0.5 }} size="small" />}
        label={
          <Stack direction="row" spacing={1.5} alignItems="center">
            {value === "increasing" ? (
              <ArrowUpwardIcon fontSize="small" />
            ) : (
              <ArrowDownwardIcon fontSize="small" />
            )}

            <Typography variant="body2">
              {costAnomaliesWidgetSortingOrderLabels.get(value)}
            </Typography>
          </Stack>
        }
        labelPlacement="start"
        sx={{
          m: 0,
          justifyContent: "space-between",
        }}
      />
    </Stack>
  );
};
