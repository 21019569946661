import { FC, useEffect, useState } from "react";
import { DialogContent, Divider, Stack } from "@mui/material";
import { CleanV2ResourceGrid } from "./components/CleanV2ResourceGrid";
import { CleanV2ConfirmationSection } from "./components/CleanV2ConfirmationSection";
import { CleanV2DescriptionSection } from "./components/CleanV2DescriptionSection";
import { CleanV2ExcludedResourcesInfo } from "./components/CleanV2ExcludedResourcesInfo";
import { useAppSelector } from "../../../../../../../store/hooks";
import { CleanResourcesDataType } from "../../utils/types";
import { cleanV2DetailsResourceTypeSelector } from "../../../../../../../store/clean-v2/selectors/store-selectors/cleanV2DetailsResourceTypeSelector";
import { cleanV2ResourcesGridDataSelector } from "../../../../../../../store/clean-v2/selectors/cleanV2ResourcesGridDataSelector";

interface CleanV2DialogContentProps {
  cleanInputValue: string;
  onCleanInputChange(value: string): void;
  actionType: string;
}
export const CleanV2DialogContent: FC<CleanV2DialogContentProps> = ({
  cleanInputValue,
  onCleanInputChange,
  actionType,
}) => {
  const resourceType = useAppSelector(cleanV2DetailsResourceTypeSelector);
  const [localCleanableData, setLocalCleanableData] =
    useState<CleanResourcesDataType>([]);

  const data = useAppSelector(cleanV2ResourcesGridDataSelector);

  useEffect(() => {
    data?.length && setLocalCleanableData(data);
  }, [data]);

  if (!resourceType) {
    return null;
  }

  return (
    <DialogContent dividers sx={{ pb: 3, p: 0 }}>
      <CleanV2DescriptionSection resourceType={resourceType} />

      <Stack px={3} spacing={4} mb={3}>
        <CleanV2ResourceGrid
          resourceType={resourceType}
          data={localCleanableData}
        />

        <CleanV2ExcludedResourcesInfo />
      </Stack>

      <Divider />

      <CleanV2ConfirmationSection
        cleanInputValue={cleanInputValue}
        onCleanInputChange={onCleanInputChange}
        placeholder={actionType}
      />
    </DialogContent>
  );
};
