import { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { ResourceExplorerBreadcrumbItem } from "./ResourceExplorerBreadcrumbItem";
import { ResourceExplorerBreadcrumbsWithMenu } from "./ResourceExplorerBreadcrumbsWithMenu";
import { ResourceExplorerBreadcrumbsBackButton } from "./ResourceExplorerBreadcrumbsBackButton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerPathSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathSelector";
import { resourceExplorerGroupingSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { changePathOnBreadcrumbSelectThunk } from "../../../../../../../store/resource-explorer/thunks/widgets/path/changePathOnBreadcrumbSelectThunk";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";

export const ResourceExplorerBreadcrumbs: FC = () => {
  const dispatch = useAppDispatch();

  const path = useAppSelector(resourceExplorerPathSelector);
  const groupBy = useAppSelector(resourceExplorerGroupingSelector);
  const groupingLabel = useAppSelector((state) =>
    resourceExplorerGroupingLabelByGroupingSelector(state, groupBy),
  );

  const breadcrumbClickHandler = useCallback(
    (index: number) => {
      dispatch(changePathOnBreadcrumbSelectThunk(index));
    },
    [dispatch],
  );

  if (path.length === 1) {
    return (
      <Typography variant="body1" sx={{ pl: 1.5 }}>
        {groupingLabel?.plural}
      </Typography>
    );
  }

  return (
    <Stack direction="row" spacing={1} overflow="auto" alignItems="center">
      <ResourceExplorerBreadcrumbsBackButton />

      {path.length > 4 ? (
        <ResourceExplorerBreadcrumbsWithMenu />
      ) : (
        <Stack
          px={1.5}
          py={0.5}
          spacing={1}
          direction="row"
          borderRadius={3}
          bgcolor="grey.200"
          width="fit-content"
          alignItems="center"
          overflow="auto"
        >
          {path.map((pathItem, index) => (
            <ResourceExplorerBreadcrumbItem
              pathItem={pathItem}
              index={index}
              key={pathItem.groupedBy + pathItem.name}
              onBreadcrumbClick={breadcrumbClickHandler}
              pathLength={path.length}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};
