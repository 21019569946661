import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Stack } from "@mui/material";
import { SavingsPlanRecommendation } from "../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import {
  commitmentsPaymentOptionLabels,
  savingsPlansTypeLabels,
  savingsPlanTermLabels,
} from "../../../../../common/utils/constants/labels";
import { money } from "../../../../../../../../utils/numeral/money";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { CommitmentsRecommendationsEstimatedMonthlySavingCell } from "../../../../../reservations/components/content/recomendations/cells/CommitmentsRecommendationsEstimatedMonthlySavingCell";

export const commitmentsRecommendationsGridColumnDefs: ColDef<SavingsPlanRecommendation>[] =
  [
    {
      minWidth: 200,
      field: "savings_plan_type",
      headerName: "Type",
      suppressKeyboardEvent: () => true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanRecommendation>) =>
        data ? savingsPlansTypeLabels[data.savings_plan_type] : "",
      valueGetter: ({ data }) =>
        data ? savingsPlansTypeLabels[data.savings_plan_type] : "",
    },
    {
      minWidth: 200,
      width: 300,
      field: "term_in_years",
      headerName: "Savings Plans Term",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanRecommendation>) =>
        data ? savingsPlanTermLabels[data.term_in_years] : "",
      valueGetter: ({ data }) =>
        data ? savingsPlanTermLabels[data.term_in_years] : "",
    },
    {
      minWidth: 150,
      width: 200,
      field: "instance_family",
      headerName: "Instance Family",
    },
    {
      minWidth: 150,
      width: 200,
      field: "region",
      headerName: "Region",
    },
    {
      minWidth: 200,
      width: 250,
      field: "payment_option",
      headerName: "Payment Option",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanRecommendation>) =>
        data ? commitmentsPaymentOptionLabels[data.payment_option] : "",
      valueGetter: ({ data }) =>
        data ? commitmentsPaymentOptionLabels[data.payment_option] : "",
    },
    {
      minWidth: 150,
      width: 200,
      field: "account",
      headerName: "Account",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanRecommendation>) => {
        if (!data) {
          return;
        }

        return (
          <Stack>
            <TypographyWithTooltip
              variant="body2"
              title={data.account.provider_account_name}
            />
            <TypographyWithTooltip
              variant="body2"
              color="text.secondary"
              title={data.account.provider_account_id}
            />
          </Stack>
        );
      },
      valueGetter: ({ data }) =>
        data
          ? `${data.account.provider_account_name} ${data.account.provider_account_id}`
          : "",
    },
    {
      minWidth: 150,
      width: 200,
      field: "hourly_commitment",
      headerName: "Commitment",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanRecommendation>) =>
        money(data?.hourly_commitment ?? 0),
      valueGetter: ({ data }) => money(data?.hourly_commitment ?? 0),
    },
    {
      minWidth: 250,
      width: 300,
      field: "estimated_coverage",
      headerName: "Estimated On-Demand Coverage",
    },
    {
      minWidth: 150,
      width: 200,
      field: "estimated_monthly_savings",
      headerName: "EMS",
      resizable: false,
      pinned: "right",
      lockPinned: true,
      lockVisible: true,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanRecommendation>) => (
        <CommitmentsRecommendationsEstimatedMonthlySavingCell
          value={data?.estimated_monthly_savings ?? 0}
          link={data?.action_url ?? ""}
        />
      ),
    },
    agGridEmptyColumnToFitEmptySpace,
  ];
