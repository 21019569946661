import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { commitmentsTypeTabSelector } from "../../selectors/common/commitmentsTypeTabSelector";
import { getAzureReservationsForDataGridThunk } from "../azure/reservations/getAzureReservationsForDataGridThunk";
import { getSavingsPlansForDataGridThunk } from "../savings-plans/getSavingsPlansForDataGridThunk";
import { getReservationsForDataGridThunk } from "../reservations/getReservationsForDataGridThunk";
import { commitmentsCurrentOrgProviderSelector } from "../../selectors/common/commitmentsCurrentOrgProviderSelector";
import { getAzureSavingsPlansForDataGridThunk } from "../azure/saving-plans/getAzureSavingsPlansForDataGridThunk";

export const changeReservationDataGridGroupingThunk = createAsyncThunk(
  "commitments/changeReservationDataGridGrouping",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const activeTab = commitmentsTypeTabSelector(state);
    const provider = commitmentsCurrentOrgProviderSelector(state);
    if (provider === "azure") {
      if (activeTab === "reservation") {
        dispatch(getAzureReservationsForDataGridThunk());
      } else {
        dispatch(getAzureSavingsPlansForDataGridThunk());
      }
      return;
    }
    if (activeTab === "reservation") {
      dispatch(getReservationsForDataGridThunk());
    } else {
      dispatch(getSavingsPlansForDataGridThunk());
    }
  },
);
