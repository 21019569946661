import { FC, Fragment } from "react";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CurrentResourceExplorerActionsMenu } from "./CurrentResourceExplorerActionsMenu";
import { useMenuHook } from "../../../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { NavigationItemsVisibilityType } from "../../../../../../../../store/navigation/utils/types";
import {
  EmbeddedUser,
  ProtectionDetails,
} from "../../../../../../../../services/cloudchipr.api";

interface CurrentResourceExplorerActionButtonProps {
  id: string;
  name: string;
  folderId?: string;
  protectedBy?: EmbeddedUser;
  size?: "small" | "medium" | "large";
  protectionDetails?: ProtectionDetails;
  visibility: NavigationItemsVisibilityType;
}

export const CurrentResourceExplorerActionButton: FC<
  CurrentResourceExplorerActionButtonProps
> = ({
  id,
  name,
  size,
  folderId,
  visibility,
  protectedBy,
  protectionDetails,
}) => {
  const { cannot } = useAppAbility();
  const cannotDeleteResourceExplorer = cannot("delete", "resource-explorer");

  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  if (cannotDeleteResourceExplorer) {
    return null;
  }

  return (
    <Fragment>
      <IconButton size="small" onClick={openMenu}>
        <MoreVertIcon fontSize={size ?? "inherit"} />
      </IconButton>

      <CurrentResourceExplorerActionsMenu
        id={id}
        name={name}
        open={open}
        anchor={anchor}
        folderId={folderId}
        closeMenu={closeMenu}
        visibility={visibility}
        protectedBy={protectedBy}
        protectionDetails={protectionDetails}
      />
    </Fragment>
  );
};
