import { taskManagementTargetOpportunitiesSelector } from "./taskManagementTargetOpportunitiesSelector";
import { RootState } from "../../../../../store";

export const taskManagementTargetOpportunitiesTitlesSelector = (
  state: RootState,
): string[] | undefined => {
  const opportunities = taskManagementTargetOpportunitiesSelector(state);

  return opportunities?.map(
    (opportunity) => opportunity?.provider_identifier ?? "",
  );
};
