import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatUnderlined,
  SvgIconComponent,
} from "@mui/icons-material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import CodeIcon from "@mui/icons-material/Code";
import TerminalIcon from "@mui/icons-material/Terminal";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import { CommandName, TextAlignments } from "../types/types";

export const textAlignIcons: Record<TextAlignments, SvgIconComponent> = {
  right: FormatAlignRight,
  left: FormatAlignLeft,
  center: FormatAlignCenter,
  justify: FormatAlignJustify,
};

export const commandIconsByCommandName = new Map<CommandName, SvgIconComponent>(
  [
    ["bold", FormatBoldIcon],
    ["italic", FormatItalicIcon],
    ["underline", FormatUnderlined],
    ["strike", StrikethroughSIcon],
    ["code", CodeIcon],
    ["codeBlock", TerminalIcon],
    ["blockquote", FormatQuoteIcon],
    ["bulletList", FormatListBulletedIcon],
    ["orderedList", FormatListNumberedIcon],
    ["clearNode", FormatPaintIcon],
  ],
);
