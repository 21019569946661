import { FC, ReactNode, useCallback, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import {
  IconButton,
  IconButtonTypeMap,
  Theme,
  Tooltip,
  TooltipProps,
  useTheme,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { useTimeoutWhen } from "rooks";

interface CopyIconButtonProps {
  data: string;
  visible?: boolean;
  color?: IconButtonTypeMap["props"]["color"];
  sx?: SxProps<Theme>;
  children?: ReactNode;
  TooltipProps?: Omit<TooltipProps, "children" | "title"> & { title?: string };
  onGetDataValue?(): string;
}

export const CopyIconButton: FC<CopyIconButtonProps> = ({
  data,
  visible = true,
  color,
  sx,
  children,
  TooltipProps,
  onGetDataValue,
}) => {
  const [copied, setCopied] = useState(false);

  useTimeoutWhen(
    () => {
      setCopied(false);
    },
    3000,
    copied,
  );

  const handleCopyClick = useCallback(
    (e: any) => {
      setCopied(true);
      if (onGetDataValue) {
        const data = onGetDataValue();
        copy(data);
      } else {
        copy(data);
      }

      e.preventDefault();
      e.stopPropagation();
    },
    [data, onGetDataValue],
  );
  const theme = useTheme();
  const { enteringScreen } = theme.transitions.duration;

  return (
    <Tooltip
      arrow
      TransitionProps={{ timeout: { enter: enteringScreen, exit: 0 } }}
      {...TooltipProps}
      title={copied ? "Copied!" : (TooltipProps?.title ?? "Copy")}
    >
      <IconButton
        onClick={handleCopyClick}
        size="small"
        color={color}
        sx={{ visibility: !visible ? "hidden" : "initial", ml: 1, ...sx }}
      >
        {children ?? <ContentCopyIcon fontSize="inherit" />}
      </IconButton>
    </Tooltip>
  );
};
