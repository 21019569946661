import { ColDef, ColDefField } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../store/alerts/utils/types/types";
import { UserActivityCell } from "../../../../../../common/data-grid-cells/UserActivityCell";
import { budgetAndAlertsCreatedByValueGetter } from "../utils/helpers/valueGetters/budgetAndAlertsCreatedByValueGetter";
import { budgetAndAlertsCreatedByFilterValueGetter } from "../utils/helpers/filterValueGetters/budgetAndAlertsCreatedByFilterValueGetter";

export const getAlertsCreatedByColumnDef = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(): ColDef<T> => ({
  field: "created_by" as ColDefField<T>,
  headerName: "Created By",
  minWidth: 150,
  width: 200,
  cellRenderer: ({ data }: CustomCellRendererProps<T>) => {
    if (!data) {
      return;
    }

    return (
      <UserActivityCell
        date={data.created_at}
        name={data.created_by?.name ?? data.created_by?.email ?? ""}
        deleted={data.created_by?.status === "deleted"}
        profile={data.created_by?.profile}
      />
    );
  },
  filterValueGetter: budgetAndAlertsCreatedByFilterValueGetter,
  valueGetter: budgetAndAlertsCreatedByValueGetter,
});
