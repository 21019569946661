import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { ReservationsCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { CommitmentsProgressBar } from "../../../../../../../../common/components/CommitmentsProgressBar";

export const totalCoverageColumnDef: ColDef<ReservationsCoverageDataWithId> = {
  flex: 1.5,
  field: "total_coverage",
  minWidth: 200,
  headerName: "Total Coverage",
  cellRenderer: ({
    data,
  }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
    <CommitmentsProgressBar value={data?.total_coverage ?? null} />
  ),
};
