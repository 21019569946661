import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { updateSavingsOpportunityViewThunk } from "./updateSavingsOpportunityViewThunk";
import { PatchUsersMeOrganisationsCurrentOpportunityViewsByViewIdApiArg } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { savingsOpportunitiesCurrentViewIdSelector } from "../../../selectors/views-hierarchy/current-view/savingsOpportunitiesCurrentViewIdSelector";
import { savingsOpportunityCurrentViewConfigSelector } from "../../../selectors/views-hierarchy/current-view/savingsOpportunityCurrentViewConfigSelector";
import { getSavingsOpportunitiesViewsHierarchyThunk } from "../getSavingsOpportunitiesViewsHierarchyThunk";

export const protectSavingsOpportunityViewThunk = createAsyncThunk(
  "savingsOpportunities/protectSavingsOpportunityView",
  async (
    args: PatchUsersMeOrganisationsCurrentOpportunityViewsByViewIdApiArg,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const viewId = savingsOpportunitiesCurrentViewIdSelector(state);
    const currentConfigs = savingsOpportunityCurrentViewConfigSelector(state);

    const response = await dispatch(
      updateSavingsOpportunityViewThunk({
        viewId: args.viewId,
        body: {
          config:
            viewId === args.viewId && args?.body?.is_protected
              ? currentConfigs
              : undefined,
          is_protected: args?.body?.is_protected,
          protection_description: args?.body?.protection_description,
        },
      }),
    ).unwrap();

    if (response) {
      await dispatch(getSavingsOpportunitiesViewsHierarchyThunk());

      const snackbarId: SnackbarKey = enqueueSnackbar(
        `View successfully ${args?.body?.is_protected ? "protected and saved" : "unprotected"}.`,
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );
    }

    return response;
  },
);
