import { ColDef } from "ag-grid-community";

export const getHeaderNameByColId = (columnDefs: ColDef[]) => {
  const names: Record<string, string> = {};

  columnDefs.forEach((column) => {
    if (column.colId || column.field) {
      names[column.colId ?? column.field ?? ""] = column.headerName ?? "";
    }
  });

  return names;
};
