import { FC, memo } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import {
  DateDataPointNullable,
  DateLabelNullable,
} from "../../../../../../../../services/cloudchipr.api";
import { BillingExplorerWidgetContentCosts } from "../BillingExplorerWidgetContentCosts";
import { ChartType } from "../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { MultiTypeChartProvider } from "../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { BillingExplorerWidgetChart } from "../../../../adding-widget/widget-create/widget-setups/billing-explorer/components/chart-view/BillingExplorerWidgetChart";
import { billingExplorerSetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { billingExplorerWidgetChartDataSelector } from "../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetChartDataSelector";
import { billingExplorerWidgetCombinedChartDataSelector } from "../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetCombinedChartDataSelector";
import { billingExplorerWidgetVisualizationSelector } from "../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetVisualizationSelector";
import { billingExplorerWidgetHasAggregationSelector } from "../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetHasAggregationSelector";
import { billingExplorerWidgetTotalCostDateDetailsSelector } from "../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetTotalCostDateDetailsSelector";

interface BillingExplorerWidgetChartViewProps {
  widgetId: string;
  chartType: ChartType;
  dateLabel?: DateLabelNullable;
  dateDataPoint: DateDataPointNullable;
}

export const BillingExplorerWidgetChartView: FC<BillingExplorerWidgetChartViewProps> =
  memo(({ chartType, dateDataPoint, widgetId, dateLabel }) => {
    const chartData = useAppSelector((state) =>
      billingExplorerWidgetChartDataSelector(state, widgetId),
    );
    const combinedChartData = useAppSelector((state) =>
      billingExplorerWidgetCombinedChartDataSelector(state, widgetId),
    );
    const chartVisualization = useAppSelector((state) =>
      billingExplorerWidgetVisualizationSelector(state, widgetId),
    );
    const hasAggregations = useAppSelector((state) =>
      billingExplorerWidgetHasAggregationSelector(state, widgetId),
    );

    const dateDetails = useAppSelector((state) =>
      billingExplorerWidgetTotalCostDateDetailsSelector(state, widgetId),
    );
    const loading = useAppSelector(
      billingExplorerSetupPropertyByKeySelector("loading"),
    );
    if (!chartData) {
      return null;
    }

    return (
      <Stack
        px={2}
        py={1}
        gap={1}
        flex={1}
        justifyContent={hasAggregations ? "start" : "center"}
      >
        <MultiTypeChartProvider
          value={{
            initialChartType: chartType,
            data: chartData ?? [],
          }}
        >
          <BillingExplorerWidgetContentCosts
            widgetId={widgetId}
            dateLabel={dateLabel}
          />
          <BillingExplorerWidgetChart
            dateDataPoint={dateDataPoint}
            chartType={chartType}
            dateDetails={dateDetails}
            loading={loading}
            chartBase={chartVisualization?.chart_base_type ?? "cost"}
            combinedChartData={combinedChartData}
          />
        </MultiTypeChartProvider>
      </Stack>
    );
  });
