import { FC, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { ResourceExplorerGroupingNullable } from "../../../../../../../../services/cloudchipr.api";
import type { CustomHeaderProps } from "ag-grid-react";

export const getResourceDetailsCostsTableMainFieldInnerHeader =
  (groupBy: ResourceExplorerGroupingNullable): FC<CustomHeaderProps> =>
  ({ api }) => {
    const groupByLabel = useAppSelector((state) =>
      resourceExplorerGroupingLabelByGroupingSelector(state, groupBy),
    );

    const firstColumnHeaderName = groupByLabel?.singular ?? groupBy ?? "";

    const total = useMemo(() => {
      let sum = 0;

      api.forEachNode(() => {
        sum = sum + 1;
      });

      return sum;
    }, [api]);

    return (
      <Box width="100%">
        <TypographyWithTooltip
          title={firstColumnHeaderName}
          variant="body2"
          width="100%"
          fontWeight="medium"
        />

        <Typography
          fontSize={10}
          variant="caption"
          color="text.disabled"
          fontWeight="medium"
        >
          {total}
        </Typography>
      </Box>
    );
  };
