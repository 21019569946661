import { FC, Fragment, memo, useMemo } from "react";
import { FormikHandlers } from "formik";
import { useDidMount } from "rooks";
import { FilterItemOperatorType } from "../../../FilterItemOperatorType";
import {
  FilterItem,
  Operator,
  ResourceType,
  usePostUsersMeAccountsByTypeResourcesMutation,
} from "../../../../../../../../services/cloudchipr.api";
import {
  AutocompleteFilterBody,
  AutocompleteFilterBodyProps,
} from "../AutocompleteFilterBody";
import { DropdownSelectOption } from "../../../../../../../common/select/dropdown-select/utils/types/types";

interface SnapshotIdFilterProps extends AutocompleteFilterBodyProps {
  accountIds?: string[];
  resourceType: ResourceType;
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
}

export const SnapshotIdFilter: FC<SnapshotIdFilterProps> = memo(
  ({ resourceType, accountIds, operators, filter, onChange, ...props }) => {
    const [trigger, { data, isLoading }] =
      usePostUsersMeAccountsByTypeResourcesMutation();

    const providerUniqueIdentifiers = useMemo(() => {
      return data?.reduce((result, item) => {
        item.resources.forEach((resource) => {
          if (resource.provider_unique_identifier) {
            result.push({
              value: resource.provider_unique_identifier,
              label: resource.provider_unique_identifier,
            });
          }
        });

        return result;
      }, [] as DropdownSelectOption[]);
    }, [data]);

    useDidMount(() => {
      if (!accountIds) {
        return;
      }
      trigger({
        type: resourceType,
        body: { account_ids: accountIds },
      });
    });

    return (
      <Fragment>
        <FilterItemOperatorType
          value={filter.operator || "in"}
          options={operators}
          onChange={onChange}
        />
        <AutocompleteFilterBody
          {...props}
          options={providerUniqueIdentifiers ?? []}
          isLoading={isLoading}
          label="Snapshot ID"
          selectedValue={Array.isArray(filter?.value) ? filter.value : []}
        />
      </Fragment>
    );
  },
);
