import { FC, Fragment, memo } from "react";
import { FormikHandlers } from "formik";
import { TextField } from "@mui/material";
import * as yup from "yup";
import { FilterItemOperatorType } from "../FilterItemOperatorType";
import {
  FilterItem,
  Operator,
  Tag,
} from "../../../../../../services/cloudchipr.api";

export const LabelFilterValueValidation = yup.object().when("operator", {
  is: (operator: string) => !["exists", "does_not_exist"].includes(operator),
  then: yup.object().shape({
    key: yup.string().required("Key is required"),
    value: yup.string().required("Value is required"),
  }),
  otherwise: yup.object().shape({
    key: yup.string().required("Key is required"),
  }),
});
interface LabelFilterProps {
  operators?: Operator[];
  filter: FilterItem;
  error?: any;
  onChange: FormikHandlers["handleChange"];
}

export const LabelFilter: FC<LabelFilterProps> = memo(
  ({ operators, filter, onChange, error }) => {
    const filterValue = filter.value as Tag;

    return (
      <Fragment>
        <FilterItemOperatorType
          value={filter.operator || ""}
          options={operators}
          onChange={onChange}
        />
        <TextField
          fullWidth
          name="value.key"
          size="xsmall"
          label="Key"
          disabled={!filter.key || !filter.operator}
          onChange={onChange}
          error={!!(error?.value?.key || error?.value)}
          value={filterValue?.key || ""}
          sx={{ minWidth: 135, width: 135 }}
        />

        {!["exists", "does_not_exist"].includes(filter.operator) && (
          <TextField
            fullWidth
            name="value.value"
            size="xsmall"
            label="Value"
            disabled={!filter.key || !filter.operator}
            error={!!(error?.value?.value || error?.value)}
            onChange={onChange}
            value={filterValue?.value || ""}
            sx={{ minWidth: 135, width: 135 }}
          />
        )}
      </Fragment>
    );
  },
);
