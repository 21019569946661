import { FC, useCallback, useMemo, useState } from "react";
import {
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { DropdownSelectOption } from "../../../../../../../../common/select/dropdown-select/utils/types/types";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { FilterValueSelectionTrigger } from "../FilterValueSelectionTrigger";
import { FilterItemNodeWithId } from "../../../../../utils/types/common";
import { findAdditionalSelectedValues } from "../../../../../utils/helpers/findAdditionalSelectedValues";

interface FilterValueAutocompleteWithAsyncSearchProps {
  filterLabel: string;
  filterType: ResourceExplorerDynamicFilterItemType;
  filterValue: string[];
  provider: ResourceExplorerFilterProvider;
  onChange(value: FilterItemNodeWithId["value"]): void;
}

export const FilterValueAutocompleteWithAsyncSearch: FC<
  FilterValueAutocompleteWithAsyncSearchProps
> = ({ provider, filterType, onChange, filterValue, filterLabel }) => {
  const [searchValue, setSearchValue] = useState("");

  const { data, isLoading, isFetching } = useValuesQuery({
    filterProvider: provider,
    filterType: filterType,
    key: searchValue.trim(),
  });

  const additionalValues = useMemo(() => {
    return findAdditionalSelectedValues(filterValue, data);
  }, [filterValue, data]);

  const renderOptions = useMemo(() => {
    const options = additionalValues
      ? [...(data ?? []), ...additionalValues]
      : data;

    return options?.map((option) => ({
      rawValue: option,
      value: option.value,
      label: (
        <TypographyWithTooltip
          title={option.title ?? option.value}
          fontSize="inherit"
        />
      ),
    }));
  }, [data, additionalValues]);

  const searchChangeHandler = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const changeHandler = useCallback(
    (value: FilterItemNodeWithId["value"]) => {
      setSearchValue("");
      onChange(value);
    },
    [onChange],
  );

  return (
    <DropdownSelect
      label={`Select ${filterLabel}`}
      options={renderOptions ?? []}
      wrapperVariant="popover"
      submitHandlerOnClose={changeHandler}
      filterFn={filterFn}
      disabled={isLoading || isFetching}
      values={filterValue ?? []}
      optionsLoading={isLoading || isFetching}
      TriggerComponent={FilterValueSelectionTrigger}
      PopoverProps={{ extendAnchorWidth: true }}
      // todo: Use `DropDownHeader` props to implement local search, remove this new prop
      onSearchChange={searchChangeHandler}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  const aim = `${option.rawValue.value} ${option.rawValue.title}`.toLowerCase();

  return aim.includes(keyword.toLowerCase().trim());
};
