import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { azureOverviewEffectiveSavingRateSelector } from "../../../../../../../store/commitments/selectors/azure/overview/azureOverviewEffectiveSavingRateSelector";
import { azureOverviewPreviousDateDetailsSelector } from "../../../../../../../store/commitments/selectors/azure/overview/azureOverviewPreviousDateDetailsSelector";
import { azureOverviewLoadingSelector } from "../../../../../../../store/commitments/selectors/azure/overview/loading/azureOverviewLoadingSelector";
import { CommitmentsOverviewEffectiveSavingsRate } from "../../../../overview/metadata/components/common/CommitmentsOverviewEffectiveSavingsRate";

export const AzureOverviewEffectiveSavingsRate: FC = () => {
  const effectiveSavingRate = useAppSelector(
    azureOverviewEffectiveSavingRateSelector,
  );

  const prevDateDetails = useAppSelector(
    azureOverviewPreviousDateDetailsSelector,
  );
  const loading = useAppSelector(azureOverviewLoadingSelector);

  return (
    <CommitmentsOverviewEffectiveSavingsRate
      effectiveSavingRate={effectiveSavingRate}
      loading={loading}
      prevDateDetails={prevDateDetails}
    />
  );
};
