import { taskManagementTargetOpportunitiesTitlesSelector } from "../opportunities/taskManagementTargetOpportunitiesTitlesSelector";
import { taskManagementTargetResourcesTitlesSelector } from "../resources/taskManagementTargetResourcesTitlesSelector";
import { RootState } from "../../../../../store";

export const taskManagementTargetTitlesSelector = (
  state: RootState,
): string[] | undefined => {
  const opportunitiesTitles =
    taskManagementTargetOpportunitiesTitlesSelector(state) ?? [];
  const resourcesTitles =
    taskManagementTargetResourcesTitlesSelector(state) ?? [];

  return [...opportunitiesTitles, ...resourcesTitles];
};
