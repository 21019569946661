import { FC } from "react";
import { CreateSlackMessageDialogV2 } from "./CreateSlackMessageDialogV2";
import {
  ProviderType,
  ResourceType,
} from "../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../store/hooks";
import { slackMessageSelector } from "../../../../../store/integrations/selectors/slack/slackMessageSelector";
import { CreateIntegrationResourcesTableV2 } from "../common/CreateIntegrationResourcesTableV2";
import { liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector } from "../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector";
import { kubernetesSlackMessageSelector } from "../../../../../store/integrations/selectors/kubernetes/slack/kubernetesSlackMessageSelector";
import { kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector } from "../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector";
import { KubernetesCreateSlackResourcesTable } from "../kubernetes/slack/KubernetesCreateSlackResourcesTable";

interface CreateSlackMessageDialogWrapperProps {
  resourceType: ResourceType;
  onClose(): void;
  provider?: ProviderType | null;
}

export const CreateSlackMessageDialogWrapper: FC<
  CreateSlackMessageDialogWrapperProps
> = ({ resourceType, onClose, provider }) => {
  const liveUsageMgmtMessage = useAppSelector((state) =>
    slackMessageSelector(state, resourceType),
  );
  const kubernetesLiveUsageMgmtMessage = useAppSelector((state) =>
    kubernetesSlackMessageSelector(state, resourceType),
  );
  const liveUsageMgmtCsvData = useAppSelector((state) =>
    liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector(
      state,
      resourceType,
    ),
  );
  const kubernetesLiveUsageMgmtCsvData = useAppSelector((state) =>
    kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector(
      state,
      resourceType,
    ),
  );
  if (provider === "kubernetes") {
    return (
      <CreateSlackMessageDialogV2
        onClose={onClose}
        resourceType={resourceType}
        message={kubernetesLiveUsageMgmtMessage}
        csvData={kubernetesLiveUsageMgmtCsvData}
        table={KubernetesCreateSlackResourcesTable}
      />
    );
  }
  return (
    <CreateSlackMessageDialogV2
      onClose={onClose}
      resourceType={resourceType}
      message={liveUsageMgmtMessage}
      table={CreateIntegrationResourcesTableV2}
      csvData={liveUsageMgmtCsvData}
    />
  );
};
