import { ValueGetterParams } from "ag-grid-community";
import { capitalize } from "@mui/material";
import { Budget } from "../../../../../../../../../services/cloudchipr.api";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../../store/alerts/utils/types/types";

export const budgetAndAlertsCreatedByFilterValueGetter = <
  T extends
    | Budget
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>({
  data,
}: ValueGetterParams<T>) => {
  const createdBy = data?.created_by?.name ?? data?.created_by?.email;

  if (!createdBy) {
    return;
  }

  return createdBy
    .split(" ")
    .map((name) => capitalize(name))
    .join(" ");
};
