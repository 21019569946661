import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import ListItem from "@tiptap/extension-list-item";
import StarterKit from "@tiptap/starter-kit";
import { TextAlign } from "@tiptap/extension-text-align";
import { Heading } from "@tiptap/extension-heading";
import Paragraph from "@tiptap/extension-paragraph";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import { Underline } from "@tiptap/extension-underline";
import { getLinkExtension } from "./getLinkExtension";

export const getDefaultExtensions = () => [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  // @ts-expect-error | unknown issue, doesn't work without this
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: { keepMarks: true, keepAttributes: false },
    orderedList: { keepMarks: true, keepAttributes: false },
  }),
  TextAlign.configure({
    types: [Heading.name, Paragraph.name],
  }),
  Document,
  Paragraph.configure({
    HTMLAttributes: {
      style: "margin-bottom: 0px; margin-top: 4px;",
    },
  }),
  Text,
  Underline,
  Heading,
  getLinkExtension(),
];
