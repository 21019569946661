import { ColDef } from "ag-grid-community";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../../store/alerts/utils/types/types";

export const alertsNotificationMethodComparator: ColDef<
  CostAnomalyAlertsDataGridDataType | UtilizationAlertDataGridDataType
>["comparator"] = (_, __, nodeA, nodeB) => {
  const currentIntegrationName = nodeA?.data?.integrations?.at(0)?.name;
  const currentEmail = nodeA?.data?.emails?.to?.at(0);
  const current = (currentIntegrationName ?? currentEmail ?? "").toLowerCase();

  const nextIntegrationName = nodeB?.data?.integrations?.at(0)?.name ?? "";
  const nextEmail = nodeB?.data?.emails?.to?.at(0);
  const next = (nextIntegrationName ?? nextEmail ?? "").toLowerCase();

  return current > next ? 1 : -1;
};
