import { FC } from "react";
import { Stack } from "@mui/material";
import { WidgetCardHeaderActionRow } from "../../common/widget-header/WidgetCardHeaderActionRow";
import { useAppSelector } from "../../../../../../../store/hooks";
import { textWidgetByIdSelector } from "../../../../../../../store/dashboards/selectors/widgets/text-widget/widget-view/textWidgetByIdSelector";
import { WidgetTitleProps } from "../../../../utils/types/types";

interface TextWidgetCardHeaderProps {
  widgetId: string;
  hovered: boolean;
}

export const TextWidgetCardHeader: FC<TextWidgetCardHeaderProps> = ({
  widgetId,
  hovered,
}) => {
  const widget = useAppSelector((state) =>
    textWidgetByIdSelector(state, widgetId),
  );

  const widgetTitleProps: WidgetTitleProps = {
    widgetName: widget?.name ?? "",
    dates: {},
  };

  return (
    <Stack
      pl={2}
      pr={0}
      py={1}
      position="sticky"
      top={0}
      bgcolor="white"
      zIndex={2}
      borderColor="grey.300"
    >
      <WidgetCardHeaderActionRow
        widgetType="text"
        hovered={hovered}
        widgetId={widgetId}
        widgetTitleProps={widgetTitleProps}
      />
    </Stack>
  );
};
