import { FC, useState } from "react";
import { Box, Card } from "@mui/material";
import { UtilizationAndCoverageGaugeChart } from "./chart/UtilizationAndCoverageGaugeChart";
import { UtilizationAndCoverageData } from "./data-grid/UtilizationAndCoverageData";
import { UtilizationAndCoverageMultiTypeChart } from "./chart/multi-type-chart/UtilizationAndCoverageMultiTypeChart";
import { UtilizationAndCoverageMultiTypeChartMetadata } from "./chart/multi-type-chart/UtilizationAndCoverageMultiTypeChartMetadata";
import { PossibleVisibilities } from "../../../../common/ScreenSplitButtons";
import { CommitmentsScreenSplitButtons } from "../../../common/components/CommitmentsScreenSplitButtons";
import { useAppSelector } from "../../../../../../store/hooks";
import { commitmentsChartTypeSelector } from "../../../../../../store/commitments/selectors/common/view-options/commitmentsChartTypeSelector";

export const UtilizationAndCoverageContent: FC = () => {
  const [visibleContent, setVisibleContent] =
    useState<PossibleVisibilities>("chart_grid");
  const chartType = useAppSelector(commitmentsChartTypeSelector);

  return (
    <Card
      sx={{ p: 2, display: "flex", flex: 1, flexDirection: "column" }}
      variant="outlined"
    >
      {(chartType !== "gauge" || visibleContent === "grid") && (
        <UtilizationAndCoverageMultiTypeChartMetadata />
      )}

      {visibleContent !== "grid" && (
        <Box position="relative" zIndex={2} mb={2}>
          <UtilizationAndCoverageMultiTypeChart />
          <UtilizationAndCoverageGaugeChart />
        </Box>
      )}

      <CommitmentsScreenSplitButtons
        provider="aws"
        visibleContent={visibleContent}
        setVisibleContent={setVisibleContent}
      />

      {visibleContent !== "chart" && (
        <Box mt={2} display="flex" flex={1}>
          <UtilizationAndCoverageData />
        </Box>
      )}
    </Card>
  );
};
