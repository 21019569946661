import { Fragment } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { Row } from "@tanstack/react-table";
import { RowSelectionOptions } from "@tanstack/table-core";
import { Tune } from "@mui/icons-material";
import { KubernetesLiveUsageMgmtResourceCardFilterContent } from "./KubernetesLiveUsageMgmtResourceCardFilterContent";
import { KubernetesLiveUsageMgmtCsvDataDownloader } from "./KubernetesLiveUsageMgmtCsvDataDownloader";
import { KubernetesLiveUsageMgmtToolbarRowActions } from "./KubernetesLiveUsageMgmtToolbarRowActions";
import { KubernetesLiveUsageMgmtResourceCardToolbarHeader } from "./KubernetesLiveUsageMgmtResourceCardToolbarHeader";
import { RenderToolbar } from "../../../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { WithResourceType } from "../../../../../../../../live-usage-mgmt/utils/types";
import { useMenuHook } from "../../../../../../../../../../utils/hooks/useMenu.hook";
import { useDataGridContext } from "../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { generateMetricsFrequenciesFromContextData } from "../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateMetricsFrequenciesFromContextData";
import { KubernetesLiveUsageMgmtResourceCardStatistics } from "../../../resource-card-statistics/KubernetesLiveUsageMgmtResourceCardStatistics";
import { GlobalFilter } from "../../../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { ColumnsSwitcher } from "../../../../../../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";

interface KubernetesLiveUsageMgmtResourceCardToolbarProps
  extends RenderToolbar<WithResourceType> {
  rowSelectingConfigFns?: RowSelectionOptions<any> & {
    getSelectedRowsData: (data: Row<any>[]) => void;
  };
}

export const KubernetesLiveUsageMgmtResourceCardToolbar: KubernetesLiveUsageMgmtResourceCardToolbarProps =
  ({ table, setGlobalFilter, globalFilter, resourceType }) => {
    const { anchor, openMenu, closeMenu, open } = useMenuHook();
    const context = useDataGridContext();
    const data = context?.cellSpecificMetadata?.data;
    const metricsFrequencies = generateMetricsFrequenciesFromContextData(data);

    return (
      <Fragment>
        <Stack p={1} spacing={1}>
          <KubernetesLiveUsageMgmtResourceCardToolbarHeader
            resourceType={resourceType}
          />
          <KubernetesLiveUsageMgmtResourceCardStatistics
            resourceType={resourceType}
          />
        </Stack>
        <Stack
          px={2}
          pb={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={0.5}>
            <KubernetesLiveUsageMgmtResourceCardFilterContent
              resourceType={resourceType}
            />
          </Stack>

          <Stack alignItems="center" direction="row" spacing={1}>
            <KubernetesLiveUsageMgmtToolbarRowActions
              resourceType={resourceType}
            />

            <GlobalFilter
              key={globalFilter}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="xsmall"
            />
            <KubernetesLiveUsageMgmtCsvDataDownloader
              resourceType={resourceType}
              metricsFrequencies={metricsFrequencies}
            />
            <Tooltip arrow title="Table Settings" placement="top">
              <IconButton onClick={openMenu} size="small">
                <Tune fontSize="small" />
              </IconButton>
            </Tooltip>
            <ColumnsSwitcher
              open={open}
              onClose={closeMenu}
              anchor={anchor}
              table={table}
            />
          </Stack>
        </Stack>
      </Fragment>
    );
  };
