import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { billingSummaryWidgetSetupSelector } from "../../../../selectors/setups/billing-summary/billingSummaryWidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { getBillingSummaryWidgetDataBySetupThunk } from "../data-get/getBillingSummaryWidgetDataBySetupThunk";
import { billingSummaryDefaultFilters } from "../../../../utils/constants/billingSummarySetupDefaultData";
import { getResourceExplorerViewByIdThunk } from "../../../../../common/thunks/resource-explorer/getResourceExplorerViewByIdThunk";
import { BillingSummarySetupType } from "../../../../utils/types/setups/billingSummarySetupType";

export const billingSummaryREViewChangeThunk = createAsyncThunk(
  "dashboards/billingSummaryREViewChange",
  async (viewId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = billingSummaryWidgetSetupSelector(state);

    if (!setup) {
      return;
    }
    dispatch(setWidgetSetup({ ...setup, viewId }));

    const response = await dispatch(
      getResourceExplorerViewByIdThunk({ viewId }),
    ).unwrap();

    if (!response) {
      return;
    }

    const { data } = response;

    const to = data?.to_date;
    const from = data?.from_date;
    const label = data?.date_label;

    dispatch(
      setWidgetSetup({
        ...setup,
        filter: billingSummaryDefaultFilters,
        viewId: viewId,
        grouping: data.grouping === "resource" ? "service" : data.grouping,
        date: {
          label,
          to,
          from,
        },
      } as BillingSummarySetupType),
    );

    await dispatch(getBillingSummaryWidgetDataBySetupThunk());
  },
);
