import { FC, useCallback } from "react";
import { Drawer } from "@mui/material";
import { SavingsPlanDrawerContent } from "./savings-plan-drawer/SavingsPlanDrawerContent";
import { SavingsPlanCoverageDrawerContent } from "./coverage-drawer/SavingsPlanCoverageDrawerContent";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setCommitmentsDetailsDrawerTarget } from "../../../../../../store/commitments/commitmentsSlice";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { commitmentsDetailsDrawerOpenSelector } from "../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerOpenSelector";

export const UtilizationAndCoverageDrawers: FC = () => {
  const dispatch = useAppDispatch();
  const targetType = useAppSelector(commitmentsDetailsDrawerTargetTypeSelector);
  const open = useAppSelector(commitmentsDetailsDrawerOpenSelector);

  const closeHandler = useCallback(() => {
    dispatch(setCommitmentsDetailsDrawerTarget(undefined));
  }, [dispatch]);

  return (
    <Drawer open={open} anchor="right" onClose={closeHandler}>
      {targetType === "commitment" && (
        <SavingsPlanDrawerContent onClose={closeHandler} />
      )}

      {targetType === "account" && (
        <SavingsPlanCoverageDrawerContent onClose={closeHandler} />
      )}

      {targetType === "resource_type" && (
        <SavingsPlanCoverageDrawerContent onClose={closeHandler} />
      )}
    </Drawer>
  );
};
