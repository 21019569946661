import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";

import { getAzureCoveredUsageColumnDef } from "./common/getAzureCoveredUsageColumnDef";
import { azureNetSavingsColumnDef } from "./common/azureNetSavingsColumnDef";
import { azureCoveredInCommitmentColumnDef } from "./common/azureCoveredInCommitmentColumnDef";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import {
  AzureReservationsCoverageDataWithId,
  AzureSavingsPlanCoverageDataWithId,
} from "../../../../../../../../common/utils/types";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const azureReservationCoverageGroupedByCommitmentColumnDefs: ColDef<AzureReservationsCoverageDataWithId>[] =
  [
    {
      flex: 1.5,
      field: "commitment.provider_id",
      headerName: "Reservation ID",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => (
        <TypographyWithTooltip
          variant="body2"
          title={data?.commitment?.provider_id ?? ""}
        />
      ),
    },
    getAzureCoveredUsageColumnDef("region"),
    azureNetSavingsColumnDef,
    azureCoveredInCommitmentColumnDef,
    agGridEmptyColumnToFitEmptySpace,

    // getAzureCoverageColumn("region"), // todo: Add this column after the backend fix
  ];
