import { GetQuickFilterTextParams } from "ag-grid-community";
import { formatDate } from "../../../../../../../../../utils/helpers/date-time/datetime-format";
import { CommunicationIntegrationsGridRowType } from "../../../utils/types/common";

export const integrationsCreatedAtQuickFilter = (
  params: GetQuickFilterTextParams<CommunicationIntegrationsGridRowType>,
) => {
  const integration = params.data;
  const date = integration?.created_at;

  if (!date) {
    return "-";
  }

  return `${formatDate(date, { type: "dateTimeWithComma" })} (UTC)`.toLowerCase();
};
