import { FC } from "react";
import { FormikErrors, FormikHandlers, FormikTouched } from "formik";
import { Stack } from "@mui/material";
import { CommunicationIntegrationFormHeaderField } from "./CommunicationIntegrationFormHeaderField";
import { Integration } from "../../../../../../../../../services/cloudchipr.api";
import { EmailForm } from "../../../../email/components/form/EmailForm";
import { SlackForm } from "../../../../slack/components/form/SlackForm";
import { WebhookForm } from "../../../../webhook/components/form/WebhookForm";
import { JiraForm } from "../../../../jira/components/form/JiraForm";

interface IntegrationFormProps {
  id: string;
  values: Integration;
  errors: FormikErrors<Integration>;
  touched: FormikTouched<Integration>;
  handleChange: FormikHandlers["handleChange"];
  setFieldValue(key: string, value: any): void;
  workspaceId?: string | null;
}

export const CommunicationIntegrationForm: FC<IntegrationFormProps> = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  id,
  workspaceId,
}) => {
  return (
    <form>
      <Stack spacing={2} flex={1}>
        {values.type && (
          <CommunicationIntegrationFormHeaderField
            type={values.type}
            value={values.name}
            handleChange={handleChange}
            error={touched.name ? errors.name : ""}
          />
        )}

        {values.type === "slack" && (
          <SlackForm
            id={id}
            workspaceId={workspaceId}
            type={values.type}
            name={values.name}
            conversations={values.conversations}
            defaultConversation={values.conversation}
            setFieldValue={setFieldValue}
          />
        )}

        {values.type === "email" && (
          <EmailForm
            emails={values.emails || []}
            setFieldValue={setFieldValue}
            error={touched.emails && errors.emails}
          />
        )}

        {values.type === "webhook" && (
          <WebhookForm
            url={values.url || ""}
            handleChange={handleChange}
            urlError={touched.url && errors.url}
            contentType={values.content_type || ""}
            contentTypeError={touched.content_type && errors.content_type}
          />
        )}

        {values.type === "jira" && (
          <JiraForm
            integrationId={values.id}
            url={values.url}
            api_token={values.api_token}
            jira_email={values.jira_email}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            default_project_key={values.default_project_key}
            default_issue_type_id={values.default_issue_type_id}
            projectError={
              touched.default_project_key && errors.default_project_key
            }
            issueTypeError={
              touched.default_issue_type_id && errors.default_issue_type_id
            }
          />
        )}
      </Stack>
    </form>
  );
};
