import { resourceDetailsResourceFirstProcessKeySelector } from "./resourceDetailsResourceFirstProcessKeySelector";
import { resourceDetailsResourceBillingDataSelector } from "../resourceDetailsResourceBillingDataSelector";
import { RootState } from "../../../../store";
import { resourceDetailsCostsDataPointSelector } from "../../options/resourceDetailsCostsDataPointSelector";
import { WidgetAverageData } from "../../../../resource-explorer/utils/types/resourceExplorer";
import { getResourceExplorerWidgetAverageData } from "../../../../resource-explorer/utils/helpers/getResourceExplorerWidgetAverageData";

export const resourceDetailsResourceBillingDataAverageData = (
  state: RootState,
): WidgetAverageData | undefined => {
  const firstProcessKey = resourceDetailsResourceFirstProcessKeySelector(state);

  const data = resourceDetailsResourceBillingDataSelector(
    state,
    firstProcessKey,
  );

  const dataPoint = resourceDetailsCostsDataPointSelector(state);

  return getResourceExplorerWidgetAverageData(data, dataPoint);
};
