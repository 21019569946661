import { FC } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { useAppAbility } from "../../../services/permissions";
import { initialAccountsLoadingSelector } from "../../../store/accounts/selectors/loadings/initialAccountsLoadingSelector";
import { useUserRoute } from "../../utils/hooks/useUserRoute.hook";
import { generateLiveResourcesPath } from "../accounts-group/utils/helpers/generateLiveResourcesPath";
import { biggestOrgSelector } from "../../../store/accounts/selectors/all-providers/biggestOrgSelector";

export const RedirectPage: FC = () => {
  const { cannot } = useAppAbility();
  const cannotView = cannot("view", "account");
  const userLastVisitedPage = useUserRoute(false);
  const [searchParams] = useSearchParams();
  const toHome = searchParams.get("to") === "home";

  const accountsLoading = useAppSelector(initialAccountsLoadingSelector);

  const biggestOrg = useAppSelector(biggestOrgSelector);

  if (cannotView) {
    return <Navigate to="/access-denied" replace />;
  }

  if (accountsLoading) {
    return null;
  }

  // todo: Remove the toHome query param after adding a new home page
  if (userLastVisitedPage && !toHome && biggestOrg) {
    return <Navigate to={userLastVisitedPage} />;
  }

  if (biggestOrg) {
    return (
      <Navigate
        to={generateLiveResourcesPath({
          provider: biggestOrg.provider,
          orgId: biggestOrg.orgId,
        })}
      />
    );
  }

  return <Navigate to="/account/setup" />;
};
