import { azureReservationsDataForTableSelector } from "./azureReservationsDataForTableSelector";
import { RootState } from "../../../../../store";
import { AzureReservation } from "../../../../../../services/cloudchipr.api";

export const azureReservationsDataSelector = (
  state: RootState,
): AzureReservation[] | undefined => {
  const data = azureReservationsDataForTableSelector(state);

  return data?.utilization_and_coverage.commitments as AzureReservation[];
};
