import { billingExplorerWidgetSetupSelector } from "./billingExplorerWidgetSetupSelector";
import { billingExplorerWidgetSetupDataPayloadSelector } from "./billingExplorerWidgetSetupDataPayloadSelector";
import { RootState } from "../../../../../store";

export const billingExplorerWidgetSetupDataSelector = (state: RootState) => {
  const payload = billingExplorerWidgetSetupDataPayloadSelector(state);
  if (!payload) {
    return;
  }
  const response = billingExplorerWidgetSetupSelector(payload)(state);

  return response?.data;
};
