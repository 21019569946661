import { FC, useCallback, useMemo } from "react";
import { useCurrentEditor } from "@tiptap/react";
import { MenuItem, Stack, Typography } from "@mui/material";
import { MenuItemProps } from "@mui/material/MenuItem/MenuItem";
import { HeadingLevels } from "../../../utils/types/types";

interface ToolbarHeadingCommandButtonProps extends MenuItemProps {
  level: HeadingLevels;
  disableWhenActive?: boolean;
}

export const ToolbarHeadingCommandMenuItem: FC<
  ToolbarHeadingCommandButtonProps
> = ({ level, disableWhenActive, ...props }) => {
  const { editor } = useCurrentEditor();

  const clickHandler = useCallback(
    (event: any) => {
      if (!editor) {
        return;
      }

      if (props.onClick) {
        props.onClick(event);
      }

      editor.chain().focus().toggleHeading({ level }).run();
    },
    [editor, level, props],
  );

  const disabled = useMemo(() => {
    if (!disableWhenActive || !editor) {
      return false;
    }

    return !editor.can().chain().focus().toggleHeading({ level }).run();
  }, [editor, disableWhenActive, level]);

  if (!editor) {
    return null;
  }

  const isActive = editor.isActive("heading", { level });

  return (
    <MenuItem
      {...props}
      value={level.toString()}
      onClick={clickHandler}
      disabled={disabled}
      selected={isActive}
    >
      <Stack
        flex={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={0.5}
      >
        <Typography textAlign="center" color="grey.600" fontWeight="bold">
          H{level}{" "}
        </Typography>
        <Typography pl={1} variant="body2" fontWeight="nomral">
          Heading {level}
        </Typography>
      </Stack>
    </MenuItem>
  );
};
