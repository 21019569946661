import { accountsViewLoadingSelector } from "./accountsViewLoadingSelector";
import { accountsSortByLoadingSelector } from "./accountsSortByLoadingSelector";
import { accountsOrderingLoadingSelector } from "./accountsOrderingLoadingSelector";
import { RootState } from "../../../store";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { AccountsPageTabs } from "../../../../components/pages/accounts-group/utils/types/types";

export const accountsNeededInfoLoadingSelector = (
  state: RootState,
  provider: ProviderType,
  tabValue: AccountsPageTabs,
  orgId?: string,
) => {
  const sortByLoading = accountsSortByLoadingSelector(
    state,
    provider,
    tabValue,
  );
  const orderingLoading = accountsOrderingLoadingSelector(
    state,
    provider,
    tabValue,
    orgId,
  );
  const viewLoading = accountsViewLoadingSelector(
    state,
    provider,
    tabValue,
    orgId,
  );

  return sortByLoading || orderingLoading || viewLoading;
};
