import { getBillingExplorerSummaryFixedCashKeySelector } from "./getBillingExplorerSummaryFixedCashKeySelector";
import { getBillingExplorerSummarySelector } from "./api-select/getBillingExplorerSummarySelector";
import { RootState } from "../../../store";

export const billingExplorerSummaryDataByCurrentPayloadSelector = (
  state: RootState,
) => {
  const fixedCacheKey = getBillingExplorerSummaryFixedCashKeySelector(state);

  return getBillingExplorerSummarySelector(fixedCacheKey ?? "")(state)?.data
    ?.summary;
};
