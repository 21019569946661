import { FC, Fragment, useCallback } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { HierarchyViewRenameActionDialog } from "./HierarchyViewRenameActionDialog";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";

interface HierarchyViewRenameActionProps {
  viewId: string;
  viewName: string;
  onClose(): void;
  isProtected: boolean;
}

export const HierarchyViewRenameAction: FC<HierarchyViewRenameActionProps> = ({
  onClose,
  viewId,
  viewName,
  isProtected,
}) => {
  const { open, openDialog, closeDialog } = useDialog();

  const closeHandler = useCallback(() => {
    closeDialog();
    onClose();
  }, [onClose, closeDialog]);

  return (
    <Fragment>
      <Tooltip
        arrow
        placement="right"
        title={isProtected ? "Protected views cannot be modified." : ""}
      >
        <span>
          <MenuItem onClick={openDialog} disabled={isProtected}>
            <ListItemIcon>
              <EditOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">Rename</Typography>
            </ListItemText>
          </MenuItem>
        </span>
      </Tooltip>

      <HierarchyViewRenameActionDialog
        open={open}
        viewId={viewId}
        viewName={viewName}
        onClose={closeHandler}
      />
    </Fragment>
  );
};
