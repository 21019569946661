import { RootState } from "../../../../../../store";
import { azureReservationDetailsDataSelector } from "../data/azureReservationDetailsDataSelector";
import {
  AzureCommitmentCoverageMetadata,
  CommitmentCoverageMetadata,
} from "../../../../../../../services/cloudchipr.api";

export const azureReservationCoverageDetailsMetaDataSelector = (
  state: RootState,
): CommitmentCoverageMetadata | undefined => {
  return azureReservationDetailsDataSelector(state)
    ?.metadata as AzureCommitmentCoverageMetadata;
};
