import { createAsyncThunk } from "@reduxjs/toolkit";
import { setResourceExplorerPathThunk } from "./setResourceExplorerPathThunk";
import { getResourceExplorerDataThunk } from "../getResourceExplorerDataThunk";
import { setResourceExplorerGrouping } from "../../../resourceExplorerSlice";
import { ResourceExplorerPath } from "../../../utils/types/resourceExplorer";
import { RootState } from "../../../../store";
import {
  FilterTreeGenerationForPathArgs,
  generateFilterTreeForPath,
} from "../../../utils/helpers/generateFilterTreeForPath";
import { resourceExplorerGroupValuesSelector } from "../../../selectors/current-resource-explorer/data/resourceExplorerGroupValuesSelector";
import { resourceExplorerGroupingPossibleGroupingsSelector } from "../../../selectors/possible-groupings/resourceExplorerGroupingPossibleGroupingsSelector";
import { resourceExplorerGroupingNextGroupingByProviderSelector } from "../../../selectors/possible-groupings/resourceExplorerGroupingNextGroupingByProviderSelector";
import { resourceExplorerGroupingFilterDataMapSelector } from "../../../selectors/possible-groupings/resourceExplorerGroupingFilterDataMapSelector";

export const changePathOnRowSelectThunk = createAsyncThunk(
  "resourceExplorer/changePathOnRowSelectThunk",
  (args: FilterTreeGenerationForPathArgs, { dispatch, getState }) => {
    const {
      pathName,
      currentPath,
      currentGroupBy,
      provider,
      nextGroupingDependingOnRowValue,
    } = args;

    const state = getState() as RootState;

    if (!currentGroupBy) {
      return currentPath;
    }

    const groupValues = resourceExplorerGroupValuesSelector(state);
    const groupingFilterData =
      resourceExplorerGroupingFilterDataMapSelector(state);
    const dimensionId = groupValues?.at(0);

    const filterTree = generateFilterTreeForPath(
      {
        ...args,
        filterType: groupingFilterData?.[currentGroupBy].type ?? "array",
        filterKey: groupingFilterData?.[currentGroupBy].key ?? "account",
      },
      dimensionId,
    );

    const nextGroupingByProvider =
      resourceExplorerGroupingNextGroupingByProviderSelector(
        state,
        currentGroupBy,
        provider,
      );

    const possibleGroupings =
      resourceExplorerGroupingPossibleGroupingsSelector(state);

    const nextGrouping =
      nextGroupingDependingOnRowValue ?? nextGroupingByProvider ?? "service";

    if (!nextGrouping) {
      return currentPath;
    }

    const pathItem: ResourceExplorerPath = {
      filterTree,
      name: pathName,
      groupedBy: nextGrouping,
      groupingOptions: possibleGroupings?.[nextGrouping] ?? [],
    };

    dispatch(setResourceExplorerPathThunk([...currentPath, pathItem]));
    dispatch(setResourceExplorerGrouping({ grouping: nextGrouping }));
    dispatch(getResourceExplorerDataThunk(filterTree));
  },
);
