import { FC, Fragment, useCallback, useEffect } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { BillingExplorerWidgetSetupContent } from "./content/BillingExplorerWidgetSetupContent";
import { BillingExplorerWidgetSetupSidebar } from "./side-bar/BillingExplorerWidgetSetupSidebar";
import { setWidgetsConfigurationsFeatureToggleFromStorage } from "../utils/helpers/configurations-feature-toggle/setWidgetsConfigurationsFeatureToggleFromStorage";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import { billingExplorerSetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";
import { billingExplorerSubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSubmitButtonDisabledSelector";
import { buildBillingExplorerWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/actions/buildBillingExplorerWidgetThunk";

interface BillingExplorerWidgetSetupProps {
  onClose(): void;
  onSubmit(): void;
}

export const BillingExplorerWidgetSetup: FC<
  BillingExplorerWidgetSetupProps
> = ({ onClose, onSubmit }) => {
  const dispatch = useAppDispatch();
  const enableWidgetsSideBarConfigurations = useFlag(
    "EnableWidgetsSideBarConfigurations",
  );

  const viewId = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("viewId"),
  );

  const viewType = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("chart_type"),
  );

  const setupId = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("id"),
  );
  const disabled = useAppSelector(billingExplorerSubmitButtonDisabledSelector);

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(
      buildBillingExplorerWidgetThunk(),
    ).unwrap();

    onSubmit();
    if (responseId && !setupId) {
      scrollPageToBottom();
    }
  }, [dispatch, setupId, onSubmit]);

  useEffect(() => {
    setWidgetsConfigurationsFeatureToggleFromStorage(
      enableWidgetsSideBarConfigurations,
    );
  }, [enableWidgetsSideBarConfigurations]);

  if (!viewType) {
    return null;
  }

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />

      <WidgetSetupLayout
        empty={!viewId ? "Select Billing Report to get started." : ""}
        appBar={<BillingExplorerWidgetSetupSidebar />}
      >
        <BillingExplorerWidgetSetupContent />
      </WidgetSetupLayout>
    </Fragment>
  );
};
