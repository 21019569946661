import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Stack, Typography } from "@mui/material";
import { getSavingsPlanCommonColumnDefs } from "./getSavingsPlanCommonColumnDefs";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../common/utils/types";
import { netSavingsColumnDef } from "../coverage/common/netSavingsColumnDef";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const savingsPlanGroupedByAccountColumnDefs: ColDef<SavingsPlanCoverageDataWithId>[] =
  [
    {
      flex: 1.5,
      field: "account.name",
      headerName: "Account",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => {
        return (
          <Stack>
            <Typography variant="body2">{data?.account?.name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {data?.account?.provider_account_id}
            </Typography>
          </Stack>
        );
      },
      valueGetter: ({ data }) =>
        `${data?.account?.name} ${data?.account?.provider_account_id}`,
    },
    netSavingsColumnDef,
    ...getSavingsPlanCommonColumnDefs("account"),
    agGridEmptyColumnToFitEmptySpace,
  ];
