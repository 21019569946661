import { billingSummarySumSetupPropertyByKeySelector } from "./billingSummarySumSetupPropertyByKeySelector";
import { RootState } from "../../../../store";
import { billingSummaryWidgetEditChangesExistSelector } from "../../widgets/billing-summary/billingSummaryWidgetEditChangesExistSelector";

export const billingSummarySubmitButtonDisabledSelector = (
  state: RootState,
): boolean => {
  const editDisabledByChange =
    billingSummaryWidgetEditChangesExistSelector(state);
  const filter = billingSummarySumSetupPropertyByKeySelector("filter")(state);
  const viewId = billingSummarySumSetupPropertyByKeySelector("viewId")(state);
  const name = billingSummarySumSetupPropertyByKeySelector("name")(state);
  const id = billingSummarySumSetupPropertyByKeySelector("id")(state);

  return !filter?.length || (id && !editDisabledByChange) || !name || !viewId;
};
