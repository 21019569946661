import { FC, useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAppAbility } from "../../../../../../../services/permissions";
import { toggleAlertsOpenDrawer } from "../../../../../../../store/alerts/alertsSlice";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { initiateBudgetByIdThunk } from "../../../../../../../store/budgets/thunks/initiateBudgetByIdThunk";

interface BudgetsDataGridEditActionProps {
  budgetId: string;
  closeMenu(): void;
}

export const BudgetsDataGridEditAction: FC<BudgetsDataGridEditActionProps> = ({
  budgetId,
  closeMenu,
}) => {
  const dispatch = useAppDispatch();

  const { can } = useAppAbility();
  const canEditBudget = can("edit", "budget");

  const handleEditBudget = useCallback(() => {
    closeMenu();
    dispatch(toggleAlertsOpenDrawer());
    dispatch(initiateBudgetByIdThunk(budgetId));
  }, [dispatch, closeMenu, budgetId]);

  return (
    <MenuItem dense onClick={handleEditBudget} disabled={!canEditBudget}>
      <ListItemIcon>
        <EditOutlinedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Edit</ListItemText>
    </MenuItem>
  );
};
