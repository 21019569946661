import { FC } from "react";
import { ReservationsDataGridViewTabs } from "./components/ReservationsDataViewTabs";
import { reservedInstancesTableColumnDefs } from "./columns/reservedInstancesTableColumnDefs";
import { useAppSelector } from "../../../../../../../store/hooks";
import { reservationsDataSelector } from "../../../../../../../store/commitments/selectors/reservations/data/reservationsDataSelector";
import { reservationsForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/reservations/data/loading/reservationsForTableLoadingSelector";
import { CommitmentsAGGridToolbar } from "../../../../common/components/data-grid/CommitmentsAGGridToolbar";

import {
  CommitmentsAgGrid,
  CommitmentsToolbar,
} from "../../../../common/components/CommitmentsAgGrid";

export const ReservedInstancesDataGrid: FC = () => {
  const data = useAppSelector(reservationsDataSelector);
  const loading = useAppSelector(reservationsForTableLoadingSelector);

  return (
    <CommitmentsAgGrid
      Toolbar={toolbar}
      loading={loading}
      columnDefs={reservedInstancesTableColumnDefs}
      data={data}
      grouping="commitment"
      localStorageUniqueKey="reservedInstanceDataGrid"
    />
  );
};

const toolbar: CommitmentsToolbar = ({ gridApi }) => (
  <CommitmentsAGGridToolbar gridApi={gridApi}>
    <ReservationsDataGridViewTabs />
  </CommitmentsAGGridToolbar>
);
