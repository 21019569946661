import { RootState } from "../../../../store";
import { WidgetType } from "../../../../../services/cloudchipr.api";
import { billingSummaryWidgetNotFoundSelector } from "../billing-summary/billingSummaryWidgetNotFoundSelector";
import { costAnomaliesWidgetNotFoundSelector } from "../cost-anomalies/costAnomaliesWidgetNotFoundSelector";
import { commitmentUtilizationChangesWidgetNotFoundSelector } from "../commitment-utilization/widget-view/commitmentUtilizationChangesWidgetNotFoundSelector";
import { billingExplorerWidgetNotFoundSelector } from "../billing-explorer-widget/widget-view/billingExplorerWidgetNotFoundSelector";
import { coverageChangesWidgetNotFoundSelector } from "../coverage/widget-view/coverageChangesWidgetNotFoundSelector";

export const widgetDataNotFoundSelector = (
  state: RootState,
  widgetId: string,
  widgetType: WidgetType,
) => {
  if (widgetType === "cost_breakdown") {
    return billingExplorerWidgetNotFoundSelector(state, widgetId);
  }

  if (widgetType === "cost_and_usage_summary") {
    return billingSummaryWidgetNotFoundSelector(state, widgetId);
  }

  if (widgetType === "largest_cost_changes") {
    return costAnomaliesWidgetNotFoundSelector(state, widgetId);
  }

  if (widgetType === "commitments_utilization") {
    return commitmentUtilizationChangesWidgetNotFoundSelector(state, widgetId);
  }

  if (widgetType === "coverage") {
    return coverageChangesWidgetNotFoundSelector(state, widgetId);
  }

  return false;
};
