import { kubernetesLiveUsageMgmtResourceTypeDataSelector } from "./kubernetesLiveUsageMgmtResourceTypeDataSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { kubernetesLiveUsagePageStateSelector } from "../store-selectors/kubernetesLiveUsagePageStateSelector";

export const kubernetesLiveUsageMgmtResourceTypeDataFilteredSpendingSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const pageState = kubernetesLiveUsagePageStateSelector(state);

  if (pageState === "pending") {
    return 0;
  }

  return (
    kubernetesLiveUsageMgmtResourceTypeDataSelector(state, resourceType)
      ?.filtered_monthly_spending ?? 0
  );
};
