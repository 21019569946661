import { FC } from "react";
import { Stack } from "@mui/material";
import moment from "moment";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";

interface ReportsExecutionCellProps {
  executionDate: string;
  timeZone?: string;
}

export const ReportsExecutionCell: FC<ReportsExecutionCellProps> = ({
  executionDate,
  timeZone,
}) => {
  const offset = moment()
    .tz(timeZone ?? "")
    .format("Z");
  const time = formatDate(executionDate, {
    type: "time",
  });
  const title = !timeZone ? time : `${time} (UTC${offset})`;

  return (
    <Stack>
      <TypographyWithTooltip
        variant="body2"
        title={formatDate(executionDate, {
          type: "dateWithComma",
        })}
      />
      <TypographyWithTooltip
        variant="caption"
        fontSize={10}
        title={title}
        color="text.secondary"
      />
    </Stack>
  );
};
