import { createAsyncThunk } from "@reduxjs/toolkit";
import { CoverageWidgetSetupType } from "../../../../utils/types/setups/coverageWidgetSetupType";
import { RootState } from "../../../../../store";
import { isDeepEqual } from "../../../../../../utils/is-deep-equal";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { coverageWidgetSetupSelector } from "../../../../selectors/setups/coverage/coverageWidgetSetupSelector";
import { getCoverageWidgetSetupDataThunk } from "../getCoverageWidgetSetupDataThunk";

export const coverageWidgetSetupDataChangeThunk = createAsyncThunk(
  "dashboard/coverageWidgetSetupDataChange",
  async (data: Partial<CoverageWidgetSetupType>, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = coverageWidgetSetupSelector(state);

    if (!existingSetup || isDeepEqual(existingSetup, data)) {
      return;
    }

    dispatch(
      setWidgetSetup({
        ...existingSetup,
        ...data,
      }),
    );

    dispatch(getCoverageWidgetSetupDataThunk());
  },
);
