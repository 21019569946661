import { RootState } from "../../../store";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";
import { navigationDataByVisibilitySelector } from "../../../navigation/selectors/dashboard/navigationDataByVisibilitySelector";
import { TreeItems } from "../../../../components/app-navigation/components/sortable-tree/utils/types";

export const dashboardHierarchyFoldersSelector = (
  state: RootState,
  visibility: NavigationItemsVisibilityType,
): TreeItems | undefined => {
  const hierarchy = navigationDataByVisibilitySelector(state, visibility);

  if (!hierarchy) {
    return;
  }
  return hierarchy.reduce((result, item) => {
    if (item.type === "folder") {
      result.push(item);
    }
    return result;
  }, [] as TreeItems);
};
