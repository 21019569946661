import { generateLiveResourcesPath } from "../../../../accounts-group/utils/helpers/generateLiveResourcesPath";
import {
  OpportunityTarget,
  ResourceTarget,
} from "../../../../../../services/cloudchipr.api";

export const generateLiveResourcesPathFromTarget = (
  target: ResourceTarget | OpportunityTarget,
) => {
  return generateLiveResourcesPath({
    provider: target.account.provider,
    activeTab: "live-resources",
    accountIds: target?.live_resource_link_response?.account_id ?? "",
    resourceType:
      target?.live_resource_link_response?.resource_type ??
      target.resource_type,
    resourceIds:
      target?.live_resource_link_response?.provider_identifier ??
      target.provider_identifier,
  });
};
