export const filterUnits: Map<string, string> = new Map([
  ["network_in", "GiB"],
  ["network_out", "GiB"],
  ["cpu", "%"],
  ["cpu_core", "Core"],
  ["cpu_utilization", "%"],
  ["memory_utilization", "%"],
  ["memory_bytes", "Byte"],
  ["read_usage", "%"],
  ["write_usage", "%"],
  ["snapshots_price_ratio", "%"],
  ["size", "Byte"],
  ["memory_reservation", "GB"],
  ["storage", "GB"],
  ["processed_bytes", "MB"],
  ["monthly_price", "$"],
  ["memory", "%"],
]);
