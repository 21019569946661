import { FC } from "react";
import { Box, Card, CardHeader, IconButton, Stack } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { AccountCardHeaderTitle } from "./AccountCardHeaderTitle";
import { AccountCardHeaderInfo } from "./AccountCardHeaderInfo";
import {
  AccountMissingPermissions,
  AccountStatus,
  ProviderAccessType,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";

interface AccountCardDragPreviewProps {
  name: string;
  id: string;
  status?: AccountStatus;
  accessType?: ProviderAccessType;
  missingPermissions?: AccountMissingPermissions;
  currentOffsetX: number;
  provider: ProviderType;
  currentOffsetY: number;
}

export const AccountCardDragPreview: FC<AccountCardDragPreviewProps> = ({
  currentOffsetY,
  currentOffsetX,
  name,
  id,
  status,
  accessType,
  provider,
  missingPermissions,
}) => {
  return (
    <Box
      position="fixed"
      sx={{ pointerEvents: "none", transform: "rotate(-10deg)" }}
      zIndex={100}
      width={400}
      left={currentOffsetX}
      top={currentOffsetY}
    >
      <Card
        sx={{
          borderRadius: 3,
        }}
        variant="outlined"
      >
        <CardHeader
          sx={{
            "& .MuiCardHeader-content": { overflow: "hidden" },
            pb: 1,
          }}
          title={
            <Stack direction="row" alignItems="center">
              <IconButton>
                <DragIndicatorIcon color="action" />
              </IconButton>
              <AccountCardHeaderTitle name={name ?? ""} accountId={id ?? ""} />
            </Stack>
          }
        />
        <AccountCardHeaderInfo
          accountId={id ?? ""}
          status={status}
          accessType={accessType}
          provider={provider}
          missingPermissions={missingPermissions}
        />
      </Card>
    </Box>
  );
};
