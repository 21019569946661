import { ColDef } from "ag-grid-community";

export const assigneesComparatorFn: ColDef["comparator"] = (
  _,
  __,
  nodeA,
  nodeB,
) => {
  const currentAssignee = nodeA?.data?.assignees?.at(0);
  const nextAssignee = nodeB?.data?.assignees?.at(0);

  const current = currentAssignee?.name ?? currentAssignee?.email ?? "";
  const next = nextAssignee?.name ?? nextAssignee?.email ?? "";

  if (!current) {
    return 1;
  }

  return current.localeCompare(next);
};
