import { FC, useEffect, useRef } from "react";
import { Stack } from "@mui/material";
import { TaskActivityComponent } from "./activities/TaskActivity";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { getTaskActivitiesDataSelector } from "../../../../../../../../../store/task-management/selectors/activiities/getTaskActivitiesDataSelector";

import { taskManagementTaskIdSelector } from "../../../../../../../../../store/task-management/selectors/form/properties/taskManagementTaskIdSelector";

export const TaskCreationDrawerActivitiesContent: FC = () => {
  const taskId = useAppSelector(taskManagementTaskIdSelector);
  const ref = useRef<HTMLDivElement | null>(null);
  const activities = useAppSelector((state) =>
    getTaskActivitiesDataSelector(state, taskId ?? ""),
  );

  useEffect(() => {
    ref?.current?.scrollTo({
      top: ref.current.scrollHeight,
      behavior: "smooth",
    });
  }, [activities]);

  if (!taskId) {
    return;
  }

  return (
    <Stack flex={1} spacing={2} p={2} pt={0} overflow="auto" ref={ref}>
      {activities?.map((activity) => (
        <TaskActivityComponent
          key={activity.id}
          {...activity}
          taskId={taskId}
        />
      ))}
    </Stack>
  );
};
