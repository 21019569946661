import { FC } from "react";
import { ResourceDetailsGeneralContentCostsChartType } from "../common/ResourceDetailsGeneralContentCostsChartType";
import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceDetailsChartTypeSelector } from "../../../../../../../store/resource-details/selectors/options/resourceDetailsChartTypeSelector";

interface ResourceDetailsCostsContentChartTypeProps {
  processKey: string;
}

export const ResourceDetailsCostsContentChartType: FC<
  ResourceDetailsCostsContentChartTypeProps
> = ({ processKey }) => {
  const chartType = useAppSelector((state) =>
    resourceDetailsChartTypeSelector(state, processKey),
  );

  if (!chartType) {
    return null;
  }

  return (
    <ResourceDetailsGeneralContentCostsChartType
      chartType={chartType}
      processKey={processKey}
    />
  );
};
