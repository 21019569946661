import { FC } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TypographyWithCopy } from "../../../../../../../../../../../common/TypographyWithCopy";
import {
  ProviderType,
  Tags,
} from "../../../../../../../../../../../../services/cloudchipr.api";
import { tagLabels } from "../../../../../../../utils/constants/tagLabels";

interface ResourceTagsTableProps {
  tags: Tags;
  provider: ProviderType;
}

export const ResourceTagsTable: FC<ResourceTagsTableProps> = ({
  tags,
  provider,
}) => {
  return (
    <Box width={500} minHeight={200} maxHeight={350}>
      <Box>
        <Typography
          pl={2}
          variant="overline"
          fontWeight="medium"
          color="text.secondary"
        >
          {tagLabels.get(provider)}
        </Typography>
        <Table size="small" sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow sx={{ bgcolor: "grey.50" }}>
              <TableCell width="50%">Key</TableCell>
              <TableCell width="50%">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!tags.length && (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  No {tagLabels.get(provider)}
                </TableCell>
              </TableRow>
            )}

            {tags.map((tag) => {
              return (
                <TableRow key={tag.key}>
                  <TableCell>
                    <TypographyWithCopy value={tag.key} variant="body2" />
                  </TableCell>
                  <TableCell>
                    <TypographyWithCopy value={tag.value} variant="body2" />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
