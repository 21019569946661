import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBillingExplorerWidgetSetupDataThunk } from "./getBillingExplorerWidgetSetupDataThunk";
import { RootState } from "../../../../store";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { billingExplorerWidgetSetupDefaultData } from "../../../utils/constants/billingExplorerWidgetSetupDefaultData";
import { resourceExplorerViewsDataSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsDataSelector";

export const initiateBillingExplorerWidgetThunk = createAsyncThunk(
  "dashboards/initiateBillingExplorerWidgetWidget",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const views = resourceExplorerViewsDataSelector(state);

    const defaultViewId = views?.find((view) => view.is_default)?.id;

    if (!defaultViewId) {
      dispatch(setWidgetSetup(billingExplorerWidgetSetupDefaultData));
      return;
    }

    dispatch(
      setWidgetSetup({
        ...billingExplorerWidgetSetupDefaultData,
        viewId: defaultViewId,
      }),
    );

    dispatch(getBillingExplorerWidgetSetupDataThunk());
  },
);
