import { RightsizingRecommendationsAdvancedFilters } from "../../../../../../../store/recommendations/utils/types/types";

export const checkAdvancedFiltersPreferenceExistence = (
  preferences: RightsizingRecommendationsAdvancedFilters,
) => {
  return !(
    preferences.displayRecommendationsAcrossInstanceFamily === undefined &&
    preferences.multipleRecommendationsPerResource === undefined
  );
};
