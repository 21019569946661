import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, Fragment, useCallback } from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { enqueueSnackbar } from "notistack";
import { useAppAbility } from "../../../../../../../../../../services/permissions";
import { useIntegrationsUsage } from "../../../../utils/hooks/useIntegrationsUsage.hook";
import {
  Integration,
  useDeleteUsersMeIntegrationsByIntegrationIdMutation,
  useGetUsersMeIntegrationsQuery,
} from "../../../../../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../../../../../utils/hooks/useDialog.hook";
import { DeleteConfirmationDialog } from "../../../../../../../../../common/modals/DeleteConfirmationDialog";

interface IntegrationRemoveActionProps {
  closeMenu(): void;
  integrationId: string;
  type: Integration["type"];
  name: string;
}

export const IntegrationRemoveAction: FC<IntegrationRemoveActionProps> = ({
  integrationId,
  type,
  name,
  closeMenu,
}) => {
  const { cannot } = useAppAbility();
  const canNotDeleteIntegration = cannot("delete", "integration");
  const { getIntegrationsUsageExist } = useIntegrationsUsage();
  const usageExist = getIntegrationsUsageExist(integrationId);

  const [deleteIntegration, { isLoading }] =
    useDeleteUsersMeIntegrationsByIntegrationIdMutation();
  const { refetch } = useGetUsersMeIntegrationsQuery({ type });

  const { open, openDialog, closeDialog } = useDialog();

  const closeConfirmDialogHandler = useCallback(() => {
    closeMenu();
    closeDialog();
  }, [closeMenu, closeDialog]);

  const onDeleteConfirmHandler = useCallback(async () => {
    await deleteIntegration({ integrationId }).unwrap();
    enqueueSnackbar(`${name} Integration deleted!`, {
      variant: "snackbarAlert",
      AlertSnackBarProps: {
        severity: "success",
      },
    });
    refetch();
    closeConfirmDialogHandler();
  }, [
    integrationId,
    name,
    refetch,
    deleteIntegration,
    closeConfirmDialogHandler,
  ]);

  return (
    <Fragment>
      <Tooltip
        arrow
        title={usageExist ? "This integration is currently in use." : ""}
      >
        <span>
          <MenuItem
            dense
            onClick={openDialog}
            disabled={canNotDeleteIntegration || usageExist}
          >
            <ListItemIcon>
              <DeleteOutlinedIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </span>
      </Tooltip>

      <DeleteConfirmationDialog
        open={open}
        title="Delete Integration?"
        onClose={closeConfirmDialogHandler}
        onDelete={onDeleteConfirmHandler}
        loading={isLoading}
        content={
          <Typography>
            Please confirm if you want to delete
            <Typography component="span" fontWeight="bold">
              {" "}
              {name}{" "}
            </Typography>
            integration.
          </Typography>
        }
      />
    </Fragment>
  );
};
