import { FC, Fragment, useCallback, useMemo } from "react";
import { ListItemIcon, MenuItem, Tooltip, Typography } from "@mui/material";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { ResourceExplorerViewMoveActionMenu } from "./ResourceExplorerViewMoveActionMenu";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { useMenuHook } from "../../../../../../../../../utils/hooks/useMenu.hook";
import { resourceExplorerViewsHierarchyFoldersSelector } from "../../../../../../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyFoldersSelector";
import { navigationHierarchyItemNoDndKey } from "../../../../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../../../../store/navigation/utils/types";

interface ResourceExplorerViewMoveActionProps {
  id: string;
  name: string;
  folderId?: string;
  onClose(): void;
  isProtected?: boolean;
  visibility: NavigationItemsVisibilityType;
}

export const ResourceExplorerViewMoveAction: FC<
  ResourceExplorerViewMoveActionProps
> = ({ id, name, folderId, onClose, visibility, isProtected }) => {
  const { anchor, open, openMenu, closeMenu: closeMoveMenu } = useMenuHook();
  const privateFolders = useAppSelector((state) =>
    resourceExplorerViewsHierarchyFoldersSelector(state, "visible_only_to_me"),
  );
  const publicFolders = useAppSelector((state) =>
    resourceExplorerViewsHierarchyFoldersSelector(state, "visible_to_everyone"),
  );

  const filteredFolders = useMemo(() => {
    return [...(publicFolders ?? []), ...(privateFolders ?? [])].filter(
      (item) => item.id !== folderId,
    );
  }, [publicFolders, privateFolders, folderId]);

  const moveMenuItemDisabled = !filteredFolders?.length && !folderId;

  const closeMenusHandler = useCallback(() => {
    onClose();
    closeMoveMenu();
  }, [onClose, closeMoveMenu]);

  const tooltipTitle = useMemo(() => {
    if (moveMenuItemDisabled) {
      return "No available folders to move to.";
    }

    if (isProtected) {
      return "Protected reports cannot be modified.";
    }

    return "";
  }, [isProtected, moveMenuItemDisabled]);

  return (
    <Fragment>
      <Tooltip
        arrow
        title={tooltipTitle}
        placement="right"
        {...navigationHierarchyItemNoDndKey}
      >
        <span>
          <MenuItem
            onClick={openMenu}
            disabled={moveMenuItemDisabled || isProtected}
          >
            <ListItemIcon>
              <DriveFileMoveOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">Move</Typography>
            <ListItemIcon sx={{ justifyContent: "flex-end" }}>
              <ChevronRightOutlinedIcon fontSize="small" />
            </ListItemIcon>
          </MenuItem>
        </span>
      </Tooltip>
      <ResourceExplorerViewMoveActionMenu
        folderId={folderId}
        id={id}
        name={name}
        open={open}
        onClose={closeMenusHandler}
        anchor={anchor}
        visibility={visibility}
      />
    </Fragment>
  );
};
