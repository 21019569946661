import { FC } from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import NoMatchImage from "../../../../../../../../assets/images/integrations_no_match.svg";

export const CommunicationIntegrationsDataGridNoRowsComponent: FC = () => {
  return (
    <Stack spacing={0.5} alignItems="center">
      <img src={NoMatchImage} alt="EmptyStateImage" width={144} />

      <Typography component="h6" variant="h6" fontWeight="bold" pt={2}>
        No results found
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Try using different keywords
      </Typography>
    </Stack>
  );
};
