import { kubernetesClustersSelector } from "./kubernetes/kubernetesClustersSelector";
import { accountsDividedToStatusesByOrgIdSelector } from "./accountsDividedToStatusesByOrgIdSelector";
import {
  Account,
  KubernetesCluster,
  ProviderType,
} from "../../../services/cloudchipr.api";
import { RootState } from "../../store";

type DividedAccountsType = {
  active?: (Account | KubernetesCluster)[];
  inactive?: Account[];
};

export const accountsWithKubernetesDividedToStatusesByOrgIdSelector = (
  state: RootState,
  provider?: ProviderType,
  orgId?: string,
): DividedAccountsType | null => {
  const clusters = kubernetesClustersSelector(state);

  if (provider === "kubernetes") {
    return {
      active: clusters,
    };
  }

  return accountsDividedToStatusesByOrgIdSelector(state, provider, orgId);
};
