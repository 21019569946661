import { FC, useCallback, useMemo } from "react";
import { GridApi } from "ag-grid-community";
import { IconButton, Tooltip } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useAppSelector } from "../../../../../../store/hooks";
import { commitmentsDatesSelector } from "../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { calculateFromToByDatesData } from "../../../../../common/date-range-picker/utils/helpers/calculateFromToByDatesData";
import { commitmentsCsvFileNameSelector } from "../../../../../../store/commitments/selectors/common/commitmentsCsvFileNameSelector";

interface CommitmentCsvExportProps {
  gridApi: GridApi | null;
}

export const CommitmentCsvExport: FC<CommitmentCsvExportProps> = ({
  gridApi,
}) => {
  const dates = useAppSelector(commitmentsDatesSelector);

  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );
  const fileName = useAppSelector(commitmentsCsvFileNameSelector);

  const tooltip = useMemo(() => {
    const { toDate, fromDate } = calculateFromToByDatesData(
      dates,
      quarterStartMonth,
    );

    return fromDate && toDate ? `from ${fromDate} to ${toDate}` : "";
  }, [dates, quarterStartMonth]);

  const exportDataAsCsv = useCallback(() => {
    gridApi?.exportDataAsCsv({
      fileName,
    });
  }, [gridApi, fileName]);

  return (
    <Tooltip title={`Export CSV ${tooltip}`} arrow placement="top">
      <IconButton size="medium" onClick={exportDataAsCsv}>
        <DownloadOutlinedIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
