import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { ExecutionLogsDateRangeSelector } from "./ExecutionLogsDateRangeSelector";
import { ExecutionLogsSearch } from "./ExecutionLogsSearch";
import { ExecutionLogsShowAll } from "./ExecutionLogsShowAll";
import { ExecutionLogsFilters } from "./header/filters/ExecutionLogsFilters";
import { ExecutionLogTotal } from "./ExecutionLogTotal";
import { ExecutionLogCount } from "./ExecutionLogCount";

export const ExecutionLogsToolbar: FC = () => {
  return (
    <Box bgcolor="white">
      <Stack
        p={1}
        direction="row"
        alignItems="center"
        spacing={1}
        borderBottom={1}
        borderColor="grey.300"
      >
        <ExecutionLogsDateRangeSelector />
        <ExecutionLogsFilters />
        <ExecutionLogCount />
        <ExecutionLogsShowAll />
      </Stack>
      <Stack
        p={1}
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <ExecutionLogTotal />
        <ExecutionLogsSearch />
      </Stack>
    </Box>
  );
};
