import { billingExplorerWidgetDataByWidgetIdSelector } from "./billingExplorerWidgetDataByWidgetIdSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridDataWithId } from "../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { formatBillingExplorerREDataGridData } from "../../../../utils/helpers/billing-explorer/formatBillingExplorerREDataGridData";

export const billingExplorerWidgetTotalDataWithIdsSelector = (
  state: RootState,
  widgetId: string,
): ResourceExplorerGridDataWithId[] | undefined => {
  const data = billingExplorerWidgetDataByWidgetIdSelector(
    state,
    widgetId,
  )?.total;

  return formatBillingExplorerREDataGridData(data ?? [], true) ?? [];
};
