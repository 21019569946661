import { FC } from "react";
import Stack from "@mui/material/Stack";
import { Box, Tooltip } from "@mui/material";
import { ImplementationEffort } from "../../../../services/cloudchipr.api";
import {
  possibleEfforts,
  possibleEffortsLabels,
} from "../utils/constants/common";

interface SavingsOpportunityImplementationEffortProps {
  effort: ImplementationEffort;
}

export const SavingsOpportunityImplementationEffort: FC<
  SavingsOpportunityImplementationEffortProps
> = ({ effort }) => {
  if (!effort) {
    return null;
  }

  const currentEffortIndex = possibleEfforts.findIndex(
    (possibleEffort) => possibleEffort === effort,
  );

  return (
    <Tooltip title={possibleEffortsLabels[effort]} arrow placement="top">
      <Stack direction="row" spacing={0.5} width="fit-content">
        <Box width={10} height={6} borderRadius={2} bgcolor="primary.main" />

        <Box
          width={10}
          height={6}
          borderRadius={2}
          bgcolor={currentEffortIndex > 0 ? "primary.main" : "grey.300"}
        />
        <Box
          width={10}
          height={6}
          borderRadius={2}
          bgcolor={currentEffortIndex > 1 ? "primary.main" : "grey.300"}
        />
        <Box
          width={10}
          height={6}
          borderRadius={2}
          bgcolor={currentEffortIndex > 2 ? "primary.main" : "grey.300"}
        />
        <Box
          width={10}
          height={6}
          borderRadius={2}
          bgcolor={currentEffortIndex > 3 ? "primary.main" : "grey.300"}
        />
      </Stack>
    </Tooltip>
  );
};
