import { FC } from "react";
import {
  DateDataPoint,
  DateLabelNullable,
  ResourceExplorerCostsDateDetails,
} from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";
import { capitalizeString } from "../../../../../../../../utils/helpers/capitalizeString";
import { TrendChip } from "../../../../../../../common/chips/TrendChip";
import { InfoCard } from "../../../../../../common/info-cards/InfoCard";
import { getTrendTooltipTitle } from "../../../../../../dashboard/utils/helpers/trend-tooltip-title/getTrendTooltipTitle";

interface AverageCostProps {
  dataPoint: DateDataPoint;
  average?: number | null;
  dateDetails?: ResourceExplorerCostsDateDetails;
  previousPeriodCostDates?: ResourceExplorerCostsDateDetails;
  dateLabel?: DateLabelNullable;
  averageTrend?: number | null;
  loading?: boolean;
}

export const AverageCost: FC<AverageCostProps> = ({
  average,
  dataPoint,
  dateDetails,
  previousPeriodCostDates,
  dateLabel,
  averageTrend,
  loading,
}) => {
  const tooltipTitle = getTrendTooltipTitle(
    dateDetails?.from_date,
    dateDetails?.to_date,
    previousPeriodCostDates?.from_date,
    previousPeriodCostDates?.to_date,
    undefined,
    dateLabel,
  );

  if (average === null) {
    return null;
  }

  return (
    <InfoCard
      loading={loading}
      title={`${capitalizeString(dataPoint)} Average`}
      value={money(average)}
      extra={<TrendChip value={averageTrend} tooltipTitle={tooltipTitle} />}
    />
  );
};
