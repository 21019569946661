import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { AlertsCostAnomalyDataGridViewCell } from "./AlertsCostAnomalyDataGridViewCell";
import { AlertsCostAnomalyDataGridGroupingCell } from "./AlertsCostAnomalyDataGridGroupingCell";
import { AlertsCostAnomalyDataGridTimeIntervalCell } from "./AlertsCostAnomalyDataGridTimeIntervalCell";
import { CostAnomalyAlertsDataGridDataType } from "../../../../../../../store/alerts/utils/types/types";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { costAnomalyAlertsTimeIntervalComparator } from "../utils/helpers/comparators/costAnomalyAlertsTimeIntervalComparator";
import { costAnomalyAlertsTimeIntervalValueGetter } from "../utils/helpers/valueGetters/costAnomalyAlertsTimeIntervalValueGetter";
import { ResourceExplorerGroupingLabelMap } from "../../../../../common/resource-explorer-grouping-selector-v2/utils/types/types";
import { getAlertsNameColumnDef } from "../../common/columnDefs/alertsNameColumnDef";
import { getAlertsThresholdColumnDef } from "../../common/columnDefs/alertsThresholdColumnDef";
import { getAlertsCreatedByColumnDef } from "../../common/columnDefs/alertsCreatedByColumnDef";
import { getAlertsModifiedByColumnDef } from "../../common/columnDefs/alertsModifiedByColumnDef";
import { getAlertsNotificationMethodColumnDef } from "../../common/columnDefs/alertsNotificationMethodColumnDef";
import { getAlertsActionsColumnDef } from "../../common/columnDefs/alertsActionsColumnDef";

const alertsNameColumnDef =
  getAlertsNameColumnDef<CostAnomalyAlertsDataGridDataType>();
const alertsThresholdColumnDef =
  getAlertsThresholdColumnDef<CostAnomalyAlertsDataGridDataType>();
const alertsCreatedByColumnDef =
  getAlertsCreatedByColumnDef<CostAnomalyAlertsDataGridDataType>();
const alertsModifiedByColumnDef =
  getAlertsModifiedByColumnDef<CostAnomalyAlertsDataGridDataType>();
const alertsNotificationMethodColumnDef =
  getAlertsNotificationMethodColumnDef<CostAnomalyAlertsDataGridDataType>();
const alertsActionsColumnDef =
  getAlertsActionsColumnDef<CostAnomalyAlertsDataGridDataType>();

export const getAlertsCostAnomalyDataGridColumnDefs = (
  billingExplorerGroupingLabels?: ResourceExplorerGroupingLabelMap,
): ColDef<CostAnomalyAlertsDataGridDataType>[] => [
  alertsNameColumnDef,
  {
    field: "viewName",
    headerName: "Billing Report",
    minWidth: 160,
    width: 200,
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<CostAnomalyAlertsDataGridDataType>) => {
      if (!data) {
        return;
      }

      return (
        <AlertsCostAnomalyDataGridViewCell
          viewId={data.view_id}
          viewName={data.viewName ?? ""}
        />
      );
    },
  },
  {
    field: "grouping",
    headerName: "Grouping",
    minWidth: 150,
    width: 175,
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<CostAnomalyAlertsDataGridDataType>) => {
      if (!data) {
        return;
      }

      return <AlertsCostAnomalyDataGridGroupingCell grouping={data.grouping} />;
    },
    valueGetter: ({ data }) => {
      const grouping = data?.grouping;

      return (
        billingExplorerGroupingLabels?.[grouping ?? ""]?.singular ??
        grouping ??
        ""
      );
    },
  },
  alertsThresholdColumnDef,
  {
    field: "time_interval.time_interval",
    headerName: "Time Interval",
    minWidth: 120,
    width: 200,
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<CostAnomalyAlertsDataGridDataType>) => {
      const interval = data?.time_interval;

      if (!interval) {
        return "-";
      }

      return (
        <AlertsCostAnomalyDataGridTimeIntervalCell
          timeInterval={interval.time_interval}
          timeIntervalUnit={interval.time_interval_unit}
        />
      );
    },
    comparator: costAnomalyAlertsTimeIntervalComparator,
    valueGetter: costAnomalyAlertsTimeIntervalValueGetter,
  },
  alertsCreatedByColumnDef,
  alertsModifiedByColumnDef,
  alertsNotificationMethodColumnDef,
  agGridEmptyColumnToFitEmptySpace,
  alertsActionsColumnDef,
];
