import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDidMount } from "rooks";
import { Stack } from "@mui/material";
import { ColDef, type FilterChangedEvent, GridApi } from "ag-grid-community";
import { CommunicationIntegrationsDataGridNoRowsComponent } from "./CommunicationIntegrationsDataGridNoRowsComponent";
import { CommunicationIntegrationsDataGridToolbar } from "./CommunicationIntegrationsDataGridToolbar";
import { getAllReportsThunk } from "../../../../../../../../store/reports/thunks/getAllReportsThunk";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { getWorkflowsThunk } from "../../../../../../../../store/automations/thunks/wokrflows/getWorkflowsThunk";
import { getCostAnomalyAlertsThunk } from "../../../../../../../../store/alerts/thunks/cost-anomaly-alerts/getCostAnomalyAlertsThunk";
import { getUtilizationAlertsThunk } from "../../../../../../../../store/alerts/thunks/utilization-alerts/getUtilizationAlertsThunk";
import { AgDataGrid } from "../../../../../../common/ag-grid/AgDataGrid";
import { Budget } from "../../../../../../../../services/cloudchipr.api";
import { useIntegrationsUsage } from "../../utils/hooks/useIntegrationsUsage.hook";

interface CommunicationIntegrationsGridProps {
  columnDefs: ColDef[];
  data: any[] | undefined;
  loading?: boolean;
  uniqueKey: string;
  actionButton?: ReactNode;
}

export const CommunicationIntegrationsDataGrid: FC<
  CommunicationIntegrationsGridProps
> = ({ columnDefs, data, loading, uniqueKey, actionButton }) => {
  const dispatch = useAppDispatch();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const { getIntegrationsUsage } = useIntegrationsUsage();

  const rowData = useMemo(
    () =>
      data?.map((integration) => {
        const usages = getIntegrationsUsage(integration?.id);

        return { ...integration, usages };
      }),
    [data, getIntegrationsUsage],
  );

  useDidMount(() => {
    dispatch(getWorkflowsThunk());
    dispatch(getCostAnomalyAlertsThunk());
    dispatch(getUtilizationAlertsThunk());
    dispatch(getAllReportsThunk());
  });

  const handleNoRowsOverlayVisibility = useCallback(
    (gridApi: GridApi | null) => {
      if (!gridApi) {
        return;
      }

      if (!gridApi.getDisplayedRowCount()) {
        gridApi?.showNoRowsOverlay();
        gridApi?.setGridOption("domLayout", "normal");
      } else {
        gridApi?.hideOverlay();
        gridApi?.setGridOption("domLayout", "autoHeight");
      }
    },
    [],
  );

  const filterChangeHandler = useCallback(
    (event: FilterChangedEvent<Budget>) => {
      handleNoRowsOverlayVisibility(event.api);
    },
    [handleNoRowsOverlayVisibility],
  );

  useEffect(() => {
    handleNoRowsOverlayVisibility(gridApi);
  }, [data?.length, gridApi, handleNoRowsOverlayVisibility]);

  const configToRememberStateInStorage = {
    uniqueNameAsKey: uniqueKey,
  };

  return (
    <Stack height="100%">
      <CommunicationIntegrationsDataGridToolbar gridApi={gridApi}>
        {actionButton}
      </CommunicationIntegrationsDataGridToolbar>

      <AgDataGrid
        rowHeight={52}
        headerHeight={52}
        rowData={rowData}
        loading={loading}
        columnDefs={columnDefs}
        configToRememberStateInStorage={configToRememberStateInStorage}
        defaultColDef={defaultColDef}
        gridApiSetter={setGridApi}
        noRowsOverlayComponent={
          CommunicationIntegrationsDataGridNoRowsComponent
        }
        onFilterChanged={filterChangeHandler}
        domLayout="autoHeight"
        wrapperStyles={wrapperStyles}
      />
    </Stack>
  );
};

const defaultColDef = {
  unSortIcon: true,
  suppressHeaderMenuButton: true,
};

// Adds border
const wrapperStyles = {
  "& .ag-root-wrapper": {
    borderRadius: "8px",
    border: "1px solid #E0E0E0",
  },
  "& .ag-header": {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  "& .ag-body": {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
};
