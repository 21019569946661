import { FC, useEffect } from "react";
import { useDidMount } from "rooks";
import { Stack } from "@mui/material";
import { ResourceDetailsGeneralContentTags } from "./components/ResourceDetailsGeneralContentTags";
import { ResourceDetailsGeneralContentDetails } from "./components/details/ResourceDetailsGeneralContentDetails";
import { ResourceDetailsGeneralContentCosts } from "./components/ResourceDetailsGeneralContentCosts";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { setResourceDetailsBillingDataProcesses } from "../../../../../../../store/resource-details/resourceDetailsSlice";
import { resourceDetailsGeneralTabProcessKey } from "../../../../../../../store/resource-details/utils/constants/processesKeys";
import { getResourceDetailsResourceBillingDataThunk } from "../../../../../../../store/resource-details/thunks/data/getResourceDetailsResourceBillingDataThunk";
import { resourceDetailsTargetResourceSelector } from "../../../../../../../store/resource-details/selectors/target-resource/resourceDetailsTargetResourceSelector";

export const ResourceDetailsGeneralContent: FC = () => {
  const dispatch = useAppDispatch();
  const resource = useAppSelector(resourceDetailsTargetResourceSelector);
  const resourceId = resource?.provider_unique_identifier;

  useDidMount(() => {
    dispatch(
      setResourceDetailsBillingDataProcesses({
        [resourceDetailsGeneralTabProcessKey]: {
          groupBy: "product_family",
          chartType: "column",
        },
      }),
    );
  });

  useEffect(() => {
    if (resourceId) {
      dispatch(getResourceDetailsResourceBillingDataThunk());
    }
  }, [dispatch, resourceId]);

  return (
    <Stack spacing={1.5}>
      <ResourceDetailsGeneralContentCosts />

      <ResourceDetailsGeneralContentDetails />

      {resource?.tags && (
        <ResourceDetailsGeneralContentTags tags={resource?.tags} label="Tags" />
      )}
    </Stack>
  );
};
