import { FC, Fragment } from "react";
import { Button, PopoverOrigin, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { BillingExplorerNavigationAddActionMenu } from "../../../../../app-navigation/components/page-components/billing-explorer/components/BillingExplorerNavigationAddActionMenu";
import { useAppAbility } from "../../../../../../services/permissions";
import { getUserPermissionInfoText } from "../../../../utils/helpers/getUserPermissionInfoText";

interface ResourceExplorerAllViewsHeaderActionsProps {
  loading?: boolean;
}

export const ResourceExplorerAllViewsHeaderActions: FC<
  ResourceExplorerAllViewsHeaderActionsProps
> = ({ loading }) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();
  const { cannot } = useAppAbility();
  const cannotCreateReport = cannot("create", "resource-explorer");

  const userPermissionTooltipText = cannotCreateReport
    ? getUserPermissionInfoText("Add Report")
    : "";

  return (
    <Fragment>
      <Tooltip title={userPermissionTooltipText}>
        <span>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={openMenu}
            disabled={loading || cannotCreateReport}
            sx={{ textTransform: "none" }}
          >
            Add
          </Button>
        </span>
      </Tooltip>

      <BillingExplorerNavigationAddActionMenu
        open={open}
        onCloseMenu={closeMenu}
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
        redirectAfterCreate={false}
        transformOrigin={transformOrigin}
      />
    </Fragment>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
