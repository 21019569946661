import { useCallback, useMemo, useState } from "react";
import { useEffectOnceWhen } from "rooks";
import { SortModelItem } from "ag-grid-community";
import {
  useGetUsersMeCurrentPreferenceByKeyQuery,
  usePatchUsersMeCurrentPreferenceByKeyMutation,
} from "../../../../../../../services/cloudchipr.api";
import { AutomationType } from "../../../../../../../store/automations/utils/types/common";

const userDefinedSortingId = "userDefinedSortingId";

type ReturnType<A> = {
  sortedData: A[];
  sorting: SettingsType[] | null;
  onRowsOrderChange(data: A[]): void;
  onColumnsSortingChange(sorting: SettingsType[]): void;
};

export type SettingsType = SortModelItem & {
  sortIndex: 0;
  order?: string[];
};

export const useAutomationsRowsOrder = <A extends { id: string }>(
  automationType: AutomationType,
  automationData: A[],
): ReturnType<A> => {
  const settingKey = `c8r:${automationType}-grid-rows-sorting`;

  const [update] = usePatchUsersMeCurrentPreferenceByKeyMutation();
  const {
    data: initialSettings,
    refetch,
    isLoading,
    isFetching,
  } = useGetUsersMeCurrentPreferenceByKeyQuery(
    { key: settingKey },
    { refetchOnMountOrArgChange: true },
  );

  const [settings, setSettings] = useState<SettingsType | null>(null);
  const sortingSettings = (
    initialSettings?.value ? JSON.parse(initialSettings?.value) : {}
  ) as SettingsType;

  const settingsUpdateHandler = useCallback(
    async (settings: SettingsType) => {
      await update({
        key: settingKey,
        body: { value: JSON.stringify(settings) },
      });

      refetch();
    },
    [update, refetch, settingKey],
  );

  const onColumnsSortingChange = useCallback(
    async (newSorting: SettingsType[]) => {
      const settings = newSorting?.at(0);

      setSettings((prevState) => {
        const newSettings = settings
          ? { ...settings, order: prevState?.order ?? [] }
          : ({
              ...prevState,
              colId: userDefinedSortingId,
            } as SettingsType);

        settingsUpdateHandler(newSettings);
        return newSettings;
      });
    },
    [settingsUpdateHandler],
  );

  const onRowsOrderChange = useCallback(
    (data: A[]) => {
      const newSettings: SettingsType = {
        sort: "desc",
        colId: userDefinedSortingId,
        sortIndex: 0,
        order: data.map((item) => item.id),
      };

      setSettings(newSettings);
      settingsUpdateHandler(newSettings);
    },
    [settingsUpdateHandler],
  );

  const sortedData = useMemo(() => {
    return getAutomationSortedData<A>(settings, automationData);
  }, [settings, automationData]);

  useEffectOnceWhen(
    async () => {
      await update({
        key: settingKey,
        body: {
          value: JSON.stringify({ colId: "name", sort: "desc", sortIndex: 0 }),
        },
      });

      refetch();
    },
    !sortingSettings?.colId && !isLoading && !isFetching,
  );

  useEffectOnceWhen(() => {
    setSettings(sortingSettings);
  }, !settings && !!sortingSettings?.colId);

  return {
    sortedData,
    onRowsOrderChange,
    onColumnsSortingChange,
    sorting: getSorting(sortingSettings),
  };
};

const getSorting = (sortingSettings: SettingsType): SettingsType[] | null => {
  if (!sortingSettings.colId) {
    return null;
  }

  if (sortingSettings?.colId === userDefinedSortingId) {
    return [];
  }

  return [
    {
      colId: sortingSettings.colId,
      sort: sortingSettings.sort,
      sortIndex: sortingSettings.sortIndex,
    },
  ];
};

const getAutomationSortedData = <A extends { id: string }>(
  settings: SettingsType | null,
  automationData: A[],
) => {
  if (settings?.colId !== userDefinedSortingId || !settings?.order?.length) {
    return automationData;
  }

  const dataByObject = automationData.reduce(
    (acc, item) => {
      acc[item.id] = item;

      return acc;
    },
    {} as Record<string, A>,
  );

  let sortedData =
    settings?.order?.map((id) => {
      const automation = dataByObject[id];
      delete dataByObject[id];

      return automation;
    }) ?? [];

  sortedData = [...sortedData, ...Object.values(dataByObject)].filter(
    (item) => !!item,
  );

  return sortedData?.length ? sortedData : automationData;
};
