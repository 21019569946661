import { FC } from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Button } from "@mui/material";

interface ExploreWithAIButtonV2Props {
  onClick(): void;
  state: "focused" | "initial";
}
export const ExploreWithAIButtonV2: FC<ExploreWithAIButtonV2Props> = ({
  onClick,
  state,
}) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        px: 1,
        textTransform: "none",
        borderRadius: "24px",
        background:
          state === "focused"
            ? "linear-gradient(100deg, #1443EA33, #7334F133)"
            : undefined,
        borderColor: "linear-gradient(100deg, #1443EA, #7334F1)",
        border: 1,
      }}
      size="small"
      startIcon={<AutoAwesomeIcon fontSize="small" color="primary" />}
    >
      Explain With AI
    </Button>
  );
};
