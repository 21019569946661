import { FC, Fragment, memo, useCallback, useMemo } from "react";
import { FormikHandlers } from "formik";
import { Typography } from "@mui/material";
import * as yup from "yup";
import {
  AutocompleteFilterBody,
  AutocompleteFilterBodyProps,
} from "../AutocompleteFilterBody";
import {
  FilterItem,
  Operator,
} from "../../../../../../../../services/cloudchipr.api";
import { FilterItemOperatorType } from "../../../FilterItemOperatorType";
import { DropdownSelectOption } from "../../../../../../../common/select/dropdown-select/utils/types/types";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { taskAvailablePropertiesLoadingSelector } from "../../../../../../../../store/task-management/selectors/properties/edit/loadings/taskAvailablePropertiesLoadingSelector";
import { taskAvailableStatusPropertiesSelector } from "../../../../../../../../store/task-management/selectors/properties/taskAvailableStatusPropertiesSelector";
import { multipleValueSelectOperators } from "../../../../utils/constants/filter-types/autocomplete-multipe-selection-operator-types";

interface TaskStatusFilterProps extends AutocompleteFilterBodyProps {
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
}

export const TaskStatusFilterValueValidation = yup.mixed().when("operator", {
  is: (operator: string) => multipleValueSelectOperators.includes(operator),
  then: yup.array().min(1).required(),
  otherwise: yup.string().required(),
});

export const TaskStatusFilter: FC<TaskStatusFilterProps> = memo(
  ({ operators, filter, onChange, ...props }) => {
    const statuses = useAppSelector(taskAvailableStatusPropertiesSelector);
    const loading = useAppSelector(taskAvailablePropertiesLoadingSelector);

    const formatSelectedLabel = useCallback(
      (value: string) => {
        return statuses?.find((status) => status.id === value)?.name || value;
      },
      [statuses],
    );

    const renderOption = useCallback(
      (option: DropdownSelectOption) => {
        const status = statuses?.find((status) => status.id === option.value);

        if (!status) {
          return option.value;
        }

        return <Typography variant="body2">{status.name}</Typography>;
      },
      [statuses],
    );

    const renderOptions = useMemo(() => {
      return statuses?.map((item) => {
        return {
          value: item.id,
          label: item.id,
        };
      });
    }, [statuses]);

    return (
      <Fragment>
        <FilterItemOperatorType
          onChange={onChange}
          options={operators}
          value={filter.operator || "in"}
        />

        <AutocompleteFilterBody
          {...props}
          options={renderOptions ?? []}
          isLoading={loading}
          label="Status"
          selectedValue={Array.isArray(filter?.value) ? filter.value : []}
          formatSelectedLabel={formatSelectedLabel}
          renderOption={renderOption}
        />
      </Fragment>
    );
  },
);
