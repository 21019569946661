import { RootState } from "../../../../store";
import { resourceDetailsBillingProcessesSelector } from "../../processes/resourceDetailsBillingProcessesSelector";

export const resourceDetailsResourceFirstProcessKeySelector = (
  state: RootState,
) => {
  const processes = resourceDetailsBillingProcessesSelector(state);

  return Object.keys(processes).at(0) ?? "";
};
