import { FC, memo, useCallback, useMemo } from "react";
import { FormikHelpers } from "formik";
import {
  FilterItem,
  ResourceType,
  useGetUsersMeOrganisationsCurrentKubernetesByResourceTypeFiltersAndFilterTypeValuesQuery,
} from "../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { DropdownSelectOption } from "../../../../../../../common/select/dropdown-select/utils/types/types";
import { AutocompleteSelect } from "../../../../../../../common/select/autocomplete-select/AutocompleteSelect";

interface KubernetesTagFilterKeysProps {
  error?: boolean;
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
  accountIds?: string[];
  resourceType: ResourceType;
  disabled: boolean;
  tagValueVisible: boolean;
  value?: string;
}

export const KubernetesTagFilterKeys: FC<KubernetesTagFilterKeysProps> = memo(
  ({
    value,
    resourceType,
    accountIds,
    setFieldValue,
    error,
    tagValueVisible,
    disabled,
  }) => {
    const { data, isLoading } =
      useGetUsersMeOrganisationsCurrentKubernetesByResourceTypeFiltersAndFilterTypeValuesQuery(
        {
          resourceType: resourceType,
          filterType: "tag_key",
        },
      );
    const autoCompleteSelectChangeHandler = useCallback(
      (data: any) => {
        if (value !== data[0]) {
          setFieldValue("value.key", data[0]);
          tagValueVisible && setFieldValue("value.value", []);
        }
      },
      [setFieldValue, tagValueVisible, value],
    );

    const options = useMemo(() => {
      return data?.map((item) => {
        return {
          label: <TypographyWithTooltip title={item?.title ?? item?.value} />,
          value: item.value,
        };
      });
    }, [data]);

    const renderOption = useCallback((option: DropdownSelectOption) => {
      return <TypographyWithTooltip title={option.value} />;
    }, []);

    return (
      <AutocompleteSelect
        size="xsmall"
        freeSolo
        creatable
        singleSelect
        label="Key"
        placeholder="Key"
        allSelectedLabel="keys"
        options={options ?? []}
        error={error}
        initialSelectedValues={data && value ? [value] : undefined}
        values={data && value ? [value] : undefined}
        onChange={autoCompleteSelectChangeHandler}
        disabled={disabled}
        optionsLoading={isLoading}
        renderOption={renderOption}
      />
    );
  },
);
