import { FC, Fragment, useCallback, useState } from "react";
import { Stack } from "@mui/material";
import { alertsUtilizationGridColumnDefs } from "./alertsUtilizationGridColumnDefs";
import { AlertsEmptyPage } from "../common/AlertsEmptyPage";
import { useAppSelector } from "../../../../../../store/hooks";
import { getUtilizationAlertsLoadingSelector } from "../../../../../../store/alerts/selectors/utilization-alerts/getUtilizationAlertsLoadingSelector";
import { utilizationAlertsDataGridDataSelector } from "../../../../../../store/alerts/selectors/utilization-alerts/utilizationAlertsDataGridDataSelector";
import { organizationsForCommitmentsLoadingSelector } from "../../../../../../store/commitments/selectors/organisations/organizationsForCommitmentsLoadingSelector";
import { BudgetAndAlertsAgGridToolbar } from "../common/BudgetAndAlertsAgGridToolbar";
import { AgDataGrid } from "../../../../common/ag-grid/AgDataGrid";
import { NoFilteredDataOverlay } from "../../../../common/ag-grid/components/NoFilteredDataOverlay";
import { budgetAndAlertsDefaultColDef } from "../common/utils/constants/constants";
import type { GridApi } from "ag-grid-community";

export const AlertsUtilization: FC = () => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const data = useAppSelector(utilizationAlertsDataGridDataSelector);
  const emptyData = !data?.length;
  const utilizationLoading = useAppSelector(
    getUtilizationAlertsLoadingSelector,
  );
  const organizationsLoading = useAppSelector(
    organizationsForCommitmentsLoadingSelector,
  );

  const onFilterChanged = useCallback(() => {
    if (gridApi) {
      const rowCount = gridApi.getDisplayedRowCount();
      if (rowCount === 0) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [gridApi]);

  return (
    <Stack height="100%">
      {emptyData && !utilizationLoading && <AlertsEmptyPage />}

      {!emptyData && (
        <Fragment>
          <BudgetAndAlertsAgGridToolbar gridApi={gridApi} />

          <AgDataGrid
            rowData={data}
            rowHeight={58}
            headerHeight={56}
            gridApiSetter={setGridApi}
            onFilterChanged={onFilterChanged}
            defaultColDef={budgetAndAlertsDefaultColDef}
            columnDefs={alertsUtilizationGridColumnDefs}
            noRowsOverlayComponent={NoFilteredDataOverlay}
            loading={utilizationLoading || organizationsLoading}
            configToRememberStateInStorage={configToRememberStateInStorage}
          />
        </Fragment>
      )}
    </Stack>
  );
};

const configToRememberStateInStorage = {
  uniqueNameAsKey: "utilizationAlertsTable",
};
