import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Collapse } from "@mui/material";
import { LiveUsageMgmtNestedNavItem } from "./LiveUsageMgmtNestedNavItem";
import { LiveUsageResourceNavItemCollapsed } from "./LiveUsageResourceNavItemCollapsed";
import { LiveUsageMgmtNavItemMain } from "./LiveUsageMgmtNavItemMain";
import { ResourceGroupType } from "../../../../../../../../utils/constants/resources/resourceGroupsByProvider";
import { ProviderType } from "../../../../../../../../services/cloudchipr.api";
import {
  LiveUsageNavigationResourceType,
  useLiveUsageMgmtPageNavigate,
} from "../../../utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../../utils/helpers/getIsResourceTypeHiddenByFeatureToggle";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { liveUsageMgmtResourceGroupEmsSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-group-data/liveUsageMgmtResourceGroupEmsSelector";
import { liveUsageMgmtResourceGroupSpendingSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-group-data/liveUsageMgmtResourceGroupSpendingSelector";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";

interface LiveUsageResourceNavItemProps extends ResourceGroupType {
  provider: ProviderType;
  selectedResourceType?: LiveUsageNavigationResourceType;
  sidebarCollapsed?: boolean;
  hideCosts?: boolean;
}

export const LiveUsageResourceNavItem: FC<LiveUsageResourceNavItemProps> = ({
  expandable,
  actionableResourceTypes,
  value,
  provider,
  selectedResourceType,
  sidebarCollapsed,
  hideCosts,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleNavigationClick = useLiveUsageMgmtPageNavigate();

  const nestedSelected = actionableResourceTypes.some(
    (resourceType) => resourceType === selectedResourceType,
  );
  const filterTemplatesEnabled = useAppSelector(
    liveUsageMgmtFilterTemplatesEnabledSelector,
  );
  const ems = useAppSelector((state) =>
    liveUsageMgmtResourceGroupEmsSelector(state, provider, value),
  );

  const total = useAppSelector((state) =>
    liveUsageMgmtResourceGroupSpendingSelector(state, provider, value),
  );
  const mainSelected =
    (!expandable || (expandable && !expanded)) && nestedSelected;

  const toggleExpanded = useCallback(() => {
    setExpanded((e) => !e);
  }, []);

  const handleMainItemClick = useCallback(() => {
    toggleExpanded();

    if (!expanded) {
      handleNavigationClick(value);
    }
  }, [toggleExpanded, expanded, value, handleNavigationClick]);

  useEffect(() => {
    if (nestedSelected) {
      setExpanded(true);
    }
  }, [nestedSelected]);

  if (sidebarCollapsed) {
    return (
      <LiveUsageResourceNavItemCollapsed
        resourceType={value}
        selected={nestedSelected}
        provider={provider}
        expandable={expandable}
        actionableResourceTypes={actionableResourceTypes}
        selectedResourceType={selectedResourceType}
        parentTotalCost={total}
        parentFilteredCost={ems}
        filterTemplatesEnabled={filterTemplatesEnabled}
      />
    );
  }

  return (
    <Fragment>
      <LiveUsageMgmtNavItemMain
        resourceType={value}
        provider={provider}
        selected={mainSelected}
        expandable={expandable}
        expanded={expanded}
        onClick={handleMainItemClick}
        hideCosts={hideCosts}
        parentTotalCost={total}
        parentFilteredCost={ems}
        filterTemplatesEnabled={filterTemplatesEnabled}
      />
      {expandable && (
        <Collapse in={expanded}>
          {actionableResourceTypes.map((resourceType) => {
            if (getIsResourceTypeHiddenByFeatureToggle(resourceType)) {
              return null;
            }

            return (
              <LiveUsageMgmtNestedNavItem
                key={resourceType}
                resourceType={resourceType}
                selected={resourceType === selectedResourceType}
              />
            );
          })}
        </Collapse>
      )}
    </Fragment>
  );
};
