import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Stack, Typography } from "@mui/material";
import { getAzureSavingsPlanCommonColumnDefs } from "./getAzureSavingsPlanCommonColumnDefs";
import { AzureSavingsPlanCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { azureNetSavingsColumnDef } from "../coverage/common/azureNetSavingsColumnDef";
import { azureTotalCoverageColumnDef } from "../coverage/common/azureTotalCoverageColumnDef";

export const azureSavingsPlanGroupedByAccountColumnDefs: ColDef<AzureSavingsPlanCoverageDataWithId>[] =
  [
    {
      field: "account.name",
      headerName: "Subscription",
      suppressKeyboardEvent: () => true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      width: 250,
      minWidth: 250,

      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => {
        if (!data) {
          return;
        }

        return (
          <Stack>
            <Typography variant="body2">{data?.account?.name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {data?.account?.provider_account_id}
            </Typography>
          </Stack>
        );
      },
      valueGetter: ({ data }) =>
        `${data?.account?.name ?? ""} ${data?.account?.provider_account_id ?? ""}`,
    },
    azureNetSavingsColumnDef,
    ...getAzureSavingsPlanCommonColumnDefs("account"),
    azureTotalCoverageColumnDef,
    agGridEmptyColumnToFitEmptySpace,
  ];
