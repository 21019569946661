import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { azureReservationDrawerDataGridColumnDefs } from "./columns/azureReservationDrawerDataGridColumnDefs";
import { DataViewTab } from "../../../../../../../../../store/commitments/utils/types/types";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "../../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerCoverageGroupingSelector";
import { azureReservationDetailsCoverageDataSelector } from "../../../../../../../../../store/commitments/selectors/azure/reservations/details-drawer/data/azureReservationDetailsCoverageDataSelector";
import { azureReservationDetailsLoadingSelector } from "../../../../../../../../../store/commitments/selectors/azure/reservations/details-drawer/data/azureReservationDetailsLoadingSelector";
import { CommitmentsGridLoadingLayer } from "../../../../../../common/components/data-grid/CommitmentsGridLoadingLayer";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";

import { CommitmentsDrawerAGGridToolbar } from "../../../../../../common/components/drawer/data-grid/CommitmentsDrawerAGGridToolbar";
import { CommitmentsAgGrid } from "../../../../../../common/components/CommitmentsAgGrid";

interface AzureReservationDrawerDataGridProps {
  viewTab: DataViewTab;
}

export const AzureReservationDrawerDataGrid: FC<
  AzureReservationDrawerDataGridProps
> = ({ viewTab }) => {
  const data = useAppSelector(azureReservationDetailsCoverageDataSelector);
  const loading = useAppSelector(azureReservationDetailsLoadingSelector);
  const grouping = useAppSelector(
    commitmentsDetailsDrawerCoverageGroupingSelector,
  );
  const targetType = useAppSelector(commitmentsDetailsDrawerTargetTypeSelector);

  const columnDefs = useMemo(() => {
    if (!targetType) {
      return [];
    }
    return (
      azureReservationDrawerDataGridColumnDefs?.[targetType]?.[viewTab]?.[
        grouping
      ] ?? []
    );
  }, [grouping, viewTab, targetType]);

  if (!columnDefs) {
    return null;
  }

  return (
    <Stack mt={1.5} position="relative" zIndex={1} height={400}>
      {loading && <CommitmentsGridLoadingLayer />}

      <CommitmentsAgGrid
        Toolbar={CommitmentsDrawerAGGridToolbar}
        loading={loading}
        columnDefs={columnDefs}
        data={data}
        grouping={grouping}
        localStorageUniqueKey={`azureReservationDrawerDataGrid-${targetType}-${viewTab}`}
      />
    </Stack>
  );
};
