import { FC, Fragment, useCallback } from "react";
import { List } from "@mui/material";
import { LiveUsageMgmtNestedNavItem } from "./LiveUsageMgmtNestedNavItem";
import { LiveUsageMgmtNavItemMain } from "./LiveUsageMgmtNavItemMain";
import { LiveUsageCollapsedNavItem } from "./LiveUsageCollapsedNavItem";
import { IconByResourceType } from "../../../../../../common/IconByResourceType";
import {
  ProviderType,
  ResourceType,
} from "../../../../../../../../services/cloudchipr.api";
import {
  LiveUsageNavigationResourceType,
  useLiveUsageMgmtPageNavigate,
} from "../../../utils/hooks/useLiveUsageMgmtPageNavigate.hook";

interface LiveUsageResourceNavItemCollapsedProps {
  resourceType: ResourceType;
  provider: ProviderType;
  actionableResourceTypes: ResourceType[];
  selected?: boolean;
  expandable?: boolean;
  selectedResourceType?: LiveUsageNavigationResourceType;
  parentTotalCost: number;
  parentFilteredCost: number;
  filterTemplatesEnabled: boolean;
}

export const LiveUsageResourceNavItemCollapsed: FC<
  LiveUsageResourceNavItemCollapsedProps
> = ({
  resourceType,
  selected,
  provider,
  expandable,
  actionableResourceTypes,
  selectedResourceType,
  parentTotalCost,
  parentFilteredCost,
  filterTemplatesEnabled,
}) => {
  const handleNavigationClick = useLiveUsageMgmtPageNavigate();

  const navigateHandler = useCallback(() => {
    handleNavigationClick(resourceType);
  }, [handleNavigationClick, resourceType]);

  return (
    <LiveUsageCollapsedNavItem
      onClick={navigateHandler}
      icon={
        <IconByResourceType resourceType={resourceType} primary={selected} />
      }
      selected={selected}
    >
      <Fragment>
        <LiveUsageMgmtNavItemMain
          resourceType={resourceType}
          provider={provider}
          expandable={expandable}
          clickable={!expandable}
          filterTemplatesEnabled={filterTemplatesEnabled}
          parentTotalCost={parentTotalCost}
          parentFilteredCost={parentFilteredCost}
        />
        {expandable && (
          <List dense>
            {actionableResourceTypes.map((rt) => (
              <LiveUsageMgmtNestedNavItem
                resourceType={rt}
                key={rt}
                selected={selectedResourceType === rt}
              />
            ))}
          </List>
        )}
      </Fragment>
    </LiveUsageCollapsedNavItem>
  );
};
