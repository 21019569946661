import { FC } from "react";
import { FormikHandlers, FormikHelpers } from "formik";
import { getStatisticsFilterMinMax } from "./utils/helpers/getStatisticsFilterMinMax";
import { StatisticsFilter } from "./filter-by-type/statistic-filter/StatisticsFilter";
import { SmartTagFilter } from "./filter-by-type/SmartTagFilter";
import { TagFilter } from "./filter-by-type/tag-filter/TagFilter";
import { CreatedByFilter } from "./filter-by-type/autocomplete-filters/filters/CreatedByFilter";
import { RelativeTimeFilter } from "./filter-by-type/RelativeTimeFilter";
import { DateSelectionFilter } from "./filter-by-type/DateSelectionFilter";
import { UniqueIdFilter } from "./filter-by-type/UniqueIdFilter";
import { SimpleValueFilter } from "./filter-by-type/SimpleValueFilter";
import { AzResourcesGroupFilter } from "./filter-by-type/autocomplete-filters/filters/AzResourceGroupFilter";
import { InstanceTypeFilter } from "./filter-by-type/autocomplete-filters/filters/InstanceTypeFilter";
import { InstanceFamilyFilter } from "./filter-by-type/autocomplete-filters/filters/InstanceFamilyFilter";
import { UnusedSinceFilter } from "./filter-by-type/UnusedSinceFilter";
import { StorageTypeFilter } from "./filter-by-type/autocomplete-filters/filters/StorageTypeFilter";
import { SnapshotTypeFilter } from "./filter-by-type/autocomplete-filters/filters/SnapshotTypeFilter";
import { NameFilter } from "./filter-by-type/autocomplete-filters/filters/NameFilter";
import { SourceNameFilter } from "./filter-by-type/autocomplete-filters/filters/SourceNameFilter";
import { SnapshotIdFilter } from "./filter-by-type/autocomplete-filters/filters/SnapshotIdFilter";
import { VolumeIdFilter } from "./filter-by-type/autocomplete-filters/filters/VolumeIdFilter";
import { StorageTierFilter } from "./filter-by-type/autocomplete-filters/filters/StorageTierFilter";
import { SnapshotStatusFilter } from "./filter-by-type/autocomplete-filters/filters/SnapshotStatusFilter";
import { LabelFilter } from "./filter-by-type/LabelFilter";
import { DynamoDbTableStatusFilter } from "./filter-by-type/autocomplete-filters/filters/DynamoDbTableStatusFilter";
import { CapacityModeFilter } from "./filter-by-type/autocomplete-filters/filters/CapacityModeFilter";
import { TableNamesFilter } from "./filter-by-type/autocomplete-filters/filters/TableNamesFilter";
import { ProtectedByFilter } from "./filter-by-type/autocomplete-filters/filters/ProtectedByFilter";
import { IpAddressesFilter } from "./filter-by-type/autocomplete-filters/filters/IpAddressesFilter";
import { VpcFilter } from "./filter-by-type/autocomplete-filters/filters/VpcFilter";
import { NatGatewayIdFilter } from "./filter-by-type/autocomplete-filters/filters/NatGatewayIdFilter";
import { CapacityProvidersFilter } from "./filter-by-type/autocomplete-filters/filters/CapacityProvidersFilter";
import { SupportTypeFilter } from "./filter-by-type/autocomplete-filters/filters/SupportTypeFilter";
import { EbsOptimizedFilter } from "./filter-by-type/autocomplete-filters/filters/EbsOptimizedFilter";
import { SchemeFilter } from "./filter-by-type/autocomplete-filters/filters/SchemeFilter";
import { PublicAccessFilter } from "./filter-by-type/autocomplete-filters/filters/PublicAccessFilter";
import { TaskStatusFilter } from "./filter-by-type/autocomplete-filters/filters/TaskStatusFilter";
import { DynamoDbBackupClassesFilter } from "./filter-by-type/autocomplete-filters/filters/DynamoDbBackupClassesFilter";
import { RegionFilter } from "./filter-by-type/autocomplete-filters/filters/RegionFilter";
import { dateFilterTypes } from "../utils/constants/filter-types/date-filter-types";
import { durationFilterTypes } from "../utils/constants/filter-types/duration-filter-types";
import { statisticsFilterTypes } from "../utils/constants/filter-types/statistics-filter-types";
import { isSmartTag } from "../utils/helpers/isSmartTag";
import {
  FilterItem,
  FilterItemTemplate,
  FilterItemWithType,
  ResourceType,
} from "../../../../../services/cloudchipr.api";
import { instanceTypeFilterTypes } from "../utils/constants/filter-types/instance-type-filter-types";

interface FilterBodyByTypeProps {
  error?: any;
  accountIds?: string[];
  currentFilter: FilterItemWithType;
  resourceType: ResourceType;
  filters: FilterItemTemplate[];
  filterTemplate: FilterItemTemplate;
  onChange: FormikHandlers["handleChange"];
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
}

export const FilterBodyByType: FC<FilterBodyByTypeProps> = ({
  filters,
  currentFilter,
  filterTemplate,
  resourceType,
  accountIds,
  onChange,
  setFieldValue,
  error,
}) => {
  const filterType = currentFilter.filter.key;

  const filter = currentFilter.filter;
  //TODO: have logic depend on resourceType
  if (statisticsFilterTypes.has(filterType)) {
    const { min, max } = getStatisticsFilterMinMax(filterTemplate);
    return (
      <StatisticsFilter
        min={min}
        max={max}
        error={error}
        onChange={onChange}
        filter={filter}
        resourceType={resourceType}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
        filterType={filterType}
      />
    );
  }

  if (isSmartTag(filterType)) {
    return (
      <SmartTagFilter
        error={error}
        filters={filters}
        onChange={onChange}
        filter={filter}
        resourceType={resourceType}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "task_status") {
    return (
      <TaskStatusFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "region") {
    return (
      <RegionFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "capacity_mode") {
    return (
      <CapacityModeFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }
  if (filterType === "table_class") {
    return (
      <DynamoDbBackupClassesFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "capacity_provider") {
    return (
      <CapacityProvidersFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "protected_by") {
    return (
      <ProtectedByFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "tag") {
    return (
      <TagFilter
        error={error}
        onChange={onChange}
        setFieldValue={setFieldValue}
        filter={filter}
        operators={filterTemplate.operators}
        accountIds={accountIds}
        resourceType={resourceType}
      />
    );
  }

  if (filterType === "label") {
    return (
      <LabelFilter
        error={error}
        onChange={onChange}
        filter={filter}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "creator") {
    return (
      <CreatedByFilter
        error={error}
        onChange={onChange}
        filter={filter}
        accountIds={accountIds}
        resourceType={resourceType}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "unused_since") {
    return (
      <UnusedSinceFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "resource_group") {
    return (
      <AzResourcesGroupFilter
        error={error}
        onChange={onChange}
        filter={filter}
        accountIds={accountIds}
        resourceType={resourceType}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }
  if (filterType === "nat_gateway_id") {
    return (
      <NatGatewayIdFilter
        error={error}
        onChange={onChange}
        filter={filter}
        accountIds={accountIds}
        resourceType={resourceType}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (instanceTypeFilterTypes.has(filterType)) {
    return (
      <InstanceTypeFilter
        error={error}
        onChange={onChange}
        filter={filter}
        accountIds={accountIds}
        resourceType={resourceType}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }
  if (filterType === "vpc") {
    return (
      <VpcFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "storage_type") {
    return (
      <StorageTypeFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "source_name") {
    return (
      <SourceNameFilter
        error={error}
        onChange={onChange}
        filter={filter}
        accountIds={accountIds}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "type") {
    return (
      <SnapshotTypeFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "name") {
    return (
      <NameFilter
        error={error}
        onChange={onChange}
        filter={filter}
        accountIds={accountIds}
        resourceType={currentFilter.type}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }
  if (filterType === "table") {
    return (
      <TableNamesFilter
        error={error}
        onChange={onChange}
        filter={filter}
        accountIds={accountIds}
        resourceType={currentFilter.type}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }
  if (filterType === "volume_id") {
    return (
      <VolumeIdFilter
        error={error}
        onChange={onChange}
        filter={filter}
        accountIds={accountIds}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }
  if (filterType === "storage_tier") {
    return (
      <StorageTierFilter
        error={error}
        onChange={onChange}
        filter={filter}
        resourceType={resourceType}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }
  if (filterType === "support_type") {
    return (
      <SupportTypeFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }
  if (filterType === "ebs_optimized") {
    return (
      <EbsOptimizedFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        resourceType={resourceType}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "scheme") {
    return (
      <SchemeFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        resourceType={resourceType}
        operators={filterTemplate.operators}
      />
    );
  }
  if (filterType === "support_type_expiration") {
    return (
      <SimpleValueFilter
        error={error}
        onChange={onChange}
        filter={filter}
        operators={filterTemplate.operators}
        label="Days"
        type="number"
        minNumberValue={0}
      />
    );
  }
  if (filterType === "ip_addresses") {
    return (
      <IpAddressesFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }
  if (filterType === "snapshot_status") {
    return (
      <SnapshotStatusFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }
  if (filterType === "instance_family") {
    return (
      <InstanceFamilyFilter
        error={error}
        onChange={onChange}
        filter={filter}
        resourceType={resourceType}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "snapshot_id") {
    return (
      <SnapshotIdFilter
        error={error}
        onChange={onChange}
        filter={filter}
        accountIds={accountIds}
        resourceType={resourceType}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "public_access") {
    return (
      <PublicAccessFilter
        error={error}
        onChange={onChange}
        filter={filter}
        resourceType={resourceType}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (durationFilterTypes.has(filterType)) {
    return (
      <RelativeTimeFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (dateFilterTypes.has(filterType)) {
    return (
      <DateSelectionFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "provider_unique_identifier" && accountIds) {
    return (
      <UniqueIdFilter
        error={error}
        onChange={onChange}
        filter={filter}
        accountIds={accountIds}
        resourceType={resourceType}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (
    filterType === "status" &&
    (resourceType === "dynamo_db_table" ||
      resourceType === "dynamo_db_backup_source")
  ) {
    return (
      <DynamoDbTableStatusFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  return (
    <SimpleValueFilter
      error={error}
      onChange={onChange}
      filter={filter}
      operators={filterTemplate.operators}
      type={filterTemplate.value_type === "number" ? "number" : "text"}
    />
  );
};
