import { updateTaskCommentLoadingSelector } from "./updateTaskCommentLoadingSelector";
import { createTaskCommentLoadingSelector } from "./createTaskCommentLoadingSelector";
import { RootState } from "../../../../store";

export const sendTaskCommentLoadingSelector = (state: RootState) => {
  const updateLoading = updateTaskCommentLoadingSelector(state);
  const createLoading = createTaskCommentLoadingSelector(state);

  return updateLoading || createLoading;
};
