import { FC } from "react";
import { Stack } from "@mui/material";
import { ResourceDetailsHeaderK8SMetaBar } from "./ResourceDetailsHeaderK8SMetaBar";
import { useAppSelector } from "../../../../../../store/hooks";
import { resourceDetailsTargetResourceSelector } from "../../../../../../store/resource-details/selectors/target-resource/resourceDetailsTargetResourceSelector";
import { KeyValueSplitChip } from "../../../../../common/chips/KeyValueSplitChip";
import { getResourceTypeName } from "../../../../../../utils/helpers/resources/getResourceTypeName";

export const ResourceDetailsHeaderMetaBar: FC = () => {
  const resource = useAppSelector(resourceDetailsTargetResourceSelector);

  if (!resource) {
    return null;
  }

  if (resource.providerMetadata?.k8s) {
    return <ResourceDetailsHeaderK8SMetaBar />;
  }

  return (
    <Stack spacing={1} direction="row" px={1.5} py={1}>
      <KeyValueSplitChip
        title="Account"
        value={resource.providerAccountName || resource.providerAccountId}
      />

      <KeyValueSplitChip title="Region" value={resource.region} />

      {resource.resourceType && (
        <KeyValueSplitChip
          title="Service"
          value={getResourceTypeName(resource.resourceType, {
            type: "long",
            service: true,
          })}
        />
      )}
    </Stack>
  );
};
