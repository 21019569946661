import { ColDef } from "ag-grid-community";
import { reservationGroupedByResourceTypeColumnDefs } from "./reservation/reservationGroupedByResourceTypeColumnDefs";
import { reservationGroupedByAccountColumnDefs } from "./reservation/reservationGroupedByAccountColumnDefs";
import { reservationCoverageGroupedByAccountColumnDefs } from "./coverage/reservationCoverageGroupedByAccountColumnDefs";
import { reservationCoverageGroupedByCommitmentColumnDefs } from "./coverage/reservationCoverageGroupedByCommitmentColumnDefs";
import { reservationCoverageGroupedByResourceTypeColumnDefs } from "./coverage/reservationCoverageGroupedByResourceTypeColumnDefs";
import { ReservationsCoverageDataWithId } from "../../../../../../common/utils/types";
import { DataViewTab } from "../../../../../../../../../store/commitments/utils/types/types";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../services/cloudchipr.api";

type Columns = Partial<
  Record<SavingsPlanCoverageGrouping, ColDef<ReservationsCoverageDataWithId>[]>
>;

export const reservationDrawerDataGridColumnDefs: Partial<
  Record<DataViewTab, Columns>
> = {
  commitment: {
    resource_type: reservationGroupedByResourceTypeColumnDefs,
    account: reservationGroupedByAccountColumnDefs,
  },
  coverage: {
    account: reservationCoverageGroupedByAccountColumnDefs,
    commitment: reservationCoverageGroupedByCommitmentColumnDefs,
    resource_type: reservationCoverageGroupedByResourceTypeColumnDefs,
  },
};
