import { darken, lighten } from "@mui/material";
import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepPurple,
  grey,
  indigo,
  orange,
  pink,
  teal,
  yellow,
} from "@mui/material/colors";

const defaultColors = [
  blue[400],
  teal[300],
  deepPurple[300],
  amber[400],
  pink[300],
  amber[700],
  grey[400],
  pink["A700"],
  pink[200],
  brown[300],
  indigo[400],
  brown[500],
  teal[500],
  teal[200],
  cyan[700],
  yellow[700],
  orange[500],
  blueGrey[800],
  blue["A100"],
  blue["A700"],
  pink[900],
];

export const highChartColors = Array(5)
  .fill(1)
  .reduce((colors, _, index) => {
    defaultColors.forEach((color, i) => {
      const fn = i % 2 === 0 ? darken : lighten;

      colors.push(fn(color, index * 0.2));
    });

    return colors;
  }, [] as string[]);
