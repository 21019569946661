import { FC } from "react";
import { Card, Divider, LinearProgress } from "@mui/material";
import { CostAnomaliesWidgetSetupHeader } from "./components/CostAnomaliesWidgetSetupHeader";
import { CostAnomaliesWidgetBody } from "./components/CostAnomaliesWidgetBody";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { costAnomaliesSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/costAnomaliesSetupPropertyByKeySelector";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";
import { CostAnomaliesWidgetFooter } from "../../../../../widgets/cost-anomalies/components/footer/CostAnomaliesWidgetFooter";
import { WidgetSetupContentWrapper } from "../../common/WidgetSetupContentWrapper";
import { costAnomaliesSetupWidgetDataLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/data/costAnomaliesSetupWidgetDataLoadingSelector";
import { costAnomaliesSetupWidgetDataTotalSelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/data/costAnomaliesSetupWidgetDataTotalSelector";
import { costAnomaliesSetupPrevPeriodCostDateDetailsSelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/data/costAnomaliesSetupPrevPeriodCostDateDetailsSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { costAnomaliesSetupSelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/costAnomaliesSetupSelector";

export const CostAnomaliesWidgetSetupContent: FC = () => {
  const loading = useAppSelector(costAnomaliesSetupWidgetDataLoadingSelector);
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const data = useAppSelector(costAnomaliesSetupWidgetDataTotalSelector);
  const widget = useAppSelector(costAnomaliesSetupSelector);

  const prevDate = useAppSelector(
    costAnomaliesSetupPrevPeriodCostDateDetailsSelector,
  );

  const date = useAppSelector(costAnomaliesSetupPropertyByKeySelector("dates"));

  return (
    <WidgetSetupContentWrapper>
      <Card elevation={3} sx={cardSx}>
        {loading && <LinearProgress />}

        <CostAnomaliesWidgetSetupHeader />

        <Divider />

        <CostAnomaliesWidgetBody
          date={date}
          data={data ?? []}
          loading={loading}
          viewId={widget?.viewId}
          grouping={widget?.grouping}
          previousPeriodDate={prevDate}
          groupValues={widget?.group_values ?? undefined}
        />

        {!loading && !!data?.length && (
          <CostAnomaliesWidgetFooter
            quarterStartMonth={quarterStartMonth}
            prevDate={prevDate}
            date={date}
          />
        )}
      </Card>
    </WidgetSetupContentWrapper>
  );
};

const cardSx = {
  height: widgetSetupContentChartHeight,
  display: "flex",
  flexDirection: "column",
};
