import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { AzureUtilizationAndCoverageMetadata } from "./components/metadata/AzureUtilizationAndCoverageMetadata";
import { AzureUtilizationAndCoverageContent } from "./components/content/AzureUtilizationAndCoverageContent";
import { AzureUtilizationAndCoverageDrawers } from "./components/drawers/AzureUtilizationAndCoverageDrawers";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { commitmentsCurrentOrgIdSelector } from "../../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { getAzureSavingsPlansThunk } from "../../../../../store/commitments/thunks/azure/saving-plans/getAzureSavingsPlansThunk";

export const AzureUtilizationAndCoverage: FC = () => {
  const dispatch = useAppDispatch();

  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);

  useEffect(() => {
    dispatch(getAzureSavingsPlansThunk());
  }, [dispatch, orgId]);

  return (
    <Stack spacing={1} flex={1}>
      <AzureUtilizationAndCoverageMetadata />
      <AzureUtilizationAndCoverageContent />

      <AzureUtilizationAndCoverageDrawers />
    </Stack>
  );
};
