import { FC } from "react";
import { ResourceDetailsCostsTable } from "../../common/table/ResourceDetailsCostsTable";
import { resourceDetailsGeneralTabBillingGranularDataSelector } from "../../../../../../../../store/resource-details/selectors/general-tab/grid-data/resourceDetailsGeneralTabBillingGranularDataSelector";
import { resourceDetailsGeneralTabBillingGridDataSelector } from "../../../../../../../../store/resource-details/selectors/general-tab/grid-data/resourceDetailsGeneralTabBillingGridDataSelector";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { resourceDetailsGroupBySelector } from "../../../../../../../../store/resource-details/selectors/options/resourceDetailsGroupBySelector";
import { resourceDetailsGeneralTabProcessKey } from "../../../../../../../../store/resource-details/utils/constants/processesKeys";

export const ResourceDetailsGeneralContentGrid: FC = () => {
  const data = useAppSelector(
    resourceDetailsGeneralTabBillingGranularDataSelector,
  );
  const gridData = useAppSelector(
    resourceDetailsGeneralTabBillingGridDataSelector,
  );
  const groupBy = useAppSelector((state) =>
    resourceDetailsGroupBySelector(state, resourceDetailsGeneralTabProcessKey),
  );

  return (
    <ResourceDetailsCostsTable
      groupBy={groupBy}
      gridData={gridData}
      columns={data?.columns}
    />
  );
};
