import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Stack, Typography } from "@mui/material";
import { ReservationsCoverageDataWithId } from "../../../../../../../common/utils/types";
import { getCoveredUsageColumnDef } from "../coverage/common/getCoveredUsageColumnDef";
import { netSavingsColumnDef } from "../coverage/common/netSavingsColumnDef";
import { getCoverageColumnDef } from "../coverage/common/getCoverageColumnDef";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { totalCoverageColumnDef } from "../coverage/common/totalCoverageColumnDef";

export const reservationGroupedByAccountColumnDefs: ColDef<ReservationsCoverageDataWithId>[] =
  [
    {
      flex: 1.5,
      field: "account",
      headerName: "Account",
      minWidth: 200,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
        <Stack>
          <Typography variant="body2">{data?.account?.name ?? ""}</Typography>
          <Typography variant="body2" color="text.secondary">
            {data?.account?.provider_account_id ?? ""}
          </Typography>
        </Stack>
      ),
      valueGetter: ({ data }) =>
        `${data?.account?.name ?? ""} ${data?.account?.provider_account_id ?? ""}`,
    },

    getCoveredUsageColumnDef("account"),

    netSavingsColumnDef,

    getCoverageColumnDef("account"),

    totalCoverageColumnDef,

    agGridEmptyColumnToFitEmptySpace,
  ];
