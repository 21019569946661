import { FC, useCallback } from "react";
import { IconButton, Stack } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  EmailsSelectionIntegrationsSelect,
  EmailsSelectionIntegrationsSelectProps,
} from "./EmailsSelectionIntegrationsSelect";
import {
  EmailsSelection,
  EmailsSelectionErrors,
} from "../../../../../common/integration-dialogs/components/email/components/email-selection/EmailsSelection";
import { Emails } from "../../../../../../services/cloudchipr.api";
import {
  NotificationCustomMessage,
  NotificationCustomMessageConfig,
} from "../custom-message/NotificationCustomMessage";

export interface NotificationsEmailSelectionProps
  extends EmailsSelectionIntegrationsSelectProps {
  emailsErrors?: EmailsSelectionErrors;
  selectedEmails: Emails | null;
  customMessageConfigs?: NotificationCustomMessageConfig;
}

export const NotificationsEmailSelection: FC<
  NotificationsEmailSelectionProps
> = ({ emailsErrors, selectedEmails, customMessageConfigs, ...props }) => {
  const { emailsChangeHandler } = props;

  const deleteEmailsHandler = useCallback(() => {
    emailsChangeHandler(null);
  }, [emailsChangeHandler]);

  const customMessageChangeHandler = useCallback(
    (message: string) => {
      if (selectedEmails) {
        emailsChangeHandler({
          ...selectedEmails,
          custom_message: message ?? "",
        });
      }
    },
    [emailsChangeHandler, selectedEmails],
  );

  if (!selectedEmails) {
    return null;
  }

  return (
    <Stack direction="row" spacing={2} mt={2} alignItems="start">
      <Stack flexGrow={1} gap={2}>
        <EmailsSelectionIntegrationsSelect {...props} />

        <Stack spacing={2}>
          <EmailsSelection
            errors={emailsErrors}
            toEmails={selectedEmails.to}
            ccEmails={selectedEmails.cc}
            bccEmails={selectedEmails.bcc}
            onEmailsChange={emailsChangeHandler}
          />

          {customMessageConfigs && (
            <NotificationCustomMessage
              type="email"
              onMessageChange={customMessageChangeHandler}
              message={selectedEmails?.custom_message ?? ""}
              {...customMessageConfigs}
            />
          )}
        </Stack>
      </Stack>

      <IconButton onClick={deleteEmailsHandler}>
        <DeleteOutlineIcon />
      </IconButton>
    </Stack>
  );
};
