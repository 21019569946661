import { accountsByProviderSelector } from "./all-providers/accountsByProviderSelector";
import { RootState } from "../../store";
import { ProviderType } from "../../../services/cloudchipr.api";

export const accountsByProviderAndOrgIdSelector = (
  state: RootState,
  provider?: ProviderType,
  orgId?: string,
) => {
  if (!orgId || !provider) {
    return null;
  }

  const allAccountsGroupedByProviderAndOrg = accountsByProviderSelector(
    state,
    provider,
  );

  return allAccountsGroupedByProviderAndOrg?.[orgId] ?? [];
};
