import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { allAccountsGroupedByProviderAndOrgSelector } from "./allAccountsGroupedByProviderAndOrgSelector";
import { kubernetesClustersSelector } from "../kubernetes/kubernetesClustersSelector";
import { noOrgProviderOrganisationOrgId } from "../../../commitments/selectors/organisations/utils/constants";
import {
  Account,
  KubernetesCluster,
  ProviderType,
} from "../../../../services/cloudchipr.api";

type ProviderWithOrgsAndAccounts = {
  provider: ProviderType;
  organizations: string[];
  accounts: (Account | KubernetesCluster)[];
};

export const allAccountsGroupedByProviderAndOrgFlatSelector =
  createDraftSafeSelector(
    [allAccountsGroupedByProviderAndOrgSelector, kubernetesClustersSelector],
    (accounts, clusters) => {
      if (!accounts) {
        return;
      }

      const providersWithOrgs: ProviderWithOrgsAndAccounts[] = Object.entries(
        accounts,
      ).map(([provider, organizations]) => ({
        provider: provider as ProviderType,
        organizations: Object.keys(organizations),
        accounts: Object.values(organizations)
          .flat()
          .filter((account) => account.status === "connected"),
      }));

      if (clusters) {
        providersWithOrgs.push({
          provider: "kubernetes",
          organizations: [noOrgProviderOrganisationOrgId],
          accounts: clusters,
        });
      }

      return providersWithOrgs;
    },
  );
