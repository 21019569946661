import { FC, Fragment, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { costAnomaliesSetupPropertyByKeySelector } from "../../../../../../../store/dashboards/selectors/setups/cost-anomalies/costAnomaliesSetupPropertyByKeySelector";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { costAnomaliesREViewChangeThunk } from "../../../../../../../store/dashboards/thunks/widgets/cost-anomalies/widget-change/costAnomaliesREViewChangeThunk";
import { WidgetsViewsHierarchySelect } from "../../../adding-widget/widget-create/widget-setups/common/widgets-views-hierarchy-select/WidgetsViewsHierarchySelect";
import { CostAnomaliesGroupingSelect } from "../../../adding-widget/widget-create/widget-setups/cost-anomalies/app-bar/CostAnomaliesGroupingSelect";
import { CostAnomaliesDateRangeSelect } from "../../../adding-widget/widget-create/widget-setups/cost-anomalies/app-bar/CostAnomaliesDateRangeSelect";

export const CostAnomaliesEditPopoverContent: FC = () => {
  const dispatch = useAppDispatch();

  const viewId = useAppSelector(
    costAnomaliesSetupPropertyByKeySelector("viewId"),
  );
  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );

  const viewChangeHandler = useCallback(
    (id: string) => {
      dispatch(costAnomaliesREViewChangeThunk(id));
    },
    [dispatch],
  );

  return (
    <Fragment>
      <WidgetsViewsHierarchySelect
        selectedItemId={viewId}
        onChange={viewChangeHandler}
      />

      <CostAnomaliesGroupingSelect disabled={viewGettingLoading} />

      <CostAnomaliesDateRangeSelect />
    </Fragment>
  );
};
