import { ChangeEvent, FC, Fragment, memo, useCallback } from "react";
import { FormikHandlers } from "formik";
import { SelectChangeEvent, TextField } from "@mui/material";
import {
  AutocompleteFilterBody,
  AutocompleteFilterBodyProps,
} from "../../../filters-selection/filter-by-type/autocomplete-filters/AutocompleteFilterBody";
import { FilterItemOperatorType } from "../../../filters-selection/FilterItemOperatorType";
import {
  FilterItem,
  Operator,
  ResourceType,
} from "../../../../../../../services/cloudchipr.api";
import { DropdownSelectOption } from "../../../../../../common/select/dropdown-select/utils/types/types";
import { filterFreeSoloOperators } from "../../../utils/constants/filter-types/filter-free-solo-operators";

interface FilterWithOperatorAndAutocompleteProps
  extends AutocompleteFilterBodyProps {
  accountIds?: string[];
  resourceType: ResourceType;
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
  options: DropdownSelectOption[];
  loading: boolean;
}

export const FilterWithOperatorAndAutocomplete: FC<FilterWithOperatorAndAutocompleteProps> =
  memo(
    ({
      resourceType,
      accountIds,
      operators,
      filter,
      onChange,
      options,
      loading,
      setFieldValue,
      ...props
    }) => {
      const inputChangeHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          setFieldValue("value", event.target.value);
        },
        [setFieldValue],
      );

      const operatorChangeHandler = useCallback(
        (event: SelectChangeEvent) => {
          setFieldValue("value", null);
          onChange(event);
        },
        [setFieldValue, onChange],
      );

      return (
        <Fragment>
          <FilterItemOperatorType
            onChange={operatorChangeHandler}
            options={operators}
            value={filter.operator || "in"}
          />
          {filterFreeSoloOperators.has(filter.operator) ? (
            <TextField
              fullWidth
              error={
                typeof filter?.value === "string"
                  ? !filter?.value?.trim()?.length
                  : true
              }
              label="Value"
              size="xsmall"
              value={typeof filter?.value === "string" ? filter.value : ""}
              onChange={inputChangeHandler}
            />
          ) : (
            <AutocompleteFilterBody
              {...props}
              setFieldValue={setFieldValue}
              freeSolo={filterFreeSoloOperators.has(filter.operator)}
              options={options ?? []}
              isLoading={loading}
              label="Select values"
              selectedValue={Array.isArray(filter?.value) ? filter.value : []}
            />
          )}
        </Fragment>
      );
    },
  );
