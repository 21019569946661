import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import {
  HeaderCellWidthDateTooltip,
  HeaderCellWidthDateTooltipProps,
} from "../../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { commitmentsTotalsSelector } from "../../../../../../../../../store/commitments/selectors/common/commitmentsTotalsSelector";
import { money } from "../../../../../../../../../utils/numeral/money";

interface UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDateProps
  extends HeaderCellWidthDateTooltipProps {
  type: "netSavings" | "coveredUsage" | "unCoveredUsage";
}

export const UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDate: FC<
  UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDateProps
> = ({ type, ...props }) => {
  const totals = useAppSelector(commitmentsTotalsSelector);

  return (
    <Stack alignItems="start" flex={1} spacing={0.5}>
      <HeaderCellWidthDateTooltip {...props} />

      <Typography variant="caption" color="text.secondary">
        {money(totals?.[type] ?? 0)}
      </Typography>
    </Stack>
  );
};
