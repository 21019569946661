import { FC } from "react";
import { CommitmentsOverviewMetadataRightSide } from "./common/CommitmentsOverviewMetadataRightSide";
import { useAppSelector } from "../../../../../../store/hooks";
import { overviewLoadingSelector } from "../../../../../../store/commitments/selectors/overview/loading/overviewLoadingSelector";
import { overviewTotalNetSavingsSelector } from "../../../../../../store/commitments/selectors/overview/overviewTotalNetSavingsSelector";
import { overviewTotalCoverageSelector } from "../../../../../../store/commitments/selectors/overview/overviewTotalCoverageSelector";
import { overviewTotalUtilization } from "../../../../../../store/commitments/selectors/overview/overviewTotalUtilizationSelector";

export const AwsCommitmentsOverviewMetadataRightSide: FC = () => {
  const totalNetSavings = useAppSelector(overviewTotalNetSavingsSelector);
  const totalCoverage = useAppSelector(overviewTotalCoverageSelector);
  const totalUtilization = useAppSelector(overviewTotalUtilization);
  const loading = useAppSelector(overviewLoadingSelector);

  return (
    <CommitmentsOverviewMetadataRightSide
      totalNetSavings={totalNetSavings}
      totalCoverage={totalCoverage}
      totalUtilization={totalUtilization}
      loading={loading}
      awsPotentialSavings
    />
  );
};
