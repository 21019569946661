import { FC, useCallback, useMemo } from "react";
import { Button, Menu, Stack } from "@mui/material";
import { GridApi } from "ag-grid-community";
import { AgGridFilterChip } from "./AgGridFilterChip";
import { getHeaderNameByColId } from "./utils/helpers/getHeaderNameByColId";

interface TaskManagementAllFiltersMenuProps {
  open: boolean;
  onClose(): void;
  filterNames: string[];
  gridApi: GridApi | null;
  onClearAllFilters(): void;
  anchor: HTMLElement | null;
}

export const AgGridAllFiltersMenu: FC<TaskManagementAllFiltersMenuProps> = ({
  open,
  anchor,
  onClose,
  gridApi,
  filterNames,
  onClearAllFilters,
}) => {
  const columnDefs = gridApi?.getColumnDefs();
  const columnHeaderNameByColId = useMemo(
    () => getHeaderNameByColId(columnDefs ?? []),
    [columnDefs],
  );

  const cleanAllFiltersHandler = useCallback(() => {
    onClose();
    onClearAllFilters();
  }, [onClearAllFilters, onClose]);

  const clearFilterByName = useCallback(
    (filterName: string) => {
      if (filterNames.length === 1) {
        onClose();
      }

      gridApi?.destroyFilter(filterName);
    },
    [gridApi, onClose, filterNames],
  );

  const chipClickHandler = useCallback(
    (filterName: string) => {
      if (!gridApi) return;

      onClose();

      gridApi.setColumnsVisible([filterName], true);

      gridApi.ensureColumnVisible(filterName);

      setTimeout(() => {
        const headerEl = document.querySelector(`[col-id="${filterName}"]`);
        if (headerEl) {
          const filterButton = headerEl.querySelector(
            agGridFilterButtonClass,
          ) as HTMLElement;

          if (filterButton) {
            filterButton.click();
          }
        }
      }, 100);
    },
    [gridApi, onClose],
  );

  return (
    <Menu open={open} onClose={onClose} anchorEl={anchor}>
      <Stack spacing={1} px={1} pt={0.5} alignItems="start" minWidth={150}>
        {filterNames.map((filterName) => (
          <AgGridFilterChip
            key={filterName}
            filterName={filterName}
            onChipClick={chipClickHandler}
            onClearFilterByName={clearFilterByName}
            columnHeaderNameByColId={columnHeaderNameByColId}
          />
        ))}

        <Button
          variant="text"
          size="small"
          color="tertiary"
          sx={buttonStyles}
          onClick={cleanAllFiltersHandler}
        >
          Clear All Filters
        </Button>
      </Stack>
    </Menu>
  );
};

const buttonStyles = { textTransform: "none", pt: 0.5 };
const agGridFilterButtonClass = ".ag-header-cell-filter-button";
