import { FC, useCallback } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { HeaderContext } from "@tanstack/table-core";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { blue } from "@mui/material/colors";
import { useDataGridContext } from "../../../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { metricCellFrequencyValues } from "../utils/constants";
import { getMetricFrequencyKey } from "../utils/helpers/helpers";

export const ResourceMetricsHeaderCell: FC<HeaderContext<any, any>> = ({
  column,
}) => {
  const context = useDataGridContext();
  const data = context?.cellSpecificMetadata?.data;
  const setData = context?.cellSpecificMetadata?.setData;
  const metricCellFrequencyKey = getMetricFrequencyKey(column.id);

  const value = data?.[metricCellFrequencyKey];
  const title = column.columnDef.meta?.headerTitle ?? "";
  const tooltip = column.columnDef.meta?.customHeaderTooltip ?? "";

  const changeHandler = useCallback(
    (e: any) => {
      setData((data) => ({
        ...data,
        [metricCellFrequencyKey]: e.target.value,
      }));
    },
    [setData, metricCellFrequencyKey],
  );

  if (!data || !setData) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="inherit">{title}</Typography>
      {tooltip && (
        <Tooltip arrow title={tooltip} placement="top">
          <HelpOutlineIcon
            fontSize="small"
            sx={{ verticalAlign: "middle", ml: 1, color: blue[500] }}
          />
        </Tooltip>
      )}
      <FormControl size="xsmall" focused={false}>
        <Select
          value={value ?? ""}
          sx={{ "& .MuiSelect-select": { pl: 0.5 } }}
          onChange={changeHandler}
        >
          {Object.keys(metricCellFrequencyValues).map((key) => {
            return (
              <MenuItem value={key} key={key}>
                {key}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
};
