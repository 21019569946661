import { taskManagementTargetOpportunitiesSelector } from "./taskManagementTargetOpportunitiesSelector";
import { RootState } from "../../../../../store";
import { OpportunityTargetRequest } from "../../../../../../services/cloudchipr.api";

export const taskManagementTargetOpportunitiesForPayloadSelector = (
  state: RootState,
): OpportunityTargetRequest["ids"] | undefined => {
  const opportunities = taskManagementTargetOpportunitiesSelector(state);

  return opportunities?.map(({ id }) => id) ?? [];
};
