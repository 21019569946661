import { FC, Fragment } from "react";
import { BillingExplorerExplainWithAiAnomaliesSpike } from "./BillingExplorerExplainWithAiAnomaliesSpike";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { billingExplorerSummaryAnomaliesSpikesSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/billingExplorerSummaryAnomaliesSpikesSelector";

interface BillingExplorerExplainWithAiAnomaliesSpikesProps {
  index: number;
}
export const BillingExplorerExplainWithAiAnomaliesSpikes: FC<
  BillingExplorerExplainWithAiAnomaliesSpikesProps
> = ({ index }) => {
  const spikes = useAppSelector((state) =>
    billingExplorerSummaryAnomaliesSpikesSelector(state, index),
  );

  if (!spikes) {
    return null;
  }

  return (
    <Fragment>
      {spikes?.map((item) => {
        return (
          <BillingExplorerExplainWithAiAnomaliesSpike
            key={item.explanation}
            {...item}
          />
        );
      })}
    </Fragment>
  );
};
