import {
  SavingsPlanCoverageGrouping,
  CommitmentFilterType,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";

type GroupingType = {
  value: SavingsPlanCoverageGrouping;
  riLabel: string;
  spLabel: string;
};

const resourceType: GroupingType = {
  value: "resource_type",
  spLabel: "Covered Resource Types",
  riLabel: "Covered Resources",
};
const account: GroupingType = {
  value: "account",
  spLabel: "Accounts",
  riLabel: "Accounts",
};

const getAccountGroups = (provider: ProviderType): GroupingType => {
  return {
    value: "account",
    spLabel: getProviderName(provider, {
      capitalize: true,
      plural: true,
    }),
    riLabel: getProviderName(provider, {
      capitalize: true,
      plural: true,
    }),
  };
};

const commitment: GroupingType = {
  value: "commitment",
  spLabel: "Savings Plans",
  riLabel: "Reservations",
};

export const getDrawerDataGridPossibleGroupings = (
  provider: ProviderType,
): Record<CommitmentFilterType, GroupingType[]> => {
  return {
    commitment: [resourceType, getAccountGroups(provider)],
    account: [resourceType, commitment],
    resource_type: [commitment, getAccountGroups(provider)],
    savings_plan: [],
    region: [resourceType, commitment, getAccountGroups(provider)],
  };
};
