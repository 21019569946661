import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { commitmentsGranularPayloadDataSelector } from "../../../selectors/common/payload/commitmentsGranularPayloadDataSelector";

export const getAzureReservationsForGranularThunk = createAsyncThunk(
  "commitments/getAzureReservationsForGranular",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentAzureReservations } =
      cloudChiprApi.endpoints;

    const payload = commitmentsGranularPayloadDataSelector(state);

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentAzureReservations.initiate(payload),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
