import { createAsyncThunk } from "@reduxjs/toolkit";
import { taskManagementUpdateTaskThunk } from "./taskManagementUpdateTaskThunk";

import { taskManagementTaskSavingPayloadSelector } from "../../selectors/form/payload/taskManagementTaskSavingPayloadSelector";
import { RootState } from "../../../store";
import { taskManagementTaskIdSelector } from "../../selectors/form/properties/taskManagementTaskIdSelector";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { taskEditSaveButtonDisabledSelector } from "../../selectors/edit/taskEditSaveButtonDisabledSelector";

export const taskManagementUpdateEditingTaskThunk = createAsyncThunk(
  "taskManagement/taskManagementUpdateEditingTask",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const isDisabled = taskEditSaveButtonDisabledSelector(state);
    const payload = taskManagementTaskSavingPayloadSelector(state);
    const id = taskManagementTaskIdSelector(state);

    if (!id || isDisabled) {
      return;
    }

    try {
      await dispatch(
        taskManagementUpdateTaskThunk({
          task_ids: [id],
          name: payload?.name,
          status_id: payload?.status_id ? payload?.status_id : null,
          priority_id: { value: payload?.priority_id ?? null },
          environment_id: { value: payload?.environment_id ?? null },
          owner_id: { value: payload?.owner_id ?? null },
          action_id: { value: payload?.action_id ?? null },
          due_date: { value: payload?.due_date ?? null },
          description: { value: payload?.description ?? null },
          assignees: { value: payload?.assignees ?? null },
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
