import { resourceGroupsByProvider } from "./resourceGroupsByProvider";
import { ResourceType } from "../../../services/cloudchipr.api";

export const resourceServices = new Set(
  Array.from(resourceGroupsByProvider.values())
    .flat()
    .map(({ value }) => value),
);

export const dbResourceTypes = new Set(["rds", "sql"]);

export const resourceTypesWithBillingDataCosts = new Set<ResourceType>([
  "ebs_snapshot",
  "rds_snapshot",
  "rds_snapshot_source",
  "s3",
  "nat_gateway",
]);
export const actionableChildResourceTypes = new Set([
  "rds_snapshot",
  "ecs_workload",
]);
export const notActionableResourceTypes = new Set([
  "rds_snapshot_source",
  "dynamo_db_backup_source",
]);

//todo: use `nestedResourcesHierarchy` instead of this
export const resourceTypeGroups: Partial<Record<ResourceType, ResourceType[]>> =
  {
    rds_snapshot_source: ["rds_snapshot"],
    dynamo_db_backup_source: ["dynamo_db_backup"],
    az_aks: ["az_node_pool", "az_aks_vm"],
    asg: ["asg_ec2"],
    eks: ["node_group", "eks_ec2"],
    gke: ["node_pool", "gke_vm"],
    mig: ["mig_vm"],
    ecs: ["ecs_workload"],
    az_vmss: ["az_vmss_vm"],
  };
