import { CoverageGrouping } from "../../../../../../../../../../services/cloudchipr.api";

export const coverageGroupByTitleByGrouping = new Map<CoverageGrouping, string>(
  [
    ["resource_type", "Resource Type"],
    ["account", "Account"],
    ["region", "Region"],
    ["ungrouped", "Ungrouped"],
  ],
);
