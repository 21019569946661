import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { SlackAutocompleteTrigger } from "./SlackAutocompleteTrigger";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import {
  findSlackSelectedOptions,
  mergeSlackConversations,
  slackSelectionOptionsFilter,
} from "../../utils/helpers/helpers";
import { DropdownSelectOption } from "../../../../../../../../common/select/dropdown-select/utils/types/types";
import {
  SlackConversationResponse,
  useGetUsersMeSlackIntegrationsByIntegrationIdConversationsQuery,
} from "../../../../../../../../../services/cloudchipr.api";
import { SlackConversationLabel } from "../SlackConversationLabel";

interface SlackConversationSelectProps {
  id: string;
  label?: string;
  selectedConversations?: SlackConversationResponse[] | null;
  onChange(conversations: SlackConversationResponse[]): void;
}

export const SlackConversationSelect: FC<SlackConversationSelectProps> = ({
  onChange,
  id,
  selectedConversations,
  label,
}) => {
  const [options, setOptions] = useState([] as DropdownSelectOption[]);
  const [search, setSearch] = useState("");

  const {
    data: conversations,
    isFetching,
    isLoading,
  } = useGetUsersMeSlackIntegrationsByIntegrationIdConversationsQuery(
    { integrationId: id, search },
    { skip: !id, refetchOnMountOrArgChange: !!search },
  );

  const values = useMemo(() => {
    return selectedConversations?.map((conversation) => conversation.id) ?? [];
  }, [selectedConversations]);

  const conversationsChangeHandler = useCallback(
    (values: string[]) => {
      const selected = findSlackSelectedOptions(options, values);

      onChange(selected);
    },
    [options, onChange],
  );

  const TriggerComponent = useCallback(
    (triggerProps: any) => {
      return (
        <SlackAutocompleteTrigger
          onChange={conversationsChangeHandler}
          searchHandler={setSearch}
          triggerProps={triggerProps}
          loading={isLoading}
        />
      );
    },
    [conversationsChangeHandler, isLoading, setSearch],
  );

  const noItemsLabel = useCallback(
    (keyword: any) => (
      <Typography align="center" variant="subtitle2" color="text.secondary">
        No conversations found for “{keyword}”
      </Typography>
    ),
    [],
  );

  useEffect(() => {
    if (!conversations?.length) {
      return;
    }

    const uniqueConversations = mergeSlackConversations(
      selectedConversations ?? [],
      conversations,
    );

    setOptions(
      uniqueConversations.map((item) => {
        return {
          value: item.id,
          rawValue: item,
          label: <SlackConversationLabel type={item.type} name={item.name} />,
        };
      }),
    );
  }, [conversations, selectedConversations]);

  return (
    <DropdownSelect
      showSearch
      values={values}
      options={options}
      showSelectAll={false}
      wrapperVariant="dropdown"
      noItemsLabel={noItemsLabel}
      label={label ?? "Select Conversations*"}
      submitHandlerOnClose={conversationsChangeHandler}
      optionsLoading={isFetching}
      TriggerComponent={TriggerComponent}
      filterFn={slackSelectionOptionsFilter}
      PopoverProps={PopoverProps}
      searchPlaceholder="Search for members, private and public channels."
    />
  );
};

const PopoverProps = { extendAnchorWidth: true };
