import { FC, useCallback, useEffect, useState } from "react";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { TextField } from "@mui/material";
import { useDebounce } from "rooks";

interface DebouncedInputProps extends Omit<TextFieldProps, "onChange"> {
  onChange: (value: string | number) => void;
  debounce?: number;
}

export const DebouncedInput: FC<
  DebouncedInputProps & Omit<TextFieldProps, "onChange">
> = ({ value: initialValue, onChange, debounce = 500, ...props }) => {
  const [value, setValue] = useState(initialValue);

  const setDebouncedValue = useDebounce(onChange, debounce);

  const handleChange = useCallback(
    (event: any) => {
      setValue(event.target.value);
      setDebouncedValue(event.target.value);
    },
    [setDebouncedValue],
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <TextField {...props} onChange={handleChange} value={value} />;
};
