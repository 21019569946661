import { FC, Fragment, useCallback, useState } from "react";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import { DashboardV2FoldersDeleteDialog } from "./dialogs/DashboardV2FoldersDeleteDialog";
import { DashboardV2FoldersRenameDialog } from "./dialogs/DashboardV2FoldersRenameDialog";
import { DashboardV2FolderMoveAction } from "./move/DashboardV2FolderMoveAction";
import { navigationHierarchyItemNoDndKey } from "../../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { DashboardCreateFolderOrItemDialog } from "../DashboardCreateFolderOrItemDialog";

interface DashboardFolderListItemActionsMenuProps {
  id: string;
  name: string;
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  redirectAfterCreate?: boolean;
  containsProtectedItem?: boolean;
  visibility: NavigationItemsVisibilityType;
}

export const DashboardFolderListItemActionsMenu: FC<
  DashboardFolderListItemActionsMenuProps
> = ({
  id,
  open,
  name,
  anchor,
  closeMenu,
  visibility,
  redirectAfterCreate,
  containsProtectedItem,
}) => {
  const [openDialogType, setOpenDialogType] = useState<
    "add-item" | "rename" | "delete"
  >();

  const renameClickHandler = useCallback(() => {
    setOpenDialogType("rename");
  }, []);

  const deleteClickHandler = useCallback(() => {
    setOpenDialogType("delete");
  }, []);

  const addClickHandler = useCallback(() => {
    setOpenDialogType("add-item");
  }, []);

  const closeDialog = useCallback(() => {
    setOpenDialogType(undefined);
    closeMenu();
  }, [closeMenu]);

  return (
    <Fragment>
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        variant="menu"
        {...navigationHierarchyItemNoDndKey}
      >
        <MenuItem onClick={renameClickHandler} sx={menuItemSx}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" sx={iconSx} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Rename</Typography>
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={addClickHandler} sx={menuItemSx}>
          <ListItemIcon>
            <AddIcon fontSize="small" sx={iconSx} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Add dashboard</Typography>
          </ListItemText>
        </MenuItem>

        <DashboardV2FolderMoveAction
          id={id}
          name={name}
          onClose={closeMenu}
          visibility={visibility}
          containsProtectedItem={containsProtectedItem}
        />

        <Tooltip
          title={
            containsProtectedItem
              ? "This folder contains protected dashboard(s) and cannot be moved."
              : ""
          }
          arrow
          placement="right"
        >
          <span>
            <MenuItem
              onClick={deleteClickHandler}
              sx={menuItemSx}
              disabled={containsProtectedItem}
            >
              <ListItemIcon>
                <DeleteOutlineIcon fontSize="small" sx={iconSx} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Delete</Typography>
              </ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>

      {openDialogType === "delete" && (
        <DashboardV2FoldersDeleteDialog
          open
          id={id}
          name={name}
          closeMenu={closeDialog}
          visibility={visibility}
        />
      )}

      {openDialogType === "rename" && (
        <DashboardV2FoldersRenameDialog
          open
          id={id}
          name={name}
          closeMenu={closeDialog}
        />
      )}

      {openDialogType === "add-item" && (
        <DashboardCreateFolderOrItemDialog
          type="item"
          folderId={id}
          onClose={closeDialog}
          visibility={visibility}
          redirectAfterCreate={redirectAfterCreate}
        />
      )}
    </Fragment>
  );
};

const menuItemSx = {
  pl: 1,
  pr: 1.5,
};

const iconSx = {
  minWidth: 28,
};
