import { getBillingExplorerSummarySelector } from "../api-select/getBillingExplorerSummarySelector";
import { RootState } from "../../../../store";
import { getBillingExplorerSummaryFixedCashKeySelector } from "../getBillingExplorerSummaryFixedCashKeySelector";

export const billingExplorerSummaryLoadingSelector = (state: RootState) => {
  const key = getBillingExplorerSummaryFixedCashKeySelector(state);
  return getBillingExplorerSummarySelector(key ?? "")(state)?.isLoading;
};

// TODO: to be removed after EnableResourcesExplorerExplainWithAIWithExplanation
