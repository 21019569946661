import { FC, useCallback, useMemo } from "react";
import { PopoverProps, Stack } from "@mui/material";
import { useDidMount } from "rooks";
import { AssigneeSelectionTrigger } from "./AssigneeSelectionTrigger";
import { DropdownSelect } from "../../../../../../../common/select/dropdown-select/DropdownSelect";
import { DropdownSelectOption } from "../../../../../../../common/select/dropdown-select/utils/types/types";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { organisationUsersSelector } from "../../../../../../../../store/user/selectors/organisationUsersSelector";
import { getOrganisationUsersThunk } from "../../../../../../../../store/user/thunks/getOrganisationUsersThunk";
import { FilterTriggerComponentProps } from "../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { UserAvatar } from "../../../../../../../common/avatars/UserAvatar";
import { DeletedUserAvatar } from "../../../../../../../common/avatars/DeletedUserAvatar";

interface AssigneeSelectionProps {
  values?: string[] | null;
  inCell?: boolean;
  disableSelection?: boolean;
  onChange(values: string[] | null): void;
  Trigger?: FC<FilterTriggerComponentProps>;
  PopoverProps?: Partial<PopoverProps> & { extendAnchorWidth?: boolean };
}

export const AssigneeSelection: FC<AssigneeSelectionProps> = ({
  onChange,
  values,
  disableSelection,
  inCell,
  Trigger: CustomTrigger,
  PopoverProps,
}) => {
  const dispatch = useAppDispatch();

  const users = useAppSelector(organisationUsersSelector);

  const renderOptions = useMemo(() => {
    return (
      users
        ?.filter(
          (user) => user.status === "active" || !values?.includes(user.id),
        )
        ?.map((user) => {
          const userIsNotActive =
            user.status !== "active" && !values?.includes(user.id);

          return {
            rawValue: user,
            value: user.id,
            disabled: userIsNotActive,
            label: (
              <Stack direction="row" spacing={1} alignItems="center">
                {userIsNotActive ? (
                  <DeletedUserAvatar />
                ) : (
                  <UserAvatar
                    profile={user.profile}
                    name={user.name}
                    sx={{ cursor: "inherit" }}
                  />
                )}

                <TypographyWithTooltip
                  variant="body2"
                  title={user.name || user.email}
                />
              </Stack>
            ),
          };
        }) ?? []
    );
  }, [users, values]);

  const changeHandler = useCallback(
    (values: string[]) => {
      onChange(values.length ? values : null);
    },
    [onChange],
  );

  const TriggerComponent = useCallback(
    (props: FilterTriggerComponentProps) => (
      <AssigneeSelectionTrigger {...props} inCell={inCell} />
    ),
    [inCell],
  );

  useDidMount(() => {
    dispatch(getOrganisationUsersThunk());
  });

  return (
    <DropdownSelect
      listWidth={300}
      showSelectAll={false}
      options={renderOptions}
      wrapperVariant="popover"
      filterFn={filterFn}
      label="Select Assignee"
      values={values ?? []}
      disabled={disableSelection}
      PopoverProps={PopoverProps}
      TriggerComponent={CustomTrigger ?? TriggerComponent}
      submitHandlerOnClose={changeHandler}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  const target =
    `${option.rawValue?.name} ${option.rawValue?.email}`.toLowerCase();

  return target.includes(keyword.toLowerCase().trim());
};
