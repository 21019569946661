import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { coverageWidgetSetupDataSelector } from "./coverageWidgetSetupDataSelector";
import { coverageSetupVisualizationChartBaseSelector } from "../../../setups/coverage/visualization/coverageSetupVisualizationChartBaseSelector";
import { ChartDataType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";

import { generateCoverageWidgetChartData } from "../utils/helpers/generateCoverageWidgetChartData";

export const coverageWidgetSetupChartDataSelector = createDraftSafeSelector(
  [
    coverageWidgetSetupDataSelector,
    coverageSetupVisualizationChartBaseSelector,
  ],
  (data, chartBase): ChartDataType[] | undefined => {
    const { groups } = data ?? {};

    return generateCoverageWidgetChartData(groups, chartBase);
  },
);
