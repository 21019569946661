import { ValueGetterParams } from "ag-grid-community";
import { CostAnomalyAlertsDataGridDataType } from "../../../../../../../../../store/alerts/utils/types/types";

export const costAnomalyAlertsTimeIntervalValueGetter = ({
  data,
}: ValueGetterParams<CostAnomalyAlertsDataGridDataType>) => {
  const timeInterval = data?.time_interval?.time_interval;
  const timeIntervalUnit = data?.time_interval?.time_interval_unit;

  if (!timeInterval || !timeIntervalUnit) {
    return;
  }

  const unit =
    timeInterval > 1 ? timeIntervalUnit : timeIntervalUnit.slice(0, -1);

  return `${timeInterval} ${unit}`;
};
