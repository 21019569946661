import { FC } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { useLocation } from "react-router-dom";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { SavingsOpportunitiesNavigationItem } from "./SavingsOpportunitiesNavigationItem";
import { navigationCollapsedSelector } from "../../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { useAppSelector } from "../../../../../store/hooks";
import { AppNavItemWrapper } from "../../app-nav-components/AppNavItemWrapper";

export const SavingsOpportunitiesNavigation: FC = () => {
  const location = useLocation();

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);
  const enableSavingsOpportunities = useFlag("EnableSavingsOpportunities");
  const selected = location.pathname.startsWith("/recommendations");

  return (
    <AppNavItemWrapper
      primary="Savings Opportunities"
      to={
        enableSavingsOpportunities
          ? "/recommendations/savings-opportunities"
          : "/recommendations/off-hours"
      }
      Icon={LightbulbOutlinedIcon}
      selected={selected}
      navCollapsed={navigationCollapsed}
    >
      {enableSavingsOpportunities && (
        <SavingsOpportunitiesNavigationItem type="savings-opportunities" />
      )}

      <SavingsOpportunitiesNavigationItem type="off-hours" />

      <SavingsOpportunitiesNavigationItem type="rightsizing" />
    </AppNavItemWrapper>
  );
};
