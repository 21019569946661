import { RootState } from "../../../../store";
import { widgetSetupSelector } from "../widgetSetupSelector";
import { BillingExplorerWidgetSetupType } from "../../../utils/types/setups/billingExplorerSetupType";

export const billingExplorerWidgetSetupSelector = (
  state: RootState,
): BillingExplorerWidgetSetupType | undefined => {
  if (!widgetSetupSelector(state)) {
    return;
  }
  return widgetSetupSelector(state) as BillingExplorerWidgetSetupType;
};
