import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Typography } from "@mui/material";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { money } from "../../../../../../../../../../../utils/numeral/money";

export const coveredUsageColumnDef: ColDef<SavingsPlanCoverageDataWithId> = {
  flex: 1.5,
  field: "covered_usage",
  headerName: "Spend Covered by Savings Plans",
  cellRenderer: ({
    data,
  }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => (
    <Typography variant="body2">{money(data?.covered_usage ?? 0)}</Typography>
  ),
  valueGetter: ({ data }) => money(data?.covered_usage ?? 0),
};
