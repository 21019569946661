import { useFlag } from "@unleash/proxy-client-react";
import { isResourceTypeHiddenByFeatureToggleStorageKey } from "../helpers/getIsResourceTypeHiddenByFeatureToggle";

export const useInitiateHiddenResourceTypesByFeatureToggle = () => {
  const enableDynamoDBTable = useFlag("EnableDynamoTable");
  const enableDynamoDBBackup = useFlag("EnableDynamoDBBackup");
  const enableDaxCluster = useFlag("EnableDAXCluster");

  const data = {
    dynamo_db_table: !enableDynamoDBTable,
    dynamo_db_backup: !enableDynamoDBBackup,
    dax_cluster: !enableDaxCluster,
  };

  sessionStorage.setItem(
    isResourceTypeHiddenByFeatureToggleStorageKey,
    JSON.stringify(data),
  );
};
