import { GridApi } from "ag-grid-community";
import { isReservationsCoverageDataWithId } from "./isReservationsCoverageDataWithId";
import {
  ReservationsCoverageDataWithId,
  SavingsPlanCoverageDataWithId,
} from "../types";

export type CommitmentsTotals = {
  netSavings: number;
  coveredUsage: number;
  unCoveredUsage: number;
  coveredHours: number;
  unCoveredHours: number;
};

export const commitmentsTotalsFromGridApi = (
  gridApi: GridApi<
    SavingsPlanCoverageDataWithId | ReservationsCoverageDataWithId
  > | null,
): CommitmentsTotals | undefined => {
  if (!gridApi) {
    return;
  }

  const filteredData: (
    | SavingsPlanCoverageDataWithId
    | ReservationsCoverageDataWithId
  )[] = [];

  gridApi.forEachNodeAfterFilter((node) => {
    if (!node.data) {
      return;
    }

    filteredData.push(node.data);
  });

  return filteredData?.reduce(
    (acc, item) => {
      acc.netSavings += item.net_savings ?? 0;
      acc.coveredUsage += item.covered_usage ?? 0;
      acc.unCoveredUsage += item.uncovered_usage ?? 0;
      acc.coveredHours += isReservationsCoverageDataWithId(item)
        ? (item?.covered_hours ?? 0)
        : 0;
      // @ts-expect-error: TODO fix types
      acc.unCoveredHours += item?.uncovered_hours ?? 0;

      return acc;
    },
    {
      netSavings: 0,
      coveredUsage: 0,
      unCoveredUsage: 0,
      coveredHours: 0,
      unCoveredHours: 0,
    },
  );
};
