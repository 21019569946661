import { FC } from "react";
import { Stack, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ColumnsInnerHeaderComponent } from "../../../common/ag-grid/components/ColumnsInnerHeaderComponent";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { money } from "../../../../../utils/numeral/money";
import { useTotalInHeaderCalculator } from "../../../common/ag-grid/utils/hooks/useTotalInHeaderCalculator.hook";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import type { CustomHeaderProps } from "ag-grid-react";

interface SavingsOpportunitiesPriceInnerHeaderProps extends CustomHeaderProps {
  propName:
    | "current_monthly_price"
    | "estimated_monthly_price"
    | "monthly_savings";
  infoIconTooltipText?: string;
}

const SavingsOpportunitiesPriceInnerHeader: FC<
  SavingsOpportunitiesPriceInnerHeaderProps
> = ({ propName, infoIconTooltipText, ...props }) => {
  const total = useTotalInHeaderCalculator<SavingsOpportunity>(
    props.api,
    (data) => +(data[propName] ?? 0),
  );

  const content = (
    <Stack position="relative">
      <ColumnsInnerHeaderComponent {...props} />

      <TypographyWithTooltip
        title={money(total)}
        variant="caption"
        fontSize={10}
      />
    </Stack>
  );

  if (infoIconTooltipText) {
    return (
      <Stack
        position="relative"
        direction="row"
        flex={1}
        spacing={1}
        alignItems="center"
      >
        {content}
        <Tooltip title={infoIconTooltipText} placement="top" arrow>
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      </Stack>
    );
  }

  return content;
};

export const getSavingsOpportunitiesPriceInnerHeader = (
  propName:
    | "current_monthly_price"
    | "estimated_monthly_price"
    | "monthly_savings",
  infoIconTooltipText?: string,
) => {
  return (props: CustomHeaderProps) => (
    <SavingsOpportunitiesPriceInnerHeader
      propName={propName}
      infoIconTooltipText={infoIconTooltipText}
      {...props}
    />
  );
};
