import { FC, Fragment } from "react";
import { useDidMount } from "rooks";
import { Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { WidgetLoadingState } from "../common/WidgetLoadingState";
import { getBillingSummaryWidgetDataByWidgetIdThunk } from "../../../../../../store/dashboards/thunks/widgets/billing-summary/data-get/getBillingSummaryWidgetDataByWidgetIdThunk";
import { BillingSummaryWidgetView } from "../../adding-widget/widget-create/widget-setups/billing-summary/content/BillingSummaryWidgetView";
import { billingSummaryWidgetTitlePropsSelector } from "../../../../../../store/dashboards/selectors/widgets/billing-summary/billingSummaryWidgetTitlePropsSelector";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { billingSummaryWidgetNotFoundSelector } from "../../../../../../store/dashboards/selectors/widgets/billing-summary/billingSummaryWidgetNotFoundSelector";
import { DashboardNotFoundWidget } from "../common/DashboardNotFoundWidget";
import { billingSummaryWidgetViewNameSelector } from "../../../../../../store/dashboards/selectors/widgets/billing-summary/billingSummaryWidgetViewNameSelector";
import { billingSummaryWidgetViewDataSelector } from "../../../../../../store/dashboards/selectors/widgets/billing-summary/widget-data/billingSummaryWidgetViewDataSelector";
import { billingSummaryWidgetDataLoadingSelector } from "../../../../../../store/dashboards/selectors/widgets/billing-summary/widget-data/billingSummaryWidgetDataLoadingSelector";
import { WidgetCardHeader } from "../common/widget-header/WidgetCardHeader";
import { billingSummaryWidgetBillingExplorerUrlSelector } from "../../../../../../store/dashboards/selectors/widgets/billing-summary/billingSummaryWidgetBillingExplorerUrlSelector";

interface DashboardBillingSummaryWidgetProps {
  widgetId: string;
}
export const DashboardBillingSummaryWidget: FC<
  DashboardBillingSummaryWidgetProps
> = ({ widgetId }) => {
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();

  const widgetDataNotFound = useAppSelector((state) =>
    billingSummaryWidgetNotFoundSelector(state, widgetId),
  );
  const loading = useAppSelector((state) =>
    billingSummaryWidgetDataLoadingSelector(state, widgetId),
  );

  const data = useAppSelector((state) =>
    billingSummaryWidgetViewDataSelector(state, widgetId),
  );

  const viewName = useAppSelector((state) =>
    billingSummaryWidgetViewNameSelector(state, widgetId),
  );

  const widgetTitleProps = useAppSelector((state) =>
    billingSummaryWidgetTitlePropsSelector(state, widgetId),
  );
  const url = useAppSelector((state) =>
    billingSummaryWidgetBillingExplorerUrlSelector(state, widgetId),
  );

  useDidMount(() => {
    dispatch(getBillingSummaryWidgetDataByWidgetIdThunk(widgetId));
  });

  return (
    <Stack ref={ref} flexGrow={1} overflow="auto">
      {loading ? (
        <WidgetLoadingState />
      ) : (
        <Fragment>
          <WidgetCardHeader
            hovered={hovered}
            widgetId={widgetId}
            widgetType="cost_and_usage_summary"
            widgetTitleProps={widgetTitleProps}
          />

          {widgetDataNotFound ? (
            <DashboardNotFoundWidget
              name={viewName ?? ""}
              label="billing report"
            />
          ) : (
            <BillingSummaryWidgetView
              data={data ?? []}
              loading={loading}
              dateLabel={widgetTitleProps?.dates?.label}
              totalCostUrl={url}
            />
          )}
        </Fragment>
      )}
    </Stack>
  );
};
