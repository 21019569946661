import { billingExplorerWidgetSetupSelector } from "./billingExplorerWidgetSetupSelector";
import { RootState } from "../../../../store";
import { BillingExplorerWidgetSetupType } from "../../../utils/types/setups/billingExplorerSetupType";

export const billingExplorerSetupPropertyByKeySelector =
  (key: keyof BillingExplorerWidgetSetupType) =>
  (state: RootState): any => {
    const setup = billingExplorerWidgetSetupSelector(state);

    if (!setup) {
      return null;
    }

    return setup[key];
  };
