import { FC } from "react";
import {
  CorporateFareRounded,
  SettingsInputHdmiOutlined,
} from "@mui/icons-material";
import { Card, Stack, Tooltip, Typography } from "@mui/material";
import { CommunicationIntegrationConnectionType } from "../../../common/utils/types/common";

interface ConnectionsChipProps {
  connectionType: CommunicationIntegrationConnectionType;
  connections: number;
}

export const CommunicationIntegrationsCountChip: FC<ConnectionsChipProps> = ({
  connectionType,
  connections,
}) => {
  const tooltipText = getTooltipText(connectionType, connections);

  return (
    <Tooltip placement="top" title={tooltipText}>
      <Card variant="outlined">
        <Stack px={1} py={0.5} direction="row" spacing={1}>
          {icons[connectionType]}
          <Typography>{connections}</Typography>
        </Stack>
      </Card>
    </Tooltip>
  );
};

const getTooltipText = (
  connectionType: CommunicationIntegrationConnectionType,
  connections: number,
) => {
  const integrationTypeLabel = `${tooltipLabels[connectionType]}${connections === 1 ? "" : "s"}`;
  const postfix = connectionType === "workspace" ? "connected" : "";

  return `${connections} ${integrationTypeLabel} ${postfix}`;
};

const tooltipLabels: Record<CommunicationIntegrationConnectionType, string> = {
  integration: "Integration",
  workspace: "Workspace",
};

const icons: Record<CommunicationIntegrationConnectionType, any> = {
  integration: <SettingsInputHdmiOutlined sx={{ color: "text.secondary" }} />,
  workspace: <CorporateFareRounded sx={{ color: "text.secondary" }} />,
};
