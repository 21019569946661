import { FC, memo, RefObject, useCallback, useRef } from "react";
import { SxProps } from "@mui/system";
import { useVirtual } from "react-virtual";
import { Row, Table } from "@tanstack/react-table";
import { TableBody, TableCell, Theme } from "@mui/material";
import { generateExpandedRowId } from "./ExpandedRow";
import { WithGetRowProps } from "./RowDnDWrapper";
import { TableRow } from "./rows/TableRow";
import { RowExpandingConfig } from "../utils/types/prop-types";
import { useDataGridContext } from "../DataGridProvider";
import { calculateVirtualizationPaddings } from "../../../utils/virtualization/calculateVirtualizationPaddings";
import { VIRTUALIZED_ROW_DEFAULT_SIZE } from "../utils/constants";
import { VirtualColumnsConfig } from "../table-header/TableHeadComponent";

interface TableBodyVirtualizedRowsProps extends WithGetRowProps {
  table: Table<any>;
  rows: Row<any>[];
  rowDnDEnabled: boolean;
  stickyColumnsEnabled: boolean;
  expandingRowConfig?: RowExpandingConfig;
  tableContainerRef: RefObject<HTMLTableElement>;
  styles?: SxProps<Theme>;
  globalFilter?: string;
  virtualColumnsConfig: VirtualColumnsConfig;
  enableColumnVirtualization: boolean;
}

export const TableBodyVirtualizedRows: FC<TableBodyVirtualizedRowsProps> = memo(
  ({
    table,
    rows,
    expandingRowConfig,
    rowDnDEnabled,
    stickyColumnsEnabled,
    tableContainerRef,
    styles,
    globalFilter,
    getRowProps,
    virtualColumnsConfig,
    enableColumnVirtualization,
  }) => {
    const { rowExpanding } = useDataGridContext();
    const needToReEstimateSize = rowExpanding?.needToReEstimateSize;
    const defaultRowSizeRef = rowExpanding?.defaultRowSizeRef;

    const heights = useRef<Record<string, number>>({});

    const estimateSize = useCallback(
      (index: any) => {
        let rowId = rows.find((row) => row.index === index)?.id;

        // needToReEstimateSize checked just to avoid "unnecessary dependency" warning
        if (!rowId || !needToReEstimateSize || !defaultRowSizeRef) {
          return VIRTUALIZED_ROW_DEFAULT_SIZE;
        }

        let rowHeight = defaultRowSizeRef.current;
        const element = document.getElementById(rowId);

        if (
          element?.offsetHeight &&
          rowHeight === VIRTUALIZED_ROW_DEFAULT_SIZE &&
          element.offsetHeight !== rowHeight
        ) {
          defaultRowSizeRef.current = element?.offsetHeight;
          rowHeight = defaultRowSizeRef.current;
        }

        if (!rowHeight) {
          defaultRowSizeRef.current =
            element?.offsetHeight ?? VIRTUALIZED_ROW_DEFAULT_SIZE;
          rowHeight = defaultRowSizeRef.current;
        }

        rowId = generateExpandedRowId(rowId);

        let expandedHeight = document.getElementById(rowId)?.clientHeight;

        if (expandedHeight) {
          expandedHeight = expandedHeight + rowHeight;
          heights.current[rowId] = expandedHeight;
        }

        const memoizedHeight = heights.current[rowId];

        return expandedHeight ?? memoizedHeight ?? rowHeight;
      },
      [rows, needToReEstimateSize, defaultRowSizeRef],
    );

    const { virtualItems, totalSize } = useVirtual({
      estimateSize: expandingRowConfig ? estimateSize : undefined,
      overscan: 10,
      size: rows.length,
      parentRef: tableContainerRef,
    });

    const { paddingTop, paddingBottom } = calculateVirtualizationPaddings(
      virtualItems,
      totalSize,
    );

    return (
      <TableBody>
        {paddingTop > 0 && (
          <tr>
            <TableCell sx={{ height: paddingTop }} />
          </tr>
        )}

        {virtualItems.map((virtualRow) => {
          const row = rows[virtualRow.index];

          return (
            <TableRow
              key={
                row.id +
                row.getIsExpanded().toString() +
                row.getIsSelected().toString() +
                virtualRow.index
              }
              enableColumnVirtualization={enableColumnVirtualization}
              stickyColumnsEnabled={stickyColumnsEnabled}
              virtualColumnsConfig={virtualColumnsConfig}
              styles={styles}
              table={table}
              row={row}
              getRowProps={getRowProps}
              dndEnabled={rowDnDEnabled}
              expandingRowConfig={expandingRowConfig}
              globalFilter={globalFilter}
              tableContainerRef={tableContainerRef}
            />
          );
        })}

        {paddingBottom > 0 && (
          <tr>
            <TableCell sx={{ height: paddingBottom }} />
          </tr>
        )}
      </TableBody>
    );
  },
);
