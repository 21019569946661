import { FC, useCallback } from "react";
import { TypographyProps } from "@mui/material";
import { ReportsDrawerNotificationsCustomMessageAfterMessage } from "./custom-message-components/ReportsDrawerNotificationsCustomMessageAfterMessage";
import { ReportsDrawerNotificationsCustomMessageBeforeMessage } from "./custom-message-components/ReportsDrawerNotificationsCustomMessageBeforeMessage";
import { NotificationsSelection } from "../../../../../common/notification-selection/NotificationsSelection";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { reportsDataPropertyByKeySelector } from "../../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";
import { Emails } from "../../../../../../../services/cloudchipr.api";
import { setReportsData } from "../../../../../../../store/reports/reportsSlice";
import { ReportsData } from "../../../../../../../store/reports/utils/types/types";

export const ReportsDrawerNotifications: FC = () => {
  const dispatch = useAppDispatch();
  const emails = useAppSelector(reportsDataPropertyByKeySelector("emails"));
  const notifications = useAppSelector(
    reportsDataPropertyByKeySelector("notifications"),
  );

  const emailsChangeHandler = useCallback(
    (emails: Emails | null) => {
      dispatch(setReportsData({ emails: emails ?? undefined }));
    },
    [dispatch],
  );

  const integrationsChangeHandler = useCallback(
    (notifications: ReportsData["notifications"]) => {
      dispatch(setReportsData({ notifications }));
    },
    [dispatch],
  );

  return (
    <NotificationsSelection
      selectedEmails={emails ?? null}
      emailsChangeHandler={emailsChangeHandler}
      disabledIntegrationTypes={["jira", "webhook"]}
      selectedIntegrations={notifications}
      setSelectedIntegrations={integrationsChangeHandler}
      titleTypographyProps={titleTypographyProps}
      customMessageConfigs={customMessageConfigs}
    />
  );
};

const titleTypographyProps: TypographyProps = {
  variant: "body1",
  fontWeight: "medium",
};

const customMessageConfigs = {
  preview: {
    AfterMessage: ReportsDrawerNotificationsCustomMessageAfterMessage,
    BeforeMessage: ReportsDrawerNotificationsCustomMessageBeforeMessage,
  },
  dialogContentProps: {
    AfterMessage: ReportsDrawerNotificationsCustomMessageAfterMessage,
    BeforeMessage: ReportsDrawerNotificationsCustomMessageBeforeMessage,
  },
};
