import { FC, Fragment, useMemo } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import { useDidMount } from "rooks";
import { Stack } from "@mui/material";
import { InternalDashboardCoverageWidgetHeader } from "./components/InternalDashboardCoverageWidgetHeader";
import {
  DashboardWidgetCoverage,
  GetUsersMeOrganisationsCurrentWidgetsCoverageByWidgetIdApiResponse,
  WidgetCoverageAggregation,
} from "../../../../../../services/cloudchipr.api";
import { DashboardNotFoundWidget } from "../../../../../pages/dashboard/components/widgets/common/DashboardNotFoundWidget";
import { NoCoverage } from "../../../../../pages/dashboard/components/widgets/coverage/components/NoCoverage";
import { CoverageWidgetCosts } from "../../../../../pages/dashboard/components/widgets/coverage/components/CoverageWidgetCosts";
import { CoverageWidgetNumeralView } from "../../../../../pages/dashboard/components/widgets/coverage/components/CoverageWidgetNumeralView";
import { CoverageWidgetTableView } from "../../../../../pages/dashboard/components/widgets/coverage/components/CoverageWidgetTableView";
import { CoverageWidgetChartView } from "../../../../../pages/dashboard/components/widgets/coverage/components/CoverageWidgetChartView";
import { generateCoverageWidgetChartData } from "../../../../../../store/dashboards/selectors/widgets/coverage/utils/helpers/generateCoverageWidgetChartData";

interface InternalDashboardCoverageWidgetProps extends DashboardWidgetCoverage {
  data?: GetUsersMeOrganisationsCurrentWidgetsCoverageByWidgetIdApiResponse;
  isError: boolean;
  error?: FetchBaseQueryError | SerializedError;
  onWidgetMount?(): void;
}

export const InternalDashboardCoverageWidget: FC<
  InternalDashboardCoverageWidgetProps
> = ({
  data,
  error,
  isError,
  name,
  provider_organisation_id,
  commitment_type,
  grouping,
  dates,
  aggregation,
  visualization,
  onWidgetMount,
}) => {
  const { groups } = data ?? {};
  const status = error && "status" in error ? error?.status : 0;
  const hasAggregation = useMemo(
    () =>
      Object.keys(aggregation ?? {}).some(
        (key) => !!aggregation?.[key as keyof WidgetCoverageAggregation],
      ),
    [aggregation],
  );
  const chartType = visualization.chart_type;
  const chartBase = visualization.chart_base_type;
  const chartData = useMemo(
    () => generateCoverageWidgetChartData(groups, chartBase),
    [groups, chartBase],
  );

  useDidMount(() => {
    onWidgetMount?.();
  });

  if (isError && status !== 404) {
    return;
  }

  return (
    <Stack flexGrow={1} overflow="auto">
      <InternalDashboardCoverageWidgetHeader
        widgetName={name}
        providerOrganisationId={provider_organisation_id}
        commitmentType={commitment_type}
        grouping={grouping}
        dates={dates}
      />

      {status === 404 && (
        <DashboardNotFoundWidget name="" label="organization" />
      )}

      {!data?.groups.length && status !== 404 && <NoCoverage />}

      {!!data?.groups.length && status !== 404 && (
        <Fragment>
          {hasAggregation && (
            <CoverageWidgetCosts
              data={data}
              dates={dates}
              //TODO: add quarterStartMonth
              quarterStartMonth={1}
              aggregation={aggregation}
            />
          )}

          {chartType === "numeral" && (
            <CoverageWidgetNumeralView groups={groups} loading={false} />
          )}

          {chartType === "table" && (
            <CoverageWidgetTableView
              groups={groups}
              visualization={visualization}
              dates={dates}
              grouping={grouping ?? "resource_type"}
              //TODO: add quarterStartMonth
              quarterStartMonth={1}
            />
          )}

          {chartType !== "table" && chartType !== "numeral" && (
            <CoverageWidgetChartView
              groups={groups}
              hasAggregation={hasAggregation}
              chartType={chartType ?? "stack"}
              chartBase={chartBase}
              chartData={chartData}
            />
          )}
        </Fragment>
      )}
    </Stack>
  );
};
