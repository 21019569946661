import { FC, Fragment } from "react";
import { Card, LinearProgress } from "@mui/material";
import { CoverageWidgetSetupSubHeader } from "./components/CoverageWidgetSetupSubHeader";
import { CoverageWidgetSetupContentCosts } from "./components/CoverageWidgetSetupContentCosts";
import { CoverageWidgetSetupChartView } from "./components/CoverageWidgetSetupChartView";
import { CoverageWidgetSetupTableView } from "./components/table/CoverageWidgetSetupTableView";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { WidgetSetupContentWrapper } from "../../../common/WidgetSetupContentWrapper";
import { widgetSetupContentChartHeight } from "../../../../../../../utils/constants/stylings";
import { coverageSetupVisualizationChartTypeSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/coverage/visualization/coverageSetupVisualizationChartTypeSelector";
import { coverageSetupHasAggregationSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/coverage/aggregation/coverageSetupHasAggregationSelector";
import { coverageWidgetSetupDataLoadingSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/coverage/setup/coverageWidgetSetupDataLoadingSelector";
import { coverageWidgetSetupEmptyDataSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/coverage/setup/coverageWidgetSetupEmptyDataSelector";
import { NoCoverage } from "../../../../../../widgets/coverage/components/NoCoverage";
import { coverageWidgetSetupDataGroupsSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/coverage/setup/coverageWidgetSetupDataGroupsSelector";
import { CoverageWidgetNumeralView } from "../../../../../../widgets/coverage/components/CoverageWidgetNumeralView";

export const CoverageWidgetSetupContent: FC = () => {
  const chartType = useAppSelector(coverageSetupVisualizationChartTypeSelector);
  const hasAggregation = useAppSelector(coverageSetupHasAggregationSelector);
  const loading = useAppSelector(coverageWidgetSetupDataLoadingSelector);
  const groups = useAppSelector(coverageWidgetSetupDataGroupsSelector);
  const emptyData = useAppSelector(coverageWidgetSetupEmptyDataSelector);

  return (
    <WidgetSetupContentWrapper>
      <Card elevation={3} sx={widgetCardStyles}>
        {loading && <LinearProgress />}

        <CoverageWidgetSetupSubHeader />

        {emptyData && !loading ? (
          <NoCoverage />
        ) : (
          <Fragment>
            {hasAggregation && <CoverageWidgetSetupContentCosts />}

            {chartType === "numeral" && (
              <CoverageWidgetNumeralView groups={groups} loading={loading} />
            )}

            {chartType === "table" && <CoverageWidgetSetupTableView />}

            {!["numeral", "table"].includes(chartType) && (
              <CoverageWidgetSetupChartView />
            )}
          </Fragment>
        )}
      </Card>
    </WidgetSetupContentWrapper>
  );
};

const widgetCardStyles = {
  height: widgetSetupContentChartHeight,
  display: "flex",
  flexDirection: "column",
};
