import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { formatDate } from "../../../../../../../../../utils/helpers/date-time/datetime-format";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { CommunicationIntegrationsGridRowType } from "../../../utils/types/common";

export const IntegrationsCreatedAtCellRenderer: FC<
  ICellRendererParams<CommunicationIntegrationsGridRowType>
> = ({ data }) => {
  const date = data?.created_at;

  if (!date) {
    return;
  }

  return (
    <TypographyWithTooltip
      title={`${formatDate(date, { type: "dateTimeWithComma" })} (UTC)`}
      variant="body2"
    />
  );
};
