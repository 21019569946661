import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import moment from "moment/moment";
import { budgetQuarters, monthNames } from "../utils/constants/date-selection";
import { capitalizeString } from "../../../../../../../../../../utils/helpers/capitalizeString";
import { BudgetPeriod } from "../../../../../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { isCurrentBudgetEditingSelector } from "../../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/isCurrentBudgetEditingSelector";

interface DateSelectionMonthComponentProps {
  period: BudgetPeriod;
  startDate: string;
  onDateChange(date: string): void;
}

export const DateSelectionMonthComponent: FC<
  DateSelectionMonthComponentProps
> = ({ startDate, period, onDateChange }) => {
  const isEdit = useAppSelector(isCurrentBudgetEditingSelector);

  const selectedMonthIndex = moment(startDate).get("month");

  const monthChangeHandler = useCallback(
    (event: any) => {
      onDateChange(
        formatDate(
          moment(startDate || undefined).set("month", +event.target.value),
        ),
      );
    },
    [onDateChange, startDate],
  );

  const monthSelectRenderValue = useCallback(
    (index: string) => {
      if (period === "quarterly") {
        return (
          budgetQuarters.find(
            ({ firstMonthIndex }) => firstMonthIndex === +index,
          )?.quarterName ?? index
        );
      }

      return capitalizeString(monthNames.at(+index) ?? "");
    },
    [period],
  );

  return (
    <FormControl size="small" fullWidth>
      {period === "monthly" ? (
        <InputLabel>Start Month</InputLabel>
      ) : (
        <InputLabel>Start Quarter</InputLabel>
      )}

      <Select
        fullWidth
        size="small"
        disabled={isEdit}
        onChange={monthChangeHandler}
        renderValue={monthSelectRenderValue}
        value={!isNaN(selectedMonthIndex) ? `${selectedMonthIndex}` : ""}
        label={period === "monthly" ? "Start Month" : "Start Quarter"}
      >
        {period === "quarterly"
          ? budgetQuarters.map((quarter) => (
              <MenuItem
                key={quarter.quarterName}
                value={`${quarter.firstMonthIndex}`}
              >
                <ListItemText>{quarter.quarterName}</ListItemText>
              </MenuItem>
            ))
          : monthNames.map((month, index) => (
              <MenuItem key={month} value={`${index}`}>
                <ListItemText>{capitalizeString(month)}</ListItemText>
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};
