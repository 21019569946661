import { FC, Fragment } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { NestedIntegration } from "../../../../../../../../../services/cloudchipr.api";
import { IntegrationCustomMessageInfoSection } from "../../../../../../../common/notification-selection/components/custom-message/common-components/IntegrationCustomMessageInfoSection";

interface WorkflowNotificationNotifySectionCustomMessageBeforeMessageComponentProps {
  type: NestedIntegration["type"];
}

export const WorkflowNotificationNotifySectionCustomMessageBeforeMessageComponent: FC<
  WorkflowNotificationNotifySectionCustomMessageBeforeMessageComponentProps
> = ({ type }) => {
  if (type === "email") {
    return (
      <Fragment>
        <Typography variant="body1">
          Subject:{" "}
          <Typography component="span" fontWeight="medium" variant="body1">
            Resources Found by Automation Workflow - {`{{workflow name}}`}
          </Typography>
        </Typography>
        <Divider />

        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <IntegrationCustomMessageInfoSection
              start="Workflow"
              tail="{{workflow name}}"
              color="primary.main"
            />

            <Stack direction="row" spacing={0.5}>
              <SearchIcon sx={{ color: "info.main" }} />
              <Typography variant="body1" fontWeight="medium">
                Found Resources
              </Typography>
            </Stack>
            <IntegrationCustomMessageInfoSection
              start="Run date"
              tail="{{date}}"
            />
          </Stack>
          <Stack alignItems="flex-end">
            <Typography
              color="text.secondary"
              variant="body2"
              fontWeight="medium"
            >
              Monthly Price:
            </Typography>
            <Typography variant="body2" fontWeight="medium">
              {`{{0.00}}`}
            </Typography>
          </Stack>
        </Stack>

        <Divider />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Stack spacing={1.5}>
        <Stack direction="row" spacing={0.5}>
          <SearchIcon sx={{ color: "info.main" }} />
          <Typography variant="body1" fontWeight="medium">
            Resources Found
          </Typography>
        </Stack>

        <Typography variant="body1">
          Resources found by workflow
          <Typography component="span" variant="inherit" fontWeight="medium">
            {`  {{workflow name}}.`}
          </Typography>
          <Typography variant="inherit" component="span">
            Please see the detailed list attached.
          </Typography>
        </Typography>
      </Stack>

      <Box>
        <Typography variant="body2" color="text.secondary" fontWeight="medium">
          Workflow:{" "}
          <Typography
            color="primary.main"
            component="span"
            fontWeight="inherit"
          >
            {`{{workflow name}}`}
          </Typography>
        </Typography>

        <Typography variant="body2" color="text.secondary" fontWeight="medium">
          Monthly Price:{" "}
          <Typography
            color="text.primary"
            component="span"
            fontWeight="inherit"
          >
            {`{{$0.00}}`}
          </Typography>
        </Typography>
      </Box>
    </Fragment>
  );
};
