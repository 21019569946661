import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertsData, AlertsInterface, AlertTabType } from "./utils/types/types";
import { alertsInitialData } from "./utils/constants/constants";

const initialState: AlertsInterface = {
  config: {
    activeTab: "budget",
    openDrawer: false,
  },
  data: alertsInitialData,
};

export const alertsSlice = createSlice({
  name: "alerts",
  initialState: initialState,
  reducers: {
    setAlertsActiveTab: (state, action: PayloadAction<AlertTabType>) => {
      state.config.activeTab = action.payload;
    },
    toggleAlertsOpenDrawer: (state) => {
      state.config.openDrawer = !state.config.openDrawer;
    },
    resetAlertsData: (state) => {
      state.data = initialState.data;
    },
    resetAlerts: () => {
      return initialState;
    },
    setAlertsData: (state, action: PayloadAction<Partial<AlertsData>>) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const {
  setAlertsActiveTab,
  toggleAlertsOpenDrawer,
  resetAlertsData,
  setAlertsData,
  resetAlerts,
} = alertsSlice.actions;

export default alertsSlice.reducer;
