import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { updateTaskViewFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.patchUsersMeOrganisationsCurrentTaskViewsByViewId.select(
    updateTaskViewFixedCacheKey,
  );

export const updateTasksViewLoadingSelector = (state: RootState) => {
  return endpoint(state)?.isLoading;
};
