import { FC, ReactElement } from "react";

import { Stack, Tooltip, Typography } from "@mui/material";
import { blue, deepPurple } from "@mui/material/colors";
import { KubernetesClusterCardFooterTooltipItem } from "./KubernetesClusterCardFooterTooltipItem";

interface KubernetesClusterCardFooterMetricInfoProps {
  title: string;
  total: number;
  usage: number;
  requested: number;
  unit: "gib" | "core";
  children: ReactElement;
}
export const KubernetesClusterCardFooterMetricInfoTooltip: FC<
  KubernetesClusterCardFooterMetricInfoProps
> = ({ title, total, usage, requested, unit, children }) => {
  return (
    <Tooltip
      title={
        <Stack spacing={1} width={200}>
          <Typography
            variant="caption"
            color="text.secondary"
            fontWeight="medium"
          >
            {title}
          </Typography>

          <KubernetesClusterCardFooterTooltipItem
            title="Usage"
            color={blue[400]}
            cost={usage}
            total={total}
            unit={unit}
          />
          <KubernetesClusterCardFooterTooltipItem
            title="Reserved"
            color={deepPurple["A100"]}
            cost={requested}
            total={total}
            unit={unit}
          />

          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Typography variant="caption" fontWeight="medium">
              Total
            </Typography>

            <Typography variant="caption" fontWeight="bold">
              {total} {unit === "core" ? "Core" : "GiB"}
            </Typography>
          </Stack>
        </Stack>
      }
      placement="top"
      componentsProps={{
        tooltip: { sx: customTooltipStyles },
      }}
    >
      {children}
    </Tooltip>
  );
};

const customTooltipStyles = {
  color: "text.primary",
  bgcolor: "background.paper",
  "& .MuiTooltip-arrow": {
    color: "background.paper",
  },
  boxShadow: 7,
  border: 1,
  borderColor: "grey.300",
  borderRadius: 2,
  p: 1.5,
};
