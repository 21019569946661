import { ResourceExplorerGridDataWithId } from "../../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { calculateTotalCostTrend } from "../../../../../../../components/pages/utils/helpers/calculateTotalCostTrend";

export const formatBillingExplorerWidgetTableViewLastGridItem = (
  data: ResourceExplorerGridDataWithId[],
) => {
  const mutatedData = data.reduce(
    (result, item) => {
      result.total_cost += item.total_cost ?? 0;

      if (item?.previous_period_total_cost !== null) {
        if (result.previous_period_total_cost === null) {
          result.previous_period_total_cost = item?.previous_period_total_cost;
        } else {
          result.previous_period_total_cost += item?.previous_period_total_cost;
        }
      }

      return result;
    },
    {
      total_cost: 0,
      previous_period_total_cost: null,
    } as ResourceExplorerGridDataWithId,
  );

  const trend =
    mutatedData.previous_period_total_cost !== null
      ? calculateTotalCostTrend(
          mutatedData.total_cost,
          mutatedData.previous_period_total_cost,
        )
      : null;

  const dataLength = data.length > 0 ? ` (${data?.length})` : "";

  return {
    ...mutatedData,
    trend,
    field: `Others${dataLength}`,
    id: "Others",
  };
};
