import React, { FC, Fragment, useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { TaskDeleteActionMenuDialog } from "./TaskDeleteActionMenuDialog";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { taskDeletionLoadingSelector } from "../../../../../../../../store/task-management/selectors/loading/taskDeletionLoadingSelector";
import { tasksListGetLoadingSelector } from "../../../../../../../../store/task-management/selectors/loading/tasksListGetLoadingSelector";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import { Task } from "../../../../../../../../services/cloudchipr.api";

interface TaskDeleteActionMenuItemProps {
  task: Task;
  onClose(): void;
}

export const TaskDeleteActionMenuItem: FC<TaskDeleteActionMenuItemProps> = ({
  task,
  onClose,
}) => {
  const { open, openDialog, closeDialog } = useDialog();

  const { can } = useAppAbility();
  const canDeleteTask = can("delete", "task");

  const deletionLoading = useAppSelector(taskDeletionLoadingSelector);
  const getLoading = useAppSelector(tasksListGetLoadingSelector);

  const closeHandler = useCallback(() => {
    closeDialog();
    onClose();
  }, [closeDialog, onClose]);

  return (
    <Fragment>
      <MenuItem
        dense
        disabled={!canDeleteTask || deletionLoading || getLoading}
        onClick={openDialog}
      >
        <ListItemIcon>
          <DeleteOutlineIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>

      <TaskDeleteActionMenuDialog
        open={open}
        onClose={closeHandler}
        onDialogClose={closeHandler}
        tasks={[task]}
      />
    </Fragment>
  );
};
