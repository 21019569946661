import { ColumnSetupType } from "../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { KubernetesNamespace } from "../../../../../../../../../../../../../services/cloudchipr.api";

export const kubernetesNamespacesColumn: ColumnSetupType<KubernetesNamespace>[] =
  [
    {
      accessorKey: "name",
      header: "Namespace Name",
      size: 280,
      type: "identifier",
      meta: { sticky: "left", hideFromSettings: true },
    },
    {
      accessorKey: "total_monthly_cost",
      header: "Monthly Price",
      type: "money",
      headerTooltip:
        "Monthly prices are calculated based on the on-demand list price of each resource.",
    },
    {
      accessorKey: "cluster",
      header: "Cluster",
      type: "cluster",
    },
    {
      accessorKey: "pod_count",
      header: "Pods",
    },
    {
      accessorKey: "age_in_seconds",
      header: "Age",
      type: "secondsToFormattedDate",
    },
    {
      accessorKey: "labels",
      header: "Kubernetes Label",
      type: "tag",
      enableSorting: false,
    },
  ];
