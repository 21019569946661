import { FC } from "react";
import { Stack } from "@mui/material";
import { ResourceDetailsHeaderActionsBar } from "./ResourceDetailsHeaderActionsBar";
import { ResourceDetailsHeaderMetaBar } from "./ResourceDetailsHeaderMetaBar";
import { ResourceDetailsHeaderTabs } from "./ResourceDetailsHeaderTabs";
import { ResourceDetailsDateRange } from "../content/common/ResourceDetailsDateRange";
import { useAppSelector } from "../../../../../../store/hooks";
import { resourceDetailsActiveTabSelector } from "../../../../../../store/resource-details/selectors/options/resourceDetailsActiveTabSelector";
import { ResourceDetailsDataPointSelector } from "../content/common/ResourceDetailsDataPointSelector";

interface ResourceDetailsHeaderProps {
  onClose(): void;
}

export const ResourceDetailsHeader: FC<ResourceDetailsHeaderProps> = ({
  onClose,
}) => {
  const activeTab = useAppSelector(resourceDetailsActiveTabSelector);

  return (
    <Stack bgcolor="white">
      <ResourceDetailsHeaderActionsBar onClose={onClose} />
      <ResourceDetailsHeaderMetaBar />
      <ResourceDetailsHeaderTabs />

      {activeTab === "costs" && (
        <Stack
          spacing={1}
          direction="row"
          p={1.5}
          borderBottom={1}
          borderColor="grey.300"
        >
          <ResourceDetailsDateRange />
          <ResourceDetailsDataPointSelector />
        </Stack>
      )}
    </Stack>
  );
};
