import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../../../services/cloudchipr.api";
import { ReservationsCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { CommitmentsProgressBar } from "../../../../../../../../common/components/CommitmentsProgressBar";

export const getCoverageColumnDef = (
  grouping: SavingsPlanCoverageGrouping,
): ColDef<ReservationsCoverageDataWithId> => {
  const headerName =
    grouping !== "commitment" ? "Covered by this RI" : "RI Covers";

  return {
    flex: 1.5,
    field: "coverage",
    headerName,
    minWidth: 200,
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => {
      if (!data) {
        return;
      }

      return (
        <CommitmentsProgressBar
          overrideColor="info.light"
          value={data.coverage}
        />
      );
    },
  };
};
