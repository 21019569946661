import { FC, useCallback } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Threshold } from "../../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { currentBudgetAmountSelector } from "../../../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/currentBudgetAmountSelector";
import {
  getNumberByPercentage,
  getPercentage,
} from "../../../../../../../../../../../utils/numeral/getPercentage";
import { capitalizeString } from "../../../../../../../../../../../utils/helpers/capitalizeString";
import { fixNumber } from "../../utils/helpers/common";
import { currentBudgetPeriodSelector } from "../../../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/currentBudgetPeriodSelector";

interface ThresholdItemProps {
  index: number;
  removable: boolean;
  threshold: Threshold;
  onRemove(index: number): void;
  onChange(threshold: Threshold, index: number): void;
}

export const ThresholdItem: FC<ThresholdItemProps> = ({
  threshold,
  index,
  onRemove,
  onChange,
  removable,
}) => {
  const budgetAmount = useAppSelector(currentBudgetAmountSelector);
  const budgetPeriod = useAppSelector(currentBudgetPeriodSelector);
  const forecastAvailable =
    budgetPeriod === "monthly" || budgetPeriod === "quarterly";

  const amountChangeHandler = useCallback(
    (event: any) => {
      const value = +event.target.value;

      if (value < 0) {
        return;
      }

      onChange(
        {
          trigger: threshold.trigger,
          percent: getPercentage(value, budgetAmount),
        },
        index,
      );
    },
    [onChange, index, threshold.trigger, budgetAmount],
  );

  const percentageChangeHandler = useCallback(
    (event: any) => {
      const value = +event.target.value;

      if (value < 0) {
        return;
      }

      onChange(
        {
          trigger: threshold.trigger,
          percent: value,
        },
        index,
      );
    },
    [onChange, index, threshold.trigger],
  );

  const triggerChangeHandler = useCallback(
    (event: any) => {
      const value = event.target.value;

      onChange({ ...threshold, trigger: value }, index);
    },
    [onChange, index, threshold],
  );

  const removeHandler = useCallback(() => onRemove(index), [index, onRemove]);

  return (
    <Grid container gap={2} sx={removable ? removableContainerStyles : {}}>
      <Grid item flex={1}>
        <TextField
          fullWidth
          disabled={!budgetAmount}
          type="number"
          size="small"
          variant="outlined"
          label="Threshold"
          placeholder="0"
          sx={{ bgcolor: "white" }}
          InputProps={{
            startAdornment: (
              <Typography pr={1} color="text.secondary">
                %
              </Typography>
            ),
          }}
          value={fixNumber(threshold.percent, 2) || ""}
          onChange={percentageChangeHandler}
        />
      </Grid>

      <Grid item flex={1}>
        <TextField
          fullWidth
          disabled={!budgetAmount}
          type="number"
          size="small"
          variant="outlined"
          value={
            fixNumber(
              getNumberByPercentage(threshold.percent, budgetAmount),
              2,
            ) || ""
          }
          label="Amount"
          onChange={amountChangeHandler}
          placeholder="0"
          sx={{ bgcolor: "white" }}
          InputProps={{
            startAdornment: (
              <Typography pr={1} color="text.secondary">
                $
              </Typography>
            ),
          }}
        />
      </Grid>

      <Grid item flex={1}>
        <FormControl size="small" sx={{ bgcolor: "white" }} fullWidth>
          <InputLabel>Trigger</InputLabel>

          <Select
            label="Trigger"
            value={threshold.trigger}
            onChange={triggerChangeHandler}
            renderValue={(val) => capitalizeString(val)}
            disabled={!budgetAmount}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
          >
            <MenuItem value="actual">
              <ListItemText>Actual</ListItemText>
            </MenuItem>

            {forecastAvailable ? (
              <MenuItem value="forecasted">
                <ListItemText>Forecasted</ListItemText>
              </MenuItem>
            ) : (
              <Tooltip
                arrow
                placement="right"
                title="The Forecasted trigger is only available for monthly and quarterly budget periods."
              >
                <span>
                  <MenuItem disabled>
                    <ListItemText>Forecasted</ListItemText>
                  </MenuItem>
                </span>
              </Tooltip>
            )}
          </Select>
        </FormControl>
      </Grid>

      {removable && (
        <Grid item xs={1}>
          <IconButton onClick={removeHandler}>
            <DeleteOutlineIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

const removableContainerStyles = {
  bgcolor: "grey.100",
  borderRadius: 1,
  p: 1,
};
