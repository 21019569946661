import { FC } from "react";
import { useLocation } from "react-router-dom";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import { useFlag } from "@unleash/proxy-client-react";
import { LiveUsageNavigationItem } from "./LiveUsageNavigationItem";
import { AppNavItemWrapper } from "../../app-nav-components/AppNavItemWrapper";
import { useAppSelector } from "../../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { generateLiveResourcesPath } from "../../../../pages/accounts-group/utils/helpers/generateLiveResourcesPath";
import { liveUsageNavigationAllItemsSelector } from "../../../../../store/accounts/selectors/liveUsageNavigationAllItemsSelector";
import { biggestOrgSelector } from "../../../../../store/accounts/selectors/all-providers/biggestOrgSelector";
import { liveUsageMgmtProviderSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { ProviderType } from "../../../../../services/cloudchipr.api";

export const LiveUsageNavigation: FC = () => {
  const allAccountsGroupedByProviderAndOrg = useAppSelector(
    liveUsageNavigationAllItemsSelector,
  );
  const selectedProvider = useAppSelector(liveUsageMgmtProviderSelector);
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);
  const location = useLocation();
  const selected = ["aws", "gcp", "azure", "kubernetes"].some((item) =>
    location.pathname.startsWith(`/providers/${item}`),
  );
  const enableKubernetes = useFlag("EnableKubernetes");

  const biggestOrg = useAppSelector(biggestOrgSelector);

  if (!biggestOrg) {
    return null;
  }

  return (
    <AppNavItemWrapper
      divider
      to={generateLiveResourcesPath({
        provider: biggestOrg.provider,
        orgId: biggestOrg.orgId,
      })}
      primary="Live Resources"
      Icon={PlayCircleOutlineOutlinedIcon}
      selected={selected}
      navCollapsed={navigationCollapsed}
    >
      {allAccountsGroupedByProviderAndOrg.map((provider) => {
        if ((provider === "kubernetes" && !enableKubernetes) || !provider) {
          return null;
        }

        return (
          <LiveUsageNavigationItem
            key={provider}
            provider={provider as ProviderType}
            selectedProvider={
              provider === "kubernetes" ? "kubernetes" : selectedProvider
            }
          />
        );
      })}
    </AppNavItemWrapper>
  );
};
