import { FC } from "react";
import { Fab } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

interface AskAiFloatingButtonProps {
  onClick(): void;
}

export const AskAiFloatingButton: FC<AskAiFloatingButtonProps> = ({
  onClick,
}) => {
  return (
    <Fab
      sx={{
        position: "fixed",
        bottom: 32,
        right: 32,
        background: "linear-gradient(100deg, #1443EA, #7334F1)",
        borderColor: "linear-gradient(100deg, #1443EA, #7334F1)",
        height: 48,
        width: 48,
      }}
      onClick={onClick}
    >
      <AutoAwesomeIcon fontSize="small" sx={{ color: "white" }} />
    </Fab>
  );
};
