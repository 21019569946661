import { coverageWidgetSetupIdSelector } from "./coverageWidgetSetupIdSelector";
import { coverageWidgetSetupOrgIdSelector } from "./coverageWidgetSetupOrgIdSelector";
import { coverageWidgetSetupNameSelector } from "./coverageWidgetSetupNameSelector";
import { RootState } from "../../../../store";
import { coverageWidgetEditChangesExistSelector } from "../../widgets/coverage/setup/coverageWidgetEditChangesExistSelector";

export const coverageSubmitButtonDisabledSelector = (state: RootState) => {
  const id = coverageWidgetSetupIdSelector(state);
  const providerOrgId = coverageWidgetSetupOrgIdSelector(state);
  const name = coverageWidgetSetupNameSelector(state);
  const editDisabledByChange = coverageWidgetEditChangesExistSelector(state);

  return (id && !editDisabledByChange) || !name || !providerOrgId;
};
