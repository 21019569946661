import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { currentBudgetSelector } from "../currentBudgetSelector";
import { FormInitialValues } from "../../../../../components/pages/budgets-and-alerts/components/drawer/components/budgets/components/create-budget/utils/types/formInitialValues";

export const formDataSelector = createDraftSafeSelector(
  [currentBudgetSelector],
  (budget): FormInitialValues | null => {
    if (!budget) {
      return null;
    }

    return {
      name: budget.body.name,
      viewId: budget["view-id"],
      period: budget.body.period,
      startDate: budget.body.start_date,
      amount: budget.body.amount || "",
      thresholds: budget.body.thresholds,
      email_to: budget.body.email_to ?? null,
      notifications: budget.body.notifications ?? null,
      receive_updates: budget.body.receive_updates,
    };
  },
);
