import { Collapse, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { BillingExplorerExplainWithAiContentNotCollapsedState } from "./BillingExplorerExplainWithAiContentNotCollapsedState";
import { BillingExplorerExplainWithAiContentCollapsedState } from "./BillingExplorerExplainWithAiContentCollapsedState";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { billingExplorerExplainWithAiDataSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiDataSelector";
import { billingExplorerExplainWithAiNewDataNeededSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiNewDataNeededSelector";
import { billingExplorerExplainWithAiOpenSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiOpenSelector";

export const BillingExplorerExplainWithAiCollapsableContent = () => {
  const explainWithAiData = useAppSelector(
    billingExplorerExplainWithAiDataSelector,
  );
  const newDataNeeded = useAppSelector(
    billingExplorerExplainWithAiNewDataNeededSelector,
  );
  const explainWithAiOpen = useAppSelector(
    billingExplorerExplainWithAiOpenSelector,
  );

  const [state, setState] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);

  const clickHandler = useCallback(() => {
    setState((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (explainWithAiOpen) {
      setLocalLoading(true);

      const timer = setTimeout(() => {
        setLocalLoading(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [explainWithAiOpen]);

  useEffect(() => {
    if (explainWithAiData?.length === 1) {
      if (newDataNeeded) {
        setState(false);
      } else {
        setState(true);
      }
    }
  }, [newDataNeeded, setState, explainWithAiData]);

  return (
    <Stack borderRadius={2}>
      {explainWithAiData?.length === 1 ? (
        <Stack bgcolor="grey.200" borderRadius={2}>
          {newDataNeeded && (
            <BillingExplorerExplainWithAiContentNotCollapsedState
              onClick={clickHandler}
              open={state}
            />
          )}
          <Collapse in={state}>
            <BillingExplorerExplainWithAiContentCollapsedState
              localLoading={localLoading}
            />
          </Collapse>
        </Stack>
      ) : (
        <BillingExplorerExplainWithAiContentCollapsedState
          localLoading={localLoading}
        />
      )}
    </Stack>
  );
};
