import { FC } from "react";
import Box from "@mui/material/Box";
import { blue, deepPurple } from "@mui/material/colors";
import { Stack } from "@mui/material";
import { getPercentage } from "../../../../../../../../../utils/numeral/getPercentage";

interface KubernetesClusterCardFooterProps {
  total: number;
  usage: number;
  requested: number;
}
export const KubernetesClusterCardFooterProgress: FC<
  KubernetesClusterCardFooterProps
> = ({ total, usage, requested }) => {
  const safeTotal = total || 1;

  const usagePercent =
    safeTotal > 0 ? getPercentage(usage, safeTotal).toFixed(2) : 0;
  const requestedPercent =
    safeTotal > 0 ? getPercentage(requested, safeTotal).toFixed(2) : 0;

  return (
    <Stack spacing={0.5}>
      <Box
        width="100%"
        height={8}
        bgcolor="grey.300"
        borderRadius={2}
        overflow="hidden"
      >
        <Box width={`${usagePercent ?? 0}%`} bgcolor={blue[400]} height={8} />
      </Box>

      <Box
        width="100%"
        height={8}
        bgcolor="grey.300"
        borderRadius={2}
        overflow="hidden"
      >
        <Box
          width={`${requestedPercent ?? 0}%`}
          bgcolor={deepPurple["A100"]}
          height={8}
        />
      </Box>
    </Stack>
  );
};
