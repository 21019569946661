import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { AzureReservationsCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import { getAzureCoveredUsageColumnDef } from "../coverage/common/getAzureCoveredUsageColumnDef";
import { azureCoveredColumnDef } from "../coverage/common/azureCoveredColumnDef";
import { azureNetSavingsColumnDef } from "../coverage/common/azureNetSavingsColumnDef";
import { getAzureCoverageColumnDef } from "../coverage/common/getAzureCoverageColumnDef";
import { azureTotalCoverageColumnDef } from "../coverage/common/azureTotalCoverageColumnDef";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const azureReservationGroupedByResourceTypeColumnDefs: ColDef<AzureReservationsCoverageDataWithId>[] =
  [
    {
      field: "resource_type.resource_type",
      headerName: "Resource Type",
      suppressKeyboardEvent: () => true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      width: 230,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureReservationsCoverageDataWithId>) => (
        <TypographyWithTooltip
          title={data?.resource_type?.resource_type ?? ""}
          variant="body2"
          align="left"
          textTransform="capitalize"
        />
      ),
    },
    {
      field: "resource_type.product_name",
      headerName: "Product Name",
      width: 240,
      flex: 240,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureReservationsCoverageDataWithId>) => {
        return data?.resource_type?.product_name ?? "-";
      },
    },
    {
      field: "resource_type.region",
      headerName: "Region/AZ",
      width: 230,
      flex: 230,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureReservationsCoverageDataWithId>) => {
        return data?.resource_type?.region ?? null;
      },
    },
    getAzureCoveredUsageColumnDef("commitment"),
    azureCoveredColumnDef,
    azureNetSavingsColumnDef,
    getAzureCoverageColumnDef("commitment"),
    azureTotalCoverageColumnDef,
    agGridEmptyColumnToFitEmptySpace,
  ];
