import { FC, ReactElement, useCallback } from "react";
import { FormControl, MenuItem, Select, Stack } from "@mui/material";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";

import { useAppDispatch } from "../../../../../../../store/hooks";
import { setResourceDetailsBillingDataProcess } from "../../../../../../../store/resource-details/resourceDetailsSlice";
import { ResourceDetailsChartTypes } from "../../../../../../../store/resource-details/utils/types/common";
import { getResourceDetailsResourceBillingDataThunk } from "../../../../../../../store/resource-details/thunks/data/getResourceDetailsResourceBillingDataThunk";
import { highchartsChartTypeIcons } from "../../../../../../../storybook/c8r-highcharts/common/components/HighchartsChartTypeSelect";

interface ResourceDetailsGeneralContentCostsChartTypeProps {
  processKey: string;
  chartType: ResourceDetailsChartTypes;
}

export const ResourceDetailsGeneralContentCostsChartType: FC<
  ResourceDetailsGeneralContentCostsChartTypeProps
> = ({ processKey, chartType }) => {
  const dispatch = useAppDispatch();

  const chartTypeChangeHandler = useCallback(
    (event: any) => {
      const newChartType = event.target.value as ResourceDetailsChartTypes;

      dispatch(
        setResourceDetailsBillingDataProcess({
          key: processKey,
          chartType: event.target.value as ResourceDetailsChartTypes,
        }),
      );

      if (newChartType === "table" || chartType === "table") {
        dispatch(getResourceDetailsResourceBillingDataThunk());
      }
    },
    [dispatch, chartType, processKey],
  );

  return (
    <FormControl size="small">
      <Select
        MenuProps={{ disablePortal: true }}
        value={chartType}
        size="small"
        sx={selectStyles}
        onChange={chartTypeChangeHandler}
        renderValue={(type: ResourceDetailsChartTypes) => (
          <Stack alignItems="center" height="inherit">
            {icons[type]}
          </Stack>
        )}
      >
        {Object.entries(icons).map(([type, Icon]) => (
          <MenuItem value={type} key={type}>
            {Icon}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const selectStyles = {
  bgcolor: "white",
  "& .MuiSelect-select": { p: 0.5 },
};

const icons: Record<ResourceDetailsChartTypes, ReactElement> = {
  line: highchartsChartTypeIcons.line,
  column: highchartsChartTypeIcons.column,
  stackedColumn: highchartsChartTypeIcons.stackedColumn,
  table: <TableChartOutlinedIcon color="action" fontSize="medium" />,
};
