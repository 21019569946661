import { billingExplorerWidgetByIdSelector } from "./billingExplorerWidgetByIdSelector";
import { RootState } from "../../../../../store";
import { objectToUrl } from "../../../../../../components/utils/helpers/dataToUrlToDataConvertors";

export const billingExplorerWidgetBillingExportUrlSelector = (
  state: RootState,
  widgetId: string,
) => {
  const widget = billingExplorerWidgetByIdSelector(state, widgetId);

  if (!widget) {
    return "";
  }

  const path = objectToUrl([
    {
      groupedBy: widget.grouping,
      groupValues: widget.group_values,
      forecastOption: widget.forecast_option,
      dates: widget.dates,
      dateGranularity: widget.date_granularity,
      chartType: widget.chart_type,
      filterTree: undefined,
    },
  ]);

  return `/resource-explorer/${widget.view_id}?path=${path}`;
};
