import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dates } from "../../../../../../services/cloudchipr.api";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { billingExplorerWidgetSetupSelector } from "../../../../selectors/setups/billing-explorer/billingExplorerWidgetSetupSelector";
import { getBillingExplorerWidgetSetupDataThunk } from "../getBillingExplorerWidgetSetupDataThunk";

export const billingExplorerDateRangeChangeThunk = createAsyncThunk(
  "dashboards/billingExplorerWidgetDateRangeChange",
  async (dates: Dates, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = billingExplorerWidgetSetupSelector(state);

    if (!existingSetup || !dates) {
      return;
    }

    dispatch(setWidgetSetup({ ...existingSetup, dates }));

    dispatch(getBillingExplorerWidgetSetupDataThunk());
  },
);
