import { FC, Fragment } from "react";
import { Divider, Stack } from "@mui/material";
import { TaskCreationDrawerTaskDetails } from "./details-section/TaskCreationDrawerTaskDetails";
import { TaskCreationDrawerActivities } from "./activities-section/TaskCreationDrawerActivities";
import { useAppSelector } from "../../../../../../../store/hooks";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";

export const TaskCreationDrawerContent: FC = () => {
  const editMode = useAppSelector(isTaskManagementDrawerEditModeSelector);

  return (
    <Stack height="100%" direction="row" overflow="auto">
      <TaskCreationDrawerTaskDetails />

      {editMode && (
        <Fragment>
          <Divider orientation="vertical" />
          <TaskCreationDrawerActivities />
        </Fragment>
      )}
    </Stack>
  );
};
