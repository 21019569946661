import { FC, Fragment } from "react";
import { Box, Stack } from "@mui/material";
import { useDidMount, useIntervalWhen } from "rooks";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AccountsPageTabs } from "../../../../utils/types/types";
import { KubernetesCluster } from "../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { accountsFilterValueSelector } from "../../../../../../../store/accounts/selectors/slice-data/accountsFilterValueSelector";
import { accountsViewSelector } from "../../../../../../../store/accounts/selectors/slice-data/accountsViewSelector";
import { useAccountsDnD } from "../../../../../live-usage-mgmt/utils/hooks/useAccountsDnD";
import { getKubernetesClustersThunk } from "../../../../../../../store/accounts/thunks/getKubernetesClustersThunk";
import { AccountsEmptyTemplate } from "../../../accounts/AccountsEmptyTemplate";
import { KubernetesClustersGridView } from "../clusters-grid/KubernetesClustersGridView";
import { KubernetesClusterAddButton } from "../header/add-cluster/KubernetesClusterAddButton";
import { KubernetesClustersToolbar } from "../toolbar/KubernetesClustersToolbar";
import { KubernetesClustersCashs } from "../KubernetesClustersCashs";
import { KubernetesClustersListView } from "../clusters-list/KubernetesClustersListView";
import { noOrgAccountOrgId } from "../../../../utils/helpers/noOrganizationConstants";
import { kubernetesClustersCountSelector } from "../../../../../../../store/accounts/selectors/kubernetes/kubernetesClustersCountSelector";
import { AccountsActiveLength } from "../../../accounts/AccountsActiveLength";

interface KubernetesClustersPageBodyProps {
  clusters: Record<AccountsPageTabs, KubernetesCluster[]>;
  activeTab: AccountsPageTabs;
}

export const KubernetesClustersPageBody: FC<
  KubernetesClustersPageBodyProps
> = ({ clusters, activeTab }) => {
  const dispatch = useAppDispatch();

  const clustersCount = useAppSelector(kubernetesClustersCountSelector);
  const filterValue = useAppSelector(accountsFilterValueSelector);
  const accountsView = useAppSelector(accountsViewSelector);

  const { sortedData, onSortingChange } = useAccountsDnD(
    "kubernetes",
    clusters[activeTab],
    activeTab,
    noOrgAccountOrgId,
  );

  useDidMount(() => {
    dispatch(getKubernetesClustersThunk());
  });

  useIntervalWhen(
    () => {
      // TODO: Instead of using interval, we should use polling mechanism from RTK
      dispatch(getKubernetesClustersThunk());
    },
    600_000,
    true,
  );

  if (!clusters) {
    return null;
  }

  const accountsByView = {
    grid: (
      <KubernetesClustersGridView
        clusters={sortedData as KubernetesCluster[]}
        onSortingChange={onSortingChange}
      />
    ),
    list: (
      <KubernetesClustersListView
        clusters={sortedData as KubernetesCluster[]}
        onSortingChange={onSortingChange}
      />
    ),
    bar: (
      <KubernetesClustersListView
        withBar
        clusters={sortedData as KubernetesCluster[]}
        onSortingChange={onSortingChange}
      />
    ),
  };

  const isEmpty = !(clusters[activeTab]?.length || filterValue);

  return (
    <Stack pb={isEmpty ? 0 : 2} pt={isEmpty || activeTab === "active" ? 0 : 2}>
      {isEmpty ? (
        <AccountsEmptyTemplate
          provider="kubernetes"
          onlyInActive={!!clusters.inactive?.length}
          actionButton={<KubernetesClusterAddButton />}
        />
      ) : (
        <Fragment>
          {activeTab === "active" && (
            <AccountsActiveLength
              provider="kubernetes"
              activeAccountsLength={clustersCount ?? 0}
            />
          )}

          <Stack px={1} spacing={1}>
            <KubernetesClustersToolbar tabValue={activeTab} />

            <KubernetesClustersCashs />
          </Stack>

          <Box px={1} mt={accountsView === "grid" ? 0 : 1}>
            <DndProvider backend={HTML5Backend} context={window}>
              {!!sortedData && accountsView && accountsByView[accountsView]}
            </DndProvider>
          </Box>
        </Fragment>
      )}
    </Stack>
  );
};
