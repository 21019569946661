import { FC, Fragment } from "react";
import { Card, Box } from "@mui/material";
import { useDidMount } from "rooks";
import { RightsizingRecommendationsEmptyState } from "./components/RightsizingRecommendationsEmptyState";
import { RightsizingRecommendationWarnings } from "./components/warnings/RightsizingRecommendationWarnings";
import { RightsizingRecommendationsTableContent } from "./components/table/RightsizingRecommendationsTableContent";
import { checkAdvancedFiltersPreferenceExistence } from "./utils/helpers/checkAdvancedFiltersPreferenceExistence";
import { ResourceRestoreConfirmDialog } from "../common/dismiss/by-resource/ResourceRestoreConfirmDialog";
import { RecommendationDismissConfirmDialog } from "../common/dismiss/RecommendationDismissConfirmDialog";
import { RecommendationRestoreConfirmDialog } from "../common/dismiss/RecommendationRestoreConfirmDialog";
import { PageHeader } from "../../../common/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { getRightSizingRecommendationsStatusesThunk } from "../../../../../store/recommendations/thunks/rightsizing/getRightSizingRecommendationsStatusesThunk";
import { allProvidersStatusesNotDisabledSelector } from "../../../../../store/recommendations/selectors/rightsizing/statuses/allProvidersStatusesNotDisabledSelector";
import { allProvidersStatusesDisabledSelector } from "../../../../../store/recommendations/selectors/rightsizing/statuses/allProvidersStatusesDisabledSelector";
import { rightsizingRecommendationsStatusLoadingSelector } from "../../../../../store/recommendations/selectors/rightsizing/statuses/rightsizingRecommendationsStatusLoadingSelector";
import { getRightsizingAllRecommendationsThunk } from "../../../../../store/recommendations/thunks/rightsizing/getRightsizingAllRecommendationsThunk";
import { rightsizingRecommendationsLoadingSelector } from "../../../../../store/recommendations/selectors/rightsizing/loading/rightsizingRecommendationsLoadingSelector";
import { rightsizingRecommendationsDataSelector } from "../../../../../store/recommendations/selectors/rightsizing/data/rightsizingRecommendationsDataSelector";
import { rightsizingDismissedRecommendationsLoadingSelector } from "../../../../../store/recommendations/selectors/rightsizing/loading/rightsizingDismissedRecommendationsLoadingSelector";
import { getRightsizingDismissedResourcesThunk } from "../../../../../store/recommendations/thunks/rightsizing/dismiss/getRightsizingDismissedResourcesThunk";
import { getRightsizingRecommendationsAdvancedFiltersThunk } from "../../../../../store/recommendations/thunks/rightsizing/getRightsizingRecommendationsAdvancedFiltersThunk";
import { getRightsizingAdvancedFiltersSettingsLoadingSelector } from "../../../../../store/recommendations/selectors/rightsizing/advanced-filters/getRightsizingAdvancedFiltersSettingsLoadingSelector";
import { resetRecommendationsFilters } from "../../../../../store/recommendations/recommendationsSlice";
import { rightsizingAdvancedFiltersSettingsSelector } from "../../../../../store/recommendations/selectors/rightsizing/advanced-filters/rightsizingAdvancedFiltersSettingsSelector";

export const RightsizingRecommendations: FC = () => {
  const dispatch = useAppDispatch();

  const rightSizingRecommendations = useAppSelector(
    rightsizingRecommendationsDataSelector,
  );
  const allDisabled = useAppSelector(allProvidersStatusesDisabledSelector);
  const preferences = useAppSelector(
    rightsizingAdvancedFiltersSettingsSelector,
  );
  const dataLoading = useAppSelector(rightsizingRecommendationsLoadingSelector);
  const dismissedDataLoading = useAppSelector(
    rightsizingDismissedRecommendationsLoadingSelector,
  );
  const statusesLoading = useAppSelector(
    rightsizingRecommendationsStatusLoadingSelector,
  );
  const advancedFiltersLoading = useAppSelector(
    getRightsizingAdvancedFiltersSettingsLoadingSelector,
  );

  const loading =
    statusesLoading ||
    dismissedDataLoading ||
    dataLoading ||
    advancedFiltersLoading;

  const advancedFilterPreferenceExists =
    checkAdvancedFiltersPreferenceExistence(preferences);
  const allNotDisabled = useAppSelector(
    allProvidersStatusesNotDisabledSelector,
  );
  const allEnabledAndNotData =
    allNotDisabled && !rightSizingRecommendations?.length;
  const noRecommendation =
    (allEnabledAndNotData || allDisabled) && !advancedFilterPreferenceExists;

  useDidMount(() => {
    dispatch(resetRecommendationsFilters());
    dispatch(getRightsizingAllRecommendationsThunk());
    dispatch(getRightsizingDismissedResourcesThunk());
    dispatch(getRightSizingRecommendationsStatusesThunk());
    dispatch(getRightsizingRecommendationsAdvancedFiltersThunk());
  });

  return (
    <Fragment>
      <PageHeader
        title="Rightsizing Recommendations"
        actions={<RightsizingRecommendationWarnings />}
      />

      {!loading && noRecommendation ? (
        <RightsizingRecommendationsEmptyState
          reason={allEnabledAndNotData ? "noData" : "notEnabled"}
        />
      ) : (
        <Box sx={{ m: 2 }}>
          <Card variant="outlined">
            <RightsizingRecommendationsTableContent />
          </Card>
        </Box>
      )}

      <RecommendationDismissConfirmDialog />
      <RecommendationRestoreConfirmDialog />
      <ResourceRestoreConfirmDialog recommendationType="rightsizing" />
    </Fragment>
  );
};
