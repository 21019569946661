import {
  FC,
  MouseEvent,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from "react";
import { Chip, IconButton } from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Stack from "@mui/material/Stack";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffectOnceWhen } from "rooks";
import { FilterTriggerComponentProps } from "../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { taskAvailablePropertiesSelector } from "../../../../../../../../store/task-management/selectors/properties/taskAvailablePropertiesSelector";
import { useHover } from "../../../../../../../../utils/hooks/useHover.hook";
import { TaskPropertyTriggerWrapper } from "../TaskPropertyTriggerWrapper";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { TaskProperties } from "../../../../../../task-management/utils/types/types";

interface TaskPropertySelectionTriggerProps
  extends FilterTriggerComponentProps {
  optionAvatar?: ReactElement;
  value?: string | null;
  inCell?: boolean;
  optionsKey: TaskProperties;
  removable: boolean;
}

export const TaskPropertySelectionTrigger: FC<
  TaskPropertySelectionTriggerProps
> = ({
  optionAvatar,
  optionsKey,
  removable,
  value,
  onClick,
  onCloseAndSubmit,
  disabled,
  inCell,
}) => {
  const { ref, hovered } = useHover();
  const labelRef = useRef<HTMLDivElement | null>(null);
  const [labelWidth, setLabelWidth] = useState<number | undefined>(undefined);

  const data = useAppSelector(taskAvailablePropertiesSelector);
  const options = data?.[optionsKey];
  const selectedOption = options?.find((option) => option.id === value);

  const removeHandler = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      onCloseAndSubmit([]);
    },
    [onCloseAndSubmit],
  );

  const canRemove = !disabled && removable && selectedOption && hovered;

  useEffectOnceWhen(() => {
    setLabelWidth(Math.max((labelRef?.current?.clientWidth ?? 0) + 5, 30));
  }, !!labelRef?.current?.clientWidth);

  return (
    <TaskPropertyTriggerWrapper
      inCell={inCell}
      refToPass={ref}
      hovered={hovered}
      onClick={onClick}
      disabled={disabled}
    >
      {selectedOption ? (
        <Chip
          size="small"
          variant="filled"
          avatar={optionAvatar}
          label={
            <Stack
              ref={labelRef}
              direction="row"
              minWidth={labelWidth}
              maxWidth={labelWidth}
            >
              <TypographyWithTooltip
                variant="inherit"
                title={selectedOption.name}
              />

              {canRemove && (
                <IconButton size="small" onClick={removeHandler} sx={{ p: 0 }}>
                  <CancelIcon sx={{ fontSize: 14, color: "white" }} />
                </IconButton>
              )}
            </Stack>
          }
          onClick={disabled ? undefined : onClick}
          sx={{
            mr: 0.5,
            color: "white",
            overflow: "hidden",
            bgcolor: selectedOption.color,
            "&:hover": { bgcolor: selectedOption.color },
          }}
        />
      ) : (
        <HorizontalRuleIcon color="action" fontSize="small" />
      )}
    </TaskPropertyTriggerWrapper>
  );
};
