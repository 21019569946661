import { getResourceExplorerPossibleGroupingsFromAccountServiceSelector } from "./getResourceExplorerPossibleGroupingsFromAccountServiceSelector";
import { RootState } from "../../../store";

export const resourceExplorerInternalPossibleGroupingsSelector = (
  state: RootState,
  organisationId: string,
) => {
  return getResourceExplorerPossibleGroupingsFromAccountServiceSelector(
    state,
    organisationId,
  )?.data;
};
