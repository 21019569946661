import { FC, Fragment, useMemo } from "react";
import { grey } from "@mui/material/colors";
import { Box, Theme } from "@mui/material";
import { getUnauthorizedBillingExplorerWidgetTableViewColumns } from "./getUnauthorizedBillingExplorerWidgetTableViewColumns";
import { ResourceExplorerGridDataWithId } from "../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";
import {
  DateDataPointNullable,
  DateLabelNullable,
  ForecastedCost,
  ForecastOption,
  ResourceExplorerGroupingNullable,
  WidgetCostBreakdownAggregation,
  WidgetCostBreakdownVisualization,
} from "../../../../../services/cloudchipr.api";
import { WidgetCosts } from "../../../../../store/dashboards/utils/types/types";
import { calculateTotalCostTrend } from "../../../../pages/utils/helpers/calculateTotalCostTrend";
import { getColumnVisibilityByVisualizationAndForecastedOption } from "../../../../pages/dashboard/components/widgets/billing-explorer-widget/components/chart/table/utils/helpers/getColumnVisibilityByVisualizationAndForecastedOption";
import { WidgetTotalsSummary } from "../../../../pages/dashboard/components/widgets/common/WidgetTotalsSummary";
import { DataGrid } from "../../../../../storybook/data-grid/DataGrid";
import { widgetSetupContentChartHeight } from "../../../../pages/dashboard/utils/constants/stylings";
import { useAppSelector } from "../../../../../store/hooks";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";

interface UnauthorizedBillingExplorerWidgetTableViewProps {
  originalDataCount?: number;
  gridData?: ResourceExplorerGridDataWithId[];
  grouping: ResourceExplorerGroupingNullable;
  forecastOption: ForecastOption;
  forecastedCosts?: ForecastedCost[];
  widgetCosts?: WidgetCosts;
  visualization: WidgetCostBreakdownVisualization;
  dateLabel?: DateLabelNullable;
  dateDataPoint: DateDataPointNullable;
  aggregation: WidgetCostBreakdownAggregation;
}

export const UnauthorizedBillingExplorerWidgetTableView: FC<
  UnauthorizedBillingExplorerWidgetTableViewProps
> = ({
  forecastOption,
  grouping,
  gridData,
  forecastedCosts,
  originalDataCount,
  widgetCosts,
  visualization,
  dateLabel,
  dateDataPoint,
  aggregation,
}) => {
  const groupingLabel = useAppSelector((state) =>
    resourceExplorerGroupingLabelByGroupingSelector(state, grouping),
  );

  const columns = useMemo(() => {
    const forecastedCost = forecastedCosts?.find(
      (item) => item.forecast_option === forecastOption,
    );

    const total = widgetCosts?.total.cost ?? 0;
    const previous = widgetCosts?.previousPeriodTotal?.cost ?? 0;
    const trend =
      previous > 0 ? calculateTotalCostTrend(total, previous) : null;

    return getUnauthorizedBillingExplorerWidgetTableViewColumns({
      trend,
      grouping,
      groupingLabel: groupingLabel?.singular ?? "",
      forecastOption,
      forecastedCost,
      total: widgetCosts?.total,
      dataCount: originalDataCount ?? 0,
      previousPeriodTotal: widgetCosts?.previousPeriodTotal,
    });
  }, [
    originalDataCount,
    forecastOption,
    grouping,
    forecastedCosts,
    widgetCosts?.previousPeriodTotal,
    widgetCosts?.total,
    groupingLabel?.singular,
  ]);

  const columnVisibility = useMemo(
    () =>
      getColumnVisibilityByVisualizationAndForecastedOption(
        visualization ?? {},
        forecastOption,
      ),
    [visualization, forecastOption],
  );

  return (
    <Fragment>
      <Box p={2}>
        {dateDataPoint && forecastOption && widgetCosts && (
          <WidgetTotalsSummary
            frequency={dateDataPoint}
            costsData={widgetCosts}
            forecastOption={forecastOption}
            dateLabel={dateLabel}
            showTrends={visualization.trend}
            aggregations={aggregation}
          />
        )}
      </Box>

      <DataGrid
        styles={styles}
        columns={columns}
        data={gridData ?? []}
        columnVisibility={{
          initialVisibility: columnVisibility,
        }}
        enableRowsVirtualization={gridData && gridData?.length > 30}
        columnResizeMode="onEnd"
      />
    </Fragment>
  );
};

const styles = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& tr:hover td": { bgcolor: grey[100] },
    "& tr td": { borderColor: grey[100], py: 0.5, pl: 2 },
  },

  tableHeaderRow: {
    "& th": {
      p: 1,
      verticalAlign: "inherit",
      bgcolor: grey[100],
    },
    top: 0,
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
  },
  tableRow: {
    height: 40,
  },

  tableContainer: {
    border: 1,
    borderColor: grey[200],
    maxHeight: `${widgetSetupContentChartHeight}px`,
  },
};
