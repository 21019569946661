import { FC, useCallback, useState } from "react";
import { GridApi } from "ag-grid-community";
import { Stack } from "@mui/material";
import { CommitmentsDrawerDataGridGrouping } from "./CommitmentsDrawerDataGridGrouping";
import { GlobalFilter } from "../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { CommitmentCsvExport } from "../../data-grid/CommitmentCsvExport";

interface CommitmentsDrawerAGGridToolbarProps {
  gridApi: GridApi | null;
}

export const CommitmentsDrawerAGGridToolbar: FC<
  CommitmentsDrawerAGGridToolbarProps
> = ({ gridApi }) => {
  const [searchValue, setSearchValue] = useState("");

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridApi?.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [gridApi],
  );

  return (
    <Stack
      pb={1.5}
      direction="row"
      alignItems="start"
      justifyContent="space-between"
    >
      <CommitmentsDrawerDataGridGrouping />

      <Stack alignItems="center" direction="row" spacing={2}>
        <GlobalFilter
          globalFilter={searchValue}
          setGlobalFilter={searchChangeHandler}
          size="xsmall"
          sx={{ width: 260 }}
        />

        <CommitmentCsvExport gridApi={gridApi} />
      </Stack>
    </Stack>
  );
};
