import { FC, useCallback, useMemo } from "react";
import { Switch, Tooltip } from "@mui/material";
import { useToggle } from "rooks";
import { AlertTabType } from "../../../../../../../store/alerts/utils/types/types";
import { AlertStatus } from "../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { toggleAlertStatusByIdAndTypeThunk } from "../../../../../../../store/alerts/thunks/common/toggleAlertStatusByIdAndTypeThunk";
import { toggleAlertStatusLoadingSelector } from "../../../../../../../store/alerts/selectors/common/toggleAlertStatusLoadingSelector";
import { useAppAbility } from "../../../../../../../services/permissions";
import { organizationsForCommitmentsSelector } from "../../../../../../../store/commitments/selectors/organisations/organizationsForCommitmentsSelector";

interface AlertsDataGridSwitchProps {
  id: string;
  orgId?: string;
  disabled?: boolean;
  status: AlertStatus;
  alertsActiveTab: AlertTabType;
  disabledTooltipTitle?: string;
}

export const AlertsDataGridSwitch: FC<AlertsDataGridSwitchProps> = ({
  id,
  orgId,
  status,
  disabled,
  alertsActiveTab,
  disabledTooltipTitle,
}) => {
  const dispatch = useAppDispatch();
  const [checked, toggleChecked] = useToggle(status === "active");
  const { cannot } = useAppAbility();
  const cannotEdit = cannot("edit", "alert");
  const organizations = useAppSelector(organizationsForCommitmentsSelector);
  const noCommitment = useMemo(() => {
    const org = organizations?.find((org) => org.organization_id === orgId);

    return !org && alertsActiveTab === "utilization";
  }, [organizations, alertsActiveTab, orgId]);
  const title = noCommitment
    ? "You don’t have any commitments in the selected organization."
    : disabledTooltipTitle;

  const loading = useAppSelector((state) =>
    toggleAlertStatusLoadingSelector(state, id, alertsActiveTab),
  );

  const handleClick = useCallback(() => {
    toggleChecked();
    dispatch(
      toggleAlertStatusByIdAndTypeThunk({ id, status, alertsActiveTab }),
    );
  }, [toggleChecked, dispatch, id, status, alertsActiveTab]);

  return (
    <Tooltip
      arrow
      placement="top"
      title={noCommitment || disabled ? title : ""}
    >
      <span>
        <Switch
          size="small"
          checked={checked}
          onClick={handleClick}
          disabled={loading || cannotEdit || disabled || noCommitment}
        />
      </span>
    </Tooltip>
  );
};
