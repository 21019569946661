import { taskManagementTargetSelector } from "./taskManagementTargetSelector";
import { RootState } from "../../../../../store";
import { taskManagementTargetResourcesForPayloadSelector } from "../resources/taskManagementTargetResourcesForPayloadSelector";
import { taskManagementTargetOpportunitiesForPayloadSelector } from "../opportunities/taskManagementTargetOpportunitiesForPayloadSelector";

export const taskManagementTargetsLengthSelector = (state: RootState) => {
  const target = taskManagementTargetSelector(state);
  const targetResources =
    taskManagementTargetResourcesForPayloadSelector(state);
  const targetOpportunities =
    taskManagementTargetOpportunitiesForPayloadSelector(state);

  if (target?.type === "resource") {
    return targetResources?.length ?? 0;
  }

  if (target?.type === "opportunity") {
    return targetOpportunities?.length ?? 0;
  }
};
