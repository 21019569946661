import { billingExplorerSetupPropertyByKeySelector } from "./billingExplorerSetupPropertyByKeySelector";
import { BillingExplorerWidgetSetupType } from "../../../utils/types/setups/billingExplorerSetupType";
import { RootState } from "../../../../store";

export const billingExplorerSetupAggregationsPropertyByKeySelector =
  (key: keyof BillingExplorerWidgetSetupType["aggregation"]) =>
  (state: RootState): any => {
    const setup =
      billingExplorerSetupPropertyByKeySelector("aggregation")(state);

    if (!setup) {
      return null;
    }

    return setup?.[key];
  };
