import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateDataPointNullable } from "../../../../../../../../../services/cloudchipr.api";
import { capitalizeString } from "../../../../../../../../../utils/helpers/capitalizeString";

interface WidgetFrequencySelectProps {
  frequency: DateDataPointNullable;
  onChange(value: SelectChangeEvent<DateDataPointNullable>): void;
  disabled?: boolean;
}
export const WidgetFrequencySelect: FC<WidgetFrequencySelectProps> = ({
  frequency,
  onChange,
  disabled,
}) => {
  const renderValue = useCallback((value: DateDataPointNullable) => {
    if (!value) {
      return;
    }
    return capitalizeString(value);
  }, []);

  return (
    <Tooltip
      arrow
      placement="right"
      title={
        disabled
          ? "The chart granularity is not applicable for this chart type."
          : ""
      }
    >
      <FormControl>
        <InputLabel>Chart Granularity</InputLabel>
        <Select
          label="Chart Granularity"
          size="small"
          fullWidth
          value={frequency}
          disabled={disabled}
          onChange={onChange}
          renderValue={renderValue}
        >
          <MenuItem value="daily">
            <ListItemText>Daily</ListItemText>
          </MenuItem>

          <MenuItem value="weekly">
            <ListItemText>Weekly</ListItemText>
          </MenuItem>

          <MenuItem value="monthly">
            <ListItemText>Monthly</ListItemText>
          </MenuItem>

          <MenuItem value="quarterly">
            <ListItemText>Quarterly</ListItemText>
          </MenuItem>

          <MenuItem value="yearly">
            <Typography>Yearly</Typography>
          </MenuItem>
        </Select>
      </FormControl>
    </Tooltip>
  );
};
