import { FC } from "react";
import { Card, LinearProgress } from "@mui/material";

import { BillingExplorerWidgetSetupChartView } from "./BillingExplorerWidgetSetupChartView";
import { BillingExplorerWidgetSetupSubHeader } from "../components/BillingExplorerWidgetSetupSubHeader";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";
import { WidgetSetupContentWrapper } from "../../common/WidgetSetupContentWrapper";
import { BillingExplorerWidgetSetupTableView } from "../components/table-view/BillingExplorerWidgetSetupTableView";
import { BillingExplorerWidgetSetupNumeralView } from "../components/numeral-view/BillingExplorerWidgetSetupNumeralView";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";
import { billingExplorerWidgetSetupLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupLoadingSelector";

export const BillingExplorerWidgetSetupContent: FC = () => {
  const setupChartType = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("chart_type"),
  );

  const chartType = setupChartType ?? "line";

  const loading = useAppSelector(billingExplorerWidgetSetupLoadingSelector);

  return (
    <WidgetSetupContentWrapper>
      <Card
        elevation={3}
        sx={{
          height: widgetSetupContentChartHeight,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading && <LinearProgress />}

        <BillingExplorerWidgetSetupSubHeader />

        {chartType === "table" && <BillingExplorerWidgetSetupTableView />}

        {chartType === "numeral" && <BillingExplorerWidgetSetupNumeralView />}

        {!["numeral", "table"].includes(chartType) && (
          <BillingExplorerWidgetSetupChartView />
        )}
      </Card>
    </WidgetSetupContentWrapper>
  );
};
