import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { azureCoveredUsageColumnDef } from "./common/azureCoveredUsageColumnDef";
import { azureNetSavingsColumnDef } from "./common/azureNetSavingsColumnDef";
import { AzureSavingsPlanCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const azureCoverageGroupedByCommitmentColumnDefs: ColDef<AzureSavingsPlanCoverageDataWithId>[] =
  [
    {
      flex: 1.5,
      field: "commitment.provider_id",
      headerName: "Savings Plan ID",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => (
        <TypographyWithTooltip
          variant="body2"
          title={data?.commitment?.provider_id ?? ""}
        />
      ),
    },
    azureCoveredUsageColumnDef,
    azureNetSavingsColumnDef,
    agGridEmptyColumnToFitEmptySpace,
    // getAzureCoverageColumnDefs("commitment"), // todo: Add this column after the backend fix
  ];
