import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { LiveResourceFiltersContentCard } from "./LiveResourceFiltersContentCard";
import { useAppSelector } from "../../../../../store/hooks";
import { liveUsageMgmtAppliedAllFiltersCountSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAppliedAllFiltersCountSelector";
import { liveUsageMgmtAppliedFiltersSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAppliedFiltersSelector";
import { allResourcesByProvider } from "../../../../../utils/constants/resources/resourceGroupsByProvider";
import { SetRefsFromKey } from "../../../../../utils/hooks/useRefs";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../pages/live-usage-mgmt/utils/helpers/getIsResourceTypeHiddenByFeatureToggle";
import { LiveUsageNavigationResourceType } from "../../../../pages/live-usage-mgmt/components/navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { isStringResourceType } from "../../../../../utils/helpers/resources/isStringResourceType";

export interface LiveResourceFiltersContentProps {
  onClose(): void;
  setRefFromKey: SetRefsFromKey;
  provider: ProviderType;
  activeResourceType: LiveUsageNavigationResourceType;
}

export const LiveResourceFiltersContent: FC<
  LiveResourceFiltersContentProps
> = ({ setRefFromKey, provider, onClose, activeResourceType }) => {
  const filtersCount = useAppSelector(
    liveUsageMgmtAppliedAllFiltersCountSelector,
  );
  const filters = useAppSelector(liveUsageMgmtAppliedFiltersSelector);
  const resources = allResourcesByProvider.get(provider);

  if (!provider) {
    return null;
  }

  const notAllResources = isStringResourceType(activeResourceType);

  const singleResourceFilter = notAllResources
    ? filters[activeResourceType]
    : null;
  const singleResourceItem = singleResourceFilter && (
    <LiveResourceFiltersContentCard
      onClose={onClose}
      key={activeResourceType}
      setRefFromKey={setRefFromKey}
      filter={singleResourceFilter}
    />
  );

  const count = notAllResources
    ? singleResourceFilter?.filter_items?.length
    : filtersCount;

  return (
    <Stack flex={1}>
      <Typography variant="subtitle1" fontWeight="medium" p={1} pb={2} pl={2}>
        Filters ({count})
      </Typography>

      <Stack overflow="auto" px={1} spacing={2}>
        {notAllResources
          ? singleResourceItem
          : resources?.map((resourceType) => {
              const filter = filters[resourceType];

              if (
                getIsResourceTypeHiddenByFeatureToggle(resourceType) ||
                !filter
              ) {
                return null;
              }

              return (
                <LiveResourceFiltersContentCard
                  filter={filter}
                  onClose={onClose}
                  key={resourceType}
                  setRefFromKey={setRefFromKey}
                />
              );
            })}
      </Stack>
    </Stack>
  );
};
