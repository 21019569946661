import { FC, useRef } from "react";
import { Stack } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import { useAppSelector } from "../../../../../../../store/hooks";
import { billingExplorerSummaryDataSelector } from "../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/billingExplorerSummaryDataSelector";

interface BillingExplorerExplainWithAiSummaryProps {
  index: number;
}
export const BillingExplorerExplainWithAiSummary: FC<
  BillingExplorerExplainWithAiSummaryProps
> = ({ index }) => {
  const summary = useAppSelector((state) =>
    billingExplorerSummaryDataSelector(state, index),
  );
  const contentRef = useRef<HTMLDivElement | null>(null);

  return (
    <Stack
      ref={contentRef}
      overflow="auto"
      dangerouslySetInnerHTML={{ __html: summary ?? "" }}
      sx={htmlStyle}
    />
  );
};
const htmlStyle = {
  "& code": {
    backgroundColor: "#EDEFF6",
    padding: 0.25,
    borderRadius: 0.5,
    color: deepPurple["500"],
  },
};
