import { FC } from "react";
import { Typography } from "@mui/material";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../../../utils/helpers/resources/getResourceTypeName";

interface ResourceCardHeaderTitleProps {
  resourceType: ResourceType;
}

export const LiveUsageMgmtResourceCardHeaderTitle: FC<
  ResourceCardHeaderTitleProps
> = ({ resourceType }) => {
  return (
    <Typography color="primary" variant="body2" fontWeight="medium">
      {getResourceTypeName(resourceType, {
        type: "long",
      })}
    </Typography>
  );
};
