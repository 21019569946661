import { billingExplorerWidgetByIdSelector } from "./billingExplorerWidgetByIdSelector";
import { billingExplorerWidgetNotFoundSelector } from "./billingExplorerWidgetNotFoundSelector";
import { billingExplorerWidgetBillingExportUrlSelector } from "./billingExplorerWidgetBillingExportUrlSelector";
import { RootState } from "../../../../../store";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { WidgetTitleProps } from "../../../../../../components/pages/dashboard/utils/types/types";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../../resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";

export const billingExplorerWidgetTitlePropsSelector = (
  state: RootState,
  widgetId: string,
): WidgetTitleProps | null => {
  const widget = billingExplorerWidgetByIdSelector(state, widgetId);

  if (!widget) {
    return null;
  }

  const viewName = resourceExplorerViewNameByViewIdSelector(
    state,
    widget.view_id ?? undefined,
  );

  const groupByLabel = resourceExplorerGroupingLabelByGroupingSelector(
    state,
    widget.grouping,
  );
  const viewNotFound = billingExplorerWidgetNotFoundSelector(state, widgetId);
  const url = billingExplorerWidgetBillingExportUrlSelector(state, widgetId);

  return {
    groupBy: widget.grouping,
    groupValues: widget.group_values,
    groupByLabel,
    widgetName: widget.name,
    dates: widget.dates ?? {},
    sourceData: {
      title: viewNotFound || !viewName ? "" : viewName,
      url,
    },
  };
};
