import { FC, Fragment } from "react";
import { DashboardVisibilityItem } from "./DashboardVisibilityItem";
import { useAppSelector } from "../../../../../../../store/hooks";
import { navigationDashboardPublicDataSelector } from "../../../../../../../store/navigation/selectors/dashboard/navigationDashboardPublicDataSelector";
import { navigationDashboardPrivateDataSelector } from "../../../../../../../store/navigation/selectors/dashboard/navigationDashboardPrivateDataSelector";

export const DashboardVisibility: FC = () => {
  const publicHierarchyData = useAppSelector(
    navigationDashboardPublicDataSelector,
  );
  const privateHierarchyData = useAppSelector(
    navigationDashboardPrivateDataSelector,
  );

  return (
    <Fragment>
      <DashboardVisibilityItem
        data={publicHierarchyData}
        visibility="visible_to_everyone"
      />
      <DashboardVisibilityItem
        data={privateHierarchyData}
        visibility="visible_only_to_me"
      />
    </Fragment>
  );
};
