import { FC } from "react";
import { CoverageWidgetChartView } from "./CoverageWidgetChartView";
import { useAppSelector } from "../../../../../../../store/hooks";
import { coverageWidgetByWidgetIdGroupsSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetByWidgetIdGroupsSelector";
import { coverageWidgetHasAggregationSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetHasAggregationSelector";
import { coverageWidgetVisualizationChartTypeSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetVisualizationChartTypeSelector";
import { coverageWidgetVisualizationChartBaseSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetVisualizationChartBaseSelector";
import { coverageWidgetByWidgetIdChartDataSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetByWidgetIdChartDataSelector";

interface CoverageWidgetContentChartViewProps {
  widgetId: string;
}

export const CoverageWidgetContentChartView: FC<
  CoverageWidgetContentChartViewProps
> = ({ widgetId }) => {
  const groups = useAppSelector((state) =>
    coverageWidgetByWidgetIdGroupsSelector(state, widgetId),
  );
  const hasAggregations = useAppSelector((state) =>
    coverageWidgetHasAggregationSelector(state, widgetId),
  );
  const chartType = useAppSelector((state) =>
    coverageWidgetVisualizationChartTypeSelector(state, widgetId),
  );
  const chartBase = useAppSelector((state) =>
    coverageWidgetVisualizationChartBaseSelector(state, widgetId),
  );
  const chartData = useAppSelector((state) =>
    coverageWidgetByWidgetIdChartDataSelector(state, widgetId),
  );

  return (
    <CoverageWidgetChartView
      groups={groups}
      hasAggregation={hasAggregations}
      chartType={chartType ?? "stack"}
      chartBase={chartBase}
      chartData={chartData}
    />
  );
};
