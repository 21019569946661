import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Stack, Typography } from "@mui/material";
import { getCoveredUsageColumnDef } from "./common/getCoveredUsageColumnDef";
import { uncoveredUsageColumnDef } from "./common/uncoveredUsageColumnDef";
import { netSavingsColumnDef } from "./common/netSavingsColumnDef";
import { totalCoverageColumnDef } from "./common/totalCoverageColumnDef";
import { CommitmentsProgressBar } from "../../../../../../../common/components/CommitmentsProgressBar";
import { ReservationsCoverageDataWithId } from "../../../../../../../common/utils/types";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const reservationCoverageGroupedByAccountColumnDefs: ColDef<ReservationsCoverageDataWithId>[] =
  [
    {
      flex: 1.5,
      field: "account",
      headerName: "Account",
      minWidth: 200,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
        <Stack>
          <Typography variant="body2">{data?.account?.name ?? ""}</Typography>
          <Typography variant="body2" color="text.secondary">
            {data?.account?.provider_account_id ?? ""}
          </Typography>
        </Stack>
      ),
      valueGetter: ({ data }) =>
        `${data?.account?.name ?? ""} ${data?.account?.provider_account_id ?? ""}`,
    },

    getCoveredUsageColumnDef("account"),

    uncoveredUsageColumnDef,

    netSavingsColumnDef,

    {
      flex: 1.5,
      field: "coverage",
      minWidth: 200,
      headerName: "Coverage",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
        <CommitmentsProgressBar
          overrideColor="info.light"
          value={data?.coverage ?? 0}
        />
      ),
    },

    totalCoverageColumnDef,

    agGridEmptyColumnToFitEmptySpace,
  ];
