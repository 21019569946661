import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "../../../../store";
import {
  automationsInitialData,
  workflowInitialData,
} from "../../../utils/constants/common";
import { providersRegionsSelector } from "../../../selectros/common/helpers-data/regions/providersRegionsSelector";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";
import {
  setAutomationData,
  setAutomationInDrawerMode,
  setAutomationInitialized,
  setWorkflowSpecificData,
} from "../../../automationsSlice";
import {
  ProviderType,
  ResourceFiltersWithAction,
  ResourceType,
} from "../../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";
import { getProvidersRegionsThunk } from "../../regions/getProvidersRegionsThunk";

export type InitiateInDrawerWorkflowForMultiAccountsThunkArgs = {
  accountIds: string[];
  regions?: string[];
  filters?: ResourceFiltersWithAction[];
  resourceType?: ResourceType;
  provider?: ProviderType;
};

export const initiateInDrawerWorkflowForMultiAccountsThunk = createAsyncThunk(
  "automation/initiateInDrawerWorkflowForMultiAccounts",
  async (
    {
      accountIds,
      filters,
      resourceType,
      provider,
      regions,
    }: InitiateInDrawerWorkflowForMultiAccountsThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    if (!regions?.length) {
      await dispatch(getProvidersRegionsThunk());
    }

    const serviceName = resourceType
      ? getResourceTypeName(resourceType, {
          service: true,
          singular: true,
          type: "abbreviation",
        })
      : "";

    const name = `${serviceName} Workflow | ${formatDate(moment(), {
      type: "dateTime",
    })}`;

    const allRegions = providersRegionsSelector(state);
    const providerRegions = provider ? allRegions[provider] : [];

    dispatch(
      setAutomationData({
        ...automationsInitialData,
        name,
        provider,
        filter: filters,
        accountIds: accountIds,
        regions: regions?.length
          ? regions
          : providerRegions?.map(({ key }) => key),
      }),
    );

    dispatch(
      setWorkflowSpecificData({ ...workflowInitialData, action: "notify" }),
    );
    dispatch(setAutomationInitialized(true));
    dispatch(setAutomationInDrawerMode(true));
  },
);
