import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { LiveUsageMgmtToolbarRowIntegrationAction } from "./LiveUsageMgmtToolbarRowIntegrationAction";
import { liveUsageRowActionTooltipTitles } from "./utils/constants";
import { ToolbarTaskCreateActionItem } from "./ToolbarTaskCreateActionItem";
import { LiveUsageMgmtToolbarFilterOutActionItem } from "./LiveUsageMgmtToolbarFilterOutActionItem";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import {
  IntegrationType,
  IntegrationTypeWithoutWebhook,
} from "../../../../../../../../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import { initiateCurrentIntegration } from "../../../../../../../../../../../store/integrations/integrationsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { liveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector";
import { integrationOptions } from "../../utils/constants/constants";
import { liveUsageMgmtProviderSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";

interface LiveUsageMgmtRowActionsProps {
  resourceType: ResourceType;
}

export const LiveUsageMgmtToolbarRowActions: FC<
  LiveUsageMgmtRowActionsProps
> = ({ resourceType }) => {
  const dispatch = useAppDispatch();
  const resourceCleanActionsButtonDisabledReason = useAppSelector((state) =>
    liveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector(
      state,
      resourceType,
    ),
  );
  const provider = useAppSelector(liveUsageMgmtProviderSelector);
  const integrationIconClickHandler = useCallback(
    (type: IntegrationType) => {
      dispatch(initiateCurrentIntegration({ type, resourceType, provider }));
    },
    [dispatch, resourceType, provider],
  );

  const tooltipType = resourceCleanActionsButtonDisabledReason
    ? "disabled"
    : "active";

  return (
    <Stack
      bgcolor="grey.100"
      spacing={0.5}
      direction="row"
      p={0.5}
      alignItems="center"
      borderRadius={2}
    >
      <LiveUsageMgmtToolbarFilterOutActionItem
        resourceType={resourceType}
        tooltipTitle={
          liveUsageRowActionTooltipTitles.filter_out[tooltipType] ?? ""
        }
        disabled={!!resourceCleanActionsButtonDisabledReason}
      />

      <ToolbarTaskCreateActionItem
        resourceType={resourceType}
        disabled={!!resourceCleanActionsButtonDisabledReason}
      />

      {integrationOptions.map(({ type }) => {
        return (
          <LiveUsageMgmtToolbarRowIntegrationAction
            key={type}
            type={type as IntegrationTypeWithoutWebhook}
            onClick={integrationIconClickHandler}
            disabled={!!resourceCleanActionsButtonDisabledReason}
          />
        );
      })}
    </Stack>
  );
};
