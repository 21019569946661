import { kubernetesClustersSelector } from "./kubernetes/kubernetesClustersSelector";
import { RootState } from "../../store";
import { ProviderType } from "../../../services/cloudchipr.api";
import { connectedProvidersSelector } from "../../common/selectors/connectedProvidersSelector";

export const liveUsageNavigationAllItemsSelector = (state: RootState) => {
  const clusters = kubernetesClustersSelector(state);
  const cloudProviderAccounts = connectedProvidersSelector(state);

  if (!clusters) {
    return cloudProviderAccounts;
  }

  return [
    ...cloudProviderAccounts,
    ...(clusters ? ["kubernetes"] : []),
  ] as ProviderType[];
};
