import { FC } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import { useDidMount } from "rooks";
import { Stack, Typography } from "@mui/material";
import {
  DashboardWidgetText,
  GetUsersMeOrganisationsCurrentWidgetsTextByWidgetIdApiResponse,
} from "../../../../../../services/cloudchipr.api";
import { Editor } from "../../../../../../storybook/editor/Editor";

interface InternalDashboardTextWidget extends DashboardWidgetText {
  data?: GetUsersMeOrganisationsCurrentWidgetsTextByWidgetIdApiResponse;
  isError: boolean;
  error?: FetchBaseQueryError | SerializedError;
  onWidgetMount?(): void;
}

export const InternalDashboardTextWidget: FC<InternalDashboardTextWidget> = ({
  data,
  isError,
  error,
  onWidgetMount,
  name,
}) => {
  const status = error && "status" in error ? error?.status : 0;

  useDidMount(() => {
    onWidgetMount?.();
  });

  if (isError && status !== 404) {
    return;
  }

  return (
    <Stack flexGrow={1} overflow="auto">
      <Stack
        pl={2}
        pr={0}
        py={1}
        position="sticky"
        top={0}
        bgcolor="white"
        zIndex={2}
        borderColor="grey.300"
      >
        <Typography variant="subtitle1" fontWeight="medium">
          {name}
        </Typography>
      </Stack>

      <Stack height="100%" tabIndex={0} p={2} pt={0} overflow={"hidden"}>
        <Editor content={data?.text ?? ""} editable={false} />
      </Stack>
    </Stack>
  );
};
