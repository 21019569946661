import { FC } from "react";
import { Stack } from "@mui/material";
import { CloudChiprCell } from "./CloudChiprCell";
import { TypographyWithTooltip } from "../TypographyWithTooltip";
import { formatDate } from "../../../utils/helpers/date-time/datetime-format";
import { UserAvatar } from "../avatars/UserAvatar";
import { UserProfile } from "../../../services/cloudchipr.api";

interface ResourceExplorerCreatedByCellProps {
  name?: string;
  date?: string;
  byCloudchipr?: boolean;
  deleted?: boolean;
  profile?: UserProfile;
}

export const UserActivityCell: FC<ResourceExplorerCreatedByCellProps> = ({
  name,
  byCloudchipr,
  deleted,
  date,
  profile,
}) => {
  if (byCloudchipr) {
    return <CloudChiprCell />;
  }

  if (!name) {
    return "-";
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <UserAvatar profile={profile} name={name} deleted={deleted} />
      <Stack>
        <TypographyWithTooltip variant="body2" title={name} />
        {date && (
          <TypographyWithTooltip
            variant="caption"
            sx={{ fontSize: 10 }}
            color="text.secondary"
            title={`${formatDate(date, { type: "dateTime" })} (UTC)`}
          />
        )}
      </Stack>
    </Stack>
  );
};
