import { cloudChiprApi } from "../../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../../store";
import { azureUtilizationAndCoverageDetailsPayloadSelector } from "../azureUtilizationAndCoverageDetailsPayloadSelector";

const selector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentAzureSavingsPlansData
    .select;

export const azureUtilizationAndCoverageDetailsSelector = (
  state: RootState,
) => {
  const payload = azureUtilizationAndCoverageDetailsPayloadSelector(state);

  if (!payload) {
    return;
  }

  return selector(payload)(state);
};
