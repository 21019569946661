import { FC, useState } from "react";
import { Avatar, Stack } from "@mui/material";
import { SxProps } from "@mui/system";
import { DefaultUserAvatar } from "./DefaultUserAvatar";
import { DeletedUserAvatar } from "./DeletedUserAvatar";
import { UserProfile } from "../../../services/cloudchipr.api";

interface UserAvatarProps {
  profile?: UserProfile;
  name?: string | null;
  deleted?: boolean;
  sx?: SxProps;
  fallbackIconSx?: SxProps;
}

export const UserAvatar: FC<UserAvatarProps> = ({
  profile,
  name,
  deleted,
  sx,
  fallbackIconSx,
}) => {
  const [imgError, setImgError] = useState(false);

  if (deleted) {
    return <DeletedUserAvatar sx={sx} />;
  }

  if (profile) {
    const { picture, color } = profile;

    if (picture && !imgError) {
      return (
        <Avatar
          sx={{ width: 28, height: 28, ...sx }}
          src={picture}
          onError={() => setImgError(true)}
          slotProps={{
            img: { referrerPolicy: "no-referrer" },
          }}
        />
      );
    }

    if (name && color) {
      return (
        <Stack
          {...letterAvatarStyles}
          sx={{ cursor: "default", ...sx }}
          bgcolor={color}
        >
          {name.substring(0, 1).toUpperCase()}
        </Stack>
      );
    }
  }

  return <DefaultUserAvatar sx={sx} iconSx={fallbackIconSx} />;
};

const letterAvatarStyles = {
  color: "white",
  width: 28,
  height: 28,
  minWidth: 28,
  minHeight: 28,
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
};
