import { RootState } from "../../../../../store";
import { DashboardWidgetText } from "../../../../../../services/cloudchipr.api";
import { widgetByIdSelector } from "../../common/widgetByIdSelector";

export const textWidgetByIdSelector = (
  state: RootState,
  widgetId?: string,
): DashboardWidgetText | undefined => {
  if (!widgetId) {
    return;
  }

  return widgetByIdSelector(state, widgetId) as DashboardWidgetText;
};
