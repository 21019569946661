import { FC, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, Stack, Theme } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { useEffectOnceWhen } from "rooks";
import { SlackConversationsSelect } from "./SlackConversationsSelect";
import { CreateIntegrationMessageEditor } from "../common/CreateIntegrationMessageEditor";
import {
  PostUsersMeSlackIntegrationsByIntegrationIdMessageApiArg,
  ResourceType,
  SlackConversation,
  usePostUsersMeSlackIntegrationsByIntegrationIdMessageMutation,
} from "../../../../../services/cloudchipr.api";
import { DialogTitleClosable } from "../../../dialog-utils/DialogTitleClosable";
import { useAppDispatch } from "../../../../../store/hooks";
import { getIntegrationByIdThunk } from "../../../../../store/integrations/thunks/getIntegrationByIdThunk";
import { CreateIntegrationMessageFormActions } from "../common/CreateIntegrationMessageFormActions";
import { IntegrationSelect } from "../common/IntegrationSelect";
import { CreateIntegrationResourcesTableProps } from "../common/CreateIntegrationResourcesTableV2";
import { enqueueSnackbarErrorAlert } from "../../../../pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { getSlackFormatFromHTML } from "../../../../utils/helpers/slack-formatters/getSlackFormatFromHTML";

interface CreateSlackMessageDialogProps {
  resourceType: ResourceType;
  onClose(): void;
  message: string;
  table: FC<CreateIntegrationResourcesTableProps>;
  csvData: Record<string, string | number>[] | null;
}

const validationSchema = yup.object({
  integrationId: yup.string().required("This field is required"),
  body: yup.object({
    conversations: yup
      .array()
      .min(1, "At least one Slack conversation should be selected."),
  }),
});

const initialValues: PostUsersMeSlackIntegrationsByIntegrationIdMessageApiArg =
  {
    integrationId: "",
    body: {
      message: "",
      conversations: [],
      mentions: [],
      resources: [],
    },
  };

export const CreateSlackMessageDialogV2: FC<CreateSlackMessageDialogProps> = ({
  resourceType,
  onClose,
  message,
  table: Table,
  csvData,
}) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [createMessage, { isLoading }] =
    usePostUsersMeSlackIntegrationsByIntegrationIdMessageMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const payload = {
        ...values,
        body: {
          ...values.body,
          message: values.body.message
            ? getSlackFormatFromHTML(values.body.message)
            : "",
        },
      };

      try {
        await createMessage(payload).unwrap();

        enqueueSnackbar("Slack message successfully sent.", {
          variant: "snackbarAlert",
          AlertSnackBarProps: { severity: "success" },
        });

        onClose();
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  });

  const { isValid, values, submitForm, setFieldValue, errors, touched } =
    formik;

  const conversationsChangeHandler = useCallback(
    (conversations: SlackConversation[]) => {
      setFieldValue("body.conversations", conversations);
    },
    [setFieldValue],
  );

  const mentionsChangeHandler = useCallback(
    (mentions: SlackConversation[]) => {
      setFieldValue("body.mentions", mentions);
    },
    [setFieldValue],
  );

  const integrationChangeHandler = useCallback(
    (integrationId: string) => {
      setFieldValue("integrationId", integrationId);

      dispatch(
        getIntegrationByIdThunk({ id: integrationId, type: "slack" }),
      ).then((response: any) => {
        const conversation = response?.payload?.data?.conversation;
        setFieldValue("body.conversations", conversation ? [conversation] : []);
      });
    },
    [dispatch, setFieldValue],
  );

  const messageChangeHandler = useCallback(
    (value: string) => setFieldValue("body.message", value),
    [setFieldValue],
  );

  useEffectOnceWhen(() => {
    setFieldValue("body.message", message);
    setFieldValue("body.resources", csvData);
  }, !!message && !!csvData?.length);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="md"
      sx={{
        zIndex: (theme: Theme) => theme.zIndex.drawer,
      }}
      fullWidth
    >
      <DialogTitleClosable title="Send Slack" onClose={onClose} />
      <DialogContent dividers>
        <IntegrationSelect
          type="slack"
          value={values.integrationId}
          onChange={integrationChangeHandler}
          error={touched.integrationId ? errors.integrationId : ""}
        />

        <SlackConversationsSelect
          disabled={!values.integrationId}
          integrationId={values.integrationId}
          onChange={conversationsChangeHandler}
          selectedConversations={values.body.conversations}
          error={touched.body?.conversations ? errors.body?.conversations : ""}
        />

        <Stack p={2} my={3} spacing={2} bgcolor="grey.50">
          {/*<CreateIntegrationResourcesTableV2*/}
          {/*  label="Resources.csv (snippet)"*/}
          {/*  resourceType={resourceType}*/}
          {/*/>*/}

          <Table label="Resources.csv (snippet)" resourceType={resourceType} />

          <CreateIntegrationMessageEditor
            value={values.body.message ?? ""}
            onChange={messageChangeHandler}
            mention={{
              integrationId: values.integrationId,
              selected: values.body.mentions,
              onChange: mentionsChangeHandler,
            }}
          />
        </Stack>
      </DialogContent>
      <CreateIntegrationMessageFormActions
        disabled={!isValid}
        onCancel={onClose}
        onSubmit={submitForm}
        isLoading={isLoading}
      />
    </Dialog>
  );
};
