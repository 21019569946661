import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useLiveUsageLastVisitedOrgHook } from "./useLiveUsageLastVisitedOrg.hook";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useAccountStorageMemoizedTabHook } from "../../../pages/accounts-group/utils/hooks/useAccountStorageMemoizedTabHook";
import { biggestOrgByProviderSelector } from "../../../../store/accounts/selectors/all-providers/biggestOrgByProviderSelector";
import { generateLiveResourcesPath } from "../../../pages/accounts-group/utils/helpers/generateLiveResourcesPath";
import { setAccountsProviderOrganisationId } from "../../../../store/accounts/accountsSlice";
import { setLiveUsageMgmtProvider } from "../../../../store/live-usage-mgmt/liveUsageMgmtSlice";
import { accountIdsByProviderAndOrgIdSelector } from "../../../../store/accounts/selectors/accountIdsByProviderAndOrgIdSelector";

export const useLiveUsageNavigationHook = (provider: ProviderType) => {
  const navigate = useNavigate();
  const { getOrgId } = useLiveUsageLastVisitedOrgHook(provider);

  const dispatch = useAppDispatch();

  const { getAccountTab } = useAccountStorageMemoizedTabHook();
  const biggestOrgByProvider = useAppSelector((state) =>
    biggestOrgByProviderSelector(state, provider),
  );

  const orgIdFromStorage = getOrgId();

  const biggestAccountIds = biggestOrgByProvider?.accounts.map(
    (account) => account.id,
  );

  const orgId =
    typeof orgIdFromStorage === "string" && orgIdFromStorage
      ? orgIdFromStorage
      : (biggestOrgByProvider?.orgId ?? "");
  const accountIdsAsStringFromRedux = useAppSelector((state) =>
    accountIdsByProviderAndOrgIdSelector(state, provider, orgId),
  );

  const biggestAccountIdsAsString = biggestAccountIds?.join(",") ?? "";
  const accountIdsAsString =
    accountIdsAsStringFromRedux?.trim() ?? biggestAccountIdsAsString;

  const accountsPath = generateLiveResourcesPath({ provider, orgId });

  const liveUsagePath = generateLiveResourcesPath({
    provider,
    activeTab: "live-resources",
    accountIds: accountIdsAsString,
  });

  const accountTab = getAccountTab();
  const path = accountTab === "live-resources" ? liveUsagePath : accountsPath;

  const setAccountsProviderAndOrganizationId = useCallback(() => {
    dispatch(setAccountsProviderOrganisationId(orgId ?? ""));
    dispatch(setLiveUsageMgmtProvider(provider));
  }, [dispatch, orgId, provider]);

  return useCallback(() => {
    setAccountsProviderAndOrganizationId();
    navigate(!accountIdsAsString ? accountsPath : path);
  }, [
    setAccountsProviderAndOrganizationId,
    accountsPath,
    path,
    navigate,
    accountIdsAsString,
  ]);
};
