import { resourceDetailsResourceBillingGranularRequestSelector } from "./requests/resourceDetailsResourceBillingGranularRequestSelector";
import { ResourceExplorerGranularCost } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const resourceDetailsResourceBillingGranularDataSelector = (
  state: RootState,
  processKey: string,
): ResourceExplorerGranularCost | undefined => {
  return resourceDetailsResourceBillingGranularRequestSelector(
    state,
    processKey,
  )?.data;
};
