import { FC } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { azureReservationDetailsMetaDataSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/details-drawer/reserved-instance/azureReservationDetailsMetaDataSelector";
import { azureReservationDetailsLoadingSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/details-drawer/data/azureReservationDetailsLoadingSelector";
import { CommitmentsDrawerContentInfoCard } from "../../../../../common/components/drawer/CommitmentsDrawerContentInfoCard";
import { CommitmentsProgressBar } from "../../../../../common/components/CommitmentsProgressBar";
import { money } from "../../../../../../../../utils/numeral/money";
import { CommitmentsDrawerChartHeader } from "../../../../../common/components/drawer/CommitmentsDrawerChartHeader";

export const AzureReservedInstanceDrawerChartHeader: FC = () => {
  const metadata = useAppSelector(azureReservationDetailsMetaDataSelector);
  const isLoading = useAppSelector(azureReservationDetailsLoadingSelector);

  return (
    <CommitmentsDrawerChartHeader>
      <Stack direction="row" flexWrap="wrap" gap={1} mt={1.5}>
        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Utilization"
        >
          <CommitmentsProgressBar
            width="100%"
            value={metadata?.utilization ?? 0}
          />
        </CommitmentsDrawerContentInfoCard>

        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Used Commitment"
          value={money(metadata?.used_commitment)}
        />

        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="On-demand Spend Equivalent"
          value={money(metadata?.covered_usage)}
        />

        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Net Savings"
          value={money(metadata?.net_savings)}
        />
      </Stack>
    </CommitmentsDrawerChartHeader>
  );
};

const cardSx = { minWidth: 256, flex: 1 };
