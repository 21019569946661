import { FC, useMemo } from "react";
import { ReservationsDataGridViewTabs } from "./components/ReservationsDataViewTabs";
import { reservationCoverageTableColumnsDefs } from "./columns/reservationCoverageTableColumnsDefs";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsCoverageGroupingSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsCoverageGroupingSelector";
import { reservationsCoverageDataSelector } from "../../../../../../../store/commitments/selectors/reservations/data/reservationsCoverageDataSelector";
import { reservationsForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/reservations/data/loading/reservationsForTableLoadingSelector";
import { CommitmentsAGGridToolbar } from "../../../../common/components/data-grid/CommitmentsAGGridToolbar";
import {
  CommitmentsAgGrid,
  CommitmentsToolbar,
} from "../../../../common/components/CommitmentsAgGrid";

export const ReservationsCoverageDataGrid: FC = () => {
  const data = useAppSelector(reservationsCoverageDataSelector);
  const loading = useAppSelector(reservationsForTableLoadingSelector);
  const grouping = useAppSelector(commitmentsCoverageGroupingSelector);

  const columnDefs = useMemo(
    () => reservationCoverageTableColumnsDefs(grouping),
    [grouping],
  );

  return (
    <CommitmentsAgGrid
      key={grouping}
      Toolbar={toolbar}
      loading={loading}
      columnDefs={columnDefs}
      data={data}
      grouping={grouping}
      localStorageUniqueKey="reservationCoverageDataGrid"
    />
  );
};

const toolbar: CommitmentsToolbar = ({ gridApi }) => {
  return (
    <CommitmentsAGGridToolbar gridApi={gridApi}>
      <ReservationsDataGridViewTabs />
    </CommitmentsAGGridToolbar>
  );
};
