import { RootState } from "../../../store";
import {
  cloudChiprApi,
  ResourceExplorerPossibleGroupings,
} from "../../../../services/cloudchipr.api";

const selector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentResourceExplorerPossibleGroupings.select();

export const resourceExplorerPossibleGroupingsSelector = (
  state: RootState,
): ResourceExplorerPossibleGroupings | undefined => {
  return selector(state)?.data;
};
