import { FC } from "react";
import { useGetUsersMeOrganisationsCurrentAccountsByAccountIdAndResourceTypeProviderUniqueIdentifierMetricsQuery as useMetricsQuery } from "../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceDetailsTargetSelector } from "../../../../../../../store/resource-details/selectors/target-resource/resourceDetailsTargetSelector";
import { ResourceDetailsMetricsContent } from "../common/metrics/ResourceDetailsMetricsContent";

export const ResourceDetailsMetricsTabContent: FC = () => {
  const resource = useAppSelector(resourceDetailsTargetSelector);

  const accountId = resource?.resource?.accountId ?? "";
  const resourceType = resource?.resource?.resourceType ?? "ec2";
  const providerUniqueIdentifier =
    resource?.resource?.provider_unique_identifier ?? "";

  const { data, isFetching } = useMetricsQuery(
    { accountId, resourceType, providerUniqueIdentifier },
    { skip: !accountId || !resourceType || !providerUniqueIdentifier },
  );

  return <ResourceDetailsMetricsContent data={data} loading={isFetching} />;
};
