import { navigationDashboardDataSelector } from "./navigationDashboardDataSelector";
import { TreeItems } from "../../../../components/app-navigation/components/sortable-tree/utils/types";
import { RootState } from "../../../store";

export const navigationDashboardPrivateDataSelector = (
  state: RootState,
): TreeItems | undefined => {
  const data = navigationDashboardDataSelector(state);
  return data?.visible_only_to_me ?? undefined;
};
