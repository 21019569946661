import { FC } from "react";
import { Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import { BudgetsGrid } from "./grids/budgets/BudgetsGrid";
import { BudgetsEmptyState } from "./grids/budgets/BudgetsEmptyState";
import { useGetUsersMeOrganisationsCurrentBudgetsQuery } from "../../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { budgetByIdSelector } from "../../../../store/budgets/selectors/budgetByIdSelector";
import { toggleAlertsOpenDrawer } from "../../../../store/alerts/alertsSlice";
import { initiateBudgetByIdThunk } from "../../../../store/budgets/thunks/initiateBudgetByIdThunk";

export const Budgets: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { data, isLoading: loading } =
    useGetUsersMeOrganisationsCurrentBudgetsQuery();
  const budgetId = searchParams.get("id");
  const openedBudget = useAppSelector((state) =>
    budgetByIdSelector(state, budgetId),
  );

  useEffectOnceWhen(() => {
    if (budgetId) {
      dispatch(toggleAlertsOpenDrawer());
      dispatch(initiateBudgetByIdThunk(budgetId));
    }
  }, !!openedBudget);

  return (
    <Stack height="100%">
      {!!data?.length && <BudgetsGrid />}

      {!loading && !data?.length && <BudgetsEmptyState />}
    </Stack>
  );
};
