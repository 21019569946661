import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import {
  AzureReservationsCoverageDataWithId,
  AzureSavingsPlanCoverageDataWithId,
} from "../../../../../../../../../common/utils/types";

import { CommitmentsProgressBar } from "../../../../../../../../../common/components/CommitmentsProgressBar";

export const azureTotalCoverageColumnDef: ColDef<AzureReservationsCoverageDataWithId> =
  {
    flex: 1.5,
    field: "total_coverage",
    minWidth: 230,
    headerName: "Total Coverage",
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => (
      <CommitmentsProgressBar value={data?.total_coverage ?? null} />
    ),
  };
