import { FC, Fragment, useMemo } from "react";
import { Box } from "@mui/material";
import { useDidMount } from "rooks";
import { AzureReservationCoverageDrawerContentHeaderInfoCards } from "./AzureReservationCoverageDrawerContentHeaderInfoCards";
import { AzureReservationCoverageDrawerChartHeader } from "./AzureReservationCoverageDrawerChartHeader";
import { azureReservationSelectedCoverageDataAccountNameAndIdSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/details-drawer/resource-type/azureReservationSelectedCoverageDataAccountNameAndIdSelector";
import { CommitmentsDrawerHeader } from "../../../../../common/components/drawer/header/CommitmentsDrawerHeader";
import { getAzureReservationDetailsThunk } from "../../../../../../../../store/commitments/thunks/azure/reservations/getAzureReservationDetailsThunk";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { CommitmentsDrawerContent } from "../../../../../common/components/drawer/CommitmentsDrawerContent";
import { AzureReservedInstanceDrawerChart } from "../common/AzureReservedInstanceDrawerChart";

import { AzureReservationDrawerDataGrid } from "../common/data-grid/AzureReservationDrawerDataGrid";
import { azureReservationSelectedCoverageDataRegionSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/details-drawer/resource-type/azureReservationSelectedCoverageDataRegionSelector";

interface AzureReservationCoverageDrawerContentProps {
  onClose(): void;
}

export const AzureReservationCoverageDrawerContent: FC<
  AzureReservationCoverageDrawerContentProps
> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const accountId = useAppSelector(
    azureReservationSelectedCoverageDataAccountNameAndIdSelector,
  );
  const region = useAppSelector(
    azureReservationSelectedCoverageDataRegionSelector,
  );
  const targetType = useAppSelector(commitmentsDetailsDrawerTargetTypeSelector);

  useDidMount(() => {
    dispatch(getAzureReservationDetailsThunk());
  });

  const title = useMemo(() => {
    if (targetType === "region") {
      return region ?? "Region";
    }
    if (targetType === "resource_type") {
      return "Resource Type";
    }
    return accountId;
  }, [accountId, targetType, region]);

  return (
    <Fragment>
      <CommitmentsDrawerHeader title={title} onClose={onClose}>
        {targetType === "resource_type" && (
          <AzureReservationCoverageDrawerContentHeaderInfoCards />
        )}
      </CommitmentsDrawerHeader>

      <Box width="80dvw" bgcolor="grey.50" height="100%">
        <CommitmentsDrawerContent title="Coverage" icon="utilization">
          <AzureReservedInstanceDrawerChart
            ChartHeader={AzureReservationCoverageDrawerChartHeader}
          />
          <AzureReservationDrawerDataGrid viewTab="coverage" />
        </CommitmentsDrawerContent>
      </Box>
    </Fragment>
  );
};
