import { FC } from "react";
import { WebhookIntegrationGrid } from "./components/grid/WebhookIntegrationGrid";
import { CommunicationIntegrationsPageWrapper } from "../common/components/CommunicationIntegrationsPageWrapper";

export const WebhookIntegration: FC = () => {
  return (
    <CommunicationIntegrationsPageWrapper
      type="webhook"
      breadcrumbs={breadcrumbs}
    >
      <WebhookIntegrationGrid />
    </CommunicationIntegrationsPageWrapper>
  );
};

const breadcrumbs = [
  { title: "Integrations", to: "/integrations" },
  { title: "Webhook" },
];
