import { FC, useCallback } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { resourceExplorerDataPointSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDataPointSelector";
import { DateDataPoint } from "../../../../../../../../../services/cloudchipr.api";
import {
  setResourceExplorerDataPoint,
  setResourceExplorerSearch,
} from "../../../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { getResourceExplorerDataThunk } from "../../../../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerDataThunk";
import { resourceExplorerPathLastItemFilterTreeSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathLastItemFilterTreeSelector";

export const BillingExplorerDataPointSelector: FC = () => {
  const dispatch = useAppDispatch();

  const dataPoint = useAppSelector(resourceExplorerDataPointSelector);

  const pathLastItemFilterTree = useAppSelector(
    resourceExplorerPathLastItemFilterTreeSelector,
  );

  const changeHandler = useCallback(
    (event: SelectChangeEvent) => {
      dispatch(
        setResourceExplorerDataPoint(event.target.value as DateDataPoint),
      );
      dispatch(getResourceExplorerDataThunk(pathLastItemFilterTree));
      dispatch(setResourceExplorerSearch(""));
    },
    [dispatch, pathLastItemFilterTree],
  );

  if (!dataPoint) {
    return null;
  }

  return (
    <FormControl size="xsmall" fullWidth sx={{ width: 110 }}>
      <Select
        value={dataPoint}
        onChange={changeHandler}
        name="data_point"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "grey.300" },
        }}
      >
        <MenuItem value="daily">
          <Typography variant="caption">Daily</Typography>
        </MenuItem>
        <MenuItem value="weekly">
          <Typography variant="caption">Weekly</Typography>
        </MenuItem>
        <MenuItem value="monthly">
          <Typography variant="caption">Monthly</Typography>
        </MenuItem>
        <MenuItem value="quarterly">
          <Typography variant="caption">Quarterly</Typography>
        </MenuItem>
        <MenuItem value="yearly">
          <Typography variant="caption">Yearly</Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
