import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { GridApi } from "ag-grid-community";
import { updateSavingsOpportunityViewThunk } from "../../../../../store/savings-opportunities/thunks/views-hierarchy/actions/updateSavingsOpportunityViewThunk";
import { setSavingsOpportunitiesCurrentViewId } from "../../../../../store/savings-opportunities/savingsOpportunitiesSlice";
import { createSavingsOpportunityViewThunk } from "../../../../../store/savings-opportunities/thunks/views-hierarchy/actions/createSavingsOpportunityViewThunk";
import { hierarchyViewIdSearchParamKey } from "../../../common/views-hierarchy-tabs/utils/constants/constants";
import { deleteSavingsOpportunityViewThunk } from "../../../../../store/savings-opportunities/thunks/views-hierarchy/actions/deleteSavingsOpportunityViewThunk";
import { duplicateSavingsOpportunityViewThunk } from "../../../../../store/savings-opportunities/thunks/views-hierarchy/actions/duplicateSavingsOpportunityViewThunk";
import { protectSavingsOpportunityViewThunk } from "../../../../../store/savings-opportunities/thunks/views-hierarchy/actions/protectSavingsOpportunityViewThunk";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { savingsOpportunityCurrentViewSelector } from "../../../../../store/savings-opportunities/selectors/views-hierarchy/current-view/savingsOpportunityCurrentViewSelector";
import { isGridStatesEqual } from "../helpers/isGridStatesEqual";
import { savingsOpportunitiesPathName } from "../constants/common";

export const useSavingsOpportunitiesViewActions = (gridApi: GridApi | null) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentView = useAppSelector(savingsOpportunityCurrentViewSelector);

  const currentViewId = currentView?.id;
  const currentViewConfig = currentView?.config;
  const currentViewIsProtected = currentView?.protection_details?.is_protected;

  const setNewViewId = useCallback(
    (viewId: string) => {
      navigate(
        `${savingsOpportunitiesPathName}/?${hierarchyViewIdSearchParamKey}=${viewId}`,
      );
      dispatch(setSavingsOpportunitiesCurrentViewId(viewId));
    },
    [navigate, dispatch],
  );

  const onTabChange = useCallback(
    async (viewId: string) => {
      const smthChanged = !isGridStatesEqual({
        viewConfig: currentViewConfig?.gridConfig,
        gridState: gridApi?.getState(),
      });

      const needToUpdate =
        currentViewId && !currentViewIsProtected && smthChanged;

      if (needToUpdate) {
        await dispatch(
          updateSavingsOpportunityViewThunk({
            viewId: currentViewId,
            body: {
              config: {
                gridConfig: JSON.stringify(gridApi?.getState()),
              },
            },
          }),
        ).unwrap();
      }

      dispatch(setSavingsOpportunitiesCurrentViewId(viewId));
    },
    [
      dispatch,
      gridApi,
      currentViewIsProtected,
      currentViewConfig,
      currentViewId,
    ],
  );

  const onViewCreate = useCallback(
    async (viewName: string) => {
      const response = await dispatch(
        createSavingsOpportunityViewThunk({
          body: { name: viewName },
        }),
      ).unwrap();

      if (response) {
        setNewViewId(response.id);
      }
      return response;
    },
    [dispatch, setNewViewId],
  );

  const onViewRename = useCallback(
    async (viewId: string, name: string) => {
      return await dispatch(
        updateSavingsOpportunityViewThunk({ viewId, body: { name: name } }),
      ).unwrap();
    },
    [dispatch],
  );

  const onViewDelete = useCallback(
    async (viewId: string) => {
      await dispatch(deleteSavingsOpportunityViewThunk(viewId)).unwrap();

      if (viewId === currentViewId) {
        dispatch(setSavingsOpportunitiesCurrentViewId(""));
        navigate(savingsOpportunitiesPathName);
      }
    },
    [dispatch, navigate, currentViewId],
  );

  const onViewDuplicate = useCallback(
    async (viewId: string, name: string) => {
      const response = await dispatch(
        duplicateSavingsOpportunityViewThunk({
          viewId,
          name,
        }),
      ).unwrap();

      if (response) {
        setNewViewId(response.id);
      }

      return response;
    },
    [dispatch, setNewViewId],
  );

  const onViewProtect = useCallback(
    async (viewId: string, isProtected: boolean, description: string) => {
      if (!isProtected) {
        dispatch(setSavingsOpportunitiesCurrentViewId(""));
      }

      const response = await dispatch(
        protectSavingsOpportunityViewThunk({
          viewId,
          body: {
            is_protected: isProtected,
            protection_description: description,
          },
        }),
      ).unwrap();

      if (!isProtected) {
        dispatch(setSavingsOpportunitiesCurrentViewId(viewId));
      }

      return response;
    },
    [dispatch],
  );

  return {
    onTabChange,
    onViewCreate,
    onViewRename,
    onViewDelete,
    onViewDuplicate,
    onViewProtect,
  };
};
