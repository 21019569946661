import React, { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { DeleteConfirmationDialog } from "../../../../../../../common/modals/DeleteConfirmationDialog";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { taskManagementDeleteTasksByIdsThunk } from "../../../../../../../../store/task-management/thunks/actions/taskManagementDeleteTasksByIdsThunk";
import { taskDeletionLoadingSelector } from "../../../../../../../../store/task-management/selectors/loading/taskDeletionLoadingSelector";
import { tasksListGetLoadingSelector } from "../../../../../../../../store/task-management/selectors/loading/tasksListGetLoadingSelector";
import { Task } from "../../../../../../../../services/cloudchipr.api";

interface FloatingDeleteActionDialogProps {
  open: boolean;
  onClose(): void;
  onDialogClose(): void;
  tasks: Task[];
}

export const TaskDeleteActionMenuDialog: FC<
  FloatingDeleteActionDialogProps
> = ({ onClose, open, tasks, onDialogClose }) => {
  const dispatch = useAppDispatch();
  const taskIds = tasks?.map((task) => task.id);
  const tasksCount = tasks?.length;

  const deletionLoading = useAppSelector(taskDeletionLoadingSelector);
  const getLoading = useAppSelector(tasksListGetLoadingSelector);

  const deleteHandler = useCallback(async () => {
    await dispatch(taskManagementDeleteTasksByIdsThunk(taskIds));

    onClose();
  }, [dispatch, onClose, taskIds]);

  return (
    <DeleteConfirmationDialog
      open={open}
      onClose={onDialogClose}
      onDelete={deleteHandler}
      loading={deletionLoading || getLoading}
      title={`Delete Task${tasksCount > 1 ? "s" : ""}?`}
      content={
        tasksCount > 1 ? (
          <Stack spacing={1}>
            <Typography sx={{ position: "sticky", top: 0, bgcolor: "white" }}>
              Please confirm if you want to delete the following tasks.
            </Typography>

            <Stack
              p={1}
              border={1}
              spacing={1}
              borderRadius={1}
              bgcolor="grey.100"
              borderColor="grey.200"
            >
              {tasks?.map((task) => (
                <TypographyWithTooltip
                  variant="body2"
                  color="text.secondary"
                  fontWeight="medium"
                  title={task?.name ?? task?.id}
                  key={task?.id}
                />
              ))}
            </Stack>
          </Stack>
        ) : (
          <Typography>
            Please confirm if you want to delete the{" "}
            <Typography component="span" variant="inherit" fontWeight="bold">
              {tasks?.[0]?.name ?? tasks?.[0]?.id}
            </Typography>{" "}
            task.
          </Typography>
        )
      }
    />
  );
};
