import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Box, Button, capitalize, Typography } from "@mui/material";
import { Reservation } from "../../../../../../../../services/cloudchipr.api";
import { ProviderUniqueIdCell } from "../../../../../common/components/data-grid/cells/ProviderUniqueIdCell";
import {
  commitmentsPaymentOptionLabels,
  commitmentsResourceTypeNames,
} from "../../../../../common/utils/constants/labels";
import {
  reservationAccountCellValueGetter,
  reservationEndDateCellValueGetter,
  reservationHasRecommendationCellValueGetter,
  reservationPaymentOptionCellValueGetter,
  reservationReservedCellValueGetter,
  reservationStartDateCellValueGetter,
  reservationTypeCellValueGetter,
  reservationUtilizationCellValueGetter,
} from "../utils/helpers/valueGetters";
import { AccountCell } from "../../../../../common/components/data-grid/cells/AccountCell";
import { HeaderCellWidthDateTooltip } from "../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { ReservationUtilizationCell } from "../components/cells/ReservationUtilizationCell";
import { money } from "../../../../../../../../utils/numeral/money";
import { reservationReservedCellComparator } from "../utils/helpers/comparators";
import { DatesCell } from "../../../../../common/components/data-grid/cells/DatesCell";

import { TagCell } from "../../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { CommitmentsTableName } from "../../../../../common/components/data-grid/cells/CommitmentsTableName";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const reservedInstancesTableColumnDefs: ColDef<Reservation>[] = [
  {
    width: 300,
    minWidth: 250,
    field: "provider_unique_id",
    headerName: "Reservation ID",
    suppressKeyboardEvent: () => true,
    lockVisible: true,
    suppressColumnsToolPanel: true,
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        <ProviderUniqueIdCell id={data?.provider_unique_id ?? ""} />
      </Box>
    ),
  },
  {
    width: 150,
    minWidth: 150,
    field: "type",
    headerName: "Type",
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => {
      const type = data?.type;

      return (
        <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
          {type ? (commitmentsResourceTypeNames[type] ?? null) : null}
        </Box>
      );
    },
    valueGetter: reservationTypeCellValueGetter,
  },
  {
    minWidth: 150,
    width: 210,
    field: "instance_type",
    headerName: "Instance Type",
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data?.instance_type ?? ""}
      </Box>
    ),
  },
  {
    minWidth: 150,
    width: 210,
    field: "instance_count",
    headerName: "Instance Count",
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data?.instance_count ?? ""}
      </Box>
    ),
  },
  {
    width: 250,
    minWidth: 150,
    field: "account.provider_account_name",
    headerName: "Linked Account",
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data && (
          <AccountCell
            accountId={data.account?.provider_account_id}
            accountName={data.account?.provider_account_name}
            isRootAccount={data.account?.is_provider_root_account}
          />
        )}
      </Box>
    ),
    valueGetter: reservationAccountCellValueGetter,
  },
  {
    width: 230,
    minWidth: 230,
    resizable: false,
    field: "used_commitment_to_date.cost",
    headerName: "Utilization",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <HeaderCellWidthDateTooltip title="Utilization " />
      ),
    },
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data && <ReservationUtilizationCell reservation={data} />}
      </Box>
    ),
    valueGetter: reservationUtilizationCellValueGetter,
  },
  {
    width: 180,
    minWidth: 150,
    field: "total_commitment_to_date.cost",
    headerName: "Reserved",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <HeaderCellWidthDateTooltip title="Reserved " />
      ),
    },
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Typography
        variant="body2"
        bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}
      >
        {money(data?.total_commitment_to_date?.cost ?? 0)} (
        {data?.total_commitment_to_date?.units ?? 0}h)
      </Typography>
    ),
    valueGetter: reservationReservedCellValueGetter,
    comparator: reservationReservedCellComparator,
  },
  {
    width: 180,
    minWidth: 150,
    field: "platform",
    headerName: "Platform",
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data?.platform}
      </Box>
    ),
  },
  {
    width: 190,
    minWidth: 150,
    field: "region",
    headerName: "Region/AZ",
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data?.region}
      </Box>
    ),
  },
  {
    width: 220,
    minWidth: 150,
    field: "payment_option",
    headerName: "Payment Option",
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data?.payment_option
          ? commitmentsPaymentOptionLabels[data.payment_option]
          : ""}
      </Box>
    ),
    valueGetter: reservationPaymentOptionCellValueGetter,
  },
  {
    width: 190,
    minWidth: 150,
    field: "net_savings_to_date",
    headerName: "Net Savings",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <HeaderCellWidthDateTooltip title="Net Savings" />
      ),
    },
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Typography variant="body2">
        {money(data?.net_savings_to_date ?? 0)}
      </Typography>
    ),
    valueGetter: ({ data }) => data?.net_savings_to_date ?? 0,
  },
  {
    width: 200,
    minWidth: 150,
    field: "offering_class",
    headerName: "Offering Class",
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Typography variant="body2">
        {capitalize(data?.offering_class ?? "")}
      </Typography>
    ),
    valueGetter: ({ data }) => capitalize(data?.offering_class ?? ""),
  },
  {
    width: 180,
    minWidth: 100,
    field: "start_date",
    headerName: "Start Date",
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data && <DatesCell date={data.start_date} />}
      </Box>
    ),
    valueGetter: reservationStartDateCellValueGetter,
  },
  {
    width: 180,
    minWidth: 100,
    field: "end_date",
    headerName: "End Date",
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data && <DatesCell date={data.end_date} state={data.state} />}
      </Box>
    ),
    valueGetter: reservationEndDateCellValueGetter,
  },
  {
    width: 250,
    minWidth: 200,
    sortable: false,
    filter: false,
    field: "tags",
    headerName: "Tags",
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data && <TagCell tags={data.tags} />}
      </Box>
    ),
  },
  {
    sortable: false,
    resizable: false,
    filter: false,
    suppressKeyboardEvent: () => true,
    field: "has_recommendation",
    headerName: "Recommendation",
    cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data?.has_recommendation ? (
          <Button
            size="small"
            variant="outlined"
            sx={{ textTransform: "initial" }}
          >
            <CommitmentsTableName
              id={data.provider_unique_id}
              type="commitment"
              name="Review"
            />
          </Button>
        ) : (
          <Typography variant="body2" color="text.disabled">
            No recommendation
          </Typography>
        )}
      </Box>
    ),
    valueGetter: reservationHasRecommendationCellValueGetter,
  },
  agGridEmptyColumnToFitEmptySpace,
];
