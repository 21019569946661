import { ValueGetterParams } from "ag-grid-community";
import { capitalize } from "@mui/material";
import { Budget } from "../../../../../../../../../services/cloudchipr.api";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../../store/alerts/utils/types/types";

export const budgetAndAlertsModifiedByFilterValueGetter = <
  T extends
    | Budget
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>({
  data,
}: ValueGetterParams<T>) => {
  const modifiedBy = data?.modified_by?.name ?? data?.modified_by?.email;

  if (!modifiedBy) {
    return;
  }

  return modifiedBy
    .split(" ")
    .map((name) => capitalize(name))
    .join(" ");
};
