import { FC } from "react";
import { Stack } from "@mui/material";
import { useDidMount } from "rooks";
import { TaskCreationDrawerCommentInput } from "./components/TaskCreationDrawerCommentInput";
import { TaskCreationDrawerActivitiesContent } from "./components/TaskCreationDrawerActivitiesContent";
import { TaskCreationDrawerActivitiesHeader } from "./components/TaskCreationDrawerActivitiesHeader";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { taskManagementTaskIdSelector } from "../../../../../../../../store/task-management/selectors/form/properties/taskManagementTaskIdSelector";
import { getTaskActivitiesThunk } from "../../../../../../../../store/task-management/thunks/activities/getTaskActivitiesThunk";

export const TaskCreationDrawerActivities: FC = () => {
  const dispatch = useAppDispatch();
  const taskId = useAppSelector(taskManagementTaskIdSelector);

  useDidMount(() => {
    dispatch(getTaskActivitiesThunk(taskId ?? ""));
  });

  return (
    <Stack flex={2} width="25dvw" height="100%" position="relative">
      <TaskCreationDrawerActivitiesHeader />
      <TaskCreationDrawerActivitiesContent />
      <TaskCreationDrawerCommentInput />
    </Stack>
  );
};
