import { FC, Fragment, useCallback, useMemo, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import { CreateIntegrationButton } from "./integrations/CreateIntegrationButton";
import { emailNotificationId } from "../utils/constants/constants";
import { IntegrationLogo } from "../../../../common/integration-dialogs/components/common/IntegrationLogo";
import { useGetUsersMeIntegrationsQuery } from "../../../../../services/cloudchipr.api";
import { IntegrationType } from "../../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import { IntegrationNotification } from "../utils/types/types";
import { setAutomationErrors } from "../../../../../store/automations/automationsSlice";
import { useAppDispatch } from "../../../../../store/hooks";
import EmailIcon from "../../../../../assets/images/icons/email-icon/EmailIcon";

interface AddNotificationButtonProps {
  onEmailsCreate(): void;
  onCreateDialogOpen(): void;
  disableEmailsSelection: boolean;
  onAddNewIntegration(
    type: IntegrationType,
    integrationId: string,
    addToFirst?: boolean,
  ): void;
  selectedIntegrations?: IntegrationNotification[] | null;
  disabledIntegrationTypes?: IntegrationType[];
}

export const AddNotificationButton: FC<AddNotificationButtonProps> = ({
  onAddNewIntegration,
  selectedIntegrations,
  onCreateDialogOpen,
  onEmailsCreate,
  disableEmailsSelection,
  disabledIntegrationTypes,
}) => {
  const dispatch = useAppDispatch();
  const { data } = useGetUsersMeIntegrationsQuery({});
  const [showSelect, setShowSelect] = useState(
    !(selectedIntegrations?.length || disableEmailsSelection),
  );

  const options = useMemo(() => {
    return data?.filter(
      (integration) => !disabledIntegrationTypes?.includes(integration.type),
    );
  }, [data, disabledIntegrationTypes]);

  const showButtonHandler = useCallback(() => {
    setShowSelect(false);
  }, []);

  const showSelectHandler = useCallback(() => {
    setShowSelect(true);
  }, []);

  const emailCreateHandler = useCallback(() => {
    onEmailsCreate();
    showButtonHandler();
  }, [onEmailsCreate, showButtonHandler]);

  const changeHandler = useCallback(
    (event: SelectChangeEvent<string>) => {
      const id = event.target.value;

      if (id === emailNotificationId) {
        emailCreateHandler();
        return;
      }

      const type = options?.find((integration) => integration.id === id)?.type;

      if (!type) {
        return;
      }

      onAddNewIntegration(type, id);
      dispatch(setAutomationErrors({ notifications: "" }));
      setShowSelect(false);
    },
    [dispatch, options, onAddNewIntegration, emailCreateHandler],
  );

  return (
    <Fragment>
      {showSelect && (
        <Stack spacing={2} pt={2}>
          {(selectedIntegrations?.length || disableEmailsSelection) && (
            <Divider sx={{ my: 1 }}>AND</Divider>
          )}

          <Stack direction="row" flexGrow={1} gap={2} alignItems="end">
            <FormControl size="small" fullWidth>
              <InputLabel>Select Notification Method</InputLabel>

              <Select
                onChange={changeHandler}
                label="Select Notification Method"
              >
                <MenuItem
                  value={emailNotificationId}
                  disabled={disableEmailsSelection}
                >
                  <ListItemIcon>
                    <EmailIcon width={20} />
                  </ListItemIcon>

                  <ListItemText>Email</ListItemText>
                </MenuItem>

                <Divider />

                <ListSubheader sx={{ lineHeight: 1 }}>
                  <Typography variant="caption" color="text.secondary">
                    INTEGRATIONS
                  </Typography>
                </ListSubheader>

                {options?.map((integration) => {
                  return (
                    <MenuItem
                      key={integration.id}
                      value={integration.id}
                      disabled={selectedIntegrations?.some(
                        ({ integration_id }) =>
                          integration_id === integration.id,
                      )}
                    >
                      <ListItemIcon>
                        <IntegrationLogo
                          variant="colored"
                          type={integration.type}
                          width={20}
                        />
                      </ListItemIcon>

                      <ListItemText>{integration.name}</ListItemText>
                    </MenuItem>
                  );
                })}

                <CreateIntegrationButton
                  onClick={onCreateDialogOpen}
                  empty={!options?.length}
                />
              </Select>
            </FormControl>

            {(!!selectedIntegrations?.length || disableEmailsSelection) && (
              <IconButton onClick={showButtonHandler}>
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>
      )}

      <Button
        color="primary"
        variant="outlined"
        disabled={showSelect}
        startIcon={<AddIcon />}
        onClick={showSelectHandler}
        sx={{ mt: 2, textTransform: "none" }}
      >
        Add Notification Method
      </Button>
    </Fragment>
  );
};
