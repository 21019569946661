import { ValueGetterParams } from "ag-grid-community";
import {
  AzureReservation,
  AzureSavingsPlan,
  CommitmentResourceType,
  SavingsPlan,
} from "../../../../../../services/cloudchipr.api";
import {
  commitmentsPaymentOptionLabels,
  commitmentsResourceTypeNames,
  savingsPlansTypeLabels,
} from "../../../common/utils/constants/labels";
import { money } from "../../../../../../utils/numeral/money";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { SavingsPlanCoverageDataWithId } from "../../../common/utils/types";
import { scopeLabels } from "../../../common/components/data-grid/cells/ScopeCell";

export const savingsPlansUtilizationCellValueGetter = ({
  data,
}: ValueGetterParams<SavingsPlan>) =>
  `${(data?.used_commitment_to_date ?? 0).toFixed(2)} / ${(data?.total_commitment_to_date ?? 0).toFixed(2)} (${(((data?.used_commitment_to_date ?? 0) / (data?.total_commitment_to_date ?? 1)) * 100).toFixed(2)}%)`;

export const azureSavingsPlansUtilizationCellValueGetter = ({
  data,
}: ValueGetterParams<AzureSavingsPlan>) =>
  `${(data?.used_commitment_to_date?.cost ?? 0).toFixed(2)} / ${(data?.total_commitment_to_date?.cost ?? 0).toFixed(2)} (${(((data?.used_commitment_to_date?.cost ?? 0) / (data?.total_commitment_to_date?.cost ?? 1)) * 100).toFixed(2)}%)`;

export const savingsPlansTypeCellValueGetter = <
  T extends AzureSavingsPlan | SavingsPlan,
>({
  data,
}: ValueGetterParams<T>) =>
  data?.type ? (savingsPlansTypeLabels[data?.type] ?? "") : "";

export const savingsPlansAccountCellValueGetter = ({
  data,
}: ValueGetterParams<SavingsPlan>) =>
  `${data?.account?.provider_account_name ?? ""} ${data?.account?.provider_account_id ?? ""}`;

export const savingsPlansPaymentOptionCellValueGetter = <
  T extends AzureSavingsPlan | SavingsPlan,
>({
  data,
}: ValueGetterParams<T>) =>
  data?.payment_option
    ? (commitmentsPaymentOptionLabels[data?.payment_option] ?? "")
    : "";

export const savingsPlansCommitmentCellValueGetter = ({
  data,
}: ValueGetterParams<SavingsPlan>) =>
  money(data?.total_commitment_to_date ?? 0);

export const azureSavingsPlansCommitmentCellValueGetter = ({
  data,
}: ValueGetterParams<AzureSavingsPlan>) =>
  money(data?.total_commitment_to_date?.cost ?? 0);

export const savingsPlansNetSavingsCellValueGetter = <
  T extends AzureSavingsPlan | SavingsPlan,
>({
  data,
}: ValueGetterParams<T>) => money(data?.net_savings_to_date ?? 0);

export const savingsPlansStartDateCellValueGetter = <
  T extends AzureSavingsPlan | SavingsPlan,
>({
  data,
}: ValueGetterParams<T>) =>
  formatDate(data?.start_date ?? "", { type: "date" });

export const savingsPlansEndDateCellValueGetter = <
  T extends AzureSavingsPlan | SavingsPlan,
>({
  data,
}: ValueGetterParams<T>) => formatDate(data?.end_date ?? "", { type: "date" });

export const savingsPlansHasRecommendationCellValueGetter = <
  T extends AzureSavingsPlan | SavingsPlan,
>({
  data,
}: ValueGetterParams<T>) =>
  data?.has_recommendation ? "Has recommendation" : "No recommendation";

export const savingsPlanCoverageResourceTypeValueGetter = ({
  data,
}: ValueGetterParams<SavingsPlanCoverageDataWithId>) => {
  const rt = data?.resource_type?.resource_type as CommitmentResourceType;

  if (!rt) {
    return;
  }

  return commitmentsResourceTypeNames[rt];
};

export const savingsPlansScopeCellValueGetter = <
  T extends AzureReservation | AzureSavingsPlan,
>({
  data,
}: ValueGetterParams<T>) => {
  if (!data) {
    return;
  }

  const { name, type, id } = data.scope;
  const secondary = name ?? id;
  const secondaryText = secondary ? "- " + secondary : "";

  return `${scopeLabels?.[type]} ${secondaryText}`;
};
