import { FC, useMemo } from "react";
import { Stack, TypographyProps } from "@mui/material";
import {
  commitmentsPaymentOptionLabels,
  commitmentsStateLabels,
  savingsPlansTypeLabels,
} from "../../../../common/utils/constants/labels";
import { useAppSelector } from "../../../../../../../store/hooks";
import { selectedSavingsPlanDataSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/savings-plan/selectedSavingsPlanDataSelector";
import { TagCell } from "../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { money } from "../../../../../../../utils/numeral/money";
import { AccountCell } from "../../../../common/components/data-grid/cells/AccountCell";
import { DatesCell } from "../../../../common/components/data-grid/cells/DatesCell";

import { KeyValueSplitChip } from "../../../../../../common/chips/KeyValueSplitChip";

export const SavingsPlanDrawerContentHeaderInfoCards: FC = () => {
  const savingsPlan = useAppSelector(selectedSavingsPlanDataSelector);

  const data = useMemo(() => {
    const type = savingsPlan ? savingsPlansTypeLabels[savingsPlan.type] : "";
    const status = savingsPlan
      ? commitmentsStateLabels[savingsPlan?.state]
      : "";
    const paymentOption = savingsPlan
      ? commitmentsPaymentOptionLabels[savingsPlan.payment_option]
      : "";
    const tags = savingsPlan?.tags?.length ? (
      <TagCell tags={savingsPlan.tags} widthToEllipsis={130} />
    ) : (
      "-"
    );

    return {
      Type: type,
      "Payment Option": paymentOption,
      "Linked Account": (
        <AccountCell
          TypographyProps={typographyProps}
          accountName={savingsPlan?.account?.provider_account_name}
          isRootAccount={savingsPlan?.account?.is_provider_root_account}
        />
      ),
      "Instance Family": savingsPlan?.ec2_instance_family,
      "Start Date": (
        <DatesCell
          TypographyProps={typographyProps}
          date={savingsPlan?.start_date ?? ""}
        />
      ),
      "End Date": (
        <DatesCell
          TypographyProps={typographyProps}
          date={savingsPlan?.end_date ?? ""}
        />
      ),
      Region: savingsPlan?.region,
      Status: status,
      "Total Commitment":
        savingsPlan?.total_commitment === null
          ? "N/A"
          : `${money(savingsPlan?.hourly_commitment)}/hour`,
      Tags: tags,
    };
  }, [savingsPlan]);

  if (!data) {
    return null;
  }

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {Object.entries(data).map(([title, value]) => {
        const props = {
          title,
          key: title,
          width: 200,
          valueSx: value === "Active" ? statusActiveStyles : undefined,
        };

        if (typeof value === "string") {
          return <KeyValueSplitChip {...props} value={value} />;
        }

        return <KeyValueSplitChip {...props}>{value}</KeyValueSplitChip>;
      })}
    </Stack>
  );
};

const typographyProps: TypographyProps = {
  variant: "body2",
  fontWeight: "medium",
};

const statusActiveStyles = {
  color: "white",
  bgcolor: "success.light",
};
