import { taskManagementTargetSelector } from "../common/taskManagementTargetSelector";
import { RootState } from "../../../../../store";
import { ResourceTarget } from "../../../../../../services/cloudchipr.api";
import { tasksListResourceIdsSelector } from "../../../data/tasksListResourceIdsSelector";
import { isTaskManagementDrawerEditModeSelector } from "../../options/isTaskManagementDrawerEditModeSelector";
import { TaskTargetResource } from "../../../../utils/types/types";

export const taskManagementTargetResourcesSelector = (
  state: RootState,
): ResourceTarget[] | undefined => {
  const target = taskManagementTargetSelector(state) as TaskTargetResource;
  const resourceIdsInTasks = tasksListResourceIdsSelector(state);
  const editMode = isTaskManagementDrawerEditModeSelector(state);

  if (editMode) {
    return target?.resources;
  }

  return target?.resources?.filter(
    (resource) => !resourceIdsInTasks[resource.provider_identifier],
  );
};
