import { getUserPermissionInfoText } from "../../../../../utils/helpers/getUserPermissionInfoText";

interface GetInviteButtonTooltipTextArgs {
  cannotCreateUser?: boolean;
  hasSsoSetup?: boolean;
}

export const getInviteButtonTooltipText = ({
  cannotCreateUser,
  hasSsoSetup,
}: GetInviteButtonTooltipTextArgs) => {
  if (cannotCreateUser) {
    return getUserPermissionInfoText("invite users");
  }
  if (hasSsoSetup) {
    return "Your organization uses SSO, so users can't be invited directly from Cloudchipr. Please add users through your SSO provider.";
  }

  return "";
};
