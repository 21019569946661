import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Typography } from "@mui/material";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../services/cloudchipr.api";
import { ReservationsCoverageDataWithId } from "../../../../../common/utils/types";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { CommitmentsTableName } from "../../../../../common/components/data-grid/cells/CommitmentsTableName";
import {
  reservationCoverageAccountCellValueGetter,
  reservationCoverageCoveredHoursCellValueGetter,
  reservationCoverageResourceTypeCellValueGetter,
} from "../utils/helpers/valueGetters";
import { commitmentsResourceTypeNames } from "../../../../../common/utils/constants/labels";
import { ReservationsColumnsHeaderCellWithTotalAndDate } from "../components/cells/ReservationsColumnsHeaderCellWithTotalAndDate";
import { money } from "../../../../../../../../utils/numeral/money";
import { HeaderCellWidthDateTooltip } from "../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { CommitmentsProgressBar } from "../../../../../common/components/CommitmentsProgressBar";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { ReservationCoverageAccountCellRenderer } from "../components/cells/ReservationCoverageAccountCellRenderer";

const groupedByAccountColumns: ColDef<ReservationsCoverageDataWithId>[] = [
  {
    width: 250,
    minWidth: 200,
    field: "account.name",
    headerName: "Account",
    suppressKeyboardEvent: () => true,
    lockVisible: true,
    suppressColumnsToolPanel: true,
    cellRenderer: ReservationCoverageAccountCellRenderer,
    valueGetter: reservationCoverageAccountCellValueGetter,
  },
];

const groupedByResourceColumns: ColDef<ReservationsCoverageDataWithId>[] = [
  {
    width: 220,
    minWidth: 150,
    field: "resource_type.resource_type",
    headerName: "Resource Type",
    suppressKeyboardEvent: () => true,
    lockVisible: true,
    suppressColumnsToolPanel: true,
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => {
      const rt = data?.resource_type?.resource_type;

      if (!rt) {
        return;
      }

      return (
        <CommitmentsTableName
          type="resource_type"
          id={data.id}
          name={
            <TypographyWithTooltip
              title={commitmentsResourceTypeNames[rt]}
              variant="body2"
              align="left"
            />
          }
        />
      );
    },
    valueGetter: reservationCoverageResourceTypeCellValueGetter,
  },
  {
    width: 210,
    minWidth: 150,
    field: "resource_type.instance_type",
    headerName: "Instance Type",
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
      <TypographyWithTooltip
        variant="inherit"
        title={data?.resource_type?.instance_type ?? "-"}
      />
    ),
    valueGetter: ({ data }) => data?.resource_type?.instance_type ?? "-",
  },
  {
    width: 170,
    minWidth: 150,
    field: "resource_type.platform",
    headerName: "Platform",
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
      <TypographyWithTooltip
        variant="inherit"
        title={data?.resource_type?.platform ?? "-"}
      />
    ),
    valueGetter: ({ data }) => data?.resource_type?.platform ?? "-",
  },
  {
    width: 180,
    minWidth: 150,
    field: "resource_type.region",
    headerName: "Region/AZ",
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
      <TypographyWithTooltip
        variant="inherit"
        title={data?.resource_type?.region ?? "-"}
      />
    ),
    valueGetter: ({ data }) => data?.resource_type?.region ?? "-",
  },
];

const columnsWithoutGrouping: ColDef<ReservationsCoverageDataWithId>[] = [
  {
    width: 250,
    minWidth: 150,
    field: "covered_hours",
    headerName: "Covered / Total Hours",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <ReservationsColumnsHeaderCellWithTotalAndDate
          title="Covered / Total Hours"
          type="coveredHours"
        />
      ),
    },
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => {
      // @ts-expect-error: TODO fix types
      const uncoveredHours = data?.uncovered_hours ?? 0;

      if (!data) {
        return;
      }

      return (
        <Typography variant="body2">
          {data.covered_hours}h / {uncoveredHours + data.covered_hours}h
        </Typography>
      );
    },
    valueGetter: reservationCoverageCoveredHoursCellValueGetter,
  },
  {
    width: 250,
    minWidth: 150,
    field: "covered_usage",
    headerName: "Covered / Total Hours",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <ReservationsColumnsHeaderCellWithTotalAndDate
          title="Spend Covered by RIs"
          type="coveredUsage"
        />
      ),
    },
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => {
      if (!data) {
        return;
      }

      return (
        <Typography variant="body2">{money(data.covered_usage)}</Typography>
      );
    },
  },
  {
    width: 280,
    minWidth: 150,
    field: "uncovered_usage",
    headerName: "Not Covered (On-Demand)",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <ReservationsColumnsHeaderCellWithTotalAndDate
          title="Not Covered (On-Demand)"
          type="unCoveredUsage"
        />
      ),
    },
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => {
      if (!data) {
        return;
      }

      return (
        <Typography variant="body2">{money(data.uncovered_usage)}</Typography>
      );
    },
  },
  {
    width: 190,
    minWidth: 150,
    field: "net_savings",
    headerName: "Net Savings",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <ReservationsColumnsHeaderCellWithTotalAndDate
          title="Net Savings"
          type="netSavings"
        />
      ),
    },
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => {
      if (!data) {
        return;
      }

      return <Typography variant="body2">{money(data.net_savings)}</Typography>;
    },
  },
  {
    width: 200,
    resizable: false,
    field: "coverage",
    headerName: "Coverage",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <HeaderCellWidthDateTooltip title="Coverage" />
      ),
    },
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => {
      if (!data) {
        return;
      }

      return <CommitmentsProgressBar value={data.coverage} />;
    },
    valueGetter: ({ data }) => `${data?.coverage ?? 0}%`,
  },
  agGridEmptyColumnToFitEmptySpace,
];

const accountGroupedColumns: ColDef<ReservationsCoverageDataWithId>[] = [
  ...groupedByAccountColumns,
  ...columnsWithoutGrouping,
];
const resourceGroupedColumns: ColDef<ReservationsCoverageDataWithId>[] = [
  ...groupedByResourceColumns,
  ...columnsWithoutGrouping,
];

export const reservationCoverageTableColumnsDefs = (
  grouping: SavingsPlanCoverageGrouping,
): ColDef<ReservationsCoverageDataWithId>[] => {
  if (grouping === "account") {
    return accountGroupedColumns;
  }

  if (grouping === "resource_type") {
    return resourceGroupedColumns;
  }

  return [];
};
