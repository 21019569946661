import { FC, ReactNode, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import moment from "moment/moment";

interface TaskActivityWrapperProps {
  content: ReactNode;
  createdAt: string;
}

export const TaskActivityWrapper: FC<TaskActivityWrapperProps> = ({
  content,
  createdAt,
}) => {
  const localDate = useMemo(
    () =>
      moment
        .utc(createdAt, "MMM DD [at] HH:mm")
        .local()
        .format("MMM DD [at] HH:mm"),
    [createdAt],
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      px={1}
    >
      {content}

      <Typography variant="caption" color="text.secondary" whiteSpace="nowrap">
        {localDate}
      </Typography>
    </Stack>
  );
};
