import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../../../../services/cloudchipr.api";
import {
  AzureSavingsPlanCoverageDataWithId,
  ReservationsCoverageDataWithId,
} from "../../../../../../../../../common/utils/types";
import { CommitmentsProgressBar } from "../../../../../../../../../common/components/CommitmentsProgressBar";
import { getUtilizationAndCoverageDrawerDynamicHeaderCellTitles } from "../../../../../../../../../utilization-and-coverage/utils/helpers/getUtilizationAndCoverageDrawerDynamicHeaderCellTitles";

export const getAzureCoverageColumnDef = (
  grouping: SavingsPlanCoverageGrouping,
): ColDef<AzureSavingsPlanCoverageDataWithId> => ({
  flex: 1.5,
  field: "coverage",
  headerName: getUtilizationAndCoverageDrawerDynamicHeaderCellTitles(
    "coverage",
    grouping,
  )?.coverageCell,
  minWidth: 200,
  cellRenderer: ({
    data,
  }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => {
    if (!data) {
      return;
    }

    return (
      <CommitmentsProgressBar
        overrideColor="info.light"
        value={data.coverage}
      />
    );
  },
});
