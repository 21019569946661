import { FC, Fragment, useCallback, useMemo } from "react";
import Button from "@mui/material/Button";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { accessTypeNames } from "../../../../../common/chips/account-access-type-chip/utils/constants/accessTypeNames";
import {
  ProviderAccessType,
  usePatchUsersMeProvidersByProviderAccountsAndAccountIdAccessModeMutation,
} from "../../../../../../services/cloudchipr.api";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { AzureSubscriptionAppIdCodeSnippet } from "../../../../common/connectors/components/azure/AzureSubscriptionAppIdCodeSnippet";
import { accountAzureProviderDetailsByAccountIdSelector } from "../../../../../../store/accounts/selectors/azure/accountAzureProviderDetailsByAccountIdSelector";
import { getAccountsThunk } from "../../../../../../store/accounts/thunks/getAccountsThunk";
import {
  AzureAccessCommand,
  generateAzureCommand,
} from "../../../../common/connectors/components/azure/utils/generateAzureCommand";

interface AccountSwitchModelAzureDialogProps {
  providerAccessType: ProviderAccessType;
  onClose(): void;
  accountId: string;
}

export const AccountSwitchModeAzureDialog: FC<
  AccountSwitchModelAzureDialogProps
> = ({ accountId, onClose, providerAccessType }) => {
  const providerDetails = useAppSelector((state) => {
    return accountAzureProviderDetailsByAccountIdSelector(state, accountId);
  });
  const longOppositeAccessType = accessTypeNames[providerAccessType].long;
  const shortOppositeAccessType = accessTypeNames[providerAccessType].short;

  const azureSubscriptionCommands: AzureAccessCommand[] = useMemo(() => {
    const commands: AzureAccessCommand[] = [
      {
        actionType: providerAccessType === "read_write" ? "create" : "delete",
        role: "contributor",
      },
    ];
    if (providerAccessType !== "read_write") {
      commands.unshift({
        actionType: "create",
        role: "reader",
      });
    }
    return commands;
  }, [providerAccessType]);

  const dispatch = useAppDispatch();

  const [trigger, { isLoading }] =
    usePatchUsersMeProvidersByProviderAccountsAndAccountIdAccessModeMutation();

  const handleSwitchToClick = useCallback(async () => {
    await trigger({
      accountId,
      provider: "azure",
      body: {
        access_type: providerAccessType,
      },
    });
    onClose();

    dispatch(getAccountsThunk());
  }, [accountId, providerAccessType, trigger, dispatch, onClose]);

  if (!providerDetails) {
    return null;
  }

  const command = generateAzureCommand({
    type: "subscription",
    actionType: "update",
    providerAccessType,
    identifier: providerDetails?.subscription_id,
    servicePrincipal: providerDetails?.service_principal || "",
  });

  const azureSnippetFirstLine = `In order to switch to ${shortOppositeAccessType} mode please copy and execute the following snippet in your terminal.`;
  return (
    <Fragment>
      <DialogTitleClosable
        title={`Switch to  ${longOppositeAccessType}`}
        onClose={onClose}
        TitleProps={{ sx: { px: 4 } }}
        TitleTypographyProps={{
          variant: "subtitle1",
          fontWeight: "bold",
        }}
      />
      <DialogContent sx={{ p: 4 }} dividers>
        <Typography color="text.secondary" fontSize={14} fontWeight={500}>
          {azureSnippetFirstLine}
        </Typography>
        <AzureSubscriptionAppIdCodeSnippet
          command={command}
          scope="subscription"
          identifier={providerDetails?.subscription_id}
          servicePrincipal={providerDetails?.service_principal}
          commands={azureSubscriptionCommands}
        />
      </DialogContent>
      <DialogActions sx={{ px: 4, py: 3 }}>
        <Button
          variant="text"
          color="tertiary"
          onClick={onClose}
          sx={{ textTransform: "none" }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isLoading}
          onClick={handleSwitchToClick}
          sx={{ textTransform: "none" }}
        >
          Done
        </LoadingButton>
      </DialogActions>
    </Fragment>
  );
};
