import moment from "moment";
import { ChartDataType } from "../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { formatDateByFrequencyType } from "../../../../../utils/helpers/date-time/datetime-format";
import { DateDataPoint } from "../../../../../services/cloudchipr.api";
import { chartDataPointKey } from "../../../../../storybook/charts/multi-type-chart/utils/constants/constants";

export const normalizeResourceExplorerChartData = (
  chartData: ChartDataType[],
  dataPoint?: DateDataPoint,
  quarterStartMonth?: number,
) => {
  const allKeysTotal = chartData.reduce(
    (acc, item) => {
      const keys = Object.keys(item);
      keys.forEach((key) => {
        const value = +(item[key] ?? 0);

        if (value || value === 0) {
          acc[key] = (acc[key] ?? 0) + (item[key] ?? 0);
        }
      });

      return acc;
    },
    {} as Record<string, number>,
  );

  let sortedKeys = Object.keys(allKeysTotal).sort((a, b) => {
    return allKeysTotal[b] - allKeysTotal[a];
  });

  let otherKey = "";
  const keysWithoutOther = sortedKeys.filter((key) => {
    if (key.toLowerCase().includes("other")) {
      otherKey = key;
      return false;
    }

    return true;
  });

  if (otherKey) {
    sortedKeys = [...keysWithoutOther, otherKey];
  }

  return chartData.map((data) => {
    const dataDate = data.date ?? data[chartDataPointKey];

    const date = dataPoint
      ? formatDateByFrequencyType(
          dataPoint,
          data[chartDataPointKey],
          quarterStartMonth,
        )
      : dataDate;

    const isDateValid =
      dataPoint === "weekly" || dataPoint === "quarterly"
        ? !!date
        : moment(date).isValid();

    const result = {
      [chartDataPointKey]: isDateValid ? date : dataDate,
    } as ChartDataType;

    sortedKeys.forEach((key) => {
      result[key] = data[key];
    });

    return result;
  });
};
