import { ColDef } from "ag-grid-community";
import { IntegrationsEmailCellRenderer } from "./cell-renderers/IntegrationsEmailCellRenderer";
import { IntegrationsInUseCellRenderer } from "../../../common/components/grid/cell-renderers/in-use-cell/IntegrationsInUseCellRenderer";
import { IntegrationsCreatedAtCellRenderer } from "../../../common/components/grid/cell-renderers/IntegrationsCreatedAtCellRenderer";
import { IntegrationsActionCellRenderer } from "../../../common/components/grid/cell-renderers/action-cell/IntegrationsActionCellRenderer";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { IntegrationsNameCellRenderer } from "../../../common/components/grid/cell-renderers/IntegrationsNameCellRenderer";
import { integrationsInUseComparator } from "../../../common/components/grid/comparators/integrationsInUseComparator";
import { integrationsInUseValueGetter } from "../../../common/components/grid/value-getters/integrationsInUseValueGetter";
import { integrationsCreatedAtQuickFilter } from "../../../common/components/grid/quick-filters/integrationsCreatedAtQuickFilter";

export const emailIntegrationGridColumns: ColDef[] = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    cellRenderer: IntegrationsNameCellRenderer,
  },
  {
    field: "usages",
    headerName: "In Use",
    minWidth: 300,
    cellRenderer: IntegrationsInUseCellRenderer,
    valueGetter: integrationsInUseValueGetter,
    comparator: integrationsInUseComparator,
  },
  {
    field: "metadata.emails",
    headerName: "Email",
    minWidth: 220,
    cellRenderer: IntegrationsEmailCellRenderer,
  },
  {
    field: "created_at",
    headerName: "Created At",
    minWidth: 240,
    cellRenderer: IntegrationsCreatedAtCellRenderer,
    getQuickFilterText: integrationsCreatedAtQuickFilter,
  },
  agGridEmptyColumnToFitEmptySpace,
  {
    colId: "actions",
    width: 80,
    minWidth: 80,
    pinned: "right",
    resizable: false,
    unSortIcon: false,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    cellRenderer: IntegrationsActionCellRenderer,
  },
];
