import { useCallback, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { ExploreWithAIButton } from "../../../../../../../storybook/explore-with-AI-button/ExploreWithAIButton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerNameSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerNameSelector";
import { billingExplorerSummaryLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/loading/billingExplorerSummaryLoadingSelector";
import { billingExplorerSummaryDataSelector } from "../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/billingExplorerSummaryDataSelector";
import { getBillingExplorerSummaryThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer-summary/getBillingExplorerSummaryThunk";

export const ResourceExplorerSummary = () => {
  const resourceExplorerName = useAppSelector(resourceExplorerNameSelector);

  const data = useAppSelector((state) =>
    billingExplorerSummaryDataSelector(state, 0),
  );
  const loading = useAppSelector(billingExplorerSummaryLoadingSelector);
  const dispatch = useAppDispatch();

  const popOverMountHandler = useCallback(() => {
    dispatch(getBillingExplorerSummaryThunk());
  }, [dispatch]);

  const title = useMemo(() => {
    return (
      <Typography>
        Explaining{" "}
        <Typography fontWeight="bold" component="span">
          {resourceExplorerName}
        </Typography>{" "}
        billing report with AI
      </Typography>
    );
  }, [resourceExplorerName]);

  return (
    <ExploreWithAIButton
      title={title}
      onPopoverMount={popOverMountHandler}
      loading={loading}
      popoverContent={data ?? ""}
    />
  );
};
