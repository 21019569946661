import { FC, MouseEventHandler, useCallback } from "react";
import { Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { TypographyWithCopy } from "../../../../../../../../../../../common/TypographyWithCopy";
import { TypographyWithTooltip } from "../../../../../../../../../../../common/TypographyWithTooltip";
import { initiateResourceDetailsDrawerThunk } from "../../../../../../../../../../../../store/resource-details/thunks/initiateResourceDetailsDrawerThunk";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../store/hooks";
import {
  ProviderType,
  ResourceType,
} from "../../../../../../../../../../../../services/cloudchipr.api";
import { getResourceExplorerSingleResourceDataThunk } from "../../../../../../../../../../../../store/resource-explorer/thunks/resource-explorer/getResourceExplorerSingleResourceDataThunk";
import { resourceExplorerGroupingSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";

interface LabelColumnCellLabelProps {
  title: string;
  label: string;
  region: string;
  tooltip: string;
  hovered: boolean;
  isTracked: boolean;
  filterValue: string;
  liveAccountId: string;
  resourceId: string;
  accountId: string;
  service: ResourceType;
  providerAccountId: string;
  providerAccountName: string;
  liveUsageProvider?: ProviderType;
}

export const LabelColumnCellLabel: FC<LabelColumnCellLabelProps> = ({
  tooltip,
  isTracked,
  hovered,
  title,
  liveUsageProvider,
  liveAccountId,
  accountId,
  providerAccountName,
  providerAccountId,
  region,
  filterValue,
  service,
  resourceId,
  label,
}) => {
  const dispatch = useAppDispatch();
  const enableLiveUsageResourceDetailsDrawer = useFlag(
    "EnableLiveUsageResourceDetailsDrawer",
  );

  const groupBy = useAppSelector(resourceExplorerGroupingSelector);

  const withResourceDetailsDrawer =
    enableLiveUsageResourceDetailsDrawer &&
    liveAccountId &&
    liveUsageProvider &&
    groupBy === "resource";

  const resourceDetailsDrawerOpenHandler: MouseEventHandler = useCallback(
    async (e) => {
      e.stopPropagation();

      if (!liveUsageProvider) {
        return;
      }

      const data = await dispatch(
        getResourceExplorerSingleResourceDataThunk({
          provider: liveUsageProvider,
          accountId,
          resourceId,
        }),
      ).unwrap();

      dispatch(
        initiateResourceDetailsDrawerThunk({
          openedFrom: "billingExplorer",
          availableTabs: ["general", "costs"],
          resource: {
            region,
            provider: liveUsageProvider,
            providerAccountId: providerAccountId,
            name: providerAccountId,
            providerAccountName: providerAccountName,
            accountId: liveAccountId,
            provider_unique_identifier: filterValue,
            resourceType: service,
            tags: data?.tags ?? [],
          },
          resourceAdditionalDetails: [
            { title: "Name", value: label || filterValue },
            { title: "Instance Id", value: filterValue },
            {
              title: "Account",
              value: providerAccountName || providerAccountId,
            },
            { title: "Region", value: region },
          ],
        }),
      );
    },
    [
      label,
      region,
      service,
      dispatch,
      accountId,
      resourceId,
      filterValue,
      liveAccountId,
      providerAccountId,
      liveUsageProvider,
      providerAccountName,
    ],
  );

  return (
    <Stack width="100%" justifyContent="center" overflow="hidden">
      {title &&
        (withResourceDetailsDrawer ? (
          <TypographyWithCopy
            color="primary"
            value={title}
            hideCopyButtonArea
            overrideHovered={hovered}
            sx={{ cursor: "pointer" }}
            onClick={resourceDetailsDrawerOpenHandler}
          />
        ) : (
          <TypographyWithCopy
            color={isTracked ? "red" : undefined}
            overrideHovered={hovered}
            value={title}
            hideCopyButtonArea
          />
        ))}

      {tooltip && (
        <TypographyWithTooltip
          variant="body2"
          title={tooltip ?? ""}
          color="text.disabled"
          fontSize={10}
        />
      )}
    </Stack>
  );
};
