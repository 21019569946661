import { ColDef } from "ag-grid-community";

export const automationsModifiedBySortingFn: ColDef["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const current =
    nodeA.data.modified_by?.name ?? nodeA.data.modified_by?.email ?? "";
  const next =
    nodeB.data.modified_by?.name ?? nodeB.data.modified_by?.email ?? "";

  return current.localeCompare(next);
};
