import { FC } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { taskManagementTaskIdSelector } from "../../../../../../../../../store/task-management/selectors/form/properties/taskManagementTaskIdSelector";
import { CommentEditor } from "../../../../../../../task-management/components/grid/cell-renderers/comment-cell/components/comment-editor/CommentEditor";

export const TaskCreationDrawerCommentInput: FC = () => {
  const taskId = useAppSelector(taskManagementTaskIdSelector);

  if (!taskId) {
    return;
  }

  return (
    <Stack p={2} width="100%" borderTop={1} borderColor="grey.300">
      <CommentEditor taskId={taskId} focused={false} />
    </Stack>
  );
};
