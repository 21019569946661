import { FC, useCallback } from "react";
import { Button, Stack, Tooltip } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../store/hooks";
import { resourceExplorerGroupingSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import {
  ResourceExplorerFilterProvider,
  ResourceExplorerGroupingNullable,
} from "../../../../../../../../../../../../services/cloudchipr.api";
import { resourceExplorerPathSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathSelector";
import { canRowDrillDownMoreSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/canRowDrillDownMoreSelector";
import { changePathOnRowSelectThunk } from "../../../../../../../../../../../../store/resource-explorer/thunks/widgets/path/changePathOnRowSelectThunk";
import { setResourceExplorerSearch } from "../../../../../../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { resourceExplorerGroupingNextGroupingByProviderSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingNextGroupingByProviderSelector";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";

export interface RowSelectionButtonProps {
  aggregatedByValue?: string;
  clickable?: boolean;
  field?: string | null;
  filterValue?: string | null;
  provider: ResourceExplorerFilterProvider;
  rowNextGrouping?: ResourceExplorerGroupingNullable;
}

export const RowSelectionButton: FC<RowSelectionButtonProps> = ({
  aggregatedByValue,
  clickable,
  field,
  provider,
  filterValue,
  rowNextGrouping,
}) => {
  const name = field;
  const uncategorized = field?.toLowerCase() === "uncategorized";
  const value = filterValue ?? field;

  const dispatch = useAppDispatch();
  const groupedBy = useAppSelector(resourceExplorerGroupingSelector);
  const currentPath = useAppSelector(resourceExplorerPathSelector);
  const canRowDrillDownMore = useAppSelector((state) =>
    canRowDrillDownMoreSelector(state, provider),
  );

  const nextGroupingByProvider = useAppSelector((state) => {
    return resourceExplorerGroupingNextGroupingByProviderSelector(
      state,
      groupedBy,
      provider,
    );
  });

  const nextGrouping = rowNextGrouping ?? (groupedBy && nextGroupingByProvider);

  const groupingLabel = useAppSelector((state) =>
    resourceExplorerGroupingLabelByGroupingSelector(state, nextGrouping),
  );

  const rowSelectHandler = useCallback(() => {
    dispatch(
      changePathOnRowSelectThunk({
        currentGroupBy: groupedBy,
        currentPath,
        pathName: name ?? "",
        filterValue: value ?? undefined,
        aggregated: !!aggregatedByValue,
        provider,
        uncategorized,
        nextGroupingDependingOnRowValue: rowNextGrouping,
      }),
    );
    dispatch(setResourceExplorerSearch(""));
  }, [
    dispatch,
    groupedBy,
    currentPath,
    name,
    value,
    aggregatedByValue,
    provider,
    uncategorized,
    rowNextGrouping,
  ]);

  const rowSelectionDisabled =
    !canRowDrillDownMore || clickable === false || !nextGrouping;

  const nextGroupingName = nextGrouping
    ? `Costs by ${groupingLabel?.plural}`
    : "";

  return (
    <Stack direction="row" justifyContent="center" mr={1}>
      <Tooltip
        arrow
        placement="top"
        title={rowSelectionDisabled ? "No internal elements" : nextGroupingName}
      >
        <span>
          <Button
            size="small"
            variant={rowSelectionDisabled ? "contained" : "outlined"}
            disabled={rowSelectionDisabled}
            onClick={rowSelectHandler}
            color={rowSelectionDisabled ? "tertiary" : "primary"}
            sx={{
              minWidth: 28,
              minHeight: 28,
              p: 0.5,
            }}
          >
            <ArrowForwardIcon sx={{ fontSize: 20 }} />
          </Button>
        </span>
      </Tooltip>
    </Stack>
  );
};
