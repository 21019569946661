import {
  MetricsFrequenciesKeysType,
  MetricsFrequenciesType,
} from "../types/metricsFrequenciesType";

export const generateMetricsCsvKey = (
  headerTitle?: string,
  metricsFrequencies?: MetricsFrequenciesType,
  id?: string,
) => {
  const tail =
    metricsFrequencies && id
      ? (metricsFrequencies?.[id as MetricsFrequenciesKeysType] ?? "")
      : "7d";
  return `${headerTitle ?? ""} ${tail}`;
};
