import { RootState } from "../../../store";
import { KubernetesCluster } from "../../../../services/cloudchipr.api";
import { kubernetesLiveUsageMgmtSelectedClusterIdsSelector } from "../store-selectors/kubernetesLiveUsageMgmtSelectedClusterIdsSelector";
import { kubernetesClustersSelector } from "../../../accounts/selectors/kubernetes/kubernetesClustersSelector";

export const kubernetesLiveUsageMgmtSelectedAccountsSelector = (
  state: RootState,
): KubernetesCluster[] | undefined => {
  const clusters = kubernetesClustersSelector(state);

  const selectedClusterIds =
    kubernetesLiveUsageMgmtSelectedClusterIdsSelector(state);

  if (!selectedClusterIds || !clusters) {
    return;
  }

  return selectedClusterIds?.reduce((result, item) => {
    const data = clusters?.find((account) => account.id === item);
    if (data) {
      result.push(data);
    }
    return result;
  }, [] as KubernetesCluster[]);
};
