import { ColDef } from "ag-grid-community";
import { azureSavingsPlanGroupedByResourceTypeColumnDefs } from "./saving-plan/azureSavingsPlanGroupedByResourceTypeColumnDefs";
import { azureSavingsPlanGroupedByAccountColumnDefs } from "./saving-plan/azureSavingsPlanGroupedByAccountColumnDefs";
import { azureCoverageGroupedByCommitmentColumnDefs } from "./coverage/azureCoverageGroupedByCommitmentColumnDefs";
import { azureCoverageGroupedByAccountColumnDefs } from "./coverage/azureCoverageGroupedByAccountColumnDefs";
import { azureCoverageGroupedByResourceTypeColumnDefs } from "./coverage/azureCoverageGroupedByResourceTypeColumnDefs";
import { azureCoverageGroupedByResourceTypeByRegionFilterTypeColumnDefs } from "./coverage/azureCoverageGroupedByResourceTypeByRegionFilterTypeColumnDefs";
import { AzureSavingsPlanCoverageDataWithId } from "../../../../../../../common/utils/types";
import { DataViewTab } from "../../../../../../../../../../store/commitments/utils/types/types";
import {
  CommitmentFilterType,
  SavingsPlanCoverageGrouping,
} from "../../../../../../../../../../services/cloudchipr.api";

type Columns = Partial<
  Record<
    SavingsPlanCoverageGrouping,
    ColDef<AzureSavingsPlanCoverageDataWithId>[]
  >
>;

const commonColumns = {
  coverage: {
    account: azureCoverageGroupedByAccountColumnDefs,
    commitment: azureCoverageGroupedByCommitmentColumnDefs,
    resource_type: azureCoverageGroupedByResourceTypeColumnDefs,
  },
  commitment: {
    account: azureSavingsPlanGroupedByAccountColumnDefs,
    resource_type: azureSavingsPlanGroupedByResourceTypeColumnDefs,
  },
};

export const azureUtilizationAndCoverageDrawerDataGridColumnDefs: Record<
  CommitmentFilterType,
  Partial<Record<DataViewTab, Columns>>
> = {
  resource_type: commonColumns,
  account: commonColumns,
  savings_plan: commonColumns,
  commitment: commonColumns,
  region: {
    coverage: {
      account: azureCoverageGroupedByAccountColumnDefs,
      commitment: azureCoverageGroupedByCommitmentColumnDefs,
      resource_type:
        azureCoverageGroupedByResourceTypeByRegionFilterTypeColumnDefs,
    },
    commitment: {
      account: azureSavingsPlanGroupedByAccountColumnDefs,
      resource_type: azureSavingsPlanGroupedByResourceTypeColumnDefs,
    },
  },
};
