import { FC, Fragment, useCallback } from "react";
import { TextWidgetSetupSidebar } from "./components/TextWidgetSetupSidebar";
import { TextWidgetSetupContent } from "./components/TextWidgetSetupContent";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { textWidgetSetupSelector } from "../../../../../../../../store/dashboards/selectors/setups/text/textWidgetSetupSelector";

import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import { buildTextWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/text-widget/actions/buildTextWidgetThunk";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";

import { textWidgetSubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/text/textWidgetSubmitButtonDisabledSelector";

interface TextWidgetSetupProps {
  onClose(): void;
  onSubmit(): void;
}

export const TextWidgetSetup: FC<TextWidgetSetupProps> = ({
  onClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();
  const setup = useAppSelector(textWidgetSetupSelector);

  const disabled = useAppSelector(textWidgetSubmitButtonDisabledSelector);

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(buildTextWidgetThunk()).unwrap();
    onSubmit();

    if (responseId && !setup?.id) {
      scrollPageToBottom();
    }
  }, [onSubmit, dispatch, setup?.id]);

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />
      <WidgetSetupLayout appBarWidth={560} appBar={<TextWidgetSetupSidebar />}>
        <TextWidgetSetupContent />
      </WidgetSetupLayout>
    </Fragment>
  );
};
