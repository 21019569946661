import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { LiveResourceFilters } from "../../../../common/resource-filters/LiveResourceFilters";
import { LiveUsageNavigationResourceType } from "../navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { LiveResourceFiltersTrigger } from "../../../../common/resource-filters/utils/types/types";
import { liveUsageMgmtProviderSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtAppliedAllFiltersCountSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAppliedAllFiltersCountSelector";
import { useAppSelector } from "../../../../../store/hooks";
import { liveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector } from "../../../../../store/live-usage-mgmt/selectors/liveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector";
import { LiveResourceFiltersContent } from "../../../../common/resource-filters/components/content/LiveResourceFiltersContent";

interface LiveUsageAccountFiltersProps {
  resourceType: LiveUsageNavigationResourceType;
}

export const LiveUsageResourceFilter: FC<LiveUsageAccountFiltersProps> = ({
  resourceType,
}) => {
  const provider = useAppSelector(liveUsageMgmtProviderSelector);
  const filtersCount = useAppSelector(
    liveUsageMgmtAppliedAllFiltersCountSelector,
  );
  const resourceTypeFiltersCountMap = useAppSelector(
    liveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector,
  );
  if (!provider) {
    return null;
  }
  return (
    <LiveResourceFilters
      singleResource
      provider={provider}
      filtersCount={filtersCount}
      popoverStaticHeight="unset"
      resourceType={resourceType}
      TriggerComponent={ResourceFiltersTextViewSourceButton}
      resourceTypeFiltersCountMap={resourceTypeFiltersCountMap}
      FiltersContent={LiveResourceFiltersContent}
    />
  );
};

const ResourceFiltersTextViewSourceButton: LiveResourceFiltersTrigger = ({
  onClick,
}) => {
  return (
    <Stack
      direction="row"
      spacing={0.5}
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      <FilterAltIcon fontSize="small" color="primary" />
      <Typography variant="body2" color="primary" fontWeight="medium">
        Filtered Results:
      </Typography>
    </Stack>
  );
};
