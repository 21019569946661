import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { TrendColumnHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/TrendColumnHeaderCell";
import { billingExplorerWidgetTableTrendSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetTableTrendSelector";

interface BillingExplorerWidgetTableTrendHeaderCellProps {
  widgetId: string;
}
export const BillingExplorerWidgetTableTrendHeaderCell: FC<
  BillingExplorerWidgetTableTrendHeaderCellProps
> = ({ widgetId }) => {
  const trend = useAppSelector((state) =>
    billingExplorerWidgetTableTrendSelector(state, widgetId),
  );
  return <TrendColumnHeaderCell trend={trend} />;
};
