import { FC, SyntheticEvent, useCallback } from "react";
import { Divider, Stack, Tab } from "@mui/material";
import { TabList } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setResourceDetailsActiveTab } from "../../../../../../store/resource-details/resourceDetailsSlice";
import { ResourceDetailsActiveTab } from "../../../../../../store/resource-details/utils/types/common";
import { resourceDetailsAvailableTabsSelector } from "../../../../../../store/resource-details/selectors/options/resourceDetailsAvailableTabsSelector";

export const ResourceDetailsHeaderTabs: FC = () => {
  const dispatch = useAppDispatch();
  const availableTabs = useAppSelector(resourceDetailsAvailableTabsSelector);

  const handleTabChange = useCallback(
    (_: SyntheticEvent, tab: ResourceDetailsActiveTab) => {
      dispatch(setResourceDetailsActiveTab(tab));
    },
    [dispatch],
  );

  if (!availableTabs?.length) {
    return null;
  }

  return (
    <Stack>
      <TabList sx={{ bgcolor: "white" }} onChange={handleTabChange}>
        {availableTabs.includes("general") && (
          <Tab value="general" label="General" sx={{ textTransform: "none" }} />
        )}

        {availableTabs.includes("costs") && (
          <Tab value="costs" label="Costs" sx={{ textTransform: "none" }} />
        )}

        {availableTabs.includes("metrics") && (
          <Tab value="metrics" label="Metrics" sx={{ textTransform: "none" }} />
        )}
      </TabList>

      <Divider />
    </Stack>
  );
};
