import { FC, ReactElement, useCallback, useEffect, useMemo } from "react";
import { Chip, Stack } from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { TaskPropertySelectionTrigger } from "./TaskPropertySelectionTrigger";
import { TaskPropertySelectionEditItem } from "./TaskPropertySelectionEditItem";
import { DropdownSelect } from "../../../../../../../common/select/dropdown-select/DropdownSelect";
import { DropdownSelectOption } from "../../../../../../../common/select/dropdown-select/utils/types/types";
import { FilterTriggerComponentProps } from "../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { taskAvailablePropertiesSelector } from "../../../../../../../../store/task-management/selectors/properties/taskAvailablePropertiesSelector";
import { taskManagementGetTasksThunk } from "../../../../../../../../store/task-management/thunks/actions/taskManagementGetTasksThunk";
import { reInitiateEditingTaskThunk } from "../../../../../../../../store/task-management/thunks/reInitiateEditingTaskThunk";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { TaskProperties } from "../../../../../../task-management/utils/types/types";

interface TaskPropertySelectionProps {
  label: string;
  required?: boolean;
  inCell?: boolean;
  value?: string | null;
  disableSelection?: boolean;
  optionAvatar?: ReactElement;
  onChange(value: string | null): void;
  optionsKey: TaskProperties;
}

export const TaskPropertySelection: FC<TaskPropertySelectionProps> = ({
  optionsKey,
  label,
  optionAvatar,
  value,
  onChange,
  required,
  disableSelection,
  inCell,
}) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(taskAvailablePropertiesSelector);
  const options = data?.[optionsKey];

  const renderOptions = useMemo(() => {
    const defaultOptions: DropdownSelectOption[] = [];

    if (!required) {
      defaultOptions.push({
        value: unselectOption,
        label: (
          <Stack justifyContent="center">
            <HorizontalRuleIcon color="action" fontSize="small" />
          </Stack>
        ),
      } as DropdownSelectOption);
    }

    options?.forEach((option) => {
      defaultOptions.push({
        rawValue: option,
        value: option.id,
        label: (
          <Chip
            size="small"
            variant="filled"
            label={
              <TypographyWithTooltip title={option.name} variant="inherit" />
            }
            avatar={optionAvatar}
            sx={{ bgcolor: option.color, color: "white" }}
          />
        ),
      });
    });

    return defaultOptions;
  }, [optionAvatar, options, required]);

  const changeHandler = useCallback(
    (values: string[]) => {
      const value = values.at(0) ?? null;

      onChange(value === unselectOption ? null : value);
    },
    [onChange],
  );

  const TriggerComponent = useCallback(
    (props: FilterTriggerComponentProps) => {
      return (
        <TaskPropertySelectionTrigger
          value={value}
          removable={!required}
          optionsKey={optionsKey}
          optionAvatar={optionAvatar}
          inCell={inCell}
          {...props}
        />
      );
    },
    [optionAvatar, optionsKey, required, inCell, value],
  );

  const deletedProperty = useMemo(() => {
    if (!value || !options) {
      return false;
    }

    return options.every((option) => option.id !== value);
  }, [options, value]);

  const deletedPropertyUpdateHandler = useCallback(async () => {
    if (deletedProperty) {
      await dispatch(taskManagementGetTasksThunk());
      dispatch(reInitiateEditingTaskThunk());
    }
  }, [dispatch, deletedProperty]);

  const DropdownFooter = useCallback(
    () => <TaskPropertySelectionEditItem propertyType={optionsKey} />,
    [optionsKey],
  );

  useEffect(() => {
    deletedPropertyUpdateHandler();
  }, [deletedPropertyUpdateHandler, deletedProperty]);

  return (
    <DropdownSelect
      forceToClose={deletedProperty}
      sortSelectedOptions={false}
      singleSelect
      disabled={disableSelection}
      listWidth={300}
      filterFn={filterFn}
      options={renderOptions}
      wrapperVariant="popover"
      label={`Select ${label}`}
      values={value ? [value] : []}
      TriggerComponent={TriggerComponent}
      submitHandlerOnClose={changeHandler}
      DropdownFooter={DropdownFooter}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  const target =
    `${option.rawValue?.name} ${option.rawValue?.category}`.toLowerCase();

  return target.includes(keyword.toLowerCase().trim());
};

const unselectOption = "unselectOption";
