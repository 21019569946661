import { FC } from "react";
import { Box, LinearProgress, Stack } from "@mui/material";
import { BudgetsAndAlertsTabs } from "./BudgetsAndAlertsTabs";
import { NavigationExpander } from "../../common/navigation-expander/NavigationExpander";
import { useAppSelector } from "../../../../store/hooks";
import { alertsPageDataLoadingSelector } from "../../../../store/alerts/selectors/alertsPageDataLoadingSelector";
import { budgetsDataLoadingSelector } from "../../../../store/budgets/selectors/current-budget/data-selectors/budgetsDataLoadingSelector";

export const BudgetsAndAlertsHeader: FC = () => {
  const alertsLoading = useAppSelector(alertsPageDataLoadingSelector);
  const budgetsLoading = useAppSelector(budgetsDataLoadingSelector);

  const loading = alertsLoading || budgetsLoading;

  return (
    <Stack
      position="relative"
      direction="row"
      alignItems="center"
      spacing={1}
      borderBottom={1}
      pl={1}
      borderColor="grey.300"
    >
      <NavigationExpander />
      <Stack flex={1}>
        <BudgetsAndAlertsTabs />
      </Stack>

      {loading && (
        <Box position="absolute" bottom={0} left={0} right={0}>
          <LinearProgress />
        </Box>
      )}
    </Stack>
  );
};
