import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useState,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { View } from "../../../../../../../services/cloudchipr.api";
import { getPressEnterHandler } from "../../../../../../utils/helpers/getPressEnterHandler";
import { navigationHierarchyItemNoDndKey } from "../../../../../../app-navigation/components/sortable-tree/utils/constants";
import { DialogTitleClosable } from "../../../../../../common/dialog-utils/DialogTitleClosable";
import { useViewsHierarchyContext } from "../../ViewsHierarchyProvider";

export interface HierarchyViewProtectActionGlobalProps {
  loading: boolean;
  onProtect(
    viewId: string,
    isProtected: boolean,
    description: string,
  ): Promise<View>;
}

interface HierarchyViewProtectActionDialogProps {
  viewId: string;
  isProtected: boolean;
  protectedBy: string;
  protectionDescription?: string;
  open: boolean;
  onClose(): void;
}

export const HierarchyViewProtectActionDialog: FC<
  HierarchyViewProtectActionDialogProps
> = ({
  onClose,
  open,
  viewId,
  protectionDescription,
  protectedBy,
  isProtected,
}) => {
  const [description, setDescription] = useState("");
  const { loading, onProtect } = useViewsHierarchyContext().actions.viewProtect;

  const title = isProtected ? unprotectTitle : protectTitle;

  const closeDialogHandler = useCallback(() => {
    onClose();
    setDescription("");
  }, [onClose]);

  const protectHandler = useCallback(async () => {
    await onProtect(viewId, !isProtected, description);

    closeDialogHandler();
  }, [closeDialogHandler, viewId, isProtected, description, onProtect]);

  const inputChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDescription(event.target.value);
    },
    [],
  );
  const pressEnterHandler = getPressEnterHandler(protectHandler, loading);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={closeDialogHandler}
      onKeyDown={pressEnterHandler}
      {...navigationHierarchyItemNoDndKey}
    >
      <DialogTitleClosable
        title={isProtected ? "Unprotect View" : "Protect View"}
        onClose={closeDialogHandler}
      />

      <DialogContent dividers>
        <Stack spacing={2}>
          <Typography variant="body1">{title}</Typography>
          {isProtected ? (
            <Stack
              p={1}
              border={1}
              spacing={1}
              borderRadius={1}
              bgcolor="grey.100"
              alignItems="center"
              borderColor="grey.200"
              justifyContent="center"
            >
              {protectedBy && (
                <Typography variant="body2" color="text.secondary">
                  Protected by{" "}
                  <Typography
                    component="span"
                    variant="inherit"
                    color="text.primary"
                  >
                    {protectedBy}
                  </Typography>{" "}
                </Typography>
              )}

              {protectionDescription && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight="medium"
                >
                  {protectionDescription}
                </Typography>
              )}
            </Stack>
          ) : (
            <TextField
              value={description}
              size="small"
              onChange={inputChangeHandler}
              label="Description (Optional)"
              onKeyDown={stopPropagation}
            />
          )}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button color="tertiary" onClick={closeDialogHandler}>
          Cancel
        </Button>
        <LoadingButton
          onClick={protectHandler}
          loading={loading}
          variant="contained"
        >
          {isProtected ? "Unprotect View" : "Protect View"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const stopPropagation = (event: KeyboardEvent<HTMLInputElement>) => {
  event.stopPropagation();
};

const unprotectTitle = "Please confirm if you want to unprotect this view.";
const protectTitle =
  "Anyone with Edit permission can unprotect. Add an optional message for users unprotecting this view.";
