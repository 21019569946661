import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  StateTaskType,
  TaskManagementState,
  TaskTargetType,
} from "./utils/types/types";
import {
  Task,
  GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse,
} from "../../services/cloudchipr.api";

const initialState: TaskManagementState = {
  form: { drawerOpen: false, task: null, target: null },
  list: { selectedTasks: [] },
  properties: {
    priorities: [],
    statuses: [],
    environments: [],
    actions: [],
    owners: [],
  },
  views: { currentViewId: "", showCompleted: false },
};

export const taskManagementSlice = createSlice({
  name: "taskManagement",
  initialState: initialState,
  reducers: {
    setTaskManagementDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.form.drawerOpen = action.payload;
    },

    setTaskManagementTask: (
      state,
      action: PayloadAction<TaskManagementState["form"]["task"]>,
    ) => {
      state.form.task = action.payload;
    },
    setTaskManagementTaskTarget: (
      state,
      action: PayloadAction<TaskTargetType | null>,
    ) => {
      state.form.target = action.payload;
    },
    updateTaskManagementTask: (
      state,
      action: PayloadAction<Partial<StateTaskType>>,
    ) => {
      if (!state.form.task) {
        return;
      }

      state.form.task = { ...state.form.task, ...action.payload };
    },

    // list ============================================================

    setTaskManagementSelectedTasks: (state, action: PayloadAction<Task[]>) => {
      state.list.selectedTasks = action.payload;
    },

    // properties ============================================================

    setTaskManagementProperties: (
      state,
      action: PayloadAction<
        Partial<GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse>
      >,
    ) => {
      state.properties = { ...state.properties, ...action.payload };
    },

    // views ============================================================

    setTaskManagementCurrentViewId: (state, action: PayloadAction<string>) => {
      state.views.currentViewId = action.payload;
    },
    setTaskManagementViewCompletedShown: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.views.showCompleted = action.payload;
    },
  },
});

export const {
  setTaskManagementDrawerOpen,
  setTaskManagementTask,
  updateTaskManagementTask,
  setTaskManagementTaskTarget,
  // list ============================================================
  setTaskManagementSelectedTasks,
  // properties ============================================================
  setTaskManagementProperties,
  // views ============================================================
  setTaskManagementCurrentViewId,
  setTaskManagementViewCompletedShown,
} = taskManagementSlice.actions;

export default taskManagementSlice.reducer;
