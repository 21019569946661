import { createAsyncThunk } from "@reduxjs/toolkit";
import { setResourceExplorerPathThunk } from "./setResourceExplorerPathThunk";
import { getResourceExplorerDataThunk } from "../getResourceExplorerDataThunk";
import { setResourceExplorerGrouping } from "../../../resourceExplorerSlice";
import { ResourceExplorerGroupingNullable } from "../../../../../services/cloudchipr.api";
import { resourceExplorerPathSelector } from "../../../selectors/current-resource-explorer/path/resourceExplorerPathSelector";
import { RootState } from "../../../../store";
import { resourceExplorerStateSelector } from "../../../selectors/resourceExplorerStateSelector";

export const pathLastItemGroupingChangeThunk = createAsyncThunk(
  "resourceExplorer/pathLastItemGroupingChangeThunk",
  (
    {
      grouping,
      enableBillingExplorerPathChangeFromMainGrouping,
    }: {
      grouping: ResourceExplorerGroupingNullable;
      enableBillingExplorerPathChangeFromMainGrouping: boolean;
    },
    { dispatch, getState },
  ) => {
    if (!grouping) {
      return;
    }

    const state = getState() as RootState;
    const path = resourceExplorerPathSelector(state);
    const resourceExplorerState = resourceExplorerStateSelector(state);
    const groupValues =
      enableBillingExplorerPathChangeFromMainGrouping &&
      resourceExplorerState.data?.group_values
        ? resourceExplorerState.data?.group_values
        : undefined;

    const newPath = path.map((pathItem, index) => {
      if (index !== path.length - 1) {
        return pathItem;
      }

      return { ...pathItem, groupedBy: grouping, groupValues };
    });

    const lastItem = newPath.at(-1);
    if (!lastItem) {
      return;
    }

    dispatch(setResourceExplorerGrouping({ grouping, groupValues }));
    dispatch(getResourceExplorerDataThunk(lastItem.filterTree));
    dispatch(setResourceExplorerPathThunk(newPath));
  },
);
