// TODO: Percentage calculations should be moved to numeral() instead manual calculation and formatting
export const getPercentage = (partial: number, total: number): number => {
  return (partial / total) * 100;
};

export const getNumberByPercentage = (
  percent: number,
  total: number,
): number => {
  return (percent / 100) * total;
};
