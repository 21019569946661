import { FC } from "react";
import { Typography } from "@mui/material";
import { money } from "../../../../../../../../../../../../../utils/numeral/money";

interface LiveFilteredResourceCardHeaderPriceChipProps {
  price: number | null;
  withBackground?: boolean;
}
export const LiveFilteredResourceCardHeaderPriceChip: FC<
  LiveFilteredResourceCardHeaderPriceChipProps
> = ({ price, withBackground }) => {
  return (
    <Typography
      variant="body2"
      fontWeight="bold"
      bgcolor={withBackground ? "grey.50" : "white"}
      border={1}
      borderColor="grey.200"
      borderRadius={1}
      px={1}
      py={0.5}
    >
      {money(price)}
      {price !== null && (
        <Typography variant="caption" color="text.secondary">
          /mo
        </Typography>
      )}
    </Typography>
  );
};
