import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetCostBreakdownAggregation } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { billingExplorerWidgetSetupSelector } from "../../../../selectors/setups/billing-explorer/billingExplorerWidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";

export const billingExplorerViewAggregationsChangeThunk = createAsyncThunk(
  "dashboards/billingExplorerWidgetViewAggregationsChange",
  async (
    aggregation: WidgetCostBreakdownAggregation,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const existingSetup = billingExplorerWidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }

    dispatch(
      setWidgetSetup({
        ...existingSetup,
        aggregation: {
          ...existingSetup.aggregation,
          ...aggregation,
        },
      }),
    );
  },
);
