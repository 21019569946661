import { FC } from "react";
import { Stack, Typography, Chip, Box } from "@mui/material";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";

interface EmailIntegrationCsvInfoProps {
  showLinkInfo?: boolean;
}

export const EmailIntegrationCsvInfo: FC<EmailIntegrationCsvInfoProps> = ({
  showLinkInfo,
}) => {
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={0.5}>
        <AttachEmailOutlinedIcon
          fontSize="small"
          sx={{ color: "text.secondary" }}
        />
        <Typography variant="caption" fontWeight="medium">
          Please see the attached CSV file for more detailed resources list.
        </Typography>
      </Stack>
      {showLinkInfo && (
        <Typography variant="caption">
          *This link is private. Please keep it confidential and do not share.
        </Typography>
      )}
      <Box>
        <Chip
          avatar={<AttachmentOutlinedIcon fontSize="small" />}
          label="Resources.csv"
        />
      </Box>
    </Stack>
  );
};
