import { useCallback } from "react";
import {
  ProviderType,
  useGetUsersMeCurrentPreferenceByKeyQuery,
  usePatchUsersMeCurrentPreferenceByKeyMutation,
} from "../../../../../services/cloudchipr.api";
import { AccountsPageTabs } from "../../../accounts-group/utils/types/types";
import { AccountsViewType } from "../../../../../store/accounts/accountsSlice";

export function useAccountsView(
  provider: ProviderType,
  type: AccountsPageTabs,
) {
  const sortByKey = getAccountsViewSettingsKey(provider, type);

  const [updateViewSetting] = usePatchUsersMeCurrentPreferenceByKeyMutation();
  const {
    data: accountView,
    refetch,
    isLoading,
  } = useGetUsersMeCurrentPreferenceByKeyQuery({
    key: sortByKey,
  });

  const saveAccountsView = useCallback(
    async (sortBy: AccountsViewType) => {
      const body = {
        key: sortByKey,
        body: { value: sortBy },
      };

      await updateViewSetting(body);

      refetch();
    },
    [updateViewSetting, sortByKey, refetch],
  );

  return {
    isLoading,
    accountsView: accountView?.value as AccountsViewType,
    saveAccountsView,
  };
}

export const getAccountsViewSettingsKey = (
  provider: ProviderType,
  type: AccountsPageTabs,
  id?: string,
) => {
  return `c8r:${provider}:${type}AccountsView`;
};
