import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Typography } from "@mui/material";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../../services/cloudchipr.api";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../common/utils/types";
import { getUtilizationAndCoverageDrawerDynamicHeaderCellTitles } from "../../../../../../utils/helpers/getUtilizationAndCoverageDrawerDynamicHeaderCellTitles";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { CommitmentsProgressBar } from "../../../../../../../common/components/CommitmentsProgressBar";

export const getSavingsPlanCommonColumnDefs = (
  grouping: SavingsPlanCoverageGrouping,
): ColDef<SavingsPlanCoverageDataWithId>[] => {
  const titles = getUtilizationAndCoverageDrawerDynamicHeaderCellTitles(
    "commitment",
    grouping,
  );

  return [
    {
      flex: 1.5,
      field: "covered_usage",
      headerName: titles?.coveredUsageCell,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => (
        <Typography variant="body2">
          {money(data?.covered_usage ?? 0)}
        </Typography>
      ),
      valueGetter: ({ data }) => money(data?.covered_usage ?? 0),
    },

    {
      flex: 1.5,
      field: "coverage",
      headerName: titles?.coverageCell,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => (
        <CommitmentsProgressBar
          overrideColor="info.light"
          value={data?.coverage ?? 0}
        />
      ),
    },
  ];
};
