import { billingSummaryWidgetSetupSelector } from "./billingSummaryWidgetSetupSelector";
import { RootState } from "../../../../store";
import { BillingSummarySetupType } from "../../../utils/types/setups/billingSummarySetupType";

export const billingSummarySumSetupPropertyByKeySelector =
  (key: keyof BillingSummarySetupType) =>
  (state: RootState): any => {
    const setup = billingSummaryWidgetSetupSelector(state);

    if (!setup) {
      return null;
    }

    return setup[key];
  };
