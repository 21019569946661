import { RootState } from "../../../../../../store";
import { azureUtilizationAndCoverageDetailsDataSelector } from "../data/azureUtilizationAndCoverageDetailsDataSelector";
import { AzureCommitmentUtilizationMetadata } from "../../../../../../../services/cloudchipr.api";

export const azureSavingsPlanDetailsMetaDataSelector = (
  state: RootState,
): AzureCommitmentUtilizationMetadata | undefined => {
  return azureUtilizationAndCoverageDetailsDataSelector(state)
    ?.metadata as AzureCommitmentUtilizationMetadata;
};
