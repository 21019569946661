import { IconButton, LinearProgress, Stack, Tooltip } from "@mui/material";
import { Fragment, useMemo } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { Tune } from "@mui/icons-material";
import { LiveFilteredResourceCardHeaderPriceChip } from "./LiveFilteredResourceCardHeaderPriceChip";
import { getLiveFilteredResourcesColumns } from "../utils/getLiveFilteredResourcesColumns";
import { ResourceType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { RenderToolbar } from "../../../../../../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { ColumnsSwitcher } from "../../../../../../../../../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";
import { GlobalFilter } from "../../../../../../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { useMenuHook } from "../../../../../../../../../../../../../utils/hooks/useMenu.hook";
import { useAppSelector } from "../../../../../../../../../../../../../store/hooks";
import { liveFilteredResourcesSelector } from "../../../../../../../../../../../../../store/resource-explorer/selectors/live-filtered-resources/liveFilteredResourcesSelector";
import { liveFilteredResourcesLoadingSelector } from "../../../../../../../../../../../../../store/resource-explorer/selectors/live-filtered-resources/liveFilteredResourcesLoadingSelector";
import { liveFilteredResourcesEmsPriceSelector } from "../../../../../../../../../../../../../store/resource-explorer/selectors/live-filtered-resources/liveFilteredResourcesEmsPriceSelector";
import { useDataGridContext } from "../../../../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { CsvDataDownloader } from "../../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/toolbar/CsvDataDownloader";
import { generateExportedResourceDataByColumns } from "../../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateExportedResourceDataByColumns";
import { generateMetricsFrequenciesFromContextData } from "../../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateMetricsFrequenciesFromContextData";
import { taskAvailableStatusPropertiesSelector } from "../../../../../../../../../../../../../store/task-management/selectors/properties/taskAvailableStatusPropertiesSelector";

interface InternalProps {
  resourceType: ResourceType;
}

interface LiveFilteredResourceItemToolbarProps
  extends RenderToolbar<InternalProps> {}

export const LiveFilteredResourceCardToolbar: LiveFilteredResourceItemToolbarProps =
  ({ resourceType, table, globalFilter, setGlobalFilter }) => {
    const { anchor, openMenu, closeMenu, open } = useMenuHook();
    const enableMetricsCharts = useFlag("EnableResourceSmallCharts");

    const context = useDataGridContext();
    const data = context?.cellSpecificMetadata?.data;
    const metricsFrequencies = generateMetricsFrequenciesFromContextData(data);

    const resources = useAppSelector((state) =>
      liveFilteredResourcesSelector(state, resourceType),
    );
    const loading = useAppSelector((state) =>
      liveFilteredResourcesLoadingSelector(state, resourceType),
    );
    const taskStatuses = useAppSelector(taskAvailableStatusPropertiesSelector);
    const totalMonthlyPrice = useAppSelector((state) =>
      liveFilteredResourcesEmsPriceSelector(state, resourceType),
    );

    const csvData = useMemo(() => {
      if (!resources?.length) {
        return;
      }
      const columns =
        getLiveFilteredResourcesColumns(resourceType, {
          enableMetricsCharts,
        }) ?? [];

      return generateExportedResourceDataByColumns(resources, columns, [], {
        includeNestedRows: true,
        metricsFrequencies,
        taskStatuses,
      });
    }, [
      resources,
      resourceType,
      enableMetricsCharts,
      metricsFrequencies,
      taskStatuses,
    ]);

    return (
      <Fragment>
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          p={1}
        >
          <LiveFilteredResourceCardHeaderPriceChip
            price={totalMonthlyPrice}
            withBackground
          />

          <Stack direction="row" spacing={1}>
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="xsmall"
            />
            <CsvDataDownloader data={csvData} resourceType={resourceType} />
            <Tooltip arrow title="Table Settings" placement="top">
              <IconButton onClick={openMenu} size="small">
                <Tune fontSize="small" />
              </IconButton>
            </Tooltip>
            <ColumnsSwitcher
              open={open}
              onClose={closeMenu}
              anchor={anchor}
              table={table}
            />
          </Stack>
        </Stack>
        {loading && <LinearProgress />}
      </Fragment>
    );
  };
