import { liveUsageMgmtSelectedRowIdsWithHierarchyByResourceTypeSelector } from "./liveUsageMgmtSelectedRowIdsWithHierarchyByResourceTypeSelector";
import { liveUsageMgmtResourceTypeDataWithChildrenSelector } from "./liveUsageMgmtResourceTypeDataWithChildrenSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { ResourceDataType } from "../../utils/types/types";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { getAllResourcesInHierarchyByResourceType } from "../../../../utils/helpers/resources/getAllResourcesInHierarchyByResourceType";

export const liveUsageMgmtSelectedResourcesByResourceTypeSelector = (
  state: RootState,
  resourceType: ResourceType,
): ResourceDataType[] | undefined => {
  const parentResourceType =
    getFirstParentInHierarchyResourceType(resourceType);

  const allResourceTypesInHierarchy =
    getAllResourcesInHierarchyByResourceType(resourceType);

  const selectedResources = allResourceTypesInHierarchy?.reduce(
    (acc, rt) => {
      const selectedResourcesByRt =
        liveUsageMgmtSelectedRowIdsWithHierarchyByResourceTypeSelector(
          state,
          rt,
        );

      if (selectedResourcesByRt) {
        acc = { ...acc, ...selectedResourcesByRt };
      }

      return acc;
    },
    {} as Record<string, boolean>,
  );

  const selectedResourceIds = selectedResources
    ? Object.keys(selectedResources)
    : [];

  const allResources = liveUsageMgmtResourceTypeDataWithChildrenSelector(
    state,
    parentResourceType ?? resourceType,
  );

  return allResources?.filter(({ resource }) =>
    selectedResourceIds?.includes(resource?.id),
  );
};
