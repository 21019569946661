import { FC, useCallback } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { costAnomaliesSetupChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-anomalies/widget-change/costAnomaliesSetupChangeThunk";
import { costAnomaliesSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/costAnomaliesSetupPropertyByKeySelector";
import { costAnomaliesSortByCountValues } from "../utils/constants";

export const CostAnomaliesSortByItemsCount: FC = () => {
  const dispatch = useAppDispatch();

  const itemsCount = useAppSelector(
    costAnomaliesSetupPropertyByKeySelector("itemsCount"),
  );

  const itemsCountChangeHandler = useCallback(
    (event: any) => {
      dispatch(
        costAnomaliesSetupChangeThunk({
          itemsCount: event.target.value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <FormControl size="small" fullWidth>
      <InputLabel>Count</InputLabel>
      <Select
        value={itemsCount}
        label="Count"
        onChange={itemsCountChangeHandler}
        sx={{
          flex: 1,
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "grey.300" },
        }}
      >
        {costAnomaliesSortByCountValues.map((item) => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
