import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, IconButton, Popover, PopoverOrigin, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { DotsLoading } from "./DotsLoading";
import { CopyIconButton } from "../../../components/common/CopyIconButton";
import { isBillingExplorerDatesOutRangesForSummarySelector } from "../../../store/resource-explorer/selectors/resource-explorer-summary/isBillingExplorerDatesOutRangesForSummarySelector";
import { useAppSelector } from "../../../store/hooks";
import { BillingExplorerExplainWithAiOutRangesAlert } from "../../../components/pages/resource-explorer/components/explain-with-ai-panel/components/body/BillingExplorerExplainWithAiOutRangesAlert";

interface ExploreWithAIButtonPopoverProps {
  open: boolean;
  anchor: HTMLElement | null;
  onClose(): void;
  title?: ReactNode;
  onPopoverMount?(): void;
  loading?: boolean;
  content?: string;
}

export const ExploreWithAIButtonPopover: FC<
  ExploreWithAIButtonPopoverProps
> = ({ open, anchor, onClose, title, onPopoverMount, loading, content }) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [localLoading, setLocalLoading] = useState(false);
  const isOutRangesDates = useAppSelector(
    isBillingExplorerDatesOutRangesForSummarySelector,
  );
  const copyClickHandler = useCallback((): string => {
    return contentRef?.current?.innerText ?? "";
  }, [contentRef]);

  useEffect(() => {
    if (open) {
      setLocalLoading(true);
      onPopoverMount?.();

      const timer = setTimeout(() => {
        setLocalLoading(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [open, onPopoverMount]);

  const renderingContent = useMemo(() => {
    if (loading || localLoading) {
      return <DotsLoading />;
    }
    return (
      <Stack
        mb={2}
        flex={1}
        bgcolor="grey.100"
        borderRadius={2}
        pr={1}
        pl={0.5}
        overflow="auto"
      >
        <Stack
          ref={contentRef}
          overflow="auto"
          dangerouslySetInnerHTML={{ __html: content ?? "" }}
        />
        {content && content?.length > 0 && (
          <Stack
            direction="row"
            spacing={0.5}
            pr={2}
            alignItems="center"
            justifyContent="flex-end"
            mb={2}
          >
            <CopyIconButton
              data={contentRef?.current?.innerText ?? ""}
              color="primary"
              onGetDataValue={copyClickHandler}
            />
          </Stack>
        )}
      </Stack>
    );
  }, [loading, localLoading, content, copyClickHandler]);

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      slotProps={slotProps}
    >
      <Stack
        justifyContent="space-between"
        direction="row"
        spacing={2}
        alignItems="center"
        position="sticky"
        bgcolor="white"
        top={0}
        pb={1}
        zIndex={(theme) => theme.zIndex.modal}
      >
        <Box pt={1}>{title}</Box>
        <Box p={0.5}>
          <IconButton onClick={onClose} sx={{ p: 0.5 }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Stack>

      <Stack direction="row" py={1.5} spacing={1}>
        <Box>
          <IconButton sx={{ border: 1, borderColor: "grey.300" }} disableRipple>
            <AutoAwesomeIcon fontSize="small" color="primary" />
          </IconButton>
        </Box>
        {isOutRangesDates ? (
          <BillingExplorerExplainWithAiOutRangesAlert />
        ) : (
          renderingContent
        )}
      </Stack>
    </Popover>
  );
};

const slotProps = {
  paper: {
    sx: {
      pl: 2,
      width: 560,
    },
  },
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "center",
};

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "center",
};
