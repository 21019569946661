import { FC, useCallback } from "react";
import { ListItem, ListItemButton, ListItemText, Stack } from "@mui/material";
import { LiveUsageMgmtNavItemStats } from "./LiveUsageMgmtNavItemStats";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { ResourceType } from "../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { liveUsageMgmtResourceTypeDataEmsSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataEmsSelector";
import { liveUsageMgmtResourceTypeDataSpendingSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataSpendingSelector";
import { useLiveUsageMgmtPageNavigate } from "../../../utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";

interface LiveUsageMgmtNestedNavItemProps {
  resourceType: ResourceType;
  selected?: boolean;
}

export const LiveUsageMgmtNestedNavItem: FC<
  LiveUsageMgmtNestedNavItemProps
> = ({ resourceType, selected }) => {
  const handleNavigationClick = useLiveUsageMgmtPageNavigate();

  const ems = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataEmsSelector(state, resourceType),
  );

  const total = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataSpendingSelector(state, resourceType),
  );

  const filterTemplatesEnabled = useAppSelector(
    liveUsageMgmtFilterTemplatesEnabledSelector,
  );

  const navigateHandler = useCallback(() => {
    handleNavigationClick(resourceType);
  }, [handleNavigationClick, resourceType]);

  return (
    <ListItem sx={listItemSx}>
      <ListItemButton
        sx={listItemButtonSx}
        selected={selected}
        onClick={navigateHandler}
      >
        <ListItemText
          inset
          sx={{ pl: 3 }}
          primary={
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <TypographyWithTooltip
                variant="subtitle2"
                title={getResourceTypeName(resourceType)}
                color={selected ? "primary" : "inherit"}
              />
              <LiveUsageMgmtNavItemStats
                ems={ems}
                total={total}
                filterTemplatesEnabled={filterTemplatesEnabled}
              />
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

const listItemSx = { px: 1, py: 0.25 };
const listItemButtonSx = { borderRadius: 2 };
