import { billingExplorerWidgetSetupSelector } from "./billingExplorerWidgetSetupSelector";
import { RootState } from "../../../../store";
import { objectToUrl } from "../../../../../components/utils/helpers/dataToUrlToDataConvertors";

export const billingExplorerWidgetSetupBillingExportUrlSelector = (
  state: RootState,
) => {
  const widgetSetup = billingExplorerWidgetSetupSelector(state);
  const path = objectToUrl([
    {
      groupedBy: widgetSetup?.grouping,
      groupValues: widgetSetup?.group_values,
      forecastOption: widgetSetup?.forecastOption,
      dates: widgetSetup?.dates,
      dateGranularity: widgetSetup?.frequency,
      chartType: widgetSetup?.viewType,
      filterTree: undefined,
    },
  ]);
  return `/resource-explorer/${widgetSetup?.viewId}?path=${path}`;
};
