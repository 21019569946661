import { FC, Fragment, useCallback } from "react";
import Typography from "@mui/material/Typography";
import { Button, Tooltip } from "@mui/material";
import { useAppAbility } from "../../../../../../../../../services/permissions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import {
  initiateBudgetForCreate,
  setCurrentBudgetData,
} from "../../../../../../../../../store/budgets/budgetsSlice";
import { toggleAlertsOpenDrawer } from "../../../../../../../../../store/alerts/alertsSlice";
import { getResourceExplorerDataByBudgetThunk } from "../../../../../../../../../store/budgets/thunks/getResourceExplorerDataByBudgetThunk";
import { resourceExplorerViewVisibilityByIdSelector } from "../../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewVisibilityByIdSelector";

interface CreateBudgetButtonProps {
  viewId: string;
}

export const CreateBudgetButton: FC<CreateBudgetButtonProps> = ({ viewId }) => {
  const { can } = useAppAbility();
  const canCreateBudget = can("create", "budget");
  const dispatch = useAppDispatch();

  const visibility = useAppSelector((state) =>
    resourceExplorerViewVisibilityByIdSelector(state, viewId),
  );

  const toggleBudgetsAndAlertsDrawer = useCallback(() => {
    dispatch(initiateBudgetForCreate());
    dispatch(setCurrentBudgetData({ viewId }));
    dispatch(getResourceExplorerDataByBudgetThunk());
    dispatch(toggleAlertsOpenDrawer());
  }, [dispatch, viewId]);

  const visibleToMe = visibility === "visible_only_to_me";

  return (
    <Fragment>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        fontWeight="regular"
      >
        Budget
      </Typography>

      <Tooltip
        title={
          visibleToMe
            ? "Budgets are only available for reports visible to everyone."
            : "Create Budget"
        }
        placement={visibleToMe ? "right" : "top-start"}
        arrow
        disableHoverListener={!canCreateBudget}
      >
        <span>
          <Button
            sx={{
              minWidth: 28,
              width: 28,
              height: 28,
              borderRadius: 2,
              bgcolor: "grey.200",
              fontSize: 22,
              fontWeight: 400,
            }}
            disabled={!canCreateBudget || visibleToMe}
            onClick={toggleBudgetsAndAlertsDrawer}
            color="tertiary"
            variant="contained"
          >
            +
          </Button>
        </span>
      </Tooltip>
    </Fragment>
  );
};
