import { RootState } from "../../../../store";
import { getBillingExplorerAnomalyByIdSelector } from "../api-select/getBillingExplorerAnomalyByIdSelector";

export const billingExplorerAnomalyByIdLoadingSelector = (
  state: RootState,
  id: string,
) => {
  return getBillingExplorerAnomalyByIdSelector({ anomalyId: id })(state)
    ?.isLoading;
};
