import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import {
  AccountLight,
  ResourceType,
  Tags,
} from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";
import { TagCell } from "../../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { ResourceItemType } from "../../../../../../../../store/live-usage-mgmt/utils/types/types";
import { columnsMapV2 } from "../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/columnsMapV2";
import { AccountInfoData } from "../../../../../../../common/AccountInfoData";
import { IdentifierCellWithProtectedIcon } from "../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/identifier-cell/IdentifierCellWithProtectedIcon";
import { generateLiveResourcesPath } from "../../../../../../accounts-group/utils/helpers/generateLiveResourcesPath";

export const getCleanDialogGridColumns = (
  resourceType: ResourceType,
): ColumnSetupType<ResourceItemType>[] => {
  const firstCell = columnsMapV2.get(resourceType)?.[0];
  const firstCellAccessorKey =
    (firstCell?.accessorKey as keyof ResourceItemType) ||
    "provider_unique_identifier";

  const tag = columnsMapV2
    .get(resourceType)
    ?.find((column) => column.accessorKey === "tags");

  const columns: ColumnSetupType<ResourceItemType>[] = [
    {
      accessorKey: firstCellAccessorKey,
      header: firstCell?.header || "Id",
      minSize: 160,
      cell: ({ row, cell }) => {
        const rowData = row.original;
        return (
          <IdentifierCellWithProtectedIcon
            data={cell.getValue() as string}
            lockIconMessage={rowData.protected_by ?? undefined}
          />
        );
      },
      meta: { alwaysShowSortIcon: true },
    },
    {
      accessorKey: "price_per_month",
      header: "Monthly Price",
      cell: (cell) => money(cell.getValue() as string),
      meta: { alwaysShowSortIcon: true },
    },
    {
      accessorKey: "account",
      header: "Account",
      type: "account",
      size: 210,
      cell: (cell) => {
        const data = cell.getValue() as AccountLight;
        return (
          <AccountInfoData
            provider={data.provider}
            accessType={data.provider_access_type}
            accountName={data.provider_account_name ?? data.provider_account_id}
            link={generateLiveResourcesPath({
              provider: data.provider,
              accountIds: data.id,
              resourceType,
            })}
            nameTypographyProps={{ fontSize: 14 }}
          />
        );
      },
    },
  ];

  if (tag) {
    columns.push({
      accessorKey: "tags",
      header: tag.header,
      minSize: 120,
      enableSorting: false,
      cell: (cell) => {
        return <TagCell tags={cell.getValue() as Tags} widthToEllipsis={100} />;
      },
    });
  }

  return columns;
};
