import { FC, ReactNode } from "react";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { Stack, Typography } from "@mui/material";
import { blue, green, red } from "@mui/material/colors";
import { IconProps } from "@mui/material/Icon/Icon";

interface AccountCardLabelV2Props {
  label: string;
  value?: number | ReactNode;
  color?: IconProps["color"] | null;
}

export const AccountCardLabelV2: FC<AccountCardLabelV2Props> = ({
  label,
  value,
  color,
}) => {
  const styles =
    color !== null
      ? {
          color: SquareRoundedIconColor.get(color),
        }
      : undefined;

  return (
    <Stack>
      <Stack direction="row">
        {color !== null && (
          <SquareRoundedIcon
            color={color}
            sx={{
              fontSize: 12,
              mr: 0.3,
              mt: 0.5,
              color: styles?.color,
            }}
          />
        )}
        <Typography
          variant="caption"
          color="text.secondary"
          fontWeight="medium"
        >
          {label}
        </Typography>
      </Stack>
      <Typography variant="body2" fontWeight="medium">
        {value}/
        <Typography variant="caption" component="span" color="grey.600">
          mo
        </Typography>
      </Typography>
    </Stack>
  );
};

const SquareRoundedIconColor = new Map<IconProps["color"], string>([
  ["primary", green[500]],
  ["error", red[500]],
  ["info", blue[500]],
]);
