import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { azureUtilizationAndCoverageDrawerDataGridColumnDefs } from "./columns/azureUtilizationAndCoverageDrawerDataGridColumnDefs";
import { DataViewTab } from "../../../../../../../../../store/commitments/utils/types/types";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { azureUtilizationAndCoverageDetailsCoverageDataSelector } from "../../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/details-drawer/data/azureUtilizationAndCoverageDetailsCoverageDataSelector";
import { azureUtilizationAndCoverageDetailsLoadingSelector } from "../../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/details-drawer/data/azureUtilizationAndCoverageDetailsLoadingSelector";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "../../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerCoverageGroupingSelector";
import { CommitmentsGridLoadingLayer } from "../../../../../../common/components/data-grid/CommitmentsGridLoadingLayer";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { CommitmentsDrawerAGGridToolbar } from "../../../../../../common/components/drawer/data-grid/CommitmentsDrawerAGGridToolbar";
import { CommitmentsAgGrid } from "../../../../../../common/components/CommitmentsAgGrid";

interface AzureUtilizationAndCoverageDrawerDataGridProps {
  viewTab: DataViewTab;
}

export const AzureUtilizationAndCoverageDrawerDataGrid: FC<
  AzureUtilizationAndCoverageDrawerDataGridProps
> = ({ viewTab }) => {
  const data = useAppSelector(
    azureUtilizationAndCoverageDetailsCoverageDataSelector,
  );
  const loading = useAppSelector(
    azureUtilizationAndCoverageDetailsLoadingSelector,
  );
  const targetType = useAppSelector(commitmentsDetailsDrawerTargetTypeSelector);

  const grouping = useAppSelector(
    commitmentsDetailsDrawerCoverageGroupingSelector,
  );

  const columnDefs = useMemo(() => {
    if (!targetType) {
      return [];
    }
    return (
      azureUtilizationAndCoverageDrawerDataGridColumnDefs?.[targetType]?.[
        viewTab
      ]?.[grouping] ?? []
    );
  }, [grouping, viewTab, targetType]);

  if (!columnDefs) {
    return null;
  }

  return (
    <Stack mt={1.5} position="relative" zIndex={1} height={400}>
      {loading && <CommitmentsGridLoadingLayer />}

      <CommitmentsAgGrid
        Toolbar={CommitmentsDrawerAGGridToolbar}
        loading={loading}
        columnDefs={columnDefs}
        data={data}
        grouping={grouping}
        localStorageUniqueKey={`azureSavingsPlanDataGrid-${targetType}-${viewTab}-${viewTab}`}
      />
    </Stack>
  );
};
