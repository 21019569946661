import { FC, useCallback, useMemo, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { CSVLink } from "react-csv";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import moment from "moment";
import { useAppSelector } from "../../../../../../store/hooks";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { tasksListIsEmptySelector } from "../../../../../../store/task-management/selectors/data/tasksListIsEmptySelector";
import { useTaskManagementContext } from "../../TaskManagementProvider";
import { Task } from "../../../../../../services/cloudchipr.api";
import { getTasksCSVData } from "../../../utils/helpers/getTasksCSVData";
import { isTask } from "../../../utils/helpers/isTask";

export const TasksGridCsvDownloader: FC = () => {
  const { gridApi } = useTaskManagementContext();
  const [filteredData, setFilteredData] = useState<Task[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  const csvData = useMemo(
    () => getTasksCSVData(filteredData, visibleColumns),
    [filteredData, visibleColumns],
  );
  const fileName = `${formatDate(moment(), { type: "date" })}_tasks`;
  const empty = useAppSelector(tasksListIsEmptySelector);

  const updateDataClickHandler = useCallback(() => {
    if (!gridApi) {
      return;
    }

    const columns = gridApi
      .getAllDisplayedColumns()
      .map((col) => col.getColId());
    setVisibleColumns(columns);

    const data: Task[] = [];
    gridApi.forEachNodeAfterFilterAndSort((node) => {
      if (!isTask(node.data)) {
        return;
      }

      data.push(node.data);
    });
    setFilteredData(data);
  }, [gridApi]);

  if (!csvData) {
    return null;
  }

  return (
    <Tooltip arrow title="Export CSV" placement="top">
      <span>
        <IconButton
          disabled={empty}
          onClick={updateDataClickHandler}
          size="small"
          sx={{ "& a": { textDecoration: "none", color: "inherit" }, pb: 0 }}
        >
          <CSVLink
            filename={`${fileName}.csv`}
            data={csvData}
            enclosingCharacter=""
            separator=";"
          >
            <DownloadOutlinedIcon fontSize="small" />
          </CSVLink>
        </IconButton>
      </span>
    </Tooltip>
  );
};
