import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { reservationDrawerDataGridColumnDefs } from "./columns/reservationDrawerDataGridColumnDefs";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerCoverageGroupingSelector";
import { CommitmentsGridLoadingLayer } from "../../../../../common/components/data-grid/CommitmentsGridLoadingLayer";
import { DataViewTab } from "../../../../../../../../store/commitments/utils/types/types";
import { reservationDetailsCoverageDataSelector } from "../../../../../../../../store/commitments/selectors/reservations/details-drawer/data/reservationDetailsCoverageDataSelector";
import { reservationDetailsLoadingSelector } from "../../../../../../../../store/commitments/selectors/reservations/details-drawer/data/reservationDetailsLoadingSelector";
import { CommitmentsDrawerAGGridToolbar } from "../../../../../common/components/drawer/data-grid/CommitmentsDrawerAGGridToolbar";
import { CommitmentsAgGrid } from "../../../../../common/components/CommitmentsAgGrid";

interface ReservationDrawerDataGridProps {
  viewTab: DataViewTab;
}

export const ReservationDrawerDataGrid: FC<ReservationDrawerDataGridProps> = ({
  viewTab,
}) => {
  const data = useAppSelector(reservationDetailsCoverageDataSelector);
  const loading = useAppSelector(reservationDetailsLoadingSelector);
  const grouping = useAppSelector(
    commitmentsDetailsDrawerCoverageGroupingSelector,
  );

  const columnDefs = useMemo(() => {
    return reservationDrawerDataGridColumnDefs?.[viewTab]?.[grouping] ?? [];
  }, [viewTab, grouping]);

  if (!columnDefs) {
    return null;
  }

  return (
    <Stack mt={1.5} position="relative" zIndex={1} height={400}>
      {loading && <CommitmentsGridLoadingLayer />}

      <CommitmentsAgGrid
        Toolbar={CommitmentsDrawerAGGridToolbar}
        loading={loading}
        columnDefs={columnDefs}
        data={data}
        grouping={grouping}
        localStorageUniqueKey={`reservationDataGrid-${viewTab}`}
      />
    </Stack>
  );
};
