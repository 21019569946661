import { RootState } from "../../../store";
import { resourceDetailsBillingProcessesSelector } from "../processes/resourceDetailsBillingProcessesSelector";

export const resourceDetailsChartTypeSelector = (
  state: RootState,
  processKey: string,
) => {
  const processes = resourceDetailsBillingProcessesSelector(state);

  return processes[processKey]?.chartType;
};
