import { FC } from "react";
import { ForecastedCostHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/ForecastedCostHeaderCell";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { billingExplorerWidgetForecastOptionSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetForecastOptionSelector";
import { billingExplorerWidgetForecastedCostsSumSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetForecastedCostsSumSelector";

interface BillingExplorerWidgetTableForecastedCostHeaderCellProps {
  widgetId: string;
}
export const BillingExplorerWidgetTableForecastedCostHeaderCell: FC<
  BillingExplorerWidgetTableForecastedCostHeaderCellProps
> = ({ widgetId }) => {
  const forecastOption = useAppSelector((state) =>
    billingExplorerWidgetForecastOptionSelector(state, widgetId),
  );

  const forecastedCost = useAppSelector((state) =>
    billingExplorerWidgetForecastedCostsSumSelector(state, widgetId),
  );

  if (!forecastOption) {
    return null;
  }

  return (
    <ForecastedCostHeaderCell
      forecastOption={forecastOption}
      forecastedCost={forecastedCost}
    />
  );
};
