import { FC } from "react";
import { Stack } from "@mui/material";
import { TaskManagementSearch } from "./components/TaskManagementSearch";
import { TasksGridCsvDownloader } from "./components/TasksGridCsvDownloader";
import { TaskManagementShowCompleted } from "./components/TaskManagementShowCompleted";
import { TaskManagementColumnsToolPanel } from "./components/TaskManagementColumnsToolPanel";
import { AgGridAllFiltersChip } from "../../../common/ag-grid/components/filters/AgGridAllFiltersChip";
import { TaskManagementPropertiesSettings } from "../toolbar/components/properties-settings/TaskManagementPropertiesSettings";
import { useTaskManagementContext } from "../TaskManagementProvider";
import { useAppSelector } from "../../../../../store/hooks";
import { tasksCurrentViewSelector } from "../../../../../store/task-management/selectors/views-hierarchy/current-view/tasksCurrentViewSelector";

export const TaskManagementAppbar: FC = () => {
  const { gridApi } = useTaskManagementContext();
  const viewId = useAppSelector(tasksCurrentViewSelector)?.id;

  return (
    <Stack direction="row" justifyContent="space-between" p={1.5}>
      <Stack direction="row" spacing={1.5} alignItems="center">
        <TaskManagementShowCompleted />

        <AgGridAllFiltersChip key={viewId} gridApi={gridApi} />
      </Stack>

      <Stack direction="row" spacing={1.5} alignItems="center">
        <TaskManagementSearch />

        <TasksGridCsvDownloader />

        <TaskManagementPropertiesSettings />

        <TaskManagementColumnsToolPanel />
      </Stack>
    </Stack>
  );
};
