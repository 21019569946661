import { FC, useCallback } from "react";
import { SwitchWithTypography } from "../../../../../../../../../../SwitchWithTypography";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";
import { billingExplorerViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerViewVisualizationChangeThunk";

export const BillingExplorerWidgetVisualizationPreviousPeriodColumn: FC =
  () => {
    const dispatch = useAppDispatch();
    const previousPeriodColumnChecked = useAppSelector(
      billingExplorerSetupVisualizationPropertyByKeySelector(
        "previous_period_cost",
      ),
    );

    const handlePreviousPeriodCheckedChange = useCallback(
      (checked: boolean) => {
        dispatch(
          billingExplorerViewVisualizationChangeThunk({
            previous_period_cost: checked,
          }),
        );
      },
      [dispatch],
    );

    return (
      <SwitchWithTypography
        checked={previousPeriodColumnChecked}
        onChange={handlePreviousPeriodCheckedChange}
        title="Previous Period Cost"
      />
    );
  };
