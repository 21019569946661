import { FC, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { formatDateByFrequencyType } from "../../../../../../../../utils/helpers/date-time/datetime-format";
import { money } from "../../../../../../../../utils/numeral/money";
import { resourceDetailsCostsDataPointSelector } from "../../../../../../../../store/resource-details/selectors/options/resourceDetailsCostsDataPointSelector";
import type { CustomHeaderProps } from "ag-grid-react";

export const getResourceDetailsCostsTableDateColumnsInnerHeader =
  (isTotal: boolean): FC<CustomHeaderProps> =>
  ({ displayName, api, column }) => {
    const dataPoint = useAppSelector(resourceDetailsCostsDataPointSelector);

    const total = useMemo(() => {
      let sum = 0;

      api.forEachNode((node) => {
        let cost = node.data.total_cost;

        if (!isTotal) {
          const key = column.getColId().replace(".total_cost", "");
          cost = node.data[key]?.total_cost;
        }

        if (!isNaN(cost)) {
          sum = sum + cost;
        }
      });

      return sum;
    }, [api, column]);

    const title = isTotal
      ? displayName
      : formatDateByFrequencyType(dataPoint, displayName);

    return (
      <Box width="100%" textAlign="right">
        {title && (
          <TypographyWithTooltip
            title={title}
            variant="body2"
            width="100%"
            fontWeight="medium"
          />
        )}

        <Typography
          fontSize={10}
          variant="caption"
          color="text.disabled"
          fontWeight="medium"
        >
          {money(total)}
        </Typography>
      </Box>
    );
  };
