import { azureReservationSelectedCoverageDataSelector } from "./azureReservationSelectedCoverageDataSelector";
import { RootState } from "../../../../../../store";

export const azureReservationSelectedCoverageDataRegionSelector = (
  state: RootState,
): string | undefined => {
  const data = azureReservationSelectedCoverageDataSelector(state);

  return data?.region?.name;
};
