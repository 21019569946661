import { RootState } from "../../../../../../../store";
import { azureSavingsPlansDataForGranularSelector } from "../../azureSavingsPlansDataForGranularSelector";

export const azureSavingsPlansGranularDataTotalCommitmentSelector = (
  state: RootState,
) => {
  const data = azureSavingsPlansDataForGranularSelector(state);

  return data?.date_granular_data?.total_commitment;
};
