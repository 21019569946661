import { FC } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { resourceCardStatisticColorsByValue } from "./utils/constants";
import { ResourceCardStatisticsType } from "../resource-card/resource-card-content/resource-card-data/resource-card-statistics/ResourceCardStatisticsCard";

interface LiveUsageMgmtResourceStatisticProps {
  value: string;
  count?: number | null;
  type: ResourceCardStatisticsType;
  disableColors?: boolean;
}

export const LiveUsageMgmtResourceStatistic: FC<
  LiveUsageMgmtResourceStatisticProps
> = ({ value, count, type, disableColors }) => {
  const cardThemeColor = disableColors
    ? resourceCardStatisticColorsByValue.disabled
    : resourceCardStatisticColorsByValue[type];

  return (
    <Card
      variant="outlined"
      sx={{
        border: "none",
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        borderLeft={4}
        borderColor={cardThemeColor.medium}
        bgcolor="white"
        p={0.5}
      >
        <Typography variant="body1" fontWeight="medium">
          {value}
          <Typography component="span" color="text.secondary" variant="caption">
            /mo
          </Typography>
        </Typography>

        <Box px={1} pt={0.25} borderRadius={1} bgcolor={cardThemeColor.light}>
          <Typography
            variant="body2"
            color={cardThemeColor.dark}
            fontWeight="medium"
            noWrap
          >
            {count ?? ""}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};
