import { FC } from "react";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";

interface CpuRequestCellProps {
  data: string;
}

export const CpuRequestCell: FC<CpuRequestCellProps> = ({ data }) => {
  return <TypographyWithTooltip title={`${data} Core`} variant="body2" />;
};
