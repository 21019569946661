import { FC, Fragment, useMemo } from "react";
import { grey } from "@mui/material/colors";
import { Theme } from "@mui/material";
import { InternalResourceExplorerCardDataGridToolbar } from "./InternalResourceExplorerCardDataGridToolbar";
import { DataGrid } from "../../../../storybook/data-grid/DataGrid";
import { getResourceExplorerDataGridColumns } from "../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/setups/getResourceExplorerDataGridColumns";
import {
  ResourceExplorerGroupingNullable,
  ResourceExplorerWidget,
} from "../../../../services/cloudchipr.api";
import { getResourceExplorerMutatedData } from "../utils/helpers/helpers";
import { ResourceExplorerTableFooter } from "../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/footer/ResourceExplorerTableFooter";

interface InternalResourceExplorerTotalViewDataGridProps {
  groupBy: ResourceExplorerGroupingNullable;
  data: ResourceExplorerWidget;
  organisationId: string;
}

export const InternalResourceExplorerTotalViewDataGrid: FC<
  InternalResourceExplorerTotalViewDataGridProps
> = ({ groupBy, data, organisationId }) => {
  const { gridData, aggregatedData } = useMemo(
    () => getResourceExplorerMutatedData(data, groupBy),
    [data, groupBy],
  );

  const tableStyles = useMemo(() => {
    return {
      ...styles,
      tableContainer: {
        ...styles.tableContainer,
        borderBottomLeftRadius: !aggregatedData ? 8 : 0,
        borderBottomRightRadius: !aggregatedData ? 8 : 0,
      },
    };
  }, [aggregatedData]);

  const columns = useMemo(() => {
    return getResourceExplorerDataGridColumns(groupBy);
  }, [groupBy]);

  return (
    <Fragment>
      <DataGrid
        globalFilter
        data={gridData}
        columns={columns}
        toolbar={{
          renderToolbar: () => {
            return (
              <InternalResourceExplorerCardDataGridToolbar
                groupBy={groupBy}
                organisationId={organisationId}
              />
            );
          },
        }}
        styles={tableStyles}
        columnResizeMode="onEnd"
        columnSorting={sortingConfig}
      />
      {aggregatedData && (
        <ResourceExplorerTableFooter
          count={+aggregatedData.metadata.count}
          threshold={+aggregatedData.metadata.threshold}
          totalCost={aggregatedData?.total_cost}
          gridDataCount={gridData.length}
        />
      )}
    </Fragment>
  );
};

const sortingConfig = {
  initialSort: [{ id: "total_cost", desc: true }],
};

const styles = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& td": {
      py: 0.5,
      pl: 2,
      borderBottom: `1px solid ${grey[100]}`,
    },
    "& tr:hover td": {
      bgcolor: grey[100],
    },
  },
  tableRow: {
    background: "white",
  },
  tableHeaderRow: {
    top: 0,
    "& th": { p: 2, verticalAlign: "inherit" },
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
    bgcolor: "grey.50",
  },

  tableContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    border: 1,
    borderColor: grey[300],
  },
};
