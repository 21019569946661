import { azureSavingsPlansGranularDataTotalUtilizationSelector } from "./granular/totals/azureSavingsPlansGranularDataTotalUtilizationSelector";
import { savingsPlansDataSelector } from "../../../coverage-and-utilization/data/savingsPlansDataSelector";
import { RootState } from "../../../../../store";

export const azureSavingsPlanDataIsNotAvailableSelector = (
  state: RootState,
) => {
  const data = savingsPlansDataSelector(state);
  const totalUtilization =
    azureSavingsPlansGranularDataTotalUtilizationSelector(state);

  return !!(data?.length && !totalUtilization?.amount);
};
