import { FC, memo } from "react";
import { Stack, Typography } from "@mui/material";
import { money } from "../../../../../utils/numeral/money";
import { TrendChip } from "../../../../common/chips/TrendChip";

interface BillingExplorerGranularCostCellProps {
  pr?: number;
  cost: number | null;
  trend?: number | null;
  trendTooltip?: string;
}

export const BillingExplorerGranularCostCell: FC<BillingExplorerGranularCostCellProps> =
  memo(({ cost, trend, trendTooltip, pr = 4.5 }) => {
    return (
      <Stack alignItems="end" justifyContent="center" pr={pr} spacing={0.25}>
        <Typography variant="body2">
          {cost !== null ? money(cost) : "-"}
        </Typography>

        {trend !== null && (
          <TrendChip value={trend} size="small" tooltipTitle={trendTooltip} />
        )}
      </Stack>
    );
  });
