import { FC, useMemo } from "react";
import { getTargetMultipleResourcesGridColumns } from "./targetMultipleResourcesGridColumns";
import { taskManagementTargetResourcesSelector } from "../../../../../../../../../../../../store/task-management/selectors/form/target/resources/taskManagementTargetResourcesSelector";
import { useAppSelector } from "../../../../../../../../../../../../store/hooks";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";
import { TaskTargetDataGrid } from "../../common/TaskTargetDataGrid";

export const TargetMultipleResources: FC = () => {
  const resources = useAppSelector(taskManagementTargetResourcesSelector);
  const edit = useAppSelector(isTaskManagementDrawerEditModeSelector);

  const resourceType = resources?.at(0)?.resource_type;

  const columnDefs = useMemo(() => {
    if (!resourceType) {
      return [];
    }

    return getTargetMultipleResourcesGridColumns(resourceType, edit);
  }, [resourceType, edit]);

  if (!resources) {
    return null;
  }

  return <TaskTargetDataGrid rowData={resources} columnDefs={columnDefs} />;
};
