import { FC } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import { ServiceCell } from "../../../../common/data-grid-cells/ServiceCell";

export const SavingsOpportunityServiceCellRenderer: FC<
  CustomCellRendererProps<SavingsOpportunity>
> = (cell) => {
  const data = cell.data as SavingsOpportunity;

  if (!data?.service) {
    return null;
  }

  return <ServiceCell service={data.service} />;
};
