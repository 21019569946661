import { billingSummaryWidgetDataResponseSelector } from "./billingSummaryWidgetDataResponseSelector";
import { RootState } from "../../../../../store";

export const billingSummaryWidgetDataLoadingSelector = (
  state: RootState,
  widgetId: string,
) => {
  const response = billingSummaryWidgetDataResponseSelector(state, widgetId);

  return !!response?.isLoading;
};
