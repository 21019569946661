import { FC, Fragment } from "react";
import { useDidMount } from "rooks";
import { Stack } from "@mui/material";
import { BillingExplorerWidgetContent } from "./components/BillingExplorerWidgetContent";
import { WidgetLoadingState } from "../common/WidgetLoadingState";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../../../store/resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { WidgetCardHeader } from "../common/widget-header/WidgetCardHeader";
import { billingExplorerWidgetViewIdByWidgetIdSelector } from "../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetViewIdByWidgetIdSelector";
import { billingExplorerWidgetTitlePropsSelector } from "../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetTitlePropsSelector";
import { billingExplorerWidgetByWidgetIdLoadingSelector } from "../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/loading/billingExplorerWidgetByWidgetIdLoadingSelector";
import { getBillingExplorerWidgetDataByWidgetIdThunk } from "../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/getBillingExplorerWidgetDataByWidgetIdThunk";

interface DashboardBillingExplorerWidgetProps {
  widgetId: string;
}

export const DashboardBillingExplorerWidget: FC<
  DashboardBillingExplorerWidgetProps
> = ({ widgetId }) => {
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();

  const viewId = useAppSelector((state) =>
    billingExplorerWidgetViewIdByWidgetIdSelector(state, widgetId),
  );

  const widgetTitleProps = useAppSelector((state) =>
    billingExplorerWidgetTitlePropsSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    billingExplorerWidgetByWidgetIdLoadingSelector(state, widgetId),
  );

  const viewName = useAppSelector((state) =>
    resourceExplorerViewNameByViewIdSelector(state, viewId),
  );

  useDidMount(() => {
    dispatch(getBillingExplorerWidgetDataByWidgetIdThunk(widgetId));
  });

  return (
    <Stack ref={ref} flexGrow={1} overflow="auto">
      {loading ? (
        <WidgetLoadingState />
      ) : (
        <Fragment>
          <WidgetCardHeader
            hovered={hovered}
            widgetId={widgetId}
            widgetType="cost_breakdown"
            widgetTitleProps={widgetTitleProps}
          />
          <BillingExplorerWidgetContent
            viewName={viewName}
            widgetId={widgetId}
          />
        </Fragment>
      )}
    </Stack>
  );
};
