import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import PercentIcon from "@mui/icons-material/Percent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { costAnomaliesSetupChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-anomalies/widget-change/costAnomaliesSetupChangeThunk";
import { costAnomaliesSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/costAnomaliesSetupPropertyByKeySelector";
import { costAnomaliesWidgetSortByValues } from "../utils/constants";

export const CostAnomaliesSortBy: FC = () => {
  const dispatch = useAppDispatch();

  const sortBy = useAppSelector(
    costAnomaliesSetupPropertyByKeySelector("sortingBy"),
  );

  const sortByChangeHandler = useCallback(
    (event: any) => {
      dispatch(
        costAnomaliesSetupChangeThunk({
          sortingBy: event.target.value,
        }),
      );
    },
    [dispatch],
  );

  const renderValue = useCallback((value: any) => {
    return (
      <Stack direction="row" gap={1}>
        {value === "sort_by_percentage" ? (
          <PercentIcon fontSize="small" color="action" />
        ) : (
          <AttachMoneyIcon fontSize="small" color="action" />
        )}

        {costAnomaliesWidgetSortByValues.get(value)}
      </Stack>
    );
  }, []);

  return (
    <FormControl size="small" fullWidth>
      <InputLabel>Sort by</InputLabel>
      <Select
        value={sortBy}
        label="Sort by"
        onChange={sortByChangeHandler}
        sx={{
          flex: 1,
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "grey.300" },
        }}
        renderValue={renderValue}
      >
        <MenuItem value="sort_by_percentage">
          <Stack direction="row" spacing={0.5}>
            <PercentIcon fontSize="small" color="action" />
            <ListItemText sx={{ m: 0 }}>Percentage</ListItemText>
          </Stack>
        </MenuItem>
        <MenuItem value="sort_by_amount">
          <Stack direction="row" spacing={0.5}>
            <AttachMoneyIcon fontSize="small" color="action" />
            <ListItemText sx={{ m: 0 }}>Cost Amount</ListItemText>
          </Stack>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
