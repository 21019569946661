import { ProviderType } from "../../../../services/cloudchipr.api";
import AWSSvgIcon from "../../../../assets/images/logos/providers/AWSSvgIcon";
import GCPSvgIcon from "../../../../assets/images/logos/providers/GCPSvgIcon";
import AzureSvgIcon from "../../../../assets/images/logos/providers/AzureSvgIcon";
import KubernetesSvgIcon from "../../../../assets/images/logos/providers/KubernetesSvgIcon";

export const navigationProviderIconsMap = new Map<ProviderType, any>([
  ["aws", AWSSvgIcon],
  ["gcp", GCPSvgIcon],
  ["azure", AzureSvgIcon],
  ["kubernetes", KubernetesSvgIcon],
]);
