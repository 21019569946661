import { FC, Fragment, useMemo } from "react";
import {
  Divider,
  IconButton,
  Link,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import filterTemplateIcon from "../../../../assets/images/icons/filter_template.svg";
import {
  ProviderType,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../store/hooks";
import { filterTemplateByIdAndProviderSelector } from "../../../../store/accounts/selectors/filterTemplateByIdAndProviderSelector";
import { useMenuHook } from "../../../../utils/hooks/useMenu.hook";
import { FiltersDisabledView } from "../../common/filters/filters-disabled-view/FiltersDisabledView";

interface SavingsOpportunityFilterSetIconProps {
  filterSetId: string;
  provider: ProviderType;
  accountId: string;
  resourceType: ResourceType;
}

export const SavingsOpportunityFilterSetIcon: FC<
  SavingsOpportunityFilterSetIconProps
> = ({ filterSetId, provider, accountId, resourceType }) => {
  const { open, openMenu, closeMenu, anchor } = useMenuHook();
  const filterSet = useAppSelector((state) =>
    filterTemplateByIdAndProviderSelector(state, filterSetId, provider),
  );
  const currentResourceFilter = useMemo(() => {
    return filterSet?.filters?.find((filter) => filter.type === resourceType);
  }, [resourceType, filterSet]);

  if (!filterSet) {
    return;
  }

  return (
    <Fragment>
      <Tooltip
        title={`Filter Template: ${filterSet.name}`}
        arrow
        placement="top"
      >
        <IconButton size="small" onClick={openMenu}>
          <img src={filterTemplateIcon} alt="filter-template" />
        </IconButton>
      </Tooltip>

      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        <Stack px={2} pb={1}>
          <Typography variant="body2" fontWeight="medium">
            Filters
          </Typography>
          <Stack spacing={0.5} direction="row" alignItems="center">
            <Typography variant="caption">Filter Template:</Typography>
            <Typography variant="caption" fontWeight="bold">
              {filterSet.name}
            </Typography>
            <Tooltip title="Manage Filter Templates" arrow placement="top">
              <IconButton
                size="small"
                component={Link}
                target="_blank"
                href={`/providers/${provider}/live-resources?accountIds=${accountId}`}
              >
                <OpenInNew sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Divider />
        <Stack pt={1} px={2} maxWidth="65dvw">
          {currentResourceFilter?.filter_items?.length ? (
            <FiltersDisabledView filter={currentResourceFilter} />
          ) : (
            <Typography variant="caption">No Filters</Typography>
          )}
        </Stack>
      </Menu>
    </Fragment>
  );
};
