import { billingExplorerWidgetByIdSelector } from "./billingExplorerWidgetByIdSelector";
import { RootState } from "../../../../../store";
import { DateDataPointNullable } from "../../../../../../services/cloudchipr.api";

export const billingExplorerWidgetFrequencySelector = (
  state: RootState,
  widgetId: string,
): DateDataPointNullable | undefined => {
  const widgetData = billingExplorerWidgetByIdSelector(state, widgetId);

  return widgetData?.date_granularity;
};
