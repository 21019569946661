import { resourceDetailsTargetSelector } from "./resourceDetailsTargetSelector";
import { RootState } from "../../../store";
import { ResourceDetailsTargetType } from "../../utils/types/common";

export const resourceDetailsTargetResourceSelector = (
  state: RootState,
): ResourceDetailsTargetType | undefined => {
  const target = resourceDetailsTargetSelector(state);

  return target?.resource;
};
