import { FC } from "react";
import { Stack } from "@mui/material";
import { useContextYears } from "@rehookify/datepicker";
import { DateRangeCalendarYear } from "./DateRangeCalendarYear";

interface DateRangeCalendarYearSelectionProps {
  onSelect(): void;
  setOffsetDate(date?: Date): void;
}

export const DateRangeCalendarYearSelection: FC<
  DateRangeCalendarYearSelectionProps
> = ({ onSelect, setOffsetDate }) => {
  const years = useContextYears();

  return (
    <Stack
      px={5}
      gap={1}
      direction="row"
      flexWrap="wrap"
      justifyContent="center"
    >
      {years.years?.map((dpYear) => {
        return (
          <DateRangeCalendarYear
            dpYear={dpYear}
            key={dpYear.year}
            onSelect={onSelect}
            setOffsetDate={setOffsetDate}
          />
        );
      })}
    </Stack>
  );
};
