import { FC, Fragment, useCallback } from "react";
import { FormikHelpers } from "formik";
import { AutomationNotificationThreshold } from "../../../../../../automations/common/components/form/threshold/AutomationNotificationThreshold";
import { NotificationsSelection } from "../../../../../../common/notification-selection/NotificationsSelection";
import { ModalFilterItem } from "../../../../../../common/filters/filters-selection/utils/types/types";
import { IntegrationNotification } from "../../../../../../common/notification-selection/utils/types/types";
import {
  Emails,
  Schedule,
} from "../../../../../../../../services/cloudchipr.api";

interface SchedulesNotificationsProps {
  errors: any;
  threshold: boolean;
  selectedEmails: Emails | null;
  selectedMinThreshold?: Schedule["min_threshold"];
  selectedIntegrations?: IntegrationNotification[] | null;
  setFieldValue: FormikHelpers<ModalFilterItem>["setFieldValue"];
  setFieldError: FormikHelpers<ModalFilterItem>["setFieldError"];
}

export const SchedulesNotifications: FC<SchedulesNotificationsProps> = ({
  setFieldValue,
  errors,
  selectedEmails,
  selectedIntegrations,
  selectedMinThreshold,
  setFieldError,
  threshold,
}) => {
  const integrationsChangeHandler = useCallback(
    (integrations: IntegrationNotification[] | null) => {
      setFieldValue("notifications", integrations);
    },
    [setFieldValue],
  );

  const emailsChangeHandler = useCallback(
    (emails: Emails | null) => {
      if (!emails) {
        setFieldError("emails", undefined);
      }

      setFieldValue("emails", emails);
    },
    [setFieldValue, setFieldError],
  );

  const minThresholdChangeHandler = useCallback(
    (value: null | number) => {
      setFieldValue("min_threshold", value);
    },
    [setFieldValue],
  );

  return (
    <Fragment>
      <NotificationsSelection
        emailsErrors={errors.emails}
        integrationErrors={errors.notifications}
        selectedEmails={selectedEmails}
        selectedIntegrations={selectedIntegrations}
        emailsChangeHandler={emailsChangeHandler}
        setSelectedIntegrations={integrationsChangeHandler}
        disabledIntegrationTypes={["jira"]}
      />

      {threshold && (
        <AutomationNotificationThreshold
          minThreshold={selectedMinThreshold}
          onThresholdChange={minThresholdChangeHandler}
          error={errors?.min_threshold}
        />
      )}
    </Fragment>
  );
};
