import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { SxProps } from "@mui/system";
import { useToggle } from "rooks";
import { LegendItem } from "./LegendItem";
import { useHighchartsContext } from "../HighchartsProvider";

export const HighChartLegends: FC = () => {
  const [updaterDependency, forceUpdate] = useToggle();
  const { chartApi } = useHighchartsContext();

  return (
    <Stack sx={wrapperStyles}>
      {chartApi?.series?.map((series) =>
        series ? (
          <Box onClick={forceUpdate} key={series.name}>
            <LegendItem
              chartApi={chartApi}
              legend={series.name}
              enabled={updaterDependency !== null && series.visible}
            />
          </Box>
        ) : null,
      )}
    </Stack>
  );
};

const wrapperStyles: SxProps = {
  p: 1,
  gap: 1,
  flexShrink: 0,
  flexWrap: "wrap",
  bgcolor: "white",
  overflow: "hidden",
  flexDirection: "row",
  justifyContent: "center",
};
