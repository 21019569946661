import { FC } from "react";
import { Stack } from "@mui/material";

import { ResourceDetailsGeneralContentK8SUsageChart } from "./ResourceDetailsGeneralContentK8SUsageChart";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { resourceDetailsTargetResourceSelector } from "../../../../../../../../../store/resource-details/selectors/target-resource/resourceDetailsTargetResourceSelector";

export const ResourceDetailsGeneralContentK8SUsageReservation: FC = () => {
  const resource = useAppSelector(resourceDetailsTargetResourceSelector);

  return (
    <Stack direction="row" flexWrap="wrap">
      {resource?.providerMetadata?.k8s?.usage?.chartData?.map((item) => {
        return (
          <ResourceDetailsGeneralContentK8SUsageChart
            key={item.primary}
            {...item}
          />
        );
      })}
    </Stack>
  );
};
