import { costAnomaliesSetupWidgetDataResponseSelector } from "./costAnomaliesSetupWidgetDataResponseSelector";
import { RootState } from "../../../../../store";

export const costAnomaliesSetupWidgetDataLoadingSelector = (
  state: RootState,
) => {
  const response = costAnomaliesSetupWidgetDataResponseSelector(state);

  return !!response?.isLoading;
};
