import { FC } from "react";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import { ResourceExplorerAllViewsHeaderActions } from "./components/ResourceExplorerAllViewsHeaderActions";
import { PageHeader } from "../../../common/PageHeader";
import { useAppSelector } from "../../../../../store/hooks";
import { resourceExplorerHierarchyVisibilitiesDataLoadingSelector } from "../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesDataLoadingSelector";

export const ResourceExplorerAllViewsHeader: FC = () => {
  const loading = useAppSelector(
    resourceExplorerHierarchyVisibilitiesDataLoadingSelector,
  );

  return (
    <PageHeader
      breadcrumbs={breadcrumbs}
      loading={loading}
      actions={<ResourceExplorerAllViewsHeaderActions loading={loading} />}
    />
  );
};

const breadcrumbs = [
  {
    title: "Billing Explorer",
    icon: <BarChartOutlinedIcon fontSize="small" />,
  },
];
