import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { useToggle } from "rooks";
import { deepPurple } from "@mui/material/colors";
import { BillingExplorerExplainWithAiAnomaliesSpikeHeaderTitle } from "./BillingExplorerExplainWithAiAnomaliesSpikeHeaderTitle";
import { BillingExplorerExplainWithAiAnomalyTrendItemWrapper } from "../BillingExplorerExplainWithAiAnomalyTrendItemWrapper";
import {
  ProviderType,
  RepresentsASingleCostSpike,
} from "../../../../../../../../services/cloudchipr.api";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { billingExplorerAnomalyByIdLoadingSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/loading/billingExplorerAnomalyByIdLoadingSelector";
import { billingExplorerAnomalySummaryDataByIdSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/billingExplorerAnomalySummaryDataByIdSelector";
import { BillingExplorerExplainWithAiLoading } from "../../BillingExplorerExplainWithAiLoading";
import { getBillingExplorerAnomalyByIdThunk } from "../../../../../../../../store/resource-explorer/thunks/resource-explorer-summary/getBillingExplorerAnomalyByIdThunk";

interface BillingExplorerExplainWithAiPanelContentTrendItemProps
  extends RepresentsASingleCostSpike {
  provider: ProviderType;
}
export const BillingExplorerExplainWithAiAnomaliesSpike: FC<
  BillingExplorerExplainWithAiPanelContentTrendItemProps
> = ({ id, ...props }) => {
  const [collapsed, toggleCollapsed] = useToggle(true);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) =>
    billingExplorerAnomalyByIdLoadingSelector(state, id),
  );
  const data = useAppSelector((state) =>
    billingExplorerAnomalySummaryDataByIdSelector(state, id),
  );

  const onCollapse = useCallback(() => {
    toggleCollapsed();
    dispatch(getBillingExplorerAnomalyByIdThunk(id));
  }, [toggleCollapsed, id, dispatch]);

  return (
    <BillingExplorerExplainWithAiAnomalyTrendItemWrapper
      headerTitle={
        <BillingExplorerExplainWithAiAnomaliesSpikeHeaderTitle
          {...props}
          id={id}
        />
      }
      collapsed={collapsed}
      onCollapse={onCollapse}
      loading={loading}
    >
      {loading ? (
        <Stack p={1} pl={2}>
          <BillingExplorerExplainWithAiLoading variant="dark" />
        </Stack>
      ) : (
        <Stack
          overflow="auto"
          px={4}
          dangerouslySetInnerHTML={{ __html: data ?? "" }}
          sx={htmlStyle}
        />
      )}
    </BillingExplorerExplainWithAiAnomalyTrendItemWrapper>
  );
};

const htmlStyle = {
  "& code": {
    backgroundColor: "#EDEFF6",
    padding: 0.25,
    borderRadius: 0.5,
    color: deepPurple["500"],
  },
};
