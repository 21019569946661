import { RootState } from "../../../../../store";
import { billingExplorerWidgetByWidgetIdSelector } from "../widget-view/billingExplorerWidgetByWidgetIdSelector";

export const billingExplorerWidgetByWidgetIdLoadingSelector = (
  state: RootState,
  widgetId: string,
): boolean => {
  const response = billingExplorerWidgetByWidgetIdSelector({ widgetId })(state);

  return !!response?.isLoading;
};
