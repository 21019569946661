import { UnauthorizedWidgetHookArguments } from "../types/types";
import { useGetWidgetsCostAndUsageSummaryByWidgetIdFromAccountServiceQuery } from "../../../../../services/cloudchipr-account-service.api";
import {
  GetWidgetsCostAndUsageSummaryByWidgetIdApiArg,
  useGetWidgetsCostAndUsageSummaryByWidgetIdQuery,
} from "../../../../../services/cloudchipr-authless.api";
import { covertDatesToQueryParam } from "../../../../common/date-range-picker/utils/helpers/covertDatesToQueryParam";

export function useUnauthorizedBillingSummaryHook({
  widgetId,
  organisationId,
  categoryId,
  overrideQueryDates,
}: UnauthorizedWidgetHookArguments) {
  const accountServiceResponse =
    useGetWidgetsCostAndUsageSummaryByWidgetIdFromAccountServiceQuery(
      { widgetId, organisationId: organisationId ?? "" },
      { skip: !organisationId, refetchOnMountOrArgChange: true },
    );

  const datesFormQuery = covertDatesToQueryParam(overrideQueryDates ?? {});
  const apiServiceResponse = useGetWidgetsCostAndUsageSummaryByWidgetIdQuery(
    {
      widgetId: widgetId,
      categoryId,
      ...datesFormQuery,
    } as GetWidgetsCostAndUsageSummaryByWidgetIdApiArg,
    { skip: !categoryId, refetchOnMountOrArgChange: true },
  );

  return !accountServiceResponse.isUninitialized
    ? accountServiceResponse
    : apiServiceResponse;
}
