import { ColumnDef, Row } from "@tanstack/react-table";
import { SxProps } from "@mui/system";
import { grey } from "@mui/material/colors";
import { MetricsFrequenciesType } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/types/metricsFrequenciesType";
import {
  ColumnSetupType,
  isCellStylesCallable,
} from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { KubernetesResourceDataType } from "../../../../../../../../../../../store/kubernetes-live-usage-mgmt/utils/types/types";
import { getColumnSize } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/resourceColumnSizes";
import { customCell } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/TableCellByType";
import { filterFnByCellType } from "../../../../../../../../../../../storybook/data-grid/utils/filters/common";
import { getSortingFnByKeyAndType } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/getSortingFnByKeyAndType";
import { wrapWithParentKey } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/nf-tmp-helpers";
import { HeaderCell } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/header-cells/HeaderCell";
import { RowSelectionCellCheckbox } from "../../../../../../../../../../../storybook/data-grid/common/RowSelectionCellCheckbox";
import { RowExpandingCell } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/row-expanding-cell/RowExpandingCell";
import { RowExpandingHeaderCell } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/row-expanding-cell/RowExpandingHeaderCell";
import { RowSelectCheckboxHeaderCell } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/row-select-checkbox-cell/RowSelectCheckboxHeaderCell";

interface GenerateColumnsConfigs {
  includeCheckbox?: boolean;
  includeActions?: boolean;
  includeExpander?: boolean;
  metricsFrequencies?: MetricsFrequenciesType;
  alwaysShowSortIcon?: boolean;
}

export const generateKubernetesColumns = (
  setupData: ColumnSetupType<KubernetesResourceDataType>[],
  configs?: GenerateColumnsConfigs,
): ColumnDef<KubernetesResourceDataType>[] => {
  const {
    alwaysShowSortIcon,
    includeCheckbox,
    includeActions,
    includeExpander,
    metricsFrequencies,
  } = configs ?? {};
  const tdStyles: SxProps = {
    py: 0.25,
    px: 2,
  };

  const columns = setupData.map(({ type, headerTooltip, ...props }, index) => {
    const headerTitle =
      typeof props.header === "string" ? props.header : props.meta?.headerTitle;

    const minSize = getColumnSize({
      type: type,
      enableSorting: props.enableSorting,
      headerTooltip: headerTooltip,
    });

    return {
      cell: customCell(type),
      enableSorting: true,
      enableResizing: true,
      minSize: minSize,
      filterFn: filterFnByCellType.get(type) ?? "defaultFilter",
      sortingFn: getSortingFnByKeyAndType(
        type,
        props?.accessorKey,
        props?.id,
        metricsFrequencies,
      ),
      ...props,

      // todo: (nf) manually change accessor keys in all columns, when resources new data structure will approved
      accessorKey: props.accessorKey
        ? wrapWithParentKey(props.accessorKey)
        : undefined,
      id: wrapWithParentKey(props.id ?? props.accessorKey ?? `${index}`),

      header: (header) => {
        if (props.header instanceof Function) {
          return props.header(header);
        }

        return (
          <HeaderCell
            title={props?.header?.toString() || ""}
            tooltip={headerTooltip}
          />
        );
      },

      meta: {
        alwaysShowSortIcon: alwaysShowSortIcon ?? true,
        headerTitle,
        ...props.meta,
        cellStyles: (row: Row<any>) => {
          const resource = row.original.resource;

          let cellStyles = props.meta?.cellStyles;
          if (isCellStylesCallable(cellStyles)) {
            cellStyles = cellStyles(row);
          }

          const styles = { ...cellStyles, ...tdStyles };

          if (resource.is_protected || resource.isUnavailable) {
            return {
              ...styles,
              bgcolor: grey[100],
            };
          }

          return styles;
        },
      },
    } as ColumnDef<KubernetesResourceDataType>;
  });

  if (includeCheckbox) {
    columns.unshift({
      id: "select",
      size: 40,
      enableResizing: false,
      enableGlobalFilter: false,
      header: ({ table }) => <RowSelectCheckboxHeaderCell table={table} />,
      cell: ({ row, table }) => (
        <RowSelectionCellCheckbox row={row} table={table} />
      ),
      meta: {
        hideFromSettings: true,
        sticky: "left",
        cellStyles: (row: Row<any>) => {
          const resource = row.original.resource;

          if (resource.is_protected || resource.isUnavailable) {
            return {
              bgcolor: grey[100],
            };
          }

          return {
            borderRight: "none !important",
          };
        },
      },
    });
  }

  if (includeExpander) {
    columns.unshift({
      id: "expander",
      size: 45,
      enableResizing: false,
      enableGlobalFilter: false,
      cell: ({ row }) => <RowExpandingCell row={row} />,
      header: ({ table }) => <RowExpandingHeaderCell table={table} />,
      meta: {
        hideFromSettings: true,
        sticky: "left",
        cellStyles: (row: Row<any>) => {
          const resource = row.original.resource;

          if (resource.is_protected || resource.isUnavailable) {
            return {
              bgcolor: grey[100],
            };
          }

          return {};
        },
      },
    });
  }

  if (includeActions) {
    columns.push({
      id: "actions",
      enableResizing: false,
      enableGlobalFilter: false,
      cell: customCell("actions"),
      size: 100,
      meta: {
        hideFromSettings: true,
        sticky: "right",
        cellStyles: (row: Row<any>) => {
          const resource = row.original.resource;

          if (resource.is_protected || resource.isUnavailable) {
            return {
              ...tdStyles,
              bgcolor: grey[100],
            };
          }

          return tdStyles;
        },
      },
    });
  }

  return columns;
};
