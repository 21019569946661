import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Account } from "../../services/cloudchipr.api";

export const accountsDefaultSort: AccountSortByFields = "total_costs";

export type AccountsViewType = "grid" | "list" | "bar" | null;

export type KubernetesClusterSortByFields =
  | "total_monthly_cost"
  | "idle_monthly_cost"
  | "reserved_monthly_cost"
  | "name"
  | "cluster_id"
  | "provider";

export type CloudAccountSortByFields =
  | "ems"
  | "total_costs"
  | "provider_account_id"
  | "provider_account_name";

export type AccountSortByFields =
  | null
  | "custom"
  | CloudAccountSortByFields
  | KubernetesClusterSortByFields;

export interface AccountsInterface {
  accounts: Account[] | null;
  accountSortBy: AccountSortByFields;
  filterValue: string;
  accountsView: AccountsViewType;
  providerOrganisationId: string;
}

const initialState: AccountsInterface = {
  accounts: null,
  accountSortBy: null,
  filterValue: "",
  accountsView: null,
  providerOrganisationId: "",
};

export const accountsSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAccounts: (state, action: PayloadAction<Account[]>) => {
      state.accounts = action.payload;
    },
    setAccountSortBy: (state, action: PayloadAction<AccountSortByFields>) => {
      state.accountSortBy = action.payload;
    },
    setFilterValue: (state, action: PayloadAction<string>) => {
      state.filterValue = action.payload;
    },
    setAccountsView: (state, action: PayloadAction<AccountsViewType>) => {
      state.accountsView = action.payload;
    },
    setAccountsProviderOrganisationId: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.providerOrganisationId = action.payload;
    },
  },
});

export const {
  setAccounts,
  setAccountSortBy,
  setFilterValue,
  setAccountsView,
  setAccountsProviderOrganisationId,
} = accountsSlice.actions;

export default accountsSlice.reducer;
