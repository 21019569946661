import { FC } from "react";
import { Stack } from "@mui/material";
import { blue, deepPurple } from "@mui/material/colors";
import { KubernetesClusterCardFooterProgressDescriptionItem } from "./KubernetesClusterCardFooterProgressDescriptionItem";

export const KubernetesClusterCardFooterProgressDescription: FC = () => {
  return (
    <Stack spacing={2} direction="row" overflow="auto">
      <KubernetesClusterCardFooterProgressDescriptionItem
        title="Usage"
        color={blue[400]}
      />
      <KubernetesClusterCardFooterProgressDescriptionItem
        title="Requested"
        color={deepPurple["A100"]}
      />
    </Stack>
  );
};
