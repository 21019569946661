import {
  YAxisOptions,
  YAxisLabelsOptions,
  YAxisPlotLinesOptions,
  AxisLabelsFormatterContextObject,
} from "highcharts";
import { grey } from "@mui/material/colors";

export const chartYAxisLabels: YAxisLabelsOptions = {
  style: { fontWeight: "600" },
};

export const chartYAxisConfigs: YAxisOptions = {
  lineColor: grey[300],
  lineWidth: 1,
  gridLineDashStyle: "Dash",
};

export const chartYAxisDefaultPlotLine: YAxisPlotLinesOptions = {
  value: 0,
  color: grey[300],
  width: 2,
  zIndex: 5,
};

export const getChartYAxisLabelsDefaultFormatter = (
  percentBased?: boolean,
): ((params: AxisLabelsFormatterContextObject) => string) | undefined => {
  return percentBased
    ? (params: AxisLabelsFormatterContextObject) => `${+params.value}%`
    : undefined;
};
