import { FC, useCallback, useMemo } from "react";
import { useEffectOnceWhen } from "rooks";
import { NoAvailableViewInBudgets } from "./NoAvailableViewInBudgets";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { useGetUsersMeOrganisationsCurrentBudgetsQuery } from "../../../../../../../../../../services/cloudchipr.api";
import { isCurrentBudgetEditingSelector } from "../../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/isCurrentBudgetEditingSelector";
import { getResourceExplorerDataByBudgetThunk } from "../../../../../../../../../../store/budgets/thunks/getResourceExplorerDataByBudgetThunk";
import { budgetFirstAvailableViewIdSelector } from "../../../../../../../../../../store/budgets/selectors/current-budget/view/budgetFirstAvailableViewIdSelector";
import { HierarchyViewSelectionWrapper } from "../../../../../../../../common/hierarchy-view-selection/HierarchyViewSelectionWrapper";
import { resourceExplorerViewsHierarchyDefaultViewSelector } from "../../../../../../../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyDefaultViewSelector";
import { resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector } from "../../../../../../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector";
import { BudgetsAndAlertsHierarchySelectTrigger } from "../../../../common/BudgetsAndAlertsHierarchySelectTrigger";
import { currentBudgetViewIdSelector } from "../../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/currentBudgetViewIdSelector";
import { currentBudgetIdSelector } from "../../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/currentBudgetIdSelector";
import { formDataSelector } from "../../../../../../../../../../store/budgets/selectors/current-budget/forms/formDataSelector";

interface BudgetViewSelectionWrapperProps {
  onChange(id: string): void;
  selectedItemId: string;
}

export const BudgetViewSelectionWrapper: FC<
  BudgetViewSelectionWrapperProps
> = ({ onChange, selectedItemId }) => {
  const currentBudgetId = useAppSelector(currentBudgetIdSelector);
  const viewId = useAppSelector(currentBudgetViewIdSelector);

  const firstAvailableViewId = useAppSelector(
    budgetFirstAvailableViewIdSelector,
  );

  const isEdit = useAppSelector(isCurrentBudgetEditingSelector);
  const formInitialData = useAppSelector(formDataSelector);

  const dispatch = useAppDispatch();
  const { data: budgets } = useGetUsersMeOrganisationsCurrentBudgetsQuery();

  const mergedHierarchyVisibilityItems = useAppSelector(
    resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector,
  );
  const hierarchyDefaultViewId = useAppSelector(
    resourceExplorerViewsHierarchyDefaultViewSelector,
  );

  const selectedViews = useMemo(() => {
    return budgets
      ?.filter(({ id }) => id !== currentBudgetId)
      ?.map(({ view }) => view.id);
  }, [budgets, currentBudgetId]);

  const DropdownFooter = useCallback(() => {
    if (selectedViews?.length === mergedHierarchyVisibilityItems?.length) {
      return (
        <NoAvailableViewInBudgets
          defaultViewId={hierarchyDefaultViewId ?? ""}
        />
      );
    }
  }, [
    hierarchyDefaultViewId,
    mergedHierarchyVisibilityItems,
    selectedViews?.length,
  ]);

  useEffectOnceWhen(
    () => {
      if (isEdit) {
        dispatch(getResourceExplorerDataByBudgetThunk());
      } else if (viewId && !formInitialData?.viewId) {
        onChange(viewId);
      } else if (!formInitialData?.viewId) {
        firstAvailableViewId && onChange(firstAvailableViewId);
      }
    },
    isEdit || !!viewId || !!(budgets && firstAvailableViewId),
  );

  return (
    <HierarchyViewSelectionWrapper
      selectedItemId={selectedItemId}
      onChange={onChange}
      selectedViews={selectedViews}
      DropdownFooter={DropdownFooter}
      CustomTriggerComponent={BudgetsAndAlertsHierarchySelectTrigger}
      visibility="visible_to_everyone"
    />
  );
};
