import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { updateTaskCommentFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

interface UpdateTaskCommentThunkArgs {
  taskId: string;
  comment: string;
  commentId: string;
}

export const updateTaskCommentThunk = createAsyncThunk(
  "taskManagement/updateTaskComment",
  async (
    { taskId, comment, commentId }: UpdateTaskCommentThunkArgs,
    { dispatch },
  ) => {
    const {
      patchUsersMeOrganisationsCurrentTasksByTaskIdCommentsAndCommentId,
    } = cloudChiprApi.endpoints;

    return await dispatch(
      patchUsersMeOrganisationsCurrentTasksByTaskIdCommentsAndCommentId.initiate(
        {
          taskId,
          commentId,
          body: {
            comment,
          },
        },
        {
          fixedCacheKey: updateTaskCommentFixedCacheKey,
        },
      ),
    ).unwrap();
  },
);
