import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCommitmentUtilizationWidgetSetupDataThunk } from "./getCommitmentUtilizationWidgetSetupDataThunk";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { commitmentUtilizationWidgetSetupDefaultData } from "../../../utils/constants/commitmentUtilizationWidgetSetupDefaultData";
import { getOrganizationsForCommitmentsThunk } from "../../../../commitments/thunks/getOrganizationsForCommitmentsThunk";
import { awsOrganizationWithCommitmentsSelector } from "../../../../commitments/selectors/organisations/awsOrganizationWithCommitmentsSelector";
import { RootState } from "../../../../store";

export const initiateCommitmentUtilizationWidgetThunk = createAsyncThunk(
  "dashboard/initiateCommitmentUtilizationWidget",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    await dispatch(getOrganizationsForCommitmentsThunk());

    const orgIds = awsOrganizationWithCommitmentsSelector(state);

    dispatch(
      setWidgetSetup({
        ...commitmentUtilizationWidgetSetupDefaultData,
        providerOrganisationId: orgIds?.at(0)?.organization_id ?? undefined,
      }),
    );

    dispatch(getCommitmentUtilizationWidgetSetupDataThunk());
  },
);
