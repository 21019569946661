import { FC, Fragment, useCallback, useMemo } from "react";
import { Alert, Link } from "@mui/material";
import { WorkflowActionsTypeSelector } from "./WorkflowActionsTypeSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { automationAccountIdsSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationAccountIdsSelector";
import { automationProviderSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationProviderSelector";
import { workflowActionSelector } from "../../../../../../../../store/automations/selectros/workflow/workflowActionSelector";
import { automationResourcesSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationResourcesSelector";
import { useAllResourcesVisibility } from "../../../../../../schedule/common/utils/hooks/useAllResourcesVisibility.hook";
import {
  setAutomationData,
  setWorkflowSpecificData,
} from "../../../../../../../../store/automations/automationsSlice";
import { ScheduleActionType } from "../../../../../../../../services/cloudchipr.api";
import { stateAccountsSelector } from "../../../../../../../../store/accounts/selectors/slice-data/stateAccountsSelector";
import { c8rTermsAndConditionsLink } from "../../../../../../../utils/constants/c8rTermsAndConditionsLink";

export const WorkflowActionsSwitcher: FC = () => {
  const dispatch = useAppDispatch();

  const accountIds = useAppSelector(automationAccountIdsSelector);
  const provider = useAppSelector(automationProviderSelector);
  const currentAction = useAppSelector(workflowActionSelector);
  const resources = useAppSelector(automationResourcesSelector);
  const accounts = useAppSelector(stateAccountsSelector);

  const resourcesVisibility = useAllResourcesVisibility({
    accountIds: accountIds.join(", "),
    provider,
  });

  const bothAccountTypesExist = useMemo(() => {
    const readAccounts = accounts?.filter(({ id }) => accountIds?.includes(id));

    return (
      readAccounts?.some(({ access_type }) => access_type === "read") &&
      readAccounts?.some(({ access_type }) => access_type === "read_write")
    );
  }, [accountIds, accounts]);

  const addActionToResources = useCallback(() => {
    const resourcesNew = resources?.map((resource) => {
      const availableForDelete = Object.values(resourcesVisibility).some(
        (visibility) => visibility?.[resource.filter.type]?.actions.delete,
      );

      return {
        ...resource,
        action: availableForDelete ? "terminate" : resource.action,
      };
    });

    dispatch(setAutomationData({ filter: resourcesNew }));
  }, [dispatch, resources, resourcesVisibility]);

  const actionChangeHandler = useCallback(
    (newAction: ScheduleActionType) => {
      if (currentAction === newAction) {
        return;
      }

      dispatch(setWorkflowSpecificData({ action: newAction }));
      dispatch(setAutomationData({ notifications: null, emails: null }));

      if (newAction === "notify") {
        dispatch(setWorkflowSpecificData({ gracePeriod: null }));
      }

      if (newAction?.includes("clean") && !currentAction?.includes("clean")) {
        addActionToResources();
      }
    },
    [dispatch, addActionToResources, currentAction],
  );

  return (
    <Fragment>
      <WorkflowActionsTypeSelector
        accountIds={accountIds}
        onChange={actionChangeHandler}
        action={currentAction ?? undefined}
      />

      {bothAccountTypesExist && currentAction !== "notify" && (
        <Alert
          variant="outlined"
          severity="warning"
          sx={{ display: "flex", alignItems: "center" }}
        >
          Actions will be performed only on accounts with Read/Write access.
        </Alert>
      )}
      {(currentAction === "clean" || currentAction === "clean_and_notify") && (
        <Alert
          variant="outlined"
          severity="warning"
          sx={{ display: "flex", alignItems: "center" }}
        >
          Executing automation actions on production accounts is not
          recommended, as it may impact critical resources. Check our {""}
          <Link
            href={c8rTermsAndConditionsLink}
            sx={linkStyles}
            target="_blank"
          >
            Terms and Conditions.
          </Link>
        </Alert>
      )}
    </Fragment>
  );
};

const linkStyles = { color: "inherit", textDecorationColor: "#663C00" };
