import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { FC, useCallback, MouseEvent } from "react";
import { IDoesFilterPassParams } from "ag-grid-community";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../store/hooks";
import { tasksAllAssigneesSelector } from "../../../../../../store/task-management/selectors/data/tasksAllAssigneesSelector";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { UserAvatar } from "../../../../../common/avatars/UserAvatar";

export const AssigneeFilter: FC<CustomFilterProps> = ({
  model,
  onModelChange,
  getValue,
}) => {
  const assignees = useAppSelector(tasksAllAssigneesSelector);

  const doesFilterPass = useCallback(
    (params: IDoesFilterPassParams) => {
      const { node } = params;
      const filterTexts: string[] = model ?? [];
      const value: string = getValue(node).toString().toLowerCase();

      return filterTexts.some((text) =>
        value.toLowerCase().includes(text.toLowerCase()),
      );
    },
    [getValue, model],
  );

  const changeHandler = useCallback(
    (e: MouseEvent<HTMLLabelElement>) => {
      if (!("value" in e.target)) {
        return;
      }

      const selected = e.target.value;

      if (!model) {
        onModelChange(
          assignees
            .map((user) => user.name || user.email)
            .filter((value) => value !== selected),
        );

        return;
      }

      let values = [...(model ?? [])];

      if (values.includes(selected)) {
        values = values.filter((value) => value !== selected);
      } else {
        values.push(selected);
      }

      onModelChange(
        values?.length && values?.length !== assignees.length ? values : null,
      );
    },
    [assignees, model, onModelChange],
  );

  const allSelected = !model || assignees.length === model?.length;

  const selectAllHandler = useCallback(() => {
    onModelChange(allSelected ? [] : null);
  }, [allSelected, onModelChange]);

  useGridFilter({
    doesFilterPass,
  });

  return (
    <Stack p={1} pl={0} spacing={1}>
      <FormControlLabel
        onChange={selectAllHandler}
        sx={{ px: 1, mr: 0, width: "fit-content" }}
        control={
          <Checkbox
            size="small"
            checked={!model || allSelected}
            indeterminate={!allSelected && !!model?.length}
          />
        }
        label={
          <Stack direction="row" spacing={1} alignItems="center">
            <TypographyWithTooltip variant="body2" title="Select All" />
          </Stack>
        }
      />

      {assignees.map((user) => {
        const identifier = user.name || user.email || "";

        return (
          <FormControlLabel
            onClick={changeHandler}
            key={identifier}
            sx={{ px: 1, mr: 0, width: "fit-content" }}
            control={
              <Checkbox
                value={identifier}
                size="small"
                checked={(!model || model?.includes(identifier)) ?? false}
              />
            }
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <UserAvatar
                  profile={user.profile}
                  name={identifier}
                  sx={{ cursor: "inherit" }}
                />

                <TypographyWithTooltip variant="body2" title={identifier} />
              </Stack>
            }
          />
        );
      })}
    </Stack>
  );
};
