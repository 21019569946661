import { FC, useCallback } from "react";
import { useDidMount } from "rooks";
import { useFlag } from "@unleash/proxy-client-react";
import { DialogsContainer } from "./components/DialogsContainer";
import { DialogsContainerV2 } from "./components/DialogsContainerV2";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { currentIntegrationTypeSelector } from "../../../store/integrations/selectors/currentIntegrationTypeSelector";
import { currentIntegrationResourceTypeSelector } from "../../../store/integrations/selectors/currentIntegrationResourceTypeSelector";
import { destroyCurrentIntegration } from "../../../store/integrations/integrationsSlice";
import { getAllIntegrationsThunk } from "../../../store/integrations/thunks/getAllIntegrationsThunk";
import { useAppAbility } from "../../../services/permissions";
import { currentIntegrationProviderSelector } from "../../../store/integrations/selectors/currentIntegrationProviderSelector";

export const IntegrationDialogs: FC = () => {
  const dispatch = useAppDispatch();
  const { can } = useAppAbility();
  const integrationsAvailable = can("view", "integration");
  const enableRedesignedIntegrations = useFlag("EnableRedesignedIntegrations");

  const currentIntegrationType = useAppSelector(currentIntegrationTypeSelector);
  const resourceType = useAppSelector(currentIntegrationResourceTypeSelector);
  const provider = useAppSelector(currentIntegrationProviderSelector);

  const closeHandler = useCallback(() => {
    dispatch(destroyCurrentIntegration());
  }, [dispatch]);

  useDidMount(() => {
    if (!integrationsAvailable) {
      return;
    }
    dispatch(getAllIntegrationsThunk());
  });

  if (!currentIntegrationType || !resourceType) {
    return null;
  }

  if (enableRedesignedIntegrations) {
    return (
      <DialogsContainerV2
        onClose={closeHandler}
        resourceType={resourceType}
        integrationType={currentIntegrationType}
        provider={provider}
      />
    );
  }

  return (
    <DialogsContainer
      onClose={closeHandler}
      resourceType={resourceType}
      integrationType={currentIntegrationType}
      provider={provider}
    />
  );
};
