import { FC } from "react";
import { Chip, Stack } from "@mui/material";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";

export const ReportsDrawerNotificationsCustomMessageAfterMessage: FC = () => {
  return (
    <Stack direction="row" spacing={1}>
      <Chip
        avatar={<AttachmentOutlinedIcon fontSize="small" />}
        label="Resources.csv"
      />
      <Chip
        avatar={<AttachmentOutlinedIcon fontSize="small" />}
        label="Resources.pdf"
      />
    </Stack>
  );
};
