import { FC } from "react";
import {
  ResourceFilters,
  FilterGroup,
  FilterItemWithType,
  ResourceType,
} from "../../../../../../../services/cloudchipr.api";

export type ModalFilterItem = FilterItemWithType & { id: string };

export interface FiltersInitialValues {
  type: ResourceType;
  filter_items: FilterItems;
  filter_groups: FilterGroups;
  operators: FilterOperators;
}

export type FilterGroups = ResourceFilters["filter_groups"];

export type FilterOperators = ResourceFilters["operators"];

export type FilterItems = ModalFilterItem[];

export const isFilterGroup = (
  filterIndex: number | FilterGroup,
): filterIndex is FilterGroup => {
  return Array.isArray(filterIndex);
};

export type FilterSourceButton = FC<{
  openDialog: () => void;
  resourceType: ResourceType;
}>;
