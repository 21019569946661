import { FC } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { noOrgAccountOrgId } from "../utils/helpers/noOrganizationConstants";
import { generateLiveResourcesPath } from "../utils/helpers/generateLiveResourcesPath";
import { ProviderType } from "../../../../services/cloudchipr.api";

export const AccountsOldUrlsTmpGuard: FC = () => {
  const location = useLocation();
  const { orgId } = useParams();

  const provider = location.pathname.split("/").at(1) as ProviderType;

  if (!provider) {
    return null;
  }

  if (orgId) {
    return <Navigate to={generateLiveResourcesPath({ provider, orgId })} />;
  }

  return (
    <Navigate
      to={generateLiveResourcesPath({ provider, orgId: noOrgAccountOrgId })}
    />
  );
};
