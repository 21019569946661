import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../store/alerts/utils/types/types";
import { AlertsDataGridActionsCell } from "../actions/AlertsDataGridActionsCell";
import { isCostAnomalyAlertsDataGridDataType } from "../../cost-anomaly/utils/helpers/isCostAnomalyAlertsDataGridDataType";

export const getAlertsActionsColumnDef = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(): ColDef<T> => ({
  colId: "actions",
  width: 120,
  pinned: "right",
  sortable: false,
  resizable: false,
  unSortIcon: true,
  cellDataType: false,
  lockPinned: true,
  lockVisible: true,
  suppressMovable: true,
  suppressHeaderContextMenu: true,
  suppressHeaderMenuButton: true,
  suppressColumnsToolPanel: true,
  filter: false,

  cellRenderer: ({ data }: CustomCellRendererProps<T>) => {
    if (!data) {
      return;
    }
    const isCostAnomaly = isCostAnomalyAlertsDataGridDataType(data);

    return (
      <AlertsDataGridActionsCell
        alert={data}
        alertsActiveTab={isCostAnomaly ? "costAnomaly" : "utilization"}
        disabled={isCostAnomaly ? !data.viewName : undefined}
        disabledTooltipTitle="The connected Billing Reports has been deleted."
        orgId={isCostAnomaly ? undefined : data.provider_organisation_id}
      />
    );
  },
});
