import { FC, Fragment, useCallback } from "react";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ResourceExplorerViewDuplicateDialog } from "./ResourceExplorerViewDuplicateDialog";
import { useDialog } from "../../../../../../../../../utils/hooks/useDialog.hook";
import { NavigationItemsVisibilityType } from "../../../../../../../../../store/navigation/utils/types";

interface ResourceExplorerDuplicateActionItemProps {
  id: string;
  name: string;
  folderId?: string;
  visibility: NavigationItemsVisibilityType;
  onClose(): void;
}

export const ResourceExplorerDuplicateActionItem: FC<
  ResourceExplorerDuplicateActionItemProps
> = ({ name, id, folderId, visibility, onClose }) => {
  const { open, openDialog, closeDialog } = useDialog();

  const closeHandler = useCallback(() => {
    closeDialog();
    onClose();
  }, [onClose, closeDialog]);

  return (
    <Fragment>
      <MenuItem onClick={openDialog}>
        <ListItemIcon>
          <ContentCopyIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="body2">Duplicate</Typography>
      </MenuItem>

      <ResourceExplorerViewDuplicateDialog
        open={open}
        id={id}
        folderId={folderId}
        name={name}
        visibility={visibility}
        closeMenu={closeHandler}
      />
    </Fragment>
  );
};
