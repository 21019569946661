import { FC, Fragment, useMemo } from "react";
import { kubernetesClustersTableColumns } from "./kubernetesClustersTableColumns";
import { KubernetesCluster } from "../../../../../../../services/cloudchipr.api";
import { AccountCardRouterWrapper } from "../../../accounts/account-card/AccountCardRouterWrapper";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import { KubernetesClustersBarView } from "../clusters-bar/KubernetesClustersBarView";
import { generateLiveResourcesPath } from "../../../../utils/helpers/generateLiveResourcesPath";

interface KubernetesClustersListViewProps {
  clusters: KubernetesCluster[] | null;
  onSortingChange: (data: KubernetesCluster[], drop?: boolean) => void;
  withBar?: boolean;
}

export const KubernetesClustersListView: FC<
  KubernetesClustersListViewProps
> = ({ clusters, onSortingChange, withBar }) => {
  const columns = useMemo(() => {
    return kubernetesClustersTableColumns.map((column) => {
      if (!column.cell) {
        return column;
      }

      const col = { ...column };

      col.cell = (props) => {
        const navigateTo = generateLiveResourcesPath({
          provider: "kubernetes",
          activeTab: "live-resources",
          accountIds: props.row.original.id,
        });

        return (
          <AccountCardRouterWrapper status="connected" navigateTo={navigateTo}>
            <Fragment>
              {typeof column.cell === "function"
                ? column.cell(props)
                : column.cell}
            </Fragment>
          </AccountCardRouterWrapper>
        );
      };

      return col;
    });
  }, []);

  if (!clusters) {
    return null;
  }

  if (withBar) {
    return <KubernetesClustersBarView clusters={clusters} />;
  }

  return (
    <DataGrid
      enableRowDnD
      styles={styles}
      data={clusters}
      columns={columns}
      onRowsDndChange={onSortingChange}
    />
  );
};

const styles = {
  tableHeaderRow: { borderTop: 1, borderColor: "grey.300" },
  tableContainer: {
    borderRight: 1,
    borderLeft: 1,
    borderColor: "grey.300",
  },
};
