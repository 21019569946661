import { FC } from "react";
import { InternalDashboardCoverageWidget } from "./InternalDashboardCoverageWidget";
import { DashboardWidgetCoverage } from "../../../../../../services/cloudchipr.api";
import { useGetWidgetsCoverageByWidgetIdFromAccountServiceQuery } from "../../../../../../services/cloudchipr-account-service.api";

interface InternalDashboardCoverageWidgetWrapperProps
  extends DashboardWidgetCoverage {
  organisationId: string;
  onWidgetMount?(): void;
}

export const InternalDashboardCoverageWidgetWrapper: FC<
  InternalDashboardCoverageWidgetWrapperProps
> = ({ id, organisationId, ...props }) => {
  const { data, isLoading, isUninitialized, isError, error } =
    useGetWidgetsCoverageByWidgetIdFromAccountServiceQuery(
      { widgetId: id, organisationId },
      { skip: !organisationId, refetchOnMountOrArgChange: true },
    );

  if (isLoading || isUninitialized) {
    return null;
  }

  return (
    <InternalDashboardCoverageWidget
      {...props}
      data={data}
      isError={isError}
      error={error}
      id={id}
    />
  );
};
