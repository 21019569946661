import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { getBillingExplorerWidgetNumeralItemSecondaryLabel } from "./utils/helpers/getBillingExplorerWidgetNumeralItemSecondaryLabel";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { WidgetNumeralViewItem } from "../../../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralViewItem";
import { WidgetNumeralView } from "../../../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { BillingExplorerWidgetContentCosts } from "../../BillingExplorerWidgetContentCosts";
import { DateLabelNullable } from "../../../../../../../../../services/cloudchipr.api";
import { billingExplorerWidgetTableViewDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetTableViewDataSelector";
import { billingExplorerWidgetForecastOptionSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetForecastOptionSelector";
import { billingExplorerWidgetVisualizationSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetVisualizationSelector";
import { billingExplorerWidgetByWidgetIdLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/loading/billingExplorerWidgetByWidgetIdLoadingSelector";
import { billingExplorerWidgetGroupingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetGroupingSelector";
import { billingExplorerWidgetHasAggregationSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetHasAggregationSelector";

interface BillingExplorerWidgetNumeralViewProps {
  widgetId: string;
  dateLabel?: DateLabelNullable;
}

export const BillingExplorerWidgetNumeralView: FC<
  BillingExplorerWidgetNumeralViewProps
> = ({ widgetId, dateLabel }) => {
  const data = useAppSelector((state) =>
    billingExplorerWidgetTableViewDataSelector(state, widgetId),
  );
  const forecastOption = useAppSelector((state) =>
    billingExplorerWidgetForecastOptionSelector(state, widgetId),
  );
  const chartVisualization = useAppSelector((state) =>
    billingExplorerWidgetVisualizationSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    billingExplorerWidgetByWidgetIdLoadingSelector(state, widgetId),
  );

  const grouping = useAppSelector((state) =>
    billingExplorerWidgetGroupingSelector(state, widgetId),
  );

  const hasAggregations = useAppSelector((state) =>
    billingExplorerWidgetHasAggregationSelector(state, widgetId),
  );

  if (!forecastOption) {
    return null;
  }

  return (
    <Fragment>
      {hasAggregations && (
        <Box px={2} pt={2}>
          <BillingExplorerWidgetContentCosts
            widgetId={widgetId}
            dateLabel={dateLabel}
          />
        </Box>
      )}

      <WidgetNumeralView
        loading={loading}
        skeletonCount={data?.length}
        width={itemWidth}
      >
        {data?.map((item) => {
          const providers = item?.multiProviders ?? [item.cloud_provider];

          const secondaryLabel =
            getBillingExplorerWidgetNumeralItemSecondaryLabel({
              monthlyCost: item?.monthly_forecast?.cost,
              quarterlyCost: item?.quarterly_forecast?.cost,
              grouping,
              forecastOption,
            });

          return (
            <WidgetNumeralViewItem
              key={item.cloud_provider + item.field}
              providers={item.cloud_provider ? providers : []}
              label={item.field}
              cost={item.total_cost}
              trend={chartVisualization?.trend ? item.trend : null}
              itemsCount={data.length}
              secondaryLabel={
                chartVisualization?.forecasted_cost ? secondaryLabel : undefined
              }
              width={itemWidth}
            />
          );
        })}
      </WidgetNumeralView>
    </Fragment>
  );
};

const itemWidth = "20%";
