import { FC, Fragment } from "react";
import { useDidMount } from "rooks";
import { Stack } from "@mui/material";
import { CostAnomaliesWidgetContent } from "./components/CostAnomaliesWidgetContent";
import { CostAnomaliesWidgetFooter } from "./components/footer/CostAnomaliesWidgetFooter";
import { CostAnomaliesWidgetCardHeaderDate } from "./components/header/CostAnomaliesWidgetCardHeaderDate";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { WidgetLoadingState } from "../common/WidgetLoadingState";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { getCostAnomaliesWidgetDataByWidgetIdThunk } from "../../../../../../store/dashboards/thunks/widgets/cost-anomalies/data-get/getCostAnomaliesWidgetDataByWidgetIdThunk";
import { costAnomaliesWidgetViewIdByWidgetIdSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-anomalies/costAnomaliesWidgetViewIdByWidgetIdSelector";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../../../store/resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { costAnomaliesWidgetTitlePropsSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-anomalies/costAnomaliesWidgetTitlePropsSelector";
import { costAnomaliesWidgetDataLoadingSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-anomalies/widget-data/costAnomaliesWidgetDataLoadingSelector";
import { costAnomaliesWidgetDataExistSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-anomalies/widget-data/costAnomaliesWidgetDataExistSelector";
import { costAnomaliesWidgetPrevPeriodCostDateDetailsSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-anomalies/widget-data/costAnomaliesWidgetPrevPeriodCostDateDetailsSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { WidgetCardHeader } from "../common/widget-header/WidgetCardHeader";
import { WidgetSourceInfo } from "../common/widget-header/source-and-name/WidgetSourceInfo";

interface CostAnomaliesWidgetProps {
  widgetId: string;
}

export const CostAnomaliesWidget: FC<CostAnomaliesWidgetProps> = ({
  widgetId,
}) => {
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();

  const viewId = useAppSelector((state) =>
    costAnomaliesWidgetViewIdByWidgetIdSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    costAnomaliesWidgetDataLoadingSelector(state, widgetId),
  );
  const viewName = useAppSelector((state) =>
    resourceExplorerViewNameByViewIdSelector(state, viewId),
  );

  const dataExists = useAppSelector((state) =>
    costAnomaliesWidgetDataExistSelector(state, widgetId),
  );

  const widgetTitleProps = useAppSelector((state) =>
    costAnomaliesWidgetTitlePropsSelector(state, widgetId),
  );

  const prevDate = useAppSelector((state) =>
    costAnomaliesWidgetPrevPeriodCostDateDetailsSelector(state, widgetId),
  );

  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  useDidMount(() => {
    dispatch(getCostAnomaliesWidgetDataByWidgetIdThunk(widgetId));
  });

  return (
    <Stack ref={ref} flexGrow={1} overflow="auto">
      {loading ? (
        <WidgetLoadingState />
      ) : (
        <Fragment>
          <WidgetCardHeader
            hovered={hovered}
            widgetId={widgetId}
            widgetType="largest_cost_changes"
            widgetTitleProps={widgetTitleProps}
          >
            {widgetTitleProps && (
              <WidgetSourceInfo {...widgetTitleProps} hideDates>
                <CostAnomaliesWidgetCardHeaderDate
                  prevDate={prevDate}
                  date={widgetTitleProps.dates}
                  hovered={hovered}
                  widgetId={widgetId}
                />
              </WidgetSourceInfo>
            )}
          </WidgetCardHeader>

          <CostAnomaliesWidgetContent
            widgetId={widgetId}
            viewName={viewName}
            previousPeriodDate={prevDate}
            date={widgetTitleProps?.dates}
          />

          {dataExists && (
            <CostAnomaliesWidgetFooter
              prevDate={prevDate}
              quarterStartMonth={quarterStartMonth}
              date={widgetTitleProps?.dates}
            />
          )}
        </Fragment>
      )}
    </Stack>
  );
};
