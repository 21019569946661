import { FC } from "react";
import { Card, LinearProgress } from "@mui/material";
import { BillingSummaryWidgetSetupHeader } from "./BillingSummaryWidgetSetupHeader";
import { BillingSummaryWidgetView } from "./BillingSummaryWidgetView";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";
import { WidgetSetupContentWrapper } from "../../common/WidgetSetupContentWrapper";
import { billingSummarySetupWidgetFilteredDataSelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-summary/data/billingSummarySetupWidgetFilteredDataSelector";
import { billingSummarySetupWidgetDataLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-summary/data/billingSummarySetupWidgetDataLoadingSelector";
import { billingSummarySumSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-summary/billingSummarySumSetupPropertyByKeySelector";
import { DashboardNotFoundWidget } from "../../../../../widgets/common/DashboardNotFoundWidget";
import { objectToUrl } from "../../../../../../../../utils/helpers/dataToUrlToDataConvertors";

export const BillingSummaryWidgetSetupContent: FC = () => {
  const loading = useAppSelector(billingSummarySetupWidgetDataLoadingSelector);
  const data = useAppSelector(billingSummarySetupWidgetFilteredDataSelector);

  const dates = useAppSelector(
    billingSummarySumSetupPropertyByKeySelector("dates"),
  );
  const viewId = useAppSelector(
    billingSummarySumSetupPropertyByKeySelector("viewId"),
  );

  const path = objectToUrl([{ dates }]);
  const totalCostUrl = `/resource-explorer/${viewId}?path=${path}`;

  return (
    <WidgetSetupContentWrapper>
      <Card
        elevation={3}
        sx={{
          height: widgetSetupContentChartHeight,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading && <LinearProgress />}

        <BillingSummaryWidgetSetupHeader />

        {!data && !loading ? (
          <DashboardNotFoundWidget name="" label="billing report" />
        ) : (
          <BillingSummaryWidgetView
            data={data ?? []}
            loading={loading}
            totalCostUrl={totalCostUrl}
            dateLabel={dates?.label}
          />
        )}
      </Card>
    </WidgetSetupContentWrapper>
  );
};
