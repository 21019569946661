import { FC, ReactNode } from "react";
import { Card, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { WidgetContentInvalidState } from "./WidgetContentInvalidState";
import { widgetSetupContentChartHeight } from "../../../../../utils/constants/stylings";

interface WidgetSetupLayoutProps {
  appBar: ReactNode;
  empty?: string;
  appBarWidth?: number;
  children?: ReactNode;
}

export const WidgetSetupLayout: FC<WidgetSetupLayoutProps> = ({
  appBar,
  children,
  empty,
  appBarWidth,
}) => {
  return (
    <Stack direction="row" height="100%" overflow="hidden">
      <Stack
        p={2}
        spacing={2}
        width={appBarWidth ?? 480}
        borderRight={1}
        borderColor={grey[300]}
      >
        <Typography variant="h6" fontWeight="medium" mb={1}>
          Configure Widget
        </Typography>

        {appBar}
      </Stack>
      <Stack flexGrow={1} overflow="hidden" flex={1}>
        {empty ? (
          <Stack
            bgcolor="grey.100"
            p={2}
            height={widgetSetupContentChartHeight}
          >
            <Card variant="outlined" sx={{ display: "flex", flex: 1 }}>
              <WidgetContentInvalidState message={empty} />
            </Card>
          </Stack>
        ) : (
          children
        )}
      </Stack>
    </Stack>
  );
};
