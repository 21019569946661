import { FC } from "react";
import { capitalize } from "@mui/material";

interface DynamoDbBackupTableClassCellProps {
  data: string;
}

export const DynamoDbBackupTableClassCell: FC<
  DynamoDbBackupTableClassCellProps
> = ({ data }) => {
  if (!data) {
    return "N/A";
  }

  return capitalize(data);
};
