import { FilterKeyType } from "../../../../../../../services/cloudchipr.api";

export const metricFilterTypes: Set<string> = new Set([
  "network_in",
  "network_out",
]);

export const metricsConvertToMb: Set<FilterKeyType> = new Set([
  "processed_bytes" as FilterKeyType,
]);
