import { FilterFn } from "@tanstack/react-table";
import { normalizeString } from "./common";

export const providerUniqueIdentifierFilter: FilterFn<any> = (
  row,
  columnId,
  value,
) => {
  const data = row?.original?.resource?.provider_unique_identifier;

  return normalizeString(data)?.includes(normalizeString(value));
};
