import moment from "moment/moment";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { kubernetesLiveUsageMgmtSelectedAccountsSelector } from "../accounts-data/kubernetesLiveUsageMgmtSelectedAccountsSelector";
import { getProviderTypeByResourceType } from "../../../../utils/helpers/providers/getProviderTypeByResourceType";
import { getResourceTypeName } from "../../../../utils/helpers/resources/getResourceTypeName";

export const kubernetesLiveUsageMgmtResourceTypeCsvDataFileNameSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const clusters = kubernetesLiveUsageMgmtSelectedAccountsSelector(state);

  const firstClusterName = clusters?.[0]?.name ?? clusters?.[0]?.cluster_id;
  const names =
    clusters?.length === 1 ? firstClusterName : `${clusters?.length} clusters`;

  const provider = getProviderTypeByResourceType(resourceType);

  const date = moment().format("MM.DD.YY");

  const resourceName =
    getResourceTypeName(resourceType, {
      type: "long",
    })
      ?.toLowerCase()
      ?.replaceAll(" ", "-") ?? "";

  return `${provider}-${names}-${date}-${resourceName}`;
};
