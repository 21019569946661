import numeral from "numeral";
import { resourceExplorerWidgetDataSelector } from "../resource-explorer-widget/resourceExplorerWidgetDataSelector";
import { RootState } from "../../../../store";
import { resourceExplorerGroupingSelector } from "../data/resourceExplorerGroupingSelector";
import { resourceExplorerQuarterEndForecastVisibilitySelector } from "../data-grid/resourceExplorerQuarterEndForecastVisibilitySelector";
import { groupingsExcludingForecastedCostsColumns } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/utils/constants/groupingsExcludingForecastedCostsColumns";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";

export const resourceExplorerCsvDataSelector = (state: RootState) => {
  const data = resourceExplorerWidgetDataSelector(state)?.total;
  const grouping = resourceExplorerGroupingSelector(state);
  const quarterEndForecastVisibility =
    resourceExplorerQuarterEndForecastVisibilitySelector(state);
  const groupingLabel = resourceExplorerGroupingLabelByGroupingSelector(
    state,
    grouping,
  );
  const key = groupingLabel?.singular ?? "";

  if (!data || !key) {
    return null;
  }

  const accountsAndRegionsExist = data.some(
    ({ metadata }) =>
      (metadata?.region || metadata?.provider_account_name) ??
      metadata?.provider_account_id,
  );

  return data.map((item) => {
    const result: Record<string, string | number | null> = {
      [key]: item.field,
      "Previous Period Cost": item.previous_period_total_cost ?? "N/A",
      "Total Cost": item.total_cost,
      Trend:
        item.trend === null ? "N/A" : `${numeral(item.trend).format("0.00")} %`,
    };

    if (grouping && !groupingsExcludingForecastedCostsColumns.has(grouping)) {
      result["Forecasted Month-end Cost"] =
        item?.monthly_forecast?.cost === null
          ? "-"
          : item?.monthly_forecast?.cost;

      if (quarterEndForecastVisibility) {
        result["Forecasted Quarter-end Cost"] =
          item?.quarterly_forecast?.cost === null
            ? "-"
            : item?.quarterly_forecast?.cost;
      }
    }

    if (accountsAndRegionsExist) {
      result.Account =
        item.metadata?.provider_account_name ??
        item.metadata?.provider_account_id ??
        "-";

      result.Region = item.metadata?.region ?? "-";
    }

    return result;
  });
};
