import { FC, useCallback, useEffect, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { useEffectOnceWhen } from "rooks";
import { ResourceExplorerChartBaseSelect } from "./components/ResourceExplorerChartBaseSelect";
import { ResourceExplorerChartBase } from "./utils/types";
import {
  newChartTypesToOld,
  oldChartTypesToNew,
} from "./utils/constants/oldAndNewChartTypes";
import { BillingExplorerChart } from "./BillingExplorerChart";
import { useResourceExplorerPreferences } from "../../../../utils/hooks/useResourceExplorerPreferences.hook";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerChartBaseSelector } from "../../../../../../../store/resource-explorer/selectors/resourceExplorerChartBaseSelector";
import {
  setBillingExplorerSideBarPanelContentType,
  setResourceExplorerChartBase,
} from "../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { BillingExplorerDataPointSelector } from "../../../app-bar/components/resource-explorer-toolbar/components/data-point-selector/BillingExplorerDataPointSelector";
import { ResourceExplorerSummary } from "../resouce-explorer-summary/ResourceExplorerSummary";
import {
  HighchartsChartType,
  HighchartsChartTypeSelect,
} from "../../../../../../../storybook/c8r-highcharts/common/components/HighchartsChartTypeSelect";
import ChartSettingsSvgIcon from "../../../../../../../assets/images/icons/commitments/ChartSettingsSvgIcon";

interface BillingExplorerChartSectionProps {
  viewId: string;
}

export const BillingExplorerChartSection: FC<
  BillingExplorerChartSectionProps
> = ({ viewId }) => {
  const dispatch = useAppDispatch();

  const [chartType, setChartType] =
    useState<HighchartsChartType>("stackedColumn");

  const enableResourcesExplorerExplainWithAI = useFlag(
    "EnableResourcesExplorerExplainWithAI",
  );
  const enableResourcesExplorerExplainWithAIWithExplanation = useFlag(
    "EnableResourcesExplorerExplainWithAIWithExplanation",
  );

  const chartBase = useAppSelector(resourceExplorerChartBaseSelector);

  const { changeChartPreferences, chartPreferences } =
    useResourceExplorerPreferences(viewId);

  const chartBaseChangeHandler = useCallback(
    (base: ResourceExplorerChartBase) => {
      dispatch(setResourceExplorerChartBase(base));
      changeChartPreferences("base", base);
    },
    [changeChartPreferences, dispatch],
  );

  const chartTypeChangeHandler = useCallback(
    (type: HighchartsChartType) => {
      setChartType(type);

      const chartType = newChartTypesToOld[type];
      changeChartPreferences("type", chartType);
      if (chartType !== "stack") {
        chartBaseChangeHandler("cost");
      }
    },
    [changeChartPreferences, chartBaseChangeHandler],
  );

  const chartSettingsPanelOpenHandler = useCallback(() => {
    dispatch(setBillingExplorerSideBarPanelContentType("chartSettings"));
  }, [dispatch]);

  useEffectOnceWhen(() => {
    if (chartPreferences.base !== chartBase) {
      dispatch(setResourceExplorerChartBase(chartPreferences.base));
    }
  }, !!chartPreferences?.base);

  useEffect(() => {
    setChartType(oldChartTypesToNew[chartPreferences?.type] ?? "stackedColumn");
  }, [chartPreferences?.type]);

  return (
    <Stack>
      <Stack
        px={1}
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {chartType === "stackedColumn" && (
            <ResourceExplorerChartBaseSelect
              base={chartBase}
              onChartBaseChange={chartBaseChangeHandler}
            />
          )}

          <BillingExplorerDataPointSelector />
        </Stack>

        <Stack spacing={1} direction="row" alignItems="center">
          {enableResourcesExplorerExplainWithAI &&
            !enableResourcesExplorerExplainWithAIWithExplanation && (
              <ResourceExplorerSummary />
            )}

          {chartType && (
            <HighchartsChartTypeSelect
              size="xsmall"
              selectionType="select"
              chartType={chartType}
              onChartTypeChange={chartTypeChangeHandler}
              availableTypes={["line", "column", "stackedColumn"]}
            />
          )}

          <IconButton onClick={chartSettingsPanelOpenHandler}>
            <ChartSettingsSvgIcon />
          </IconButton>
        </Stack>
      </Stack>

      <BillingExplorerChart chartType={chartType} />
    </Stack>
  );
};
