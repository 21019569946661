import { FC, Fragment } from "react";
import { CoverageWidgetSetupCommitmentTypeSelect } from "../../../adding-widget/widget-create/widget-setups/coverage/components/sidebar/components/CoverageWidgetSetupCommitmentTypeSelect";
import { CoverageWidgetSetupGroupBySelect } from "../../../adding-widget/widget-create/widget-setups/coverage/components/sidebar/components/CoverageWidgetSetupGroupBySelect";
import { CoverageWidgetSetupOrgSelect } from "../../../adding-widget/widget-create/widget-setups/coverage/components/sidebar/components/CoverageWidgetSetupOrgSelect";
import { CoverageWidgetSetupDateRangeSelect } from "../../../adding-widget/widget-create/widget-setups/coverage/components/sidebar/components/CoverageWidgetSetupDateRangeSelect";
import { CoverageWidgetChartTypeSelect } from "../../../adding-widget/widget-create/widget-setups/coverage/components/sidebar/components/visualization/components/CoverageWidgetChartTypeSelect";
import {
  coverageVisualizationDetailsByChartType,
  coverageVisualizationDetailsComponentByType,
} from "../../../adding-widget/widget-create/widget-setups/coverage/components/sidebar/components/visualization/CoverageWidgetVisualizationSection";
import { useAppSelector } from "../../../../../../../store/hooks";
import { coverageSetupVisualizationChartTypeSelector } from "../../../../../../../store/dashboards/selectors/setups/coverage/visualization/coverageSetupVisualizationChartTypeSelector";

export const CoverageWidgetEditPopoverContent: FC = () => {
  const chartType = useAppSelector(coverageSetupVisualizationChartTypeSelector);

  return (
    <Fragment>
      <CoverageWidgetSetupCommitmentTypeSelect />
      <CoverageWidgetSetupGroupBySelect />
      <CoverageWidgetSetupOrgSelect />
      <CoverageWidgetSetupDateRangeSelect />

      <CoverageWidgetChartTypeSelect />

      {coverageVisualizationDetailsByChartType.get(chartType)?.map((type) => {
        const Component = coverageVisualizationDetailsComponentByType.get(type);

        if (!Component) {
          return;
        }

        return <Component key={type} />;
      })}
    </Fragment>
  );
};
