import { FC, useCallback } from "react";
import { useAppDispatch } from "../../../../../../../../../store/hooks";
import { Dates } from "../../../../../../../../../services/cloudchipr.api";
import { WidgetSetupDateRangeSelect } from "../../common/toolbar/WidgetSetupDateRangeSelect";
import { billingSummaryDateChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/billing-summary/widget-change/billingSummaryDateChangeThunk";

export const BillingSummaryDateRangeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const dateRangeChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(billingSummaryDateChangeThunk(dates));
    },
    [dispatch],
  );

  return <WidgetSetupDateRangeSelect onDateChange={dateRangeChangeHandler} />;
};
