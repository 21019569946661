import { costAnomaliesSetupWidgetDataResponseSelector } from "./costAnomaliesSetupWidgetDataResponseSelector";
import { RootState } from "../../../../../store";

export const costAnomaliesSetupPrevPeriodCostDateDetailsSelector = (
  state: RootState,
) => {
  const response = costAnomaliesSetupWidgetDataResponseSelector(state);

  return response?.data?.previous_period_cost_date_details;
};
