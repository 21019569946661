import {
  CostType,
  ProviderType,
} from "../../../../../../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../../../../../../utils/helpers/providers/getProviderName";
import { costTypes } from "../../../../../../../../../app-navigation/components/page-components/billing-explorer/components/actions-menu/action-items/report-settings/components/view-cost-type/utils/constants/constants";

export const getTotalCostTooltipText = (
  connectedProviders: ProviderType[],
  costType?: CostType,
) => {
  if (!connectedProviders.includes("aws") || !costType) {
    return;
  }
  if (connectedProviders.length <= 1) {
    return;
  }

  let totalCostTooltipText = `Total cost includes AWS ${costTypes[costType]} cost, and the actual cost of `;

  const formattedProviders = connectedProviders
    .filter((provider) => provider !== "aws")
    .map((provider) => getProviderName(provider, { title: true }));

  const lastProvider = formattedProviders.pop();
  totalCostTooltipText += formattedProviders.length
    ? `${formattedProviders.join(", ")} and ${lastProvider}.`
    : `${lastProvider}.`;

  return totalCostTooltipText;
};
