import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import NoMatchImage from "../../../../../../../assets/images/no_match.svg";

export const BudgetsGridNoRowsComponent: FC = () => {
  return (
    <Stack spacing={3} alignItems="center">
      <img src={NoMatchImage} alt="EmptyStateImage" width={140} />

      <Typography variant="h6" fontWeight="normal">
        No matching results found.
      </Typography>
    </Stack>
  );
};
