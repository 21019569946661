import { FC, Fragment } from "react";
import { CoverageWidgetSetupCommitmentTypeSelect } from "./components/CoverageWidgetSetupCommitmentTypeSelect";
import { CoverageWidgetSetupGroupBySelect } from "./components/CoverageWidgetSetupGroupBySelect";
import { CoverageWidgetSetupOrgSelect } from "./components/CoverageWidgetSetupOrgSelect";
import { CoverageWidgetSetupDateRangeSelect } from "./components/CoverageWidgetSetupDateRangeSelect";
import { CoverageWidgetVisualizationSection } from "./components/visualization/CoverageWidgetVisualizationSection";
import { CoverageWidgetAggregationsSection } from "./components/aggregation/CoverageWidgetAggregationsSection";
import { CoverageWidgetAdvancedSettingsSection } from "./components/advanced-settings/CoverageWidgetAdvancedSettingsSection";

export const CoverageWidgetSetupSidebar: FC = () => {
  return (
    <Fragment>
      <CoverageWidgetSetupCommitmentTypeSelect />
      <CoverageWidgetSetupGroupBySelect />
      <CoverageWidgetSetupOrgSelect />
      <CoverageWidgetSetupDateRangeSelect />
      <CoverageWidgetVisualizationSection />
      <CoverageWidgetAggregationsSection />
      <CoverageWidgetAdvancedSettingsSection />
    </Fragment>
  );
};
