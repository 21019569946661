import { SvgIcon, SvgIconProps } from "@mui/material";

function ThresholdSvgIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00002 3L4.99997 19H21V21H3V3H5.00002ZM16.6312 7.29877L20.9282 11.2652L19.5717 12.7348L17.3679 10.701L15.3707 15.4464L12.2649 12.6L9.59358 17.5H6V15.5H8.406L11.7339 9.39952L14.629 12.053L16.6312 7.29877ZM9 5.00002V6.99998H6.99998V5.00002H9ZM13 5.00002V6.99998H11V5.00002H13ZM17 5.00002V6.99998H15V5.00002H17ZM21 5.00002V6.99998H19V5.00002H21Z"
        fill={props.color}
      />
    </SvgIcon>
  );
}

ThresholdSvgIcon.muiName = "ThresholdSvgIcon";

export default ThresholdSvgIcon;
