import { FC, useEffect } from "react";
import { List } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { KubernetesLiveUsageResourceNavItem } from "./KubernetesLiveUsageResourceNavItem";
import {
  LiveUsageNavigationResourceType,
  useLiveUsageMgmtPageNavigate,
} from "../../../../live-usage-mgmt/components/navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { resourceTypeSearchParam } from "../../../../live-usage-mgmt/utils/constants/searchParams";
import { resourceGroupsByProvider } from "../../../../../../utils/constants/resources/resourceGroupsByProvider";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../live-usage-mgmt/utils/helpers/getIsResourceTypeHiddenByFeatureToggle";

interface KubernetesLiveUsageSidebarNavigationProps {
  collapsed: boolean;
}

export const KubernetesLiveUsageSidebarNavigation: FC<
  KubernetesLiveUsageSidebarNavigationProps
> = ({ collapsed }) => {
  const [searchParams] = useSearchParams();

  const setResourceTypeInRouter = useLiveUsageMgmtPageNavigate();

  const searchParamsResourceType = searchParams.get(
    resourceTypeSearchParam,
  ) as LiveUsageNavigationResourceType;

  const resourceGroups = resourceGroupsByProvider.get("kubernetes");

  const defaultResourceType = resourceGroups?.at(0)?.value;

  useEffect(() => {
    if (searchParamsResourceType || !defaultResourceType) {
      return;
    }

    setResourceTypeInRouter(defaultResourceType);
  }, [searchParamsResourceType, setResourceTypeInRouter, defaultResourceType]);

  if (!searchParamsResourceType) {
    return null;
  }

  return (
    <List dense>
      {resourceGroups?.map((rg) => {
        if (getIsResourceTypeHiddenByFeatureToggle(rg.value)) {
          return null;
        }

        return (
          <KubernetesLiveUsageResourceNavItem
            key={rg.value}
            selectedResourceType={searchParamsResourceType}
            sidebarCollapsed={collapsed}
            {...rg}
          />
        );
      })}
    </List>
  );
};
