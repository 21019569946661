import { FC, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import PhotoSizeSelectSmallOutlinedIcon from "@mui/icons-material/PhotoSizeSelectSmallOutlined";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import { NestedNavItem } from "../../app-nav-components/nested-nav-item/NestedNavItem";
import { useAppNavOverlayContext } from "../../../utils/useAppNavOverlay.context";

interface SavingsOpportunitiesNavigationItemProps {
  type: "savings-opportunities" | "rightsizing" | "off-hours";
}

export const SavingsOpportunitiesNavigationItem: FC<
  SavingsOpportunitiesNavigationItemProps
> = ({ type }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { onClose } = useAppNavOverlayContext();

  const handleButtonClick = useCallback(() => {
    onClose?.();
    navigate(`/recommendations/${type}`);
  }, [onClose, navigate, type]);

  return (
    <NestedNavItem
      nested
      primary={labelsByType[type]}
      Icon={iconsByType[type]}
      onButtonClick={handleButtonClick}
      selected={location.pathname.startsWith(`/recommendations/${type}`)}
    />
  );
};

const iconsByType = {
  "savings-opportunities": SelectAllIcon,
  rightsizing: PhotoSizeSelectSmallOutlinedIcon,
  "off-hours": HistoryToggleOffOutlinedIcon,
};

const labelsByType = {
  "savings-opportunities": "All Opportunities",
  rightsizing: "Rightsizing",
  "off-hours": "Off Hours",
};
