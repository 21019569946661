import { azureSavingsPlansDataForGranularSelector } from "../../azureSavingsPlansDataForGranularSelector";
import { RootState } from "../../../../../../../store";

export const azureSavingsPlansGranularDataTotalUtilizationSelector = (
  state: RootState,
) => {
  const data = azureSavingsPlansDataForGranularSelector(state);

  return data?.date_granular_data?.total_utilization;
};
