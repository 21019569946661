import { FC } from "react";
import moment from "moment/moment";
import { TypographyProps } from "@mui/material";
import { TooltipText } from "../../../../../../../../../common/TooltipText";
import { formatDate } from "../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { UnusedSince } from "../../../../../../../../../../services/cloudchipr.api";

interface UnusedSinceCellProps extends TypographyProps {
  data: UnusedSince;
  textFormatter?: (value: string) => void;
  tooltipTitle?: string;
}

export const UnusedSinceCell: FC<UnusedSinceCellProps> = ({
  data,
  textFormatter,
  tooltipTitle,
  ...props
}) => {
  if (data?.is_used || !data?.timestamp) {
    return (
      <TooltipText
        tooltipTitle="No data could be found in cloud provider."
        color="text.secondary"
        {...props}
      >
        No data
      </TooltipText>
    );
  }

  const formattedData = moment(formatDate(data?.timestamp));

  const fromNow = moment().diff(formattedData, "hours");

  const durationAsDays =
    Math.abs(fromNow) > 23
      ? moment.duration(-Math.abs(fromNow), "hours").humanize(true, {
          h: 0,
        })
      : "";

  const formattedDurationAsDays =
    durationAsDays && textFormatter
      ? textFormatter(durationAsDays)
      : durationAsDays;

  const titleDate = `${formatDate(formattedData, {
    type: "date",
  })}`;

  return (
    <TooltipText
      tooltipTitle={tooltipTitle ?? titleDate}
      color="text.secondary"
      {...props}
    >
      {formattedDurationAsDays || "Less than a day"}
    </TooltipText>
  );
};
