import { FC, useCallback } from "react";
import { coverageSetupVisualizationChartBaseSelector } from "../../../../../../../../../../../../../store/dashboards/selectors/setups/coverage/visualization/coverageSetupVisualizationChartBaseSelector";
import { WidgetSetupChartBaseSelect } from "../../../../../../common/toolbar/WidgetSetupChartBaseSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../store/hooks";
import { coverageWidgetVisualizationChangeThunk } from "../../../../../../../../../../../../../store/dashboards/thunks/widgets/coverage-widget/setup-change/coverageWidgetVisualizationChangeThunk";
import { ChartBaseType } from "../../../../../../../../../../../../../services/cloudchipr.api";

export const CoverageWidgetChartBaseSelect: FC = () => {
  const dispatch = useAppDispatch();
  const chartBase = useAppSelector(coverageSetupVisualizationChartBaseSelector);

  const handleChange = useCallback(
    (base: ChartBaseType) => {
      dispatch(
        coverageWidgetVisualizationChangeThunk({
          chart_base_type: base,
        }),
      );
    },
    [dispatch],
  );

  return (
    <WidgetSetupChartBaseSelect
      chartBase={chartBase}
      onChartChange={handleChange}
    />
  );
};
