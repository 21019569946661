import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Box, Button, Typography } from "@mui/material";
import {
  AzureSavingsPlan,
  SavingsPlan,
} from "../../../../../../../../../services/cloudchipr.api";
import { ProviderUniqueIdCell } from "../../../../../../common/components/data-grid/cells/ProviderUniqueIdCell";
import {
  commitmentsPaymentOptionLabels,
  savingsPlansTypeLabels,
} from "../../../../../../common/utils/constants/labels";
import {
  azureSavingsPlansCommitmentCellValueGetter,
  azureSavingsPlansUtilizationCellValueGetter,
  savingsPlansEndDateCellValueGetter,
  savingsPlansHasRecommendationCellValueGetter,
  savingsPlansNetSavingsCellValueGetter,
  savingsPlansPaymentOptionCellValueGetter,
  savingsPlansScopeCellValueGetter,
  savingsPlansStartDateCellValueGetter,
  savingsPlansTypeCellValueGetter,
} from "../../../../../../utilization-and-coverage/utils/helpers/valueGetters";
import { ScopeCell } from "../../../../../../common/components/data-grid/cells/ScopeCell";
import { HeaderCellWidthDateTooltip } from "../../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { UtilizationCell } from "../../../../../../utilization-and-coverage/components/content/data-grid/components/cells/UtilizationCell";
import { money } from "../../../../../../../../../utils/numeral/money";
import { DatesCell } from "../../../../../../common/components/data-grid/cells/DatesCell";
import { CommitmentsTableName } from "../../../../../../common/components/data-grid/cells/CommitmentsTableName";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const azureSavingsPlansTableColumnDefs: ColDef<AzureSavingsPlan>[] = [
  {
    width: 350,
    minWidth: 250,
    field: "provider_unique_id",
    headerName: "Savings Plan ID",
    suppressKeyboardEvent: () => true,
    lockVisible: true,
    suppressColumnsToolPanel: true,
    cellRenderer: ({ data }: CustomCellRendererProps<AzureSavingsPlan>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        <ProviderUniqueIdCell id={data?.provider_unique_id ?? ""} />
      </Box>
    ),
  },
  {
    width: 150,
    minWidth: 150,
    field: "type",
    headerName: "Type",
    cellRenderer: ({ data }: CustomCellRendererProps<AzureSavingsPlan>) => {
      const type = data?.type;

      return (
        <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
          {type ? (savingsPlansTypeLabels[type] ?? null) : null}
        </Box>
      );
    },
    valueGetter: savingsPlansTypeCellValueGetter,
  },
  {
    width: 160,
    minWidth: 150,
    field: "scope",
    headerName: "Scope",
    cellRenderer: ({ data }: CustomCellRendererProps<AzureSavingsPlan>) => {
      if (!data) {
        return;
      }

      return <ScopeCell {...data.scope} />;
    },
    valueGetter: savingsPlansScopeCellValueGetter,
  },
  {
    width: 180,
    minWidth: 150,
    field: "region",
    headerName: "Region",
    cellRenderer: ({ data }: CustomCellRendererProps<AzureSavingsPlan>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data?.region ?? ""}
      </Box>
    ),
  },
  {
    width: 200,
    minWidth: 150,
    field: "payment_option",
    headerName: "Payment Option",
    cellRenderer: ({ data }: CustomCellRendererProps<AzureSavingsPlan>) => {
      const option = data?.payment_option;

      return (
        <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
          {option ? (commitmentsPaymentOptionLabels[option] ?? null) : null}
        </Box>
      );
    },
    valueGetter: savingsPlansPaymentOptionCellValueGetter,
  },
  {
    width: 230,
    minWidth: 230,
    resizable: false,
    field: "used_commitment_to_date",
    headerName: "Utilization",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <HeaderCellWidthDateTooltip title="Utilization " />
      ),
    },
    cellRenderer: ({ data }: CustomCellRendererProps<AzureSavingsPlan>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data && (
          <UtilizationCell
            used={data?.used_commitment_to_date?.cost ?? 0}
            total={data?.total_commitment_to_date?.cost ?? 0}
            state={data?.state}
          />
        )}
      </Box>
    ),
    valueGetter: azureSavingsPlansUtilizationCellValueGetter,
  },
  {
    width: 200,
    minWidth: 200,
    field: "total_commitment_to_date",
    headerName: "Commitment",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <HeaderCellWidthDateTooltip title="Commitment " />
      ),
    },
    cellRenderer: ({ data }: CustomCellRendererProps<AzureSavingsPlan>) => (
      <Typography
        variant="body2"
        bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}
      >
        {money(data?.total_commitment_to_date?.cost ?? 0)}
      </Typography>
    ),
    valueGetter: azureSavingsPlansCommitmentCellValueGetter,
  },
  {
    width: 190,
    minWidth: 190,
    field: "net_savings_to_date",
    headerName: "Net Savings",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <HeaderCellWidthDateTooltip title="Net Savings" />
      ),
    },
    cellRenderer: ({ data }: CustomCellRendererProps<AzureSavingsPlan>) => (
      <Typography
        variant="body2"
        bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}
      >
        {money(data?.net_savings_to_date ?? 0)}
      </Typography>
    ),
    valueGetter: savingsPlansNetSavingsCellValueGetter,
  },
  {
    width: 180,
    minWidth: 100,
    field: "start_date",
    headerName: "Start Date",
    cellRenderer: ({ data }: CustomCellRendererProps<AzureSavingsPlan>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data && <DatesCell date={data.start_date} />}
      </Box>
    ),
    valueGetter: savingsPlansStartDateCellValueGetter,
  },
  {
    width: 180,
    minWidth: 100,
    field: "end_date",
    headerName: "End Date",
    cellRenderer: ({ data }: CustomCellRendererProps<SavingsPlan>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data && <DatesCell date={data.end_date} state={data.state} />}
      </Box>
    ),
    valueGetter: savingsPlansEndDateCellValueGetter,
  },
  {
    sortable: false,
    resizable: false,
    filter: false,
    suppressKeyboardEvent: () => true,
    field: "has_recommendation",
    headerName: "Recommendation",
    cellRenderer: ({ data }: CustomCellRendererProps<SavingsPlan>) => (
      <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
        {data?.has_recommendation ? (
          <Button
            size="small"
            variant="outlined"
            sx={{ textTransform: "initial" }}
          >
            <CommitmentsTableName
              id={data.provider_unique_id}
              type="commitment"
              name="Review"
            />
          </Button>
        ) : (
          <Typography variant="body2" color="text.disabled">
            No recommendation
          </Typography>
        )}
      </Box>
    ),
    valueGetter: savingsPlansHasRecommendationCellValueGetter,
  },
  agGridEmptyColumnToFitEmptySpace,
];
