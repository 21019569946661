import { FC, Fragment } from "react";
import { Button, Theme } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { SxProps } from "@mui/system";
import { LiveResourceFiltersPopover } from "./components/LiveResourceFiltersPopover";
import { LiveResourceFiltersTrigger } from "./utils/types/types";
import { LiveResourceFiltersContentProps } from "./components/content/LiveResourceFiltersContent";
import { LiveUsageNavigationResourceType } from "../../pages/live-usage-mgmt/components/navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { useMenuHook } from "../../../utils/hooks/useMenu.hook";
import { ProviderType, ResourceType } from "../../../services/cloudchipr.api";

interface LiveUsageAccountFiltersProps {
  singleResource?: boolean;
  popoverStaticHeight?: string | number;
  resourceType: LiveUsageNavigationResourceType;
  TriggerComponent?: LiveResourceFiltersTrigger;
  provider: ProviderType;
  filtersCount: number;
  resourceTypeFiltersCountMap: Map<ResourceType, number>;
  FiltersContent: FC<LiveResourceFiltersContentProps>;
}

export const LiveResourceFilters: FC<LiveUsageAccountFiltersProps> = ({
  resourceType,
  TriggerComponent,
  singleResource,
  popoverStaticHeight,
  provider,
  filtersCount,
  resourceTypeFiltersCountMap,
  FiltersContent,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  if (!resourceType || !provider) {
    return null;
  }

  return (
    <Fragment>
      {TriggerComponent ? (
        <TriggerComponent onClick={openMenu} filtersCount={filtersCount} />
      ) : (
        <Button
          disableElevation
          size="small"
          onClick={openMenu}
          startIcon={<FilterAltOutlinedIcon />}
          endIcon={<KeyboardArrowDownOutlinedIcon />}
          sx={filterSourceButtonStyles(!!filtersCount)}
          variant={filtersCount ? "contained" : "outlined"}
        >
          Filters ({filtersCount})
        </Button>
      )}

      <LiveResourceFiltersPopover
        open={open}
        anchor={anchor}
        onClose={closeMenu}
        resourceType={resourceType}
        singleResource={singleResource}
        popoverStaticHeight={popoverStaticHeight}
        filtersCount={filtersCount}
        provider={provider}
        resourceTypeFiltersCountMap={resourceTypeFiltersCountMap}
        FiltersContent={FiltersContent}
      />
    </Fragment>
  );
};

const filterSourceButtonStyles = (active: boolean): SxProps<Theme> => ({
  py: 0.5,
  height: 32,
  borderRadius: 4,
  textTransform: "none",
  whiteSpace: "nowrap",
  minWidth: "auto",
  bgcolor: active ? "grey.100" : "tertiary",
  color: ({ palette }) =>
    active ? palette.primary.main : palette.tertiary.main,
  borderColor: ({ palette }) =>
    active ? palette.primary.main : palette.tertiary.main,
  "&:hover": {
    bgcolor: ({ palette }) => (active ? palette.primary.light : "tertiary"),
    borderColor: ({ palette }) =>
      active ? palette.primary.main : palette.tertiary.main,
  },
});
