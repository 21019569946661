import { FC, useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { ResourceFilters } from "../../../../../services/cloudchipr.api";
import { SetRefsFromKey } from "../../../../../utils/hooks/useRefs";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  setKubernetesLiveUsageMgmtAppliedFilters,
  setKubernetesLiveUsageMgmtFilterChangedManually,
  setKubernetesLiveUsageMgmtViewWithoutFiltersResourceTypes,
} from "../../../../../store/kubernetes-live-usage-mgmt/kubernetesLiveUsageMgmtSlice";
import { liveUsageMgmtApplyingFilterTemplateFiltersByResourceTypeSelector } from "../../../../../store/live-usage-mgmt/selectors/liveUsageMgmtApplyingFilterTemplateFiltersByResourceTypeSelector";
import { resourceTypeFilterSubmitHandlerKeyGenerator } from "../../../../common/resource-filters/utils/helpers/resourceTypeFilterSubmitHandlerKeyGenerator";
import { isDeepEqual } from "../../../../../utils/is-deep-equal";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";
import { FiltersDialogContent } from "../../../common/filters/filters-selection/filters-modal/FiltersDialogContent";
import { kubernetesLiveUsageMgmtSelectedClusterIdsSelector } from "../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtSelectedClusterIdsSelector";
import { getKubernetesLiveUsageMgmtResourcesWithFiltersThunk } from "../../../../../store/kubernetes-live-usage-mgmt/thunks/resources/getKubernetesLiveUsageMgmtResourcesWithFiltersThunk";
import { getKubernetesResourceDefaultFiltersByResourceType } from "../../../../../store/kubernetes-live-usage-mgmt/thunks/resources/utils/helpers/getKubernetesResourceDefaultFiltersByResourceType";

interface KubernetesLiveResourceFiltersContentCardProps {
  filter: ResourceFilters;
  setRefFromKey: SetRefsFromKey;
  onClose(): void;
}

export const KubernetesLiveResourceFiltersContentCard: FC<
  KubernetesLiveResourceFiltersContentCardProps
> = ({ filter, setRefFromKey, onClose }) => {
  const dispatch = useAppDispatch();

  const resourceType = filter.type;

  const clusterIds = useAppSelector(
    kubernetesLiveUsageMgmtSelectedClusterIdsSelector,
  );
  const defaultFilters = useAppSelector((state) =>
    liveUsageMgmtApplyingFilterTemplateFiltersByResourceTypeSelector(
      state,
      resourceType,
    ),
  );

  const submitHandlerInitializer = useCallback(
    (submitFormFn: () => void) => {
      const key = resourceTypeFilterSubmitHandlerKeyGenerator(resourceType);

      setRefFromKey(key)(submitFormFn);
    },
    [setRefFromKey, resourceType],
  );

  const submitHandler = useCallback(
    (values: ResourceFilters) => {
      const filtersEqual = isDeepEqual(filter, values);

      if (!clusterIds || filtersEqual) {
        return;
      }

      dispatch(setKubernetesLiveUsageMgmtAppliedFilters(values));
      dispatch(setKubernetesLiveUsageMgmtFilterChangedManually());
      dispatch(
        setKubernetesLiveUsageMgmtViewWithoutFiltersResourceTypes({
          resourceType,
          value: false,
        }),
      );

      dispatch(
        getKubernetesLiveUsageMgmtResourcesWithFiltersThunk(resourceType),
      );
    },
    [dispatch, clusterIds, resourceType, filter],
  );

  const defaultFiltersValue: ResourceFilters = useMemo(() => {
    if (defaultFilters) {
      return defaultFilters;
    }
    const defaultFiltersByResourceType =
      getKubernetesResourceDefaultFiltersByResourceType(resourceType);
    if (defaultFiltersByResourceType) {
      return defaultFiltersByResourceType;
    }
    return {
      filter_groups: [],
      filter_items: [],
      operators: [],
      type: resourceType,
    };
  }, [defaultFilters, resourceType]);

  if (!clusterIds) {
    return null;
  }

  return (
    <Stack bgcolor="grey.50" borderRadius={2}>
      <Typography fontWeight="bold" pl={2.5} pt={1.5}>
        {getResourceTypeName(resourceType, { type: "long" })}
      </Typography>

      <FiltersDialogContent
        unifiedFilters
        filters={filter}
        onSubmit={submitHandler}
        onClose={onClose}
        onSubmitCallback={submitHandlerInitializer}
        accountIds={clusterIds}
        defaultFilters={defaultFiltersValue}
        resourceType={resourceType}
      />
    </Stack>
  );
};
