import { coverageWidgetByWidgetIdEndpointSelector } from "./coverageWidgetByWidgetIdEndpointSelector";
import { RootState } from "../../../../../store";

export const coverageWidgetByWidgetIdDataSelector = (
  state: RootState,
  widgetId: string,
) => {
  const response = coverageWidgetByWidgetIdEndpointSelector({
    widgetId,
  })(state);

  return response?.data;
};
