import { InlineResourceMetric } from "../../../../../../../../../../../../services/cloudchipr.api";
import { metricCellFrequencyValues } from "../../../cells/metrics-cells/utils/constants";

export const metricsChartValueGenerator = (
  duration: string,
  option: "min" | "max" | "sum",
  metrics?: InlineResourceMetric,
) => {
  const frequency = metricCellFrequencyValues[duration] ?? "daily";

  if (!metrics?.[frequency]) {
    return "N/A";
  }

  let splitData = metrics[frequency].split(",");

  if (
    duration === "7d" ||
    // tmp check for integrations modals, should be removed after agGrid
    !duration
  ) {
    splitData = splitData?.slice(splitData?.length - 7, splitData?.length);
  }

  const formatedData = splitData?.map(Number).filter((num) => !isNaN(num));

  if (!formatedData?.length) {
    return "N/A";
  }

  if (option === "sum") {
    return formatedData.reduce((sum, current) => sum + current, 0).toString();
  }

  return (
    formatedData
      ?.reduce((min: number | null, current: number) => {
        if (option === "min") {
          return min && current > min ? min : current;
        } else {
          return min && current < min ? min : current;
        }
      }, null)
      ?.toString() ?? "N/A"
  );
};
