import { ColDef } from "ag-grid-community";
import { Budget } from "../../../../../../../../../services/cloudchipr.api";

export const budgetsLastModifiedBySortingFn: ColDef<Budget>["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const current =
    nodeA?.data?.modified_by?.name ?? nodeA?.data?.modified_by?.email ?? "";
  const next =
    nodeB?.data?.modified_by?.name ?? nodeB?.data?.modified_by?.email ?? "";

  return current.localeCompare(next);
};
