import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCoverageWidgetSetupDataThunk } from "./getCoverageWidgetSetupDataThunk";
import { RootState } from "../../../../store";
import { getOrganizationsForCommitmentsThunk } from "../../../../commitments/thunks/getOrganizationsForCommitmentsThunk";
import { awsOrganizationWithCommitmentsSelector } from "../../../../commitments/selectors/organisations/awsOrganizationWithCommitmentsSelector";

import { setWidgetSetup } from "../../../dashboardsSlice";

import { coverageWidgetSetupDefaultData } from "../../../utils/constants/coverageWidgetSetupDefaultData";

export const initiateCoverageWidgetThunk = createAsyncThunk(
  "dashboard/initiateCoverageWidget",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    await dispatch(getOrganizationsForCommitmentsThunk());

    const orgIds = awsOrganizationWithCommitmentsSelector(state);

    dispatch(
      setWidgetSetup({
        ...coverageWidgetSetupDefaultData,
        providerOrganisationId: orgIds?.at(0)?.organization_id ?? undefined,
      }),
    );

    dispatch(getCoverageWidgetSetupDataThunk());
  },
);
