import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAzureReservationsForDataGridThunk } from "./getAzureReservationsForDataGridThunk";
import { getAzureReservationsForGranularThunk } from "./getAzureReservationsForGranularThunk";

export const getAzureReservationsThunk = createAsyncThunk(
  "commitments/getAzureReservations",
  (_: void, { dispatch }) => {
    dispatch(getAzureReservationsForDataGridThunk());
    dispatch(getAzureReservationsForGranularThunk());
  },
);
