import { FC } from "react";
import { Alert, Typography } from "@mui/material";

export const BillingExplorerExplainWithAiOutRangesAlert: FC = () => {
  return (
    <Alert severity="warning" variant="outlined" sx={{ bgcolor: "white" }}>
      <Typography variant="body2">
        The selected date range exceeds the allowed limit. Please adjust it to
        be within 60 days to use the Explain AI feature.
      </Typography>
    </Alert>
  );
};
