import { FC, useCallback, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AccountsPageBody } from "./components/accounts/AccountsPageBody";
import { AccountsPageTabs } from "./utils/types/types";
import { generateLiveResourcesPath } from "./utils/helpers/generateLiveResourcesPath";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProviderType } from "../../../services/cloudchipr.api";
import { initialProviderSelector } from "../../../store/accounts/selectors/initialProviderSelector";
import { filteredAccountsConvertedForProviderAccountsPageByOrgIdSelector } from "../../../store/accounts/selectors/filteredAccountsConvertedForProviderAccountsPageByOrgIdSelector";
import { RootState } from "../../../store/store";
import { allAccountsGroupedByProviderAndOrgSelector } from "../../../store/accounts/selectors/all-providers/allAccountsGroupedByProviderAndOrgSelector";
import { setAccountsProviderOrganisationId } from "../../../store/accounts/accountsSlice";
import { accountsPageTabSearchParam } from "../live-usage-mgmt/utils/constants/searchParams";

export const ProviderAccounts: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const tabValue = searchParams.get(
    accountsPageTabSearchParam,
  ) as AccountsPageTabs;

  const { provider: providerFromUrl, orgId } = useParams<{
    orgId: string;
    provider: ProviderType;
  }>();

  const initialProvider = useAppSelector(initialProviderSelector);
  const allAccountsGroupedByProviderAndOrg = useAppSelector(
    allAccountsGroupedByProviderAndOrgSelector,
  );

  const provider =
    !providerFromUrl && initialProvider ? initialProvider : providerFromUrl;

  const orgIdIsWrong = !!(
    provider &&
    orgId &&
    !allAccountsGroupedByProviderAndOrg?.[provider]?.[orgId]
  );

  const accountsSelector = useCallback(
    (state: RootState) => {
      return filteredAccountsConvertedForProviderAccountsPageByOrgIdSelector(
        state,
        provider,
        orgId,
      );
    },
    [provider, orgId],
  );
  const accounts = useAppSelector(accountsSelector);

  useEffect(() => {
    if (!orgIdIsWrong || !provider || !allAccountsGroupedByProviderAndOrg) {
      return;
    }

    const providerOrgs = Object.keys(
      allAccountsGroupedByProviderAndOrg[provider],
    );

    const firstOrgId = providerOrgs.at(0);

    if (!firstOrgId) {
      return;
    }

    navigate(generateLiveResourcesPath({ provider, orgId: firstOrgId }));
  }, [orgIdIsWrong, navigate, allAccountsGroupedByProviderAndOrg, provider]);

  useEffect(() => {
    if (!orgId) {
      return;
    }

    dispatch(setAccountsProviderOrganisationId(orgId));
  }, [orgId, dispatch]);

  if (!orgId || !provider || !accounts) {
    return null;
  }

  if (!accounts || !tabValue) {
    return null;
  }

  return (
    <AccountsPageBody
      accounts={accounts}
      provider={provider}
      activeTab={tabValue}
      key={`${orgId}${tabValue}`}
      orgId={orgId}
    />
  );
};
