import { FC } from "react";
import { Tabs } from "@mui/material";
import { ViewsHierarchyTab } from "./ViewsHierarchyTab";
import { useViewsHierarchyContext } from "./ViewsHierarchyProvider";
import { View } from "../../../../../services/cloudchipr.api";

interface ViewsHierarchyTabsProps {
  views: View[];
  selectedViewId: string;
  onTabChange(viewId: string): void;
}

export const ViewsHierarchyTabs: FC<ViewsHierarchyTabsProps> = ({
  onTabChange,
  selectedViewId,
  views,
}) => {
  const { allItemsLabel } = useViewsHierarchyContext();

  return (
    <Tabs value={views.length ? selectedViewId : ""}>
      <ViewsHierarchyTab
        value=""
        onTabChange={onTabChange}
        selected={!selectedViewId}
        view={{ id: "", name: allItemsLabel } as View}
      />

      {views.map((view) => {
        return (
          <ViewsHierarchyTab
            key={view.id}
            view={view}
            value={view.id}
            onTabChange={onTabChange}
            selected={selectedViewId === view.id}
          />
        );
      })}
    </Tabs>
  );
};
