import { ColDef } from "ag-grid-community";
import {
  AzureReservation,
  Reservation,
} from "../../../../../../../../../services/cloudchipr.api";

export const reservationReservedCellComparator: ColDef<
  Reservation | AzureReservation
>["comparator"] = (valueA, valueB, nodeA, nodeB) => {
  const current = nodeA?.data?.total_commitment_to_date?.cost ?? 0;
  const next = nodeB?.data?.total_commitment_to_date?.cost ?? 0;

  return current > next ? 1 : -1;
};
