import { FC, useCallback, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { NotificationCustomMessageDialogContent } from "./NotificationCustomMessageDialogContent";
import { DialogTitleClosable } from "../../../../../../common/dialog-utils/DialogTitleClosable";
import {
  NotificationCustomMessageContent,
  NotificationCustomMessageSlackMentionsConfig,
} from "../../../utils/types/types";
import { NestedIntegration } from "../../../../../../../services/cloudchipr.api";

interface NotificationCustomMessageDialogProps {
  open: boolean;
  type: NestedIntegration["type"];
  message: string;
  onClose(): void;
  onMessageChange(message: string): void;
  dialogContentProps: NotificationCustomMessageContent;
  mention?: NotificationCustomMessageSlackMentionsConfig;
}

export const NotificationCustomMessageDialog: FC<
  NotificationCustomMessageDialogProps
> = ({
  open,
  type,
  onClose,
  message,
  onMessageChange,
  dialogContentProps,
  mention,
}) => {
  const [customMessage, setCustomMessage] = useState<string>(message);

  const submitCustomMessageHandler = useCallback(() => {
    onMessageChange(customMessage);
    onClose();
  }, [onMessageChange, customMessage, onClose]);

  const closeHandler = useCallback(() => {
    onMessageChange(message);
    onClose();
    setCustomMessage(message);
  }, [onMessageChange, onClose, message]);

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      sx={{ px: 1 }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitleClosable
        title="Edit Message"
        onClose={closeHandler}
        TitleProps={{ sx: { p: 2 } }}
      />

      <DialogContent dividers sx={{ p: 2 }}>
        <NotificationCustomMessageDialogContent
          type={type}
          message={message}
          mention={mention}
          onChange={setCustomMessage}
          {...dialogContentProps}
        />
      </DialogContent>

      <DialogActions sx={{ px: 2 }}>
        <Button onClick={closeHandler}>Cancel</Button>
        <Button onClick={submitCustomMessageHandler} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
