import { FC } from "react";
import { StackProps } from "@mui/material";
import { ReservationsColumnsHeaderCellWithTotalAndDateCommon } from "./common/ReservationsColumnsHeaderCellWithTotalAndDateCommon";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { HeaderCellWidthDateTooltipProps } from "../../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { commitmentsTotalsSelector } from "../../../../../../../../../store/commitments/selectors/common/commitmentsTotalsSelector";

interface ReservationsColumnsHeaderCellWithTotalAndDateProps
  extends HeaderCellWidthDateTooltipProps {
  type: "netSavings" | "coveredUsage" | "unCoveredUsage" | "coveredHours";
  alignItems?: StackProps["alignItems"];
}

export const ReservationsColumnsHeaderCellWithTotalAndDate: FC<
  ReservationsColumnsHeaderCellWithTotalAndDateProps
> = ({ ...props }) => {
  const totals = useAppSelector(commitmentsTotalsSelector);

  return (
    <ReservationsColumnsHeaderCellWithTotalAndDateCommon
      alignItems="start"
      {...props}
      totals={totals}
    />
  );
};
