import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { formatDate } from "../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { TooltipText } from "../../../../../../../../../common/TooltipText";
import { TrendChip } from "../../../../../../../../../common/chips/TrendChip";
import { billingExplorerWidgetPrevTotalCostSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetPrevTotalCostSelector";

interface BillingExplorerWidgetTrendCellProps {
  widgetId: string;
  value: number | null;
}

export const BillingExplorerWidgetTrendCell: FC<
  BillingExplorerWidgetTrendCellProps
> = ({ value, widgetId }) => {
  const previousPeriodTotal = useAppSelector((state) =>
    billingExplorerWidgetPrevTotalCostSelector(state, widgetId),
  );

  const fromDate = formatDate(
    previousPeriodTotal?.dateDetails?.from_date ?? "",
    { type: "date" },
  );
  const toDate = formatDate(previousPeriodTotal?.dateDetails?.to_date ?? "", {
    type: "date",
  });

  if (value === null) {
    return (
      <TooltipText
        tooltipTitle={`No data for Previous Period - ${fromDate} - ${toDate}`}
      >
        N/A
      </TooltipText>
    );
  }

  return <TrendChip value={value} />;
};
