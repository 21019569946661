import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSavingsOpportunityViewThunk } from "./createSavingsOpportunityViewThunk";
import { View } from "../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { savingsOpportunityViewByIdSelector } from "../../../selectors/views-hierarchy/savingsOpportunityViewByIdSelector";
import { RootState } from "../../../../store";

type ThunkArgs = {
  name: string;
  viewId: string;
};

export const duplicateSavingsOpportunityViewThunk = createAsyncThunk(
  "savingsOpportunities/duplicateSavingsOpportunityView",
  async (
    { viewId, name }: ThunkArgs,
    { dispatch, getState },
  ): Promise<View | undefined> => {
    const state = getState() as RootState;
    const viewById = savingsOpportunityViewByIdSelector(state, viewId);

    try {
      return await dispatch(
        createSavingsOpportunityViewThunk({
          body: { name, config: viewById?.config },
          disableSnackbar: true,
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
      return;
    }
  },
);
