import { FC, Fragment, useCallback } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { GppBadOutlined, VerifiedUserOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { HierarchyViewProtectActionDialog } from "./HierarchyViewProtectActionDialog";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";

interface HierarchyViewProtectActionProps {
  onClose(): void;
  viewId: string;
  protectedBy: string;
  isProtected: boolean;
  protectionDescription?: string;
}

export const HierarchyViewProtectAction: FC<
  HierarchyViewProtectActionProps
> = ({ onClose, viewId, isProtected, protectedBy, protectionDescription }) => {
  const { open, openDialog, closeDialog } = useDialog();

  const closeHandler = useCallback(() => {
    closeDialog();
    onClose();
  }, [onClose, closeDialog]);

  return (
    <Fragment>
      <MenuItem onClick={openDialog}>
        <ListItemIcon>
          {isProtected ? (
            <GppBadOutlined fontSize="small" />
          ) : (
            <VerifiedUserOutlined fontSize="small" />
          )}
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2">
            {isProtected ? "Unprotect" : "Protect"}
          </Typography>
        </ListItemText>

        <ListItemIcon sx={{ justifyContent: "flex-end" }}>
          <Tooltip
            arrow
            placement="right"
            title={isProtected ? unprotectTooltipTitle : protectTooltipTitle}
          >
            <InfoOutlinedIcon sx={{ fontSize: 16 }} />
          </Tooltip>
        </ListItemIcon>
      </MenuItem>

      <HierarchyViewProtectActionDialog
        open={open}
        onClose={closeHandler}
        viewId={viewId}
        protectedBy={protectedBy}
        isProtected={isProtected}
        protectionDescription={protectionDescription}
      />
    </Fragment>
  );
};

const protectTooltipTitle =
  "Protecting view prevents its settings and preferences from being changed.";
const unprotectTooltipTitle = "Unprotect the view to save changes.";
