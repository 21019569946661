import { FC, Fragment } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import { CommentDeleteActionDialog } from "./CommentDeleteActionDialog";

interface CommentDeleteActionProps {
  commentId: string;
  taskId: string;
  open: boolean;
  closeDialog(): void;
  openDialog(): void;
}

export const CommentDeleteAction: FC<CommentDeleteActionProps> = ({
  commentId,
  taskId,
  closeDialog,
  open,
  openDialog,
}) => {
  return (
    <Fragment>
      <Tooltip arrow title="Delete" placement="top">
        <IconButton size="small" sx={buttonStyles} onClick={openDialog}>
          <DeleteOutlined sx={iconStyles} />
        </IconButton>
      </Tooltip>
      {open && (
        <CommentDeleteActionDialog
          open
          commentId={commentId}
          taskId={taskId}
          closeDialog={closeDialog}
        />
      )}
    </Fragment>
  );
};

const buttonStyles = { p: 0.5 };
const iconStyles = { fontSize: 16 };
