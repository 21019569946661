import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Stack, Typography } from "@mui/material";
import { getAzureCoveredUsageColumnDef } from "./common/getAzureCoveredUsageColumnDef";
import { azureNetSavingsColumnDef } from "./common/azureNetSavingsColumnDef";
import { azureTotalCoverageColumnDef } from "./common/azureTotalCoverageColumnDef";
import {
  AzureReservationsCoverageDataWithId,
  ReservationsCoverageDataWithId,
} from "../../../../../../../../common/utils/types";
import { azureUncoveredUsageColumnDef } from "../../../../../../../utilization-and-coverage/components/drawers/common/data-grid/columns/coverage/common/azureUncoveredUsageColumnDef";
import { CommitmentsProgressBar } from "../../../../../../../../common/components/CommitmentsProgressBar";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const azureReservationCoverageGroupedByAccountColumnDefs: ColDef<AzureReservationsCoverageDataWithId>[] =
  [
    {
      field: "account.name",
      headerName: "Subscription",
      suppressKeyboardEvent: () => true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      width: 250,
      minWidth: 250,

      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureReservationsCoverageDataWithId>) => {
        if (!data) {
          return;
        }

        return (
          <Stack>
            <Typography variant="body2">{data?.account?.name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {data?.account?.provider_account_id}
            </Typography>
          </Stack>
        );
      },
      valueGetter: ({ data }) =>
        `${data?.account?.name ?? ""} ${data?.account?.provider_account_id ?? ""}`,
    },
    getAzureCoveredUsageColumnDef("account"),
    azureUncoveredUsageColumnDef,
    azureNetSavingsColumnDef,
    {
      flex: 1.5,
      field: "coverage",
      minWidth: 200,
      headerName: "Coverage",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
        <CommitmentsProgressBar
          overrideColor="info.light"
          value={data?.coverage ?? 0}
        />
      ),
    },
    azureTotalCoverageColumnDef,
    agGridEmptyColumnToFitEmptySpace,
  ];
