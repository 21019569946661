import { FC } from "react";
import { EmailIntegrationGrid } from "./components/grid/EmailIntegrationGrid";
import { CommunicationIntegrationsPageWrapper } from "../common/components/CommunicationIntegrationsPageWrapper";

export const EmailIntegration: FC = () => {
  return (
    <CommunicationIntegrationsPageWrapper
      type="email"
      breadcrumbs={breadcrumbs}
    >
      <EmailIntegrationGrid />
    </CommunicationIntegrationsPageWrapper>
  );
};

const breadcrumbs = [
  { title: "Integrations", to: "/integrations" },
  { title: "Email" },
];
