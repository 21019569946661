import { azureOverviewDataSelector } from "./azureOverviewDataSelector";
import { RootState } from "../../../../store";

export const azureOverviewTotalUtilization = (
  state: RootState,
): number | null => {
  const data = azureOverviewDataSelector(state);

  return data?.total_utilization ?? null;
};
