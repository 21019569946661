import { green, grey, red } from "@mui/material/colors";

export const resourceCardStatisticColorsByValue = {
  filtered: {
    dark: red[500],
    medium: red[400],
    light: red[50],
  },
  all: {
    dark: green[500],
    medium: green[400],
    light: green[50],
  },
  disabled: {
    dark: grey[600],
    medium: grey[400],
    light: grey[100],
  },
};
