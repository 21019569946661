import {
  RepresentsACostTrendWithProvider,
  RepresentsASingleCostSpikeWithProvider,
} from "./utils/types";
import { billingExplorerSummaryAnomaliesDataByKeySelector } from "./billingExplorerSummaryAnomaliesDataByKeySelector";
import { RootState } from "../../../store";
import { billingExplorerExplainWithAiDataSelector } from "../current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiDataSelector";

export const billingExplorerSummaryAnomaliesFormattedDataSelector = (
  state: RootState,
  index: number,
): ExplorerSummaryAnomaliesFormattedDataType | undefined => {
  const explainWithAiData = billingExplorerExplainWithAiDataSelector(state);
  const key = explainWithAiData?.at(index)?.key ?? "";
  const data = billingExplorerSummaryAnomaliesDataByKeySelector(state, key);

  return data?.reduce((result, item) => {
    const spikesWithProvider =
      item?.explainer?.spikes?.map((spike) => {
        return {
          ...spike,
          provider: item.cloud_provider,
        };
      }) ?? [];

    const increaseTrendsWithProvider =
      item?.explainer?.increase_trends?.map((increaseTrend) => {
        return {
          ...increaseTrend,
          provider: item.cloud_provider,
        };
      }) ?? [];

    const decreaseTrendsWithProvider =
      item?.explainer?.decrease_trends?.map((decreaseTrend) => {
        return {
          ...decreaseTrend,
          provider: item.cloud_provider,
        };
      }) ?? [];

    const spikes = [...result.spikes, ...spikesWithProvider];

    const increaseTrends = [
      ...result.increaseTrends,
      ...increaseTrendsWithProvider,
    ];

    const decreaseTrends = [
      ...result.decreaseTrends,
      ...decreaseTrendsWithProvider,
    ];
    result = {
      spikes,
      increaseTrends,
      decreaseTrends,
      totalCount: spikes.length + increaseTrends.length + decreaseTrends.length,
    };

    return result;
  }, initialState as ExplorerSummaryAnomaliesFormattedDataType);
};

const initialState = {
  increaseTrends: [],
  decreaseTrends: [],
  spikes: [],
  totalCount: 0,
};

export interface ExplorerSummaryAnomaliesFormattedDataType {
  increaseTrends: RepresentsACostTrendWithProvider[];
  decreaseTrends: RepresentsACostTrendWithProvider[];
  spikes: RepresentsASingleCostSpikeWithProvider[];
  totalCount: number;
}
