import { SvgIcon, SvgIconProps } from "@mui/material";

export const ScreenSplitEvenSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9545 0.5L2.04545 0.5C1.19545 0.5 0.5 1.35 0.5 2.38889L0.5 15.6111C0.5 16.65 1.19545 17.5 2.04545 17.5L15.9545 17.5C16.8045 17.5 17.5 16.65 17.5 15.6111L17.5 2.38889C17.5 1.35 16.8045 0.5 15.9545 0.5ZM2.54545 15.9C2.26931 15.9 2.04545 15.6761 2.04545 15.4L2.04547 9.83007L15.9546 9.83007L15.9545 15.4C15.9545 15.6761 15.7307 15.9 15.4545 15.9L2.54545 15.9ZM15.9546 8.17007L15.9546 2.67363C15.9546 2.39749 15.7307 2.17363 15.4546 2.17363L2.54549 2.17363C2.26934 2.17363 2.04549 2.39749 2.04549 2.67363L2.04547 8.17007L15.9546 8.17007Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};

ScreenSplitEvenSvgIcon.muiName = "ScreenSplitEvenSvgIcon";

export default ScreenSplitEvenSvgIcon;
