import { FC, memo, useCallback, useMemo } from "react";
import { FormikHandlers } from "formik";
import {
  AutocompleteFilterBody,
  AutocompleteFilterBodyProps,
} from "../AutocompleteFilterBody";
import {
  FilterItem,
  Operator,
  ResourceType,
  useGetResourceTypesByTypeAndFilterKeyTypeQuery,
} from "../../../../../../../../services/cloudchipr.api";

interface SchemeFilterProps extends AutocompleteFilterBodyProps {
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
  resourceType: ResourceType;
}

export const SchemeFilter: FC<SchemeFilterProps> = memo(
  ({ operators, filter, onChange, resourceType, setFieldValue, ...props }) => {
    const { data, isLoading } = useGetResourceTypesByTypeAndFilterKeyTypeQuery({
      type: resourceType,
      filterKeyType: "scheme",
    });

    const selectedValue = useMemo(() => {
      const value = filter?.value;

      const arrayValue = value && typeof value === "string" ? [value] : [];
      return Array.isArray(value) ? value : arrayValue;
    }, [filter?.value]);

    const filterValueChangeHandler = useCallback(
      (_: any, values: any) => {
        const value = values[0];
        setFieldValue("value", value);
        return setFieldValue("value", value);
      },
      [setFieldValue],
    );

    const renderOptions = useMemo(() => {
      return data?.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
    }, [data]);

    return (
      <AutocompleteFilterBody
        singleSelect
        {...props}
        options={renderOptions ?? []}
        isLoading={isLoading}
        label="Value"
        selectedValue={selectedValue}
        setFieldValue={filterValueChangeHandler}
      />
    );
  },
);
