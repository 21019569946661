import { ValueGetterParams } from "ag-grid-community";
import { CommunicationIntegrationsGridRowType } from "../../../utils/types/common";

export const integrationsInUseValueGetter = <
  T extends CommunicationIntegrationsGridRowType,
>({
  data,
}: ValueGetterParams<T>) => {
  const usages = data?.usages?.map((usage) => usage.name).join(" ");

  if (!usages) {
    return "";
  }

  return usages.toLowerCase();
};
