import { FC, useCallback } from "react";
import { IconButton, Menu, MenuItem, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { KubernetesDetachClusterDrawer } from "./KubernetesDetachClusterDrawer";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../../services/permissions";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";

export const KubernetesClusterActions: FC = () => {
  const { cannot } = useAppAbility();

  const { open: dialogOpen, openDialog, closeDialog } = useDialog();
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const canNotDeleteAccount = cannot("delete", "account");

  const handlePreventCapturing = useCallback((event: any) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const detachHandler = useCallback(() => {
    closeMenu();
    openDialog();
  }, [openDialog, closeMenu]);

  return (
    <Stack alignItems="end" flexGrow={1} onClick={handlePreventCapturing}>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        <MenuItem onClick={detachHandler} disabled={canNotDeleteAccount}>
          Detach
        </MenuItem>
      </Menu>

      <KubernetesDetachClusterDrawer open={dialogOpen} onClose={closeDialog} />
    </Stack>
  );
};
