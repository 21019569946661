import { IconButton, Tooltip } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { FC, useCallback } from "react";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import { filterOutResourcesThunk } from "../../../../../../../../../../../store/live-usage-mgmt/thunks/filterOutResourcesThunk";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";

interface LiveUsageMgmtToolbarFilterOutActionItemProps {
  resourceType: ResourceType;
  disabled: boolean;
  tooltipTitle: string;
}

export const LiveUsageMgmtToolbarFilterOutActionItem: FC<
  LiveUsageMgmtToolbarFilterOutActionItemProps
> = ({ tooltipTitle, resourceType, disabled }) => {
  const dispatch = useAppDispatch();
  const selectedResources = useAppSelector((state) =>
    liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType),
  );
  const filterOutClickHandler = useCallback(() => {
    dispatch(filterOutResourcesThunk({ resourceType, selectedResources }));
  }, [dispatch, resourceType, selectedResources]);

  return (
    <Tooltip title={tooltipTitle} placement="top" arrow>
      <span>
        <IconButton
          sx={{ p: 0.5 }}
          onClick={filterOutClickHandler}
          disabled={disabled}
        >
          <FilterAltOffIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  );
};
