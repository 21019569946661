import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { DatasetLinkedOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
  CommitmentsUtilizationGrouping,
  CommitmentUtilizationType,
  Dates,
} from "../../../../../../../services/cloudchipr.api";
import {
  commitmentGroupByTitleByGrouping,
  commitmentTypeTitleByType,
} from "../../../adding-widget/widget-create/widget-setups/commitment-utilization /utils/constants/constants";
import { InfoDataChip } from "../../common/widget-header/source-and-name/InfoDataChip";
import { DateInfoChip } from "../../../adding-widget/widget-create/widget-setups/common/DateInfoChip";
import { useAppSelector } from "../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { WidgetCardHeaderEditButton } from "../../common/widget-header/components/WidgetCardHeaderEditButton";

interface CommitmentUtilizationWidgetHeaderInfoProps {
  providerOrganisationId?: string;
  commitmentType?: CommitmentUtilizationType;
  grouping?: CommitmentsUtilizationGrouping;
  dates?: Dates;
  widgetId?: string;
  hovered?: boolean;
}

export const CommitmentUtilizationWidgetHeaderInfo: FC<
  CommitmentUtilizationWidgetHeaderInfoProps
> = ({
  providerOrganisationId,
  commitmentType,
  grouping,
  dates,
  widgetId,
  hovered,
}) => {
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );
  const active = commitmentType === "all" ? "overview" : commitmentType;

  return (
    <Stack direction="row" gap={1} alignItems="center" overflow="auto">
      {providerOrganisationId && (
        <Stack
          px={1}
          py={0.25}
          spacing={0.5}
          direction="row"
          borderRadius={1}
          alignItems="center"
          sx={{ cursor: "pointer" }}
          bgcolor={(theme) => theme.palette.primary.light}
        >
          <DatasetLinkedOutlined sx={{ fontSize: 16 }} color="primary" />

          <Typography
            variant="body2"
            color="primary"
            component={Link}
            to={`/commitments/${providerOrganisationId}?active=${active}`}
            target="_blank"
          >
            {commitmentType === "all"
              ? "Commitments"
              : `${commitmentTypeTitleByType.get(commitmentType ?? "reservation")}s`}
          </Typography>
        </Stack>
      )}

      {providerOrganisationId && (
        <InfoDataChip>
          Org ID:{" "}
          <Typography
            component="span"
            variant="inherit"
            fontWeight="medium"
            color="text.primary"
          >
            {providerOrganisationId}
          </Typography>
        </InfoDataChip>
      )}

      {grouping && (
        <InfoDataChip>
          Group By:{" "}
          <Typography
            component="span"
            variant="inherit"
            fontWeight="medium"
            color="text.primary"
          >
            {commitmentGroupByTitleByGrouping.get(grouping)}
          </Typography>
        </InfoDataChip>
      )}

      <DateInfoChip {...dates} quarterStartMonth={quarterStartMonth} />

      {widgetId && (
        <WidgetCardHeaderEditButton
          widgetId={widgetId}
          hovered={!!hovered}
          widgetType="commitments_utilization"
        />
      )}
    </Stack>
  );
};
