import { cloudChiprApi } from "../../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../../store";
import { azureReservationsDetailsPayloadSelector } from "../azureReservationsDetailsPayloadSelector";

const selector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentAzureReservationsData
    .select;

export const azureReservationDetailsSelector = (state: RootState) => {
  const payload = azureReservationsDetailsPayloadSelector(state);

  if (!payload) {
    return;
  }

  return selector(payload)(state);
};
