import { FC } from "react";
import { colors, List, Stack } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { LogoArea } from "./components/logo-area/LogoArea";
import { DashboardNavigation } from "./components/page-components/dashboard/DashboardNavigation";
import { BillingExplorerNavigation } from "./components/page-components/billing-explorer/BillingExplorerNavigation";
import { CommitmentsNavigation } from "./components/page-components/commitments/CommitmentsNavigation";
import { AlertsNavigation } from "./components/page-components/AlertsNavigation";
import { ReportsNavigation } from "./components/page-components/ReportsNavigation";
import { AutomationsNavigation } from "./components/page-components/automations/AutomationsNavigation";
import { SavingsOpportunitiesNavigation } from "./components/page-components/saving-opportunities/SavingsOpportunitiesNavigation";
import { TasksNavigation } from "./components/page-components/TasksNavigation";
import { LiveUsageNavigation } from "./components/page-components/live-usage/LiveUsageNavigation";
import { DimensionsNavigation } from "./components/page-components/DimensionsNavigation";
import { IntegrationsNavigation } from "./components/page-components/IntegrationsNavigation";
import { ExecutionsLogsNavigation } from "./components/page-components/ExecutionsLogsNavigation";
import { SettingsNavigation } from "./components/page-components/settings/SettingsNavigation";
import { getResourceExplorerVisibilityHierarchyThunk } from "../../store/resource-explorer/thunks/resource-explorer-hierarchy-visibility/getResourceExplorerVisibilityHierarchyThunk";
import { useAppAbility } from "../../services/permissions";
import { useAppDispatch } from "../../store/hooks";

interface AppNavigationProps {
  onCollapse(): void;
}

export const AppNavigation: FC<AppNavigationProps> = ({ onCollapse }) => {
  const { can } = useAppAbility();
  const dispatch = useAppDispatch();
  const resourceExplorerAvailable = can("view", "resource-explorer");

  useEffectOnceWhen(() => {
    dispatch(getResourceExplorerVisibilityHierarchyThunk());
  }, resourceExplorerAvailable);

  return (
    <Stack
      height="100%"
      bgcolor="grey.50"
      zIndex={(theme) => theme.zIndex.drawer}
    >
      <LogoArea onCollapse={onCollapse} />

      {/* Scrollable navigation area */}
      <Stack sx={wrapperStyles}>
        <List dense component="nav" sx={{ pt: 0, overflowX: "hidden" }}>
          <DashboardNavigation />

          <BillingExplorerNavigation />

          <CommitmentsNavigation />

          <AlertsNavigation />

          <ReportsNavigation />

          <LiveUsageNavigation />

          <AutomationsNavigation />

          <SavingsOpportunitiesNavigation />

          <TasksNavigation />
        </List>
      </Stack>

      {/* Bottom sticky navigation area */}
      <Stack justifySelf="flex-end">
        <List dense component="nav">
          <DimensionsNavigation />
          <IntegrationsNavigation />
          <ExecutionsLogsNavigation />

          <SettingsNavigation />
        </List>
      </Stack>
    </Stack>
  );
};

const wrapperStyles = {
  flexGrow: 1,
  flexShrink: 1,
  overflow: "auto",
  background: `
  linear-gradient(${colors["grey"]["50"]} 60%, ${colors["grey"]["50"]}) center top,
  linear-gradient(${colors["grey"]["50"]}, ${colors["grey"]["50"]} 70%) center bottom,
  radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0)) center bottom
  `,
  backgroundRepeat: "no-repeat",
  backgroundSize: "0 0, 100% 40px, 700% 44px, 100% 14px",
  backgroundAttachment: "local, local, scroll, scroll",
};
