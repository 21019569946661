import { billingExplorerWidgetForecastOptionSelector } from "./billingExplorerWidgetForecastOptionSelector";
import { billingExplorerWidgetCostsSelector } from "./billingExplorerWidgetCostsSelector";
import { RootState } from "../../../../../store";
import { ForecastedCost } from "../../../../../../services/cloudchipr.api";

export const billingExplorerWidgetForecastedCostsSumSelector = (
  state: RootState,
  widgetId: string,
): ForecastedCost | undefined => {
  const forecastedOption = billingExplorerWidgetForecastOptionSelector(
    state,
    widgetId,
  );
  if (!forecastedOption) {
    return;
  }
  const costs = billingExplorerWidgetCostsSelector(state, widgetId);

  return costs?.forecasted_costs?.find(
    (item) => item.forecast_option === forecastedOption,
  );
};
