import { FC, useCallback, useMemo } from "react";
import { WidgetWithREViewDisabledAction } from "./disabled-actions/WidgetWithREViewDisabledAction";
import { WidgetOptionCard } from "../../WidgetOptionCard";
import Cover from "../../../../utils/assets/images/widget-covers/largest_cost.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { isBillingAvailableSelector } from "../../../../../../../store/common/selectors/billing-status/isBillingAvailableSelector";
import { initiateCostAnomaliesWidgetThunk } from "../../../../../../../store/dashboards/thunks/widgets/cost-anomalies/initiateCostAnomaliesWidgetThunk";
import { dashboardWidgetLabels } from "../../../../utils/constants/constants";
import { defaultResourceExplorerViewIdSelector } from "../../../../../../../store/resource-explorer/selectors/all-resource-explorers/defaultResourceExplorerViewIdSelector";

export const CostAnomaliesCard: FC = () => {
  const dispatch = useAppDispatch();

  const isResourceExplorerAvailable = useAppSelector(
    isBillingAvailableSelector,
  );
  const defaultViewId = useAppSelector(defaultResourceExplorerViewIdSelector);

  const clickHandler = useCallback(() => {
    dispatch(initiateCostAnomaliesWidgetThunk());
  }, [dispatch]);

  const disabledAction = useMemo(() => {
    if (!isResourceExplorerAvailable) {
      return (
        <WidgetWithREViewDisabledAction
          viewId={defaultViewId}
          available={isResourceExplorerAvailable}
        />
      );
    }
  }, [isResourceExplorerAvailable, defaultViewId]);

  return (
    <WidgetOptionCard
      title={dashboardWidgetLabels.largest_cost_changes ?? ""}
      description="Understand the most significant cost increases and decreases across different time ranges."
      onClick={clickHandler}
      disabledAction={disabledAction}
    >
      <img src={Cover} alt="ConstAndUsageCard" />
    </WidgetOptionCard>
  );
};
