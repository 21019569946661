import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBillingSummaryWidgetDataBySetupThunk } from "../data-get/getBillingSummaryWidgetDataBySetupThunk";
import { RootState } from "../../../../../store";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { billingSummaryWidgetByIdSelector } from "../../../../selectors/widgets/billing-summary/billingSummaryWidgetByIdSelector";
import { BillingSummarySetupType } from "../../../../utils/types/setups/billingSummarySetupType";

interface EditBillingSummaryWidgetArgs {
  widgetId: string;
  doNotSetWidgetType?: boolean;
}

export const editBillingSummaryWidgetThunk = createAsyncThunk(
  "dashboards/editBillingSummaryWidgetThunk",
  (
    { doNotSetWidgetType, widgetId }: EditBillingSummaryWidgetArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = billingSummaryWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    dispatch(
      setWidgetSetup({
        widgetType: doNotSetWidgetType ? undefined : "cost_and_usage_summary",
        id: widget.id,
        name: widget.name,
        grouping: widget.grouping,
        group_values: widget.group_values,
        date: {
          from: widget.date_from,
          to: widget.date_to,
          label: widget.date_label ?? null,
        },
        dates: widget.dates,
        viewId: widget.view_id ?? "",
        filter: widget.filter ?? [],
      } as BillingSummarySetupType),
    );

    dispatch(getBillingSummaryWidgetDataBySetupThunk());
  },
);
