import { LinearProgress, Stack } from "@mui/material";
import { FC, Fragment, useCallback, useState } from "react";
import { GridApi } from "ag-grid-community";
import { UtilizationAndCoverageDataViewTabs } from "../../data-grid/components/UtilizationAndCoverageDataViewTabs";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { CommitmentsRecommendationsFilters } from "../filters/CommitmentsRecommendationsFilters";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { commitmentsRecommendationsLoadingSelector } from "../../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsLoadingSelector";
import { AGGridColumnsToolPanel } from "../../../../../../common/ag-grid/components/AGGridColumnsToolPanel";
import { CommitmentsOverviewRecommendationsDataGridViewTabs } from "../../../../../overview/recommendation/CommitmentsOverviewRecommendationsDataGridViewTabs";
import { CommitmentCsvExport } from "../../../../../common/components/data-grid/CommitmentCsvExport";

interface SavingPlansRecommendationsAGGridToolbarProps {
  gridApi: GridApi | null;
  overview?: boolean;
}

export const SavingPlansRecommendationsAGGridToolbar: FC<
  SavingPlansRecommendationsAGGridToolbarProps
> = ({ gridApi, overview }) => {
  const loading = useAppSelector(commitmentsRecommendationsLoadingSelector);

  const [searchValue, setSearchValue] = useState("");

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridApi?.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [gridApi],
  );

  return (
    <Fragment>
      <Stack pb={2} spacing={2}>
        {overview ? (
          <CommitmentsOverviewRecommendationsDataGridViewTabs />
        ) : (
          <UtilizationAndCoverageDataViewTabs />
        )}

        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          gap={2}
          flexWrap="wrap"
        >
          <CommitmentsRecommendationsFilters type="savings_plan" />
          <Stack direction="row" spacing={2}>
            <GlobalFilter
              globalFilter={searchValue}
              setGlobalFilter={searchChangeHandler}
              size="xsmall"
              sx={{ width: 260 }}
            />

            <CommitmentCsvExport gridApi={gridApi} />

            <AGGridColumnsToolPanel gridApi={gridApi} />
          </Stack>
        </Stack>
      </Stack>

      {loading && <LinearProgress />}
    </Fragment>
  );
};
