import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { azureOverviewTotalNetSavingsSelector } from "../../../../../../../store/commitments/selectors/azure/overview/azureOverviewTotalNetSavingsSelector";
import { azureOverviewTotalCoverageSelector } from "../../../../../../../store/commitments/selectors/azure/overview/azureOverviewTotalCoverageSelector";
import { azureOverviewTotalUtilization } from "../../../../../../../store/commitments/selectors/azure/overview/azureOverviewTotalUtilization";
import { azureOverviewLoadingSelector } from "../../../../../../../store/commitments/selectors/azure/overview/loading/azureOverviewLoadingSelector";
import { CommitmentsOverviewMetadataRightSide } from "../../../../overview/metadata/components/common/CommitmentsOverviewMetadataRightSide";

export const AzureCommitmentsOverviewMetadataRightSide: FC = () => {
  const totalNetSavings = useAppSelector(azureOverviewTotalNetSavingsSelector);
  const totalCoverage = useAppSelector(azureOverviewTotalCoverageSelector);
  const totalUtilization = useAppSelector(azureOverviewTotalUtilization);
  const loading = useAppSelector(azureOverviewLoadingSelector);

  return (
    <CommitmentsOverviewMetadataRightSide
      totalNetSavings={totalNetSavings}
      totalCoverage={totalCoverage}
      totalUtilization={totalUtilization}
      loading={loading}
    />
  );
};
