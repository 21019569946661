import { textWidgetByWidgetIdEndpointSelector } from "./textWidgetByWidgetIdEndpointSelector";
import { RootState } from "../../../../../store";

export const textWidgetDataByIdSelector = (
  state: RootState,
  widgetId: string,
) => {
  const response = textWidgetByWidgetIdEndpointSelector({
    widgetId,
  })(state);

  return response?.data;
};
