import { FC, useMemo } from "react";
import { TypographyProps } from "@mui/material";
import { getTargetsFormattedValue } from "../../utils/helpers/getTargetsFormattedValue";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import { TooltipText } from "../../../../../../../../../../common/TooltipText";

interface TargetTypographyProps {
  target: string;
  typographyProps?: Omit<TypographyProps, "title">;
}

export const ElbTargetTypography: FC<TargetTypographyProps> = ({
  typographyProps,
  target,
}) => {
  const formattedTarget = useMemo(
    () => getTargetsFormattedValue(target),
    [target],
  );

  if (formattedTarget === target) {
    return (
      <TypographyWithTooltip
        variant="inherit"
        title={target}
        {...typographyProps}
      />
    );
  }

  return (
    <TooltipText
      variant="inherit"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      tooltipTitle={target}
      sx={textStyles}
      {...typographyProps}
    >
      {formattedTarget}
    </TooltipText>
  );
};

const textStyles = {
  textDecoration: "none",
  textUnderlineOffset: 0,
  display: "block",
};
