import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../../../../services/cloudchipr.api";
import { AzureReservationsCoverageDataWithId } from "../../../../../../../../../common/utils/types";
import { CommitmentsProgressBar } from "../../../../../../../../../common/components/CommitmentsProgressBar";

export const getAzureCoverageColumnDef = (
  grouping: SavingsPlanCoverageGrouping,
): ColDef<AzureReservationsCoverageDataWithId> => {
  const headerName =
    grouping === "commitment" ? "Covered by This RI" : " RI Covers";

  return {
    flex: 1.5,
    field: "coverage",
    minWidth: 200,
    headerName,
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<AzureReservationsCoverageDataWithId>) => (
      <CommitmentsProgressBar
        value={data?.coverage ?? 0}
        overrideColor="info.light"
      />
    ),
  };
};
