import { FC, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTimeoutWhen } from "rooks";
import { Link as RouterLink } from "react-router-dom";
import { ProgressBlock } from "./components/ProgressBlock";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setAccount } from "../../../../store/account-setup/accountSetupSlice";
import { useGetUsersMeAccountsByAccountIdQuery } from "../../../../services/cloudchipr.api";
import { currentAccountSelector } from "../../../../store/account-setup/selectors/currentAccountSelector";
import { accountAttemptSelector } from "../../../../store/account-setup/selectors/accountAttemptSelector";
import { getAccountsThunk } from "../../../../store/accounts/thunks/getAccountsThunk";
import { getProviderName } from "../../../../utils/helpers/providers/getProviderName";
import { gcpConnectionTypeSelector } from "../../../../store/account-setup/selectors/gcpConnectionTypeSelector";
import { getAllProvidersBillingStatusThunk } from "../../../../store/common/thunks/billing-status/getAllProvidersBillingStatusThunk";
import { noOrgAccountOrgId } from "../../accounts-group/utils/helpers/noOrganizationConstants";
import { generateLiveResourcesPath } from "../../accounts-group/utils/helpers/generateLiveResourcesPath";
import { noProviderConnectedSelector } from "../../../../store/accounts/selectors/noProviderConnectedSelector";

export const DataCollectionStep: FC = () => {
  const [pollingInterval, setPollingInterval] = useState(3000);

  const dispatch = useAppDispatch();
  const account = useAppSelector(currentAccountSelector);
  const accountAttempt = useAppSelector(accountAttemptSelector);
  const connectionType = useAppSelector(gcpConnectionTypeSelector);
  const noProviderConnected = useAppSelector(noProviderConnectedSelector);

  useTimeoutWhen(
    async () => {
      await dispatch(getAccountsThunk());
      dispatch(getAllProvidersBillingStatusThunk());
      setPollingInterval(0);
    },
    1000,
    account?.status === "connected",
  );

  const { data } = useGetUsersMeAccountsByAccountIdQuery(
    { accountId: accountAttempt?.status_details?.closed?.account_id || "" },
    { pollingInterval },
  );

  if (data) {
    dispatch(setAccount(data));
  }
  if (!account?.type) {
    return null;
  }

  const connectionTitle =
    connectionType === "single" ? "Project" : "Organization";

  const accountTypeName =
    account?.type === "gcp"
      ? connectionTitle
      : getProviderName(account?.type, {
          capitalize: true,
        });

  const connectionMessage = `Cloudchipr is now connected to your ${account?.type.toUpperCase()} ${accountTypeName}`;

  return (
    <Box>
      <Typography variant="body1" fontWeight="medium" mb={3}>
        {connectionMessage}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={3}>
        This may take few minutes
      </Typography>

      <ProgressBlock />

      {account.status !== "initial_pulling" && (
        <Stack alignItems="end" justifyContent="end">
          <Button
            color="primary"
            variant="outlined"
            component={RouterLink}
            disabled={!!noProviderConnected}
            to={generateLiveResourcesPath({
              provider: account?.type,
              orgId: account?.group_id ?? noOrgAccountOrgId,
            })}
          >
            Go to dashboard
          </Button>
        </Stack>
      )}
    </Box>
  );
};
