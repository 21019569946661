import { useState } from "react";
import { useEffectOnceWhen } from "rooks";
import { GridApi } from "ag-grid-community";

export const useTotalInHeaderCalculator = <T>(
  gridApi: GridApi | null,
  getCost: (data: T) => number,
) => {
  const [total, setTotal] = useState(0);

  useEffectOnceWhen(() => {
    setTotal(totalCalculator<T>(gridApi, getCost));

    gridApi?.addEventListener(
      "filterChanged",
      ({ api }: { api: GridApi | null }) => {
        setTotal(totalCalculator<T>(api, getCost));
      },
    );
  }, !!gridApi);

  return total;
};

const totalCalculator = <T>(
  gridApi: GridApi | null,
  getCost: (data: T) => number,
) => {
  let sum = 0;

  gridApi?.forEachNodeAfterFilter((node) => {
    if (!node.data) {
      return;
    }

    sum = sum + getCost(node.data);
  });

  return sum;
};
