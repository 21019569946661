import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { allAccountsGroupedByProviderAndOrgSelector } from "./allAccountsGroupedByProviderAndOrgSelector";
import { ProviderType } from "../../../../services/cloudchipr.api";

type BiggestOrg = {
  provider: ProviderType;
  orgId: string;
  activeAccountCount: number;
};

export const biggestOrgSelector = createDraftSafeSelector(
  [allAccountsGroupedByProviderAndOrgSelector],
  (allAccountsGroupedByProviderAndOrg): BiggestOrg | undefined => {
    if (!allAccountsGroupedByProviderAndOrg) return undefined;

    return Object.entries(allAccountsGroupedByProviderAndOrg)
      .flatMap(([provider, orgs]) =>
        Object.entries(orgs).map(([orgId, accounts]) => ({
          provider: provider as ProviderType,
          orgId,
          activeAccountCount: accounts.reduce(
            (count, account) =>
              count + (account.status === "connected" ? 1 : 0),
            0,
          ),
        })),
      )
      .reduce<BiggestOrg | undefined>(
        (maxOrg, org) =>
          org.activeAccountCount > (maxOrg?.activeAccountCount || 0)
            ? org
            : maxOrg,
        undefined,
      );
  },
);
