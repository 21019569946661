import { FC } from "react";
import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";

interface KubernetesClusterCardFooterProgressDescriptionItemProps {
  color: string;
  title: string;
}
export const KubernetesClusterCardFooterProgressDescriptionItem: FC<
  KubernetesClusterCardFooterProgressDescriptionItemProps
> = ({ color, title }) => {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Box width={3} height={12} bgcolor={color} borderRadius={0.5} />
      <Typography variant="caption" fontWeight="medium" color="text.secondary">
        {title}
      </Typography>
    </Stack>
  );
};
