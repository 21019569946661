import { createAsyncThunk } from "@reduxjs/toolkit";
import { getKubernetesLiveUsageMgmtResourcesWithOutFiltersByResourceTypeThunk } from "./getKubernetesLiveUsageMgmtResourcesWithOutFiltersByResourceTypeThunk";
import { resourceTypesByProvider } from "../../../../utils/helpers/providers/resourceTypesByProvider";

export const getKubernetesLiveUsageMgmtResourcesWithOutFiltersThunk =
  createAsyncThunk(
    "getKubernetesLiveUsageMgmtResourcesWithOutFilters",
    async (_: void, { dispatch, getState }) => {
      const resourceTypes = resourceTypesByProvider("kubernetes");
      resourceTypes?.forEach((resourceType) => {
        dispatch(
          getKubernetesLiveUsageMgmtResourcesWithOutFiltersByResourceTypeThunk(
            resourceType,
          ),
        );
      });
    },
  );
