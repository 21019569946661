import { FC, useCallback } from "react";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupPropertyByKeySelector";
import { commitmentUtilizationWidgetSetupDataChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/setup-change/commitmentUtilizationWidgetSetupDataChangeThunk";
import { AwsOrganizationSelect } from "../../../../../../../../../../common/AwsOrganizationSelect";

export const CommitmentUtilizationWidgetSetupOrgSelect: FC = () => {
  const dispatch = useAppDispatch();

  const orgId = useAppSelector(
    commitmentUtilizationSetupPropertyByKeySelector("providerOrganisationId"),
  );

  const handleChange = useCallback(
    (providerOrganisationId: string) => {
      dispatch(
        commitmentUtilizationWidgetSetupDataChangeThunk({
          providerOrganisationId,
        }),
      );
    },
    [dispatch],
  );

  return (
    <AwsOrganizationSelect selectedOrgId={orgId} onChange={handleChange} />
  );
};
