import { FC } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import Stack from "@mui/material/Stack";
import { Link, Typography } from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import { money } from "../../../../../utils/numeral/money";

import { generateLiveResourcesPath } from "../../../accounts-group/utils/helpers/generateLiveResourcesPath";

export const SavingsOpportunityMonthlySavingsCellRenderer: FC<
  CustomCellRendererProps<SavingsOpportunity>
> = (cell) => {
  const data = cell.data as SavingsOpportunity;

  if (!data) {
    return null;
  }

  let link = "";

  if (data.account && data.action_type === "live_filtered_resource") {
    link = generateLiveResourcesPath({
      provider: data.account.provider,
      activeTab: "live-resources",
      accountIds: data.account.id,
      resourceType: data.service,
      resourceIds: data.resource_id ?? undefined,
    });
  }

  if (data.action_type === "off_hours" && data.resource_type) {
    link = `/recommendations/off-hours/${data.resource_type}?id=${data.recommendation_id}`;
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{ lineHeight: 0 }}
    >
      <Typography variant="body2">{money(data.monthly_savings)}</Typography>

      {link && (
        <Link href={link} target="_blank" pt={0.5}>
          <OpenInNewOutlinedIcon fontSize="small" color="action" />
        </Link>
      )}
    </Stack>
  );
};
