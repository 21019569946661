import { FC, useCallback } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { CostAnomaliesPriceDirectionItem } from "./CostAnomaliesPriceDirectionItem";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { costAnomaliesSetupChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-anomalies/widget-change/costAnomaliesSetupChangeThunk";
import { costAnomaliesSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/costAnomaliesSetupPropertyByKeySelector";

export const CostAnomaliesPriceDirection: FC = () => {
  const dispatch = useAppDispatch();

  const priceDirection = useAppSelector(
    costAnomaliesSetupPropertyByKeySelector("priceDirection"),
  );

  const priceDirectionChangeHandler = useCallback(
    (event: any) => {
      dispatch(
        costAnomaliesSetupChangeThunk({
          priceDirection: event.target.value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <FormControl>
      <FormLabel>
        <Typography variant="body2" color="text.primary" pb={0.5}>
          Change
        </Typography>
      </FormLabel>
      <RadioGroup value={priceDirection} onChange={priceDirectionChangeHandler}>
        <Stack direction="row" spacing={2}>
          <CostAnomaliesPriceDirectionItem
            checked={priceDirection === "increasing"}
            value="increasing"
          />
          <CostAnomaliesPriceDirectionItem
            checked={priceDirection === "decreasing"}
            value="decreasing"
          />
        </Stack>
      </RadioGroup>
    </FormControl>
  );
};
