import { ValueGetterParams } from "ag-grid-community";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../../store/alerts/utils/types/types";
import { isCostAnomalyAlertsDataGridDataType } from "../../../../cost-anomaly/utils/helpers/isCostAnomalyAlertsDataGridDataType";
import { costAnomalyAlertsThresholdValueGetter } from "../../../../cost-anomaly/utils/helpers/valueGetters/costAnomalyAlertsThresholdValueGetter";
import { utilizationAlertsThresholdValueGetter } from "../../../../utilization/utils/valueGetters/utilizationAlertsThresholdValueGetter";

export const alertsThresholdGetter = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(
  params: ValueGetterParams<T>,
) => {
  const isCostAnomaly = isCostAnomalyAlertsDataGridDataType(params.data);

  if (isCostAnomaly) {
    return costAnomalyAlertsThresholdValueGetter(
      params as unknown as ValueGetterParams<CostAnomalyAlertsDataGridDataType>,
    );
  }

  return utilizationAlertsThresholdValueGetter(
    params as unknown as ValueGetterParams<UtilizationAlertDataGridDataType>,
  );
};
