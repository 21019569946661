import { ColumnSetupType } from "../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { KubernetesDeployment } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { ResourceMetricsHeaderCell } from "../../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/metrics-cells/components/ResourceMetricsHeaderCell";

export const kubernetesDeploymentsColumn: ColumnSetupType<KubernetesDeployment>[] =
  [
    {
      accessorKey: "name",
      header: "Deployment Name",
      size: 280,
      type: "identifier",
      meta: { sticky: "left", hideFromSettings: true },
    },
    {
      accessorKey: "total_monthly_cost",
      header: "Monthly Price",
      type: "money",
      headerTooltip:
        "Monthly prices are calculated based on the on-demand list price of each resource.",
    },
    {
      accessorKey: "cluster",
      header: "Cluster",
      type: "cluster",
    },
    {
      accessorKey: "age_in_seconds",
      header: "Age",
      type: "secondsToFormattedDate",
    },
    {
      accessorKey: "namespace",
      header: "Namespace",
      type: "copyWithTooltip",
    },
    {
      id: "cpu_percent",
      size: 270,
      minSize: 260,
      maxSize: 350,
      meta: {
        cellStyles: { p: 0 },
        headerTitle: "CPU Utilization Max",
        customHeaderTooltip:
          "Represents the highest CPU utilization recorded for the workload over the selected time period, relative to its requested CPU resources.",
      },
      accessorKey: "inline_metrics",
      type: "computeMetricsChart",
      header: ResourceMetricsHeaderCell,
    },
    {
      id: "cpu_request",
      accessorKey: "cpu_request",
      header: "CPU Reservation",
      type: "cpuRequest",
      minSize: 180,
    },
    {
      id: "memory_used_percent",
      size: 300,
      minSize: 290,
      maxSize: 350,
      meta: {
        cellStyles: { p: 0 },
        headerTitle: "Memory Utilization Max",
        customHeaderTooltip:
          "Represents the highest Memory utilization recorded for the workload over the selected time period, relative to its requested Memory resources.",
      },
      accessorKey: "inline_metrics",
      type: "memoryUsedMetricsChart",
      header: ResourceMetricsHeaderCell,
    },
    {
      id: "memory_request",
      accessorKey: "memory_request",
      header: "Memory Reservation",
      type: "byteToGib",
      minSize: 200,
    },
    {
      accessorKey: "pod_count",
      header: "Pods",
    },
    {
      accessorKey: "labels",
      header: "Kubernetes Label",
      type: "tag",
      enableSorting: false,
    },
  ];
