import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";

import { RootState } from "../../../../../store";
import { billingSummaryWidgetNotFoundSelector } from "../../../../selectors/widgets/billing-summary/billingSummaryWidgetNotFoundSelector";
import { enqueueSnackbarErrorAlert } from "../../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getBillingSummaryWidgetDataByWidgetIdThunk = createAsyncThunk(
  "dashboards/getBillingSummaryWidgetDataByWidgetId",
  async (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const {
      getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetId,
    } = cloudChiprApi.endpoints;

    const viewNotFound = billingSummaryWidgetNotFoundSelector(state, widgetId);

    if (viewNotFound) {
      return;
    }

    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetId.initiate(
          { widgetId },
          { forceRefetch: true },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
