import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Box, Button, Typography } from "@mui/material";
import {
  AzureReservation,
  Reservation,
} from "../../../../../../../../../services/cloudchipr.api";
import { ProviderUniqueIdCell } from "../../../../../../common/components/data-grid/cells/ProviderUniqueIdCell";
import {
  commitmentsPaymentOptionLabels,
  commitmentsResourceTypeNames,
} from "../../../../../../common/utils/constants/labels";
import {
  reservationEndDateCellValueGetter,
  reservationHasRecommendationCellValueGetter,
  reservationPaymentOptionCellValueGetter,
  reservationReservedCellValueGetter,
  reservationStartDateCellValueGetter,
  reservationTypeCellValueGetter,
  reservationUtilizationCellValueGetter,
} from "../../../../../../reservations/components/content/data-grid/utils/helpers/valueGetters";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { ScopeCell } from "../../../../../../common/components/data-grid/cells/ScopeCell";
import { HeaderCellWidthDateTooltip } from "../../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { ReservationUtilizationCell } from "../../../../../../reservations/components/content/data-grid/components/cells/ReservationUtilizationCell";
import { money } from "../../../../../../../../../utils/numeral/money";
import { reservationReservedCellComparator } from "../../../../../../reservations/components/content/data-grid/utils/helpers/comparators";
import { DatesCell } from "../../../../../../common/components/data-grid/cells/DatesCell";
import { CommitmentsTableName } from "../../../../../../common/components/data-grid/cells/CommitmentsTableName";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { savingsPlansScopeCellValueGetter } from "../../../../../../utilization-and-coverage/utils/helpers/valueGetters";

export const azureReservedInstancesTableColumnDefs: ColDef<AzureReservation>[] =
  [
    {
      width: 300,
      minWidth: 250,
      field: "provider_unique_id",
      headerName: "Reservation ID",
      suppressKeyboardEvent: () => true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ({ data }: CustomCellRendererProps<AzureReservation>) => (
        <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
          <ProviderUniqueIdCell id={data?.provider_unique_id ?? ""} />
        </Box>
      ),
    },
    {
      width: 150,
      minWidth: 150,
      field: "type",
      headerName: "Type",
      cellRenderer: ({ data }: CustomCellRendererProps<AzureReservation>) => {
        const type = data?.type;

        return (
          <Box
            bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}
          >
            {type ? (commitmentsResourceTypeNames[type] ?? null) : null}
          </Box>
        );
      },
      valueGetter: reservationTypeCellValueGetter,
    },
    {
      minWidth: 150,
      width: 210,
      field: "product_name",
      headerName: "Product Name",
      cellRenderer: ({ data }: CustomCellRendererProps<AzureReservation>) => (
        <TypographyWithTooltip
          variant="inherit"
          title={data?.product_name ?? ""}
          bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}
        />
      ),
    },
    {
      minWidth: 100,
      width: 170,
      field: "instance_count",
      headerName: "Quantity",
      cellRenderer: ({ data }: CustomCellRendererProps<Reservation>) => (
        <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
          {data?.instance_count ?? ""}
        </Box>
      ),
    },
    {
      minWidth: 150,
      width: 170,
      field: "scope",
      headerName: "Scope",
      cellRenderer: ({ data }: CustomCellRendererProps<AzureReservation>) => (
        <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
          {data && <ScopeCell {...data.scope} />}
        </Box>
      ),
      valueGetter: savingsPlansScopeCellValueGetter,
    },
    {
      width: 230,
      minWidth: 230,
      resizable: false,
      field: "used_commitment_to_date.cost",
      headerName: "Utilization",
      headerComponentParams: {
        innerHeaderComponent: () => (
          <HeaderCellWidthDateTooltip title="Utilization " />
        ),
      },
      cellRenderer: ({ data }: CustomCellRendererProps<AzureReservation>) => (
        <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
          {data && <ReservationUtilizationCell reservation={data} />}
        </Box>
      ),
      valueGetter: reservationUtilizationCellValueGetter,
    },
    {
      width: 180,
      minWidth: 150,
      field: "total_commitment_to_date.cost",
      headerName: "Reserved",
      headerComponentParams: {
        innerHeaderComponent: () => (
          <HeaderCellWidthDateTooltip title="Reserved " />
        ),
      },
      cellRenderer: ({ data }: CustomCellRendererProps<AzureReservation>) => (
        <Typography
          variant="body2"
          bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}
        >
          {money(data?.total_commitment_to_date?.cost ?? 0)} (
          {data?.total_commitment_to_date?.units ?? 0}h)
        </Typography>
      ),
      valueGetter: reservationReservedCellValueGetter,
      comparator: reservationReservedCellComparator,
    },
    {
      width: 190,
      minWidth: 150,
      field: "region",
      headerName: "Region/AZ",
      cellRenderer: ({ data }: CustomCellRendererProps<AzureReservation>) => (
        <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
          {data?.region}
        </Box>
      ),
    },
    {
      width: 220,
      minWidth: 150,
      field: "payment_option",
      headerName: "Payment Option",
      cellRenderer: ({ data }: CustomCellRendererProps<AzureReservation>) => (
        <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
          {data?.payment_option
            ? commitmentsPaymentOptionLabels[data.payment_option]
            : ""}
        </Box>
      ),
      valueGetter: reservationPaymentOptionCellValueGetter,
    },
    {
      width: 190,
      minWidth: 150,
      field: "net_savings_to_date",
      headerName: "Net Savings",
      headerComponentParams: {
        innerHeaderComponent: () => (
          <HeaderCellWidthDateTooltip title="Net Savings" />
        ),
      },
      cellRenderer: ({ data }: CustomCellRendererProps<AzureReservation>) => (
        <Typography variant="body2">
          {money(data?.net_savings_to_date ?? 0)}
        </Typography>
      ),
      valueGetter: ({ data }) => data?.net_savings_to_date ?? 0,
    },
    {
      width: 180,
      minWidth: 100,
      field: "start_date",
      headerName: "Start Date",
      cellRenderer: ({ data }: CustomCellRendererProps<AzureReservation>) => (
        <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
          {data && <DatesCell date={data.start_date} />}
        </Box>
      ),
      valueGetter: reservationStartDateCellValueGetter,
    },
    {
      width: 180,
      minWidth: 100,
      field: "end_date",
      headerName: "End Date",
      cellRenderer: ({ data }: CustomCellRendererProps<AzureReservation>) => (
        <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
          {data && <DatesCell date={data.end_date} state={data.state} />}
        </Box>
      ),
      valueGetter: reservationEndDateCellValueGetter,
    },
    {
      sortable: false,
      resizable: false,
      filter: false,
      suppressKeyboardEvent: () => true,
      field: "has_recommendation",
      headerName: "Recommendation",
      cellRenderer: ({ data }: CustomCellRendererProps<AzureReservation>) => (
        <Box bgcolor={data?.state === "expires_soon" ? "#ed6c0216" : undefined}>
          {data?.has_recommendation ? (
            <Button
              size="small"
              variant="outlined"
              sx={{ textTransform: "initial" }}
            >
              <CommitmentsTableName
                id={data.provider_unique_id}
                type="commitment"
                name="Review"
              />
            </Button>
          ) : (
            <Typography variant="body2" color="text.disabled">
              No recommendation
            </Typography>
          )}
        </Box>
      ),
      valueGetter: reservationHasRecommendationCellValueGetter,
    },
    agGridEmptyColumnToFitEmptySpace,
  ];
