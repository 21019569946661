import { FC, Fragment, useCallback } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { HierarchyViewDeleteActionDialog } from "./HierarchyViewDeleteActionDialog";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";

interface HierarchyViewDeleteActionProps {
  onClose(): void;
  viewId: string;
  viewName: string;
  isProtected: boolean;
}

export const HierarchyViewDeleteAction: FC<HierarchyViewDeleteActionProps> = ({
  onClose,
  viewId,
  viewName,
  isProtected,
}) => {
  const { open, openDialog, closeDialog } = useDialog();

  const closeHandler = useCallback(() => {
    closeDialog();
    onClose();
  }, [onClose, closeDialog]);

  return (
    <Fragment>
      <Tooltip
        arrow
        placement="right"
        title={isProtected ? "Protected views cannot be modified." : ""}
      >
        <span>
          <MenuItem disabled={isProtected} onClick={openDialog}>
            <ListItemIcon>
              <DeleteOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">Delete</Typography>
            </ListItemText>
          </MenuItem>
        </span>
      </Tooltip>

      <HierarchyViewDeleteActionDialog
        viewId={viewId}
        open={open}
        onClose={closeHandler}
        viewName={viewName}
      />
    </Fragment>
  );
};
