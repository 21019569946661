import { ValueGetterParams } from "ag-grid-community";
import { CostAnomalyAlertsDataGridDataType } from "../../../../../../../../../store/alerts/utils/types/types";
import { joinThresholds } from "../joinThresholds";

export const costAnomalyAlertsThresholdValueGetter = ({
  data,
}: ValueGetterParams<CostAnomalyAlertsDataGridDataType>) => {
  const thresholds = data?.thresholds;

  if (!thresholds) {
    return;
  }

  const thresholdTitles = joinThresholds(thresholds);

  return `${thresholdTitles[0] ?? ""} ${thresholds.length > 1 ? data?.threshold?.operator : ""} ${thresholdTitles[1] ?? ""}`;
};
