import { taskManagementTargetSelector } from "../common/taskManagementTargetSelector";
import { RootState } from "../../../../../store";
import { tasksListResourceIdsSelector } from "../../../data/tasksListResourceIdsSelector";
import { TaskTargetResource } from "../../../../utils/types/types";

export const taskManagementAlreadyHasTaskResourcesIdsSelector = (
  state: RootState,
): string[] | undefined => {
  const target = taskManagementTargetSelector(state) as TaskTargetResource;
  const resourceIdsInTasks = tasksListResourceIdsSelector(state);

  return target?.resources
    ?.filter((resource) => resourceIdsInTasks[resource.provider_identifier])
    ?.map((resource) => resource.provider_identifier);
};
