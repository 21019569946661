import { createAsyncThunk } from "@reduxjs/toolkit";
import { getKubernetesLiveUsageMgmtAllResourceTypesDataThunk } from "./resources/getKubernetesLiveUsageMgmtAllResourceTypesDataThunk";
import { setKubernetesResourcesDefaultFilterThunk } from "./resources/setKubernetesResourcesDefaultFilterThunk";
import { AccountId } from "../../../services/cloudchipr.api";
import { RootState } from "../../store";
import { generateAccountIdsFromSearchParams } from "../../../components/pages/live-usage-mgmt/utils/helpers/generateAccountIdsFromSearchParams";
import { kubernetesLiveUsagePageStateSelector } from "../selectors/store-selectors/kubernetesLiveUsagePageStateSelector";
import {
  resetKubernetesLiveUsageMgmtSlice,
  setKubernetesLiveUsageMgmtClusterIds,
  setKubernetesLiveUsageMgmtPageState,
} from "../kubernetesLiveUsageMgmtSlice";

interface InitiateKubernetesLiveUsageMgmtThunkArgs {
  clusterIds: AccountId | null;
}

export const initiateKubernetesLiveUsageMgmtThunk = createAsyncThunk(
  "liveUsageMgmt/initiateKubernetesLiveUsageMgmt",
  async (
    { clusterIds }: InitiateKubernetesLiveUsageMgmtThunkArgs,
    { dispatch, getState },
  ) => {
    dispatch(resetKubernetesLiveUsageMgmtSlice());

    const state = getState() as RootState;

    const pageState = kubernetesLiveUsagePageStateSelector(state);

    if (pageState === "fulfilled") {
      return;
    }

    dispatch(setKubernetesLiveUsageMgmtPageState("pending"));
    if (!clusterIds) {
      return;
    }

    dispatch(setKubernetesResourcesDefaultFilterThunk());
    dispatch(
      setKubernetesLiveUsageMgmtClusterIds(
        generateAccountIdsFromSearchParams(clusterIds),
      ),
    );

    await dispatch(getKubernetesLiveUsageMgmtAllResourceTypesDataThunk());

    dispatch(setKubernetesLiveUsageMgmtPageState("fulfilled"));
  },
);
