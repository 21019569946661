import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { v4 as uuid } from "uuid";
import { reservedInstanceDrawerRecommendationsGridColumnDefs } from "./reservedInstanceDrawerRecommendationsGridColumnDefs";
import { useGetUsersMeOrganisationsCurrentReservationsUtilizationRecommendationsQuery } from "../../../../../../../../services/cloudchipr.api";
import { CommitmentsDrawerContent } from "../../../../../common/components/drawer/CommitmentsDrawerContent";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { selectedReservedInstancePlanUUIdSelector } from "../../../../../../../../store/commitments/selectors/reservations/details-drawer/reserved-instance/selectedReservedInstancePlanUUIdSelector";
import { CommitmentsAgGrid } from "../../../../../common/components/CommitmentsAgGrid";

export const ReservedInstanceDrawerRecommendationsSection: FC = () => {
  const reservationId = useAppSelector(
    selectedReservedInstancePlanUUIdSelector,
  );

  const { data } =
    useGetUsersMeOrganisationsCurrentReservationsUtilizationRecommendationsQuery(
      { reservationId: reservationId ?? "" },
      {
        skip: !reservationId,
        selectFromResult: ({ data }) => {
          return {
            data: data?.map((recommendation) => ({
              ...recommendation,
              id: uuid(),
            })),
          };
        },
      },
    );

  return (
    <CommitmentsDrawerContent title="Recommendations" icon="recommendation">
      {!data?.length ? (
        <Stack alignItems="center" p={3}>
          <Typography
            variant="subtitle1"
            fontWeight="medium"
            color="text.secondary"
          >
            No Recommendations
          </Typography>
        </Stack>
      ) : (
        <CommitmentsAgGrid
          columnDefs={reservedInstanceDrawerRecommendationsGridColumnDefs}
          data={data}
          localStorageUniqueKey="ReservedInstanceDrawerRecommendationsSection"
        />
      )}
    </CommitmentsDrawerContent>
  );
};
