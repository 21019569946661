import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApiAccountService } from "../../../../services/cloudchipr-account-service.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getResourceExplorerInternalPossibleGroupingsThunk =
  createAsyncThunk(
    "resourceExplorer/getResourceExplorerInternalPossibleGroupings",
    async (organisationId: string, { dispatch, getState }) => {
      const { getResourceExplorerPossibleGroupingsFromAccountService } =
        cloudChiprApiAccountService.endpoints;

      try {
        dispatch(
          getResourceExplorerPossibleGroupingsFromAccountService.initiate({
            organisationId,
          }),
        );
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);

        return null;
      }
    },
  );
