import { billingExplorerWidgetVisualizationSelector } from "./billingExplorerWidgetVisualizationSelector";
import { billingExplorerWidgetDataByWidgetIdSelector } from "./billingExplorerWidgetDataByWidgetIdSelector";
import { billingExplorerWidgetFrequencySelector } from "./billingExplorerWidgetFrequencySelector";
import { RootState } from "../../../../../store";
import { ChartDataType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { convertCostToPercent } from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/convertCostToPercent";
import { organisationQuarterStartMonthSelector } from "../../../../../common/selectors/org-current-settings/organisationQuarterStartMonthSelector";

export const billingExplorerWidgetChartDataSelector = (
  state: RootState,
  widgetId: string,
): ChartDataType[] | [] => {
  const visualization = billingExplorerWidgetVisualizationSelector(
    state,
    widgetId,
  );
  const data = billingExplorerWidgetDataByWidgetIdSelector(
    state,
    widgetId,
  )?.data;

  const frequency = billingExplorerWidgetFrequencySelector(state, widgetId);

  const quarterStartMonth = organisationQuarterStartMonthSelector(state);

  if (!data || !frequency) {
    return [];
  }

  const formatedData = formatResourceExplorerChartData(
    data,
    frequency,
    quarterStartMonth,
  );

  if (visualization?.chart_base_type === "cost" || !formatedData) {
    return formatedData;
  }

  return convertCostToPercent(formatedData);
};
