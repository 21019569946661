import { FC, useCallback } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { useAppAbility } from "../../../../../../../../../services/permissions";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { setActiveIntegration } from "../../../../../../../../../store/integrations/integrationsSlice";
import { useAppDispatch } from "../../../../../../../../../store/hooks";
import { CommunicationIntegrationsGridRowType } from "../../../utils/types/common";

export const IntegrationsNameCellRenderer: FC<
  ICellRendererParams<CommunicationIntegrationsGridRowType>
> = ({ data }) => {
  const { cannot } = useAppAbility();
  const canNotEditIntegration = cannot("edit", "integration");
  const dispatch = useAppDispatch();

  const openEditIntegrationDrawer = useCallback(() => {
    dispatch(
      setActiveIntegration({ type: data?.type ?? null, id: data?.id ?? null }),
    );
  }, [dispatch, data?.type, data?.id]);

  if (!data) {
    return;
  }

  if (canNotEditIntegration) {
    return <TypographyWithTooltip title={data?.name} variant="body2" />;
  }

  return (
    <TypographyWithTooltip
      fontWeight="regular"
      color="primary"
      display="block"
      width="fit-content"
      onClick={openEditIntegrationDrawer}
      sx={{ textDecoration: "underline", cursor: "pointer" }}
      variant="body2"
      title={data.name}
    />
  );
};
