import { organizationsForCommitmentsSelector } from "./organizationsForCommitmentsSelector";
import { ProviderOrganisationsGroupedByProvider } from "./utils/types";
import { RootState } from "../../../store";

export const providerOrganisationsGroupedByProviderSelector = (
  state: RootState,
): ProviderOrganisationsGroupedByProvider | null => {
  const data = organizationsForCommitmentsSelector(state);

  if (!data) {
    return null;
  }

  return data.reduce((acc, currentValue) => {
    if (acc?.[currentValue.cloud_provider]) {
      acc[currentValue.cloud_provider] = [
        ...(acc[currentValue.cloud_provider] ?? []),
        currentValue,
      ];
    } else {
      acc[currentValue.cloud_provider] = [currentValue];
    }

    return acc;
  }, {} as ProviderOrganisationsGroupedByProvider);
};
