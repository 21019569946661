import { RootState } from "../../../../../store";
import { taskManagementAlreadyHasTaskResourcesIdsSelector } from "../resources/taskManagementAlreadyHasTaskResourcesIdsSelector";
import { taskManagementAlreadyHasTaskOpportunitiesIdsSelector } from "../opportunities/taskManagementAlreadyHasTaskOpportunitiesIdsSelector";

export const taskManagementTargetAlreadyHasTaskIdsSelector = (
  state: RootState,
) => {
  const resources =
    taskManagementAlreadyHasTaskResourcesIdsSelector(state) ?? [];
  const opportunities =
    taskManagementAlreadyHasTaskOpportunitiesIdsSelector(state) ?? [];

  return [...resources, ...opportunities];
};
