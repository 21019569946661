import { textWidgetSetupSelector } from "./textWidgetSetupSelector";
import { RootState } from "../../../../store";
import { textWidgetEditChangesExistSelector } from "../../widgets/text-widget/setup/textWidgetEditChangesExistSelector";

export const textWidgetSubmitButtonDisabledSelector = (state: RootState) => {
  const setup = textWidgetSetupSelector(state);
  const editDisabledByChange = textWidgetEditChangesExistSelector(state);
  const { text, name, id } = setup ?? {};

  return (id && !editDisabledByChange) || !text || !name;
};
