import { billingSummaryWidgetByIdSelector } from "./billingSummaryWidgetByIdSelector";
import { RootState } from "../../../../store";
import { buildingInProgressWidgetIdWithNoFilter } from "../../../thunks/widgets/utils/constants";

export const billingSummaryWidgetFiltersByIdSelector = (
  state: RootState,
  widgetId?: string,
): string[] => {
  const data = billingSummaryWidgetByIdSelector(
    state,
    widgetId ?? buildingInProgressWidgetIdWithNoFilter,
  );

  if (!data) {
    return [];
  }
  return data?.filter ?? [];
};
