import { billingExplorerWidgetSetupDataSelector } from "./billingExplorerWidgetSetupDataSelector";
import { RootState } from "../../../../../store";
import { ChartDataType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { billingExplorerSetupPropertyByKeySelector } from "../../../setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { convertCostToPercent } from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/convertCostToPercent";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";
import { organisationQuarterStartMonthSelector } from "../../../../../common/selectors/org-current-settings/organisationQuarterStartMonthSelector";

export const billingExplorerWidgetSetupChartDataSelector = (
  state: RootState,
): ChartDataType[] | [] => {
  const base =
    billingExplorerSetupVisualizationPropertyByKeySelector("chart_base_type")(
      state,
    );
  const data = billingExplorerWidgetSetupDataSelector(state)?.data;
  const frequency =
    billingExplorerSetupPropertyByKeySelector("frequency")(state);
  const quarterStartMonth = organisationQuarterStartMonthSelector(state);

  if (!data) {
    return [];
  }

  const formatedData = formatResourceExplorerChartData(
    data,
    frequency,
    quarterStartMonth,
  );

  if (base === "cost" || !formatedData) {
    return formatedData;
  }

  return convertCostToPercent(formatedData);
};
