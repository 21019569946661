import { FC } from "react";
import { useLocation } from "react-router-dom";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";
import { AutomationsNavigationItem } from "./AutomationsNavigationItem";
import { AppNavItemWrapper } from "../../app-nav-components/AppNavItemWrapper";
import { navigationCollapsedSelector } from "../../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { useAppSelector } from "../../../../../store/hooks";

export const AutomationsNavigation: FC = () => {
  const location = useLocation();

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);
  const selected = location.pathname.startsWith("/automations");

  return (
    <AppNavItemWrapper
      primary="Automations"
      to="/automations/workflow"
      Icon={AvTimerOutlinedIcon}
      selected={selected}
      navCollapsed={navigationCollapsed}
    >
      <AutomationsNavigationItem type="workflow" />
      <AutomationsNavigationItem type="off-hours" />
    </AppNavItemWrapper>
  );
};
