import { oneDayInSeconds } from "../constants/constants";

export const formatSecondsToFormattedDate = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(seconds / 3600);
  const days = Math.floor(seconds / oneDayInSeconds);
  const months = Math.floor(seconds / (oneDayInSeconds * 30));

  if (seconds < 3600) {
    return `${format(minutes)}min`;
  } else if (seconds < oneDayInSeconds) {
    return `${format(hours)}h`;
  } else if (seconds < oneDayInSeconds * 30) {
    const remainingHours = hours - days * 24;
    const remainingPart = remainingHours ? `${format(remainingHours)}h ` : "";

    return `${format(days)}d ${remainingPart}`;
  } else {
    const remainingDays = days - months * 30;
    const remainingPart = remainingDays ? `${format(remainingDays)}d ` : "";

    return `${format(months)}mo ${remainingPart}`;
  }
};

export const formatSecondsToDays = (seconds: number): string => {
  return `${format(Math.floor(seconds / oneDayInSeconds))}d`;
};

const format = (value: number) => value.toFixed(2).replace(/\.00$/, "");
