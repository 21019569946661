import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { YAxisPlotLinesOptions } from "highcharts";
import { resourceExplorerViewBudgetSelector } from "./resourceExplorerViewBudgetSelector";
import { resourceExplorerIdSelector } from "../current-resource-explorer/resourceExplorerIdSelector";
import { ReferenceLineType } from "../../../../storybook/charts/multi-type-chart/utils/types/types";
import { resourceExplorerDataPointSelector } from "../current-resource-explorer/data/resourceExplorerDataPointSelector";
import { generateBudgetDataOnChart } from "../../utils/helpers/generateBudgetDataOnChart";
import { generateBudgetDataOnHighChart } from "../../utils/helpers/generateBudgetDataOnHighChart";

export const budgetDataOnChartSelector = createDraftSafeSelector(
  [
    resourceExplorerIdSelector,
    resourceExplorerDataPointSelector,
    resourceExplorerViewBudgetSelector,
  ],
  (viewId, viewPeriod, budget): ReferenceLineType[] | null => {
    if (!viewId) {
      return null;
    }
    if (!budget) {
      return null;
    }

    return generateBudgetDataOnChart(viewPeriod, budget);
  },
);

export const budgetDataOnHighChartSelector = createDraftSafeSelector(
  [
    resourceExplorerIdSelector,
    resourceExplorerDataPointSelector,
    resourceExplorerViewBudgetSelector,
  ],
  (viewId, viewPeriod, budget): YAxisPlotLinesOptions[] | null => {
    if (!viewId) {
      return null;
    }
    if (!budget) {
      return null;
    }

    return generateBudgetDataOnHighChart(viewPeriod, budget);
  },
);
