import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../store/hooks";
import { savingsPlanSelectedCoverageDataSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/resource-type/savingsPlanSelectedCoverageDataSelector";
import { commitmentsResourceTypeNames } from "../../../../common/utils/constants/labels";
import { CommitmentResourceType } from "../../../../../../../services/cloudchipr.api";

import { KeyValueSplitChip } from "../../../../../../common/chips/KeyValueSplitChip";

export const SavingsPlanCoverageDrawerContentHeaderInfoCards: FC = () => {
  const coverageData = useAppSelector(savingsPlanSelectedCoverageDataSelector);

  const data = useMemo(() => {
    const resourceType = coverageData?.resource_type
      ?.resource_type as CommitmentResourceType;

    return {
      "Resource Type": resourceType
        ? commitmentsResourceTypeNames[resourceType]
        : "",
      "Instance Family": coverageData?.resource_type?.instance_family,
      Region: coverageData?.resource_type?.region,
    };
  }, [coverageData]);

  if (!data) {
    return null;
  }

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {Object.entries(data).map(([title, value]) => {
        return <KeyValueSplitChip key={title} title={title} value={value} />;
      })}
    </Stack>
  );
};
