import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { kubernetesClustersSelector } from "./kubernetesClustersSelector";
import { accountsFilterValueSelector } from "../slice-data/accountsFilterValueSelector";
import { accountsSortBySelector } from "../slice-data/accountsSortBySelector";
import { AccountsPageTabs } from "../../../../components/pages/accounts-group/utils/types/types";
import { KubernetesCluster } from "../../../../services/cloudchipr.api";
import { kubernetesClustersSortingCombiner } from "../../combiners/kubernetes/kubernetesClustersSortingCombiner";
import { KubernetesClusterSortByFields } from "../../accountsSlice";

export const filteredClustersConvertedForClustersPageByOrgIdSelector =
  createDraftSafeSelector(
    [
      kubernetesClustersSelector,
      accountsFilterValueSelector,
      accountsSortBySelector,
    ],
    (
      clusters,
      filterValue,
      sortBy,
    ): Record<AccountsPageTabs, KubernetesCluster[]> | null => {
      if (!clusters) {
        return null;
      }

      const searchValue = filterValue.toLowerCase();

      const filteredActiveAccounts = clusters.filter(
        (cluster) =>
          cluster.cluster_id?.toLowerCase()?.includes(searchValue) ||
          cluster.region?.toLowerCase()?.includes(searchValue) ||
          cluster.name?.toLowerCase()?.includes(searchValue),
      );

      const sortedActiveAccounts =
        kubernetesClustersSortingCombiner(
          filteredActiveAccounts,
          sortBy as KubernetesClusterSortByFields | "custom",
        ) ?? [];

      return {
        active: sortedActiveAccounts,
        inactive: [],
      };
    },
  );
