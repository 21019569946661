import { Integration } from "../../../../../../../../services/cloudchipr.api";
import { integrationDetails } from "../constants/integrationDetails";
import { IntegrationItem } from "../types/common";
import { IntegrationType } from "../../../../../../../../store/integrations/integrationsSlice";

export const getConnectionCounts = (
  integrations?: Integration[],
): IntegrationItem[] => {
  const connectionCounts = Object.entries(integrationDetails).reduce(
    (acc: Record<string, number>, [type, _]) => {
      acc[type] = 0;

      return acc;
    },
    {},
  );

  integrations?.forEach((integration: Integration) => {
    connectionCounts[integration.type]++;
  });

  return Object.entries(connectionCounts).map(([key, value]) => {
    const type = key as IntegrationType;
    const connections = value as number;

    return {
      type,
      connections,
    };
  });
};
