import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { CommentEditAction } from "./comment-actions/CommentEditAction";
import { CommentDeleteAction } from "./comment-actions/CommentDeleteAction";
import { CommentUser } from "./CommentUser";
import { EmbeddedUser } from "../../../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { currentUserIdSelector } from "../../../../../../../../store/profile/currentUserIdSelector";

interface CommentContentProps {
  hovered: boolean;
  onEditClick(): void;
  commentId: string;
  taskId: string;
  createdAt: string;
  comment: string;
  createdBy: EmbeddedUser;
}

export const CommentContent: FC<CommentContentProps> = ({
  hovered,
  commentId,
  createdBy,
  createdAt,
  taskId,
  onEditClick,
  comment,
}) => {
  const { open, openDialog, closeDialog } = useDialog();
  const currentUserId = useAppSelector(currentUserIdSelector);
  const editable = createdBy.id === currentUserId;

  return (
    <Stack
      p={1}
      border={1}
      spacing={0.5}
      borderRadius={1}
      borderColor="grey.300"
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <CommentUser user={createdBy} />
        {(hovered || open) && editable ? (
          <Stack direction="row" spacing={0.25} alignItems="center">
            <CommentEditAction onClick={onEditClick} />
            <CommentDeleteAction
              commentId={commentId}
              taskId={taskId}
              open={open}
              openDialog={openDialog}
              closeDialog={closeDialog}
            />
          </Stack>
        ) : (
          <Typography variant="caption" color="text.secondary">
            {createdAt}
          </Typography>
        )}
      </Stack>

      <Box
        dangerouslySetInnerHTML={{ __html: comment ?? "" }}
        sx={commentStyles}
      />
    </Stack>
  );
};

const commentStyles = {
  "& p": {
    wordWrap: "break-word",
    padding: 0,
    margin: 0,
    fontSize: 12,
    color: "text.secondary",
  },
  fontSize: 12,
  color: "text.secondary",
};
