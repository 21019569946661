import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBillingSummaryWidgetDataBySetupThunk } from "./data-get/getBillingSummaryWidgetDataBySetupThunk";
import { RootState } from "../../../../store";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { resourceExplorerViewsDataSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsDataSelector";
import { billingSummarySetupDefaultData } from "../../../utils/constants/billingSummarySetupDefaultData";

export const initiateBillingSummaryWidgetThunk = createAsyncThunk(
  "dashboards/initiateBillingSummaryWidget",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const views = resourceExplorerViewsDataSelector(state);

    const defaultViewId = views?.find((view) => view.is_default)?.id;

    if (!defaultViewId) {
      dispatch(setWidgetSetup(billingSummarySetupDefaultData));
      return;
    }

    dispatch(
      setWidgetSetup({
        ...billingSummarySetupDefaultData,
        viewId: defaultViewId,
      }),
    );

    dispatch(getBillingSummaryWidgetDataBySetupThunk());
  },
);
