import { createAsyncThunk } from "@reduxjs/toolkit";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { ChartType } from "../../../../../../services/cloudchipr.api";
import { billingExplorerWidgetSetupSelector } from "../../../../selectors/setups/billing-explorer/billingExplorerWidgetSetupSelector";
import { billingExplorerWidgetSetupDefaultData } from "../../../../utils/constants/billingExplorerWidgetSetupDefaultData";
import { getBillingExplorerWidgetSetupDataThunk } from "../getBillingExplorerWidgetSetupDataThunk";

export const billingExplorerViewTypeChangeThunk = createAsyncThunk(
  "dashboards/billingExplorerWidgetViewTypeChange",
  async (viewType: ChartType, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = billingExplorerWidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }
    dispatch(
      setWidgetSetup({
        ...existingSetup,
        //TODO: EnableWidgetsSideBarConfigurations, remove viewType
        viewType,
        visualization: {
          ...billingExplorerWidgetSetupDefaultData.visualization,
          //TODO change chart_type and set other settings to default
          chart_type: viewType,
        },
        frequency: viewType === "pie" ? "monthly" : existingSetup.frequency,
      }),
    );
    dispatch(getBillingExplorerWidgetSetupDataThunk());
  },
);
