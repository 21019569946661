import { azureOverviewDataSelector } from "./azureOverviewDataSelector";
import { RootState } from "../../../../store";

export const azureOverviewTotalNotCoveredSelector = (
  state: RootState,
): number | null => {
  const data = azureOverviewDataSelector(state);

  return data?.total_not_covered ?? null;
};
