import { blue, purple, teal } from "@mui/material/colors";
import { MetricKey, MetricPeriods } from "../types/metrics";

export const colorsByMetricKey: Record<MetricKey, string> = {
  cpu: blue["300"],
  database_connections: blue["300"],
  mem_available: purple["300"],
  mem_used: purple["300"],
  network_in: teal["300"],
  network_out: teal["300"],
  requests: blue["300"],
};

export const metricKeyLabels: Record<MetricKey, string> = {
  cpu: "CPU Utilization",
  database_connections: "DB Connections",
  mem_available: "Available Memory",
  mem_used: "Memory Utilization",
  network_in: "Network In",
  network_out: "Network Out",
  requests: "All Requests",
};

export const metricsChartsPeriods: Record<
  MetricPeriods,
  { value: number; label: string; frequency: "hourly" | "daily" }
> = {
  "24h": {
    value: 24,
    label: "24 hours",
    frequency: "hourly",
  },
  "7d": {
    value: 7,
    label: "7 days",
    frequency: "daily",
  },
  "30d": {
    value: 30,
    label: "30 days",
    frequency: "daily",
  },
};
