import { billingSummaryWidgetByIdSelector } from "./billingSummaryWidgetByIdSelector";
import { RootState } from "../../../../store";
import { billingSummaryWidgetSetupSelector } from "../../setups/billing-summary/billingSummaryWidgetSetupSelector";
import { sortAlphabeticallyCallback } from "../../../../../utils/helpers/sorting";

export const billingSummaryWidgetEditChangesExistSelector = (
  state: RootState,
) => {
  const setup = billingSummaryWidgetSetupSelector(state);
  if (!setup?.id) {
    return false;
  }

  const widget = billingSummaryWidgetByIdSelector(state, setup?.id);

  if (!widget) {
    return false;
  }

  const setupFilters = setup?.filter ? [...setup.filter] : [];
  const widgetFilter = widget?.filter ? [...widget.filter] : [];

  return (
    !setupFilters.length ||
    setup.name !== widget.name ||
    setup.viewId !== widget.view_id ||
    setup.dates.from !== widget.dates.from ||
    setup.dates.to !== widget.dates.to ||
    setup.dates.label !== widget.dates.label ||
    setup.dates.value !== widget.dates.value ||
    setup.dates.type !== widget.dates.type ||
    setupFilters.toSorted(sortAlphabeticallyCallback)?.toString() !==
      widgetFilter.toSorted(sortAlphabeticallyCallback)?.toString()
  );
};
