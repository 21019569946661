import { FC, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import { EmbeddedUser } from "../../../../../../../../services/cloudchipr.api";
import { UserAvatar } from "../../../../../../../common/avatars/UserAvatar";

interface CommentUserProps {
  user: EmbeddedUser;
  additionalText?: ReactNode;
}

export const CommentUser: FC<CommentUserProps> = ({ user, additionalText }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={0.75}>
      <UserAvatar profile={user.profile} sx={avatarStyles} />
      <Typography variant="body2">
        {user.name ?? user.email}{" "}
        {additionalText && (
          <Typography component="span" variant="inherit" color="text.secondary">
            {additionalText}
          </Typography>
        )}
      </Typography>
    </Stack>
  );
};

const avatarStyles = { width: 24, height: 24, alignSelf: "flex-start" };
