import { grey } from "@mui/material/colors";

const iconsStyles = {
  "span:has(> svg[data-type='ag-overwritten-icon'])": {
    lineHeight: 0,
    minWidth: 24,
    minHeight: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".ag-menu-option-icon:has(> svg[data-type='ag-overwritten-icon'])": {
    padding: 0,
    margin: "8px 0 0 16px",
  },
};

const columnSortIconStyles = {
  ".ag-sort-indicator-icon": {
    visibility: "hidden",
    padding: "4px",
    height: "24px !important",
    width: "24px !important",
    borderRadius: "50%",
    margin: "0 4px",
    "&:hover": { background: grey[300] },
  },
  ".ag-header-cell-sortable.ag-header-active:hover .ag-sort-indicator-icon": {
    visibility: "visible",
  },

  ".ag-header-cell-sorted-desc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },
  ".ag-header-cell-sorted-asc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },

  '.ag-header-cell[aria-sort="ascending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },
  '.ag-header-cell[aria-sort="descending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },

  ".ag-sort-indicator-container .ag-sort-order": {
    display: "none",
  },
};

const advancedFiltersFieldsStyles = {
  ".ag-advanced-filter-builder-column-pill,.ag-advanced-filter-builder-option-pill,.ag-advanced-filter-builder-value-pill,.ag-advanced-filter-builder-join-pill ":
    {
      backgroundColor: "white !important",
      border: "1px solid black",
    },
};

const resizeHandleStyles = {
  ".ag-header-cell-resize": { width: 20, visibility: "hidden" },
  ".ag-header-cell:hover .ag-header-cell-resize": { visibility: "visible" },
  "--ag-header-column-resize-handle-height": "80%",
};

const groupItemsCountStyle = {
  ".ag-group-child-count": {
    display: "flex",
    flex: 1,
    justifyContent: "end",
  },
};

const rowGroupingStyles = {
  ...groupItemsCountStyle,
};

const expanderStyles = {
  ".ag-group-expanded,.ag-group-contracted": {
    padding: "4px",
    overflow: "hidden",
    borderRadius: "50%",
    height: "24px !important",
    width: "24px !important",
    "&:hover": { background: grey[300] },
  },
};

const tableBodyStyles = {
  ".ag-center-cols-viewport": {
    minHeight: "unset !important",
  },
};

export const agGridWrapperStyles = {
  ".ag-cell-wrapper": { height: "100%", alignItems: "center" },
  ".ag-root-wrapper": { borderRadius: 0, border: "none" },
  "--ag-header-background-color": grey[100],
  "--ag-row-hover-color": grey[50],
  ".ag-row-last": { borderBottom: `1px solid ${grey[300]}` },

  ...iconsStyles,
  ...expanderStyles,
  ...columnSortIconStyles,
  ...resizeHandleStyles,
  ...advancedFiltersFieldsStyles,
  ...rowGroupingStyles,
  ...tableBodyStyles,
};
