import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";

export const possibleEfforts: SavingsOpportunity["implementation_effort"][] = [
  "very_low",
  "low",
  "medium",
  "high",
  "very_high",
];

export const possibleEffortsLabels: Record<
  SavingsOpportunity["implementation_effort"],
  string
> = {
  very_low: "Very Low",
  low: "Low",
  medium: "Medium",
  high: "High",
  very_high: "Very High",
};

export const opportunityTypeTitlesByActionType: Record<
  SavingsOpportunity["action_type"],
  string
> = {
  off_hours: "Create Off Hours",
  live_filtered_resource: "Underutilized",
  rightsizing: "Rightsizing",
  upgrade: "Upgrade",
  migrate_to_graviton: "Migrate To Graviton",
  stop: "Stop",
  delete: "Delete",
};

export const savingsOpportunitiesPathName =
  "/recommendations/savings-opportunities";
