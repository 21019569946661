import { kubernetesLiveUsageMgmtPostUsersMeOrganisationsCurrentKubernetesByTypeResources } from "./kubernetesLiveUsageMgmtPostUsersMeOrganisationsCurrentKubernetesByTypeResources";
import { RootState } from "../../../store";
import { PostUsersMeOrganisationsCurrentKubernetesByTypeResourcesApiResponse } from "../../../../services/cloudchipr.api";

export const kubernetesLiveUsageMgmtResourceTypeDataSelector = (
  state: RootState,
  fixedCacheKey: string,
):
  | PostUsersMeOrganisationsCurrentKubernetesByTypeResourcesApiResponse
  | undefined => {
  return kubernetesLiveUsageMgmtPostUsersMeOrganisationsCurrentKubernetesByTypeResources(
    fixedCacheKey,
  )(state)?.data;
};
