import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { allAccountsGroupedByProviderAndOrgSelector } from "./allAccountsGroupedByProviderAndOrgSelector";
import { generateLiveResourcesPath } from "../../../../components/pages/accounts-group/utils/helpers/generateLiveResourcesPath";
import { ProviderType } from "../../../../services/cloudchipr.api";

export const liveUsageFirstOrgAllAccountsPathSelector = createDraftSafeSelector(
  [allAccountsGroupedByProviderAndOrgSelector],
  (accounts) => {
    let path = "";

    Object.entries(accounts ?? {}).forEach(([provider, orgs]) => {
      if (path) return;

      Object.values(orgs).forEach((accounts) => {
        if (path) return;
        const connectedAccounts = accounts.filter(
          (account) => account.status === "connected",
        );

        if (connectedAccounts.length) {
          const ids = connectedAccounts.map((account) => account.id);

          path = generateLiveResourcesPath({
            provider: provider as ProviderType,
            activeTab: "live-resources",
            accountIds: ids.join(","),
          });
        }
      });
    });

    return path;
  },
);
