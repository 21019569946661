import { billingExplorerExplainWithAiDataSelector } from "./billingExplorerExplainWithAiDataSelector";
import { getBillingExplorerSummaryFixedCashKeySelector } from "../../resource-explorer-summary/getBillingExplorerSummaryFixedCashKeySelector";
import { RootState } from "../../../../store";

export const billingExplorerExplainWithAiNewDataNeededSelector = (
  state: RootState,
): boolean => {
  const existingData = billingExplorerExplainWithAiDataSelector(state);
  const newKey = getBillingExplorerSummaryFixedCashKeySelector(state) ?? "";

  return (
    !!newKey && !!existingData.at(0)?.key && newKey !== existingData.at(0)?.key
  );
};
