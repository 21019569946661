import { FC, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffectOnceWhen } from "rooks";
import { WidgetType } from "../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { widgetSetupSelector } from "../../../../../../../../store/dashboards/selectors/setups/widgetSetupSelector";
import { setWidgetSetup } from "../../../../../../../../store/dashboards/dashboardsSlice";
import { widgetDataLoadingSelector } from "../../../../../../../../store/dashboards/selectors/setups/common/widgetDataLoadingSelector";
import { widgetEditChangesExistSelector } from "../../../../../../../../store/dashboards/selectors/setups/common/widgetEditChangesExistSelector";
import { buildWidgetByTypeThunk } from "../../../../../../../../store/dashboards/thunks/widgets/common/buildWidgetByTypeThunk";
import { buildWidgetLoadingSelector } from "../../../../../../../../store/dashboards/selectors/widgets/common/buildWidgetLoadingSelector";

interface WidgetCardHeaderEditPopoverActionsProps {
  widgetType: WidgetType;
  onClose(): void;
}

export const WidgetCardHeaderEditPopoverActions: FC<
  WidgetCardHeaderEditPopoverActionsProps
> = ({ widgetType, onClose }) => {
  const dispatch = useAppDispatch();
  const widgetSetup = useAppSelector(widgetSetupSelector);
  const loading = useAppSelector(widgetDataLoadingSelector);
  const buildWidgetLoading = useAppSelector(buildWidgetLoadingSelector);
  const editChangesExist = useAppSelector((state) =>
    widgetEditChangesExistSelector(state, widgetType),
  );

  const moreClickHandler = useCallback(() => {
    if (!widgetSetup) {
      return;
    }

    onClose();
    dispatch(setWidgetSetup({ ...widgetSetup, widgetType }));
  }, [widgetSetup, widgetType, dispatch, onClose]);

  const cancelHandler = useCallback(() => {
    onClose();
    dispatch(setWidgetSetup(null));
  }, [onClose, dispatch]);

  const applyHandler = useCallback(async () => {
    await dispatch(buildWidgetByTypeThunk(widgetType));

    cancelHandler();
  }, [dispatch, cancelHandler, widgetType]);

  useEffectOnceWhen(() => {
    onClose();
  }, !widgetSetup);

  return (
    <Stack direction="row" justifyContent="space-between" pt={2}>
      <Button
        variant="text"
        size="small"
        onClick={moreClickHandler}
        sx={textTransformNone}
      >
        More Options
      </Button>

      <Stack spacing={2} direction="row">
        <Button
          variant="text"
          size="small"
          onClick={cancelHandler}
          sx={textTransformNone}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          size="small"
          onClick={applyHandler}
          loading={buildWidgetLoading}
          disabled={loading || !editChangesExist}
          sx={textTransformNone}
        >
          Apply
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

const textTransformNone = { textTransform: "none" };
