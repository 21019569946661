import { FC } from "react";
import { Stack, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAppSelector } from "../../../../../store/hooks";
import { savingsOpportunitiesAggregatedDataSelector } from "../../../../../store/savings-opportunities/selectors/savingsOpportunitiesAggregatedDataSelector";
import { ColumnsInnerHeaderComponent } from "../../../common/ag-grid/components/ColumnsInnerHeaderComponent";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { money } from "../../../../../utils/numeral/money";
import type { CustomHeaderProps } from "ag-grid-react";

interface SavingsOpportunitiesInnerHeader extends CustomHeaderProps {
  moneyFormatting?: boolean;
  infoIconTooltipText?: string;
}

const SavingsOpportunitiesInnerHeader: FC<SavingsOpportunitiesInnerHeader> = ({
  moneyFormatting,
  infoIconTooltipText,
  ...props
}) => {
  const aggregatedDataCounts = useAppSelector(
    savingsOpportunitiesAggregatedDataSelector,
  );

  // @ts-expect-error: Wrong types
  const count = aggregatedDataCounts?.[props.column?.colId ?? ""];

  if (!count) {
    return <ColumnsInnerHeaderComponent {...props} />;
  }

  if (infoIconTooltipText) {
    return (
      <Stack
        position="relative"
        direction="row"
        flex={1}
        spacing={1}
        alignItems="center"
      >
        <Stack>
          <ColumnsInnerHeaderComponent {...props} />

          <TypographyWithTooltip
            title={moneyFormatting ? money(count) : count}
            variant="caption"
            fontSize={10}
          />
        </Stack>

        <Tooltip title={infoIconTooltipText} placement="top" arrow>
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Stack position="relative">
      <ColumnsInnerHeaderComponent {...props} />

      <TypographyWithTooltip
        title={moneyFormatting ? money(count) : count}
        variant="caption"
        fontSize={10}
      />
    </Stack>
  );
};

export const getSavingsOpportunitiesInnerHeader = (
  moneyFormatting?: boolean,
  infoIconTooltipText?: string,
) => {
  return (props: CustomHeaderProps) => (
    <SavingsOpportunitiesInnerHeader
      moneyFormatting={moneyFormatting}
      infoIconTooltipText={infoIconTooltipText}
      {...props}
    />
  );
};
