import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { azureReservationsDataForTableSelector } from "./azureReservationsDataForTableSelector";
import { AzureReservationsCoverageDataWithId } from "../../../../../../components/pages/commtiments/common/utils/types";
import { addIdInCoverageData } from "../../../../utils/helpers/addIdInCoverageData";
import { AzureCommitmentCoverageData } from "../../../../../../services/cloudchipr.api";

export const azureReservationsCoverageDataSelector = createDraftSafeSelector(
  [azureReservationsDataForTableSelector],
  (data): AzureReservationsCoverageDataWithId[] | undefined => {
    return addIdInCoverageData(
      (data?.utilization_and_coverage
        ?.coverage_data as AzureCommitmentCoverageData[]) ?? [],
    );
  },
);
