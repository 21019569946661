import { Stack, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import EmptyStateImage from "../../../../../../assets/images/empty_integrations.png";

interface IntegrationsEmptyStateProps {
  title: string;
  description: string;
  children?: ReactNode;
}

export const IntegrationsEmptyState: FC<IntegrationsEmptyStateProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <Stack justifyContent="center" alignItems="center" mt={4}>
      <img src={EmptyStateImage} alt="Empty State" width={204} />
      <Typography component="h6" variant="h6" fontWeight="bold" mt={6}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        m={2}
        textAlign="center"
        maxWidth={500}
      >
        {description}
      </Typography>

      {children}
    </Stack>
  );
};
