import { FC } from "react";
import { useLocation } from "react-router-dom";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { AppNavItemWrapper } from "../app-nav-components/AppNavItemWrapper";

export const TasksNavigation: FC = () => {
  const location = useLocation();

  const selected = location.pathname.startsWith("/task-mgmt");

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  return (
    <AppNavItemWrapper
      to="/task-mgmt"
      primary="Tasks"
      Icon={AssignmentOutlinedIcon}
      navCollapsed={navigationCollapsed}
      selected={selected}
    />
  );
};
