import { resourceDetailsResourceFirstProcessKeySelector } from "./resourceDetailsResourceFirstProcessKeySelector";
import { resourceDetailsResourceBillingDataSelector } from "../resourceDetailsResourceBillingDataSelector";
import { RootState } from "../../../../store";

export const resourceDetailsResourceBillingDataTotalCostDateDetailsSelector = (
  state: RootState,
) => {
  const firstProcessKey = resourceDetailsResourceFirstProcessKeySelector(state);

  return resourceDetailsResourceBillingDataSelector(state, firstProcessKey)
    ?.total_cost_date_details;
};
