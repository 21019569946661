import { FC, Fragment } from "react";
import AddIcon from "@mui/icons-material/Add";

import { PopoverOrigin } from "@mui/material";
import { DashboardNavigationAddActionMenu } from "./DashboardNavigationAddActionMenu";
import { NestedNavItem } from "../../../app-nav-components/nested-nav-item/NestedNavItem";
import { useAppAbility } from "../../../../../../services/permissions";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { NavigationItemsVisibilityType } from "../../../../../../store/navigation/utils/types";

interface DashboardNavigationAddDashboardNavItemPros {
  visibility?: NavigationItemsVisibilityType;
}
export const DashboardNavigationAddDashboardNavItem: FC<
  DashboardNavigationAddDashboardNavItemPros
> = ({ visibility }) => {
  const { cannot } = useAppAbility();

  const cannotCreateDashboard = cannot("create", "dashboard");
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  return (
    <Fragment>
      <NestedNavItem
        nested
        primary="Add"
        disabled={cannotCreateDashboard}
        onButtonClick={openMenu}
        primaryTypographyProps={{ color: "primary" }}
        Icon={AddIcon}
        iconProps={{ sx: { color: "primary.main" } }}
      />
      <DashboardNavigationAddActionMenu
        open={open}
        onCloseMenu={closeMenu}
        anchorEl={anchor}
        visibility={visibility}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      />
    </Fragment>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
