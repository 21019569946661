import { FC } from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppAbility } from "../../../../../../../../../services/permissions";
import { IntegrationType } from "../../../../../../../../../store/integrations/integrationsSlice";

interface IntegrationFormActionsProps {
  loading: boolean;
  disabled: boolean;
  edit: boolean;
  type: IntegrationType;
  onSubmit(): void;
  onCancel(): void;
}

export const CommunicationIntegrationFormActions: FC<
  IntegrationFormActionsProps
> = ({ loading, onCancel, onSubmit, disabled, type, edit }) => {
  const { cannot } = useAppAbility();
  const canNotCreateIntegration = cannot("create", "integration");
  const canNotEditIntegration = cannot("edit", "integration");

  return (
    <Stack direction="row" spacing={2.5}>
      <LoadingButton
        onClick={onSubmit}
        disabled={canNotCreateIntegration || canNotEditIntegration || disabled}
        loading={loading}
        variant="contained"
        sx={{ textTransform: "none" }}
      >
        {type === "jira" && !edit ? "Create" : "Done"}
      </LoadingButton>
      <Button
        disabled={loading}
        onClick={onCancel}
        color="tertiary"
        sx={{ textTransform: "none" }}
      >
        Cancel
      </Button>
    </Stack>
  );
};
