import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

export const AnomalySvgIcon = ({ color }: SvgIconProps) => {
  const { palette } = useTheme();
  const fillColor =
    color === "error" ? palette.error.main : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.16668 2.5L4.16664 15.8333H17.5V17.5H2.5V2.5H4.16668ZM13.8594 6.0823L17.4402 9.38766L16.3097 10.6123L14.4733 8.9175L12.8089 12.872L10.2208 10.5L7.99465 14.5833H5V12.9167H7.005L9.77824 7.83293L12.1908 10.0442L13.8594 6.0823ZM7.5 4.16668V5.83332H5.83332V4.16668H7.5ZM10.8333 4.16668V5.83332H9.16668V4.16668H10.8333ZM14.1667 4.16668V5.83332H12.5V4.16668H14.1667ZM17.5 4.16668V5.83332H15.8333V4.16668H17.5Z"
          fill={fillColor}
        />
      </svg>
    </SvgIcon>
  );
};

AnomalySvgIcon.muiName = "AnomalySvgIcon";

export default AnomalySvgIcon;
