import { FC } from "react";
import { InternalDashboardTextWidget } from "./InternalDashboardTextWidget";
import { DashboardWidgetText } from "../../../../../../services/cloudchipr.api";
import { useGetWidgetsTextByWidgetIdFromAccountServiceQuery } from "../../../../../../services/cloudchipr-account-service.api";

interface InternalDashboardTextWidgetWrapper extends DashboardWidgetText {
  organisationId: string;
  onWidgetMount?(): void;
}

export const InternalDashboardTextWidgetWrapper: FC<
  InternalDashboardTextWidgetWrapper
> = ({ id, organisationId, ...props }) => {
  const { data, isLoading, isUninitialized, isError, error } =
    useGetWidgetsTextByWidgetIdFromAccountServiceQuery(
      { widgetId: id, organisationId },
      { skip: !organisationId, refetchOnMountOrArgChange: true },
    );

  if (isLoading || isUninitialized) {
    return null;
  }

  return (
    <InternalDashboardTextWidget
      {...props}
      data={data}
      isError={isError}
      error={error}
      id={id}
    />
  );
};
