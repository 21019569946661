import {
  ResourceExplorerFilterProvider,
  ResourceExplorerGroupingNullable,
  ResourceExplorerPossibleGroupings,
} from "../../../../../services/cloudchipr.api";
import { ResourceExplorerPossibleGroupingsFormattedData } from "../../../../../components/pages/common/resource-explorer-grouping-selector-v2/utils/types/types";
import {
  resourceExplorerGroupingsSequence,
  resourceExplorerHasNoParentKey,
} from "../../../../../components/pages/common/resource-explorer-grouping-selector-v2/utils/constants/constants";

export const generateResourceExplorerPossibleGroupingsFormatedData = (
  data: ResourceExplorerPossibleGroupings,
): ResourceExplorerPossibleGroupingsFormattedData => {
  const {
    groupings,
    groupingProviderMap,
    groupingLabelMap,
    groupingParentLabel,
    groupingNextGrouping,
    groupingPossibleGroupings,
    groupingFilterDataMap,
  } = data?.reduce(
    (acc, item) => {
      const parentLabel = item.parent_label ?? resourceExplorerHasNoParentKey;
      const key = item.key;
      const provider = item.filter_provider;

      if (!key || !provider) return acc;

      // Initialize parentLabel in groupings
      if (!acc.groupings[parentLabel]) {
        acc.groupings[parentLabel] = [];
      }

      // Find or create the grouping object
      let grouping = acc.groupings[parentLabel].find(
        (group) => group.key === key,
      );
      if (!grouping) {
        grouping = { key, items: [], filter_provider: [] };
        acc.groupings[parentLabel].push(grouping);
      }

      // Add the item and filter_provider
      grouping.items.push(item);
      if (!grouping.filter_provider.includes(provider)) {
        grouping.filter_provider.push(provider);
      }

      // Update groupingProviderMap
      if (!acc.groupingProviderMap[key]) {
        acc.groupingProviderMap[key] = [];
      }
      if (!acc.groupingProviderMap[key].includes(provider)) {
        acc.groupingProviderMap[key].push(provider);
      }

      // Update groupingLabelMap
      if (grouping.items[0]?.label) {
        acc.groupingLabelMap[key] = grouping.items[0].label;
      }

      if (!acc.groupingParentLabel[key]) {
        acc.groupingParentLabel[key] = parentLabel;
      }
      //
      // if (!acc.groupingNextGrouping[key]) {
      const data = grouping.items.reduce(
        (result, item) => {
          if (item.filter_provider) {
            result[item.filter_provider] = item.next_grouping ?? null;
          }

          return result;
        },
        {} as Record<
          ResourceExplorerFilterProvider,
          ResourceExplorerGroupingNullable
        >,
      );
      acc.groupingNextGrouping[key] = {
        ...acc.groupingNextGrouping[key],
        ...data,
      };

      if (!acc.groupingPossibleGroupings[key]) {
        acc.groupingPossibleGroupings[key] = grouping?.items[0]
          ?.possible_groupings ?? [grouping.key];
      }

      if (!acc.groupingFilterDataMap[key]) {
        acc.groupingFilterDataMap[key] = {
          key: grouping?.items[0].filter_key,
          type: grouping?.items[0].filter_type,
        };
      }

      return acc;
    },
    {
      groupings: {},
      groupingProviderMap: {},
      groupingLabelMap: {},
      groupingParentLabel: {},
      groupingNextGrouping: {},
      groupingPossibleGroupings: {},
      groupingFilterDataMap: {},
    } as ResourceExplorerPossibleGroupingsFormattedData,
  );

  // Reorder groupings[parentLabel] based on the given keys array

  for (const parentLabel in groupings) {
    const orderedGroups = [];
    const unorderedGroups = [];

    const setArr = Array.from(resourceExplorerGroupingsSequence);
    // Separate groups based on the provided keys array
    for (const key of setArr) {
      const group = groupings[parentLabel].find((g) => g.key === key);
      if (group) {
        orderedGroups.push(group);
      }
    }

    // Add any remaining groups that are not in the keys array
    for (const group of groupings[parentLabel]) {
      if (!resourceExplorerGroupingsSequence.has(group.key)) {
        unorderedGroups.push(group);
      }
    }

    // Combine ordered and unordered groups
    groupings[parentLabel] = [...orderedGroups, ...unorderedGroups];
  }

  return {
    groupings,
    groupingProviderMap,
    groupingLabelMap,
    groupingParentLabel,
    groupingNextGrouping,
    groupingPossibleGroupings,
    groupingFilterDataMap,
  };
};
