import { FC, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";
import { usePreviousImmediate } from "rooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { WithResourceType } from "../../../../../../live-usage-mgmt/utils/types";
import { kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector } from "../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { kubernetesLiveUsageMgmtResourceTypeDataSpendingSelector } from "../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtResourceTypeDataSpendingSelector";
import { kubernetesLiveUsageMgmtResourceTypeDataFilteredSpendingSelector } from "../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtResourceTypeDataFilteredSpendingSelector";
import { kubernetesLiveUsageMgmtResourceTypeDataLoadingSelector } from "../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/loading/kubernetesLiveUsageMgmtResourceTypeDataLoadingSelector";
import { kubernetesLiveUsageMgmtResourceTypeDataTotalCountSelector } from "../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/count/kubernetesLiveUsageMgmtResourceTypeDataTotalCountSelector";
import { kubernetesLiveUsageMgmtFilteredResourcesCountSelector } from "../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/count/kubernetesLiveUsageMgmtFilteredResourcesCountSelector";
import { kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector";
import { resourceTypesWithBillingDataCosts } from "../../../../../../../../utils/constants/resources/resources";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";
import {
  ResourceCardStatisticsCard,
  ResourceCardStatisticsType,
} from "../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-statistics/ResourceCardStatisticsCard";
import { getAllResourcesInHierarchyByResourceType } from "../../../../../../../../utils/helpers/resources/getAllResourcesInHierarchyByResourceType";
import {
  resetKubernetesLiveUsageMgmtSelectedResources,
  setKubernetesLiveUsageMgmtViewWithoutFiltersResourceTypes,
} from "../../../../../../../../store/kubernetes-live-usage-mgmt/kubernetesLiveUsageMgmtSlice";
import { getKubernetesLiveUsageMgmtResourceTypeDataThunk } from "../../../../../../../../store/kubernetes-live-usage-mgmt/thunks/resources/getKubernetesLiveUsageMgmtResourceTypeDataThunk";

interface KubernetesLiveUsageMgmtResourceCardStatisticsProps
  extends WithResourceType {}

export const KubernetesLiveUsageMgmtResourceCardStatistics: FC<
  KubernetesLiveUsageMgmtResourceCardStatisticsProps
> = ({ resourceType }) => {
  const dispatch = useAppDispatch();

  const viewIsWithoutFilters = useAppSelector((state) =>
    kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector(
      state,
      resourceType,
    ),
  );
  const filteredSpending = useAppSelector((state) =>
    kubernetesLiveUsageMgmtResourceTypeDataFilteredSpendingSelector(
      state,
      resourceType,
    ),
  );
  const spending = useAppSelector((state) =>
    kubernetesLiveUsageMgmtResourceTypeDataSpendingSelector(
      state,
      resourceType,
    ),
  );
  const loading = useAppSelector((state) =>
    kubernetesLiveUsageMgmtResourceTypeDataLoadingSelector(state, resourceType),
  );
  const resourcesTotalCount = useAppSelector((state) =>
    kubernetesLiveUsageMgmtResourceTypeDataTotalCountSelector(
      state,
      resourceType,
    ),
  );
  const resourcesCount = useAppSelector((state) =>
    kubernetesLiveUsageMgmtFilteredResourcesCountSelector(state, resourceType),
  );

  const filterTemplatesEnabled = useAppSelector(
    kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector,
  );

  const tooltip = useMemo(() => {
    if (!resourceTypesWithBillingDataCosts.has(resourceType)) {
      return;
    }

    return `${getResourceTypeName(resourceType, {
      singular: true,
      type: "abbreviation",
    })} prices are calculated from cost and usage billing reports and updated  every 24 hours.`;
  }, [resourceType]);

  const count = resourcesCount;

  const onChange = useCallback(
    (value: ResourceCardStatisticsType) => {
      if ((value === "all") === viewIsWithoutFilters) {
        return;
      }
      const hierarchyResourceTypes =
        getAllResourcesInHierarchyByResourceType(resourceType);

      hierarchyResourceTypes.forEach((rt) => {
        dispatch(resetKubernetesLiveUsageMgmtSelectedResources(rt));
        dispatch(
          setKubernetesLiveUsageMgmtViewWithoutFiltersResourceTypes({
            resourceType: rt,
            value: value === "all",
          }),
        );
      });

      dispatch(getKubernetesLiveUsageMgmtResourceTypeDataThunk(resourceType));
    },
    [dispatch, resourceType, viewIsWithoutFilters],
  );

  const previousCount = usePreviousImmediate(count);
  const previousTotalCount = usePreviousImmediate(resourcesTotalCount);

  return (
    <Stack direction="row" spacing={2} alignItems="center" borderRadius={2}>
      {filterTemplatesEnabled && (
        <ResourceCardStatisticsCard
          price={filteredSpending}
          value="filtered"
          loading={loading}
          tooltip={tooltip}
          onChange={onChange}
          title="Filtered Resources"
          selected={!viewIsWithoutFilters}
          instances={`${count ?? previousCount ?? ""} ${getResourceTypeName(
            resourceType,
          )}`}
        />
      )}
      <ResourceCardStatisticsCard
        value="all"
        price={spending}
        loading={loading}
        tooltip={tooltip}
        onChange={onChange}
        title="All Resources"
        selected={viewIsWithoutFilters}
        instances={`${
          resourcesTotalCount ?? previousTotalCount ?? ""
        } ${getResourceTypeName(resourceType)}`}
        hideRadioButton={!filterTemplatesEnabled}
      />
    </Stack>
  );
};
