import { FC, useCallback, useState } from "react";
import { CoverageCommitmentTypeSelectTrigger } from "./CoverageCommitmentTypeSelectTrigger";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { coverageWidgetSetupCommitmentTypeSelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/coverage/coverageWidgetSetupCommitmentTypeSelector";
import { CommitmentType } from "../../../../../../../../../../../services/cloudchipr.api";
import { DropdownSelectOption } from "../../../../../../../../../../common/select/dropdown-select/utils/types/types";
import { coverageWidgetSetupDataChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/coverage-widget/setup-change/coverageWidgetSetupDataChangeThunk";
import { DropdownSelect } from "../../../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { commitmentTypeTitleByType } from "../../../../commitment-utilization /utils/constants/constants";

export const CoverageWidgetSetupCommitmentTypeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const commitmentType = useAppSelector(
    coverageWidgetSetupCommitmentTypeSelector,
  );
  const [commitmentTypes, setCommitmentTypes] = useState<CommitmentType[]>(
    commitmentType === "all" ? allOption : [commitmentType],
  );

  const handleChange = useCallback(
    (value: CommitmentType[]) => {
      if (!value?.length) {
        setCommitmentTypes([...allOption]);
        coverageWidgetSetupDataChangeThunk({
          commitmentType: "all",
        });
        return;
      }

      const commitmentType = value.length === 1 ? value.at(0) : "all";

      if (!commitmentType) {
        return;
      }

      setCommitmentTypes(value);
      dispatch(coverageWidgetSetupDataChangeThunk({ commitmentType }));
    },
    [dispatch],
  );

  return (
    <DropdownSelect
      disableEmptySelectionList
      lastSelectedOptionDisabled
      listWidth={200}
      label="Select Type"
      showSearch={false}
      options={options}
      values={commitmentTypes}
      submitHandlerOnClose={handleChange}
      TriggerComponent={CoverageCommitmentTypeSelectTrigger}
    />
  );
};

const allOption: CommitmentType[] = ["savings_plan", "reservation"];

const options: DropdownSelectOption[] = [
  {
    value: "savings_plan",
    label: commitmentTypeTitleByType.get("savings_plan"),
    hideOnly: true,
  },
  {
    value: "reservation",
    label: commitmentTypeTitleByType.get("reservation"),
    hideOnly: true,
  },
];
