import { ChartDataType } from "../../../../storybook/charts/multi-type-chart/utils/types/types";
import { normalizeResourceExplorerChartData } from "../../../common/utils/helpers/chart/normalizeResourceExplorerChartData";
import {
  DateDataPoint,
  ResourceExplorerChartData,
} from "../../../../services/cloudchipr.api";
import { chartDataPointKey } from "../../../../storybook/charts/multi-type-chart/utils/constants/constants";
import { resourceExplorerChartKeyGenerator } from "../../../../components/pages/common/billing-explorer/utils/resourceExplorerChartKeyGenerator";

export const formatResourceExplorerChartData = (
  data: ResourceExplorerChartData[],
  dataPoint?: DateDataPoint,
  quarterStartMonth?: number,
) => {
  const chartData = data?.map((item) => {
    if (!item.items || !item.date) {
      return {} as ChartDataType;
    }

    const items = item.items.reduce(
      (acc, { field, label, cost }) => {
        const key = resourceExplorerChartKeyGenerator(field, label);

        if (field === undefined || cost === undefined) {
          return acc;
        }

        acc[key] = +cost;

        return acc;
      },
      {} as Record<string, number>,
    );

    return {
      [chartDataPointKey]: item.date,
      ...items,
    } as ChartDataType;
  });

  return normalizeResourceExplorerChartData(
    chartData,
    dataPoint,
    quarterStartMonth,
  );
};
