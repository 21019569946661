import { FC, ReactNode } from "react";
import { TableCell, TableRow } from "@mui/material";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";

interface ResourceDetailsGeneralContentDetailsTableRowProps {
  highlight?: boolean;
  label: string;
  children?: ReactNode;
}

export const ResourceDetailsGeneralContentDetailsTableRow: FC<
  ResourceDetailsGeneralContentDetailsTableRowProps
> = ({ children, label, highlight }) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          px: 2,
          py: 1,
          border: "none",
          minWidth: 190,
          bgcolor: highlight ? "grey.50" : undefined,
        }}
      >
        <TypographyWithTooltip
          title={label}
          variant="body2"
          fontWeight="medium"
          whiteSpace="nowrap"
        />
      </TableCell>

      <TableCell
        sx={{
          px: 2,
          py: 1,
          border: "none",
          bgcolor: highlight ? "grey.50" : undefined,
        }}
      >
        {children}
      </TableCell>
    </TableRow>
  );
};
