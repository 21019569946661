import { FC, useCallback } from "react";
import { useAppDispatch } from "../../../../../../../../../../../store/hooks";
import { Dates } from "../../../../../../../../../../../services/cloudchipr.api";
import { WidgetSetupDateRangeSelect } from "../../../../common/toolbar/WidgetSetupDateRangeSelect";
import { coverageWidgetSetupDataChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/coverage-widget/setup-change/coverageWidgetSetupDataChangeThunk";

export const CoverageWidgetSetupDateRangeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const dateRangeChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(coverageWidgetSetupDataChangeThunk({ dates }));
    },
    [dispatch],
  );

  return <WidgetSetupDateRangeSelect onDateChange={dateRangeChangeHandler} />;
};
