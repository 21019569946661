import { FC, Fragment, useCallback, useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import { Clear, FilterList } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { FilterModel, GridApi } from "ag-grid-community";
import { AgGridAllFiltersMenu } from "./AgGridAllFiltersMenu";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";

interface AgGridAllFiltersChipProps {
  gridApi: GridApi | null;
}

export const AgGridAllFiltersChip: FC<AgGridAllFiltersChipProps> = ({
  gridApi,
}) => {
  const [filterModel, setFilterModel] = useState<FilterModel | null>(
    gridApi?.getFilterModel() ?? null,
  );
  const filterNames = Object.keys(filterModel ?? {});
  const filterCount = filterNames.length;

  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const clearAllFiltersHandler = useCallback(() => {
    gridApi?.setFilterModel(null);
  }, [gridApi]);

  const onFilterChanged = useCallback(({ api }: { api: GridApi | null }) => {
    if (!api) return;

    const filterModel = api.getFilterModel();
    setFilterModel(filterModel);
  }, []);

  useEffect(() => {
    if (!gridApi) return;

    gridApi.addEventListener("filterChanged", onFilterChanged);

    return () => {
      gridApi.removeEventListener("filterChanged", onFilterChanged);
    };
  }, [gridApi, onFilterChanged]);

  useEffect(() => {
    onFilterChanged({ api: gridApi });
  }, [onFilterChanged, gridApi]);

  if (!filterCount) {
    return;
  }

  return (
    <Fragment>
      <Chip
        icon={icon}
        sx={{ pl: 0.5 }}
        onClick={openMenu}
        variant="outlined"
        deleteIcon={deleteIcon}
        label={`${filterCount} filter${filterCount > 1 ? "s" : ""}`}
        onDelete={clearAllFiltersHandler}
      />

      <AgGridAllFiltersMenu
        open={open}
        anchor={anchor}
        gridApi={gridApi}
        onClose={closeMenu}
        filterNames={filterNames}
        onClearAllFilters={clearAllFiltersHandler}
      />
    </Fragment>
  );
};

const deleteIcon = (
  <IconButton size="small" color="primary">
    <Tooltip arrow title="Clear all filters" placement="right">
      <Clear sx={{ fontSize: 16, color: "text.secondary" }} />
    </Tooltip>
  </IconButton>
);

const icon = <FilterList sx={{ fontSize: 16 }} />;
