import { taskManagementTargetSelector } from "../common/taskManagementTargetSelector";
import { RootState } from "../../../../../store";
import { tasksListResourceIdsSelector } from "../../../data/tasksListResourceIdsSelector";
import { TaskTargetOpportunity } from "../../../../utils/types/types";

export const taskManagementAlreadyHasTaskOpportunitiesIdsSelector = (
  state: RootState,
): string[] | undefined => {
  const target = taskManagementTargetSelector(state) as TaskTargetOpportunity;
  const resourceIdsInTasks = tasksListResourceIdsSelector(state);

  return target?.opportunities
    ?.filter(
      (opportunity) => resourceIdsInTasks[opportunity.provider_identifier],
    )
    ?.map((opportunity) => opportunity.provider_identifier);
};
