import { FC } from "react";
import { AzureReservationsDataGridViewTabs } from "./components/AzureReservationsDataGridViewTabs";
import { azureReservedInstancesTableColumnDefs } from "./columns/azureReservedInstancesTableColumnDefs";
import { azureReservationsForTableLoadingSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/data/loading/azureReservationsForTableLoadingSelector";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { azureReservationsDataSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/data/azureReservationsDataSelector";
import { CommitmentsAGGridToolbar } from "../../../../../common/components/data-grid/CommitmentsAGGridToolbar";

import {
  CommitmentsAgGrid,
  CommitmentsToolbar,
} from "../../../../../common/components/CommitmentsAgGrid";

export const AzureReservedInstancesDataGrid: FC = () => {
  const data = useAppSelector(azureReservationsDataSelector);
  const loading = useAppSelector(azureReservationsForTableLoadingSelector);

  return (
    <CommitmentsAgGrid
      Toolbar={toolbar}
      loading={loading}
      columnDefs={azureReservedInstancesTableColumnDefs}
      data={data}
      grouping="commitment"
      localStorageUniqueKey="azureReservedInstancesDataGrid"
    />
  );
};

const toolbar: CommitmentsToolbar = ({ gridApi }) => (
  <CommitmentsAGGridToolbar gridApi={gridApi}>
    <AzureReservationsDataGridViewTabs />
  </CommitmentsAGGridToolbar>
);
