import { FC, Fragment } from "react";
import { LinearProgress } from "@mui/material";
import { LiveFilteredResourcesDrawerBodyForView } from "./LiveFilteredResourcesDrawerBodyForView";
import { useAppSelector } from "../../../../../../../../../../../../../store/hooks";
import { LiveFilteredResourcesDrawerHeader } from "../LiveFilteredResourcesDrawerHeader";
import { liveFilteredTrackedResourcesLoadingForViewSelector } from "../../../../../../../../../../../../../store/resource-explorer/selectors/live-filtered-resources/view/liveFilteredTrackedResourcesLoadingForViewSelector";
import { useGetUsersMeOrganisationsCurrentFilterSetsQuery } from "../../../../../../../../../../../../../services/cloudchipr.api";

interface LiveFilteredResourcesDrawerContentForViewProps {
  onDrawerClose(): void;
}
export const LiveFilteredResourcesDrawerContentForView: FC<
  LiveFilteredResourcesDrawerContentForViewProps
> = ({ onDrawerClose }) => {
  useGetUsersMeOrganisationsCurrentFilterSetsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const loading = useAppSelector(
    liveFilteredTrackedResourcesLoadingForViewSelector,
  );

  return (
    <Fragment>
      <LiveFilteredResourcesDrawerHeader
        onDrawerClose={onDrawerClose}
        loading={loading}
      />

      {loading && <LinearProgress />}

      <LiveFilteredResourcesDrawerBodyForView loading={loading} />
    </Fragment>
  );
};
