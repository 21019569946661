import { FC } from "react";
import { GridApi, RowNode } from "ag-grid-community";
import { Box } from "@mui/material";

interface AutoGroupColumnDefInnerRendererProps {
  api: GridApi;
  node: RowNode;
}

export const AutoGroupColumnDefInnerRenderer: FC<
  AutoGroupColumnDefInnerRendererProps
> = (props) => {
  const groupingColumns = props.api.getRowGroupColumns();
  const currentGrouping = props.node.rowGroupColumn?.getColId() as string;

  if (!currentGrouping) {
    return null;
  }

  const currentCol = groupingColumns.find(
    (col) => col?.getColId() === currentGrouping,
  );

  if (!currentCol) {
    return null;
  }

  const dataFromChildren = props.node?.allLeafChildren?.at(0)?.data;
  const ownData = props.node?.data;

  let data = dataFromChildren ?? ownData;

  const field = props.node.field;
  if (field && data.hasOwnProperty(field) && !data[field]) {
    data = { ...data, [field]: `no ${field}` };
  }

  return (
    <Box pr={1} pl={2} minWidth={150}>
      {currentCol?.getColDef()?.cellRenderer?.({ ...props, data })}
    </Box>
  );
};
