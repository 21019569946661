import { FC } from "react";
import { EmptyState } from "./EmptyState";
import { DataGrid } from "../../../../storybook/data-grid/DataGrid";

export const ChatTable: FC = () => {
  return (
    <div>
      <DataGrid
        toolbar
        columnSorting
        data={[]}
        styles={{
          tableHeaderRow: {
            bgcolor: "grey.50",
            "& th": { textTransform: "capitalize" },
          },
        }}
        columns={[]}
      />

      <EmptyState />
    </div>
  );
};
