import { SvgIcon, SvgIconProps } from "@mui/material";

export const ChartSettingsSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 19H5V5H13V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V11H19V19Z"
          fill="black"
          fillOpacity="0.54"
        />
        <path d="M17 13H15V17H17V13Z" fill="black" fillOpacity="0.54" />
        <path d="M9 10H7V17H9V10Z" fill="black" fillOpacity="0.54" />
        <path d="M13 7H11V17H13V7Z" fill="black" fillOpacity="0.54" />
        <path
          d="M20.1597 6.8875C20.1812 6.67988 20.1883 6.47226 20.1668 6.2718L20.9329 5.66326C21.0045 5.60598 21.0188 5.51291 20.9758 5.43416L20.2384 4.15265C20.1955 4.07389 20.1024 4.04526 20.0165 4.07389L19.1001 4.43186C18.9354 4.31015 18.7565 4.20992 18.5632 4.13117L18.42 3.1575C18.4057 3.06443 18.3341 3 18.241 3H16.759C16.6731 3 16.5943 3.06443 16.58 3.15035L16.4368 4.12401C16.2507 4.20276 16.0717 4.31015 15.9071 4.4247L14.9907 4.06673C14.9048 4.03094 14.8117 4.06674 14.7687 4.14549L14.0313 5.427C13.9884 5.50575 14.0027 5.59882 14.0743 5.6561L14.8403 6.2718C14.8188 6.47942 14.8117 6.68704 14.8332 6.8875L14.0671 7.49604C13.9955 7.55331 13.9812 7.64638 14.0242 7.72513L14.7616 9.00665C14.8045 9.0854 14.8976 9.11404 14.9835 9.0854L15.8927 8.72744C16.0574 8.84914 16.2364 8.94937 16.4297 9.02813L16.5729 10.0018C16.5872 10.0877 16.6588 10.1521 16.7519 10.1521H18.2338C18.3197 10.1521 18.3985 10.0877 18.4128 10.0018L18.556 9.02813C18.7421 8.94937 18.9211 8.84198 19.0858 8.72744L20.0022 9.0854C20.0881 9.1212 20.1812 9.0854 20.2241 9.00665L20.9615 7.72513C21.0045 7.64638 20.9902 7.55331 20.9186 7.49604L20.1597 6.8875ZM17.5036 7.65354C16.9094 7.65354 16.4297 7.17387 16.4297 6.57965C16.4297 5.98543 16.9094 5.50575 17.5036 5.50575C18.0978 5.50575 18.5775 5.98543 18.5775 6.57965C18.5775 7.17387 18.0978 7.65354 17.5036 7.65354Z"
          fill="black"
          fillOpacity="0.54"
        />
      </svg>
    </SvgIcon>
  );
};

ChartSettingsSvgIcon.muiName = "ChartSettingsSvgIcon";

export default ChartSettingsSvgIcon;
