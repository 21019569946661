import { FC, useCallback } from "react";
import { Drawer, Stack } from "@mui/material";
import { BillingSummaryWidgetSetup } from "./widget-setups/billing-summary/BillingSummaryWidgetSetup";
import { CostAnomaliesWidgetSetup } from "./widget-setups/cost-anomalies/CostAnomaliesWidgetSetup";
import { BillingExplorerWidgetSetup } from "./widget-setups/billing-explorer/BillingExplorerWidgetSetup";
import { CommitmentUtilizationWidgetSetup } from "./widget-setups/commitment-utilization /CommitmentUtilizationWidgetSetup";
import { CoverageWidgetSetup } from "./widget-setups/coverage/CoverageWidgetSetup";
import { TextWidgetSetup } from "./widget-setups/text/TextWidgetSetup";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  setWidgetSelectorState,
  setWidgetSetup,
} from "../../../../../../store/dashboards/dashboardsSlice";
import { widgetSetupWidgetTypeSelector } from "../../../../../../store/dashboards/selectors/setups/common/widgetSetupWidgetTypeSelector";

export const WidgetCreateDrawer: FC = () => {
  const widgetType = useAppSelector(widgetSetupWidgetTypeSelector);

  const dispatch = useAppDispatch();

  const closeHandler = useCallback(() => {
    dispatch(setWidgetSetup(null));
  }, [dispatch]);

  const widgetCreateHandler = useCallback(() => {
    dispatch(setWidgetSelectorState(false));
  }, [dispatch]);

  return (
    <Drawer anchor="right" open={!!widgetType} onClose={closeHandler}>
      <Stack width="95dvw" flexGrow={1} overflow="hidden">
        {widgetType === "cost_breakdown" && (
          <BillingExplorerWidgetSetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}

        {widgetType === "cost_and_usage_summary" && (
          <BillingSummaryWidgetSetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}

        {widgetType === "largest_cost_changes" && (
          <CostAnomaliesWidgetSetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}

        {widgetType === "commitments_utilization" && (
          <CommitmentUtilizationWidgetSetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}

        {widgetType === "coverage" && (
          <CoverageWidgetSetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}

        {widgetType === "text" && (
          <TextWidgetSetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}
      </Stack>
    </Drawer>
  );
};
