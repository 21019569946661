import {
  ChangeEvent,
  FC,
  KeyboardEventHandler,
  useCallback,
  useState,
} from "react";
import TextField from "@mui/material/TextField";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { sendAskAiQuestionThunk } from "../../../../../../store/ask-ai/thunks/sendAskAiQuestionThunk";
import { askAiLoadingSelector } from "../../../../../../store/ask-ai/selectors/askAiLoadingSelector";

interface AskAiInputAreaProps {
  scrollIntoConversationBottom(): void;
}

export const AskAiInputArea: FC<AskAiInputAreaProps> = ({
  scrollIntoConversationBottom,
}) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(askAiLoadingSelector);
  const [inputValue, setInputValue] = useState("");

  const disabled = loading || !inputValue?.length;

  const sendQuestionHandler = useCallback(() => {
    if (disabled) {
      return;
    }
    setInputValue("");
    dispatch(sendAskAiQuestionThunk(inputValue));
    scrollIntoConversationBottom();
  }, [inputValue, dispatch, scrollIntoConversationBottom, disabled]);

  const inputChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    },
    [],
  );

  const handleKeyDown: KeyboardEventHandler = useCallback(
    (event) => {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        if (inputValue.trim()) {
          sendQuestionHandler();
        }
      }
    },
    [sendQuestionHandler, inputValue],
  );

  return (
    <Stack position="sticky" bottom={0} bgcolor="white" spacing={1}>
      <Divider orientation="horizontal" />
      <Stack px={2} flex={1} position="relative">
        <TextField
          multiline
          rows={4}
          placeholder="Ask anything about your cloud resources..."
          variant="filled"
          sx={textFieldSx}
          value={inputValue}
          onChange={inputChangeHandler}
          onKeyDown={handleKeyDown}
        />
        <Box position="absolute" right={0} mr={5} mt={1}>
          <IconButton
            edge="end"
            onClick={sendQuestionHandler}
            disabled={disabled}
            sx={{
              backgroundColor: "primary.main",
              borderRadius: "50%",
              padding: 1,
              "&:hover": {
                backgroundColor: "primary.main",
              },
              "&.Mui-disabled": {
                backgroundColor: "grey.200",
              },
            }}
          >
            <ArrowUpwardIcon sx={{ color: "white" }} fontSize="small" />
          </IconButton>
        </Box>

        <Typography
          variant="body2"
          color="text.disabled"
          textAlign="center"
          py={1}
          pb={2}
        >
          Cloudchipr AI can make mistakes. Check important info.
        </Typography>
      </Stack>
    </Stack>
  );
};

const textFieldSx = {
  flex: 1,
  "& .MuiFilledInput-root": {
    pr: 7,
    py: 2,
    borderRadius: 2,
    "&:before, &:after": {
      display: "none",
    },
  },
};
