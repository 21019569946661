import { azureOverviewDataSelector } from "./azureOverviewDataSelector";
import { RootState } from "../../../../store";

export const azureOverviewReservationTotalCostSelector = (
  state: RootState,
): number | null => {
  const data = azureOverviewDataSelector(state);

  return data?.reservation_total_commitments ?? null;
};
