import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { currentDashboardIdSelector } from "../../../../selectors/dashboard/currentDashboardIdSelector";
import { buildWidgetThunk } from "../../common/buildWidgetThunk";
import { enqueueSnackbarErrorAlert } from "../../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { DashboardWidgetTextRequest } from "../../../../../../services/cloudchipr.api";
import { getTextWidgetDataByWidgetIdThunk } from "../getTextWidgetDataByWidgetIdThunk";
import { textWidgetSetupSelector } from "../../../../selectors/setups/text/textWidgetSetupSelector";

export const buildTextWidgetThunk = createAsyncThunk(
  "dashboards/buildTextWidget",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const widget = textWidgetSetupSelector(state);
    const dashboardId = currentDashboardIdSelector(state);

    if (!dashboardId) {
      return;
    }

    try {
      const response = await dispatch(
        buildWidgetThunk({
          widgetId: widget?.id,
          widget: {
            type: "text",
            name: widget?.name ?? "Text Widget",
            text: widget?.text ?? "",
          } as DashboardWidgetTextRequest,
        }),
      ).unwrap();

      if (response?.id) {
        dispatch(getTextWidgetDataByWidgetIdThunk(response?.id));
      }

      return response?.id;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
