import { FC } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { BudgetsForm } from "./components/create-budget/BudgetsForm";
import { BudgetsChart } from "./components/create-budget/BudgetsChart";
import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceExplorerViewsLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsLoadingSelector";
import { currentBudgetDataLoadingSelector } from "../../../../../../../store/budgets/selectors/current-budget/currentBudgetDataLoadingSelector";

export const BudgetsDrawer: FC = () => {
  const viewsLoading = useAppSelector(resourceExplorerViewsLoadingSelector);
  const currentBudgetLoading = useAppSelector(currentBudgetDataLoadingSelector);

  if (viewsLoading || currentBudgetLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" minHeight={450}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack direction="row" height="100%" overflow="hidden">
      <BudgetsForm />
      <BudgetsChart />
    </Stack>
  );
};
