import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { SwitchWithTypography } from "../../../../../../../../../../SwitchWithTypography";
import { billingExplorerSetupAggregationsPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupAggregationsPropertyByKeySelector";
import { billingExplorerViewAggregationsChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerViewAggregationsChangeThunk";
import { BillingExplorerWidgetSetupType } from "../../../../../../../../../../../store/dashboards/utils/types/setups/billingExplorerSetupType";

export interface BillingExplorerWidgetAggregationsSwitchProps {
  selectorKey: keyof BillingExplorerWidgetSetupType["aggregation"];
  title: string;
}

export const BillingExplorerWidgetAggregationsSwitch: FC<
  BillingExplorerWidgetAggregationsSwitchProps
> = ({ selectorKey, title }) => {
  const dispatch = useAppDispatch();
  const checked = useAppSelector(
    billingExplorerSetupAggregationsPropertyByKeySelector(selectorKey),
  );

  const handleCheckedChange = useCallback(
    (checked: boolean) => {
      dispatch(
        billingExplorerViewAggregationsChangeThunk({
          [selectorKey]: checked,
        }),
      );
    },
    [dispatch, selectorKey],
  );

  return (
    <SwitchWithTypography
      checked={checked}
      onChange={handleCheckedChange}
      title={title}
    />
  );
};
