import {
  ActionActivityValue,
  AssigneeActivityValue,
  CommentActivityValue,
  DueDateActivityValue,
  EnvironmentActivityValue,
  NameActivityValue,
  OwnerActivityValue,
  PriorityActivityValue,
  StatusActivityValue,
} from "../../../../../../../../../../services/cloudchipr.api";

export const isCommentActivityValue = (
  value: any,
): value is CommentActivityValue => {
  return value?.type === "comment";
};

export const isDueDateActivityValue = (
  value: any,
): value is DueDateActivityValue => {
  return value?.type === "due_date";
};

export const isAssigneeActivityValue = (
  value: any,
): value is AssigneeActivityValue => {
  return value?.type === "assignee";
};

export const isNameActivityValue = (value: any): value is NameActivityValue => {
  return value?.type === "name";
};

export const isPropertyActivityValue = (
  value: any,
): value is
  | StatusActivityValue
  | ActionActivityValue
  | EnvironmentActivityValue
  | OwnerActivityValue
  | PriorityActivityValue => {
  return (
    value?.type === "environment" ||
    value?.type === "action" ||
    value?.type === "priority" ||
    value?.type === "owner" ||
    value?.type === "status"
  );
};
