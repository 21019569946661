import { FC, Fragment } from "react";
import { BillingExplorerExplainWithAiAnomalyTrendItem } from "../BillingExplorerExplainWithAiAnomalyTrendItem";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { billingExplorerSummaryAnomaliesDecreaseTrendsSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/billingExplorerSummaryAnomaliesDecreaseTrendsSelector";

interface BillingExplorerExplainWithAiDecreaseTrendsProps {
  index: number;
}
export const BillingExplorerExplainWithAiDecreaseTrends: FC<
  BillingExplorerExplainWithAiDecreaseTrendsProps
> = ({ index }) => {
  const decreaseTrends = useAppSelector((state) =>
    billingExplorerSummaryAnomaliesDecreaseTrendsSelector(state, index),
  );

  if (!decreaseTrends) {
    return null;
  }

  return (
    <Fragment>
      {decreaseTrends?.map((item) => {
        return (
          <BillingExplorerExplainWithAiAnomalyTrendItem
            key={item.explanation}
            type="decrease"
            {...item}
          />
        );
      })}
    </Fragment>
  );
};
