import { Layout, Layouts } from "react-grid-layout";
import { DashboardLayoutBreakpoint } from "../types/types";

export const generateDefaultLayoutFromWidgetIds = (
  widgetIds: string[],
): Layouts => {
  return {
    lg: widgetIds.map((id) => generateBreakpointLayoutById("lg", id)),
    md: widgetIds.map((id) => generateBreakpointLayoutById("md", id)),
    sm: widgetIds.map((id) => generateBreakpointLayoutById("sm", id)),
  };
};

export const generateBreakpointLayoutById = (
  bp: DashboardLayoutBreakpoint,
  widgetId: string,
  parameters?: Partial<Layout>,
): Layout => {
  return {
    ...dashboardDefaultLayouts[bp],
    ...parameters,
    i: widgetId,
  };
};

const dashboardDefaultLayouts: Record<DashboardLayoutBreakpoint, Layout> = {
  lg: {
    w: 12,
    minW: 4,
    maxH: 4,
    minH: 4,
    h: 4,
    x: 0,
    y: 0,
    i: "",
  },
  md: {
    w: 10,
    minW: 5,
    maxH: 4,
    minH: 4,
    h: 4,
    x: 0,
    y: 0,
    i: "",
  },
  sm: {
    w: 6,
    h: 4,
    minW: 6,
    maxH: 4,
    minH: 4,
    x: 0,
    y: 0,
    i: "",
  },
};
