import { FC } from "react";
import { FormikErrors, FormikHelpers } from "formik";
import { Card, Stack } from "@mui/material";
import { FilterDialogListItem } from "./FilterDialogListItem";
import { FilterDialogGroupListActionButtons } from "./FilterDialogGroupListActionButtons";
import { FilterDialogListItemOperator } from "./FilterDialogListItemOperator";
import {
  FilterKeyType,
  ResourceFilters,
  ResourceType,
} from "../../../../../../services/cloudchipr.api";
import {
  FilterGroups,
  FilterItems,
  FilterOperators,
  FiltersInitialValues,
  isFilterGroup,
  ModalFilterItem,
} from "../utils/types/types";

interface FilterDialogGroupListProps {
  indexOfGroup: number;
  defaultFilters: ResourceFilters;
  accountIds?: string[];
  indexOfOperator: number;
  filterItems: FilterItems;
  filterGroup: FilterGroups;
  resourceType: ResourceType;
  operators: FilterOperators;
  excludedFilters?: FilterKeyType[];
  errors: FormikErrors<FiltersInitialValues>;
  setValues: FormikHelpers<FiltersInitialValues>["setValues"];
  setFieldValue: FormikHelpers<ModalFilterItem>["setFieldValue"];
}

export const FilterDialogGroupList: FC<FilterDialogGroupListProps> = ({
  errors,
  filterGroup,
  filterItems,
  resourceType,
  operators,
  setValues,
  accountIds,
  indexOfGroup,
  setFieldValue,
  excludedFilters,
  indexOfOperator,
  defaultFilters,
}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="start">
      <FilterDialogListItemOperator
        index={indexOfGroup}
        setValues={setValues}
        operator={operators[0]}
        indexOfOperator={0}
      />

      <Card variant="outlined" sx={{ p: 2, bgcolor: "grey.50", flex: 1 }}>
        <Stack spacing={1}>
          {filterGroup.map((filterIndex, index) => {
            if (isFilterGroup(filterIndex)) {
              return null;
            }

            const currentFilter = filterItems[filterIndex];

            return (
              <FilterDialogListItem
                index={index}
                defaultFilters={defaultFilters}
                itemOperator={operators[indexOfOperator]}
                indexOfOperator={indexOfOperator}
                key={`${filterIndex}_FilterDialogListItem`}
                errors={errors}
                setValues={setValues}
                accountIds={accountIds}
                filterIndex={filterIndex}
                resourceType={resourceType}
                setFieldValue={setFieldValue}
                excludedFilters={excludedFilters}
                currentFilter={currentFilter}
              />
            );
          })}

          <FilterDialogGroupListActionButtons
            setValues={setValues}
            indexOfGroup={indexOfGroup}
            resourceType={resourceType}
            defaultFilters={defaultFilters}
          />
        </Stack>
      </Card>
    </Stack>
  );
};
