// general tab
export const resourceDetailsGeneralTabProcessKey =
  "resourceDetailsGeneralTabProcessKey";

// costs tab
export const resourceDetailsCostsTabProductFamilyProcessKey =
  "resourceDetailsCostsTabProductFamilyProcessKey";
export const resourceDetailsCostsTabUsageTypeProcessKey =
  "resourceDetailsCostsTabUsageTypeProcessKey";
export const resourceDetailsCostsTabItemDescriptionProcessKey =
  "resourceDetailsCostsTabItemDescriptionProcessKey";

export const resourceDetailsCostsTabAllProcessesKeys = [
  resourceDetailsCostsTabProductFamilyProcessKey,
  resourceDetailsCostsTabUsageTypeProcessKey,
  resourceDetailsCostsTabItemDescriptionProcessKey,
] as const;
