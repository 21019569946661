import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Divider, Drawer } from "@mui/material";
import { AlertDrawerHeader } from "./components/common/AlertsDrawerHeader";
import { AlertsCostsAnomalyDrawer } from "./components/cost-anomaly/AlertsCostAnomalyDrawer";
import { AlertsUtilizationDrawer } from "./components/utilization/AlertsUtilizationDrawer";
import { BudgetsDrawer } from "./components/budgets/BudgetsDrawer";
import { AlertTabType } from "../../../../../store/alerts/utils/types/types";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { alertsActiveTabSelector } from "../../../../../store/alerts/selectors/alertsActiveTabSelector";
import { alertsOpenDrawerSelector } from "../../../../../store/alerts/selectors/alertsOpenDrawerSelector";
import { resetCurrentBudget } from "../../../../../store/budgets/budgetsSlice";

interface AlertsDrawerProps {
  onClose(): void;
}

export const BudgetsAndAlertsDrawer: FC<AlertsDrawerProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const alertsActiveTab = useAppSelector(alertsActiveTabSelector);
  const alertsOpenDrawer = useAppSelector(alertsOpenDrawerSelector);
  const [drawerTab, setDrawerTab] = useState<AlertTabType>(alertsActiveTab);

  const paperProps = useMemo(() => {
    return {
      sx: {
        width: alertsActiveTab === "budget" ? "80dvw" : "50vw",
        maxWidth: alertsActiveTab === "budget" ? 1080 : 720,
        minWidth: 670,
      },
    };
  }, [alertsActiveTab]);

  const handleClose = useCallback(() => {
    onClose();
    setDrawerTab(alertsActiveTab);

    if (drawerTab === "budget") {
      dispatch(resetCurrentBudget());
    }
  }, [onClose, alertsActiveTab, drawerTab, dispatch]);

  useEffect(() => {
    setDrawerTab(alertsActiveTab);
  }, [alertsActiveTab]);

  return (
    <Drawer
      anchor="right"
      open={alertsOpenDrawer}
      onClose={handleClose}
      PaperProps={paperProps}
      slotProps={slotProps}
    >
      <AlertDrawerHeader onClose={handleClose} />
      <Divider />

      <Fragment>
        {drawerTab === "budget" && <BudgetsDrawer />}
        {drawerTab === "costAnomaly" && (
          <AlertsCostsAnomalyDrawer onClose={handleClose} />
        )}
        {drawerTab === "utilization" && (
          <AlertsUtilizationDrawer onClose={handleClose} />
        )}
      </Fragment>
    </Drawer>
  );
};

const slotProps = {
  backdrop: {
    invisible: true,
  },
};
