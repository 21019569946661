import { FC, useMemo } from "react";
import { useDragLayer } from "react-dnd";
import { KubernetesCluster } from "../../../../../../services/cloudchipr.api";
import { AccountCardDragPreview } from "../../accounts/account-card/AccountCardDragPreview";

interface KubernetesClusterCardDragPreviewProps {
  clusters: KubernetesCluster[];
}
export const KubernetesClusterCardDragPreview: FC<
  KubernetesClusterCardDragPreviewProps
> = ({ clusters }) => {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset(),
  }));

  const cluster = useMemo(() => {
    return clusters?.find((a) => a.id === item?.id);
  }, [item?.id, clusters]);

  if (!cluster || !isDragging || !currentOffset) {
    return null;
  }

  return (
    <AccountCardDragPreview
      name={cluster.name ?? ""}
      id={cluster.cluster_id ?? ""}
      accessType="read"
      currentOffsetX={currentOffset?.x}
      currentOffsetY={currentOffset?.y}
      provider="kubernetes"
    />
  );
};
