import { FC, useCallback } from "react";
import { useAppDispatch } from "../../../../../../../../../../store/hooks";
import { Dates } from "../../../../../../../../../../services/cloudchipr.api";
import { billingExplorerDateRangeChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerDateRangeChangeThunk";
import { WidgetSetupDateRangeSelect } from "../../../common/toolbar/WidgetSetupDateRangeSelect";

export const BillingExplorerWidgetSetupDateRangeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const dateRangeChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(billingExplorerDateRangeChangeThunk(dates));
    },
    [dispatch],
  );

  return <WidgetSetupDateRangeSelect onDateChange={dateRangeChangeHandler} />;
};
