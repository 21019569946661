import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Stack, Typography } from "@mui/material";
import { azureCoveredUsageColumnDef } from "./common/azureCoveredUsageColumnDef";
import { azureUncoveredUsageColumnDef } from "./common/azureUncoveredUsageColumnDef";
import { azureNetSavingsColumnDef } from "./common/azureNetSavingsColumnDef";
import { getAzureCoverageColumnDef } from "./common/getAzureCoverageColumnDef";
import { AzureSavingsPlanCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const azureCoverageGroupedByAccountColumnDefs: ColDef<AzureSavingsPlanCoverageDataWithId>[] =
  [
    {
      field: "account.name",
      headerName: "Subscription",
      suppressKeyboardEvent: () => true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      width: 250,
      minWidth: 250,

      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => {
        if (!data) {
          return;
        }

        return (
          <Stack>
            <Typography variant="body2">{data?.account?.name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {data?.account?.provider_account_id}
            </Typography>
          </Stack>
        );
      },
      valueGetter: ({ data }) =>
        `${data?.account?.name ?? ""} ${data?.account?.provider_account_id ?? ""}`,
    },
    azureCoveredUsageColumnDef,
    azureUncoveredUsageColumnDef,
    azureNetSavingsColumnDef,
    getAzureCoverageColumnDef("account"),
    agGridEmptyColumnToFitEmptySpace,

    // azureTotalCoverageColumnDef, // todo: Add this column after the backend fix
  ];
