import { FC, useCallback } from "react";
import { Link } from "@mui/material";
import { generateDateLabelByBudgetPeriod } from "./tooltip/utils/helpers/generateDateLabelByBudgetPeriod";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { capitalizeString } from "../../../../../../../../../utils/helpers/capitalizeString";
import { resourceExplorerViewBudgetSelector } from "../../../../../../../../../store/resource-explorer/selectors/budget/resourceExplorerViewBudgetSelector";
import { toggleAlertsOpenDrawer } from "../../../../../../../../../store/alerts/alertsSlice";
import { initiateBudgetByIdThunk } from "../../../../../../../../../store/budgets/thunks/initiateBudgetByIdThunk";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";

export const CreateBudgetButtonComponent: FC = () => {
  const dispatch = useAppDispatch();
  const budget = useAppSelector(resourceExplorerViewBudgetSelector);

  const dateLabelByBudgetPeriod = generateDateLabelByBudgetPeriod(
    budget?.start_date,
    budget?.period,
  );

  const budgetId = budget?.id;

  const handleBudgetsDrawerOpen = useCallback(() => {
    if (!budgetId) {
      return;
    }
    dispatch(toggleAlertsOpenDrawer());
    dispatch(initiateBudgetByIdThunk(budgetId));
  }, [dispatch, budgetId]);

  if (!budget) {
    return null;
  }

  return (
    <Link
      component="button"
      onClick={handleBudgetsDrawerOpen}
      variant="subtitle2"
      fontWeight="regular"
      color="text.secondary"
      underline="none"
      sx={{
        "&:hover": {
          color: "primary.main",
        },
      }}
    >
      <TypographyWithTooltip
        title={`Budget - ${capitalizeString(budget.period)} (${dateLabelByBudgetPeriod})`}
      />
    </Link>
  );
};
