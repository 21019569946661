import { FC, ReactNode, useCallback, useState } from "react";
import { GridApi } from "ag-grid-community";
import { Stack } from "@mui/material";
import { CoverageDataGridGrouping } from "./CoverageDataGridGrouping";
import { CommitmentCsvExport } from "./CommitmentCsvExport";
import { GlobalFilter } from "../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { AGGridColumnsToolPanel } from "../../../../common/ag-grid/components/AGGridColumnsToolPanel";
import { useAppSelector } from "../../../../../../store/hooks";
import { commitmentsDataViewTabSelector } from "../../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";

interface CommitmentsAGGridToolbarProps {
  gridApi: GridApi | null;
  children?: ReactNode;
}

export const CommitmentsAGGridToolbar: FC<CommitmentsAGGridToolbarProps> = ({
  gridApi,
  children,
}) => {
  const tab = useAppSelector(commitmentsDataViewTabSelector);
  const isCoverageTab = tab === "coverage";
  const [searchValue, setSearchValue] = useState("");

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridApi?.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [gridApi],
  );

  return (
    <Stack
      pb={2}
      flexDirection={!isCoverageTab ? "row" : "column"}
      justifyContent="space-between"
    >
      {children}
      <Stack
        direction="row"
        spacing={2}
        pt={isCoverageTab ? 2 : 0}
        alignItems="flex-end"
      >
        {tab === "coverage" && <CoverageDataGridGrouping />}
        <Stack
          flex={1}
          spacing={1}
          direction="row"
          justifyContent="flex-end"
          alignContent="center"
          alignItems="center"
        >
          <GlobalFilter
            globalFilter={searchValue}
            setGlobalFilter={searchChangeHandler}
            size="xsmall"
            sx={{ width: 260 }}
          />

          <CommitmentCsvExport gridApi={gridApi} />

          <AGGridColumnsToolPanel gridApi={gridApi} />
        </Stack>
      </Stack>
    </Stack>
  );
};
