import { FC, Fragment } from "react";
import { Divider } from "@mui/material";
import { IntegrationCustomMessageDataGrid } from "../../../../../../../common/notification-selection/components/custom-message/common-components/IntegrationCustomMessageDataGrid";
import { integrationCustomEmailActionExecuteDataGrid } from "../../../../utils/constants/customMessages";
import { EmailIntegrationCsvInfo } from "../../../../../../../common/notification-selection/components/custom-message/common-components/EmailIntegrationCsvInfo";
import { SlackIntegrationDataGrid } from "../../../../../../../common/notification-selection/components/custom-message/common-components/SlackIntegrationDataGrid";
import { NestedIntegration } from "../../../../../../../../../services/cloudchipr.api";

interface WorkflowNotificationCustomMessageAfterMessageComponentProps {
  type: NestedIntegration["type"];
}

export const WorkflowNotificationCustomMessageAfterMessageComponent: FC<
  WorkflowNotificationCustomMessageAfterMessageComponentProps
> = ({ type }) => {
  if (type === "email") {
    return (
      <Fragment>
        <Divider sx={{ mt: 1 }} />
        <IntegrationCustomMessageDataGrid
          data={integrationCustomEmailActionExecuteDataGrid}
          label="Account Name / ID"
        />
        <EmailIntegrationCsvInfo />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Divider sx={{ mt: 1 }} />

      <SlackIntegrationDataGrid />
    </Fragment>
  );
};
