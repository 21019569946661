import { FC } from "react";
import Typography from "@mui/material/Typography";
import { Chip, Stack } from "@mui/material";
import {
  NestedIntegration,
  SlackConversationResponse,
} from "../../../../../../../services/cloudchipr.api";
import { NotificationCustomMessageContent } from "../../../utils/types/types";
import { SlackConversationLabel } from "../../../../../integrations/components/integrations-create/components/slack/components/SlackConversationLabel";

export interface NotificationCustomMessagePreviewContentProps
  extends NotificationCustomMessageContent {
  message: string;
  type: NestedIntegration["type"];
  mentions?: SlackConversationResponse[] | null;
}

export const NotificationCustomMessagePreviewContent: FC<
  NotificationCustomMessagePreviewContentProps
> = ({ BeforeMessage, AfterMessage, message, type, mentions }) => {
  return (
    <Stack spacing={1} mt={2}>
      {BeforeMessage && <BeforeMessage type={type} />}

      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{ __html: message }}
      />

      {type === "slack" && !!mentions?.length && (
        <Stack direction="row" flexWrap="wrap" gap={0.5}>
          {mentions?.map((conversation) => {
            return (
              <Chip
                key={conversation?.type + conversation?.id + conversation?.name}
                size="small"
                variant="outlined"
                label={
                  <SlackConversationLabel
                    maxWidth={150}
                    type={conversation?.type}
                    name={conversation?.name}
                  />
                }
              />
            );
          })}
        </Stack>
      )}

      {AfterMessage && <AfterMessage type={type} />}
    </Stack>
  );
};
