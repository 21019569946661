import { FC } from "react";
import { CommitmentsOverviewMetadataLeftSide } from "./common/CommitmentsOverviewMetadataLeftSide";
import { useAppSelector } from "../../../../../../store/hooks";
import { overviewLoadingSelector } from "../../../../../../store/commitments/selectors/overview/loading/overviewLoadingSelector";
import { overviewReservationTotalCostSelector } from "../../../../../../store/commitments/selectors/overview/overviewReservationTotalCostSelector";
import { overviewSavingsPlanTotalCommitmentsSelector } from "../../../../../../store/commitments/selectors/overview/overviewSavingsPlanTotalCommitmentsSelector";
import { overviewTotalNotCoveredSelector } from "../../../../../../store/commitments/selectors/overview/overviewTotalNotCoveredSelector";
import { overviewRealCostSelector } from "../../../../../../store/commitments/selectors/overview/overviewRealCostSelector";
import { overviewTotalCommitmentsSelector } from "../../../../../../store/commitments/selectors/overview/overviewTotalCommitmentsSelector";

export const AwsCommitmentsOverviewMetadataLeftSide: FC = () => {
  const realCost = useAppSelector(overviewRealCostSelector);
  const totalCommitments = useAppSelector(overviewTotalCommitmentsSelector);
  const totalNotCovered = useAppSelector(overviewTotalNotCoveredSelector);
  const savingsPlanTotalCommitments = useAppSelector(
    overviewSavingsPlanTotalCommitmentsSelector,
  );
  const reservationTotalCommitments = useAppSelector(
    overviewReservationTotalCostSelector,
  );
  const loading = useAppSelector(overviewLoadingSelector);

  return (
    <CommitmentsOverviewMetadataLeftSide
      realCost={realCost}
      totalCommitments={totalCommitments}
      totalNotCovered={totalNotCovered}
      savingsPlanTotalCommitments={savingsPlanTotalCommitments}
      reservationTotalCommitments={reservationTotalCommitments}
      loading={loading}
    />
  );
};
