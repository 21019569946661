import { FC, useCallback } from "react";
import { useDidMount, useEffectOnceWhen, useToggle } from "rooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useLocation } from "react-router-dom";
import { usePatchUsersMeCelebrationPreferenceMutation } from "../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getExecutionLogDetailsThunk } from "../../store/execution-log/thunks/getExecutionLogDetailsThunk";
import { executionLogDetailsSelector } from "../../store/execution-log/selectors/executionLogDetailsSelector";
import showCelebrationPopup from "../../assets/images/show_celebration_popup.svg";
import { money } from "../../utils/numeral/money";
import { getProfileThunk } from "../../store/profile/profileThunks";

export const ShowCelebrationPopup: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const details = useAppSelector(executionLogDetailsSelector);
  const [updateShowPopup] = usePatchUsersMeCelebrationPreferenceMutation();
  const [open, toggleOpen] = useToggle(false);

  const hours = (details?.operation_time ?? 0) / 60;

  const handleClose = useCallback(async () => {
    toggleOpen();

    await updateShowPopup({
      body: {
        celebration_popup_enabled: false,
      },
    });
    await dispatch(getProfileThunk());
  }, [toggleOpen, dispatch, updateShowPopup]);

  useEffectOnceWhen(() => {
    if (!open) {
      setTimeout(() => toggleOpen(), 0);
    }
  }, !!details?.total_price);

  useDidMount(() => {
    if (location.pathname.startsWith("/account/setup")) {
      return;
    }

    dispatch(getExecutionLogDetailsThunk({ account_ids: null }));
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        px: 1,
      }}
      fullWidth
      maxWidth="sm"
    >
      <Stack direction="row" justifyContent="end" px={2} py={1}>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Stack>

      <DialogContent
        sx={{
          px: 2,
          pb: 3,
          pt: 0,
        }}
      >
        <Stack spacing={2}>
          <img src={showCelebrationPopup} alt="showCelebrationPopup" />

          <Typography variant="h6" fontWeight="medium" textAlign="center">
            Great work! 🎉
            <br />
            Your team has saved 💰{" "}
            <Typography
              variant="inherit"
              component="span"
              fontWeight="bold"
              color={successTextColor}
            >
              {money(details?.total_price)}
            </Typography>{" "}
            and cut down their <br />
            work time by ⏰{" "}
            <Typography
              variant="inherit"
              component="span"
              fontWeight="bold"
              color={successTextColor}
            >
              ~{hours.toFixed(0)} hours
            </Typography>{" "}
            using Cloudchipr.
            <br />
            Keep up the great work!
          </Typography>
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
        }}
      >
        <Stack width="100%" spacing={2} direction="row" justifyContent="center">
          <Button
            variant="text"
            sx={{ textTransform: "none", color: "action.active" }}
            component={Link}
            to="/execution-log"
            onClick={handleClose}
          >
            View Execution Logs
          </Button>

          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ textTransform: "none" }}
          >
            Amazing!
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

const successTextColor = "#3B873E";
