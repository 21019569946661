import { FC, Fragment } from "react";
import { usePreviousImmediate } from "rooks";
import { useAppSelector } from "../../../../../../store/hooks";
import { money } from "../../../../../../utils/numeral/money";
import { liveUsageMgmtResourceTypeDataEmsSelector } from "../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataEmsSelector";
import { liveUsageMgmtResourceTypeDataSpendingSelector } from "../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataSpendingSelector";
import { LiveUsageMgmtResourceStatistic } from "../../common/LiveUsageMgmtResourceStatistic";
import { liveUsageMgmtResourceTypeDataTotalCountSelector } from "../../../../../../store/live-usage-mgmt/selectors/resource-type-data/count/liveUsageMgmtResourceTypeDataTotalCountSelector";
import { liveUsageMgmtFilteredResourcesCountSelector } from "../../../../../../store/live-usage-mgmt/selectors/resource-type-data/count/liveUsageMgmtFilteredResourcesCountSelector";
import { liveUsageMgmtFilteredResourcesChildResourcesCountSelector } from "../../../../../../store/live-usage-mgmt/selectors/resource-type-data/count/liveUsageMgmtFilteredResourcesChildResourcesCountSelector";
import { WithResourceType } from "../../../utils/types";

interface LiveUsageMgmtResourceCardHeaderStatisticsProps
  extends WithResourceType {}

export const LiveUsageMgmtResourceCardHeaderStatistics: FC<
  LiveUsageMgmtResourceCardHeaderStatisticsProps
> = ({ resourceType }) => {
  const ems = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataEmsSelector(state, resourceType),
  );

  const spending = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataSpendingSelector(state, resourceType),
  );

  const resourcesTotalCount = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataTotalCountSelector(state, resourceType),
  );
  const resourcesCount = useAppSelector((state) =>
    liveUsageMgmtFilteredResourcesCountSelector(state, resourceType),
  );
  const resourceChildrenCount = useAppSelector((state) =>
    liveUsageMgmtFilteredResourcesChildResourcesCountSelector(
      state,
      resourceType,
    ),
  );

  const count =
    resourceType === "rds_snapshot_source" ||
    resourceType === "dynamo_db_backup_source"
      ? resourceChildrenCount
      : resourcesCount;

  const previousCount = usePreviousImmediate(resourcesCount);
  const previousTotalCount = usePreviousImmediate(count);
  const totalCount = resourcesTotalCount ?? previousTotalCount;

  return (
    <Fragment>
      {totalCount !== 0 && (
        <LiveUsageMgmtResourceStatistic
          type="filtered"
          count={resourcesCount ?? previousCount}
          value={money(ems)}
        />
      )}
      <LiveUsageMgmtResourceStatistic
        type="all"
        count={totalCount}
        value={money(spending)}
        disableColors={totalCount === 0}
      />
    </Fragment>
  );
};
