import { FC, useCallback } from "react";
import { Stack, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LoadingButton } from "@mui/lab";
import {
  SlackConversation,
  usePostUsersMeSlackIntegrationsByIntegrationIdTestMessagesMutation,
} from "../../../../../../../services/cloudchipr.api";

interface SlackConnectionTestMessageProps {
  integrationId: string;
  conversation?: SlackConversation;
}

export const SlackConnectionTestMessage: FC<
  SlackConnectionTestMessageProps
> = ({ integrationId, conversation }) => {
  const [trigger, { isLoading }] =
    usePostUsersMeSlackIntegrationsByIntegrationIdTestMessagesMutation();

  const testClickHandler = useCallback(() => {
    if (!conversation) {
      return;
    }

    trigger({
      integrationId,
      body: { conversation },
    });
  }, [integrationId, trigger, conversation]);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <LoadingButton
        size="small"
        variant="outlined"
        loading={isLoading}
        onClick={testClickHandler}
        sx={{ bgcolor: "white" }}
        disabled={!conversation || isLoading}
      >
        Send Test Message{" "}
      </LoadingButton>

      <Tooltip
        arrow
        title="This will send a test message to the “Default Conversation”."
        placement="top"
      >
        <InfoOutlinedIcon color="action" fontSize="small" />
      </Tooltip>
    </Stack>
  );
};
