import React, { FC } from "react";
import { Button, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link as RouterLink } from "react-router-dom";

export const KubernetesClusterAddDrawerContentSecondStep: FC = () => {
  return (
    <Typography variant="body1" color="text.secondary">
      Go to{" "}
      <Button
        to={"/settings/api-keys"}
        component={RouterLink}
        target="_blank"
        variant="text"
        endIcon={<LaunchIcon />}
        sx={{ textTransform: "none" }}
      >
        API settings page
      </Button>{" "}
      to generate and copy the API key.
    </Typography>
  );
};
