import { billingSummaryWidgetByIdSelector } from "./billingSummaryWidgetByIdSelector";
import { RootState } from "../../../../store";
import { objectToUrl } from "../../../../../components/utils/helpers/dataToUrlToDataConvertors";

export const billingSummaryWidgetBillingExplorerUrlSelector = (
  state: RootState,
  widgetId: string,
) => {
  const widget = billingSummaryWidgetByIdSelector(state, widgetId);

  if (!widget) {
    return "";
  }

  const path = objectToUrl([
    {
      dates: widget.dates,
    },
  ]);

  return `/resource-explorer/${widget.view_id}?path=${path}`;
};
