import { FC, Fragment } from "react";
import Typography from "@mui/material/Typography";
import { NestedIntegration } from "../../../../../../../../services/cloudchipr.api";

interface CostAnomalyAlertCustomMessageBeforeMessageComponentProps {
  type: NestedIntegration["type"];
}

export const CostAnomalyAlertCustomMessageBeforeMessageComponent: FC<
  CostAnomalyAlertCustomMessageBeforeMessageComponentProps
> = ({ type }) => {
  return (
    <Fragment>
      {type === "email" && (
        <Typography variant="body1">
          Subject:{" "}
          <Typography component="span" fontWeight="medium">
            Cost anomaly alert for {`{{All Resources}}`} view.
          </Typography>
        </Typography>
      )}

      <Fragment>
        <Typography variant="body1">
          Cost anomaly is detected for{" "}
          <Typography component="span" fontWeight="medium">
            {`{{All Resources}}`}
          </Typography>{" "}
          view.
        </Typography>

        <Typography variant="body1">
          The{" "}
          <Typography component="span" fontWeight="medium">
            {`{{services}}`}
          </Typography>{" "}
          listed in the attached file may require your attention due to a{" "}
          <Typography component="span" fontWeight="medium">
            {`{{20%}}`}
          </Typography>{" "}
          cost increase over the{" "}
          <Typography component="span" fontWeight="medium">
            {`{{past 5 days}}`}
          </Typography>{" "}
          compared to the{" "}
          <Typography component="span" fontWeight="medium">
            {`{{preceding 5 days}}.`}
          </Typography>{" "}
        </Typography>
      </Fragment>
    </Fragment>
  );
};
