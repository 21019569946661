import { FC } from "react";
import { PersonOffOutlined } from "@mui/icons-material";
import { Avatar, Tooltip } from "@mui/material";
import { SxProps } from "@mui/system";

interface DeletedUserAvatarProps {
  sx?: SxProps;
}

export const DeletedUserAvatar: FC<DeletedUserAvatarProps> = ({ sx }) => {
  return (
    <Tooltip arrow placement="top" title="Deleted user">
      <Avatar
        sx={{
          bgcolor: "grey.300",
          width: 28,
          height: 28,
          mr: 2,
          ...sx,
        }}
      >
        <PersonOffOutlined fontSize="small" sx={{ color: "text.secondary" }} />
      </Avatar>
    </Tooltip>
  );
};
