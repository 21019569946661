import Link, { LinkProtocolOptions } from "@tiptap/extension-link";

export const getLinkExtension = () => {
  return Link.configure({
    openOnClick: true,
    autolink: true,
    defaultProtocol: "https",
    protocols: ["http", "https"],
    isAllowedUri,
    shouldAutoLink,
  });
};

const isAllowedUri = (
  url: string,
  ctx: {
    defaultProtocol: string;
    defaultValidate(url: string): boolean;
    protocols: (string | LinkProtocolOptions)[];
  },
) => {
  try {
    // construct URL
    const parsedUrl = url.includes(":")
      ? new URL(url)
      : new URL(`${ctx.defaultProtocol}://${url}`);

    // use default validation
    if (!ctx.defaultValidate(parsedUrl.href)) {
      return false;
    }

    // disallowed protocols
    const disallowedProtocols = ["ftp", "file"];
    const protocol = parsedUrl.protocol.replace(":", "");

    if (disallowedProtocols.includes(protocol)) {
      return false;
    }

    // only allow protocols specified in ctx.protocols
    const allowedProtocols = ctx.protocols.map((p) =>
      typeof p === "string" ? p : p.scheme,
    );

    if (!allowedProtocols.includes(protocol)) {
      return false;
    }

    // disallowed domains
    const disallowedDomains = ["disallowedDomain.com"];
    const domain = parsedUrl.hostname;

    return !disallowedDomains.includes(domain);
  } catch {
    return false;
  }
};

const shouldAutoLink = (url: string) => {
  try {
    // construct URL
    const parsedUrl = url.includes(":")
      ? new URL(url)
      : new URL(`https://${url}`);

    // only auto-link if the domain is not in the disallowed list
    const disallowedDomains = ["disallowedDomain.com"];
    const domain = parsedUrl.hostname;

    return !disallowedDomains.includes(domain);
  } catch {
    return false;
  }
};
