import { getUsersMeOrganisationsCurrentWidgetsCostAnomaliesByWidgetIdSelector } from "./getUsersMeOrganisationsCurrentWidgetsCostAnomaliesByWidgetIdSelector";
import { RootState } from "../../../../../store";

export const costAnomaliesWidgetDataResponseSelector = (
  state: RootState,
  widgetId: string,
) => {
  return getUsersMeOrganisationsCurrentWidgetsCostAnomaliesByWidgetIdSelector({
    widgetId,
  })(state);
};
