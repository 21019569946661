import { taskSourceLabels, taskTargetStateLabels } from "./labels";
import { Task } from "../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";

//Keys are colId from columnDefs
export const getCSVValueGetterAndTitleByColId: Record<
  string,
  { title: string; getValue: (task: Task) => string }[]
> = {
  name: [
    {
      title: "Task Name",
      getValue: (task) => task.name,
    },
    {
      title: "Task Link",
      getValue: (task) =>
        `${window.location.origin}/task-mgmt?taskId=${task.id}`,
    },
  ],
  "target.provider_identifier": [
    {
      title: "Resource Id/Name",
      getValue: (task) => task.target?.provider_identifier ?? "-",
    },
  ],
  action: [
    {
      title: "Action",
      getValue: (task) => task.action?.name ?? "-",
    },
  ],
  status: [
    {
      title: "Status",
      getValue: (task) => task.status?.name ?? "-",
    },
  ],
  assignees: [
    {
      title: "Assignee",
      getValue: (task) =>
        task.assignees?.map((assignee) => assignee.name)?.join(", ") ?? "-",
    },
  ],
  priority: [
    {
      title: "Priority",
      getValue: (task) => task.priority?.name ?? "-",
    },
  ],
  due_date: [
    {
      title: "Due Date",
      getValue: (task) => formatDate(task.due_date ?? "", { type: "date" }),
    },
  ],
  environment: [
    {
      title: "Environment",
      getValue: (task) => task.environment?.name ?? "-",
    },
  ],
  source: [
    {
      title: "Source",
      getValue: (task) => taskSourceLabels[task.source],
    },
  ],
  created_by: [
    {
      title: "Task Created by",
      getValue: (task) =>
        task.created_by?.name ?? task.created_by?.email ?? "-",
    },
    {
      title: "Task Created at",
      getValue: (task) => formatDate(task.created_at ?? "", { type: "date" }),
    },
  ],
  modified_by: [
    {
      title: "Task Modified by",
      getValue: (task) =>
        task.modified_by?.name ?? task.modified_by?.email ?? "-",
    },
    {
      title: "Task Modified at",
      getValue: (task) => formatDate(task.modified_at ?? "", { type: "date" }),
    },
  ],
  "target.state": [
    {
      title: "Resource State",
      getValue: (task) =>
        task.target.state ? taskTargetStateLabels[task.target.state] : "-",
    },
  ],
  "target.price_per_month": [
    {
      title: "Monthly Price",
      getValue: (task) => `${task.target.price_per_month ?? ""}`,
    },
  ],
  "target.account": [
    {
      title: "Account",
      getValue: (task) => task.target.account.provider_account_name ?? "",
    },
  ],
  "target.resource_type": [
    {
      title: "Service",
      getValue: (task) =>
        task.target?.resource_type
          ? getResourceTypeName(task.target.resource_type, { type: "short" })
          : "",
    },
  ],
  "target.tags": [
    {
      title: "Tags",
      getValue: (task) =>
        task.target.tags?.map((tag) => `${tag.key} ${tag.value}`)?.join(", ") ??
        "-",
    },
  ],
  comments_count: [
    {
      title: "Comments",
      getValue: (task) => (task.comments_count ?? 0).toString(),
    },
  ],
};
