import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { getResourceDetailsResourceBillingGranularDataFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";
import { RootState } from "../../../../store";

const selector =
  cloudChiprApi.endpoints
    .postUsersMeOrganisationsCurrentResourceExplorerGranularCosts.select;

export const resourceDetailsResourceBillingGranularRequestSelector = (
  state: RootState,
  processKey: string,
) => {
  return selector(
    getResourceDetailsResourceBillingGranularDataFixedCacheKey(processKey),
  )(state);
};
