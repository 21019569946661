import { FC, Fragment } from "react";
import { Add } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import { AddViewInHierarchyDialog } from "./AddViewInHierarchyDialog";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { getUserPermissionInfoText } from "../../../../utils/helpers/getUserPermissionInfoText";
import { useViewsHierarchyContext } from "../ViewsHierarchyProvider";

export interface AddViewInHierarchyButtonProps {
  disabled?: boolean;
  loading?: boolean;
  onSubmit(viewName: string): Promise<any>;
}

export const AddViewInHierarchyButton: FC = () => {
  const { open, openDialog, closeDialog } = useDialog();
  const { loading, onSubmit, disabled } =
    useViewsHierarchyContext().actions.viewCreate;

  const userPermissionTooltipText = disabled
    ? getUserPermissionInfoText("Add Views")
    : "";

  return (
    <Fragment>
      <Tooltip title={userPermissionTooltipText}>
        <span>
          <Button
            autoFocus={false}
            variant="text"
            size="small"
            disabled={loading || disabled}
            sx={buttonStyles}
            onClick={openDialog}
            startIcon={<Add sx={{ width: 18 }} />}
          >
            Add View
          </Button>
        </span>
      </Tooltip>

      {open && (
        <AddViewInHierarchyDialog
          open
          loading={!!loading}
          onSubmit={onSubmit}
          onClose={closeDialog}
        />
      )}
    </Fragment>
  );
};

const buttonStyles = {
  ml: 1,
  width: "auto",
  whiteSpace: "nowrap",
  textTransform: "none",
  minWidth: "fit-content",
};
