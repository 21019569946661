import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { createSavingsOpportunityViewFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentOpportunityViews.select(
    createSavingsOpportunityViewFixedCacheKey,
  );

export const createSavingsOpportunityViewLoadingSelector = (
  state: RootState,
) => {
  return endpoint(state)?.isLoading;
};
