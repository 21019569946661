import { coverageSetupAggregationSelector } from "./coverageSetupAggregationSelector";
import { RootState } from "../../../../../store";
import { WidgetCoverageAggregation } from "../../../../../../services/cloudchipr.api";

export const coverageSetupHasAggregationSelector = (state: RootState) => {
  const aggregation = coverageSetupAggregationSelector(state);

  return Object.keys(aggregation ?? {}).some(
    (key) => !!aggregation?.[key as keyof WidgetCoverageAggregation],
  );
};
