import { FC } from "react";
import { Stack } from "@mui/material";
import { AwsCommitmentsOverviewCoverage } from "./components/coverage/AwsCommitmentsOverviewCoverage";
import { AwsCommitmentsOverviewCommitments } from "./components/commitments/AwsCommitmentsOverviewCommitments";

export const CommitmentsOverviewContent: FC = () => {
  return (
    <Stack direction="row" spacing={1} flex={1} justifyContent={"center"}>
      <AwsCommitmentsOverviewCoverage />
      <AwsCommitmentsOverviewCommitments />
    </Stack>
  );
};
