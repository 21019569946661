import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { createTaskCommentFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

interface CreateTaskCommentThunkArgs {
  taskId: string;
  comment: string;
}

export const createTaskCommentThunk = createAsyncThunk(
  "taskManagement/createTaskComment",
  async ({ taskId, comment }: CreateTaskCommentThunkArgs, { dispatch }) => {
    const { postUsersMeOrganisationsCurrentTasksByTaskIdComments } =
      cloudChiprApi.endpoints;

    return await dispatch(
      postUsersMeOrganisationsCurrentTasksByTaskIdComments.initiate(
        {
          taskId,
          body: {
            comment,
          },
        },
        {
          fixedCacheKey: createTaskCommentFixedCacheKey,
        },
      ),
    ).unwrap();
  },
);
