import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useDidMount } from "rooks";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { generateLiveResourcesPath } from "../utils/helpers/generateLiveResourcesPath";

export const AccountV2: FC<{ provider: ProviderType }> = ({ provider }) => {
  const navigate = useNavigate();

  useDidMount(() => {
    if (provider) {
      navigate(
        generateLiveResourcesPath({
          provider,
          activeTab: "active",
        }),
      );
    } else {
      navigate(`/`);
    }
  });

  return null;
};
