import { FC } from "react";
import { useLocation } from "react-router-dom";
import { IconButton, PopoverOrigin } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import { BillingExplorerNavigationAddActionMenu } from "./components/BillingExplorerNavigationAddActionMenu";
import { BillingExplorerVisibility } from "./components/visibility/BillingExplorerVisibility";
import { useAppSelector } from "../../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { useMenuHook } from "../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../services/permissions";
import { AppNavItemWrapper } from "../../app-nav-components/AppNavItemWrapper";
import { navigationResourceExplorerDataSelector } from "../../../../../store/navigation/selectors/resource-explorer/navigationResourceExplorerDataSelector";
import { resourceExplorerViewsHierarchyDefaultViewSelector } from "../../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyDefaultViewSelector";

export const BillingExplorerNavigation: FC = () => {
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);
  const reportsByVisibility = useAppSelector(
    navigationResourceExplorerDataSelector,
  );
  const isSingleReport =
    (reportsByVisibility.visible_to_everyone?.length ?? 0) +
      (reportsByVisibility.visible_only_to_me?.length ?? 0) ===
    1;
  const allResourcesReportId = useAppSelector(
    resourceExplorerViewsHierarchyDefaultViewSelector,
  );

  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const { can } = useAppAbility();
  const canCreateView = can("create", "resource-explorer");

  const location = useLocation();
  const resourceExplorerSelected =
    location.pathname.startsWith("/resource-explorer");

  return (
    <AppNavItemWrapper
      divider
      primary="Billing Explorer"
      to={`/resource-explorer${isSingleReport ? `/${allResourcesReportId}` : ""}`}
      Icon={BarChartOutlinedIcon}
      selected={resourceExplorerSelected}
      navCollapsed={navigationCollapsed}
      popperHeaderClickable
      secondaryAction={
        <div>
          <IconButton size="small" onClick={openMenu} disabled={!canCreateView}>
            <AddIcon fontSize="inherit" />
          </IconButton>

          <BillingExplorerNavigationAddActionMenu
            open={open}
            onCloseMenu={closeMenu}
            anchorEl={anchor}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
          />
        </div>
      }
    >
      <BillingExplorerVisibility key="billing-explorer-hierarchy" />
    </AppNavItemWrapper>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
