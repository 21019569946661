import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import moment from "moment";
import { ResourceMetricsCell } from "./metrics-cells/ResourceMetricsCell";
import { UnusedSinceCell } from "./UnusedSinceCell";
import {
  ElbInlineMetrics,
  UnusedSince,
} from "../../../../../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../../../../../utils/helpers/date-time/datetime-format";

interface TrafficMetricsWithUnusedSinceProps {
  metricsData: ElbInlineMetrics;
  unusedSince: UnusedSince;
  columnId?: string;
}

export const TrafficMetricsWithUnusedSince: FC<
  TrafficMetricsWithUnusedSinceProps
> = ({ metricsData, columnId, unusedSince }) => {
  const formattedData = moment(formatDate(unusedSince?.timestamp ?? ""));
  const tooltipTitleDate = `No Traffic Since ${formatDate(formattedData, {
    type: "date",
  })}`;

  if (!metricsData.daily_start_point && !metricsData.hourly_start_point) {
    if (unusedSince?.is_used || !unusedSince?.timestamp) {
      return (
        <UnusedSinceCell
          data={unusedSince}
          color="text.primary"
          variant="body2"
        />
      );
    }

    return (
      <Stack>
        <Typography variant="caption" color="text.secondary" lineHeight={1.25}>
          No traffic since
        </Typography>
        <UnusedSinceCell
          tooltipTitle={tooltipTitleDate}
          variant="body2"
          color="text.primary"
          data={unusedSince}
        />
      </Stack>
    );
  }

  return (
    <ResourceMetricsCell
      type="processed_bytes"
      columnId={columnId}
      metricsData={metricsData}
    />
  );
};
