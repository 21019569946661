import { createAsyncThunk } from "@reduxjs/toolkit";

import { billingExplorerSummaryDataByCurrentPayloadSelector } from "../../selectors/resource-explorer-summary/billingExplorerSummaryDataByCurrentPayloadSelector";
import { RootState } from "../../../store";
import { billingExplorerExplainWithAiDataSelector } from "../../selectors/current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiDataSelector";
import { getBillingExplorerSummaryFixedCashKeySelector } from "../../selectors/resource-explorer-summary/getBillingExplorerSummaryFixedCashKeySelector";
import { resourceExplorerPayloadDataSelector } from "../../selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerPayloadDataSelector";
import { setResourceExplorerExplainWithAIData } from "../../resourceExplorerSlice";

export const addBillingExplorerSummaryMetaDataThunk = createAsyncThunk(
  "resourceExplorer/addBillingExplorerSummaryMetaData",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const explainWithAiData = billingExplorerExplainWithAiDataSelector(state);
    const key = getBillingExplorerSummaryFixedCashKeySelector(state);
    const payload = resourceExplorerPayloadDataSelector(state);

    if (!key) {
      return;
    }
    const cashedData =
      billingExplorerSummaryDataByCurrentPayloadSelector(state);

    const updatedData = [
      {
        key: key,
        payload,
      },
    ];
    if (cashedData) {
      dispatch(setResourceExplorerExplainWithAIData(updatedData));
      return;
    }

    dispatch(
      setResourceExplorerExplainWithAIData([
        ...updatedData,
        ...explainWithAiData,
      ]),
    );
  },
);
