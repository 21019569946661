import { getAzureUsersMeOrganisationsCurrentSavingsPlansSelector } from "./getAzureUsersMeOrganisationsCurrentSavingsPlansSelector";
import { commitmentsDataGridPayloadDataSelector } from "../../../../common/payload/commitmentsDataGridPayloadDataSelector";
import { RootState } from "../../../../../../store";

export const azureSavingsPlansForTableSelector = (state: RootState) => {
  const payload = commitmentsDataGridPayloadDataSelector(state);

  return getAzureUsersMeOrganisationsCurrentSavingsPlansSelector(payload)(
    state,
  );
};
