import { FC } from "react";
import { Box } from "@mui/material";
import { AzureReservedInstancesDataGrid } from "./AzureReservedInstancesDataGrid";
import { AzureReservationsCoverageDataGrid } from "./AzureReservationsCoverageDataGrid";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { commitmentsDataViewTabSelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";
import { azureReservationsForTableLoadingSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/data/loading/azureReservationsForTableLoadingSelector";
import { CommitmentsGridLoadingLayer } from "../../../../../common/components/data-grid/CommitmentsGridLoadingLayer";

export const AzureReservationsDataGrid: FC = () => {
  const tab = useAppSelector(commitmentsDataViewTabSelector);
  const loading = useAppSelector(azureReservationsForTableLoadingSelector);

  return (
    <Box position="relative" zIndex={1} flex={1}>
      {loading && <CommitmentsGridLoadingLayer />}

      {tab === "commitment" && <AzureReservedInstancesDataGrid />}

      {tab === "coverage" && <AzureReservationsCoverageDataGrid />}
    </Box>
  );
};
