import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { billingExplorerWidgetSetupCostsSelector } from "./billingExplorerWidgetSetupCostsSelector";

export const billingExplorerWidgetSetupPrevTotalCostSelector =
  createDraftSafeSelector(
    [billingExplorerWidgetSetupCostsSelector],
    (costs) => {
      return costs.previousPeriodTotal;
    },
  );
