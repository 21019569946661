import { ChangeEvent, FC, useCallback, useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../../../../../store/hooks";
import { currentUserSelector } from "../../../../../../store/profile/currentUserSelector";
import { useDeleteUsersMeMutation } from "../../../../../../services/cloudchipr.api";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";

interface ProfileSelfDeleteConfirmationProps {
  open: boolean;
  onClose(): void;
}

export const ProfileSelfDeleteConfirmation: FC<
  ProfileSelfDeleteConfirmationProps
> = ({ open, onClose }) => {
  const { logout } = useAuth0();

  const [triggerDelete, { isLoading }] = useDeleteUsersMeMutation();
  const currentUserDetails = useAppSelector(currentUserSelector);

  const [typedEmail, setTypedEmail] = useState("");
  const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTypedEmail(e.target.value);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    await triggerDelete();
    logout({ returnTo: window.location.origin });
  }, [triggerDelete, logout]);

  const cleanDisabled =
    !currentUserDetails?.email ||
    !typedEmail ||
    typedEmail !== currentUserDetails?.email;

  return (
    <Dialog open={open}>
      <DialogTitleClosable title="Delete account?" onClose={onClose} />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This will permanently delete your account. Please type account owner’s
          email address to confirm.
          <br /> {currentUserDetails?.email}
        </DialogContentText>
        <Alert variant="outlined" severity="warning" sx={{ mt: 2 }}>
          This action is irreversible. Please be certain.
        </Alert>
        <TextField
          sx={{ mt: 4 }}
          autoFocus
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          size="small"
          variant="outlined"
          value={typedEmail}
          onChange={handleEmailChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          autoFocus
          color="tertiary"
          sx={{ textTransform: "none" }}
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={handleConfirmDelete}
          variant="contained"
          disabled={cleanDisabled}
          loading={isLoading}
          sx={{ textTransform: "none" }}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
