import { resourceDetailsTargetResourceSelector } from "./resourceDetailsTargetResourceSelector";
import { RootState } from "../../../store";

export const resourceDetailsTargetResourceIsKubernetesSelector = (
  state: RootState,
) => {
  const resource = resourceDetailsTargetResourceSelector(state);

  return !!resource?.providerMetadata?.k8s;
};
