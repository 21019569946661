import { getAzureUsersMeOrganisationsCurrentSavingsPlansSelector } from "./getAzureUsersMeOrganisationsCurrentSavingsPlansSelector";
import { RootState } from "../../../../../../store";
import { commitmentsGranularPayloadDataSelector } from "../../../../common/payload/commitmentsGranularPayloadDataSelector";

export const azureSavingsPlansForGranularSelector = (state: RootState) => {
  const payload = commitmentsGranularPayloadDataSelector(state);

  return getAzureUsersMeOrganisationsCurrentSavingsPlansSelector(payload)(
    state,
  );
};
