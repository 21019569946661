import { RootState } from "../../../../store";
import { ResourceExplorerGranularCost } from "../../../../../services/cloudchipr.api";
import { resourceDetailsResourceBillingGranularDataSelector } from "../../billing-data/resourceDetailsResourceBillingGranularDataSelector";

export const resourceDetailsCostsTabBillingGranularDataSelector = (
  state: RootState,
  processKey: string,
): ResourceExplorerGranularCost | undefined => {
  return resourceDetailsResourceBillingGranularDataSelector(state, processKey);
};
