import { YAxisPlotLinesOptions } from "highcharts";
import { Budget, DateDataPoint } from "../../../../services/cloudchipr.api";
import { moneyFormatter } from "../../../../utils/numeral/moneyFormatter";

export const generateBudgetDataOnHighChart = (
  viewPeriod?: DateDataPoint,
  budget?: Budget | null,
) => {
  if (!budget) {
    return null;
  }

  if (budget.period === "daily" && viewPeriod === "monthly") {
    return getLineData(budget.amount * 30);
  }

  if (budget.period === "monthly" && viewPeriod === "daily") {
    return getLineData(fixNumber(budget.amount / 30));
  }

  // quarterly budget
  if (budget.period === "quarterly" && viewPeriod === "daily") {
    return getLineData(fixNumber(budget.amount / 90));
  }

  if (budget.period === "quarterly" && viewPeriod === "monthly") {
    return getLineData(fixNumber(budget.amount / 3));
  }

  // annually budget
  if (budget.period === "annually" && viewPeriod === "daily") {
    return getLineData(fixNumber(budget.amount / 386));
  }

  if (budget.period === "annually" && viewPeriod === "monthly") {
    return getLineData(fixNumber(budget.amount / 12));
  }

  return getLineData(budget.amount);
};

const getLineData = (amount: number): YAxisPlotLinesOptions[] => [
  {
    id: "Budget",
    value: amount,
    color: "red",
    width: 1,
    dashStyle: "Dash",
    zIndex: 5,
    label: {
      x: 0,
      text: `${moneyFormatter(amount)}`,
      style: { color: "red" },
    },
  },
];

const fixNumber = (num: number) => +num.toFixed(2);
