import { currentBudgetsDataSelector } from "./currentBudgetsDataSelector";
import { RootState } from "../../store";

export const budgetByIdSelector = (
  state: RootState,
  budgetId: string | null,
) => {
  const budgets = currentBudgetsDataSelector(state);

  return budgets?.find((budget) => budget.id === budgetId);
};
