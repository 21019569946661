import { FC, Fragment, useCallback } from "react";
import { BillingSummaryWidgetSetupContent } from "./content/BillingSummaryWidgetSetupContent";
import { BillingSummaryDateRangeSelect } from "./app-bar/BillingSummaryDateRangeSelect";
import { BillingSummaryVisualizationSection } from "./app-bar/BillingSummaryVisualizationSection";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import { WidgetsViewsHierarchySelect } from "../common/widgets-views-hierarchy-select/WidgetsViewsHierarchySelect";
import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { billingSummaryREViewChangeThunk } from "../../../../../../../../store/dashboards/thunks/widgets/billing-summary/widget-change/billingSummaryREViewChangeThunk";
import { billingSummarySumSetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/billing-summary/billingSummarySumSetupPropertyByKeySelector";
import { buildBillingSummaryWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/billing-summary/actions/buildBillingSummaryWidgetThunk";
import { billingSummarySubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/billing-summary/billingSummarySubmitButtonDisabledSelector";

interface BillingSummarySetupProps {
  onClose(): void;
  onSubmit(): void;
}

export const BillingSummaryWidgetSetup: FC<BillingSummarySetupProps> = ({
  onClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();

  const viewId = useAppSelector(
    billingSummarySumSetupPropertyByKeySelector("viewId"),
  );

  const setupId = useAppSelector(
    billingSummarySumSetupPropertyByKeySelector("id"),
  );
  const disabled = useAppSelector(billingSummarySubmitButtonDisabledSelector);

  const viewChangeHandler = useCallback(
    (id: string) => {
      dispatch(billingSummaryREViewChangeThunk(id));
    },
    [dispatch],
  );

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(
      buildBillingSummaryWidgetThunk(),
    ).unwrap();
    onSubmit();

    if (responseId && !setupId) {
      scrollPageToBottom();
    }
  }, [dispatch, setupId, onSubmit]);

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />

      <WidgetSetupLayout
        empty={!viewId ? "Select Billing Report to get started." : ""}
        appBar={
          <Fragment>
            <WidgetsViewsHierarchySelect
              selectedItemId={viewId}
              onChange={viewChangeHandler}
            />

            <BillingSummaryDateRangeSelect />
            <BillingSummaryVisualizationSection />
          </Fragment>
        }
      >
        <BillingSummaryWidgetSetupContent />
      </WidgetSetupLayout>
    </Fragment>
  );
};
