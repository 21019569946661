import { FC, useMemo } from "react";
import { BillingSummaryDataGridType } from "./utils/types";
import { BillingSummaryLiveFilteredTitle } from "./BillingSummaryLiveFilteredTitle";
import { getTrendTooltipTitle } from "../../../../../../utils/helpers/trend-tooltip-title/getTrendTooltipTitle";
import { WidgetNumeralViewItem } from "../../common/view-type/WidgetNumeralViewItem";
import { DateLabelNullable } from "../../../../../../../../../services/cloudchipr.api";

interface BillingSummaryWidgetViewItemProps extends BillingSummaryDataGridType {
  dateLabel?: DateLabelNullable;
  itemsCount: number;
  totalCostUrl?: string;
}

export const BillingSummaryWidgetViewItem: FC<
  BillingSummaryWidgetViewItemProps
> = ({
  trendDetails,
  cost,
  label,
  billingSummaryFilterType,
  dateLabel,
  totalCostUrl,
  itemsCount,
}) => {
  const tooltipTitle = useMemo(
    () =>
      getTrendTooltipTitle(
        trendDetails?.currentDates?.from,
        trendDetails?.currentDates?.to,
        trendDetails?.previousDates?.from,
        trendDetails?.previousDates?.to,
        billingSummaryFilterType,
        dateLabel,
      ),
    [trendDetails, billingSummaryFilterType, dateLabel],
  );

  const costUrl =
    billingSummaryFilterType === "total_cost" ? totalCostUrl : undefined;

  const customLabel =
    billingSummaryFilterType === "live_usage_total_cost"
      ? BillingSummaryLiveFilteredTitle
      : undefined;

  const postfix =
    billingSummaryFilterType === "live_usage_total_cost" ? "/mo" : undefined;

  const showApproximate =
    billingSummaryFilterType === "forecasted_month_end_cost" ||
    billingSummaryFilterType === "forecasted_quarter_end_cost";

  return (
    <WidgetNumeralViewItem
      key={billingSummaryFilterType}
      label={label}
      costUrl={costUrl}
      CustomLabel={customLabel}
      cost={cost}
      postfix={postfix}
      trend={trendDetails?.trend ? trendDetails.trend : null}
      itemsCount={itemsCount}
      tooltipTitle={tooltipTitle}
      approximate={showApproximate}
    />
  );
};
