import { FC, memo, ReactElement, useCallback } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AreaChartSvgIcon from "../../../../assets/images/icons/AreaChartSvgIcon";

export type HighchartsChartType = "stackedColumn" | "column" | "line" | "area";

export const highchartsChartTypeIcons: Record<
  HighchartsChartType,
  ReactElement
> = {
  line: <ShowChartIcon color="action" fontSize="medium" />,
  area: <AreaChartSvgIcon color="action" fontSize="medium" />,
  column: <BarChartIcon color="action" fontSize="medium" />,
  stackedColumn: <StackedBarChartIcon color="action" fontSize="medium" />,
};

export interface HighchartsChartTypeSelectProps {
  selectionType?: "button" | "select";
  availableTypes?: HighchartsChartType[];
  size?: "xsmall" | "small";
  chartType?: HighchartsChartType;
  onChartTypeChange(type: HighchartsChartType): void;
}

export const HighchartsChartTypeSelect: FC<HighchartsChartTypeSelectProps> =
  memo(
    ({
      onChartTypeChange,
      availableTypes,
      selectionType = "button",
      size = "small",
      chartType,
    }) => {
      const chartTypeChangeHandler = useCallback(
        (_: unknown, chartType: HighchartsChartType) =>
          onChartTypeChange(chartType),
        [onChartTypeChange],
      );

      const chartTypeSelectHandler = useCallback(
        (event: any) => onChartTypeChange(event.target.value),
        [onChartTypeChange],
      );

      if (!availableTypes) {
        return null;
      }

      if (selectionType === "select") {
        return (
          <FormControl size={size}>
            <Select
              value={chartType}
              size="small"
              sx={selectStyles}
              MenuProps={selectMenuProps}
              onChange={chartTypeSelectHandler}
              renderValue={(type) => (
                <Stack alignItems="center" height="inherit">
                  {highchartsChartTypeIcons[type]}
                </Stack>
              )}
            >
              {availableTypes.map((type) => (
                <MenuItem value={type} key={type}>
                  {highchartsChartTypeIcons[type]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }

      return (
        <ToggleButtonGroup
          exclusive
          size="small"
          value={chartType}
          onChange={chartTypeChangeHandler}
        >
          {availableTypes.map((type) => (
            <ToggleButton value={type} key={type}>
              {highchartsChartTypeIcons[type]}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      );
    },
  );

const selectMenuProps: SelectProps["MenuProps"] = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: 5,
    horizontal: "left",
  },
};

const selectStyles = {
  bgcolor: "white",
  "& .MuiSelect-select": { px: 0.5 },
};
