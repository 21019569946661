import { FC } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAppSelector } from "../../../../store/hooks";
import { executionLogTotalPriceSelector } from "../../../../store/execution-log/selectors/executionLogTotalPriceSelector";
import { executionLogDetailsLoadingSelector } from "../../../../store/execution-log/selectors/executionLogDetailsLoadingSelector";
import { money } from "../../../../utils/numeral/money";

export const ExecutionLogTotal: FC = () => {
  const total = useAppSelector(executionLogTotalPriceSelector);
  const totalLoading = useAppSelector(executionLogDetailsLoadingSelector);
  const enableExecutionLogTotal = useFlag("EnableExecutionLogTotal");

  if (!enableExecutionLogTotal) {
    return;
  }

  return (
    <Stack
      px={1}
      py={0.5}
      direction="row"
      alignItems="center"
      spacing={1}
      border={1}
      borderRadius={1}
      borderColor="grey.300"
      bgcolor="grey.100"
    >
      <Stack direction="row" alignItems="center" spacing={0.25}>
        <Typography variant="caption" color="text.secondary">
          Cost Saved
        </Typography>
        <Tooltip
          arrow
          placement="top"
          title="Total cost savings for all successful executions in the selected time range."
        >
          <InfoOutlinedIcon color="action" sx={{ fontSize: 16 }} />
        </Tooltip>
      </Stack>

      <Typography fontWeight="medium" component="span" variant="body1">
        {totalLoading ? (
          <Skeleton
            variant="text"
            height={24}
            width={60}
            sx={{ display: "inline-block" }}
          />
        ) : (
          money(total)
        )}
      </Typography>
    </Stack>
  );
};
