import { FC, useCallback } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { CreateBudgetButton } from "./CreateBudgetButton";
import { ResourceExplorerBudget } from "./ResourceExplorerBudget";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { resourceExplorerIdSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerIdSelector";
import { resourceExplorerViewBudgetSelector } from "../../../../../../../../../store/resource-explorer/selectors/budget/resourceExplorerViewBudgetSelector";
import { resourceExplorerByIdLoadingSelector } from "../../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerByIdLoadingSelector";
import { toggleAlertsOpenDrawer } from "../../../../../../../../../store/alerts/alertsSlice";
import { resetCurrentBudget } from "../../../../../../../../../store/budgets/budgetsSlice";
import { BudgetsAndAlertsDrawer } from "../../../../../../../budgets-and-alerts/components/drawer/BudgetsAndAlertsDrawer";

export const ResourceExplorerBudgets: FC = () => {
  const viewId = useAppSelector(resourceExplorerIdSelector);
  const dispatch = useAppDispatch();

  const data = useAppSelector(resourceExplorerViewBudgetSelector);
  const isLoading = useAppSelector((state) =>
    resourceExplorerByIdLoadingSelector(state, viewId),
  );

  const handleBudgetsDrawerClose = useCallback(() => {
    dispatch(toggleAlertsOpenDrawer());
    dispatch(resetCurrentBudget());
  }, [dispatch]);

  const content = data ? (
    <ResourceExplorerBudget />
  ) : (
    <CreateBudgetButton viewId={viewId} />
  );

  return (
    <Stack>
      {isLoading ? <CircularProgress /> : content}
      <BudgetsAndAlertsDrawer onClose={handleBudgetsDrawerClose} />
    </Stack>
  );
};
