import { createAsyncThunk } from "@reduxjs/toolkit";
import { getKubernetesLiveUsageMgmtResourcesWithFiltersThunk } from "./getKubernetesLiveUsageMgmtResourcesWithFiltersThunk";
import { getKubernetesLiveUsageMgmtResourcesWithOutFiltersByResourceTypeThunk } from "./getKubernetesLiveUsageMgmtResourcesWithOutFiltersByResourceTypeThunk";
import { kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector } from "../../selectors/resource-type-data/kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector } from "../../selectors/store-selectors/kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector";
import { kubernetesLiveUsageMgmtSelectedClusterIdsSelector } from "../../selectors/store-selectors/kubernetesLiveUsageMgmtSelectedClusterIdsSelector";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const getKubernetesLiveUsageMgmtResourceTypeDataThunk = createAsyncThunk(
  "liveUsageMgmt/getKubernetesLiveUsageMgmtResourceTypeData",
  async (resourceType: ResourceType, { dispatch, getState }) => {
    const state = getState() as RootState;

    const showingAllResources =
      kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector(
        state,
        resourceType,
      );
    const filterTemplatesEnabled =
      kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector(state);

    const accountIds = kubernetesLiveUsageMgmtSelectedClusterIdsSelector(state);

    const type =
      getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;

    if (!accountIds) {
      return;
    }

    if (showingAllResources || !filterTemplatesEnabled) {
      await dispatch(
        getKubernetesLiveUsageMgmtResourcesWithOutFiltersByResourceTypeThunk(
          type,
        ),
      );
    } else {
      await dispatch(getKubernetesLiveUsageMgmtResourcesWithFiltersThunk(type));
    }
  },
);
