import { FC, MouseEvent } from "react";
import { DPMonth, useContextMonthsPropGetters } from "@rehookify/datepicker";
import { Chip } from "@mui/material";

interface DateRangeCalendarMonthProps {
  dpMonth: DPMonth;
  onSelect(): void;
  setOffsetDate(date?: Date): void;
}

export const DateRangeCalendarMonth: FC<DateRangeCalendarMonthProps> = ({
  dpMonth,
  onSelect,
  setOffsetDate,
}) => {
  const { monthButton } = useContextMonthsPropGetters();

  const buttonProps = monthButton(dpMonth, {
    onClick(_?: MouseEvent<HTMLElement>, date?: Date) {
      setOffsetDate(date);
      onSelect();
    },
  });

  return (
    <Chip
      label={dpMonth.month}
      size="small"
      color="primary"
      {...buttonProps}
      sx={{
        border: "none",
        color: dpMonth.selected ? "primary" : "text.secondary",
      }}
      variant={dpMonth.selected ? "filled" : "outlined"}
    />
  );
};
