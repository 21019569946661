import { FC, useCallback } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { resourceExplorerGroupingSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { resourceExplorerGroupValuesSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupValuesSelector";
import { resourceExplorerGroupByChangeThunk } from "../../../../../../../../store/resource-explorer/thunks/resource-explorer/resourceExplorerGroupByChangeThunk";
import { ResourceExplorerGroupingNullable } from "../../../../../../../../services/cloudchipr.api";
import { GroupingSelector } from "../../../../../../common/resource-explorer-grouping-selector-v2/GroupingSelector";

export const BillingExplorerGroupBySelector: FC = () => {
  const dispatch = useAppDispatch();

  const groupBy = useAppSelector(resourceExplorerGroupingSelector);
  const groupValues = useAppSelector(resourceExplorerGroupValuesSelector);
  const enableBillingExplorerPathChangeFromMainGrouping = useFlag(
    "EnableBillingExplorerPathChangeFromMainGrouping",
  );

  const groupingChangeHandler = useCallback(
    (
      newGroupBy: ResourceExplorerGroupingNullable,
      newGroupValues?: string[],
    ) => {
      const groupByIsNotChanged = groupBy === newGroupBy;
      const valuesAreTheSame =
        groupValues?.join("") === newGroupValues?.join("");

      if (groupByIsNotChanged && (!newGroupValues || valuesAreTheSame)) {
        return;
      }

      dispatch(
        resourceExplorerGroupByChangeThunk({
          grouping: newGroupBy,
          groupValues: newGroupValues,
          enableBillingExplorerPathChangeFromMainGrouping,
        }),
      );
    },
    [
      dispatch,
      groupBy,
      groupValues,
      enableBillingExplorerPathChangeFromMainGrouping,
    ],
  );

  return (
    <GroupingSelector
      value={groupBy}
      triggerSize="xsmall"
      dropDownMaxHeight={600}
      groupValues={groupValues}
      onChange={groupingChangeHandler}
    />
  );
};
