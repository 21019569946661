import { ValueGetterParams } from "ag-grid-community";
import { capitalize } from "@mui/material";
import { Task } from "../../../../../../services/cloudchipr.api";

export const taskModifiedByFilterValueGetter = ({
  data,
}: ValueGetterParams<Task>) => {
  const modifiedBy = data?.modified_by?.name ?? data?.modified_by?.email;

  if (!modifiedBy) {
    return;
  }

  return modifiedBy
    .split(" ")
    .map((name) => capitalize(name))
    .join(" ");
};
