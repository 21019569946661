import { FC, Fragment, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { commitmentsDatesSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { generateFromToFromDates } from "../../../../utilization-and-coverage/utils/helpers/generateFromToFromDates";
import { CommitmentsPageHeaderMetadataCard } from "../../../../common/components/CommitmentsPageHeaderMetadataCard";
import { money } from "../../../../../../../utils/numeral/money";

interface CommitmentsOverviewMetadataLeftSideProps {
  realCost: number | null;
  totalCommitments: number | null;
  totalNotCovered: number | null;
  savingsPlanTotalCommitments: number | null;
  reservationTotalCommitments: number | null;
  loading: boolean;
}

export const CommitmentsOverviewMetadataLeftSide: FC<
  CommitmentsOverviewMetadataLeftSideProps
> = ({
  realCost,
  totalCommitments,
  totalNotCovered,
  savingsPlanTotalCommitments,
  reservationTotalCommitments,
  loading,
}) => {
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );
  const dates = useAppSelector(commitmentsDatesSelector);
  const datesFromTo = useMemo(() => {
    return generateFromToFromDates(dates, quarterStartMonth);
  }, [dates, quarterStartMonth]);

  return (
    <Stack spacing={1}>
      <CommitmentsPageHeaderMetadataCard
        primary="Real Cost"
        loading={loading}
        value={realCost === null ? "N/A" : money(realCost)}
        tooltip={`Total costs paid for all resources eligible for commitments (Savings Plans, Reserved Instances) including RI fees and Savings Plan Recurring Fees.`}
      />
      <Stack direction="row" spacing={1}>
        <CommitmentsPageHeaderMetadataCard
          primary="Not Covered"
          loading={loading}
          value={totalNotCovered === null ? "N/A" : money(totalNotCovered)}
          tooltip={`On-Demand spend of resources not covered by Commitments for date range ${datesFromTo}`}
        />
        <CommitmentsPageHeaderMetadataCard
          primary="Commitments"
          loading={loading}
          value={realCost === null ? "N/A" : money(totalCommitments)}
          tooltip={
            <Fragment>
              <Typography variant="caption" fontWeight="medium">
                Total amount paid for commitments {datesFromTo}.
              </Typography>
              <li>
                Savings Plan Recurring Fees:
                {savingsPlanTotalCommitments === null
                  ? " N/A"
                  : ` ${money(savingsPlanTotalCommitments)}`}
              </li>

              <li>
                RI Fees:
                {reservationTotalCommitments === null
                  ? " N/A"
                  : ` ${money(reservationTotalCommitments)}`}
              </li>
            </Fragment>
          }
        />
      </Stack>
    </Stack>
  );
};
