import { ColDef } from "ag-grid-community";
import { TaskAction } from "../../../../../../services/cloudchipr.api";

export const actionComparatorFn =
  (actions?: TaskAction[]): ColDef["comparator"] =>
  (_, __, nodeA, nodeB) => {
    const current = nodeA?.data?.action?.id ?? "";
    const next = nodeB?.data?.action?.id ?? "";

    const currentIndex = current
      ? actions?.findIndex((item) => item.id === current)
      : Infinity;
    const nextIndex = next
      ? actions?.findIndex((item) => item.id === next)
      : Infinity;

    return (currentIndex ?? 0) - (nextIndex ?? 0);
  };
