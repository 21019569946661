import { FC, Fragment, useCallback, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { type GridApi } from "ag-grid-community";
import { getAlertsCostAnomalyDataGridColumnDefs } from "./components/alertsCostAnomalyGridColumnDefs";
import { useAppSelector } from "../../../../../../store/hooks";
import { costAnomalyAlertsDataGridDataSelector } from "../../../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyAlertsDataGridDataSelector";
import { getCostAnomalyAlertsLoadingSelector } from "../../../../../../store/alerts/selectors/cost-anomaly-alerts/getCostAnomalyAlertsLoadingSelector";
import { AlertsEmptyPage } from "../common/AlertsEmptyPage";
import { AgDataGrid } from "../../../../common/ag-grid/AgDataGrid";
import { resourceExplorerHierarchyVisibilitiesDataLoadingSelector } from "../../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesDataLoadingSelector";
import { resourceExplorerGroupingLabelsSelector } from "../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelsSelector";
import { NoFilteredDataOverlay } from "../../../../common/ag-grid/components/NoFilteredDataOverlay";
import { BudgetAndAlertsAgGridToolbar } from "../common/BudgetAndAlertsAgGridToolbar";
import { budgetAndAlertsDefaultColDef } from "../common/utils/constants/constants";

export const AlertsCostAnomaly: FC = () => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const data = useAppSelector(costAnomalyAlertsDataGridDataSelector);
  const emptyData = !data?.length;
  const costAnomalyLoading = useAppSelector(
    getCostAnomalyAlertsLoadingSelector,
  );
  const billingExplorerLoading = useAppSelector(
    resourceExplorerHierarchyVisibilitiesDataLoadingSelector,
  );
  const billingExplorerGroupingLabels = useAppSelector(
    resourceExplorerGroupingLabelsSelector,
  );

  const columnDefs = useMemo(
    () => getAlertsCostAnomalyDataGridColumnDefs(billingExplorerGroupingLabels),
    [billingExplorerGroupingLabels],
  );

  const onFilterChanged = useCallback(() => {
    if (gridApi) {
      const rowCount = gridApi.getDisplayedRowCount();
      if (rowCount === 0) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [gridApi]);

  return (
    <Stack height="100%">
      {emptyData && !costAnomalyLoading && <AlertsEmptyPage />}

      {!emptyData && (
        <Fragment>
          <BudgetAndAlertsAgGridToolbar gridApi={gridApi} />

          <AgDataGrid
            key={String(billingExplorerLoading)}
            rowData={data}
            rowHeight={58}
            headerHeight={56}
            columnDefs={columnDefs}
            gridApiSetter={setGridApi}
            onFilterChanged={onFilterChanged}
            defaultColDef={budgetAndAlertsDefaultColDef}
            noRowsOverlayComponent={NoFilteredDataOverlay}
            loading={costAnomalyLoading || billingExplorerLoading}
            configToRememberStateInStorage={configToRememberStateInStorage}
          />
        </Fragment>
      )}
    </Stack>
  );
};

const configToRememberStateInStorage = {
  uniqueNameAsKey: "costAnomalyAlertsTable",
};
