import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { coverageSetupAggregationSelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/coverage/aggregation/coverageSetupAggregationSelector";

import { coverageWidgetSetupDataSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/coverage/setup/coverageWidgetSetupDataSelector";
import { widgetSetupWidgetDatesSelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/common/widgetSetupWidgetDatesSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { CoverageWidgetCosts } from "../../../../../../../widgets/coverage/components/CoverageWidgetCosts";

export const CoverageWidgetSetupContentCosts: FC = () => {
  const aggregations = useAppSelector(coverageSetupAggregationSelector);
  const data = useAppSelector(coverageWidgetSetupDataSelector);
  const dates = useAppSelector(widgetSetupWidgetDatesSelector);
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  return (
    <CoverageWidgetCosts
      aggregation={aggregations}
      data={data}
      dates={dates}
      quarterStartMonth={quarterStartMonth}
    />
  );
};
