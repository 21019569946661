import { FC, Fragment, useCallback, useState } from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { goToPrevStep } from "../../../../store/account-setup/accountSetupSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { createAccountAttemptThunk } from "../../../../store/account-setup/thunks/createAccountAttemptThunk";
import { PermissionOptions } from "../../common/connectors/components/PermissionOptions";
import { GcpConnectionTypes } from "../../common/connectors/components/gcp/components/GcpConnectionTypes";
import { providerSelector } from "../../../../store/account-setup/selectors/providerSelector";

export const PermissionsStep: FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const provider = useAppSelector(providerSelector);

  const prevHandler = useCallback(() => dispatch(goToPrevStep()), [dispatch]);
  const nextHandler = useCallback(() => {
    setLoading(true);
    dispatch(createAccountAttemptThunk());
  }, [dispatch]);

  return (
    <Fragment>
      {provider === "gcp" && <GcpConnectionTypes />}
      <PermissionOptions provider={provider} />
      <Stack
        mt={4}
        gap={2}
        direction="row"
        alignItems="end"
        justifyContent="end"
      >
        <Button variant="outlined" onClick={prevHandler}>
          Previous
        </Button>
        <LoadingButton
          loading={loading}
          disabled={loading}
          onClick={nextHandler}
          variant="contained"
          color="primary"
        >
          Next
        </LoadingButton>
      </Stack>
    </Fragment>
  );
};
