import { FC, useCallback } from "react";
import { Card, Collapse, Stack } from "@mui/material";
import { useToggle } from "rooks";
import { LiveUsageMgmtResourceCardHeader } from "./resource-card-header/LiveUsageMgmtResourceCardHeader";
import { LiveUsageMgmtResourceCardGrid } from "./resource-card-content/resource-card-data/resource-card-grid/LiveUsageMgmtResourceCardGrid";
import { LiveUsageMgmtResourceCardDialog } from "./LiveUsageMgmtResourceCardDialog";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { useResourceCardCollapsedState } from "../../utils/hooks/useResourceCardState.hook";
import { useAppSelector } from "../../../../../store/hooks";
import { liveUsageMgmtAccountIdsSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";

interface ResourceCardProps {
  singleView?: boolean;
  initialCollapsed?: boolean;
  resourceType: ResourceType;
}

export const LiveUsageMgmtResourceCard: FC<ResourceCardProps> = ({
  resourceType,
  singleView,
  initialCollapsed,
}) => {
  const accountIds = useAppSelector(liveUsageMgmtAccountIdsSelector) ?? [];
  const { collapsedState, setCollapsedState } = useResourceCardCollapsedState(
    resourceType,
    accountIds,
    initialCollapsed,
  );

  const [collapsed, toggleCollapsed] = useToggle(collapsedState);
  const [fullScreen, toggleFullScreen] = useToggle();

  const onCollapse = useCallback(() => {
    setCollapsedState(!collapsed);
    toggleCollapsed(!collapsed);
  }, [collapsed, setCollapsedState, toggleCollapsed]);

  if (singleView) {
    return (
      <Stack width="100%">
        <LiveUsageMgmtResourceCardGrid resourceType={resourceType} fullHeight />
      </Stack>
    );
  }

  return (
    <Card variant="outlined" sx={{ borderColor: "primary.light" }}>
      <LiveUsageMgmtResourceCardHeader
        resourceType={resourceType}
        onCollapse={onCollapse}
        onFullScreen={toggleFullScreen}
        collapsed={collapsed}
      />

      <Collapse in={singleView || !collapsed}>
        <LiveUsageMgmtResourceCardGrid resourceType={resourceType} />
      </Collapse>

      {fullScreen && (
        <LiveUsageMgmtResourceCardDialog
          resourceType={resourceType}
          onFullScreen={toggleFullScreen}
        />
      )}
    </Card>
  );
};
