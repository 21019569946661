import { FC, Fragment, useCallback, useMemo } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
} from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { SxProps } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { TypographyWithTooltip } from "../../../common/TypographyWithTooltip";

interface AppNavItemWrapperPopperHeaderProps {
  to: string;
  onClose(): void;
  Icon: SvgIconComponent;
  popperHeaderClickable?: boolean;
  primary: ListItemTextProps["primary"];
  secondaryAction?: ListItemProps["secondaryAction"];
}

export const AppNavItemWrapperPopperHeader: FC<
  AppNavItemWrapperPopperHeaderProps
> = ({
  primary,
  Icon,
  secondaryAction,
  popperHeaderClickable,
  to,
  onClose,
}) => {
  const navigate = useNavigate();
  const clickHandler = useCallback(() => {
    navigate(to);
    onClose();
  }, [navigate, to, onClose]);

  const navItemContent = useMemo(
    () => (
      <Fragment>
        <ListItemIcon sx={{ minWidth: 30 }}>
          <Icon
            fontSize="small"
            sx={{ color: popperHeaderClickable ? "black" : "grey.600" }}
          />
        </ListItemIcon>

        <ListItemText
          primary={
            <TypographyWithTooltip
              pr={5}
              title={primary}
              color={!popperHeaderClickable ? "grey.600" : undefined}
              variant="inherit"
            />
          }
        />
      </Fragment>
    ),
    [primary, Icon, popperHeaderClickable],
  );

  if (popperHeaderClickable) {
    return (
      <ListItem sx={stickyStyles} secondaryAction={secondaryAction}>
        <ListItemButton onClick={clickHandler} sx={listItemButtonSx}>
          {navItemContent}
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <ListItem
      sx={{ ...stickyStyles, pt: 1.25, pb: 1.25 }}
      secondaryAction={secondaryAction}
    >
      {navItemContent}
    </ListItem>
  );
};

const listItemButtonSx = {
  borderRadius: 2,
  px: 1,
  py: 0.5,

  "&:hover": { bgcolor: "grey.300" },
};

const stickyStyles: SxProps = {
  position: "absolute",
  top: 0,
  left: -1,
  zIndex: 2,
  bgcolor: "grey.200",
  px: 1.25,
  pt: 1,
  pb: 0.5,
};
