import { FC } from "react";
import { useLocation } from "react-router-dom";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import { IconButton, PopoverOrigin } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffectOnceWhen } from "rooks";
import { DashboardVisibility } from "./components/visibility/DashboardVisibility";
import { DashboardNavigationAddActionMenu } from "./components/DashboardNavigationAddActionMenu";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { AppNavItemWrapper } from "../../app-nav-components/AppNavItemWrapper";
import { useMenuHook } from "../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../services/permissions";
import { getDashboardsVisibilityHierarchyThunk } from "../../../../../store/dashboards/thunks/dashboard-hierarchy-visibility/getDashboardsVisibilityHierarchyThunk";

export const DashboardNavigation: FC = () => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const dispatch = useAppDispatch();
  const { cannot, can } = useAppAbility();

  const location = useLocation();
  const selected = location.pathname.startsWith("/dashboards");

  const cannotCreateDashboard = cannot("create", "dashboard");
  const dashboardsAvailable = can("view", "dashboard");

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  useEffectOnceWhen(() => {
    dispatch(getDashboardsVisibilityHierarchyThunk());
  }, dashboardsAvailable);

  return (
    <AppNavItemWrapper
      divider
      primary="Dashboards"
      to="/dashboards"
      popperHeaderClickable
      Icon={DashboardCustomizeOutlinedIcon}
      selected={selected}
      navCollapsed={navigationCollapsed}
      secondaryAction={
        <div>
          <IconButton
            size="small"
            disabled={cannotCreateDashboard}
            onClick={openMenu}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>

          <DashboardNavigationAddActionMenu
            open={open}
            onCloseMenu={closeMenu}
            anchorEl={anchor}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
          />
        </div>
      }
    >
      <DashboardVisibility />
    </AppNavItemWrapper>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
