import { ValueGetterParams } from "ag-grid-community";
import { UtilizationAlertDataGridDataType } from "../../../../../../../../store/alerts/utils/types/types";
import { joinThresholds } from "../../../cost-anomaly/utils/helpers/joinThresholds";

export const utilizationAlertsThresholdValueGetter = ({
  data,
}: ValueGetterParams<UtilizationAlertDataGridDataType>) => {
  const thresholds = data?.thresholds;

  if (!thresholds) {
    return;
  }

  const thresholdTitles = joinThresholds(thresholds);

  return thresholdTitles.join(" , ");
};
