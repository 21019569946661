import { ColDef } from "ag-grid-community";
import { TaskStatus } from "../../../../../../services/cloudchipr.api";

export const statusComparatorFn =
  (statuses?: TaskStatus[]): ColDef["comparator"] =>
  (_, __, nodeA, nodeB) => {
    const current = nodeA?.data?.status?.id ?? "";
    const next = nodeB?.data?.status?.id ?? "";

    const currentIndex = current
      ? statuses?.findIndex((item) => item.id === current)
      : Infinity;
    const nextIndex = next
      ? statuses?.findIndex((item) => item.id === next)
      : Infinity;

    return (currentIndex ?? 0) - (nextIndex ?? 0);
  };
