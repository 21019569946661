import { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
interface AskAiSuggestionItemProps {
  title: string;
  onClick(suggestion: string): void;
}
export const AskAiSuggestionItem: FC<AskAiSuggestionItemProps> = ({
  title,
  onClick,
}) => {
  const clickHandler = useCallback(() => {
    onClick(title);
  }, [onClick, title]);

  return (
    <Stack
      p={1}
      direction="row"
      justifyContent="space-between"
      bgcolor="grey.100"
      borderRadius={2}
      sx={{
        cursor: "pointer",
      }}
      alignItems="center"
      onClick={clickHandler}
    >
      <Stack direction="row" spacing={1}>
        <LightbulbOutlinedIcon fontSize="small" />
        <Typography variant="subtitle2" fontWeight="normal">
          {title}
        </Typography>
      </Stack>

      <ArrowForwardIcon fontSize="small" />
    </Stack>
  );
};
