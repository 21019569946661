import { BillingSummaryDataGridType } from "../../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/billing-summary/content/utils/types";
import { BillingSummaryWidgetFilterType } from "../../../../../utils/constants/billingSummarySetupDefaultData";

export interface BillingSummaryFilterTypeDataArgs {
  billingSummaryFilterType: BillingSummaryWidgetFilterType;
  label: string;
  cost: number | null;
  trend?: number | null;
  currentDatesFrom?: string;
  currentDatesTo?: string;
  previousDatesFrom?: string;
  previousDatesTo?: string;
}

export const generateBillingSummaryFilterTypeData = (
  args: BillingSummaryFilterTypeDataArgs,
): BillingSummaryDataGridType => {
  const {
    billingSummaryFilterType,
    label,
    cost,
    currentDatesFrom,
    currentDatesTo,
    previousDatesFrom,
    previousDatesTo,
  } = args;
  const trend = args.trend ?? null;

  return {
    id: billingSummaryFilterType,
    billingSummaryFilterType,
    label,
    cost,
    trendDetails:
      trend !== null
        ? {
            trend: trend ?? 0,
            currentDates: {
              from: currentDatesFrom,
              to: currentDatesTo,
            },
            previousDates: {
              from: previousDatesFrom,
              to: previousDatesTo,
            },
          }
        : null,
  };
};
