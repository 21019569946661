import { billingExplorerWidgetDataByWidgetIdSelector } from "./billingExplorerWidgetDataByWidgetIdSelector";
import { RootState } from "../../../../../store";

export const billingExplorerWidgetTotalCostDateDetailsSelector = (
  state: RootState,
  widgetId: string,
) => {
  return billingExplorerWidgetDataByWidgetIdSelector(state, widgetId)
    ?.total_cost_date_details;
};
