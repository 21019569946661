import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { coverageWidgetByIdSelector } from "../../../../selectors/widgets/coverage/widget-view/coverageWidgetByIdSelector";
import { CoverageWidgetSetupType } from "../../../../utils/types/setups/coverageWidgetSetupType";
import { getCoverageWidgetSetupDataThunk } from "../getCoverageWidgetSetupDataThunk";

interface EditCoverageChangesWidgetArgs {
  widgetId: string;
  doNotSetWidgetType?: boolean;
}

export const editCoverageChangesWidgetThunk = createAsyncThunk(
  "dashboards/editCoverageChangesWidget",
  async (
    { widgetId, doNotSetWidgetType }: EditCoverageChangesWidgetArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = coverageWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    dispatch(
      setWidgetSetup({
        widgetType: doNotSetWidgetType ? undefined : "coverage",
        id: widget.id,
        name: widget.name,
        dates: widget.dates,
        grouping: widget.grouping,
        visualization: widget.visualization,
        aggregation: widget.aggregation,
        trendType: widget.trend_type,
        commitmentType: widget.commitment_type,
        providerOrganisationId: widget.provider_organisation_id,
      } as CoverageWidgetSetupType),
    );

    dispatch(getCoverageWidgetSetupDataThunk());
  },
);
