import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetType } from "../../../../../services/cloudchipr.api";
import { buildBillingExplorerWidgetThunk } from "../billing-explorer-widget/actions/buildBillingExplorerWidgetThunk";
import { buildCommitmentUtilizationWidgetThunk } from "../commitment-utilization/actions/buildCommitmentUtilizationWidgetThunk";
import { buildBillingSummaryWidgetThunk } from "../billing-summary/actions/buildBillingSummaryWidgetThunk";
import { buildCostAnomaliesWidgetThunk } from "../cost-anomalies/actions/buildCostAnomaliesWidgetThunk";
import { buildCoverageWidgetThunk } from "../coverage-widget/actions/buildCoverageWidgetThunk";

export const buildWidgetByTypeThunk = createAsyncThunk(
  "dashboards/buildWidgetByType",
  async (widgetType: WidgetType, { dispatch }) => {
    if (widgetType === "cost_breakdown") {
      return await dispatch(buildBillingExplorerWidgetThunk()).unwrap();
    }

    if (widgetType === "commitments_utilization") {
      return await dispatch(buildCommitmentUtilizationWidgetThunk()).unwrap();
    }

    if (widgetType === "cost_and_usage_summary") {
      return await dispatch(buildBillingSummaryWidgetThunk()).unwrap();
    }

    if (widgetType === "largest_cost_changes") {
      return await dispatch(buildCostAnomaliesWidgetThunk()).unwrap();
    }

    if (widgetType === "coverage") {
      return await dispatch(buildCoverageWidgetThunk()).unwrap();
    }
  },
);
