import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { BudgetsDataGrid } from "./BudgetsDataGrid";
import { Budget } from "../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../store/hooks";
import { currentBudgetsDataSelector } from "../../../../../../store/budgets/selectors/currentBudgetsDataSelector";
import { BudgetAndAlertsAgGridToolbar } from "../common/BudgetAndAlertsAgGridToolbar";
import type { FilterChangedEvent, GridApi } from "ag-grid-community";

export const BudgetsGrid: FC = () => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const data = useAppSelector(currentBudgetsDataSelector);

  const handleNoRowsOverlayVisibility = useCallback(
    (gridApi: GridApi | null) => {
      if (!gridApi) {
        return;
      }

      if (!gridApi.getDisplayedRowCount()) {
        gridApi?.showNoRowsOverlay();
      } else {
        gridApi?.hideOverlay();
      }
    },
    [],
  );

  const filterChangeHandler = useCallback(
    (event: FilterChangedEvent<Budget>) => {
      handleNoRowsOverlayVisibility(event.api);
    },
    [handleNoRowsOverlayVisibility],
  );

  useEffect(() => {
    handleNoRowsOverlayVisibility(gridApi);
  }, [data?.length, gridApi, handleNoRowsOverlayVisibility]);

  return (
    <Fragment>
      <BudgetAndAlertsAgGridToolbar gridApi={gridApi} />
      <BudgetsDataGrid
        data={data ?? []}
        setGridApi={setGridApi}
        onFilterChanged={filterChangeHandler}
      />
    </Fragment>
  );
};
