import { azureReservationsCoverageDataSelector } from "../../data/azureReservationsCoverageDataSelector";
import { RootState } from "../../../../../../store";
import { AzureReservationsCoverageDataWithId } from "../../../../../../../components/pages/commtiments/common/utils/types";
import { commitmentsDetailsDrawerTargetIdSelector } from "../../../../common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";

export const azureReservationSelectedCoverageDataSelector = (
  state: RootState,
): AzureReservationsCoverageDataWithId | undefined => {
  const coverageData = azureReservationsCoverageDataSelector(state);
  const id = commitmentsDetailsDrawerTargetIdSelector(state);

  return coverageData?.find((data) => data.id === id);
};
