import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { TargetSingleOpportunity } from "./singe-opportunity/TargetSingleOpportunity";
import { TargetMultipleOpportunities } from "./multiple-opportunities/TargetMultipleOpportunities";
import { ViewTargetInOpportunitiesButton } from "./ViewTargetInOpportunitiesButton";
import { taskManagementTargetOpportunitiesSelector } from "../../../../../../../../../../../store/task-management/selectors/form/target/opportunities/taskManagementTargetOpportunitiesSelector";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";

export const OpportunitiesSectionAsTarget: FC = () => {
  const opportunities = useAppSelector(
    taskManagementTargetOpportunitiesSelector,
  );

  if (!opportunities) {
    return null;
  }

  return (
    <Stack spacing={1} width="100%" height="100%" minHeight={400}>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <Typography fontWeight="medium">Details</Typography>

        <ViewTargetInOpportunitiesButton />
      </Stack>

      {opportunities.length === 1 ? (
        <TargetSingleOpportunity />
      ) : (
        <TargetMultipleOpportunities />
      )}
    </Stack>
  );
};
