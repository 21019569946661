import { ResourceExplorerDataGridView } from "./resourceExplorerDataGrid";
import {
  ChartType,
  DateDataPointNullable,
  Dates,
  FilterTreeNode,
  ForecastOption,
  ResourceExplorerData,
  TrendType,
  CostType,
  ResourceExplorerGroupingNullable,
  ResourceExplorerFilterSchema,
} from "../../../../services/cloudchipr.api";
import { ResourceExplorerChartBase } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-chart/utils/types";
import { FilterTreeNodeWithId } from "../../../../components/pages/common/filters-tree/utils/types/common";

export type ResourceExplorerPath = {
  name: string;
  groupedBy: ResourceExplorerGroupingNullable;
  groupingOptions: ResourceExplorerGroupingNullable[];
  groupValues?: string[];
  filterTree?: FilterTreeNode;
  forecastOption?: ForecastOption;
  dates?: Dates;
  chartType?: ChartType;
  dateGranularity?: DateDataPointNullable;
  trendType?: TrendType;
  costType?: CostType;
  search?: string;
};

export type ResourceExplorerChartType = Extract<
  ChartType,
  "stack" | "line" | "bar"
>;

export type ResourceExplorerChartScale = "linear" | "logarithmic";

export type BillingExplorerSideBarPanelContentType =
  | "explainWithAI"
  | "chartSettings";

export const resourceExplorerPossibleChartTypes: ResourceExplorerChartType[] = [
  "stack",
  "line",
  "bar",
];

export interface BillingExplorerChartSettings {
  showLabels: boolean;
  showLegends: boolean;
  chartScale: ResourceExplorerChartScale;
}

export interface ResourceExplorerInterface {
  id: string;
  filterTree?: FilterTreeNodeWithId;
  data: ResourceExplorerData | null;
  path: ResourceExplorerPath[];
  search: string;
  quarterEndForecastVisibility: boolean;
  dataGridView: ResourceExplorerDataGridView | null;
  sideBarPanelContentType: BillingExplorerSideBarPanelContentType | null;
  csvDataHash: string;
  explainWithAi: { data: ExplainWithAiData[] };
  chartSettings: BillingExplorerChartSettings;
  // todo: move "chart_base" and "show_budget_line" into the settings, and add chart type
  chartBase: ResourceExplorerChartBase;
}

export type WidgetAverageData = {
  cost: number | null;
  trend: number | null;
};

export interface ExplainWithAiData {
  key: string;
  payload?: ResourceExplorerFilterSchema;
}
