import { Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { awsOrganizationWithCommitmentsSelector } from "../../../../../../../../store/commitments/selectors/organisations/awsOrganizationWithCommitmentsSelector";

export const CommitmentUtilizationDisabledActions = () => {
  const awsOrganization = useAppSelector(
    awsOrganizationWithCommitmentsSelector,
  );
  const disabledText = !awsOrganization?.length
    ? "Currently, only AWS is supported."
    : "Please enable billing exports, to see your billing information. Once enabled, you can create widget.";

  return (
    <Typography variant="caption" color="white" align="center" component="div">
      {disabledText}
    </Typography>
  );
};
