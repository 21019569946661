import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { textWidgetByIdSelector } from "../../../../selectors/widgets/text-widget/widget-view/textWidgetByIdSelector";
import { createWidgetThunk } from "../../common/createWidgetThunk";
import { openWidgetBuildingSnackbar } from "../../utils/openWidgetBuildingSnackbar";
import { textWidgetDataByIdSelector } from "../../../../selectors/widgets/text-widget/widget-view/textWidgetDataByIdSelector";

export const duplicateTextWidgetThunk = createAsyncThunk(
  "dashboard/duplicateTextWidget",
  async (
    { widgetId, widgetName }: { widgetId: string; widgetName: string },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = textWidgetByIdSelector(state, widgetId);
    const widgetData = textWidgetDataByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    const response = await dispatch(
      createWidgetThunk({
        type: "text",
        name: widgetName,
        text: widgetData?.text,
      }),
    ).unwrap();

    if (response) {
      openWidgetBuildingSnackbar("text", "duplicate");
    }

    return response;
  },
);
