import { KubernetesConvertorFnsType } from "../types";
import { EmbededKubernetesCluster } from "../../../../../../../../../../../services/cloudchipr.api";

export const clusterValueGenerator: KubernetesConvertorFnsType = (
  key,
  resource,
) => {
  const value = resource?.[key] as EmbededKubernetesCluster;
  return value?.name ?? value?.provider_id;
};
