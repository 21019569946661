import { FC, useCallback, useMemo } from "react";
import { PopoverProps, Skeleton, Stack } from "@mui/material";
import { KubernetesLiveUsageMgmtSelectedClusterNavigationTriggerComponent } from "./components/KubernetesLiveUsageMgmtSelectedClusterNavigationTriggerComponent";
import { KubernetesLiveUsageMgmtSelectClusterOption } from "./components/KubernetesLiveUsageMgmtSelectClusterOption";
import { KubernetesLiveUsageMgmtSelectClusterHeader } from "./components/KubernetesLiveUsageMgmtSelectClusterHeader";
import { kubernetesLiveUsageMgmtSelectedClusterIdsSelector } from "../../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtSelectedClusterIdsSelector";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { kubernetesLiveUsageMgmtClustersSelectOptionsSelector } from "../../../../../../store/kubernetes-live-usage-mgmt/selectors/clusters-data/kubernetesLiveUsageMgmtClustersSelectOptionsSelector";
import { isEqualArrays } from "../../../../live-usage-mgmt/components/navigation/components/selected-account/utils/helpers/isEqualArrays";
import { generateLiveResourcesPath } from "../../../../accounts-group/utils/helpers/generateLiveResourcesPath";
import { SIDEBAR_WIDTH } from "../../../../../../utils/constants/style-sizes";
import { DropdownSelect } from "../../../../../common/select/dropdown-select/DropdownSelect";
import { accountOptionFilterFn } from "../../../../../common/account-select/utils/helpers/accountOptionFilterFn";
import { kubernetesLiveUsageMgmtAccountsChangeThunk } from "../../../../../../store/kubernetes-live-usage-mgmt/thunks/kubernetesLiveUsageMgmtAccountsChangeThunk";

export const KubernetesLiveUsageMgmtSelectedClusterNavigation: FC = () => {
  const appliedClusterIds = useAppSelector(
    kubernetesLiveUsageMgmtSelectedClusterIdsSelector,
  );

  const dispatch = useAppDispatch();
  const groupedAllClusters = useAppSelector(
    kubernetesLiveUsageMgmtClustersSelectOptionsSelector,
  );

  const options = useMemo(() => {
    return groupedAllClusters?.map((option) => {
      let listItemHeight = 60;

      if (option.clusterId && !option.label.includes(option.clusterId)) {
        listItemHeight += 20;
      }

      return {
        value: option.value,
        rawValue: option,
        listItemHeight,
        label: (
          <KubernetesLiveUsageMgmtSelectClusterOption
            option={{ ...option }}
            idleMonthlyCost={option.idleMonthlyCost}
            totalMonthlyCost={option.totalMonthlyCost}
            showClusterId
          />
        ),
      };
    });
  }, [groupedAllClusters]);

  const accountChangeHandler = useCallback(
    async (accountIds: string[]) => {
      if (!options || isEqualArrays(appliedClusterIds ?? [], accountIds)) {
        return;
      }

      if (!accountIds.length) {
        const allAccountIds =
          options?.reduce((result, item) => {
            result.push(item.value);
            return result;
          }, [] as string[]) ?? [];

        window.history.replaceState(
          {},
          "",
          generateLiveResourcesPath({
            provider: "kubernetes",
            activeTab: "live-resources",
            accountIds: allAccountIds.join(","),
          }),
        );

        dispatch(kubernetesLiveUsageMgmtAccountsChangeThunk(allAccountIds));
      } else {
        window.history.replaceState(
          {},
          "",
          generateLiveResourcesPath({
            provider: "kubernetes",
            activeTab: "live-resources",
            accountIds: accountIds.join(","),
          }),
        );
        dispatch(kubernetesLiveUsageMgmtAccountsChangeThunk(accountIds));
      }
    },
    [dispatch, options, appliedClusterIds],
  );

  if (!appliedClusterIds) {
    return <Skeleton variant="text" height={100} width={SIDEBAR_WIDTH - 20} />;
  }

  return (
    <Stack
      p={0.5}
      width={280}
      minWidth={280}
      borderRight={1}
      height="100%"
      borderColor="grey.300"
      justifyContent="center"
    >
      <DropdownSelect
        label="Clusters"
        listWidth={400}
        options={options ?? []}
        filterFn={accountOptionFilterFn}
        PopoverProps={popoverProps}
        submitHandlerOnClose={accountChangeHandler}
        DropdownHeader={KubernetesLiveUsageMgmtSelectClusterHeader}
        values={appliedClusterIds ?? []}
        TriggerComponent={
          KubernetesLiveUsageMgmtSelectedClusterNavigationTriggerComponent
        }
        disableEmptySelectionList
        lastSelectedOptionDisabled
      />
    </Stack>
  );
};

const popoverProps = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
} as PopoverProps;
