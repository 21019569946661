import React, { FC, Fragment, useMemo } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import { Stack } from "@mui/material";
import { useDidMount } from "rooks";
import { UnauthorizedBillingExplorerWidgetTableView } from "./UnauthorizedBillingExplorerWidgetTableView";
import { UnauthorizedBillingExplorerWidgetNumeralView } from "./UnauthorizedBillingExplorerWidgetNumeralView";
import { UnauthorizedBillingExplorerWidgetChartView } from "./UnauthorizedBillingExplorerWidgetChartView";
import {
  DashboardWidgetCostBreakdown,
  GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiResponse,
} from "../../../../../services/cloudchipr.api";
import { UnauthorizedWidgetHeader } from "../UnauthorizedWidgetHeader";
import { DashboardNotFoundWidget } from "../../../../pages/dashboard/components/widgets/common/DashboardNotFoundWidget";
import { ChartType } from "../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { formatBillingExplorerREDataGridData } from "../../../../../store/dashboards/utils/helpers/billing-explorer/formatBillingExplorerREDataGridData";
import { combineBillingExplorerWidgetGridDataWithOthers } from "../../../../../store/dashboards/utils/helpers/billing-explorer/combineBillingExplorerWidgetGridDataWithOthers";
import { generateBillingExplorerWidgetCosts } from "../../../../../store/dashboards/utils/helpers/billing-explorer/generateBillingExplorerWidgetCosts";

interface UnauthorizedDashboardBillingExplorerWidgetProps
  extends DashboardWidgetCostBreakdown {
  data?: GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiResponse;
  isError: boolean;
  error?: FetchBaseQueryError | SerializedError;
  onWidgetMount?(): void;
  organisationId: string;
}

export const UnauthorizedDashboardBillingExplorerWidget: FC<
  UnauthorizedDashboardBillingExplorerWidgetProps
> = ({
  data,
  error,
  isError,
  name,
  dates,
  chart_type,
  date_granularity,
  grouping,
  forecast_option,
  visualization,
  aggregation,
  onWidgetMount,
  organisationId,
}) => {
  const status = error && "status" in error ? error?.status : 0;

  const gridData = useMemo(() => {
    const formatedData =
      formatBillingExplorerREDataGridData(data?.total ?? [], true) ?? [];

    if (!formatedData) {
      return [];
    }

    return combineBillingExplorerWidgetGridDataWithOthers(formatedData);
  }, [data?.total]);

  const widgetCostsData = useMemo(() => {
    return generateBillingExplorerWidgetCosts(data, date_granularity);
  }, [data, date_granularity]);

  useDidMount(() => {
    onWidgetMount?.();
  });

  if (isError && status !== 404) {
    return;
  }

  return (
    <Stack flexGrow={1} overflow="auto">
      <UnauthorizedWidgetHeader
        name={name}
        dates={dates}
        grouping={grouping}
        organisationId={organisationId}
      />
      {status === 404 && (
        <DashboardNotFoundWidget name="" label="billing report" />
      )}
      {status !== 404 && (
        <Fragment>
          {chart_type === "table" && (
            <UnauthorizedBillingExplorerWidgetTableView
              gridData={gridData}
              grouping={grouping}
              widgetCosts={widgetCostsData}
              forecastOption={forecast_option}
              originalDataCount={data?.total?.length}
              forecastedCosts={data?.forecasted_costs}
              visualization={visualization}
              dateLabel={dates?.label}
              dateDataPoint={date_granularity}
              aggregation={aggregation}
            />
          )}

          {chart_type === "numeral" && (
            <UnauthorizedBillingExplorerWidgetNumeralView
              data={gridData}
              grouping={grouping}
              loading={false}
              forecastOption={forecast_option}
              visualization={visualization}
              dateLabel={dates?.label}
              dateDataPoint={date_granularity}
              widgetCosts={widgetCostsData}
              aggregation={aggregation}
            />
          )}

          {data && chart_type !== "numeral" && chart_type !== "table" && (
            <UnauthorizedBillingExplorerWidgetChartView
              loading={false}
              widgetCosts={widgetCostsData}
              widgetData={data}
              dateLabel={dates?.label}
              dateDataPoint={date_granularity}
              chartType={chart_type as ChartType}
              forecastOption={forecast_option}
              visualization={visualization}
              chartBase={visualization?.chart_base_type ?? "cost"}
              aggregation={aggregation}
            />
          )}
        </Fragment>
      )}
    </Stack>
  );
};
