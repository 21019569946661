import { Stack, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { FC } from "react";
import { getResourceTypeName } from "../../../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { kubernetesLiveUsageMgmtResourceTypeDataTotalCountSelector } from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/count/kubernetesLiveUsageMgmtResourceTypeDataTotalCountSelector";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";

interface KubernetesLiveUsageMgmtResourceCardToolbarHeaderProps {
  resourceType: ResourceType;
}

export const KubernetesLiveUsageMgmtResourceCardToolbarHeader: FC<
  KubernetesLiveUsageMgmtResourceCardToolbarHeaderProps
> = ({ resourceType }) => {
  const resourcesTotalCount = useAppSelector((state) =>
    kubernetesLiveUsageMgmtResourceTypeDataTotalCountSelector(
      state,
      resourceType,
    ),
  );
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="h6">{getResourceTypeName(resourceType)}</Typography>
      {typeof resourcesTotalCount === "number" && (
        <Chip
          label={resourcesTotalCount}
          size="small"
          sx={{
            color: "text.secondary",
            fontWeight: "medium",
          }}
        />
      )}
    </Stack>
  );
};
