import { FC, Fragment } from "react";
import { Alert, Box, Divider, Stack, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { yellow } from "@mui/material/colors";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import { IntegrationCustomMessageInfoSection } from "../../../../../../../common/notification-selection/components/custom-message/common-components/IntegrationCustomMessageInfoSection";
import { NestedIntegration } from "../../../../../../../../../services/cloudchipr.api";

interface WorkflowNotificationCleanSectionCustomMessageBeforeMessageComponentProps {
  type: NestedIntegration["type"];
}

export const WorkflowNotificationCleanSectionCustomMessageBeforeMessageComponent: FC<
  WorkflowNotificationCleanSectionCustomMessageBeforeMessageComponentProps
> = ({ type }) => {
  if (type === "email") {
    return (
      <Fragment>
        <Typography variant="body1">
          Subject:{" "}
          <Typography component="span" fontWeight="medium">
            Actions executed by automation workflow - {`{{workflow name}}`}
          </Typography>
        </Typography>

        <Divider />

        <Box>
          <Stack justifyContent="space-between" direction="row">
            <IntegrationCustomMessageInfoSection
              start="Workflow"
              tail="{{workflow name}}"
              color="primary.main"
            />

            <Typography
              variant="body2"
              color="text.secondary"
              fontWeight="medium"
            >
              Monthly Price:
            </Typography>
          </Stack>

          <Stack justifyContent="space-between" direction="row" spacing={0.5}>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <AutoFixHighOutlinedIcon fontSize="small" color="success" />
              <Typography variant="body1" fontWeight="medium">
                Actions Executed
              </Typography>
            </Stack>
            <Typography variant="body2" fontWeight="medium">
              {`{{0.00}}`}
            </Typography>
          </Stack>
          <IntegrationCustomMessageInfoSection
            start="Run date"
            tail="{{date}}}"
          />
          <Alert severity="info" sx={alertSx}>
            Cloudchipr dispatches an email once it performes an action, but it
            doesn't provide updates on the action's status. The action statuses
            can be viewed in Execution Logs. Please be aware that some actions
            may take time to complete.
          </Alert>
        </Box>

        <Divider />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Stack spacing={1} direction="row">
        <AutoAwesomeIcon fontSize="small" sx={{ color: yellow["700"] }} />
        <Typography variant="body1" fontWeight="medium">
          Actions Executed
        </Typography>
      </Stack>
      <Typography variant="body1">
        Actions executed on resources by workflow{" "}
        <Typography component="span" fontWeight="medium">
          {`{{workflow name}}`}
        </Typography>
        . Please see the detailed list attached.
      </Typography>

      <Typography variant="body2" fontStyle="italic">
        Cloudchipr dispatches a message once it executes an action, but it
        doesn't provide updates on the action's status. The action statuses can
        be viewed in{" "}
        <Typography
          sx={{ textDecoration: "underline" }}
          component="span"
          variant="body2"
          fontStyle="italic"
        >
          Execution Logs
        </Typography>
        . Please be aware that some actions may take time to complete.
      </Typography>

      <Stack>
        <IntegrationCustomMessageInfoSection
          start="Workflow"
          tail="{{workflow name}}"
          color="primary.main"
        />
        <IntegrationCustomMessageInfoSection
          start="Monthly Price"
          tail="{{$0.00}}"
        />
      </Stack>

      <Divider />
    </Fragment>
  );
};

const alertSx = {
  color: "text.secondary",
  fontVariant: "caption",
  alignItems: "center",
  mt: 1.5,
  "& .MuiAlert-icon": { color: "text.secondary" },
};
