import { FC } from "react";
import {
  Card,
  Divider,
  Table,
  TableContainer,
  Typography,
} from "@mui/material";
import { CommitmentsOverviewEmptyState } from "./CommitmentsOverviewEmptyState";
import { CommitmentsOverviewContentLoading } from "./CommitmentsOverviewContentLoading";
import { CommitmentsOverviewCommitmentsTableHeader } from "./CommitmentsOverviewCommitmentsTableHeader";
import { CommitmentsOverviewCommitmentsTableBody } from "../commitments/components/CommitmentsOverviewCommitmentsTableBody";
import { CommitmentOverview } from "../../../../../../../services/cloudchipr.api";

interface CommitmentsOverviewCommitmentsProps {
  loading: boolean;
  data: CommitmentOverview["commitments"] | null;
}

export const CommitmentsOverviewCommitments: FC<
  CommitmentsOverviewCommitmentsProps
> = ({ loading, data }) => {
  const content =
    data?.length === 0 ? (
      <CommitmentsOverviewEmptyState dataType="commitments" />
    ) : (
      <TableContainer>
        <Table size="small" sx={{ my: 1 }}>
          <CommitmentsOverviewCommitmentsTableHeader />
          <CommitmentsOverviewCommitmentsTableBody data={data} />
        </Table>
      </TableContainer>
    );

  return (
    <Card
      variant="outlined"
      sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}
    >
      <Typography variant="subtitle2" color="text.secondary" px={2} py={1}>
        Commitments
      </Typography>
      <Divider />

      {loading ? <CommitmentsOverviewContentLoading /> : content}
    </Card>
  );
};
