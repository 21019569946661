import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

function TreeDisabledSvgIcon({ color, ...props }: SvgIconProps) {
  const { palette } = useTheme();

  const fillColor =
    color === "primary" ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon {...props}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19 15.0539V13.35H17.306L19 15.0539Z" fill={fillColor} />
        <path
          d="M16.1667 12.2039V9.55H13.5283L16.1667 12.2039Z"
          fill={fillColor}
        />
        <path
          d="M11.6394 7.65H14.2778V1H6.72222V2.70393L8.61111 4.60393V2.9H12.3889V5.75H9.75048L11.6394 7.65Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.12751 9.55H4.83333V13.35H2V20H9.55556V13.35H6.72222V11.45H10.0275L9.07751 10.5L8.12751 9.55ZM7.66667 18.1V15.25H3.88889V18.1H7.66667Z"
          fill={fillColor}
        />
        <path
          d="M11.9275 13.35H11.4444V20H18.5775L16.6775 18.1H13.3333V15.25H13.8275L11.9275 13.35Z"
          fill={fillColor}
        />
        <path
          d="M21.9975 20.59L1.41 0C1.41 0 -0.000122075 1.41155 7.9267e-09 1.40904L5.16847 6.59095L20.5775 22C20.5775 22 21.6075 20.98 21.9975 20.59Z"
          fill={fillColor}
        />
      </svg>
    </SvgIcon>
  );
}

TreeDisabledSvgIcon.muiName = "TreeDisabledSvgIcon";

export default TreeDisabledSvgIcon;
