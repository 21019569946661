import { FC, useCallback, useEffect, useRef } from "react";
import { ImperativePanelHandle, Panel } from "react-resizable-panels";
import { useLayoutConfigHook } from "../../../../layout/utils/hooks/useLayoutConfigHook";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { setBillingExplorerSideBarPanelContentType } from "../../../../../store/resource-explorer/resourceExplorerSlice";
import { BillingExplorerExplainWithAiPanel } from "../explain-with-ai-panel/BillingExplorerExplainWithAiPanel";
import { BillingExplorerChartSettingsPanel } from "../resource-explorer-card/components/resource-explorer-chart/components/settings/BillingExplorerChartSettingsPanel";
import { currentBillingExplorerSideBarPanelContentTypeSelector } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/currentBillingExplorerSideBarPanelContentTypeSelector";

export const BillingExplorerSideBarPanel: FC = () => {
  const dispatch = useAppDispatch();

  const sideBarRef = useRef<ImperativePanelHandle>(null);

  const { min, max, collapsedSize } = useLayoutConfigHook(0, 230, 800);

  const sideBarPanelContentType = useAppSelector(
    currentBillingExplorerSideBarPanelContentTypeSelector,
  );

  const sideBarOpen = !!sideBarPanelContentType;

  const onAfterCollapse = useCallback(() => {
    dispatch(setBillingExplorerSideBarPanelContentType(null));
  }, [dispatch]);

  const collapseHandler = useCallback(() => {
    sideBarRef.current?.collapse();
  }, []);

  useEffect(() => {
    if (sideBarOpen) {
      sideBarRef.current?.expand();
    } else {
      sideBarRef.current?.collapse();
    }
  }, [sideBarOpen, dispatch]);

  return (
    <Panel
      order={2}
      collapsible
      minSize={min}
      maxSize={max}
      ref={sideBarRef}
      defaultSize={35}
      collapsedSize={collapsedSize}
      id="billing-explorer-sidebar-panel"
      onCollapse={onAfterCollapse}
      style={{ overflowY: "auto", background: "white" }}
    >
      {sideBarPanelContentType === "explainWithAI" && (
        <BillingExplorerExplainWithAiPanel onClose={collapseHandler} />
      )}

      {sideBarPanelContentType === "chartSettings" && (
        <BillingExplorerChartSettingsPanel onClose={collapseHandler} />
      )}
    </Panel>
  );
};
