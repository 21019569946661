import { RootState } from "../../../../../store";
import { textWidgetSetupSelector } from "../../../setups/text/textWidgetSetupSelector";
import { textWidgetByIdSelector } from "../widget-view/textWidgetByIdSelector";
import { textWidgetDataByIdSelector } from "../widget-view/textWidgetDataByIdSelector";

export const textWidgetEditChangesExistSelector = (state: RootState) => {
  const setup = textWidgetSetupSelector(state);

  if (!setup?.id) {
    return false;
  }

  const widget = textWidgetByIdSelector(state, setup?.id);
  const widgetData = textWidgetDataByIdSelector(state, setup?.id);

  if (!widget || !widgetData) {
    return false;
  }

  return setup.name !== widget.name || setup.text !== widgetData.text;
};
