import { FC } from "react";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";

export const ViewTargetInOpportunitiesButton: FC = () => {
  const editMode = useAppSelector(isTaskManagementDrawerEditModeSelector);

  if (!editMode) {
    return null;
  }

  return (
    <Button
      sx={sx}
      size="small"
      target="_blank"
      variant="outlined"
      component={RouterLink}
      to="/recommendations/savings-opportunities"
      startIcon={<SelectAllIcon fontSize="small" color="primary" />}
    >
      View In Opportunities
    </Button>
  );
};

const sx = { textTransform: "none" };
