import { useCallback, useEffect, useRef, useState } from "react";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { GridApi, GridState } from "ag-grid-community";
import { useWillUnmount } from "rooks";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { savingsOpportunityCurrentViewSelector } from "../../../../../store/savings-opportunities/selectors/views-hierarchy/current-view/savingsOpportunityCurrentViewSelector";
import {
  useGridDebouncedConfigsUpdate,
  UseGridDebouncedConfigsUpdateHookReturnType,
} from "../../../common/ag-grid/utils/hooks/useGridDebouncedConfigsUpdate.hook";
import { getSavingsOpportunitiesViewsHierarchySaverSnackbarActions } from "../../components/views/SavingsOpportunitiesViewsHierarchySaverSnackbarActions";
import { updateSavingsOpportunityViewThunk } from "../../../../../store/savings-opportunities/thunks/views-hierarchy/actions/updateSavingsOpportunityViewThunk";

export const useSavingsOpportunitiesViewsHierarchySaveHook = (
  gridApi: GridApi | null,
): UseGridDebouncedConfigsUpdateHookReturnType => {
  const dispatch = useAppDispatch();
  const snackbarIdRef = useRef<SnackbarKey>("");
  const [snackbarOpened, setSnackbarOpened] = useState(false);

  const currentView = useAppSelector(savingsOpportunityCurrentViewSelector);

  const isDefaultView = !currentView;
  const viewId = currentView?.id;
  const viewConfig = currentView?.config;
  const isProtected = currentView?.protection_details?.is_protected;
  const protectedView = isDefaultView || isProtected;

  const stateChangeHandler = useCallback(
    (griState: GridState | null) => {
      const gridConfig = JSON.stringify(griState ?? {});

      if (!protectedView && viewId) {
        dispatch(
          updateSavingsOpportunityViewThunk({
            viewId,
            body: { config: { gridConfig } },
          }),
        );
        return;
      }

      if (snackbarOpened) {
        return;
      }

      setSnackbarOpened(true);

      const closeHandler = () => {
        setSnackbarOpened(false);
        closeSnackbar(snackbarIdRef.current);
      };

      snackbarIdRef.current = enqueueSnackbar(
        "This view has unsaved changes.",
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "info",
            icon: false,
            description:
              getSavingsOpportunitiesViewsHierarchySaverSnackbarActions(
                gridApi,
                closeHandler,
              ),
          },
          autoHideDuration: null,
        },
      );
    },
    [dispatch, snackbarOpened, protectedView, gridApi, viewId],
  );

  const { onStateDebouncedUpdate } = useGridDebouncedConfigsUpdate(
    stateChangeHandler,
    protectedView ? 0 : 1000,
    viewConfig?.gridConfig,
  );

  useEffect(() => {
    closeSnackbar(snackbarIdRef.current);
    setSnackbarOpened(false);
  }, [viewId]);

  useWillUnmount(() => {
    closeSnackbar(snackbarIdRef.current);
    setSnackbarOpened(false);
  });

  return { onStateDebouncedUpdate };
};
