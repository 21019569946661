import { RootState } from "../../../../../../../store";
import { azureReservationsDataForGranularSelector } from "../../azureReservationsDataForGranularSelector";

export const azureReservationsGranularDataTotalCoverageSelector = (
  state: RootState,
) => {
  const data = azureReservationsDataForGranularSelector(state);

  return data?.date_granular_data?.total_coverage;
};
