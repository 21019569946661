import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { costAnomaliesWidgetByIdSelector } from "../../../../selectors/widgets/cost-anomalies/costAnomaliesWidgetByIdSelector";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { costAnomaliesWidgetNotFoundSelector } from "../../../../selectors/widgets/cost-anomalies/costAnomaliesWidgetNotFoundSelector";
import { enqueueSnackbarErrorAlert } from "../../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getCostAnomaliesWidgetDataByWidgetIdThunk = createAsyncThunk(
  "dashboards/getCostAnomaliesWidgetDataByWidgetId",
  async (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const widget = costAnomaliesWidgetByIdSelector(state, widgetId);
    const viewNotFound = costAnomaliesWidgetNotFoundSelector(state, widgetId);

    const {
      getUsersMeOrganisationsCurrentWidgetsLargestCostChangesByWidgetId,
    } = cloudChiprApi.endpoints;

    if (!widget || viewNotFound) {
      return;
    }

    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentWidgetsLargestCostChangesByWidgetId.initiate(
          { widgetId },
          { forceRefetch: true },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
