import { resourceExplorerGroupedPossibleGroupingsSelector } from "./resourceExplorerGroupedPossibleGroupingsSelector";
import { RootState } from "../../../store";
import { resourceExplorerGroupingsParentsSequence } from "../../../../components/pages/common/resource-explorer-grouping-selector-v2/utils/constants/constants";
import { reOrderArrayByGivenSet } from "../../../../utils/helpers/reOrderArrayByGivenSet";

export const resourceExplorerGroupingsOrderedParentLabelsSelector = (
  state: RootState,
): string[] | undefined => {
  const groupings = resourceExplorerGroupedPossibleGroupingsSelector(state);

  if (!groupings) {
    return;
  }

  const labels = Object.keys(groupings);

  return reOrderArrayByGivenSet(
    labels,
    resourceExplorerGroupingsParentsSequence,
  );
};
