import { billingSummarySetupWidgetDataSelector } from "./billingSummarySetupWidgetDataSelector";
import { RootState } from "../../../../../store";
import { BillingSummaryDataGridType } from "../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/billing-summary/content/utils/types";
import { generateBillingSummaryDataGridData } from "../../../widgets/billing-summary/utils/helpers/generateBillingSummaryDataGridData";

export const billingSummarySetupWidgetDataGridDataSelector = (
  state: RootState,
): BillingSummaryDataGridType[] | null => {
  const data = billingSummarySetupWidgetDataSelector(state);

  if (!data) {
    return null;
  }

  return generateBillingSummaryDataGridData(data);
};
