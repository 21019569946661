import { dashboardHierarchyFoldersSelector } from "./dashboardHierarchyFoldersSelector";
import { RootState } from "../../../store";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";
import { TreeItems } from "../../../../components/app-navigation/components/sortable-tree/utils/types";

export const dashboardHierarchyItemsByFolderIdSelector = (
  state: RootState,
  folderId: string,
  visibility: NavigationItemsVisibilityType,
): TreeItems | undefined => {
  const folders = dashboardHierarchyFoldersSelector(state, visibility);

  return folders?.find((item) => item.id === folderId)?.items;
};
