import { FC, Fragment, ReactNode } from "react";
import { Card, CardHeader, IconButton, LinearProgress } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface BillingExplorerExplainWithAiPanelContentTrendProps {
  headerTitle: ReactNode;
  children: ReactNode;
  collapsed: boolean;
  onCollapse(): void;
  loading?: boolean;
}
export const BillingExplorerExplainWithAiAnomalyTrendItemWrapper: FC<
  BillingExplorerExplainWithAiPanelContentTrendProps
> = ({ headerTitle, children, collapsed, onCollapse, loading }) => {
  return (
    <Card variant="outlined" sx={{ borderColor: "primary.light" }}>
      <CardHeader
        title={headerTitle}
        avatar={
          <IconButton
            onClick={onCollapse}
            color="primary"
            size="small"
            sx={{
              transform: collapsed ? "rotate(-90deg)" : "rotate(0deg)",
              transition: "transform 0.3s",
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        sx={{
          p: 1,
          "& .MuiCardHeader-avatar": {
            marginRight: 0.5,
          },
        }}
      />
      <Fragment>
        {!collapsed && children}
        {loading && <LinearProgress />}
      </Fragment>
    </Card>
  );
};
