import { FC, useCallback } from "react";
import { Drawer } from "@mui/material";
import { ReservedInstanceDrawerContent } from "./reserved-instance-drawer/ReservedInstanceDrawerContent";
import { ReservationCoverageDrawerContent } from "./coverage-drawer/ReservationCoverageDrawerContent";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setCommitmentsDetailsDrawerTarget } from "../../../../../../store/commitments/commitmentsSlice";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { commitmentsDetailsDrawerOpenSelector } from "../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerOpenSelector";

export const ReservationsDrawers: FC = () => {
  const dispatch = useAppDispatch();
  const targetType = useAppSelector(commitmentsDetailsDrawerTargetTypeSelector);
  const open = useAppSelector(commitmentsDetailsDrawerOpenSelector);

  const closeHandler = useCallback(() => {
    dispatch(setCommitmentsDetailsDrawerTarget(undefined));
  }, [dispatch]);

  return (
    <Drawer open={open} anchor="right" onClose={closeHandler}>
      {targetType === "commitment" && (
        <ReservedInstanceDrawerContent onClose={closeHandler} />
      )}

      {targetType === "account" && (
        <ReservationCoverageDrawerContent onClose={closeHandler} />
      )}

      {targetType === "resource_type" && (
        <ReservationCoverageDrawerContent onClose={closeHandler} />
      )}
    </Drawer>
  );
};
