import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import {
  Dates,
  GetUsersMeOrganisationsCurrentWidgetsCoverageByWidgetIdApiResponse,
  WidgetCoverageAggregation,
} from "../../../../../../../services/cloudchipr.api";
import { calculateFromToByDatesData } from "../../../../../../common/date-range-picker/utils/helpers/calculateFromToByDatesData";
import { getTrendTooltipTitle } from "../../../../utils/helpers/trend-tooltip-title/getTrendTooltipTitle";
import { WidgetContentCostsCard } from "../../common/WidgetContentCostsCard";

interface CoverageWidgetCostsProps {
  aggregation?: WidgetCoverageAggregation;
  data?: GetUsersMeOrganisationsCurrentWidgetsCoverageByWidgetIdApiResponse;
  dates?: Dates;
  quarterStartMonth?: number;
}

export const CoverageWidgetCosts: FC<CoverageWidgetCostsProps> = ({
  aggregation,
  dates,
  data,
  quarterStartMonth,
}) => {
  const { toDate, fromDate } = useMemo(
    () => calculateFromToByDatesData(dates ?? {}, quarterStartMonth),
    [dates, quarterStartMonth],
  );

  const trendTooltipTitle = useMemo(
    () =>
      getTrendTooltipTitle(
        data?.current_period_date_details?.date_from,
        data?.current_period_date_details?.date_to,
        data?.previous_period_date_details?.date_from,
        data?.previous_period_date_details?.date_to,
        undefined,
        dates?.label,
      ),
    [
      data?.current_period_date_details,
      data?.previous_period_date_details,
      dates?.label,
    ],
  );

  return (
    <Stack direction="row" padding={2} spacing={2}>
      {aggregation?.covered && (
        <WidgetContentCostsCard
          title="Covered"
          tooltipTitle={`Covered spend from ${fromDate} to ${toDate}`}
          cost={data?.covered?.amount}
          percent={data?.covered?.percentage}
          trend={Number(data?.covered_trend)}
          trendTooltipTitle={trendTooltipTitle}
        />
      )}

      {aggregation?.not_covered && (
        <WidgetContentCostsCard
          title="Not Covered (On-Demand)"
          tooltipTitle={`On-Demand spend of resources not covered by Commitments for date range ${fromDate} to ${toDate}`}
          cost={data?.not_covered}
          trend={Number(data?.not_covered_trend)}
          trendTooltipTitle={trendTooltipTitle}
        />
      )}

      {aggregation?.real_cost && (
        <WidgetContentCostsCard
          title="Real Cost"
          tooltipTitle={realCostTooltipTitle}
          cost={data?.real_cost}
          trend={Number(data?.real_cost_trend)}
          trendTooltipTitle={trendTooltipTitle}
        />
      )}

      {aggregation?.net_savings && (
        <WidgetContentCostsCard
          title="Net Savings"
          tooltipTitle={netSavingsTooltipTitle}
          cost={data?.net_savings}
          trend={Number(data?.net_savings_trend)}
          trendTooltipTitle={trendTooltipTitle}
        />
      )}
    </Stack>
  );
};

const realCostTooltipTitle =
  "Total costs paid for resources eligible for commitments including Commitments Fees.";
const netSavingsTooltipTitle =
  "Net Savings from commitments for the selected date range. Calculated by comparing the usage cost covered by Commitments to the public On-Demand cost.";
