import { ColDef } from "ag-grid-community";
import { CostAnomalyAlertsDataGridDataType } from "../../../../../../../../../store/alerts/utils/types/types";

export const costAnomalyAlertsTimeIntervalComparator: ColDef<CostAnomalyAlertsDataGridDataType>["comparator"] =
  (_, __, nodeA, nodeB) => {
    const currentInterval = nodeA?.data?.time_interval;
    const nextInterval = nodeB?.data?.time_interval;

    const currentIntervalAsDays =
      Number(currentInterval?.time_interval ?? 0) *
      multiplierByUnit[currentInterval?.time_interval_unit ?? "days"];

    const nextIntervalAsDays =
      Number(nextInterval?.time_interval ?? 0) *
      multiplierByUnit[nextInterval?.time_interval_unit ?? "days"];

    return currentIntervalAsDays > nextIntervalAsDays ? 1 : -1;
  };

const multiplierByUnit = {
  days: 1,
  weeks: 7,
  months: 31,
};
