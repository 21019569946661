import { FilterModel } from "ag-grid-community";

const isOldFilterModel = (filterModel: FilterModel): boolean => {
  if (!filterModel || typeof filterModel !== "object") return false;

  return Object.values(filterModel).some(
    (filter) =>
      filter?.filterType &&
      ["text", "number", "date", "set"].includes(filter.filterType),
  );
};

const convertToMultiFilter = (oldFilterModel: FilterModel) => {
  if (!oldFilterModel || typeof oldFilterModel !== "object") {
    return {};
  }

  const newFilterModel: FilterModel = {};

  Object.entries(oldFilterModel).forEach(([key, value]) => {
    if (value?.filterType === "text" || value?.filterType === "number") {
      newFilterModel[key] = {
        filterType: "multi",
        filterModels: [value, null],
      };
    } else {
      newFilterModel[key] = value;
    }
  });

  return newFilterModel;
};

export const savingsOpportunitiesTemporaryFilterModelFixingFns = {
  isOldFilterModel,
  convertToMultiFilter,
};
