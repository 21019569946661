import { FC, ReactElement, useCallback } from "react";
import { Box, Grid } from "@mui/material";
import { IntegrationItem } from "../../../../integrations/components/communications/common/utils/types/common";
import { setActiveIntegration } from "../../../../../../store/integrations/integrationsSlice";
import { useAppDispatch } from "../../../../../../store/hooks";

interface CommunicationIntegrationCardWrapperProps {
  item: IntegrationItem;
  children: ReactElement;
}

export const IntegrationCardWrapper: FC<
  CommunicationIntegrationCardWrapperProps
> = ({ item, children }) => {
  const dispatch = useAppDispatch();

  const openCreateIntegrationDrawer = useCallback(() => {
    dispatch(setActiveIntegration({ type: item.type, id: null }));
  }, [dispatch, item.type]);

  return (
    <Grid item xs={1}>
      <Box onClick={openCreateIntegrationDrawer} sx={{ cursor: "pointer" }}>
        {children}
      </Box>
    </Grid>
  );
};
