import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { coverageWidgetSetupSelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/coverage/coverageWidgetSetupSelector";
import { CoverageWidgetSubHeader } from "../../../../../../../widgets/coverage/components/CoverageWidgetSubHeader";

export const CoverageWidgetSetupSubHeader: FC = () => {
  const setup = useAppSelector(coverageWidgetSetupSelector);

  return (
    <CoverageWidgetSubHeader
      name={setup?.name ?? ""}
      providerOrganisationId={setup?.providerOrganisationId}
      commitmentType={setup?.commitmentType}
      grouping={setup?.grouping}
      dates={setup?.dates}
    />
  );
};
