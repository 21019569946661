import { FC, useCallback } from "react";
import { LiveResourceFiltersNavigationItem } from "./LiveResourceFiltersNavigationItem";
import { allResourcesResourceType } from "../../../../pages/live-usage-mgmt/utils/constants/common";
import { LiveUsageNavigationResourceType } from "../../../../pages/live-usage-mgmt/components/navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";

interface LiveResourceFiltersNavigationAllResourcesItemProps {
  activeResourceType: LiveUsageNavigationResourceType;
  setActiveResourceType(rt: LiveUsageNavigationResourceType): void;
  filtersCount: number;
}

export const LiveResourceFiltersNavigationAllResourcesItem: FC<
  LiveResourceFiltersNavigationAllResourcesItemProps
> = ({ setActiveResourceType, activeResourceType, filtersCount }) => {
  const allResourcesClickHandler = useCallback(() => {
    setActiveResourceType(allResourcesResourceType);
  }, [setActiveResourceType]);

  return (
    <LiveResourceFiltersNavigationItem
      title="All Filters"
      onClick={allResourcesClickHandler}
      filtersCount={filtersCount}
      active={activeResourceType === allResourcesResourceType}
    />
  );
};
