import { FC } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { azureSavingsPlansGranularDataTotalCommitmentSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/granular/totals/azureSavingsPlansGranularDataTotalCommitmentSelector";
import { azureSavingsPlansGranularDataTotalCoverageSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/granular/totals/azureSavingsPlansGranularDataTotalCoverageSelector";
import { azureSavingsPlansGranularDataTotalUtilizationSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/granular/totals/azureSavingsPlansGranularDataTotalUtilizationSelector";
import { azureSavingsPlansGranularDataUncoveredUsageSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/granular/totals/azureSavingsPlansGranularDataUncoveredUsageSelector";
import { azureSavingsPlansForGranularLoadingSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/loading/azureSavingsPlansForGranularLoadingSelector";
import { commitmentsChartTypeSelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsChartTypeSelector";
import { CommitmentsChartTypeSelect } from "../../../../../common/components/chart/CommitmentsChartTypeSelect";
import { CommitmentsGaugeChartCard } from "../../../../../common/components/chart/gauge-chart/CommitmentsGaugeChartCard";

export const AzureUtilizationAndCoverageGaugeChart: FC = () => {
  const totalCommitment = useAppSelector(
    azureSavingsPlansGranularDataTotalCommitmentSelector,
  );
  const totalCoverage = useAppSelector(
    azureSavingsPlansGranularDataTotalCoverageSelector,
  );
  const totalUtilization = useAppSelector(
    azureSavingsPlansGranularDataTotalUtilizationSelector,
  );
  const uncoveredUsage = useAppSelector(
    azureSavingsPlansGranularDataUncoveredUsageSelector,
  );
  const loading = useAppSelector(azureSavingsPlansForGranularLoadingSelector);

  const contentView = useAppSelector(commitmentsChartTypeSelector);

  if (contentView !== "gauge") {
    return null;
  }

  return (
    <Stack spacing={1}>
      <CommitmentsChartTypeSelect />

      <Stack direction="row" spacing={2}>
        <CommitmentsGaugeChartCard
          title="Utilization"
          loading={loading}
          primary={{
            label: "Utilization",
            value: totalUtilization?.amount,
            percent: totalUtilization?.percent,
          }}
          secondary={{
            label: "Commitment",
            value: totalCommitment,
          }}
        />

        <CommitmentsGaugeChartCard
          title="Coverage"
          loading={loading}
          primary={{
            label: "Covered",
            value: totalCoverage?.amount,
            percent: totalCoverage?.percent,
          }}
          secondary={{
            label: "Not Covered (On-Demand)",
            value: uncoveredUsage,
          }}
        />
      </Stack>
    </Stack>
  );
};
