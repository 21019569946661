import { SvgIcon, SvgIconProps } from "@mui/material";

export const CommentSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 20C14.1811 20 15.8245 19.5015 17.2223 18.5675C18.6202 17.6335 19.7096 16.306 20.353 14.7528C20.9963 13.1996 21.1646 11.4906 20.8367 9.84173C20.5087 8.1929 19.6991 6.67834 18.5104 5.4896C17.3217 4.30085 15.8071 3.4913 14.1583 3.16333C12.5094 2.83535 10.8004 3.00368 9.24719 3.64703C7.69402 4.29037 6.3665 5.37984 5.43251 6.77766C4.49852 8.17547 4 9.81886 4 11.5C4 12.9053 4.34 14.2304 4.94444 15.3977L4 20L8.60228 19.0556C9.76961 19.66 11.0956 20 12.5 20Z"
          stroke="black"
          strokeOpacity="0.54"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

CommentSvgIcon.muiName = "CommentSvgIcon";

export default CommentSvgIcon;
