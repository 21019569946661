import { FC, useCallback } from "react";
import { CheckCircleOutline } from "@mui/icons-material";
import { FloatingActionButton } from "./FloatingActionButton";
import { taskManagementUpdateSelectedTasksThunk } from "../../../../../../store/task-management/thunks/actions/taskManagementUpdateSelectedTasksThunk";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { taskAvailableStatusPropertiesSelector } from "../../../../../../store/task-management/selectors/properties/taskAvailableStatusPropertiesSelector";
import { useAppAbility } from "../../../../../../services/permissions";

export const FloatingMarkCompleteButton: FC = () => {
  const dispatch = useAppDispatch();
  const statuses = useAppSelector(taskAvailableStatusPropertiesSelector);
  const completedId = statuses?.find(
    (status) => status.category === "completed",
  )?.id;
  const { can } = useAppAbility();
  const canEditTask = can("edit", "task");

  const clickHandler = useCallback(() => {
    if (!completedId) {
      return;
    }

    dispatch(
      taskManagementUpdateSelectedTasksThunk({
        status_id: completedId,
      }),
    );
  }, [dispatch, completedId]);

  return (
    <FloatingActionButton
      icon={CheckCircleOutline}
      disabled={!canEditTask}
      onClick={clickHandler}
    >
      Mark As Complete
    </FloatingActionButton>
  );
};
