import { HighchartsChartType } from "../../../../../../../../../storybook/c8r-highcharts/common/components/HighchartsChartTypeSelect";
import { ResourceExplorerChartType } from "../../../../../../../../../store/resource-explorer/utils/types/resourceExplorer";

export const oldChartTypesToNew: Record<
  ResourceExplorerChartType,
  HighchartsChartType
> = {
  stack: "stackedColumn",
  bar: "column",
  line: "line",
};

export const newChartTypesToOld: Record<
  HighchartsChartType,
  ResourceExplorerChartType
> = {
  stackedColumn: "stack",
  column: "bar",
  line: "line",
  area: "line",
};
