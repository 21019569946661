import { FC } from "react";
import {
  ChartType,
  WidgetCostBreakdownVisualization,
} from "../../../../../../../../services/cloudchipr.api";
import { BillingExplorerWidgetVisualizationChartBase } from "../../../../adding-widget/widget-create/widget-setups/billing-explorer/configurations/components/visualization/BillingExplorerWidgetVisualizationChartBase";
import { BillingExplorerWidgetVisualizationTrend } from "../../../../adding-widget/widget-create/widget-setups/billing-explorer/configurations/components/visualization/BillingExplorerWidgetVisualizationTrend";
import { BillingExplorerWidgetVisualizationForecastedCost } from "../../../../adding-widget/widget-create/widget-setups/billing-explorer/configurations/components/visualization/BillingExplorerWidgetVisualizationForecastedCost";
import { BillingExplorerWidgetVisualizationAccountColumn } from "../../../../adding-widget/widget-create/widget-setups/billing-explorer/configurations/components/visualization/BillingExplorerWidgetVisualizationAccountColumn";
import { BillingExplorerWidgetVisualizationRegionColumn } from "../../../../adding-widget/widget-create/widget-setups/billing-explorer/configurations/components/visualization/BillingExplorerWidgetVisualizationRegionColumn";
import { BillingExplorerWidgetVisualizationPreviousPeriodColumn } from "../../../../adding-widget/widget-create/widget-setups/billing-explorer/configurations/components/visualization/BillingExplorerWidgetVisualizationPreviousPeriodColumn";

export const billingExplorerWidgetVisualizationDetailsByChartType = new Map<
  ChartType,
  (keyof WidgetCostBreakdownVisualization)[]
>([
  ["stack", ["chart_base_type"]],
  ["pie", ["trend"]],
  ["numeral", ["trend", "forecasted_cost"]],
  [
    "table",
    //TODO: after adding account and region column only add "account_column", "region_column", to this array
    ["previous_period_cost", "trend", "forecasted_cost"],
  ],
]);

export const billingExplorerWidgetVisualizationDetailsComponentByType = new Map<
  keyof WidgetCostBreakdownVisualization,
  FC
>([
  ["chart_base_type", BillingExplorerWidgetVisualizationChartBase],
  ["trend", BillingExplorerWidgetVisualizationTrend],
  ["forecasted_cost", BillingExplorerWidgetVisualizationForecastedCost],
  ["account_column", BillingExplorerWidgetVisualizationAccountColumn],
  ["region_column", BillingExplorerWidgetVisualizationRegionColumn],
  [
    "previous_period_cost",
    BillingExplorerWidgetVisualizationPreviousPeriodColumn,
  ],
]);
