import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { AzureReservationsCoverageDataWithId } from "../../../../../../../../../common/utils/types";

import { TypographyWithTooltip } from "../../../../../../../../../../../common/TypographyWithTooltip";

export const azureCoveredInCommitmentColumnDef: ColDef<AzureReservationsCoverageDataWithId> =
  {
    field: "covered_units",
    headerName: "Covered",
    minWidth: 200,
    flex: 1.5,

    cellRenderer: ({
      data,
    }: CustomCellRendererProps<AzureReservationsCoverageDataWithId>) => {
      const measure = data?.resource_type
        ? ` (${data?.resource_type?.unit_of_measure})`
        : "";

      if (typeof data?.covered_units !== "number") {
        return null;
      }
      return (
        <TypographyWithTooltip
          title={data.covered_units + measure}
          variant="body2"
        />
      );
    },
  };
