import { FC } from "react";
import Stack from "@mui/material/Stack";
import { StackProps, Typography } from "@mui/material";
import {
  HeaderCellWidthDateTooltip,
  HeaderCellWidthDateTooltipProps,
} from "../../../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { CommitmentsTotals } from "../../../../../../../common/utils/helpers/commitmentsTotalsFromGridApi";

interface ReservationsColumnsHeaderCellWithTotalAndDateProps
  extends HeaderCellWidthDateTooltipProps {
  type: "netSavings" | "coveredUsage" | "unCoveredUsage" | "coveredHours";
  alignItems?: StackProps["alignItems"];
  totals?: CommitmentsTotals;
}

export const ReservationsColumnsHeaderCellWithTotalAndDateCommon: FC<
  ReservationsColumnsHeaderCellWithTotalAndDateProps
> = ({ type, alignItems = "end", totals, ...props }) => {
  return (
    <Stack alignItems={alignItems} flex={1} spacing={0.5}>
      <HeaderCellWidthDateTooltip {...props} />

      {totals && (
        <Typography variant="caption" color="text.secondary">
          {type === "coveredHours"
            ? `${totals[type]}h / ${totals.unCoveredHours + totals[type]}h`
            : money(totals[type])}
        </Typography>
      )}
    </Stack>
  );
};
