import { Stack } from "@mui/material";
import { FC } from "react";
import { useDidMount } from "rooks";
import { ResourceExplorerAllViewsHeader } from "./components/header/ResourceExplorerAllViewsHeader";
import { ResourceExplorerAllViewsTable } from "./components/table/ResourceExplorerAllViewsTable";
import { HierarchyAllItemsFiltersProvider } from "../common/hierarchy-all-items/components/HierarchyAllItemsFiltersProvider";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { resourceExplorerHierarchyMixedVisibilitiesCreatedByDataSelector } from "../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyMixedVisibilitiesCreatedByDataSelector";
import { ProviderBillingDataAccessController } from "../common/billing/billing-data-access-controller/ProviderBillingDataAccessController";
import { connectedProvidersSelector } from "../../../store/common/selectors/connectedProvidersSelector";
import { isBillingAvailableSelector } from "../../../store/common/selectors/billing-status/isBillingAvailableSelector";
import { resourceExplorerHierarchyVisibilitiesDataLoadingSelector } from "../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesDataLoadingSelector";
import { resourceExplorerHierarchyVisibilitiesDataUninitializedSelector } from "../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesDataUninitializedSelector";
import { getResourceExplorerVisibilityHierarchyThunk } from "../../../store/resource-explorer/thunks/resource-explorer-hierarchy-visibility/getResourceExplorerVisibilityHierarchyThunk";

export const ResourceExplorerAllViews: FC = () => {
  const dispatch = useAppDispatch();

  const createdByes = useAppSelector(
    resourceExplorerHierarchyMixedVisibilitiesCreatedByDataSelector,
  );
  const billingAvailable = useAppSelector(isBillingAvailableSelector);
  const connectedProviders = useAppSelector(connectedProvidersSelector);
  const loading = useAppSelector(
    resourceExplorerHierarchyVisibilitiesDataLoadingSelector,
  );
  const uninitialized = useAppSelector(
    resourceExplorerHierarchyVisibilitiesDataUninitializedSelector,
  );

  useDidMount(() => {
    dispatch(getResourceExplorerVisibilityHierarchyThunk());
  });

  if (uninitialized) {
    return null;
  }

  if (!billingAvailable && !loading) {
    const provider = connectedProviders[0];
    return <ProviderBillingDataAccessController provider={provider} />;
  }

  return (
    <Stack bgcolor="white" height="100dvh" overflow="hidden">
      <ResourceExplorerAllViewsHeader />

      <HierarchyAllItemsFiltersProvider createdByOptions={createdByes}>
        <ResourceExplorerAllViewsTable />
      </HierarchyAllItemsFiltersProvider>
    </Stack>
  );
};
