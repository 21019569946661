import { FC, useCallback, useEffect, useMemo } from "react";
import { SavingPlansRecommendationsDataGridFooter } from "./SavingPlansRecommendationsDataGridFooter";
import { SavingPlansRecommendationsAGGridToolbar } from "./data-grid/SavingPlansRecommendationsAGGridToolbar";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { commitmentsRecommendationsFilteredDataSelector } from "../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsFilteredDataSelector";
import { connectedOrganizationExistsSelector } from "../../../../../../../store/commitments/selectors/organisations/connectedOrganizationExistsSelector";
import { commitmentsRecommendationsSavingsPlanTypeSelector } from "../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsSavingsPlanTypeSelector";
import { commitmentsRecommendationsLoadingSelector } from "../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsLoadingSelector";
import { setCommitmentsRecommendationsFilters } from "../../../../../../../store/commitments/commitmentsSlice";
import { getCommitmentsRecommendationsThunk } from "../../../../../../../store/commitments/thunks/recommendations/getCommitmentsRecommendationsThunk";
import { commitmentsCurrentOrgIdSelector } from "../../../../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { filterRecommendationsColumns } from "../../../utils/helpers/filterRecommendationsColumns";
import { setRecommendationsInitialFiltersThunk } from "../../../../../../../store/commitments/thunks/recommendations/setRecommendationsInitialFiltersThunk";
import { SavingsPlanType } from "../../../../../../../services/cloudchipr.api";
import {
  CommitmentsAgGrid,
  CommitmentsToolbar,
} from "../../../../common/components/CommitmentsAgGrid";

interface SavingPlansRecommendationsDataGridProps {
  overview?: boolean;
}

export const SavingPlansRecommendationsDataGrid: FC<
  SavingPlansRecommendationsDataGridProps
> = ({ overview }) => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(commitmentsRecommendationsFilteredDataSelector);
  const orgExists = useAppSelector(connectedOrganizationExistsSelector);
  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);
  const loading = useAppSelector(commitmentsRecommendationsLoadingSelector);
  const savingsPlanTypes = useAppSelector(
    commitmentsRecommendationsSavingsPlanTypeSelector,
  );
  const empty = !loading && !data?.length;

  const columnDefs = useMemo(() => {
    return filterRecommendationsColumns(
      orgExists,
      savingsPlanTypes as SavingsPlanType[],
    );
  }, [orgExists, savingsPlanTypes]);

  const noRowOverlayComponent = useCallback(() => {
    if (empty) {
      return <SavingPlansRecommendationsDataGridFooter option="emptyData" />;
    }

    if (data?.length || loading) {
      return null;
    }

    return <SavingPlansRecommendationsDataGridFooter option="emptyData" />;
  }, [data?.length, loading, empty]);

  const toolbar: CommitmentsToolbar = useCallback(
    ({ gridApi }) => (
      <SavingPlansRecommendationsAGGridToolbar
        overview={overview}
        gridApi={gridApi}
      />
    ),
    [overview],
  );

  useEffect(() => {
    dispatch(setCommitmentsRecommendationsFilters(null));
    dispatch(getCommitmentsRecommendationsThunk());
    dispatch(setRecommendationsInitialFiltersThunk());
  }, [dispatch, orgId]);

  return (
    <CommitmentsAgGrid
      Toolbar={toolbar}
      loading={loading}
      columnDefs={columnDefs}
      data={data}
      localStorageUniqueKey="savingsPlanRecommendationsDataGrid"
      overrideDefaultColDef={savingsPlanRecommendationsDataDefaultColDef}
      noRowOverlayComponent={noRowOverlayComponent}
    />
  );
};

const savingsPlanRecommendationsDataDefaultColDef = {
  filter: false,
};
