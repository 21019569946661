import { FC } from "react";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import { DashboardAllItemsHeaderActions } from "./components/DashboardAllItemsHeaderActions";
import { PageHeader } from "../../../common/PageHeader";
import { useAppSelector } from "../../../../../store/hooks";
import { dashboardHierarchyVisibilitiesLoadingSelector } from "../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/dashboardHierarchyVisibilitiesLoadingSelector";

export const DashboardAllItemsHeader: FC = () => {
  const loading = useAppSelector(dashboardHierarchyVisibilitiesLoadingSelector);

  return (
    <PageHeader
      breadcrumbs={breadcrumbs}
      loading={loading}
      actions={<DashboardAllItemsHeaderActions loading={loading} />}
    />
  );
};

const breadcrumbs = [
  {
    title: "Dashboards",
    icon: <DashboardCustomizeOutlinedIcon fontSize="small" />,
  },
];
