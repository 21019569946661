import { createAsyncThunk } from "@reduxjs/toolkit";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { TextWidgetSetupType } from "../../../../utils/types/setups/textWidgetSetupType";
import { textWidgetByIdSelector } from "../../../../selectors/widgets/text-widget/widget-view/textWidgetByIdSelector";
import { textWidgetDataByIdSelector } from "../../../../selectors/widgets/text-widget/widget-view/textWidgetDataByIdSelector";
import { RootState } from "../../../../../store";

export const editTextChangesWidgetThunk = createAsyncThunk(
  "dashboards/editTextChangesWidget",
  (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const widget = textWidgetByIdSelector(state, widgetId);
    const widgetData = textWidgetDataByIdSelector(state, widgetId);

    dispatch(
      setWidgetSetup({
        widgetType: "text",
        id: widget?.id,
        name: widget?.name,
        text: widgetData?.text,
      } as TextWidgetSetupType),
    );
  },
);
