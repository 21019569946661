import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDidMount } from "rooks";
import { AccountId, ProviderType } from "../../../../services/cloudchipr.api";
import { generateLiveResourcesPath } from "../utils/helpers/generateLiveResourcesPath";

export const AccountV1: FC<{ provider: ProviderType }> = ({ provider }) => {
  const { accountId } = useParams<{
    accountId: AccountId;
  }>();

  const navigate = useNavigate();

  useDidMount(() => {
    if (accountId && provider) {
      navigate(
        generateLiveResourcesPath({
          provider,
          activeTab: "live-resources",
          accountIds: accountId,
        }),
      );
    } else {
      navigate(`/`);
    }
  });

  return null;
};
