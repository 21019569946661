import { RootState } from "../../../../store";
import { resourceDetailsBillingProcessesSelector } from "../../processes/resourceDetailsBillingProcessesSelector";
import { resourceDetailsResourceBillingGranularRequestSelector } from "../requests/resourceDetailsResourceBillingGranularRequestSelector";

export const resourceDetailsResourceBillingGranularDataLoadingSelector = (
  state: RootState,
) => {
  const processes = resourceDetailsBillingProcessesSelector(state);

  const loadings = Object.keys(processes).map(
    (process) =>
      resourceDetailsResourceBillingGranularRequestSelector(state, process)
        ?.isLoading,
  );

  return loadings.some((loading) => loading);
};
