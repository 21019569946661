import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AskAiInterface } from "./utils/types";

const initialState: AskAiInterface = {
  conversation: [],
};

export const askAiSlice = createSlice({
  name: "alerts",
  initialState: initialState,
  reducers: {
    setAskAiConversation: (
      state,
      action: PayloadAction<AskAiInterface["conversation"]>,
    ) => {
      state.conversation = action.payload;
    },

    resetAskAi: () => {
      return initialState;
    },
  },
});

export const { setAskAiConversation } = askAiSlice.actions;

export default askAiSlice.reducer;
