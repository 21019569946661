import { FC, Fragment, useCallback, useState } from "react";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import { BillingExplorerReportWithFoldersDeleteDialog } from "./dialogs/BillingExplorerReportWithFoldersDeleteDialog";
import { BillingExplorerReportFoldersRenameDialog } from "./dialogs/BillingExplorerReportFoldersRenameDialog";
import { BillingExplorerFolderMoveAction } from "./move/BillingExplorerFolderMoveAction";
import { navigationHierarchyItemNoDndKey } from "../../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { BillingExplorerCreateFolderOrReportDialog } from "../BillingExplorerCreateFolderOrReportDialog";

interface BillingExplorerFolderListItemActionsMenuProps {
  id: string;
  name: string;
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  redirectAfterCreate?: boolean;
  containsProtectedItem?: boolean;
  visibility: NavigationItemsVisibilityType;
}

export const BillingExplorerFolderListItemActionsMenu: FC<
  BillingExplorerFolderListItemActionsMenuProps
> = ({
  name,
  id,
  open,
  anchor,
  closeMenu,
  visibility,
  redirectAfterCreate,
  containsProtectedItem,
}) => {
  const [openDialogType, setOpenDialogType] = useState<
    "add-view" | "rename" | "delete"
  >();

  const renameClickHandler = useCallback(() => {
    setOpenDialogType("rename");
  }, []);

  const deleteClickHandler = useCallback(() => {
    setOpenDialogType("delete");
  }, []);

  const addViewClickHandler = useCallback(() => {
    setOpenDialogType("add-view");
  }, []);

  const closeDialog = useCallback(() => {
    setOpenDialogType(undefined);
    closeMenu();
  }, [closeMenu]);

  return (
    <Fragment>
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        disablePortal={false}
        {...navigationHierarchyItemNoDndKey}
      >
        <MenuItem onClick={renameClickHandler} sx={menuItemSx}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" sx={iconSx} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Rename</Typography>
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={addViewClickHandler} sx={menuItemSx}>
          <ListItemIcon>
            <AddIcon fontSize="small" sx={iconSx} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Add report</Typography>
          </ListItemText>
        </MenuItem>

        <BillingExplorerFolderMoveAction
          id={id}
          name={name}
          onClose={closeMenu}
          visibility={visibility}
          containsProtectedItem={containsProtectedItem}
        />

        <Tooltip
          title={
            containsProtectedItem
              ? "This folder contains protected report(s) and cannot be moved."
              : ""
          }
          arrow
          placement="right"
        >
          <span>
            <MenuItem
              onClick={deleteClickHandler}
              sx={menuItemSx}
              disabled={containsProtectedItem}
            >
              <ListItemIcon>
                <DeleteOutlineIcon fontSize="small" sx={iconSx} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Delete</Typography>
              </ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>

      {openDialogType === "delete" && (
        <BillingExplorerReportWithFoldersDeleteDialog
          open
          id={id}
          name={name}
          closeMenu={closeDialog}
          visibility={visibility}
        />
      )}

      {openDialogType === "rename" && (
        <BillingExplorerReportFoldersRenameDialog
          open
          id={id}
          name={name}
          closeMenu={closeDialog}
        />
      )}

      {openDialogType === "add-view" && (
        <BillingExplorerCreateFolderOrReportDialog
          type="item"
          folderId={id}
          onClose={closeDialog}
          visibility={visibility}
          redirectAfterCreate={redirectAfterCreate}
        />
      )}
    </Fragment>
  );
};

const menuItemSx = {
  pl: 1,
  pr: 1.5,
};

const iconSx = {
  minWidth: 28,
};
