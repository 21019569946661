import { FC, useCallback, useEffect } from "react";
import { Divider, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useLocalStorageHook } from "../../../utils/hooks/useLocalStorage.hook";
import ScreenSplitEvenSvgIcon from "../../../assets/images/icons/ScreenSplitEvenSvgIcon";
import ScreenSplitSvgIcon from "../../../assets/images/icons/ScreenSplitSvgIcon";

export type PossibleVisibilities = "chart" | "grid" | "chart_grid";
const possibleVisibilities: PossibleVisibilities[] = [
  "chart",
  "grid",
  "chart_grid",
];

export interface ScreenSplitButtonsProps {
  localStorageKey: string;
  visibleContent: PossibleVisibilities;
  setVisibleContent(value: PossibleVisibilities): void;
}

export const ScreenSplitButtons: FC<ScreenSplitButtonsProps> = ({
  localStorageKey,
  visibleContent,
  setVisibleContent,
}) => {
  const { getItem, setItem } = useLocalStorageHook();
  const savedVisibility = getItem(localStorageKey) as PossibleVisibilities;

  const visibleContentChangeHandler = useCallback(
    (_: unknown, visibleContent: "chart" | "grid" | "chart_grid") => {
      if (!visibleContent) {
        return;
      }

      setVisibleContent(visibleContent);
      setItem(localStorageKey, visibleContent);
    },
    [setItem, setVisibleContent, localStorageKey],
  );

  useEffect(() => {
    if (possibleVisibilities.includes(savedVisibility)) {
      setVisibleContent(savedVisibility);
    }
  }, [savedVisibility, setVisibleContent]);

  return (
    <Stack direction="row" alignItems="center" mt={1}>
      <Divider sx={dividerSx} />

      <ToggleButtonGroup
        exclusive
        size="small"
        value={visibleContent}
        sx={toggleButtonGroupStyles}
        onChange={visibleContentChangeHandler}
      >
        <ToggleButton value="chart_grid" sx={firstButtonSx}>
          <ScreenSplitEvenSvgIcon fontSize="small" />
        </ToggleButton>

        <ToggleButton value="grid">
          <ScreenSplitSvgIcon fontSize="small" />
        </ToggleButton>

        <ToggleButton value="chart" sx={lastButtonSx}>
          <ScreenSplitSvgIcon
            fontSize="small"
            sx={{ transform: "rotate(180deg)" }}
          />
        </ToggleButton>
      </ToggleButtonGroup>

      <Divider sx={dividerSx} />
    </Stack>
  );
};

const toggleButtonGroupStyles = {
  "& .Mui-selected svg": { color: "primary.main" },
};

const firstButtonSx = { borderRadius: "8px 0 0 8px" };
const lastButtonSx = { borderRadius: "0 8px 8px 0" };
const dividerSx = { flexGrow: 1 };
