import { FC, useEffect, useMemo } from "react";
import { DialogContent, Typography } from "@mui/material";
import { useFormik } from "formik";
import { v4 as uuid } from "uuid";
import { useEffectOnceWhen, useWillUnmount } from "rooks";
import { FiltersDialogActions } from "./FiltersDialogActions";
import { FilterDialogList } from "./FilterDialogList";
import { FilterDialogListActionButtons } from "./FilterDialogListActionButtons";
import {
  FilterKeyType,
  ResourceFilters,
  ResourceType,
} from "../../../../../../services/cloudchipr.api";
import { filtersValidationSchema } from "../utils/constants/validation";
import { FiltersInitialValues } from "../utils/types/types";
import { commonExcludedFilters } from "../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/utils/constants";
import { useAppDispatch } from "../../../../../../store/hooks";
import { setAllFiltersAreValid } from "../../../../../../store/filters/filtersSlice";

interface FiltersDialogContentProps {
  onClose(): void;
  accountIds?: string[];
  hideRevert?: boolean;
  unifiedFilters?: boolean;
  resourceType: ResourceType;
  excludedFilters?: FilterKeyType[];
  onSubmit(values: ResourceFilters): void;
  filters: ResourceFilters;
  defaultFilters: ResourceFilters;
  onSubmitCallback?: (submitFormFn: () => void) => void;
}

export const FiltersDialogContent: FC<FiltersDialogContentProps> = ({
  resourceType,
  onClose,
  onSubmit,
  accountIds,
  excludedFilters,
  filters,
  defaultFilters,
  hideRevert,
  unifiedFilters,
  onSubmitCallback,
}) => {
  const dispatch = useAppDispatch();

  const initialValues: FiltersInitialValues = useMemo(() => {
    return {
      ...filters,
      filter_items: filters.filter_items.map((filterItem) => ({
        ...filterItem,
        id: uuid(),
      })),
    };
  }, [filters]);

  const formik = useFormik({
    initialValues,
    validationSchema: filtersValidationSchema,
    onSubmit: (values) => {
      onSubmit({
        ...values,
        filter_items: values.filter_items.map(({ id, ...filter }) => ({
          ...filter,
        })),
      });
      onClose();
    },
  });

  const {
    dirty,
    values,
    errors,
    isValid,
    setValues,
    resetForm,
    handleSubmit,
    setFieldValue,
  } = formik;

  useWillUnmount(resetForm);

  const combinedExcludedFilters = useMemo(() => {
    return (excludedFilters ?? [])?.concat(commonExcludedFilters);
  }, [excludedFilters]);

  useEffectOnceWhen(
    () => onSubmitCallback?.(handleSubmit),
    !!(unifiedFilters && onSubmitCallback && handleSubmit),
  );

  useEffect(() => {
    dispatch(setAllFiltersAreValid(isValid));
  }, [isValid, dispatch]);

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent dividers={!unifiedFilters}>
        {values.filter_items.length ? (
          <FilterDialogList
            errors={errors}
            setValues={setValues}
            accountIds={accountIds}
            defaultFilters={defaultFilters}
            resourceType={resourceType}
            operators={values.operators}
            setFieldValue={setFieldValue}
            filterItems={values.filter_items}
            filterGroups={values.filter_groups}
            excludedFilters={combinedExcludedFilters}
          />
        ) : (
          <Typography>No filters applied</Typography>
        )}

        <FilterDialogListActionButtons
          filters={values}
          hideRevert={hideRevert}
          setValues={setValues}
          defaultFilters={defaultFilters}
          resourceType={resourceType}
        />
      </DialogContent>

      {!unifiedFilters && (
        <FiltersDialogActions onClose={onClose} disabled={!dirty || !isValid} />
      )}
    </form>
  );
};
