import { kubernetesLiveUsageMgmtAppliedFiltersByResourceTypeSelector } from "./filter-template/kubernetesLiveUsageMgmtAppliedFiltersByResourceTypeSelector";
import { RootState } from "../../store";
import { ResourceType } from "../../../services/cloudchipr.api";

export const isKubernetesLiveUsageMgmtFilterAppliedToResourceSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const filters = kubernetesLiveUsageMgmtAppliedFiltersByResourceTypeSelector(
    state,
    resourceType,
  );

  return !!filters?.filter_items?.length;
};
