import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerChartDataSelectorV2 } from "./resourceExplorerChartDataSelectorV2";
import { getPercentage } from "../../../../../utils/numeral/getPercentage";
import { HighchartsChartDataType } from "../../../../../storybook/c8r-highcharts/common/utils/types/common";

export const resourceExplorerPercentBasedChartDataSelector =
  createDraftSafeSelector(
    [resourceExplorerChartDataSelectorV2],
    (chartData): HighchartsChartDataType | null => {
      if (!chartData) {
        return null;
      }

      const totals = chartData.categories.reduce(
        (acc, category, index) => {
          acc[category] = chartData.series.reduce((sum, series) => {
            return sum + Math.abs(series.data[index] ?? 0);
          }, 0);

          return acc;
        },
        {} as Record<string, number>,
      );

      const percentBasedSeries = chartData.series.map((series) => {
        return {
          ...series,
          data: series.data.map((value, index) => {
            const categoryLabel = chartData.categories.at(index);
            const totalValue = categoryLabel ? totals[categoryLabel] : null;

            if (!totalValue || value === null) {
              return null;
            }

            return Math.abs(getPercentage(value, totalValue));
          }),
        };
      });

      return {
        ...chartData,
        series: percentBasedSeries,
      };
    },
  );
