import { FC } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TaskCreationDrawerTargetsTitles } from "./TaskCreationDrawerTargetsTitles";
import { TaskCreateSaveButton } from "./TaskCreateSaveButton";
import { TaskCreationDrawerHeaderEditActions } from "./TaskCreationDrawerHeaderEditActions";
import { useAppSelector } from "../../../../../../../store/hooks";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";

interface TaskCreationDrawerHeaderProps {
  onClose(): void;
}

export const TaskCreationDrawerHeader: FC<TaskCreationDrawerHeaderProps> = ({
  onClose,
}) => {
  const editMode = useAppSelector(isTaskManagementDrawerEditModeSelector);

  return (
    <Stack
      px={2}
      py={1.5}
      direction="row"
      borderBottom={1}
      alignItems="center"
      borderColor="grey.300"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        {!editMode && <Typography fontWeight="medium">Create Task</Typography>}

        <TaskCreationDrawerTargetsTitles />
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        {editMode ? (
          <TaskCreationDrawerHeaderEditActions onClose={onClose} />
        ) : (
          <TaskCreateSaveButton />
        )}

        <IconButton onClick={onClose} size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
