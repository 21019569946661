import { FC } from "react";
import { Skeleton, Stack } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FilterTriggerComponentProps } from "../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { SIDEBAR_WIDTH } from "../../../../../../../utils/constants/style-sizes";
import { ProviderIcon } from "../../../../../../common/ProviderIcon";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { useAppSelector } from "../../../../../../../store/hooks";
import { kubernetesLiveUsageMgmtSelectedClustersSelector } from "../../../../../../../store/kubernetes-live-usage-mgmt/selectors/clusters-data/kubernetesLiveUsageMgmtSelectedClustersSelector";

export const KubernetesLiveUsageMgmtSelectedClusterNavigationTriggerComponent: FC<
  FilterTriggerComponentProps
> = ({ onClick, open }) => {
  const selectedClusters = useAppSelector(
    kubernetesLiveUsageMgmtSelectedClustersSelector,
  );

  if (!selectedClusters) {
    return <Skeleton variant="text" height={100} width={SIDEBAR_WIDTH - 20} />;
  }

  const firstClusterName =
    selectedClusters.at(0)?.name ?? `ID: ${selectedClusters.at(0)?.cluster_id}`;

  const title =
    selectedClusters?.length === 1
      ? firstClusterName
      : `${selectedClusters?.length} selected clusters`;

  return (
    <Stack
      py={1.25}
      px={1}
      direction="row"
      borderRadius={2}
      onClick={onClick}
      bgcolor="grey.100"
      alignItems="center"
      sx={{ cursor: "pointer" }}
      justifyContent="space-between"
      spacing={1}
    >
      <ProviderIcon provider="kubernetes" width={24} />

      <TypographyWithTooltip title={title} variant="subtitle2" width="100%" />

      {open ? (
        <ArrowRightIcon sx={{ mr: 1 }} />
      ) : (
        <ArrowDropDownIcon sx={{ mr: 1 }} />
      )}
    </Stack>
  );
};
