import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";
import { billingExplorerViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerViewVisualizationChangeThunk";
import { ChartBaseType } from "../../../../../../../../../../../services/cloudchipr.api";
import { WidgetSetupChartBaseSelect } from "../../../../common/toolbar/WidgetSetupChartBaseSelect";

export const BillingExplorerWidgetVisualizationChartBase: FC = () => {
  const dispatch = useAppDispatch();
  const base = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("chart_base_type"),
  );

  const handleChartBaseChange = useCallback(
    (chartBase: ChartBaseType) => {
      dispatch(
        billingExplorerViewVisualizationChangeThunk({
          chart_base_type: chartBase,
        }),
      );
    },
    [dispatch],
  );

  return (
    <WidgetSetupChartBaseSelect
      chartBase={base}
      onChartChange={handleChartBaseChange}
    />
  );
};
