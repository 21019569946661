import { azureOverviewDataSelector } from "./azureOverviewDataSelector";
import { RootState } from "../../../../store";
import { ResourceTypeCoverage } from "../../../../../services/cloudchipr.api";

export const azureOverviewCoveragesSelector = (
  state: RootState,
): ResourceTypeCoverage[] | null => {
  const data = azureOverviewDataSelector(state);

  if (!data?.coverage) {
    return null;
  }

  return [...data.coverage].sort(
    (item1, item2) =>
      (item2.uncovered_usage ?? 0) - (item1.uncovered_usage ?? 0),
  );
};
