import React, { FC, Fragment, useCallback } from "react";
import {
  IconButton,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { SendReportDialog } from "./SendReportDialog";
import { ReportSourceType } from "../../../../services/cloudchipr.api";
import { useDialog } from "../../../../utils/hooks/useDialog.hook";
import SendSvgIcon from "../../../../assets/images/icons/SendSvgIcon";
import { getLabelByBillingSourceType } from "../../../utils/helpers/getLabelByBillingSourceType";

export interface SendReportActionItemProps {
  sourceId: string;
  sourceType: ReportSourceType;
  disabled?: boolean;
  iconButton?: boolean;
  iconSx?: SxProps<Theme>;
  menuItemSx?: SxProps<Theme>;
  onClose?(): void;
}

export const SendReportActionItem: FC<SendReportActionItemProps> = ({
  sourceId,
  sourceType,
  disabled,
  iconButton,
  iconSx,
  menuItemSx,
  onClose,
}) => {
  const { open, openDialog, closeDialog } = useDialog();

  const closeHandler = useCallback(() => {
    closeDialog();
    onClose?.();
  }, [onClose, closeDialog]);

  return (
    <Fragment>
      {iconButton ? (
        <Tooltip
          arrow
          placement="top"
          title={`Send ${getLabelByBillingSourceType(sourceType, true)}`}
        >
          <span>
            <IconButton size="small" onClick={openDialog} disabled={disabled}>
              <SendSvgIcon color={!disabled ? "primary" : undefined} />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <MenuItem onClick={openDialog} disabled={disabled} sx={menuItemSx}>
          <ListItemIcon sx={iconSx}>
            <SendSvgIcon />
          </ListItemIcon>
          <Typography variant="body2">Send</Typography>
        </MenuItem>
      )}

      {open && (
        <SendReportDialog
          onClose={closeHandler}
          sourceId={sourceId}
          sourceType={sourceType}
        />
      )}
    </Fragment>
  );
};
