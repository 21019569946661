import { grey } from "@mui/material/colors";
import { KubernetesNode } from "../../../../../../../services/cloudchipr.api";
import { KubernetesUsageSmallPieChartType } from "../../../../../../resource-details/utils/types/k8sSpecific";
import { convertByteToSize } from "../../../../../../../components/utils/helpers/convertByteToSize";
import { money } from "../../../../../../../utils/numeral/money";
import { getPercentage } from "../../../../../../../utils/numeral/getPercentage";

export const generateKubernetesNodeDetailsChartData = (
  node: KubernetesNode,
): KubernetesUsageSmallPieChartType[] => {
  return [
    {
      primary: "Cores",
      secondary: `${node.cpu_total}`,
      data: [
        {
          name: "Available",
          y: node.cpu_idle,
          color: grey[400],
          label: `${node.cpu_idle}c, ${getPercentage(node.cpu_idle, node.cpu_total).toFixed(2)}%`,
        },
        {
          name: "System reserved",
          y: node.cpu_system_reserved,
          color: "#4AC0C0",
          label: `${node.cpu_system_reserved}c, ${getPercentage(node.cpu_system_reserved, node.cpu_total).toFixed(2)}%`,
        },
        {
          name: "Workload reserved",
          y: node.cpu_workload_reserved,
          color: "#36A2EB",
          label: `${node.cpu_workload_reserved}c, ${getPercentage(node.cpu_workload_reserved, node.cpu_total).toFixed(2)}%`,
        },
      ],
    },
    {
      primary: "Memory",
      secondary: convertByteToSize(node.memory_total, 1),
      data: [
        {
          name: "Available",
          y: node.memory_idle,
          color: grey[400],
          label: `${convertByteToSize(node.memory_idle, 2)}, ${getPercentage(node.memory_idle, node.memory_total).toFixed(2)}%`,
        },
        {
          name: "System reserved",
          y: node.memory_system_reserved,
          color: "#4AC0C0",
          label: `${convertByteToSize(node.memory_system_reserved, 2)}, ${getPercentage(node.memory_system_reserved, node.memory_total).toFixed(2)}%`,
        },
        {
          name: "Workload reserved",
          y: node.memory_workload_reserved,
          color: "#36A2EB",
          label: `${convertByteToSize(node.memory_workload_reserved, 2)}, ${getPercentage(node.memory_workload_reserved, node.memory_total).toFixed(2)}%`,
        },
      ],
    },

    {
      primary: "Pods",
      secondary: `${node.pod_capacity}`,
      data: [
        {
          name: "Available",
          y: node.pod_capacity - node.pod_used,
          color: grey[400],
          label: `${node.pod_capacity - node.pod_used} pods, ${getPercentage(node.pod_capacity - node.pod_used, node.pod_capacity).toFixed(2)}%`,
        },
        {
          name: "Used",
          y: node.pod_used,
          color: "#9966FF",
          label: `${node.pod_used} pods, ${getPercentage(node.pod_used, node.pod_capacity).toFixed(2)}%`,
        },
      ],
    },

    {
      primary: "Cost",
      secondary: money(node.total_monthly_cost),
      data: [
        {
          name: "Idle",
          y: node.idle_monthly_cost,
          color: "#E31B0C",
          label: `${money(node.idle_monthly_cost)} / ${getPercentage(node.idle_monthly_cost, node.total_monthly_cost).toFixed(2)}%`,
        },
        {
          name: "Reserved",
          y: node.reserved_monthly_cost,
          color: grey[400],
          label: `${money(node.reserved_monthly_cost)} / ${getPercentage(node.reserved_monthly_cost, node.total_monthly_cost).toFixed(2)}%`,
        },
      ],
    },
  ];
};
