import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { billingSummaryWidgetSetupSelector } from "../../../../selectors/setups/billing-summary/billingSummaryWidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import {
  billingSummaryDefaultFilters,
  BillingSummaryWidgetFilterType,
} from "../../../../utils/constants/billingSummarySetupDefaultData";
import { billingSummarySumSetupPropertyByKeySelector } from "../../../../selectors/setups/billing-summary/billingSummarySumSetupPropertyByKeySelector";

interface BillingSummaryFilterToggleThunkArgs {
  key: BillingSummaryWidgetFilterType;
  checked: boolean;
}

export const billingSummaryFilterToggleThunk = createAsyncThunk(
  "dashboards/billingSummaryFilterChange",
  async (
    { key, checked }: BillingSummaryFilterToggleThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const setup = billingSummaryWidgetSetupSelector(state);
    const currentFilter =
      billingSummarySumSetupPropertyByKeySelector("filter")(state);

    const filter = billingSummaryDefaultFilters.filter((filter) => {
      if (filter === key) {
        return checked;
      }

      return currentFilter?.includes(filter);
    });

    if (!setup) {
      return;
    }

    dispatch(setWidgetSetup({ ...setup, filter }));
  },
);
