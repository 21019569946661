import { liveUsageMgmtAppliedFiltersSelector } from "./store-selectors/liveUsageMgmtAppliedFiltersSelector";
import { ResourceType } from "../../../services/cloudchipr.api";
import { RootState } from "../../store";

export const liveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector = (
  state: RootState,
): Map<ResourceType, number> => {
  const appliedFilters = liveUsageMgmtAppliedFiltersSelector(state);

  const resourceTypes = Object.keys(appliedFilters) as ResourceType[];

  return resourceTypes.reduce(
    (result, resourceType) => {
      result.set(
        resourceType,
        appliedFilters?.[resourceType]?.filter_items?.length ?? 0,
      );
      return result;
    },
    new Map() as Map<ResourceType, number>,
  );
};
