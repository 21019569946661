import { createAsyncThunk } from "@reduxjs/toolkit";
import { createTaskCommentThunk } from "./createTaskCommentThunk";
import { updateTaskCommentThunk } from "./updateTaskCommentThunk";
import { getTaskActivitiesThunk } from "../getTaskActivitiesThunk";
import { taskManagementGetTasksThunk } from "../../actions/taskManagementGetTasksThunk";

interface SendTaskCommentThunkArgs {
  taskId: string;
  comment: string;
  commentId?: string;
}

export const sendTaskCommentThunk = createAsyncThunk(
  "taskManagement/sendTaskComment",
  async (
    { taskId, comment, commentId }: SendTaskCommentThunkArgs,
    { dispatch },
  ) => {
    let response;

    if (commentId) {
      response = await dispatch(
        updateTaskCommentThunk({ taskId, comment, commentId }),
      ).unwrap();
    } else {
      response = await dispatch(
        createTaskCommentThunk({ taskId, comment }),
      ).unwrap();
    }

    if (response) {
      dispatch(getTaskActivitiesThunk(taskId));
      dispatch(taskManagementGetTasksThunk());
    }

    return response;
  },
);
