import { FC, useMemo } from "react";
import { Divider, Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { azureSavingsPlansGranularDataTotalCommitmentSelector } from "../../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/granular/totals/azureSavingsPlansGranularDataTotalCommitmentSelector";
import { azureSavingsPlansGranularDataTotalUtilizationSelector } from "../../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/granular/totals/azureSavingsPlansGranularDataTotalUtilizationSelector";
import { azureSavingsPlansGranularDataTotalCoverageSelector } from "../../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/granular/totals/azureSavingsPlansGranularDataTotalCoverageSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { commitmentsDatesSelector } from "../../../../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { generateFromToFromDates } from "../../../../../../utilization-and-coverage/utils/helpers/generateFromToFromDates";
import { azureSavingsPlansForGranularLoadingSelector } from "../../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/loading/azureSavingsPlansForGranularLoadingSelector";
import { azureSavingsPlanDataIsNotAvailableSelector } from "../../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/azureSavingsPlanDataIsNotAvailableSelector";
import { money } from "../../../../../../../../../utils/numeral/money";
import { CommitmentsChartMetadataCard } from "../../../../../../common/components/chart/CommitmentsChartMetadataCard";

export const AzureUtilizationAndCoverageMultiTypeChartMetadata: FC = () => {
  const totalCommitment = useAppSelector(
    azureSavingsPlansGranularDataTotalCommitmentSelector,
  );
  const totalUtilization = useAppSelector(
    azureSavingsPlansGranularDataTotalUtilizationSelector,
  );

  const totalCoverage = useAppSelector(
    azureSavingsPlansGranularDataTotalCoverageSelector,
  );

  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dates = useAppSelector(commitmentsDatesSelector);
  const datesFromTo = useMemo(() => {
    return generateFromToFromDates(dates, quarterStartMonth);
  }, [dates, quarterStartMonth]);

  const loading = useAppSelector(azureSavingsPlansForGranularLoadingSelector);
  const notAvailable = useAppSelector(
    azureSavingsPlanDataIsNotAvailableSelector,
  );
  const notAvailableMessage = notAvailable ? "N/A" : "-";
  const utilizationAmount =
    totalUtilization?.amount === null
      ? notAvailableMessage
      : money(totalUtilization?.amount);

  return (
    <Stack
      spacing={2}
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
    >
      <CommitmentsChartMetadataCard
        title="Utilization"
        loading={loading}
        value={utilizationAmount}
        secondaryValue={
          totalUtilization?.percent ? `(${totalUtilization?.percent}%)` : ""
        }
        tooltip={`Total SPs utilization ${datesFromTo}.`}
      />

      <CommitmentsChartMetadataCard
        loading={loading}
        title="Commitments"
        value={money(totalCommitment)}
        tooltip={`Total SP commitments ${datesFromTo}.`}
      />

      <CommitmentsChartMetadataCard
        loading={loading}
        title="Covered"
        value={money(totalCoverage?.amount)}
        secondaryValue={
          totalCoverage?.percent ? `(${totalCoverage?.percent}%)` : ""
        }
        tooltip={`Total cost and percentage of resources covered by SPs ${datesFromTo}.`}
      />
    </Stack>
  );
};
