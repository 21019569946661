import { FC, useCallback } from "react";
import { SelectChangeEvent } from "@mui/material";
import { WidgetViewTypeSelect } from "../../../../../../../common/WidgetViewTypeSelect";
import { WidgetViewType } from "../../../../../../../../../../../../../../store/dashboards/utils/types/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupVisualizationPropertyByKeySelector";
import { commitmentUtilizationWidgetVisualizationChangeThunk } from "../../../../../../../../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/setup-change/commitmentUtilizationWidgetVisualizationChangeThunk";
import { ChartType } from "../../../../../../../../../../../../../../services/cloudchipr.api";
import { commitmentUtilizationWidgetSetupDefaultData } from "../../../../../../../../../../../../../../store/dashboards/utils/constants/commitmentUtilizationWidgetSetupDefaultData";

export const CommitmentUtilizationWidgetChartTypeSelect: FC = () => {
  const dispatch = useAppDispatch();
  const chartType = useAppSelector(
    commitmentUtilizationSetupVisualizationPropertyByKeySelector("chart_type"),
  );
  const chartBase = useAppSelector(
    commitmentUtilizationSetupVisualizationPropertyByKeySelector(
      "chart_base_type",
    ),
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<WidgetViewType>) => {
      dispatch(
        commitmentUtilizationWidgetVisualizationChangeThunk({
          ...commitmentUtilizationWidgetSetupDefaultData.visualization,
          chart_type: event.target.value as ChartType,
          chart_base_type: chartBase,
        }),
      );
    },
    [dispatch, chartBase],
  );

  return (
    <WidgetViewTypeSelect
      viewType={chartType}
      options={options}
      onChange={handleChange}
    />
  );
};

const options: WidgetViewType[] = [
  "stack",
  "horizontal_stack_bar",
  "bar",
  "table",
  "numeral",
];
