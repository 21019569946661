import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import MenuList from "@mui/material/MenuList";
import {
  FilterItemSelectMenuItem,
  NewFilterAddFn,
} from "./FilterItemSelectMenuItem";
import {
  ProviderAllAccountsSelectionFilterItem,
  providersWithIncludeAllOption,
} from "./ProviderAllAccountsSelectionFilterItem";
import { ResourceExplorerPossibleFilterV2 } from "../../../../../../../services/cloudchipr.api";
import { useFilterTreeContext } from "../../FilterTreeProvider";

interface FilterItemSelectProps {
  filters: ResourceExplorerPossibleFilterV2[];
  onSelect: NewFilterAddFn;
}

export const FilterItemSelect: FC<FilterItemSelectProps> = ({
  filters,
  onSelect,
}) => {
  const { withIncludeAllProviderAccountsOption } = useFilterTreeContext();

  return (
    <Stack
      p={1}
      width={360}
      overflow="auto"
      maxHeight={filters.length ? 350 : undefined}
    >
      {filters.length ? (
        <MenuList disablePadding>
          {filters.map((filterItem) => {
            const needToRenderIncludeAllOption =
              withIncludeAllProviderAccountsOption &&
              filterItem.key === "account" &&
              filterItem.filter_provider &&
              providersWithIncludeAllOption.includes(
                filterItem.filter_provider,
              );

            if (needToRenderIncludeAllOption) {
              return [
                <ProviderAllAccountsSelectionFilterItem
                  key={`include_all_${filterItem.filter_provider}_${filterItem.key}`}
                  provider={filterItem.filter_provider!}
                  onSelect={onSelect}
                />,

                <FilterItemSelectMenuItem
                  filter={filterItem}
                  onSelect={onSelect}
                  key={filterItem.key + filterItem.filter_provider}
                />,
              ];
            }

            return (
              <FilterItemSelectMenuItem
                filter={filterItem}
                onSelect={onSelect}
                key={filterItem.key + filterItem.filter_provider}
              />
            );
          })}
        </MenuList>
      ) : (
        <Typography variant="caption" align="center" p={2}>
          No options found.
        </Typography>
      )}
    </Stack>
  );
};
