import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { TooltipText } from "../../../../../../../common/TooltipText";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";
import { capitalizeString } from "../../../../../../../../utils/helpers/capitalizeString";
import { Budget } from "../../../../../../../../services/cloudchipr.api";

export const BudgetsPeriodCell: FC<ICellRendererParams<Budget>> = ({
  data: budget,
}) => {
  const period = budget?.period;
  const startDate = budget?.start_date;
  if (!period || !startDate) {
    return;
  }

  return (
    <TooltipText
      tooltipTitle={`Started on ${formatDate(startDate, { type: "date" })}`}
    >
      {capitalizeString(period)}
    </TooltipText>
  );
};
