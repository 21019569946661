import numeral from "numeral";
import { clusterValueGenerator } from "./clusterValueGenerator";
import { getKubernetesMetricsGeneratorFn } from "./getKubernetesMetricsGeneratorFn";
import { KubernetesResourceItemType } from "../../../../../../../../../../../store/kubernetes-live-usage-mgmt/utils/types/types";
import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import {
  ConvertorKeysType,
  KubernetesConvertorsType,
  KubernetesExportedResourceDataOptions,
} from "../types";
import { formatSecondsToFormattedDate } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/helpers/formatSecondsToFormattedDate";
import {
  ResourceCreationData,
  Tags,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../../../../../../utils/helpers/date-time/datetime-format";

export const kubernetesGenerateValue = (
  resource: KubernetesResourceItemType,
  col: ColumnSetupType<KubernetesResourceItemType>,
  options?: KubernetesExportedResourceDataOptions,
) => {
  const key = col.accessorKey;
  const type = col.type as ConvertorKeysType;

  if (!key || !resource) {
    return "";
  }

  const converter = valuesConverterByType[type];

  const value = converter
    ? converter(key, resource, { ...options, columnId: col.id })
    : resource[key];

  return value?.toString() || "";
};

const valuesConverterByType: KubernetesConvertorsType = {
  secondsToFormattedDate: (key, resource) => {
    const data = resource?.[key];

    if (typeof data !== "number") {
      return "-";
    }

    return formatSecondsToFormattedDate(data);
  },
  createdBy: (key, resource) => {
    const value = resource[key] as ResourceCreationData;
    return value?.creator ?? "";
  },
  money: (key, resource) => {
    const value = resource[key];

    return numeral(value)?.format("0.000");
  },
  cpuStatistic: (key, resource) => {
    const value = resource[key];
    return numeral(value)?.format("0.000");
  },

  tag: (
    key,
    resource: KubernetesResourceItemType,
    options?: KubernetesExportedResourceDataOptions,
  ) => {
    const tags = resource[key] as Tags;

    if (options?.onlyNameTag) {
      const nameTag = tags?.find(({ key }) => key.toLowerCase() === "name");

      return nameTag ? `${nameTag?.key}: ${nameTag?.value}` : "";
    } else {
      const t = tags?.map((tag) => `${tag?.key}:${tag?.value}`).join(" , ");
      return `"${t}"`;
    }
  },
  date: (key, resource) => {
    const value = resource[key] as string;

    return formatDate(value, { type: "dateTime" });
  },
  cluster: clusterValueGenerator,
  computeMetricsChart: getKubernetesMetricsGeneratorFn("cpu_percent"),
  memoryUsedMetricsChart: getKubernetesMetricsGeneratorFn(
    "memory_used_percent",
  ),
};
