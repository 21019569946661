import { kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeSelector } from "./kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { taskAvailableStatusPropertiesSelector } from "../../../task-management/selectors/properties/taskAvailableStatusPropertiesSelector";
import { generateKubernetesExportedResourceData } from "../../../../components/pages/kubernetes-live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/utils/csv-data/generateKubernetesExportedResourceData";

export const selectedResourcesCSVDataDisabledColumns = ["issues"];

export const kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector =
  (
    state: RootState,
    resourceType: ResourceType,
    disabledColumns?: string[],
  ) => {
    const resources =
      kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeSelector(
        state,
        resourceType,
      );
    const taskStatuses = taskAvailableStatusPropertiesSelector(state);

    if (!resources) {
      return null;
    }

    return generateKubernetesExportedResourceData(
      resourceType,
      resources,
      [...(disabledColumns ?? []), ...selectedResourcesCSVDataDisabledColumns],
      { taskStatuses },
    );
  };
