import { FC, useState } from "react";
import { keyframes, Stack } from "@mui/material";
import { useCurrentEditor } from "@tiptap/react";
import { CommentEditorActions } from "./CommentEditorActions";
import { ToolbarCommandByCommandName } from "../../../../../../../../../storybook/editor/components/toolbar/ToolbarCommandByCommandName";

interface CommentEditorToolbarProps {
  taskId: string;
  comment: string;
  commentId?: string;
  onSend?(): void;
  onCancel?(): void;
}

const setAbsolute = keyframes`
  to {
    position: absolute;
  }
`;

export const CommentEditorToolbar: FC<CommentEditorToolbarProps> = ({
  comment,
  taskId,
  onSend,
  onCancel,
  commentId,
}) => {
  const [stackFocused, setStackFocused] = useState(false);
  const { editor } = useCurrentEditor();

  const focused = editor?.isFocused || stackFocused;
  const focusHandler = () => setStackFocused(true);
  const blurHandler = () => setStackFocused(false);

  return (
    <Stack
      tabIndex={-1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="white"
      position="sticky"
      bottom={0}
      onFocus={focusHandler}
      onBlur={blurHandler}
      sx={{
        transition: "opacity 0.3s ease-in-out",
        opacity: focused ? 1 : 0,
        pointerEvents: focused ? "auto" : "none",
        ...(focused ? {} : { animation: `${setAbsolute} 0s 0.3s forwards` }),
      }}
      p={1}
      pt={0}
    >
      <Stack direction="row" spacing={0.5}>
        <Stack
          direction="row"
          spacing={0.5}
          borderRight={1}
          borderColor="grey.400"
          pr={0.5}
        >
          <ToolbarCommandByCommandName commandName="bold" />
          <ToolbarCommandByCommandName commandName="italic" />
          <ToolbarCommandByCommandName commandName="underline" />
          <ToolbarCommandByCommandName commandName="strike" />
        </Stack>

        <ToolbarCommandByCommandName commandName="link" />
      </Stack>

      <CommentEditorActions
        comment={comment}
        taskId={taskId}
        onSend={onSend}
        onCancel={onCancel}
        commentId={commentId}
      />
    </Stack>
  );
};
