import { capitalize } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Ec2 } from "../../../../../../../../../../../../../../services/cloudchipr.api";
import { ResourceMetricsHeaderCell } from "../../../../../cells/metrics-cells/components/ResourceMetricsHeaderCell";

export const ec2Columns: ColumnSetupType<Ec2>[] = [
  {
    accessorKey: "name",
    header: "Name",
    type: "identifierWithRecommendationLink",
    size: 210,
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "instance_id",
    header: "Instance-id",
    type: "copyWithTooltip",
    size: 210,
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "moneyStoppedCell",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    accessorKey: "unused_since",
    header: "State",
    type: "state",
  },
  {
    id: "cpu_max",
    size: 220,
    minSize: 165,
    maxSize: 300,
    meta: { cellStyles: { p: 0 }, headerTitle: "CPU Max" },
    accessorKey: "inline_metrics",
    type: "computeMetricsChart",
    header: ResourceMetricsHeaderCell,
  },
  {
    id: "memory_max",
    size: 250,
    minSize: 250,
    maxSize: 350,
    meta: { cellStyles: { p: 0 }, headerTitle: "Memory Utilization Max" },
    accessorKey: "inline_metrics",
    type: "memoryUsedMetricsChart",
    header: ResourceMetricsHeaderCell,
  },
  {
    id: "unused_memory",
    size: 250,
    minSize: 250,
    maxSize: 350,
    meta: { cellStyles: { p: 0 }, headerTitle: "Memory Available Min" },
    accessorKey: "inline_metrics",
    type: "memoryAvailableMetricsChart",
    header: ResourceMetricsHeaderCell,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
    type: "createdBy",
  },
  {
    accessorKey: "ebs_optimized",
    header: "EBS Optimized",
    cell: (cell) => {
      const value = cell.getValue() as string;
      return value ? capitalize(value) : "-";
    },
  },
  {
    accessorKey: "created_at",
    header: "Launch time",
    type: "date",
  },
  { accessorKey: "type", header: "Instance Type" },
  {
    accessorKey: "cpu_value",
    header: "CPU Max",
    headerTooltip: "The maximum usage of CPU for the last 7 days.",
    type: "cpuStatistic",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "task",
    header: "Task Status",
    type: "taskStatus",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "JIRA Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
