import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLiveUsageMgmtResourcesWithFiltersByProviderThunk } from "./resources/getLiveUsageMgmtResourcesWithFiltersByProviderThunk";
import {
  ResourceFilters,
  ResourceType,
} from "../../../services/cloudchipr.api";
import { RootState } from "../../store";

import { liveUsageMgmtAppliedFiltersByResourceTypeSelector } from "../selectors/filter-template/liveUsageMgmtAppliedFiltersByResourceTypeSelector";
import {
  resetLiveUsageMgmtSelectedResources,
  setLiveUsageMgmtAppliedFilters,
  setLiveUsageMgmtFilterChangedManually,
} from "../liveUsageMgmtSlice";
import { addProviderUniqueIdentifierFilterToAppliedFilters } from "../utils/helpers/addProviderUniqueIdentifierFilterToAppliedFilters";
import { ResourceDataType } from "../utils/types/types";

interface FilterOutResourcesThunkArgs {
  resourceType: ResourceType;
  selectedResources: ResourceDataType[] | undefined;
}

export const filterOutResourcesThunk = createAsyncThunk(
  "liveUsageMgmt/filterOutResources",
  (
    { resourceType, selectedResources }: FilterOutResourcesThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    const selectedResourceType = selectedResources?.at(0)?.resource_type;

    if (!selectedResources || !selectedResourceType) {
      return;
    }

    const appliedFilters = getFinalAppliedFilters(
      resourceType,
      liveUsageMgmtAppliedFiltersByResourceTypeSelector(state, resourceType),
    );

    const uniqueIds = selectedResources?.map(
      (item) => item.resource.provider_unique_identifier,
    );

    const newFilters = addProviderUniqueIdentifierFilterToAppliedFilters({
      uniqueIds,
      selectedResourceType,
      appliedFilters,
    });

    dispatch(setLiveUsageMgmtAppliedFilters(newFilters));
    dispatch(setLiveUsageMgmtFilterChangedManually());
    dispatch(resetLiveUsageMgmtSelectedResources(selectedResourceType));
    dispatch(getLiveUsageMgmtResourcesWithFiltersByProviderThunk(resourceType));
  },
);

const getFinalAppliedFilters = (
  resourceType: ResourceType,
  appliedFilters: ResourceFilters | null,
): ResourceFilters => {
  const hasAppliedFilters = appliedFilters?.filter_items?.length;

  if (hasAppliedFilters) {
    return appliedFilters;
  }

  return {
    type: resourceType,
    filter_groups: [],
    filter_items: [],
    operators: ["and"],
  };
};
