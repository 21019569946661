import { FC } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { Stack, Tooltip, Typography } from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import { ReservationsCoverageDataWithId } from "../../../../../../common/utils/types";
import { CommitmentsTableName } from "../../../../../../common/components/data-grid/cells/CommitmentsTableName";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";

export const ReservationCoverageAccountCellRenderer: FC<
  CustomCellRendererProps<ReservationsCoverageDataWithId>
> = ({ data }) => {
  if (!data) {
    return;
  }

  return (
    <CommitmentsTableName
      type="account"
      id={data.id}
      name={
        <Typography variant="body2" align="left" component="div">
          <Stack direction="row" spacing={0.5}>
            {data.account?.is_management_account && (
              <Tooltip title="Management account" placement="top" arrow>
                <StarsIcon color="primary" fontSize="small" />
              </Tooltip>
            )}

            <TypographyWithTooltip variant="body2" title={data.account?.name} />
          </Stack>

          <TypographyWithTooltip
            variant="body2"
            title={data.account?.provider_account_id}
          />
        </Typography>
      }
    />
  );
};
