import { FC, ReactNode } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { TrendingDown, TrendingFlat, TrendingUp } from "@mui/icons-material";
import { green, grey, red } from "@mui/material/colors";
import { SxProps } from "@mui/system";
import numeral from "numeral";

type TrendSize = "small" | "large";

type TrendColors = {
  color: string;
  bgcolor: string;
};

export type TrendTypes = "positive" | "negative" | "neutral";

interface TrendChipProps {
  value?: number | null;
  title?: ReactNode;
  tooltipTitle?: string | null;
  size?: TrendSize;
}

export const TrendChip: FC<TrendChipProps> = ({
  value,
  tooltipTitle,
  size = "large",
  title,
}) => {
  if (value !== 0 && !value) {
    return null;
  }

  let trend: TrendTypes = "negative";

  if (value < 0) {
    trend = "positive";
  }

  if (value === 0) {
    trend = "neutral";
  }

  const formatedValue = title ?? numeral(value).format("0.00");

  const styles = {
    color: trendColors.get(trend)?.color,
    bgcolor: trendColors.get(trend)?.bgcolor,
  };

  const trendStyles = {
    color: styles.color,
    fontSize: 16,
  };

  return (
    <Tooltip title={tooltipTitle ?? ""} arrow placement="top">
      <Stack
        direction="row"
        alignItems="center"
        bgcolor={styles.bgcolor}
        maxWidth="fit-content"
        borderRadius={1}
        sx={sxStyles[size]}
      >
        {trend === "positive" && <TrendingDown sx={trendStyles} />}
        {trend === "negative" && <TrendingUp sx={trendStyles} />}
        {trend === "neutral" && <TrendingFlat sx={trendStyles} />}

        <Typography
          noWrap
          color={styles.color}
          fontSize={10}
          fontWeight="medium"
        >
          {trend === "negative" ? `+${formatedValue}` : formatedValue} %
        </Typography>
      </Stack>
    </Tooltip>
  );
};

const sxStyles: Record<TrendSize, SxProps> = {
  small: { gap: 0.25, px: 0.5, py: 0.25 },
  large: { gap: 0.5, p: 0.5 },
};

export const trendColors = new Map<TrendTypes, TrendColors>([
  ["positive", { color: green[700], bgcolor: green[50] }],
  ["negative", { color: red[700], bgcolor: red[50] }],
  ["neutral", { color: "text.secondary", bgcolor: grey[100] }],
]);
