import { resourceExplorerPathSelector } from "./resourceExplorerPathSelector";
import { RootState } from "../../../../store";
import { ResourceExplorerGroupingNullable } from "../../../../../services/cloudchipr.api";

export const resourceExplorerPathLastItemGroupingOptionsSelector = (
  state: RootState,
): ResourceExplorerGroupingNullable[] | undefined => {
  const path = resourceExplorerPathSelector(state);
  const lastItem = path?.at(-1);

  if (!lastItem?.groupingOptions) {
    return [];
  }
  return lastItem?.groupingOptions?.filter((option) => {
    return (
      path.every(({ groupedBy }) => groupedBy !== option) ||
      option === lastItem.groupedBy
    );
  });
};
