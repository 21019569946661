import { FC, useMemo } from "react";
import { KubernetesClusterAddButton } from "./add-cluster/KubernetesClusterAddButton";
import { PageHeader } from "../../../../../common/PageHeader";
import { noOrgAccountOrgId } from "../../../../utils/helpers/noOrganizationConstants";
import { useAppSelector } from "../../../../../../../store/hooks";
import { kubernetesClustersLoadingSelector } from "../../../../../../../store/accounts/selectors/kubernetes/kubernetesClustersLoadingSelector";
import { kubernetesClustersIdsSelector } from "../../../../../../../store/accounts/selectors/kubernetes/kubernetesClustersIdsSelector";
import { AccountTabsWithResources } from "../../../../utils/types/types";
import { AccountsHeaderNavigation } from "../../../header/AccountsHeaderNavigation";

interface KubernetesClusterHeaderProps {
  activeCount?: number;
  activeTab: AccountTabsWithResources;
}

export const KubernetesClusterHeader: FC<KubernetesClusterHeaderProps> = ({
  activeCount,
  activeTab,
}) => {
  const accountsExist = !!activeCount;
  const loading = useAppSelector(kubernetesClustersLoadingSelector);

  const clusterIds = useAppSelector(kubernetesClustersIdsSelector);

  const actions = useMemo(() => {
    if (!accountsExist) {
      return;
    }

    return <KubernetesClusterAddButton />;
  }, [accountsExist]);

  if (!accountsExist) {
    return null;
  }

  return (
    <PageHeader
      sticky
      breadcrumbs={
        accountsExist ? (
          <AccountsHeaderNavigation
            activeTab={activeTab}
            activeCount={activeCount ?? 0}
            inactiveCount={null}
            orgId={noOrgAccountOrgId}
            provider="kubernetes"
            accountIds={clusterIds ?? []}
          />
        ) : undefined
      }
      loading={loading}
      actions={actions}
    />
  );
};
