import { FC, useCallback, useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import { useEffectOnceWhen } from "rooks";
import { AccountsHeader } from "./AccountsHeader";
import { accountsPageTabSearchParam } from "../../../live-usage-mgmt/utils/constants/searchParams";
import { OutletContextType } from "../../../../layout/MainLayout";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { AccountTabsWithResources } from "../../utils/types/types";
import { useAppSelector } from "../../../../../store/hooks";
import { accountOrganisationIdSelector } from "../../../../../store/accounts/selectors/slice-data/accountOrganisationIdSelector";
import { RootState } from "../../../../../store/store";
import { generateLiveResourcesPath } from "../../utils/helpers/generateLiveResourcesPath";
import { AccountSetupDialog } from "../../../common/connectors/AccountSetupDialog";
import { KubernetesClusterHeader } from "../kubernetes/components/header/KubernetesClusterHeader";
import { accountsWithKubernetesDividedToStatusesByOrgIdSelector } from "../../../../../store/accounts/selectors/accountsWithKubernetesDividedToStatusesByOrgIdSelector";

export const CloudAccountHeaderWrapper: FC = () => {
  const outletContext = useOutletContext<OutletContextType>();

  const location = useLocation();
  const navigate = useNavigate();
  const { provider } = useParams<{ provider: ProviderType }>();

  const [searchParams] = useSearchParams();
  const accountTypeFromSearchParams = searchParams.get(
    accountsPageTabSearchParam,
  );

  const [activeTab, setActiveTab] = useState<AccountTabsWithResources | null>(
    null,
  );

  const orgId = useAppSelector(accountOrganisationIdSelector);

  const accountsSelector = useCallback(
    (state: RootState) =>
      accountsWithKubernetesDividedToStatusesByOrgIdSelector(
        state,
        provider,
        orgId,
      ),
    [provider, orgId],
  );

  const accounts = useAppSelector(accountsSelector);
  const activeAccountsLength = accounts?.active?.length ?? 0;
  const inactiveAccountsLength = accounts?.inactive?.length ?? 0;

  const isLiveResources = location.pathname.endsWith("live-resources");
  const effectsCanWork = !!(provider && orgId);

  useEffectOnceWhen(
    () => {
      if (!provider || !orgId) {
        return;
      }

      const activeTab = activeAccountsLength ? "active" : "inactive";

      navigate(generateLiveResourcesPath({ provider, orgId, activeTab }));
    },
    !accountTypeFromSearchParams && !isLiveResources && effectsCanWork,
  );

  useEffect(() => {
    if (accountTypeFromSearchParams) {
      setActiveTab(accountTypeFromSearchParams as AccountTabsWithResources);
    }
  }, [accountTypeFromSearchParams]);

  useEffect(() => {
    if (
      activeTab !== "live-resources" &&
      !accountTypeFromSearchParams &&
      isLiveResources
    ) {
      setActiveTab("live-resources");
    }
  }, [accountTypeFromSearchParams, activeTab, isLiveResources]);

  if (!provider || !activeTab) {
    return null;
  }

  return (
    <TabContext value={activeTab}>
      {provider === "kubernetes" ? (
        <KubernetesClusterHeader
          activeCount={activeAccountsLength ?? 0}
          activeTab={activeTab}
        />
      ) : (
        <AccountsHeader
          orgId={orgId}
          provider={provider}
          activeTab={activeTab}
          activeCount={activeAccountsLength}
          inactiveCount={inactiveAccountsLength}
        />
      )}

      <Outlet context={outletContext} />
      <AccountSetupDialog />
    </TabContext>
  );
};
