import { FC, useCallback } from "react";
import { SelectChangeEvent } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../store/hooks";
import { coverageSetupVisualizationChartTypeSelector } from "../../../../../../../../../../../../../store/dashboards/selectors/setups/coverage/visualization/coverageSetupVisualizationChartTypeSelector";
import { WidgetViewType } from "../../../../../../../../../../../../../store/dashboards/utils/types/types";
import { ChartType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { coverageWidgetVisualizationChangeThunk } from "../../../../../../../../../../../../../store/dashboards/thunks/widgets/coverage-widget/setup-change/coverageWidgetVisualizationChangeThunk";
import { WidgetViewTypeSelect } from "../../../../../../common/WidgetViewTypeSelect";

export const CoverageWidgetChartTypeSelect: FC = () => {
  const dispatch = useAppDispatch();
  const chartType = useAppSelector(coverageSetupVisualizationChartTypeSelector);

  const handleChange = useCallback(
    (event: SelectChangeEvent<WidgetViewType>) => {
      dispatch(
        coverageWidgetVisualizationChangeThunk({
          chart_type: event.target.value as ChartType,
        }),
      );
    },
    [dispatch],
  );

  return (
    <WidgetViewTypeSelect
      viewType={chartType}
      options={options}
      onChange={handleChange}
    />
  );
};

const options: WidgetViewType[] = [
  "stack",
  "horizontal_stack_bar",
  "bar",
  "table",
  "numeral",
];
