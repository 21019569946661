import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../../../store";
import { setResourceExplorerExplainWithAIData } from "../../resourceExplorerSlice";
import { billingExplorerExplainWithAiDataSelector } from "../../selectors/current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiDataSelector";
import { getBillingExplorerSummaryFixedCashKeySelector } from "../../selectors/resource-explorer-summary/getBillingExplorerSummaryFixedCashKeySelector";
import { resourceExplorerPayloadDataSelector } from "../../selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerPayloadDataSelector";

export const updateBillingExplorerSummaryMetaDataThunk = createAsyncThunk(
  "resourceExplorer/updateBillingExplorerSummaryMetaData",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const explainWithAiData = billingExplorerExplainWithAiDataSelector(state);
    const key = getBillingExplorerSummaryFixedCashKeySelector(state);
    const payload = resourceExplorerPayloadDataSelector(state);

    if (!key) {
      return;
    }

    if (explainWithAiData?.length === 1) {
      return;
    }

    if (explainWithAiData?.length === 2) {
      if (key !== explainWithAiData?.at(0)?.key) {
        dispatch(setResourceExplorerExplainWithAIData([explainWithAiData[1]]));
        return;
      }

      const updatedData = [
        {
          key: key,
          payload,
        },
        ...[explainWithAiData[1]],
      ];

      dispatch(setResourceExplorerExplainWithAIData(updatedData));
      return;
    }

    const updatedData = [
      {
        key: key,
        payload,
      },
      ...explainWithAiData,
    ];

    dispatch(setResourceExplorerExplainWithAIData(updatedData));
  },
);
