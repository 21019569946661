import { FC } from "react";
import { IconButton, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../../../../../store/hooks";
import { alertsDrawerEditModeSelector } from "../../../../../../../store/alerts/selectors/alertsDrawerEditModeSelector";
import { alertsActiveTabSelector } from "../../../../../../../store/alerts/selectors/alertsActiveTabSelector";
import { isCurrentBudgetEditingSelector } from "../../../../../../../store/budgets/selectors/current-budget/data-selectors/isCurrentBudgetEditingSelector";
import { AlertTabType } from "../../../../../../../store/alerts/utils/types/types";

interface AlertDrawerHeaderProps {
  onClose(): void;
}

export const AlertDrawerHeader: FC<AlertDrawerHeaderProps> = ({ onClose }) => {
  const budgetsEditMode = useAppSelector(isCurrentBudgetEditingSelector);
  const alertsEditMode = useAppSelector(alertsDrawerEditModeSelector);
  const alertsActiveTab = useAppSelector(alertsActiveTabSelector);

  const editMode = budgetsEditMode || alertsEditMode;

  const actionName = editMode ? "Edit" : "Create";
  const drawerHeading = drawerHeadings[alertsActiveTab];

  return (
    <Stack
      py={1}
      px={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="subtitle1">{`${actionName} ${drawerHeading}`}</Typography>
      <IconButton size="small" onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};

const drawerHeadings: Record<AlertTabType, string> = {
  budget: "Budget",
  costAnomaly: "Cost Anomaly Alert",
  utilization: "Commitment Alert",
};
