import { FC, Fragment } from "react";
import { Button, Dialog } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { FiltersDialogContent } from "./filters-modal/FiltersDialogContent";
import { FilterSourceButton } from "./utils/types/types";
import {
  FilterKeyType,
  ResourceFilters,
} from "../../../../../services/cloudchipr.api";
import { DialogTitleClosable } from "../../../../common/dialog-utils/DialogTitleClosable";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";
import { WithResourceType } from "../../../live-usage-mgmt/utils/types";

interface FiltersSourceButtonProps extends WithResourceType {
  accountIds?: string[];
  hideRevert?: boolean;
  filters: ResourceFilters;
  excludedFilters?: FilterKeyType[];
  defaultFilters: ResourceFilters;
  onSubmit(values: ResourceFilters): void;
  SourceButton?: FilterSourceButton;
}

export const FiltersSourceButton: FC<FiltersSourceButtonProps> = ({
  resourceType,
  onSubmit,
  filters,
  accountIds,
  excludedFilters,
  defaultFilters,
  SourceButton,
  hideRevert,
}) => {
  const { open, openDialog, closeDialog } = useDialog();
  const filtersLength = filters.filter_items.length;

  return (
    <Fragment>
      {SourceButton ? (
        <SourceButton resourceType={resourceType} openDialog={openDialog} />
      ) : (
        <Button
          size="small"
          color={filtersLength ? "primary" : "tertiary"}
          variant="outlined"
          onClick={openDialog}
          sx={{ borderRadius: 4, textTransform: "none" }}
          startIcon={<FilterAltOutlinedIcon />}
          endIcon={<KeyboardArrowDownOutlinedIcon />}
        >
          Filters ({filtersLength})
        </Button>
      )}

      <Dialog fullWidth maxWidth="lg" open={open} onClose={closeDialog}>
        <DialogTitleClosable
          title={`${getResourceTypeName(resourceType)} Filters`}
          onClose={closeDialog}
          TitleTypographyProps={{ sx: { fontWeight: "medium" } }}
        />

        <FiltersDialogContent
          hideRevert={hideRevert}
          filters={filters}
          onSubmit={onSubmit}
          onClose={closeDialog}
          accountIds={accountIds}
          defaultFilters={defaultFilters}
          resourceType={resourceType}
          excludedFilters={excludedFilters}
        />
      </Dialog>
    </Fragment>
  );
};
