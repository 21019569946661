import { FC } from "react";
import { Avatar } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { SxProps } from "@mui/system";

interface DefaultUserAvatarProps {
  sx?: SxProps;
  iconSx?: SxProps;
}

export const DefaultUserAvatar: FC<DefaultUserAvatarProps> = ({
  sx,
  iconSx,
}) => {
  return (
    <Avatar sx={{ bgcolor: "grey.300", width: 28, height: 28, ...sx }}>
      <PersonOutlineOutlinedIcon
        fontSize="small"
        sx={{ color: "text.secondary", ...iconSx }}
      />
    </Avatar>
  );
};
