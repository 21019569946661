import { FC, useCallback, useState } from "react";
import { Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffectOnceWhen } from "rooks";
import { RevertButton, RevertButtonProps } from "./RevertButton";
import { FilterSelect } from "../FilterSelect";
import { NewFilterAddFn } from "../popover/FilterItemSelectMenuItem";

export interface FiltersMainActionsProps extends RevertButtonProps {
  onAddNewFilter: NewFilterAddFn;
  showSelectOnFirstOpening?: boolean;
}

export const FiltersMainActions: FC<FiltersMainActionsProps> = ({
  onAddNewFilter,
  showSelectOnFirstOpening,
  onRevertFilters,
}) => {
  const [showSelect, setShowSelect] = useState(false);

  const addFilterClickHandler = useCallback(() => {
    setShowSelect(true);
  }, []);

  const addFilterRemoveHandler = useCallback(() => {
    setShowSelect(false);
  }, []);

  useEffectOnceWhen(() => {
    setShowSelect(true);
  }, !!showSelectOnFirstOpening);

  return (
    <Stack spacing={1}>
      {showSelect && (
        <FilterSelect
          onSelect={onAddNewFilter}
          onRemove={addFilterRemoveHandler}
        />
      )}

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Button
          size="small"
          variant="outlined"
          startIcon={<AddIcon />}
          disabled={showSelect}
          sx={{ alignSelf: "flex-start" }}
          onClick={addFilterClickHandler}
        >
          Add Filter
        </Button>

        {onRevertFilters && <RevertButton onRevertFilters={onRevertFilters} />}
      </Stack>
    </Stack>
  );
};
