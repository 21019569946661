import { FC } from "react";
import { webhookIntegrationGridColumns } from "./webhookIntegrationGridColumns";
import { useGetUsersMeIntegrationsQuery } from "../../../../../../../../services/cloudchipr.api";
import { CommunicationIntegrationsDataGrid } from "../../../common/components/grid/CommunicationIntegrationsDataGrid";
import { IntegrationsEmptyState } from "../../../../common/components/IntegrationsEmptyState";
import { integrationDetails } from "../../../common/utils/constants/integrationDetails";
import { WebhookIntegrationConnectButton } from "../WebhookIntegrationConnectButton";

export const WebhookIntegrationGrid: FC = () => {
  const { data, isLoading } = useGetUsersMeIntegrationsQuery({
    type: "webhook",
  });

  if (!isLoading && !data?.length) {
    return <EmptyState />;
  }

  return (
    <CommunicationIntegrationsDataGrid
      columnDefs={webhookIntegrationGridColumns}
      data={data}
      loading={isLoading}
      uniqueKey="webhookIntegrationsDataGrid"
      actionButton={
        <WebhookIntegrationConnectButton withStartIcon>
          Add
        </WebhookIntegrationConnectButton>
      }
    />
  );
};

const details = integrationDetails["webhook"];

const EmptyState: FC = () => (
  <IntegrationsEmptyState
    title={details.noDataDescription}
    description={details.description}
  >
    <WebhookIntegrationConnectButton withStartIcon variant="contained">
      Connect
    </WebhookIntegrationConnectButton>
  </IntegrationsEmptyState>
);
