import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Typography } from "@mui/material";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../../../services/cloudchipr.api";
import { AzureSavingsPlanCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { getUtilizationAndCoverageDrawerDynamicHeaderCellTitles } from "../../../../../../../../utilization-and-coverage/utils/helpers/getUtilizationAndCoverageDrawerDynamicHeaderCellTitles";
import { money } from "../../../../../../../../../../../utils/numeral/money";
import { CommitmentsProgressBar } from "../../../../../../../../common/components/CommitmentsProgressBar";

export const getAzureSavingsPlanCommonColumnDefs = (
  grouping: SavingsPlanCoverageGrouping,
): ColDef<AzureSavingsPlanCoverageDataWithId>[] => {
  const headerNames = getUtilizationAndCoverageDrawerDynamicHeaderCellTitles(
    "commitment",
    grouping,
  );

  return [
    {
      flex: 1.5,
      minWidth: 200,
      field: "covered_usage",
      headerName: headerNames?.coveredUsageCell,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => (
        <Typography variant="body2">
          {money(data?.covered_usage ?? 0)}
        </Typography>
      ),
      valueGetter: ({ data }) => money(data?.covered_usage ?? 0),
    },

    {
      flex: 1.5,
      minWidth: 230,
      field: "coverage",
      headerName: headerNames?.coverageCell,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => (
        <CommitmentsProgressBar
          overrideColor="info.light"
          value={data?.coverage ?? 0}
        />
      ),
    },
  ];
};
