import { Box, Stack } from "@mui/material";
import { CSSProperties } from "react";
import { BillingExplorerExplainWithAiLoading } from "../../../../../resource-explorer/components/explain-with-ai-panel/components/BillingExplorerExplainWithAiLoading";

export const AskAICollapsableConversationItemBodyLoading = () => {
  return (
    <Stack spacing={1.5} direction="row" alignItems="center">
      <Box sx={boxStyles}>
        <style>{keyframesStyle}</style>

        <div style={loaderStyle}></div>
      </Box>
      <BillingExplorerExplainWithAiLoading hideIcon />
    </Stack>
  );
};

const loaderStyle: CSSProperties = {
  width: "3px",
  height: "3px",
  borderRadius: "50%",
  animation: "dots 1.3s infinite linear alternate",
  position: "absolute",
  backgroundColor: "white",
  boxShadow: "6px 0 white, -6px 0 white",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const boxStyles = {
  position: "relative",
  display: "inline-block",
  width: 28,
  height: 28,
  borderRadius: "50%",
  background: "linear-gradient(100deg, #1443EA, #7334F1)",
};

const keyframesStyle = `
  @keyframes dots {
    0% { box-shadow: 4.5px 0 white, -4.5px 0 white; }
    33% { box-shadow: 4.5px 0 white, -4.5px 0 transparent; }
    66% { box-shadow: 4.5px 0 transparent, -4.5px 0 white; }
    100% { box-shadow: 4.5px 0 transparent, -4.5px 0 transparent; }
  }
`;
