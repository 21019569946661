import { resourceDetailsResourceBillingRequestSelector } from "../requests/resourceDetailsResourceBillingRequestSelector";
import { RootState } from "../../../../store";
import { resourceDetailsBillingProcessesSelector } from "../../processes/resourceDetailsBillingProcessesSelector";

export const resourceDetailsResourceBillingDataLoadingSelector = (
  state: RootState,
) => {
  const processes = resourceDetailsBillingProcessesSelector(state);

  const loadings = Object.keys(processes).map(
    (process) =>
      resourceDetailsResourceBillingRequestSelector(state, process)?.isLoading,
  );

  return loadings.some((loading) => loading);
};
