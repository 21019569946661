import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { azureReservationsDetailsPayloadSelector } from "../../../selectors/azure/reservations/details-drawer/azureReservationsDetailsPayloadSelector";

export const getAzureReservationDetailsThunk = createAsyncThunk(
  "commitments/getAzureReservationDetails",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentAzureReservationsData } =
      cloudChiprApi.endpoints;

    const payload = azureReservationsDetailsPayloadSelector(state);

    if (!payload) {
      return;
    }

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentAzureReservationsData.initiate(payload),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
