import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";

interface AccountsActiveLengthProps {
  provider: ProviderType;
  activeAccountsLength: number;
}

export const AccountsActiveLength: FC<AccountsActiveLengthProps> = ({
  provider,
  activeAccountsLength,
}) => {
  return (
    <Stack direction="row" p={1} pb={2} spacing={1} alignItems="center">
      <Typography variant="h6">
        {getProviderName(provider, { plural: true, capitalize: true })}
      </Typography>
      <Stack
        alignItems="center"
        width={24}
        height={24}
        borderRadius={10}
        justifyContent="center"
        bgcolor={"primary.light"}
      >
        <Typography color="primary.main" fontWeight={500} fontSize={12}>
          {activeAccountsLength}
        </Typography>
      </Stack>
    </Stack>
  );
};
