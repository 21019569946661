import { createAsyncThunk } from "@reduxjs/toolkit";
import { tasksListDataByIdSelector } from "../selectors/data/tasksListDataByIdSelector";
import { RootState } from "../../store";
import {
  setTaskManagementDrawerOpen,
  setTaskManagementTask,
  setTaskManagementTaskTarget,
} from "../taskManagementSlice";
import {
  OpportunityTarget,
  ResourceTarget,
} from "../../../services/cloudchipr.api";
import { TaskTargetType } from "../utils/types/types";

export const openDrawerForTaskByIdThunk = createAsyncThunk(
  "taskManagement/openDrawerForTaskById",
  async (taskId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const task = tasksListDataByIdSelector(state, taskId);

    if (!task) {
      return;
    }

    dispatch(setTaskManagementDrawerOpen(true));

    dispatch(
      setTaskManagementTask({
        id: task.id,
        name: task.name,
        status_id: task.status.id,
        owner_id: task.owner?.id ?? null,
        priority_id: task.priority?.id ?? null,
        action_id: task.action?.id ?? null,
        environment_id: task.environment?.id ?? null,
        description: task.description,
        assignees: task.assignees?.map(({ id }) => id),
        due_date: task.due_date,
      }),
    );

    let target: TaskTargetType | null = null;

    if (task.source === "live_usage") {
      target = {
        type: "resource",
        resources: [task.target as ResourceTarget],
      };
    }

    if (task.source === "opportunity") {
      target = {
        type: "opportunity",
        opportunities: [task.target as OpportunityTarget],
      };
    }

    dispatch(setTaskManagementTaskTarget(target));
  },
);
