import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { azureSavingsPlansDataForTableSelector } from "./azureSavingsPlansDataForTableSelector";
import { addIdInCoverageData } from "../../../../utils/helpers/addIdInCoverageData";
import { AzureSavingsPlanCoverageDataWithId } from "../../../../../../components/pages/commtiments/common/utils/types";

export const azureSavingsPlansCoverageDataSelector = createDraftSafeSelector(
  [azureSavingsPlansDataForTableSelector],
  (data): AzureSavingsPlanCoverageDataWithId[] | undefined => {
    return addIdInCoverageData(
      data?.utilization_and_coverage?.coverage_data ?? [],
    );
  },
);
