import { FC, Fragment } from "react";
import { Button, Menu, Theme } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AccountsHeaderProviderSelectItem } from "./AccountsHeaderProviderSelectItem";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../store/hooks";
import { allAccountsGroupedByProviderAndOrgFlatSelector } from "../../../../../store/accounts/selectors/all-providers/allAccountsGroupedByProviderAndOrgFlatSelector";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";
import { ProviderIcon } from "../../../../common/ProviderIcon";
import { useMenuHook } from "../../../../../utils/hooks/useMenu.hook";

interface AccountsHeaderProviderSelectProps {
  provider: ProviderType;
}

export const AccountsHeaderProviderSelect: FC<
  AccountsHeaderProviderSelectProps
> = ({ provider }) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const providersWithOrgs = useAppSelector(
    allAccountsGroupedByProviderAndOrgFlatSelector,
  );

  if (!providersWithOrgs) {
    return null;
  }

  return (
    <Fragment>
      <Button
        variant="text"
        color="tertiary"
        sx={buttonStyles}
        onClick={openMenu}
        startIcon={<ProviderIcon provider={provider} />}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        {getProviderName(provider, {
          title: true,
        })}
      </Button>

      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        {providersWithOrgs.map((props) => {
          return (
            <AccountsHeaderProviderSelectItem
              key={props.provider}
              onClose={closeMenu}
              {...props}
            />
          );
        })}
      </Menu>
    </Fragment>
  );
};

const buttonStyles = {
  color: "grey.700",
  textTransform: "none",
  ":hover": {
    bgcolor: (theme: Theme) => theme.palette.action.hover,
  },
  "& .MuiButton-endIcon": {
    ml: 0.5,
  },
};
