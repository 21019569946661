import { DataViewTab } from "../../../../../../store/commitments/utils/types/types";
import { SavingsPlanCoverageGrouping } from "../../../../../../services/cloudchipr.api";

export const getUtilizationAndCoverageDrawerDynamicHeaderCellTitles = (
  dataViewTab: DataViewTab,
  grouping: SavingsPlanCoverageGrouping,
  type?: "reservation" | "savings plan",
) => {
  if (dataViewTab === "coverage") {
    return {
      coverageCell:
        grouping === "commitment" ? "Savings Plan Covers" : "Coverage",
      coveredUsageCell:
        type === "reservation"
          ? "Spend Covered by RIs"
          : "Spend Covered by Savings Plans",
    };
  }

  if (dataViewTab === "commitment") {
    return {
      coverageCell:
        type === "reservation"
          ? "Covered by this RI"
          : "Covered by This Savings Plan",
      coveredUsageCell:
        type === "reservation"
          ? "Spend Covered by this RI"
          : "Spend Covered by This Savings Plan",
    };
  }
};
