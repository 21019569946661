import { FC } from "react";
import Typography from "@mui/material/Typography";
import { Box, Container, Grid } from "@mui/material";
import { ProfileUpdateForm } from "./components/ProfileUpdateForm";
import { ProfileSelfDelete } from "./components/ProfileSelfDelete";
import { PageHeader } from "../../../common/PageHeader";
import { UserAvatar } from "../../../../common/avatars/UserAvatar";
import { useGetUsersMeQuery } from "../../../../../services/cloudchipr.api";

export const Profile: FC = () => {
  const { data: user } = useGetUsersMeQuery({});

  return (
    <Box bgcolor="white" height="100%">
      <PageHeader title="Profile" />

      <Container
        sx={{ p: 2, bgcolor: "white" }}
        disableGutters
        maxWidth={false}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10} lg={6} pb={5}>
            <Typography variant="h6" py={1}>
              Profile Information
            </Typography>
            <UserAvatar
              name={user?.name}
              profile={user?.profile}
              sx={{ height: 64, width: 64, fontSize: 28, my: 3 }}
              fallbackIconSx={{ width: 32, height: 32 }}
            />
            <ProfileUpdateForm />
            <ProfileSelfDelete />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
