import { createAsyncThunk } from "@reduxjs/toolkit";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { ResourceExplorerGroupingNullable } from "../../../../../../services/cloudchipr.api";
import { billingExplorerWidgetSetupSelector } from "../../../../selectors/setups/billing-explorer/billingExplorerWidgetSetupSelector";
import { getBillingExplorerWidgetSetupDataThunk } from "../getBillingExplorerWidgetSetupDataThunk";

interface BillingExplorerWidgetGroupingChangeThunkArgs {
  grouping: ResourceExplorerGroupingNullable;
  groupValues?: string[];
}
export const billingExplorerGroupingChangeThunk = createAsyncThunk(
  "dashboards/billingExplorerWidgetGroupingChange",
  async (
    { grouping, groupValues }: BillingExplorerWidgetGroupingChangeThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const existingSetup = billingExplorerWidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }
    dispatch(
      setWidgetSetup({
        ...existingSetup,
        grouping,
        group_values: groupValues ?? null,
      }),
    );
    dispatch(getBillingExplorerWidgetSetupDataThunk());
  },
);
