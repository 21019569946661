import { FC, memo, useCallback } from "react";
import { TabList } from "@mui/lab";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import { ViewAgendaOutlined } from "@mui/icons-material";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import { AccountTabsWithResources } from "../../../utils/types/types";
import { useAccountStorageMemoizedTabHook } from "../../../utils/hooks/useAccountStorageMemoizedTabHook";
import { pageHeaderHeight } from "../../../../common/PageHeader";

import { generateLiveResourcesPath } from "../../../utils/helpers/generateLiveResourcesPath";
import TreeSvgIcon from "../../../../../../assets/images/icons/TreeSvgIcon";
import TreeDisabledSvgIcon from "../../../../../../assets/images/icons/TreeDisabledSvgIcon";

export interface AccountsTabsProps {
  provider: ProviderType;
  activeCount: number;
  inactiveCount: number | null;
  orgId?: string;
  accountIds?: string[];
  activeTab: AccountTabsWithResources;
}

export const AccountsTabs: FC<AccountsTabsProps> = memo(
  ({ provider, activeCount, inactiveCount, orgId, accountIds, activeTab }) => {
    const { setAccountTab } = useAccountStorageMemoizedTabHook();

    const getTabClickHandler = useCallback(
      (tabValue: AccountTabsWithResources) => () => setAccountTab(tabValue),
      [setAccountTab],
    );

    if (!orgId) {
      return null;
    }

    return (
      <TabList sx={{ background: "white" }}>
        <Tab
          component={Link}
          to={generateLiveResourcesPath({ provider, orgId })}
          icon={
            <TreeSvgIcon
              fontSize="small"
              color={activeTab === "active" ? "primary" : undefined}
            />
          }
          iconPosition="start"
          label={
            <Typography
              variant="inherit"
              textTransform="none"
              width="max-content"
            >
              {getProviderName(provider, { plural: true, capitalize: true })}{" "}
            </Typography>
          }
          onClick={getTabClickHandler("active")}
          value="active"
          sx={tabStyles}
        />

        <Tab
          component={Link}
          to={generateLiveResourcesPath({
            provider,
            activeTab: "live-resources",
            accountIds: accountIds?.join(","),
          })}
          icon={<ViewAgendaOutlined fontSize="small" />}
          iconPosition="start"
          disabled={!activeCount}
          label={
            <Typography
              variant="inherit"
              textTransform="none"
              width="max-content"
            >
              Live Resources
            </Typography>
          }
          onClick={getTabClickHandler("live-resources")}
          value="live-resources"
          sx={tabStyles}
        />

        {inactiveCount !== null && (
          <Box height={28} alignSelf="center" px={1}>
            <Divider orientation="vertical" />
          </Box>
        )}

        {inactiveCount !== null && (
          <Tab
            component={Link}
            icon={
              <TreeDisabledSvgIcon
                fontSize="small"
                color={activeTab === "inactive" ? "primary" : undefined}
              />
            }
            iconPosition="start"
            to={generateLiveResourcesPath({
              provider,
              orgId,
              activeTab: "inactive",
            })}
            label={
              <Typography
                variant="inherit"
                textTransform="none"
                width="max-content"
              >
                Inactive {getProviderName(provider, { plural: true })} (
                {inactiveCount})
              </Typography>
            }
            value="inactive"
            disabled={!inactiveCount}
            sx={tabStyles}
          />
        )}
      </TabList>
    );
  },
);

const paddingHeight = 2;
const tabStyles = {
  minHeight: "inherit",
  height: pageHeaderHeight - paddingHeight,
};
