import { FC, useMemo } from "react";
import { useDragLayer } from "react-dnd";
import { AccountCardDragPreview } from "./AccountCardDragPreview";
import { Account } from "../../../../../../services/cloudchipr.api";

export const CloudAccountDragPreview: FC<{ accounts?: Account[] }> = ({
  accounts,
}) => {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset(),
  }));

  const account = useMemo(() => {
    return accounts?.find((a) => a.id === item?.id);
  }, [item?.id, accounts]);

  if (!account || !isDragging || !currentOffset) {
    return null;
  }

  return (
    <AccountCardDragPreview
      name={account.provider_account_name ?? ""}
      id={account.provider_account_id ?? ""}
      accessType={account.access_type}
      status={account.status}
      missingPermissions={account.missing_permissions}
      currentOffsetX={currentOffset?.x}
      currentOffsetY={currentOffset?.y}
      provider={account.type}
    />
  );
};
