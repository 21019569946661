import { FC } from "react";
import { emailIntegrationGridColumns } from "./emailIntegrationGridColumns";
import { useGetUsersMeIntegrationsQuery } from "../../../../../../../../services/cloudchipr.api";
import { CommunicationIntegrationsDataGrid } from "../../../common/components/grid/CommunicationIntegrationsDataGrid";
import { IntegrationsEmptyState } from "../../../../common/components/IntegrationsEmptyState";
import { integrationDetails } from "../../../common/utils/constants/integrationDetails";
import { EmailIntegrationConnectButton } from "../EmailIntegrationConnectButton";

export const EmailIntegrationGrid: FC = () => {
  const { data, isLoading } = useGetUsersMeIntegrationsQuery({
    type: "email",
  });

  if (!isLoading && !data?.length) {
    return <EmptyState />;
  }

  return (
    <CommunicationIntegrationsDataGrid
      columnDefs={emailIntegrationGridColumns}
      data={data}
      loading={isLoading}
      uniqueKey="emailIntegrationsDataGrid"
      actionButton={
        <EmailIntegrationConnectButton withStartIcon>
          Add
        </EmailIntegrationConnectButton>
      }
    />
  );
};

const details = integrationDetails["email"];

const EmptyState: FC = () => (
  <IntegrationsEmptyState
    title={details.noDataDescription}
    description={details.description}
  >
    <EmailIntegrationConnectButton withStartIcon variant="contained">
      Create
    </EmailIntegrationConnectButton>
  </IntegrationsEmptyState>
);
