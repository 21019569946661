import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { coverageSetupVisualizationChartTypeSelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/coverage/visualization/coverageSetupVisualizationChartTypeSelector";
import { coverageSetupHasAggregationSelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/coverage/aggregation/coverageSetupHasAggregationSelector";
import { coverageSetupVisualizationChartBaseSelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/coverage/visualization/coverageSetupVisualizationChartBaseSelector";
import { coverageWidgetSetupChartDataSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/coverage/setup/coverageWidgetSetupChartDataSelector";
import { coverageWidgetSetupDataGroupsSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/coverage/setup/coverageWidgetSetupDataGroupsSelector";
import { CoverageWidgetChartView } from "../../../../../../../widgets/coverage/components/CoverageWidgetChartView";

export const CoverageWidgetSetupChartView: FC = () => {
  const chartType = useAppSelector(coverageSetupVisualizationChartTypeSelector);
  const hasAggregation = useAppSelector(coverageSetupHasAggregationSelector);
  const chartBase = useAppSelector(coverageSetupVisualizationChartBaseSelector);
  const groups = useAppSelector(coverageWidgetSetupDataGroupsSelector);
  const chartData = useAppSelector(coverageWidgetSetupChartDataSelector);

  return (
    <CoverageWidgetChartView
      chartType={chartType}
      hasAggregation={hasAggregation}
      chartBase={chartBase}
      chartData={chartData}
      groups={groups}
    />
  );
};
