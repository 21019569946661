import { useCallback, FC, ReactNode, useState } from "react";
import { Box, Button, ButtonProps, Theme } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SxProps } from "@mui/system";
import { useTimeoutWhen } from "rooks";

interface CommandSnippetProps {
  code: string;
  onCopy?(): void;
  disabled?: boolean;
  parentBoxSx?: SxProps<Theme>;
  copyButtonSize?: ButtonProps["size"];
  children?: ReactNode;
}

export const CommandSnippet: FC<CommandSnippetProps> = ({
  code,
  onCopy,
  children,
  disabled,
  parentBoxSx,
  copyButtonSize,
}) => {
  const [copied, setCopied] = useState(false);

  useTimeoutWhen(
    () => {
      setCopied(false);
    },
    3000,
    copied,
  );

  const handleCopyClick = useCallback(() => {
    setCopied(true);
    onCopy?.();
  }, [onCopy]);

  return (
    <Box
      sx={{
        bgcolor: "grey.800",
        p: 2,
        borderRadius: 3,
        color: "grey.400",
        fontFamily: "monospace",
        my: 2,
        position: "relative",
        wordWrap: "break-word",
        ...parentBoxSx,
      }}
    >
      <CopyToClipboard text={code} onCopy={handleCopyClick}>
        <Button
          disabled={disabled}
          size={copyButtonSize ?? "small"}
          sx={{ float: "right", color: "white" }}
        >
          {copied ? "COPIED!" : "COPY"}
        </Button>
      </CopyToClipboard>
      {children || code}
    </Box>
  );
};
