import { azureReservationsDataSelector } from "../../data/azureReservationsDataSelector";
import { commitmentsDetailsDrawerTargetIdSelector } from "../../../../common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";
import { RootState } from "../../../../../../store";

export const azureSelectedReservedInstancePlanUUIdSelector = (
  state: RootState,
) => {
  const id = commitmentsDetailsDrawerTargetIdSelector(state);
  const reservations = azureReservationsDataSelector(state);

  return reservations?.find(
    (reservations) => reservations.provider_unique_id === id,
  )?.id;
};
