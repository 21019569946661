import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { rightsizingRecommendationsStatusSelector } from "./rightsizingRecommendationsStatusSelector";
import {
  ProviderType,
  RecommendationState,
} from "../../../../../services/cloudchipr.api";

export type StatusesAccumulatorType = Record<
  ProviderType,
  Record<RecommendationState, string[]>
>;

export const rightsizingRecommendationsStatusesGroupedSelector =
  createDraftSafeSelector(
    [rightsizingRecommendationsStatusSelector],
    (data): StatusesAccumulatorType => {
      const accumulator: StatusesAccumulatorType = {
        aws: {
          enabled: [],
          disabled: [],
          not_detected: [],
          missing_permission: [],
        },
        gcp: {
          enabled: [],
          disabled: [],
          not_detected: [],
          missing_permission: [],
        },
        azure: {
          enabled: [],
          disabled: [],
          not_detected: [],
          missing_permission: [],
        },
        kubernetes: {
          enabled: [],
          disabled: [],
          not_detected: [],
          missing_permission: [],
        },
      };

      return (
        data?.reduce((acc, item) => {
          if (!item.provider || !item.status) {
            return acc;
          }

          acc[item?.provider]?.[item.status]?.push(item.account_id);

          return acc;
        }, accumulator) ?? accumulator
      );
    },
  );
