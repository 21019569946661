import { GridState } from "ag-grid-community";
import { isDeepEqual } from "../../../../../utils/is-deep-equal";

export const isGridStatesEqual = ({
  gridState,
  viewConfig,
}: {
  viewConfig?: string;
  gridState?: GridState | null;
}) => {
  const gridCleanConfig = JSON.parse(JSON.stringify(gridState ?? {}));

  return isDeepEqual(JSON.parse(viewConfig ?? "{}"), gridCleanConfig);
};
