import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { coveredUsageColumnDef } from "./common/coveredUsageColumnDef";
import { netSavingsColumnDef } from "./common/netSavingsColumnDef";
import { getCoverageColumnDef } from "./common/getCoverageColumnDef";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../common/utils/types";

export const coverageGroupedByCommitmentColumnDefs: ColDef<SavingsPlanCoverageDataWithId>[] =
  [
    {
      flex: 1.5,
      field: "savings_plan.provider_id",
      headerName: "Savings Plan ID",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => (
        <TypographyWithTooltip
          variant="body2"
          title={data?.savings_plan?.provider_id ?? ""}
        />
      ),
    },
    coveredUsageColumnDef,
    netSavingsColumnDef,
    getCoverageColumnDef("commitment"),
    agGridEmptyColumnToFitEmptySpace,
  ];
