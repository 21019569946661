import { FC, useCallback } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { WorkspacesOutlined } from "@mui/icons-material";
import { TaskCreationPropertyRow } from "./TaskCreationPropertyRow";
import { TaskPropertySelection } from "../../../../../common/properties/task-property-selection/TaskPropertySelection";
import { AssigneeSelection } from "../../../../../common/properties/assignee/AssigneeSelection";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { updateTaskManagementTask } from "../../../../../../../../../../store/task-management/taskManagementSlice";
import { StateTaskType } from "../../../../../../../../../../store/task-management/utils/types/types";
import { taskManagementAssigneeSelector } from "../../../../../../../../../../store/task-management/selectors/form/properties/taskManagementAssigneeSelector";
import { taskManagementActionSelector } from "../../../../../../../../../../store/task-management/selectors/form/properties/taskManagementActionSelector";
import { taskManagementStatusSelector } from "../../../../../../../../../../store/task-management/selectors/form/properties/taskManagementStatusSelector";
import { taskManagementPrioritySelector } from "../../../../../../../../../../store/task-management/selectors/form/properties/taskManagementPrioritySelector";
import { taskManagementEnvironmentSelector } from "../../../../../../../../../../store/task-management/selectors/form/properties/taskManagementEnvironmentSelector";
import { TaskDueDateSelection } from "../../../../../common/properties/TaskDueDateSelection";
import { taskManagementDueDateSelector } from "../../../../../../../../../../store/task-management/selectors/form/properties/taskManagementDueDateSelector";
import { taskManagementOwnerSelector } from "../../../../../../../../../../store/task-management/selectors/form/properties/taskManagementOwnerSelector";
import { taskManagementUpdateEditingTaskWithActivitiesThunk } from "../../../../../../../../../../store/task-management/thunks/actions/taskManagementUpdateEditingTaskWithActivitiesThunk";

export const TaskCreationPropertiesSection: FC = () => {
  const dispatch = useAppDispatch();

  const assignee = useAppSelector(taskManagementAssigneeSelector);
  const action = useAppSelector(taskManagementActionSelector);
  const status = useAppSelector(taskManagementStatusSelector);
  const priority = useAppSelector(taskManagementPrioritySelector);
  const environment = useAppSelector(taskManagementEnvironmentSelector);
  const owner = useAppSelector(taskManagementOwnerSelector);
  const dueDate = useAppSelector(taskManagementDueDateSelector);

  const assigneeChangeHandler = useCallback(
    async (values: string[]) => {
      dispatch(updateTaskManagementTask({ assignees: values }));
      await dispatch(taskManagementUpdateEditingTaskWithActivitiesThunk());
    },
    [dispatch],
  );

  const taskPropertyChangeHandler = useCallback(
    (key: keyof StateTaskType) => async (value: string) => {
      dispatch(updateTaskManagementTask({ [key]: value }));
      await dispatch(taskManagementUpdateEditingTaskWithActivitiesThunk());
    },
    [dispatch],
  );

  return (
    <Stack spacing={1}>
      <Typography fontWeight="medium">Properties</Typography>

      <Grid container>
        <TaskCreationPropertyRow label="Assignee" LabelIcon={PersonAddAltIcon}>
          <AssigneeSelection
            onChange={assigneeChangeHandler}
            values={assignee}
          />
        </TaskCreationPropertyRow>
        <TaskCreationPropertyRow label="Action" LabelIcon={AutoFixHighIcon}>
          <TaskPropertySelection
            optionsKey="actions"
            value={action}
            label="Action"
            onChange={taskPropertyChangeHandler("action_id")}
          />
        </TaskCreationPropertyRow>
        <TaskCreationPropertyRow
          label="Status"
          LabelIcon={ExpandCircleDownOutlinedIcon}
        >
          <TaskPropertySelection
            required
            optionsKey="statuses"
            value={status}
            label="Status"
            onChange={taskPropertyChangeHandler("status_id")}
          />
        </TaskCreationPropertyRow>
        <TaskCreationPropertyRow
          label="Due Date"
          LabelIcon={CalendarTodayOutlinedIcon}
        >
          <TaskDueDateSelection
            value={dueDate}
            onChange={taskPropertyChangeHandler("due_date")}
          />
        </TaskCreationPropertyRow>
        <TaskCreationPropertyRow label="Priority" LabelIcon={OutlinedFlagIcon}>
          <TaskPropertySelection
            optionsKey="priorities"
            value={priority}
            label="Priority"
            optionAvatar={
              <OutlinedFlagIcon sx={{ color: "white !important" }} />
            }
            onChange={taskPropertyChangeHandler("priority_id")}
          />
        </TaskCreationPropertyRow>
        <TaskCreationPropertyRow
          label="Environment"
          LabelIcon={PlaceOutlinedIcon}
        >
          <TaskPropertySelection
            optionsKey="environments"
            value={environment}
            label="Environment"
            onChange={taskPropertyChangeHandler("environment_id")}
          />
        </TaskCreationPropertyRow>

        <TaskCreationPropertyRow
          label="Owner Domain"
          LabelIcon={WorkspacesOutlined}
        >
          <TaskPropertySelection
            optionsKey="owners"
            value={owner}
            label="Owner Domain"
            onChange={taskPropertyChangeHandler("owner_id")}
          />
        </TaskCreationPropertyRow>
      </Grid>
    </Stack>
  );
};
