import { stateAccountsSelector } from "./slice-data/stateAccountsSelector";
import { RootState } from "../../store";
import { generateLiveResourcesPath } from "../../../components/pages/accounts-group/utils/helpers/generateLiveResourcesPath";

export const accountsWithMissingPermissionsByIdsSelector = (
  state: RootState,
  accountIds: string[],
) => {
  const accounts = stateAccountsSelector(state)?.filter((account) =>
    accountIds.includes(account.id),
  );

  return accounts?.map((account) => ({
    id: account.id,
    name: account.provider_account_name || account.provider_account_id,
    provider: account.type ?? "aws",
    url: generateLiveResourcesPath({
      provider: account.type ?? "aws",
      activeTab: "live-resources",
      accountIds: account.id,
    }),
  }));
};
