import { RootState } from "../../../../store";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { kubernetesLiveUsageMgmtResourceTypeDataSelector } from "../kubernetesLiveUsageMgmtResourceTypeDataSelector";

export const kubernetesLiveUsageMgmtFilteredResourcesCountSelector = (
  state: RootState,
  resourceType: ResourceType,
): number | undefined => {
  const data = kubernetesLiveUsageMgmtResourceTypeDataSelector(
    state,
    resourceType,
  );

  return data?.resources?.length;
};
