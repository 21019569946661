import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { setResourceDetailsBillingDataProcess } from "../../../../../../../../store/resource-details/resourceDetailsSlice";
import { GroupingOptionIcon } from "../../../../../resource-explorer-grouping-selector-v2/components/GroupingOptionIcon";
import { ResourceExplorerGroupingNullable } from "../../../../../../../../services/cloudchipr.api";
import { getResourceDetailsResourceBillingDataThunk } from "../../../../../../../../store/resource-details/thunks/data/getResourceDetailsResourceBillingDataThunk";
import { resourceDetailsGeneralTabProcessKey } from "../../../../../../../../store/resource-details/utils/constants/processesKeys";
import { resourceDetailsGroupBySelector } from "../../../../../../../../store/resource-details/selectors/options/resourceDetailsGroupBySelector";

export const ResourceDetailsGeneralContentGrouping: FC = () => {
  const dispatch = useAppDispatch();

  const groupBy = useAppSelector((state) =>
    resourceDetailsGroupBySelector(state, resourceDetailsGeneralTabProcessKey),
  );
  const changeHandler = useCallback(
    (event: SelectChangeEvent) => {
      dispatch(
        setResourceDetailsBillingDataProcess({
          key: resourceDetailsGeneralTabProcessKey,
          groupBy: event.target.value as ResourceExplorerGroupingNullable,
        }),
      );

      dispatch(getResourceDetailsResourceBillingDataThunk());
    },
    [dispatch],
  );

  if (!groupBy) {
    return null;
  }

  return (
    <FormControl size="xsmall" sx={{ width: 180, height: 30 }}>
      <InputLabel>Group By</InputLabel>

      <Select
        value={groupBy}
        onChange={changeHandler}
        MenuProps={{ disablePortal: true }}
        label="Group By"
        renderValue={(key) => (
          <Stack direction="row" spacing={1} py={0.25}>
            <GroupingOptionIcon grouping={key as GroupingWithoutNull} />
            <Typography variant="body2">
              {groupings[key as GroupingWithoutNull]}
            </Typography>
          </Stack>
        )}
      >
        {Object.entries(groupings).map(([key, label]) => {
          return (
            <MenuItem value={key} key={key}>
              <ListItemIcon>
                <GroupingOptionIcon
                  grouping={key as ResourceExplorerGroupingNullable}
                />
              </ListItemIcon>

              <ListItemText primary={label} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

type GroupingWithoutNull = Exclude<ResourceExplorerGroupingNullable, null>;

const groupings: Partial<Record<GroupingWithoutNull, string>> = {
  resource: "Resource",
  product_family: "Product Family",
  usage_type: "Usage Type",
  description: "Item Description",
};
