import { ValueGetterParams } from "ag-grid-community";
import { capitalize } from "@mui/material";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../../store/alerts/utils/types/types";
import { formatDate } from "../../../../../../../../../utils/helpers/date-time/datetime-format";
import { Budget } from "../../../../../../../../../services/cloudchipr.api";

export const budgetAndAlertsModifiedByValueGetter = <
  T extends
    | Budget
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>({
  data,
}: ValueGetterParams<T>) => {
  const modifiedBy = data?.modified_by?.name ?? data?.modified_by?.email;

  if (!modifiedBy) {
    return;
  }

  const name = modifiedBy
    .split(" ")
    .map((name) => capitalize(name))
    .join(" ");
  const date = data?.modified_at ?? "";
  return `${name} ${formatDate(date, { type: "dateTime" })}`;
};
