import { RootState } from "../../../../../store";
import { resourceExplorerStateSelector } from "../../../resourceExplorerStateSelector";
import { BillingExplorerChartSettings } from "../../../../utils/types/resourceExplorer";

export const resourceExplorerChartSettingsSelector = (
  state: RootState,
): BillingExplorerChartSettings => {
  const slice = resourceExplorerStateSelector(state);

  return slice.chartSettings;
};
