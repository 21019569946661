import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { updateTasksViewThunk } from "./updateTasksViewThunk";
import { PatchUsersMeOrganisationsCurrentTaskViewsByViewIdApiArg } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { getTasksViewsHierarchyThunk } from "../getTasksViewsHierarchyThunk";
import { tasksCurrentViewIdSelector } from "../../../selectors/views-hierarchy/current-view/tasksCurrentViewIdSelector";
import { tasksCurrentViewConfigSelector } from "../../../selectors/views-hierarchy/current-view/tasksCurrentViewConfigSelector";

export const protectTasksViewThunk = createAsyncThunk(
  "taskManagement/protectTasksView",
  async (
    args: PatchUsersMeOrganisationsCurrentTaskViewsByViewIdApiArg,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const viewId = tasksCurrentViewIdSelector(state);
    const currentConfigs = tasksCurrentViewConfigSelector(state);

    const response = await dispatch(
      updateTasksViewThunk({
        viewId: args.viewId,
        body: {
          config:
            viewId === args.viewId && args?.body?.is_protected
              ? currentConfigs
              : undefined,
          is_protected: args?.body?.is_protected,
          protection_description: args?.body?.protection_description,
        },
      }),
    ).unwrap();

    if (response) {
      await dispatch(getTasksViewsHierarchyThunk());

      const snackbarId: SnackbarKey = enqueueSnackbar(
        `View successfully ${args?.body?.is_protected ? "protected and saved" : "unprotected"}.`,
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );
    }

    return response;
  },
);
