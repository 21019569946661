import { RootState } from "../../../../../../store";
import { commitmentsDetailsDrawerTargetIdSelector } from "../../../../common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";
import { azureSavingsPlansDataSelector } from "../../data/azureSavingsPlansDataSelector";

export const azureSelectedSavingsPlanUUIdSelector = (state: RootState) => {
  const id = commitmentsDetailsDrawerTargetIdSelector(state);
  const savingsPlans = azureSavingsPlansDataSelector(state);

  return savingsPlans?.find(
    (savingsPlan) => savingsPlan.provider_unique_id === id,
  )?.id;
};
