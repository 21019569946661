import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { commitmentsDatesSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { generateFromToFromDates } from "../../../../utilization-and-coverage/utils/helpers/generateFromToFromDates";
import { CommitmentsPageHeaderMetadataCard } from "../../../../common/components/CommitmentsPageHeaderMetadataCard";
import { money } from "../../../../../../../utils/numeral/money";
import { CommitmentsOverviewPotentialSavingsMetadata } from "../CommitmentsOverviewPotentialSavingsMetadata";

interface CommitmentsOverviewMetadataRightSideProps {
  totalNetSavings: number | null;
  totalCoverage: number | null;
  totalUtilization: number | null;
  loading: boolean;
  awsPotentialSavings?: boolean;
}

export const CommitmentsOverviewMetadataRightSide: FC<
  CommitmentsOverviewMetadataRightSideProps
> = ({
  totalNetSavings,
  totalCoverage,
  totalUtilization,
  loading,
  awsPotentialSavings,
}) => {
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dates = useAppSelector(commitmentsDatesSelector);
  const datesFromTo = useMemo(() => {
    return generateFromToFromDates(dates, quarterStartMonth);
  }, [dates, quarterStartMonth]);

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1}>
        {awsPotentialSavings && <CommitmentsOverviewPotentialSavingsMetadata />}

        <Stack flex={1}>
          <CommitmentsPageHeaderMetadataCard
            primary="Net Savings"
            loading={loading}
            value={totalNetSavings === null ? "N/A" : money(totalNetSavings)}
            tooltip={`Total Net Savings from commitments ${datesFromTo}. Calculated by comparing the usage cost covered by Commitments to the public On-Demand cost.`}
          />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Stack flex={1}>
          <CommitmentsPageHeaderMetadataCard
            primary="Coverage"
            loading={loading}
            value={totalCoverage === null ? "N/A" : `${totalCoverage}%`}
            tooltip={`Total Coverage ${datesFromTo}`}
          />
        </Stack>
        <Stack flex={1}>
          <CommitmentsPageHeaderMetadataCard
            primary="Utilization"
            loading={loading}
            value={totalUtilization === null ? "N/A" : `${totalUtilization}%`}
            tooltip={`Total Commitments Utilization ${datesFromTo}.`}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
