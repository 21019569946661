import { FC, useMemo } from "react";
import { Divider, Stack } from "@mui/material";
import { azureReservationsGranularDataTotalCommitmentSelector } from "../../../../../../../../../store/commitments/selectors/azure/reservations/data/granular/totals/azureReservationsGranularDataTotalCommitmentSelector";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { azureReservationsGranularDataTotalCoverageSelector } from "../../../../../../../../../store/commitments/selectors/azure/reservations/data/granular/totals/azureReservationsGranularDataTotalCoverageSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { commitmentsDatesSelector } from "../../../../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { generateFromToFromDates } from "../../../../../../utilization-and-coverage/utils/helpers/generateFromToFromDates";
import { azureReservationsForGranularLoadingSelector } from "../../../../../../../../../store/commitments/selectors/azure/reservations/data/loading/azureReservationsForGranularLoadingSelector";
import { money } from "../../../../../../../../../utils/numeral/money";
import { CommitmentsChartMetadataCard } from "../../../../../../common/components/chart/CommitmentsChartMetadataCard";
import { azureReservationsGranularDataTotalUtilizationSelector } from "../../../../../../../../../store/commitments/selectors/azure/reservations/data/granular/totals/azureReservationsGranularDataTotalUtilizationSelector";
import { azureReservationsDataIsNotAvailableSelector } from "../../../../../../../../../store/commitments/selectors/azure/reservations/data/azureReservationsDataIsNotAvailableSelector";

export const AzureReservationsMultiTypeChartMetadata: FC = () => {
  const totalCommitment = useAppSelector(
    azureReservationsGranularDataTotalCommitmentSelector,
  );
  const totalUtilization = useAppSelector(
    azureReservationsGranularDataTotalUtilizationSelector,
  );

  const totalCoverage = useAppSelector(
    azureReservationsGranularDataTotalCoverageSelector,
  );
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dates = useAppSelector(commitmentsDatesSelector);
  const datesFromTo = useMemo(() => {
    return generateFromToFromDates(dates, quarterStartMonth);
  }, [dates, quarterStartMonth]);

  const loading = useAppSelector(azureReservationsForGranularLoadingSelector);
  const notAvailable = useAppSelector(
    azureReservationsDataIsNotAvailableSelector,
  );
  const notAvailableMessage = notAvailable ? "N/A" : "-";
  const utilizationAmount =
    totalUtilization?.amount === null
      ? notAvailableMessage
      : money(totalUtilization?.amount);

  return (
    <Stack
      spacing={2}
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
    >
      <CommitmentsChartMetadataCard
        title="Utilization"
        loading={loading}
        value={utilizationAmount}
        secondaryValue={
          totalUtilization?.percent ? `(${totalUtilization?.percent}%)` : ""
        }
        tooltip={`Total RIs utilization ${datesFromTo}.`}
      />
      <CommitmentsChartMetadataCard
        loading={loading}
        title="Reserved"
        value={money(totalCommitment.cost)}
        secondaryValue={
          totalCommitment?.hours ? `(${totalCommitment?.hours}h)` : ""
        }
        tooltip={`Total RI commitments ${datesFromTo}.`}
      />
      <CommitmentsChartMetadataCard
        loading={loading}
        title="Covered"
        value={money(totalCoverage?.amount)}
        secondaryValue={
          totalCoverage?.percent ? `(${totalCoverage?.percent}%)` : ""
        }
        tooltip={`Total cost and percentage of resources covered by RIs from ${datesFromTo}.`}
      />
    </Stack>
  );
};
