import { RootState } from "../../../store";
import { resourceDetailsSliceSelector } from "../resourceDetailsSliceSelector";
import { ResourceDetailsInterface } from "../../utils/types/common";

export const resourceDetailsOptionsSelector = (
  state: RootState,
): ResourceDetailsInterface["options"] => {
  const slice = resourceDetailsSliceSelector(state);

  return slice.options;
};
