import * as yup from "yup";
import { GracePeriodNotificationsType } from "../../../workflows/single-workflow/form/actions-section/grace-period/notifications/GracePeriodNotifications";
import { integrationNotificationValidationSchema } from "../../../../common/notification-selection/utils/validation/validation";
import { emailsValidationSchema } from "../../../../../common/integration-dialogs/components/email/utils/validation";
import { NotificationEmail } from "../../../../../../services/cloudchipr.api";

export const getGracePeriodNotificationsValidationSchema = () => {
  return yup.lazy((data: GracePeriodNotificationsType[0]) => {
    if (data.integration_id) {
      return integrationNotificationValidationSchema;
    }

    return emailsValidationSchema(data as NotificationEmail);
  });
};
