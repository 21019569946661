import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllowedFiltersThunk } from "../filters/thunks/getAllowedFiltersThunk";
import { getResourceExplorerVisibilityHierarchyThunk } from "../resource-explorer/thunks/resource-explorer-hierarchy-visibility/getResourceExplorerVisibilityHierarchyThunk";
import { getDashboardsVisibilityHierarchyThunk } from "../dashboards/thunks/dashboard-hierarchy-visibility/getDashboardsVisibilityHierarchyThunk";
import { getResourceExplorerPossibleGroupingsThunk } from "../resource-explorer/thunks/common/getResourceExplorerPossibleGroupingsThunk";
import { getTaskAvailablePropertiesThunk } from "../task-management/thunks/properties/getTaskAvailablePropertiesThunk";

export const initializingApplicationThunk = createAsyncThunk(
  "applicationCommon/applicationCommonThunk",
  async (_, { dispatch }) => {
    await Promise.all([
      dispatch(getAllowedFiltersThunk()),
      dispatch(getTaskAvailablePropertiesThunk()),
      dispatch(getResourceExplorerVisibilityHierarchyThunk()),
      dispatch(getDashboardsVisibilityHierarchyThunk()),
      dispatch(getResourceExplorerPossibleGroupingsThunk()),
    ]);
  },
);
