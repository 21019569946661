import { getResourceExplorerPossibleGroupingsFromAccountServiceSelector } from "./getResourceExplorerPossibleGroupingsFromAccountServiceSelector";
import { RootState } from "../../../store";

export const resourceExplorerInternalPossibleGroupingsLoadingSelector = (
  state: RootState,
  organisationId: string,
) => {
  return getResourceExplorerPossibleGroupingsFromAccountServiceSelector(
    state,
    organisationId,
  )?.isLoading;
};
