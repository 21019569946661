import { ColDef } from "ag-grid-community";
import { Budget } from "../../../../../../../../../services/cloudchipr.api";

export const budgetsCreatedBySortingFn: ColDef<Budget>["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const current =
    nodeA?.data?.created_by?.name ?? nodeA?.data?.created_by?.email ?? "";
  const next =
    nodeB?.data?.created_by?.name ?? nodeB?.data?.created_by?.email ?? "";

  return current.localeCompare(next);
};
