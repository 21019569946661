import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetCostBreakdownVisualization } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { billingExplorerWidgetSetupSelector } from "../../../../selectors/setups/billing-explorer/billingExplorerWidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";

export const billingExplorerViewVisualizationChangeThunk = createAsyncThunk(
  "dashboards/billingExplorerWidgetViewVisualizationChange",
  async (
    visualization: WidgetCostBreakdownVisualization,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const existingSetup = billingExplorerWidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }

    const viewType =
      visualization.chart_type ?? existingSetup.visualization.chart_type;

    dispatch(
      setWidgetSetup({
        ...existingSetup,
        //TODO: EnableWidgetsSideBarConfigurations, remove viewType
        viewType,
        visualization: {
          ...existingSetup.visualization,
          ...visualization,
        },
        frequency: viewType === "pie" ? "monthly" : existingSetup.frequency,
      }),
    );
  },
);
