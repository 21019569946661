import moment from "moment/moment";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../utils/helpers/resources/getResourceTypeName";
import { getProviderTypeByResourceType } from "../../../../utils/helpers/providers/getProviderTypeByResourceType";
import { liveUsageMgmtSelectedAccountsSelector } from "../accounts-data/liveUsageMgmtSelectedAccountsSelector";
import { getProviderName } from "../../../../utils/helpers/providers/getProviderName";

export const liveUsageMgmtResourceTypeCsvDataFileNameSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const accounts = liveUsageMgmtSelectedAccountsSelector(state);
  const provider = getProviderTypeByResourceType(resourceType);

  const firstAccountName =
    accounts?.[0]?.provider_account_name ?? accounts?.[0]?.provider_account_id;
  const names =
    accounts?.length === 1
      ? firstAccountName
      : `${accounts?.length} ${getProviderName(provider, {
          plural: true,
        })}`;

  const date = moment().format("MM.DD.YY");

  const resourceName =
    getResourceTypeName(resourceType, {
      type: "long",
    })
      ?.toLowerCase()
      ?.replaceAll(" ", "-") ?? "";

  return `${provider}-${names}-${date}-${resourceName}`;
};
