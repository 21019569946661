import { FC, memo } from "react";
import { CSVLink } from "react-csv";
import { IconButton, Tooltip } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { MetricsFrequenciesType } from "../utils/types/metricsFrequenciesType";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { liveUsageMgmtGenerateExportedResourceData } from "../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtGenerateExportedResourceData";
import { liveUsageMgmtResourceTypeCsvDataFileNameSelector } from "../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeCsvDataFileNameSelector";
import { taskAvailableStatusPropertiesSelector } from "../../../../../../../../../../store/task-management/selectors/properties/taskAvailableStatusPropertiesSelector";

interface CsvDataDownloaderProps {
  resourceType: ResourceType;
  data?: Record<string, string | number>[] | null;
  metricsFrequencies?: MetricsFrequenciesType;
}

export const CsvDataDownloader: FC<CsvDataDownloaderProps> = memo(
  ({ resourceType, data, metricsFrequencies }) => {
    const taskStatuses = useAppSelector(taskAvailableStatusPropertiesSelector);

    let csvData = useAppSelector((state) =>
      liveUsageMgmtGenerateExportedResourceData(
        state,
        resourceType,
        [],
        metricsFrequencies,
        taskStatuses,
      ),
    ); // TODO: check do we need to load csv data keeping sorting

    if (data) {
      csvData = data;
    }

    const fileName = useAppSelector((state) =>
      liveUsageMgmtResourceTypeCsvDataFileNameSelector(state, resourceType),
    );

    if (!csvData) {
      return null;
    }

    return (
      <Tooltip arrow title="Export CSV" placement="top">
        <span>
          <IconButton
            disabled={!csvData?.length}
            size="small"
            sx={{ "& a": { textDecoration: "none", color: "inherit" }, pb: 0 }}
          >
            <CSVLink
              filename={`${fileName}.csv`}
              data={csvData}
              enclosingCharacter=""
              separator=";"
            >
              <DownloadOutlinedIcon fontSize="small" />
            </CSVLink>
          </IconButton>
        </span>
      </Tooltip>
    );
  },
);
