import { FC } from "react";

import { Divider, Stack } from "@mui/material";
import { KubernetesClusterCardFooterMetricInfo } from "./KubernetesClusterCardFooterMetricInfo";
import { convertByteToGB } from "../../../../../../../common/filters/utils/helpers/unitsConverting";

interface KubernetesClusterCardFooterProps {
  cpuUsage: number;
  cpuRequest: number;
  cpuTotal: number;
  memoryUsage: number;
  memoryRequest: number;
  memoryTotal: number;
}
export const KubernetesClusterCardFooter: FC<
  KubernetesClusterCardFooterProps
> = ({
  cpuTotal,
  cpuUsage,
  cpuRequest,
  memoryUsage,
  memoryRequest,
  memoryTotal,
}) => {
  return (
    <Stack direction="row" spacing={2} pb={2} px={2}>
      <KubernetesClusterCardFooterMetricInfo
        title="CPU"
        total={cpuTotal}
        usage={cpuUsage}
        requested={cpuRequest}
        unit="core"
      />
      <Divider orientation="vertical" flexItem />
      <KubernetesClusterCardFooterMetricInfo
        title="Memory"
        total={memoryTotal > 0 ? +convertByteToGB(memoryTotal).toFixed(1) : 0}
        usage={memoryUsage > 0 ? +convertByteToGB(memoryUsage).toFixed(1) : 0}
        requested={
          memoryRequest > 0 ? +convertByteToGB(memoryRequest).toFixed(1) : 0
        }
        unit="gib"
      />
    </Stack>
  );
};
