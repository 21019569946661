import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateBillingExplorerSummaryMetaDataAfterDataGettingThunk } from "./updateBillingExplorerSummaryMetaDataAfterDataGettingThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { resourceExplorerPayloadDataSelector } from "../../selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerPayloadDataSelector";
import { addFilterTreePathToResourceExplorerPayload } from "../../utils/helpers/addFilterTreePathToResourceExplorerPayload";
import { RootState } from "../../../store";
import { getBillingExplorerSummaryFixedCashKeySelector } from "../../selectors/resource-explorer-summary/getBillingExplorerSummaryFixedCashKeySelector";
import { isBillingExplorerDatesOutRangesForSummarySelector } from "../../selectors/resource-explorer-summary/isBillingExplorerDatesOutRangesForSummarySelector";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { billingExplorerSummaryDataByCurrentPayloadSelector } from "../../selectors/resource-explorer-summary/billingExplorerSummaryDataByCurrentPayloadSelector";

export const getBillingExplorerSummaryThunk = createAsyncThunk(
  "resourceExplorer/getBillingExplorerSummary",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const resourceExplorerDataGettingPayload =
      resourceExplorerPayloadDataSelector(state);
    const isOutRangesDates =
      isBillingExplorerDatesOutRangesForSummarySelector(state);
    const { postUsersMeOrganisationsCurrentResourceExplorerSummary } =
      cloudChiprApi.endpoints;

    const cashedData =
      billingExplorerSummaryDataByCurrentPayloadSelector(state);

    if (
      !resourceExplorerDataGettingPayload.group_by ||
      isOutRangesDates ||
      cashedData
    ) {
      return;
    }

    const payload = addFilterTreePathToResourceExplorerPayload(
      resourceExplorerDataGettingPayload,
    );
    const fixedCacheKey = getBillingExplorerSummaryFixedCashKeySelector(state);

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentResourceExplorerSummary.initiate(
          { resourceExplorerFilterSchema: payload },
          {
            fixedCacheKey,
          },
        ),
      ).unwrap();
      if (response && fixedCacheKey) {
        dispatch(
          updateBillingExplorerSummaryMetaDataAfterDataGettingThunk(
            fixedCacheKey,
          ),
        );
      }
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
