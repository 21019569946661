import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { widgetDataNotFoundSelector } from "../../../selectors/widgets/common/widgetDataNotFoundSelector";
import { coverageWidgetSetupDataPayloadSelector } from "../../../selectors/widgets/coverage/setup/coverageWidgetSetupDataPayloadSelector";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getCoverageWidgetSetupDataThunk = createAsyncThunk(
  "dashboard/getCoverageWidgetSetupData",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentWidgetsCoverage } =
      cloudChiprApi.endpoints;

    const widgetNotFound = widgetDataNotFoundSelector(state, "", "coverage");

    const payload = coverageWidgetSetupDataPayloadSelector(state);

    if (!payload) {
      return;
    }

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentWidgetsCoverage.initiate(payload, {
          forceRefetch: true,
        }),
      );
    } catch (e) {
      if (!widgetNotFound) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    }
  },
);
