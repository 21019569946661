import { FC } from "react";
import { PlotColumnDataLabelsOptions } from "highcharts";
import { Stack } from "@mui/material";
import { HighchartsLineChart } from "./line-chart/HighchartsLineChart";
import { HighchartsStackedColumnChart } from "./stacked-column-chart/HighchartsStackedColumnChart";
import { HighchartsColumnChart } from "./column-chart/HighchartsColumnChart";
import { HighchartsChartType } from "./common/components/HighchartsChartTypeSelect";
import {
  highchartsLoadingChartData,
  HighchartsEmptyChartMarkup,
  HighchartsLoadingType,
} from "./common/components/HighchartsEmptyChartMarkup";
import {
  HighchartsChartDataType,
  HighChartsCommonProps,
} from "./common/utils/types/common";
import { HighChartLegends } from "./common/components/legends/HighChartLegends";
import { HighchartsAreaChart } from "./area-chart/HighchartsAreaChart";

type CommonPropsOmitted = Omit<HighChartsCommonProps, "loading" | "chartData">;

interface MultiTypeHighchartsProps extends CommonPropsOmitted {
  showLegends?: boolean;
  noDataMessage?: string;
  chartType: HighchartsChartType;
  loading?: HighchartsLoadingType;
  chartData?: HighchartsChartDataType | null;
  columnDataLabels?: PlotColumnDataLabelsOptions;
  stackedChartHeadingLabelFormatter?: (total: number) => string;
}

export const MultiTypeHighcharts: FC<MultiTypeHighchartsProps> = ({
  chartType,
  loading,
  chartData,
  stackedChartHeadingLabelFormatter,
  columnDataLabels,
  noDataMessage,
  showLegends,

  ...props
}) => {
  if (loading) {
    return <HighchartsEmptyChartMarkup loading={loading} />;
  }

  const empty =
    chartData && (!chartData.categories.length || !chartData?.series.length);

  if (empty) {
    return <HighchartsEmptyChartMarkup noDataMessage={noDataMessage} />;
  }

  return (
    <Stack
      mt={1}
      height="100%"
      sx={{
        "& .highcharts-xaxis .highcharts-axis-line, .highcharts-yaxis .highcharts-axis-line":
          { strokeDasharray: " 4,4 !important" },
      }}
    >
      {chartType === "stackedColumn" && (
        <HighchartsStackedColumnChart
          loading={!!loading}
          columnDataLabels={columnDataLabels}
          chartData={chartData ?? highchartsLoadingChartData}
          stackedChartHeadingLabelFormatter={stackedChartHeadingLabelFormatter}
          {...props}
        />
      )}

      {chartType === "column" && (
        <HighchartsColumnChart
          loading={!!loading}
          columnDataLabels={columnDataLabels}
          chartData={chartData ?? highchartsLoadingChartData}
          {...props}
        />
      )}

      {chartType === "line" && (
        <HighchartsLineChart
          loading={!!loading}
          chartData={chartData ?? highchartsLoadingChartData}
          {...props}
        />
      )}

      {chartType === "area" && (
        <HighchartsAreaChart
          loading={!!loading}
          chartData={chartData ?? highchartsLoadingChartData}
          {...props}
        />
      )}

      {showLegends && <HighChartLegends />}
    </Stack>
  );
};
