import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { rightsizingRecommendationsStatusesGroupedSelector } from "./rightsizingRecommendationsStatusesGroupedSelector";
import { CloudProviderType } from "../../../../../components/common/types/types";

export const rightsizingRecommendationsWarningStatusesCountSelector =
  createDraftSafeSelector(
    [rightsizingRecommendationsStatusesGroupedSelector],
    (data) => {
      let count = 0;

      Object.keys(data).forEach((provider) => {
        count +=
          (data[provider as CloudProviderType]?.disabled?.length ?? 0) +
          (data[provider as CloudProviderType]?.missing_permission?.length ??
            0);
      });

      return count;
    },
  );
