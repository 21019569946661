import { RootState } from "../../../../store";
import { TextWidgetSetupType } from "../../../utils/types/setups/textWidgetSetupType";
import { widgetSetupSelector } from "../widgetSetupSelector";

export const textWidgetSetupSelector = (
  state: RootState,
): TextWidgetSetupType | undefined => {
  if (!widgetSetupSelector(state)) {
    return;
  }
  return widgetSetupSelector(state) as TextWidgetSetupType;
};
