import { KubernetesCluster } from "../../../../../services/cloudchipr.api";
import { chartDataPointKey } from "../../../../../storybook/charts/multi-type-chart/utils/constants/constants";
import { ChartDataType } from "../../../../../storybook/charts/multi-type-chart/utils/types/types";

export const kubernetesClustersChartDataGenerator = (
  clusters: KubernetesCluster[] | null,
) => {
  let chartData = clusters?.map((cluster) => {
    const name = cluster.name ?? cluster.cluster_id ?? cluster.id;

    return {
      [chartDataPointKey]: name,
      Idle: cluster.idle_monthly_cost || 0,
      Reserved: cluster.reserved_monthly_cost || 0,
    };
  }) as unknown as ChartDataType[];

  if (chartData?.length && chartData.length > 15) {
    const firstPart = chartData.splice(0, 14);
    const secondPart = chartData.splice(14);

    const others = secondPart.reduce(
      (res, data) => {
        return {
          [chartDataPointKey]: "Others",
          Idle: (res["Idle"] ?? 0) + (data["Idle"] ?? 0),
          Reserved: (res["Reserved"] ?? 0) + (data["Reserved"] ?? 0),
        } as unknown as ChartDataType;
      },
      {} as unknown as ChartDataType,
    );

    chartData = [...firstPart, others] as unknown as ChartDataType[];
  }

  return (chartData ?? []) as unknown as ChartDataType[];
};
