import { ListItemText, Stack } from "@mui/material";
import { GroupingOptionIcon } from "../../GroupingOptionIcon";
import {
  ResourceExplorerGrouping,
  ResourceExplorerPossibleGrouping,
} from "../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { ResourceExplorerGroupedPossibleGroupings } from "../../../utils/types/types";

export const getResourceExplorerGroupingOptionWithParentLabel = (
  groupedPossibleGroupings: ResourceExplorerGroupedPossibleGroupings,
  parentLabel: string,
) => {
  const children = groupedPossibleGroupings?.[parentLabel]?.map(
    (groupingData) => groupingData?.items.at(0),
  ) as ResourceExplorerPossibleGrouping[];

  const firstChildKey = children.at(0)?.key as ResourceExplorerGrouping;

  return {
    key: parentLabel,
    value: parentLabel,
    disableSelection: true,
    label: (
      <Stack direction="row" spacing={1} alignItems="center">
        {firstChildKey && <GroupingOptionIcon grouping={firstChildKey} />}
        <ListItemText primary={parentLabel} />
      </Stack>
    ),
    rawValue: { search: parentLabel },
    nestedOptions: children.map((option) => ({
      value: option?.key ?? "",
      rawValue: { search: `${option.label?.singular} ${parentLabel}` },
      label: (
        <Stack direction="row" spacing={1} alignItems="center" pl={2}>
          {option?.key && <GroupingOptionIcon grouping={option.key} />}
          <TypographyWithTooltip
            variant="body2"
            title={option.label?.singular ?? ""}
          />
        </Stack>
      ),
    })),
  };
};
