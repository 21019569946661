import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { deleteTaskViewFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.deleteUsersMeOrganisationsCurrentTaskViewsByViewId.select(
    deleteTaskViewFixedCacheKey,
  );

export const deleteTasksViewLoadingSelector = (state: RootState) => {
  return endpoint(state)?.isLoading;
};
