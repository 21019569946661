import { Stack } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { GridApi } from "ag-grid-community";
import { CommitmentsRecommendationsFilters } from "../../../../../utilization-and-coverage/components/content/recommendations/filters/CommitmentsRecommendationsFilters";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { CommitmentFilterType } from "../../../../../../../../services/cloudchipr.api";

interface ReservationCoverageDrawerRecommendationsGridToolbar {
  gridApi: GridApi | null;
  targetType: CommitmentFilterType | undefined;
}

export const ReservationCoverageDrawerRecommendationsGridToolbar: FC<
  ReservationCoverageDrawerRecommendationsGridToolbar
> = ({ gridApi, targetType }) => {
  const [searchValue, setSearchValue] = useState("");

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridApi?.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [gridApi],
  );

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={2}
      justifyContent="space-between"
      py={1}
    >
      <CommitmentsRecommendationsFilters
        type="reservation"
        hideFilters={targetType === "resource_type" ? hiddenFilters : undefined}
      />
      <GlobalFilter
        globalFilter={searchValue}
        setGlobalFilter={searchChangeHandler}
        size="xsmall"
      />
    </Stack>
  );
};

const hiddenFilters = new Set(["type"]);
