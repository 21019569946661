import { FC, useEffect } from "react";
import { Content } from "@tiptap/core";
import { useCurrentEditor } from "@tiptap/react";

interface ContentUpdaterProps {
  content: Content;
}

export const ContentUpdater: FC<ContentUpdaterProps> = ({ content }) => {
  const { editor } = useCurrentEditor();

  useEffect(() => {
    const currentHTML = editor?.getHTML();

    if (currentHTML === content) {
      return;
    }

    editor?.commands?.setContent(content);
  }, [editor, content]);

  return null;
};
