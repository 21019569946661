import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { coverageWidgetSetupGroupingSelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/coverage/coverageWidgetSetupGroupingSelector";
import { CoverageGrouping } from "../../../../../../../../../../../services/cloudchipr.api";
import { coverageWidgetSetupDataChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/coverage-widget/setup-change/coverageWidgetSetupDataChangeThunk";
import { coverageGroupByTitleByGrouping } from "../../../utils/constants/constants";

export const CoverageWidgetSetupGroupBySelect: FC = () => {
  const dispatch = useAppDispatch();

  const grouping = useAppSelector(coverageWidgetSetupGroupingSelector);

  const handleChange = useCallback(
    (event: SelectChangeEvent<CoverageGrouping>) => {
      dispatch(
        coverageWidgetSetupDataChangeThunk({
          grouping: event.target.value as CoverageGrouping,
        }),
      );
    },
    [dispatch],
  );

  return (
    <FormControl fullWidth size="small">
      <InputLabel>Group By</InputLabel>

      <Select value={grouping} onChange={handleChange} label="Group By">
        <MenuItem value="resource_type">
          {coverageGroupByTitleByGrouping.get("resource_type")}
        </MenuItem>
        <MenuItem value="account">
          {coverageGroupByTitleByGrouping.get("account")}
        </MenuItem>
        <MenuItem value="region">
          {coverageGroupByTitleByGrouping.get("region")}
        </MenuItem>
        <MenuItem value="ungrouped">
          {coverageGroupByTitleByGrouping.get("ungrouped")}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
