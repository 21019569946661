import { FC } from "react";
import { Stack } from "@mui/material";
import { CostAnomaliesWidgetSetupResourceItem } from "./CostAnomaliesWidgetSetupResourceItem";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { WidgetNumeralView } from "../../../common/view-type/WidgetNumeralView";
import { costAnomaliesSetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/costAnomaliesSetupPropertyByKeySelector";
import { WidgetContentInvalidState } from "../../../common/WidgetContentInvalidState";
import {
  Dates,
  ResourceExplorerCostsDateDetails,
  ResourceExplorerGridTotal,
  ResourceExplorerGroupingNullable,
} from "../../../../../../../../../../services/cloudchipr.api";

interface CostAnomaliesWidgetBodyProps {
  date?: Dates;
  viewId?: string;
  loading: boolean;
  groupValues?: string[];
  data: ResourceExplorerGridTotal[];
  grouping?: ResourceExplorerGroupingNullable;
  previousPeriodDate?: ResourceExplorerCostsDateDetails;
}

export const CostAnomaliesWidgetBody: FC<CostAnomaliesWidgetBodyProps> = ({
  date,
  data,
  viewId,
  loading,
  grouping,
  groupValues,
  previousPeriodDate,
}) => {
  const itemsCount = useAppSelector(
    costAnomaliesSetupPropertyByKeySelector("itemsCount"),
  );

  if (!data.length && !loading) {
    return (
      <WidgetContentInvalidState message="No items match the conditions. Adjust conditions to view results." />
    );
  }

  return (
    <Stack sx={{ overflow: "auto" }} flex={1}>
      <WidgetNumeralView loading={loading} skeletonCount={itemsCount}>
        {data.map((item) => {
          return (
            <CostAnomaliesWidgetSetupResourceItem
              difference={
                item.period_total_cost_difference !== null
                  ? Math.abs(item.period_total_cost_difference)
                  : null
              }
              date={date}
              viewId={viewId}
              trend={item.trend}
              grouping={grouping}
              itemsCount={data.length}
              groupValues={groupValues}
              totalCost={item.total_cost}
              provider={item.cloud_provider}
              label={item.label ?? item.field}
              key={item.field + item.cloud_provider}
              previousPeriodDate={previousPeriodDate}
              previousPeriodTotalCost={item.previous_period_total_cost}
            />
          );
        })}
      </WidgetNumeralView>
    </Stack>
  );
};
