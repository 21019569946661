import { FC, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import TabContext from "@mui/lab/TabContext";
import { TabPanel } from "@mui/lab";
import { Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { UtilizationAndCoveragePageHeader } from "./utilization-and-coverage/components/UtilizationAndCoveragePageHeader";
import { Reservations } from "./reservations/Reservations";
import { CommitmentsOverview } from "./overview/CommitmentsOverview";
import { AzureCommitments } from "./azure/AzureCommitments";
import { UtilizationAndCoverage } from "./utilization-and-coverage/UtilizationAndCoverage";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { awsBillingStatusNotAvailableSelector } from "../../../store/commitments/selectors/organisations/awsBillingStatusNotAvailableSelector";
import { setCommitmentsTypeTab } from "../../../store/commitments/commitmentsSlice";
import { ProviderBillingDataAccessController } from "../common/billing/billing-data-access-controller/ProviderBillingDataAccessController";
import { commitmentsTypeTabSelector } from "../../../store/commitments/selectors/common/commitmentsTypeTabSelector";
import { CommitmentType, ProviderType } from "../../../services/cloudchipr.api";
import { initiateCommitmentsThunk } from "../../../store/commitments/thunks/common/initiateCommitmentsThunk";

export const Commitments: FC = () => {
  const params = useParams();
  const orgId = params?.orgId ?? "";
  const provider = params?.provider as ProviderType;
  const [searchParams] = useSearchParams();
  const tabFromRouter = searchParams.get("active") as CommitmentType;

  const enableCommitmentsOverview = useFlag("EnableCommitmentsOverview");
  const enableAzureCommitments = useFlag("EnableAzureCommitments");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const billingStatusNotAvailable = useAppSelector(
    awsBillingStatusNotAvailableSelector,
  );
  const activeTab = useAppSelector(commitmentsTypeTabSelector);

  useEffect(() => {
    dispatch(initiateCommitmentsThunk({ orgId, provider }));
    if (!provider) {
      // previous not valid routes are only for aws
      navigate(`/commitments/aws/${orgId}`, {
        replace: true,
      });
    }
  }, [dispatch, orgId, provider, navigate]);

  useEffectOnceWhen(() => {
    const tab = enableCommitmentsOverview ? "overview" : "savings_plan";
    const tabValue = tab ?? "overview";
    navigate({ search: `active=${tabValue}` });

    dispatch(setCommitmentsTypeTab(tabValue));
  }, !tabFromRouter);

  useEffectOnceWhen(() => {
    if (tabFromRouter) {
      dispatch(setCommitmentsTypeTab(tabFromRouter));
    }
  }, !!tabFromRouter);

  if (billingStatusNotAvailable) {
    return <ProviderBillingDataAccessController provider="aws" />;
  }

  if (!activeTab) {
    return null;
  }

  return (
    <TabContext value={activeTab}>
      <UtilizationAndCoveragePageHeader />

      {provider === "azure" && enableAzureCommitments && <AzureCommitments />}
      {!provider ||
        (provider === "aws" && (
          <Stack p={1} flex={1}>
            {enableCommitmentsOverview && (
              <TabPanel value="overview" sx={{ p: 0, flex: 1 }}>
                <Stack height="100%">
                  <CommitmentsOverview />
                </Stack>
              </TabPanel>
            )}
            <TabPanel value="savings_plan" sx={{ p: 0, flex: 1 }}>
              <Stack height="100%">
                <UtilizationAndCoverage />
              </Stack>
            </TabPanel>

            <TabPanel value="reservation" sx={{ p: 0, flex: 1 }}>
              <Stack height="100%">
                <Reservations />
              </Stack>
            </TabPanel>
          </Stack>
        ))}
    </TabContext>
  );
};
