import { FC } from "react";
import { CreateIntegrationButton } from "../../../common/components/CreateIntegrationButton";
import { integrationDetails } from "../../common/utils/constants/integrationDetails";

interface SlackIntegrationConnectButtonProps {
  withStartIcon?: boolean;
  variant?: "outlined" | "contained";
}

export const SlackIntegrationConnectButton: FC<
  SlackIntegrationConnectButtonProps
> = ({ withStartIcon, variant }) => {
  const { label } = integrationDetails["slack"];

  return (
    <CreateIntegrationButton
      type="slack"
      withStartIcon={withStartIcon}
      integrationLabel={label}
      variant={variant}
    >
      Connect
    </CreateIntegrationButton>
  );
};
