import { getUsersMeOrganisationsCurrentTaskViewsHierarchySelector } from "./getUsersMeOrganisationsCurrentTaskViewsHierarchySelector";
import { RootState } from "../../../store";
import { View } from "../../../../services/cloudchipr.api";

export const tasksViewsHierarchySelector = (
  state: RootState,
): View[] | undefined => {
  return getUsersMeOrganisationsCurrentTaskViewsHierarchySelector(state)?.data
    ?.hierarchy;
};
