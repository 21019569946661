import { azureReservationsGranularDataTotalUtilizationSelector } from "./granular/totals/azureReservationsGranularDataTotalUtilizationSelector";
import { azureReservationsDataSelector } from "./azureReservationsDataSelector";
import { RootState } from "../../../../../store";

export const azureReservationsDataIsNotAvailableSelector = (
  state: RootState,
) => {
  const data = azureReservationsDataSelector(state);
  const totalUtilization =
    azureReservationsGranularDataTotalUtilizationSelector(state);

  return !!(data?.length && !totalUtilization?.amount);
};
