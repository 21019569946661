import { FC, useCallback } from "react";
import Chip from "@mui/material/Chip";
import { Tooltip } from "@mui/material";
import { Clear } from "@mui/icons-material";

interface AgGridFilterChipProps {
  filterName: string;
  columnHeaderNameByColId: Record<string, string>;
  onClearFilterByName(filterName: string): void;
  onChipClick(filterName: string): void;
}

export const AgGridFilterChip: FC<AgGridFilterChipProps> = ({
  filterName,
  onChipClick,
  onClearFilterByName,
  columnHeaderNameByColId,
}) => {
  const onDelete = useCallback(() => {
    onClearFilterByName(filterName);
  }, [onClearFilterByName, filterName]);

  const onClick = useCallback(() => {
    onChipClick(filterName);
  }, [filterName, onChipClick]);

  return (
    <Chip
      key={filterName}
      label={columnHeaderNameByColId[filterName] ?? filterName}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={deleteIcon}
      size="small"
      variant="filled"
      sx={{ pl: 0.5 }}
    />
  );
};

const deleteIcon = (
  <Tooltip arrow title="Clear filter" placement="right">
    <Clear
      sx={{
        width: 13,
        height: 13,
      }}
    />
  </Tooltip>
);
