import { FC, useState, useCallback, ReactNode, Fragment } from "react";
import {
  CircularProgress,
  Divider,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { IntegrationsDialog } from "./components/integrations/IntegrationsDialog";
import { IntegrationItem } from "./components/integrations/IntegrationItem";
import { AddNotificationButton } from "./components/AddNotificationButton";
import { generateIntegrationMetadata } from "./utils/helpers/helpers";
import { IntegrationNotification } from "./utils/types/types";
import { NotificationsEmailSelectionWrapper } from "./components/emails/NotificationsEmailSelectionWrapper";
import { NotificationCustomMessageConfig } from "./components/custom-message/NotificationCustomMessage";
import { IntegrationsGridViewDrawer } from "./components/integrations/IntegrationsGridViewDrawer";
import { IntegrationType } from "../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import { EmailsSelectionErrors } from "../../../common/integration-dialogs/components/email/components/email-selection/EmailsSelection";
import { getIntegrationByIdThunk } from "../../../../store/integrations/thunks/getIntegrationByIdThunk";
import { useAppDispatch } from "../../../../store/hooks";
import {
  Emails,
  Integration,
  useGetUsersMeIntegrationsQuery,
} from "../../../../services/cloudchipr.api";
import { IntegrationErrors } from "../../automations/workflows/single-workflow/form/actions-section/grace-period/notifications/GracePeriodNotifications";
import { CommunicationIntegrationDrawer } from "../../integrations/components/communications/common/components/drawer/CommunicationIntegrationDrawer";

interface NotificationsSelectionProps {
  emailsErrors?: EmailsSelectionErrors;
  selectedEmails: Emails | null;
  emailsChangeHandler(emails: Emails | null): void;
  setSelectedIntegrations(integrations: IntegrationNotification[] | null): void;
  children?: ReactNode;
  integrationErrors?: IntegrationErrors[];
  selectedIntegrations?: IntegrationNotification[] | null;
  disabledIntegrationTypes?: IntegrationType[];
  titleTypographyProps?: TypographyProps;
  customMessageConfigs?: NotificationCustomMessageConfig;
}

export const NotificationsSelection: FC<NotificationsSelectionProps> = ({
  selectedIntegrations,
  setSelectedIntegrations,
  emailsChangeHandler,
  selectedEmails,
  emailsErrors,
  integrationErrors,
  disabledIntegrationTypes,
  children,
  titleTypographyProps,
  customMessageConfigs,
}) => {
  const enableRedesignedIntegrations = useFlag("EnableRedesignedIntegrations");

  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetUsersMeIntegrationsQuery({});

  const [open, setOpen] = useState(false);

  const closeHandler = useCallback(() => setOpen(false), []);
  const openHandler = useCallback(() => setOpen(true), []);

  const newIntegrationAddHandler = useCallback(
    async (type: IntegrationType, id: string, addToFirst?: boolean) => {
      const newIntegration = {
        integration_id: id,
        type,
      } as IntegrationNotification;
      const restIntegrations = selectedIntegrations || [];

      setSelectedIntegrations(
        addToFirst
          ? [newIntegration, ...restIntegrations]
          : [...restIntegrations, newIntegration],
      );

      if (!["slack", "email"].includes(type)) {
        return;
      }

      const response = await dispatch(
        getIntegrationByIdThunk({ id, type }),
      ).unwrap();
      const data = response?.data as Integration;

      if (!data) {
        return;
      }

      const selected = generateIntegrationMetadata(type, data);

      setSelectedIntegrations(
        addToFirst
          ? [selected, ...(selectedIntegrations || [])]
          : [...(selectedIntegrations || []), selected],
      );

      closeHandler();
    },
    [dispatch, selectedIntegrations, setSelectedIntegrations, closeHandler],
  );

  const createEmailsHandler = useCallback(() => {
    emailsChangeHandler({ to: [] });
  }, [emailsChangeHandler]);

  if (!data && isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress disableShrink />
      </Stack>
    );
  }

  return (
    <div>
      <Typography variant="body2" fontWeight="medium" {...titleTypographyProps}>
        Select a notification method
      </Typography>

      <NotificationsEmailSelectionWrapper
        emailsChangeHandler={emailsChangeHandler}
        selectedEmails={selectedEmails}
        emailsErrors={emailsErrors}
        selectedIntegrations={selectedIntegrations}
        disabledIntegrationTypes={disabledIntegrationTypes}
        customMessageConfigs={customMessageConfigs}
        onAddNewIntegration={newIntegrationAddHandler}
        onCreateDialogOpen={openHandler}
      />

      <Stack
        gap={1.5}
        mt={selectedIntegrations?.length ? 2 : 0}
        spacing={1}
        divider={<Divider>AND</Divider>}
      >
        {selectedIntegrations?.map((integration, i) => (
          <IntegrationItem
            integration={integration}
            error={integrationErrors?.at(i)}
            key={integration.integration_id}
            onCreateDialogOpen={openHandler}
            selectedIntegrations={selectedIntegrations}
            setSelectedIntegrations={setSelectedIntegrations}
            disabledIntegrationTypes={disabledIntegrationTypes}
            customMessageConfigs={customMessageConfigs}
            disableEmailsSelection={!!selectedEmails}
            onEmailsCreate={createEmailsHandler}
          />
        ))}
      </Stack>

      {children}

      <AddNotificationButton
        onCreateDialogOpen={openHandler}
        disableEmailsSelection={!!selectedEmails}
        onEmailsCreate={createEmailsHandler}
        selectedIntegrations={selectedIntegrations}
        onAddNewIntegration={newIntegrationAddHandler}
        disabledIntegrationTypes={disabledIntegrationTypes}
      />

      {enableRedesignedIntegrations ? (
        <Fragment>
          <IntegrationsGridViewDrawer
            open={open}
            onClose={closeHandler}
            disabledIntegrationTypes={disabledIntegrationTypes}
          />
          <CommunicationIntegrationDrawer
            onNewIntegrationCreate={open ? newIntegrationAddHandler : undefined}
          />
        </Fragment>
      ) : (
        <IntegrationsDialog
          open={open}
          onClose={closeHandler}
          onNewIntegrationCreate={newIntegrationAddHandler}
          disabledIntegrationTypes={disabledIntegrationTypes}
        />
      )}
    </div>
  );
};
