import { FC } from "react";
import { Typography } from "@mui/material";
import { MoneyCell } from "./MoneyCell";
import { TooltipText } from "../../../../../../../../../common/TooltipText";
import { Billable } from "../../../../../../../../../../services/cloudchipr.api";

interface DbPricePerMonthCellProps {
  data: number | null;
  billable?: Billable;
}

export const DbPricePerMonthCell: FC<DbPricePerMonthCellProps> = ({
  data,
  billable,
}) => {
  if (billable === "no") {
    return <Typography variant="body2">$0.00 (Not Billable)</Typography>;
  }

  if (billable === "analyzing") {
    return <TooltipText tooltipTitle="Analyzing">N/A</TooltipText>;
  }

  return <MoneyCell data={data} />;
};
