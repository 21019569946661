import { azureSavingsPlansDataForTableSelector } from "./azureSavingsPlansDataForTableSelector";
import { AzureSavingsPlan } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";

export const azureSavingsPlansDataSelector = (
  state: RootState,
): AzureSavingsPlan[] | undefined => {
  const data = azureSavingsPlansDataForTableSelector(state);

  return data?.utilization_and_coverage.commitments as AzureSavingsPlan[];
};
