import { FC, useMemo } from "react";
import { azureReservationCoverageTableColumnDefs } from "./columns/azureReservationCoverageTableColumnDefs";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { azureReservationsForTableLoadingSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/data/loading/azureReservationsForTableLoadingSelector";
import { commitmentsCoverageGroupingSelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsCoverageGroupingSelector";
import { azureReservationsCoverageDataSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/data/azureReservationsCoverageDataSelector";
import {
  CommitmentsAgGrid,
  CommitmentsToolbar,
} from "../../../../../common/components/CommitmentsAgGrid";
import { CommitmentsAGGridToolbar } from "../../../../../common/components/data-grid/CommitmentsAGGridToolbar";
import { AzureUtilizationAndCoverageDataViewTabs } from "../../../../utilization-and-coverage/components/content/data-grid/components/AzureUtilizationAndCoverageDataViewTabs";

export const AzureReservationsCoverageDataGrid: FC = () => {
  const data = useAppSelector(azureReservationsCoverageDataSelector);
  const loading = useAppSelector(azureReservationsForTableLoadingSelector);
  const grouping = useAppSelector(commitmentsCoverageGroupingSelector);

  const columnDefs = useMemo(
    () => azureReservationCoverageTableColumnDefs(grouping),
    [grouping],
  );

  return (
    <CommitmentsAgGrid
      Toolbar={toolbar}
      loading={loading}
      columnDefs={columnDefs}
      data={data}
      grouping={grouping}
      localStorageUniqueKey="azureReservationCoverageDataGrid"
    />
  );
};

const toolbar: CommitmentsToolbar = ({ gridApi }) => (
  <CommitmentsAGGridToolbar gridApi={gridApi}>
    <AzureUtilizationAndCoverageDataViewTabs />
  </CommitmentsAGGridToolbar>
);
