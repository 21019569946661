import { FC, useCallback } from "react";
import { WidgetOptionCard } from "../../WidgetOptionCard";
import { dashboardWidgetLabels } from "../../../../utils/constants/constants";
import Cover from "../../../../utils/assets/images/widget-covers/text_widget_cover.svg";
import { useAppDispatch } from "../../../../../../../store/hooks";

import { initiateTextWidgetThunk } from "../../../../../../../store/dashboards/thunks/widgets/text-widget/initiateTextWidgetThunk";

export const TextWidgetCard: FC = () => {
  const dispatch = useAppDispatch();

  const clickHandler = useCallback(async () => {
    dispatch(initiateTextWidgetThunk());
  }, [dispatch]);

  return (
    <WidgetOptionCard
      onClick={clickHandler}
      title={dashboardWidgetLabels.text ?? ""}
      description="Add custom text, notes, hyperlinks, or descriptions to your dashboard for better communication."
    >
      <img src={Cover} alt="ConstAndUsageCard" />
    </WidgetOptionCard>
  );
};
