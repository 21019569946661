import { FC, useCallback, ChangeEvent } from "react";
import { FormikHandlers } from "formik";
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAppDispatch } from "../../../../../../../../../../store/hooks";
import { setCurrentBudgetData } from "../../../../../../../../../../store/budgets/budgetsSlice";
import { BudgetPeriod } from "../../../../../../../../../../services/cloudchipr.api";

interface ReceiveUpdatesCheckboxProps {
  receiveUpdates: boolean;
  period: BudgetPeriod;
  handleChange: FormikHandlers["handleChange"];
}

export const ReceiveUpdatesCheckbox: FC<ReceiveUpdatesCheckboxProps> = ({
  receiveUpdates,
  period,
  handleChange,
}) => {
  const dispatch = useAppDispatch();

  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleChange(event);
      dispatch(setCurrentBudgetData({ receive_updates: event.target.checked }));
    },
    [dispatch, handleChange],
  );

  if (period === "daily") {
    return null;
  }

  return (
    <Stack pl={1} direction="row" gap={0.5} alignItems="center">
      <FormControlLabel
        sx={{ mr: 0, flexShrink: 0 }}
        control={
          <Checkbox
            name="receive_updates"
            checked={receiveUpdates}
            onChange={changeHandler}
          />
        }
        label={
          <Typography>
            Send {receiveBudgetUpdatesPeriods[period]} progress updates
          </Typography>
        }
      />
      <Tooltip
        title="When checked, all recipients will receive regular updates about the budget according to the budget frequency."
        placement="top"
        arrow
      >
        <InfoOutlinedIcon color="action" sx={{ fontSize: 16 }} />
      </Tooltip>
    </Stack>
  );
};

const receiveBudgetUpdatesPeriods: Record<
  Exclude<BudgetPeriod, "daily">,
  string
> = {
  monthly: "weekly",
  quarterly: "monthly",
  annually: "quarterly",
};
