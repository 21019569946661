import { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { AskAiSuggestionItem } from "./AskAiSuggestionItem";
import { askAiStaticSuggestions } from "./utils/constants";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { sendAskAiQuestionThunk } from "../../../../../../store/ask-ai/thunks/sendAskAiQuestionThunk";
import { askAiLoadingSelector } from "../../../../../../store/ask-ai/selectors/askAiLoadingSelector";

export const AskAiSuggestions: FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(askAiLoadingSelector);

  const suggestionClickHandler = useCallback(
    (question: string) => {
      dispatch(sendAskAiQuestionThunk(question));
    },
    [dispatch],
  );

  if (loading) {
    return null;
  }
  return (
    <Stack px={1.5} spacing={1}>
      <Typography variant="subtitle1">
        Feel free to ask anything about your cloud resources.
      </Typography>
      <Typography variant="caption" color="text.disabled">
        Suggestions
      </Typography>

      <Stack spacing={1}>
        {askAiStaticSuggestions.map((item) => {
          return (
            <AskAiSuggestionItem
              key={item}
              title={item}
              onClick={suggestionClickHandler}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
