import { FC, ReactNode, useCallback, useState } from "react";
import { CommentEditorToolbar } from "./CommentEditorToolbar";
import { Editor } from "../../../../../../../../../storybook/editor/Editor";

interface CommentEditorProps {
  taskId: string;
  comment?: string;
  commentId?: string;
  slotBefore?: ReactNode;
  onSend?(): void;
  onCancel?(): void;
  onChange?(text: string): void;
  focused?: boolean;
}

export const CommentEditor: FC<CommentEditorProps> = ({
  taskId,
  commentId,
  comment,
  slotBefore,
  onSend,
  onCancel,
  onChange,
  focused,
}) => {
  const [newComment, setNewComment] = useState(comment ?? "");

  const sendHandler = useCallback(() => {
    onSend?.();
    setNewComment("");
  }, [onSend]);

  const changeHandler = useCallback(
    (text: string) => {
      onChange?.(text);
      setNewComment(text);
    },
    [onChange],
  );

  return (
    <Editor
      autofocus={focused}
      content={newComment}
      minHeight={45}
      maxHeight={100}
      placeholder="Write a comment..."
      commands={["bold", "italic", "underline", "strike", "link"]}
      onChange={changeHandler}
      slotBefore={slotBefore ?? null}
      disableFocusBorder={false}
      slotAfter={
        <CommentEditorToolbar
          taskId={taskId}
          comment={newComment}
          commentId={commentId}
          onCancel={onCancel}
          onSend={sendHandler}
        />
      }
    />
  );
};
