import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { commitmentsDataGridPayloadDataSelector } from "../../../selectors/common/payload/commitmentsDataGridPayloadDataSelector";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { RootState } from "../../../../store";

export const getAzureSavingsPlansForDataGridThunk = createAsyncThunk(
  "commitments/getAzureSavingsPlansForDataGrid",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentAzureSavingsPlans } =
      cloudChiprApi.endpoints;

    const payload = commitmentsDataGridPayloadDataSelector(state);

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentAzureSavingsPlans.initiate(payload),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
