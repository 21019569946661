import { useMemo } from "react";
import { useAllItemsFiltersContext } from "../HierarchyAllItemsFiltersProvider";
import { DropdownSelect } from "../../../../../common/select/dropdown-select/DropdownSelect";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { DropdownSelectOption } from "../../../../../common/select/dropdown-select/utils/types/types";

export const HierarchyAllItemsCreatedByFilter = () => {
  const {
    filters: { createdBy, setCreatedBy },
    createdByOptions,
  } = useAllItemsFiltersContext();

  const options = useMemo(
    () =>
      createdByOptions.map((option) => ({
        value: option,
        label: option,
      })),
    [createdByOptions],
  );

  if (!createdByOptions?.length) {
    return null;
  }

  return (
    <DropdownSelect
      initialSelectedValues={createdBy}
      label="Created By"
      options={options}
      listWidth={300}
      renderOption={renderOption}
      submitHandlerOnClose={setCreatedBy}
    />
  );
};

const renderOption = ({ label }: DropdownSelectOption) => (
  <TypographyWithTooltip variant="inherit" title={label} />
);
