import { FC, useCallback } from "react";
import { CommitmentsUtilizationCustomMessageBeforeMessageComponent } from "./notification-custom-messages/CommitmentsUtilizationCustomMessageBeforeMessageComponent";
import { CommitmentsExpirationCustomMessageBeforeMessageComponent } from "./notification-custom-messages/CommitmentsExpirationCustomMessageBeforeMessageComponent";
import { CommitmentsCoverageCustomMessageBeforeMessageComponent } from "./notification-custom-messages/CommitmentsCoverageCustomMessageBeforeMessageComponent";
import { CostAnomalyAlertCustomMessageBeforeMessageComponent } from "./notification-custom-messages/CostAnomalyAlertCustomMessageBeforeMessageComponent";
import { CostAnomalyAlertCustomMessageAfterMessageComponent } from "./notification-custom-messages/CostAnomalyAlertCustomMessageAfterMessageComponent";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { alertsDataNotificationsSelector } from "../../../../../../../store/alerts/selectors/common/data/alertsDataNotificationsSelector";
import {
  CostAnomalyAlert,
  Emails,
} from "../../../../../../../services/cloudchipr.api";
import { setAlertsData } from "../../../../../../../store/alerts/alertsSlice";
import { NotificationsSelection } from "../../../../../common/notification-selection/NotificationsSelection";
import { alertsDataEmailsSelector } from "../../../../../../../store/alerts/selectors/common/data/alertsDataEmailsSelector";
import { AlertsCustomMessageIntegrationActionFromType } from "../custom-message/utils/types/types";

interface AlertsNotificationsProps {
  actionFrom: AlertsCustomMessageIntegrationActionFromType;
}

export const AlertsNotifications: FC<AlertsNotificationsProps> = ({
  actionFrom,
}) => {
  const dispatch = useAppDispatch();

  const emails = useAppSelector(alertsDataEmailsSelector);
  const notifications = useAppSelector(alertsDataNotificationsSelector);

  const emailsChangeHandler = useCallback(
    (emails: Emails | null) => {
      dispatch(setAlertsData({ emails }));
    },
    [dispatch],
  );

  const integrationsChangeHandler = useCallback(
    (notifications: CostAnomalyAlert["notifications"]) => {
      dispatch(setAlertsData({ notifications }));
    },
    [dispatch],
  );

  return (
    <NotificationsSelection
      selectedEmails={emails ?? null}
      emailsChangeHandler={emailsChangeHandler}
      disabledIntegrationTypes={["jira"]}
      selectedIntegrations={notifications}
      setSelectedIntegrations={integrationsChangeHandler}
      customMessageConfigs={customMessageConfigs[actionFrom]}
    />
  );
};

const customMessageConfigs = {
  "cost-anomaly-alert": {
    dialogContentProps: {
      BeforeMessage: CostAnomalyAlertCustomMessageBeforeMessageComponent,
      AfterMessage: CostAnomalyAlertCustomMessageAfterMessageComponent,
    },
    preview: {
      BeforeMessage: CostAnomalyAlertCustomMessageBeforeMessageComponent,
      AfterMessage: CostAnomalyAlertCustomMessageAfterMessageComponent,
    },
  },
  coverage: {
    dialogContentProps: {
      BeforeMessage: CommitmentsCoverageCustomMessageBeforeMessageComponent,
    },
    preview: {
      BeforeMessage: CommitmentsCoverageCustomMessageBeforeMessageComponent,
    },
  },
  commitments_expiration: {
    dialogContentProps: {
      BeforeMessage: CommitmentsExpirationCustomMessageBeforeMessageComponent,
    },
    preview: {
      BeforeMessage: CommitmentsExpirationCustomMessageBeforeMessageComponent,
    },
  },
  commitments_utilization: {
    dialogContentProps: {
      BeforeMessage: CommitmentsUtilizationCustomMessageBeforeMessageComponent,
    },
    preview: {
      BeforeMessage: CommitmentsUtilizationCustomMessageBeforeMessageComponent,
    },
  },
};
