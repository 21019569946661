import { FC, ReactNode } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { TypographyWithTooltip } from "../TypographyWithTooltip";

interface KeyValueSplitChipProps {
  title: string;
  value?: ReactNode;
  loading?: boolean;
  children?: ReactNode;
  keySx?: SxProps;
  valueSx?: SxProps;
}

export const KeyValueSplitChip: FC<KeyValueSplitChipProps> = ({
  value,
  title,
  loading,
  children,
  keySx,
  valueSx,
}) => {
  const child = children ?? (
    <TypographyWithTooltip
      title={value ?? "N/A"}
      variant="body2"
      fontWeight="medium"
      whiteSpace="nowrap"
    />
  );

  return (
    <Stack
      direction="row"
      border={1}
      overflow="hidden"
      borderRadius={1.5}
      borderColor="grey.300"
      alignItems="center"
    >
      <Stack
        bgcolor="grey.100"
        py={0.5}
        px={1.25}
        sx={keySx}
        height="100%"
        justifyContent="center"
        borderRight={1}
        borderColor="grey.300"
      >
        <Typography variant="body2" color="text.secondary" whiteSpace="nowrap">
          {title}
        </Typography>
      </Stack>

      <Box bgcolor="white" py={0.5} sx={valueSx} px={1.25}>
        {loading ? (
          <Skeleton variant="text" height={24} width={150} />
        ) : (
          <Stack>{child}</Stack>
        )}
      </Box>
    </Stack>
  );
};
