import { coverageWidgetByWidgetIdEndpointSelector } from "./coverageWidgetByWidgetIdEndpointSelector";
import { RootState } from "../../../../../store";

export const coverageWidgetByWidgetIdLoadingSelector = (
  state: RootState,
  widgetId: string,
) => {
  const response = coverageWidgetByWidgetIdEndpointSelector({
    widgetId,
  })(state);

  return !!response?.isLoading;
};
