import { billingExplorerWidgetByWidgetIdSelector } from "./billingExplorerWidgetByWidgetIdSelector";
import { RootState } from "../../../../../store";

export const billingExplorerWidgetDataByWidgetIdSelector = (
  state: RootState,
  widgetId: string,
) => {
  const response = billingExplorerWidgetByWidgetIdSelector({ widgetId })(state);

  return response?.data;
};
