import { FC, useEffect, useState } from "react";
import {
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useHover } from "../../utils/hooks/useHover.hook";

interface TypographyWithTooltipProps extends Omit<TypographyProps, "title"> {
  title: TooltipProps["title"];
  placement?: TooltipProps["placement"];
}

export const TypographyWithTooltip: FC<TypographyWithTooltipProps> = ({
  title,
  placement = "bottom",
  ...props
}) => {
  const { ref, hovered } = useHover();
  const [withTooltip, setWithTooltip] = useState(true);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const { clientWidth, scrollWidth } = ref.current;

    if (clientWidth >= scrollWidth) {
      setWithTooltip(false);
    } else {
      setWithTooltip(true);
    }
  }, [title, hovered, ref]);

  return (
    <Tooltip
      arrow
      placement={placement}
      title={
        withTooltip && title ? (
          <div style={{ maxHeight: 100, overflowY: "auto" }}>{title}</div>
        ) : (
          ""
        )
      }
    >
      <Typography
        ref={ref}
        {...props}
        sx={{
          ...props.sx,
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {props?.children ?? title}
      </Typography>
    </Tooltip>
  );
};
