function TaskPropertyOwnerIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="8" fill="#880E4F" />
      <path
        d="M10.0003 17.5C10.917 17.5 11.667 18.25 11.667 19.1667C11.667 20.0833 10.917 20.8333 10.0003 20.8333C9.08366 20.8333 8.33366 20.0833 8.33366 19.1667C8.33366 18.25 9.08366 17.5 10.0003 17.5ZM10.0003 15.8333C8.16699 15.8333 6.66699 17.3333 6.66699 19.1667C6.66699 21 8.16699 22.5 10.0003 22.5C11.8337 22.5 13.3337 21 13.3337 19.1667C13.3337 17.3333 11.8337 15.8333 10.0003 15.8333ZM15.0003 9.16667C15.917 9.16667 16.667 9.91667 16.667 10.8333C16.667 11.75 15.917 12.5 15.0003 12.5C14.0837 12.5 13.3337 11.75 13.3337 10.8333C13.3337 9.91667 14.0837 9.16667 15.0003 9.16667ZM15.0003 7.5C13.167 7.5 11.667 9 11.667 10.8333C11.667 12.6667 13.167 14.1667 15.0003 14.1667C16.8337 14.1667 18.3337 12.6667 18.3337 10.8333C18.3337 9 16.8337 7.5 15.0003 7.5ZM20.0003 17.5C20.917 17.5 21.667 18.25 21.667 19.1667C21.667 20.0833 20.917 20.8333 20.0003 20.8333C19.0837 20.8333 18.3337 20.0833 18.3337 19.1667C18.3337 18.25 19.0837 17.5 20.0003 17.5ZM20.0003 15.8333C18.167 15.8333 16.667 17.3333 16.667 19.1667C16.667 21 18.167 22.5 20.0003 22.5C21.8337 22.5 23.3337 21 23.3337 19.1667C23.3337 17.3333 21.8337 15.8333 20.0003 15.8333Z"
        fill="white"
      />
    </svg>
  );
}

export default TaskPropertyOwnerIcon;
