import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { getBillingExplorerSummaryFixedCashKeySelector } from "../../selectors/resource-explorer-summary/getBillingExplorerSummaryFixedCashKeySelector";
import { setResourceExplorerExplainWithAIData } from "../../resourceExplorerSlice";
import { resourceExplorerPayloadDataSelector } from "../../selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerPayloadDataSelector";
import { addFilterTreePathToResourceExplorerPayload } from "../../utils/helpers/addFilterTreePathToResourceExplorerPayload";

export const updateBillingExplorerSummaryMetaDataAfterDataGettingThunk =
  createAsyncThunk(
    "resourceExplorer/updateBillingExplorerSummaryMetaDataAfterDataGetting",
    async (keyUsedToGetData: string, { dispatch, getState }) => {
      const state = getState() as RootState;
      const newKey = getBillingExplorerSummaryFixedCashKeySelector(state) ?? "";
      const resourceExplorerDataGettingPayload =
        resourceExplorerPayloadDataSelector(state);
      const payload = addFilterTreePathToResourceExplorerPayload(
        resourceExplorerDataGettingPayload,
      );
      if (keyUsedToGetData === newKey) {
        dispatch(
          setResourceExplorerExplainWithAIData([
            {
              key: newKey,
              payload,
            },
          ]),
        );
      }
    },
  );
