import { billingExplorerWidgetSetupDataSelector } from "./billingExplorerWidgetSetupDataSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridDataWithId } from "../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { formatBillingExplorerREDataGridData } from "../../../../utils/helpers/billing-explorer/formatBillingExplorerREDataGridData";

export const billingExplorerWidgetSetupTotalDataWithIdsSelector = (
  state: RootState,
): ResourceExplorerGridDataWithId[] | undefined => {
  const data = billingExplorerWidgetSetupDataSelector(state)?.total;
  return formatBillingExplorerREDataGridData(data ?? [], true) ?? [];
};
