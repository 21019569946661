import { FC, useCallback, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { AskAICollapsableConversationItemHeader } from "./AskAICollapsableConversationItemHeader";
import { AskAICollapsableConversationItemBody } from "./AskAICollapsableConversationItemBody";
import { AskAiConversationItem } from "../../../../../../../store/ask-ai/utils/types";
import { useAppSelector } from "../../../../../../../store/hooks";
import { askAiLoadingSelector } from "../../../../../../../store/ask-ai/selectors/askAiLoadingSelector";

interface AskAICollapsableConversationItemProps {
  data: AskAiConversationItem[];
  index: number;
  totalCount: number;
  collapsed: boolean;
}

export const AskAICollapsableConversationItem: FC<
  AskAICollapsableConversationItemProps
> = ({ data, index, totalCount, collapsed: collapsedProps }) => {
  const [collapsed, setCollapsed] = useState(false);
  const loading = useAppSelector(askAiLoadingSelector);

  const toggleCollapse = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  useEffectOnceWhen(() => {
    setCollapsed(true);
  }, !!data?.[1]);

  useEffect(() => {
    if (totalCount > 1 && index !== totalCount - 1 && !loading) {
      setCollapsed(false);
    }
  }, [totalCount, index, loading]);

  return (
    <Stack bgcolor="grey.100" px={1.5} borderRadius={2}>
      <AskAICollapsableConversationItemHeader
        question={data?.[0]?.text}
        onCollapse={toggleCollapse}
        collapsed={collapsed}
        index={index}
        totalCount={totalCount}
      />

      <AskAICollapsableConversationItemBody
        charts={data[1]?.charts}
        tables={data[1]?.tables}
        text={data[1]?.text}
        type={data[1]?.type}
        collapsed={collapsed}
      />
    </Stack>
  );
};
