import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { capitalize, Typography } from "@mui/material";
import { getSavingsPlanCommonColumnDefs } from "./getSavingsPlanCommonColumnDefs";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../common/utils/types";
import { commitmentsResourceTypeNames } from "../../../../../../../common/utils/constants/labels";
import { netSavingsColumnDef } from "../coverage/common/netSavingsColumnDef";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const savingsPlanGroupedByResourceTypeColumnDefs: ColDef<SavingsPlanCoverageDataWithId>[] =
  [
    {
      flex: 1.5,
      field: "resource_type.resource_type",
      headerName: "Resource Type",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => {
        const rt = data?.resource_type?.resource_type;

        if (!rt) {
          return;
        }

        return (
          <Typography variant="body2" textTransform="capitalize">
            {commitmentsResourceTypeNames[rt]}
          </Typography>
        );
      },
      valueGetter: ({ data }) =>
        data?.resource_type?.resource_type
          ? capitalize(
              commitmentsResourceTypeNames[data.resource_type.resource_type] ??
                "",
            )
          : "",
    },
    {
      flex: 1.5,
      field: "resource_type.instance_family",
      headerName: "Instance Family",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => (
        <Typography variant="body2">
          {data?.resource_type?.instance_family ?? "-"}
        </Typography>
      ),
    },
    {
      flex: 1.5,
      field: "resource_type.region",
      headerName: "Region",
    },
    netSavingsColumnDef,
    ...getSavingsPlanCommonColumnDefs("resource_type"),
    agGridEmptyColumnToFitEmptySpace,
  ];
