import { FC, useCallback } from "react";
import { Card, Collapse, Stack } from "@mui/material";
import { useToggle } from "rooks";
import { KubernetesLiveUsageMgmtResourceCardGrid } from "./resource-card-content/resource-card-data/resource-card-grid/KubernetesLiveUsageMgmtResourceCardGrid";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { useResourceCardCollapsedState } from "../../../live-usage-mgmt/utils/hooks/useResourceCardState.hook";
import { LiveUsageMgmtResourceCardHeader } from "../../../live-usage-mgmt/components/resource-card/resource-card-header/LiveUsageMgmtResourceCardHeader";
import { KubernetesLiveUsageMgmtResourceCardDialog } from "../../KubernetesLiveUsageMgmtResourceCardDialog";
import { useAppSelector } from "../../../../../store/hooks";
import { kubernetesLiveUsageMgmtSelectedClusterIdsSelector } from "../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtSelectedClusterIdsSelector";

interface KubernetesLiveUsageMgmtResourceCardProps {
  singleView?: boolean;
  initialCollapsed?: boolean;
  resourceType: ResourceType;
}

export const KubernetesLiveUsageMgmtResourceCard: FC<
  KubernetesLiveUsageMgmtResourceCardProps
> = ({ resourceType, singleView, initialCollapsed }) => {
  const clusterIds =
    useAppSelector(kubernetesLiveUsageMgmtSelectedClusterIdsSelector) ?? [];
  const { collapsedState, setCollapsedState } = useResourceCardCollapsedState(
    resourceType,
    clusterIds,
    initialCollapsed,
  );

  const [collapsed, toggleCollapsed] = useToggle(collapsedState);
  const [fullScreen, toggleFullScreen] = useToggle();

  const onCollapse = useCallback(() => {
    setCollapsedState(!collapsed);
    toggleCollapsed(!collapsed);
  }, [collapsed, setCollapsedState, toggleCollapsed]);

  if (singleView) {
    return (
      <Stack width="100%">
        <KubernetesLiveUsageMgmtResourceCardGrid
          resourceType={resourceType}
          fullHeight
        />
      </Stack>
    );
  }

  return (
    <Card variant="outlined" sx={{ borderColor: "primary.light" }}>
      <LiveUsageMgmtResourceCardHeader
        resourceType={resourceType}
        onCollapse={onCollapse}
        onFullScreen={toggleFullScreen}
        collapsed={collapsed}
      />

      <Collapse in={singleView || !collapsed}>
        <KubernetesLiveUsageMgmtResourceCardGrid resourceType={resourceType} />
      </Collapse>

      {fullScreen && (
        <KubernetesLiveUsageMgmtResourceCardDialog
          resourceType={resourceType}
          onFullScreen={toggleFullScreen}
        />
      )}
    </Card>
  );
};
