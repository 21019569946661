import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { TrendingUp } from "@mui/icons-material";
import { TrendType } from "../../../../../../../../../services/cloudchipr.api";
import { TrendSettingsExampleDates } from "../../../../../../../../app-navigation/components/page-components/billing-explorer/components/actions-menu/action-items/report-settings/components/TrendSettingsExampleDates";
import { trendSettingsTitlesByType } from "../../../../../../../../app-navigation/components/page-components/billing-explorer/components/actions-menu/action-items/report-settings/utils/constants";

interface WidgetSetupTrendSettingSelectProps {
  trendType: TrendType;
  onTrendTypeChange(value: TrendType): void;
}

export const WidgetSetupTrendSettingSelect: FC<
  WidgetSetupTrendSettingSelectProps
> = ({ trendType, onTrendTypeChange }) => {
  const renderValue = useCallback((value: TrendType) => {
    if (!value) {
      return;
    }
    return trendSettingsTitlesByType.get(value);
  }, []);

  const handleChange = useCallback(
    (e: SelectChangeEvent<TrendType>) => {
      onTrendTypeChange(e.target.value as TrendType);
    },
    [onTrendTypeChange],
  );

  return (
    <FormControl fullWidth size="small">
      <InputLabel>Trend</InputLabel>
      <Select
        size="small"
        fullWidth
        label="Trend"
        value={trendType}
        onChange={handleChange}
        renderValue={renderValue}
      >
        <Stack px={1} pb={1}>
          <Stack direction="row" spacing={0.5}>
            <TrendingUp sx={{ fontSize: 16, color: "grey.600" }} />
            <Typography variant="caption" fontWeight="bold">
              Trend Settings
            </Typography>
          </Stack>
          <Typography variant="caption" color="text.secondary" fontSize={10}>
            Trend setting is relevant for date ranges less than 30 days.
          </Typography>
        </Stack>
        <MenuItem value="month_over_month">
          <Stack>
            <Typography variant="body2">
              {trendSettingsTitlesByType.get("month_over_month")}
            </Typography>
            <TrendSettingsExampleDates trendType="month_over_month" />
          </Stack>
        </MenuItem>
        <MenuItem value="period_over_period">
          <Stack>
            <Typography variant="body2">
              {trendSettingsTitlesByType.get("period_over_period")}
            </Typography>
            <TrendSettingsExampleDates trendType="period_over_period" />
          </Stack>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
