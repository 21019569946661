import { FC, useMemo } from "react";
import { savingsPlanCoverageColumnDefs } from "./columns/savingsPlanCoverageColumnDefs";
import { UtilizationAndCoverageDataViewTabs } from "./components/UtilizationAndCoverageDataViewTabs";
import { useAppSelector } from "../../../../../../../store/hooks";
import { savingsPlansCoverageDataSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/savingsPlansCoverageDataSelector";
import { commitmentsCoverageGroupingSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsCoverageGroupingSelector";
import { savingsPlansForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/savingsPlansForTableLoadingSelector";
import { CommitmentsAGGridToolbar } from "../../../../common/components/data-grid/CommitmentsAGGridToolbar";

import {
  CommitmentsAgGrid,
  CommitmentsToolbar,
} from "../../../../common/components/CommitmentsAgGrid";

export const SavingsPlanCoverageDataGrid: FC = () => {
  const data = useAppSelector(savingsPlansCoverageDataSelector);
  const loading = useAppSelector(savingsPlansForTableLoadingSelector);
  const grouping = useAppSelector(commitmentsCoverageGroupingSelector);

  const columnDefs = useMemo(
    () => savingsPlanCoverageColumnDefs(grouping),
    [grouping],
  );

  return (
    <CommitmentsAgGrid
      key={grouping}
      Toolbar={toolbar}
      loading={loading}
      columnDefs={columnDefs}
      data={data}
      grouping={grouping}
      localStorageUniqueKey="savingsPlanCoverageDataGrid"
    />
  );
};

const toolbar: CommitmentsToolbar = ({ gridApi }) => (
  <CommitmentsAGGridToolbar gridApi={gridApi}>
    <UtilizationAndCoverageDataViewTabs />
  </CommitmentsAGGridToolbar>
);
