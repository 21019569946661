import { costAnomaliesSetupPropertyByKeySelector } from "./costAnomaliesSetupPropertyByKeySelector";
import { RootState } from "../../../../store";
import { costAnomaliesWidgetEditChangesExistSelector } from "../../widgets/cost-anomalies/costAnomaliesWidgetEditChangesExistSelector";

export const costAnomaliesSubmitButtonDisabledSelector = (
  state: RootState,
): boolean => {
  const editDisabledByChange =
    costAnomaliesWidgetEditChangesExistSelector(state);
  const viewId = costAnomaliesSetupPropertyByKeySelector("viewId")(state);
  const name = costAnomaliesSetupPropertyByKeySelector("name")(state);
  const id = costAnomaliesSetupPropertyByKeySelector("id")(state);

  return (id && !editDisabledByChange) || !name || !viewId;
};
