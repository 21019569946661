import { taskManagementTargetSelector } from "./taskManagementTargetSelector";
import { RootState } from "../../../../../store";
import { TaskTargetType } from "../../../../utils/types/types";

export const taskManagementTargetTypeSelector = (
  state: RootState,
): TaskTargetType["type"] | undefined => {
  const target = taskManagementTargetSelector(state);

  return target?.type;
};
