import { billingExplorerWidgetSetupTotalDataWithIdsSelector } from "./billingExplorerWidgetSetupTotalDataWithIdsSelector";
import { RootState } from "../../../../../store";

export const billingExplorerWidgetSetupTotalDataCountSelector = (
  state: RootState,
): number | undefined => {
  const data = billingExplorerWidgetSetupTotalDataWithIdsSelector(state);

  return data?.length;
};
