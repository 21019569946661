import { FC, useCallback, useMemo } from "react";
import { SvgIconComponent } from "@mui/icons-material";

import { formatHierarchyVisibilityOptions } from "./utils/formateHierarchyVisibilityOptions";
import { hierarchySelectFilterFn } from "./utils/helpers/hierarchySelectFilterFn";
import { HierarchySelectDropdownHeader } from "./components/HierarchySelectDropdownHeader";
import { DropdownSelect } from "../../../common/select/dropdown-select/DropdownSelect";
import { FilterTriggerComponentProps } from "../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

import { DropdownFooterComponent } from "../../../common/select/dropdown-select/utils/types/types";
import {
  NavigationHierarchyVisibilityData,
  NavigationItemsVisibilityType,
} from "../../../../store/navigation/utils/types";

export interface HierarchySelectVisibilityProps {
  onChange(id: string): void;
  label: string;
  selectedItemId?: string;
  ItemIcon?: SvgIconComponent;
  disabledOptionsIds?: string[];
  hierarchy?: NavigationHierarchyVisibilityData;
  CustomTriggerComponent?: FC<FilterTriggerComponentProps>;
  DropdownFooter?: DropdownFooterComponent;
  visibility?: NavigationItemsVisibilityType;
  isProtected?: boolean;
}

export const HierarchySelectVisibility: FC<HierarchySelectVisibilityProps> = ({
  ItemIcon,
  onChange,
  hierarchy,
  selectedItemId,
  disabledOptionsIds,
  CustomTriggerComponent,
  label,
  DropdownFooter,
  visibility,
  isProtected,
}) => {
  const publicOptions = useMemo(() => {
    return formatHierarchyVisibilityOptions({
      data: hierarchy?.visible_to_everyone ?? [],
      ItemIcon,
      disabledOptionsIds,
      visibility: "visible_to_everyone",
    });
  }, [hierarchy, disabledOptionsIds, ItemIcon]);

  const privateOptions = useMemo(() => {
    return formatHierarchyVisibilityOptions({
      data: hierarchy?.visible_only_to_me ?? [],
      disabledOptionsIds,
      ItemIcon,
      visibility: "visible_only_to_me",
    });
  }, [hierarchy, disabledOptionsIds, ItemIcon]);

  const options = useMemo(() => {
    if (visibility === "visible_to_everyone") {
      return publicOptions;
    }
    return publicOptions.concat(privateOptions);
  }, [publicOptions, privateOptions, visibility]);

  const selectHandler = useCallback(
    (selected: string[]) => {
      const id = selected.at(0);

      if (id) {
        onChange(id);
      }
    },
    [onChange],
  );

  // TODO: Handle this case during DropdownSelect component refactoring.(C8R-6216)
  const key = useMemo(() => {
    return options?.reduce((result, item) => {
      result += item.value;
      return result;
    }, "");
  }, [options]);

  if (!hierarchy?.visible_to_everyone) {
    return null;
  }

  return (
    <DropdownSelect
      key={key}
      singleSelect
      showSearch={false}
      label={label ?? ""}
      isProtected={isProtected}
      options={options}
      sortSelectedOptions={false}
      values={[selectedItemId ?? ""]}
      submitHandlerOnClose={selectHandler}
      filterFn={hierarchySelectFilterFn}
      TriggerComponent={CustomTriggerComponent}
      DropdownHeader={HierarchySelectDropdownHeader}
      listWidth={400}
      DropdownFooter={DropdownFooter}
    />
  );
};
