import { navigationResourceExplorerPublicDataSelector } from "./navigationResourceExplorerPublicDataSelector";
import { navigationResourceExplorerPrivateDataSelector } from "./navigationResourceExplorerPrivateDataSelector";
import { NavigationItemsVisibilityType } from "../../utils/types";
import { RootState } from "../../../store";
import { TreeItems } from "../../../../components/app-navigation/components/sortable-tree/utils/types";

export const navigationResourceExplorerDataByVisibilitySelector = (
  state: RootState,
  visibility?: NavigationItemsVisibilityType,
): TreeItems | undefined => {
  if (visibility === "visible_to_everyone") {
    return navigationResourceExplorerPublicDataSelector(state);
  }
  return navigationResourceExplorerPrivateDataSelector(state);
};
