import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";
import { Account, ProviderType } from "../../../../services/cloudchipr.api";
import { AccountOptionType } from "../../../../components/common/account-select/utils/types/types";
import { sortByAlphanumericForAccountOptionType } from "../../../../components/common/account-select/utils/helpers/sorting/sortByAlphanumericForAccountOptionType";
import { sortAccountOptionTypeByOrgIdCompareFn } from "../utils/helpers/sortingFn";
import { awsAllAccountsGroupedByOrgsSelector } from "../../../accounts/selectors/aws/awsAllAccountsGroupedByOrgsSelector";
import { gcpAllAccountsGroupedByOrgsSelector } from "../../../accounts/selectors/gcp/gcpAllAccountsGroupedByOrgsSelector";
import { azureAllAccountsGroupedByOrgsSelector } from "../../../accounts/selectors/azure/azureAllAccountsGroupedByOrgsSelector";
import { accountOrganisationIdSelector } from "../../../accounts/selectors/slice-data/accountOrganisationIdSelector";

export const allAccountsGroupedByOrgAndProviderSelector =
  createDraftSafeSelector(
    [
      awsAllAccountsGroupedByOrgsSelector,
      gcpAllAccountsGroupedByOrgsSelector,
      azureAllAccountsGroupedByOrgsSelector,
      liveUsageMgmtProviderSelector,
      accountOrganisationIdSelector,
    ],
    (aws, gcp, azure, provider, orgId): AccountOptionType[] | undefined => {
      const accounts: Record<ProviderType, Record<string, Account[]> | null> = {
        aws,
        gcp,
        azure,
        kubernetes: {},
      };

      if (!provider || !orgId || !accounts?.[provider]?.[orgId]) {
        return;
      }

      return generateAccountSelectionOptions(
        accounts?.[provider]?.[orgId] ?? [],
      );
    },
  );

const generateAccountSelectionOptions = (
  accounts: Account[] | null,
): AccountOptionType[] => {
  if (!accounts) {
    return [];
  }

  const options = accounts.reduce((result, account) => {
    if (account.status === "deactivated" || account.status !== "connected") {
      return result;
    }
    result.push({
      value: account.id,
      label:
        account.provider_account_name ?? `ID: ${account.provider_account_id}`,
      orgId: account.group_id ?? undefined,
      providerAccountId: account.provider_account_id ?? "",
      accountType: account.type,
      accessType: account.access_type,
      status: account.status,
      warning: !account.has_satisfied_permissions,
    });

    return result;
  }, [] as AccountOptionType[]);

  const accountSelectionOptions =
    sortByAlphanumericForAccountOptionType(options);

  return accountSelectionOptions
    .flat()
    .toSorted(sortAccountOptionTypeByOrgIdCompareFn);
};
