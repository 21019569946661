import { FC, useCallback, useMemo, useState } from "react";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { useDidMount } from "rooks";
import { GroupBySelectorTrigger } from "./components/triggers/GroupBySelectorTrigger";
import { GroupingChangeHandler } from "./utils/types/types";
import { getResourceExplorerGroupingOptions } from "./components/options/getResourceExplorerGroupingOptions";
import {
  ResourceExplorerGroupingNullable,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../services/cloudchipr.api";
import { DropdownSelect } from "../../../common/select/dropdown-select/DropdownSelect";
import { FilterTriggerComponentProps } from "../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { DropdownSelectOption } from "../../../common/select/dropdown-select/utils/types/types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getResourceExplorerGroupingTagKeysThunk } from "../../../../store/common/thunks/resource-explorer/getResourceExplorerGroupingTagKeysThunk";
import { resourceExplorerPossibleGroupingKeysSelector } from "../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerPossibleGroupingKeysSelector";
import { resourceExplorerGroupedPossibleGroupingsSelector } from "../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupedPossibleGroupingsSelector";
import { resourceExplorerGroupingsOrderedParentLabelsSelector } from "../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingsOrderedParentLabelsSelector";

interface GroupingSelectorProps {
  disabled?: boolean;
  dropDownMaxHeight?: number;
  groupValues?: string[];
  value: ResourceExplorerGroupingNullable;
  triggerSize?: TextFieldProps["size"];
  hiddenGroupings?: ResourceExplorerGroupingNullable[];
  onChange: GroupingChangeHandler;
}

export const GroupingSelector: FC<GroupingSelectorProps> = ({
  value,
  groupValues,
  disabled,
  onChange,
  hiddenGroupings,
  triggerSize,
  dropDownMaxHeight,
}) => {
  const dispatch = useAppDispatch();
  const [forceToClose, setForceToClose] = useState(false);

  const { data: dimensionsGroupingValues } = useValuesQuery({
    filterProvider: "dimensions",
    filterType: "dimension_id",
  });

  const possibleGroupingsKeys = useAppSelector(
    resourceExplorerPossibleGroupingKeysSelector,
  );
  const groupedPossibleGroupings = useAppSelector(
    resourceExplorerGroupedPossibleGroupingsSelector,
  );
  const orderedParentLabels = useAppSelector(
    resourceExplorerGroupingsOrderedParentLabelsSelector,
  );

  const closeHandler = useCallback(() => {
    setForceToClose(true);

    setTimeout(() => {
      setForceToClose(false);
    }, 300);
  }, []);

  const renderOptions = useMemo(() => {
    if (!groupedPossibleGroupings || !orderedParentLabels) {
      return [];
    }

    return getResourceExplorerGroupingOptions(
      value,
      onChange,
      closeHandler,
      orderedParentLabels,
      groupedPossibleGroupings,
      dimensionsGroupingValues,
      groupValues,
      hiddenGroupings,
    );
  }, [
    dimensionsGroupingValues,
    closeHandler,
    value,
    groupValues,
    onChange,
    groupedPossibleGroupings,
    orderedParentLabels,
    hiddenGroupings,
  ]);

  const submitHandlerOnClose = useCallback(
    (values: string[]) => {
      const selected = values.at(0) as ResourceExplorerGroupingNullable;

      if (!selected) {
        return;
      }
      // TODO: refactor this logic
      if (possibleGroupingsKeys?.includes(selected)) {
        onChange(selected);
      } else {
        onChange("category", [selected]);
      }
    },
    [onChange, possibleGroupingsKeys],
  );

  const TriggerComponent = useCallback(
    (props: FilterTriggerComponentProps) => {
      return (
        <GroupBySelectorTrigger
          {...props}
          groupValues={groupValues}
          size={triggerSize}
        />
      );
    },
    [groupValues, triggerSize],
  );

  useDidMount(() => {
    dispatch(getResourceExplorerGroupingTagKeysThunk());
  });

  if (!renderOptions) {
    return null;
  }

  const groupingAsValue = value ? [value] : [];

  return (
    <DropdownSelect
      singleSelect
      listWidth={320}
      label="Group by"
      disabled={disabled}
      filterFn={filterFn}
      showSelectAll={false}
      options={renderOptions}
      sortSelectedOptions={false}
      forceToClose={forceToClose}
      listMaxHeight={dropDownMaxHeight}
      TriggerComponent={TriggerComponent}
      submitHandlerOnClose={submitHandlerOnClose}
      values={value === "category" ? groupValues : groupingAsValue}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string): boolean => {
  keyword = keyword.trim().toLowerCase();

  return (
    option.rawValue?.search?.toLowerCase()?.includes(keyword) ||
    !!option.nestedOptions?.some((option) => filterFn(option, keyword))
  );
};
