import { FC } from "react";
import { Stack } from "@mui/material";
import { CoverageWidgetHeaderInfo } from "./CoverageWidgetHeaderInfo";
import { useAppSelector } from "../../../../../../../store/hooks";
import { coverageWidgetByIdSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetByIdSelector";
import { WidgetTitleProps } from "../../../../utils/types/types";
import { WidgetCardHeaderActionRow } from "../../common/widget-header/WidgetCardHeaderActionRow";

interface CoverageWidgetCardHeaderProps {
  widgetId: string;
  hovered: boolean;
}

export const CoverageWidgetCardHeader: FC<CoverageWidgetCardHeaderProps> = ({
  widgetId,
  hovered,
}) => {
  const widget = useAppSelector((state) =>
    coverageWidgetByIdSelector(state, widgetId),
  );

  const widgetTitleProps: WidgetTitleProps = {
    widgetName: widget?.name ?? "",
    dates: {},
  };

  return (
    <Stack
      pl={2}
      pr={0}
      py={1}
      borderBottom={1}
      position="sticky"
      top={0}
      bgcolor="white"
      zIndex={2}
      borderColor="grey.300"
    >
      <WidgetCardHeaderActionRow
        widgetId={widgetId}
        hovered={hovered}
        widgetType="coverage"
        widgetTitleProps={widgetTitleProps}
      />

      <CoverageWidgetHeaderInfo
        providerOrganisationId={widget?.provider_organisation_id}
        commitmentType={widget?.commitment_type}
        grouping={widget?.grouping}
        dates={widget?.dates}
        widgetId={widgetId}
        hovered={hovered}
      />
    </Stack>
  );
};
