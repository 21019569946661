import { ColDef } from "ag-grid-community";
import { azureReservationGroupedByResourceTypeColumnDefs } from "./reservation/azureReservationGroupedByResourceTypeColumnDefs";
import { azureReservationGroupedByAccountColumnDefs } from "./reservation/azureReservationGroupedByAccountColumnDefs";
import { azureReservationCoverageGroupedByAccountColumnDefs } from "./coverage/azureReservationCoverageGroupedByAccountColumnDefs";
import { azureReservationCoverageGroupedByCommitmentColumnDefs } from "./coverage/azureReservationCoverageGroupedByCommitmentColumnDefs";
import {
  CommitmentFilterType,
  SavingsPlanCoverageGrouping,
} from "../../../../../../../../../../services/cloudchipr.api";
import { DataViewTab } from "../../../../../../../../../../store/commitments/utils/types/types";
import { AzureReservationsCoverageDataWithId } from "../../../../../../../common/utils/types";

type Columns = Partial<
  Record<
    SavingsPlanCoverageGrouping,
    ColDef<AzureReservationsCoverageDataWithId>[]
  >
>;

const commonColumns = {
  commitment: {
    resource_type: azureReservationGroupedByResourceTypeColumnDefs,
    account: azureReservationGroupedByAccountColumnDefs,
  },
  coverage: {
    account: azureReservationCoverageGroupedByAccountColumnDefs,
    commitment: azureReservationCoverageGroupedByCommitmentColumnDefs,
    resource_type: [],
  },
};

export const azureReservationDrawerDataGridColumnDefs: Record<
  CommitmentFilterType,
  Partial<Record<DataViewTab, Columns>>
> = {
  resource_type: commonColumns,
  account: commonColumns,
  commitment: commonColumns,
  savings_plan: commonColumns,
  region: {
    commitment: {
      resource_type: azureReservationGroupedByResourceTypeColumnDefs,
      account: azureReservationGroupedByAccountColumnDefs,
    },
    coverage: {
      account: azureReservationCoverageGroupedByAccountColumnDefs,
      commitment: azureReservationCoverageGroupedByCommitmentColumnDefs,
      resource_type: [],
    },
  },
};
