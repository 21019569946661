import { FC, useCallback } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { resourceDetailsCostsDataPointSelector } from "../../../../../../../store/resource-details/selectors/options/resourceDetailsCostsDataPointSelector";
import { setResourceDetailsDataPoint } from "../../../../../../../store/resource-details/resourceDetailsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { DateDataPoint } from "../../../../../../../services/cloudchipr.api";
import { getResourceDetailsResourceBillingDataThunk } from "../../../../../../../store/resource-details/thunks/data/getResourceDetailsResourceBillingDataThunk";

export const ResourceDetailsDataPointSelector: FC = () => {
  const dispatch = useAppDispatch();

  const dataPoint = useAppSelector(resourceDetailsCostsDataPointSelector);

  const changeHandler = useCallback(
    (event: SelectChangeEvent) => {
      dispatch(
        setResourceDetailsDataPoint(event.target.value as DateDataPoint),
      );
      dispatch(getResourceDetailsResourceBillingDataThunk());
    },
    [dispatch],
  );

  if (!dataPoint) {
    return null;
  }

  return (
    <FormControl size="xsmall" fullWidth sx={{ width: 110 }}>
      <Select
        MenuProps={{ disablePortal: true }}
        value={dataPoint}
        onChange={changeHandler}
        name="data_point"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "grey.300" },
        }}
      >
        <MenuItem value="daily">
          <Typography variant="caption">Daily</Typography>
        </MenuItem>
        <MenuItem value="weekly">
          <Typography variant="caption">Weekly</Typography>
        </MenuItem>
        <MenuItem value="monthly">
          <Typography variant="caption">Monthly</Typography>
        </MenuItem>
        <MenuItem value="quarterly">
          <Typography variant="caption">Quarterly</Typography>
        </MenuItem>
        <MenuItem value="yearly">
          <Typography variant="caption">Yearly</Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
