import { TabList } from "@mui/lab";
import { Divider, Stack, Tab } from "@mui/material";
import { FC, SyntheticEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BudgetsAndAlertsTabLabel } from "./BudgetsAndAlertsTabLabel";
import { AlertTabType } from "../../../../store/alerts/utils/types/types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setAlertsActiveTab } from "../../../../store/alerts/alertsSlice";
import { costAnomalyAlertsLengthSelector } from "../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyAlertsLengthSelector";
import { utilizationAlertsLengthSelector } from "../../../../store/alerts/selectors/utilization-alerts/utilizationAlertsLengthSelector";
import { alertsActiveTabSelector } from "../../../../store/alerts/selectors/alertsActiveTabSelector";
import { useGetUsersMeOrganisationsCurrentBudgetsQuery } from "../../../../services/cloudchipr.api";

export const BudgetsAndAlertsTabs: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const costAnomalyLength = useAppSelector(costAnomalyAlertsLengthSelector);
  const utilizationLength = useAppSelector(utilizationAlertsLengthSelector);
  const { data: budgetsData } = useGetUsersMeOrganisationsCurrentBudgetsQuery();
  const budgetLength = budgetsData?.length ?? 0;

  const alertsActiveTab = useAppSelector(alertsActiveTabSelector);

  const handleTabChange = useCallback(
    (_: SyntheticEvent, tab: AlertTabType) => {
      dispatch(setAlertsActiveTab(tab));
      navigate(`/alerts?alertType=${tab}`, { replace: true });
    },
    [dispatch, navigate],
  );

  return (
    <Stack>
      <TabList sx={{ bgcolor: "white" }} onChange={handleTabChange}>
        <Tab
          value="budget"
          label={
            <BudgetsAndAlertsTabLabel
              label="Budgets"
              count={budgetLength}
              active={alertsActiveTab === "budget"}
            />
          }
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="costAnomaly"
          label={
            <BudgetsAndAlertsTabLabel
              label="Cost Anomaly Alerts"
              count={costAnomalyLength}
              active={alertsActiveTab === "costAnomaly"}
            />
          }
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="utilization"
          label={
            <BudgetsAndAlertsTabLabel
              label="Commitment Alerts"
              count={utilizationLength}
              active={alertsActiveTab === "utilization"}
            />
          }
          sx={{ textTransform: "none" }}
        />
      </TabList>

      <Divider />
    </Stack>
  );
};
