import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateTasksViewThunk } from "./views-hierarchy/actions/updateTasksViewThunk";
import { RootState } from "../../store";
import { setTaskManagementViewCompletedShown } from "../taskManagementSlice";
import { taskCurrentViewConfigShowCompletedSelector } from "../selectors/taskCurrentViewConfigShowCompletedSelector";
import { tasksCurrentViewIdSelector } from "../selectors/views-hierarchy/current-view/tasksCurrentViewIdSelector";
import { tasksCurrentViewConfigSelector } from "../selectors/views-hierarchy/current-view/tasksCurrentViewConfigSelector";

export const updateTaskViewConfigShowCompletedThunk = createAsyncThunk(
  "taskManagement/updateTaskViewConfigShowCompleted",
  (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const showCompleted = taskCurrentViewConfigShowCompletedSelector(state);
    const currentViewId = tasksCurrentViewIdSelector(state);
    const currentViewConfig = tasksCurrentViewConfigSelector(state);

    dispatch(setTaskManagementViewCompletedShown(!showCompleted));
    if (currentViewId) {
      dispatch(
        updateTasksViewThunk({
          viewId: currentViewId,
          body: {
            config: { ...currentViewConfig, showCompleted: !showCompleted },
          },
        }),
      );
    }
  },
);
