import { money } from "../../../../../../../utils/numeral/money";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { formatSecondsToFormattedDate } from "../../../../../../../components/pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/helpers/formatSecondsToFormattedDate";
import { KubernetesResourceDataType } from "../../../../../utils/types/types";

export const generateKubernetesResourceDetailsHeaderChipsData = (
  resource: KubernetesResourceDataType,
) => {
  const data = [
    { title: "Cluster", value: resource.resource.cluster?.name },
    {
      title: "Monthly Price",
      value: money(resource.resource.total_monthly_cost),
    },
    { title: "Node Group/Pool", value: resource.resource.name },
  ];

  if ("instance_type" in resource.resource) {
    data.push({
      title: "Instance Type",
      value: resource.resource.instance_type,
    });
  }

  if ("region" in resource.resource) {
    data.push({ title: "Region", value: resource.resource.region });
  }

  if ("pod_count" in resource.resource) {
    data.push({ title: "Pods", value: `${resource.resource.pod_count}` });
  }

  if ("namespace" in resource.resource) {
    data.push({ title: "Namespace", value: resource.resource.namespace });
  }

  if ("generation" in resource.resource) {
    data.push({ title: "Generation", value: resource.resource.generation });
  }

  if ("strategy" in resource.resource) {
    data.push({ title: "Strategy", value: resource.resource.strategy });
  }

  if ("pod_management_policy" in resource.resource) {
    data.push({
      title: "Pod Management Policy",
      value: resource.resource.pod_management_policy,
    });
  }

  if ("creation_timestamp" in resource.resource) {
    data.push({
      title: "Creation Date",
      value: formatDate(resource.resource.creation_timestamp, {
        type: "date",
      }),
    });
  }

  if ("age_in_seconds" in resource.resource) {
    data.push({
      title: "Age",
      value: formatSecondsToFormattedDate(resource.resource.age_in_seconds),
    });
  }

  return data;
};
