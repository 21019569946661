import { FC } from "react";
import { useCurrentEditor } from "@tiptap/react";
import { MenuProps, Select, Stack } from "@mui/material";
import { ToolbarTextAlignCommandMenuItem } from "./ToolbarTextAlignCommandMenuItem";
import { TextAlignments } from "../../../utils/types/types";
import { textAlignIcons } from "../../../utils/constants/icons";

export const ToolbarTextAlignCommandSelector: FC = () => {
  const { editor } = useCurrentEditor();

  let value = "left";

  textAlignments.forEach((alignment) => {
    if (editor?.isActive({ textAlign: alignment })) {
      value = alignment;
    }
  });

  return (
    <Select
      renderValue={renderValue}
      value={value}
      sx={selectStyles}
      MenuProps={menuProps}
    >
      {textAlignments.map((textAlign) => (
        <ToolbarTextAlignCommandMenuItem
          key={textAlign}
          disableWhenActive
          textAlign={textAlign}
        />
      ))}
    </Select>
  );
};

const textAlignments: TextAlignments[] = ["left", "center", "right", "justify"];

const renderValue = (value: string) => {
  const Icon = textAlignIcons[value as TextAlignments];

  return (
    <Stack alignItems="center" justifyContent="center">
      <Icon fontSize="small" sx={{ color: "grey.600" }} />
    </Stack>
  );
};

const selectStyles = { height: 28, width: 60, border: "none" };

const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
};
