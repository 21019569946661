import { FC, memo, useMemo } from "react";
import { Stack, FormHelperText, Typography, Box } from "@mui/material";
import {
  ProviderType,
  useGetProvidersByProviderRegionsQuery,
} from "../../../../../../../../services/cloudchipr.api";
import { AutocompleteSelect } from "../../../../../../../common/select/autocomplete-select/AutocompleteSelect";
import { DropdownSelectOption } from "../../../../../../../common/select/dropdown-select/utils/types/types";
import { CloudProviderType } from "../../../../../../../common/types/types";

interface SchedulesRegionsProps {
  selectedRegions: (string | null)[];
  provider: ProviderType;
  error?: string | string[];
  disabled?: boolean;
  onChange(regions: string[]): void;
}

export const OffHoursRegions: FC<SchedulesRegionsProps> = memo(
  ({ selectedRegions, error, provider, onChange, disabled }) => {
    const { data: regionsData } = useGetProvidersByProviderRegionsQuery({
      provider: provider as CloudProviderType,
    });

    const options = useMemo(() => {
      return (
        regionsData?.map((region) => ({
          value: region.key,
          rawValue: region,
          label: (
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography variant="body2">{region.name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {region.key}
              </Typography>
            </Stack>
          ),
        })) ?? []
      );
    }, [regionsData]);

    if (!regionsData) {
      return null;
    }

    return (
      <Box>
        <AutocompleteSelect
          disabled={disabled}
          options={options}
          filterFn={filterFn}
          onChange={onChange}
          label="Select Region/s*"
          allSelectedLabel="Regions"
          values={selectedRegions as string[]}
          wrapperVariant="popover"
          PopoverProps={{
            extendAnchorWidth: true,
          }}
        />

        {error && <FormHelperText error>{error}</FormHelperText>}
      </Box>
    );
  },
);

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  keyword = keyword.trim().toLowerCase();

  if (!option.rawValue) {
    return false;
  }

  const aim = `${option.rawValue.key} ${option.rawValue.name}`.toLowerCase();

  return aim.includes(keyword);
};
