import { FC, useState } from "react";
import { useDidMount, useEffectOnceWhen } from "rooks";
import { InternalResourceExplorerCard } from "./InternalResourceExplorerCard";
import {
  ResourceExplorer,
  ResourceExplorerWidget,
} from "../../../../services/cloudchipr.api";
import { getInternalResourceExplorerFilterSchema } from "../utils/helpers/helpers";
import { setGlobalVarReadyWithTimeout } from "../../common/utils/helpers/setGlobalVarReadyWithTimeout";
import { usePostResourceExplorerDataFromAccountServiceMutation } from "../../../../services/cloudchipr-account-service.api";

interface InternalResourceExplorerCardWrapperProps {
  view: ResourceExplorer;
  organisationId: string;
}

export const InternalResourceExplorerCardWrapper: FC<
  InternalResourceExplorerCardWrapperProps
> = ({ organisationId, view }) => {
  const [getResourceExplorerData, { isLoading, isError }] =
    usePostResourceExplorerDataFromAccountServiceMutation();
  const [viewData, setViewData] = useState<ResourceExplorerWidget>();

  useDidMount(async () => {
    try {
      const res = await getResourceExplorerData({
        organisationId,
        resourceExplorerFilterSchema:
          getInternalResourceExplorerFilterSchema(view),
      }).unwrap();

      setViewData(res);
    } catch (e) {
      console.error(e);
    }
  });

  useEffectOnceWhen(() => {
    setGlobalVarReadyWithTimeout();
  }, isError);

  if (isLoading && !viewData) {
    return;
  }

  return (
    <InternalResourceExplorerCard
      view={view}
      viewData={viewData}
      organisationId={organisationId}
    />
  );
};
