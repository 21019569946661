import { coverageWidgetAggregationSelector } from "./coverageWidgetAggregationSelector";
import { RootState } from "../../../../../store";
import { WidgetCoverageAggregation } from "../../../../../../services/cloudchipr.api";

export const coverageWidgetHasAggregationSelector = (
  state: RootState,
  widgetId: string,
) => {
  const aggregations = coverageWidgetAggregationSelector(state, widgetId);

  return Object.keys(aggregations ?? {}).some(
    (key) => !!aggregations?.[key as keyof WidgetCoverageAggregation],
  );
};
