import { FC, useCallback } from "react";
import { Drawer, Grid, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useFlag } from "@unleash/proxy-client-react";
import { BillingSummaryCard } from "./cards/BillingSummaryCard";
import { BillingExplorerWidgetCard } from "./cards/BillingExplorerWidgetCard";
import { CostAnomaliesCard } from "./cards/CostAnomaliesCard";
import { CommitmentUtilizationWidgetCard } from "./cards/CommitmentUtilizationWidgetCard";
import { TextWidgetCard } from "./cards/TextWidgetCard";
import { CoverageWidgetCard } from "./cards/CoverageWidgetCard";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { widgetSelectorStateSelector } from "../../../../../../store/dashboards/selectors/dashboard/widgetSelectorStateSelector";
import { setWidgetSelectorState } from "../../../../../../store/dashboards/dashboardsSlice";

export const WidgetSelectDrawer: FC = () => {
  const dispatch = useAppDispatch();
  const widgetSelectorOpen = useAppSelector(widgetSelectorStateSelector);

  const enableCoverageWidget = useFlag("EnableCoverageWidget");

  const closeWidgetSelector = useCallback(() => {
    dispatch(setWidgetSelectorState(false));
  }, [dispatch]);

  return (
    <Drawer
      anchor="right"
      open={widgetSelectorOpen}
      onClose={closeWidgetSelector}
    >
      <Stack width={736} px={2} pb={2}>
        <Stack
          py={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          top={0}
          bgcolor="white"
          zIndex="appBar"
          position="sticky"
        >
          <Typography variant="subtitle1" fontWeight="medium">
            Add Widget
          </Typography>

          <IconButton onClick={closeWidgetSelector} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>

        <Grid container spacing={2}>
          <Grid item sm={6}>
            <BillingSummaryCard />
          </Grid>

          <Grid item sm={6}>
            <BillingExplorerWidgetCard />
          </Grid>

          <Grid item sm={6}>
            <CostAnomaliesCard />
          </Grid>

          <Grid item sm={6}>
            <TextWidgetCard />
          </Grid>

          <Grid item sm={6}>
            <CommitmentUtilizationWidgetCard />
          </Grid>

          {enableCoverageWidget && (
            <Grid item sm={6}>
              <CoverageWidgetCard />
            </Grid>
          )}
        </Grid>
      </Stack>
    </Drawer>
  );
};
