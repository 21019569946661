import { FC } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import { SavingsOpportunityImplementationEffort } from "../SavingsOpportunityImplementationEffort";

export const SavingsOpportunityImplementationEffortCellRenderer: FC<
  CustomCellRendererProps<SavingsOpportunity>
> = (cell) => {
  const data = cell.data as SavingsOpportunity;

  if (!data) {
    return null;
  }

  return (
    <SavingsOpportunityImplementationEffort
      effort={data.implementation_effort}
    />
  );
};
