import { DimensionFiltersCount } from "./DimensionFiltersCount";
import { DimensionActions } from "./actions/DimensionActions";
import { dimensionsNameColumnFilter } from "./helpers/filterFunctions/dimensionsNameColumnFilter";
import { ColumnSetupType } from "../../../../../../storybook/data-grid/utils/types/types";
import { DimensionWithCosts } from "../../../../../../services/cloudchipr.api";
import { getExpanderCell } from "../../../../../../storybook/data-grid/common/getExpanderCell";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";

export const dimensionsDataGridColumns: ColumnSetupType<DimensionWithCosts>[] =
  [
    getExpanderCell<DimensionWithCosts>(),
    {
      size: 400,
      minSize: 400,
      maxSize: 400,
      accessorKey: "name",
      header: "Name",
      cell: (cell) => {
        return (
          <TypographyWithTooltip
            title={cell.getValue() as string}
            variant="subtitle1"
            fontWeight="medium"
            color="text.secondary"
          />
        );
      },
      filterFn: dimensionsNameColumnFilter,
    },

    {
      id: "filters",
      size: 350,
      minSize: 350,
      maxSize: 350,
      header: "Filters",
      enableSorting: false,
      cell: DimensionFiltersCount,
    },

    {
      id: "action",
      size: 40,
      minSize: 40,
      maxSize: 40,
      enableSorting: false,
      cell: DimensionActions,
    },
  ];
