import { FC, useCallback } from "react";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch } from "../../../../store/hooks";
import { toggleReportsOpenDrawer } from "../../../../store/reports/reportsSlice";
import { useAppAbility } from "../../../../services/permissions";
import { getUserPermissionInfoText } from "../../utils/helpers/getUserPermissionInfoText";

interface CreateReportButtonProps {
  icon?: boolean;
  applyOnClick?(): void;
}

export const CreateReportButton: FC<CreateReportButtonProps> = ({
  icon = true,
  applyOnClick,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const cannotCreateReport = cannot("create", "report");

  const toggleOpenDrawer = useCallback(() => {
    applyOnClick?.();
    dispatch(toggleReportsOpenDrawer());
  }, [dispatch, applyOnClick]);

  const userPermissionTooltipText = cannotCreateReport
    ? getUserPermissionInfoText("Subscribe")
    : "";

  return (
    <Tooltip title={userPermissionTooltipText}>
      <span>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleOpenDrawer}
          disabled={cannotCreateReport}
          sx={{ textTransform: "none" }}
          startIcon={
            icon ? <AddIcon sx={{ mr: 1 }} fontSize="small" /> : undefined
          }
        >
          Subscribe
        </Button>
      </span>
    </Tooltip>
  );
};
