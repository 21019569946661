import { FC, useCallback, useMemo, useRef, useState } from "react";
import {
  ColDef,
  ColumnRowGroupChangedEvent,
  GetMainMenuItemsParams,
  SelectionChangedEvent,
  SortChangedEvent,
} from "ag-grid-community";
import { RowSelectionOptions } from "ag-grid-community/dist/types/src/entities/gridOptions";
import { getTasksGridColumns } from "./tasksGridColumns";
import {
  autoGroupColumnId,
  getAutoGroupColumnDef,
} from "./auto-group-column/getAutoGroupColumnDef";
import { Task } from "../../../../../services/cloudchipr.api";
import { useTaskManagementContext } from "../TaskManagementProvider";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { taskAvailablePropertiesSelector } from "../../../../../store/task-management/selectors/properties/taskAvailablePropertiesSelector";
import { setTaskManagementSelectedTasks } from "../../../../../store/task-management/taskManagementSlice";
import { AgDataGrid } from "../../../common/ag-grid/AgDataGrid";
import { useTasksViewsHierarchySaveHook } from "../../utils/hooks/useTasksViewsHierarchySave.hook";
import { tasksCurrentViewConfigSelector } from "../../../../../store/task-management/selectors/views-hierarchy/current-view/tasksCurrentViewConfigSelector";
import { tasksCurrentViewSelector } from "../../../../../store/task-management/selectors/views-hierarchy/current-view/tasksCurrentViewSelector";

interface TasksGridProps {
  tasks: Task[];
}

const rowHeight = 53;

export const TasksGrid: FC<TasksGridProps> = ({ tasks }) => {
  const advancedFilterParentRef = useRef<HTMLDivElement | null>(null);
  const { gridApi, setGridApi } = useTaskManagementContext();
  const dispatch = useAppDispatch();
  const [rowGroupCountReached, setRowGroupCountReached] = useState(false);

  const viewId = useAppSelector(tasksCurrentViewSelector)?.id;
  const config = useAppSelector(tasksCurrentViewConfigSelector);

  const taskAvailableProperties = useAppSelector(
    taskAvailablePropertiesSelector,
  );

  const isDefaultView = !viewId;

  const columnDefs = useMemo(() => {
    return getTasksGridColumns(taskAvailableProperties);
  }, [taskAvailableProperties]);

  const { onStateDebouncedUpdate } = useTasksViewsHierarchySaveHook(gridApi);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      minWidth: 150,
      unSortIcon: true,
      enableRowGroup: !rowGroupCountReached,
      filter: "agMultiColumnFilter",
    };
  }, [rowGroupCountReached]);

  const configs = useMemo(() => {
    try {
      return JSON.parse(config?.gridConfig ?? "{}");
    } catch (e) {
      return;
    }
  }, [config?.gridConfig]);

  const autoGroupColumnDef = useMemo(
    () => getAutoGroupColumnDef(taskAvailableProperties),
    [taskAvailableProperties],
  );

  const onColumnRowGroupChanged = useCallback(
    (params: ColumnRowGroupChangedEvent) => {
      const rowGroupColumns = params.api.getRowGroupColumns();

      const colId = params.column?.getColId();
      if (colId) {
        params.api.setColumnsPinned([colId], false);
      }

      setRowGroupCountReached(rowGroupColumns?.length > 2);
    },
    [],
  );

  const getMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams) => {
      if (rowGroupCountReached) {
        return params.defaultItems.filter((item) => item !== "rowGroup");
      }

      return params.defaultItems;
    },
    [rowGroupCountReached],
  );

  const sortChangeHandler = useCallback(({ api }: SortChangedEvent) => {
    const sortState = api
      .getColumnState()
      .filter(({ sort }) => sort !== null)
      .map((s) => ({ colId: s.colId, sort: s.sort, sortIndex: s.sortIndex }));

    const sortedByGrouping = sortState.some(
      ({ colId }) => colId === autoGroupColumnId,
    );

    if (!sortedByGrouping) {
      sortState.push({
        colId: autoGroupColumnId,
        sort: "asc",
        sortIndex: sortState.length,
      });
    }

    api.applyColumnState({ state: sortState });
  }, []);

  const onSelectionChanged = useCallback(
    ({ api }: SelectionChangedEvent) => {
      dispatch(setTaskManagementSelectedTasks(api.getSelectedRows()));
    },
    [dispatch],
  );

  if (!tasks.length) {
    return;
  }

  return (
    <AgDataGrid
      key={`${viewId} ${tasks.length}`}
      rowData={tasks}
      headerHeight={60}
      rowHeight={rowHeight}
      columnDefs={columnDefs}
      rowGroupPanelShow="always"
      gridApiSetter={setGridApi}
      enableAdvancedFilter={false}
      wrapperStyles={wrapperStyles}
      defaultColDef={defaultColDef}
      onSortChanged={sortChangeHandler}
      getMainMenuItems={getMainMenuItems}
      onStateUpdated={onStateDebouncedUpdate}
      autoGroupColumnDef={autoGroupColumnDef}
      initialState={isDefaultView ? undefined : configs}
      onColumnRowGroupChanged={onColumnRowGroupChanged}
      advancedFilterParent={advancedFilterParentRef.current}
      configToRememberStateInStorage={
        isDefaultView ? configToRememberStateInStorage : undefined
      }
      // row selection
      onSelectionChanged={onSelectionChanged}
      rowSelection={rowSelection}
    />
  );
};

const rowSelection: RowSelectionOptions = {
  mode: "multiRow",
  selectAll: "filtered",
  checkboxLocation: "autoGroupColumn",
  groupSelects: "filteredDescendants",
  isRowSelectable: (row) => !row.group,
};

const wrapperStyles = {
  height: "96%",
  ".ag-cell-focus": { border: "none !important" },
  ".ag-cell-label-container": { paddingBottom: "6px" },
};

const configToRememberStateInStorage = {
  uniqueNameAsKey: "task-management-all-tasks-view",
};
