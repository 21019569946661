import { billingExplorerWidgetSetupTotalDataWithIdsSelector } from "./billingExplorerWidgetSetupTotalDataWithIdsSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridDataWithId } from "../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { formatBillingExplorerWidgetTableViewLastGridItem } from "../utils/helpers/formatBillingExplorerWidgetTableViewLastGridItem";

export const billingExplorerWidgetSetupNoChartViewDataSelector = (
  state: RootState,
): ResourceExplorerGridDataWithId[] => {
  const data = billingExplorerWidgetSetupTotalDataWithIdsSelector(state);
  if (!data) {
    return [];
  }

  const lastItems = data.slice(itemsCount, data?.length);
  const startItems = data.slice(0, itemsCount);

  const lastItem = formatBillingExplorerWidgetTableViewLastGridItem(lastItems);

  const completeData = [...startItems];

  if (lastItems.length) {
    completeData.push(lastItem);
  }

  return completeData;
};

const itemsCount = 9;
