import { resourceDetailsResourceFirstProcessKeySelector } from "./resourceDetailsResourceFirstProcessKeySelector";
import { resourceDetailsResourceBillingDataSelector } from "../resourceDetailsResourceBillingDataSelector";
import { RootState } from "../../../../store";

export const resourceDetailsResourceBillingDataTotalCostSelector = (
  state: RootState,
) => {
  const firstProcessKey = resourceDetailsResourceFirstProcessKeySelector(state);

  const totalCost = resourceDetailsResourceBillingDataSelector(
    state,
    firstProcessKey,
  )?.total_cost;

  return totalCost ? +totalCost.toFixed(2) : 0;
};
