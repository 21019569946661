import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { ChartDataType } from "../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { resourceExplorerWidgetDataSelector } from "../resource-explorer-widget/resourceExplorerWidgetDataSelector";
import { resourceExplorerDataPointSelector } from "../data/resourceExplorerDataPointSelector";
import { formatResourceExplorerChartData } from "../../../utils/helpers/formatResourceExplorerChartData";
import { organisationQuarterStartMonthSelector } from "../../../../common/selectors/org-current-settings/organisationQuarterStartMonthSelector";

export const resourceExplorerChartDataSelector = createDraftSafeSelector(
  [
    resourceExplorerWidgetDataSelector,
    resourceExplorerDataPointSelector,
    organisationQuarterStartMonthSelector,
  ],
  (widgetData, dataPoint, quarterStartMonth): ChartDataType[] | null => {
    const data = widgetData?.data;
    if (!data) {
      return null;
    }

    return formatResourceExplorerChartData(data, dataPoint, quarterStartMonth);
  },
);

export const resourceExplorerChartDataOthersKey = "Others";
