import { FC, useCallback, useMemo, useState } from "react";
import { FormHelperText, Typography } from "@mui/material";
import { SlackConversationsDropdownTrigger } from "./SlackConversationsDropdownTrigger";
import { SlackConversationsDropdownFooter } from "./SlackConversationsDropdownFooter";
import { SlackConversationsSelectDropdownHeader } from "./SlackConversationsSelectDropdownHeader";
import {
  SlackConversation,
  useGetUsersMeByTypeIntegrationsAndIntegrationIdQuery,
} from "../../../../../services/cloudchipr.api";
import { slackSelectionOptionsFilter } from "../../../../pages/integrations/components/integrations-create/components/slack/utils/helpers/helpers";
import { DropdownSelect } from "../../../select/dropdown-select/DropdownSelect";
import { SlackConversationLabel } from "../../../../pages/integrations/components/integrations-create/components/slack/components/SlackConversationLabel";
import { DropdownHeaderComponent } from "../../../select/dropdown-select/utils/types/types";

interface SlackConversationsSelectProps {
  integrationId: string;
  error?: string;
  disabled?: boolean;
  selectedConversations?: SlackConversation[] | null;
  onChange(conversations: SlackConversation[]): void;
}

export const SlackConversationsSelect: FC<SlackConversationsSelectProps> = ({
  integrationId,
  selectedConversations,
  error,
  onChange,
  disabled,
}) => {
  const [forceToClose, setForceToClose] = useState(false);

  const { data, isLoading, isFetching, refetch } =
    useGetUsersMeByTypeIntegrationsAndIntegrationIdQuery(
      {
        type: "slack",
        integrationId: integrationId,
      },
      { skip: !integrationId },
    );

  const value = useMemo(() => {
    const isCorrectOptions = selectedConversations?.every((conversation) =>
      data?.conversations?.some(({ id }) => id === conversation.id),
    );

    if (isCorrectOptions) {
      return selectedConversations?.map(({ id }) => id) ?? [];
    }

    return [];
  }, [selectedConversations, data]);

  const dropdownOptions = useMemo(() => {
    return (
      data?.conversations?.map((item) => {
        return {
          value: item.id,
          rawValue: item,
          label: <SlackConversationLabel type={item.type} name={item.name} />,
        };
      }) ?? []
    );
  }, [data]);

  const changeHandler = useCallback(
    (ids: string[]) => {
      const selectedConversationsById = data?.conversations?.filter(
        (conversation) => ids.includes(conversation.id),
      );

      selectedConversationsById && onChange(selectedConversationsById);
    },
    [onChange, data?.conversations],
  );

  const closeHandler = useCallback(() => {
    setForceToClose(true);

    setTimeout(() => {
      setForceToClose(false);
    }, 0);
  }, []);

  const FooterComponent = useCallback(() => {
    return (
      <SlackConversationsDropdownFooter
        integrationId={integrationId}
        onClose={closeHandler}
      />
    );
  }, [integrationId, closeHandler]);

  const noItemsLabel = useCallback(
    (keyword: string) => (
      <Typography align="center" variant="subtitle2" color="text.secondary">
        No conversations found for “{keyword}”
      </Typography>
    ),
    [],
  );

  const DropdownHeader: DropdownHeaderComponent = useCallback(
    ({ onSearchChange }) => {
      return (
        <SlackConversationsSelectDropdownHeader
          onSearchChange={onSearchChange}
          onRefresh={refetch}
        />
      );
    },
    [refetch],
  );

  return (
    <div>
      <DropdownSelect
        forceToClose={forceToClose}
        values={value}
        error={error}
        disabled={disabled}
        showSelectAll={false}
        wrapperVariant="popover"
        DropdownFooter={FooterComponent}
        options={dropdownOptions}
        optionsLoading={isLoading || isFetching}
        noItemsLabel={noItemsLabel}
        label="Select Conversations"
        showSearch={false}
        PopoverProps={{
          extendAnchorWidth: true,
        }}
        submitHandlerOnClose={changeHandler}
        filterFn={slackSelectionOptionsFilter}
        TriggerComponent={SlackConversationsDropdownTrigger}
        DropdownHeader={DropdownHeader}
      />

      {error && !(isLoading || isFetching) && (
        <FormHelperText error>{error}</FormHelperText>
      )}
    </div>
  );
};
