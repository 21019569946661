import React, { FC, Fragment, useCallback } from "react";
import { IconButton } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import { TaskDeleteActionMenuDialog } from "../../grid/cell-renderers/row-actions/delete-action/TaskDeleteActionMenuDialog";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { tasksListGetLoadingSelector } from "../../../../../../store/task-management/selectors/loading/tasksListGetLoadingSelector";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { selectedTasksSelector } from "../../../../../../store/task-management/selectors/selection/selectedTasksSelector";
import {
  floatingToolbarDarkActive,
  floatingToolbarDarkHover,
  floatingToolbarDisabled,
  floatingToolbarLight,
} from "../utils/constants/colors";
import { useAppAbility } from "../../../../../../services/permissions";
import { setTaskManagementSelectedTasks } from "../../../../../../store/task-management/taskManagementSlice";

export const FloatingDeleteAction: FC = () => {
  const dispatch = useAppDispatch();
  const tasksLoading = useAppSelector(tasksListGetLoadingSelector);
  const selectedTasks = useAppSelector(selectedTasksSelector);
  const { open, openDialog, closeDialog } = useDialog();
  const { can } = useAppAbility();
  const canEditTask = can("edit", "task");

  const closeHandler = useCallback(() => {
    closeDialog();
    dispatch(setTaskManagementSelectedTasks([]));
  }, [dispatch, closeDialog]);

  return (
    <Fragment>
      <IconButton
        size="small"
        sx={iconButtonStyles}
        onClick={openDialog}
        disabled={tasksLoading || !canEditTask}
      >
        <DeleteOutlined
          fontSize="small"
          sx={{
            color: !canEditTask
              ? floatingToolbarDisabled
              : floatingToolbarLight,
          }}
        />
      </IconButton>

      <TaskDeleteActionMenuDialog
        open={open}
        onClose={closeHandler}
        onDialogClose={closeDialog}
        tasks={selectedTasks}
      />
    </Fragment>
  );
};

const iconButtonStyles = {
  borderRadius: 1,
  "&:hover": {
    backgroundColor: floatingToolbarDarkHover,
  },
  "&:active": {
    backgroundColor: floatingToolbarDarkActive,
  },
};
