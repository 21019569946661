import { ColumnSetupType } from "../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { KubernetesReplicaSet } from "../../../../../../../../../../../../../services/cloudchipr.api";

export const kubernetesReplicaSetsColumns: ColumnSetupType<KubernetesReplicaSet>[] =
  [
    {
      accessorKey: "name",
      header: "ReplicaSet Name",
      size: 280,
      type: "identifier",
      meta: { sticky: "left", hideFromSettings: true },
    },
    {
      accessorKey: "total_monthly_cost",
      header: "Monthly Price",
      type: "money",
      headerTooltip:
        "Monthly prices are calculated based on the on-demand list price of each resource.",
    },
    {
      accessorKey: "cluster",
      header: "Cluster",
      type: "cluster",
    },
    {
      accessorKey: "namespace",
      header: "Namespace",
      type: "withTooltipCell",
    },
    {
      accessorKey: "cpu_monthly_cost",
      header: "CPU cost",
      type: "money",
    },
    {
      accessorKey: "labels",
      header: "Kubernetes Label",
      type: "tag",
      enableSorting: false,
    },
  ];
