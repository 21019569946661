import { azureOverviewDataSelector } from "./azureOverviewDataSelector";
import { RootState } from "../../../../store";

export const azureOverviewSavingsPlanTotalCommitmentsSelector = (
  state: RootState,
): number | null => {
  const data = azureOverviewDataSelector(state);

  return data?.savings_plan_total_commitments ?? null;
};
