import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { DrawerActions, DrawerActionsProps } from "./DrawerActions";
import { WarningCardHeader } from "./WarningCardHeader";

export const DrawerWarningCard: FC<DrawerActionsProps> = ({
  provider,
  accountId,
}) => {
  return (
    <Box p={2}>
      <Stack bgcolor="grey.50" p={2} borderRadius={4} spacing={1}>
        <WarningCardHeader
          accountId={accountId}
          provider={provider}
          title={titles[provider]}
        />

        <Typography variant="body2">
          To access the complete saving opportunities, please enable rightsizing
          recommendations.
        </Typography>

        <DrawerActions provider={provider} accountId={accountId} />
      </Stack>
    </Box>
  );
};

const titles = {
  aws: "Enable EC2 Resource Recommendations",
  gcp: "Enable GCP Recommender API",
  azure: "",
  kubernetes: "",
};
