import { FC, useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { AccountCard } from "../account-card/AccountCard";
import { AccountInfoDrawer } from "../AccountInfoDrawer";
import {
  Account,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";
import { CloudAccountDragPreview } from "../account-card/CloudAccountDragPreview";

interface AccountsProps {
  accounts: Account[] | null;
  provider: ProviderType;
  onSortingChange: (data: Account[], drop?: boolean) => void;
}

export const AccountsGridView: FC<AccountsProps> = ({
  accounts,
  provider,
  onSortingChange,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [data, setData] = useState(accounts);

  const cardMoveHandler = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) => {
      setData((data) => {
        if (!data) {
          return data;
        }

        const dndData = [...data];
        const firstItem = dndData.splice(draggedRowIndex, 1).at(0);
        if (firstItem) {
          dndData.splice(targetRowIndex, 0, firstItem);
        }

        return dndData;
      });
    },
    [],
  );

  const cardDropHandler = useCallback(
    (draggedRowIndex: number, targetRowIndex: number, drop: boolean) => {
      let fetchingData: Account[] | null = null;

      setData((data) => {
        if (!data) {
          return data;
        }

        const dndData = [...data];

        const firstItem = dndData.splice(draggedRowIndex, 1).at(0);
        if (firstItem) {
          dndData.splice(targetRowIndex, 0, firstItem);
        }

        fetchingData = dndData;
        return dndData;
      });

      fetchingData && onSortingChange(fetchingData, drop);
    },
    [onSortingChange],
  );

  useEffect(() => {
    if (!accounts?.length) {
      return;
    }

    setData(accounts);
  }, [accounts]);

  if (!accounts?.length) {
    return null;
  }

  return (
    <Grid
      container
      spacing={1}
      mt={0}
      pb={1}
      ml={-1}
      columns={{ xs: 2, lg: 3, xl: 4, xxl: 5 }}
    >
      {data?.map((account: Account, index: number) => {
        return (
          <Grid item xs={1} key={account.id}>
            <AccountCard
              {...account}
              disableDnD={data?.length === 1}
              index={index}
              onCardMove={cardMoveHandler}
              onCardDrop={cardDropHandler}
              changeAccountInfoDrawerState={setIsDrawerOpen}
            />
          </Grid>
        );
      })}
      <CloudAccountDragPreview accounts={accounts} />
      <AccountInfoDrawer
        open={isDrawerOpen}
        onChangeDrawerState={setIsDrawerOpen}
        provider={provider}
      />
    </Grid>
  );
};
