import { tasksViewsHierarchySelector } from "./tasksViewsHierarchySelector";
import { RootState } from "../../../store";
import { View } from "../../../../services/cloudchipr.api";

export const tasksViewByIdSelector = (
  state: RootState,
  viewId: string,
): View | undefined => {
  const views = tasksViewsHierarchySelector(state);

  return views?.find((view) => view.id === viewId);
};
