import { resourceExplorerPossibleGroupingsFormattedDataSelector } from "./resourceExplorerPossibleGroupingsFormattedDataSelector";
import { RootState } from "../../../store";
import { ResourceExplorerGrouping } from "../../../../services/cloudchipr.api";

export const resourceExplorerPossibleGroupingKeysSelector = (
  state: RootState,
): ResourceExplorerGrouping[] | undefined => {
  const formattedData =
    resourceExplorerPossibleGroupingsFormattedDataSelector(state);
  if (formattedData?.groupingProviderMap) {
    return Object.keys(
      formattedData.groupingProviderMap,
    ) as ResourceExplorerGrouping[];
  }
};
