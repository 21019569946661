import { RootState } from "../../../../store";
import { DashboardWidgetCostAndUsageSummary } from "../../../../../services/cloudchipr.api";
import { widgetByIdSelector } from "../common/widgetByIdSelector";

export const billingSummaryWidgetByIdSelector = (
  state: RootState,
  widgetId?: string,
): DashboardWidgetCostAndUsageSummary | undefined => {
  if (!widgetId) {
    return;
  }

  return widgetByIdSelector(
    state,
    widgetId,
  ) as DashboardWidgetCostAndUsageSummary;
};
