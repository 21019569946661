import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { TotalCostColumnHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/TotalCostColumnHeaderCell";
import { billingExplorerWidgetTotalCostSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetTotalCostSelector";

interface BillingExplorerWidgetTableTotalCostHeaderCellProps {
  widgetId: string;
}
export const BillingExplorerWidgetTableTotalCostHeaderCell: FC<
  BillingExplorerWidgetTableTotalCostHeaderCellProps
> = ({ widgetId }) => {
  const total = useAppSelector((state) =>
    billingExplorerWidgetTotalCostSelector(state, widgetId),
  );

  return (
    <TotalCostColumnHeaderCell
      totalCost={total?.cost ?? 0}
      dateDetails={total?.dateDetails}
    />
  );
};
