import { FC, useCallback, useMemo } from "react";
import { useDidMount } from "rooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { setReportFilters } from "../../../../../../../../store/reports/reportsSlice";
import { DropdownSelect } from "../../../../../../../common/select/dropdown-select/DropdownSelect";
import { reportsCreatedByFilterValuesSelector } from "../../../../../../../../store/reports/selector/reportsCreatedByFilterValuesSelector";
import { getUsersLoadingSelector } from "../../../../../../../../store/user/selectors/getUsersLoadingSelector";
import { activeUsersNameEmailSelector } from "../../../../../../../../store/user/selectors/activeUsersNameEmailSelector";
import { DropdownSelectOption } from "../../../../../../../common/select/dropdown-select/utils/types/types";
import { getOrganisationUsersThunk } from "../../../../../../../../store/user/thunks/getOrganisationUsersThunk";

export const ReportsCreatedByFilter: FC = () => {
  const dispatch = useAppDispatch();
  const initialCreatedBys = useAppSelector(
    reportsCreatedByFilterValuesSelector,
  );
  const loading = useAppSelector(getUsersLoadingSelector);
  const createdByOptions = useAppSelector(activeUsersNameEmailSelector);

  const options = useMemo(
    () =>
      createdByOptions?.map((createdBy) => ({
        value: createdBy,
        hideOnly: true,
        label: createdBy,
      })),
    [createdByOptions],
  );

  const optionsCount = options?.length;

  const changeHandler = useCallback(
    (newFilters: string[]) => {
      dispatch(
        setReportFilters({
          key: "created_by",
          values: newFilters.length === optionsCount ? [] : newFilters,
        }),
      );
    },
    [dispatch, optionsCount],
  );

  useDidMount(() => {
    dispatch(getOrganisationUsersThunk());
  });

  if (!options && !loading) {
    return;
  }

  return (
    <DropdownSelect
      initialSelectedValues={initialCreatedBys}
      label="Created By"
      options={(options ?? []) as DropdownSelectOption[]}
      disabled={loading}
      submitHandlerOnClose={changeHandler}
    />
  );
};
