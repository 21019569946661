import { FC, Fragment } from "react";
import { Button, PopoverOrigin, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { DashboardNavigationAddActionMenu } from "../../../../../app-navigation/components/page-components/dashboard/components/DashboardNavigationAddActionMenu";
import { useAppAbility } from "../../../../../../services/permissions";
import { getUserPermissionInfoText } from "../../../../utils/helpers/getUserPermissionInfoText";

interface DashboardAllItemsHeaderActionsProps {
  loading?: boolean;
}

export const DashboardAllItemsHeaderActions: FC<
  DashboardAllItemsHeaderActionsProps
> = ({ loading }) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();
  const { cannot } = useAppAbility();
  const cannotCreateDashboard = cannot("create", "dashboard");

  const userPermissionTooltipText = cannotCreateDashboard
    ? getUserPermissionInfoText("Add Dashboards")
    : "";

  return (
    <Fragment>
      <Tooltip title={userPermissionTooltipText}>
        <span>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={openMenu}
            disabled={loading || cannotCreateDashboard}
            sx={{ textTransform: "none" }}
          >
            Add
          </Button>
        </span>
      </Tooltip>

      <DashboardNavigationAddActionMenu
        open={open}
        anchorEl={anchor}
        onCloseMenu={closeMenu}
        anchorOrigin={anchorOrigin}
        redirectAfterCreate={false}
        transformOrigin={transformOrigin}
      />
    </Fragment>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
