import { FC, useCallback } from "react";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { azureReservationDetailsGranularDataGroupedByDateSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/details-drawer/azureReservationDetailsGranularDataGroupedByDateSelector";
import { commitmentsDetailsDrawerDateGranularitySelector } from "../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerDateGranularitySelector";
import { azureReservationDetailsLoadingSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/details-drawer/data/azureReservationDetailsLoadingSelector";
import { formatDateByFrequencyType } from "../../../../../../../../utils/helpers/date-time/datetime-format";
import { CommitmentsChart } from "../../../../../common/components/chart/CommitmentsChart";

interface AzureReservedInstanceDrawerChartProps {
  ChartHeader?: FC;
}

export const AzureReservedInstanceDrawerChart: FC<
  AzureReservedInstanceDrawerChartProps
> = ({ ChartHeader }) => {
  const dataGroupedByDate = useAppSelector(
    azureReservationDetailsGranularDataGroupedByDateSelector,
  );
  const dataPoint = useAppSelector(
    commitmentsDetailsDrawerDateGranularitySelector,
  );
  const loading = useAppSelector(azureReservationDetailsLoadingSelector);

  const labelFormatter = useCallback(
    (value: string) => formatDateByFrequencyType(dataPoint, value),
    [dataPoint],
  );

  return (
    <CommitmentsChart
      loading={loading}
      availableTypes={["area"]}
      labelFormatter={labelFormatter}
      dataGroupedByDate={dataGroupedByDate}
      ChartHeader={ChartHeader}
    />
  );
};
