import { FC, useCallback } from "react";
import { SwitchWithTypography } from "../../../../../../../../../../SwitchWithTypography";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";
import { billingExplorerViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerViewVisualizationChangeThunk";

export const BillingExplorerWidgetVisualizationAccountColumn: FC = () => {
  const dispatch = useAppDispatch();
  const accountColumnChecked = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("account_column"),
  );

  const handleAccountColumnCheckedChange = useCallback(
    (checked: boolean) => {
      dispatch(
        billingExplorerViewVisualizationChangeThunk({
          account_column: checked,
        }),
      );
    },
    [dispatch],
  );

  return (
    <SwitchWithTypography
      checked={accountColumnChecked}
      onChange={handleAccountColumnCheckedChange}
      title="Account"
    />
  );
};
