import { FC } from "react";
import { Card, Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { WidgetSetupContentWrapper } from "../../common/WidgetSetupContentWrapper";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";

import { Editor } from "../../../../../../../../../storybook/editor/Editor";
import { textWidgetSetupSelector } from "../../../../../../../../../store/dashboards/selectors/setups/text/textWidgetSetupSelector";

export const TextWidgetSetupContent: FC = () => {
  const setup = useAppSelector(textWidgetSetupSelector);
  const { text, name } = setup ?? {};

  return (
    <WidgetSetupContentWrapper>
      <Card
        elevation={3}
        sx={{
          height: widgetSetupContentChartHeight,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack
          pl={2}
          pr={0}
          py={1}
          position="sticky"
          top={0}
          bgcolor="white"
          zIndex={2}
          borderColor="grey.300"
        >
          <Typography variant="subtitle1" fontWeight="medium">
            {name}
          </Typography>
        </Stack>

        <Stack height="100%" tabIndex={0} p={2} pt={0} overflow={"hidden"}>
          <Editor key={text} content={text} editable={false} />
        </Stack>
      </Card>
    </WidgetSetupContentWrapper>
  );
};
