import { coverageSetupAggregationSelector } from "./coverageSetupAggregationSelector";
import { WidgetCoverageAggregation } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";

export const coverageSetupAggregationPropertyByKeySelector =
  (key: keyof WidgetCoverageAggregation) =>
  (state: RootState): any => {
    const setup = coverageSetupAggregationSelector(state);

    if (!setup) {
      return null;
    }

    return setup[key];
  };
