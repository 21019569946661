import { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { DateDataPoint } from "../../../../../../services/cloudchipr.api";
import { CommitmentsDateGranularitySelector } from "../options/date-granularity/CommitmentsDateGranularitySelector";
import { commitmentsDetailsDrawerDateGranularitySelector } from "../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerDateGranularitySelector";
import { changeCommitmentDrawerDateGranularityThunk } from "../../../../../../store/commitments/thunks/common/changeCommitmentDrawerDateGranularityThunk";

export const CommitmentsDrawerDateGranularity: FC = () => {
  const dispatch = useAppDispatch();

  const dateGranularity = useAppSelector(
    commitmentsDetailsDrawerDateGranularitySelector,
  );

  const changeHandler = useCallback(
    (dg: DateDataPoint) => {
      dispatch(changeCommitmentDrawerDateGranularityThunk(dg));
    },
    [dispatch],
  );

  if (!dateGranularity) {
    return null;
  }

  return (
    <CommitmentsDateGranularitySelector
      size="small"
      onChange={changeHandler}
      dateGranularity={dateGranularity}
    />
  );
};
