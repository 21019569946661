import { FC, useCallback } from "react";
import { TextField } from "@mui/material";
import { FormikHandlers } from "formik";
import { useDebounce } from "rooks";
import { useAppDispatch } from "../../../../../../../../../../store/hooks";
import { setCurrentBudgetData } from "../../../../../../../../../../store/budgets/budgetsSlice";

interface BudgetNameProps {
  name: string;
  handleChange: FormikHandlers["handleChange"];
}

export const BudgetName: FC<BudgetNameProps> = ({ name, handleChange }) => {
  const dispatch = useAppDispatch();

  const dispatchNameHandler = useCallback(
    (name: string) => {
      dispatch(setCurrentBudgetData({ name }));
    },
    [dispatch],
  );

  const dispatchNameDebounced = useDebounce(dispatchNameHandler, 500);

  const changeHandler = useCallback(
    (event: any) => {
      handleChange(event);
      dispatchNameDebounced(event.target.value);
    },
    [dispatchNameDebounced, handleChange],
  );

  return (
    <TextField
      fullWidth
      name="name"
      size="small"
      value={name}
      variant="outlined"
      onChange={changeHandler}
      placeholder="Budget Name"
    />
  );
};
