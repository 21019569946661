import { createAsyncThunk } from "@reduxjs/toolkit";
import { generateKubernetesResourceDetailsTarget } from "./utils/helpers/kubernetes-reosurce-details-drawer-data/generateKubernetesResourceDetailsTarget";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { initiateResourceDetailsDrawerThunk } from "../../../resource-details/thunks/initiateResourceDetailsDrawerThunk";
import { kubernetesLiveUsageMgmtResourceTypeDataResourceByIdSelector } from "../../selectors/resource-type-data/kubernetesLiveUsageMgmtResourceTypeDataResourceByIdSelector";
import { kubernetesLiveUsageMgmtGenerateExportedResourceDataByResourceId } from "../../selectors/resource-type-data/kubernetesLiveUsageMgmtGenerateExportedResourceDataByResourceId";
import { generateAdditionalDetailsForResourceDrawer } from "../../../live-usage-mgmt/utils/helpers/generateAdditionalDetailsForResourceDrawer";

export const setK8SLiveUsageMgmtResourceDetailsDrawerResourceDataThunk =
  createAsyncThunk(
    "liveUsageMgmt/setK8SLiveUsageMgmtResourceDetailsDrawerResourceData",
    async (
      {
        resourceType,
        resourceId,
      }: { resourceType: ResourceType; resourceId: string },
      { dispatch, getState },
    ) => {
      const state = getState() as RootState;

      const resource =
        kubernetesLiveUsageMgmtResourceTypeDataResourceByIdSelector(
          state,
          resourceType,
          resourceId,
        );

      if (!resource?.resource) {
        return;
      }

      const csvData =
        kubernetesLiveUsageMgmtGenerateExportedResourceDataByResourceId(
          state,
          resourceType,
          resourceId,
          ["labels", "ram_monthly_cost", "inline_metrics"],
        )?.at(0) ?? {};

      const details = generateAdditionalDetailsForResourceDrawer(csvData);

      const target = generateKubernetesResourceDetailsTarget(resource);

      dispatch(
        initiateResourceDetailsDrawerThunk({
          availableTabs: ["general"],
          openedFrom: "liveUsage",
          resource: target,
          resourceAdditionalDetails: details,
        }),
      );
    },
  );
