import { FC } from "react";

import { useAppSelector } from "../../../../../../../store/hooks";
import { overviewCommitmentsSelector } from "../../../../../../../store/commitments/selectors/overview/overviewCommitmentsSelector";
import { overviewLoadingSelector } from "../../../../../../../store/commitments/selectors/overview/loading/overviewLoadingSelector";
import { CommitmentsOverviewCommitments } from "../common/CommitmentsOverviewCommitments";

export const AwsCommitmentsOverviewCommitments: FC = () => {
  const commitments = useAppSelector(overviewCommitmentsSelector);
  const loading = useAppSelector(overviewLoadingSelector);

  return (
    <CommitmentsOverviewCommitments data={commitments} loading={loading} />
  );
};
