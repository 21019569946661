import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { BillingExplorerWidgetSetupType } from "../../../../utils/types/setups/billingExplorerSetupType";
import { billingExplorerWidgetByIdSelector } from "../../../../selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetByIdSelector";
import { getBillingExplorerWidgetSetupDataThunk } from "../getBillingExplorerWidgetSetupDataThunk";

interface EditBillingExplorerWidgetArgs {
  widgetId: string;
  doNotSetWidgetType?: boolean;
}

export const editBillingExplorerWidgetThunk = createAsyncThunk(
  "dashboards/editBillingExplorerWidget",
  (
    { widgetId, doNotSetWidgetType }: EditBillingExplorerWidgetArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = billingExplorerWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    dispatch(
      setWidgetSetup({
        widgetType: doNotSetWidgetType ? undefined : "cost_breakdown",
        id: widget.id,
        //TODO: EnableWidgetsSideBarConfigurations, remove viewType
        viewType: widget.chart_type,
        frequency: widget.date_granularity,
        forecastOption: widget.forecast_option,
        name: widget.name,
        dates: widget.dates,
        grouping: widget.grouping,
        group_values: widget.group_values,
        visualization: widget.visualization,
        aggregation: widget.aggregation,
        viewId: widget.view_id,
        trendType: widget.trend_type,
      } as BillingExplorerWidgetSetupType),
    );
    dispatch(getBillingExplorerWidgetSetupDataThunk());
  },
);
