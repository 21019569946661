import { FC, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { InfoDataChip } from "./widget-header/source-and-name/InfoDataChip";
import {
  ResourceExplorerGroupingLabel,
  ResourceExplorerGroupingNullable,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../services/cloudchipr.api";
import { GroupingOptionIcon } from "../../../../common/resource-explorer-grouping-selector-v2/components/GroupingOptionIcon";

interface WidgetGroupingChipProps {
  groupBy: ResourceExplorerGroupingNullable;
  groupValues?: string[];
  groupByLabel?: ResourceExplorerGroupingLabel;
}

export const WidgetGroupingChip: FC<WidgetGroupingChipProps> = ({
  groupValues,
  groupBy,
  groupByLabel,
}) => {
  const { data, isError } = useValuesQuery(
    { filterProvider: "dimensions", filterType: "dimension_id" },
    { skip: groupBy !== "category" },
  );

  const dimensionName = useMemo(() => {
    if (groupBy !== "category") {
      return "";
    }

    if (isError) {
      return "Dimension";
    }

    const firstValue = groupValues?.at(0);
    const selected = data?.find((option) => option.value === firstValue);

    return selected?.title ?? selected?.value ?? "";
  }, [groupValues, groupBy, data, isError]);

  const label = groupBy === "category" ? dimensionName : groupByLabel?.singular;

  return (
    <InfoDataChip>
      <Stack spacing={0.5} direction="row">
        <Typography variant="inherit">Group By:</Typography>
        <GroupingOptionIcon grouping={groupBy} />
        <Typography
          component="span"
          variant="inherit"
          fontWeight="medium"
          color="text.primary"
        >
          {label}
        </Typography>
      </Stack>
    </InfoDataChip>
  );
};
