import { CoverageWidgetSetupType } from "../types/setups/coverageWidgetSetupType";

export const coverageWidgetSetupDefaultData: CoverageWidgetSetupType = {
  widgetType: "coverage",
  name: "Coverage",
  grouping: "resource_type",
  commitmentType: "all",
  dates: {
    type: "past",
    value: 60,
    unit: "day",
  },
  trendType: "month_over_month",
  aggregation: {
    net_savings: true,
    covered: true,
    not_covered: true,
    real_cost: true,
  },
  visualization: {
    chart_type: "stack",
    chart_base_type: "percent",
    covered: true,
    not_covered: true,
    coverage: true,
  },
};
