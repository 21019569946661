import { FC } from "react";
import {
  Alert,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import { grey } from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CommandSnippet } from "../../../../../common/connectors/components/CommandSnippet";

interface KubernetesDetachClusterDrawerProps {
  open: boolean;
  onClose(): void;
}

export const KubernetesDetachClusterDrawer: FC<
  KubernetesDetachClusterDrawerProps
> = ({ open, onClose }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      slotProps={slotProps}
      PaperProps={paperProps}
    >
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          px={2}
          py={1}
          alignItems="center"
        >
          <Typography variant="subtitle1">Detach Cluster</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
        <Divider />

        <Stack px={2} py={1}>
          <Stack direction="row" spacing={2}>
            <Typography variant="body1" color="text.secondary">
              Prerequisites
            </Typography>

            <Chip
              onClick={onChipClick}
              label="Helm v3"
              size="small"
              color="primary"
              sx={chipStyles}
            />
          </Stack>

          <Stack component="ol" sx={{ pl: 3 }} spacing={1}>
            <li style={liStyle}>
              <Stack spacing={1}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body1" color="text.secondary">
                    Run the following command in your terminal to uninstall the
                    c8r Helm Chart from your cluster.
                  </Typography>

                  <Tooltip
                    arrow
                    title="Make sure your terminal is connected to the right cluster."
                    placement="top"
                  >
                    <InfoOutlinedIcon fontSize="small" color="info" />
                  </Tooltip>
                </Stack>

                <CommandSnippet
                  code={"helm uninstall c8r-agent -n c8r-agent "}
                  parentBoxSx={{ p: 2 }}
                  copyButtonSize="xsmall"
                >
                  <Typography variant="body2">
                    helm uninstall c8r-agent -n c8r-agent
                  </Typography>
                </CommandSnippet>
              </Stack>
            </li>
          </Stack>

          <Alert variant="outlined" severity="info">
            After detaching, the cluster will be listed under the{" "}
            <Typography fontWeight="medium" component="span">
              “Inactive Clusters”
            </Typography>{" "}
            tab.
          </Alert>
        </Stack>
      </Stack>
    </Drawer>
  );
};

const paperProps = {
  sx: { width: 640 },
};

const slotProps = {
  backdrop: { invisible: true },
};

const helmDocLink = "https://helm.sh/docs/intro/install/";
const chipStyles = { cursor: "pointer", bgcolor: "info.light" };

const liStyle = {
  listStyle: "disc",
  color: grey[600],
};
const onChipClick = () => {
  window.open(helmDocLink, "_blank");
};
