import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { flattenNestedResourcesHierarchy } from "../../../../utils/constants/resources/nestedResourcesHierarchy";

export const kubernetesLiveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector =
  (
    state: RootState,
    resourceType?: ResourceType,
  ): Record<ResourceType, Record<string, boolean>> | undefined => {
    if (!resourceType) {
      return;
    }

    const resourceTypesHierarchy = flattenNestedResourcesHierarchy[
      resourceType
    ] ?? [resourceType];

    return resourceTypesHierarchy.reduce(
      (result, item) => {
        const data = Object.values(
          state.kubernetesLiveUsageMgmtSlice.selected[item] ?? {},
        );

        const values = Object.values(data);
        values.forEach((member) => {
          if (!Object.keys(member).length) {
            return;
          }
          const data = result?.[item]
            ? { ...result[item], ...member }
            : { ...member };
          result = {
            ...result,
            [item]: {
              ...data,
            },
          };
        });

        return result;
      },
      {} as Record<ResourceType, Record<string, boolean>>,
    );
  };
