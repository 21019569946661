import { createAsyncThunk } from "@reduxjs/toolkit";
import { createTasksViewThunk } from "./createTasksViewThunk";
import { View } from "../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { RootState } from "../../../../store";
import { tasksViewByIdSelector } from "../../../selectors/views-hierarchy/tasksViewByIdSelector";

type ThunkArgs = {
  name: string;
  viewId: string;
};

export const duplicateTasksViewThunk = createAsyncThunk(
  "taskManagement/duplicateTasksView",
  async (
    { viewId, name }: ThunkArgs,
    { dispatch, getState },
  ): Promise<View | undefined> => {
    const state = getState() as RootState;
    const viewById = tasksViewByIdSelector(state, viewId);

    try {
      return await dispatch(
        createTasksViewThunk({
          body: { name, config: viewById?.config },
          disableSnackbar: true,
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
      return;
    }
  },
);
