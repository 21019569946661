import { FC, ReactNode } from "react";
import { useToggle } from "rooks";
import {
  Box,
  Card,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

interface ResourceDetailsContentCollapsableCardProps {
  title: string;
  Icon: ReactNode;
  children: ReactNode;
  HeaderAction?: ReactNode;
}

export const ResourceDetailsContentCollapsableCard: FC<
  ResourceDetailsContentCollapsableCardProps
> = ({ Icon, title, children, HeaderAction }) => {
  const [collapsed, toggleCollapsed] = useToggle(false);

  return (
    <Card variant="outlined" sx={{ borderRadius: 3, flexShrink: 0 }}>
      <Stack
        p={1}
        direction="row"
        spacing={1}
        alignItems="center"
        borderBottom={collapsed ? 0 : 1}
        borderColor="grey.300"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton size="small" onClick={toggleCollapsed}>
            {collapsed ? (
              <KeyboardArrowRightOutlinedIcon />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )}
          </IconButton>

          {Icon}

          <Typography variant="body1" fontWeight="medium">
            {title}
          </Typography>
        </Stack>

        {HeaderAction}
      </Stack>

      <Collapse in={!collapsed}>
        <Box p={1.5}>{children}</Box>
      </Collapse>
    </Card>
  );
};
