import { RootState } from "../../../../../../store";
import { CommitmentDateGranularDataPoint } from "../../../../../../../services/cloudchipr.api";
import { azureSavingsPlansDataForGranularSelector } from "../azureSavingsPlansDataForGranularSelector";

export const azureSavingsPlansGranularDataSelector = (
  state: RootState,
): CommitmentDateGranularDataPoint[] | undefined => {
  const data = azureSavingsPlansDataForGranularSelector(state);

  return data?.date_granular_data?.data;
};
