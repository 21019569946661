import { FC, MouseEvent, useCallback } from "react";
import { Box, Stack, Tooltip } from "@mui/material";
import { UserAvatar } from "../../../../../../../common/avatars/UserAvatar";
import { User } from "../../../../../../../../services/cloudchipr.api";
import { useHover } from "../../../../../../../../utils/hooks/useHover.hook";

interface AssigneeSelectionUserProps {
  user: User;
  index: number;
  visibleUsersCount: number;
  onRemove(userId: string): void;
}

export const AssigneeSelectionUserAvatar: FC<AssigneeSelectionUserProps> = ({
  user,
  visibleUsersCount,
  index,
  onRemove,
}) => {
  const { ref, hovered } = useHover();

  const removeHandler = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onRemove(user.id);
    },
    [onRemove, user.id],
  );

  return (
    <Tooltip arrow placement="top" title={user.name || user.email}>
      <Stack
        ref={ref}
        bgcolor={user.profile?.color ?? ""}
        sx={{
          ...assigneeSelectionUserAvatarStyles,
          zIndex: visibleUsersCount - index,
          left: index * 20,
        }}
      >
        <Box position="relative">
          {hovered && (
            <Stack sx={removeIconStyles} onClick={removeHandler}>
              x
            </Stack>
          )}

          <UserAvatar
            profile={user.profile}
            name={user.name}
            sx={{ border: 2, borderColor: "white", cursor: "inherit" }}
          />
        </Box>
      </Stack>
    </Tooltip>
  );
};

export const assigneeSelectionUserAvatarStyles = {
  top: 0,
  border: 2,
  width: 28,
  height: 28,
  minWidth: 28,
  minHeight: 28,
  color: "white",
  cursor: "pointer",
  borderRadius: "50%",
  alignItems: "center",
  position: "absolute",
  borderColor: "white",
  justifyContent: "center",
};

const removeIconStyles = {
  top: -4,
  right: 0,
  border: 1,
  width: 14,
  height: 14,
  zIndex: 100,
  fontSize: 8,
  cursor: "pointer",
  bgcolor: "grey.600",
  borderRadius: "50%",
  fontWeight: "bold",
  position: "absolute",
  alignItems: "center",
  borderColor: "white",
  justifyContent: "center",
};
