import { ColDef } from "ag-grid-community";
import { TaskEnvironment } from "../../../../../../services/cloudchipr.api";

export const environmentComparatorFn =
  (environments?: TaskEnvironment[]): ColDef["comparator"] =>
  (_, __, nodeA, nodeB) => {
    const current = nodeA?.data?.environment?.id ?? "";
    const next = nodeB?.data?.environment?.id ?? "";

    const currentIndex = current
      ? environments?.findIndex((item) => item.id === current)
      : Infinity;
    const nextIndex = next
      ? environments?.findIndex((item) => item.id === next)
      : Infinity;

    return (currentIndex ?? 0) - (nextIndex ?? 0);
  };
