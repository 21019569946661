import { FC } from "react";

import { useAppSelector } from "../../../../../../../store/hooks";
import { overviewCoveragesSelector } from "../../../../../../../store/commitments/selectors/overview/overviewCoveragesSelector";
import { overviewLoadingSelector } from "../../../../../../../store/commitments/selectors/overview/loading/overviewLoadingSelector";
import { CommitmentsOverviewCoverage } from "../common/CommitmentsOverviewCoverage";

export const AwsCommitmentsOverviewCoverage: FC = () => {
  const coverages = useAppSelector(overviewCoveragesSelector);
  const loading = useAppSelector(overviewLoadingSelector);

  return <CommitmentsOverviewCoverage data={coverages} loading={loading} />;
};
