import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { billingExplorerWidgetTableDataCountSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetTableDataCountSelector";
import { billingExplorerWidgetGroupingSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetGroupingSelector";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";

interface BillingExplorerWidgetTableViewLabelHeaderCellProps {
  widgetId: string;
}

export const BillingExplorerWidgetTableLabelHeaderCell: FC<
  BillingExplorerWidgetTableViewLabelHeaderCellProps
> = ({ widgetId }) => {
  const dataCount = useAppSelector((state) =>
    billingExplorerWidgetTableDataCountSelector(state, widgetId),
  );
  const grouping = useAppSelector((state) =>
    billingExplorerWidgetGroupingSelector(state, widgetId),
  );

  const groupingLabel = useAppSelector((state) =>
    resourceExplorerGroupingLabelByGroupingSelector(state, grouping),
  );

  if (!grouping) {
    return null;
  }

  return (
    <Stack pl={1}>
      {groupingLabel?.singular}
      <Typography variant="tiny" color="text.disabled" fontWeight="medium">
        {dataCount}
      </Typography>
    </Stack>
  );
};
