import { resourceExplorerPossibleGroupingsFormattedDataSelector } from "./resourceExplorerPossibleGroupingsFormattedDataSelector";
import { RootState } from "../../../store";
import { ResourceExplorerGroupingLabelMap } from "../../../../components/pages/common/resource-explorer-grouping-selector-v2/utils/types/types";

export const resourceExplorerGroupingLabelsSelector = (
  state: RootState,
): ResourceExplorerGroupingLabelMap | undefined => {
  const formattedData =
    resourceExplorerPossibleGroupingsFormattedDataSelector(state);

  return formattedData?.groupingLabelMap;
};
