import { createAsyncThunk } from "@reduxjs/toolkit";
import { generateConversationItemByAskAIResponse } from "./utils/helpers/generateConversationItemByAskAIResponse";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { askAiConversationSelector } from "../selectors/store-selectors/askAiConversationSelector";
import { setAskAiConversation } from "../askAiSlice";
import { RootState } from "../../store";

export const askAiThunkFixedCacheKey = "ask-ai-postUsersMeAskAiQuestions";

export const askAiThunk = createAsyncThunk(
  "ask-ai/askAi",
  async (question: string, { dispatch, getState }) => {
    const { postUsersMeAskAiQuestions } = cloudChiprApi.endpoints;
    const state = getState() as RootState;
    const conversation = askAiConversationSelector(state);
    try {
      const response = await dispatch(
        postUsersMeAskAiQuestions.initiate(
          {
            body: {
              question,
            },
          },
          {
            fixedCacheKey: askAiThunkFixedCacheKey,
          },
        ),
      )?.unwrap();

      if (response) {
        const newItem = generateConversationItemByAskAIResponse(response);
        dispatch(setAskAiConversation([...conversation, newItem]));
      }
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
