import { RootState } from "../../../store";
import {
  ResourceFilters,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { kubernetesLiveUsageMgmtAppliedFiltersSelector } from "../store-selectors/kubernetesLiveUsageMgmtAppliedFiltersSelector";

export const kubernetesLiveUsageMgmtAppliedFiltersByResourceTypeSelector = (
  state: RootState,
  resourceType: ResourceType,
): ResourceFilters | null => {
  const appliedFilters = kubernetesLiveUsageMgmtAppliedFiltersSelector(state);

  return appliedFilters?.[resourceType] ?? null;
};
