import { FC, Fragment, useCallback } from "react";
import { WidgetsViewsHierarchySelect } from "../../adding-widget/widget-create/widget-setups/common/widgets-views-hierarchy-select/WidgetsViewsHierarchySelect";
import { BillingSummaryDateRangeSelect } from "../../adding-widget/widget-create/widget-setups/billing-summary/app-bar/BillingSummaryDateRangeSelect";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { billingSummarySumSetupPropertyByKeySelector } from "../../../../../../store/dashboards/selectors/setups/billing-summary/billingSummarySumSetupPropertyByKeySelector";
import { billingSummaryREViewChangeThunk } from "../../../../../../store/dashboards/thunks/widgets/billing-summary/widget-change/billingSummaryREViewChangeThunk";
import { billingSummarySetupWidgetNoMonthlyAverageSelector } from "../../../../../../store/dashboards/selectors/setups/billing-summary/data/billingSummarySetupWidgetNoMonthlyAverageSelector";
import { billingSummarySetupWidgetNoDailyAverageSelector } from "../../../../../../store/dashboards/selectors/setups/billing-summary/data/billingSummarySetupWidgetNoDailyAverageSelector";
import {
  billingSummaryDefaultFilters,
  BillingSummaryWidgetFilterType,
} from "../../../../../../store/dashboards/utils/constants/billingSummarySetupDefaultData";
import { BillingSummaryVisualizationSwitch } from "../../adding-widget/widget-create/widget-setups/billing-summary/app-bar/BillingSummaryVisualizationSwitch";

export const BillingSummaryEditPopoverContent: FC = () => {
  const dispatch = useAppDispatch();
  const viewId = useAppSelector(
    billingSummarySumSetupPropertyByKeySelector("viewId"),
  );
  const currentFilter = useAppSelector(
    billingSummarySumSetupPropertyByKeySelector("filter"),
  );
  const noMonthlyAverage = useAppSelector(
    billingSummarySetupWidgetNoMonthlyAverageSelector,
  );
  const noDailyAverage = useAppSelector(
    billingSummarySetupWidgetNoDailyAverageSelector,
  );

  const viewChangeHandler = useCallback(
    (id: string) => {
      dispatch(billingSummaryREViewChangeThunk(id));
    },
    [dispatch],
  );

  return (
    <Fragment>
      <WidgetsViewsHierarchySelect
        selectedItemId={viewId}
        onChange={viewChangeHandler}
      />

      <BillingSummaryDateRangeSelect />

      {billingSummaryDefaultFilters.map(
        (filter: BillingSummaryWidgetFilterType) => {
          if (filter === "average_daily_cost_details" && noDailyAverage) {
            return null;
          }

          if (filter === "average_monthly_cost_details" && noMonthlyAverage) {
            return null;
          }

          return (
            <BillingSummaryVisualizationSwitch
              key={filter}
              filter={filter}
              checked={!!currentFilter?.includes(filter)}
            />
          );
        },
      )}
    </Fragment>
  );
};
