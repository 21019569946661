import { FC, useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import {
  FilterItemNode,
  FilterOperatorType,
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
  ResourceExplorerPossibleFilterV2,
} from "../../../../../../../services/cloudchipr.api";
import { ProviderIcon } from "../../../../../../common/ProviderIcon";

export type NewFilterAddFn = (
  provider: ResourceExplorerFilterProvider,
  key: ResourceExplorerDynamicFilterItemType,
  operator: FilterOperatorType,
  value?: FilterItemNode["value"],
) => void;

interface FilterItemSelectMenuItemProps {
  filter: ResourceExplorerPossibleFilterV2;
  onSelect: NewFilterAddFn;
}

export const FilterItemSelectMenuItem: FC<FilterItemSelectMenuItemProps> = ({
  filter,
  onSelect,
}) => {
  const clickHandler = useCallback(() => {
    const operator = filter.operators.at(0)?.key;
    if (!operator || !filter?.filter_provider) {
      return;
    }

    onSelect(filter.filter_provider, filter.key, operator);
  }, [onSelect, filter]);

  return (
    <MenuItem sx={menuItemSx} onClick={clickHandler}>
      {!!filter?.filter_provider && (
        <ListItemIcon>
          <ProviderIcon provider={filter.filter_provider} />
        </ListItemIcon>
      )}

      <ListItemText primary={filter.label} />
    </MenuItem>
  );
};

const menuItemSx = {
  px: 0.5,
  my: 1,
  borderRadius: 2,
  "&.Mui-selected": { color: "primary.main" },
};
