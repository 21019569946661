import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { IntegrationCustomMessageInfoSection } from "../../../../../../../../common/notification-selection/components/custom-message/common-components/IntegrationCustomMessageInfoSection";

export const EmailIntegrationGracePeriodWorkflowInfo: FC = () => {
  return (
    <Stack>
      <Stack justifyContent="space-between" direction="row">
        <IntegrationCustomMessageInfoSection
          start="Workflow"
          tail="{{workflow name}}"
          color="primary.main"
        />

        <Typography variant="body2" color="text.secondary" fontWeight="medium">
          Monthly Price:
        </Typography>
      </Stack>

      <Stack justifyContent="space-between" direction="row" spacing={0.5}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <NotificationsActiveOutlinedIcon
            fontSize="small"
            sx={{ color: "warning.light" }}
          />
          <Typography variant="body1" fontWeight="medium">
            Actions will be performed after grace period.
          </Typography>
        </Stack>
        <Typography variant="body2" fontWeight="medium">
          {`{{0.00}}`}
        </Typography>
      </Stack>
      <IntegrationCustomMessageInfoSection
        start="Grace Period"
        tail="{{period}}"
      />
      <IntegrationCustomMessageInfoSection
        start="Grace Period Expires"
        tail="{{date}}"
      />
    </Stack>
  );
};
