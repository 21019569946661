import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { resourceDetailsTargetSelector } from "../../../../../../../../../store/resource-details/selectors/target-resource/resourceDetailsTargetSelector";
import { useGetUsersMeOrganisationsCurrentKubernetesByTypeAndResourceIdMetricsQuery as useMetricsQuery } from "../../../../../../../../../services/cloudchipr.api";
import { ResourceDetailsMetricsContent } from "../../../common/metrics/ResourceDetailsMetricsContent";

export const ResourceDetailsGeneralContentK8SUsageUtilization: FC = () => {
  const resource = useAppSelector(resourceDetailsTargetSelector);

  const resourceType = resource?.resource?.resourceType ?? "ec2";
  const resourceId = resource?.resource?.provider_unique_identifier ?? "";

  const { data, isFetching } = useMetricsQuery(
    { type: resourceType, resourceId: resourceId },
    { skip: !resourceType || !resourceId },
  );

  return <ResourceDetailsMetricsContent data={data} loading={isFetching} />;
};
