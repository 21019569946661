import { FC } from "react";
import { Stack, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import { TypographyWithTooltip } from "../../../../../../../../../../../common/TypographyWithTooltip";
import type { CustomHeaderProps } from "ag-grid-react";

export const getCoverageWidgetCoverageInnerHeader = (
  tooltipTitle: string,
): FC<CustomHeaderProps> => {
  return (props) => {
    return (
      <Tooltip title={tooltipTitle} placement="top">
        <Stack position="relative">
          <TypographyWithTooltip
            title={props.displayName}
            sx={textStyles}
            variant="body2"
            width="100%"
          />
        </Stack>
      </Tooltip>
    );
  };
};

const textStyles = {
  textDecoration: "underline",
  textDecorationStyle: "dashed",
  textUnderlineOffset: 4,
  textDecorationColor: grey["A400"],
};
