import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { widgetDataNotFoundSelector } from "../../../selectors/widgets/common/widgetDataNotFoundSelector";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getBillingExplorerWidgetDataByWidgetIdThunk = createAsyncThunk(
  "dashboards/getBillingExplorerWidgetWidgetDataByWidgetIdV2",
  async (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetId } =
      cloudChiprApi.endpoints;

    const widgetNotFound = widgetDataNotFoundSelector(
      state,
      widgetId ?? "",
      "cost_breakdown",
    );
    try {
      await dispatch(
        getUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetId.initiate(
          {
            widgetId,
          },
          {
            forceRefetch: true,
          },
        ),
      );
    } catch (e) {
      if (!widgetNotFound) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    }
  },
);
