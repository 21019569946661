import { getSlackFormatFromHTML } from "./getSlackFormatFromHTML";
import { Schedule } from "../../../../services/cloudchipr.api";

export const formatNotificationsFromHtmlToSlack = (
  notifications: Schedule["notifications"],
) => {
  return notifications?.map((item) => {
    if (item.type === "slack") {
      item = {
        ...item,
        custom_message: item.custom_message
          ? getSlackFormatFromHTML(item.custom_message)
          : "",
      };
      return item;
    }
    return item;
  });
};
