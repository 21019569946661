import { useCallback } from "react";
import { getModifiedAdvancedFilterPreferencesJson } from "../helpers/getModifiedAdvancedFilterPreferencesJson";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { rightsizingAdvancedFiltersSettingsSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/advanced-filters/rightsizingAdvancedFiltersSettingsSelector";
import { usePatchUsersMeCurrentPreferenceByKeyMutation } from "../../../../../../../services/cloudchipr.api";
import { RightsizingAdvancedFilterType } from "../../../../../../../store/recommendations/utils/types/rightsizingAdvancedFilterType";
import {
  getRightsizingRecommendationsAdvancedFiltersThunk,
  rightsizingRecommendationsAdvancedFiltersPreferenceKey,
} from "../../../../../../../store/recommendations/thunks/rightsizing/getRightsizingRecommendationsAdvancedFiltersThunk";

export const useToggleRightsizingAdvancedFilterUserPreference = () => {
  const dispatch = useAppDispatch();
  const preferences = useAppSelector(
    rightsizingAdvancedFiltersSettingsSelector,
  );
  const [updatePreferences, { isLoading: isUpdateLoading }] =
    usePatchUsersMeCurrentPreferenceByKeyMutation();

  const toggleRightsizingAdvancedFilterUserPreference = useCallback(
    async (filterType: RightsizingAdvancedFilterType) => {
      const preferenceJSON = getModifiedAdvancedFilterPreferencesJson(
        preferences,
        filterType,
      );

      await updatePreferences({
        key: rightsizingRecommendationsAdvancedFiltersPreferenceKey,
        body: {
          value: preferenceJSON,
        },
      });

      dispatch(getRightsizingRecommendationsAdvancedFiltersThunk());
    },
    [updatePreferences, dispatch, preferences],
  );

  return {
    toggleRightsizingAdvancedFilterUserPreference,
    loading: isUpdateLoading,
  };
};
