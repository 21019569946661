import { FC } from "react";
import { MenuProps, Select, Typography } from "@mui/material";
import { useCurrentEditor } from "@tiptap/react";
import { ToolbarHeadingCommandMenuItem } from "./ToolbarHeadingCommandMenuItem";
import { ToolbarParagraphCommandMenuItem } from "./ToolbarParagraphCommandMenuItem";
import { HeadingLevels } from "../../../utils/types/types";

export const ToolbarHeadingCommandSelector: FC = () => {
  const { editor } = useCurrentEditor();

  let value = "P";

  levels.forEach((level) => {
    if (editor?.isActive("heading", { level })) {
      value = level.toString();
    }
  });

  return (
    <Select
      renderValue={renderValue}
      value={value}
      sx={selectStyles}
      MenuProps={menuProps}
    >
      <ToolbarParagraphCommandMenuItem />

      {levels.map((level) => (
        <ToolbarHeadingCommandMenuItem
          key={level}
          disableWhenActive
          level={level}
        />
      ))}
    </Select>
  );
};

const renderValue = (value: string) => {
  const title = value === "P" ? "P" : `H${value}`;

  return (
    <Typography textAlign="center" color="grey.600" fontWeight="bold">
      {title}
    </Typography>
  );
};

const levels: HeadingLevels[] = [1, 2, 3, 4, 5, 6];

const selectStyles = { height: 28, width: 65, border: "none" };

const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
};
