import { FC, useCallback } from "react";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { taskManagementCreateTaskThunk } from "../../../../../../../store/task-management/thunks/actions/taskManagementCreateTaskThunk";
import { taskCreationLoadingSelector } from "../../../../../../../store/task-management/selectors/loading/taskCreationLoadingSelector";
import { TaskCreateSnackbarAlert } from "../TaskCreateSnackbarAlert";
import { taskEditSaveButtonDisabledSelector } from "../../../../../../../store/task-management/selectors/form/taskCreateSaveButtonDisabledSelector";
import { taskManagementTargetTitlesSelector } from "../../../../../../../store/task-management/selectors/form/target/common/taskManagementTargetTitlesSelector";

export const TaskCreateSaveButton: FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(taskCreationLoadingSelector);
  const titles = useAppSelector(taskManagementTargetTitlesSelector);
  const disabled = useAppSelector(taskEditSaveButtonDisabledSelector);

  const createHandler = useCallback(async () => {
    const response = await dispatch(taskManagementCreateTaskThunk());

    if (response.payload) {
      const taskId =
        response.payload?.length > 1 ? undefined : response.payload.at(0)?.id;

      const multiple = titles && titles.length > 1;
      const moreThan2 = titles && titles.length > 2;

      const multipleMessage = moreThan2
        ? `"${titles?.at(0)},${titles?.at(1)}" and ${titles?.length - 2} more tasks created`
        : `"${titles?.at(0)}" and "${titles?.at(1)}" tasks created`;

      enqueueSnackbar(
        multiple ? multipleMessage : `"${titles?.at(0)}" task created`,
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            footer: <TaskCreateSnackbarAlert id={taskId} />,
          },
        },
      );
    }
  }, [dispatch, titles]);

  return (
    <LoadingButton
      size="small"
      variant="contained"
      disabled={disabled}
      loading={loading}
      onClick={createHandler}
      sx={{ textTransform: "none" }}
    >
      Create {(titles?.length ?? 0) > 1 ? ` ${titles?.length} Tasks` : ""}
    </LoadingButton>
  );
};
