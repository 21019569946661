import { FC, useState } from "react";
import { DatePickerStateProvider } from "@rehookify/datepicker";
import { DPUserConfig } from "@rehookify/datepicker/dist/types/config";
import { DateRangeCalendar } from "./DateRangeCalendar";

interface DateRangeSelectProps {
  minDate?: string;
  maxDate?: string;
  clickSource: string;
  highlightToday?: boolean;
  onChange: DPUserConfig["onDatesChange"];
  selectedDates: DPUserConfig["selectedDates"];
}

export const DateRangeSelect: FC<DateRangeSelectProps> = ({
  highlightToday,
  minDate,
  maxDate,
  onChange,
  selectedDates,
  clickSource,
}) => {
  const [offsetDate, setOffsetDate] = useState(
    clickSource === "from" ? selectedDates.at(0) : selectedDates.at(1),
  );

  return (
    <DatePickerStateProvider
      config={{
        offsetDate,
        selectedDates,
        onDatesChange: onChange,
        calendar: { startDay: 1, mode: "fluid" },
        focusDate: new Date(),

        years: { numberOfYears: 6 },
        dates: {
          mode: "range",
          selectSameDate: true,
          minDate: minDate ? new Date(minDate) : undefined,
          maxDate: maxDate ? new Date(maxDate) : new Date(),
        },
      }}
    >
      <DateRangeCalendar
        onChange={onChange}
        setOffsetDate={setOffsetDate}
        selectedDates={selectedDates}
        highlightToday={highlightToday}
      />
    </DatePickerStateProvider>
  );
};
