import { FC, useEffect, useRef } from "react";
import Highcharts, { PlotColumnDataLabelsOptions } from "highcharts";
import { Box } from "@mui/material";
import { stackedColumnChartPlotOptionsColumn } from "./utils/plot-options/stackedColumnChartPlotOptionsColumn";
import { getTooltipOptions } from "../common/utils/tooltip/getTooltipOptions";
import { plotOptionsSeries } from "../common/utils/plot-options/plotOptionsSeries";
import { chartDefaultConfigs } from "../common/utils/constants/chartDefaultConfigs";
import {
  chartXAxisConfigs,
  chartXAxisLabels,
} from "../common/utils/chart-axises/chartXAxis";
import {
  chartYAxisConfigs,
  chartYAxisDefaultPlotLine,
  chartYAxisLabels,
  getChartYAxisLabelsDefaultFormatter,
} from "../common/utils/chart-axises/chartYAxis";
import { getChartXAxisDynamicStep } from "../common/utils/helpers/getChartXAxisDynamicStep";
import { useHighchartsContext } from "../common/components/HighchartsProvider";
import { HighChartsCommonProps } from "../common/utils/types/common";
import { highChartColors } from "../common/utils/constants/chartColors";

interface HighchartsStackedColumnChartProps extends HighChartsCommonProps {
  columnDataLabels?: PlotColumnDataLabelsOptions;
  stackedChartHeadingLabelFormatter?: (total: number) => string;
}

export const HighchartsStackedColumnChart: FC<
  HighchartsStackedColumnChartProps
> = ({
  loading,
  chartData,
  tooltipValueFormatter,
  stackedChartHeadingLabelFormatter,
  percentBased,
  yAxisLabelsFormatter,
  columnDataLabels,
  showTotalValueInTooltip,
  yAxisPlotLines,
  onChartZoom,
  chartScale,
  xAxisLabelsFormatter,
  yAxisTickAmount,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { chartApi, setChartApi } = useHighchartsContext();

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const step = getChartXAxisDynamicStep(
      chartData.categories.length,
      chartRef.current.clientWidth,
    );

    // @ts-expect-error  | Something wrong with types, this is waiting for "Options" but it should be an "Element"
    const chart = Highcharts.chart(chartRef.current, {
      chart: {
        type: "column",
        zoomType: chartData.categories.length > 3 ? "x" : undefined,
        events: { selection: onChartZoom },
      },

      colors: highChartColors,

      title: { text: "" },

      xAxis: {
        categories: chartData.categories,
        labels: {
          ...chartXAxisLabels,
          step: step,
          formatter: xAxisLabelsFormatter,
        },
        ...chartXAxisConfigs,
      },

      yAxis: {
        type: chartScale,
        tickAmount: yAxisTickAmount,
        min: percentBased ? 0 : undefined,
        max: percentBased ? 100 : undefined,
        title: { text: "" },
        stackLabels: {
          enabled: true,
          style: { fontWeight: "bold", color: "#333" },
          formatter: function () {
            return stackedChartHeadingLabelFormatter?.(+this.total) ?? "";
          },
        },
        labels: {
          formatter:
            yAxisLabelsFormatter ??
            getChartYAxisLabelsDefaultFormatter(percentBased),
          ...chartYAxisLabels,
        },
        plotLines: [...(yAxisPlotLines ?? []), chartYAxisDefaultPlotLine],
        ...chartYAxisConfigs,
      },

      series: chartData.series,

      tooltip: getTooltipOptions({
        valueFormatter: tooltipValueFormatter,
        showTotal: showTotalValueInTooltip,
      }),

      plotOptions: {
        column: {
          ...stackedColumnChartPlotOptionsColumn,
          dataLabels: columnDataLabels,
        },
        series: plotOptionsSeries,
      },

      ...chartDefaultConfigs,
    });

    setChartApi(chart);

    return () => {
      chart?.destroy?.();
    };
  }, [
    chartData,
    yAxisTickAmount,
    chartScale,
    setChartApi,
    onChartZoom,
    percentBased,
    yAxisPlotLines,
    columnDataLabels,
    yAxisLabelsFormatter,
    tooltipValueFormatter,
    showTotalValueInTooltip,
    xAxisLabelsFormatter,
    stackedChartHeadingLabelFormatter,
  ]);

  useEffect(() => {
    if (!chartApi) {
      return;
    }

    if (loading) {
      chartApi.showLoading();
    } else {
      chartApi.hideLoading();
    }
  }, [chartApi, loading]);

  return <Box ref={chartRef} flex={1} width="100%" margin="auto" />;
};
