import { FC, useCallback } from "react";
import { useCurrentEditor } from "@tiptap/react";
import { Stack } from "@mui/material";
import { ToolbarCommandByCommandName } from "./ToolbarCommandByCommandName";
import { CommandName, CommandsArray } from "../../utils/types/types";
import { commandsArray } from "../../utils/constants/constants";

interface EditorToolbarProps {
  commands?: CommandsArray;
}

export const EditorToolbar: FC<EditorToolbarProps> = ({ commands }) => {
  const { editor } = useCurrentEditor();

  const show = useCallback(
    (commandName: CommandName) => {
      return !commands?.length || commands.flat().includes(commandName);
    },
    [commands],
  );

  if (!editor) {
    return null;
  }

  return (
    <Stack
      px={2}
      py={1}
      spacing={0.25}
      direction="row"
      bgcolor="grey.100"
      top={0}
      zIndex={1}
      position={"sticky"}
      sx={{ overflowX: "auto", overflowY: "hidden" }}
    >
      {commandsArray.map((commandName) => {
        if (typeof commandName === "string") {
          return (
            show(commandName) && (
              <ToolbarCommandByCommandName
                key={commandName}
                commandName={commandName}
              />
            )
          );
        }

        const showSection = commandName.some((name) => show(name));

        if (!showSection) {
          return null;
        }

        return (
          <Stack
            direction="row"
            spacing={0.25}
            borderRight={1}
            pr={0.5}
            borderColor="grey.400"
            key={commandName.join("")}
          >
            {commandName.map((name) => {
              return (
                show(name) && (
                  <ToolbarCommandByCommandName key={name} commandName={name} />
                )
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
};
