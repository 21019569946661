import { FC } from "react";
import { Alert, Button, Collapse } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useToggle } from "rooks";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";
import { taskManagementTargetAlreadyHasTaskIdsSelector } from "../../../../../../../../../store/task-management/selectors/form/target/common/taskManagementTargetAlreadyHasTaskIdsSelector";

export const TaskCreationDuplicatedResourcesAlertMessage: FC = () => {
  const [collapsed, toggleCollapsed] = useToggle();

  const targetIds = useAppSelector(
    taskManagementTargetAlreadyHasTaskIdsSelector,
  );

  const editMode = useAppSelector(isTaskManagementDrawerEditModeSelector);

  if (!targetIds?.length || editMode) {
    return null;
  }

  return (
    <Alert
      variant="filled"
      severity="info"
      sx={{
        bgcolor: blue[100],
        fontWeight: "normal",
        color: "primary.main",
      }}
      action={
        <Button
          size="small"
          onClick={toggleCollapsed}
          sx={{ whiteSpace: "nowrap" }}
        >
          {collapsed ? "Hide" : "Show"}
        </Button>
      }
    >
      Tasks will not be created for the following {targetIds.length} resource
      {targetIds.length > 1 ? "s " : " "}
      because {targetIds.length > 1 ? "they" : "it"} already have task
      {targetIds.length > 1 ? "s " : " "} assigned.
      <Collapse in={collapsed}>
        <ul>
          {targetIds.map((id) => {
            return (
              <li key={id}>
                <TypographyWithTooltip variant="body2" title={id} />
              </li>
            );
          })}
        </ul>
      </Collapse>
    </Alert>
  );
};
