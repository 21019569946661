import { FC, useCallback } from "react";
import { SwitchWithTypography } from "../../../../../../../../../../SwitchWithTypography";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";
import { billingExplorerViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerViewVisualizationChangeThunk";

export const BillingExplorerWidgetVisualizationForecastedCost: FC = () => {
  const dispatch = useAppDispatch();
  const forecastedCostChecked = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("forecasted_cost"),
  );

  const handleForecastedCostCheckedChange = useCallback(
    (checked: boolean) => {
      dispatch(
        billingExplorerViewVisualizationChangeThunk({
          forecasted_cost: checked,
        }),
      );
    },
    [dispatch],
  );

  return (
    <SwitchWithTypography
      checked={forecastedCostChecked}
      onChange={handleForecastedCostCheckedChange}
      title="Forecasted Cost"
    />
  );
};
