import { FC, Fragment } from "react";
import Typography from "@mui/material/Typography";
import { NestedIntegration } from "../../../../../../../../services/cloudchipr.api";
import { IntegrationCustomMessageDataGrid } from "../../../../../../common/notification-selection/components/custom-message/common-components/IntegrationCustomMessageDataGrid";
import { integrationCustomEmailCostAnomalyDataGridColumns } from "../../custom-message/utils/constants/constants";
import { EmailIntegrationCsvInfo } from "../../../../../../common/notification-selection/components/custom-message/common-components/EmailIntegrationCsvInfo";

interface CostAnomalyAlertCustomMessageAfterMessageComponentProps {
  type: NestedIntegration["type"];
}

export const CostAnomalyAlertCustomMessageAfterMessageComponent: FC<
  CostAnomalyAlertCustomMessageAfterMessageComponentProps
> = ({ type }) => {
  return (
    <Fragment>
      <Typography variant="body1">
        Please review and take the necessary actions.
      </Typography>
      <IntegrationCustomMessageDataGrid
        data={integrationCustomEmailCostAnomalyDataGridColumns}
        label=""
      />

      {type === "email" && <EmailIntegrationCsvInfo />}
    </Fragment>
  );
};
