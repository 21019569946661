import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { DashboardNotFoundWidget } from "../../common/DashboardNotFoundWidget";
import { DashboardEmptyWidgetState } from "../../common/DashboardEmptyWidgetState";
import { costAnomaliesWidgetNotFoundSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-anomalies/costAnomaliesWidgetNotFoundSelector";
import { costAnomaliesWidgetByIdSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-anomalies/costAnomaliesWidgetByIdSelector";
import { CostAnomaliesWidgetBody } from "../../../adding-widget/widget-create/widget-setups/cost-anomalies/content/components/CostAnomaliesWidgetBody";
import { costAnomaliesWidgetDataTotalSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-anomalies/widget-data/costAnomaliesWidgetDataTotalSelector";
import { costAnomaliesWidgetDataLoadingSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-anomalies/widget-data/costAnomaliesWidgetDataLoadingSelector";
import {
  Dates,
  ResourceExplorerCostsDateDetails,
} from "../../../../../../../services/cloudchipr.api";

interface CostAnomaliesWidgetContentProps {
  widgetId: string;
  viewName?: string;
  previousPeriodDate?: ResourceExplorerCostsDateDetails;
  date?: Dates;
}
export const CostAnomaliesWidgetContent: FC<
  CostAnomaliesWidgetContentProps
> = ({ widgetId, viewName, previousPeriodDate, date }) => {
  const widgetDataNotFound = useAppSelector((state) =>
    costAnomaliesWidgetNotFoundSelector(state, widgetId),
  );

  const widget = useAppSelector((state) =>
    costAnomaliesWidgetByIdSelector(state, widgetId),
  );

  const data = useAppSelector((state) =>
    costAnomaliesWidgetDataTotalSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    costAnomaliesWidgetDataLoadingSelector(state, widgetId),
  );

  if (widgetDataNotFound) {
    return (
      <DashboardNotFoundWidget name={viewName ?? ""} label="billing report" />
    );
  }

  if (!widget) {
    return <DashboardEmptyWidgetState />;
  }

  return (
    <CostAnomaliesWidgetBody
      date={date}
      loading={loading}
      data={data ?? []}
      grouping={widget.grouping}
      groupValues={widget.group_values}
      viewId={widget.view_id ?? undefined}
      previousPeriodDate={previousPeriodDate}
    />
  );
};
