import { getUsersMeOrganisationsCurrentWidgetsBillingSummarySelector } from "./getUsersMeOrganisationsCurrentWidgetsBillingSummarySelector";
import { RootState } from "../../../../../store";
import { billingSummarySetupWidgetPayloadDataSelector } from "../billingSummarySetupWidgetPayloadDataSelector";

export const billingSummarySetupWidgetDataResponseSelector = (
  state: RootState,
) => {
  const payload = billingSummarySetupWidgetPayloadDataSelector(state);

  if (!payload) {
    return;
  }

  return getUsersMeOrganisationsCurrentWidgetsBillingSummarySelector(payload)(
    state,
  );
};
