import { Typography } from "@mui/material";
import { KubernetesClusterClusterNameColumn } from "./columns/KubernetesClusterClusterNameColumn";
import { KubernetesClusterClusterIdleTotalCostsColumn } from "./columns/KubernetesClusterClusterIdleTotalCostsColumn";
import { KubernetesClusterCloudProviderColumn } from "./columns/KubernetesClusterCloudProviderColumn";
import { KubernetesCluster } from "../../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../../storybook/data-grid/utils/types/types";
import { money } from "../../../../../../../utils/numeral/money";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { AccountAccessTypeChip } from "../../../../../../common/chips/account-access-type-chip/AccountAccessTypeChip";
import { KubernetesClusterActions } from "../actions/KubernetesClusterActions";

export const kubernetesClustersTableColumns: ColumnSetupType<KubernetesCluster>[] =
  [
    {
      id: "name",
      accessorKey: "name",
      header: "Cluster",
      cell: ({ row }) => {
        const data = row.original;
        return (
          <KubernetesClusterClusterNameColumn
            name={data.name}
            provider={data.provider}
            clusterId={data.cluster_id}
          />
        );
      },
    },
    {
      id: "total_monthly_cost",
      accessorKey: "total_monthly_cost",
      header: "Total Monthly Price ",
      cell: ({ row }) => {
        return (
          <TypographyWithTooltip
            title={money(row.original?.total_monthly_cost ?? 0)}
          />
        );
      },
    },
    {
      id: "idle_monthly_cost",
      accessorKey: "idle_monthly_cost",
      header: "Idle / Reserved",
      cell: ({ row }) => {
        const data = row.original;
        return (
          <KubernetesClusterClusterIdleTotalCostsColumn
            idleMonthlyCost={data?.idle_monthly_cost}
            reservedMonthlyCost={data.reserved_monthly_cost}
            totalMonthlyCost={data.total_monthly_cost}
          />
        );
      },
    },
    {
      minSize: 300,
      id: "provider",
      accessorKey: "provider",
      header: "Cloud Provider",
      cell: ({ row }) => {
        const data = row.original;
        return (
          <KubernetesClusterCloudProviderColumn provider={data?.provider} />
        );
      },
    },
    {
      id: "id",
      accessorKey: "id",
      header: "Access",
      cell: () => {
        return (
          <AccountAccessTypeChip
            accessType="read"
            textType="long"
            provider="kubernetes"
          />
        );
      },
    },
    {
      id: "region",
      accessorKey: "region",
      header: "Region",
      cell: ({ row }) => {
        return <Typography variant="body2">{row.original.region}</Typography>;
      },
    },
    {
      id: "actionCell",
      header: "",
      cell: KubernetesClusterActions,
    },
  ];
