import { FC, useEffect, useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Highcharts from "highcharts";
import { getNumberByPercentage } from "../../../../../../../../../utils/numeral/getPercentage";

export interface ResourceDetailsGeneralContentK8SUsageChartProps {
  primary: string;
  secondary: string;
  data: { name: string; y: number; color: string; label: string }[];
}

export const ResourceDetailsGeneralContentK8SUsageChart: FC<
  ResourceDetailsGeneralContentK8SUsageChartProps
> = ({ primary, data, secondary }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    // @ts-expect-error  | Something wrong with types, this is waiting for "Options" but it should be an "Element"
    Highcharts.chart(chartRef.current, {
      chart: { type: "pie" },
      title: { text: null },
      plotOptions: {
        pie: {
          innerSize: "70%",
          dataLabels: { enabled: false },
          states: { hover: { enabled: false }, inactive: { enabled: false } },
        },
      },
      tooltip: { enabled: false },
      series: [{ name: primary, data: data }],
      credits: { enabled: false },
    });
  }, [primary, data]);

  const fontSize = Math.min(
    Math.round(getNumberByPercentage(10, chartRef.current?.clientWidth ?? 100)),
    20,
  );

  return (
    <Stack
      width="50%"
      direction="row"
      alignItems="center"
      justifyContent="start"
    >
      <Box position="relative" width="inherit" height={180}>
        <Box ref={chartRef} width="100%" height="100%" />

        <Box
          top="50%"
          left="50%"
          textAlign="center"
          position="absolute"
          sx={{ transform: "translate(-50%, -50%)" }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            fontSize={Math.max(fontSize, 14)}
          >
            {secondary}
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            color="text.secondary"
          >
            {primary}
          </Typography>
        </Box>
      </Box>

      <Stack>
        {data.map((item) => {
          return (
            <Stack key={item.name}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Box
                  width={12}
                  height={12}
                  borderRadius="2px"
                  bgcolor={item.color}
                />
                <Typography variant="caption" color="text.secondary">
                  {item.name}
                </Typography>
              </Stack>

              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ pl: 2.5 }}
              >
                {item.label}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
