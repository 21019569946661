import { FC, useEffect } from "react";
import { Divider, LinearProgress, Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { KubernetesLiveUsageHeader } from "./components/header/KubernetesLiveUsageHeader";
import { KubernetesLiveUsageSidebarNavigation } from "./components/navigation/sidebar-navigation/KubernetesLiveUsageSidebarNavigation";
import { KubernetesLiveUsageResourcesArea } from "./components/resources/KubernetesLiveUsageResourcesArea";
import { pageHeaderHeight } from "../common/PageHeader";
import { accountIdsSearchParam } from "../live-usage-mgmt/utils/constants/searchParams";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { initiateKubernetesLiveUsageMgmtThunk } from "../../../store/kubernetes-live-usage-mgmt/thunks/initiateKubernetesLiveUsageMgmtThunk";
import { kubernetesLiveUsagePageStateSelector } from "../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsagePageStateSelector";
import { kubernetesLiveUsageMgmtIsPageLoadingSelector } from "../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtIsPageLoadingSelector";
import { LiveUsageSidebarPanel } from "../live-usage-mgmt/components/LiveUsageSidebarPanel";

export const KubernetesLiveUsageMgmt: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const clusterIds = searchParams.get(accountIdsSearchParam);
  const pageState = useAppSelector(kubernetesLiveUsagePageStateSelector);

  const isPageLoading = useAppSelector(
    kubernetesLiveUsageMgmtIsPageLoadingSelector,
  );

  useEffect(() => {
    if (!clusterIds) {
      return;
    }

    dispatch(
      initiateKubernetesLiveUsageMgmtThunk({
        clusterIds,
      }),
    );
  }, [clusterIds, dispatch]);

  if (pageState !== "fulfilled") {
    return <LinearProgress />;
  }

  return (
    <Stack height={`calc(100% - ${pageHeaderHeight}px)`}>
      {isPageLoading && <LinearProgress />}
      <KubernetesLiveUsageHeader />
      <PanelGroup direction="horizontal" autoSaveId="account-page-navigation">
        <LiveUsageSidebarPanel>
          {KubernetesLiveUsageSidebarNavigation}
        </LiveUsageSidebarPanel>

        <PanelResizeHandle>
          <Divider orientation="vertical" />
        </PanelResizeHandle>

        <Panel style={{ overflowY: "auto" }}>
          <KubernetesLiveUsageResourcesArea />
        </Panel>
      </PanelGroup>
    </Stack>
  );
};
