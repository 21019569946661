import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { useDidMount } from "rooks";
import { AzureSavingsPlanDrawerContentHeaderInfoCards } from "./AzureSavingsPlanDrawerContentHeaderInfoCards";
import { AzureSavingsPlanDrawerChartHeader } from "./AzureSavingsPlanDrawerChartHeader";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { commitmentsDetailsDrawerTargetIdSelector } from "../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";
import { getAzureUtilizationAndCoverageDetailsThunk } from "../../../../../../../../store/commitments/thunks/azure/getAzureUtilizationAndCoverageDetailsThunk";
import { CommitmentsDrawerHeader } from "../../../../../common/components/drawer/header/CommitmentsDrawerHeader";
import { AzureUtilizationAndCoverageDrawerChart } from "../common/AzureUtilizationAndCoverageDrawerChart";
import { AzureUtilizationAndCoverageDrawerDataGrid } from "../common/data-grid/AzureUtilizationAndCoverageDrawerDataGrid";
import { CommitmentsDrawerContent } from "../../../../../common/components/drawer/CommitmentsDrawerContent";

interface AzureSavingsPlanDrawerContentProps {
  onClose(): void;
}

export const AzureSavingsPlanDrawerContent: FC<
  AzureSavingsPlanDrawerContentProps
> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const savingsPlanId = useAppSelector(
    commitmentsDetailsDrawerTargetIdSelector,
  );

  useDidMount(() => {
    dispatch(getAzureUtilizationAndCoverageDetailsThunk());
  });

  return (
    <Fragment>
      <CommitmentsDrawerHeader title={savingsPlanId} onClose={onClose}>
        <AzureSavingsPlanDrawerContentHeaderInfoCards />
      </CommitmentsDrawerHeader>

      <Box width="80dvw" bgcolor="grey.50" height="100%">
        <CommitmentsDrawerContent
          title="Utilization"
          icon="utilization"
          padding="16px 16px 0px 16px"
        >
          <AzureUtilizationAndCoverageDrawerChart
            ChartHeader={AzureSavingsPlanDrawerChartHeader}
          />

          <AzureUtilizationAndCoverageDrawerDataGrid viewTab="commitment" />
        </CommitmentsDrawerContent>
      </Box>
    </Fragment>
  );
};
