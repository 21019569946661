import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { ReservedInstancesDataGrid } from "./ReservedInstancesDataGrid";
import { ReservationsCoverageDataGrid } from "./ReservationsCoverageDataGrid";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsDataViewTabSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";
import { CommitmentsGridLoadingLayer } from "../../../../common/components/data-grid/CommitmentsGridLoadingLayer";
import { reservationsForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/reservations/data/loading/reservationsForTableLoadingSelector";
import { ReservationsRecommendationsDataGrid } from "../recomendations/ReservationsRecommendationsDataGrid";

export const ReservationsDataGrid: FC = () => {
  const tab = useAppSelector(commitmentsDataViewTabSelector);
  const loading = useAppSelector(reservationsForTableLoadingSelector);

  const enableReservationRecommendation = useFlag(
    "EnableReservationRecommendation",
  );

  if (tab === "recommendations") {
    if (enableReservationRecommendation) {
      return (
        <Box flex={1}>
          <ReservationsRecommendationsDataGrid />
        </Box>
      );
    }
    return null;
  }

  return (
    <Stack position="relative" zIndex={1} flex={1}>
      {loading && <CommitmentsGridLoadingLayer />}

      {tab === "commitment" && <ReservedInstancesDataGrid />}

      {tab === "coverage" && <ReservationsCoverageDataGrid />}
    </Stack>
  );
};
