import { FC, useMemo } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import Stack from "@mui/material/Stack";
import { IconButton, Link, Tooltip } from "@mui/material";
import { PlayCircleOutlined } from "@mui/icons-material";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { CopyIconButton } from "../../../../common/CopyIconButton";
import { useHover } from "../../../../../utils/hooks/useHover.hook";
import { getParentResourceType } from "../../../../../utils/helpers/resources/getParentResourceType";
import { opportunityTypeTitlesByActionType } from "../../utils/constants/common";
import { generateLiveResourcesPath } from "../../../accounts-group/utils/helpers/generateLiveResourcesPath";
import { SavingsOpportunityFilterSetIcon } from "../SavingsOpportunityFilterSetIcon";
import { autoGroupColumnId } from "../../../task-management/components/grid/auto-group-column/getAutoGroupColumnDef";

export const SavingsOpportunityActionTypeCellRenderer: FC<
  CustomCellRendererProps<SavingsOpportunity>
> = (cell) => {
  const { ref, hovered } = useHover();

  const colId = cell.column?.getColId() || cell.colDef?.colId;
  const inGroup = colId === autoGroupColumnId;

  const data = cell.data;
  const title = data?.action_type
    ? opportunityTypeTitlesByActionType[data.action_type]
    : "";
  const parentResourceType = useMemo(
    () => (data?.service ? getParentResourceType(data?.service) : ""),
    [data?.service],
  );
  const resourceType =
    parentResourceType !== data?.service ? parentResourceType : data?.service;
  const resourceIdParam =
    parentResourceType !== data?.service ? "" : data.resource_id;

  if (!data) {
    return null;
  }

  if (inGroup) {
    return <TypographyWithTooltip title={title} variant="body2" />;
  }

  return (
    <Stack direction="row" spacing={0.5} alignItems="center" ref={ref}>
      <TypographyWithTooltip title={title} variant="body2" />

      {data.action_type === "live_filtered_resource" && data.filter_set_id && (
        <SavingsOpportunityFilterSetIcon
          filterSetId={data.filter_set_id}
          provider={data.cloud_provider}
          accountId={data?.account?.id ?? ""}
          resourceType={data.service}
        />
      )}

      <CopyIconButton
        data={title}
        visible={hovered}
        TooltipProps={{ placement: "top" }}
      />

      {data.account &&
        data.action_type === "live_filtered_resource" &&
        hovered && (
          <Tooltip title="View in Live Resources." arrow placement="top">
            <IconButton
              size="small"
              component={Link}
              href={generateLiveResourcesPath({
                provider: data.account.provider,
                activeTab: "live-resources",
                accountIds: data.account.id,
                resourceIds: resourceIdParam ?? undefined,
                resourceType: resourceType || undefined,
              })}
              target="_blank"
            >
              <PlayCircleOutlined fontSize="small" color="action" />
            </IconButton>
          </Tooltip>
        )}

      {data.action_type === "off_hours" && data.resource_type && hovered && (
        <Tooltip title="View in Off Hours page" arrow placement="top">
          <IconButton
            size="small"
            component={Link}
            href={`/recommendations/off-hours/${data.resource_type}?id=${data.recommendation_id}`}
            target="_blank"
          >
            <HistoryToggleOffOutlinedIcon fontSize="small" color="action" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};
