import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { allAccountsGroupedByProviderSelector } from "../../../accounts/selectors/all-providers/allAccountsGroupedByProviderSelector";
import { ResourceFilters } from "../../../../services/cloudchipr.api";
import { liveUsageMgmtProviderSelector } from "../../selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtAccountIdsSelector } from "../../selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { orgCurrentFilterTemplatesSelector } from "../../selectors/org-filter-templates/orgCurrentFilterTemplatesSelector";
import {
  setLiveUsageMgmtAppliedFilters,
  setLiveUsageMgmtAppliedFilterTemplateId,
} from "../../liveUsageMgmtSlice";
import { liveUsageMgmtDefaultFilterTemplateSelector } from "../../selectors/filter-template/liveUsageMgmtDefaultFilterTemplateSelector";

export const findAndApplyFilterTemplatesThunk = createAsyncThunk(
  "liveUsageMgmt/findAndSetAppliedFilterTemplateId",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const provider = liveUsageMgmtProviderSelector(state);
    const accountIds = liveUsageMgmtAccountIdsSelector(state);
    const accountsByProvider = allAccountsGroupedByProviderSelector(state);
    const filterTemplates = orgCurrentFilterTemplatesSelector(state);
    if (!provider || !accountIds) {
      return;
    }
    const accounts = accountsByProvider?.[provider] ?? [];

    const filterSetIds = accounts.reduce((result, item) => {
      if (
        accountIds.includes(item.id) &&
        item.filter_set_id &&
        !result.includes(item.filter_set_id)
      ) {
        result.push(item.filter_set_id);
      }
      return result;
    }, [] as string[]);

    let filterTemplateData: ResourceFilters[] = [];

    if (filterSetIds.length === 1 && filterSetIds[0]) {
      filterTemplateData =
        filterTemplates?.find((item) => item.id === filterSetIds[0])?.filters ??
        [];
      dispatch(setLiveUsageMgmtAppliedFilterTemplateId(filterSetIds[0]));
    } else {
      const defaultFilterTemplate =
        liveUsageMgmtDefaultFilterTemplateSelector(state);
      if (defaultFilterTemplate?.id) {
        dispatch(
          setLiveUsageMgmtAppliedFilterTemplateId(defaultFilterTemplate?.id),
        );
        filterTemplateData = defaultFilterTemplate?.filters ?? [];
      }
    }

    if (filterTemplateData) {
      filterTemplateData.forEach((item) => {
        dispatch(setLiveUsageMgmtAppliedFilters(item));
      });
    }

    return true;
  },
);
