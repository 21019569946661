import { costAnomaliesWidgetDataResponseSelector } from "./costAnomaliesWidgetDataResponseSelector";
import { RootState } from "../../../../../store";

export const costAnomaliesWidgetPrevPeriodCostDateDetailsSelector = (
  state: RootState,
  widgetId: string,
) => {
  const response = costAnomaliesWidgetDataResponseSelector(state, widgetId);

  return response?.data?.previous_period_cost_date_details;
};
