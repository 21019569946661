import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import {
  AccountId,
  ResourceFilters,
  cloudChiprApi,
} from "../../../../services/cloudchipr.api";
import { liveUsageMgmtProviderSelector } from "../../selectors/store-selectors/liveUsageMgmtProviderSelector";
import { getAccountsThunk } from "../../../accounts/thunks/getAccountsThunk";
import { findAndApplyFilterTemplatesThunk } from "../filters/findAndApplyLiveUsageMgmtFiltersThunk";
import { getOrganisationCurrentFilterTemplatesThunk } from "../getOrganisationCurrentFilterTemplatesThunk";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface CreateLiveUsageMgmtFilterTemplateByAccountIdsThunkArgs {
  name: string;
  accountIds: AccountId[];
  filters: ResourceFilters[];
}
export const createLiveUsageMgmtFilterTemplateByAccountIdsThunk =
  createAsyncThunk(
    "liveUsageMgmt/createLiveUsageMgmtFilterTemplateByAccountIds",
    async (
      {
        name,
        filters,
        accountIds,
      }: CreateLiveUsageMgmtFilterTemplateByAccountIdsThunkArgs,
      { dispatch, getState },
    ) => {
      const state = getState() as RootState;

      const provider = liveUsageMgmtProviderSelector(state);

      const { postUsersMeOrganisationsCurrentFilterSetsV2 } =
        cloudChiprApi.endpoints;

      if (!provider) {
        return;
      }

      try {
        const response = await dispatch(
          postUsersMeOrganisationsCurrentFilterSetsV2.initiate({
            body: {
              name,
              filters,
              cloud_provider: provider,
              accounts_to_apply: accountIds ?? null,
            },
          }),
        ).unwrap();

        if (response?.id) {
          await dispatch(getAccountsThunk());
          await dispatch(getOrganisationCurrentFilterTemplatesThunk());
          dispatch(findAndApplyFilterTemplatesThunk());
        }

        return response;
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  );
