import { FC, useState } from "react";
import { Box, Card } from "@mui/material";
import { AzureUtilizationAndCoverageMultiTypeChart } from "./chart/multi-type-chart/AzureUtilizationAndCoverageMultiTypeChart";
import { AzureUtilizationAndCoverageGaugeChart } from "./chart/AzureUtilizationAndCoverageGaugeChart";
import { AzureUtilizationAndCoverageData } from "./data-grid/AzureUtilizationAndCoverageData";
import { AzureUtilizationAndCoverageMultiTypeChartMetadata } from "./chart/multi-type-chart/AzureUtilizationAndCoverageMultiTypeChartMetadata";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsChartTypeSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsChartTypeSelector";
import { CommitmentsScreenSplitButtons } from "../../../../common/components/CommitmentsScreenSplitButtons";
import { PossibleVisibilities } from "../../../../../common/ScreenSplitButtons";

export const AzureUtilizationAndCoverageContent: FC = () => {
  const [visibleContent, setVisibleContent] =
    useState<PossibleVisibilities>("chart_grid");
  const chartType = useAppSelector(commitmentsChartTypeSelector);

  return (
    <Card
      sx={{ p: 2, display: "flex", flex: 1, flexDirection: "column" }}
      variant="outlined"
    >
      {(chartType !== "gauge" || visibleContent === "grid") && (
        <AzureUtilizationAndCoverageMultiTypeChartMetadata />
      )}

      {visibleContent !== "grid" && (
        <Box position="relative" zIndex={2} mb={2}>
          <AzureUtilizationAndCoverageMultiTypeChart />
          <AzureUtilizationAndCoverageGaugeChart />
        </Box>
      )}

      <CommitmentsScreenSplitButtons
        provider="azure"
        visibleContent={visibleContent}
        setVisibleContent={setVisibleContent}
      />

      {visibleContent !== "chart" && (
        <Box mt={2} display="flex" flex={1}>
          <AzureUtilizationAndCoverageData />
        </Box>
      )}
    </Card>
  );
};
