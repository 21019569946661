import { FC, useCallback } from "react";
import { LinearProgress, Popover, PopoverOrigin, Stack } from "@mui/material";
import { WidgetCardHeaderEditPopoverActions } from "./WidgetCardHeaderEditPopoverActions";
import { WidgetType } from "../../../../../../../../services/cloudchipr.api";
import { setWidgetSetup } from "../../../../../../../../store/dashboards/dashboardsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { BillingSummaryEditPopoverContent } from "../../../billing-summary/BillingSummaryEditPopoverContent";
import { BillingExplorerWidgetEditPopoverContent } from "../../../billing-explorer-widget/components/BillingExplorerWidgetEditPopoverContent";
import { CostAnomaliesEditPopoverContent } from "../../../cost-anomalies/components/CostAnomaliesEditPopoverContent";
import { CommitmentUtilizationWidgetEditPopoverContent } from "../../../commitment-utlization/components/CommitmentUtilizationWidgetEditPopoverContent";
import { widgetDataLoadingSelector } from "../../../../../../../../store/dashboards/selectors/setups/common/widgetDataLoadingSelector";
import { CoverageWidgetEditPopoverContent } from "../../../coverage/components/CoverageWidgetEditPopoverContent";

interface WidgetCardHeaderEditPopoverProps {
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  widgetType: WidgetType;
}

export const WidgetCardHeaderEditPopover: FC<
  WidgetCardHeaderEditPopoverProps
> = ({ open, widgetType, anchor, closeMenu }) => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(widgetDataLoadingSelector);

  const closeHandler = useCallback(() => {
    closeMenu();
    dispatch(setWidgetSetup(null));
  }, [dispatch, closeMenu]);

  return (
    <Popover
      open={open}
      onClose={closeHandler}
      anchorEl={anchor}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <Stack p={2} spacing={2} minWidth={350}>
        {loading && <LinearProgress />}

        {widgetType === "cost_and_usage_summary" && (
          <BillingSummaryEditPopoverContent />
        )}

        {widgetType === "cost_breakdown" && (
          <BillingExplorerWidgetEditPopoverContent />
        )}

        {widgetType === "largest_cost_changes" && (
          <CostAnomaliesEditPopoverContent />
        )}

        {widgetType === "commitments_utilization" && (
          <CommitmentUtilizationWidgetEditPopoverContent />
        )}

        {widgetType === "coverage" && <CoverageWidgetEditPopoverContent />}

        <WidgetCardHeaderEditPopoverActions
          widgetType={widgetType}
          onClose={closeMenu}
        />
      </Stack>
    </Popover>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
