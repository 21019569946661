import { FC } from "react";
import { Stack } from "@mui/material";
import { BillingSummaryDataGridType } from "./utils/types";
import { BillingSummaryWidgetViewItem } from "./BillingSummaryWidgetViewItem";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { WidgetContentInvalidState } from "../../common/WidgetContentInvalidState";
import { WidgetNumeralView } from "../../common/view-type/WidgetNumeralView";
import { DateLabelNullable } from "../../../../../../../../../services/cloudchipr.api";
import { billingSummarySumSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-summary/billingSummarySumSetupPropertyByKeySelector";

interface BillingSummaryWidgetViewProps {
  loading: boolean;
  data: BillingSummaryDataGridType[];
  dateLabel?: DateLabelNullable;
  totalCostUrl?: string;
}

export const BillingSummaryWidgetView: FC<BillingSummaryWidgetViewProps> = ({
  loading,
  data,
  dateLabel,
  totalCostUrl,
}) => {
  const filter = useAppSelector(
    billingSummarySumSetupPropertyByKeySelector("filter"),
  );

  if (filter?.length === 0 && !loading) {
    return (
      <Stack p={2} flex={1}>
        <WidgetContentInvalidState message="Select at least one metric from the list to get started." />
      </Stack>
    );
  }

  return (
    <WidgetNumeralView loading={loading} skeletonCount={6}>
      {data?.map((item) => (
        <BillingSummaryWidgetViewItem
          key={item.id}
          dateLabel={dateLabel}
          itemsCount={data.length}
          totalCostUrl={totalCostUrl}
          {...item}
        />
      ))}
    </WidgetNumeralView>
  );
};
