import { createAsyncThunk } from "@reduxjs/toolkit";
import { generateKubernetesFilterTreeByFilters } from "./utils/helpers/generateKubernetesFilterTreeByFilters";
import {
  cloudChiprApi,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { kubernetesLiveUsageMgmtSelectedClusterIdsSelector } from "../../selectors/store-selectors/kubernetesLiveUsageMgmtSelectedClusterIdsSelector";
import { RootState } from "../../../store";

export const getKubernetesResourceTypeDataWithoutFiltersFixedCacheKey = (
  type: ResourceType,
) => {
  return `postUsersMeOrganisationsCurrentKubernetesByTypeResources-${type}-without-filters`;
};

export const getKubernetesLiveUsageMgmtResourcesWithOutFiltersByResourceTypeThunk =
  createAsyncThunk(
    "liveUsageMgmt/getKubernetesLiveUsageMgmtResourcesWithOutFiltersByResourceType",
    async (resourceType: ResourceType, { dispatch, getState }) => {
      const state = getState() as RootState;
      const type =
        getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;
      const clusterIds =
        kubernetesLiveUsageMgmtSelectedClusterIdsSelector(state);

      if (!clusterIds) {
        return;
      }
      const clusterFilter = generateKubernetesFilterTreeByFilters({
        filter: null,
        clusterIds,
      });
      dispatch(
        cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentKubernetesByTypeResources.initiate(
          {
            type: type,
            body: {
              filter: clusterFilter,
            },
          },
          {
            fixedCacheKey:
              getKubernetesResourceTypeDataWithoutFiltersFixedCacheKey(type),
          },
        ),
      ).unwrap();
    },
  );
