import { FC, Fragment } from "react";
import { Divider, List, ListItem, Stack, Typography } from "@mui/material";
import { NestedIntegration } from "../../../../../../../../services/cloudchipr.api";

interface CommitmentsUtilizationCustomMessageBeforeMessageComponentProps {
  type: NestedIntegration["type"];
}

export const CommitmentsUtilizationCustomMessageBeforeMessageComponent: FC<
  CommitmentsUtilizationCustomMessageBeforeMessageComponentProps
> = ({ type }) => {
  return (
    <Fragment>
      {type === "email" ? (
        <Fragment>
          <Typography variant="body1">
            Subject:{" "}
            <Typography component="span" fontWeight="medium">
              Commitment Utilization is Below {`{{ 80%}}`} - {`{{Alert Name}}`}
            </Typography>
          </Typography>

          <Divider />

          <Typography variant="body1">Dear Customer,</Typography>

          <Typography variant="body1">
            Utilization of the{" "}
            <Typography component="span" fontWeight="medium">
              {`{{Commitment ID}}`}
            </Typography>{" "}
            has dropped below{" "}
            <Typography component="span" fontWeight="medium">
              {`{{80%}}`}
            </Typography>
            .
          </Typography>
        </Fragment>
      ) : (
        <Typography variant="body1">
          Commitment Utilization Below 80% -{" "}
          <Typography component="span" fontWeight="medium">
            {`{{Alert Name}}`}
          </Typography>
        </Typography>
      )}

      <Stack gap={0.5}>
        <Typography variant="body1" fontWeight="medium">
          Details
        </Typography>
        <List sx={{ listStyleType: "disc", p: 0, m: 0 }}>
          {commitmentsUtilizationDetailsData.map(({ key, value }) => (
            <ListItem sx={{ display: "list-item", p: 0, ml: 3 }} key={key}>
              <Typography variant="body1">
                {key}{" "}
                <Typography component="span" fontWeight="medium">
                  {value}
                </Typography>
              </Typography>
            </ListItem>
          ))}
        </List>
      </Stack>

      <Divider />
    </Fragment>
  );
};

const commitmentsUtilizationDetailsData = [
  { key: "Alert Name:", value: "{{Alert Name}}" },
  { key: "Organization:", value: "{{Organization ID}}" },
  { key: "Alert Type:", value: "{{Commitments Utilization}}" },
  { key: "Commitment:", value: "{{Commitment ID}}" },
  { key: "Threshold:", value: "{{80%}}" },
  { key: "Current Utilization:", value: "{{79%}}" },
];
