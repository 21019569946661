import { FC } from "react";
import { Stack } from "@mui/material";
import { CoverageWidgetChartTypeSelect } from "./components/CoverageWidgetChartTypeSelect";
import { CoverageWidgetChartBaseSelect } from "./components/CoverageWidgetChartBaseSelect";
import { CoverageWidgetVisualizationCovered } from "./components/CoverageWidgetVisualizationCovered";
import { CoverageWidgetVisualizationNotCovered } from "./components/CoverageWidgetVisualizationNotCovered";
import { CoverageWidgetVisualizationCoverage } from "./components/CoverageWidgetVisualizationCoverage";
import { WidgetConfigSection } from "../../../../../common/widget-configurations/WidgetConfigSection";
import VisualizationIcon from "../../../../../../../../../../../../assets/images/icons/visualization_icon.svg";
import { useAppSelector } from "../../../../../../../../../../../../store/hooks";
import { coverageSetupVisualizationChartTypeSelector } from "../../../../../../../../../../../../store/dashboards/selectors/setups/coverage/visualization/coverageSetupVisualizationChartTypeSelector";
import {
  ChartType,
  WidgetCoverageVisualization,
} from "../../../../../../../../../../../../services/cloudchipr.api";

export const CoverageWidgetVisualizationSection: FC = () => {
  const chartType = useAppSelector(coverageSetupVisualizationChartTypeSelector);

  return (
    <WidgetConfigSection
      initialExpanded
      title="Visualization"
      icon={<img src={VisualizationIcon} alt="Visualization Icon" />}
    >
      <Stack spacing={1.5}>
        <CoverageWidgetChartTypeSelect />

        {coverageVisualizationDetailsByChartType.get(chartType)?.map((type) => {
          const Component =
            coverageVisualizationDetailsComponentByType.get(type);

          if (!Component) {
            return;
          }

          return <Component key={type} />;
        })}
      </Stack>
    </WidgetConfigSection>
  );
};

export const coverageVisualizationDetailsByChartType = new Map<
  ChartType,
  (keyof WidgetCoverageVisualization)[]
>([
  ["stack", ["chart_base_type"]],
  ["horizontal_stack_bar", ["chart_base_type"]],
  ["bar", ["chart_base_type"]],
  ["table", ["coverage", "covered", "not_covered"]],
]);

export const coverageVisualizationDetailsComponentByType = new Map<
  keyof WidgetCoverageVisualization,
  FC
>([
  ["chart_base_type", CoverageWidgetChartBaseSelect],
  ["covered", CoverageWidgetVisualizationCovered],
  ["not_covered", CoverageWidgetVisualizationNotCovered],
  ["coverage", CoverageWidgetVisualizationCoverage],
]);
