import { FC } from "react";
import { Stack } from "@mui/material";
import { UnauthorizedCostAnomaliesWidget } from "./UnauthorizedCostAnomaliesWidget";
import { DashboardWidgetLargestCostChanges } from "../../../../../services/cloudchipr.api";
import { UnauthorizedWidgetHookArguments } from "../../utils/types/types";
import { useUnauthorizedCostAnomaliesHook } from "../../utils/hooks/useUnauthorizedCostAnomaliesHook";
import { UnauthorizedWidgetHeader } from "../UnauthorizedWidgetHeader";
import { WidgetNumeralView } from "../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { costAnomaliesDefaultData } from "../../../../../store/dashboards/utils/constants/costAnomaliesDefaultData";

interface UnauthorizedCostAnomaliesWidgetWrapperProps
  extends DashboardWidgetLargestCostChanges,
    Omit<UnauthorizedWidgetHookArguments, "widgetId"> {
  onWidgetMount?(): void;
}

export const UnauthorizedCostAnomaliesWidgetWrapper: FC<
  UnauthorizedCostAnomaliesWidgetWrapperProps
> = ({
  id,
  organisationId,
  categoryId,
  overrideQueryDates,
  name,
  dates,
  grouping,
  ...props
}) => {
  const { data, isFetching, error, isError, isUninitialized } =
    useUnauthorizedCostAnomaliesHook({
      widgetId: id,
      categoryId,
      organisationId,
      overrideQueryDates,
    });

  if (isFetching || isUninitialized) {
    return (
      <Stack flexGrow={1} overflow="auto">
        <UnauthorizedWidgetHeader
          name={name}
          grouping={grouping}
          dates={dates}
          organisationId={organisationId}
        />
        <WidgetNumeralView
          loading
          skeletonCount={costAnomaliesDefaultData.itemsCount}
        ></WidgetNumeralView>
      </Stack>
    );
  }

  return (
    <UnauthorizedCostAnomaliesWidget
      {...props}
      data={data}
      isError={isError}
      error={error}
      id={id}
      name={name}
      grouping={grouping}
      dates={dates}
      organisationId={organisationId}
    />
  );
};
