import { billingExplorerWidgetByIdSelector } from "./billingExplorerWidgetByIdSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGroupingNullable } from "../../../../../../services/cloudchipr.api";

export const billingExplorerWidgetGroupingSelector = (
  state: RootState,
  widgetId: string,
): ResourceExplorerGroupingNullable | undefined => {
  const widgetData = billingExplorerWidgetByIdSelector(state, widgetId);

  return widgetData?.grouping;
};
