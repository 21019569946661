import { FC, useCallback } from "react";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { UniqueIdentifier } from "@dnd-kit/core";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useParams } from "react-router-dom";
import { DashboardV2FolderActionButton } from "../folder-actions-menu/DashboardV2FolderActionButton";
import { DashboardV2ItemActionButton } from "../item-actions-menu/DashboardV2ItemActionButton";
import { SortableTree } from "../../../../sortable-tree/SortableTree";
import { NavigationVisibilitySection } from "../../../../hierarchy/visibility/NavigationVisibilitySection";
import {
  SortableTreeFolderActionArgs,
  SortableTreeItemActionArgs,
  SortableTreeOnchangeArgs,
  TreeItems,
} from "../../../../sortable-tree/utils/types";
import { setNavigationDashboard } from "../../../../../../../store/navigation/navigationSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { updateDashboardVisibilityHierarchyThunk } from "../../../../../../../store/dashboards/thunks/dashboard-hierarchy-visibility/updateDashboardVisibilityHierarchyThunk";
import { navigationDashboardVisibilitySelectedSelector } from "../../../../../../../store/navigation/selectors/dashboard/navigationDashboardVisibilitySelectedSelector";
import { useAppAbility } from "../../../../../../../services/permissions";
import { DashboardNavigationAddDashboardNavItem } from "../DashboardNavigationAddDashboardNavItem";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { useAppNavOverlayContext } from "../../../../../utils/useAppNavOverlay.context";

interface DashboardVisibilityItemProps {
  visibility: NavigationItemsVisibilityType;
  data?: TreeItems;
}

export const DashboardVisibilityItem: FC<DashboardVisibilityItemProps> = ({
  visibility,
  data,
}) => {
  const { dashboardId: activeDashboardId } = useParams<{
    dashboardId: string;
  }>();

  const { onClose } = useAppNavOverlayContext();
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();

  const cannotCreateDashboard = cannot("create", "dashboard");

  const selected = useAppSelector((state) =>
    navigationDashboardVisibilitySelectedSelector(
      state,
      visibility,
      activeDashboardId,
    ),
  );

  const renderFolderAction = useCallback(
    ({ id, name, containsProtectedItem }: SortableTreeFolderActionArgs) => {
      return (
        <DashboardV2FolderActionButton
          id={id}
          name={name}
          visibility={visibility}
          containsProtectedItem={containsProtectedItem}
        />
      );
    },
    [visibility],
  );

  const renderItemAction = useCallback(
    ({
      id,
      name,
      parentId,
      protectedBy,
      protectionDetails,
    }: SortableTreeItemActionArgs) => {
      return (
        <DashboardV2ItemActionButton
          id={id}
          name={name}
          folderId={(parentId as string) ?? undefined}
          visibility={visibility}
          renderReportsBySourceActionItem
          protectedBy={protectedBy}
          protectionDetails={protectionDetails}
        />
      );
    },
    [visibility],
  );

  const hierarchyChangeHandler = useCallback(
    ({ index, id, parentId, items, type }: SortableTreeOnchangeArgs) => {
      if (!items) {
        return;
      }
      dispatch(setNavigationDashboard({ data: items, type: visibility }));
      dispatch(
        updateDashboardVisibilityHierarchyThunk({
          id: id as string,
          index,
          folderId: (parentId as string) ?? undefined,
          visibility,
          type: type === "item" ? "dashboard" : "folder",
        }),
      );
    },
    [dispatch, visibility],
  );

  const resetHandler = useCallback(() => {
    dispatch(
      setNavigationDashboard({
        data: null,
        type: visibility,
      }),
    );
  }, [dispatch, visibility]);

  const navigateToHandler = useCallback((id: UniqueIdentifier) => {
    return `/dashboards/${id}`;
  }, []);

  const handleTreeItemClick = useCallback(() => {
    onClose?.();
  }, [onClose]);

  if (!data) {
    return null;
  }

  return (
    <NavigationVisibilitySection
      expanded={!activeDashboardId}
      selected={selected}
      visibility={visibility}
    >
      {data?.length ? (
        <SortableTree
          items={data}
          renderItemAction={renderItemAction}
          renderFolderAction={renderFolderAction}
          onChange={hierarchyChangeHandler}
          getNavigateTo={navigateToHandler}
          onReset={resetHandler}
          icon={GridViewOutlinedIcon}
          rightIcon={
            visibility === "visible_only_to_me" ? LockOutlinedIcon : undefined
          }
          emptyText="No dashboards inside"
          disabled={cannotCreateDashboard}
          onTreeItemClick={handleTreeItemClick}
        />
      ) : (
        <TypographyWithTooltip
          variant="caption"
          color="text.secondary"
          title="No folders and dashboards inside"
          py={0.5}
          pl={4}
        />
      )}
      <DashboardNavigationAddDashboardNavItem visibility={visibility} />
    </NavigationVisibilitySection>
  );
};
