import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { TotalCostColumnHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/TotalCostColumnHeaderCell";
import { billingExplorerWidgetSetupTotalCostSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupTotalCostSelector";

export const BillingExplorerWidgetSetupTableTotalCostHeaderCell: FC = () => {
  const total = useAppSelector(billingExplorerWidgetSetupTotalCostSelector);

  return (
    <TotalCostColumnHeaderCell
      totalCost={total?.cost ?? 0}
      dateDetails={total?.dateDetails}
    />
  );
};
