import { FC } from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { ResourceTypeCoverage } from "../../../../../../../services/cloudchipr.api";
import { commitmentsResourceTypeNames } from "../../../../common/utils/constants/labels";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { CommitmentsProgressBar } from "../../../../common/components/CommitmentsProgressBar";
import { money } from "../../../../../../../utils/numeral/money";

interface CommitmentsOverviewCoverageTableBodyProps {
  data: ResourceTypeCoverage[] | null;
}

export const CommitmentsOverviewCoverageTableBody: FC<
  CommitmentsOverviewCoverageTableBodyProps
> = ({ data }) => {
  return (
    <TableBody sx={{ pt: 1 }}>
      {data?.map((item) => {
        return (
          <TableRow
            key={item.resource_type + item.uncovered_usage + item.coverage}
          >
            <TableCell sx={{ ...tableCellSx }}>
              <TypographyWithTooltip
                title={
                  commitmentsResourceTypeNames?.[item.resource_type] ??
                  item.resource_type
                }
                variant="body2"
                fontWeight="bold"
              />
            </TableCell>
            <TableCell sx={{ ...tableCellSx }}>
              <CommitmentsProgressBar
                value={item.coverage}
                overrideColor="info.light"
                width="90%"
              />
            </TableCell>
            <TableCell sx={{ ...tableCellSx }}>
              <TypographyWithTooltip
                variant="body2"
                fontWeight="medium"
                textAlign="end"
                title={
                  item.uncovered_usage === null
                    ? "N/A"
                    : money(item.uncovered_usage)
                }
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

const tableCellSx = {
  py: 1,
  borderBottom: "none",
};
