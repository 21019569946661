import { billingSummarySetupWidgetDataResponseSelector } from "./billingSummarySetupWidgetDataResponseSelector";
import { RootState } from "../../../../../store";

export const billingSummarySetupWidgetDataLoadingSelector = (
  state: RootState,
) => {
  const response = billingSummarySetupWidgetDataResponseSelector(state);

  return !!response?.isLoading;
};
