import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { useToggle } from "rooks";
import { deepPurple } from "@mui/material/colors";
import { BillingExplorerExplainWithAiAnomalyTrendItemWrapper } from "./BillingExplorerExplainWithAiAnomalyTrendItemWrapper";
import { BillingExplorerExplainWithAiAnomalyTrendItemHeaderTitle } from "./BillingExplorerExplainWithAiAnomalyTrendItemHeaderTitle";
import {
  ProviderType,
  RepresentsACostTrend,
} from "../../../../../../../services/cloudchipr.api";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { getBillingExplorerAnomalyByIdThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer-summary/getBillingExplorerAnomalyByIdThunk";
import { billingExplorerAnomalyByIdLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/loading/billingExplorerAnomalyByIdLoadingSelector";
import { BillingExplorerExplainWithAiLoading } from "../BillingExplorerExplainWithAiLoading";
import { billingExplorerAnomalySummaryDataByIdSelector } from "../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/billingExplorerAnomalySummaryDataByIdSelector";

interface BillingExplorerExplainWithAiPanelContentTrendItemProps
  extends RepresentsACostTrend {
  provider: ProviderType;
  type: "increase" | "decrease";
}
export const BillingExplorerExplainWithAiAnomalyTrendItem: FC<
  BillingExplorerExplainWithAiPanelContentTrendItemProps
> = ({ id, ...props }) => {
  const [collapsed, toggleCollapsed] = useToggle(true);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) =>
    billingExplorerAnomalyByIdLoadingSelector(state, id),
  );
  const data = useAppSelector((state) =>
    billingExplorerAnomalySummaryDataByIdSelector(state, id),
  );

  const onCollapse = useCallback(() => {
    toggleCollapsed();
    dispatch(getBillingExplorerAnomalyByIdThunk(id));
  }, [toggleCollapsed, id, dispatch]);

  return (
    <BillingExplorerExplainWithAiAnomalyTrendItemWrapper
      headerTitle={
        <BillingExplorerExplainWithAiAnomalyTrendItemHeaderTitle
          id={id}
          {...props}
        />
      }
      collapsed={collapsed}
      onCollapse={onCollapse}
      loading={loading}
    >
      {loading ? (
        <Stack p={1} pl={2}>
          <BillingExplorerExplainWithAiLoading variant="dark" />
        </Stack>
      ) : (
        <Stack
          overflow="auto"
          px={4}
          dangerouslySetInnerHTML={{ __html: data ?? "" }}
          sx={htmlStyle}
        />
      )}
    </BillingExplorerExplainWithAiAnomalyTrendItemWrapper>
  );
};

const htmlStyle = {
  "& code": {
    backgroundColor: "#EDEFF6",
    padding: 0.25,
    borderRadius: 0.5,
    color: deepPurple["500"],
  },
};
