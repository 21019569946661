import { FC, ReactNode, useCallback } from "react";
import { Box, FormControl, Select, Stack, Typography } from "@mui/material";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { VerifiedUserOutlined } from "@mui/icons-material";
import { FilterTriggerComponentProps } from "../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

export const SelectViewTrigger: FC<FilterTriggerComponentProps> = ({
  onClick,
  open,
  label,
  isProtected,
}) => {
  const renderValue = useCallback(
    (value: ReactNode) => {
      if (value) {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <InsertChartOutlinedOutlinedIcon fontSize="small" />
            {isProtected && <VerifiedUserOutlined sx={{ fontSize: 16 }} />}
            <Box>{value}</Box>
          </Stack>
        );
      }
    },
    [isProtected],
  );

  return (
    <Stack>
      <Typography variant="body2" mb={1}>
        Select Billing Report*
      </Typography>
      <FormControl size="small" fullWidth>
        <Select
          onClick={onClick}
          size="small"
          open={false}
          value={label}
          IconComponent={open ? ArrowDropUpIcon : ArrowDropDownIcon}
          renderValue={renderValue}
        />
      </FormControl>
    </Stack>
  );
};
