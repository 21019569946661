import { FC } from "react";
import { slackIntegrationGridColumns } from "./slackIntegrationGridColumns";
import { useGetUsersMeIntegrationsQuery } from "../../../../../../../../services/cloudchipr.api";
import { CommunicationIntegrationsDataGrid } from "../../../common/components/grid/CommunicationIntegrationsDataGrid";
import { IntegrationsEmptyState } from "../../../../common/components/IntegrationsEmptyState";
import { integrationDetails } from "../../../common/utils/constants/integrationDetails";
import { SlackIntegrationConnectButton } from "../SlackIntegrationConnectButton";

export const SlackIntegrationGrid: FC = () => {
  const { data, isLoading } = useGetUsersMeIntegrationsQuery({
    type: "slack",
  });

  if (!isLoading && !data?.length) {
    return <EmptyState />;
  }

  return (
    <CommunicationIntegrationsDataGrid
      columnDefs={slackIntegrationGridColumns}
      data={data}
      loading={isLoading}
      uniqueKey="slackIntegrationsDataGrid"
      actionButton={<SlackIntegrationConnectButton />}
    />
  );
};

const details = integrationDetails["slack"];

const EmptyState: FC = () => (
  <IntegrationsEmptyState
    title={details.noDataDescription}
    description={details.description}
  >
    <SlackIntegrationConnectButton withStartIcon variant="contained" />
  </IntegrationsEmptyState>
);
