import { FC } from "react";
import { Stack } from "@mui/material";
import { TaskCreationName } from "./components/TaskCreationName";
import { TaskCreationMultiTaskingAlertMessage } from "./components/TaskCreationMultiTaskingAlertMessage";
import { TaskCreationDuplicatedResourcesAlertMessage } from "./components/TaskCreationDuplicatedResourcesAlertMessage";
import { TaskCreationPropertiesSection } from "./components/properties-section/TaskCreationPropertiesSection";
import { TaskCreationDescription } from "./components/TaskCreationDescription";
import { TaskCreationTargetSection } from "./components/target-section/TaskCreationTargetSection";

export const TaskCreationDrawerTaskDetails: FC = () => {
  return (
    <Stack p={2} spacing={3} overflow="auto" flex={3}>
      <TaskCreationName />

      <TaskCreationMultiTaskingAlertMessage />

      <TaskCreationDuplicatedResourcesAlertMessage />

      <TaskCreationPropertiesSection />

      <TaskCreationDescription />

      <TaskCreationTargetSection />
    </Stack>
  );
};
