import { billingExplorerWidgetTotalDataWithIdsSelector } from "./billingExplorerWidgetTotalDataWithIdsSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridDataWithId } from "../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { combineBillingExplorerWidgetGridDataWithOthers } from "../../../../utils/helpers/billing-explorer/combineBillingExplorerWidgetGridDataWithOthers";

export const billingExplorerWidgetTableViewDataSelector = (
  state: RootState,
  widgetId: string,
): ResourceExplorerGridDataWithId[] => {
  const data = billingExplorerWidgetTotalDataWithIdsSelector(state, widgetId);

  if (!data) {
    return [];
  }

  return combineBillingExplorerWidgetGridDataWithOthers(data);
};
