import { FC, SyntheticEvent } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { NavigationItemsVisibilityType } from "../../../../../store/navigation/utils/types";

interface NavigationVisibilitySectionDialogContentProps {
  value: string;
  onTextFieldChange: StandardInputProps["onChange"];
  label: string;
  visibility: NavigationItemsVisibilityType;
  disabledVisibility?: NavigationItemsVisibilityType;
  onVisibilityChange(_: SyntheticEvent, value: string): void;
}

export const NavigationVisibilitySectionDialogContent: FC<
  NavigationVisibilitySectionDialogContentProps
> = ({
  value,
  onTextFieldChange,
  label,
  visibility,
  onVisibilityChange,
  disabledVisibility,
}) => {
  const publicDisabled =
    disabledVisibility && disabledVisibility !== "visible_to_everyone";
  const privateDisabled =
    disabledVisibility && disabledVisibility !== "visible_only_to_me";

  return (
    <Stack spacing={2}>
      <TextField
        value={value}
        onChange={onTextFieldChange}
        name="name"
        label={label}
        fullWidth
        size="small"
        autoFocus
      />
      <FormControl size="small">
        <FormLabel>
          <Typography
            variant="caption"
            fontWeight="medium"
            color="text.secondary"
          >
            Visibility
          </Typography>
        </FormLabel>

        <RadioGroup row value={visibility} onChange={onVisibilityChange}>
          <FormControlLabel
            value="visible_to_everyone"
            control={<Radio size="small" sx={{ p: 0.5 }} />}
            label={
              <Typography
                variant="body2"
                color={publicDisabled ? "text.disabled" : "text.primary"}
              >
                Visible to everyone
              </Typography>
            }
            disabled={publicDisabled}
          />

          <FormControlLabel
            value="visible_only_to_me"
            control={<Radio size="small" sx={{ p: 0.5 }} />}
            label={
              <Typography
                color={privateDisabled ? "text.disabled" : "text.primary"}
                variant="body2"
              >
                Visible only to me
              </Typography>
            }
            disabled={privateDisabled}
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};
