import { widgetSetupSelector } from "../widgetSetupSelector";
import { Dates } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { isTextWidgetSetup } from "../../../utils/helpers/isTextWidgetSetup";

export const widgetSetupWidgetDatesSelector = (
  state: RootState,
): Dates | undefined => {
  const setup = widgetSetupSelector(state);

  if (setup && isTextWidgetSetup(setup)) {
    return;
  }

  return setup?.dates;
};
