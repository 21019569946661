import { FC, useCallback, useMemo, useState } from "react";
import { ViewsHierarchyMoreTabsDropdownLabel } from "./ViewsHierarchyMoreTabsDropdownLabel";
import { ViewsHierarchyMoreTabsDropdownTrigger } from "./ViewsHierarchyMoreTabsDropdownTrigger";
import { DropdownSelect } from "../../../../../common/select/dropdown-select/DropdownSelect";
import { DropdownSelectOption } from "../../../../../common/select/dropdown-select/utils/types/types";
import { View } from "../../../../../../services/cloudchipr.api";
import { useViewsHierarchyContext } from "../ViewsHierarchyProvider";

interface ViewsHierarchyMoreTabsDropdownProps {
  selectedViewId: string;
  hiddenViewsCount: number;
  onTabChange(viewId: string): void;
}

export const ViewsHierarchyMoreTabsDropdown: FC<
  ViewsHierarchyMoreTabsDropdownProps
> = ({ onTabChange, selectedViewId, hiddenViewsCount }) => {
  const { allItemsLabel, views } = useViewsHierarchyContext();

  const [forceToClose, setForceToClose] = useState(false);

  const forceCloseHandler = useCallback(() => {
    setForceToClose(true);
    setTimeout(() => setForceToClose(false), 0);
  }, []);

  const changeHandler = useCallback(
    (selectedOptions: string[]) => {
      const viewId = selectedOptions.at(0);

      onTabChange(viewId ?? "");
    },
    [onTabChange],
  );

  const renderOptions: DropdownSelectOption[] | undefined = useMemo(() => {
    const options: DropdownSelectOption[] | undefined =
      views?.map((option) => ({
        value: option.id,
        rawValue: option,
        label: (
          <ViewsHierarchyMoreTabsDropdownLabel
            view={option}
            selected={selectedViewId === option.id}
            onClose={forceCloseHandler}
          />
        ),
      })) ?? [];

    const defaultView = {
      value: "",
      rawValue: { name: allItemsLabel },
      label: (
        <ViewsHierarchyMoreTabsDropdownLabel
          view={{ id: "", name: allItemsLabel } as View}
        />
      ),
    };

    return [defaultView, ...options];
  }, [views, selectedViewId, allItemsLabel, forceCloseHandler]);

  if (!views) {
    return;
  }

  return (
    <DropdownSelect
      singleSelect
      filterFn={filterFn}
      listMaxHeight={500}
      values={[selectedViewId]}
      forceToClose={forceToClose}
      sortSelectedOptions={false}
      options={renderOptions ?? []}
      submitHandlerOnClose={changeHandler}
      label={`${hiddenViewsCount} more...`}
      TriggerComponent={ViewsHierarchyMoreTabsDropdownTrigger}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  const searchKeyword = keyword.toLowerCase().trim();

  return option?.rawValue?.name?.toLowerCase()?.trim()?.includes(searchKeyword);
};
