import { FC, useCallback, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDebounce, useEffectOnceWhen } from "rooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { costAnomaliesSetupChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-anomalies/widget-change/costAnomaliesSetupChangeThunk";
import { costAnomaliesSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/costAnomaliesSetupPropertyByKeySelector";

interface CostAnomaliesMinChangeProps {
  type: "minCostChange" | "minPercentChange";
}

export const CostAnomaliesMinChange: FC<CostAnomaliesMinChangeProps> = ({
  type,
}) => {
  const [localMinDataChange, setLocalMinDataChange] = useState<{
    state?: boolean;
    value: number | null;
  } | null>(null);

  const dispatch = useAppDispatch();
  const minDataChange = useAppSelector(
    costAnomaliesSetupPropertyByKeySelector(type),
  );

  const costChangeHandler = useCallback(
    (value: string) => {
      dispatch(
        costAnomaliesSetupChangeThunk({
          [type]: {
            state: true,
            value: +value,
          },
        }),
      );
    },
    [dispatch, type],
  );
  const setDebouncedValue = useDebounce(costChangeHandler, 1000);

  const textFieldChangeHandler = useCallback(
    (event: any) => {
      const value = event.target.value;
      setLocalMinDataChange({
        ...localMinDataChange,
        value: value,
      });
      setDebouncedValue(value);
    },
    [setDebouncedValue, localMinDataChange],
  );
  const checkboxChangeHandler = useCallback(() => {
    setLocalMinDataChange({
      value: localMinDataChange?.value ?? null,
      state: !localMinDataChange?.state,
    });
    dispatch(
      costAnomaliesSetupChangeThunk({
        [type]: {
          value: localMinDataChange?.value ? +localMinDataChange.value : null,
          state: !localMinDataChange?.state,
        },
      }),
    );
  }, [localMinDataChange, dispatch, type]);

  useEffectOnceWhen(() => {
    setLocalMinDataChange(minDataChange);
  }, !!minDataChange);

  return (
    <FormControl size="small" fullWidth>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              checked={localMinDataChange?.state ?? false}
              onChange={checkboxChangeHandler}
            />
          }
          label={
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography variant="body1">{dataByType[type].label}</Typography>
              <Tooltip title={dataByType[type].tooltip} placement="right" arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Stack>
          }
        />
        <TextField
          sx={{
            width: 120,
          }}
          size="small"
          label={dataByType[type].textFieldLabel}
          value={localMinDataChange?.value ?? ""}
          onChange={textFieldChangeHandler}
          type="number"
          disabled={!localMinDataChange?.state}
          autoFocus={true}
        />
      </Stack>
    </FormControl>
  );
};

const dataByType = {
  minCostChange: {
    tooltip: "Cost changes less than “Min cost change” will not be shown.",
    textFieldLabel: "Amount ($)",
    label: "Min cost change",
  },
  minPercentChange: {
    tooltip:
      "Cost changes less than “Min percentage change” will not be shown.",
    textFieldLabel: "Percent (%)",
    label: "Min percentage change",
  },
};
