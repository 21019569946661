import { resourceDetailsTargetResourceSelector } from "./resourceDetailsTargetResourceSelector";
import { RootState } from "../../../store";

export const resourceDetailsTargetResourceIdSelector = (
  state: RootState,
): string => {
  const resource = resourceDetailsTargetResourceSelector(state);

  return resource?.provider_unique_identifier ?? "";
};
