import { liveUsageMgmtResourceTypeDataResourceByIdSelector } from "./liveUsageMgmtResourceTypeDataResourceByIdSelector";
import { RootState } from "../../../store";
import { ResourceType, TaskStatus } from "../../../../services/cloudchipr.api";
import { MetricsFrequenciesType } from "../../../../components/pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/types/metricsFrequenciesType";
import { generateExportedResourceData } from "../../../../components/pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateExportedResourceData";

export const liveUsageMgmtGenerateExportedResourceDataByResourceId = (
  state: RootState,
  resourceType: ResourceType,
  resourceId: string,
  disabledColumns: string[] = [],
  metricsFrequencies?: MetricsFrequenciesType,
  taskStatuses?: TaskStatus[],
) => {
  const resource = liveUsageMgmtResourceTypeDataResourceByIdSelector(
    state,
    resourceType,
    resourceId,
  );

  if (!resource) {
    return null;
  }

  return generateExportedResourceData(
    resourceType,
    [resource],
    disabledColumns,
    {
      includeNestedRows: true,
      metricsFrequencies,
      taskStatuses,
    },
  );
};
