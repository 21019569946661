import {
  TargetState,
  TaskSource,
} from "../../../../../services/cloudchipr.api";

export const taskSourceLabels: Record<TaskSource, string> = {
  live_usage: "Live Resources",
  opportunity: "Savings Opportunities",
};

export const taskTargetStateLabels: Record<
  Exclude<TargetState, null>,
  string
> = {
  not_found: "Doesn't exist",
  live: "Live",
};
