import { FC, useCallback, useMemo } from "react";
import { Box, Card } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { KubernetesCluster } from "../../../../../../../services/cloudchipr.api";
import { kubernetesClustersTableColumns } from "../clusters-list/kubernetesClustersTableColumns";
import { moneyFormatter } from "../../../../../../../utils/numeral/moneyFormatter";
import { MultiTypeChartProvider } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { MultiTypeChart } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChart";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import { kubernetesClustersChartDataGenerator } from "../../../../utils/helpers/kubernetesClustersChartDataGenerator";
import { getAccountBarViewXAxisTickProps } from "../../../accounts/accounts-bar/utils/helpers/helpers";

interface KubernetesClustersBarViewProps {
  clusters: KubernetesCluster[] | null;
}

export const KubernetesClustersBarView: FC<KubernetesClustersBarViewProps> = ({
  clusters,
}) => {
  const chartData = useMemo(() => {
    return kubernetesClustersChartDataGenerator(clusters);
  }, [clusters]);

  const yAxisFormatter = useCallback((value: number | string) => {
    return moneyFormatter(+value);
  }, []);

  if (!clusters) {
    return null;
  }

  return (
    <Card variant="outlined" sx={{ px: 3, pb: 3 }}>
      <Box mt={3} mb={4}>
        <MultiTypeChartProvider
          value={{
            initialChartType: "bar",
            data: chartData,
            colors,
          }}
        >
          <MultiTypeChart
            highlight
            selectable
            yAxisLabel="Costs ($)"
            availableTypes={["bar"]}
            xAxisTickProps={getAccountBarViewXAxisTickProps(chartData.length)}
            yAxisFormatter={yAxisFormatter}
            tooltipProps={{
              showTotal: true,
            }}
          />
        </MultiTypeChartProvider>
      </Box>

      <Box
        border="1px solid"
        borderColor="grey.300"
        borderRadius={2}
        position="relative"
      >
        <DataGrid
          styles={styles}
          data={clusters}
          columns={kubernetesClustersTableColumns}
        />
      </Box>
    </Card>
  );
};

const styles = {
  tableHeaderRow: { "& th": { py: 2 }, "& th:first-of-type": { pl: 2 } },
  tableContainer: {
    "& td:first-of-type": { pl: 2 },
  },
};

const colors = {
  Idle: red[500],
  Reserved: grey[400],
};
