import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavigationItemsVisibilityType, NavigationState } from "./utils/types";
import { TreeItems } from "../../components/app-navigation/components/sortable-tree/utils/types";

const initialState: NavigationState = {
  resourceExplorer: {},
  dashboard: {},
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {
    setNavigationResourceExplorer: (
      state,
      action: PayloadAction<{
        data: TreeItems | null;
        type: NavigationItemsVisibilityType;
      }>,
    ) => {
      const { data, type } = action.payload;

      state.resourceExplorer = {
        ...state.resourceExplorer,
        [type]: data,
      };
    },
    setNavigationDashboard: (
      state,
      action: PayloadAction<{
        data: TreeItems | null;
        type: NavigationItemsVisibilityType;
      }>,
    ) => {
      const { data, type } = action.payload;

      state.dashboard = {
        ...state.dashboard,
        [type]: data,
      };
    },
    resetNavigationDashboard: (state) => {
      state.dashboard = {};
    },
    resetNavigationResourceExplorer: (state) => {
      state.resourceExplorer = {};
    },
  },
});

export const {
  setNavigationResourceExplorer,
  setNavigationDashboard,
  resetNavigationDashboard,
  resetNavigationResourceExplorer,
} = navigationSlice.actions;

export default navigationSlice.reducer;
