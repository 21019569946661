import { getBillingExplorerSummarySelector } from "../api-select/getBillingExplorerSummarySelector";
import { RootState } from "../../../../store";
import { billingExplorerExplainWithAiDataSelector } from "../../current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiDataSelector";

export const billingExplorerSummaryLoadingByIndexSelector = (
  state: RootState,
  index: number,
) => {
  const data = billingExplorerExplainWithAiDataSelector(state);
  const key = data[index]?.key;
  return getBillingExplorerSummarySelector(key ?? "")(state)?.isLoading;
};
