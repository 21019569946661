import { RootState } from "../../../../../../store";
import { azureSavingsPlansDataForGranularSelector } from "../azureSavingsPlansDataForGranularSelector";

export const azureSavingsPlansNetSavingsSelector = (
  state: RootState,
): number | null => {
  const data = azureSavingsPlansDataForGranularSelector(state);

  return data?.totals?.total_net_savings ?? null;
};
