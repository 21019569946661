import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Gke } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const gkeColumns: ColumnSetupType<Gke>[] = [
  {
    accessorKey: "name",
    header: "Cluster Name",
    type: "identifier",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "support_type",
    header: "Support Type",
    type: "supportType",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "created_at",
    header: "Launch time",
    type: "date",
  },
  {
    accessorKey: "node_pool_count",
    header: "Node Pools Count",
    size: 200,
  },
  {
    accessorKey: "nodes_count",
    header: "Node Count",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Labels", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Labels",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },

  {
    accessorKey: "task",
    header: "Task Status",
    type: "taskStatus",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "JIRA Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
