import { FC, memo } from "react";
import { CSVLink } from "react-csv";
import { IconButton, Tooltip } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { taskAvailableStatusPropertiesSelector } from "../../../../../../../../../../store/task-management/selectors/properties/taskAvailableStatusPropertiesSelector";
import { kubernetesLiveUsageMgmtGenerateExportedResourceData } from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtGenerateExportedResourceData";
import { kubernetesLiveUsageMgmtResourceTypeCsvDataFileNameSelector } from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtResourceTypeCsvDataFileNameSelector";
import { MetricsFrequenciesType } from "../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/types/metricsFrequenciesType";

interface KubernetesLiveUsageMgmtCsvDataDownloaderProps {
  resourceType: ResourceType;
  data?: Record<string, string | number>[] | null;
  metricsFrequencies?: MetricsFrequenciesType;
}

export const KubernetesLiveUsageMgmtCsvDataDownloader: FC<KubernetesLiveUsageMgmtCsvDataDownloaderProps> =
  memo(({ resourceType, data, metricsFrequencies }) => {
    const taskStatuses = useAppSelector(taskAvailableStatusPropertiesSelector);

    let csvData = useAppSelector((state) =>
      kubernetesLiveUsageMgmtGenerateExportedResourceData(
        state,
        resourceType,
        [],
        metricsFrequencies,
        taskStatuses,
      ),
    ); // TODO: check do we need to load csv data keeping sorting

    if (data) {
      csvData = data;
    }

    const fileName = useAppSelector((state) =>
      kubernetesLiveUsageMgmtResourceTypeCsvDataFileNameSelector(
        state,
        resourceType,
      ),
    );

    if (!csvData) {
      return null;
    }

    return (
      <Tooltip arrow title="Export CSV" placement="top">
        <span>
          <IconButton
            disabled={!csvData?.length}
            size="small"
            sx={{ "& a": { textDecoration: "none", color: "inherit" }, pb: 0 }}
          >
            <CSVLink
              filename={`${fileName}.csv`}
              data={csvData}
              enclosingCharacter=""
              separator=";"
            >
              <DownloadOutlinedIcon fontSize="small" />
            </CSVLink>
          </IconButton>
        </span>
      </Tooltip>
    );
  });
