import { kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeSelector } from "./kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { RootState } from "../../../store";
import {
  ProviderType,
  ResourceType,
} from "../../../../services/cloudchipr.api";

export const kubernetesLiveUsageMgmtSelectedResourcesClustersMetaDataSelector =
  (
    state: RootState,
    resourceType: ResourceType,
  ):
    | {
        name: string;
        id: string;
        provider: ProviderType;
      }[]
    | undefined => {
    const data = kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeSelector(
      state,
      resourceType,
    );

    if (!data) {
      return;
    }

    return data?.reduce(
      (result, item) => {
        const id = item.resource.cluster.id;
        const name = `${item.resource.cluster.name ?? item.resource.cluster.provider_id}`;

        const newItem = {
          name,
          provider: item.resource.cluster.provider,
          id,
        };
        if (!result?.find((item) => item.id === id)) {
          result.push(newItem);
        }
        return result;
      },
      [] as {
        id: string;
        name: string;
        provider: ProviderType;
      }[],
    );
  };
