import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsResourceTypeNames } from "../../../../common/utils/constants/labels";
import { CommitmentResourceType } from "../../../../../../../services/cloudchipr.api";
import { reservationSelectedCoverageDataSelector } from "../../../../../../../store/commitments/selectors/reservations/details-drawer/resource-type/reservationSelectedCoverageDataSelector";

import { KeyValueSplitChip } from "../../../../../../common/chips/KeyValueSplitChip";

export const ReservationCoverageDrawerContentHeaderInfoCards: FC = () => {
  const coverageData = useAppSelector(reservationSelectedCoverageDataSelector);
  const data = useMemo(() => {
    const resourceType = coverageData?.resource_type
      ?.resource_type as CommitmentResourceType;

    return {
      "Resource Type": resourceType
        ? commitmentsResourceTypeNames[resourceType]
        : "",
      "Instance Type": coverageData?.resource_type?.instance_type,
      Platform: coverageData?.resource_type?.platform,
      Account: coverageData?.account
        ? coverageData?.account?.name
        : "All Accounts",
      "Region/AZ": coverageData?.resource_type?.region,
    };
  }, [coverageData]);

  if (!data) {
    return null;
  }

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {Object.entries(data).map(([title, value]) => {
        return <KeyValueSplitChip key={title} title={title} value={value} />;
      })}
    </Stack>
  );
};
