import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { WidgetTotalsSummary } from "../../../../../widgets/common/WidgetTotalsSummary";
import { billingExplorerSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";
import { billingExplorerWidgetSetupCostsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupCostsSelector";
import { billingExplorerWidgetSetupBillingExportUrlSelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerWidgetSetupBillingExportUrlSelector";

export const BillingExplorerWidgetSetupContentCosts: FC = () => {
  const dates = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("dates"),
  );
  const costsData = useAppSelector(billingExplorerWidgetSetupCostsSelector);
  const showTrends = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("trend"),
  );

  const frequency = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("frequency"),
  );

  const forecastOption = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("forecastOption"),
  );

  const aggregations = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("aggregation"),
  );

  const billingExplorerUrl = useAppSelector(
    billingExplorerWidgetSetupBillingExportUrlSelector,
  );

  if (!costsData) {
    return null;
  }

  return (
    <WidgetTotalsSummary
      frequency={frequency}
      costsData={costsData}
      forecastOption={forecastOption}
      aggregations={aggregations}
      dateLabel={dates.label}
      showTrends={showTrends}
      billingExplorerUrl={billingExplorerUrl}
    />
  );
};
