import { getBillingExplorerAnomalyByIdSelector } from "./api-select/getBillingExplorerAnomalyByIdSelector";
import { RootState } from "../../../store";

export const billingExplorerAnomalySummaryDataByIdSelector = (
  state: RootState,
  id: string,
) => {
  return getBillingExplorerAnomalyByIdSelector({ anomalyId: id })(state)?.data
    ?.summary;
};
