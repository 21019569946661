import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import moment from "moment";
import {
  ProviderType,
  RepresentsACostTrend,
} from "../../../../../../../services/cloudchipr.api";
import { ProviderIcon } from "../../../../../../common/ProviderIcon";
import {
  TrendChip,
  trendColors,
  TrendTypes,
} from "../../../../../../common/chips/TrendChip";
import { money } from "../../../../../../../utils/numeral/money";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

interface BillingExplorerExplainWithAiAnomalyTrendItemHeaderTitleProps
  extends RepresentsACostTrend {
  provider: ProviderType;
  type: "increase" | "decrease";
}
export const BillingExplorerExplainWithAiAnomalyTrendItemHeaderTitle: FC<
  BillingExplorerExplainWithAiAnomalyTrendItemHeaderTitleProps
> = ({ provider, start_date, end_date, type, trend_percentage, change }) => {
  let trend: TrendTypes = "negative";
  if ((trend_percentage ?? 0) < 0) {
    trend = "positive";
  }

  if ((trend_percentage ?? 0) === 0) {
    trend = "neutral";
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="row" spacing={1}>
        <ProviderIcon provider={provider} />
        <Typography variant="subtitle2" fontWeight="normal">
          {type === "increase" ? "Increase" : "Decrease"}
          Trend from
          <Typography variant="subtitle2" fontWeight="bold" component="span">
            {" "}
            {moment(start_date).format("MMMM D, YYYY")}
          </Typography>{" "}
          to{" "}
          <Typography variant="subtitle2" fontWeight="bold" component="span">
            {" "}
            {moment(end_date).format("MMMM D, YYYY")}
          </Typography>
        </Typography>
      </Stack>

      <Stack
        sx={{
          maxWidth: "40%",
        }}
      >
        <TypographyWithTooltip
          title={money(change)}
          variant="body2"
          fontWeight="medium"
        >
          <Typography
            component="span"
            color={trendColors.get(trend)?.color}
            variant="inherit"
            fontWeight="inherit"
          >
            {trend === "positive" && "- "}
            {trend === "negative" && "+ "}
          </Typography>
          {money(Math.abs(change))}
        </TypographyWithTooltip>
        <TrendChip value={trend_percentage} />
      </Stack>
    </Stack>
  );
};
