import { groupingsExcludingForecastedCostsColumns } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/utils/constants/groupingsExcludingForecastedCostsColumns";
import {
  ForecastOption,
  ResourceExplorerGroupingNullable,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../../../../utils/numeral/money";

interface GetBillingExplorerWidgetNumeralItemSecondaryLabelArgs {
  monthlyCost?: number;
  quarterlyCost?: number;
  forecastOption: ForecastOption;
  grouping?: ResourceExplorerGroupingNullable;
}
export const getBillingExplorerWidgetNumeralItemSecondaryLabel = ({
  monthlyCost,
  quarterlyCost,
  forecastOption,
  grouping,
}: GetBillingExplorerWidgetNumeralItemSecondaryLabelArgs):
  | string
  | undefined => {
  if (grouping && groupingsExcludingForecastedCostsColumns.has(grouping)) {
    return;
  }
  const forecastedCost: number | undefined =
    forecastOption === "month" ? monthlyCost : quarterlyCost;

  return !forecastedCost ? "-" : `~${money(forecastedCost)}`;
};
