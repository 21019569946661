import {
  ChartBaseType,
  CoverageGroupResponse,
} from "../../../../../../../services/cloudchipr.api";
import { chartDataPointKey } from "../../../../../../../storybook/charts/multi-type-chart/utils/constants/constants";
import { ChartDataType } from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";

export const generateCoverageWidgetChartData = (
  groups?: CoverageGroupResponse[],
  chartBase?: ChartBaseType,
) => {
  return groups?.map((group) => ({
    [chartDataPointKey]:
      typeof group?.name === "string"
        ? group?.name
        : (group.name?.name ?? group.name?.provider_account_id ?? ""),
    SP: Number(
      (chartBase === "cost"
        ? group?.savings_plan?.amount
        : group?.savings_plan?.percentage) ?? 0,
    ),
    RI: Number(
      (chartBase === "cost"
        ? group?.reservation?.amount
        : group?.reservation?.percentage) ?? 0,
    ),
    "On-Demand": Number(
      (chartBase === "cost"
        ? group?.on_demand?.amount
        : group?.on_demand?.percentage) ?? 0,
    ),
  })) as unknown as ChartDataType[] | undefined;
};
