import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useCurrentEditor } from "@tiptap/react";

export const ToolbarColorCommandSelector: FC = () => {
  const { editor } = useCurrentEditor();
  const [color, setColor] = useState<string>("#000000");

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!editor) return;
      const newColor = event.target.value;
      editor.chain().focus().setColor(newColor).run();
      setColor(newColor);
    },
    [editor],
  );

  useEffect(() => {
    if (!editor) return;

    const updateColor = () => {
      const newColor = editor.getAttributes("textStyle").color || "#000000";
      setColor(newColor);
    };

    editor.on("update", updateColor);
    editor.on("selectionUpdate", updateColor);

    updateColor();

    return () => {
      editor.off("update", updateColor);
      editor.off("selectionUpdate", updateColor);
    };
  }, [editor]);

  if (!editor) {
    return;
  }

  return (
    <input
      type="color"
      onChange={handleChange}
      value={color}
      style={colorInputStyles}
      data-testid="setColor"
    />
  );
};

const colorInputStyles = { width: 35, minWidth: 35, cursor: "pointer" };
