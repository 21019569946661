import { billingExplorerSetupPropertyByKeySelector } from "./billingExplorerSetupPropertyByKeySelector";
import { BillingExplorerWidgetSetupType } from "../../../utils/types/setups/billingExplorerSetupType";
import { RootState } from "../../../../store";
import { getWidgetsConfigurationsFeatureToggleFromStorage } from "../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/utils/helpers/configurations-feature-toggle/getWidgetsConfigurationsFeatureToggleFromStorage";

export const billingExplorerSetupVisualizationPropertyByKeySelector =
  (key: keyof BillingExplorerWidgetSetupType["visualization"]) =>
  (state: RootState): any => {
    const enableWidgetsSideBarConfigurations =
      getWidgetsConfigurationsFeatureToggleFromStorage();

    if (!enableWidgetsSideBarConfigurations && key == "chart_type") {
      return billingExplorerSetupPropertyByKeySelector("viewType")(state);
    }

    const setup =
      billingExplorerSetupPropertyByKeySelector("visualization")(state);

    if (!setup) {
      return null;
    }

    return setup?.[key];
  };
