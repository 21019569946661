import { createAsyncThunk } from "@reduxjs/toolkit";
import { widgetDataNotFoundSelector } from "../../../selectors/widgets/common/widgetDataNotFoundSelector";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";

export const getCoverageWidgetDataByWidgetIdThunk = createAsyncThunk(
  "dashboards/getCoverageWidgetDataByWidgetId",
  async (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentWidgetsCoverageByWidgetId } =
      cloudChiprApi.endpoints;

    const widgetNotFound = widgetDataNotFoundSelector(
      state,
      widgetId ?? "",
      "coverage",
    );

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentWidgetsCoverageByWidgetId.initiate(
          {
            widgetId,
          },
          {
            forceRefetch: true,
          },
        ),
      );
    } catch (e) {
      if (!widgetNotFound) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    }
  },
);
