import { FC, useMemo } from "react";
import { AccountsHeaderNavigation } from "./AccountsHeaderNavigation";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { AccountAddButton } from "../accounts/AccountAddButton";
import { PageHeader } from "../../../common/PageHeader";
import { GcpAccountsHeaderActionButtons } from "../accounts/gcp-billing-export/GcpAccountsHeaderActionButtons";
import { useAppSelector } from "../../../../../store/hooks";
import { accountsNeededInfoLoadingSelector } from "../../../../../store/accounts/selectors/loadings/accountsNeededInfoLoadingSelector";
import { AccountTabsWithResources } from "../../utils/types/types";
import { activeAccountsIdsSelector } from "../../../../../store/accounts/selectors/activeAccountsIdsSelector";

interface AccountsHeaderProps {
  provider: ProviderType;
  activeTab: AccountTabsWithResources;
  activeCount?: number;
  inactiveCount?: number;
  orgId?: string;
}

export const AccountsHeader: FC<AccountsHeaderProps> = ({
  provider,
  activeCount,
  inactiveCount,
  orgId,
  activeTab,
}) => {
  const accountsExist = !!(activeCount || inactiveCount);
  const loading = useAppSelector((state) => {
    return activeTab === "live-resources"
      ? false
      : accountsNeededInfoLoadingSelector(state, provider, activeTab, orgId);
  });

  const accountIds = useAppSelector((state) =>
    activeAccountsIdsSelector(state, provider, orgId),
  );

  const actions = useMemo(() => {
    if (!accountsExist) {
      return;
    }

    if (provider === "gcp") {
      return <GcpAccountsHeaderActionButtons />;
    }

    return <AccountAddButton provider={provider} />;
  }, [accountsExist, provider]);

  if (!accountsExist) {
    return null;
  }

  return (
    <PageHeader
      sticky
      breadcrumbs={
        accountsExist ? (
          <AccountsHeaderNavigation
            activeTab={activeTab}
            activeCount={activeCount ?? 0}
            inactiveCount={inactiveCount ?? 0}
            orgId={orgId}
            provider={provider}
            accountIds={accountIds ?? []}
          />
        ) : undefined
      }
      loading={loading}
      actions={actions}
    />
  );
};
