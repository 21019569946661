import { FC, Fragment } from "react";
import { Divider, Stack } from "@mui/material";
import { BillingExplorerExplainWithAiIncreaseTrends } from "./increase-trends/BillingExplorerExplainWithAiIncreaseTrends";
import { BillingExplorerExplainWithAiDecreaseTrends } from "./decrease-trends/BillingExplorerExplainWithAiDecreaseTrends";
import { BillingExplorerExplainWithAiAnomaliesSpikes } from "./spikes/BillingExplorerExplainWithAiAnomaliesSpikes";
import { BillingExplorerExplainWithAiAnomaliesCount } from "./BillingExplorerExplainWithAiAnomaliesCount";
import { BillingExplorerExplainWithAiSummary } from "./BillingExplorerExplainWithAiSummary";
import { BillingExplorerExplainWithAiLoading } from "../BillingExplorerExplainWithAiLoading";
import { useAppSelector } from "../../../../../../../store/hooks";
import { isBillingExplorerDatesOutRangesForSummarySelector } from "../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/isBillingExplorerDatesOutRangesForSummarySelector";
import { billingExplorerSummaryLoadingByIndexSelector } from "../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/loading/billingExplorerSummaryLoadingByIndexSelector";

interface BillingExplorerExplainWithAiAnomaliesProps {
  index: number;
  localLoading?: boolean;
}

export const BillingExplorerExplainWithAiAnomalies: FC<
  BillingExplorerExplainWithAiAnomaliesProps
> = ({ index, localLoading }) => {
  const loading = useAppSelector((state) =>
    billingExplorerSummaryLoadingByIndexSelector(state, index),
  );

  const isOutRangesDates = useAppSelector(
    isBillingExplorerDatesOutRangesForSummarySelector,
  );

  return (
    <Stack py={1.5}>
      {(loading || localLoading) && !isOutRangesDates ? (
        <BillingExplorerExplainWithAiLoading />
      ) : (
        <Fragment>
          <Divider sx={{ mb: 1.5 }} />
          <BillingExplorerExplainWithAiAnomaliesCount index={index} />
          <BillingExplorerExplainWithAiSummary index={index} />
          <Stack spacing={1.5}>
            <BillingExplorerExplainWithAiIncreaseTrends index={index} />
            <BillingExplorerExplainWithAiAnomaliesSpikes index={index} />
            <BillingExplorerExplainWithAiDecreaseTrends index={index} />
          </Stack>
        </Fragment>
      )}
    </Stack>
  );
};
