import { Task } from "../../../../../services/cloudchipr.api";

export const generatePayloadFromTask = (task: Task | undefined) => {
  return {
    task_ids: [task?.id],
    name: task?.name,
    status_id: task?.status?.id ?? null,
    priority_id: { value: task?.priority?.id ?? null },
    environment_id: { value: task?.environment?.id ?? null },
    owner_id: { value: task?.owner?.id ?? null },
    action_id: { value: task?.action?.id ?? null },
    due_date: { value: task?.due_date ?? null },
    description: { value: task?.description ?? null },
    assignees: { value: task?.assignees?.map(({ id }) => id) ?? null },
  };
};
