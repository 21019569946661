import { FC, useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem, Tooltip } from "@mui/material";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../store/hooks";
import { setTaskManagementTaskTarget } from "../../../../../../../../../../../../store/task-management/taskManagementSlice";
import { taskManagementInitiateEmptyTaskThunk } from "../../../../../../../../../../../../store/task-management/thunks/form/taskManagementInitiateEmptyTaskThunk";
import { liveUsageMgmtResourceByIdAndTypeSelector } from "../../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceByIdAndTypeSelector";
import { ResourceType } from "../../../../../../../../../../../../services/cloudchipr.api";

interface CreateTaskActionItemProps {
  resourceId: string;
  resourceType: ResourceType;
  onClose(): void;
  resourceHasTask: boolean;
}

export const CreateTaskActionItem: FC<CreateTaskActionItemProps> = ({
  onClose,
  resourceType,
  resourceId,
  resourceHasTask,
}) => {
  const dispatch = useAppDispatch();
  const resource = useAppSelector((state) =>
    liveUsageMgmtResourceByIdAndTypeSelector(state, resourceType, resourceId),
  );

  const clickHandler = useCallback(async () => {
    if (!resource) {
      return;
    }

    await dispatch(taskManagementInitiateEmptyTaskThunk());
    dispatch(
      setTaskManagementTaskTarget({
        type: "resource",
        resources: [
          {
            state: "live",
            id: resource.id,
            name: resource.resource.name ?? "",
            tags: resource.resource.tags,
            link: resource.resource.link,
            account: resource.resource.account,
            resource_type: resource.resource_type,
            region: resource.resource.region ?? "",
            price_per_month: `${resource.resource.price_per_month}`,
            provider_identifier: resource.resource.provider_unique_identifier,
            creation_data: resource.resource.creation_data ?? {},
            live_resource_link_response: null,
          },
        ],
      }),
    );

    onClose();
  }, [dispatch, onClose, resource]);

  return (
    <Tooltip
      arrow
      placement="top"
      title={resourceHasTask ? "This resource already has a task created." : ""}
    >
      <span>
        <MenuItem dense onClick={clickHandler} disabled={resourceHasTask}>
          <ListItemIcon
            sx={{
              "& svg": { fontSize: "24px !important" },
            }}
          >
            <PostAddOutlinedIcon color="primary" />
          </ListItemIcon>

          <ListItemText>Create Task </ListItemText>
        </MenuItem>
      </span>
    </Tooltip>
  );
};
