import { createContext, FC, ReactNode, useContext } from "react";
import { AddViewInHierarchyButtonProps } from "./adding-view/AddViewInHierarchyButton";
import { HierarchyViewRenameActionGlobalProps } from "./actions/rename/HierarchyViewRenameActionDialog";
import { HierarchyViewProtectActionGlobalProps } from "./actions/protect/HierarchyViewProtectActionDialog";
import { HierarchyViewDuplicateActionGlobalProps } from "./actions/duplicate/HierarchyViewDuplicateActionDialog";
import { HierarchyViewDeleteActionGlobalProps } from "./actions/delete/HierarchyViewDeleteActionDialog";
import { View } from "../../../../../services/cloudchipr.api";

type ViewsHierarchyContextType = Omit<ViewsHierarchyProviderProps, "children">;

const ViewsHierarchyContext = createContext({} as ViewsHierarchyContextType);

export interface ViewsHierarchyProviderProps {
  views: View[];
  pathname: string;
  allItemsLabel: string;

  actions: {
    viewCreate: AddViewInHierarchyButtonProps;
    viewDelete: HierarchyViewDeleteActionGlobalProps;
    viewDuplicate: HierarchyViewDuplicateActionGlobalProps;
    viewProtect: HierarchyViewProtectActionGlobalProps;
    viewRename: HierarchyViewRenameActionGlobalProps;
  };

  children?: ReactNode;
}

export const ViewsHierarchyProvider: FC<ViewsHierarchyProviderProps> = ({
  children,
  ...props
}) => {
  return (
    <ViewsHierarchyContext.Provider value={props}>
      {children}
    </ViewsHierarchyContext.Provider>
  );
};

export const useViewsHierarchyContext = () => {
  return useContext(ViewsHierarchyContext);
};
