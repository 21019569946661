import { FC, useMemo, MouseEvent } from "react";
import { Button } from "@mui/material";
import moment from "moment/moment";
import Stack from "@mui/material/Stack";
import { useContextDaysPropGetters } from "@rehookify/datepicker";
import { DPUserConfig } from "@rehookify/datepicker/dist/types/config";

interface TodayButtonProps {
  setOffsetDate(date?: Date): void;
  onChange: DPUserConfig["onDatesChange"];
  selectedDates: DPUserConfig["selectedDates"];
}

export const TodayButton: FC<TodayButtonProps> = ({
  setOffsetDate,
  selectedDates,
  onChange,
}) => {
  const { dayButton } = useContextDaysPropGetters();

  const buttonProps = useMemo(() => {
    return dayButton(
      {
        $date: new Date(),
        active: false,
        day: `${moment().day()}`,
        now: true,
        range: "",
        disabled: false,
        selected: false,
        inCurrentMonth: true,
      },
      {
        onClick: (_?: MouseEvent<HTMLElement>, date?: Date) => {
          setOffsetDate(date!);

          setTimeout(() => {
            const firstDate = selectedDates.at(0);

            if (selectedDates.length === 2 && firstDate) {
              onChange([firstDate, date!]);
            }
          }, 0);
        },
      },
    );
  }, [onChange, dayButton, selectedDates, setOffsetDate]);

  return (
    <Stack alignItems="center" pt={1}>
      <Button variant="text" sx={{ textTransform: "none" }} {...buttonProps}>
        Today
      </Button>
    </Stack>
  );
};
