import { FC, ReactNode, useCallback, useState } from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Button, Stack } from "@mui/material";
import { ExploreWithAIButtonPopover } from "./components/ExploreWithAIButtonPopover";
import { useMenuHook } from "../../utils/hooks/useMenu.hook";

interface ExploreWithAIButtonProps {
  title?: ReactNode;
  onPopoverMount?(): void;
  loading?: boolean;
  popoverContent?: string;
}

export const ExploreWithAIButton: FC<ExploreWithAIButtonProps> = ({
  title,
  onPopoverMount,
  loading,
  popoverContent,
}) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();
  const [hovered, setHovered] = useState(false);

  const mouseEnterHandler = useCallback(() => {
    setHovered(true);
  }, []);

  const mouseLeaveHandler = useCallback(() => {
    setHovered(false);
  }, []);

  const buttonClickHandler = useCallback(
    (event: any) => {
      openMenu(event);
      mouseLeaveHandler();
    },
    [openMenu, mouseLeaveHandler],
  );

  return (
    <Stack
      height={32}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <Stack
        onClick={buttonClickHandler}
        sx={{
          justifyContent: "center",
          position: "relative",
          width: hovered ? 140 : 32,
          transition: "width 0.4s ease-in-out",
          overflow: "hidden",
          borderRadius: "24px",
          background: "linear-gradient(100deg, #1443EA33, #7334F133)",
        }}
        fontSize="medium"
      >
        <Button
          sx={{
            px: 0.5,
            justifyContent: "start",
            textTransform: "none",
            whiteSpace: "nowrap",
            minWidth: 32,
          }}
          size="medium"
        >
          <Stack
            direction="row"
            justifyContent="start"
            alignItems="center"
            gap={1}
          >
            <AutoAwesomeIcon fontSize="small" color="primary" />
            <span
              style={{
                opacity: hovered ? 1 : 0,
                visibility: hovered ? "visible" : "hidden",
                transition:
                  "opacity 0.4s ease-in-out, visibility 0.3s ease-in-out",
              }}
            >
              Explain With AI
            </span>
          </Stack>
        </Button>
      </Stack>

      <ExploreWithAIButtonPopover
        open={open}
        anchor={anchor}
        onClose={closeMenu}
        title={title}
        onPopoverMount={onPopoverMount}
        loading={loading}
        content={popoverContent}
      />
    </Stack>
  );
};
