import { FC, useMemo } from "react";
import Stack from "@mui/material/Stack";
import { Chip, Tooltip } from "@mui/material";
import { PossibleFilterValue } from "../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { dimensionUsedAccountsSelector } from "../../../../../../../../store/dimensions/selectors/slice-data/dimensionUsedAccountsSelector";
import { DropdownSelectOption } from "../../../../../../../common/select/dropdown-select/utils/types/types";

export const DimensionsFilterValueOption: FC<DropdownSelectOption> = ({
  rawValue,
}) => {
  const { title, value } = (rawValue ?? {}) as PossibleFilterValue;
  const usedAccounts = useAppSelector(dimensionUsedAccountsSelector);
  const categories = usedAccounts[value];
  const tooltipTitle = useMemo(() => {
    const joinedCategories = categories
      ?.map((category) => `"${category}"`)
      ?.join(", ");

    return `Account already used in ${joinedCategories} ${categories?.length === 1 ? "category" : "categories"}`;
  }, [categories]);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <TypographyWithTooltip title={title ?? value} fontSize="inherit" />

      {categories && (
        <Tooltip arrow placement="top" title={tooltipTitle}>
          <Chip label="Used" size="small" variant="outlined" color="warning" />
        </Tooltip>
      )}
    </Stack>
  );
};
