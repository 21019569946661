import { FC } from "react";
import { Stack } from "@mui/material";
import { CellContext } from "@tanstack/react-table";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import { ProviderIcon } from "../../../../../../../../../common/ProviderIcon";
import { ProviderType } from "../../../../../../../../../../services/cloudchipr.api";

type BillingExplorerWidgetLabelCellProps = CellContext<any, any>;

export const BillingExplorerWidgetLabelCell: FC<
  BillingExplorerWidgetLabelCellProps
> = (cell) => {
  const { field, label, cloud_provider } = cell.row.original;
  const name = label ?? field;

  const provider = cloud_provider ? [cloud_provider] : [];
  const multiProviders = cell.row.original?.multiProviders ?? provider;

  return (
    <Stack direction="row" spacing={1}>
      {(multiProviders as ProviderType[]).map((item) => {
        return <ProviderIcon key={item} provider={item} width={16} />;
      })}

      <TypographyWithTooltip title={name} variant="body2" maxWidth={300} />
    </Stack>
  );
};
