import { resourceDetailsResourceBillingDataSelector } from "../../billing-data/resourceDetailsResourceBillingDataSelector";
import { RootState } from "../../../../store";
import { ResourceExplorerWidget } from "../../../../../services/cloudchipr.api";
import { resourceDetailsGeneralTabProcessKey } from "../../../utils/constants/processesKeys";

export const resourceDetailsGeneralTabBillingDataSelector = (
  state: RootState,
): ResourceExplorerWidget | undefined => {
  return resourceDetailsResourceBillingDataSelector(
    state,
    resourceDetailsGeneralTabProcessKey,
  );
};
