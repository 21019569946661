import { FC } from "react";
import { Stack } from "@mui/material";
import { ResourceDetailsGeneralContentTags } from "./components/ResourceDetailsGeneralContentTags";
import { ResourceDetailsGeneralContentDetails } from "./components/details/ResourceDetailsGeneralContentDetails";
import { ResourceDetailsGeneralContentK8SUsage } from "./components/kubernetes/ResourceDetailsGeneralContentK8SUsage";
import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceDetailsTargetResourceSelector } from "../../../../../../../store/resource-details/selectors/target-resource/resourceDetailsTargetResourceSelector";

export const ResourceDetailsK8SGeneralContent: FC = () => {
  const resource = useAppSelector(resourceDetailsTargetResourceSelector);

  if (!resource?.providerMetadata?.k8s) {
    return null;
  }

  return (
    <Stack spacing={1.5}>
      <ResourceDetailsGeneralContentK8SUsage />

      <ResourceDetailsGeneralContentDetails />

      {resource?.tags && (
        <ResourceDetailsGeneralContentTags tags={resource?.tags} label="Tags" />
      )}

      {resource?.providerMetadata?.k8s.labels && (
        <ResourceDetailsGeneralContentTags
          tags={resource?.providerMetadata?.k8s.labels}
          label="Kubernetes Labels"
        />
      )}
    </Stack>
  );
};
