import { FC, Fragment, useCallback } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useAppAbility } from "../../../../../../../services/permissions";
import { DeleteConfirmationDialog } from "../../../../../../common/modals/DeleteConfirmationDialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { deleteAlertByIdAndTypeThunk } from "../../../../../../../store/alerts/thunks/common/deleteAlertByIdAndTypeThunk";
import { deleteCostAnomalyAlertLoadingSelector } from "../../../../../../../store/alerts/selectors/cost-anomaly-alerts/deleteCostAnomalyAlertLoadingSelector";
import { deleteUtilizationAlertLoadingSelector } from "../../../../../../../store/alerts/selectors/utilization-alerts/deleteUtilizationAlertLoadingSelector";
import { AlertTabType } from "../../../../../../../store/alerts/utils/types/types";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";

interface AlertsDataGridDeleteActionCellProps {
  id: string;
  name: string;
  closeMenu(): void;
  alertsActiveTab: AlertTabType;
}

export const AlertsDataGridDeleteAction: FC<
  AlertsDataGridDeleteActionCellProps
> = ({ id, name, alertsActiveTab, closeMenu }) => {
  const dispatch = useAppDispatch();

  const { cannot } = useAppAbility();
  const cannotEdit = cannot("delete", "alert");

  const { open, openDialog, closeDialog } = useDialog();

  const costAnomalyLoading = useAppSelector(
    deleteCostAnomalyAlertLoadingSelector,
  );
  const utilizationLoading = useAppSelector(
    deleteUtilizationAlertLoadingSelector,
  );
  const loading =
    alertsActiveTab === "costAnomaly" ? costAnomalyLoading : utilizationLoading;

  const handleDelete = useCallback(async () => {
    await dispatch(deleteAlertByIdAndTypeThunk({ id, type: alertsActiveTab }));
    closeMenu();
  }, [id, dispatch, alertsActiveTab, closeMenu]);

  return (
    <Fragment>
      <MenuItem dense onClick={openDialog} disabled={cannotEdit}>
        <ListItemIcon>
          <DeleteOutlineIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText color="error">Delete</ListItemText>
      </MenuItem>

      <DeleteConfirmationDialog
        open={open}
        onClose={closeDialog}
        onDelete={handleDelete}
        loading={loading}
        title="Delete Alert?"
        content={
          <Typography>
            Please confirm if you want to delete the
            <Typography
              component="span"
              fontWeight="bold"
              whiteSpace="break-spaces"
              sx={{ wordBreak: "break-word" }}
            >
              {" "}
              {name}{" "}
            </Typography>
            alert
          </Typography>
        }
      />
    </Fragment>
  );
};
