import { ColDef, GridApi, IMultiFilterParams } from "ag-grid-community";
import { SavingsOpportunityActionTypeCellRenderer } from "./cell-renderers/SavingsOpportunityActionTypeCellRenderer";
import { SavingsOpportunityServiceCellRenderer } from "./cell-renderers/SavingsOpportunityServiceCellRenderer";
import { SavingsOpportunityImplementationEffortCellRenderer } from "./cell-renderers/SavingsOpportunityImplementationEffortCellRenderer";
import { SavingsOpportunityAccountCellRenderer } from "./cell-renderers/SavingsOpportunityAccountCellRenderer";
import { SavingsOpportunityTagsCellRenderer } from "./cell-renderers/SavingsOpportunityTagsCellRenderer";
import { SavingsOpportunityMonthlySavingsCellRenderer } from "./cell-renderers/SavingsOpportunityMonthlySavingsCellRenderer";
import { getSimpleTypographyCellRenderer } from "./cell-renderers/getSimpleTypographyCellRenderer";
import { getMoneyTypographyCellRenderer } from "./cell-renderers/getMoneyTypographyCellRenderer";
import { SavingsOpportunityBasedOnPastCellRenderer } from "./cell-renderers/SavingsOpportunityBasedOnPastCellRenderer";
import { SavingsOpportunityCloudProviderCellRenderer } from "./cell-renderers/SavingsOpportunityCloudProviderCellRenderer";
import { getSavingsOpportunitiesInnerHeader } from "./header/SavingsOpportunitiesInnerHeader";
import { getSavingsOpportunitiesPriceInnerHeader } from "./header/SavingsOpportunitiesPriceInnerHeader";
import { SavingsOpportunity } from "../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../utils/helpers/resources/getResourceTypeName";
import { agGridEmptyColumnToFitEmptySpace } from "../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import {
  opportunityTypeTitlesByActionType,
  possibleEfforts,
  possibleEffortsLabels,
} from "../utils/constants/common";
import { getProviderName } from "../../../../utils/helpers/providers/getProviderName";

export const savingsOpportunitiesTableColumns: ColDef<SavingsOpportunity>[] = [
  {
    field: "action_type",
    headerName: "Opportunity Type",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    getQuickFilterText: ({ data }) =>
      data?.action_type
        ? opportunityTypeTitlesByActionType[data.action_type]
        : "",
    valueGetter: ({ data }) =>
      data?.action_type
        ? opportunityTypeTitlesByActionType[data.action_type]
        : "",
    cellRenderer: SavingsOpportunityActionTypeCellRenderer,
  },

  {
    field: "description",
    maxWidth: 400,
    headerName: "Description",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: getSimpleTypographyCellRenderer("description"),
  },

  {
    field: "cloud_provider",
    headerName: "Cloud Provider",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    valueGetter: ({ data }) =>
      data?.account?.provider
        ? getProviderName(data.account.provider, { title: true })
        : "",
    cellRenderer: SavingsOpportunityCloudProviderCellRenderer,
    getQuickFilterText: ({ data }) => data.account.provider,
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const current = nodeA?.data?.account?.provider;
      const next = nodeB?.data?.account?.provider;

      if (!current) return 1;
      if (!next) return -1;

      return current.localeCompare(next);
    },
  },

  {
    field: "service",
    valueGetter: ({ data }) => {
      return data?.service
        ? getResourceTypeName(data?.service, {
            type: "long",
            singular: true,
          })
        : "";
    },
    headerName: "Service",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: SavingsOpportunityServiceCellRenderer,
    getQuickFilterText: ({ data }) => {
      return getResourceTypeName(data.service, {
        type: "long",
        singular: true,
      });
    },
  },

  {
    field: "resource_type",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    headerName: "Resource Type",
    cellRenderer: getSimpleTypographyCellRenderer("resource_type"),
  },

  {
    field: "recommended_type",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    headerName: "Recommended Type",
    cellRenderer: getSimpleTypographyCellRenderer("recommended_type"),
  },

  {
    field: "resource_id",
    maxWidth: 400,
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    headerName: "Resource ID",
    cellRenderer: getSimpleTypographyCellRenderer("resource_id"),
  },

  {
    field: "resource_arn",
    maxWidth: 400,
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    headerName: "Resource ARN",
    cellRenderer: getSimpleTypographyCellRenderer("resource_arn"),
  },

  {
    field: "implementation_effort",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    valueGetter: ({ data }) =>
      data?.implementation_effort
        ? possibleEffortsLabels[data?.implementation_effort]
        : "",
    headerName: "Implementation Effort",
    cellRenderer: SavingsOpportunityImplementationEffortCellRenderer,
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const current = nodeA?.data?.implementation_effort;
      const next = nodeB?.data?.implementation_effort;

      const currentEffortIndex = possibleEfforts.findIndex(
        (effort) => effort === current,
      );

      const nextEffortIndex = possibleEfforts.findIndex(
        (effort) => effort === next,
      );

      return currentEffortIndex - nextEffortIndex;
    },
  },

  {
    field: "based_on_past",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    valueGetter: ({ data }) =>
      data?.based_on_past ? `${data?.based_on_past} days` : "",
    headerName: "Based On Past",
    cellRenderer: SavingsOpportunityBasedOnPastCellRenderer,
  },

  {
    field: "region",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    headerName: "Region",
    cellRenderer: getSimpleTypographyCellRenderer("region"),
  },

  {
    field: "account.provider_account_id",
    headerName: "Account",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: SavingsOpportunityAccountCellRenderer,
    valueGetter: ({ data }) => data?.account?.provider_account_name,
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const current = nodeA?.data?.account?.provider_account_name;
      const next = nodeB?.data?.account?.provider_account_name;

      if (!current) return 1;
      if (!next) return -1;

      return current.localeCompare(next);
    },
  },

  {
    field: "tags",
    filterParams: {
      filters: [
        { filter: "agTextColumnFilter" },
        {
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params: any) => {
              const gridApi = params.api as GridApi<SavingsOpportunity>;

              const uniqueValues = new Set<string>();

              gridApi?.forEachNode((node) => {
                node?.data?.tags?.forEach((tag) => {
                  uniqueValues.add(`${tag.key}: ${tag.value}`);
                });
              });

              params?.success(Array.from(uniqueValues));
            },
          },
        },
      ],
    } as IMultiFilterParams,

    headerName: "Tags",
    valueGetter: ({ data }) =>
      data?.tags
        ?.map((tag) => `${tag.key} ${tag.value}`)
        ?.join(" ")
        ?.toLowerCase() ?? "",
    enableRowGroup: false,
    cellRenderer: SavingsOpportunityTagsCellRenderer,
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const current = nodeA?.data?.tags?.length ?? 0;
      const next = nodeB?.data?.tags?.length ?? 0;

      return current - next;
    },
    getQuickFilterText: ({ data }) =>
      data.tags?.map((tag) => `${tag.key} ${tag.value}`).join(" ") ?? "",
  },

  {
    field: "current_monthly_price",
    filter: "agNumberColumnFilter",
    headerName: "Current Monthly Price",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesPriceInnerHeader(
        "current_monthly_price",
      ),
    },
    cellRenderer: getMoneyTypographyCellRenderer("current_monthly_price"),
  },

  {
    field: "estimated_monthly_price",
    minWidth: 300,
    filter: "agNumberColumnFilter",
    headerName: "Estimated Monthly Price",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesPriceInnerHeader(
        "estimated_monthly_price",
        "Estimated monthly price after implementing the recommendation.",
      ),
    },
    cellRenderer: getMoneyTypographyCellRenderer("estimated_monthly_price"),
  },

  {
    field: "monthly_savings",
    filter: "agNumberColumnFilter",
    suppressColumnsToolPanel: true,
    pinned: "right",
    lockPinned: true,
    headerName: "Monthly Savings",
    headerComponentParams: {
      innerHeaderComponent:
        getSavingsOpportunitiesPriceInnerHeader("monthly_savings"),
    },
    cellRenderer: SavingsOpportunityMonthlySavingsCellRenderer,
  },

  agGridEmptyColumnToFitEmptySpace,
];
