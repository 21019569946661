import { RootState } from "../../../../store";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { currentUserEmailSelector } from "../../../../profile/currentUserEmailSelector";
import { kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../../kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";
import { generateLiveResourcesPath } from "../../../../../components/pages/accounts-group/utils/helpers/generateLiveResourcesPath";
import { money } from "../../../../../utils/numeral/money";
import { kubernetesLiveUsageMgmtSelectedResourcesClustersMetaDataSelector } from "../../../../kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtSelectedResourcesClustersMetaDataSelector";

export const kubernetesEmailMessageSelectorV2 = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const userEmail = currentUserEmailSelector(state);

  const accountData =
    kubernetesLiveUsageMgmtSelectedResourcesClustersMetaDataSelector(
      state,
      resourceType,
    );

  const selectedResources =
    kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeSelector(
      state,
      resourceType,
    );

  if (!selectedResources) {
    return "";
  }

  const account = getProviderName("kubernetes", {
    nameAndTitle: true,
    capitalize: true,
    plural: true,
  });

  const price = selectedResources.reduce(
    (acc, resource) => resource.resource.total_monthly_cost + acc,
    0,
  );

  const accountUrls = accountData?.reduce((result, item) => {
    const url = `${window.location.origin}${generateLiveResourcesPath({
      provider: "kubernetes",
      activeTab: "live-resources",
      accountIds: item.id,
    })}`;
    const comma = result.length ? "," : "";
    result =
      result + ` <a href="${url}" target="_blank">${comma}${item.name}</a>`;
    return result;
  }, "");
  const monthlyPrice = `Monthly Price - <b>${money(price)}</b>/mo`;
  const user = userEmail
    ? `This email is sent from Cloudchipr by user <a href="mailto:${userEmail}" target="_blank">${userEmail}</a>`
    : "";

  return `Hi, 
            <br/>  
            <br/> 
            Resources in the attached file may need your attention. Please review and take the necessary actions.
            <br/> 
            <br/> 
            ${account} - ${accountUrls} 
            <br/> 
            ${monthlyPrice}
            <br/> 
            ${user} `;
};
