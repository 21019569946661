import { RootState } from "../../../../../store";
import { billingExplorerWidgetSetupSelector } from "../../../setups/billing-explorer/billingExplorerWidgetSetupSelector";
import { arrayIsEqual } from "../../../../../../utils/arrayIsEqual";
import { isDeepEqual } from "../../../../../../utils/is-deep-equal";
import { billingExplorerWidgetByIdSelector } from "../widget-view/billingExplorerWidgetByIdSelector";

export const billingExplorerWidgetEditChangesExistSelector = (
  state: RootState,
) => {
  const setup = billingExplorerWidgetSetupSelector(state);

  if (!setup?.id) {
    return false;
  }

  const widget = billingExplorerWidgetByIdSelector(state, setup?.id);

  if (!widget) {
    return false;
  }

  return (
    setup.name !== widget.name ||
    setup.grouping !== widget.grouping ||
    !arrayIsEqual(setup.group_values ?? [], widget.group_values ?? []) ||
    setup.viewId !== widget.view_id ||
    setup?.dates.label !== widget?.dates?.label ||
    setup?.dates.to !== widget?.dates?.to ||
    setup?.dates.from !== widget?.dates?.from ||
    (setup?.dates.unit ?? null) !== (widget?.dates?.unit ?? null) ||
    (setup?.dates.type ?? null) !== (widget?.dates?.type ?? null) ||
    (setup?.dates.value ?? null) !== (widget?.dates?.value ?? null) ||
    setup.forecastOption !== widget.forecast_option ||
    setup.frequency !== widget.date_granularity ||
    setup.visualization?.chart_type !== widget?.chart_type ||
    !isDeepEqual(setup?.visualization ?? {}, widget?.visualization ?? {}) ||
    !isDeepEqual(setup?.aggregation ?? {}, widget?.aggregation ?? {}) ||
    setup?.trendType !== widget.trend_type ||
    setup.forecastOption !== widget.forecast_option
  );
};
