import { FilterKeyType } from "../../../../../../../../services/cloudchipr.api";

export const statisticsSelectOptionsByFilterType: Partial<
  Record<FilterKeyType, string[]>
> = {
  processed_data: ["sum"],
  cpu_core: ["maximum"],
  cpu_utilization: ["maximum"],
  memory_utilization: ["maximum"],
  memory_bytes: ["maximum"],
};

export const statisticsSelectDefaultOptions = [
  "sum",
  "maximum",
  "minimum",
  "average",
];
