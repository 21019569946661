import { FC } from "react";
import { ResourceType } from "../../../services/cloudchipr.api";
// AWS
import S3SvgIcon from "../../../assets/images/logos/resources/aws/S3SvgIcon";
import Ec2SvgIcon from "../../../assets/images/logos/resources/aws/Ec2SvgIcon";
import EksSvgIcon from "../../../assets/images/logos/resources/aws/EksSvgIcon";
import ElcSvgIcon from "../../../assets/images/logos/resources/aws/ElcSvgIcon";
import RdsSvgIcon from "../../../assets/images/logos/resources/aws/RdsSvgIcon";
import DynamoDbTableSvgIcon from "../../../assets/images/logos/resources/aws/DynamoDbTableSvgIcon";
import NatGatewaySvgIcon from "../../../assets/images/logos/resources/aws/NatGatewaySvgIcon";
import EcsSvgIcon from "../../../assets/images/logos/resources/aws/EcsSvgIcon";
import EbsSvgIcon from "../../../assets/images/logos/resources/aws/EbsSvgIcon";
// Azure
import AzAksSvgIcon from "../../../assets/images/logos/resources/azure/AzAksSvgIcon";
import AzDiskSvgIcon from "../../../assets/images/logos/resources/azure/AzDiskSvgIcon";
import AzIpSvgIcon from "../../../assets/images/logos/resources/azure/AzIpSvgIcon";
import AzSqlSvgIcon from "../../../assets/images/logos/resources/azure/AzSqlSvgIcon";
import AzVmSvgIcon from "../../../assets/images/logos/resources/azure/AzVmSvgIcon";
import AzVmssSvgIcon from "../../../assets/images/logos/resources/azure/AzVmssSvgIcon";
import AzLbSvgIcon from "../../../assets/images/logos/resources/azure/AzLbSvgIcon";
// GCP
import VmSvgIcon from "../../../assets/images/logos/resources/gcp/VmSvgIcon";
import SqlSvgIcon from "../../../assets/images/logos/resources/gcp/SqlSvgIcon";
import GkeSvgIcon from "../../../assets/images/logos/resources/gcp/GkeSvgIcon";
import IpSvgIcon from "../../../assets/images/logos/resources/gcp/IpSvgIcon";
import LbSvgIcon from "../../../assets/images/logos/resources/gcp/LbSvgIcon";
import AsgSvgIcon from "../../../assets/images/logos/resources/aws/AsgSvgIcon";
import EipSvgIcon from "../../../assets/images/logos/resources/aws/EipSvgIcon";
import ElbSvgIcon from "../../../assets/images/logos/resources/aws/ElbSvgIcon";
import MigSvgIcon from "../../../assets/images/logos/resources/gcp/MigSvgIcon";
import DiskSvgIcon from "../../../assets/images/logos/resources/gcp/DiskSvgIcon";
// KUBERNETES
import KubernetesNodeSvgIcon from "../../../assets/images/logos/resources/kubernetes/KubernetesNodeSvgIcon";
import KubernetesNamespaceSvgIcon from "../../../assets/images/logos/resources/kubernetes/KubernetesNamespaceSvgIcon";
import KubernetesPodsSvgIcon from "../../../assets/images/logos/resources/kubernetes/KubernetesPodsSvgIcon";
import KubernetesDeploymentSvgIcon from "../../../assets/images/logos/resources/kubernetes/KubernetesDeploymentSvgIcon";
import KubernetesDaemonSetsSvgIcon from "../../../assets/images/logos/resources/kubernetes/KubernetesDaemonSetsSvgIcon";
import KubernetesStatefulSetSvgIcon from "../../../assets/images/logos/resources/kubernetes/KubernetesStatefulSetSvgIcon";
import KubernetesReplicaSetSvgIcon from "../../../assets/images/logos/resources/kubernetes/KubernetesReplicaSetSvgIcon";
import KubernetesJobSvgIcon from "../../../assets/images/logos/resources/kubernetes/KubernetesJobSvgIcon";

interface IconByResourceTypeProps {
  resourceType: ResourceType;
  primary?: boolean;
}

export const IconByResourceType: FC<IconByResourceTypeProps> = ({
  resourceType,
  primary,
}) => {
  switch (resourceType) {
    // AWS
    case "ec2":
      return <Ec2SvgIcon primary={primary} />;
    case "asg":
    case "asg_ec2":
      return <AsgSvgIcon primary={primary} />;
    case "eks_ec2":
    case "node_group":
    case "eks":
      return <EksSvgIcon primary={primary} />;
    case "elc":
      return <ElcSvgIcon primary={primary} />;
    case "rds_snapshot_source":
    case "rds_snapshot":
    case "rds":
      return <RdsSvgIcon primary={primary} />;
    case "s3":
      return <S3SvgIcon primary={primary} />;
    case "dynamo_db_table":
    case "dax_cluster":
    case "dynamo_db_backup_source":
    case "dynamo_db_backup":
      return <DynamoDbTableSvgIcon primary={primary} />;
    case "nat_gateway":
      return <NatGatewaySvgIcon primary={primary} />;
    case "ecs":
    case "ecs_workload":
      return <EcsSvgIcon primary={primary} />;
    case "ebs_snapshot":
    case "ebs":
      return <EbsSvgIcon primary={primary} />;
    case "eip":
      return <EipSvgIcon primary={primary} />;
    case "elb":
      return <ElbSvgIcon primary={primary} />;

    // AZURE
    case "az_node_pool":
    case "az_aks_vm":
    case "az_aks":
      return <AzAksSvgIcon primary={primary} />;
    case "az_disk":
      return <AzDiskSvgIcon primary={primary} />;
    case "az_ip":
      return <AzIpSvgIcon primary={primary} />;
    case "az_lb":
      return <AzLbSvgIcon primary={primary} />;
    case "az_sql":
      return <AzSqlSvgIcon primary={primary} />;
    case "az_vm":
      return <AzVmSvgIcon primary={primary} />;
    case "az_vmss_vm":
    case "az_vmss":
      return <AzVmssSvgIcon primary={primary} />;

    // GCP
    case "vm":
      return <VmSvgIcon primary={primary} />;
    case "mig":
    case "mig_vm":
      return <MigSvgIcon />;
    case "disk":
      return <DiskSvgIcon />;
    case "sql":
      return <SqlSvgIcon primary={primary} />;
    case "gke":
    case "gke_vm":
    case "node_pool":
      return <GkeSvgIcon primary={primary} />;
    case "ip":
      return <IpSvgIcon primary={primary} />;
    case "lb":
      return <LbSvgIcon />;

    // KUBERNETES
    case "kubernetes_node":
      return <KubernetesNodeSvgIcon primary={true} />;
    case "kubernetes_namespace":
      return <KubernetesNamespaceSvgIcon primary={true} />;
    case "kubernetes_pod":
      return <KubernetesPodsSvgIcon primary={true} />;
    case "kubernetes_deployment":
      return <KubernetesDeploymentSvgIcon primary={true} />;
    case "kubernetes_daemon_set":
      return <KubernetesDaemonSetsSvgIcon primary={true} />;
    case "kubernetes_stateful_set":
      return <KubernetesStatefulSetSvgIcon primary={true} />;
    case "kubernetes_replica_set":
      return <KubernetesReplicaSetSvgIcon primary={true} />;
    case "kubernetes_job":
      return <KubernetesJobSvgIcon primary={true} />;
  }
};
