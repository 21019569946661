import { ColDef } from "ag-grid-community";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../../store/alerts/utils/types/types";

export const alertsThresholdComparator: ColDef<
  CostAnomalyAlertsDataGridDataType | UtilizationAlertDataGridDataType
>["comparator"] = (_, __, nodeA, nodeB) => {
  const currentThreshold = nodeA?.data?.thresholds;
  const nextThreshold = nodeB?.data?.thresholds;

  if (!currentThreshold) {
    return -1;
  }
  if (!nextThreshold) {
    return +1;
  }

  const current =
    currentThreshold.find((threshold) => threshold.unit === "percent")
      ?.amount ?? currentThreshold?.at(0)?.amount;
  const currentFirstThresholdValue = Number(current ?? 0);
  const next =
    nextThreshold.find((threshold) => threshold.unit === "percent")?.amount ??
    nextThreshold?.at(0)?.amount;
  const nextFirstThresholdValue = Number(next ?? 0);

  return currentFirstThresholdValue > nextFirstThresholdValue ? 1 : -1;
};
