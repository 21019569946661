import { resourceDetailsResourceBillingDataSelector } from "../../billing-data/resourceDetailsResourceBillingDataSelector";
import { RootState } from "../../../../store";
import { ResourceExplorerWidget } from "../../../../../services/cloudchipr.api";

export const resourceDetailsCostsTabBillingDataSelector = (
  state: RootState,
  processKey: string,
): ResourceExplorerWidget | undefined => {
  return resourceDetailsResourceBillingDataSelector(state, processKey);
};
