import { FC } from "react";
import { Stack } from "@mui/material";
import { AccountsHeaderProviderSelect } from "./AccountsHeaderProviderSelect";
import { AccountsTabs, AccountsTabsProps } from "../accounts/tabs/AccountsTabs";

export const AccountsHeaderNavigation: FC<AccountsTabsProps> = (props) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <AccountsHeaderProviderSelect provider={props.provider} />
      <AccountsTabs {...props} />
    </Stack>
  );
};
