import { noOrgProviderOrganisationOrgId } from "./constants";
import {
  ProviderOrganisationsGroupedByProvider,
  ProviderOrganisationsGroupedByProviderAndOrgType,
} from "./types";
import {
  ProviderOrganisation,
  ProviderType,
} from "../../../../../services/cloudchipr.api";

export const providerOrganisationsByProviderAndOrgCombiner = (
  providerOrganisationsGroupedByProvider: ProviderOrganisationsGroupedByProvider | null,
) => {
  if (!providerOrganisationsGroupedByProvider) {
    return null;
  }

  return Object.entries(providerOrganisationsGroupedByProvider).reduce(
    (acc, entry) => {
      const provider = entry.at(0) as ProviderType;
      const accounts = (entry.at(1) ?? []) as ProviderOrganisation[];

      acc[provider] = accounts.reduce(
        (acc, org) => {
          const orgId = org.organization_id || noOrgProviderOrganisationOrgId;

          if (!acc[orgId]) {
            acc[orgId] = [];
          }

          acc[orgId].push(org);

          return acc;
        },
        {} as Record<string, ProviderOrganisation[]>,
      );

      return acc;
    },
    {} as ProviderOrganisationsGroupedByProviderAndOrgType,
  );
};
