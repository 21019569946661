import { FC, useState } from "react";
import { Box, Card } from "@mui/material";
import { AzureReservationsMultiTypeChart } from "./chart/multi-type-chart/AzureReservationsMultiTypeChart";
import { AzureReservationsGaugeChart } from "./chart/AzureReservationsGaugeChart";
import { AzureReservationsDataGrid } from "./data-grid/AzureReservationsDataGrid";
import { AzureReservationsMultiTypeChartMetadata } from "./chart/multi-type-chart/AzureReservationsMultiTypeChartMetadata";
import { PossibleVisibilities } from "../../../../../common/ScreenSplitButtons";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsChartTypeSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsChartTypeSelector";

import { CommitmentsScreenSplitButtons } from "../../../../common/components/CommitmentsScreenSplitButtons";

export const AzureReservationsContent: FC = () => {
  const [visibleContent, setVisibleContent] =
    useState<PossibleVisibilities>("chart_grid");
  const chartType = useAppSelector(commitmentsChartTypeSelector);

  return (
    <Card
      sx={{ p: 2, display: "flex", flex: 1, flexDirection: "column" }}
      variant="outlined"
    >
      {(chartType !== "gauge" || visibleContent === "grid") && (
        <AzureReservationsMultiTypeChartMetadata />
      )}

      {visibleContent !== "grid" && (
        <Box position="relative" zIndex={2} mb={2}>
          <AzureReservationsMultiTypeChart />
          <AzureReservationsGaugeChart />
        </Box>
      )}

      <CommitmentsScreenSplitButtons
        provider="azure"
        visibleContent={visibleContent}
        setVisibleContent={setVisibleContent}
      />

      {visibleContent !== "chart" && (
        <Box mt={2} display="flex" flex={1}>
          <AzureReservationsDataGrid />
        </Box>
      )}
    </Card>
  );
};
