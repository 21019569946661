import React, { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { CommandSnippet } from "../../../../../../../../common/connectors/components/CommandSnippet";

export const KubernetesClusterAddDrawerContentFirstStep: FC = () => {
  return (
    <Stack spacing={1}>
      <Typography variant="body1" color="text.secondary">
        Add Helm repository.
      </Typography>
      <CommandSnippet
        code={" helm repo add c8r https://cloudchipr.github.io/helm-charts"}
        parentBoxSx={{
          p: 2,
        }}
        copyButtonSize="xsmall"
      >
        <Typography variant="body2">
          helm repo add c8r https://cloudchipr.github.io/helm-charts
        </Typography>
      </CommandSnippet>
    </Stack>
  );
};
