import { PlotOptions } from "highcharts";
import { chartSeriesHighlightFunctions } from "../helpers/chartSeriesHighlightFunctions";

export const plotOptionsSeries: PlotOptions["series"] = {
  animation: false,
  states: { inactive: { opacity: 1 } },
  point: {
    events: {
      mouseOver: function () {
        const chart = this.series.chart;
        const seriesName = this.series.name;

        chartSeriesHighlightFunctions.onHoverIn(chart, seriesName);
      },

      mouseOut: function () {
        const chart = this.series.chart;

        chartSeriesHighlightFunctions.onHoverOut(chart);
      },
    },
  },
};
