import { LinearProgress, Stack } from "@mui/material";
import { FC } from "react";
import { BillingExplorerExplainWithAiContentCollapsedItem } from "./BillingExplorerExplainWithAiContentCollapsedItem";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { billingExplorerExplainWithAiDataSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiDataSelector";
import { billingExplorerSummaryLoadingByIndexSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/loading/billingExplorerSummaryLoadingByIndexSelector";

interface BillingExplorerExplainWithAiContentCollapsedStateProps {
  localLoading?: boolean;
}
export const BillingExplorerExplainWithAiContentCollapsedState: FC<
  BillingExplorerExplainWithAiContentCollapsedStateProps
> = ({ localLoading }) => {
  const explainWithAiData = useAppSelector(
    billingExplorerExplainWithAiDataSelector,
  );
  const loading = useAppSelector((state) =>
    billingExplorerSummaryLoadingByIndexSelector(state, 0),
  );

  return (
    <Stack spacing={1.5}>
      {explainWithAiData.map((item, index) => {
        if (!item.payload?.group_by) {
          return null;
        }

        return (
          <Stack
            key={item.key}
            sx={{ bgcolor: "grey.200", borderRadius: 2 }}
            overflow="hidden"
          >
            <BillingExplorerExplainWithAiContentCollapsedItem
              key={item.key}
              item={item}
              index={index}
              collapsable={explainWithAiData?.length !== 1 && index !== 0}
              localLoading={localLoading}
            />

            {index === 0 && (localLoading || loading) && <LinearProgress />}
          </Stack>
        );
      })}
    </Stack>
  );
};
