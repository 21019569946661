import { resourceExplorerPossibleGroupingsFormattedDataSelector } from "./resourceExplorerPossibleGroupingsFormattedDataSelector";
import { RootState } from "../../../store";

export const resourceExplorerGroupingFilterDataMapSelector = (
  state: RootState,
) => {
  const formattedData =
    resourceExplorerPossibleGroupingsFormattedDataSelector(state);

  return formattedData?.groupingFilterDataMap;
};
