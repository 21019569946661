export const getSlackFormatFromHTML = (html: string) => {
  return (
    html
      // Convert formatting tags to Slack style
      .replace(/<strong>(.*?)<\/strong>/gi, "*$1*")
      .replace(/<b>(.*?)<\/b>/gi, "*$1*")
      .replace(/<em>(.*?)<\/em>/gi, "_$1_")
      .replace(/<i>(.*?)<\/i>/gi, "_$1_")
      .replace(/<s>(.*?)<\/s>/gi, "~$1~")
      .replace(/<del>(.*?)<\/del>/gi, "~$1~")
      .replace(/<code>(.*?)<\/code>/gi, "`$1`")
      .replace(/<blockquote>(.*?)<\/blockquote>/gi, "> $1")
      .replace(/<br\s*\/?>(\s*)/gi, "\n")
      .replace(/<\/?p[^>]*>/gi, "\n")

      // Convert <a> tags, handling empty hrefs correctly
      .replace(
        /<a\s+(?:[^>]*?\s)?href=(['"])(.*?)\1[^>]*>([\s\S]*?)<\/a>/gi,
        (match, quote, url, text) => (url?.trim() ? `<${url}|${text}>` : text),
      )
  );
};
