import moment from "moment";
import { commitmentsDetailsDrawerOpenSelector } from "./details-drawer/commitmentsDetailsDrawerOpenSelector";
import { commitmentsTypeTabSelector } from "./commitmentsTypeTabSelector";
import { commitmentsDataViewTabSelector } from "./view-options/commitmentsDataViewTabSelector";
import { commitmentsCoverageGroupingSelector } from "./view-options/commitmentsCoverageGroupingSelector";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "./details-drawer/commitmentsDetailsDrawerCoverageGroupingSelector";
import { RootState } from "../../../store";
import { formatDate } from "../../../../utils/helpers/date-time/datetime-format";

export const commitmentsCsvFileNameSelector = (state: RootState) => {
  const drawerOpen = commitmentsDetailsDrawerOpenSelector(state);
  const commitmentsTypeTab = commitmentsTypeTabSelector(state);
  const dataViewTab = commitmentsDataViewTabSelector(state);
  const coverageGrouping = commitmentsCoverageGroupingSelector(state);
  const drawerCoverageGrouping =
    commitmentsDetailsDrawerCoverageGroupingSelector(state);
  const grouping = drawerOpen ? drawerCoverageGrouping : coverageGrouping;

  const date = formatDate(moment(), { type: "date" });

  let ending = "";

  if (dataViewTab === "coverage" || drawerOpen) {
    ending = `_${grouping}`;
  }

  if (commitmentsTypeTab === "overview") {
    ending = "_recommendations";
  }

  return `${date}_${commitmentsTypeTab}_${dataViewTab}${ending}`;
};
