import { azureReservationSelectedCoverageDataSelector } from "./azureReservationSelectedCoverageDataSelector";
import { RootState } from "../../../../../../store";

export const azureReservationSelectedCoverageDataAccountNameAndIdSelector = (
  state: RootState,
): string => {
  const data = azureReservationSelectedCoverageDataSelector(state);

  return `${data?.account?.name} (${data?.account?.provider_account_id})`;
};
