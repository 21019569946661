import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { GridApi } from "ag-grid-community";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { createSavingsOpportunityViewLoadingSelector } from "../../../../../store/savings-opportunities/selectors/views-hierarchy/loadings/createSavingsOpportunityViewLoadingSelector";
import { createSavingsOpportunityViewThunk } from "../../../../../store/savings-opportunities/thunks/views-hierarchy/actions/createSavingsOpportunityViewThunk";
import { hierarchyViewIdSearchParamKey } from "../../../common/views-hierarchy-tabs/utils/constants/constants";
import { savingsOpportunitiesPathName } from "../../utils/constants/common";
import { setSavingsOpportunitiesCurrentViewId } from "../../../../../store/savings-opportunities/savingsOpportunitiesSlice";
import { savingsOpportunityCurrentViewSelector } from "../../../../../store/savings-opportunities/selectors/views-hierarchy/current-view/savingsOpportunityCurrentViewSelector";
import { ViewsHierarchySaveAsNewSnackBar } from "../../../common/views-hierarchy-tabs/components/ViewsHierarchySaveAsNewSnackBar";

interface SavingsOpportunitiesViewsHierarchySaverSnackbarActionsProps {
  gridApi: GridApi | null;
  onClose(): void;
}

const SavingsOpportunitiesViewsHierarchySaverSnackbarActions: FC<
  SavingsOpportunitiesViewsHierarchySaverSnackbarActionsProps
> = ({ onClose, gridApi }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loading = useAppSelector(createSavingsOpportunityViewLoadingSelector);
  const currentView = useAppSelector(savingsOpportunityCurrentViewSelector);

  const submitHandler = useCallback(
    async (viewName: string) => {
      const gridConfig = JSON.stringify(gridApi?.getState());

      const response = await dispatch(
        createSavingsOpportunityViewThunk({
          body: { name: viewName, config: { gridConfig } },
        }),
      ).unwrap();

      if (response) {
        dispatch(setSavingsOpportunitiesCurrentViewId(response?.id));
        navigate(
          `${savingsOpportunitiesPathName}?${hierarchyViewIdSearchParamKey}=${response?.id}`,
        );
      }

      return response;
    },
    [dispatch, navigate, gridApi],
  );

  return (
    <ViewsHierarchySaveAsNewSnackBar
      currentView={currentView}
      onClose={onClose}
      loading={loading}
      onSubmit={submitHandler}
    />
  );
};

export const getSavingsOpportunitiesViewsHierarchySaverSnackbarActions = (
  gridApi: GridApi | null,
  onClose: () => void,
) => {
  return (
    <SavingsOpportunitiesViewsHierarchySaverSnackbarActions
      gridApi={gridApi}
      onClose={onClose}
    />
  );
};
