import { RootState } from "../../../store";
import { resourceExplorerHierarchyVisibilityFoldersSelector } from "../views-hierarchy-visibility/resourceExplorerHierarchyVisibilityFoldersSelector";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";
import { TreeItems } from "../../../../components/app-navigation/components/sortable-tree/utils/types";

export const resourceExplorerViewsHierarchyFoldersSelector = (
  state: RootState,
  visibility: NavigationItemsVisibilityType,
): TreeItems | undefined => {
  return resourceExplorerHierarchyVisibilityFoldersSelector(state, visibility);
};
