import { FC } from "react";
import { Avatar, AvatarGroup, Tooltip } from "@mui/material";
import { ProviderIcon } from "../../../../common/ProviderIcon";
import { getCloudProvidersGroupTooltipTitle } from "../utils/helpers/getCloudProvidersGroupTooltipTitle";
import { ResourceExplorerGroupingNullable } from "../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../store/hooks";
import { resourceExplorerGroupingsWithNoParentProvidersByGroupingSelector } from "../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingsWithNoParentProvidersByGroupingSelector";

interface GroupingAvailableProvidersProps {
  grouping: ResourceExplorerGroupingNullable;
  size?: "medium" | "small";
}

export const GroupingAvailableProviders: FC<
  GroupingAvailableProvidersProps
> = ({ grouping, size = "medium" }) => {
  const groupingProviders = useAppSelector((state) =>
    resourceExplorerGroupingsWithNoParentProvidersByGroupingSelector(
      state,
      grouping,
    ),
  );

  const title = getCloudProvidersGroupTooltipTitle(grouping, groupingProviders);
  const { avatarSize, iconSize, spacing } = sizes[size];
  const isMoreThanLimit = groupingProviders?.length ?? 0 > maxElementsSize;

  return (
    <Tooltip arrow placement="top" title={title}>
      <AvatarGroup
        spacing={spacing}
        max={maxElementsSize}
        sx={{
          ...avatarGroupStyles,
          "& .MuiAvatar-root:nth-of-type(1)": {
            height: isMoreThanLimit ? avatarSize - 1 : avatarSize,
            width: isMoreThanLimit ? avatarSize - 1 : avatarSize,
            fontSize: isMoreThanLimit ? iconSize - 2 : iconSize,
          },
        }}
      >
        {groupingProviders?.map((provider) => {
          return (
            <Avatar
              key={provider}
              sx={{ bgcolor: "white", height: avatarSize, width: avatarSize }}
            >
              <ProviderIcon
                provider={provider}
                key={provider}
                width={iconSize}
                disabled={grayedProviders.includes(provider)}
              />
            </Avatar>
          );
        })}
      </AvatarGroup>
    </Tooltip>
  );
};

const maxElementsSize = 5;

const sizes: Record<
  "medium" | "small",
  { avatarSize: number; iconSize: number; spacing: number }
> = {
  medium: {
    avatarSize: 28,
    iconSize: 16,
    spacing: 12,
  },
  small: {
    avatarSize: 23,
    iconSize: 13,
    spacing: 10,
  },
};

const avatarGroupStyles = {
  "& >.MuiAvatar-root": {
    border: 1,
    borderColor: "grey.200",
  },
};

const grayedProviders = ["eks"];
