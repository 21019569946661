import React, { FC } from "react";
import { Stack } from "@mui/material";
import { UnauthorizedDashboardBillingSummaryWidget } from "./UnauthorizedDashboardBillingSummaryWidget";
import { DashboardWidgetCostAndUsageSummary } from "../../../../../services/cloudchipr.api";
import { WidgetNumeralView } from "../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { UnauthorizedWidgetHookArguments } from "../../utils/types/types";
import { useUnauthorizedBillingSummaryHook } from "../../utils/hooks/useUnauthorizedBillingSummary.hook";
import { UnauthorizedWidgetHeader } from "../UnauthorizedWidgetHeader";

interface UnauthorizedDashboardBillingSummaryWidgetWrapperProps
  extends DashboardWidgetCostAndUsageSummary,
    Omit<UnauthorizedWidgetHookArguments, "widgetId"> {
  onWidgetMount?(): void;
}

export const UnauthorizedDashboardBillingSummaryWidgetWrapper: FC<
  UnauthorizedDashboardBillingSummaryWidgetWrapperProps
> = ({
  id,
  overrideQueryDates,
  organisationId,
  categoryId,
  name,
  grouping,
  dates,
  ...props
}) => {
  const { data, isFetching, error, isError, isUninitialized } =
    useUnauthorizedBillingSummaryHook({
      widgetId: id,
      overrideQueryDates,
      organisationId,
      categoryId,
    });

  if (isFetching || isUninitialized) {
    return (
      <Stack flexGrow={1} overflow="auto">
        <UnauthorizedWidgetHeader
          name={name}
          dates={dates}
          organisationId={organisationId ?? ""}
        />
        <WidgetNumeralView loading={true} skeletonCount={6}></WidgetNumeralView>
      </Stack>
    );
  }

  return (
    <UnauthorizedDashboardBillingSummaryWidget
      {...props}
      id={id}
      data={data}
      isError={isError}
      error={error}
      name={name}
      grouping={grouping}
      dates={dates}
    />
  );
};
