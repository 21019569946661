import { FC, Fragment } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CloseIcon from "@mui/icons-material/Close";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { IntegrationLogo } from "../../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import JiraVideo from "../../../../../../../assets/integrations/jira_integration.mp4";
import JiraImg from "../../../../../../../assets/integrations/jira_image.png";
import { VideoWithoutCaptions } from "../../../../../../common/VideoWithoutCaptions";

export const JiraHelperVideo: FC = () => {
  const { open, openDialog, closeDialog } = useDialog();

  return (
    <Fragment>
      <Divider sx={{ p: 1, color: "grey.50" }} />
      <Grid container justifyContent="center">
        <Grid item sm={12} md={8} lg={8}>
          <Stack justifyContent="center" alignItems="center">
            <Typography variant="h5" fontWeight="bold" mt={1}>
              Need Help?
            </Typography>
            <Typography
              variant="h6"
              fontWeight="regular"
              color="text.secondary"
              mt={1}
              mb={4}
            >
              Follow the simple steps below to create{" "}
              <Typography
                component="span"
                variant="h6"
                fontWeight="regular"
                textTransform="capitalize"
              >
                jira
              </Typography>{" "}
              integration.
            </Typography>
          </Stack>

          <Card variant="outlined" sx={{ m: 1 }}>
            <CardHeader
              avatar={<IntegrationLogo type="jira" width={25} />}
              title="Create jira Integration"
              titleTypographyProps={{
                fontWeight: "bold",
                textTransform: "capitalize",
                fontSize: 16,
              }}
            />
            <Divider sx={{ color: "grey.50" }} />
            <CardContent>
              <Stack
                sx={{
                  backgroundImage: `url(${JiraImg})`,
                  height: "220px",
                  backgroundSize: "cover",
                  borderRadius: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  sx={{
                    bgcolor: "grey.600",
                  }}
                  onClick={openDialog}
                >
                  <PlayArrowIcon fontSize="large" />
                </IconButton>
              </Stack>
            </CardContent>
            <CardActions sx={{ justifyContent: "space-between" }}>
              <Button
                fullWidth
                variant="contained"
                component={Link}
                href="https://start.atlassian.com"
                target="_blank"
                sx={{ mb: 2, mx: 1 }}
              >
                Go to jira
              </Button>
            </CardActions>
            <Dialog fullWidth maxWidth="md" open={open} onClose={closeDialog}>
              <VideoWithoutCaptions
                src={JiraVideo}
                autoPlay
                loop
                height={500}
              />

              <IconButton
                onClick={closeDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Dialog>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};
