import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { getResourceDetailsResourceBillingDataFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";
import { RootState } from "../../../../store";

const selector =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentResourceExplorer
    .select;

export const resourceDetailsResourceBillingRequestSelector = (
  state: RootState,
  processKey: string,
) => {
  return selector(
    getResourceDetailsResourceBillingDataFixedCacheKey(processKey),
  )(state);
};
