import { FC } from "react";
import { CoverageWidgetAdvancedSettingsTrendSetting } from "./CoverageWidgetAdvancedSettingsTrendSetting";
import { WidgetConfigSection } from "../../../../../common/widget-configurations/WidgetConfigSection";
import AdvancedSettingsIcon from "../../../../../../../../../../../../assets/images/icons/advanced_settings_icon.svg";

export const CoverageWidgetAdvancedSettingsSection: FC = () => {
  return (
    <WidgetConfigSection
      title="Advanced Settings"
      icon={<img src={AdvancedSettingsIcon} alt="Advanced Settings Icon" />}
    >
      <CoverageWidgetAdvancedSettingsTrendSetting />
    </WidgetConfigSection>
  );
};
