import { FC, Fragment, useCallback } from "react";
import {
  Divider,
  ListItem,
  ListItemButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { DropdownHeaderComponentProps } from "../../../../../../common/select/dropdown-select/utils/types/types";

interface GroupingByTagKeysHeaderProps extends DropdownHeaderComponentProps {
  onSubmit(values: string[]): void;
}

export const GroupingByTagKeysHeader: FC<GroupingByTagKeysHeaderProps> = ({
  onSubmit,
  onSearchChange,
  onClose,
}) => {
  const handleClick = useCallback(() => {
    onSubmit([]);
    onClose();
  }, [onSubmit, onClose]);

  return (
    <Fragment>
      <Stack p={2}>
        <TextField
          autoFocus
          size="xsmall"
          variant="outlined"
          placeholder="Search..."
          onChange={onSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Divider />
      <ListItem sx={{ p: 0 }}>
        <ListItemButton sx={{ py: 2 }} onClick={handleClick}>
          <Typography variant="body2">All Keys</Typography>
        </ListItemButton>
      </ListItem>
    </Fragment>
  );
};
