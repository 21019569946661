import React, { ChangeEvent, FC, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { getPressEnterHandler } from "../../../../../../utils/helpers/getPressEnterHandler";
import { navigationHierarchyItemNoDndKey } from "../../../../../../app-navigation/components/sortable-tree/utils/constants";
import { DialogTitleClosable } from "../../../../../../common/dialog-utils/DialogTitleClosable";
import { View } from "../../../../../../../services/cloudchipr.api";
import { useViewsHierarchyContext } from "../../ViewsHierarchyProvider";

export interface HierarchyViewRenameActionGlobalProps {
  loading: boolean;
  onRename(viewId: string, name: string): Promise<View>;
}

interface HierarchyViewRenameActionDialogProps {
  open: boolean;
  viewId: string;
  viewName: string;
  onClose(): void;
}

export const HierarchyViewRenameActionDialog: FC<
  HierarchyViewRenameActionDialogProps
> = ({ open, onClose, viewId, viewName }) => {
  const [value, setValue] = useState(viewName ?? "");
  const { loading, onRename } = useViewsHierarchyContext().actions.viewRename;

  const renameHandler = useCallback(async () => {
    const response = await onRename(viewId, value);

    onClose();

    if (response) {
      const snackbarId: SnackbarKey = enqueueSnackbar(
        "View name successfully updated.",
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );
    }
  }, [onClose, viewId, value, onRename]);

  const inputChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [],
  );

  const disabled = value === viewName || !value;
  const pressEnterHandler = getPressEnterHandler(
    renameHandler,
    disabled || loading,
  );

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onClose}
      onKeyDown={pressEnterHandler}
      {...navigationHierarchyItemNoDndKey}
    >
      <DialogTitleClosable title="Rename View Name" onClose={onClose} />

      <DialogContent dividers sx={{ py: 3 }}>
        <TextField
          autoFocus
          fullWidth
          type="text"
          value={value}
          size="small"
          variant="outlined"
          onChange={inputChangeHandler}
          label="View Name"
          placeholder="View Name"
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button color="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          disabled={disabled}
          onClick={renameHandler}
          loading={loading}
          variant="contained"
        >
          Rename
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
