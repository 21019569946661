import { FC, useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { useDebounce } from "rooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { taskManagementDescriptionSelector } from "../../../../../../../../../store/task-management/selectors/form/properties/taskManagementDescriptionSelector";
import { updateTaskManagementTask } from "../../../../../../../../../store/task-management/taskManagementSlice";
import { usersListSelector } from "../../../../../../../../../store/user/selectors/usersListSelector";
import { Editor } from "../../../../../../../../../storybook/editor/Editor";
import { taskManagementUpdateEditingTaskThunk } from "../../../../../../../../../store/task-management/thunks/actions/taskManagementUpdateEditingTaskThunk";

export const TaskCreationDescription: FC = () => {
  const dispatch = useAppDispatch();

  const value = useAppSelector(taskManagementDescriptionSelector);
  const users = useAppSelector(usersListSelector);

  const suggestions = useMemo(() => {
    return users?.map((user) => ({
      id: user.id,
      label: user.name || user.email || user.id,
    }));
  }, [users]);

  const handleSaveDebounced = useDebounce(() => {
    dispatch(taskManagementUpdateEditingTaskThunk());
  }, 500);

  const changeHandler = useCallback(
    (value: string) => {
      dispatch(updateTaskManagementTask({ description: value }));
      handleSaveDebounced();
    },
    [dispatch, handleSaveDebounced],
  );

  if (!suggestions) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <Typography fontWeight="medium">Description</Typography>

      <Editor
        content={value}
        placeholder="Add Description"
        commands={["bold", "italic", "strike", "code", "link"]}
        suggestions={suggestions}
        onChange={changeHandler}
      />
    </Stack>
  );
};
