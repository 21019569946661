import { FC, useCallback } from "react";
import { CommitmentUtilizationDisabledActions } from "./disabled-actions/CommitmentUtilizationDisabledActions";
import { WidgetOptionCard } from "../../WidgetOptionCard";
import { dashboardWidgetLabels } from "../../../../utils/constants/constants";
import Cover from "../../../../utils/assets/images/widget-covers/coverage_widget_cover.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { dashboardsPossibleWidgetStatusByWidgetTypeSelector } from "../../../../../../../store/dashboards/selectors/widget-options/dashboardsPossibleWidgetStatusByWidgetTypeSelector";

import { initiateCoverageWidgetThunk } from "../../../../../../../store/dashboards/thunks/widgets/coverage-widget/initiateCoverageWidgetThunk";

export const CoverageWidgetCard: FC = () => {
  const dispatch = useAppDispatch();

  const clickHandler = useCallback(() => {
    dispatch(initiateCoverageWidgetThunk());
  }, [dispatch]);

  const status = useAppSelector((state) =>
    dashboardsPossibleWidgetStatusByWidgetTypeSelector(state, "coverage"),
  );

  return (
    <WidgetOptionCard
      onClick={clickHandler}
      title={dashboardWidgetLabels.coverage ?? ""}
      description="View the overall cloud coverage"
      disabledAction={status ? <CommitmentUtilizationDisabledActions /> : null}
    >
      <img src={Cover} alt="ConstAndUsageCard" />
    </WidgetOptionCard>
  );
};
