import { FC, Fragment } from "react";
import { BillingExplorerWidgetVisualizationSection } from "./components/visualization/BillingExplorerWidgetVisualizationSection";
import { BillingExplorerWidgetAggregationsSection } from "./components/aggregations/BillingExplorerWidgetAggregationsSection";
import { BillingExplorerWidgetAdvancedSettingsSection } from "./components/advanced-settings/BillingExplorerWidgetAdvancedSettingsSection";

export const BillingExplorerWidgetConfigurations: FC = () => {
  return (
    <Fragment>
      <BillingExplorerWidgetVisualizationSection />
      <BillingExplorerWidgetAggregationsSection />
      <BillingExplorerWidgetAdvancedSettingsSection />
    </Fragment>
  );
};
