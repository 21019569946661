import { GridState, StateUpdatedEvent } from "ag-grid-community";
import { useCallback, useEffect, useRef, useState } from "react";
import { agGridDisabledSourcesForStateUpdate } from "../constants/agCommon";
import { isGridStatesEqual } from "../../../../savings-opportunities/utils/helpers/isGridStatesEqual";

export type UseGridDebouncedConfigsUpdateHookReturnType = {
  onStateDebouncedUpdate: (params: StateUpdatedEvent) => void;
};

export const useGridDebouncedConfigsUpdate = (
  onStateChange: (griState: GridState | null) => void,
  debounce: number,
  configs?: string,
): UseGridDebouncedConfigsUpdateHookReturnType => {
  const debounceRef = useRef(0);
  const changeHandlerRef = useRef(onStateChange);

  const [localState, setLocalState] = useState<GridState | null>(null);

  const onStateDebouncedUpdate = useCallback(
    (params: StateUpdatedEvent) => {
      const noNeedForUpdate = agGridDisabledSourcesForStateUpdate.some(
        (source) => params.sources.includes(source),
      );
      if (noNeedForUpdate || !params.state) {
        return;
      }

      const isEqual = isGridStatesEqual({
        viewConfig: configs,
        gridState: params.state,
      });

      if (isEqual) {
        return;
      }

      setLocalState(JSON.parse(JSON.stringify(params.state ?? {})));
    },
    [configs],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      localState && changeHandlerRef.current?.(localState);
    }, debounceRef.current);

    return () => clearTimeout(timeout);
  }, [localState]);

  useEffect(() => {
    debounceRef.current = debounce;
  }, [debounce]);

  useEffect(() => {
    changeHandlerRef.current = onStateChange;
    setLocalState(null);
  }, [onStateChange]);

  return { onStateDebouncedUpdate };
};
