import moment from "moment/moment";
import { RootState } from "../../../store";
import { calculateFromToByDatesData } from "../../../../components/common/date-range-picker/utils/helpers/calculateFromToByDatesData";
import { resourceExplorerDatesSelector } from "../current-resource-explorer/data/resourceExplorerDatesSelector";
import { organisationQuarterStartMonthSelector } from "../../../common/selectors/org-current-settings/organisationQuarterStartMonthSelector";

export const isBillingExplorerDatesOutRangesForSummarySelector = (
  state: RootState,
): boolean => {
  const dates = resourceExplorerDatesSelector(state);
  const quarterStartMonth = organisationQuarterStartMonthSelector(state);
  const datesData = calculateFromToByDatesData(dates ?? {}, quarterStartMonth);
  const fromDate = moment(datesData?.fromDate ?? "", "DD MMM YYYY");
  const toDate = moment(datesData?.toDate ?? "", "DD MMM YYYY");
  const diffDays = toDate.diff(fromDate, "days");
  return diffDays > 60;
};
