import { FC, Fragment, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import { DialogActions, DialogContent, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { IntegrationType } from "./components/common/integration-type/IntegrationTypeCard";
import { IntegrationForm } from "./components/common/IntegrationForm";
import { IntegrationFormActions } from "./components/common/IntegrationFormActions";
import { HelperGuideByType } from "./components/common/HelperGuideByType";
import {
  Integration,
  useGetUsersMeIntegrationsQuery,
  usePutUsersMeByTypeIntegrationsAndIntegrationIdMutation,
  usePostUsersMeByTypeIntegrationsMutation,
} from "../../../../../services/cloudchipr.api";
import {
  generateCreatePayloadData,
  generateUpdatePayloadData,
} from "../../../../../utils/helpers/integrations";
import { integrationsValidationSchema } from "../../utils/validation";
import { enqueueSnackbarErrorAlert } from "../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";

const initialValues: Integration = {
  id: "",
  user_id: "",
  organisation_id: "",

  name: "",
  type: "" as IntegrationType,
  emails: null,
  token: null,
  url: null,
  content_type: null,
  api_token: null,
  jira_email: null,
  default_issue_type_id: null,
  default_project_key: null,
  metadata: null,
  conversation: null,
  conversations: null,
  created_at: null,
};

interface IntegrationCreationProps {
  integration?: Integration;
  type?: IntegrationType;
  inDialog?: boolean;
  onNewIntegrationCreate?(type: IntegrationType, integrationId: string): void;
  onCancel(): void;
  onSubmit(): void;
  disabledIntegrationTypes?: IntegrationType[];
}

export const IntegrationCreation: FC<IntegrationCreationProps> = ({
  integration,
  type,
  onCancel,
  onNewIntegrationCreate,
  inDialog,
  onSubmit,
  disabledIntegrationTypes,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createIntegration] = usePostUsersMeByTypeIntegrationsMutation({});
  const [updateIntegration] =
    usePutUsersMeByTypeIntegrationsAndIntegrationIdMutation({});
  const { refetch } = useGetUsersMeIntegrationsQuery({});

  const formik = useFormik({
    initialValues,
    validationSchema: integrationsValidationSchema,
    onSubmit: async (values) => {
      try {
        if (values.id) {
          await updateIntegration(generateUpdatePayloadData(values)).unwrap();

          enqueueSnackbar("Integration successfully updated", {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
            },
          });

          onNewIntegrationCreate?.(values.type, values.id);
        } else {
          const { id } = await createIntegration(
            generateCreatePayloadData(values),
          ).unwrap();

          onNewIntegrationCreate?.(values.type, id);
          enqueueSnackbar("Integration successfully created", {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
            },
          });
        }

        await refetch();
        onSubmit();
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  });

  const {
    isValid,
    values,
    errors,
    handleChange,
    touched,
    submitForm,
    setFieldValue,
    isSubmitting,
    setValues,
  } = formik;

  const integrationTypeChangeHandler = useCallback(
    (type: IntegrationType) => {
      setValues({ ...initialValues, name: values.name, type });
    },
    [values.name, setValues],
  );

  useEffect(() => {
    if (integration && type) {
      setValues({ ...integration, type });
    } else if (type) {
      setFieldValue("type", type);
    }
  }, [integration, type, setValues, setFieldValue]);

  const integrationFormComponent = (
    <form>
      <IntegrationForm
        id={values.id}
        workspaceId={values.workspace_id}
        values={values}
        errors={errors}
        touched={touched}
        typeChangeDisabled={!!type}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        onTypeChange={integrationTypeChangeHandler}
        disabledIntegrationTypes={disabledIntegrationTypes}
      />
    </form>
  );
  const integrationFormActions = (
    <IntegrationFormActions
      edit={!!values.id}
      disabled={!values.type || !isValid}
      type={values.type}
      loading={isSubmitting}
      onSubmit={submitForm}
      onCancel={onCancel}
    />
  );

  return inDialog ? (
    <Fragment>
      <DialogContent sx={{ minHeight: 500 }} dividers>
        {integrationFormComponent}
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3 }}>
        {integrationFormActions}
      </DialogActions>
    </Fragment>
  ) : (
    <Fragment>
      <Grid container justifyContent="center" mb={10}>
        <Grid item xs={12} md={9} lg={8} xl={7}>
          {integrationFormComponent}
        </Grid>
      </Grid>

      {integrationFormActions}

      <HelperGuideByType type={values.type} />
    </Fragment>
  );
};
