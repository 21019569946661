import { FC, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";
import {
  CoverageWidgetAggregationsSwitch,
  CoverageWidgetAggregationsSwitchProps,
} from "./CovarageWidgetAggregationsSwitch";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../store/hooks";
import { coverageSetupAggregationSelector } from "../../../../../../../../../../../../store/dashboards/selectors/setups/coverage/aggregation/coverageSetupAggregationSelector";
import { WidgetCoverageAggregation } from "../../../../../../../../../../../../services/cloudchipr.api";
import { coverageWidgetAggregationChangeThunk } from "../../../../../../../../../../../../store/dashboards/thunks/widgets/coverage-widget/setup-change/coverageWidgetAggregationChangeThunk";
import { WidgetConfigSection } from "../../../../../common/widget-configurations/WidgetConfigSection";
import AggregationsIcon from "../../../../../../../../../../../../assets/images/icons/aggregations_icon.svg";

export const CoverageWidgetAggregationsSection: FC = () => {
  const dispatch = useAppDispatch();
  const aggregations = useAppSelector(coverageSetupAggregationSelector);

  const switchChecked = useMemo(
    () =>
      Object.keys(aggregations ?? {}).some(
        (key) => !!aggregations?.[key as keyof WidgetCoverageAggregation],
      ),
    [aggregations],
  );

  const handleSwitchChange = useCallback(() => {
    const aggregationsNewState = Object.keys(aggregations ?? {})?.reduce(
      (acc, key) => {
        acc[key as keyof WidgetCoverageAggregation] = !switchChecked;
        return acc;
      },
      {} as WidgetCoverageAggregation,
    );

    dispatch(coverageWidgetAggregationChangeThunk(aggregationsNewState));
  }, [aggregations, switchChecked, dispatch]);

  return (
    <WidgetConfigSection
      title="Aggregations"
      icon={<img src={AggregationsIcon} alt="Aggregations Icon" />}
      switchChecked={switchChecked}
      onSwitchChange={handleSwitchChange}
    >
      <Stack spacing={1.5}>
        {aggregationsSwitchesData.map(({ selectorKey, title }) => (
          <CoverageWidgetAggregationsSwitch
            selectorKey={selectorKey}
            key={selectorKey}
            title={title}
          />
        ))}
      </Stack>
    </WidgetConfigSection>
  );
};

const aggregationsSwitchesData: CoverageWidgetAggregationsSwitchProps[] = [
  { selectorKey: "covered", title: "Covered" },
  { selectorKey: "not_covered", title: "Not Covered (On-Demand)" },
  { selectorKey: "real_cost", title: "Real Cost" },
  { selectorKey: "net_savings", title: "Net Savings" },
];
