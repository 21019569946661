import { costAnomaliesSetupPropertyByKeySelector } from "./costAnomaliesSetupPropertyByKeySelector";
import { RootState } from "../../../../store";
import { widgetViewNameByViewIdIdSelector } from "../../widgets/common/widgetViewNameByViewIdIdSelector";
import { objectToUrl } from "../../../../../components/utils/helpers/dataToUrlToDataConvertors";
import { WidgetTitleProps } from "../../../../../components/pages/dashboard/utils/types/types";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";

export const costAnomaliesSetupWidgetTitleSelector = (
  state: RootState,
): WidgetTitleProps => {
  const grouping = costAnomaliesSetupPropertyByKeySelector("grouping")(state);
  const groupingValues =
    costAnomaliesSetupPropertyByKeySelector("group_values")(state);

  const widgetName = costAnomaliesSetupPropertyByKeySelector("name")(state);

  const dates = costAnomaliesSetupPropertyByKeySelector("dates")(state);
  const viewId = costAnomaliesSetupPropertyByKeySelector("viewId")(state);
  const viewName = widgetViewNameByViewIdIdSelector(state, viewId);
  const groupByLabel = resourceExplorerGroupingLabelByGroupingSelector(
    state,
    grouping,
  );
  const path = objectToUrl([
    {
      groupedBy: grouping,
      groupValues: groupingValues,
      dates: dates,
      filterTree: undefined,
    },
  ]);

  return {
    groupBy: grouping,
    groupValues: groupingValues,
    groupByLabel,
    widgetName,
    dates: dates,
    sourceData: {
      title: viewName ?? "",
      url: `/resource-explorer/${viewId}?path=${path}`,
    },
  };
};
