import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetCoverageAggregation } from "../../../../../../services/cloudchipr.api";
import { coverageWidgetSetupSelector } from "../../../../selectors/setups/coverage/coverageWidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";

export const coverageWidgetAggregationChangeThunk = createAsyncThunk(
  "dashboard/coverageWidgetAggregationChange",
  async (aggregation: WidgetCoverageAggregation, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = coverageWidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }

    dispatch(
      setWidgetSetup({
        ...existingSetup,
        aggregation: {
          ...existingSetup.aggregation,
          ...aggregation,
        },
      }),
    );
  },
);
