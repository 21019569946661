import { FC } from "react";
import HorizontalSplitOutlinedIcon from "@mui/icons-material/HorizontalSplitOutlined";
import { Table, TableBody } from "@mui/material";
import { ResourceDetailsGeneralContentDetailsTableRow } from "./ResourceDetailsGeneralContentDetailsTableRow";
import { ResourceDetailsContentCollapsableCard } from "../../../ResourceDetailsContentCollapsableCard";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { resourceDetailsTargetSelector } from "../../../../../../../../../store/resource-details/selectors/target-resource/resourceDetailsTargetSelector";
import { TypographyWithCopy } from "../../../../../../../../common/TypographyWithCopy";

export const ResourceDetailsGeneralContentDetails: FC = () => {
  const target = useAppSelector(resourceDetailsTargetSelector);

  if (!target) {
    return null;
  }

  const { resource, resourceAdditionalDetails } = target;

  if (!resource) {
    return null;
  }

  return (
    <ResourceDetailsContentCollapsableCard
      title="Details"
      Icon={<HorizontalSplitOutlinedIcon fontSize="small" />}
    >
      <Table sx={{ tableLayout: "fixed" }}>
        <TableBody>
          {resourceAdditionalDetails?.map((detail, i) => {
            return (
              <ResourceDetailsGeneralContentDetailsTableRow
                key={detail.title}
                label={detail.title}
                highlight={i % 2 === 0}
              >
                <TypographyWithCopy value={detail.value} variant="body2" />
              </ResourceDetailsGeneralContentDetailsTableRow>
            );
          })}
        </TableBody>
      </Table>
    </ResourceDetailsContentCollapsableCard>
  );
};
