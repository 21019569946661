import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCostAnomaliesWidgetDataBySetupThunk } from "../data-get/getCostAnomaliesWidgetDataBySetupThunk";
import { RootState } from "../../../../../store";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { CostAnomaliesSetupType } from "../../../../utils/types/setups/costAnomaliesSetupType";
import { costAnomaliesSetupSelector } from "../../../../selectors/setups/cost-anomalies/costAnomaliesSetupSelector";

export const costAnomaliesSetupChangeThunk = createAsyncThunk(
  "dashboards/costAnomaliesSetupChange",
  async (setup: Partial<CostAnomaliesSetupType>, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = costAnomaliesSetupSelector(state);

    if (!existingSetup) {
      return;
    }

    const newSetup = { ...existingSetup };

    let needToFetch = false;

    if (setup.id) {
      newSetup.id = setup.id;
    }

    if (setup.name !== undefined) {
      newSetup.name = setup.name;
    }

    if (setup.viewId) {
      newSetup.viewId = setup.viewId;
    }
    if (setup.priceDirection) {
      needToFetch = true;
      newSetup.priceDirection = setup.priceDirection;
    }
    if (setup.grouping) {
      needToFetch = true;
      newSetup.grouping = setup.grouping;
      newSetup.group_values = setup.group_values ?? null;
    }
    if (setup.sortingBy) {
      needToFetch = true;
      newSetup.sortingBy = setup.sortingBy;
    }
    if (setup.itemsCount) {
      needToFetch = true;
      newSetup.itemsCount = setup.itemsCount;
    }
    if (setup.minCostChange) {
      needToFetch = setup.minCostChange?.value !== null;
      newSetup.minCostChange = setup.minCostChange;
    }
    if (setup.minPercentChange) {
      needToFetch = setup.minPercentChange?.value !== null;
      newSetup.minPercentChange = setup.minPercentChange;
    }

    if (setup.grouping) {
      needToFetch = true;
      newSetup.grouping = setup.grouping;
    }

    if (setup.dates) {
      needToFetch = true;
      newSetup.dates = setup.dates;
    }

    dispatch(setWidgetSetup(newSetup));

    if (needToFetch) {
      dispatch(getCostAnomaliesWidgetDataBySetupThunk());
    }
  },
);
