import { ProviderType, ResourceType } from "../../../services/cloudchipr.api";

export const allResourceTypesWithProviders: Record<ResourceType, ProviderType> =
  {
    // AWS ------------------------------------------------------------
    ec2: "aws",
    asg: "aws",
    asg_ec2: "aws",
    s3: "aws",
    ebs: "aws",
    ebs_snapshot: "aws",
    rds: "aws",
    rds_snapshot: "aws",
    rds_snapshot_source: "aws",
    elb: "aws",
    eip: "aws",
    elc: "aws",
    eks: "aws",
    eks_ec2: "aws",
    node_group: "aws",
    dynamo_db_table: "aws",
    dynamo_db_backup: "aws",
    dynamo_db_backup_source: "aws",
    dax_cluster: "aws",
    nat_gateway: "aws",
    ecs: "aws",
    ecs_workload: "aws",

    // GCP ------------------------------------------------------------
    vm: "gcp",
    disk: "gcp",
    sql: "gcp",
    gke: "gcp",
    node_pool: "gcp",
    gke_vm: "gcp",
    lb: "gcp",
    ip: "gcp",
    mig: "gcp",
    mig_vm: "gcp",

    // AZURE ------------------------------------------------------------
    az_vm: "azure",
    az_disk: "azure",
    az_ip: "azure",
    az_lb: "azure",
    az_sql: "azure",
    az_vmss: "azure",
    az_vmss_vm: "azure",
    az_aks: "azure",
    az_aks_vm: "azure",
    az_node_pool: "azure",

    // kubernetes
    kubernetes_node: "kubernetes",
    kubernetes_namespace: "kubernetes",
    kubernetes_deployment: "kubernetes",
    kubernetes_daemon_set: "kubernetes",
    kubernetes_stateful_set: "kubernetes",
    kubernetes_replica_set: "kubernetes",
    kubernetes_job: "kubernetes",
    kubernetes_pod: "kubernetes",
  };
