import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { SxProps } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  FilterItemWithType,
  FilterKeyType,
  ResourceType,
} from "../../../../../services/cloudchipr.api";
import { isSmartTag } from "../utils/helpers/isSmartTag";
import { useAppSelector } from "../../../../../store/hooks";
import { allowedFiltersHierarchyByResourceTypeOptionsSelector } from "../../../../../store/filters/selectors/allowedFiltersHierarchyByResourceTypeOptionsSelector";
import { useFilterLabel } from "../utils/hooks/useFilterLabel.hook";

export const filterKeyAndTypePrefix = "-filterKeyAndTypePrefix-";

interface FilterTypeSelectProps {
  onChange(event: SelectChangeEvent): void;
  currentFilter: FilterItemWithType;
  resourceType: ResourceType;
  excludedFilters?: FilterKeyType[];
}

export const FilterTypeSelect: FC<FilterTypeSelectProps> = ({
  onChange,
  currentFilter,
  resourceType,
  excludedFilters,
}) => {
  const { getFilterKeyLabel } = useFilterLabel(currentFilter.type);
  const options = useAppSelector((state) =>
    allowedFiltersHierarchyByResourceTypeOptionsSelector(
      state,
      resourceType,
      excludedFilters,
    ),
  );

  const hasNestedResources = options?.length > 1;

  const renderValue = useCallback(
    (value: string) => {
      const [key, parentType] = value.split(filterKeyAndTypePrefix) as [
        FilterKeyType,
        ResourceType,
      ];

      const parentLabel = options?.find(
        ({ type }) => type === parentType,
      )?.label;

      const tail = hasNestedResources ? ` (${parentLabel})` : "";

      return `${getFilterKeyLabel(key)}${tail}`;
    },
    [getFilterKeyLabel, options, hasNestedResources],
  );

  return (
    <FormControl fullWidth size="xsmall" sx={{ width: 160 }}>
      <InputLabel id="filter-type">Filter</InputLabel>
      <Select
        labelId="filter-type"
        value={`${currentFilter.filter.key}${filterKeyAndTypePrefix}${currentFilter.type}`}
        name="key"
        label="Filter"
        renderValue={renderValue}
        sx={{ bgcolor: "grey.100" }}
        onChange={onChange}
        MenuProps={{
          sx: { maxHeight: 410 },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {options.map(({ label, filters, type }) => {
          const headerStyles: SxProps = {
            fontSize: 12,
            lineHeight: 1,
            textTransform: "uppercase",
            my: 0.5,
            py: 1,
          };

          if (options.at(-1)?.filters.length) {
            headerStyles.pt = 2;
            headerStyles.borderTop = 1;
            headerStyles.borderColor = "grey.200";
          }
          const components = [];
          if (filters.length && hasNestedResources) {
            components.push(
              <ListSubheader key={label} sx={headerStyles}>
                {label} FILTERS
              </ListSubheader>,
            );
          }

          filters.forEach((f) => {
            components.push(
              <MenuItem
                key={f.key}
                value={`${f.key}${filterKeyAndTypePrefix}${type}`}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ListItemText primary={f.label} />

                {isSmartTag(f.key) && (
                  <Typography
                    variant="caption"
                    ml={1}
                    lineHeight={1}
                    color="text.secondary"
                  >
                    By Cloudchipr
                  </Typography>
                )}

                {f.info && (
                  <Tooltip title={f.info} placement="top" arrow>
                    <InfoOutlinedIcon fontSize="small" color="action" />
                  </Tooltip>
                )}
              </MenuItem>,
            );
          });

          return components;
        })}
      </Select>
    </FormControl>
  );
};
