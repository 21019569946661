import { ResourceExplorerGridDataWithId } from "../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { formatBillingExplorerWidgetTableViewLastGridItem } from "../../../selectors/widgets/billing-explorer-widget/utils/helpers/formatBillingExplorerWidgetTableViewLastGridItem";

export const combineBillingExplorerWidgetGridDataWithOthers = (
  data: ResourceExplorerGridDataWithId[],
) => {
  const lastItems = data.slice(visibleItemsCount, data?.length);
  const startItems = data.slice(0, visibleItemsCount);

  const lastItem = formatBillingExplorerWidgetTableViewLastGridItem(lastItems);

  const completeData = [...startItems];

  if (lastItems.length) {
    completeData.push(lastItem);
  }

  return completeData;
};

const visibleItemsCount = 9;
