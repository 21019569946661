import { FC, Fragment, useCallback } from "react";
import { WidgetsViewsHierarchySelect } from "../../../adding-widget/widget-create/widget-setups/common/widgets-views-hierarchy-select/WidgetsViewsHierarchySelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { billingExplorerSetupPropertyByKeySelector } from "../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { billingExplorerREViewChangeThunk } from "../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerREViewChangeThunk";
import { BillingExplorerWidgetSetupGroupingSelect } from "../../../adding-widget/widget-create/widget-setups/billing-explorer/components/side-bar/BillingExplorerWidgetSetupGroupingSelect";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { BillingExplorerWidgetSetupDateRangeSelect } from "../../../adding-widget/widget-create/widget-setups/billing-explorer/components/side-bar/BillingExplorerWidgetSetupDateRangeSelect";

import { BillingExplorerWidgetViewTypeSelect } from "../../../adding-widget/widget-create/widget-setups/billing-explorer/components/side-bar/BillingExplorerWidgetViewTypeSelect";
import { WidgetFrequencySelect } from "../../../adding-widget/widget-create/widget-setups/common/toolbar/WidgetFrequencySelect";
import { billingExplorerFrequencyChangeThunk } from "../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerFrequencyChangeThunk";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";
import {
  billingExplorerWidgetVisualizationDetailsByChartType,
  billingExplorerWidgetVisualizationDetailsComponentByType,
} from "../utils/constants/constants";

export const BillingExplorerWidgetEditPopoverContent: FC = () => {
  const dispatch = useAppDispatch();
  const viewId = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("viewId"),
  );

  const chartType = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("chart_type"),
  );
  const viewType = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("chart_type"),
  );
  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );
  const frequency = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("frequency"),
  );

  const frequencyChangeHandler = useCallback(
    (e: any) => {
      dispatch(billingExplorerFrequencyChangeThunk(e.target.value));
    },
    [dispatch],
  );

  const viewChangeHandler = useCallback(
    (id: string) => {
      dispatch(billingExplorerREViewChangeThunk(id));
    },
    [dispatch],
  );

  return (
    <Fragment>
      <WidgetsViewsHierarchySelect
        selectedItemId={viewId}
        onChange={viewChangeHandler}
      />

      <BillingExplorerWidgetSetupGroupingSelect disabled={viewGettingLoading} />
      <BillingExplorerWidgetSetupDateRangeSelect />

      <BillingExplorerWidgetViewTypeSelect />

      <WidgetFrequencySelect
        frequency={frequency}
        onChange={frequencyChangeHandler}
        disabled={
          ["pie", "numeral", "table"].includes(viewType) || viewGettingLoading
        }
      />

      {billingExplorerWidgetVisualizationDetailsByChartType
        .get(chartType)
        ?.map((type) => {
          const Component =
            billingExplorerWidgetVisualizationDetailsComponentByType.get(type);

          if (!Component) {
            return;
          }

          return <Component key={type} />;
        })}
    </Fragment>
  );
};
