import { FC, useCallback } from "react";
import { Pagination, Stack, Typography } from "@mui/material";
import { setExecutionLogPagination } from "../../../../../store/execution-log/executionLogSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { executionLogDataSelector } from "../../../../../store/execution-log/selectors/executionLogDataSelector";
import { executionLogPaginationSize } from "../../utils/constants/common";
import { getExecutionLogThunk } from "../../../../../store/execution-log/thunks/getExecutionLogThunk";
import { executionLogDataLoadingSelector } from "../../../../../store/execution-log/selectors/executionLogDataLoadingSelector";

export const ExecutionLogsDataGridPagination: FC = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(executionLogDataSelector);
  const loading = useAppSelector(executionLogDataLoadingSelector);

  const totalItems = data?.total_items ?? 0;
  const totalPages = Math.ceil(
    (data?.total_items ?? 0) / executionLogPaginationSize,
  );
  const currentPage = data?.current_page ?? 0;

  const startItem =
    totalItems === 0 ? 0 : currentPage * executionLogPaginationSize + 1;
  const endItem = Math.min(
    startItem + executionLogPaginationSize - 1,
    totalItems,
  );

  const pageChangeHandler = useCallback(
    (_: any, newPage: number) => {
      dispatch(setExecutionLogPagination({ page: newPage - 1 }));
      dispatch(
        getExecutionLogThunk({
          withoutDetails: true,
        }),
      );
    },
    [dispatch],
  );

  if (!totalItems || totalItems < executionLogPaginationSize) {
    return;
  }

  return (
    <Stack
      direction="row"
      justifyContent="end"
      alignItems="center"
      spacing={2}
      py={1.5}
      px={1}
      borderTop={1}
      borderColor="grey.300"
      boxShadow={7}
    >
      <Typography variant="caption">
        {`${startItem}-${endItem} of ${totalItems}`}
      </Typography>

      <Pagination
        count={totalPages}
        page={currentPage + 1} // Add 1 for 1-based indexing
        onChange={pageChangeHandler}
        disabled={loading}
        shape="rounded"
      />
    </Stack>
  );
};
