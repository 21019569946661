import { FC } from "react";
import { useParams } from "react-router-dom";
import { KubernetesClusters } from "./kubernetes/components/KubernetesClusters";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { ProviderAccounts } from "../ProviderAccounts";

export const AccountsProviderCheckWrapper: FC = () => {
  const { provider } = useParams<{ provider: ProviderType }>();

  if (provider === "kubernetes") {
    return <KubernetesClusters />;
  }

  return <ProviderAccounts />;
};
