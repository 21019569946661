import { FC, useState } from "react";
import { useEffectOnceWhen } from "rooks";
import { useFlag } from "@unleash/proxy-client-react";
import { SlackAppConnectionEdit } from "./components/SlackAppConnectionEdit";
import { SlackAppConnectionCreate } from "./components/SlackAppConnectionCreate";
import { SlackAppConnectionType } from "./utils/types";
import { SlackConversationResponse } from "../../../../../../../../services/cloudchipr.api";
import { IntegrationType } from "../../../../../../../../store/integrations/integrationsSlice";

interface SlackFormProps {
  id: string;
  name: string;
  workspaceId?: string | null;
  type: IntegrationType;
  conversations?: SlackConversationResponse[] | null;
  defaultConversation?: SlackConversationResponse | null;
  setFieldValue(key: string, value: any): void;
}

export const SlackForm: FC<SlackFormProps> = ({
  workspaceId,
  type,
  setFieldValue,
  conversations,
  id,
  name,
  defaultConversation,
}) => {
  const enableSlackAppConnection = useFlag("EnableSlackAppConnection");

  const [slackConnectionType, setSlackConnectionType] =
    useState<SlackAppConnectionType>("app-create");

  useEffectOnceWhen(() => {
    if (!enableSlackAppConnection) {
      return;
    }

    if (!id) {
      setSlackConnectionType("app-create");
    } else if (workspaceId) {
      setSlackConnectionType("app-edit");
    }
  }, type === "slack");

  if (slackConnectionType === "app-edit") {
    return (
      <SlackAppConnectionEdit
        id={id}
        name={name}
        setFieldValue={setFieldValue}
        defaultConversation={defaultConversation}
        selectedConversations={conversations}
      />
    );
  }

  return (
    <SlackAppConnectionCreate
      setFieldValue={setFieldValue}
      setSlackConnectionType={setSlackConnectionType}
    />
  );
};
