import { FC } from "react";
import { Stack, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import type { CustomHeaderProps } from "ag-grid-react";

export const ColumnsInnerHeaderComponent: FC<CustomHeaderProps> = ({
  displayName,
  ...props
}) => {
  const tooltip = props.column.getColDef().headerTooltip;

  if (!tooltip) {
    return (
      <TypographyWithTooltip
        title={displayName}
        variant="body2"
        width="100%"
        fontWeight="medium"
      />
    );
  }

  return (
    <Stack
      position="relative"
      direction="row"
      flex={1}
      spacing={1}
      alignItems="center"
    >
      <TypographyWithTooltip
        title={displayName}
        variant="body2"
        width="100%"
        fontWeight="medium"
      />

      <Tooltip title={tooltip} placement="top" arrow>
        <InfoOutlinedIcon color="action" fontSize="small" />
      </Tooltip>
    </Stack>
  );
};
