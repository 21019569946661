import { FC, Fragment } from "react";

import { BillingExplorerVisibilityItem } from "./BillingExplorerVisibilityItem";
import { useAppSelector } from "../../../../../../../store/hooks";
import { navigationResourceExplorerPublicDataSelector } from "../../../../../../../store/navigation/selectors/resource-explorer/navigationResourceExplorerPublicDataSelector";
import { navigationResourceExplorerPrivateDataSelector } from "../../../../../../../store/navigation/selectors/resource-explorer/navigationResourceExplorerPrivateDataSelector";

export const BillingExplorerVisibility: FC = () => {
  const publicHierarchyData = useAppSelector(
    navigationResourceExplorerPublicDataSelector,
  );
  const privateHierarchyData = useAppSelector(
    navigationResourceExplorerPrivateDataSelector,
  );

  return (
    <Fragment>
      <BillingExplorerVisibilityItem
        data={publicHierarchyData}
        visibility="visible_to_everyone"
      />
      <BillingExplorerVisibilityItem
        data={privateHierarchyData}
        visibility="visible_only_to_me"
      />
    </Fragment>
  );
};
