import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Elb } from "../../../../../../../../../../../../../../services/cloudchipr.api";
import { ResourceMetricsHeaderCell } from "../../../../../cells/metrics-cells/components/ResourceMetricsHeaderCell";

export const elbColumns: ColumnSetupType<Elb>[] = [
  {
    accessorKey: "load_balancer_name",
    header: "Load Balancer",
    type: "identifier",
    size: 290,
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
  },
  {
    accessorKey: "created_at",
    header: "Creation Time",
    type: "date",
  },
  {
    id: "processed_bytes",
    accessorKey: "inline_metrics",
    size: 220,
    minSize: 165,
    maxSize: 300,
    meta: { cellStyles: { p: 0 }, headerTitle: "Traffic Sum" },
    header: ResourceMetricsHeaderCell,
    type: "trafficMetricsWithUnusedSince",
  },
  {
    accessorKey: "dns_name",
    header: "DNS Name",
    type: "copyWithTooltip",
  },
  {
    accessorKey: "targets",
    header: "Targets",
    type: "elbTargets",
  },
  { accessorKey: "type", header: "Type" },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  {
    accessorKey: "scheme",
    header: "Scheme",
    type: "scheme",
  },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },

  {
    accessorKey: "task",
    header: "Task Status",
    type: "taskStatus",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "JIRA Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
