import { WidgetType } from "../../../../../services/cloudchipr.api";

export const dashboardWidgetLabels: Partial<Record<WidgetType, string>> = {
  cost_breakdown: "Billing Explorer",
  cost_and_usage_summary: "Billing Summary",
  largest_cost_changes: "Cost Anomalies",
  commitments_utilization: "Commitments Utilization",
  text: "Text",
  coverage: "Coverage",
};
