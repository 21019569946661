import { RootState } from "../../../store";
import { KubernetesCluster } from "../../../../services/cloudchipr.api";
import { kubernetesClustersSelector } from "../../../accounts/selectors/kubernetes/kubernetesClustersSelector";
import { kubernetesLiveUsageMgmtSelectedClusterIdsSelector } from "../store-selectors/kubernetesLiveUsageMgmtSelectedClusterIdsSelector";

export const kubernetesLiveUsageMgmtSelectedClustersSelector = (
  state: RootState,
): KubernetesCluster[] | undefined => {
  const clusters = kubernetesClustersSelector(state);
  const selectedClusterIds =
    kubernetesLiveUsageMgmtSelectedClusterIdsSelector(state);

  if (!clusters || !selectedClusterIds) {
    return;
  }

  return selectedClusterIds?.reduce((result, item) => {
    const data = clusters?.find((account) => account.id === item);
    if (data) {
      result.push(data);
    }
    return result;
  }, [] as KubernetesCluster[]);
};
