import { ColDef } from "ag-grid-community";
import { ReservationsCoverageDataWithId } from "../../../../../../../../common/utils/types";

export const coveredHoursColumnDef: ColDef<ReservationsCoverageDataWithId> = {
  flex: 1.5,
  field: "covered_hours",
  minWidth: 200,
  headerName: "Covered Hours",
  valueGetter: ({ data }) => `${data?.covered_hours}h`,
};
