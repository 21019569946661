import { GridApi } from "ag-grid-community";

export const getAGGridAllSelectedRows = (gridApi: GridApi | null): any[] => {
  if (!gridApi) {
    return [];
  }

  const selectedNodes = gridApi.getSelectedNodes();
  const selectedRowsData: string[] = [];
  const stack = [...selectedNodes];

  while (stack.length > 0) {
    const node = stack.pop();
    if (!node) {
      continue;
    }

    if (node.data) {
      selectedRowsData.push(node.data);
    }

    if (node.group && node.childrenAfterGroup) {
      stack.push(...node.childrenAfterGroup);
    }
  }

  return selectedRowsData;
};
