import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { createTaskCommentFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentTasksByTaskIdComments.select(
    createTaskCommentFixedCacheKey,
  );

export const createTaskCommentLoadingSelector = (state: RootState) => {
  return !!endpoint(state)?.isLoading;
};
