import { FC, Fragment, useCallback } from "react";
import { MenuItem } from "@mui/material";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import { ViewMoveActionDialogContent } from "./ViewMoveActionDialogContent";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { useDialog } from "../../../../../../../../../utils/hooks/useDialog.hook";
import { ConfirmationDialog } from "../../../../../../../../common/modals/ConfirmationDialog";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { updateResourceExplorerVisibilityLoadingSelector } from "../../../../../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/updateResourceExplorerVisibilityHierarchyLoadingSelector";
import { HierarchyItemMoveActionItemProps } from "../../../../../../../utils/types/hierarchy";

interface ResourceExplorerViewMoveActionMenuFolderItemProps
  extends HierarchyItemMoveActionItemProps {}

export const ResourceExplorerViewMoveActionMenuFolderItem: FC<
  ResourceExplorerViewMoveActionMenuFolderItemProps
> = ({
  id,
  name,
  root,
  onClick,
  paddingLeft,
  visibility,
  currentItemName,
  differentVisibilities,
}) => {
  const loading = useAppSelector(
    updateResourceExplorerVisibilityLoadingSelector,
  );
  const { open, openDialog, closeDialog } = useDialog();

  const moveHandler = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  const clickHandler = useCallback(() => {
    if (differentVisibilities) {
      openDialog();
    } else {
      moveHandler();
    }
  }, [differentVisibilities, moveHandler, openDialog]);

  return (
    <Fragment>
      <MenuItem onClick={clickHandler} sx={{ pl: paddingLeft }}>
        {root ? (
          <BarChartOutlinedIcon fontSize="small" />
        ) : (
          <FolderOpenOutlinedIcon fontSize="small" />
        )}
        <TypographyWithTooltip
          title={name}
          variant="body2"
          placement="top"
          sx={{ pl: 1 }}
        />
      </MenuItem>

      {open && (
        <ConfirmationDialog
          open
          title="Move View?"
          dialogContent={
            <ViewMoveActionDialogContent
              currentItemName={currentItemName}
              visibility={visibility}
            />
          }
          CancelButtonProps={{
            onClick: closeDialog,
          }}
          ConfirmButtonProps={{
            children: "Move",
            onClick: moveHandler,
            loading,
          }}
          onClose={closeDialog}
        />
      )}
    </Fragment>
  );
};
