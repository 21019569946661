import { FC, useCallback } from "react";
import { Button, Link, Stack, Typography } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { useAppDispatch } from "../../../../../store/hooks";
import { setActiveIntegration } from "../../../../../store/integrations/integrationsSlice";

interface SlackConversationsDropdownFooterProps {
  integrationId: string;
  onClose?: () => void;
}

export const SlackConversationsDropdownFooter: FC<
  SlackConversationsDropdownFooterProps
> = ({ integrationId, onClose }) => {
  const enableRedesignedIntegrations = useFlag("EnableRedesignedIntegrations");
  const dispatch = useAppDispatch();

  const openEditIntegrationDrawer = useCallback(() => {
    onClose?.();
    dispatch(setActiveIntegration({ type: "slack", id: integrationId }));
  }, [dispatch, integrationId, onClose]);

  return (
    <Stack alignItems="center" p={2} spacing={1}>
      {enableRedesignedIntegrations ? (
        <Button onClick={openEditIntegrationDrawer}>
          Manage Conversations List
        </Button>
      ) : (
        <Link
          target="_blank"
          fontWeight="medium"
          href={`/integrations/edit/slack/${integrationId}`}
          underline="hover"
          textTransform="uppercase"
        >
          Manage Conversations List
        </Link>
      )}
      <Typography color="text.secondary" variant="caption">
        If you can’t find your preferred conversation, choose to manage the
        list.
      </Typography>
    </Stack>
  );
};
