import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useDidMount } from "rooks";
import { useFlag } from "@unleash/proxy-client-react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { commitmentsDataViewTabSelector } from "../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";
import { setCommitmentsDataViewTab } from "../../../../../store/commitments/commitmentsSlice";
import { ReservationsRecommendationsDataGrid } from "../../reservations/components/content/recomendations/ReservationsRecommendationsDataGrid";
import { SavingPlansRecommendationsDataGrid } from "../../utilization-and-coverage/components/content/recommendations/SavingPlansRecommendationsDataGrid";

export const CommitmentsOverviewRecommendations: FC = () => {
  const enableReservationRecommendation = useFlag(
    "EnableReservationRecommendation",
  );
  const tab = useAppSelector(commitmentsDataViewTabSelector);
  const dispatch = useAppDispatch();

  useDidMount(() => {
    dispatch(setCommitmentsDataViewTab("saving_plans"));
  });

  return (
    <Stack pt={1} height="100%">
      <Typography variant="h6" fontWeight="bold" pl={1} pb={1}>
        Recommendations
      </Typography>
      {tab === "saving_plans" && (
        <SavingPlansRecommendationsDataGrid overview />
      )}
      {enableReservationRecommendation && tab === "reservation" && (
        <ReservationsRecommendationsDataGrid overview />
      )}
    </Stack>
  );
};
