import { FC } from "react";
import { ResourcesSectionAsTarget } from "./resources/ResourcesSectionAsTarget";
import { OpportunitiesSectionAsTarget } from "./opportunities/OpportunitiesSectionAsTarget";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { taskManagementTargetTypeSelector } from "../../../../../../../../../../store/task-management/selectors/form/target/common/taskManagementTargetTypeSelector";

export const TaskCreationTargetSection: FC = () => {
  const targetType = useAppSelector(taskManagementTargetTypeSelector);

  if (targetType === "resource") {
    return <ResourcesSectionAsTarget />;
  }

  if (targetType === "opportunity") {
    return <OpportunitiesSectionAsTarget />;
  }

  return null;
};
