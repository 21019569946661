import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { ResourceTarget } from "../../../../../../../../../../../services/cloudchipr.api";
import { taskTargetStateLabels } from "../../../../../../../../../task-management/utils/constants/labels";

interface TargetResourceTableStateRowProps {
  state: ResourceTarget["state"];
}

export const TargetResourceTableStateRow: FC<
  TargetResourceTableStateRowProps
> = ({ state }) => {
  const isLive = state === "live";

  return (
    <Stack
      px={0.5}
      py={0.25}
      spacing={0.5}
      direction="row"
      borderRadius={1}
      width="fit-content"
      alignItems="center"
    >
      {isLive ? (
        <PlayCircleOutlineIcon color="success" sx={{ fontSize: 16 }} />
      ) : (
        <RemoveCircleOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
      )}

      {state && (
        <Typography
          variant="body2"
          fontWeight="medium"
          color={isLive ? "success.main" : "text.secondary"}
        >
          {taskTargetStateLabels[state]}
        </Typography>
      )}
    </Stack>
  );
};
