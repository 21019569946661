import { FC } from "react";
import { useDidMount, useWillUnmount } from "rooks";
import { Button, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import { WorkflowsDataGrid } from "./components/WorkflowsDataGrid";
import { WorkflowsEmptyState } from "./components/WorkflowsEmptyState";
import { useAppAbility } from "../../../../../services/permissions";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { workflowsExistSelector } from "../../../../../store/automations/selectros/workflow/list-data/workflowsExistSelector";
import { getWorkflowsThunk } from "../../../../../store/automations/thunks/wokrflows/getWorkflowsThunk";
import { PageHeader } from "../../../common/PageHeader";
import { resetAutomationsState } from "../../../../../store/automations/automationsSlice";
import { workflowsDataLoadingSelector } from "../../../../../store/automations/selectros/workflow/list-data/workflowsDataLoadingSelector";
import { workflowsDataUninitializedSelector } from "../../../../../store/automations/selectros/workflow/list-data/workflowsDataUninitializedSelector";
import { getUserPermissionInfoText } from "../../../utils/helpers/getUserPermissionInfoText";

export const WorkflowsPage: FC = () => {
  const { cannot } = useAppAbility();
  const dispatch = useAppDispatch();
  const canNotCreateSchedule = cannot("create", "schedule");
  const exist = useAppSelector(workflowsExistSelector);
  const loading = useAppSelector(workflowsDataLoadingSelector);
  const uninitialized = useAppSelector(workflowsDataUninitializedSelector);

  useDidMount(() => {
    dispatch(getWorkflowsThunk());
  });

  useWillUnmount(() => {
    dispatch(resetAutomationsState());
  });

  const userPermissionTooltipText = canNotCreateSchedule
    ? getUserPermissionInfoText("Create Workflows")
    : "";

  return (
    <Stack bgcolor="white" height="100%">
      <PageHeader
        wrapperSx={{ p: 2 }}
        loading={loading}
        breadcrumbs={breadcrumbs}
        actions={
          exist ? (
            <Tooltip title={userPermissionTooltipText}>
              <span>
                <Button
                  color="primary"
                  variant="contained"
                  component={RouterLink}
                  to="/automations/workflow/create"
                  disabled={canNotCreateSchedule}
                  startIcon={<AddIcon />}
                >
                  Create workflow
                </Button>
              </span>
            </Tooltip>
          ) : null
        }
      />

      {!uninitialized &&
        (!loading && !exist ? <WorkflowsEmptyState /> : <WorkflowsDataGrid />)}
    </Stack>
  );
};

const breadcrumbs = [{ title: "Workflows" }];
