import { getUsersMeOrganisationsCurrentSavingsOpportunitiesHierarchySelector } from "./getUsersMeOrganisationsCurrentSavingsOpportunitiesHierarchySelector";
import { RootState } from "../../../store";
import { View } from "../../../../services/cloudchipr.api";

export const savingsOpportunitiesViewsHierarchySelector = (
  state: RootState,
): View[] | undefined => {
  return getUsersMeOrganisationsCurrentSavingsOpportunitiesHierarchySelector(
    state,
  )?.data?.hierarchy;
};
