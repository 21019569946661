import { RootState } from "../../../store";
import { kubernetesClustersSelector } from "../../../accounts/selectors/kubernetes/kubernetesClustersSelector";
import { KubernetesCluster } from "../../../../services/cloudchipr.api";
import { ClusterOptionType } from "../../../../components/pages/kubernetes-live-usage-mgmt/components/navigation/selected-cluster/components/utils/types";
import { sortClusterOptionTypeByOrgIdCompareFn } from "../../utils/helpers/sortClusterOptionTypeByOrgIdCompareFn";
import { sortByAlphanumericForAccountOptionType } from "../../../../components/common/account-select/utils/helpers/sorting/sortByAlphanumericForAccountOptionType";

export const kubernetesLiveUsageMgmtClustersSelectOptionsSelector = (
  state: RootState,
): ClusterOptionType[] | undefined => {
  const clusters = kubernetesClustersSelector(state);

  if (!clusters) {
    return;
  }

  return generateAccountSelectionOptions(clusters ?? []);
};

const generateAccountSelectionOptions = (
  clusters: KubernetesCluster[] | null,
): ClusterOptionType[] => {
  if (!clusters) {
    return [];
  }

  const options = clusters.reduce((result, cluster) => {
    result.push({
      value: cluster.id,
      label: cluster.name ?? `ID: ${cluster.cluster_id}`,
      clusterId: cluster.cluster_id ?? "",
      accessType: "read",
      status: "connected",
      idleMonthlyCost: cluster.idle_monthly_cost,
      totalMonthlyCost: cluster.total_monthly_cost,
    });

    return result;
  }, [] as ClusterOptionType[]);

  const accountSelectionOptions = sortByAlphanumericForAccountOptionType(
    options,
  ) as ClusterOptionType[];

  return accountSelectionOptions
    .flat()
    .toSorted(sortClusterOptionTypeByOrgIdCompareFn);
};
