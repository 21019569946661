import { FC, useCallback } from "react";
import { useCurrentEditor } from "@tiptap/react";
import { MenuItem, Stack, Typography } from "@mui/material";
import { MenuItemProps } from "@mui/material/MenuItem/MenuItem";

export const ToolbarParagraphCommandMenuItem: FC<MenuItemProps> = (props) => {
  const { editor } = useCurrentEditor();

  const clickHandler = useCallback(
    (event: any) => {
      if (!editor) {
        return;
      }

      if (props.onClick) {
        props.onClick(event);
      }

      editor.chain().focus().setParagraph().run();
    },
    [editor, props],
  );

  if (!editor) {
    return null;
  }

  const isActive = editor.isActive("paragraph");

  return (
    <MenuItem {...props} value="P" onClick={clickHandler} selected={isActive}>
      <Stack
        flex={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={0.5}
      >
        <Typography textAlign="center" color="grey.600" fontWeight="bold">
          P
        </Typography>
        <Typography pl={1} variant="body2" fontWeight="nomral">
          Paragraph
        </Typography>
      </Stack>
    </MenuItem>
  );
};
