import { FC } from "react";
import { Stack, Table, TableBody, Typography } from "@mui/material";
import { TaskSingleTargetTableRow } from "../../common/TaskSingleTargetTableRow";
import { TaskTargetTableIdentifierRow } from "../../common/TaskTargetTableIdentifierRow";
import { TargetResourceTableStateRow } from "../TargetResourceTableStateRow";
import { useAppSelector } from "../../../../../../../../../../../../store/hooks";
import { taskManagementTargetResourcesSelector } from "../../../../../../../../../../../../store/task-management/selectors/form/target/resources/taskManagementTargetResourcesSelector";
import { TagCell } from "../../../../../../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { money } from "../../../../../../../../../../../../utils/numeral/money";
import { resourceIdentifierLabels } from "../../../../../../../../../../../../utils/constants/resources/resourceTypeNames/resourceIdentifierLabels";
import { getResourceTypeName } from "../../../../../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { CreatedByCell } from "../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/created-by-cell/CreatedByCell";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";
import { AccountInfoData } from "../../../../../../../../../../../common/AccountInfoData";
import { TaskTargetTableRegionRow } from "../../common/TaskTargetTableRegionRow";
import { ProviderIcon } from "../../../../../../../../../../../common/ProviderIcon";
import { getProviderName } from "../../../../../../../../../../../../utils/helpers/providers/getProviderName";

export const TargetSingleResource: FC = () => {
  const resources = useAppSelector(taskManagementTargetResourcesSelector);
  const targetResource = resources?.at(0);
  const edit = useAppSelector(isTaskManagementDrawerEditModeSelector);

  if (!targetResource) {
    return null;
  }

  return (
    <Table sx={{ tableLayout: "fixed" }}>
      <TableBody>
        <TaskSingleTargetTableRow
          highlight
          label={
            resourceIdentifierLabels.get(targetResource.resource_type) ?? "Name"
          }
        >
          <TaskTargetTableIdentifierRow
            cloudLink={edit}
            liveUsageLink={edit}
            target={targetResource}
          />
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Service">
          {getResourceTypeName(targetResource.resource_type, {
            singular: true,
            type: "long",
          })}
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Cloud Provider" highlight>
          <Stack direction="row" spacing={0.5}>
            <ProviderIcon provider={targetResource.account.provider} />
            <Typography variant="body2">
              {getProviderName(targetResource.account.provider, {
                title: true,
              })}
            </Typography>
          </Stack>
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Account">
          <AccountInfoData
            nameIdCopiable
            nameTypographyProps={{ variant: "body2" }}
            idTypographyProps={{ variant: "tiny" }}
            accountId={targetResource.account.provider_account_id}
            accountName={targetResource.account.provider_account_name}
          />
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Monthly Price" highlight>
          <Typography variant="body2">
            {money(targetResource.price_per_month)}
          </Typography>
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Region">
          <TaskTargetTableRegionRow region={targetResource.region} />
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow highlight label="Created By">
          <CreatedByCell data={targetResource.creation_data} copy={false} />
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Resource State">
          <TargetResourceTableStateRow state={targetResource.state} />
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow highlight label="Tags">
          {targetResource.tags?.length ? (
            <TagCell tags={targetResource.tags} />
          ) : (
            "-"
          )}
        </TaskSingleTargetTableRow>
      </TableBody>
    </Table>
  );
};
