import { FC, useCallback } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { DashboardFolderListItemActionsMenu } from "./DashboardFolderListItemActionsMenu";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../../services/permissions";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { DashboardCreateFolderOrItemDialog } from "../DashboardCreateFolderOrItemDialog";
import { navItemSecondaryActionButtonVisibilityStyles } from "../../../../../utils/helpers/navItemSecondaryActionButtonVisibilityStyles";

interface DashboardV2FolderActionButtonProps {
  id: string;
  name: string;
  redirectAfterCreate?: boolean;
  containsProtectedItem?: boolean;
  size?: "small" | "medium" | "large";
  visibility: NavigationItemsVisibilityType;
}

export const DashboardV2FolderActionButton: FC<
  DashboardV2FolderActionButtonProps
> = ({
  id,
  name,
  visibility,
  size,
  redirectAfterCreate,
  containsProtectedItem,
}) => {
  const { cannot } = useAppAbility();
  const cannotDeleteDashboard = cannot("delete", "dashboard");
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const {
    open: openAdd,
    openDialog: openAddDialog,
    closeDialog: closeAddDialog,
  } = useDialog();

  const closeDialog = useCallback(() => {
    closeAddDialog();
    closeMenu();
  }, [closeMenu, closeAddDialog]);

  if (cannotDeleteDashboard) {
    return null;
  }

  return (
    <Stack direction="row" spacing={0.5} justifyContent="flex-end">
      <Tooltip title="Add dashboard" arrow placement="top">
        <IconButton
          size="small"
          onClick={openAddDialog}
          sx={navItemSecondaryActionButtonVisibilityStyles(open || openAdd)}
        >
          <AddIcon fontSize={size ?? "inherit"} />
        </IconButton>
      </Tooltip>

      <IconButton
        size="small"
        onClick={openMenu}
        sx={navItemSecondaryActionButtonVisibilityStyles(open || openAdd)}
      >
        <MoreVertIcon fontSize={size ?? "inherit"} />
      </IconButton>

      <DashboardFolderListItemActionsMenu
        id={id}
        name={name}
        open={open}
        anchor={anchor}
        closeMenu={closeMenu}
        visibility={visibility}
        redirectAfterCreate={redirectAfterCreate}
        containsProtectedItem={containsProtectedItem}
      />

      {openAdd && (
        <DashboardCreateFolderOrItemDialog
          onClose={closeDialog}
          type="item"
          folderId={id}
          visibility={visibility}
          redirectAfterCreate={redirectAfterCreate}
        />
      )}
    </Stack>
  );
};
