import { azureReservationsDataForGranularSelector } from "../azureReservationsDataForGranularSelector";
import { RootState } from "../../../../../../store";

export const azureReservationsRealCostSelector = (
  state: RootState,
): number | null => {
  const data = azureReservationsDataForGranularSelector(state);

  return data?.totals?.real_cost ?? null;
};
