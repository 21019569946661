import { Chart } from "highcharts";

export const chartSeriesHighlightFunctions = {
  onHoverIn: (chartApi: Chart | null, key: string) => {
    if (!chartApi) {
      return;
    }

    try {
      chartApi.update(
        {
          series: chartApi.series.map((series) => {
            if (series.name !== key) {
              return { ...series.options, opacity: 0.4 };
            }
            return series.options;
          }),
        },
        false,
      );
      chartApi.redraw();
    } catch (e) {
      console.error(
        `chartSeriesHighlightFunctions.onHoverIn error message: ${e}`,
      );
    }
  },
  onHoverOut: (chartApi: Chart | null) => {
    if (!chartApi) {
      return;
    }

    try {
      chartApi.update(
        {
          series: chartApi.series.map((series) => ({
            ...series.options,
            opacity: 1,
          })),
        },
        false,
      );
      chartApi.redraw();
    } catch (e) {
      console.error(
        `chartSeriesHighlightFunctions.onHoverOut error message: ${e}`,
      );
    }
  },
};
