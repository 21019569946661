import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResourceExplorerGroupingNullable } from "../../../../services/cloudchipr.api";
import {
  setResourceExplorerGrouping,
  setResourceExplorerSearch,
} from "../../resourceExplorerSlice";
import { setResourceExplorerPathThunk } from "../widgets/path/setResourceExplorerPathThunk";
import { getResourceExplorerDataThunk } from "../widgets/getResourceExplorerDataThunk";
import { pathLastItemGroupingChangeThunk } from "../widgets/path/pathLastItemGroupingChangeThunk";
import { RootState } from "../../../store";
import { resourceExplorerPathSelector } from "../../selectors/current-resource-explorer/path/resourceExplorerPathSelector";

export const resourceExplorerGroupByChangeThunk = createAsyncThunk(
  "resourceExplorer/resourceExplorerGroupByChange",
  async (
    {
      grouping,
      groupValues,
      enableBillingExplorerPathChangeFromMainGrouping,
    }: {
      grouping: ResourceExplorerGroupingNullable;
      groupValues?: string[];
      enableBillingExplorerPathChangeFromMainGrouping: boolean;
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const path = resourceExplorerPathSelector(state);

    dispatch(setResourceExplorerGrouping({ grouping, groupValues }));
    dispatch(setResourceExplorerSearch(""));

    if (path.length > 1 && enableBillingExplorerPathChangeFromMainGrouping) {
      dispatch(
        pathLastItemGroupingChangeThunk({
          grouping,
          enableBillingExplorerPathChangeFromMainGrouping: true,
        }),
      );
    } else {
      dispatch(setResourceExplorerPathThunk(false));
      dispatch(getResourceExplorerDataThunk());
    }
  },
);
