import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceDetailsCostsTabBillingDataSelector } from "./resourceDetailsCostsTabBillingDataSelector";
import { organisationQuarterStartMonthSelector } from "../../../../common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { billingExplorerChartDataLabelsGenerator } from "../../../../../components/pages/common/billing-explorer/utils/billingExplorerChartDataLabelsGenerator";
import { resourceDetailsCostsDataPointSelector } from "../../options/resourceDetailsCostsDataPointSelector";

export const resourceDetailsCostsTabBillingChartLabelsSelector =
  createDraftSafeSelector(
    [
      resourceDetailsCostsTabBillingDataSelector,
      resourceDetailsCostsDataPointSelector,
      organisationQuarterStartMonthSelector,
    ],
    (data, dataPoint, quarterStartMonth): Record<string, string> | null => {
      const chartData = data?.data;

      if (!chartData) {
        return null;
      }

      return billingExplorerChartDataLabelsGenerator(
        chartData,
        dataPoint,
        quarterStartMonth,
      );
    },
  );
