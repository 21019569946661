import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { capitalize } from "@mui/material";
import { ReservationsCoverageDataWithId } from "../../../../../../../common/utils/types";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import { commitmentsResourceTypeNames } from "../../../../../../../common/utils/constants/labels";
import { getCoveredUsageColumnDef } from "../coverage/common/getCoveredUsageColumnDef";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { coveredHoursColumnDef } from "../coverage/common/coveredHoursColumnDef";
import { netSavingsColumnDef } from "../coverage/common/netSavingsColumnDef";
import { getCoverageColumnDef } from "../coverage/common/getCoverageColumnDef";

export const reservationGroupedByResourceTypeColumnDefs: ColDef<ReservationsCoverageDataWithId>[] =
  [
    {
      flex: 1.5,
      field: "resource_type.resource_type",
      minWidth: 200,
      headerName: "Resource Type",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => {
        const rt = data?.resource_type?.resource_type;

        if (!rt) {
          return;
        }

        return (
          <TypographyWithTooltip
            title={capitalize(commitmentsResourceTypeNames[rt] ?? "")}
            variant="body2"
          />
        );
      },
      valueGetter: ({ data }) =>
        data?.resource_type?.resource_type
          ? commitmentsResourceTypeNames[data.resource_type.resource_type]
          : "",
    },
    {
      flex: 1.5,
      field: "resource_type.instance_type",
      minWidth: 200,
      headerName: "Instance Type",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
        <TypographyWithTooltip
          variant="inherit"
          title={data?.resource_type?.instance_type ?? "-"}
        />
      ),
    },
    {
      flex: 1.5,
      field: "resource_type.platform",
      minWidth: 200,
      headerName: "Platform",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
        <TypographyWithTooltip
          variant="inherit"
          title={data?.resource_type?.platform ?? "-"}
        />
      ),
    },
    {
      flex: 1.5,
      field: "resource_type.region",
      headerName: "Region/AZ",
    },

    getCoveredUsageColumnDef("resource_type"),

    coveredHoursColumnDef,

    netSavingsColumnDef,

    getCoverageColumnDef("resource_type"),

    agGridEmptyColumnToFitEmptySpace,
  ];
