import { FC, memo, useMemo } from "react";
import { FormikHandlers } from "formik";
import { FilterWithOperatorAndAutocomplete } from "./filter-by-type/FilterWithOperatorAndAutocomplete";
import { AutocompleteFilterBodyProps } from "../../filters-selection/filter-by-type/autocomplete-filters/AutocompleteFilterBody";
import {
  FilterItem,
  Operator,
  ResourceExplorerDynamicFilterItemType,
  ResourceType,
  useGetUsersMeOrganisationsCurrentKubernetesByResourceTypeFiltersAndFilterTypeValuesQuery,
} from "../../../../../../services/cloudchipr.api";

type InstanceTypeFilterResourceType = "ec2" | "vm" | "az_vm";

interface KubernetesCommonFiltersProps extends AutocompleteFilterBodyProps {
  accountIds?: string[];
  resourceType: ResourceType;
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
}

export const KubernetesCommonFilters: FC<KubernetesCommonFiltersProps> = memo(
  ({ resourceType, filter, ...props }) => {
    const { data, isLoading } =
      useGetUsersMeOrganisationsCurrentKubernetesByResourceTypeFiltersAndFilterTypeValuesQuery(
        {
          resourceType: resourceType as InstanceTypeFilterResourceType,
          filterType: filter.key as ResourceExplorerDynamicFilterItemType,
        },
      );

    const renderOptions = useMemo(() => {
      return data?.map((item) => {
        return {
          value: item.value,
          label: item.title ?? item.value,
        };
      });
    }, [data]);

    return (
      <FilterWithOperatorAndAutocomplete
        options={renderOptions ?? []}
        loading={isLoading}
        resourceType={resourceType}
        filter={filter}
        {...props}
      />
    );
  },
);
