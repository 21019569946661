import { FC, Fragment, useCallback } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { enqueueSnackbar } from "notistack";
import { ICellRendererParams } from "ag-grid-community";
import { useMenuHook } from "../../../../../../../../utils/hooks/useMenu.hook";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import { useAppAbility } from "../../../../../../../../services/permissions";
import {
  Budget,
  useDeleteUsersMeOrganisationsCurrentBudgetsByBudgetIdMutation,
  useGetUsersMeOrganisationsCurrentBudgetsQuery,
} from "../../../../../../../../services/cloudchipr.api";
import { DeleteConfirmationDialog } from "../../../../../../../common/modals/DeleteConfirmationDialog";
import { BudgetsDataGridEditAction } from "../../../common/actions/BudgetsDataGridEditAction";

export const BudgetListActionsCell: FC<ICellRendererParams<Budget>> = ({
  data: budget,
}) => {
  const id = budget?.id;
  const name = budget?.name;

  const { can } = useAppAbility();
  const canDeleteBudget = can("delete", "budget");

  const { anchor, open: menuOpen, openMenu, closeMenu } = useMenuHook();
  const { open: confirmDialogOpen, openDialog, closeDialog } = useDialog();

  const [deleteTrigger, { isLoading: isDeleting }] =
    useDeleteUsersMeOrganisationsCurrentBudgetsByBudgetIdMutation({});
  const { refetch, isLoading } =
    useGetUsersMeOrganisationsCurrentBudgetsQuery();

  const closeConfirmDialogHandler = useCallback(() => {
    closeDialog();
    closeMenu();
  }, [closeMenu, closeDialog]);

  const deleteBudgetHandler = useCallback(async () => {
    if (!id) {
      return;
    }
    await deleteTrigger({ "budget-id": id });
    await refetch();

    enqueueSnackbar(`${name} Budget deleted!`, {
      variant: "snackbarAlert",
      AlertSnackBarProps: { severity: "success" },
    });
    closeConfirmDialogHandler();
  }, [deleteTrigger, id, name, refetch, closeConfirmDialogHandler]);

  if (!id || !name) {
    return;
  }

  return (
    <Fragment>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchor} open={menuOpen} onClose={closeMenu}>
        <BudgetsDataGridEditAction budgetId={id} closeMenu={closeMenu} />

        <MenuItem dense onClick={openDialog} disabled={!canDeleteBudget}>
          <ListItemIcon>
            <DeleteOutlineIcon color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText
            color="error"
            primaryTypographyProps={{ color: "error" }}
          >
            Delete
          </ListItemText>
        </MenuItem>
      </Menu>

      <DeleteConfirmationDialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialogHandler}
        onDelete={deleteBudgetHandler}
        loading={isLoading || isDeleting}
        title="Delete Budget?"
        content={
          <Typography>
            Please confirm if you want to delete
            <Typography component="span" fontWeight="bold">
              {" "}
              {name}{" "}
            </Typography>
            Budget.
          </Typography>
        }
      />
    </Fragment>
  );
};
