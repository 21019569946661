import {
  ColDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
} from "ag-grid-community";
import { capitalize, Typography } from "@mui/material";
import { budgetsThresholdSortingFn } from "./sorting-functions/budgetsThresholdSortingFn";
import { budgetsCreatedBySortingFn } from "./sorting-functions/budgetsCreatedBySortingFn";
import { budgetsLastModifiedBySortingFn } from "./sorting-functions/budgetsLastModifiedBySortingFn";
import { Budget } from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";
import { BudgetsPeriodCell } from "../../components/cells/BudgetsPeriodCell";
import { BudgetsProgressCell } from "../../components/cells/BudgetsProgressCell";
import { BudgetListActionsCell } from "../../components/cells/BudgetListActionsCell";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { BudgetsNameCell } from "../../components/cells/BudgetsNameCell";
import { BudgetsViewCell } from "../../components/cells/BudgetsViewCell";
import { BudgetsCreatedByCell } from "../../components/cells/BudgetsCreatedByCell";
import { BudgetsLastModifiedByCell } from "../../components/cells/BudgetsLastModifiedByCell";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { budgetAndAlertsCreatedByValueGetter } from "../../../common/utils/helpers/valueGetters/budgetAndAlertsCreatedByValueGetter";
import { budgetAndAlertsModifiedByValueGetter } from "../../../common/utils/helpers/valueGetters/budgetAndAlertsModifiedByValueGetter";
import { budgetAndAlertsModifiedByFilterValueGetter } from "../../../common/utils/helpers/filterValueGetters/budgetAndAlertsModifiedByFilterValueGetter";
import { budgetAndAlertsCreatedByFilterValueGetter } from "../../../common/utils/helpers/filterValueGetters/budgetAndAlertsCreatedByFilterValueGetter";

export const budgetsGridColumns: ColDef<Budget>[] = [
  {
    minWidth: 200,
    flex: 1,
    field: "name",
    headerName: "Name",
    pinned: "left",
    suppressColumnsToolPanel: true,
    cellRenderer: BudgetsNameCell,
  },
  {
    minWidth: 200,
    flex: 1,
    field: "amount",
    headerName: "Budget",
    cellRenderer: ({ data: budget }: ICellRendererParams<Budget>) => {
      return (
        <Typography variant="body2" fontWeight="medium">
          {money(budget?.amount)}
        </Typography>
      );
    },
  },
  {
    minWidth: 250,
    flex: 1,
    field: "view.name",
    headerName: "Billing Report",
    cellRenderer: BudgetsViewCell,
  },
  {
    minWidth: 200,
    flex: 1,
    field: "thresholds",
    headerName: "Threshold",
    valueGetter: ({ data }) =>
      data?.thresholds.map(({ percent }) => `${percent}%`).join(", "),
    comparator: budgetsThresholdSortingFn,
    cellRenderer: ({ data: budget }: ICellRendererParams<Budget>) => {
      const title =
        budget?.thresholds.map(({ percent }) => `${percent}%`).join(", ") ?? "";
      return <TypographyWithTooltip title={title} variant="body2" />;
    },
  },
  {
    minWidth: 200,
    flex: 1,
    field: "period",
    headerName: "Period",
    valueGetter: ({ data }) => capitalize(data?.period ?? ""),
    cellRenderer: BudgetsPeriodCell,
  },
  {
    minWidth: 200,
    flex: 1,
    field: "progress",
    headerName: "Progress",
    getQuickFilterText: (params: GetQuickFilterTextParams<Budget>) =>
      `${params.data?.progress}%`,
    cellRenderer: BudgetsProgressCell,
  },
  {
    field: "created_by",
    headerName: "Created By",
    minWidth: 220,
    flex: 1,
    filterValueGetter: budgetAndAlertsCreatedByFilterValueGetter,
    valueGetter: budgetAndAlertsCreatedByValueGetter,
    comparator: budgetsCreatedBySortingFn,
    cellRenderer: BudgetsCreatedByCell,
  },
  {
    field: "modified_by",
    headerName: "Last Modified By",
    minWidth: 220,
    flex: 1,
    filterValueGetter: budgetAndAlertsModifiedByFilterValueGetter,
    valueGetter: budgetAndAlertsModifiedByValueGetter,
    comparator: budgetsLastModifiedBySortingFn,
    cellRenderer: BudgetsLastModifiedByCell,
  },
  agGridEmptyColumnToFitEmptySpace,
  {
    width: 70,
    minWidth: 70,
    colId: "actions",
    pinned: "right",
    sortable: false,
    resizable: false,
    unSortIcon: true,
    cellDataType: false,
    lockPinned: true,
    lockVisible: true,
    suppressMovable: true,
    suppressHeaderContextMenu: true,
    suppressHeaderMenuButton: true,
    suppressColumnsToolPanel: true,
    filter: false,

    cellRenderer: BudgetListActionsCell,
  },
];
