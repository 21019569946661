import { FC, useMemo } from "react";
import { useDidMount, useToggle } from "rooks";
import { grey } from "@mui/material/colors";
import { Card, Collapse, Theme } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { LiveFilteredResourceCardHeader } from "./LiveFilteredResourceCardHeader";
import { LiveFilteredResourceCardToolbar } from "./LiveFilteredResourceCardToolbar";
import { getLiveFilteredResourcesColumns } from "../utils/getLiveFilteredResourcesColumns";
import { ResourceType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../store/hooks";
import { ColumnSetupType } from "../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { DataGridProvider } from "../../../../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { DataGrid } from "../../../../../../../../../../../../../storybook/data-grid/DataGrid";
import { getLiveFilteredResourcesThunk } from "../../../../../../../../../../../../../store/resource-explorer/thunks/live-filtered-resources/getLiveFilteredResourcesThunk";
import { liveFilteredResourcesSelector } from "../../../../../../../../../../../../../store/resource-explorer/selectors/live-filtered-resources/liveFilteredResourcesSelector";
import { ToolbarConfig } from "../../../../../../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { getFirstParentInHierarchyResourceType } from "../../../../../../../../../../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { generateColumns } from "../../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/resourcesColumnsGenerator";
import { resourcesDataGridSubRow } from "../../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/subrow/ResourcesSubRowNF";
import { expandableResourceTypes } from "../../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/constants";
import { nfVisibilitiesConverter } from "../../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/nf-tmp-helpers";
import { useColumnsVisibility } from "../../../../../../../../../../../live-usage-mgmt/utils/hooks/useColumnsVisibility.hook";
import { ResourceDataType } from "../../../../../../../../../../../../../store/live-usage-mgmt/utils/types/types";
import { liveFilteredResourcesEmsPriceSelector } from "../../../../../../../../../../../../../store/resource-explorer/selectors/live-filtered-resources/liveFilteredResourcesEmsPriceSelector";
import { liveUsageMgmtAccountIdsSelector } from "../../../../../../../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveFilteredResourcesLoadingSelector } from "../../../../../../../../../../../../../store/resource-explorer/selectors/live-filtered-resources/liveFilteredResourcesLoadingSelector";

interface LiveFilteredResourceCardProps {
  resourceType: ResourceType;
  accountIds: string[];
  resourceIds: string[];
  dataCount: number;
  expanded?: boolean;
}
export const LiveFilteredResourceCard: FC<LiveFilteredResourceCardProps> = ({
  resourceIds,
  accountIds,
  resourceType,
  dataCount,
  expanded,
}) => {
  const dispatch = useAppDispatch();
  const enableMetricsCharts = useFlag("EnableResourceSmallCharts");
  const selectedAccountIds =
    useAppSelector(liveUsageMgmtAccountIdsSelector) || [];

  const { visibility, setVisibility } = useColumnsVisibility(
    resourceType,
    selectedAccountIds,
  );

  const [collapsed, toggleCollapsed] = useToggle(!expanded);

  const parentResourceType =
    getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;

  const data = useAppSelector((state) =>
    liveFilteredResourcesSelector(state, parentResourceType),
  );
  const loading = useAppSelector((state) =>
    liveFilteredResourcesLoadingSelector(state, parentResourceType),
  );
  const totalMonthlyPrice = useAppSelector((state) =>
    liveFilteredResourcesEmsPriceSelector(state, resourceType),
  );

  const memoizedVisibility = useMemo(
    () => nfVisibilitiesConverter(visibility),
    [visibility],
  );

  const columnsSetup = useMemo(() => {
    const config = {
      includeExpander: expandableResourceTypes.has(parentResourceType),
      alwaysShowSortIcon: false,
    };
    const columns = getLiveFilteredResourcesColumns(parentResourceType, {
      includeFilterTemplate: true,
      enableMetricsCharts,
    });

    return generateColumns(
      columns as ColumnSetupType<ResourceDataType>[],
      config,
    );
  }, [parentResourceType, enableMetricsCharts]);

  const toolbar = useMemo((): ToolbarConfig => {
    return {
      renderToolbar: (props) => {
        return (
          <LiveFilteredResourceCardToolbar
            resourceType={parentResourceType}
            {...props}
          />
        );
      },
    };
  }, [parentResourceType]);

  useDidMount(() => {
    dispatch(
      getLiveFilteredResourcesThunk({
        accountIds,
        resourceType,
        resourceIds,
      }),
    );
  });

  const expandable = expandableResourceTypes.has(resourceType);

  return (
    <Card variant="outlined" sx={{ borderColor: "primary.light" }}>
      <LiveFilteredResourceCardHeader
        resourceType={resourceType}
        dataCount={data?.length ?? 0}
        totalMonthlyPrice={totalMonthlyPrice}
        collapsed={collapsed}
        onCollapse={toggleCollapsed}
        loading={loading}
      />

      <Collapse in={!collapsed && !loading}>
        <DataGridProvider>
          <DataGrid
            data={data ?? []}
            columns={columnsSetup}
            columnResizeMode="onEnd"
            columnSorting={sortingConfig}
            enableRowsVirtualization={!expandable}
            pagination={expandable ? { pageSize: 30 } : undefined}
            enableStickyColumns={!!data?.length}
            rowExpanding={{
              renderExpandedRowSubRow: resourcesDataGridSubRow,
              parentRowStyles: { bgcolor: grey[100], "& > td": { pl: 4 } },
            }}
            columnVisibility={{
              initialVisibility: memoizedVisibility,
              onColumnsVisibilityChange: setVisibility,
            }}
            toolbar={toolbar}
            styles={{
              ...styles,
              tableContainer: {
                maxHeight: dataCount === 1 ? "80dvh" : 612,
              },
            }}
          />
        </DataGridProvider>
      </Collapse>
    </Card>
  );
};

const sortingConfig = {
  initialSort: [{ id: "resource.price_per_month", desc: true }],
};

const styles = {
  tableHeaderRow: {
    top: 0,
    position: "sticky",
    bgcolor: "grey.200",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
  },
  tableRow: {
    background: "white",
  },
};
