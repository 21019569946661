import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { ResourceExplorerGridDataWithId } from "../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";
import { WidgetNumeralView } from "../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { WidgetNumeralViewItem } from "../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralViewItem";
import { WidgetTotalsSummary } from "../../../../pages/dashboard/components/widgets/common/WidgetTotalsSummary";
import { WidgetCosts } from "../../../../../store/dashboards/utils/types/types";
import { getBillingExplorerWidgetNumeralItemSecondaryLabel } from "../../../../pages/dashboard/components/widgets/billing-explorer-widget/components/chart/numeral/utils/helpers/getBillingExplorerWidgetNumeralItemSecondaryLabel";
import {
  DateLabelNullable,
  ForecastOption,
} from "../../../../../services/cloudchipr-authless.api";
import {
  DateDataPointNullable,
  ResourceExplorerGroupingNullable,
  WidgetCostBreakdownAggregation,
  WidgetCostBreakdownVisualization,
} from "../../../../../services/cloudchipr.api";

interface UnauthorizedBillingExplorerWidgetNumeralViewProps {
  data?: ResourceExplorerGridDataWithId[];
  forecastOption?: ForecastOption;
  loading: boolean;
  grouping: ResourceExplorerGroupingNullable;
  visualization: WidgetCostBreakdownVisualization;
  widgetCosts?: WidgetCosts;
  dateLabel?: DateLabelNullable;
  dateDataPoint: DateDataPointNullable;
  aggregation: WidgetCostBreakdownAggregation;
}
export const UnauthorizedBillingExplorerWidgetNumeralView: FC<
  UnauthorizedBillingExplorerWidgetNumeralViewProps
> = ({
  data,
  forecastOption,
  grouping,
  loading,
  visualization,
  widgetCosts,
  dateDataPoint,
  dateLabel,
  aggregation,
}) => {
  if (!forecastOption) {
    return null;
  }

  return (
    <Fragment>
      <Box px={2} pt={2}>
        {dateDataPoint && forecastOption && widgetCosts && (
          <WidgetTotalsSummary
            frequency={dateDataPoint}
            costsData={widgetCosts}
            forecastOption={forecastOption}
            dateLabel={dateLabel}
            showTrends={visualization.trend}
            aggregations={aggregation}
          />
        )}
      </Box>

      <WidgetNumeralView
        loading={loading}
        skeletonCount={data?.length}
        width={itemWidth}
      >
        {data?.map((item) => {
          const providers = item?.multiProviders ?? [item.cloud_provider];

          const secondaryLabel =
            getBillingExplorerWidgetNumeralItemSecondaryLabel({
              monthlyCost: item?.monthly_forecast?.cost,
              quarterlyCost: item?.quarterly_forecast?.cost,
              grouping,
              forecastOption,
            });

          return (
            <WidgetNumeralViewItem
              key={item.cloud_provider + item.field}
              providers={item.cloud_provider ? providers : []}
              label={item.field}
              cost={item.total_cost}
              trend={visualization?.trend ? item.trend : null}
              itemsCount={data.length}
              secondaryLabel={
                visualization?.forecasted_cost ? secondaryLabel : undefined
              }
              width={itemWidth}
            />
          );
        })}
      </WidgetNumeralView>
    </Fragment>
  );
};

const itemWidth = "20%";
