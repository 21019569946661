import { FC, Fragment, SyntheticEvent, useCallback } from "react";
import Button from "@mui/material/Button";
import {
  Box,
  DialogActions,
  DialogContent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AccountSwitchModeGcpCLI } from "./AccountSwitchModeGcpCLI";
import { AccountSwitchModeGcpConsole } from "./AccountSwitchModeGcpConsole";
import {
  ProviderAccessType,
  usePatchUsersMeProvidersByProviderAccountsAndAccountIdAccessModeMutation,
} from "../../../../../../services/cloudchipr.api";
import { accessTypeNames } from "../../../../../common/chips/account-access-type-chip/utils/constants/accessTypeNames";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { gcpAccountProviderDetailsByAccountIdSelector } from "../../../../../../store/accounts/selectors/gcp/gcpAccountProviderDetailsByAccountIdSelector";
import { getAccountsThunk } from "../../../../../../store/accounts/thunks/getAccountsThunk";
import {
  AccessToAccountTypeEnum,
  setAccessToAccountType,
} from "../../../../../../store/account-setup/accountSetupSlice";
import { accessToAccountTypeSelector } from "../../../../../../store/account-setup/selectors/accessToAccountTypeSelector";

interface AccountSwitchModelGcpDialogProps {
  providerAccessType: ProviderAccessType;
  onClose(): void;
  accountId: string;
}

export const AccountSwitchModeGcpDialog: FC<
  AccountSwitchModelGcpDialogProps
> = ({ accountId, onClose, providerAccessType }) => {
  const providerDetails = useAppSelector((state) => {
    return gcpAccountProviderDetailsByAccountIdSelector(state, accountId);
  });
  const oppositeAccessTypeTitle =
    "Switch to " + accessTypeNames[providerAccessType].lowercase;

  const dispatch = useAppDispatch();
  const type = useAppSelector(accessToAccountTypeSelector);

  const [trigger, { isLoading }] =
    usePatchUsersMeProvidersByProviderAccountsAndAccountIdAccessModeMutation();

  const handleSwitchToClick = useCallback(async () => {
    await trigger({
      accountId,
      provider: "gcp",
      body: {
        access_type: providerAccessType,
      },
    });
    onClose();

    dispatch(getAccountsThunk());
  }, [accountId, providerAccessType, trigger, dispatch, onClose]);

  const handleTabChange = useCallback(
    (_: SyntheticEvent, value: AccessToAccountTypeEnum) => {
      dispatch(setAccessToAccountType(value));
    },
    [dispatch],
  );

  return (
    <Fragment>
      <DialogTitleClosable
        title={oppositeAccessTypeTitle}
        onClose={onClose}
        TitleTypographyProps={{
          variant: "subtitle1",
          fontWeight: "bold",
        }}
      />

      <DialogContent dividers>
        <Typography variant="body1" fontWeight="medium" pb={1}>
          Choose a preferred project access switching method
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }} my={2}>
          <Tabs variant="fullWidth" onChange={handleTabChange} value={type}>
            <Tab label="Run through GCP Console" value="console_link" />
            <Tab label="Run through CLI" value="cli_command" />
          </Tabs>
        </Box>

        {type === AccessToAccountTypeEnum.CLI_COMMAND && (
          <AccountSwitchModeGcpCLI
            accountId={accountId}
            providerAccessType={providerAccessType}
          />
        )}
        {type === AccessToAccountTypeEnum.CONSOLE_LINK && (
          <AccountSwitchModeGcpConsole
            serviceAccount={providerDetails?.service_account || ""}
            providerAccessType={providerAccessType}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ px: 4, py: 2 }}>
        <Button
          variant="text"
          color="tertiary"
          onClick={onClose}
          sx={{ textTransform: "none" }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          color="primary"
          loading={isLoading}
          onClick={handleSwitchToClick}
          sx={{ textTransform: "none" }}
        >
          Done
        </LoadingButton>
      </DialogActions>
    </Fragment>
  );
};
