import { FC, useCallback } from "react";
import { BillingSummaryWidgetFilterType } from "../../../../../../../../../store/dashboards/utils/constants/billingSummarySetupDefaultData";
import { SwitchWithTypography } from "../../../../../../../../SwitchWithTypography";
import { billingSummaryTitlesByFilter } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-summary/utils/constants/billingSummaryTitlesByFilter";
import { LiveFilteredTitle } from "../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/live-usage/LiveFilteredTitle";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { useAppDispatch } from "../../../../../../../../../store/hooks";
import { billingSummaryFilterToggleThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/billing-summary/widget-change/billingSummaryFilterToggleThunk";

interface BillingSummaryVisualizationSwitchProps {
  checked: boolean;
  filter: BillingSummaryWidgetFilterType;
}

export const BillingSummaryVisualizationSwitch: FC<
  BillingSummaryVisualizationSwitchProps
> = ({ filter, checked }) => {
  const dispatch = useAppDispatch();

  const changeHandler = useCallback(
    (checked: boolean) => {
      dispatch(billingSummaryFilterToggleThunk({ key: filter, checked }));
    },
    [filter, dispatch],
  );

  return (
    <SwitchWithTypography
      checked={checked}
      onChange={changeHandler}
      title={
        filter === "live_usage_total_cost" ? (
          <LiveFilteredTitle
            tooltipTitle="Total monthly price of underutilized resources found in Live Resources for the current report."
            large
          />
        ) : (
          <TypographyWithTooltip
            title={billingSummaryTitlesByFilter.get(filter) ?? ""}
            variant="body2"
            maxWidth={300}
          />
        )
      }
    />
  );
};
