import { FC } from "react";
import { CardHeader, IconButton, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LiveUsageMgmtResourceCardHeaderTitle } from "./LiveUsageMgmtResourceCardHeaderTitle";
import { LiveUsageMgmtResourceCardHeaderFullScreenButton } from "./LiveUsageMgmtResourceCardHeaderFullScreenButton";
import { LiveUsageMgmtResourceCardHeaderStatistics } from "./LiveUsageMgmtResourceCardHeaderStatistics";
import { ResourceType } from "../../../../../../services/cloudchipr.api";

interface ResourceCardHeaderProps {
  resourceType: ResourceType;
  collapsed: boolean;
  onCollapse(): void;
  onFullScreen(): void;
}

export const LiveUsageMgmtResourceCardHeader: FC<ResourceCardHeaderProps> = ({
  resourceType,
  onCollapse,
  onFullScreen,
  collapsed,
}) => {
  return (
    <CardHeader
      title={
        <Stack direction="row" alignItems="center" spacing={1}>
          <LiveUsageMgmtResourceCardHeaderTitle resourceType={resourceType} />
          {collapsed && (
            <LiveUsageMgmtResourceCardHeaderStatistics
              resourceType={resourceType}
            />
          )}
        </Stack>
      }
      sx={cardHeaderSx}
      action={
        !collapsed && (
          <LiveUsageMgmtResourceCardHeaderFullScreenButton
            enter
            onClick={onFullScreen}
          />
        )
      }
      avatar={
        <IconButton
          onClick={onCollapse}
          color="primary"
          size="small"
          sx={{
            transform: collapsed ? "rotate(-90deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      }
    />
  );
};

const cardHeaderSx = {
  p: 1,
  pr: 1.5,
  bgcolor: "primary.light",
  ".MuiCardHeader-action": {
    minWidth: 34,
    alignSelf: "center",
  },
  ".MuiCardHeader-avatar": { m: 0, mr: 0.5 },
};
