import {
  FC,
  KeyboardEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  EditorEvents,
  EditorProvider,
  EditorProviderProps,
  Extension,
} from "@tiptap/react";
import { Box } from "@mui/material";
import Placeholder from "@tiptap/extension-placeholder";
import { EditorToolbar } from "./components/toolbar/EditorToolbar";
import { CommandsArray, EditorSuggestion } from "./utils/types/types";
import { getMentionExtension } from "./utils/helpers/extensions/getMentionExtension";
import { editorDefaultStyles } from "./utils/constants/styles";
import { ContentUpdater } from "./components/ContentUpdater";
import { getDefaultExtensions } from "./utils/helpers/extensions/getDefaultExtensions";

interface EditorProps extends EditorProviderProps {
  commands?: CommandsArray;
  minHeight?: number;
  maxHeight?: number;
  border?: boolean;
  placeholder?: string;
  suggestions?: EditorSuggestion[];
  onChange?(html: string): void;
  disableFocusBorder?: boolean;
}

export const Editor: FC<EditorProps> = ({
  commands,
  content = "",
  onChange,
  suggestions,
  placeholder,
  onUpdate,
  editable = true,
  border = true,
  minHeight = 300,
  maxHeight,
  disableFocusBorder = true,
  ...props
}) => {
  const [focused, setFocused] = useState(false);

  const editorStyles = useMemo(() => {
    const borderWidth = editable && border ? 1 : "none";

    return {
      ...editorDefaultStyles,
      minHeight,
      borderColor: focused && !disableFocusBorder ? "primary.main" : "grey.300",
      border: focused && !disableFocusBorder ? 2 : borderWidth,
      ".tiptap": {
        p: editable ? 1 : 0,
        minHeight: minHeight - 10,
        maxHeight,
      },
    };
  }, [border, editable, minHeight, maxHeight, focused, disableFocusBorder]);

  const extensions = useMemo(() => {
    const extensions = [...getDefaultExtensions()];

    if (suggestions) {
      extensions.push(getMentionExtension(suggestions));
    }

    if (placeholder) {
      extensions.push(Placeholder.configure({ placeholder }) as Extension);
    }

    return extensions;
  }, [suggestions, placeholder]);

  const focusHandler = () => setFocused(true);
  const blurHandler = () => setFocused(false);

  const updateHandler = useCallback(
    (props: EditorEvents["update"]) => {
      if (onChange) {
        onChange(props.editor.getHTML());
      } else if (onUpdate) {
        onUpdate(props);
      }
    },
    [onChange, onUpdate],
  );

  return (
    <Box sx={editorStyles} onKeyDown={onKeyDown}>
      <EditorProvider
        autofocus={false}
        content={content}
        onUpdate={updateHandler}
        extensions={extensions}
        slotBefore={
          editable ? <EditorToolbar commands={commands} /> : undefined
        }
        onFocus={focusHandler}
        onBlur={blurHandler}
        editable={editable}
        {...props}
      >
        <ContentUpdater content={content} />
      </EditorProvider>
    </Box>
  );
};

const onKeyDown: KeyboardEventHandler = (event) => {
  if (event.key === "Enter") {
    event.stopPropagation();
  }
};
