import { taskManagementSliceSelector } from "./taskManagementSliceSelector";
import { RootState } from "../../store";

export const taskCurrentViewConfigShowCompletedSelector = (
  state: RootState,
) => {
  const slice = taskManagementSliceSelector(state);

  return slice.views.showCompleted;
};
