import { FC, useCallback } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { useParams } from "react-router-dom";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import { AddBillingExplorerItemNavItem } from "./AddBillingExplorerItemNavItem";
import { ResourceExplorerActionButton } from "../actions-menu/for-all-reports/ResourceExplorerActionButton";
import { BillingExplorerViewFolderActionButton } from "../folder-actions-menu/BillingExplorerViewFolderActionButton";
import { SortableTree } from "../../../../sortable-tree/SortableTree";
import { NavigationVisibilitySection } from "../../../../hierarchy/visibility/NavigationVisibilitySection";
import {
  SortableTreeFolderActionArgs,
  SortableTreeItemActionArgs,
  SortableTreeOnchangeArgs,
  TreeItems,
} from "../../../../sortable-tree/utils/types";
import { setNavigationResourceExplorer } from "../../../../../../../store/navigation/navigationSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { updateResourceExplorerVisibilityHierarchyThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy-visibility/updateResourceExplorerVisibilityHierarchyThunk";
import { navigationResourceExplorerVisibilitySelectedSelector } from "../../../../../../../store/navigation/selectors/resource-explorer/navigationResourceExplorerVisibilitySelectedSelector";
import { useAppAbility } from "../../../../../../../services/permissions";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { useAppNavOverlayContext } from "../../../../../utils/useAppNavOverlay.context";

interface BillingExplorerVisibilityItemProps {
  visibility: NavigationItemsVisibilityType;
  data?: TreeItems;
}

export const BillingExplorerVisibilityItem: FC<
  BillingExplorerVisibilityItemProps
> = ({ visibility, data }) => {
  const dispatch = useAppDispatch();
  const { viewId } = useParams<{ viewId: string }>();
  const { cannot } = useAppAbility();
  const { onClose } = useAppNavOverlayContext();

  const canNotCreateView = cannot("create", "resource-explorer");
  const selected = useAppSelector((state) =>
    navigationResourceExplorerVisibilitySelectedSelector(
      state,
      visibility,
      viewId,
    ),
  );

  const renderFolderAction = useCallback(
    ({ id, name, containsProtectedItem }: SortableTreeFolderActionArgs) => {
      return (
        <BillingExplorerViewFolderActionButton
          id={id}
          name={name}
          visibility={visibility}
          containsProtectedItem={containsProtectedItem}
        />
      );
    },
    [visibility],
  );

  const renderItemAction = useCallback(
    ({
      id,
      name,
      parentId,
      protectedBy,
      protectionDetails,
    }: SortableTreeItemActionArgs) => {
      return (
        <ResourceExplorerActionButton
          id={id}
          name={name}
          folderId={(parentId as string) ?? undefined}
          visibility={visibility}
          protectedBy={protectedBy}
          protectionDetails={protectionDetails}
        />
      );
    },
    [visibility],
  );

  const hierarchyChangeHandler = useCallback(
    ({ index, id, parentId, items, type }: SortableTreeOnchangeArgs) => {
      if (!items) {
        return;
      }
      dispatch(
        setNavigationResourceExplorer({ data: items, type: visibility }),
      );
      dispatch(
        updateResourceExplorerVisibilityHierarchyThunk({
          id: id as string,
          index,
          folderId: (parentId as string) ?? undefined,
          visibility,
          type: type === "item" ? "view" : "folder",
        }),
      );
    },
    [dispatch, visibility],
  );

  const resetHandler = useCallback(() => {
    dispatch(
      setNavigationResourceExplorer({
        data: null,
        type: visibility,
      }),
    );
  }, [dispatch, visibility]);

  const navigateToHandler = useCallback((id: UniqueIdentifier) => {
    return `/resource-explorer/${id}`;
  }, []);

  if (!data) {
    return null;
  }

  return (
    <NavigationVisibilitySection
      expanded={!viewId}
      visibility={visibility}
      selected={selected}
    >
      {data?.length ? (
        <SortableTree
          items={data}
          renderItemAction={renderItemAction}
          renderFolderAction={renderFolderAction}
          onChange={hierarchyChangeHandler}
          getNavigateTo={navigateToHandler}
          onReset={resetHandler}
          icon={InsertChartOutlinedOutlinedIcon}
          rightIcon={
            visibility === "visible_only_to_me" ? LockOutlinedIcon : undefined
          }
          emptyText="No reports inside."
          disabled={canNotCreateView}
          onTreeItemClick={onClose}
        />
      ) : (
        <TypographyWithTooltip
          variant="caption"
          color="text.secondary"
          title="No folders and reports inside."
          py={0.5}
          pl={4}
        />
      )}

      <AddBillingExplorerItemNavItem visibility={visibility} />
    </NavigationVisibilitySection>
  );
};
