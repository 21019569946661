import { FC, Fragment, memo, useCallback, useMemo } from "react";
import { LinearProgress, Theme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Table } from "@tanstack/react-table";
import { useEffectOnceWhen } from "rooks";
import { KubernetesLiveUsageMgmtResourceCardToolbar } from "./toolbar/KubernetesLiveUsageMgmtResourceCardToolbar";
import { WithResourceType } from "../../../../../../../live-usage-mgmt/utils/types";
import { ColumnSetupType } from "../../../../../../../../../storybook/data-grid/utils/types/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { kubernetesLiveUsageMgmtResourceTypeDataLoadingSelector } from "../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/loading/kubernetesLiveUsageMgmtResourceTypeDataLoadingSelector";
import {
  resourceIdSearchParam,
  resourceTypeSearchParam,
} from "../../../../../../../live-usage-mgmt/utils/constants/searchParams";
import { useColumnsVisibility } from "../../../../../../../live-usage-mgmt/utils/hooks/useColumnsVisibility.hook";
import { useColumnsSorting } from "../../../../../../../live-usage-mgmt/utils/hooks/useColumnsSorting.hook";
import { useDataGridContext } from "../../../../../../../../../storybook/data-grid/DataGridProvider";
import { generateMetricsFrequenciesFromContextData } from "../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateMetricsFrequenciesFromContextData";
import {
  setKubernetesLiveUsageMgmtSelectedResources,
  setKubernetesLiveUsageMgmtViewWithoutFiltersResourceTypes,
} from "../../../../../../../../../store/kubernetes-live-usage-mgmt/kubernetesLiveUsageMgmtSlice";
import {
  nfSortingConverter,
  nfVisibilitiesConverter,
} from "../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/nf-tmp-helpers";
import { getKubernetesLiveUsageMgmtResourceTypeDataThunk } from "../../../../../../../../../store/kubernetes-live-usage-mgmt/thunks/resources/getKubernetesLiveUsageMgmtResourceTypeDataThunk";
import { DataGrid } from "../../../../../../../../../storybook/data-grid/DataGrid";

import { kubernetesLiveUsageMgmtIsPageLoadingSelector } from "../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtIsPageLoadingSelector";
import { kubernetesLiveUsageMgmtSelectedClusterIdsSelector } from "../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtSelectedClusterIdsSelector";
import { kubernetesLiveUsageMgmtSelectedGroupedRowsByResourceTypeSelector } from "../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtSelectedGroupedRowsByResourceTypeSelector";
import { KubernetesResourceDataType } from "../../../../../../../../../store/kubernetes-live-usage-mgmt/utils/types/types";
import { generateKubernetesColumns } from "../utils/data-grid/columns-setup/kubernetesResourcesColumnsGenerator";

interface KubernetesLiveUsageMgmtResourcesDataGridProps
  extends WithResourceType {
  fullHeight?: boolean;
  data: KubernetesResourceDataType[];
  columns: ColumnSetupType<any>[];
}

export const KubernetesLiveUsageMgmtResourcesDataGrid: FC<KubernetesLiveUsageMgmtResourcesDataGridProps> =
  memo(({ data, columns, resourceType, fullHeight }) => {
    const dispatch = useAppDispatch();
    const pageLoading = useAppSelector(
      kubernetesLiveUsageMgmtIsPageLoadingSelector,
    );

    const selectedResources = useAppSelector((state) =>
      kubernetesLiveUsageMgmtSelectedGroupedRowsByResourceTypeSelector(
        state,
        resourceType,
      ),
    );

    const loading = useAppSelector((state) =>
      kubernetesLiveUsageMgmtResourceTypeDataLoadingSelector(
        state,
        resourceType,
      ),
    );

    const [searchParams] = useSearchParams();
    const pathResourceIds = searchParams.get(resourceIdSearchParam);
    const pathResourceType = searchParams.get(resourceTypeSearchParam);
    const needToFilterById = !!(
      pathResourceType === resourceType && pathResourceIds
    );
    const clusterIds =
      useAppSelector(kubernetesLiveUsageMgmtSelectedClusterIdsSelector) ?? [];

    const { visibility, setVisibility } = useColumnsVisibility(
      resourceType,
      clusterIds ?? [],
    );
    const { sorting, setSorting } = useColumnsSorting({
      resourceType,
      columns,
      accountIds: clusterIds,
    });
    const {
      cellSpecificMetadata: { data: cellData },
    } = useDataGridContext();
    const metricsFrequencies = useMemo(
      () => generateMetricsFrequenciesFromContextData(cellData),
      [cellData],
    );

    const memoizedVisibility = useMemo(() => {
      return nfVisibilitiesConverter(visibility);
    }, [visibility]);

    const memoizedSorting = useMemo(
      () => nfSortingConverter(sorting),
      [sorting],
    );

    const columnsSetup = useMemo(() => {
      const config = {
        includeCheckbox: true,
        metricsFrequencies,
      };

      return generateKubernetesColumns(
        columns as ColumnSetupType<KubernetesResourceDataType>[],
        config,
      );
    }, [columns, metricsFrequencies]);

    const afterRenderCBFn = useCallback(
      (table: Table<any>) => table.setGlobalFilter(pathResourceIds),
      [pathResourceIds],
    );

    const onRowSelectionChange = useCallback(
      (data: Record<string, boolean>): void => {
        dispatch(
          setKubernetesLiveUsageMgmtSelectedResources({
            resourceType,
            selectedResources: data,
            parentSource: resourceType,
          }),
        );
      },
      [dispatch, resourceType],
    );

    useEffectOnceWhen(() => {
      dispatch(
        setKubernetesLiveUsageMgmtViewWithoutFiltersResourceTypes({
          resourceType,
          value: true,
        }),
      );
      dispatch(getKubernetesLiveUsageMgmtResourceTypeDataThunk(resourceType));
    }, needToFilterById && !loading);

    return (
      <DataGrid
        globalFilter
        enableRowsVirtualization={data.length > 30}
        data={data}
        key={resourceType}
        columns={columnsSetup}
        columnResizeMode="onEnd"
        enableStickyColumns={!!data?.length}
        styles={fullHeight ? fullHeightStyles : styles}
        afterFirstRenderCallback={
          needToFilterById && !loading && data?.length
            ? afterRenderCBFn
            : undefined
        }
        columnVisibility={{
          initialVisibility: memoizedVisibility,
          onColumnsVisibilityChange: setVisibility,
        }}
        columnSorting={{
          initialSort: memoizedSorting,
          onColumnsSortingChange: setSorting,
        }}
        rowSelection={{
          rowSelectionChange: onRowSelectionChange,
          initialSelectedItems: selectedResources?.[resourceType] ?? {},
        }}
        toolbar={{
          renderToolbar: (props) => {
            return (
              <Fragment>
                <KubernetesLiveUsageMgmtResourceCardToolbar
                  {...props}
                  resourceType={resourceType}
                />
                {loading || (pageLoading && <LinearProgress />)}
              </Fragment>
            );
          },
        }}
      />
    );
  });

const styles = {
  tableHeaderRow: {
    top: 0,
    position: "sticky",
    bgcolor: "grey.100",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
  },
  tableRow: {
    background: "white",
  },
  tableToolbar: { px: 2 },
  tableContainer: { maxHeight: 580 },
};

const fullHeightStyles = {
  ...styles,
  tableContainer: { ...styles.tableContainer, maxHeight: "unset" },
};
