import Mention, { MentionNodeAttrs } from "@tiptap/extension-mention";
import {
  SuggestionKeyDownProps,
  SuggestionOptions,
  SuggestionProps,
} from "@tiptap/suggestion";
import tippy, {
  GetReferenceClientRect,
  Instance as TippyInstance,
} from "tippy.js";
import { ReactRenderer } from "@tiptap/react";
import { EditorSuggestion } from "../../types/types";
import { MentionList } from "../../../components/MentionList";

export const getMentionExtension = (suggestions: EditorSuggestion[]) => {
  return Mention.configure({
    HTMLAttributes: { class: "mention" },
    suggestion: getMentionPluginSuggestion(suggestions),
  });
};

export const getMentionPluginSuggestion = (
  suggestions: EditorSuggestion[],
): Omit<SuggestionOptions<any, MentionNodeAttrs>, "editor"> => ({
  items: ({ query }) => {
    return suggestions.filter((suggestion) =>
      suggestion.label.toLowerCase().includes(query.toLowerCase()),
    );
  },

  render: suggestionRenderFunction,
});

const suggestionRenderFunction: SuggestionOptions["render"] = () => {
  let reactRenderer: any;
  let popup: TippyInstance[];

  return {
    onStart: (props: SuggestionProps) => {
      if (!props.clientRect) {
        return;
      }

      reactRenderer = new ReactRenderer(MentionList, {
        props,
        editor: props.editor,
      });

      popup = tippy("body", {
        getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        appendTo: () => document.body,
        content: reactRenderer.element,
        showOnCreate: true,
        interactive: true,
        trigger: "manual",
        placement: "bottom-start",
      });
    },

    onUpdate(props: SuggestionProps) {
      reactRenderer.updateProps(props);

      if (!props.clientRect) {
        return;
      }

      popup[0].setProps({
        getReferenceClientRect: props.clientRect as GetReferenceClientRect,
      });
    },

    onKeyDown(props: SuggestionKeyDownProps) {
      if (props.event.key === "Escape") {
        popup[0].hide();

        return true;
      }

      return !!reactRenderer.ref?.onKeyDown(props);
    },

    onExit() {
      popup[0].destroy();
      reactRenderer.destroy();
    },
  };
};
