import { FC } from "react";
import { CoverageWidgetTableView } from "./CoverageWidgetTableView";
import { useAppSelector } from "../../../../../../../store/hooks";
import { coverageWidgetByWidgetIdGroupsSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetByWidgetIdGroupsSelector";
import { coverageWidgetVisualizationSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetVisualizationSelector";
import { coverageWidgetDatesSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetDatesSelector";
import { coverageWidgetGroupingSelector } from "../../../../../../../store/dashboards/selectors/widgets/coverage/widget-view/coverageWidgetGroupingSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";

interface CoverageWidgetContentTableViewProps {
  widgetId: string;
}

export const CoverageWidgetContentTableView: FC<
  CoverageWidgetContentTableViewProps
> = ({ widgetId }) => {
  const groups = useAppSelector((state) =>
    coverageWidgetByWidgetIdGroupsSelector(state, widgetId),
  );
  const visualization = useAppSelector((state) =>
    coverageWidgetVisualizationSelector(state, widgetId),
  );
  const dates = useAppSelector((state) =>
    coverageWidgetDatesSelector(state, widgetId),
  );
  const grouping = useAppSelector((state) =>
    coverageWidgetGroupingSelector(state, widgetId),
  );
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  return (
    <CoverageWidgetTableView
      groups={groups}
      visualization={visualization}
      dates={dates}
      grouping={grouping ?? "resource_type"}
      quarterStartMonth={quarterStartMonth}
    />
  );
};
