import { FC, Fragment } from "react";
import { BillingExplorerExplainWithAiAnomalyTrendItem } from "../BillingExplorerExplainWithAiAnomalyTrendItem";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { billingExplorerSummaryAnomaliesIncreaseTrendsSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/billingExplorerSummaryAnomaliesIncreaseTrendsSelector";

interface BillingExplorerExplainWithAiIncreaseTrendsProps {
  index: number;
}
export const BillingExplorerExplainWithAiIncreaseTrends: FC<
  BillingExplorerExplainWithAiIncreaseTrendsProps
> = ({ index }) => {
  const increaseTrends = useAppSelector((state) =>
    billingExplorerSummaryAnomaliesIncreaseTrendsSelector(state, index),
  );

  if (!increaseTrends) {
    return null;
  }

  return (
    <Fragment>
      {increaseTrends?.map((item) => {
        return (
          <BillingExplorerExplainWithAiAnomalyTrendItem
            key={item.explanation}
            type="increase"
            {...item}
          />
        );
      })}
    </Fragment>
  );
};
