import { FC, Fragment } from "react";
import { Typography } from "@mui/material";
import { TaskActivityWrapper } from "./TaskActivityWrapper";
import { ActivityComponentProps } from "../../utils/types/types";
import { CommentUser } from "../../../../../../../../task-management/components/grid/cell-renderers/comment-cell/components/CommentUser";

interface NameActivityProps extends ActivityComponentProps {
  updatedName: string;
  prevName: string;
}

export const NameActivity: FC<NameActivityProps> = ({
  createdBy,
  createdAt,
  updatedName,
  prevName,
}) => {
  return (
    <TaskActivityWrapper
      content={
        <CommentUser
          user={createdBy}
          additionalText={
            <Fragment>
              changed name from{" "}
              <Typography
                variant="inherit"
                component="span"
                fontWeight="medium"
              >
                {prevName}
              </Typography>{" "}
              to{" "}
              <Typography
                variant="inherit"
                component="span"
                fontWeight="medium"
              >
                {updatedName}
              </Typography>
            </Fragment>
          }
        />
      }
      createdAt={createdAt}
    />
  );
};
