import { FC } from "react";
import { useParams } from "react-router-dom";
import { LiveUsageMgmt } from "./LiveUsageMgmt";
import { ProviderType } from "../../../services/cloudchipr.api";
import { KubernetesLiveUsageMgmt } from "../kubernetes-live-usage-mgmt/KubernetesLiveUsageMgmt";

export const LiveUsageMgmtProviderCheckWrapper: FC = () => {
  const { provider } = useParams<{ provider: ProviderType }>();

  if (provider === "kubernetes") {
    return <KubernetesLiveUsageMgmt />;
  }

  return <LiveUsageMgmt />;
};
