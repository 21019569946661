import { Stack } from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { SvgIconComponent, VerifiedUserOutlined } from "@mui/icons-material";

import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { NavigationItemsVisibilityType } from "../../../../../store/navigation/utils/types";
import { TreeItems } from "../../../../app-navigation/components/sortable-tree/utils/types";

interface FormatHierarchyVisibilityOptionsArgs {
  data?: TreeItems;
  disabledOptionsIds?: string[];
  ItemIcon?: SvgIconComponent;
  visibility?: NavigationItemsVisibilityType;
}
export const formatHierarchyVisibilityOptions = ({
  data,
  disabledOptionsIds,
  ItemIcon,
  visibility,
}: FormatHierarchyVisibilityOptionsArgs) => {
  return (
    data
      ?.map((item) => {
        const groupName =
          visibility === "visible_to_everyone"
            ? "Visible to everyone"
            : "Visible only to me";
        if (item.type === "item") {
          const isProtected = item.protectionDetails?.is_protected;
          return {
            groupName,
            value: item.id,
            rawValue: item,
            label: getViewLabel({
              name: item.name,
              Icon: ItemIcon,
              isProtected,
            }),
            disabled: disabledOptionsIds?.includes(item.id),
          };
        }

        let names = "";

        const folderViews =
          item.items?.map((row) => {
            names += row.name;
            const isProtected = row.protectionDetails?.is_protected;

            return {
              groupName,
              value: row.id,
              rawValue: { ...row, valueForSearch: item.name },
              label: getViewLabel({
                name: row.name,
                Icon: ItemIcon,
                pl: 2,
                isProtected,
              }),
              disabled: disabledOptionsIds?.includes(row.id),
            };
          }) ?? [];

        return [
          {
            groupName,
            value: item.id,
            disableSelection: true,
            rawValue: { ...item, valueForSearch: names },
            label: (
              <Stack direction="row" spacing={1} px={2} alignItems="center">
                <FolderOpenIcon fontSize="small" />
                <TypographyWithTooltip title={item.name} />
              </Stack>
            ),
          },
          ...folderViews,
        ];
      })
      ?.flat() ?? []
  );
};

interface GetViewLabelArgs {
  name: string;
  Icon?: SvgIconComponent;
  pl?: number;
  isProtected?: boolean | null;
}

const getViewLabel = ({ name, Icon, pl, isProtected }: GetViewLabelArgs) => {
  return (
    <Stack direction="row" spacing={1} pl={pl} alignItems="center">
      {Icon && <Icon fontSize="small" />}
      {isProtected && <VerifiedUserOutlined sx={{ fontSize: 16 }} />}
      <TypographyWithTooltip title={name} />
    </Stack>
  );
};
