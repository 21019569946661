import { FC, useCallback } from "react";
import { CommitmentsDateGranularitySelector } from "./CommitmentsDateGranularitySelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { commitmentsDateGranularitySelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDateGranularitySelector";
import { DateDataPoint } from "../../../../../../../services/cloudchipr.api";
import { changeCommitmentsDateGranularityThunk } from "../../../../../../../store/commitments/thunks/changeCommitmentsDateGranularityThunk";

export const CommitmentsDateGranularity: FC = () => {
  const dispatch = useAppDispatch();

  const dateGranularity = useAppSelector(commitmentsDateGranularitySelector);

  const changeHandler = useCallback(
    (dg: DateDataPoint) => {
      dispatch(changeCommitmentsDateGranularityThunk(dg));
    },
    [dispatch],
  );

  if (!dateGranularity) {
    return null;
  }

  return (
    <CommitmentsDateGranularitySelector
      onChange={changeHandler}
      dateGranularity={dateGranularity}
    />
  );
};
