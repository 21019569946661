import { KubernetesCluster } from "../../../../services/cloudchipr.api";
import { KubernetesClusterSortByFields } from "../../accountsSlice";

export const kubernetesClustersSortingCombiner = (
  clusters: KubernetesCluster[] | null,
  sortBy: KubernetesClusterSortByFields | "custom",
): KubernetesCluster[] | null => {
  if (!clusters) {
    return null;
  }

  if (!sortBy || sortBy === "custom") {
    return clusters;
  }

  const sortByCB =
    sortBy === "total_monthly_cost" ||
    sortBy === "idle_monthly_cost" ||
    sortBy === "reserved_monthly_cost"
      ? numberSortCallback(sortBy)
      : stringSortCallback(sortBy);

  return [...clusters].sort(sortByCB);
};

const numberSortCallback =
  (
    sortBy:
      | "total_monthly_cost"
      | "idle_monthly_cost"
      | "reserved_monthly_cost",
  ) =>
  (a: KubernetesCluster, b: KubernetesCluster) =>
    b[sortBy] - a[sortBy];

const stringSortCallback =
  (sortBy: "name" | "cluster_id" | "provider") =>
  (a: KubernetesCluster, b: KubernetesCluster) => {
    let current = a[sortBy];
    let next = b[sortBy];

    if (!current || !next) {
      return 0;
    }

    current = current.toLowerCase();
    next = next.toLowerCase();

    if (current < next) {
      return -1;
    }

    if (current > next) {
      return 1;
    }

    return 0;
  };
