import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

export const getSavingsOpportunitiesThunk = createAsyncThunk(
  "savingsOpportunities/getSavingsOpportunities",
  async (_: void, { dispatch }) => {
    const { getOrganisationsCurrentSavingsOpportunities } =
      cloudChiprApi.endpoints;

    return await dispatch(
      getOrganisationsCurrentSavingsOpportunities.initiate(),
    ).unwrap();
  },
);
