import { allAccountsGroupedByProviderAndOrgSelector } from "./allAccountsGroupedByProviderAndOrgSelector";
import { RootState } from "../../../store";
import { ProviderType } from "../../../../services/cloudchipr.api";

export const accountsByProviderSelector = (
  state: RootState,
  provider?: ProviderType,
) => {
  if (!provider) {
    return null;
  }

  const allAccountsGroupedByProviderAndOrg =
    allAccountsGroupedByProviderAndOrgSelector(state);

  return allAccountsGroupedByProviderAndOrg?.[provider];
};
