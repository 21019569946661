import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { FC, useCallback } from "react";
import { metricsChartsPeriods } from "../../../../utils/constants/metrics";
import { MetricPeriods } from "../../../../utils/types/metrics";

interface ResourceDetailsMetricsContentFrequencySelectionProps {
  period: MetricPeriods;
  setPeriod(f: MetricPeriods): void;
}

export const ResourceDetailsMetricsContentFrequencySelection: FC<
  ResourceDetailsMetricsContentFrequencySelectionProps
> = ({ setPeriod, period }) => {
  const changeHandler = useCallback(
    (_: any, period: string) => {
      setPeriod(period as MetricPeriods);
    },
    [setPeriod],
  );

  return (
    <FormControl>
      <RadioGroup row value={period} onChange={changeHandler}>
        {Object.entries(metricsChartsPeriods).map(([key, { label }]) => {
          return (
            <FormControlLabel
              value={key}
              key={key}
              control={<Radio />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
