import { FC, Fragment, memo, useCallback } from "react";
import { FormikHandlers, FormikHelpers } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import * as yup from "yup";
import {
  FilterItem,
  Operator,
} from "../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { FilterItemOperatorType } from "../FilterItemOperatorType";

export const DateSelectionFilterValueValidation = yup
  .string()
  .required("Date is required");

interface DateSelectionFilterProps {
  operators?: Operator[];
  filter: FilterItem;
  error?: any;
  onChange: FormikHandlers["handleChange"];
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
}

export const DateSelectionFilter: FC<DateSelectionFilterProps> = memo(
  ({ operators, filter, setFieldValue, onChange, error }) => {
    const dateChangeHandler = useCallback(
      (date: Moment | string | null) => {
        date &&
          setFieldValue(
            "value",
            formatDate(date, {
              type: "yearMonthDay",
            }),
          );
      },
      [setFieldValue],
    );

    const dateValue =
      typeof filter.value === "string"
        ? formatDate(filter?.value, {
            type: "date",
          })
        : "";

    return (
      <Fragment>
        <FilterItemOperatorType
          value={filter.operator || ""}
          options={operators}
          onChange={onChange}
        />

        <DatePicker
          sx={{ width: 150 }}
          value={dateValue ? moment(dateValue) : null}
          disableFuture
          label="Date"
          onChange={dateChangeHandler}
          slotProps={{
            textField: {
              fullWidth: true,
              size: "xsmall",
              error: !!error?.value,
              variant: "outlined",
              name: "value",
            },
          }}
        />
      </Fragment>
    );
  },
);
