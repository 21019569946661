import { FC } from "react";
import { Stack } from "@mui/material";
import { ProviderType } from "../../../../../../../../services/cloudchipr.api";
import { ProviderIcon } from "../../../../../../../common/ProviderIcon";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";

interface KubernetesClusterClusterNameColumnProps {
  provider: ProviderType;
  name: string;
  clusterId: string;
}

export const KubernetesClusterClusterNameColumn: FC<
  KubernetesClusterClusterNameColumnProps
> = ({ provider, name, clusterId }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <ProviderIcon provider="kubernetes" />
      <Stack>
        <TypographyWithTooltip variant="body2" title={name} />
        <TypographyWithTooltip
          title={`ID: ${clusterId ?? "-"}`}
          variant="caption"
          color="text.secondary"
        />
      </Stack>
    </Stack>
  );
};
