import { RootState } from "../../../../store";
import { getUsersMeOrganisationsCurrentSavingsOpportunitiesHierarchySelector } from "../getUsersMeOrganisationsCurrentSavingsOpportunitiesHierarchySelector";

export const savingsOpportunitiesViewsHierarchyLoadingSelector = (
  state: RootState,
) => {
  return !!getUsersMeOrganisationsCurrentSavingsOpportunitiesHierarchySelector(
    state,
  )?.isLoading;
};
