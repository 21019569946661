import { FC } from "react";
import { Skeleton, Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../store/hooks";
import { executionLogDataSelector } from "../../../../store/execution-log/selectors/executionLogDataSelector";
import { executionLogDataLoadingSelector } from "../../../../store/execution-log/selectors/executionLogDataLoadingSelector";
import { isExecutionLogDataFilteredSelector } from "../../../../store/execution-log/selectors/isExecutionLogDataFilteredSelector";

export const ExecutionLogCount: FC = () => {
  const data = useAppSelector(executionLogDataSelector);
  const isLoading = useAppSelector(executionLogDataLoadingSelector);
  const isExecutionLogDataFiltered = useAppSelector(
    isExecutionLogDataFilteredSelector,
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center" color="grey.600">
      {isExecutionLogDataFiltered && (
        <Typography variant="caption">Filtered Result</Typography>
      )}
      <Typography
        px={1}
        py={0.5}
        variant="body2"
        fontWeight="normal"
        borderRadius={1}
        bgcolor="grey.200"
      >
        {isLoading ? (
          <Skeleton
            variant="text"
            height={18}
            width={26}
            sx={{ display: "inline-block", mr: 0.5 }}
          />
        ) : (
          <Typography variant="body2" component="span" fontWeight="bold">
            {data?.total_items ?? 0}{" "}
          </Typography>
        )}
        Logs
      </Typography>
    </Stack>
  );
};
