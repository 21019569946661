import { createAsyncThunk } from "@reduxjs/toolkit";
import { getResourceDetailsResourceBillingGroupedDataThunk } from "./getResourceDetailsResourceBillingGroupedDataThunk";
import { getResourceDetailsResourceBillingGranularDataThunk } from "./getResourceDetailsResourceBillingGranularDataThunk";
import { RootState } from "../../../store";
import { resourceDetailsBillingProcessesSelector } from "../../selectors/processes/resourceDetailsBillingProcessesSelector";

export const getResourceDetailsResourceBillingDataThunk = createAsyncThunk(
  "resourceDetails/getResourceDetailsResourceBillingData",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const processes = resourceDetailsBillingProcessesSelector(state);

    const promises = Object.entries(processes).map(([processKey, process]) => {
      if (process.chartType === "table") {
        return dispatch(
          getResourceDetailsResourceBillingGranularDataThunk({
            processKey,
            groupBy: process.groupBy,
          }),
        ).unwrap();
      }

      return dispatch(
        getResourceDetailsResourceBillingGroupedDataThunk({
          processKey,
          groupBy: process.groupBy,
        }),
      ).unwrap();
    });

    return await Promise.all(promises);
  },
);
