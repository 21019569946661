import { FC, useCallback } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { BillingExplorerFolderListItemActionsMenu } from "./BillingExplorerFolderListItemActionsMenu";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../../services/permissions";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { BillingExplorerCreateFolderOrReportDialog } from "../BillingExplorerCreateFolderOrReportDialog";
import { navItemSecondaryActionButtonVisibilityStyles } from "../../../../../utils/helpers/navItemSecondaryActionButtonVisibilityStyles";

interface BillingExplorerViewFolderActionButtonProps {
  id: string;
  name: string;
  size?: "small" | "medium" | "large";
  visibility: NavigationItemsVisibilityType;
  redirectAfterCreate?: boolean;
  containsProtectedItem?: boolean;
}

export const BillingExplorerViewFolderActionButton: FC<
  BillingExplorerViewFolderActionButtonProps
> = ({
  id,
  name,
  visibility,
  size,
  redirectAfterCreate,
  containsProtectedItem,
}) => {
  const { cannot } = useAppAbility();
  const cannotDeleteResourceExplorer = cannot("delete", "resource-explorer");
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const {
    open: openAdd,
    openDialog: openAddDialog,
    closeDialog: closeAddDialog,
  } = useDialog();

  const closeDialog = useCallback(() => {
    closeAddDialog();
    closeMenu();
  }, [closeMenu, closeAddDialog]);

  if (cannotDeleteResourceExplorer) {
    return null;
  }

  return (
    <Stack direction="row" spacing={0.5} justifyContent="flex-end">
      <Tooltip
        title="Add report"
        arrow
        placement="top"
        sx={navItemSecondaryActionButtonVisibilityStyles(open || openAdd)}
      >
        <IconButton size="small" onClick={openAddDialog}>
          <AddIcon fontSize={size ?? "inherit"} />
        </IconButton>
      </Tooltip>

      <IconButton
        size="small"
        onClick={openMenu}
        sx={navItemSecondaryActionButtonVisibilityStyles(open || openAdd)}
      >
        <MoreVertIcon fontSize={size ?? "inherit"} />
      </IconButton>

      <BillingExplorerFolderListItemActionsMenu
        id={id}
        name={name}
        open={open}
        anchor={anchor}
        closeMenu={closeMenu}
        visibility={visibility}
        redirectAfterCreate={redirectAfterCreate}
        containsProtectedItem={containsProtectedItem}
      />

      {openAdd && (
        <BillingExplorerCreateFolderOrReportDialog
          onClose={closeDialog}
          type="item"
          folderId={id}
          visibility={visibility}
          redirectAfterCreate={redirectAfterCreate}
        />
      )}
    </Stack>
  );
};
