import { FC, useCallback } from "react";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { coverageWidgetSetupOrgIdSelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/coverage/coverageWidgetSetupOrgIdSelector";

import { coverageWidgetSetupDataChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/coverage-widget/setup-change/coverageWidgetSetupDataChangeThunk";
import { AwsOrganizationSelect } from "../../../../../../../../../../common/AwsOrganizationSelect";

export const CoverageWidgetSetupOrgSelect: FC = () => {
  const dispatch = useAppDispatch();
  const orgId = useAppSelector(coverageWidgetSetupOrgIdSelector);

  const handleChange = useCallback(
    (providerOrganisationId: string) => {
      dispatch(
        coverageWidgetSetupDataChangeThunk({
          providerOrganisationId,
        }),
      );
    },
    [dispatch],
  );

  if (!orgId) {
    return;
  }

  return (
    <AwsOrganizationSelect selectedOrgId={orgId} onChange={handleChange} />
  );
};
