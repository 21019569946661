import { FC, useCallback } from "react";
import { capitalize, Stack, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { budgetPeriodUnits } from "./utils/constants/date-selection";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { currentBudgetPeriodSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/currentBudgetPeriodSelector";
import { budgetChartDataLoadingSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/chart/budgetChartDataLoadingSelector";
import { chartReferenceLinesByThresholdsSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/chart/chartReferenceLinesByThresholdsSelector";
import { budgetChartDataSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/chart/budgetChartDataSelector";
import { moneyFormatter } from "../../../../../../../../../utils/numeral/moneyFormatter";
import { money } from "../../../../../../../../../utils/numeral/money";
import { MultiTypeChartProvider } from "../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { MultiTypeChart } from "../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChart";
import { ChartType } from "../../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";

export const BudgetsChart: FC = () => {
  const period = useAppSelector(currentBudgetPeriodSelector);
  const chartDataLoading = useAppSelector(budgetChartDataLoadingSelector);
  const referenceLines = useAppSelector(
    chartReferenceLinesByThresholdsSelector,
  );
  const chartData = useAppSelector(budgetChartDataSelector);

  const yAxisFormatter = useCallback((value: number | string) => {
    return moneyFormatter(+value);
  }, []);

  const lastMonthValue = chartData?.at(-2)?.Actual ?? 0;

  return (
    <Stack p={2} flex={1} borderLeft={1} borderColor={grey[300]}>
      <Typography variant="body1" fontWeight="medium">
        Cost Data
      </Typography>

      <Stack direction="row" spacing={1} mt={2} mb={1}>
        <Typography variant="subtitle2" color="text.secondary" fontWeight="400">
          Last {capitalize(budgetPeriodUnits[period ?? ""] ?? "")} -
        </Typography>

        <Typography variant="body1" fontWeight="medium" component="span">
          {money(!isNaN(lastMonthValue) ? lastMonthValue : 0)}
        </Typography>
      </Stack>

      <MultiTypeChartProvider
        value={{
          initialChartType: "stack",
          data: chartData ?? [],
          colors,
        }}
      >
        <MultiTypeChart
          yAxisLabel="Costs ($)"
          loading={chartDataLoading}
          tooltipProps={tooltipProps}
          yAxisFormatter={yAxisFormatter}
          availableTypes={availableTypes}
          referenceLines={referenceLines}
        />
      </MultiTypeChartProvider>
    </Stack>
  );
};

const availableTypes = ["stack"] as ChartType[];
const tooltipProps = { hideTooltip: true };
const colors = { Actual: blue[300] };
