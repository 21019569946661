import { azureSavingsPlanSelectedCoverageDataSelector } from "./azureSavingsPlanSelectedCoverageDataSelector";
import { RootState } from "../../../../../../store";

export const azureSavingsPlanSelectedCoverageDataRegionSelector = (
  state: RootState,
): string | undefined => {
  const data = azureSavingsPlanSelectedCoverageDataSelector(state);

  return data?.region?.name;
};
