import moment from "moment";
import numeral from "numeral";
import { RootState } from "../../../../store";
import { resourceExplorerGroupingSelector } from "../data/resourceExplorerGroupingSelector";
import { resourceExplorerGranularViewGridDataSelector } from "../data-grid/granular-costs-view/resourceExplorerGranularViewGridDataSelector";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";

export const resourceExplorerGranularViewCsvDataSelector = (
  state: RootState,
) => {
  const data = resourceExplorerGranularViewGridDataSelector(state);
  const grouping = resourceExplorerGroupingSelector(state);

  const groupingLabelMap = resourceExplorerGroupingLabelByGroupingSelector(
    state,
    grouping,
  );
  const key = groupingLabelMap?.singular ?? "";

  if (!data || !key) {
    return null;
  }

  return data.map((item) => {
    const result: Record<string, string | number> = {
      [key]: item.field,
      "Total Cost": item.total_cost,
      Trend: item.trend ? `${numeral(item.trend).format("0.00")} %` : "-",
    };

    Object.keys(item).forEach((key) => {
      const isKeyDate = moment(key).isValid();

      if (isKeyDate) {
        const cost = item[key].cost;

        result[formatDate(key, { type: "date" })] = cost ?? "N/A";
      }
    });

    return result;
  });
};
