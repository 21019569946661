import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { Chart } from "highcharts";

export interface HighchartsContextType {
  chartApi: Chart | null;
  setChartApi: Dispatch<SetStateAction<Chart | null>>;
}

const HighchartsContext = createContext({} as HighchartsContextType);

export interface HighchartsProviderProps {
  children?: ReactNode;
}

export const HighchartsProvider: FC<HighchartsProviderProps> = ({
  children,
}) => {
  const [chartApi, setChartApi] = useState<Chart | null>(null);

  const contextValue = useMemo<HighchartsContextType>(() => {
    return {
      chartApi,
      setChartApi,
    };
  }, [chartApi]);

  return (
    <HighchartsContext.Provider value={contextValue}>
      {children}
    </HighchartsContext.Provider>
  );
};

export const useHighchartsContext = () => {
  return useContext(HighchartsContext);
};
