import { FC } from "react";
import { Alert } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";
import { taskManagementTargetsLengthSelector } from "../../../../../../../../../store/task-management/selectors/form/target/common/taskManagementTargetsLengthSelector";

export const TaskCreationMultiTaskingAlertMessage: FC = () => {
  const targets = useAppSelector(taskManagementTargetsLengthSelector);
  const editMode = useAppSelector(isTaskManagementDrawerEditModeSelector);

  if (editMode || (targets ?? 0) < 2) {
    return null;
  }

  return (
    <Alert
      variant="filled"
      severity="info"
      sx={{
        bgcolor: blue[100],
        fontWeight: "normal",
        color: "primary.main",
      }}
    >
      Separate tasks will be created for each selected resource, and the
      specified properties will be applied to all {targets} tasks.
    </Alert>
  );
};
