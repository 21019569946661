import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setCommitmentsOrgId,
  setCommitmentsOrgProvider,
} from "../../commitmentsSlice";
import { ProviderType } from "../../../../services/cloudchipr.api";

interface InitiateCommitmentsThunkArgs {
  orgId: string;
  provider?: ProviderType | null;
}
export const initiateCommitmentsThunk = createAsyncThunk(
  "commitments/initiateCommitments",
  async ({ orgId, provider }: InitiateCommitmentsThunkArgs, { dispatch }) => {
    dispatch(setCommitmentsOrgId(orgId));
    // previous not valid routes are only for aws
    dispatch(setCommitmentsOrgProvider(provider ?? "aws"));
  },
);
