import { FC } from "react";
import { SlackIntegrationGrid } from "./components/grid/SlackIntegrationGrid";
import { CommunicationIntegrationsPageWrapper } from "../common/components/CommunicationIntegrationsPageWrapper";

export const SlackIntegration: FC = () => {
  return (
    <CommunicationIntegrationsPageWrapper
      type="slack"
      breadcrumbs={breadcrumbs}
    >
      <SlackIntegrationGrid />
    </CommunicationIntegrationsPageWrapper>
  );
};

const breadcrumbs = [
  { title: "Integrations", to: "/integrations" },
  { title: "Slack" },
];
