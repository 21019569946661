import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { SavingsPlansDataGrid } from "./SavingsPlansDataGrid";
import { SavingsPlanCoverageDataGrid } from "./SavingsPlanCoverageDataGrid";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsDataViewTabSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";
import { savingsPlansForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/savingsPlansForTableLoadingSelector";
import { CommitmentsGridLoadingLayer } from "../../../../common/components/data-grid/CommitmentsGridLoadingLayer";
import { SavingPlansRecommendationsDataGrid } from "../recommendations/SavingPlansRecommendationsDataGrid";

export const UtilizationAndCoverageData: FC = () => {
  const tab = useAppSelector(commitmentsDataViewTabSelector);
  const loading = useAppSelector(savingsPlansForTableLoadingSelector);

  if (tab === "recommendations") {
    return (
      <Box flex={1}>
        <SavingPlansRecommendationsDataGrid />
      </Box>
    );
  }

  return (
    <Stack position="relative" zIndex={1} flex={1}>
      {loading && <CommitmentsGridLoadingLayer />}

      {tab === "commitment" && <SavingsPlansDataGrid />}

      {tab === "coverage" && <SavingsPlanCoverageDataGrid />}
    </Stack>
  );
};
