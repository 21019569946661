import { accountsDividedToStatusesByOrgIdSelector } from "./accountsDividedToStatusesByOrgIdSelector";
import { kubernetesClustersSelector } from "./kubernetes/kubernetesClustersSelector";
import { RootState } from "../../store";
import { ProviderType } from "../../../services/cloudchipr.api";

export const accountIdsByProviderAndOrgIdSelector = (
  state: RootState,
  provider?: ProviderType,
  orgId?: string,
) => {
  const accounts = accountsDividedToStatusesByOrgIdSelector(
    state,
    provider,
    orgId,
  );
  const clusters = kubernetesClustersSelector(state);

  if (provider === "kubernetes") {
    return clusters?.map((cluster) => cluster.id).join(",");
  }

  return (accounts?.active?.map((account) => account.id) ?? []).join(",");
};
