import { Color } from "@mui/material";
import { blue, purple } from "@mui/material/colors";
import { MetricType } from "./types";

export const metricsUnit: Record<MetricType, string> = {
  compute: "%",
  database: "",
  storage: "",
  memory_used: "%",
  memory_available: "%",
  //TODO: need to be dynamic
  processed_bytes: "Mb",
};

export const metricsLabel: Record<MetricType, string> = {
  compute: "Max CPU",
  database: "Connections",
  storage: "Requests",
  memory_used: "Memory Max",
  memory_available: "Unused Memory",
  processed_bytes: "Traffic Sum",
};

export const metricCellFrequencyValues: Record<string, "hourly" | "daily"> = {
  "24h": "hourly",
  "7d": "daily",
  "30d": "daily",
};

export const colorsByMetricType = new Map<MetricType, Color>([
  ["compute", blue],
  ["storage", blue],
  ["database", blue],
  ["processed_bytes", blue],
  ["memory_used", purple],
  ["memory_available", purple],
]);
