import { createAsyncThunk } from "@reduxjs/toolkit";
import { generateKubernetesFilterTreeByFilters } from "./utils/helpers/generateKubernetesFilterTreeByFilters";
import {
  cloudChiprApi,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { kubernetesLiveUsageMgmtSelectedClusterIdsSelector } from "../../selectors/store-selectors/kubernetesLiveUsageMgmtSelectedClusterIdsSelector";
import { kubernetesLiveUsageMgmtAppliedFiltersByResourceTypeSelector } from "../../selectors/filter-template/kubernetesLiveUsageMgmtAppliedFiltersByResourceTypeSelector";

export const getKubernetesLiveUsageMgmtResourcesWithFiltersThunk =
  createAsyncThunk(
    "liveUsageMgmt/getKubernetesLiveUsageMgmtResourcesWithFilters",
    async (resourceType: ResourceType, { dispatch, getState }) => {
      const state = getState() as RootState;
      const clusterIds =
        kubernetesLiveUsageMgmtSelectedClusterIdsSelector(state);

      const type =
        getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;
      const appliedFilter =
        kubernetesLiveUsageMgmtAppliedFiltersByResourceTypeSelector(
          state,
          type,
        );

      if (!clusterIds) {
        return;
      }
      const filterTree = generateKubernetesFilterTreeByFilters({
        filter: appliedFilter,
        clusterIds: clusterIds ?? [],
      });

      dispatch(
        cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentKubernetesByTypeResources.initiate(
          {
            type: type,
            body: {
              filter: filterTree ?? {
                node_type: "group",
                operator: "or",
                items: [],
              },
            },
          },
          { fixedCacheKey: type },
        ),
      );
    },
  );
