import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Stack } from "@mui/material";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import {
  ChartBaseType,
  CoverageGroupResponse,
} from "../../../../../../../services/cloudchipr.api";
import {
  ChartDataType,
  ChartType,
} from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { useMultiTypeChartContext } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import {
  getInterval,
  getXAxisTickLabelFormater,
  percentFormater,
} from "../../commitment-utlization/utils/helpers/helpers";
import { moneyFormatter } from "../../../../../../../utils/numeral/moneyFormatter";
import { MultiTypeChart } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChart";
import { money } from "../../../../../../../utils/numeral/money";
import { chartDataPointKey } from "../../../../../../../storybook/charts/multi-type-chart/utils/constants/constants";
import { coverageDataKeyByChartDataKey } from "../utils/constants/constants";
import { isCommitmentAccount } from "../utils/helpers/isCommitmentAccount";
import { getCoverageRowDataFromChartData } from "../utils/helpers/getCoverageRowDataFromChartData";

interface CoverageWidgetChartProps {
  chartBase?: ChartBaseType;
  chartType?: ChartType;
  groups?: CoverageGroupResponse[];
}

export const CoverageWidgetChart: FC<CoverageWidgetChartProps> = ({
  chartBase,
  chartType,
  groups,
}) => {
  const ref = useRef<null | HTMLDivElement>(null);

  const {
    data: chartData,
    chartType: { type: chartTypeFromProvider, onChange: onChartTypeChange },
  } = useMultiTypeChartContext();
  const width = ref.current?.clientWidth ?? 0;
  const interval = getInterval(chartData.length);

  const yAxisFormatter = useCallback(
    (value: number | string) => {
      return chartBase === "percent"
        ? percentFormater(value)
        : moneyFormatter(+value);
    },
    [chartBase],
  );

  const xAxisTickLabelFormater = useMemo(
    () =>
      getXAxisTickLabelFormater(chartData.length, chartType, width, interval),
    [chartType, chartData.length, width, interval],
  );

  const tooltipValueFormatter = useCallback(
    (payload: Payload<any, any>) => {
      const dateKey = payload.dataKey;
      const identifier = payload.payload[chartDataPointKey];

      if (!dateKey) {
        return;
      }

      const group = getCoverageRowDataFromChartData(identifier, groups);

      const value = group?.[coverageDataKeyByChartDataKey[dateKey]];

      if (typeof value !== "object" || isCommitmentAccount(value)) {
        return;
      }

      return `${money(value?.amount)} (${value?.percentage}%)`;
    },
    [groups],
  );

  const tooltipTotalCostFormatter = useCallback(
    (data?: ChartDataType) => {
      const identifier = data?.[chartDataPointKey];
      const group = getCoverageRowDataFromChartData(identifier, groups);

      return `${money(group?.coverage?.amount ?? 0)} (${group?.coverage?.percentage ?? 0}%)`;
    },
    [groups],
  );

  useEffect(() => {
    if (chartTypeFromProvider === chartType) {
      return;
    }

    onChartTypeChange(chartType ?? "stack");
  }, [chartTypeFromProvider, chartType, onChartTypeChange]);

  return (
    <Stack ref={ref}>
      <MultiTypeChart
        zoom
        selectable
        highlight
        tooltipProps={{
          showTotal: true,
          totalTitle: "Coverage",
          tooltipValueFormatter,
          tooltipTotalCostFormatter,
        }}
        yAxisFormatter={yAxisFormatter}
        xAxisTickProps={{
          labelFormatter: xAxisTickLabelFormater,
          interval: interval,
        }}
        chartBase={chartBase}
        yAxisProps={chartBase === "percent" ? { domain: [0, 100] } : undefined}
        showLoader={false}
        emptyText="No data by your selected date range."
      />
    </Stack>
  );
};
