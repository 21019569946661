import {
  FilterGroup,
  FilterGroupNode,
  FilterItemNode,
  FilterTreeNode,
  MetricFilterValue,
  MetricOfFilterItem,
  ResourceExplorerDynamicFilterItemType,
  ResourceFilters,
} from "../../../../../../services/cloudchipr.api";

interface GenerateFilterTreeByFiltersArgs {
  filter: ResourceFilters | null;
  clusterIds: string[];
}
export const generateKubernetesFilterTreeByFilters = ({
  clusterIds,
  filter,
}: GenerateFilterTreeByFiltersArgs): FilterGroupNode => {
  const clusterFilter: FilterGroupNode = {
    node_type: "group",
    operator: "and",
    items: [
      {
        node_type: "item",
        type: "cluster",
        filter_provider: "kubernetes",
        value: clusterIds,
        operator: "in",
      },
    ],
  };
  if (!filter) {
    return {
      node_type: "group",
      operator: "and",
      items: [clusterFilter],
    };
  }

  const parseFilterGroups = (
    group: number | FilterGroup,
    index: number,
  ): FilterTreeNode => {
    if (typeof group === "number") {
      let value: FilterItemNode["value"] =
        filter.filter_items[group].filter.value;
      const metric = filter.filter_items[group].filter?.metric;
      if (metric && value) {
        value = generateMetricFilter(metric, value);
      }
      return {
        node_type: "item",
        filter_provider: "kubernetes",
        type: filter.filter_items[group].filter
          .key as ResourceExplorerDynamicFilterItemType,
        value: value,
        operator: filter.filter_items[group].filter.operator,
      };
    }

    return {
      node_type: "group",
      operator: filter.operators[index],
      items: group.map(parseFilterGroups),
    };
  };

  return {
    node_type: "group",
    operator: "and",
    items: [
      clusterFilter,
      {
        node_type: "group",
        operator: filter.operators[0] ?? "and",
        items: filter.filter_groups.map(parseFilterGroups),
      },
    ],
  };
};

const generateMetricFilter = (
  metric: MetricOfFilterItem,
  value: FilterItemNode["value"],
): MetricFilterValue => {
  return {
    since_value: metric.since?.value ? +metric.since.value : 0,
    since_unit: metric.since?.unit ?? "days",
    statistics: metric.statistics_type ?? "maximum",
    value: value ? +value : 0,
  };
};
