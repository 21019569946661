import { ResourceType } from "../../../../services/cloudchipr.api";

export const resourceTypeLongNames: Map<
  ResourceType,
  { singular: string; plural: string }
> = new Map([
  // AWS --------------------------------------------------------------------
  ["ec2", { singular: "EC2 Instance", plural: "EC2 Instances" }],
  ["asg", { singular: "Auto Scaling Group", plural: "Auto Scaling Groups" }],
  [
    "asg_ec2",
    { singular: "Auto Scaling Group", plural: "Auto Scaling Groups" },
  ],
  ["ebs", { singular: "EBS Volume", plural: "EBS Volumes" }],
  ["ebs_snapshot", { singular: "EBS Snapshot", plural: "EBS Snapshots" }],
  ["eip", { singular: "Elastic IP", plural: "Elastic IPs" }],
  [
    "elb",
    { singular: "Elastic Load Balancer", plural: "Elastic Load Balancers" },
  ],
  ["s3", { singular: "S3 Bucket", plural: "S3 Buckets" }],
  ["rds", { singular: "RDS Database", plural: "RDS Databases" }],
  ["rds_snapshot", { singular: "RDS Snapshot", plural: "RDS Snapshots" }],
  [
    "rds_snapshot_source",
    { singular: "RDS Snapshot", plural: "RDS Snapshots" },
  ],
  ["eks", { singular: "EKS Cluster", plural: "EKS Clusters" }],
  ["eks_ec2", { singular: "EKS Node", plural: "EKS Nodes" }],
  ["node_group", { singular: "EKS Node Group", plural: "EKS Node Groups" }],
  ["elc", { singular: "ElastiCache", plural: "ElastiCache" }],
  [
    "dynamo_db_table",
    { singular: "DynamoDB Table", plural: "DynamoDB Tables" },
  ],
  [
    "dax_cluster",
    { singular: "DynamoDB DAX Cluster", plural: "DynamoDB DAX Clusters" },
  ],
  [
    "dynamo_db_backup",
    { singular: "DynamoDB Backup", plural: "DynamoDB Backups" },
  ],
  [
    "dynamo_db_backup_source",
    { singular: "DynamoDB Backup", plural: "DynamoDB Backups" },
  ],
  ["nat_gateway", { singular: "NAT Gateway", plural: "NAT Gateways" }],
  ["ecs_workload", { singular: "ECS Workload", plural: "ECS Workloads" }],
  ["ecs", { singular: "ECS Cluster", plural: "ECS Clusters" }],

  // GCP --------------------------------------------------------------------
  [
    "vm",
    {
      singular: "Virtual Machine Instance",
      plural: "Virtual Machine Instances",
    },
  ],
  [
    "mig",
    { singular: "Managed Instance Group", plural: "Managed Instance Groups" },
  ],
  [
    "mig_vm",
    { singular: "Managed Instance Group", plural: "Managed Instance Groups" },
  ],

  ["disk", { singular: "Persistent Disk", plural: "Persistent Disks" }],
  ["ip", { singular: "External IP Address", plural: "External IP Addresses" }],
  ["lb", { singular: "Cloud Load Balancer", plural: "Cloud Load Balancers" }],
  ["sql", { singular: "SQL Database", plural: "SQL Databases" }],
  ["gke", { singular: "GKE Cluster", plural: "GKE Clusters" }],
  ["node_pool", { singular: "GKE Node Pool", plural: "GKE Node Pools" }],
  ["gke_vm", { singular: "GKE Node", plural: "GKE Nodes" }],

  // AZURE --------------------------------------------------------------------
  [
    "az_vm",
    { singular: "Azure Virtual Machine", plural: "Azure Virtual Machines" },
  ],
  [
    "az_disk",
    { singular: "Azure Managed Disk", plural: "Azure Managed Disks" },
  ],
  ["az_ip", { singular: "Public IP Address", plural: "Public IP Addresses" }],
  [
    "az_lb",
    { singular: "Azure Load Balancer", plural: "Azure Load Balancers" },
  ],
  ["az_sql", { singular: "SQL Database", plural: "SQL Databases" }],
  [
    "az_vmss",
    {
      singular: "Virtual Machine Scale Set",
      plural: "Virtual Machine Scale Sets",
    },
  ],
  ["az_aks", { singular: "AKS Cluster", plural: "AKS Clusters" }],
  ["az_node_pool", { singular: "AKS Node Pool", plural: "AKS Node Pools" }],
  ["az_aks_vm", { singular: "AKS Node", plural: "AKS Nodes" }],

  // Kubernetes --------------------------------------------------------------------
  ["kubernetes_node", { singular: "Node", plural: "Nodes" }],
  ["kubernetes_namespace", { singular: "Namespace", plural: "Namespaces" }],
  ["kubernetes_deployment", { singular: "Deployment", plural: "Deployments" }],
  [
    "kubernetes_stateful_set",
    { singular: "StatefulSet", plural: "StatefulSets" },
  ],
  ["kubernetes_daemon_set", { singular: "DaemonSet", plural: "DaemonSets" }],
  ["kubernetes_replica_set", { singular: "ReplicaSet", plural: "ReplicaSets" }],
  ["kubernetes_job", { singular: "Job", plural: "Jobs" }],
  ["kubernetes_pod", { singular: "Pod", plural: "Pods" }],
]);
