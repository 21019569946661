import { resourceExplorerInternalPossibleGroupingsFormattedDataSelector } from "./resourceExplorerInternalPossibleGroupingsFormattedDataSelector";
import { RootState } from "../../../store";
import {
  ResourceExplorerGroupingLabel,
  ResourceExplorerGroupingNullable,
} from "../../../../services/cloudchipr.api";

export const resourceExplorerInternalGroupingLabelByGroupingSelector = (
  state: RootState,
  organisationId: string,
  grouping?: ResourceExplorerGroupingNullable,
): ResourceExplorerGroupingLabel | undefined => {
  if (!grouping) {
    return;
  }
  const groupingLabels =
    resourceExplorerInternalPossibleGroupingsFormattedDataSelector(
      state,
      organisationId,
    )?.groupingLabelMap;

  return grouping ? groupingLabels?.[grouping] : undefined;
};
