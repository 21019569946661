import { FC, useCallback } from "react";

import { setResourceDetailsDates } from "../../../../../../../store/resource-details/resourceDetailsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { Dates } from "../../../../../../../services/cloudchipr.api";
import { resourceDetailsCostsDatesSelector } from "../../../../../../../store/resource-details/selectors/options/resourceDetailsCostsDatesSelector";
import { resourceExplorerMinAvailableDateSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerMinAvailableDateSelector";
import { allDateRangesByQuarterStartMonthSelector } from "../../../../../../../store/common/selectors/date-ranges/allDateRangesByQuarterStartMonthSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { DateRangePicker } from "../../../../../../common/date-range-picker/DateRangePicker";
import { getResourceDetailsResourceBillingDataThunk } from "../../../../../../../store/resource-details/thunks/data/getResourceDetailsResourceBillingDataThunk";

export const ResourceDetailsDateRange: FC = () => {
  const dispatch = useAppDispatch();

  const dates = useAppSelector(resourceDetailsCostsDatesSelector);
  const minDate = useAppSelector(resourceExplorerMinAvailableDateSelector);
  const dateRanges = useAppSelector(allDateRangesByQuarterStartMonthSelector);
  const orgQuarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const changeHandler = useCallback(
    (dates: Dates) => {
      dispatch(setResourceDetailsDates(dates));
      dispatch(getResourceDetailsResourceBillingDataThunk());
    },
    [dispatch],
  );
  if (!dates) {
    return null;
  }

  return (
    <DateRangePicker
      highlightToday
      size="xsmall"
      ranges={dateRanges}
      selectedDates={dates}
      availableMinDate={minDate}
      onDateSelect={changeHandler}
      quarterStartMonth={orgQuarterStartMonth}
    />
  );
};
