import { savingsOpportunityCurrentViewSelector } from "./savingsOpportunityCurrentViewSelector";
import { RootState } from "../../../../store";

export const savingsOpportunityCurrentViewConfigSelector = (
  state: RootState,
) => {
  const view = savingsOpportunityCurrentViewSelector(state);

  return view?.config;
};
