import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import {
  CoverageGrouping,
  CoverageGroupResponse,
  Dates,
  WidgetCoverageVisualization,
} from "../../../../../../../services/cloudchipr.api";
import { AgDataGrid } from "../../../../../common/ag-grid/AgDataGrid";
import { getCoverageTableViewColumnDefs } from "../../../adding-widget/widget-create/widget-setups/coverage/components/content/components/table/getCoverageTableViewColumnDefs";

interface CoverageWidgetTableViewProps {
  groups?: CoverageGroupResponse[];
  visualization?: WidgetCoverageVisualization;
  dates?: Dates;
  grouping: CoverageGrouping;
  quarterStartMonth?: number;
}

export const CoverageWidgetTableView: FC<CoverageWidgetTableViewProps> = ({
  groups,
  visualization,
  grouping,
  dates,
  quarterStartMonth,
}) => {
  const columnDefs = useMemo(
    () =>
      getCoverageTableViewColumnDefs(
        grouping,
        visualization,
        dates,
        quarterStartMonth,
      ),
    [grouping, visualization, dates, quarterStartMonth],
  );

  const gridData = useMemo(
    () => groups?.map((group, id) => ({ ...group, id })),
    [groups],
  );

  return (
    <Stack height="100%" p={2} pt={0}>
      <AgDataGrid
        key={grouping}
        rowHeight={40}
        headerHeight={44}
        defaultColDef={defaultColDef}
        rowData={gridData ?? []}
        columnDefs={columnDefs}
      />
    </Stack>
  );
};

const defaultColDef = { filter: "agMultiColumnFilter" };
