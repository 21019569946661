import React, { FC } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { orange } from "@mui/material/colors";
import { CommandSnippet } from "../../../../../../../../common/connectors/components/CommandSnippet";

export const KubernetesClusterAddDrawerContentThirdStep: FC = () => {
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={0.5} justifyContent="space-between">
        <Typography variant="body1" color="text.secondary">
          Paste the copied API key into the provided command, then run it in
          your terminal. This will install the c8r Helm Chart in your cluster.
        </Typography>
        <Tooltip
          title="Make sure your terminal is connected to the right cluster."
          arrow
          placement="top"
        >
          <InfoOutlinedIcon color="info" />
        </Tooltip>
      </Stack>

      <CommandSnippet
        code={`helm upgrade -i c8r-agent -n c8r-agent c8r/c8r-agent \\
--set global.bearerToken="<YOUR_API_KEY>" \\
--create-namespace`}
        parentBoxSx={{
          p: 2,
        }}
        copyButtonSize="xsmall"
      >
        <Typography
          component="pre"
          sx={{ whiteSpace: "pre-wrap", fontFamily: "monospace" }}
          variant="body2"
        >
          helm upgrade -i c8r-agent -n c8r-agent c8r/c8r-agent \ {"\n"}
          --set global.bearerToken="
          <Typography component="span" color={orange[500]}>
            &lt;YOUR_API_KEY&gt;
          </Typography>
          " \ {"\n"}
          --create-namespace
        </Typography>
      </CommandSnippet>
    </Stack>
  );
};
