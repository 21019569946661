import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { useDidMount, useWillUnmount } from "rooks";
import { Box, LinearProgress, Stack } from "@mui/material";
import { TaskManagementAppbar } from "./components/appbar/TaskManagementAppbar";
import {
  TaskManagementProvider,
  useTaskManagementContext,
} from "./components/TaskManagementProvider";
import { TasksGrid } from "./components/grid/TasksGrid";
import { TasksEmptyPage } from "./components/TasksEmptyPage";
import { FloatingToolbar } from "./components/floating-toolbar/FloatingToolbar";
import { TaskManagementViewsHierarchy } from "./components/views/TaskManagementViewsHierarchy";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { tasksListIsEmptySelector } from "../../../store/task-management/selectors/data/tasksListIsEmptySelector";
import { setTaskManagementSelectedTasks } from "../../../store/task-management/taskManagementSlice";
import { tasksListGridDataSelector } from "../../../store/task-management/selectors/data/tasksListGridDataSelector";
import { initiateTaskManagementThunk } from "../../../store/task-management/thunks/actions/initiateTaskManagementThunk";
import { taskManagementPageLoadingSelector } from "../../../store/task-management/selectors/loading/taskManagementPageLoadingSelector";

const TaskManagementComponent: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get("taskId");

  const empty = useAppSelector(tasksListIsEmptySelector);
  const loading = useAppSelector(taskManagementPageLoadingSelector);
  const allTasks = useAppSelector(tasksListGridDataSelector);

  const { gridApi } = useTaskManagementContext();

  useDidMount(() => {
    dispatch(initiateTaskManagementThunk(taskId));
  });

  useWillUnmount(() => {
    dispatch(setTaskManagementSelectedTasks([]));
  });

  if (!allTasks && !loading) {
    return null;
  }

  return (
    <Stack
      pb={2}
      flex={1}
      bgcolor="white"
      overflow="hidden"
      position="relative"
    >
      <TaskManagementViewsHierarchy gridApi={gridApi} />

      {loading && (
        <Box position="absolute" top={0} left={0} right={0}>
          <LinearProgress />
        </Box>
      )}

      <TaskManagementAppbar />

      {empty && !loading ? (
        <TasksEmptyPage />
      ) : (
        <TasksGrid tasks={allTasks ?? []} />
      )}

      <FloatingToolbar />
    </Stack>
  );
};

export const TaskManagement: FC = () => {
  return (
    <TaskManagementProvider>
      <TaskManagementComponent />
    </TaskManagementProvider>
  );
};
