import { useCallback, useMemo } from "react";
import {
  Budget,
  CostAnomalyAlert,
  OffHoursSchedule,
  Schedule,
  useGetUsersMeOrganisationsCurrentBudgetsQuery,
  useGetUsersMeOffHoursSchedulesQuery,
  UtilizationAndCoverageAlert,
} from "../../../../../services/cloudchipr.api";
import {
  UsageDataType,
  UsagesDataType,
  UsageType,
} from "../types/integration-usage";
import { useAppSelector } from "../../../../../store/hooks";
import { costAnomalyAlertsSelector } from "../../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyAlertsSelector";
import { utilizationAlertsSelector } from "../../../../../store/alerts/selectors/utilization-alerts/utilizationAlertsSelector";
import { workflowsDataSelector } from "../../../../../store/automations/selectros/workflow/list-data/workflowsDataSelector";
import { IntegrationNotification } from "../../../common/notification-selection/utils/types/types";

export function useIntegrationsUsage() {
  const { data: offHours } = useGetUsersMeOffHoursSchedulesQuery({});
  const { data: budgets } = useGetUsersMeOrganisationsCurrentBudgetsQuery();
  const scheduledJobs = useAppSelector(workflowsDataSelector);
  const costAnomalyAlerts = useAppSelector(costAnomalyAlertsSelector);
  const utilizationAlerts = useAppSelector(utilizationAlertsSelector);

  const integrationsUsage = useMemo(() => {
    if (!offHours && !scheduledJobs && !budgets) {
      return {};
    }

    const offHoursData = generateIntegrationUsageData(
      offHours || [],
      {},
      "offHours",
    );

    const schedulesData = generateIntegrationUsageData(
      scheduledJobs || [],
      offHoursData,
      "schedule",
    );

    const alertsData = generateIntegrationUsageData(
      [...(costAnomalyAlerts ?? []), ...(utilizationAlerts ?? [])],
      schedulesData,
      "alerts",
    );

    return generateIntegrationUsageData(budgets || [], alertsData, "budget");
  }, [offHours, scheduledJobs, budgets, costAnomalyAlerts, utilizationAlerts]);

  const getIntegrationsUsage = useCallback(
    (id: string): UsagesDataType => {
      const usages = (integrationsUsage[id] || []) as UsageDataType[];

      return usages.reduce(
        (acc, usage) => {
          acc[usage.type].push(usage);

          return acc;
        },
        {
          schedule: [] as UsageDataType[],
          offHours: [] as UsageDataType[],
          budget: [] as UsageDataType[],
          alerts: [] as UsageDataType[],
        },
      );
    },
    [integrationsUsage],
  );

  const getIntegrationsUsageExist = useCallback(
    (id: string): boolean => !!integrationsUsage[id]?.length,
    [integrationsUsage],
  );

  return {
    getIntegrationsUsageExist,
    getIntegrationsUsage,
  };
}

const generateIntegrationUsageData = (
  sources: (
    | Schedule
    | OffHoursSchedule
    | Budget
    | CostAnomalyAlert
    | UtilizationAndCoverageAlert
  )[],
  initialData: Record<string, Record<string, string>[]>,
  type: UsageType,
): UsagesDataType => {
  return sources.reduce((acc, source, index) => {
    const integrations = [...(source?.notifications ?? [])];

    if (isWorkflow(source)) {
      source.grace_period?.notifications?.forEach((notification) => {
        if (notification?.integration_id) {
          integrations.push(notification);
        }
      });
    }

    integrations?.forEach((integration, i) => {
      const { integration_id } = integration as IntegrationNotification;

      if (!integration_id) {
        return;
      }

      const data = {
        uniqueKey: source.id + index + i,
        name: source.name,
        id: source.id,
        type,
      };

      if (acc[integration_id]) {
        acc[integration_id].push(data);
      } else {
        acc[integration_id] = [data];
      }
    });

    return acc;
  }, initialData) as UsagesDataType;
};

const isWorkflow = (source: any): source is Schedule => {
  return !!source?.grace_period;
};
