import { TaskProperties } from "../types/types";
import { PatchUsersMeOrganisationsCurrentTasksApiArg } from "../../../../../services/cloudchipr.api";

export const taskPropertyTitle = new Map<TaskProperties, string>([
  ["statuses", "Status"],
  ["actions", "Action"],
  ["environments", "Environment"],
  ["priorities", "Priority"],
  ["owners", "Owner Domain"],
]);

export const taskUpdatePropertyName = new Map<
  TaskProperties,
  keyof PatchUsersMeOrganisationsCurrentTasksApiArg["body"]
>([
  ["statuses", "status_id"],
  ["owners", "owner_id"],
  ["actions", "action_id"],
  ["environments", "environment_id"],
  ["priorities", "priority_id"],
]);
