import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import { useLocalStorageHook } from "../../../utils/hooks/useLocalStorage.hook";

interface UseDrawerWidthArgs {
  defaultWidthPercent?: number;
  minWidthPercent?: number;
  maxWidthPercent?: number;
  key?: string;
}

export function usePanelHorizontalResize({
  defaultWidthPercent = defaultData.defaultWidthPercent,
  minWidthPercent = defaultData.minWidthPercent,
  maxWidthPercent = defaultData.maxWidthPercent,
  key,
}: UseDrawerWidthArgs) {
  const { setItem } = useLocalStorageHook();

  const widthRef = useRef(0);

  const [resizingPanelWidth, setResizingPanelWidth] =
    useState(defaultWidthPercent);

  const handleMouseDown = useCallback(
    (event: ReactMouseEvent) => {
      event.preventDefault();

      const handleMouseMove = (e: MouseEvent) => {
        const screenWidth = window.innerWidth;
        const offsetRight = ((screenWidth - e.clientX) / screenWidth) * 100;

        const localMinWidth = minWidthPercent;
        const localMaxWidth = maxWidthPercent;

        if (offsetRight < localMinWidth) return;
        if (offsetRight > localMaxWidth) return;
        widthRef.current = offsetRight;
        setResizingPanelWidth(offsetRight);
      };

      const handleMouseUp = () => {
        if (key) {
          setItem(key, widthRef.current);
        }

        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [minWidthPercent, maxWidthPercent, widthRef, key, setItem],
  );

  return {
    handleMouseDown,
    setResizingPanelWidth,
    width: `${resizingPanelWidth}%`,
  };
}

const defaultData = {
  defaultWidthPercent: 50,
  minWidthPercent: 5,
  maxWidthPercent: 80,
};
