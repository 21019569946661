import { FC, Fragment, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, LinearProgress, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useFlag } from "@unleash/proxy-client-react";
import { OffHoursGrid } from "./components/off-hours-list/OffHoursGrid";
import { PageHeader } from "../../common/PageHeader";
import { useAppAbility } from "../../../../services/permissions";
import { useAppSelector } from "../../../../store/hooks";
import { offHourDefaultProviderSelector } from "../../../../store/schedules/selectors/offHourDefaultProviderSelector";
import { useGetUsersMeOffHoursSchedulesQuery } from "../../../../services/cloudchipr.api";
import { getUserPermissionInfoText } from "../../utils/helpers/getUserPermissionInfoText";

export const OffHoursV1: FC = () => {
  const { cannot } = useAppAbility();
  const canNotCreateSchedule = cannot("create", "schedule");
  const enableNewAutomations = useFlag("EnableWorkflowAutomationSinglePage");

  const { data, isLoading: loading } = useGetUsersMeOffHoursSchedulesQuery({});

  const defaultProvider = useAppSelector(offHourDefaultProviderSelector);

  const breadcrumbs = useMemo(() => {
    return [{ title: "Off Hours" }];
  }, []);

  const userPermissionTooltipText = canNotCreateSchedule
    ? getUserPermissionInfoText("Create Off Hours")
    : "";

  return (
    <Fragment>
      <PageHeader
        breadcrumbs={breadcrumbs}
        actions={
          data?.length ? (
            <Tooltip title={userPermissionTooltipText}>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={
                    enableNewAutomations
                      ? "/automations/off-hours/create"
                      : "/schedule/off-hours/create"
                  }
                  disabled={canNotCreateSchedule || !defaultProvider}
                >
                  <AddIcon sx={{ mr: 1 }} /> Create off hours
                </Button>
              </span>
            </Tooltip>
          ) : null
        }
      />

      {loading && <LinearProgress />}

      <OffHoursGrid />
    </Fragment>
  );
};
