import { azureReservationSelectedCoverageDataSelector } from "./resource-type/azureReservationSelectedCoverageDataSelector";
import { azureSelectedReservedInstancePlanUUIdSelector } from "./reserved-instance/azureSelectedReservedInstancePlanUUIdSelector";
import { RootState } from "../../../../../store";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { GetUsersMeOrganisationsCurrentAzureReservationsDataApiArg } from "../../../../../../services/cloudchipr.api";
import { commitmentsDetailsDrawerPayloadDataSelector } from "../../../common/details-drawer/commitmentsDetailsDrawerPayloadDataSelector";

export const azureReservationsDetailsPayloadSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentAzureReservationsDataApiArg | undefined => {
  const type = commitmentsDetailsDrawerTargetTypeSelector(state);
  const commitmentPayload = commitmentsDetailsDrawerPayloadDataSelector(state);

  if (!type) {
    return;
  }

  const payloadData: GetUsersMeOrganisationsCurrentAzureReservationsDataApiArg =
    {
      type,
      ...commitmentPayload,
    };

  if (type === "commitment") {
    payloadData.commitmentId =
      azureSelectedReservedInstancePlanUUIdSelector(state);
  }

  if (type === "account") {
    const coverageData = azureReservationSelectedCoverageDataSelector(state);
    payloadData.accountId = coverageData?.account?.id;
  }

  if (type === "resource_type") {
    const coverageData = azureReservationSelectedCoverageDataSelector(state);
    payloadData.resourceType = coverageData?.resource_type?.resource_type;
    payloadData.region = coverageData?.resource_type?.region ?? undefined;
    payloadData.productName =
      coverageData?.resource_type?.product_name ?? undefined;
    payloadData.unitOfMeasure =
      coverageData?.resource_type?.unit_of_measure ?? undefined;
  }

  if (type === "region") {
    const coverageData = azureReservationSelectedCoverageDataSelector(state);
    payloadData.region = coverageData?.region?.name ?? undefined;
  }

  return payloadData;
};
