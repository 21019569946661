import { getProviderOrganisationsSelector } from "./getProviderOrganisationsSelector";
import { RootState } from "../../../store";
import { ProviderOrganisation } from "../../../../services/cloudchipr.api";

export const organizationsForCommitmentsSelector = (
  state: RootState,
): ProviderOrganisation[] | undefined => {
  return getProviderOrganisationsSelector(state)?.data?.filter(
    (item) => item.has_savings_plan,
  );
};
