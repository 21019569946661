import { FC, useCallback } from "react";
import { WidgetCoverageAggregation } from "../../../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../store/hooks";
import { coverageSetupAggregationPropertyByKeySelector } from "../../../../../../../../../../../../store/dashboards/selectors/setups/coverage/aggregation/coverageSetupAggregationPropertyByKeySelector";
import { coverageWidgetAggregationChangeThunk } from "../../../../../../../../../../../../store/dashboards/thunks/widgets/coverage-widget/setup-change/coverageWidgetAggregationChangeThunk";
import { SwitchWithTypography } from "../../../../../../../../../../../SwitchWithTypography";

export interface CoverageWidgetAggregationsSwitchProps {
  selectorKey: keyof WidgetCoverageAggregation;
  title: string;
}

export const CoverageWidgetAggregationsSwitch: FC<
  CoverageWidgetAggregationsSwitchProps
> = ({ selectorKey, title }) => {
  const dispatch = useAppDispatch();
  const checked = useAppSelector(
    coverageSetupAggregationPropertyByKeySelector(selectorKey),
  );

  const handleCheckedChange = useCallback(
    (checked: boolean) => {
      dispatch(
        coverageWidgetAggregationChangeThunk({
          [selectorKey]: checked,
        }),
      );
    },
    [dispatch, selectorKey],
  );

  return (
    <SwitchWithTypography
      checked={checked}
      onChange={handleCheckedChange}
      title={title}
    />
  );
};
