import { Box } from "@mui/material";
import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { alertsUtilizationDataAlertTypeSelector } from "../../../../../../../../store/alerts/selectors/utilization-alerts/data/alertsUtilizationDataProviderOrdIdSelector";
import { setAlertsData } from "../../../../../../../../store/alerts/alertsSlice";
import { AwsOrganizationSelect } from "../../../../../../../common/AwsOrganizationSelect";

export const AlertsUtilizationProviderOrgIdSelect: FC = () => {
  const dispatch = useAppDispatch();
  const selectedOrgId = useAppSelector(alertsUtilizationDataAlertTypeSelector);

  const handleChange = useCallback(
    (orgId: string) => {
      if (orgId !== selectedOrgId) {
        dispatch(setAlertsData({ providerOrganisationId: orgId }));
      }
    },
    [dispatch, selectedOrgId],
  );

  if (!selectedOrgId) {
    return;
  }

  return (
    <Box flex={1}>
      <AwsOrganizationSelect
        selectedOrgId={selectedOrgId}
        onChange={handleChange}
      />
    </Box>
  );
};
