import { FC, MouseEvent as ReactMouseEvent } from "react";
import { Stack } from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";

interface ResourceDetailsDragHandlerProps {
  onMouseDown(event: ReactMouseEvent): void;
}

export const ResourceDetailsDragHandler: FC<
  ResourceDetailsDragHandlerProps
> = ({ onMouseDown }) => {
  return (
    <Stack
      top="50%"
      left={0}
      width={12}
      position="absolute"
      alignItems="center"
      justifyContent="center"
      onMouseDown={onMouseDown}
      sx={{ cursor: "ew-resize" }}
    >
      <DragHandleIcon sx={{ transform: "rotate(90deg)" }} fontSize="small" />
    </Stack>
  );
};
