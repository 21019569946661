import { FC, useCallback } from "react";
import { Drawer } from "@mui/material";
import { AzureSavingsPlanDrawerContent } from "./savings-plan-drawer/AzureSavingsPlanDrawerContent";
import { AzureSavingsPlanCoverageDrawerContent } from "./coverage-drawer/AzureSavingsPlanCoverageDrawerContent";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { setCommitmentsDetailsDrawerTarget } from "../../../../../../../store/commitments/commitmentsSlice";
import { commitmentsDetailsDrawerOpenSelector } from "../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerOpenSelector";

export const AzureUtilizationAndCoverageDrawers: FC = () => {
  const dispatch = useAppDispatch();
  const targetType = useAppSelector(commitmentsDetailsDrawerTargetTypeSelector);
  const open = useAppSelector(commitmentsDetailsDrawerOpenSelector);

  const closeHandler = useCallback(() => {
    dispatch(setCommitmentsDetailsDrawerTarget(undefined));
  }, [dispatch]);

  return (
    <Drawer open={open} anchor="right" onClose={closeHandler}>
      {targetType === "commitment" && (
        <AzureSavingsPlanDrawerContent onClose={closeHandler} />
      )}

      {targetType === "account" && (
        <AzureSavingsPlanCoverageDrawerContent onClose={closeHandler} />
      )}

      {targetType === "resource_type" && (
        <AzureSavingsPlanCoverageDrawerContent onClose={closeHandler} />
      )}

      {targetType === "region" && (
        <AzureSavingsPlanCoverageDrawerContent onClose={closeHandler} />
      )}
    </Drawer>
  );
};
