import { FC, useCallback } from "react";
import { ClickAwayListener, Drawer, Stack } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { TabPanel } from "@mui/lab";
import { useEffectOnceWhen } from "rooks";
import { ResourceDetailsHeader } from "./components/header/ResourceDetailsHeader";
import { ResourceDetailsGeneralContent } from "./components/content/general-content/ResourceDetailsGeneralContent";
import { ResourceDetailsCostsContent } from "./components/content/costs-content/ResourceDetailsCostsContent";
import { ResourceDetailsMetricsTabContent } from "./components/content/metrics-content/ResourceDetailsMetricsTabContent";
import { ResourceDetailsDragHandler } from "./components/ResourceDetailsDragHandler";
import { ResourceDetailsK8SGeneralContent } from "./components/content/general-content/ResourceDetailsK8SGeneralContent";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { resetResourceDetailsSetup } from "../../../../store/resource-details/resourceDetailsSlice";
import { resourceDetailsOpenFromSelector } from "../../../../store/resource-details/selectors/options/resourceDetailsOpenFromSelector";
import { resourceDetailsActiveTabSelector } from "../../../../store/resource-details/selectors/options/resourceDetailsActiveTabSelector";
import { usePanelHorizontalResize } from "../../../utils/hooks/usePanelHorizontalResize";
import { resourceDetailsTargetResourceIsKubernetesSelector } from "../../../../store/resource-details/selectors/target-resource/resourceDetailsTargetResourceIsKubernetesSelector";

export const ResourceDetails: FC = () => {
  const { width, handleMouseDown, setResizingPanelWidth } =
    usePanelHorizontalResize(resizingDefaultParams);

  const dispatch = useAppDispatch();
  const open = useAppSelector(resourceDetailsOpenFromSelector);
  const activeTab = useAppSelector(resourceDetailsActiveTabSelector);
  const isKubernetes = useAppSelector(
    resourceDetailsTargetResourceIsKubernetesSelector,
  );

  const closeHandler = useCallback(() => {
    dispatch(resetResourceDetailsSetup());
  }, [dispatch]);

  useEffectOnceWhen(() => {
    setResizingPanelWidth(resizingDefaultParams.defaultWidthPercent);
  }, !!open);

  return (
    <ClickAwayListener onClickAway={closeHandler} mouseEvent="onMouseDown">
      <Drawer
        anchor="right"
        open={!!open}
        onClose={closeHandler}
        variant={open ? "permanent" : undefined}
        PaperProps={{ sx: { width, overflow: "hidden" } }}
      >
        <Stack
          overflow="hidden"
          height="100%"
          bgcolor="grey.100"
          position="relative"
          pb={3}
        >
          <ResourceDetailsDragHandler onMouseDown={handleMouseDown} />

          <TabContext value={activeTab}>
            <ResourceDetailsHeader onClose={closeHandler} />

            <Stack p={1.5} height="100%" overflow="auto">
              <TabPanel value="general" sx={tabPanelSx}>
                {isKubernetes ? (
                  <ResourceDetailsK8SGeneralContent />
                ) : (
                  <ResourceDetailsGeneralContent />
                )}
              </TabPanel>

              <TabPanel value="costs" sx={tabPanelSx}>
                <ResourceDetailsCostsContent />
              </TabPanel>

              <TabPanel value="metrics" sx={tabPanelSx}>
                <ResourceDetailsMetricsTabContent />
              </TabPanel>
            </Stack>
          </TabContext>
        </Stack>
      </Drawer>
    </ClickAwayListener>
  );
};

const tabPanelSx = { p: 0 };

const resizingDefaultParams = {
  defaultWidthPercent: 45,
  minWidthPercent: 30,
};
