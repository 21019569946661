import { taskManagementTargetResourcesSelector } from "./target/resources/taskManagementTargetResourcesSelector";
import { taskManagementTargetOpportunitiesSelector } from "./target/opportunities/taskManagementTargetOpportunitiesSelector";
import { RootState } from "../../../store";

export const taskEditSaveButtonDisabledSelector = (state: RootState) => {
  const resources = taskManagementTargetResourcesSelector(state);
  const opportunities = taskManagementTargetOpportunitiesSelector(state);

  return !resources?.length && !opportunities?.length;
};
