import { FC, useCallback, useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import { Tooltip } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import { FloatingActionButton } from "./FloatingActionButton";
import { useAppSelector } from "../../../../../../store/hooks";
import { selectedTasksIdsSelector } from "../../../../../../store/task-management/selectors/selection/selectedTasksIdsSelector";

export const FloatingCopyLinksButton: FC = () => {
  const [copied, setCopied] = useState(false);
  const taskIds = useAppSelector(selectedTasksIdsSelector);

  const url = taskIds
    .map((taskId) => `${window.location.origin}/task-mgmt?taskId=${taskId}`)
    .join("\n");

  const copyHandler = useCallback(() => {
    setCopied(true);

    setTimeout(() => setCopied(false), 3000);
  }, []);

  return (
    <Tooltip arrow title={copied ? "Copied!" : "Copy Links"} placement="top">
      <span>
        <CopyToClipboard onCopy={copyHandler} text={url}>
          <FloatingActionButton icon={LinkIcon} disabled={copied}>
            Copy Links
          </FloatingActionButton>
        </CopyToClipboard>
      </span>
    </Tooltip>
  );
};
