import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useHover } from "../../../../../../../../../../../utils/hooks/useHover.hook";
import { CopyIconButton } from "../../../../../../../../../../common/CopyIconButton";

interface TaskTargetTableRegionRowProps {
  region?: string;
}

export const TaskTargetTableRegionRow: FC<TaskTargetTableRegionRowProps> = ({
  region,
}) => {
  const { ref, hovered } = useHover();

  if (!region) {
    return null;
  }

  return (
    <Stack direction="row" ref={ref} spacing={1} alignItems="center">
      <Typography variant="body2">{region}</Typography>

      <CopyIconButton data={region} visible={hovered} />
    </Stack>
  );
};
