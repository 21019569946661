import { FC, memo, useEffect, useState } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { LinearProgress } from "@mui/material";
import { getKubernetesResourceTypeColumns } from "./utils/data-grid/columns-setup/getKubernetesResourceTypeColumns";
import { KubernetesLiveUsageMgmtResourcesDataGrid } from "./resource-card-data-grid/KubernetesLiveUsageMgmtResourcesDataGrid";
import { WithResourceType } from "../../../../../../live-usage-mgmt/utils/types";
import { newMetricColumnsByFT } from "../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/metrics-cells/utils/helpers/newMetricColumnsEnablenes";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { kubernetesLiveUsageMgmtResourceTypeDataResourcesSelector } from "../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtResourceTypeDataResourcesSelector";
import { kubernetesLiveUsageMgmtResourceTypeDataLoadingSelector } from "../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/loading/kubernetesLiveUsageMgmtResourceTypeDataLoadingSelector";
import { DataGridProvider } from "../../../../../../../../storybook/data-grid/DataGridProvider";

interface KubernetesLiveUsageMgmtResourceCardGridProps
  extends WithResourceType {
  fullHeight?: boolean;
}

export const KubernetesLiveUsageMgmtResourceCardGrid: FC<KubernetesLiveUsageMgmtResourceCardGridProps> =
  memo(({ resourceType, fullHeight }) => {
    const enableMetricsCharts = useFlag("EnableResourceSmallCharts");

    const columns = getKubernetesResourceTypeColumns(
      resourceType,
      newMetricColumnsByFT(enableMetricsCharts),
    );

    const resourcesData = useAppSelector((state) =>
      kubernetesLiveUsageMgmtResourceTypeDataResourcesSelector(
        state,
        resourceType,
      ),
    );
    const loading = useAppSelector((state) =>
      kubernetesLiveUsageMgmtResourceTypeDataLoadingSelector(
        state,
        resourceType,
      ),
    );

    const [gridData, setGridData] = useState(resourcesData || []);

    useEffect(() => {
      if (resourcesData) {
        setGridData(resourcesData);
      }
    }, [resourcesData]);

    if (!columns) {
      return null;
    }

    return (
      <DataGridProvider>
        {loading && <LinearProgress />}

        <KubernetesLiveUsageMgmtResourcesDataGrid
          data={gridData}
          columns={columns}
          fullHeight={fullHeight}
          resourceType={resourceType}
        />
      </DataGridProvider>
    );
  });
