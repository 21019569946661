import { RootState } from "../../../../store";
import { ResourceExplorerGranularCost } from "../../../../../services/cloudchipr.api";
import { resourceDetailsResourceBillingGranularDataSelector } from "../../billing-data/resourceDetailsResourceBillingGranularDataSelector";
import { resourceDetailsGeneralTabProcessKey } from "../../../utils/constants/processesKeys";

export const resourceDetailsGeneralTabBillingGranularDataSelector = (
  state: RootState,
): ResourceExplorerGranularCost | undefined => {
  return resourceDetailsResourceBillingGranularDataSelector(
    state,
    resourceDetailsGeneralTabProcessKey,
  );
};
