import { ColDef } from "ag-grid-community";
import { Stack, Tooltip, Typography } from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import { CustomCellRendererProps } from "ag-grid-react";
import {
  CommitmentResourceType,
  SavingsPlanCoverageGrouping,
} from "../../../../../../../../services/cloudchipr.api";
import { SavingsPlanCoverageDataWithId } from "../../../../../common/utils/types";
import { CommitmentsTableName } from "../../../../../common/components/data-grid/cells/CommitmentsTableName";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { commitmentsResourceTypeNames } from "../../../../../common/utils/constants/labels";
import { UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDate } from "../components/cells/UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDate";
import { money } from "../../../../../../../../utils/numeral/money";
import { HeaderCellWidthDateTooltip } from "../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { CommitmentsProgressBar } from "../../../../../common/components/CommitmentsProgressBar";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { savingsPlanCoverageResourceTypeValueGetter } from "../../../../utils/helpers/valueGetters";

const groupedByAccountColumns: ColDef<SavingsPlanCoverageDataWithId>[] = [
  {
    field: "account.name",
    headerName: "Account",
    suppressKeyboardEvent: () => true,
    lockVisible: true,
    suppressColumnsToolPanel: true,
    width: 250,
    minWidth: 250,

    cellRenderer: ({
      data,
    }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => {
      if (!data) {
        return;
      }

      return (
        <CommitmentsTableName
          type="account"
          id={data.id}
          name={
            <Stack alignItems="start">
              <Stack direction="row" spacing={0.5}>
                {data.account?.is_management_account && (
                  <Tooltip title="Management account" placement="top" arrow>
                    <StarsIcon color="primary" fontSize="small" />
                  </Tooltip>
                )}

                <TypographyWithTooltip
                  variant="body2"
                  title={data.account?.name}
                />
              </Stack>
              <TypographyWithTooltip
                variant="body2"
                title={data.account?.provider_account_id}
              />
            </Stack>
          }
        />
      );
    },
    valueGetter: ({ data }) =>
      `${data?.account?.name ?? ""} ${data?.account?.provider_account_id ?? ""}`,
  },
];

const groupedByResourceColumns: ColDef[] = [
  {
    field: "resource_type.resource_type",
    headerName: "Resource Type",
    suppressKeyboardEvent: () => true,
    lockVisible: true,
    suppressColumnsToolPanel: true,
    width: 230,
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => {
      if (!data) {
        return null;
      }

      const rt = data.resource_type?.resource_type as CommitmentResourceType;

      return (
        <CommitmentsTableName
          type="resource_type"
          id={data.id}
          name={
            <TypographyWithTooltip
              title={commitmentsResourceTypeNames[rt]}
              variant="body2"
              align="left"
            />
          }
        />
      );
    },
    valueGetter: savingsPlanCoverageResourceTypeValueGetter,
  },
  {
    field: "resource_type.instance_family",
    headerName: "Instance Family",
    width: 230,
    flex: 230,
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => {
      return data?.resource_type?.instance_family ?? "-";
    },
  },
  {
    field: "resource_type.region",
    headerName: "Region",
    width: 230,
    flex: 230,
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => {
      return data?.resource_type?.region ?? null;
    },
  },
];

const columnsWithoutGrouping: ColDef[] = [
  {
    field: "covered_usage",
    minWidth: 310,
    flex: 310,
    headerName: "Spend Covered by Savings Plans",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDate
          title="Spend Covered by Savings Plans"
          type="coveredUsage"
        />
      ),
    },

    cellRenderer: ({
      data,
    }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => (
      <Typography variant="body2">{money(data?.covered_usage ?? 0)}</Typography>
    ),
  },
  {
    field: "uncovered_usage",
    minWidth: 270,
    width: 300,
    flex: 270,
    headerName: "Not Covered (On-Demand)",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDate
          type="unCoveredUsage"
          title="Not Covered (On-Demand)"
        />
      ),
    },

    cellRenderer: ({
      data,
    }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => (
      <Typography variant="body2">
        {money(data?.uncovered_usage ?? 0)}
      </Typography>
    ),
  },
  {
    field: "net_savings",
    minWidth: 220,
    width: 250,
    flex: 220,
    headerName: "Net Savings",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDate
          title="Net Savings"
          type="netSavings"
        />
      ),
    },

    cellRenderer: ({
      data,
    }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => (
      <Typography variant="body2">{money(data?.net_savings ?? 0)}</Typography>
    ),
  },

  {
    field: "coverage",
    width: 250,
    minWidth: 250,
    resizable: false,
    suppressColumnsToolPanel: false,
    headerName: "Coverage",
    headerComponentParams: {
      innerHeaderComponent: () => (
        <HeaderCellWidthDateTooltip title="Coverage" />
      ),
    },
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => {
      if (!data) {
        return;
      }

      return <CommitmentsProgressBar value={data.coverage} />;
    },
  },
];

const accountGroupedColumns: ColDef[] = [
  ...groupedByAccountColumns,
  ...columnsWithoutGrouping,
  agGridEmptyColumnToFitEmptySpace,
];
const resourceGroupedColumns: ColDef[] = [
  ...groupedByResourceColumns,
  ...columnsWithoutGrouping,
  agGridEmptyColumnToFitEmptySpace,
];

export const savingsPlanCoverageColumnDefs = (
  grouping: SavingsPlanCoverageGrouping,
): ColDef[] => {
  if (grouping === "account") {
    return accountGroupedColumns;
  }

  if (grouping === "resource_type") {
    return resourceGroupedColumns;
  }

  return [];
};
