import { billingSummaryWidgetDataSelector } from "./billingSummaryWidgetDataSelector";
import { billingSummaryWidgetFiltersByIdSelector } from "../billingSummaryWidgetFiltersByIdSelector";
import { RootState } from "../../../../../store";
import { BillingSummaryDataGridType } from "../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/billing-summary/content/utils/types";
import { generateBillingSummaryDataGridData } from "../utils/helpers/generateBillingSummaryDataGridData";

export const billingSummaryWidgetViewDataSelector = (
  state: RootState,
  widgetId: string,
): BillingSummaryDataGridType[] | undefined => {
  const data = billingSummaryWidgetDataSelector(state, widgetId);

  if (!data) {
    return [];
  }

  const gridData = generateBillingSummaryDataGridData(data);

  if (!gridData) {
    return;
  }

  const filters = billingSummaryWidgetFiltersByIdSelector(state, widgetId);

  if (!filters?.length) {
    return gridData;
  }

  return gridData.filter((item) => {
    return filters?.includes(item.billingSummaryFilterType);
  });
};
