import { overviewDataSelector } from "./overviewDataSelector";
import { RootState } from "../../../store";
import { getPercentage } from "../../../../utils/numeral/getPercentage";
import { CommitmentOverview } from "../../../../services/cloudchipr.api";

export const overviewCommitmentsSelector = (
  state: RootState,
): CommitmentOverview["commitments"] | null => {
  const data = overviewDataSelector(state);

  if (!data?.commitments) {
    return null;
  }
  return [...data.commitments].sort((item1, item2) => {
    const item1percent = +getPercentage(
      item1.used_commitment_to_date ?? 0,
      item1.total_commitment_to_date ?? 0,
    ).toFixed(2);
    const item2percent = +getPercentage(
      item2.used_commitment_to_date ?? 0,
      item2.total_commitment_to_date ?? 0,
    ).toFixed(2);

    return item1percent - item2percent;
  });
};
