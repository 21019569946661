import { FC, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";
import { BillingExplorerWidgetAggregationsSwitch } from "./BillingExplorerWidgetAggregationsSwitch";
import { WidgetConfigSection } from "../../../../common/widget-configurations/WidgetConfigSection";
import AggregationsIcon from "../../../../../../../../../../../assets/images/icons/aggregations_icon.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { billingExplorerSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { getBillingExplorerWidgetAggregationsSwitchesData } from "../../utils/helpers/getBillingExplorerWidgetAggregationsSwitchesData";
import { billingExplorerWidgetSetupAverageExistsSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupAverageExistsSelector";
import { billingExplorerViewAggregationsChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerViewAggregationsChangeThunk";
import { WidgetCostBreakdownAggregation } from "../../../../../../../../../../../services/cloudchipr.api";

export const BillingExplorerWidgetAggregationsSection: FC = () => {
  const dispatch = useAppDispatch();

  const averageExists = useAppSelector(
    billingExplorerWidgetSetupAverageExistsSelector,
  );
  const frequency = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("frequency"),
  );
  const aggregations = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("aggregation"),
  );

  const switchChecked = useMemo(
    () => Object.keys(aggregations).some((key) => !!aggregations[key]),
    [aggregations],
  );
  const aggregationsSwitchesData = useMemo(
    () =>
      getBillingExplorerWidgetAggregationsSwitchesData(
        averageExists,
        frequency,
      ),
    [averageExists, frequency],
  );

  const handleSwitchChange = useCallback(() => {
    const aggregationsNewState = Object.keys(aggregations)?.reduce(
      (acc, key) => {
        acc[key as keyof WidgetCostBreakdownAggregation] = !switchChecked;
        return acc;
      },
      {} as WidgetCostBreakdownAggregation,
    );

    dispatch(billingExplorerViewAggregationsChangeThunk(aggregationsNewState));
  }, [aggregations, switchChecked, dispatch]);

  return (
    <WidgetConfigSection
      title="Aggregations"
      icon={<img src={AggregationsIcon} alt="Aggregations Icon" />}
      switchChecked={switchChecked}
      onSwitchChange={handleSwitchChange}
    >
      <Stack spacing={1.5}>
        {aggregationsSwitchesData.map(({ selectorKey, title }) => (
          <BillingExplorerWidgetAggregationsSwitch
            selectorKey={selectorKey}
            title={title}
            key={selectorKey}
          />
        ))}
      </Stack>
    </WidgetConfigSection>
  );
};
