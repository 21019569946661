import { FC, Fragment, useCallback } from "react";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import { useDidMount } from "rooks";
import { accessTypeNames } from "../../../../../common/chips/account-access-type-chip/utils/constants/accessTypeNames";
import {
  AccountId,
  ProviderAccessType,
  usePatchUsersMeProvidersByProviderAccountsAndAccountIdAccessModeMutation,
  usePostUsersMeProvidersAwsAccountsByAccountIdCfnChangeAccessModeChangeSetsMutation,
} from "../../../../../../services/cloudchipr.api";
import AwsPermissionsVideo from "../../../../../../assets/feature-videos/aws-permissions.mp4";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { getAccountsThunk } from "../../../../../../store/accounts/thunks/getAccountsThunk";
import { useAppDispatch } from "../../../../../../store/hooks";

interface AccountSwitchModelAwsDialogProps {
  providerAccessType: ProviderAccessType;
  onClose(): void;
  accountId: AccountId;
}

export const AccountSwitchModeAwsDialog: FC<
  AccountSwitchModelAwsDialogProps
> = ({ onClose, providerAccessType, accountId }) => {
  const oppositeAccessTypeTitle =
    "Switch to " + accessTypeNames[providerAccessType].long;

  const [trigger, { data, isLoading: isLinkLoading }] =
    usePostUsersMeProvidersAwsAccountsByAccountIdCfnChangeAccessModeChangeSetsMutation();

  useDidMount(() => {
    trigger({
      accountId: accountId,
    });
  });

  const dispatch = useAppDispatch();

  const [triggerChangeMode, { isLoading }] =
    usePatchUsersMeProvidersByProviderAccountsAndAccountIdAccessModeMutation();

  const handleGoToAWSClick = useCallback(async () => {
    data?.link && window.open(data.link, "_blank");

    await triggerChangeMode({
      accountId,
      provider: "aws",
      body: {
        access_type: providerAccessType,
      },
    });
    onClose();

    dispatch(getAccountsThunk());
  }, [
    accountId,
    providerAccessType,
    triggerChangeMode,
    dispatch,
    onClose,
    data?.link,
  ]);

  return (
    <Fragment>
      <DialogTitleClosable
        title={oppositeAccessTypeTitle}
        onClose={onClose}
        TitleProps={{ sx: { px: 4 } }}
        TitleTypographyProps={{
          variant: "subtitle1",
          fontWeight: "bold",
        }}
      />
      <DialogContent sx={{ p: 3 }} dividers>
        <Typography variant="body1" mb={2}>
          To change the access mode, please apply the provided Cloud Formation
          template.
        </Typography>
        <video autoPlay loop height={480} style={{ borderRadius: 16 }}>
          <track
            label="Aws missing permissions"
            kind="captions"
            src="../../../../../../assets/feature-videos/captions/aws-permissions.vtt"
          />
          <source src={AwsPermissionsVideo} type="video/mp4" />
        </video>
      </DialogContent>
      <DialogActions sx={{ px: 4, py: 3 }}>
        <Button
          variant="text"
          color="tertiary"
          onClick={onClose}
          sx={{ textTransform: "none" }}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={isLoading || isLinkLoading}
          variant="contained"
          color="primary"
          onClick={handleGoToAWSClick}
          sx={{ textTransform: "none" }}
        >
          Go To AWS
        </LoadingButton>
      </DialogActions>
    </Fragment>
  );
};
