import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../store/hooks";
import { coverageSetupVisualizationCoveredSelector } from "../../../../../../../../../../../../../store/dashboards/selectors/setups/coverage/visualization/coverageSetupVisualizationCoveredSelector";
import { coverageWidgetVisualizationChangeThunk } from "../../../../../../../../../../../../../store/dashboards/thunks/widgets/coverage-widget/setup-change/coverageWidgetVisualizationChangeThunk";
import { SwitchWithTypography } from "../../../../../../../../../../../../SwitchWithTypography";

export const CoverageWidgetVisualizationCovered: FC = () => {
  const dispatch = useAppDispatch();
  const coveredChecked = useAppSelector(
    coverageSetupVisualizationCoveredSelector,
  );

  const handleChange = useCallback(
    (checked: boolean) => {
      dispatch(
        coverageWidgetVisualizationChangeThunk({
          covered: checked,
        }),
      );
    },
    [dispatch],
  );

  return (
    <SwitchWithTypography
      checked={coveredChecked}
      onChange={handleChange}
      title="Covered"
    />
  );
};
