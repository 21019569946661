import { RootState } from "../../../store";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";
import { navigationDataByVisibilitySelector } from "../../../navigation/selectors/dashboard/navigationDataByVisibilitySelector";
import { TreeItem } from "../../../../components/app-navigation/components/sortable-tree/utils/types";

export const dashboardHierarchyItemsByVisibilitySelector = (
  state: RootState,
  visibility: NavigationItemsVisibilityType,
): TreeItem[] | undefined => {
  const hierarchy = navigationDataByVisibilitySelector(state, visibility);

  if (!hierarchy) {
    return;
  }
  return hierarchy.reduce((result, item) => {
    if (item.type === "item") {
      result.push(item);
    } else {
      item?.items?.forEach((itemsInsideFolder) => {
        result.push(itemsInsideFolder);
      });
    }
    return result;
  }, [] as TreeItem[]);
};
