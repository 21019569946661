import { FC, useMemo } from "react";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import { DueDateActivity } from "./DueDateActivity";
import { AssigneeActivity } from "./AssigneeActivity";
import { NameActivity } from "./NameActivity";
import { PropertyActivity } from "./PropertyActivity";
import { CreationActivity } from "./CreationActivity";
import { TaskActivity } from "../../../../../../../../../../services/cloudchipr.api";
import {
  isAssigneeActivityValue,
  isCommentActivityValue,
  isDueDateActivityValue,
  isNameActivityValue,
  isPropertyActivityValue,
} from "../../utils/helpers/activityTypeCheckFns";
import { Comment } from "../../../../../../../../task-management/components/grid/cell-renderers/comment-cell/components/Comment";
import { ActivityComponentProps } from "../../utils/types/types";

interface TaskActivityProps extends TaskActivity {
  taskId: string;
}

export const TaskActivityComponent: FC<TaskActivityProps> = ({
  created_at,
  created_by,
  updated_value,
  previous_value,
  id,
  taskId,
}) => {
  const defaultProps: ActivityComponentProps = useMemo(
    () => ({
      createdAt: created_at,
      createdBy: created_by,
    }),
    [created_at, created_by],
  );

  if (isCommentActivityValue(updated_value)) {
    return (
      <Comment
        {...defaultProps}
        comment={updated_value?.comment ?? ""}
        commentId={id}
        taskId={taskId}
      />
    );
  }

  const isUpdatedDueDate = isDueDateActivityValue(updated_value);
  const isPrevDueDate = isDueDateActivityValue(previous_value);
  if (isUpdatedDueDate || isPrevDueDate) {
    return (
      <DueDateActivity
        {...defaultProps}
        updatedDueDate={isUpdatedDueDate ? (updated_value?.date ?? "") : ""}
        prevDueDate={isPrevDueDate ? (previous_value?.date ?? "") : ""}
      />
    );
  }

  const isUpdatedAssignee = isAssigneeActivityValue(updated_value);
  const isPrevAssignee = isAssigneeActivityValue(previous_value);
  if (isUpdatedAssignee || isPrevAssignee) {
    return (
      <AssigneeActivity
        {...defaultProps}
        assignee={isUpdatedAssignee ? updated_value?.assignee : undefined}
        prevAssignee={isPrevAssignee ? previous_value?.assignee : undefined}
      />
    );
  }

  if (
    isNameActivityValue(updated_value) &&
    isNameActivityValue(previous_value)
  ) {
    return (
      <NameActivity
        {...defaultProps}
        updatedName={updated_value?.name}
        prevName={previous_value?.name}
      />
    );
  }

  if (!updated_value && !previous_value) {
    return <CreationActivity {...defaultProps} />;
  }

  const isUpdatedProperty = isPropertyActivityValue(updated_value);
  const isPrevProperty = isPropertyActivityValue(previous_value);
  if (isUpdatedProperty || isPrevProperty) {
    const updatedValue = isUpdatedProperty ? updated_value : undefined;
    const prevValue = isPrevProperty ? previous_value : undefined;
    const activityType = updatedValue?.type ?? prevValue?.type;

    return (
      <PropertyActivity
        {...defaultProps}
        optionAvatar={
          activityType === "priority" ? (
            <OutlinedFlagIcon sx={{ color: "white !important" }} />
          ) : undefined
        }
        updatedValue={updatedValue}
        prevValue={prevValue}
      />
    );
  }

  return null;
};
