import { FC, Fragment } from "react";
import { NestedIntegration } from "../../../../../../../../../../services/cloudchipr.api";
import { IntegrationCustomMessageDataGrid } from "../../../../../../../../common/notification-selection/components/custom-message/common-components/IntegrationCustomMessageDataGrid";
import { integrationCustomEmailActionExecuteDataGrid } from "../../../../../utils/constants/customMessages";
import { EmailIntegrationCsvInfo } from "../../../../../../../../common/notification-selection/components/custom-message/common-components/EmailIntegrationCsvInfo";
import { SlackIntegrationDataGrid } from "../../../../../../../../common/notification-selection/components/custom-message/common-components/SlackIntegrationDataGrid";

interface GracePeriodNotificationCustomMessageAfterMessageComponentProps {
  type: NestedIntegration["type"];
}

export const GracePeriodNotificationCustomMessageAfterMessageComponent: FC<
  GracePeriodNotificationCustomMessageAfterMessageComponentProps
> = ({ type }) => {
  if (type === "email") {
    return (
      <Fragment>
        <IntegrationCustomMessageDataGrid
          data={integrationCustomEmailActionExecuteDataGrid}
          label="Resource Type"
        />
        <EmailIntegrationCsvInfo showLinkInfo />
      </Fragment>
    );
  }

  return <SlackIntegrationDataGrid />;
};
