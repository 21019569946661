import { ColumnSetupType } from "../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { KubernetesJob } from "../../../../../../../../../../../../../services/cloudchipr.api";

export const kubernetesJobsColumns: ColumnSetupType<KubernetesJob>[] = [
  {
    accessorKey: "name",
    header: "Job Name",
    size: 280,
    type: "identifier",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "total_monthly_cost",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "cluster",
    header: "Cluster",
    type: "cluster",
  },
  {
    accessorKey: "age_in_seconds",
    header: "Age",
    type: "secondsToFormattedDate",
  },
  {
    accessorKey: "namespace",
    header: "Namespace",
    type: "copyWithTooltip",
  },
  {
    accessorKey: "labels",
    header: "Kubernetes Label",
    type: "tag",
    enableSorting: false,
  },
];
