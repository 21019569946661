import { FC } from "react";
import { targetMultipleOpportunitiesGridColumns } from "./targetMultipleOpportunitiesGridColumns";
import { useAppSelector } from "../../../../../../../../../../../../store/hooks";
import { taskManagementTargetOpportunitiesSelector } from "../../../../../../../../../../../../store/task-management/selectors/form/target/opportunities/taskManagementTargetOpportunitiesSelector";
import { TaskTargetDataGrid } from "../../common/TaskTargetDataGrid";

export const TargetMultipleOpportunities: FC = () => {
  const opportunities = useAppSelector(
    taskManagementTargetOpportunitiesSelector,
  );

  if (!opportunities) {
    return null;
  }

  return (
    <TaskTargetDataGrid
      rowData={opportunities}
      columnDefs={targetMultipleOpportunitiesGridColumns}
    />
  );
};
