import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";

interface BillingExplorerExplainWithAiContentNotCollapsedStateProps {
  onClick(): void;
  open: boolean;
}
export const BillingExplorerExplainWithAiContentNotCollapsedState: FC<
  BillingExplorerExplainWithAiContentNotCollapsedStateProps
> = ({ onClick, open }) => {
  return (
    <Stack
      justifyContent="space-between"
      direction="row"
      alignItems="center"
      pl={1.5}
      pr={0.5}
      py={1}
    >
      <Typography variant="subtitle2" fontWeight="bold">
        Previous Response
      </Typography>
      <Button onClick={onClick} variant="text" size="small">
        {!open ? "Show" : "Hide"}
      </Button>
    </Stack>
  );
};
