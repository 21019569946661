import { FC } from "react";
import { ShowCelebrationPopup } from "./ShowCelebrationPopup";
import { useAppSelector } from "../../store/hooks";
import { currentUserShowCelebrationPopupSelector } from "../../store/profile/currentUserShowCelebrationPopupSelector";

export const ShowCelebrationPopupWrapper: FC = () => {
  const showCelebrationPopup = useAppSelector(
    currentUserShowCelebrationPopupSelector,
  );

  if (!showCelebrationPopup) {
    return;
  }

  return <ShowCelebrationPopup />;
};
