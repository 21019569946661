import { FC, useCallback } from "react";
import {
  Box,
  Chip,
  Link,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import AddAlertOutlinedIcon from "@mui/icons-material/AddAlertOutlined";
import { Link as RouterLink } from "react-router-dom";
import { ScheduleSendOutlined } from "@mui/icons-material";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import { useMenuHook } from "../../../../../../../../../../utils/hooks/useMenu.hook";
import { UsageDataType } from "../../../../utils/types/common";

interface IntegrationsInUseCellProps {
  usages: UsageDataType[];
}

export const IntegrationsInUseCell: FC<IntegrationsInUseCellProps> = ({
  usages,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const generateSourceLink = useCallback((usage: UsageDataType) => {
    if (usage.type === "budget") {
      return `/alerts?id=${usage.id}`;
    }

    if (usage.type === "alerts") {
      return `/alerts`;
    }

    if (usage.type === "reports") {
      return `/subscriptions?subscriptionId=${usage.id}`;
    }

    const type = usage.type === "offHours" ? "off-hours" : "workflow";

    return `/automations/${type}?preview=${usage?.id}`;
  }, []);

  const firstUsage = usages?.at(0);

  if (!usages?.length || !firstUsage) {
    return <div>-</div>;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Chip
        icon={
          <Tooltip arrow placement="top" title={firstUsage.typeLabel}>
            {usageIcons[firstUsage?.type]}
          </Tooltip>
        }
        size="small"
        onClick={openMenu}
        label={
          <TypographyWithTooltip title={firstUsage?.name} variant="inherit" />
        }
        sx={{ overflow: "hidden", width: "fit-content" }}
      />

      {usages.length > 1 && (
        <Chip
          label={`+${usages.length - 1}`}
          onClick={openMenu}
          variant="outlined"
          size="small"
          sx={{ ml: 0.5 }}
        />
      )}

      {open && (
        <Popover
          open={open}
          anchorEl={anchor}
          onClose={closeMenu}
          transformOrigin={{
            horizontal: "left",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
        >
          <Box top={0} position="sticky" bgcolor="white" px={2} py={1}>
            <Typography variant="subtitle2" color="text.secondary">
              In Use ({usages.length})
            </Typography>
          </Box>
          <Stack
            py={1}
            px={2}
            spacing={1}
            minWidth={190}
            maxHeight={400}
            overflow="scroll"
          >
            {usages.map((usage) => (
              <Link
                key={usage.uniqueKey}
                color="text.primary"
                target="_blank"
                component={RouterLink}
                to={generateSourceLink(usage)}
              >
                <Stack direction="row" spacing={0.5}>
                  <Tooltip arrow placement="top" title={usage.typeLabel}>
                    {usageIcons[usage.type]}
                  </Tooltip>
                  <TypographyWithTooltip
                    title={usage.name}
                    maxWidth={200}
                    variant="body2"
                  />
                </Stack>
              </Link>
            ))}
          </Stack>
        </Popover>
      )}
    </Stack>
  );
};

const usageIcons = {
  offHours: <HistoryToggleOffOutlinedIcon sx={{ color: "text.secondary" }} />,
  schedule: <AccountTreeOutlinedIcon sx={{ color: "text.secondary" }} />,
  budget: <PaidOutlinedIcon sx={{ color: "text.secondary" }} />,
  alerts: <AddAlertOutlinedIcon sx={{ color: "text.secondary" }} />,
  reports: <ScheduleSendOutlined sx={{ color: "text.secondary" }} />,
};
