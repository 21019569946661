import { FC, useCallback } from "react";
import { Divider, List, Stack, Typography } from "@mui/material";
import {
  AccountBoxOutlined,
  BusinessCenterOutlined,
  FilterAltOutlined,
  LockOutlined,
  NotificationsNoneOutlined,
  Payment,
  PeopleAltOutlined,
  VpnKeyOutlined,
  LockPersonOutlined,
} from "@mui/icons-material";
import { useFlag } from "@unleash/proxy-client-react";
import { useNavigate } from "react-router-dom";
import { SettingsNavigationLogoutButton } from "./SettingsNavigationLogoutButton";
import { NestedNavItem } from "../../../app-nav-components/nested-nav-item/NestedNavItem";
import { useAppAbility } from "../../../../../../services/permissions";

interface SettingsNavigationListItemsProps {
  disabled: boolean;
}

export const SettingsNavigationListItems: FC<
  SettingsNavigationListItemsProps
> = ({ disabled }) => {
  const { can } = useAppAbility();
  const navigate = useNavigate();

  const enableUserRoleUsageFlow = useFlag("EnableUserRoleUsageFlow");
  const enableEmailPreferencesSettings = useFlag(
    "EnableEmailPreferencesSettings",
  );

  const handleButtonClick = useCallback(
    (type: string) => () => {
      navigate(`/settings/${type}`);
    },
    [navigate],
  );

  return (
    <Stack justifyContent="space-between" flexGrow={1}>
      <div>
        <List
          dense
          disablePadding
          subheader={
            <Typography variant="overline" pl={1} gutterBottom={false}>
              PREFERENCES
            </Typography>
          }
        >
          <NestedNavItem
            primary="Smart Tag"
            onButtonClick={handleButtonClick("smart-tag")}
            selected={location.pathname.startsWith("/settings/smart-tag")}
            Icon={FilterAltOutlined}
          />
        </List>

        <Divider sx={{ my: 1 }} />

        <List
          dense
          disablePadding
          subheader={
            <Typography variant="overline" pl={1} gutterBottom={false}>
              ORGANIZATION
            </Typography>
          }
        >
          <NestedNavItem
            primary="Users"
            Icon={PeopleAltOutlined}
            disabled={disabled}
            selected={location.pathname.startsWith("/settings/users")}
            onButtonClick={handleButtonClick("users")}
          />
          {enableUserRoleUsageFlow && (
            <NestedNavItem
              primary="Roles"
              Icon={LockPersonOutlined}
              disabled={disabled}
              selected={location.pathname.startsWith("/settings/roles")}
              onButtonClick={handleButtonClick("roles")}
            />
          )}

          <NestedNavItem
            primary="Plans & Billing"
            Icon={Payment}
            disabled={disabled}
            selected={location.pathname.startsWith("/settings/billing")}
            onButtonClick={handleButtonClick("billing")}
          />

          {can("edit", "organisation") && (
            <NestedNavItem
              primary="General"
              Icon={BusinessCenterOutlined}
              disabled={disabled}
              selected={location.pathname.startsWith("/settings/organization")}
              onButtonClick={handleButtonClick("organization")}
            />
          )}

          <NestedNavItem
            primary="API Keys"
            Icon={VpnKeyOutlined}
            selected={location.pathname.startsWith("/settings/api-keys")}
            onButtonClick={handleButtonClick("api-keys")}
            disabled={disabled}
          />
        </List>

        <Divider sx={{ my: 1 }} />

        <List
          dense
          disablePadding
          subheader={
            <Typography variant="overline" pl={1}>
              PROFILE
            </Typography>
          }
        >
          <NestedNavItem
            Icon={AccountBoxOutlined}
            primary="Profile"
            disabled={disabled}
            selected={location.pathname.startsWith("/settings/profile")}
            onButtonClick={handleButtonClick("profile")}
          />

          {enableEmailPreferencesSettings && (
            <NestedNavItem
              Icon={NotificationsNoneOutlined}
              primary="Email Notifications"
              disabled={disabled}
              selected={location.pathname.startsWith("/settings/notifications")}
              onButtonClick={handleButtonClick("notifications")}
            />
          )}

          <NestedNavItem
            Icon={LockOutlined}
            primary="Account Security"
            disabled={disabled}
            selected={location.pathname.startsWith("/settings/auth")}
            onButtonClick={handleButtonClick("auth")}
          />
        </List>
      </div>

      <SettingsNavigationLogoutButton inPopover={false} />
    </Stack>
  );
};
