import { FC } from "react";
import { Menu } from "@mui/material";
import { ResourceExplorerViewMoveAction } from "../action-items/move/ResourceExplorerViewMoveAction";
import { navigationHierarchyItemNoDndKey } from "../../../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../../../store/navigation/utils/types";
import { CopyURLAction } from "../../../../../../../common/copy-url/CopyURLAction";
import { ResourceExplorerRenameActionItem } from "../action-items/rename/ResourceExplorerRenameActionItem";
import { ResourceExplorerDeleteActionItem } from "../action-items/delete/ResourceExplorerDeleteActionItem";
import { ResourceExplorerDuplicateActionItem } from "../action-items/duplicate/ResourceExplorerDuplicateActionItem";
import { SendReportActionItem } from "../../../../../../../pages/common/send-report/SendReportActionItem";
import { ProtectAction } from "../../../../../protect/ProtectAction";
import {
  EmbeddedUser,
  ProtectionDetails,
} from "../../../../../../../../services/cloudchipr.api";

interface ResourceExplorerViewListItemActionsMenuProps {
  id: string;
  name: string;
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  folderId?: string;
  visibility: NavigationItemsVisibilityType;
  protectedBy?: EmbeddedUser;
  protectionDetails?: ProtectionDetails;
}

export const ResourceExplorerActionsMenu: FC<
  ResourceExplorerViewListItemActionsMenuProps
> = ({
  name,
  id,
  open,
  anchor,
  closeMenu,
  folderId,
  visibility,
  protectedBy,
  protectionDetails,
}) => {
  return (
    <Menu
      open={open}
      onClose={closeMenu}
      anchorEl={anchor}
      {...navigationHierarchyItemNoDndKey}
    >
      <SendReportActionItem
        sourceType="view"
        sourceId={id}
        onClose={closeMenu}
      />

      <CopyURLAction
        link={`${window.location.host}/resource-explorer/${id}`}
        onClose={closeMenu}
      />

      <ResourceExplorerRenameActionItem
        id={id}
        name={name}
        onClose={closeMenu}
        isProtected={!!protectionDetails?.is_protected}
      />

      {visibility === "visible_to_everyone" && (
        <ProtectAction
          id={id}
          source="view"
          onClose={closeMenu}
          protectedBy={protectedBy}
          protectionDetails={protectionDetails}
        />
      )}

      <ResourceExplorerViewMoveAction
        id={id}
        name={name}
        folderId={folderId}
        onClose={closeMenu}
        visibility={visibility}
        isProtected={!!protectionDetails?.is_protected}
      />

      <ResourceExplorerDuplicateActionItem
        id={id}
        name={name}
        onClose={closeMenu}
        folderId={folderId}
        visibility={visibility}
      />

      <ResourceExplorerDeleteActionItem
        id={id}
        name={name}
        onClose={closeMenu}
        isProtected={!!protectionDetails?.is_protected}
      />
    </Menu>
  );
};
