import { FC, Fragment, useCallback } from "react";
import { Menu, MenuItem, PopoverOrigin } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import { BillingExplorerFolderMoveActionMenuConfirmationDialog } from "./BillingExplorerFolderMoveActionMenuConfirmationDialog";
import { NavigationItemsVisibilityType } from "../../../../../../../../store/navigation/utils/types";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";

import { updateResourceExplorerVisibilityHierarchyThunk } from "../../../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy-visibility/updateResourceExplorerVisibilityHierarchyThunk";
import { navigationHierarchyItemNoDndKey } from "../../../../../sortable-tree/utils/constants";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";

interface BillingExplorerFolderMoveActionMenuProps {
  id: string;
  name: string;
  open: boolean;
  onClose(): void;
  anchor: HTMLElement | null;
  visibility: NavigationItemsVisibilityType;
}

export const BillingExplorerFolderMoveActionMenu: FC<
  BillingExplorerFolderMoveActionMenuProps
> = ({ id, anchor, onClose, open, visibility, name }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    open: openConfirm,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog,
  } = useDialog();

  const moveHandler = useCallback(async () => {
    await dispatch(
      updateResourceExplorerVisibilityHierarchyThunk({
        id,
        index: 0,
        visibility:
          visibility === "visible_to_everyone"
            ? "visible_only_to_me"
            : "visible_to_everyone",
        type: "folder",
      }),
    );

    if (visibility === "visible_to_everyone") {
      navigate("/resource-explorer");
    }

    onClose();
  }, [id, dispatch, onClose, visibility, navigate]);

  return (
    <Fragment>
      <Menu
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={open}
        onClose={onClose}
        anchorEl={anchor}
        slotProps={slotProps}
        {...navigationHierarchyItemNoDndKey}
      >
        <Typography variant="subtitle2" color="text.secondary" pl={1}>
          {visibility === "visible_to_everyone"
            ? "Visible only to me"
            : "Visible to everyone"}
        </Typography>
        <MenuItem onClick={openConfirmDialog}>
          <BarChartOutlinedIcon fontSize="small" />
          <TypographyWithTooltip
            title="Root"
            variant="body2"
            placement="top"
            sx={{ pl: 1 }}
          />
        </MenuItem>
      </Menu>

      {openConfirm && (
        <BillingExplorerFolderMoveActionMenuConfirmationDialog
          name={name}
          onMove={moveHandler}
          visibility={visibility}
          onClose={closeConfirmDialog}
        />
      )}
    </Fragment>
  );
};

const slotProps = {
  paper: {
    sx: {
      minWidth: 200,
      maxWidth: 400,
      maxHeight: 400,
    },
  },
};
const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
