import { FC, useCallback } from "react";
import { Drawer } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { TaskCreationDrawerHeader } from "./components/form/header/TaskCreationDrawerHeader";
import { TaskCreationDrawerContent } from "./components/form/content/TaskCreationDrawerContent";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setTaskManagementDrawerOpen } from "../../../../store/task-management/taskManagementSlice";
import { taskManagementDrawerOpenSelector } from "../../../../store/task-management/selectors/form/options/taskManagementDrawerOpenSelector";
import { taskManagementGetTasksThunk } from "../../../../store/task-management/thunks/actions/taskManagementGetTasksThunk";
import { isTaskManagementDrawerEditModeSelector } from "../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";

export const TaskCreationDrawer: FC = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(taskManagementDrawerOpenSelector);
  const editMode = useAppSelector(isTaskManagementDrawerEditModeSelector);

  const closeHandler = useCallback(() => {
    dispatch(setTaskManagementDrawerOpen(false));
  }, [dispatch]);

  useEffectOnceWhen(() => {
    dispatch(taskManagementGetTasksThunk());
  }, open);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={closeHandler}
      PaperProps={editMode ? editModeStyles : createModeStyles}
    >
      <TaskCreationDrawerHeader onClose={closeHandler} />

      <TaskCreationDrawerContent />
    </Drawer>
  );
};

const editModeStyles = { sx: { minWidth: 900, width: "65dvw" } };
const createModeStyles = { sx: { minWidth: 720, width: "50dvw" } };
