import { getUsersMeOrganisationsCurrentWidgetsBillingSummaryByWidgetIdSelector } from "./getUsersMeOrganisationsCurrentWidgetsBillingSummaryByWidgetIdSelector";
import { RootState } from "../../../../../store";

export const billingSummaryWidgetDataResponseSelector = (
  state: RootState,
  widgetId: string,
) => {
  return getUsersMeOrganisationsCurrentWidgetsBillingSummaryByWidgetIdSelector({
    widgetId,
  })(state);
};
