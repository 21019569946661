import { FC, Fragment, useCallback, useEffect } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { CreateJiraTicketDialogV2 } from "./jira/CreateJiraTicketDialogV2";
import { CreateEmailMessageDialogWrapper } from "./email/CreateEmailMessageDialogWrapper";
import { CreateSlackMessageDialogWrapper } from "./slack/CreateSlackMessageDialogWrapper";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { integrationByTypeExistSelector } from "../../../../store/integrations/selectors/integrationByTypeExistSelector";
import { IntegrationType } from "../../../pages/integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import {
  ProviderType,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { CommunicationIntegrationDrawer } from "../../../pages/integrations/components/communications/common/components/drawer/CommunicationIntegrationDrawer";
import { setActiveIntegration } from "../../../../store/integrations/integrationsSlice";

interface DialogsContainerV2Props {
  integrationType: IntegrationType;
  resourceType: ResourceType;
  onClose(): void;
  provider?: ProviderType | null;
}

export const DialogsContainerV2: FC<DialogsContainerV2Props> = ({
  integrationType,
  resourceType,
  onClose,
  provider,
}) => {
  const dispatch = useAppDispatch();
  const enableRedesignedIntegrations = useFlag("EnableRedesignedIntegrations");

  const integrationsExist = useAppSelector((state) =>
    integrationByTypeExistSelector(state, integrationType),
  );

  const closeDialog = useCallback(() => {
    dispatch(setActiveIntegration({ type: null, id: null }));
    onClose();
  }, [onClose, dispatch]);

  useEffect(() => {
    if (integrationsExist === null) {
      return;
    }

    // if integration is email, no need to open "Integration Create" dialog, we will remove this logic soon
    if (integrationsExist || integrationType === "email") {
      dispatch(setActiveIntegration({ type: null, id: null }));
    } else {
      dispatch(setActiveIntegration({ type: integrationType, id: null }));
    }
  }, [integrationsExist, integrationType, dispatch]);

  if (!integrationsExist) {
    return <CommunicationIntegrationDrawer onCancel={closeDialog} />;
  }

  if (integrationType === "jira") {
    return (
      <CreateJiraTicketDialogV2
        onClose={closeDialog}
        resourceType={resourceType}
      />
    );
  }

  if (integrationType === "slack") {
    return (
      <Fragment>
        <CreateSlackMessageDialogWrapper
          provider={provider}
          onClose={closeDialog}
          resourceType={resourceType}
        />
        {enableRedesignedIntegrations && <CommunicationIntegrationDrawer />}
      </Fragment>
    );
  }

  if (integrationType === "email") {
    return (
      <CreateEmailMessageDialogWrapper
        provider={provider}
        onClose={closeDialog}
        resourceType={resourceType}
      />
    );
  }
};
