import { getAverageDataByFrequency } from "./getAverageDataByFrequency";
import { calculateTotalCostTrend } from "../../../../../components/pages/utils/helpers/calculateTotalCostTrend";
import {
  DateDataPointNullable,
  GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiResponse,
} from "../../../../../services/cloudchipr.api";
import { WidgetCosts } from "../../types/types";

export const generateBillingExplorerWidgetCosts = (
  widgetData?: GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiResponse,
  frequency?: DateDataPointNullable,
): WidgetCosts | undefined => {
  const totalCost = +(widgetData?.total_cost ?? 0).toFixed(2);
  const previousPeriodTotalCost = +(
    widgetData?.previous_period_total_cost ?? 0
  ).toFixed(2);

  const totalCostTrend = calculateTotalCostTrend(
    totalCost,
    previousPeriodTotalCost,
  );

  if (!widgetData || !frequency) {
    return;
  }

  const average = getAverageDataByFrequency(frequency, widgetData);

  return {
    total: {
      cost: totalCost,
      costType: widgetData?.cost_type,
      trend: previousPeriodTotalCost > 0 ? totalCostTrend : null,
      dateDetails: widgetData?.total_cost_date_details,
    },

    previousPeriodTotal: {
      cost: previousPeriodTotalCost,
      dateDetails: widgetData?.previous_period_cost_date_details,
    },

    average,

    liveUsageTotal: {
      cost: +(widgetData?.live_usage_total_cost ?? 0)?.toFixed(2),
    },
    forecasted_costs: widgetData?.forecasted_costs,
  };
};
