import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IntegrationCustomMessageDataGrid } from "./IntegrationCustomMessageDataGrid";

export const SlackIntegrationDataGrid: FC = () => {
  return (
    <IntegrationCustomMessageDataGrid
      data={integrationCustomSlackActionExecuteDataGrid}
      label={
        <Stack direction="row">
          <Typography variant="caption">resources</Typography>
          <ArrowDropDownIcon
            fontSize="small"
            sx={{ color: "text.secondary", ml: -0.25 }}
          />
        </Stack>
      }
    />
  );
};

export const integrationCustomSlackActionExecuteDataGrid = [
  { header: "Account Name", value: "value" },
  { header: "Account ID", value: "value" },
  { header: "Resource Type", value: "Value" },
  { header: "Resource ID", value: "Value" },
  { header: "Region", value: "Value" },
];
