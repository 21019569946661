import { kubernetesColumnsMap } from "./resources/kubernetesColumnsMap";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";

export const getKubernetesResourceTypeColumns = (
  resourceType: ResourceType,
  disabledColumns: string[] = [],
) => {
  return (
    kubernetesColumnsMap.get(resourceType)?.filter((value) => {
      return !(
        value.accessorKey &&
        disabledColumns.includes(value.accessorKey as string)
      );
    }) ?? null
  );
};
