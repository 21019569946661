import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { Box, Popover, Stack, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useMenuHook } from "../../../../../../../../../utils/hooks/useMenu.hook";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { CommunicationIntegrationsGridRowType } from "../../../../common/utils/types/common";

export const IntegrationsEmailCellRenderer: FC<
  ICellRendererParams<CommunicationIntegrationsGridRowType>
> = ({ data }) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const emails = data?.metadata?.emails;

  if (!emails?.length) {
    return;
  }

  const firstEmail = emails.at(0);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Chip
        size="small"
        onClick={openMenu}
        label={<TypographyWithTooltip title={firstEmail} variant="inherit" />}
        sx={{ overflow: "hidden", width: "fit-content" }}
      />

      {emails.length > 1 && (
        <Chip
          label={`+${emails.length - 1}`}
          onClick={openMenu}
          variant="outlined"
          size="small"
          sx={{ ml: 0.5 }}
        />
      )}

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeMenu}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <Box top={0} position="sticky" bgcolor="white" px={2} py={1}>
          <Typography variant="subtitle2" color="text.secondary">
            Emails ({emails.length})
          </Typography>
        </Box>
        <Stack
          py={1}
          px={2}
          spacing={1}
          minWidth={190}
          maxHeight={400}
          overflow="scroll"
        >
          {emails.map((email) => (
            <Chip
              key={email}
              label={
                <TypographyWithTooltip
                  title={email}
                  maxWidth={200}
                  variant="body2"
                />
              }
              variant="outlined"
              size="small"
            />
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
};
