export const taskCreationFixedCacheKey =
  "postUsersMeOrganisationsCurrentTasks-fixed-cache-key";

export const taskUpdateFixedCacheKey =
  "patchUsersMeOrganisationsCurrentTasks-fixed-cache-key";

export const taskDeletionFixedCacheKey =
  "putUsersMeOrganisationsCurrentTasks-fixed-cache-key";

// properties
export const updateTaskActionPropertiesFixedCacheKey =
  "putUsersMeOrganisationsCurrentTasksPropertiesActions-fixed-cache-key";
export const updateTaskEnvironmentPropertiesFixedCacheKey =
  "putUsersMeOrganisationsCurrentTasksPropertiesEnvironments-fixed-cache-key";
export const updateTaskPriorityPropertiesFixedCacheKey =
  "putUsersMeOrganisationsCurrentTasksPropertiesPriorities-fixed-cache-key";
export const updateTaskStatusPropertiesFixedCacheKey =
  "putUsersMeOrganisationsCurrentTasksPropertiesStatuses-fixed-cache-key";

// views
export const createTaskViewFixedCacheKey =
  "postUsersMeOrganisationsTaskViews-fixed-cache-key";
export const updateTaskViewFixedCacheKey =
  "patchUsersMeOrganisationsCurrentTaskViewsByViewId-fixed-cache-key";
export const deleteTaskViewFixedCacheKey =
  "deleteUsersMeOrganisationsCurrentTaskViewsByViewId-fixed-cache-key";

//comments
export const createTaskCommentFixedCacheKey =
  "postUsersMeOrganisationsCurrentTasksByTaskIdComments-fixed-cache-key";
export const updateTaskCommentFixedCacheKey =
  "patchUsersMeOrganisationsCurrentTasksByTaskIdCommentsAndCommentId-fixed-cache-key";
