import { createAsyncThunk } from "@reduxjs/toolkit";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";

import { textWidgetSetupSelector } from "../../../../selectors/setups/text/textWidgetSetupSelector";

export const textWidgetSetupTextChangeThunk = createAsyncThunk(
  "dashboard/textWidgetSetupTextChange",
  (text: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = textWidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }

    dispatch(
      setWidgetSetup({
        ...existingSetup,
        text,
      }),
    );
  },
);
