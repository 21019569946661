import { FC } from "react";
import { Divider, Stack, StackProps } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { pageHeaderHeight } from "../../../common/PageHeader";
import { KubernetesLiveUsageMgmtSelectedClusterNavigation } from "../navigation/selected-cluster/KubernetesLiveUsageMgmtSelectedClusterNavigation";
import { useAppSelector } from "../../../../../store/hooks";
import { KubernetesLiveUsageFilterTemplatesSwitcher } from "../filter-templates/KubernetesLiveUsageFilterTemplatesSwitcher";
import { kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector";
import { kubernetesLiveUsageMgmtAppliedAllFiltersCountSelector } from "../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtAppliedAllFiltersCountSelector";
import { resourceTypeSearchParam } from "../../../live-usage-mgmt/utils/constants/searchParams";
import { LiveUsageNavigationResourceType } from "../../../live-usage-mgmt/components/navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { kubernetesLiveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector } from "../../../../../store/kubernetes-live-usage-mgmt/selectors/kubernetesLiveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector";
import { LiveResourceFilters } from "../../../../common/resource-filters/LiveResourceFilters";
import { KubernetesLiveResourceFiltersContent } from "../resource-filters/KubernetesLiveResourceFiltersContent";

export const KubernetesLiveUsageHeader: FC = () => {
  const [searchParams] = useSearchParams();
  const searchParamsResourceType = searchParams.get(
    resourceTypeSearchParam,
  ) as LiveUsageNavigationResourceType;

  const filterTemplatesEnabled = useAppSelector(
    kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector,
  );

  const filtersCount = useAppSelector(
    kubernetesLiveUsageMgmtAppliedAllFiltersCountSelector,
  );

  const resourceTypeFiltersCountMap = useAppSelector(
    kubernetesLiveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector,
  );

  return (
    <Stack
      zIndex="appBar"
      bgcolor="white"
      direction="row"
      spacing={0.5}
      pr={0.5}
      borderBottom={1}
      alignItems="center"
      borderColor="grey.300"
      height={pageHeaderHeight}
    >
      <KubernetesLiveUsageMgmtSelectedClusterNavigation />

      <Stack
        direction="row"
        flex={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          border={1}
          pr={1}
          direction="row"
          borderRadius={2}
          alignItems="center"
          borderColor="grey.100"
        >
          <KubernetesLiveUsageFilterTemplatesSwitcher />

          {divider}

          <Stack
            {...flexProps}
            divider={divider}
            overflow="hidden"
            spacing={1}
            sx={{
              pointerEvents: filterTemplatesEnabled ? "inherit" : "none",
              opacity: filterTemplatesEnabled ? 1 : 0.5,
            }}
            pl={1}
            py={0.5}
          >
            <LiveResourceFilters
              provider="kubernetes"
              resourceType={searchParamsResourceType}
              filtersCount={filtersCount}
              resourceTypeFiltersCountMap={resourceTypeFiltersCountMap}
              FiltersContent={KubernetesLiveResourceFiltersContent}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const divider = (
  <Divider orientation="vertical" flexItem sx={{ borderColor: "grey.100" }} />
);

const flexProps: StackProps = {
  direction: "row",
  alignItems: "center",
  justifyContent: "space-between",
};
