import { FC, useMemo } from "react";
import { TextField, Tooltip } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { generateDateMetaDataByDates } from "../utils/helpers/generateDateMetaDataByDates";
import { Dates } from "../../../../services/cloudchipr.api";
import { UseMenuHookOpenMenu } from "../../../../utils/hooks/useMenu.hook";
import { useHover } from "../../../../utils/hooks/useHover.hook";
import { PreDefinedRanges } from "../utils/constants/types";

export type InputSize = "xsmall" | "small";

interface DateRangePickerInputProps {
  open: boolean;
  selectedDates: Dates;
  onClick: UseMenuHookOpenMenu;
  fullWidth?: boolean;
  ranges: PreDefinedRanges;
  size?: InputSize;
}

export const DateRangePickerInput: FC<DateRangePickerInputProps> = ({
  open,
  onClick,
  selectedDates,
  fullWidth,
  ranges,
  size = "small",
}) => {
  const { ref, hovered } = useHover();

  const { tooltip, label } = useMemo(() => {
    return generateDateMetaDataByDates({ dates: selectedDates, ranges });
  }, [selectedDates, ranges]);

  const inputSize = size === "xsmall" ? 225 : 270;

  return (
    <Tooltip arrow title={tooltip} placement="top" open={!!tooltip && hovered}>
      <TextField
        ref={ref}
        size={size}
        value={label ?? ""}
        onClick={onClick}
        fullWidth={fullWidth}
        sx={{ bgcolor: "white", width: !fullWidth ? inputSize : undefined }}
        InputProps={{
          readOnly: true,
          startAdornment,
          sx: { pr: 1, cursor: "pointer" },
          inputProps: { sx: { cursor: "pointer", pl: 1 } },
          endAdornment: open ? openIcon : closedIcon,
        }}
      />
    </Tooltip>
  );
};

const openIcon = <ArrowDropUpIcon color="action" />;
const closedIcon = <ArrowDropDownIcon color="action" />;
const startAdornment = <CalendarTodayIcon color="action" fontSize="small" />;
