import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerChartLabelsSelector } from "./resourceExplorerChartLabelsSelector";
import { resourceExplorerWidgetDataSelector } from "../resource-explorer-widget/resourceExplorerWidgetDataSelector";
import { HighchartsChartDataType } from "../../../../../storybook/c8r-highcharts/common/utils/types/common";
import { billingExplorerChartDataGenerator } from "../../../../../components/pages/common/billing-explorer/utils/billingExplorerChartDataGenerator";

export const resourceExplorerChartDataSelectorV2 = createDraftSafeSelector(
  [resourceExplorerWidgetDataSelector, resourceExplorerChartLabelsSelector],
  (widgetData, labels): HighchartsChartDataType | null => {
    const data = widgetData?.data;

    if (!data || !labels) {
      return null;
    }

    return billingExplorerChartDataGenerator(data, labels);
  },
);
