import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import NoRecommendations from "../../../../../../../assets/images/no-recommendations.png";
import NoFilteredRecommendations from "../../../../../../../assets/images/no-filtered-recommendations.png";

interface ReservationsRecommendationsDataGridFooterProps {
  option: "noFilteredData" | "emptyData";
}

export const ReservationsRecommendationsDataGridFooter: FC<
  ReservationsRecommendationsDataGridFooterProps
> = ({ option }) => {
  return (
    <Stack sx={styles} spacing={2}>
      <img
        src={
          option === "noFilteredData"
            ? NoFilteredRecommendations
            : NoRecommendations
        }
        alt="No Recommendations"
        width={option === "noFilteredData" ? 200 : 170}
      />

      {option === "noFilteredData" ? (
        <Stack spacing={0.5} justifyContent="center" alignItems="center">
          <Typography variant="body1">No recommendations</Typography>
          <Typography variant="body2" color="text.secondary">
            Adjust your filters.
          </Typography>
        </Stack>
      ) : (
        <Typography variant="body2" color="text.secondary">
          Currently, you have no Reservation recommendations.
        </Typography>
      )}
    </Stack>
  );
};

const styles = {
  py: 3,
  justifyContent: "center",
  alignItems: "center",
};
