import { FC } from "react";
import { jiraIntegrationGridColumns } from "./jiraIntegrationGridColumns";
import { useGetUsersMeIntegrationsQuery } from "../../../../../../../../services/cloudchipr.api";
import { CommunicationIntegrationsDataGrid } from "../../../common/components/grid/CommunicationIntegrationsDataGrid";
import { IntegrationsEmptyState } from "../../../../common/components/IntegrationsEmptyState";
import { integrationDetails } from "../../../common/utils/constants/integrationDetails";
import { JiraIntegrationConnectButton } from "../JiraIntegrationConnectButton";

export const JiraIntegrationGrid: FC = () => {
  const { data, isLoading } = useGetUsersMeIntegrationsQuery({
    type: "jira",
  });

  if (!isLoading && !data?.length) {
    return <EmptyState />;
  }

  return (
    <CommunicationIntegrationsDataGrid
      columnDefs={jiraIntegrationGridColumns}
      data={data}
      loading={isLoading}
      uniqueKey="jiraIntegrationsDataGrid"
      actionButton={<JiraIntegrationConnectButton />}
    />
  );
};

const details = integrationDetails["jira"];

const EmptyState: FC = () => (
  <IntegrationsEmptyState
    title={details.noDataDescription}
    description={details.description}
  >
    <JiraIntegrationConnectButton withStartIcon variant="contained" />
  </IntegrationsEmptyState>
);
