import { FC, useCallback, useEffect } from "react";
import { MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useParams } from "react-router-dom";
import { useWillUnmount } from "rooks";
import { ArrowUpward } from "@mui/icons-material";
import { AccountsPageTabs } from "../../../../utils/types/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { getAccountsOrderingSettingsKey } from "../../../../../live-usage-mgmt/utils/hooks/useAccountsDnD";
import { useGetUsersMeCurrentPreferenceByKeyQuery } from "../../../../../../../services/cloudchipr.api";
import { accountsSortBySelector } from "../../../../../../../store/accounts/selectors/slice-data/accountsSortBySelector";
import { accountsSortByLoadingSelector } from "../../../../../../../store/accounts/selectors/loadings/accountsSortByLoadingSelector";
import { useAccountsSortBy } from "../../../../../live-usage-mgmt/utils/hooks/useAccountsSortBy.hook";
import {
  accountsDefaultSort,
  AccountSortByFields,
  setAccountSortBy,
} from "../../../../../../../store/accounts/accountsSlice";
import { kubernetesClustersSelector } from "../../../../../../../store/accounts/selectors/kubernetes/kubernetesClustersSelector";

interface KubernetesClusterSortByProps {
  tabValue: AccountsPageTabs;
}

export const KubernetesClusterSortBy: FC<KubernetesClusterSortByProps> = ({
  tabValue,
}) => {
  const { orgId } = useParams();
  const dispatch = useAppDispatch();

  const key = getAccountsOrderingSettingsKey("kubernetes", tabValue, orgId);
  const { data: accountsOrdering, isLoading: orderingLoading } =
    useGetUsersMeCurrentPreferenceByKeyQuery({
      key,
    });

  const sortBy = useAppSelector(accountsSortBySelector);
  const sortByLoading = useAppSelector((state) =>
    accountsSortByLoadingSelector(state, "kubernetes", tabValue),
  );

  const accounts = useAppSelector(kubernetesClustersSelector);
  const { accountSortBy, saveAccountSortBy } = useAccountsSortBy(
    "kubernetes",
    tabValue,
  );

  const accountsSortChangeHandler = useCallback(
    (event: SelectChangeEvent<AccountSortByFields>) => {
      const sort = event.target.value as AccountSortByFields;

      if (!sort) {
        return;
      }

      dispatch(setAccountSortBy(sort));
      saveAccountSortBy(sort);
    },
    [dispatch, saveAccountSortBy],
  );

  useEffect(() => {
    if (sortBy || sortByLoading || orderingLoading) {
      return;
    }

    if (!!accountsOrdering && !accountSortBy) {
      dispatch(setAccountSortBy("custom"));
      return;
    }

    if (accountSortBy) {
      dispatch(setAccountSortBy(accountSortBy));
      return;
    }

    dispatch(setAccountSortBy(accountsDefaultSort));
  }, [
    dispatch,
    sortBy,
    sortByLoading,
    orderingLoading,
    accountsOrdering,
    accountSortBy,
  ]);

  useWillUnmount(() => {
    dispatch(setAccountSortBy(null));
  });

  if (!accounts || !sortBy || accounts?.length <= 1) {
    return null;
  }

  return (
    <Select
      size="small"
      startAdornment={
        <ArrowUpward fontSize="small" color="action" sx={{ mr: 1 }} />
      }
      sx={{ minWidth: 210, bgcolor: "white" }}
      value={sortBy}
      onChange={accountsSortChangeHandler}
    >
      {!!accountsOrdering && <MenuItem value="custom">User Defined</MenuItem>}

      <MenuItem value="idle_monthly_cost">Idle $</MenuItem>
      <MenuItem value="reserved_monthly_cost">Reserved $</MenuItem>
      <MenuItem value="total_monthly_cost">Total $</MenuItem>
      <MenuItem value="provider">Cloud Provider </MenuItem>
      <MenuItem value="name">Cluster Name</MenuItem>
    </Select>
  );
};
