import { resourceExplorerChartKeyGenerator } from "./resourceExplorerChartKeyGenerator";
import { ResourceExplorerChartData } from "../../../../../services/cloudchipr.api";
import { HighchartsChartDataType } from "../../../../../storybook/c8r-highcharts/common/utils/types/common";

export const billingExplorerChartDataGenerator = (
  data: ResourceExplorerChartData[],
  chartLabels: Record<string, string>,
): HighchartsChartDataType => {
  const chartData: HighchartsChartDataType = {
    series: [],
    categories: Object.keys(chartLabels),
  };

  const possibleLabels = new Set<string>();
  data.forEach((item) => {
    item.items?.forEach((item) => {
      const key = resourceExplorerChartKeyGenerator(item.field, item.label);

      if (!key) {
        return;
      }

      possibleLabels.add(key);
    });
  });

  const reduced = data.reduce(
    (acc, item) => {
      // find and set the cost for labels that exist in data
      const existingLabels = item.items?.map((item) => {
        const key = resourceExplorerChartKeyGenerator(item.field, item.label);

        if (!key) {
          return;
        }
        const cost = item.cost ?? null;

        if (acc[key]) {
          acc[key].push(cost);
        } else {
          acc[key] = [cost];
        }

        return key;
      });

      // find and set the cost for labels that doesn't exist in data
      possibleLabels.forEach((label) => {
        if (existingLabels?.includes(label)) {
          return;
        }

        if (acc[label]) {
          acc[label].push(null);
        } else {
          acc[label] = [null];
        }
      });

      return acc;
    },
    {} as Record<string, (number | null)[]>,
  );

  Object.entries(reduced).forEach(([key, values]) => {
    chartData.series.push({
      name: key,
      data: values,
    });
  });

  // sort series by total cost
  chartData.series = chartData.series.toSorted((current, next) => {
    const currentTotal =
      current.data.reduce((sum, val) => (sum ?? 0) + (val ?? 0), 0) ?? 0;
    const nextTotal =
      next.data.reduce((sum, val) => (sum ?? 0) + (val ?? 0), 0) ?? 0;

    return nextTotal - currentTotal;
  });

  return chartData;
};
