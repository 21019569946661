import { FC, Fragment, useCallback, useMemo, useRef } from "react";
import { Box, CircularProgress, Stack, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useDebounce } from "rooks";
import { useFlag } from "@unleash/proxy-client-react";
import { ResourceExplorerCardDataGridToolbar } from "./components/ResourceExplorerCardDataGridToolbar";
import { ResourceExplorerTotalViewFooter } from "./components/footer/ResourceExplorerTotalViewFooter";
import { ResourceExplorerGranularViewDataGrid } from "./components/data-grid/ResourceExplorerGranularViewDataGrid";
import { ResourceExplorerTotalViewDataGrid } from "./components/data-grid/ResourceExplorerTotalViewDataGrid";
import { ResourceExplorerGranularViewFooter } from "./components/footer/ResourceExplorerGranularViewFooter";
import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceExplorerWidgetDataLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/loading/resourceExplorerWidgetDataLoadingSelector";
import { useMultiTypeChartContext } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { resourceExplorerChartDataOthersKey } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/resourceExplorerChartDataSelector";
import { ToolbarConfig } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { resourceExplorerGranularDataLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/loading/resourceExplorerGranularDataLoadingSelector";
import { resourceExplorerDataGridViewSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerDataGridViewSelector";
import { resourceExplorerDataGridAggregatedDataSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridAggregatedDataSelector";
import { chartSeriesHighlightFunctions } from "../../../../../../../storybook/c8r-highcharts/common/utils/helpers/chartSeriesHighlightFunctions";
import { useHighchartsContext } from "../../../../../../../storybook/c8r-highcharts/common/components/HighchartsProvider";
import { resourceExplorerChartKeyGenerator } from "../../../../../common/billing-explorer/utils/resourceExplorerChartKeyGenerator";

const tableToolbarHeight = 56;
const tableMinHeight = 500;

export const ResourceExplorerTable: FC = () => {
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const wrapperHeight = wrapperRef.current?.clientHeight ?? 0;
  const minHeightApplied = wrapperHeight <= tableMinHeight;

  const { hover, colors } = useMultiTypeChartContext();
  const { chartApi } = useHighchartsContext();
  const enableHighcharts = useFlag("EnableHighchartsOnBillingExplorer");

  const dataGridView = useAppSelector(resourceExplorerDataGridViewSelector);
  const aggregatedData = useAppSelector(
    resourceExplorerDataGridAggregatedDataSelector,
  );
  const totalViewLoading = useAppSelector(
    resourceExplorerWidgetDataLoadingSelector,
  );
  const granularViewLoading = useAppSelector(
    resourceExplorerGranularDataLoadingSelector,
  );
  const loading = totalViewLoading || granularViewLoading;

  const tableStyles = useMemo(() => {
    return {
      ...styles,
      tableContainer: {
        ...styles.tableContainer,
        overflow: "auto",
        height: "100%",
        minHeight: tableMinHeight,
        maxHeight: wrapperHeight - tableToolbarHeight,
        borderBottomLeftRadius: !aggregatedData ? 8 : 0,
        borderBottomRightRadius: !aggregatedData ? 8 : 0,
      },
    };
  }, [wrapperHeight, aggregatedData]);

  const toolbar: ToolbarConfig = useMemo(() => {
    return {
      renderToolbar: (props) => {
        return <ResourceExplorerCardDataGridToolbar {...props} />;
      },
    };
  }, []);

  const setHighlighted = useDebounce(hover?.setKey, 400);

  const generateRowProps = useCallback(
    (row: any) => {
      const key = resourceExplorerChartKeyGenerator(
        row.original.field,
        row.original.label,
      );

      const keyInChart = colors[key] ? key : resourceExplorerChartDataOthersKey;

      return {
        onMouseEnter: () => setHighlighted(keyInChart),
        onMouseLeave: () => setHighlighted(""),
      };
    },
    [setHighlighted, colors],
  );

  const generateRowPropsV2 = useCallback(
    (row: any) => {
      const key = resourceExplorerChartKeyGenerator(
        row.original.field,
        row.original.label,
      );

      const keyInChart = chartApi?.series?.find((s) => s?.name === key)
        ? key
        : resourceExplorerChartDataOthersKey;

      return {
        onMouseEnter: () =>
          chartSeriesHighlightFunctions.onHoverIn(chartApi, keyInChart),
        onMouseLeave: () => chartSeriesHighlightFunctions.onHoverOut(chartApi),
      };
    },
    [chartApi],
  );

  return (
    <Box
      ref={wrapperRef}
      flex={1}
      zIndex={1}
      overflow={minHeightApplied ? "unset" : "hidden"}
      position="relative"
      minHeight={tableMinHeight}
    >
      {loading && (
        <Stack
          width="100%"
          height="100%"
          zIndex="appBar"
          fontWeight="bold"
          position="absolute"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(255, 255, 255, 0.5)"
        >
          <CircularProgress size={24} color="info" />
        </Stack>
      )}

      {dataGridView === "total" && (
        <Fragment>
          <ResourceExplorerTotalViewDataGrid
            styles={tableStyles}
            toolbar={toolbar}
            getRowProps={
              enableHighcharts ? generateRowPropsV2 : generateRowProps
            }
          />
          <ResourceExplorerTotalViewFooter />
        </Fragment>
      )}

      {dataGridView === "granular" && (
        <Fragment>
          <ResourceExplorerGranularViewDataGrid
            styles={tableStyles}
            toolbar={toolbar}
            getRowProps={
              enableHighcharts ? generateRowPropsV2 : generateRowProps
            }
          />
          <ResourceExplorerGranularViewFooter />
        </Fragment>
      )}
    </Box>
  );
};

const styles = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& td": {
      py: 0.5,
      pl: 2,
      borderBottom: `1px solid ${grey[100]}`,
    },
    "& tr:hover td": {
      bgcolor: grey[100],
    },
  },
  tableRow: {
    background: "white",
  },
  tableHeaderRow: {
    top: 0,
    "& th": { p: 2, verticalAlign: "inherit" },
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
    bgcolor: "grey.50",
  },

  tableContainer: {
    borderTop: 1,
    borderBottom: 1,
    borderColor: grey[300],
  },
};
