import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetType } from "../../../../../services/cloudchipr.api";
import { editBillingSummaryWidgetThunk } from "../billing-summary/actions/editBillingSummaryWidgetThunk";
import { editCostAnomaliesWidgetThunk } from "../cost-anomalies/actions/editCostAnomaliesWidgetThunk";
import { editCommitmentUtilizationChangesWidgetThunk } from "../commitment-utilization/actions/editCommitmentUtilizationChangesWidgetThunk";
import { editBillingExplorerWidgetThunk } from "../billing-explorer-widget/actions/editBillingExplorerWidgetThunk";
import { editTextChangesWidgetThunk } from "../text-widget/actions/editTextChangesWidgetThunk";
import { editCoverageChangesWidgetThunk } from "../coverage-widget/actions/editCoverageChangesWidgetThunk";

interface EditWidgetThunkArgs {
  widgetId: string;
  widgetType: WidgetType;
  doNotSetWidgetType?: boolean;
}

export const editWidgetThunk = createAsyncThunk(
  "dashboards/editWidget",
  (
    { widgetId, widgetType, doNotSetWidgetType }: EditWidgetThunkArgs,
    { dispatch },
  ) => {
    if (widgetType === "cost_breakdown") {
      dispatch(
        editBillingExplorerWidgetThunk({ widgetId, doNotSetWidgetType }),
      );
    }
    if (widgetType === "cost_and_usage_summary") {
      dispatch(editBillingSummaryWidgetThunk({ widgetId, doNotSetWidgetType }));
    }

    if (widgetType === "largest_cost_changes") {
      dispatch(editCostAnomaliesWidgetThunk({ widgetId, doNotSetWidgetType }));
    }

    if (widgetType === "commitments_utilization") {
      dispatch(
        editCommitmentUtilizationChangesWidgetThunk({
          widgetId,
          doNotSetWidgetType,
        }),
      );
    }

    if (widgetType === "coverage") {
      dispatch(
        editCoverageChangesWidgetThunk({
          widgetId,
          doNotSetWidgetType,
        }),
      );
    }

    if (widgetType === "text") {
      dispatch(editTextChangesWidgetThunk(widgetId));
    }
  },
);
