import { FC, Fragment, memo, useMemo } from "react";
import { FormikHandlers } from "formik";
import { useDidMount } from "rooks";
import {
  AutocompleteFilterBody,
  AutocompleteFilterBodyProps,
} from "../AutocompleteFilterBody";
import {
  FilterItem,
  Operator,
  usePostUsersMeRdsSnapshotSourceNamesMutation,
} from "../../../../../../../../services/cloudchipr.api";
import { FilterItemOperatorType } from "../../../FilterItemOperatorType";

interface SourceNameFilterProps extends AutocompleteFilterBodyProps {
  accountIds?: string[];
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
}

export const SourceNameFilter: FC<SourceNameFilterProps> = memo(
  ({ accountIds, operators, filter, onChange, ...props }) => {
    const [trigger, { data, isLoading }] =
      usePostUsersMeRdsSnapshotSourceNamesMutation();

    const renderOptions = useMemo(() => {
      return data?.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
    }, [data]);
    useDidMount(() => {
      if (!accountIds) {
        return;
      }
      trigger({
        body: { account_ids: accountIds },
      });
    });

    return (
      <Fragment>
        <FilterItemOperatorType
          onChange={onChange}
          options={operators}
          value={filter.operator || "in"}
        />

        <AutocompleteFilterBody
          {...props}
          options={renderOptions ?? []}
          isLoading={isLoading}
          label="DB Instance or Cluster"
          selectedValue={Array.isArray(filter?.value) ? filter.value : []}
        />
      </Fragment>
    );
  },
);
