import { FC } from "react";
import {
  IconButton,
  Link,
  Stack,
  Tooltip,
  TypographyProps,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import { PlayCircleOutlined } from "@mui/icons-material";
import {
  OpportunityTarget,
  ResourceTarget,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../../../../../../../utils/helpers/providers/getProviderName";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import { useHover } from "../../../../../../../../../../../utils/hooks/useHover.hook";
import { CopyIconButton } from "../../../../../../../../../../common/CopyIconButton";
import { generateLiveResourcesPathFromTarget } from "../../../../../../../utils/helpers/generateLiveResourcesPathFromTarget";

interface TaskTargetTableIdentifierRowProps {
  target: ResourceTarget | OpportunityTarget;
  typographyProps?: TypographyProps;
  cloudLink?: boolean | "hover";
  copyButton?: boolean | "hover";
  liveUsageLink?: boolean | "hover";
}

export const TaskTargetTableIdentifierRow: FC<
  TaskTargetTableIdentifierRowProps
> = ({ target, cloudLink, liveUsageLink, typographyProps, copyButton }) => {
  const { ref, hovered } = useHover();
  const hoverNeeded =
    cloudLink === "hover" ||
    liveUsageLink === "hover" ||
    copyButton === "hover";

  const showCloudLink = cloudLink === "hover" ? hovered : cloudLink;
  const showLiveUsageLink = liveUsageLink === "hover" ? hovered : liveUsageLink;
  const showCopyButton = copyButton === "hover" ? hovered : copyButton;

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      ref={hoverNeeded ? ref : undefined}
    >
      <TypographyWithTooltip
        title={target.provider_identifier}
        variant="body2"
        fontWeight="medium"
        {...typographyProps}
      />

      {showCloudLink &&
        "link" in target &&
        target.link &&
        target.state === "live" && (
          <Tooltip
            arrow
            placement="top"
            title={`View in ${getProviderName(target.account.provider, { title: true })}`}
          >
            <IconButton
              size="small"
              target="_blank"
              to={target.link}
              component={RouterLink}
            >
              <CloudOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}

      {showLiveUsageLink && (
        <Tooltip title="View in Live Resources." arrow placement="top">
          <IconButton
            size="small"
            component={Link}
            href={generateLiveResourcesPathFromTarget(target)}
            target="_blank"
          >
            <PlayCircleOutlined fontSize="small" color="action" />
          </IconButton>
        </Tooltip>
      )}

      <CopyIconButton
        data={target.provider_identifier}
        visible={showCopyButton}
      />
    </Stack>
  );
};
