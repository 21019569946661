import { FC } from "react";
import { CoverageGroupResponse } from "../../../../../../../services/cloudchipr.api";
import { WidgetNumeralView } from "../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { WidgetNumeralViewItem } from "../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralViewItem";
import { money } from "../../../../../../../utils/numeral/money";

interface CoverageWidgetNumeralViewProps {
  groups?: CoverageGroupResponse[];
  loading?: boolean;
}

export const CoverageWidgetNumeralView: FC<CoverageWidgetNumeralViewProps> = ({
  groups,
  loading,
}) => {
  return (
    <WidgetNumeralView
      loading={false}
      skeletonCount={groups?.length ?? 6}
      width={itemWidth}
    >
      {groups?.map((item) => {
        const name =
          typeof item?.name === "string"
            ? item?.name
            : (item?.name?.name ?? item?.name?.provider_account_id ?? "");

        return (
          <WidgetNumeralViewItem
            key={name}
            label={name}
            costTooltipTitle="Covered"
            cost={item?.coverage?.amount}
            trend={null}
            percent={item?.coverage?.percentage}
            itemsCount={groups.length}
            secondLabelTooltipTitle="Not Covered"
            secondaryLabel={money(item?.on_demand?.amount ?? 0)}
            width={itemWidth}
          />
        );
      })}
    </WidgetNumeralView>
  );
};

const itemWidth = "25%";
