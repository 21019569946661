import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { useDidMount } from "rooks";
import { AzureReservedInstanceDrawerContentHeaderInfoCards } from "./AzureReservedInstanceDrawerContentHeaderInfoCards";
import { AzureReservedInstanceDrawerChartHeader } from "./AzureReservedInstanceDrawerChartHeader";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { commitmentsDetailsDrawerTargetIdSelector } from "../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";
import { getAzureReservationDetailsThunk } from "../../../../../../../../store/commitments/thunks/azure/reservations/getAzureReservationDetailsThunk";
import { CommitmentsDrawerHeader } from "../../../../../common/components/drawer/header/CommitmentsDrawerHeader";
import { CommitmentsDrawerContent } from "../../../../../common/components/drawer/CommitmentsDrawerContent";
import { AzureReservedInstanceDrawerChart } from "../common/AzureReservedInstanceDrawerChart";
import { AzureReservationDrawerDataGrid } from "../common/data-grid/AzureReservationDrawerDataGrid";

interface AzureReservedInstanceDrawerContentProps {
  onClose(): void;
}

export const AzureReservedInstanceDrawerContent: FC<
  AzureReservedInstanceDrawerContentProps
> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const targetId = useAppSelector(commitmentsDetailsDrawerTargetIdSelector);

  useDidMount(() => {
    dispatch(getAzureReservationDetailsThunk());
  });

  return (
    <Fragment>
      <CommitmentsDrawerHeader title={targetId} onClose={onClose}>
        <AzureReservedInstanceDrawerContentHeaderInfoCards />
      </CommitmentsDrawerHeader>

      <Box width="80dvw" bgcolor="grey.50" height="100%">
        <CommitmentsDrawerContent title="Utilization" icon="utilization">
          <AzureReservedInstanceDrawerChart
            ChartHeader={AzureReservedInstanceDrawerChartHeader}
          />

          <AzureReservationDrawerDataGrid viewTab="commitment" />
        </CommitmentsDrawerContent>
      </Box>
    </Fragment>
  );
};
