import { ColDef } from "ag-grid-community";
import Stack from "@mui/material/Stack";
import { Schedule } from "../../../../../../../services/cloudchipr.api";
import { WorkflowNameCell } from "../columns/name-cell/WorkflowNameCell";
import { WorkflowNextRunCell } from "../columns/WorkflowNextRunCell";
import { WorkflowActionCell } from "../columns/WorkflowActionCell";
import { AutomationsFrequencyCell } from "../../../../common/components/grid/columns/AutomationsFrequencyCell";
import { AccountsWithPopover } from "../../../../../../common/accounts-with-popover/AccountsWithPopover";
import { automationsNextRunSortingFn } from "../../utils/sorting-functions/automationsNextRunSortingFn";
import { automationsAccountsSortingFn } from "../../utils/sorting-functions/automationsAccountsSortingFn";
import { automationsEnabledStateSortingFn } from "../../utils/sorting-functions/automationsEnabledStateSortingFn";
import { automationsFrequencySortingFn } from "../../utils/sorting-functions/automationsFrequencySortingFn";
import { quickFilterNextRunText } from "../../utils/quickFilterTextFunctions/quickFilterNextRunText";
import { quickFilterActionText } from "../../utils/quickFilterTextFunctions/quickFilterActionText";
import { quickFilterFrequencyText } from "../../utils/quickFilterTextFunctions/quickFilterFrequencyText";
import { quickFilterAccountsText } from "../../utils/quickFilterTextFunctions/quickFilterAccountsText";
import { quickFilterNameText } from "../../utils/quickFilterTextFunctions/quickFilterNameText";
import { WorkflowGridActionsCell } from "../columns/actions/WorkflowGridActionsCell";
import { quickFilterCreatedByText } from "../../utils/quickFilterTextFunctions/quickFilterCreatedByText";
import { automationsCreatedBySortingFn } from "../../utils/sorting-functions/automationsCreatedBySortingFn";
import { UserActivityCell } from "../../../../../../common/data-grid-cells/UserActivityCell";
import { automationsModifiedBySortingFn } from "../../utils/sorting-functions/automationsModifiedBySortingFn";
import { quickFilterModifiedByText } from "../../utils/quickFilterTextFunctions/quickFilterModifiedByText";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const workflowsTableColumns: ColDef[] = [
  {
    field: "name",
    width: 500,
    minWidth: 200,
    maxWidth: 700,
    unSortIcon: true,
    lockPinned: true,
    rowDrag: true,
    pinned: "left",
    cellStyle: { paddingLeft: 8 },
    suppressColumnsToolPanel: true,
    getQuickFilterText: quickFilterNameText,
    cellRenderer: WorkflowNameCell,
  },
  {
    field: "time_zone",
    minWidth: 150,
    width: 250,
    maxWidth: 500,
    unSortIcon: true,
    headerName: "Next Run",
    comparator: automationsNextRunSortingFn,
    getQuickFilterText: quickFilterNextRunText,
    cellRenderer: (cell: any) => {
      const schedule = cell.data as Schedule;

      return (
        <WorkflowNextRunCell
          status={schedule.status}
          timeZone={schedule.time_zone}
          nextRun={schedule?.metadata.next_run_at}
          id={schedule.id}
        />
      );
    },
  },
  {
    field: "action",
    minWidth: 150,
    width: 200,
    maxWidth: 300,
    unSortIcon: true,
    getQuickFilterText: quickFilterActionText,
    cellRenderer: (cell: any) => {
      const schedule = cell.data as Schedule;

      return <WorkflowActionCell schedule={schedule} />;
    },
  },
  {
    field: "cron",
    minWidth: 120,
    width: 200,
    maxWidth: 300,
    unSortIcon: true,
    headerName: "Frequency",
    comparator: automationsFrequencySortingFn,
    getQuickFilterText: quickFilterFrequencyText,
    cellRenderer: (cell: any) => {
      const schedule = cell.data as Schedule;

      return (
        <AutomationsFrequencyCell
          nextRun={schedule?.metadata?.next_run_at ?? ""}
          timeZone={schedule.time_zone}
          cron={schedule.cron}
          weeklyInterval={schedule.weekly_schedule_interval}
        />
      );
    },
  },
  {
    field: "account",
    minWidth: 150,
    width: 300,
    maxWidth: 700,
    unSortIcon: true,
    getQuickFilterText: quickFilterAccountsText,
    comparator: automationsAccountsSortingFn,
    cellRenderer: (cell: any) => {
      const schedule = cell.data as Schedule;
      const accounts = schedule.accounts;

      return (
        <Stack height="100%" justifyContent="center">
          <AccountsWithPopover accounts={accounts} />
        </Stack>
      );
    },
  },

  {
    field: "created_by",
    width: 300,
    minWidth: 220,
    maxWidth: 300,
    unSortIcon: true,
    headerName: "Created By",
    getQuickFilterText: quickFilterCreatedByText,
    comparator: automationsCreatedBySortingFn,
    cellRenderer: (cell: any) => {
      const details = cell.data?.created_by;
      const date = cell.data.created_at;

      return (
        <Stack height="100%" justifyContent="center">
          <UserActivityCell
            date={date}
            name={details?.name ?? details?.email ?? ""}
            deleted={details?.status === "deleted"}
            profile={details?.profile}
          />
        </Stack>
      );
    },
  },

  {
    field: "modified_by",
    width: 300,
    minWidth: 220,
    maxWidth: 300,
    unSortIcon: true,
    headerName: "Last Modified By",
    getQuickFilterText: quickFilterModifiedByText,
    comparator: automationsModifiedBySortingFn,
    cellRenderer: (cell: any) => {
      const details = cell.data?.modified_by;
      const date = cell.data.modified_at;

      return (
        <Stack height="100%" justifyContent="center">
          <UserActivityCell
            date={date}
            name={details?.name ?? details?.email ?? ""}
            deleted={details?.status === "deleted"}
            profile={details?.profile}
          />
        </Stack>
      );
    },
  },

  agGridEmptyColumnToFitEmptySpace,

  {
    colId: "actions",
    maxWidth: 150,
    width: 150,
    minWidth: 150,
    resizable: false,
    unSortIcon: true,
    suppressMovable: true,
    pinned: "right",
    headerName: "Enabled",
    comparator: automationsEnabledStateSortingFn,
    suppressColumnsToolPanel: true,
    cellRenderer: WorkflowGridActionsCell,
  },
];
