import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { azureUtilizationAndCoverageDetailsPayloadSelector } from "../../selectors/azure/coverage-and-utilization/details-drawer/azureUtilizationAndCoverageDetailsPayloadSelector";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getAzureUtilizationAndCoverageDetailsThunk = createAsyncThunk(
  "commitments/getUtilizationAndCoverageDrawerDetails",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentAzureSavingsPlansData } =
      cloudChiprApi.endpoints;

    const payload = azureUtilizationAndCoverageDetailsPayloadSelector(state);

    if (!payload) {
      return;
    }

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentAzureSavingsPlansData.initiate(payload),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
