const labelFormatter = (label: string) => {
  return label.length > 28 ? `${label.substring(0, 27)}…` : label;
};

export const getAccountBarViewXAxisTickProps = (dataLength: number) => {
  if (dataLength > 5) {
    if (dataLength < 11) {
      return {
        textProps: {
          x: 50,
          y: 15,
          transform: "rotate(-10)",
          textAnchor: "end",
        },
        parentHeight: 45,
        labelFormatter,
      };
    }

    return {
      textProps: {
        x: 5,
        y: 0,
        transform: "rotate(-45)",
        textAnchor: "end",
      },
      parentPadding: { left: 16, right: 16 },
      parentHeight: 125,
      labelFormatter,
    };
  }
};
