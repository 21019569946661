import {
  FC,
  Fragment,
  MouseEvent,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemProps,
} from "@mui/material";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import {
  NestedNavItemContent,
  NestedNavItemContentProps,
} from "./NestedNavItemContent";
import { useHover } from "../../../../../utils/hooks/useHover.hook";
import { useAppSelector } from "../../../../../store/hooks";
import { isSubscriptionCancelledSelector } from "../../../../../store/subscriptions/subscriptionsSelectors";

export interface NestedNavItemProps extends NestedNavItemContentProps {
  disabled?: boolean;
  onButtonClick?: ListItemButtonProps["onClick"];
  secondaryAction?: ListItemProps["secondaryAction"];
  nested?: boolean;
  children?: ReactNode[];
  to?: string;
}

export const NestedNavItem: FC<NestedNavItemProps> = ({
  children,
  to,
  selected,
  disabled,
  secondaryAction,
  nested,
  onButtonClick,
  ...contentProps
}) => {
  const navigate = useNavigate();
  const resolvedPath = useResolvedPath(to || "");
  const secondaryActionRef = useRef<null | HTMLDivElement>(null);
  const { ref, hovered } = useHover();
  const match = useMatch({ path: resolvedPath.pathname });

  const isSubscriptionDisabled = useAppSelector(
    isSubscriptionCancelledSelector,
  );

  disabled = disabled || isSubscriptionDisabled;
  selected = (resolvedPath.pathname !== "/" && !!match) || selected;

  const buttonStyles = useMemo(() => {
    const pr = `${(secondaryActionRef?.current?.clientWidth ?? 0) + 12}px !important`;

    return {
      borderRadius: 2,
      pl: nested ? 3 : 1,
      "&:hover": { bgcolor: "grey.300" },
      "&:hover *": { fontWeight: "medium" },
      pr: hovered && secondaryAction ? pr : 1,
    };
  }, [nested, hovered, secondaryAction]);

  const clickHandler = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (to) {
        navigate(to);
      } else {
        onButtonClick?.(event);
      }
    },
    [to, onButtonClick, navigate],
  );

  const buttonContent = (
    <NestedNavItemContent {...contentProps} selected={selected} />
  );

  return (
    <Fragment>
      <ListItem
        ref={ref}
        sx={listItemSx}
        secondaryAction={
          <Box
            ref={secondaryActionRef}
            sx={{ visibility: hovered ? "visible" : "hidden" }}
          >
            {secondaryAction}
          </Box>
        }
      >
        {!to ? (
          <ListItemButton
            selected={selected}
            disabled={disabled}
            onClick={clickHandler}
            sx={buttonStyles}
          >
            {buttonContent}
          </ListItemButton>
        ) : (
          buttonContent
        )}
      </ListItem>

      {children}
    </Fragment>
  );
};

const listItemSx = { px: 1, py: 0.25 };
