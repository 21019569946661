import { FC } from "react";
import { ListItemText, Stack, Typography } from "@mui/material";
import { ClusterOptionType } from "./utils/types";
import { ProviderIcon } from "../../../../../../common/ProviderIcon";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { money } from "../../../../../../../utils/numeral/money";
import { AccountAccessTypeChip } from "../../../../../../common/chips/account-access-type-chip/AccountAccessTypeChip";

interface KubernetesLiveUsageMgmtSelectClusterOptionProps {
  showClusterId?: boolean;
  option: ClusterOptionType;
  idleMonthlyCost: number;
  totalMonthlyCost: number;
}

export const KubernetesLiveUsageMgmtSelectClusterOption: FC<
  KubernetesLiveUsageMgmtSelectClusterOptionProps
> = ({ option, showClusterId, totalMonthlyCost, idleMonthlyCost }) => {
  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1} overflow="hidden" alignItems="center">
        <ProviderIcon provider="kubernetes" width={24} />

        <ListItemText
          primary={
            <TypographyWithTooltip title={option.label} fontSize="inherit" />
          }
          secondary={
            <Stack component="span">
              <span>
                {idleMonthlyCost && (
                  <Typography color="error" variant="caption" component="span">
                    {money(idleMonthlyCost)}
                  </Typography>
                )}

                {totalMonthlyCost && (
                  <Typography
                    color="text.secondary"
                    variant="caption"
                    component="span"
                  >
                    {" / "} {money(totalMonthlyCost)}
                  </Typography>
                )}
              </span>
              {showClusterId && option.clusterId && (
                <TypographyWithTooltip
                  title={`ID: ${option.clusterId}`}
                  variant="caption"
                  color="text.disabled"
                />
              )}
            </Stack>
          }
        />
      </Stack>

      <Stack direction="row">
        {option.accessType && (
          <AccountAccessTypeChip
            accessType={option.accessType}
            provider="kubernetes"
          />
        )}
      </Stack>
    </Stack>
  );
};
