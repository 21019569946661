import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { updateTaskCommentFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.patchUsersMeOrganisationsCurrentTasksByTaskIdCommentsAndCommentId.select(
    updateTaskCommentFixedCacheKey,
  );

export const updateTaskCommentLoadingSelector = (state: RootState) => {
  return !!endpoint(state)?.isLoading;
};
