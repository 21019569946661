import { FC, ReactNode, useMemo } from "react";
import { Cell, Label, Pie, PieChart as RechartPie } from "recharts";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { green, grey, red } from "@mui/material/colors";
import { percentage } from "../utils/helpers/percentage";

interface PieChartProps {
  empty?: boolean;
  error?: boolean;
  partial: number;
  total: number;
  stroke?: boolean;
  colors?: {
    partial: string;
    total: string;
  };
  fullAngle?: boolean;
  paddingAngle?: number;
  content?: ReactNode;
  innerRadius?: number;
}

export const PieChart: FC<PieChartProps> = ({
  empty,
  error,
  partial,
  total,
  stroke,
  colors,
  fullAngle,
  paddingAngle,
  content,
  innerRadius,
}) => {
  colors = colors ?? {
    total: green[100],
    partial: red[500],
  };

  if (empty || error) {
    partial = 0;
    total = 0;
  }

  const data = useMemo(() => {
    return [
      { value: total ? partial / total : 1 },
      { value: total ? 1 - partial / total : 0 },
    ];
  }, [partial, total]);

  return (
    <RechartPie width={128} height={128}>
      {stroke && (
        <Pie
          isAnimationActive={false}
          data={[{ value: 1 }]}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={60}
          innerRadius={56}
          fill={error || empty ? grey[300] : green[500]}
          startAngle={fullAngle ? undefined : 225}
          endAngle={fullAngle ? undefined : -45}
        />
      )}

      <Pie
        isAnimationActive={false}
        data={data}
        cx="50%"
        cy="50%"
        startAngle={fullAngle ? undefined : 225}
        endAngle={fullAngle ? undefined : -45}
        innerRadius={innerRadius ?? 48}
        outerRadius={56}
        fill={error || empty ? grey[300] : colors.total}
        dataKey="value"
        stroke="none"
        paddingAngle={paddingAngle}
      >
        {error ? (
          <Label position="center" content={ErrorIcon} />
        ) : (
          <Label
            position="center"
            fontWeight="500"
            fontSize={18}
            fill="#000"
            content={content ? <CustomLabel content={content} /> : undefined}
            value={percentage(partial, total)}
          >
            {percentage(partial, total)}
          </Label>
        )}
        <Cell fill={error || empty ? grey[300] : colors.partial} />
      </Pie>
    </RechartPie>
  );
};

const ErrorIcon: FC = () => {
  return (
    <ReportProblemRoundedIcon
      color="inherit"
      sx={{ color: "#FFCA29" }}
      viewBox="-23 -20 70 70"
    />
  );
};

const CustomLabel = ({ value, content }: any) => {
  return (
    <svg>
      <foreignObject width="100" height="70" x={8} y={20}>
        {content ?? value}
      </foreignObject>
    </svg>
  );
};
