import { FC } from "react";
import Stack from "@mui/material/Stack";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../../store/hooks";
import { resourceTypeSearchParam } from "../../../live-usage-mgmt/utils/constants/searchParams";
import { LiveUsageNavigationResourceType } from "../../../live-usage-mgmt/components/navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { kubernetesLiveUsageMgmtSelectedClusterIdsSelector } from "../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtSelectedClusterIdsSelector";
import { isStringResourceType } from "../../../../../utils/helpers/resources/isStringResourceType";
import { KubernetesLiveUsageMgmtResourceCard } from "../resource-card/KubernetesLiveUsageMgmtResourceCard";

export const KubernetesLiveUsageResourcesArea: FC = () => {
  const [searchParams] = useSearchParams();
  const activeResourceType = searchParams.get(
    resourceTypeSearchParam,
  ) as LiveUsageNavigationResourceType;

  const accountIds = useAppSelector(
    kubernetesLiveUsageMgmtSelectedClusterIdsSelector,
  );

  if (!accountIds || !activeResourceType) {
    return null;
  }

  if (!isStringResourceType(activeResourceType)) {
    return null;
  }

  return (
    <Stack
      direction="row"
      overflow="auto"
      height="100%"
      bgcolor="white"
      key={activeResourceType}
    >
      <KubernetesLiveUsageMgmtResourceCard
        singleView
        resourceType={activeResourceType}
      />
    </Stack>
  );
};
