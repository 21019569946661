import { FC, useCallback } from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";
import Typography from "@mui/material/Typography";
import { BillingExplorerChartSettings } from "../../../../../../../../../store/resource-explorer/utils/types/resourceExplorer";
import { useAppDispatch } from "../../../../../../../../../store/hooks";
import { setBillingExplorerChartSettings } from "../../../../../../../../../store/resource-explorer/resourceExplorerSlice";

interface BillingExplorerChartSettingSwitcherProps {
  type: Exclude<keyof BillingExplorerChartSettings, "chartScale">;
  checked: boolean;
}

export const BillingExplorerChartSettingSwitcher: FC<
  BillingExplorerChartSettingSwitcherProps
> = ({ checked, type }) => {
  const dispatch = useAppDispatch();

  const changeHandler = useCallback(() => {
    dispatch(setBillingExplorerChartSettings({ [type]: !checked }));
  }, [dispatch, type, checked]);

  return (
    <Box pl={1} mt={1.5}>
      <FormControlLabel
        onChange={changeHandler}
        checked={checked}
        sx={{ width: "fit-content" }}
        control={<Switch size="small" />}
        label={<Typography variant="body2">{titleByType[type]}</Typography>}
      />
    </Box>
  );
};

const titleByType = {
  showLabels: "Show Data Labels",
  showLegends: "Show Legends",
  showTrendLine: "Show TrendLine",
};
