import { kubernetesLiveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector } from "./kubernetesLiveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const kubernetesLiveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector =
  (
    state: RootState,
    resourceType: ResourceType,
  ): "non_selected_data" | "multi_resource_type_selection" | undefined => {
    const selectedResourcesWithHierarchy =
      kubernetesLiveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector(
        state,
        resourceType,
      );

    const selectedItems = Object.keys(selectedResourcesWithHierarchy ?? {});

    if (!selectedItems?.length) {
      return "non_selected_data";
    }

    if (selectedItems.length > 1) {
      return "multi_resource_type_selection";
    }
  };
