import { FC, Fragment, useMemo } from "react";
import { Box } from "@mui/material";
import { useDidMount } from "rooks";
import { AzureSavingsPlanCoverageDrawerContentHeaderInfoCards } from "./AzureSavingsPlanCoverageDrawerContentHeaderInfoCards";
import { AzureSavingsPlanCoverageDrawerChartHeader } from "./AzureSavingsPlanCoverageDrawerChartHeader";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { azureSavingsPlanSelectedCoverageDataAccountNameAndIdSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/details-drawer/resource-type/azureSavingsPlanSelectedCoverageDataAccountNameAndIdSelector";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { getAzureUtilizationAndCoverageDetailsThunk } from "../../../../../../../../store/commitments/thunks/azure/getAzureUtilizationAndCoverageDetailsThunk";
import { CommitmentsDrawerHeader } from "../../../../../common/components/drawer/header/CommitmentsDrawerHeader";
import { CommitmentsDrawerContent } from "../../../../../common/components/drawer/CommitmentsDrawerContent";
import { AzureUtilizationAndCoverageDrawerChart } from "../common/AzureUtilizationAndCoverageDrawerChart";
import { AzureUtilizationAndCoverageDrawerDataGrid } from "../common/data-grid/AzureUtilizationAndCoverageDrawerDataGrid";
import { azureSavingsPlanSelectedCoverageDataRegionSelector } from "../../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/details-drawer/resource-type/azureSavingsPlanSelectedCoverageDataRegionSelector";

interface AzureSavingsPlanCoverageDrawerContentProps {
  onClose(): void;
}

export const AzureSavingsPlanCoverageDrawerContent: FC<
  AzureSavingsPlanCoverageDrawerContentProps
> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const accountId = useAppSelector(
    azureSavingsPlanSelectedCoverageDataAccountNameAndIdSelector,
  );
  const region = useAppSelector(
    azureSavingsPlanSelectedCoverageDataRegionSelector,
  );
  const targetType = useAppSelector(commitmentsDetailsDrawerTargetTypeSelector);

  useDidMount(() => {
    dispatch(getAzureUtilizationAndCoverageDetailsThunk());
  });

  const title = useMemo(() => {
    if (targetType === "region") {
      return region ?? "Region";
    }
    if (targetType === "resource_type") {
      return "Resource Type";
    }
    return accountId;
  }, [targetType, accountId, region]);

  return (
    <Fragment>
      <CommitmentsDrawerHeader title={title} onClose={onClose}>
        {targetType === "resource_type" && (
          <AzureSavingsPlanCoverageDrawerContentHeaderInfoCards />
        )}
      </CommitmentsDrawerHeader>

      <Box width="80dvw" bgcolor="grey.50" height="100%">
        <CommitmentsDrawerContent
          title="Coverage"
          icon="utilization"
          padding="16px 16px 0px 16px"
        >
          <AzureUtilizationAndCoverageDrawerChart
            ChartHeader={AzureSavingsPlanCoverageDrawerChartHeader}
          />

          <AzureUtilizationAndCoverageDrawerDataGrid viewTab="coverage" />
        </CommitmentsDrawerContent>
      </Box>
    </Fragment>
  );
};
