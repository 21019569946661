import { ValueGetterParams } from "ag-grid-community";
import {
  AzureReservation,
  Reservation,
} from "../../../../../../../../../services/cloudchipr.api";
import {
  commitmentsPaymentOptionLabels,
  commitmentsResourceTypeNames,
} from "../../../../../../common/utils/constants/labels";
import { money } from "../../../../../../../../../utils/numeral/money";
import { formatDate } from "../../../../../../../../../utils/helpers/date-time/datetime-format";
import { ReservationsCoverageDataWithId } from "../../../../../../common/utils/types";

export const reservationTypeCellValueGetter = <
  T extends Reservation | AzureReservation,
>({
  data,
}: ValueGetterParams<T>) =>
  data?.type ? (commitmentsResourceTypeNames[data?.type] ?? "") : "";

export const reservationAccountCellValueGetter = ({
  data,
}: ValueGetterParams<Reservation>) =>
  `${data?.account?.provider_account_name ?? ""} ${data?.account?.provider_account_id ?? ""}`;

export const reservationUtilizationCellValueGetter = <
  T extends Reservation | AzureReservation,
>({
  data,
}: ValueGetterParams<T>) =>
  `${money(data?.used_commitment_to_date?.cost ?? 0)} ${data?.used_commitment_to_date?.units ?? 0}h (${(((data?.used_commitment_to_date?.cost ?? 0) / (data?.total_commitment_to_date?.cost ?? 1)) * 100).toFixed(2)}%)`;

export const reservationReservedCellValueGetter = <
  T extends Reservation | AzureReservation,
>({
  data,
}: ValueGetterParams<T>) =>
  `${money(data?.total_commitment_to_date?.cost ?? 0)} (${data?.total_commitment_to_date?.units ?? 0}h)`;

export const reservationPaymentOptionCellValueGetter = <
  T extends Reservation | AzureReservation,
>({
  data,
}: ValueGetterParams<T>) =>
  data?.payment_option
    ? commitmentsPaymentOptionLabels[data.payment_option]
    : "";

export const reservationStartDateCellValueGetter = <
  T extends Reservation | AzureReservation,
>({
  data,
}: ValueGetterParams<T>) =>
  formatDate(data?.start_date ?? "", { type: "date" });

export const reservationEndDateCellValueGetter = <
  T extends Reservation | AzureReservation,
>({
  data,
}: ValueGetterParams<T>) => formatDate(data?.end_date ?? "", { type: "date" });

export const reservationHasRecommendationCellValueGetter = <
  T extends Reservation | AzureReservation,
>({
  data,
}: ValueGetterParams<T>) =>
  data?.has_recommendation ? "Has recommendation" : "No recommendation";

export const reservationCoverageAccountCellValueGetter = ({
  data,
}: ValueGetterParams<ReservationsCoverageDataWithId>) =>
  data
    ? `${data.account?.name ?? ""} ${data.account?.provider_account_id ?? ""}`
    : "";

export const reservationCoverageResourceTypeCellValueGetter = ({
  data,
}: ValueGetterParams<ReservationsCoverageDataWithId>) =>
  data?.resource_type?.resource_type
    ? commitmentsResourceTypeNames[data.resource_type.resource_type]
    : "";

export const reservationCoverageCoveredHoursCellValueGetter = ({
  data,
}: ValueGetterParams<ReservationsCoverageDataWithId>) => {
  // @ts-expect-error: TODO fix types
  const uncoveredHours = data?.uncovered_hours ?? 0;

  if (!data) {
    return;
  }

  return `${data.covered_hours}h / ${uncoveredHours + data.covered_hours}h`;
};
