import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../utils/helpers/providers/getProviderName";
import { money } from "../../../../utils/numeral/money";
import { liveUsageMgmtProviderSelector } from "../../../live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { liveUsageMgmtSelectedResourcesAccountsMetaDataSelector } from "../../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesAccountsMetaDataSelector";
import { currentUserEmailSelector } from "../../../profile/currentUserEmailSelector";
import { generateLiveResourcesPath } from "../../../../components/pages/accounts-group/utils/helpers/generateLiveResourcesPath";

export const slackMessageSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const userEmail = currentUserEmailSelector(state);

  const accountType = liveUsageMgmtProviderSelector(state);

  const accountData = liveUsageMgmtSelectedResourcesAccountsMetaDataSelector(
    state,
    resourceType,
  );

  const selectedResources =
    liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType);

  if (!selectedResources || !accountType) {
    return "";
  }

  const provider = getProviderName(accountType, {
    nameAndTitle: true,
    capitalize: true,
    plural: true,
  });

  const price = selectedResources.reduce(
    (acc, resource) => resource.resource.price_per_month + acc,
    0,
  );

  const accountUrls = accountData?.reduce((result, item) => {
    const url = `${window.location.origin}${generateLiveResourcesPath({
      provider: item.provider,
      activeTab: "live-resources",
      accountIds: item.id,
    })}`;

    const comma = result.length ? "," : "";
    result = result + ` <a href="${url}">${comma}${item.name}</a>`;
    return result;
  }, "");

  const monthlyPrice = `Monthly Price - <b>${money(price)}</b>`;
  const user = userEmail
    ? `Sent from Cloudchipr by user - <a href="mailto:${userEmail}">${userEmail}</a> `
    : "";

  return `Please review attached resources and take necessary actions if required.<br/> <br/>  ${provider} - ${accountUrls} <br/> ${monthlyPrice} <br/> ${user} `;
};
