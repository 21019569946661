import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { kubernetesLiveUsageMgmtResourceTypeDataResourcesSelector } from "./kubernetesLiveUsageMgmtResourceTypeDataResourcesSelector";
import { KubernetesResourceDataType } from "../../utils/types/types";

export const kubernetesLiveUsageMgmtResourceTypeDataWithChildrenSelector =
  createDraftSafeSelector(
    [kubernetesLiveUsageMgmtResourceTypeDataResourcesSelector],
    (data) => {
      const result: KubernetesResourceDataType[] = [];

      const processResource = (data: KubernetesResourceDataType) => {
        if (data?.resource) {
          result.push(data);
        }
      };

      data?.forEach((item) => {
        processResource(item);
      });

      return result;
    },
  );
