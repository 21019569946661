import { FC, useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { DropdownSelect } from "../../../../../common/select/dropdown-select/DropdownSelect";
import { useGetUsersMeExecutionLogsSourcesQuery } from "../../../../../../services/cloudchipr.api";
import {
  setExecutionLogFilters,
  setExecutionLogPagination,
} from "../../../../../../store/execution-log/executionLogSlice";
import { getExecutionLogThunk } from "../../../../../../store/execution-log/thunks/getExecutionLogThunk";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { executionLogsSourceNames } from "../../../utils/constants/common";
import { executionLogFiltersSelector } from "../../../../../../store/execution-log/selectors/executionLogFiltersSelector";
import { arrayIsEqual } from "../../../../../../utils/arrayIsEqual";
import { sourceAndExecutedBySeparator } from "../../../utils/helpers/getSourceAndExecutedByFromSelectValues";

export const ExecutionLogSourceFilter: FC = () => {
  const dispatch = useAppDispatch();
  const executionLogFilters = useAppSelector(executionLogFiltersSelector);
  const { data, isLoading } = useGetUsersMeExecutionLogsSourcesQuery();

  const filterSubmitHandler = useCallback(
    (values: string[]) => {
      if (
        arrayIsEqual(values, executionLogFilters?.sourceAndExecutedBy ?? [])
      ) {
        return;
      }

      dispatch(
        setExecutionLogFilters({
          sourceAndExecutedBy: values,
        }),
      );

      dispatch(setExecutionLogPagination({ page: 0 }));
      dispatch(getExecutionLogThunk({}));
    },
    [dispatch, executionLogFilters],
  );

  const options = useMemo(() => {
    return (
      data
        ?.filter((option) => option.source && option.name)
        ?.map((option) => {
          return {
            value: `${option.source}${sourceAndExecutedBySeparator}${option.name}`,
            label: (
              <Stack>
                <Typography variant="body2">{option.name}</Typography>

                {option.source && option.source !== "manual" && (
                  <Typography variant="caption" color="text.disabled">
                    {executionLogsSourceNames.get(option.source)}
                  </Typography>
                )}
              </Stack>
            ),
          };
        }) ?? []
    );
  }, [data]);

  return (
    <DropdownSelect
      label="Executed By"
      options={options}
      values={executionLogFilters?.sourceAndExecutedBy ?? []}
      optionsLoading={isLoading}
      submitHandlerOnClose={filterSubmitHandler}
    />
  );
};
