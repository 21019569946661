import { FC, Fragment } from "react";
import { Divider, Stack, Typography } from "@mui/material";

import {
  Dates,
  ResourceExplorerGroupingNullable,
} from "../../../../../../../../services/cloudchipr.api";
import { SourceDataChipProps } from "../../../../widgets/common/widget-header/source-and-name/SourceDataChip";
import { WidgetSourceInfo } from "../../../../widgets/common/widget-header/source-and-name/WidgetSourceInfo";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";
import { useAppSelector } from "../../../../../../../../store/hooks";

interface WidgetSetupSubHeaderV2Props {
  name: string;
  dates: Dates;
  title: string;
  url: string;
  groupBy?: ResourceExplorerGroupingNullable;
  groupValues?: string[];
}

export const WidgetSetupSubHeader: FC<WidgetSetupSubHeaderV2Props> = ({
  name,
  title,
  url,
  groupBy,
  groupValues,
  dates,
}) => {
  const widgetSourceData: SourceDataChipProps = {
    title,
    url,
  };
  const groupByLabel = useAppSelector((state) =>
    resourceExplorerGroupingLabelByGroupingSelector(state, groupBy),
  );

  return (
    <Fragment>
      <Stack px={2} py={1}>
        <Typography variant="body1" fontWeight="bold" minHeight={24}>
          {name}
        </Typography>

        <WidgetSourceInfo
          dates={dates}
          sourceData={widgetSourceData}
          groupBy={groupBy}
          groupByLabel={groupByLabel}
          groupValues={groupValues}
        />
      </Stack>

      <Divider />
    </Fragment>
  );
};
