import { FC, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { v4 as uuid } from "uuid";
import { useDidMount } from "rooks";
import { CoverageDrawerRecommendationsGridToolbar } from "./CoverageDrawerRecommendationsGridToolbar";
import {
  SavingsPlanRecommendation,
  useGetUsersMeOrganisationsCurrentSavingsPlansRecommendationsQuery,
} from "../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { coverageRecommendationsPayloadSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/recommendations/coverageRecommendationsPayloadSelector";
import { commitmentsRecommendationsFiltersSelector } from "../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsFiltersSelector";
import { CommitmentsDrawerContent } from "../../../../../common/components/drawer/CommitmentsDrawerContent";
import { filterSavingsPlansRecommendationsByFilters } from "../../../../../../../../store/commitments/utils/helpers/filterSavingsPlansRecommendationsByFilters";
import { initiateSavingPlansCoverageDrawerThunk } from "../../../../../../../../store/commitments/thunks/recommendations/initiateSavingPlansCoverageDrawerThunk";
import { commitmentsRecommendationsGridColumnDefs } from "../../../content/recommendations/data-grid/commitmentsRecommendationsGridColumnDefs";
import { CommitmentsAgGrid } from "../../../../../common/components/CommitmentsAgGrid";

export const CoverageDrawerRecommendationsSection: FC = () => {
  const dispatch = useAppDispatch();

  const payload = useAppSelector(coverageRecommendationsPayloadSelector);
  const filters = useAppSelector(commitmentsRecommendationsFiltersSelector);

  const { data } =
    useGetUsersMeOrganisationsCurrentSavingsPlansRecommendationsQuery(payload);

  const filteredData = useMemo(() => {
    return filterSavingsPlansRecommendationsByFilters(
      filters,
      data?.map((recommendation) => ({
        ...recommendation,
        id: uuid(),
      })),
    );
  }, [data, filters]);

  useDidMount(() => {
    dispatch(initiateSavingPlansCoverageDrawerThunk());
  });

  return (
    <CommitmentsDrawerContent title="Recommendations" icon="recommendation">
      {!data?.length ? (
        <Stack alignItems="center" p={3}>
          <Typography
            variant="subtitle1"
            fontWeight="medium"
            color="text.secondary"
          >
            No Recommendations
          </Typography>
        </Stack>
      ) : (
        <CommitmentsAgGrid
          columnDefs={commitmentsRecommendationsGridColumnDefs}
          data={
            (filteredData as (SavingsPlanRecommendation & {
              id: string;
            })[]) ?? []
          }
          Toolbar={CoverageDrawerRecommendationsGridToolbar}
          localStorageUniqueKey="CoverageDrawerRecommendationsSection"
          overrideDefaultColDef={defaultColDef}
        />
      )}
    </CommitmentsDrawerContent>
  );
};

const defaultColDef = { filter: false };
