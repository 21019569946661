import { resourceExplorerGroupingNextGroupingSelector } from "./resourceExplorerGroupingNextGroupingSelector";
import { RootState } from "../../../store";
import {
  ResourceExplorerFilterProvider,
  ResourceExplorerGroupingNullable,
} from "../../../../services/cloudchipr.api";

export const resourceExplorerGroupingNextGroupingByProviderSelector = (
  state: RootState,
  grouping: ResourceExplorerGroupingNullable,
  provider: ResourceExplorerFilterProvider,
) => {
  const localProvider = provider ?? "global";
  const nextGroupings = resourceExplorerGroupingNextGroupingSelector(state);

  if (!grouping) {
    return "service";
  }

  if (!nextGroupings?.[grouping]) {
    return "service";
  }

  if (nextGroupings?.[grouping]?.[localProvider]) {
    return nextGroupings?.[grouping]?.[localProvider];
  }

  return Object.values(nextGroupings?.[grouping])[0];
};
