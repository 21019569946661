import { FC, SyntheticEvent, useCallback, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { NavigationVisibilitySectionDialogContent } from "../../../hierarchy/visibility/NavigationVisibilitySectionDialogContent";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import {
  NavigationHierarchyItemType,
  NavigationItemsVisibilityType,
} from "../../../../../../store/navigation/utils/types";
import { NavigationVisibilitySectionDialogActions } from "../../../hierarchy/visibility/NavigationVisibilitySectionDialogActions";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { createDashboardVisibilityHierarchyFolderOrItemThunk } from "../../../../../../store/dashboards/thunks/dashboard-hierarchy-visibility/createDashboardVisibilityHierarchyFolderOrItemThunk";
import { createDashboardLoadingSelector } from "../../../../../../store/dashboards/selectors/dashboard/loadings/createDashboardLoadingSelector";
import { getPressEnterHandler } from "../../../../../utils/helpers/getPressEnterHandler";
import { createDashboardFolderLoadingSelector } from "../../../../../../store/dashboards/selectors/dashboard/loadings/createDashboardFolderLoadingSelector";

interface DashboardCreateFolderOrItemDialogProps {
  onClose(): void;
  type: NavigationHierarchyItemType;
  folderId?: string;
  visibility?: NavigationItemsVisibilityType;
  redirectAfterCreate?: boolean;
}

export const DashboardCreateFolderOrItemDialog: FC<
  DashboardCreateFolderOrItemDialogProps
> = ({
  onClose,
  type,
  folderId,
  visibility: fixedVisibility,
  redirectAfterCreate = true,
}) => {
  const navigate = useNavigate();
  const [dashboardName, setDashboardName] = useState<string>("");
  const [visibility, setVisibility] = useState(
    fixedVisibility ?? "visible_to_everyone",
  );

  const createDashboardLoading = useAppSelector(createDashboardLoadingSelector);
  const createFolderLoading = useAppSelector(
    createDashboardFolderLoadingSelector,
  );
  const disabled = !dashboardName.trim()?.length;
  const loading = createDashboardLoading || createFolderLoading;

  const dispatch = useAppDispatch();
  const closeDialogHandler = useCallback(() => {
    onClose();
    setDashboardName("");
  }, [onClose]);

  const nameChangeHandler = useCallback((event: any) => {
    setDashboardName(event.target.value);
  }, []);

  const createItemCallbackHandler = useCallback(
    (id: string) => {
      navigate({ pathname: `/dashboards/${id}` });
    },
    [navigate],
  );
  const visibilityChangeHandler = useCallback(
    (_: SyntheticEvent, value: string) => {
      setVisibility(value as NavigationItemsVisibilityType);
    },
    [],
  );

  const submitFormHandler = useCallback(async () => {
    const response = await dispatch(
      createDashboardVisibilityHierarchyFolderOrItemThunk({
        name: dashboardName,
        type,
        visibility,
        folderId,
      }),
    ).unwrap();

    if (response && redirectAfterCreate) {
      createItemCallbackHandler(response?.id);
    }
    onClose();
  }, [
    dispatch,
    onClose,
    dashboardName,
    type,
    visibility,
    folderId,
    redirectAfterCreate,
    createItemCallbackHandler,
  ]);

  const handlePressEnter = getPressEnterHandler(
    submitFormHandler,
    disabled || loading,
  );

  return (
    <Dialog
      open={!!type}
      onClose={closeDialogHandler}
      maxWidth="sm"
      fullWidth
      onKeyDown={handlePressEnter}
    >
      <DialogTitleClosable
        title={type === "item" ? "Add Dashboard" : "Add Folder"}
        onClose={closeDialogHandler}
      />
      <DialogContent
        sx={{
          pb: 3,
        }}
        dividers
      >
        <NavigationVisibilitySectionDialogContent
          value={dashboardName}
          onTextFieldChange={nameChangeHandler}
          label={type === "item" ? "Dashboard Name" : "Folder Name"}
          visibility={visibility}
          onVisibilityChange={visibilityChangeHandler}
          disabledVisibility={fixedVisibility}
        />
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <NavigationVisibilitySectionDialogActions
          onClose={closeDialogHandler}
          onSubmit={submitFormHandler}
          disabled={disabled}
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  );
};
