import { ProviderType } from "../../../../../services/cloudchipr.api";

export const noOrgProviderOrganisationOrgId = "no-organisation";

export const noOrgProviderOrganisationName: Map<ProviderType, string> = new Map(
  [
    ["aws", "Other Organisations"],
    ["gcp", "Other Organisations"],
    ["azure", "Other Organisations"],
  ],
);
