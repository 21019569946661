import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { BillingExplorerExplainWithAiREMetaDataExplainButton } from "./BillingExplorerExplainWithAiREMetaDataExplainButton";
import {
  Dates,
  ResourceExplorerGroupingNullable,
  TrendType,
} from "../../../../../../../../services/cloudchipr.api";
import { allDateRangesByQuarterStartMonthSelector } from "../../../../../../../../store/common/selectors/date-ranges/allDateRangesByQuarterStartMonthSelector";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { generateDateMetaDataByDates } from "../../../../../../../common/date-range-picker/utils/helpers/generateDateMetaDataByDates";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";
import { trendSettingsTitlesByType } from "../../../../../../../app-navigation/components/page-components/billing-explorer/components/actions-menu/action-items/report-settings/utils/constants";
import { billingExplorerSummaryLoadingByIndexSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/loading/billingExplorerSummaryLoadingByIndexSelector";
import { BillingExplorerExplainWithAiOutRangesAlert } from "../BillingExplorerExplainWithAiOutRangesAlert";
import { isBillingExplorerDatesOutRangesForSummarySelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-summary/isBillingExplorerDatesOutRangesForSummarySelector";
import { BillingExplorerExplainWithAiLoading } from "../../BillingExplorerExplainWithAiLoading";

interface ExplainWithAiBillingExplorerMetaDataProps {
  dates?: Dates;
  grouping: ResourceExplorerGroupingNullable;
  trendType?: TrendType;
  showExplainButton?: boolean;
  showOutDateRanges?: boolean;
  showLoading?: boolean;
  index: number;
}
export const ExplainWithAiBillingExplorerMetaData: FC<
  ExplainWithAiBillingExplorerMetaDataProps
> = ({
  grouping,
  dates,
  trendType,
  showExplainButton,
  index,
  showLoading,
  showOutDateRanges,
}) => {
  const ranges = useAppSelector(allDateRangesByQuarterStartMonthSelector);
  const loading = useAppSelector((state) =>
    billingExplorerSummaryLoadingByIndexSelector(state, index),
  );

  const isOutRangesDates = useAppSelector(
    isBillingExplorerDatesOutRangesForSummarySelector,
  );
  const { label, tooltip } = generateDateMetaDataByDates({
    ranges,
    dates,
  });
  const dateLabel = !dates?.label && !dates?.type ? label : tooltip;
  const groupingLabel = useAppSelector((state) =>
    resourceExplorerGroupingLabelByGroupingSelector(state, grouping),
  );

  return (
    <Stack spacing={1.5}>
      <Stack bgcolor="white" borderRadius={1} p={1}>
        {showExplainButton && (
          <Typography variant="subtitle2" fontWeight="normal">
            The AI will generate insights based on the selected parameters
          </Typography>
        )}

        <Typography variant="subtitle2" fontWeight="bold">
          Date range:
          <Typography component="span" fontWeight="normal" variant="inherit">
            {" "}
            {dateLabel}
          </Typography>
        </Typography>
        {trendType && (
          <Typography variant="subtitle2" fontWeight="bold">
            Trend Setting:
            <Typography component="span" fontWeight="normal" variant="inherit">
              {" "}
              {trendSettingsTitlesByType.get(trendType)}
            </Typography>
          </Typography>
        )}
        {groupingLabel && (
          <Typography variant="subtitle2" fontWeight="bold">
            Group By:
            <Typography component="span" fontWeight="normal" variant="inherit">
              {" "}
              {groupingLabel?.singular}
            </Typography>
          </Typography>
        )}
      </Stack>
      {showOutDateRanges && isOutRangesDates && (
        <BillingExplorerExplainWithAiOutRangesAlert />
      )}
      {showExplainButton && (
        <Stack alignItems="center" pb={1.5}>
          {showLoading && loading ? (
            <BillingExplorerExplainWithAiLoading />
          ) : (
            <BillingExplorerExplainWithAiREMetaDataExplainButton />
          )}
        </Stack>
      )}
    </Stack>
  );
};
