import { FC } from "react";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

interface NotificationCustomMessagePreviewHeaderProps {
  collapsed: boolean;
  toggleCollapsed(): void;
  onEdit(): void;
}

export const NotificationCustomMessagePreviewHeader: FC<
  NotificationCustomMessagePreviewHeaderProps
> = ({ collapsed, toggleCollapsed, onEdit }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <IconButton onClick={toggleCollapsed} size="small">
          {!collapsed ? (
            <KeyboardArrowRightOutlinedIcon color="action" />
          ) : (
            <KeyboardArrowDownOutlinedIcon color="action" />
          )}
        </IconButton>

        <Typography variant="body2" fontWeight="medium">
          Message Preview
        </Typography>
      </Stack>
      <Button
        startIcon={<ModeEditOutlineOutlinedIcon fontSize="small" />}
        variant="outlined"
        size="xsmall"
        onClick={onEdit}
      >
        EDIT MESSAGE
      </Button>
    </Stack>
  );
};
