import { Components, Theme } from "@mui/material";

export const muiTab: Components<Theme>["MuiTab"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    }),
  },
};
