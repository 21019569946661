import { FC, Fragment, useMemo } from "react";
import { Stack, Tooltip } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import CompareIcon from "../../../../../../../../assets/images/icons/compare.svg";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { CostAnomaliesPrevDateDetails } from "../../../../adding-widget/widget-create/widget-setups/cost-anomalies/content/components/CostAnomaliesPrevDateDetails";
import {
  Dates,
  ResourceExplorerCostsDateDetails,
} from "../../../../../../../../services/cloudchipr.api";
import { generateDatesCompareData } from "../../../../../../../common/date-range-picker/utils/helpers/date-formatter/generateDatesCompareData";
import { DateInfoChip } from "../../../../adding-widget/widget-create/widget-setups/common/DateInfoChip";
import { WidgetCardHeaderEditButton } from "../../../common/widget-header/components/WidgetCardHeaderEditButton";

export interface CostAnomaliesWidgetCardHeaderDateProps {
  prevDate?: ResourceExplorerCostsDateDetails;
  date?: Dates;
  hovered?: boolean;
  widgetId?: string;
}

export const CostAnomaliesWidgetCardHeaderDate: FC<
  CostAnomaliesWidgetCardHeaderDateProps
> = ({ prevDate, date, hovered, widgetId }) => {
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dateInfo = useMemo(() => {
    return generateDatesCompareData({
      prevDate: prevDate,
      date,
      quarterStartMonth,
    });
  }, [quarterStartMonth, prevDate, date]);

  return (
    <Fragment>
      <DateInfoChip
        {...date}
        quarterStartMonth={quarterStartMonth}
        tooltipPlacement="top"
      />

      <Tooltip
        title={dateInfo.title}
        arrow
        placement="top"
        componentsProps={{ tooltip: { sx: { maxWidth: 630 } } }}
      >
        <Stack alignItems="center">
          <img src={CompareIcon} alt="compare" />
        </Stack>
      </Tooltip>

      <CostAnomaliesPrevDateDetails
        quarterStartMonth={quarterStartMonth}
        prevDates={{
          ...date,
          from: prevDate?.from_date,
          to: prevDate?.to_date,
        }}
      />

      {widgetId && (
        <WidgetCardHeaderEditButton
          widgetId={widgetId}
          hovered={!!hovered}
          widgetType="largest_cost_changes"
        />
      )}
    </Fragment>
  );
};
