import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCostAnomaliesWidgetDataByWidgetIdThunk } from "../data-get/getCostAnomaliesWidgetDataByWidgetIdThunk";
import { UpdateDashboardWidgetLargestCostChangesRequest } from "../../../../../../services/cloudchipr.api";
import { currentDashboardIdSelector } from "../../../../selectors/dashboard/currentDashboardIdSelector";
import { RootState } from "../../../../../store";
import { buildWidgetThunk } from "../../common/buildWidgetThunk";
import { costAnomaliesSetupSelector } from "../../../../selectors/setups/cost-anomalies/costAnomaliesSetupSelector";
import { costAnomaliesDefaultData } from "../../../../utils/constants/costAnomaliesDefaultData";
import { enqueueSnackbarErrorAlert } from "../../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const buildCostAnomaliesWidgetThunk = createAsyncThunk(
  "dashboards/buildCostAnomaliesWidget",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const dashboardId = currentDashboardIdSelector(state);
    const setup = costAnomaliesSetupSelector(state);

    if (!dashboardId || !setup?.viewId) {
      return;
    }

    const minCostChangeDefaultValue = setup?.id ? null : undefined;
    const minPercentageChangeDefaultValue = setup?.id ? null : undefined;

    const widget = {
      type: "largest_cost_changes",
      name: setup.name,
      date_to: setup.dates.to,
      view_id: setup.viewId,
      grouping: setup.grouping,
      group_values: setup.group_values ?? undefined,
      date_from: setup.dates.from,
      date_label: setup.dates.label,
      dates: setup.dates,
      sort_by: setup.sortingBy ?? undefined,
      price_direction: setup.priceDirection ?? undefined,
      items_count: setup.itemsCount ?? costAnomaliesDefaultData.itemsCount,
      min_cost_change:
        setup.minCostChange?.state && setup.minCostChange?.value
          ? setup.minCostChange?.value
          : minCostChangeDefaultValue,
      min_percentage_change:
        setup.minPercentChange?.state && setup.minPercentChange?.value
          ? setup.minPercentChange?.value
          : minPercentageChangeDefaultValue,
      layout: null,
    } as UpdateDashboardWidgetLargestCostChangesRequest;

    try {
      const response = await dispatch(
        buildWidgetThunk({
          widgetId: setup?.id,
          widget,
        }),
      ).unwrap();

      if (response && setup?.id) {
        await dispatch(getCostAnomaliesWidgetDataByWidgetIdThunk(response.id));
      }
      return response?.id;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
