import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import { blue } from "@mui/material/colors";

const KubernetesDaemonSetsSvgIcon = ({
  primary,
}: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? blue["700"] : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3783 1.64656C11.5446 1.55902 11.7372 1.51087 11.9298 1.50211C12.1706 1.48898 12.4113 1.53713 12.6258 1.64656L20.1504 5.23157C20.5444 5.42417 20.8333 5.78311 20.9296 6.20333L22.7899 14.2794C22.8862 14.6997 22.7768 15.1461 22.5141 15.4788L17.3052 21.9616C17.0294 22.2943 16.6092 22.5 16.1802 22.5H7.82831C7.39495 22.5 6.97473 22.2943 6.69896 21.9616L1.48998 15.4919C1.4681 15.4438 1.44183 15.4088 1.40681 15.3738C1.20108 15.0498 1.13104 14.6515 1.21421 14.2794L3.07456 6.20333C3.17086 5.78311 3.45976 5.42417 3.85372 5.23157L11.3783 1.64656ZM11.4177 2.26368C11.5753 2.18489 11.7547 2.14112 11.9342 2.13236C12.1618 2.11923 12.3851 2.163 12.5908 2.26368L19.6601 5.63858C20.0322 5.81805 20.3036 6.1551 20.3955 6.55343L22.1421 14.1437C22.234 14.5376 22.1333 14.9535 21.8838 15.273L16.99 21.3618C16.7273 21.677 16.3334 21.8696 15.9263 21.8696H8.07781C7.67072 21.8696 7.27677 21.677 7.01851 21.3618L2.12031 15.2818C2.09843 15.238 2.07654 15.203 2.04152 15.168C1.85329 14.8659 1.78326 14.4938 1.86205 14.1437L3.61297 6.55343C3.70052 6.1551 3.97191 5.81805 4.34398 5.63858L11.4177 2.26368Z"
          fill={color}
        />
        <path
          d="M9.54859 6.46597V7.72663H10.2052V7.12694H11.5665V6.46597H9.54859ZM12.8841 6.46597V7.12694H15.5148V6.46597H12.8841ZM16.8237 6.46597V7.12694H17.6553V8.26941H18.3119V6.79864C18.3119 6.61479 18.1675 6.46597 17.9836 6.46597H16.8237ZM7.64009 8.22126V9.74018H8.2923V8.87785H9.39538V8.22126H7.64009ZM10.7086 8.22126V8.87785H13.335V8.22126H10.7086ZM14.6525 8.22126V8.87785H15.7425V9.75769H16.3991V8.54956C16.4034 8.37009 16.2546 8.22126 16.0708 8.22126H14.6525ZM17.6553 9.5826V12.2134H18.3119V9.5826H17.6553ZM6.05551 9.97656C5.87604 9.97656 5.72721 10.121 5.72721 10.3005V15.9953C5.72721 16.1792 5.87166 16.3236 6.05551 16.3236H14.1623C14.3417 16.3236 14.4862 16.1792 14.4906 15.9953V14.5727H15.3004V13.9161H14.4906V10.3005C14.4862 10.121 14.3417 9.97656 14.1623 9.97656H6.05551ZM15.7425 11.0753V13.7016H16.3991V12.8174H16.9462V12.1608H16.3991V11.0753H15.7425ZM5.70533 16.757V17.523H14.4949V16.757H5.70533Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

KubernetesDaemonSetsSvgIcon.muiName = "KubernetesDaemonSetsSvgIcon";

export default KubernetesDaemonSetsSvgIcon;
