import { FC, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ReplayIcon from "@mui/icons-material/Replay";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { setAskAiConversation } from "../../../../../store/ask-ai/askAiSlice";
import { askAiLoadingSelector } from "../../../../../store/ask-ai/selectors/askAiLoadingSelector";

interface AskAiHeaderProps {
  onClose(): void;
}

export const AskAiHeader: FC<AskAiHeaderProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(askAiLoadingSelector);

  const clearChatHandler = useCallback(() => {
    dispatch(setAskAiConversation([]));
  }, [dispatch]);

  return (
    <Stack
      px={1}
      top={0}
      py={0.5}
      pt={1}
      spacing={1}
      bgcolor="white"
      direction="row"
      position="sticky"
      alignItems="center"
      justifyContent="space-between"
      zIndex={(theme) => theme.zIndex.appBar}
    >
      <Stack direction="row" spacing={1}>
        <AutoAwesomeIcon fontSize="small" color="primary" />
        <Typography variant="body1">Ask AI</Typography>
      </Stack>
      <Stack direction="row" spacing={0.3}>
        <Button
          startIcon={<ReplayIcon fontSize="small" />}
          onClick={clearChatHandler}
          size="small"
          disabled={loading}
        >
          Clear Chat
        </Button>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
