import { FC, useCallback, useMemo } from "react";
import {
  AxisLabelsFormatterContextObject,
  ChartSelectionCallbackFunction,
  Point,
} from "highcharts";
import moment from "moment/moment";
import { Box } from "@mui/material";
import { billingExplorerChartValuesPercentFormater } from "./utils/helpers/billingExplorerChartValuesPercentFormater";
import { getBillingExplorerColumnDataLabels } from "./utils/helpers/getBillingExplorerColumnDataLabels";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerWidgetDataLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/loading/resourceExplorerWidgetDataLoadingSelector";
import { moneyFormatter } from "../../../../../../../utils/numeral/moneyFormatter";
import { budgetDataOnHighChartSelector } from "../../../../../../../store/resource-explorer/selectors/budget/budgetDataOnChartSelector";
import { resourceExplorerShowBudgetLineSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerShowBudgetLineSelector";
import { resourceExplorerGroupingSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { money } from "../../../../../../../utils/numeral/money";
import { resourceExplorerChartBaseSelector } from "../../../../../../../store/resource-explorer/selectors/resourceExplorerChartBaseSelector";
import { MultiTypeHighcharts } from "../../../../../../../storybook/c8r-highcharts/MultiTypeHighcharts";
import { HighchartsChartType } from "../../../../../../../storybook/c8r-highcharts/common/components/HighchartsChartTypeSelect";

import { resourceExplorerChartDataSelectorV2 } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/resourceExplorerChartDataSelectorV2";
import { resourceExplorerPercentBasedChartDataSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/resourceExplorerPercentBasedChartDataSelector";
import { TooltipValueFormatterType } from "../../../../../../../storybook/c8r-highcharts/common/utils/tooltip/getHighchartsTooltipHTML";
import { resourceExplorerChartLabelsSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/resourceExplorerChartLabelsSelector";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { changeResourceExplorerDateThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer/changeResourceExplorerDateThunk";
import { resourceExplorerChartSettingsSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/settings/resourceExplorerChartSettingsSelector";

interface BillingExplorerChartProps {
  chartType: HighchartsChartType;
}

export const BillingExplorerChart: FC<BillingExplorerChartProps> = ({
  chartType,
}) => {
  const dispatch = useAppDispatch();

  const chartBase = useAppSelector(resourceExplorerChartBaseSelector);
  const budgetLineData = useAppSelector(budgetDataOnHighChartSelector);
  const showBudgetLine = useAppSelector(resourceExplorerShowBudgetLineSelector);
  const loading = useAppSelector(resourceExplorerWidgetDataLoadingSelector);
  const grouping = useAppSelector(resourceExplorerGroupingSelector);
  const chartDataCostBased = useAppSelector(
    resourceExplorerChartDataSelectorV2,
  );
  const chartDataPercentBased = useAppSelector(
    resourceExplorerPercentBasedChartDataSelector,
  );
  const chartLabels = useAppSelector(resourceExplorerChartLabelsSelector);
  const settings = useAppSelector(resourceExplorerChartSettingsSelector);

  const chartData =
    chartBase === "percent" ? chartDataPercentBased : chartDataCostBased;

  const columnDataLabels = useMemo(
    () => getBillingExplorerColumnDataLabels(chartType, chartBase),
    [chartBase, chartType],
  );

  const yAxisPlotLines = useMemo(() => {
    return budgetLineData && showBudgetLine ? budgetLineData : [];
  }, [budgetLineData, showBudgetLine]);

  const yAxisLabelsFormatter = useCallback(
    (params: AxisLabelsFormatterContextObject) => {
      return chartBase === "cost"
        ? `${moneyFormatter(+params.value)}`
        : `${+params.value}%`;
    },
    [chartBase],
  );

  const tooltipValueFormatter: TooltipValueFormatterType = useCallback(
    (point: Point) => {
      const costBasedData = chartDataCostBased?.series.find(
        (series) => series.name === point.series.name,
      )?.data;
      const percentBasedData = chartDataPercentBased?.series.find(
        (series) => series.name === point.series.name,
      )?.data;

      const value = costBasedData?.[point.index];
      const percent = percentBasedData?.[point.index] ?? 0;

      return {
        value: value ?? 0,
        markup: `<span style="font-weight: bold; font-size: 13px; ">
            ${money(value)}
            ${!percent ? "" : `<span style="color: #888; ">(${billingExplorerChartValuesPercentFormater(percent)})</span>`}
          </span>`,
      };
    },
    [chartDataCostBased, chartDataPercentBased],
  );

  const zoomHandler: ChartSelectionCallbackFunction = useCallback(
    (event) => {
      if (!event.xAxis) {
        return;
      }

      const minIndex = Math.floor(event.xAxis.at(0)?.min ?? 0);
      const maxIndex = Math.ceil(event.xAxis.at(0)?.max ?? 0);

      const datesFromLabels = Object.values(chartLabels ?? {});

      const start = datesFromLabels.at(minIndex);
      const end = datesFromLabels.at(maxIndex);

      const dateTo = formatDate(moment(end), {
        type: "yearMonthDay",
      });
      const dateFrom = formatDate(moment(start), {
        type: "yearMonthDay",
      });

      if (!dateTo || !dateFrom) {
        return;
      }

      dispatch(
        changeResourceExplorerDateThunk({
          label: null,
          from: dateFrom,
          to: dateTo,
        }),
      );

      return false; // Return false to prevent Highcharts default zoom
    },
    [dispatch, chartLabels],
  );

  return (
    <Box height={370}>
      <MultiTypeHighcharts
        chartData={chartData}
        chartType={chartType}
        onChartZoom={zoomHandler}
        chartScale={settings.chartScale}
        yAxisPlotLines={yAxisPlotLines}
        showLegends={settings.showLegends}
        percentBased={chartBase === "percent"}
        loading={loading ? loadingProps : false}
        yAxisLabelsFormatter={yAxisLabelsFormatter}
        showTotalValueInTooltip={grouping !== "none"}
        tooltipValueFormatter={tooltipValueFormatter}
        noDataMessage="No data by your selected date range and filters."
        columnDataLabels={settings.showLabels ? columnDataLabels : undefined}
        stackedChartHeadingLabelFormatter={
          chartBase === "cost" ? stackedChartHeadingLabelFormatter : undefined
        }
      />
    </Box>
  );
};

const stackedChartHeadingLabelFormatter = (value: number) =>
  `${moneyFormatter(value) ?? value}`;

const loadingProps = { showLoader: false };
