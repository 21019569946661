import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { azureReservationSelectedCoverageDataSelector } from "../../../../../../../../store/commitments/selectors/azure/reservations/details-drawer/resource-type/azureReservationSelectedCoverageDataSelector";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { CommitmentResourceType } from "../../../../../../../../services/cloudchipr.api";

import { KeyValueSplitChip } from "../../../../../../../common/chips/KeyValueSplitChip";

export const AzureReservationCoverageDrawerContentHeaderInfoCards: FC = () => {
  const coverageData = useAppSelector(
    azureReservationSelectedCoverageDataSelector,
  );
  const data = useMemo(() => {
    const resourceType = coverageData?.resource_type
      ?.resource_type as CommitmentResourceType;

    return {
      "Resource Type": resourceType ?? "",
      "Product Name": coverageData?.resource_type?.product_name,
      Subscription: coverageData?.account
        ? coverageData?.account?.name
        : "All Subscriptions",
      "Region/AZ": coverageData?.resource_type?.region,
    };
  }, [coverageData]);

  if (!data) {
    return null;
  }

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {Object.entries(data).map(([title, value]) => {
        return <KeyValueSplitChip key={title} title={title} value={value} />;
      })}
    </Stack>
  );
};
