import {
  FC,
  Fragment,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  Backdrop,
  LinearProgress,
  Popper,
  Stack,
  Portal,
  Theme,
} from "@mui/material";
import { useDidMount, useEffectOnceWhen } from "rooks";
import { useDispatch } from "react-redux";
import { SxProps } from "@mui/system";
import { CommentEditor } from "./comment-editor/CommentEditor";
import { Comment } from "./Comment";
import { getTaskActivitiesThunk } from "../../../../../../../../store/task-management/thunks/activities/getTaskActivitiesThunk";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { getTaskActivitiesDataSelector } from "../../../../../../../../store/task-management/selectors/activiities/getTaskActivitiesDataSelector";
import { getTaskActivitiesLoadingSelector } from "../../../../../../../../store/task-management/selectors/activiities/getTaskActivitiesLoadingSelector";
import { isCommentActivityValue } from "../../../../../../common/task-management/components/form/content/activities-section/utils/helpers/activityTypeCheckFns";

interface CommentCellPopoverProps {
  taskId: string;
  open: boolean;
  closeMenu(): void;
  commentsCount: number;
  anchor: HTMLElement | null;
}

export const CommentCellPopover: FC<CommentCellPopoverProps> = ({
  taskId,
  open,
  closeMenu,
  anchor,
  commentsCount,
}) => {
  const dispatch = useDispatch();
  const commentsRef = useRef<HTMLDivElement | null>(null);

  const activities = useAppSelector((state) =>
    getTaskActivitiesDataSelector(state, taskId),
  );

  const loading = useAppSelector((state) =>
    getTaskActivitiesLoadingSelector(state, taskId),
  );

  const closeClickHandler = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      closeMenu();
    },
    [closeMenu],
  );

  useDidMount(() => {
    if (!commentsCount) {
      return;
    }

    dispatch(getTaskActivitiesThunk(taskId));
  });

  useEffectOnceWhen(() => {
    commentsRef?.current?.scrollTo({
      top: commentsRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, !!commentsRef?.current);

  useEffect(() => {
    commentsRef?.current?.scrollTo({
      top: commentsRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [activities]);

  return (
    <Fragment>
      <Portal>
        <Backdrop open={open} onClick={closeClickHandler} sx={backdropStyles} />
      </Portal>

      <Popper
        open={open}
        anchorEl={anchor}
        placement="bottom-start"
        sx={popperStyles}
      >
        <Stack
          spacing={1}
          p={1}
          width={480}
          minHeight={60}
          borderRadius={1}
          onClick={stopPropagation}
          sx={popperStyles}
          bgcolor="white"
        >
          <Stack
            overflow="auto"
            spacing={1}
            maxHeight="40dvh"
            ref={commentsRef}
          >
            {activities?.map((activity) => {
              if (!isCommentActivityValue(activity?.updated_value)) {
                return null;
              }

              return (
                <Comment
                  key={taskId + activity.id}
                  createdAt={activity.created_at}
                  createdBy={activity.created_by}
                  comment={activity.updated_value.comment}
                  commentId={activity.id}
                  taskId={taskId}
                />
              );
            })}
          </Stack>

          {loading && <LinearProgress />}

          <CommentEditor taskId={taskId} focused={!commentsCount} />
        </Stack>
      </Popper>
    </Fragment>
  );
};

const backdropStyles: SxProps<Theme> = {
  zIndex: (theme) => theme.zIndex.drawer + 1,
  backgroundColor: "transparent",
};
const popperStyles: SxProps<Theme> = {
  zIndex: (theme) => theme.zIndex.drawer + 5,
  boxShadow: (theme) => theme.shadows[8],
};
const stopPropagation = (e: MouseEvent<HTMLDivElement>) => e.stopPropagation();
