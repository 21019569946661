import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCostAnomaliesWidgetDataBySetupThunk } from "../data-get/getCostAnomaliesWidgetDataBySetupThunk";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { costAnomaliesDefaultData } from "../../../../utils/constants/costAnomaliesDefaultData";
import { costAnomaliesSetupSelector } from "../../../../selectors/setups/cost-anomalies/costAnomaliesSetupSelector";
import { getResourceExplorerViewByIdThunk } from "../../../../../common/thunks/resource-explorer/getResourceExplorerViewByIdThunk";

export const costAnomaliesREViewChangeThunk = createAsyncThunk(
  "dashboards/costAnomaliesREViewChange",
  async (viewId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = costAnomaliesSetupSelector(state);

    if (!setup) {
      return;
    }

    dispatch(setWidgetSetup({ ...setup, viewId }));

    const response = await dispatch(
      getResourceExplorerViewByIdThunk({ viewId }),
    ).unwrap();

    if (!response) {
      return;
    }

    const { data } = response;

    const to = data?.to_date;
    const from = data?.from_date;
    const label = data?.date_label;

    dispatch(
      setWidgetSetup({
        ...(setup ?? costAnomaliesDefaultData),
        grouping: data.grouping === "resource" ? "service" : data.grouping,
        group_values: data.group_values,
        viewId: viewId,
        dates: {
          label,
          to,
          from,
        },
      }),
    );
    dispatch(getCostAnomaliesWidgetDataBySetupThunk());
  },
);
