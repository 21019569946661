import { FC } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface BillingExplorerChartSettingsPanelHeaderProps {
  onClose(): void;
}

export const BillingExplorerChartSettingsPanelHeader: FC<
  BillingExplorerChartSettingsPanelHeaderProps
> = ({ onClose }) => {
  return (
    <Stack
      top={0}
      p={1}
      direction="row"
      bgcolor="white"
      borderBottom={1}
      position="sticky"
      alignItems="center"
      borderColor="grey.300"
      justifyContent="space-between"
      zIndex={(theme) => theme.zIndex.drawer}
    >
      <Stack direction="row" spacing={1}>
        <Typography variant="body1" fontWeight="medium">
          Chart Settings
        </Typography>
      </Stack>

      <IconButton onClick={onClose} sx={{ p: 0.5 }}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
