import { FC, useCallback } from "react";
import { SwitchWithTypography } from "../../../../../../../../../../SwitchWithTypography";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";
import { billingExplorerViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerViewVisualizationChangeThunk";

export const BillingExplorerWidgetVisualizationTrend: FC = () => {
  const dispatch = useAppDispatch();
  const trendChecked = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("trend"),
  );

  const handleTrendCheckedChange = useCallback(
    (checked: boolean) => {
      dispatch(
        billingExplorerViewVisualizationChangeThunk({
          trend: checked,
        }),
      );
    },
    [dispatch],
  );

  return (
    <SwitchWithTypography
      checked={trendChecked}
      onChange={handleTrendCheckedChange}
      title="Trend"
    />
  );
};
