import { FC, useCallback, useMemo } from "react";
import { MenuItemProps } from "@mui/material/MenuItem/MenuItem";
import { useCurrentEditor } from "@tiptap/react";
import { capitalize, MenuItem, Stack, Typography } from "@mui/material";
import { TextAlignments } from "../../../utils/types/types";
import { textAlignIcons } from "../../../utils/constants/icons";

interface ToolbarTextAlignCommandMenuItemProps extends MenuItemProps {
  textAlign: TextAlignments;
  disableWhenActive?: boolean;
}

export const ToolbarTextAlignCommandMenuItem: FC<
  ToolbarTextAlignCommandMenuItemProps
> = ({ textAlign, disableWhenActive, ...props }) => {
  const { editor } = useCurrentEditor();
  const Icon = textAlignIcons[textAlign];

  const clickHandler = useCallback(
    (event: any) => {
      if (!editor) {
        return;
      }

      if (props.onClick) {
        props.onClick(event);
      }

      editor.chain().focus().setTextAlign(textAlign).run();
    },
    [editor, textAlign, props],
  );

  const disabled = useMemo(() => {
    if (!disableWhenActive || !editor) {
      return false;
    }

    return !editor.can().chain().focus().setTextAlign(textAlign).run();
  }, [editor, disableWhenActive, textAlign]);

  if (!editor) {
    return null;
  }

  const isActive = editor.isActive("textAlign", textAlign);

  return (
    <MenuItem
      {...props}
      value={textAlign}
      onClick={clickHandler}
      disabled={disabled}
      selected={isActive}
    >
      <Stack flex={1} direction="row" alignItems="center" spacing={1}>
        <Icon fontSize="small" sx={{ color: "grey.600" }} />
        <Typography variant="body2" fontWeight="nomral">
          {capitalize(textAlign)}
        </Typography>
      </Stack>
    </MenuItem>
  );
};
