import { RootState } from "../../../../../../store";
import { AzureSavingsPlanCoverageDataWithId } from "../../../../../../../components/pages/commtiments/common/utils/types";
import { azureSavingsPlansCoverageDataSelector } from "../../data/azureSavingsPlansCoverageDataSelector";
import { commitmentsDetailsDrawerTargetIdSelector } from "../../../../common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";

export const azureSavingsPlanSelectedCoverageDataSelector = (
  state: RootState,
): AzureSavingsPlanCoverageDataWithId | undefined => {
  const coverageData = azureSavingsPlansCoverageDataSelector(state);
  const id = commitmentsDetailsDrawerTargetIdSelector(state);

  return coverageData?.find((data) => data.id === id);
};
