import { RootState } from "../../../../store";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { currentUserEmailSelector } from "../../../../profile/currentUserEmailSelector";
import { kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../../kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";
import { generateLiveResourcesPath } from "../../../../../components/pages/accounts-group/utils/helpers/generateLiveResourcesPath";
import { money } from "../../../../../utils/numeral/money";
import { kubernetesLiveUsageMgmtSelectedResourcesClustersMetaDataSelector } from "../../../../kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtSelectedResourcesClustersMetaDataSelector";

export const kubernetesSlackMessageSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const userEmail = currentUserEmailSelector(state);

  const selectedResources =
    kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeSelector(
      state,
      resourceType,
    );

  const accountData =
    kubernetesLiveUsageMgmtSelectedResourcesClustersMetaDataSelector(
      state,
      resourceType,
    );

  if (!selectedResources) {
    return "";
  }

  const provider = getProviderName("kubernetes", {
    nameAndTitle: true,
    capitalize: true,
    plural: true,
  });

  const price = selectedResources.reduce(
    (acc, resource) => resource.resource.total_monthly_cost + acc,
    0,
  );

  const accountUrls = accountData?.reduce((result, item) => {
    const url = `${window.location.origin}${generateLiveResourcesPath({
      provider: "kubernetes",
      activeTab: "live-resources",
      accountIds: item.id,
    })}`;

    const comma = result.length ? "," : "";
    result = result + ` <a href="${url}">${comma}${item.name}</a>`;
    return result;
  }, "");

  const monthlyPrice = `Monthly Price - <b>${money(price)}</b>`;
  const user = userEmail
    ? `Sent from Cloudchipr by user - <a href="mailto:${userEmail}">${userEmail}</a> `
    : "";

  return `Please review attached resources and take necessary actions if required.<br/> <br/>  ${provider} - ${accountUrls} <br/> ${monthlyPrice} <br/> ${user} `;
};
