import { billingSummaryWidgetDataResponseSelector } from "./billingSummaryWidgetDataResponseSelector";
import { RootState } from "../../../../../store";
import { GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdApiResponse } from "../../../../../../services/cloudchipr.api";

export const billingSummaryWidgetDataSelector = (
  state: RootState,
  widgetId: string,
):
  | GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdApiResponse
  | undefined => {
  const response = billingSummaryWidgetDataResponseSelector(state, widgetId);

  return response?.data;
};
