import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../../store/hooks";
import { coverageWidgetSetupDataGroupsSelector } from "../../../../../../../../../../../../store/dashboards/selectors/widgets/coverage/setup/coverageWidgetSetupDataGroupsSelector";
import { coverageSetupVisualizationSelector } from "../../../../../../../../../../../../store/dashboards/selectors/setups/coverage/visualization/coverageSetupVisualizationSelector";
import { coverageWidgetSetupGroupingSelector } from "../../../../../../../../../../../../store/dashboards/selectors/setups/coverage/coverageWidgetSetupGroupingSelector";
import { widgetSetupWidgetDatesSelector } from "../../../../../../../../../../../../store/dashboards/selectors/setups/common/widgetSetupWidgetDatesSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { CoverageWidgetTableView } from "../../../../../../../../widgets/coverage/components/CoverageWidgetTableView";

export const CoverageWidgetSetupTableView: FC = () => {
  const groups = useAppSelector(coverageWidgetSetupDataGroupsSelector);
  const visualization = useAppSelector(coverageSetupVisualizationSelector);
  const grouping = useAppSelector(coverageWidgetSetupGroupingSelector);
  const dates = useAppSelector(widgetSetupWidgetDatesSelector);
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  return (
    <CoverageWidgetTableView
      groups={groups}
      visualization={visualization}
      dates={dates}
      grouping={grouping ?? "resource_type"}
      quarterStartMonth={quarterStartMonth}
    />
  );
};
