import { FC } from "react";
import {
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { CommitmentsProgressBar } from "../../../../../common/components/CommitmentsProgressBar";
import { money } from "../../../../../../../../utils/numeral/money";
import { getPercentage } from "../../../../../../../../utils/numeral/getPercentage";
import { getColorByValue } from "../../../../../common/utils/helpers/grid/dataGridHelpers";
import { CommitmentOverview } from "../../../../../../../../services/cloudchipr.api";

interface CommitmentsOverviewCommitmentsTableBodyProps {
  data: CommitmentOverview["commitments"] | null;
}
export const CommitmentsOverviewCommitmentsTableBody: FC<
  CommitmentsOverviewCommitmentsTableBodyProps
> = ({ data }) => {
  if (!data) {
    return null;
  }
  return (
    <TableBody>
      {data.map((item) => {
        const percent = +getPercentage(
          item.used_commitment_to_date ?? 0,
          item.total_commitment_to_date ?? 0,
        ).toFixed(2);
        const color = getColorByValue(percent);
        return (
          <TableRow key={item.name}>
            <TableCell sx={tableCellSx}>
              <TypographyWithTooltip
                title={item.name}
                variant="body2"
                fontWeight="bold"
              />
            </TableCell>
            <TableCell sx={tableCellSx}>
              <Stack direction="row" spacing={0.5} width="fit-content">
                <Typography variant="body2" fontWeight="medium" color={color}>
                  {item.used_commitment_to_date === null
                    ? "N/A"
                    : money(item.used_commitment_to_date)}
                </Typography>
              </Stack>
              <CommitmentsProgressBar
                value={percent}
                overrideColor={color}
                width="90%"
              />
            </TableCell>
            <TableCell sx={tableCellSx}>
              <Typography variant="body2" fontWeight="medium" textAlign="end">
                {item.total_commitment_to_date === null
                  ? "N/A"
                  : money(item.total_commitment_to_date)}
              </Typography>
            </TableCell>
            <TableCell sx={{ ...tableCellSx }}>
              <Typography variant="body2" fontWeight="medium" textAlign="end">
                {item.net_savings === null ? "N/A" : money(item.net_savings)}
              </Typography>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

const tableCellSx = {
  py: 1,
  borderBottom: "none",
};
