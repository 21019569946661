import { azureReservationsDataForGranularSelector } from "../azureReservationsDataForGranularSelector";
import { RootState } from "../../../../../../store";

export const azureReservationsNetSavingsSelector = (
  state: RootState,
): number | null => {
  const data = azureReservationsDataForGranularSelector(state);

  return data?.totals?.total_net_savings ?? null;
};
