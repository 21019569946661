import { getAzureUsersMeOrganisationsCurrentReservationsSelector } from "./getAzureUsersMeOrganisationsCurrentReservationsSelector";
import { RootState } from "../../../../../../store";
import { commitmentsDataGridPayloadDataSelector } from "../../../../common/payload/commitmentsDataGridPayloadDataSelector";

export const azureReservationsForTableSelector = (state: RootState) => {
  const payload = commitmentsDataGridPayloadDataSelector(state);

  return getAzureUsersMeOrganisationsCurrentReservationsSelector(payload)(
    state,
  );
};
