import { FC, Fragment, useCallback } from "react";
import { Stack } from "@mui/material";
import { CostAnomaliesPriceDirection } from "./app-bar/CostAnomaliesPriceDirection";
import { CostAnomaliesSortBy } from "./app-bar/CostAnomaliesSortBy";
import { CostAnomaliesSortByItemsCount } from "./app-bar/CostAnomaliesSortByItemsCount";
import { CostAnomaliesWidgetSetupContent } from "./content/CostAnomaliesWidgetSetupContent";
import { CostAnomaliesGroupingSelect } from "./app-bar/CostAnomaliesGroupingSelect";
import { CostAnomaliesMinChange } from "./app-bar/CostAnomaliesMinChange";
import { CostAnomaliesDateRangeSelect } from "./app-bar/CostAnomaliesDateRangeSelect";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import { WidgetsViewsHierarchySelect } from "../common/widgets-views-hierarchy-select/WidgetsViewsHierarchySelect";
import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { costAnomaliesREViewChangeThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-anomalies/widget-change/costAnomaliesREViewChangeThunk";
import { costAnomaliesSetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/costAnomaliesSetupPropertyByKeySelector";
import { buildCostAnomaliesWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-anomalies/actions/buildCostAnomaliesWidgetThunk";
import { costAnomaliesSubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/costAnomaliesSubmitButtonDisabledSelector";

interface CostAnomaliesSetupProps {
  onClose(): void;
  onSubmit(): void;
}
export const CostAnomaliesWidgetSetup: FC<CostAnomaliesSetupProps> = ({
  onClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();

  const viewId = useAppSelector(
    costAnomaliesSetupPropertyByKeySelector("viewId"),
  );
  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );
  const setupId = useAppSelector(costAnomaliesSetupPropertyByKeySelector("id"));
  const disabled = useAppSelector(costAnomaliesSubmitButtonDisabledSelector);

  const viewChangeHandler = useCallback(
    (id: string) => {
      dispatch(costAnomaliesREViewChangeThunk(id));
    },
    [dispatch],
  );

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(buildCostAnomaliesWidgetThunk()).unwrap();
    onSubmit();

    if (responseId && !setupId) {
      scrollPageToBottom();
    }
  }, [dispatch, setupId, onSubmit]);

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />

      <WidgetSetupLayout
        empty={!viewId ? "Select Billing Report to get started." : ""}
        appBar={
          <Fragment>
            <WidgetsViewsHierarchySelect
              selectedItemId={viewId}
              onChange={viewChangeHandler}
            />

            <CostAnomaliesGroupingSelect disabled={viewGettingLoading} />

            <CostAnomaliesPriceDirection />

            <CostAnomaliesDateRangeSelect />

            <Stack direction="row" spacing={2}>
              <CostAnomaliesSortBy />
              <CostAnomaliesSortByItemsCount />
            </Stack>

            <CostAnomaliesMinChange type="minCostChange" />
            <CostAnomaliesMinChange type="minPercentChange" />
          </Fragment>
        }
      >
        <CostAnomaliesWidgetSetupContent />
      </WidgetSetupLayout>
    </Fragment>
  );
};
