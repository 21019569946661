import { createAsyncThunk } from "@reduxjs/toolkit";
import { getKubernetesLiveUsageMgmtResourcesWithOutFiltersThunk } from "./resources/getKubernetesLiveUsageMgmtResourcesWithOutFiltersThunk";
import { kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector } from "../selectors/store-selectors/kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector";
import { RootState } from "../../store";
import { setKubernetesLiveUsageMgmtFilterTemplatesEnabled } from "../kubernetesLiveUsageMgmtSlice";

export const kubernetesLiveUsageMgmtFilterTemplateEnabledChangeThunk =
  createAsyncThunk(
    "liveUsageMgmt/kubernetesLiveUsageMgmtFilterTemplateEnabledChange",
    async (_: void, { dispatch, getState }) => {
      const state = getState() as RootState;
      const filterTemplatesEnabled =
        kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector(state);

      const value = !filterTemplatesEnabled;
      dispatch(setKubernetesLiveUsageMgmtFilterTemplatesEnabled(value));

      if (!value) {
        dispatch(getKubernetesLiveUsageMgmtResourcesWithOutFiltersThunk());
      }
    },
  );
