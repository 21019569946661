import { FC, useCallback } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { DateSelectionMonthComponent } from "./DateSelectionMonthComponent";
import { BudgetPeriod } from "../../../../../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { budgetCreateYears } from "../utils/constants/date-selection";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { isCurrentBudgetEditingSelector } from "../../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/isCurrentBudgetEditingSelector";

interface StartDateSelectionProps {
  period: BudgetPeriod;
  startDate: string;
  onDateChange(date: string): void;
}

export const StartDateSelection: FC<StartDateSelectionProps> = ({
  period,
  startDate,
  onDateChange,
}) => {
  const isEdit = useAppSelector(isCurrentBudgetEditingSelector);

  const dateChangeHandler = useCallback(
    (date: any) => onDateChange(formatDate(date)),
    [onDateChange],
  );

  const yearChangeHandler = useCallback(
    (event: any) => {
      const newDate = moment(startDate || undefined).set(
        "year",
        event.target.value,
      );

      onDateChange(formatDate(newDate));
    },
    [onDateChange, startDate],
  );

  if (period === "daily") {
    return (
      <DatePicker
        value={moment(startDate)}
        label="Start Day"
        disabled={isEdit}
        minDate={moment().subtract(2, "year")}
        onChange={dateChangeHandler}
        sx={{ width: "50%" }}
        slotProps={{
          textField: {
            fullWidth: true,
            placeholder: "Start Day",
            size: "small",
            variant: "outlined",
            name: "value",
          },
        }}
      />
    );
  }

  const selectedYear = moment(startDate || undefined).get("year");

  const YearSelectionComponent = (
    <FormControl size="small" fullWidth>
      <InputLabel>Start Year</InputLabel>
      <Select
        fullWidth
        disabled={isEdit}
        size="small"
        value={startDate ? selectedYear : ""}
        label="Start Year"
        renderValue={(year) => year}
        onChange={yearChangeHandler}
      >
        {budgetCreateYears.map((year) => {
          return (
            <MenuItem value={year} key={year}>
              <ListItemText>{year}</ListItemText>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  if (period === "annually") {
    return <Box width="50%">{YearSelectionComponent}</Box>;
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      width="100%"
    >
      <Box flexGrow={1}>
        <DateSelectionMonthComponent
          period={period}
          startDate={startDate}
          onDateChange={onDateChange}
        />
      </Box>

      <Box flexGrow={1}>{YearSelectionComponent}</Box>
    </Stack>
  );
};
