import { commitmentUtilizationWidgetByIdSelector } from "./commitmentUtilizationWidgetByIdSelector";
import { RootState } from "../../../../../store";
import { awsOrganizationWithCommitmentsSelector } from "../../../../../commitments/selectors/organisations/awsOrganizationWithCommitmentsSelector";

export const commitmentUtilizationChangesWidgetNotFoundSelector = (
  state: RootState,
  widgetId: string,
) => {
  const widget = commitmentUtilizationWidgetByIdSelector(state, widgetId);
  const awsOrganizations = awsOrganizationWithCommitmentsSelector(state);

  if (!widget) {
    return false;
  }

  return !awsOrganizations?.find(
    (item) => item.organization_id === widget.provider_organisation_id,
  );
};
