import { FC } from "react";
import Chip from "@mui/material/Chip";
import { Box } from "@mui/material";
import { ResourceType } from "../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../utils/helpers/resources/getResourceTypeName";
import { TypographyWithTooltip } from "../TypographyWithTooltip";
import { IconByResourceType } from "../../pages/common/IconByResourceType";

interface ServiceCellProps {
  service: ResourceType;
}

export const ServiceCell: FC<ServiceCellProps> = ({ service }) => {
  return (
    <Chip
      sx={{ borderRadius: 2, px: 0.5 }}
      avatar={
        <Box sx={{ filter: "grayscale(1)" }}>
          <IconByResourceType resourceType={service} />
        </Box>
      }
      label={
        <TypographyWithTooltip
          variant="body2"
          title={getResourceTypeName(service, {
            type: "long",
            singular: true,
          })}
        />
      }
    />
  );
};
