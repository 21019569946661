import { FC, Fragment, useCallback, useState } from "react";
import { Alert, Button, Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import { ConfirmationDialog } from "../../../../../../../../../../common/modals/ConfirmationDialog";
import { useAppDispatch } from "../../../../../../../../../../../store/hooks";
import { useDialog } from "../../../../../../../../../../../utils/hooks/useDialog.hook";
import {
  TagKey,
  ProviderType,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { enableCostAllocationTagKeyThunk } from "../../../../../../../../../../../store/resource-explorer/thunks/common/enableCostAllocationTagKeyThunk";

interface CostAllocationTagItemProps {
  tag: TagKey;
  provider: ProviderType;
}

export const CostAllocationTagItem: FC<CostAllocationTagItemProps> = ({
  tag,
  provider,
}) => {
  const [enabled, setEnabled] = useState(false);
  const dispatch = useAppDispatch();
  const { open, openDialog, closeDialog } = useDialog();

  const enableHandler = useCallback(async () => {
    await dispatch(enableCostAllocationTagKeyThunk({ key: tag.key, provider }));
    closeDialog();
    setEnabled(true);
  }, [dispatch, closeDialog, provider, tag.key]);

  return (
    <Fragment>
      <Stack
        px={2}
        py={1}
        key={tag.key}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TypographyWithTooltip title={tag.key} variant="body2" maxWidth="80%" />

        {tag.is_active || enabled ? (
          <Stack direction="row" spacing={1}>
            <Typography
              variant="subtitle2"
              color="success.main"
              fontWeight="medium"
            >
              Enabled
            </Typography>
            <Tooltip
              arrow
              placement="left"
              title="Once enabled, the tag may take up to 12 hours to appear in the Billing Reports filters."
            >
              <InfoOutlinedIcon color="action" fontSize="small" />
            </Tooltip>
          </Stack>
        ) : (
          <Button size="small" variant="outlined" onClick={openDialog}>
            Enable
          </Button>
        )}
      </Stack>

      {open && (
        <ConfirmationDialog
          open={open}
          title="Enable Tag"
          dialogContent={
            <Stack spacing={2}>
              <Typography variant="body1" color="text.primary">
                Please confirm to enable{" "}
                <Typography fontWeight="bold" component="span">
                  {tag.key}
                </Typography>{" "}
                tag.
              </Typography>
              <Alert severity="info" variant="outlined">
                Once enabled, the tag may take up to 12 hours to appear in the
                Billing Reports filters.
              </Alert>
            </Stack>
          }
          CancelButtonProps={{ onClick: closeDialog }}
          ConfirmButtonProps={{
            children: "Enable",
            onClick: enableHandler,
          }}
          onClose={closeDialog}
        />
      )}
    </Fragment>
  );
};
