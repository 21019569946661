import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { DynamoDbBackupSource } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const dynamoDbBackupSourceTableColumns: ColumnSetupType<DynamoDbBackupSource>[] =
  [
    {
      accessorKey: "name",
      header: "Table Name",
      type: "identifier",
      size: 210,
      meta: { sticky: "left", hideFromSettings: true },
    },
    {
      accessorKey: "price_per_month",
      header: "Monthly Price",
      type: "money",
      headerTooltip:
        "Monthly prices are calculated based on the on-demand list price of each resource.",
    },
    {
      accessorKey: "account",
      header: "Account",
      type: "account",
      size: 210,
    },
    {
      accessorKey: "status",
      header: "Table Status",
      type: "dynamoDbBackupTableStatus",
      size: 210,
    },
    {
      accessorKey: "db_price_ratio",
      header: "Backups Price Ratio",
      headerTooltip:
        "Shows the monthly cost of DynamoDB backups as a percentage of the DynamoDB table cost, helping you see how backup spending compares to your table. For example, if a table costs $5,000 per month and backups total $4,000, the ratio is 80%.",
      type: "dbPriceRatio",
      size: 210,
    },
    {
      accessorKey: "table_class",
      header: "Table Class",
      size: 210,
    },
    {
      accessorKey: "backup_count",
      header: "Backups pet Table ",
      size: 210,
    },
    { accessorKey: "region", header: "Region", type: "withCopy" },
    {
      accessorKey: "created_at",
      header: "Date Created",
      type: "date",
    },
    {
      accessorKey: "all_time_spent",
      header: "Total Spend",
      type: "money",
    },
    {
      accessorKey: "creation_data",
      header: "Created By",
      type: "createdBy",
      headerTooltip:
        "The Created By value is only available for resources created within the last 90 days.",
    },
    { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
    {
      accessorKey: "tags",
      header: "Smart Tags",
      id: "smart_tag",
      type: "smartTag",
      enableSorting: false,
    },

    {
      accessorKey: "task",
      header: "Task Status",
      type: "taskStatus",
      enableSorting: false,
    },
  ];
