import { savingsOpportunitiesSelectedRowsIdsSelector } from "./savingsOpportunitiesSelectedRowsIdsSelector";
import { RootState } from "../../../store";
import { savingsOpportunitiesDataSelector } from "../savingsOpportunitiesDataSelector";

export const savingsOpportunitiesSelectedItemsSelector = (state: RootState) => {
  const ids = savingsOpportunitiesSelectedRowsIdsSelector(state);
  const data = savingsOpportunitiesDataSelector(state);

  return data?.filter((savingsOpportunity) =>
    ids.includes(savingsOpportunity.id),
  );
};
