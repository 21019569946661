import { FC } from "react";

import { Stack, Typography } from "@mui/material";
import { KubernetesClusterCardFooterProgressDescriptionItem } from "../KubernetesClusterCardFooterProgressDescriptionItem";
import { getPercentage } from "../../../../../../../../../../utils/numeral/getPercentage";

interface KubernetesClusterCardFooterTooltipItemProps {
  cost: number;
  unit?: "gib" | "core";
  total?: number;
  title: string;
  color: string;
}
export const KubernetesClusterCardFooterTooltipItem: FC<
  KubernetesClusterCardFooterTooltipItemProps
> = ({ cost, unit, total, title, color }) => {
  const safeTotal = total || 1;
  const costPercent =
    safeTotal > 0 ? getPercentage(cost, safeTotal).toFixed(2) : 0;

  const showPercent = !!total;
  return (
    <Stack direction="row" justifyContent="space-between" spacing={1}>
      <KubernetesClusterCardFooterProgressDescriptionItem
        title={title}
        color={color}
      />
      <Stack direction="row" spacing={0.25}>
        {showPercent && (
          <Typography
            variant="caption"
            color="text.secondary"
            fontWeight="bold"
          >
            {costPercent}%
          </Typography>
        )}

        <Typography variant="caption" color="text.secondary" fontWeight="bold">
          {showPercent && " ("}
          {cost} {unit === "core" ? "Core" : "GiB"}
          {showPercent && ")"}
        </Typography>
      </Stack>
    </Stack>
  );
};
