import { createAsyncThunk } from "@reduxjs/toolkit";
import { taskManagementGetTasksThunk } from "./taskManagementGetTasksThunk";
import { getOrganisationUsersThunk } from "../../../user/thunks/getOrganisationUsersThunk";
import { openDrawerForTaskByIdThunk } from "../openDrawerForTaskByIdThunk";

export const initiateTaskManagementThunk = createAsyncThunk(
  "taskManagement/initiateTaskManagement",
  async (taskId: string | null, { dispatch }) => {
    dispatch(getOrganisationUsersThunk());

    await dispatch(taskManagementGetTasksThunk());

    if (taskId) {
      dispatch(openDrawerForTaskByIdThunk(taskId));
    }
  },
);
