import { RootState } from "../../../../store";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector } from "../kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector } from "../../store-selectors/kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector";
import { kubernetesLiveUsageMgmtPostUsersMeOrganisationsCurrentKubernetesByTypeResources } from "../kubernetesLiveUsageMgmtPostUsersMeOrganisationsCurrentKubernetesByTypeResources";
import { getKubernetesResourceTypeDataWithoutFiltersFixedCacheKey } from "../../../thunks/resources/getKubernetesLiveUsageMgmtResourcesWithOutFiltersByResourceTypeThunk";

export const kubernetesLiveUsageMgmtResourceTypeDataLoadingSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const withoutFilters =
    kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector(
      state,
      resourceType,
    );
  const filterTemplatesEnabled =
    kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector(state);

  const fixedCacheKey =
    withoutFilters || !filterTemplatesEnabled
      ? getKubernetesResourceTypeDataWithoutFiltersFixedCacheKey(resourceType)
      : resourceType;

  return kubernetesLiveUsageMgmtPostUsersMeOrganisationsCurrentKubernetesByTypeResources(
    fixedCacheKey,
  )(state)?.isLoading;
};
