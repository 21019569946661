import { FC, useMemo } from "react";
import {
  Button,
  ButtonProps,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { useAppSelector } from "../../../../../../store/hooks";
import { tasksListGetLoadingSelector } from "../../../../../../store/task-management/selectors/loading/tasksListGetLoadingSelector";
import {
  floatingToolbarDarkActive,
  floatingToolbarDarkHover,
  floatingToolbarDisabled,
  floatingToolbarLight,
} from "../utils/constants/colors";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";

export interface FloatingActionButtonProps
  extends Omit<ButtonProps, "startIcon"> {
  icon: SvgIconComponent;
}

export const FloatingActionButton: FC<FloatingActionButtonProps> = ({
  icon: Icon,
  disabled,
  onClick,
  ...props
}) => {
  const tasksLoading = useAppSelector(tasksListGetLoadingSelector);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1200));
  const startIcon = useMemo(() => {
    return (
      <Icon
        fontSize="small"
        sx={{
          color:
            disabled || tasksLoading
              ? floatingToolbarDisabled
              : floatingToolbarLight,
        }}
      />
    );
  }, [Icon, disabled, tasksLoading]);

  if (isSmallScreen) {
    return (
      <Tooltip arrow placement="top" title={props.children}>
        <IconButton
          size="small"
          disabled={tasksLoading || disabled}
          onClick={onClick}
          sx={iconButtonStyles}
        >
          {startIcon}
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Button
      color="tertiary"
      variant="text"
      sx={buttonStyle}
      onClick={onClick}
      {...props}
      disabled={tasksLoading || disabled}
      startIcon={startIcon}
    >
      <TypographyWithTooltip
        title={props.children}
        variant="inherit"
        color={
          tasksLoading || disabled
            ? floatingToolbarDisabled
            : floatingToolbarLight
        }
      />
    </Button>
  );
};

const actionStyles = {
  "&:hover": {
    backgroundColor: floatingToolbarDarkHover,
  },
  "&:active": {
    backgroundColor: floatingToolbarDarkActive,
  },
};

const iconButtonStyles = {
  borderRadius: 1,
  ...actionStyles,
};

const buttonStyle = {
  textTransform: "none",
  py: 0.25,
  "& .MuiButton-startIcon": { marginRight: 0.75 },
  ...actionStyles,
};
