import { accountsByProviderSelector } from "./accountsByProviderSelector";
import { orgsByProviderSelector } from "./orgsByProviderSelector";
import { Account, ProviderType } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { kubernetesClustersSelector } from "../kubernetes/kubernetesClustersSelector";
import { noOrgProviderOrganisationOrgId } from "../../../commitments/selectors/organisations/utils/constants";

type OrgWithAccounts = {
  orgId: string;
  accounts: Account[];
};

export const biggestOrgByProviderSelector = (
  state: RootState,
  provider: ProviderType,
) => {
  const accountsByProvider = accountsByProviderSelector(state, provider);
  const orgsByProvider = orgsByProviderSelector(state, provider);
  const clusters = kubernetesClustersSelector(state);

  if (provider === "kubernetes") {
    return {
      orgId: noOrgProviderOrganisationOrgId,
      accounts: clusters ?? [],
    };
  }

  if (!accountsByProvider) {
    return;
  }

  return orgsByProvider.reduce<OrgWithAccounts | undefined>(
    (maxOrg, org) =>
      org.accounts.length > (maxOrg?.accounts.length ?? 0) ? org : maxOrg,
    undefined,
  );
};
