import { ValueGetterParams } from "ag-grid-community";
import { capitalize } from "@mui/material";
import { Task } from "../../../../../../services/cloudchipr.api";

export const taskCreatedByFilterValueGetter = ({
  data,
}: ValueGetterParams<Task>) => {
  const createdBy = data?.created_by?.name ?? data?.created_by?.email;

  if (!createdBy) {
    return;
  }

  return createdBy
    .split(" ")
    .map((name) => capitalize(name))
    .join(" ");
};
