import { Collapse, Stack } from "@mui/material";
import { useToggle } from "rooks";
import { FC, Fragment, useMemo } from "react";
import { BillingExplorerExplainWithAiContentNotCollapsedState } from "./BillingExplorerExplainWithAiContentNotCollapsedState";
import { ExplainWithAiBillingExplorerMetaData } from "../re-meta-data/ExplainWithAiBillingExplorerMetaData";
import { BillingExplorerExplainWithAiAnomalies } from "../BillingExplorerExplainWithAiAnomalies";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { billingExplorerExplainWithAiDataSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiDataSelector";
import { ExplainWithAiData } from "../../../../../../../../store/resource-explorer/utils/types/resourceExplorer";

interface BillingExplorerExplainWithAiDataNotCollapsedItemProps {
  item: ExplainWithAiData;
  index: number;
  collapsable?: boolean;
  localLoading?: boolean;
}
export const BillingExplorerExplainWithAiContentCollapsedItem: FC<
  BillingExplorerExplainWithAiDataNotCollapsedItemProps
> = ({ item, index, collapsable, localLoading }) => {
  const explainWithAiData = useAppSelector(
    billingExplorerExplainWithAiDataSelector,
  );
  const [state, toggle] = useToggle(false);

  const content = useMemo(() => {
    if (!item.payload?.group_by) {
      return null;
    }
    return (
      <Stack p={1.5} pb={0.5}>
        <ExplainWithAiBillingExplorerMetaData
          grouping={item.payload?.group_by}
          trendType={item.payload?.trend_type}
          dates={item.payload?.dates}
          showExplainButton={explainWithAiData.length > 1 && index === 0}
          index={index}
          showLoading
          showOutDateRanges={explainWithAiData.length === 1}
        />
        {!(explainWithAiData.length > 1 && index === 0) && (
          <BillingExplorerExplainWithAiAnomalies
            index={index}
            localLoading={localLoading}
          />
        )}
      </Stack>
    );
  }, [item, index, explainWithAiData, localLoading]);

  if (!item.payload?.group_by) {
    return null;
  }

  return (
    <Fragment>
      {collapsable && (
        <BillingExplorerExplainWithAiContentNotCollapsedState
          onClick={toggle}
          open={state}
        />
      )}
      {collapsable ? (
        <Collapse in={collapsable ? state : true}>{content}</Collapse>
      ) : (
        content
      )}
    </Fragment>
  );
};
