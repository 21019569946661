import { FC, Fragment } from "react";
import { capitalize, Typography } from "@mui/material";
import { ExampleTypography } from "./ExampleTypography";
import { ReportsCustomMessageSubject } from "./ReportsCustomMessageSubject";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { reportsDataPropertyByKeySelector } from "../../../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";
import { reportsOpenDrawerSelector } from "../../../../../../../../store/reports/selector/reportsOpenDrawerSelector";
import { getLabelByBillingSourceType } from "../../../../../../../utils/helpers/getLabelByBillingSourceType";
import { NestedIntegration } from "../../../../../../../../services/cloudchipr.api";

interface ReportsDrawerNotificationsCustomMessageBeforeMessageProps {
  type: NestedIntegration["type"];
}

export const ReportsDrawerNotificationsCustomMessageBeforeMessage: FC<
  ReportsDrawerNotificationsCustomMessageBeforeMessageProps
> = ({ type }) => {
  const source = useAppSelector(
    reportsDataPropertyByKeySelector("source_type"),
  );
  const isSubscriptionMessage = useAppSelector(reportsOpenDrawerSelector);

  const fullSourceLabel =
    source === "view"
      ? `billing ${getLabelByBillingSourceType(source)}`
      : getLabelByBillingSourceType(source);

  return (
    <Fragment>
      {type === "email" && <ReportsCustomMessageSubject />}

      {isSubscriptionMessage ? (
        <Fragment>
          <Typography variant="body1" lineHeight={1.5}>
            Please find the <ExampleTypography>frequency</ExampleTypography>{" "}
            update attached for{" "}
            <ExampleTypography>{`${fullSourceLabel} name`}</ExampleTypography>{" "}
            {fullSourceLabel}.
          </Typography>
          <Typography variant="body1" lineHeight={1.5}>
            Subscription -{" "}
            <ExampleTypography>subscription name</ExampleTypography>
          </Typography>
          <Typography variant="body1" lineHeight={1.5}>
            {capitalize(fullSourceLabel)} -{" "}
            <ExampleTypography>{`${fullSourceLabel} name`}</ExampleTypography>
          </Typography>
          <Typography variant="body1" lineHeight={1.5}>
            Subscription created by -{" "}
            <ExampleTypography>user name</ExampleTypography>
          </Typography>
        </Fragment>
      ) : (
        <Typography variant="body1" lineHeight={1.5}>
          Please find the attached report, generated from the{" "}
          <ExampleTypography>{`${fullSourceLabel} name`}</ExampleTypography>{" "}
          {fullSourceLabel} and sent by{" "}
          <ExampleTypography>user name</ExampleTypography> via Cloudchipr.
        </Typography>
      )}
    </Fragment>
  );
};
