import * as yup from "yup";
import { getEmailsValidationSchema } from "../../../../../../../../../../common/integration-dialogs/components/email/utils/validation";
import { Emails } from "../../../../../../../../../../../services/cloudchipr.api";
import { integrationNotificationValidationSchema } from "../../../../../../../../../common/notification-selection/utils/validation/validation";

export const validationSchema = yup.object({
  name: yup.string().required("This field is required."),
  viewId: yup.string().required("This field is required."),
  period: yup.string().required("This field is required."),
  startDate: yup.string().required("This field is required."),
  amount: yup
    .number()
    .min(1, "Minimum value is 1")
    .max(Number.MAX_SAFE_INTEGER, `Maximum value is ${Number.MAX_SAFE_INTEGER}`)
    .required(""),

  thresholds: yup
    .array()
    .of(
      yup.object({
        percent: yup
          .number()
          .min(1, "This field is required.")
          .required("This field is required."),
      }),
    )
    .min(1, "At least one threshold should be selected."),

  email_to: getEmailsValidationSchema(true),

  notifications: yup
    .array()
    .nullable()
    .when("email_to", {
      is: (email_to: Emails | null) => !!email_to,
      then: yup.array().of(integrationNotificationValidationSchema).nullable(),
      otherwise: yup
        .array()
        .of(integrationNotificationValidationSchema)
        .min(1, "At least one integration should be selected."),
    }),
});
