import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { billingSummarySetupWidgetPayloadDataSelector } from "../../../../selectors/setups/billing-summary/billingSummarySetupWidgetPayloadDataSelector";
import { viewNotFoundSelector } from "../../../../../resource-explorer/selectors/all-resource-explorers/viewNotFoundSelector";
import { enqueueSnackbarErrorAlert } from "../../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getBillingSummaryWidgetDataBySetupThunk = createAsyncThunk(
  "dashboards/getBillingSummaryWidgetDataBySetup",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummary } =
      cloudChiprApi.endpoints;
    const payload = billingSummarySetupWidgetPayloadDataSelector(state);

    if (!payload?.viewId) {
      return;
    }

    const viewNotFound = viewNotFoundSelector(state, payload.viewId);

    if (viewNotFound) {
      return;
    }

    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummary.initiate(
          payload,
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
