import { FC } from "react";
import {
  Card,
  Divider,
  Table,
  TableContainer,
  Typography,
} from "@mui/material";
import { CommitmentsOverviewEmptyState } from "./CommitmentsOverviewEmptyState";
import { CommitmentsOverviewCoverageTableBody } from "./CommitmentsOverviewCoverageTableBody";
import { CommitmentsOverviewContentLoading } from "./CommitmentsOverviewContentLoading";
import { ResourceTypeCoverage } from "../../../../../../../services/cloudchipr.api";
import { CommitmentsOverviewCoverageTableHeader } from "../coverage/components/CommitmentsOverviewCoverageTableHeader";

interface CommitmentsOverviewCoverageProps {
  data: ResourceTypeCoverage[] | null;
  loading: boolean;
}

export const CommitmentsOverviewCoverage: FC<
  CommitmentsOverviewCoverageProps
> = ({ data, loading }) => {
  const content =
    data?.length === 0 ? (
      <CommitmentsOverviewEmptyState dataType="coverage" />
    ) : (
      <TableContainer>
        <Table size="small" sx={{ my: 1 }}>
          <CommitmentsOverviewCoverageTableHeader />
          <CommitmentsOverviewCoverageTableBody data={data} />
        </Table>
      </TableContainer>
    );

  return (
    <Card variant="outlined" sx={{ flex: 1 }}>
      <Typography variant="subtitle2" color="text.secondary" px={2} py={1}>
        Coverage
      </Typography>
      <Divider />

      {loading ? <CommitmentsOverviewContentLoading /> : content}
    </Card>
  );
};
