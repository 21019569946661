import { FC } from "react";
import { TaskActivityWrapper } from "./TaskActivityWrapper";
import { ActivityComponentProps } from "../../utils/types/types";
import { CommentUser } from "../../../../../../../../task-management/components/grid/cell-renderers/comment-cell/components/CommentUser";

export const CreationActivity: FC<ActivityComponentProps> = ({
  createdAt,
  createdBy,
}) => {
  return (
    <TaskActivityWrapper
      content={
        <CommentUser user={createdBy} additionalText="created this task" />
      }
      createdAt={createdAt}
    />
  );
};
