import { FC } from "react";
import { Stack } from "@mui/material";
import { grey, teal } from "@mui/material/colors";
import { CoverageWidgetChart } from "./CoverageWidgetChart";
import { MultiTypeChartProvider } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import {
  ChartDataType,
  ChartType as MultiChartType,
} from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import {
  ChartBaseType,
  ChartType,
  CoverageGroupResponse,
} from "../../../../../../../services/cloudchipr.api";

interface CoverageWidgetChartViewProps {
  chartType?: ChartType;
  hasAggregation: boolean;
  chartData?: ChartDataType[];
  groups?: CoverageGroupResponse[];
  chartBase?: ChartBaseType;
}

export const CoverageWidgetChartView: FC<CoverageWidgetChartViewProps> = ({
  chartType,
  chartData,
  hasAggregation,
  chartBase,
  groups,
}) => {
  return (
    <Stack flex={1} p={2} justifyContent={hasAggregation ? "start" : "center"}>
      <MultiTypeChartProvider
        value={{
          data: chartData ?? [],
          initialChartType: chartType as MultiChartType,
          disabledKeysSorting: true,
          colors,
        }}
      >
        <CoverageWidgetChart
          chartType={chartType as MultiChartType}
          chartBase={chartBase}
          groups={groups}
        />
      </MultiTypeChartProvider>
    </Stack>
  );
};

const colors = {
  SP: teal[300],
  RI: "#2196F3",
  "On-Demand": grey[400],
};
