import { ColDef } from "ag-grid-community";
import { TaskOwner } from "../../../../../../services/cloudchipr.api";

export const ownerComparatorFn =
  (priorities?: TaskOwner[]): ColDef["comparator"] =>
  (_, __, nodeA, nodeB) => {
    const current = nodeA?.data?.owner?.id;
    const next = nodeB?.data?.owner?.id;

    const currentIndex = current
      ? priorities?.findIndex((item) => item.id === current)
      : Infinity;
    const nextIndex = next
      ? priorities?.findIndex((item) => item.id === next)
      : Infinity;

    return (currentIndex ?? 0) - (nextIndex ?? 0);
  };
