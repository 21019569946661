import { Typography } from "@mui/material";

import { BillingExplorerWidgetSetupTableLabelHeaderCell } from "./cells/BillingExplorerWidgetSetupTableLabelHeaderCell";
import { BillingExplorerWidgetSetupTableTotalCostHeaderCell } from "./cells/BillingExplorerWidgetSetupTableTotalCostHeaderCell";
import { BillingExplorerWidgetSetupTablePrevTotalCostHeaderCell } from "./cells/BillingExplorerWidgetSetupTablePrevTotalCostHeaderCell";
import { BillingExplorerWidgetSetupTableTrendHeaderCell } from "./cells/BillingExplorerWidgetSetupTableTrendHeaderCell";
import { BillingExplorerWidgetSetupTableForecastedCostHeaderCell } from "./cells/BillingExplorerWidgetSetupTableForecastedCostHeaderCell";
import { BillingExplorerWidgetSetupPreviousPeriodCell } from "./cells/BillingExplorerWidgetSetupPreviousPeriodCell";
import { BillingExplorerWidgetSetupTrendCell } from "./cells/BillingExplorerWidgetSetupTrendCell";
import {
  ForecastOption,
  NullableForecastedCost,
  ResourceExplorerGroupingNullable,
} from "../../../../../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { labelColumnSortingFn } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/utils/helpers/sortingFunctions/sortingFunctions";
import { groupingsExcludingForecastedCostsColumns } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/utils/constants/groupingsExcludingForecastedCostsColumns";
import { ResourceExplorerGridDataWithId } from "../../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";
import { forecastedCostOptionLabels } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/utils/constants";
import { BillingExplorerWidgetLabelCell } from "../../../../../../widgets/billing-explorer-widget/components/chart/table/cells/BillingExplorerWidgetLabelCell";
import { getForecastedCostsSortingFn } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/utils/helpers/sortingFunctions/forecastedCostsSortingFn";

const getColumnsStartPart =
  (): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
    return [
      {
        size: 400,
        minSize: 150,
        accessorKey: "label",
        header: () => {
          return <BillingExplorerWidgetSetupTableLabelHeaderCell />;
        },
        sortingFn: labelColumnSortingFn,
        cell: BillingExplorerWidgetLabelCell,
        meta: {
          headerTitle: "grouping",
        },
      },
      {
        minSize: 110,
        accessorKey: "total_cost",
        header: () => <BillingExplorerWidgetSetupTableTotalCostHeaderCell />,
        cell: (cell) => {
          const val = cell.getValue() as number;

          return (
            <Typography variant="body2" align="right" pr={4}>
              {isNaN(val) ? "N / A" : money(val)}
            </Typography>
          );
        },
        meta: {
          headerTitle: "Total Cost",
        },
      },
      {
        minSize: 170,
        accessorKey: "previous_period_total_cost",
        header: () => (
          <BillingExplorerWidgetSetupTablePrevTotalCostHeaderCell />
        ),
        cell: (cell) => {
          const val = cell.getValue() as number | null;

          return <BillingExplorerWidgetSetupPreviousPeriodCell value={val} />;
        },
        meta: {
          headerTitle: "Previous Period Total Cost",
        },
      },
      {
        size: 100,
        accessorKey: "trend",
        header: () => <BillingExplorerWidgetSetupTableTrendHeaderCell />,
        cell: (cell) => {
          const data = cell.getValue() as number | null;

          return <BillingExplorerWidgetSetupTrendCell value={data} />;
        },
        meta: {
          headerTitle: "Trend",
        },
      },
    ];
  };

interface GetBillingExplorerWidgetTableViewColumnsArgs {
  widgetId?: string;
  forecastOption?: ForecastOption;
  grouping?: ResourceExplorerGroupingNullable;
}

export const getBillingExplorerWidgetSetupTableViewColumns = ({
  forecastOption,
  grouping,
}: GetBillingExplorerWidgetTableViewColumnsArgs): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
  const startPart = getColumnsStartPart();
  if (
    !grouping ||
    groupingsExcludingForecastedCostsColumns.has(grouping) ||
    !forecastOption
  ) {
    return startPart;
  }
  return [
    ...startPart,
    {
      minSize: 190,
      accessorKey:
        forecastOption === "month" ? "monthly_forecast" : "quarterly_forecast",
      header: () => <BillingExplorerWidgetSetupTableForecastedCostHeaderCell />,
      meta: {
        headerTitle: `Forecasted ${forecastedCostOptionLabels.get(
          "month",
        )} Cost`,
      },
      sortingFn: getForecastedCostsSortingFn(forecastOption),
      cell: (cell) => {
        const data: NullableForecastedCost = cell.getValue();

        return (
          <Typography variant="body2" align="right" pr={4}>
            {data === null || data === undefined
              ? "-"
              : `~${money(data?.cost)}`}
          </Typography>
        );
      },
    },
  ];
};
