import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface ThunkArgs {
  provider: ProviderType;
  accountId: string;
  resourceId: string;
}

export const getResourceExplorerSingleResourceDataThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerSingleResourceDataThunk",
  async ({ provider, resourceId, accountId }: ThunkArgs, { dispatch }) => {
    const {
      getUsersMeOrganisationsCurrentAccountsByAccountIdProvidersAndProviderResourceExplorerResources,
    } = cloudChiprApi.endpoints;

    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentAccountsByAccountIdProvidersAndProviderResourceExplorerResources.initiate(
          {
            provider,
            resourceId,
            accountId,
          },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
