import { createAsyncThunk } from "@reduxjs/toolkit";
import { getExecutionLogDetailsThunk } from "./getExecutionLogDetailsThunk";
import { setExecutionLogData } from "../executionLogSlice";
import {
  cloudChiprApi,
  PostV3UsersMeExecutionLogsApiArg,
} from "../../../services/cloudchipr.api";
import { RootState } from "../../store";
import { executionLogStateSelector } from "../selectors/executionLogStateSelector";
import { executionLogPayloadDatesSelector } from "../selectors/executionLogPayloadDatesSelector";
import { getSourceAndExecutedByFromSelectValues } from "../../../components/pages/execution-log/utils/helpers/getSourceAndExecutedByFromSelectValues";

interface GetExecutionLogThunkArguments {
  withoutDetails?: boolean;
}

const getExecutionLogThunkFixedCacheKey =
  "postV3UsersMeExecutionLogs-fixed-cache-key";

export const getExecutionLogThunk = createAsyncThunk(
  "executionLog/getExecutionLog",
  async (
    { withoutDetails }: GetExecutionLogThunkArguments,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const { filters, pagination } = executionLogStateSelector(state);
    const dates = executionLogPayloadDatesSelector(state);

    const { source, executedBy } = getSourceAndExecutedByFromSelectValues(
      filters?.sourceAndExecutedBy ?? [],
    );

    type Body = PostV3UsersMeExecutionLogsApiArg["body"];

    const requestFilters: Body = {
      ...pagination,
      ...dates,
      search: filters?.search || undefined,
      account_ids: checkByLength(filters?.accountIds),
      executed_by: checkByLength(executedBy) as Body["executed_by"],
      execution_log_ids: checkByLength(filters?.executionLogIds),
      source: checkByLength(source) as Body["source"],
      action: checkByLength(filters?.action) as Body["action"],
      states: filters?.states,
    };

    if (!withoutDetails) {
      dispatch(getExecutionLogDetailsThunk(requestFilters));
    }

    const response = await dispatch(
      cloudChiprApi.endpoints.postV3UsersMeExecutionLogs.initiate(
        { body: requestFilters },
        { fixedCacheKey: getExecutionLogThunkFixedCacheKey },
      ),
    );

    if ("data" in response) {
      response.data && dispatch(setExecutionLogData(response.data));
    }
  },
);

const checkByLength = (data?: string[] | null) => {
  return data?.length ? data : null;
};
