import { FC, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import "highcharts/modules/heatmap";
import "highcharts/modules/funnel";
import "highcharts/modules/sankey";
import "highcharts/modules/treemap";
import "highcharts/modules/gantt";
import "highcharts/highcharts-more";

import { Box } from "@mui/material";
import { highChartColors } from "../c8r-highcharts/common/utils/constants/chartColors";
import { chartDefaultConfigs } from "../c8r-highcharts/common/utils/constants/chartDefaultConfigs";

interface HighchartsStackedColumnChartProps {
  data: any;
}

export const PoorHighChart: FC<HighchartsStackedColumnChartProps> = ({
  data,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const chart = Highcharts.chart(chartRef.current, {
      chart: {
        ...data?.chart,
        zoomType: "x",
      },
      colors: highChartColors,
      ...chartDefaultConfigs,
      title: { text: "" },
      ...data,
    });

    return () => {
      chart?.destroy?.();
    };
  }, [data]);

  return (
    <Box
      ref={chartRef}
      sx={{ width: "100%", height: "370px", margin: "auto" }}
    />
  );
};
