import { FC, useEffect } from "react";
import { Stack } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { useFlag } from "@unleash/proxy-client-react";
import { BillingExplorerExplainWithAiPanelHeader } from "./components/BillingExplorerExplainWithAiPanelHeader";
import { BillingExplorerExplainWithAiPanelBody } from "./components/BillingExplorerExplainWithAiPanelBody";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { billingExplorerExplainWithAiOpenSelector } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiOpenSelector";
import { getBillingExplorerSummaryThunk } from "../../../../../store/resource-explorer/thunks/resource-explorer-summary/getBillingExplorerSummaryThunk";
import { billingExplorerExplainWithAiNewDataNeededSelector } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/explain-with-ai/billingExplorerExplainWithAiNewDataNeededSelector";
import { updateBillingExplorerSummaryMetaDataThunk } from "../../../../../store/resource-explorer/thunks/resource-explorer-summary/updateBillingExplorerSummaryMetaDataThunk";

interface BillingExplorerExplainWithAiPanelProps {
  onClose(): void;
}

export const BillingExplorerExplainWithAiPanel: FC<
  BillingExplorerExplainWithAiPanelProps
> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const enableResourcesExplorerExplainWithAI = useFlag(
    "EnableResourcesExplorerExplainWithAI",
  );
  const enableResourcesExplorerExplainWithAIWithExplanation = useFlag(
    "EnableResourcesExplorerExplainWithAIWithExplanation",
  );

  const explainWithAiOpen = useAppSelector(
    billingExplorerExplainWithAiOpenSelector,
  );
  const newDataNeeded = useAppSelector(
    billingExplorerExplainWithAiNewDataNeededSelector,
  );

  useEffectOnceWhen(() => {
    dispatch(updateBillingExplorerSummaryMetaDataThunk());
    dispatch(getBillingExplorerSummaryThunk());
  }, explainWithAiOpen);

  useEffect(() => {
    if (explainWithAiOpen && newDataNeeded) {
      dispatch(updateBillingExplorerSummaryMetaDataThunk());
    }
  }, [explainWithAiOpen, dispatch, newDataNeeded]);

  if (
    !(
      enableResourcesExplorerExplainWithAI &&
      enableResourcesExplorerExplainWithAIWithExplanation
    )
  ) {
    return null;
  }

  return (
    <Stack px={1}>
      <BillingExplorerExplainWithAiPanelHeader onClose={onClose} />

      <BillingExplorerExplainWithAiPanelBody />
    </Stack>
  );
};
