import { FC, useCallback, useMemo } from "react";
import { useCurrentEditor } from "@tiptap/react";
import { Button } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { CommandName, SimpleCommands } from "../../utils/types/types";

interface ToolbarCommandButtonProps {
  command: SimpleCommands;
  disableWhenActive?: boolean;
  Icon: SvgIconComponent;
  commandName?: CommandName;
}

export const ToolbarCommandButton: FC<ToolbarCommandButtonProps> = ({
  command,
  disableWhenActive,
  Icon,
  commandName,
}) => {
  const { editor } = useCurrentEditor();

  const clickHandler = useCallback(() => {
    if (!editor) {
      return;
    }

    const commandFn = editor.chain().focus()[command];

    if (!commandFn) {
      return;
    }

    commandFn().run();
  }, [editor, command]);

  const disabled = useMemo(() => {
    if (!disableWhenActive || !editor) {
      return false;
    }

    const commandFn = editor.can().chain().focus()[command];

    if (!commandFn) {
      return;
    }

    return !commandFn().run();
  }, [editor, disableWhenActive, command]);

  if (!editor) {
    return null;
  }

  const isActive = commandName && editor.isActive(commandName);

  return (
    <Button
      sx={{
        ...toolbarCommandButtonStyles,
        bgcolor: isActive ? "primary.light" : undefined,
      }}
      onClick={clickHandler}
      disabled={disabled}
    >
      <Icon fontSize="small" sx={{ color: "grey.600" }} />
    </Button>
  );
};

export const toolbarCommandButtonStyles = {
  p: 0,
  minWidth: 28,
  minHeight: 28,
};
