import { FC, useCallback } from "react";
import { IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerPathSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathSelector";
import { changePathOnBreadcrumbSelectThunk } from "../../../../../../../store/resource-explorer/thunks/widgets/path/changePathOnBreadcrumbSelectThunk";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";

export const ResourceExplorerBreadcrumbsBackButton: FC = () => {
  const dispatch = useAppDispatch();

  const path = useAppSelector(resourceExplorerPathSelector);

  const lastPath = path.length - 2;
  const groupBy = path.at(-2)?.groupedBy;

  const groupingLabel = useAppSelector((state) =>
    resourceExplorerGroupingLabelByGroupingSelector(state, groupBy),
  );

  const backClickHandler = useCallback(() => {
    dispatch(changePathOnBreadcrumbSelectThunk(lastPath));
  }, [dispatch, lastPath]);

  if (!groupBy) {
    return null;
  }

  return (
    <Tooltip
      title={`Back to ${groupingLabel?.plural?.toLowerCase()}`}
      arrow
      placement="top"
    >
      <IconButton
        sx={{
          bgcolor: "grey.100",
        }}
        size="small"
        onClick={backClickHandler}
      >
        <ArrowBackIcon />
      </IconButton>
    </Tooltip>
  );
};
