import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { capitalize, Typography } from "@mui/material";
import { getCoveredUsageColumnDef } from "./common/getCoveredUsageColumnDef";
import { coveredHoursColumnDef } from "./common/coveredHoursColumnDef";
import { uncoveredUsageColumnDef } from "./common/uncoveredUsageColumnDef";
import { netSavingsColumnDef } from "./common/netSavingsColumnDef";
import { commitmentsResourceTypeNames } from "../../../../../../../common/utils/constants/labels";
import { ReservationsCoverageDataWithId } from "../../../../../../../common/utils/types";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { CommitmentsProgressBar } from "../../../../../../../common/components/CommitmentsProgressBar";

export const reservationCoverageGroupedByResourceTypeColumnDefs: ColDef<ReservationsCoverageDataWithId>[] =
  [
    {
      flex: 1.5,
      minWidth: 200,
      field: "resource_type.resource_type",
      headerName: "Resource Type",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => {
        const rt = data?.resource_type?.resource_type;

        if (!rt) {
          return;
        }

        return (
          <Typography variant="body2" textTransform="capitalize">
            {commitmentsResourceTypeNames[rt]}
          </Typography>
        );
      },
      valueGetter: ({ data }) =>
        data?.resource_type?.resource_type
          ? capitalize(
              commitmentsResourceTypeNames[data.resource_type.resource_type] ??
                "",
            )
          : "",
    },
    {
      flex: 1.5,
      field: "resource_type.instance_type",
      minWidth: 200,
      headerName: "Instance Type",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
        <Typography variant="body2">
          {data?.resource_type?.instance_type ?? "-"}
        </Typography>
      ),
    },
    {
      flex: 1.5,
      field: "resource_type.platform",
      minWidth: 150,
      headerName: "Platform",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
        <Typography variant="body2">
          {data?.resource_type?.platform ?? "-"}
        </Typography>
      ),
    },
    {
      flex: 1.5,
      field: "resource_type.region",
      minWidth: 150,
      headerName: "Region/AZ",
    },

    getCoveredUsageColumnDef("resource_type"),

    coveredHoursColumnDef,

    uncoveredUsageColumnDef,

    netSavingsColumnDef,

    {
      flex: 1.5,
      field: "coverage",
      headerName: "Coverage",
      minWidth: 200,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
        <CommitmentsProgressBar
          overrideColor="info.light"
          value={data?.coverage ?? 0}
        />
      ),
    },

    agGridEmptyColumnToFitEmptySpace,
  ];
