import { billingSummarySetupWidgetDataGridDataSelector } from "./billingSummarySetupWidgetDataGridDataSelector";
import { billingSummarySumSetupPropertyByKeySelector } from "../billingSummarySumSetupPropertyByKeySelector";
import { RootState } from "../../../../../store";
import { BillingSummaryDataGridType } from "../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/billing-summary/content/utils/types";

export const billingSummarySetupWidgetFilteredDataSelector = (
  state: RootState,
): BillingSummaryDataGridType[] | undefined => {
  const gridData = billingSummarySetupWidgetDataGridDataSelector(state);

  if (!gridData) {
    return;
  }

  const filters = billingSummarySumSetupPropertyByKeySelector("filter")(state);

  if (!filters?.length) {
    return gridData;
  }

  return gridData.filter((item) => {
    return filters?.includes(item.billingSummaryFilterType);
  });
};
