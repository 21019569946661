import { FC } from "react";
import { useAppSelector } from "../../../../../../store/hooks";
import { azureOverviewCoveragesSelector } from "../../../../../../store/commitments/selectors/azure/overview/azureOverviewCoveragesSelector";
import { azureOverviewLoadingSelector } from "../../../../../../store/commitments/selectors/azure/overview/loading/azureOverviewLoadingSelector";
import { CommitmentsOverviewCoverage } from "../../../overview/content/components/common/CommitmentsOverviewCoverage";

export const AzureCommitmentsOverviewCoverage: FC = () => {
  const coverages = useAppSelector(azureOverviewCoveragesSelector);
  const loading = useAppSelector(azureOverviewLoadingSelector);

  return <CommitmentsOverviewCoverage data={coverages} loading={loading} />;
};
