import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { IntegrationsActionCell } from "./IntegrationsActionCell";
import { CommunicationIntegrationsGridRowType } from "../../../../utils/types/common";

export const IntegrationsActionCellRenderer: FC<
  ICellRendererParams<CommunicationIntegrationsGridRowType>
> = ({ data }) => {
  const id = data?.id;
  const type = data?.type;
  const name = data?.name;

  if (!id || !type || !name) {
    return;
  }

  return <IntegrationsActionCell integrationId={id} type={type} name={name} />;
};
