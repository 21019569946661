import { ChangeEvent, FC, Fragment, useCallback, useState } from "react";
import { useCurrentEditor } from "@tiptap/react";
import {
  Button,
  Divider,
  Popover,
  PopoverOrigin,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { toolbarCommandButtonStyles } from "./ToolbarCommandButton";
import {
  useMenuHook,
  UseMenuHookOpenMenu,
} from "../../../../utils/hooks/useMenu.hook";

export const ToolbarLinkCommandButton: FC = () => {
  const { editor } = useCurrentEditor();
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");

  const setLink = useCallback(() => {
    if (!editor) {
      return;
    }

    if (!url || !text) {
      editor.chain().focus().unsetLink().run();
      closeMenu();
      return;
    }

    editor
      .chain()
      .focus()
      .insertContent(`<a href="${url}">${text}</a> <span> </span>`)
      .run();

    closeMenu();
  }, [editor, url, text, closeMenu]);

  const openHandler: UseMenuHookOpenMenu = useCallback(
    (e) => {
      if (!editor) {
        return;
      }

      const textBetween = editor.state.doc.textBetween(
        editor.state.selection.from,
        editor.state.selection.to,
        " ",
      );

      setText(editor.state.selection.empty ? "" : textBetween);
      setUrl(editor.getAttributes("link").href ?? "");

      openMenu(e);
    },
    [editor, openMenu],
  );

  const urlChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  }, []);
  const textChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  }, []);

  if (!editor) {
    return null;
  }

  return (
    <Fragment>
      <Button
        sx={{
          ...toolbarCommandButtonStyles,
          bgcolor: editor.isActive("link") ? "primary.light" : undefined,
        }}
        onClick={openHandler}
      >
        <AddLinkIcon fontSize="small" sx={{ color: "grey.600" }} />
      </Button>

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeMenu}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
      >
        <Stack py={1} spacing={1} bgcolor="white" width={300}>
          <Stack px={1}>
            <Typography variant="subtitle2">Enter the URL:</Typography>
            <TextField
              value={url}
              inputProps={inputProps}
              onChange={urlChangeHandler}
            />
          </Stack>

          <Stack px={1}>
            <Typography variant="subtitle2">Enter the link text:</Typography>
            <TextField
              value={text}
              inputProps={inputProps}
              onChange={textChangeHandler}
            />
          </Stack>

          <Divider />

          <Stack direction="row" spacing={1} justifyContent="end" px={1}>
            <Button color="tertiary" onClick={closeMenu} size="small">
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={setLink}
              size="small"
              variant="contained"
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </Fragment>
  );
};

const inputProps = { sx: { p: 0, px: 0.25 } };
const anchorOrigin: PopoverOrigin = {
  vertical: "center",
  horizontal: "center",
};
const transformOrigin: PopoverOrigin = { vertical: "top", horizontal: "left" };
