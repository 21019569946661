import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { ProviderIcon } from "../../../../../../../common/ProviderIcon";
import { ProviderType } from "../../../../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../../../../utils/helpers/providers/getProviderName";

interface KubernetesClusterCloudProviderColumnProps {
  provider: ProviderType;
}

export const KubernetesClusterCloudProviderColumn: FC<
  KubernetesClusterCloudProviderColumnProps
> = ({ provider }) => {
  return (
    <Stack direction="row" spacing={1}>
      <ProviderIcon provider={provider} />
      <Typography variant="body2">
        {getProviderName(provider, {
          title: true,
        })}
      </Typography>
    </Stack>
  );
};
