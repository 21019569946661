import {
  ProviderType,
  ResourceType,
} from "../../../../../services/cloudchipr.api";
import { AccountTabsWithResources } from "../types/types";
import {
  accountIdsSearchParam,
  accountsPageTabSearchParam,
  resourceIdSearchParam,
  resourceTypeSearchParam,
} from "../../../live-usage-mgmt/utils/constants/searchParams";
import { getParentResourceType } from "../../../../../utils/helpers/resources/getParentResourceType";

export const generateLiveResourcesPath = ({
  orgId,
  activeTab,
  provider,
  accountIds,
  resourceType,
  resourceIds,
}: {
  provider: ProviderType;
  orgId?: string;
  activeTab?: AccountTabsWithResources;
  accountIds?: string;
  resourceType?: ResourceType;
  resourceIds?: string;
}) => {
  if (activeTab !== "live-resources" && orgId) {
    return `/providers/${provider}/accounts/${orgId}?${accountsPageTabSearchParam}=${activeTab ?? "active"}`;
  }

  let queryParams = `?`;

  if (accountIds) {
    queryParams = `${queryParams}&${accountIdsSearchParam}=${accountIds ?? ""}`;
  }
  if (resourceType) {
    queryParams = `${queryParams}&${resourceTypeSearchParam}=${getParentResourceType(resourceType)}`;
  }
  if (resourceIds) {
    queryParams = `${queryParams}&${resourceIdSearchParam}=${resourceIds}`;
  }

  return `/providers/${provider}/live-resources${queryParams}`;
};
