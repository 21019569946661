import { FC, Fragment, SyntheticEvent, useCallback, useState } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LinkIcon from "@mui/icons-material/Link";
import { NameCellDescription } from "./NameCellDescription";
import { NameCellRendererEditMode } from "./NameCellRendererEditMode";
import { Task } from "../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { openDrawerForTaskByIdThunk } from "../../../../../../../store/task-management/thunks/openDrawerForTaskByIdThunk";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../services/permissions";
import { useHover } from "../../../../../../../utils/hooks/useHover.hook";
import { CopyIconButton } from "../../../../../../common/CopyIconButton";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";

export const NameCellRenderer: FC<CustomCellRendererProps<Task>> = ({
  data,
}) => {
  const dispatch = useAppDispatch();
  const { can } = useAppAbility();
  const canEditTask = can("edit", "task");

  const { ref, hovered } = useHover();

  const {
    anchor: descriptionAnchor,
    open: descriptionOpen,
    openMenu: descriptionOpenMenu,
    closeMenu: descriptionCloseMenu,
  } = useMenuHook();
  const [nameEditMode, setNameEditMode] = useState(false);

  const visibility = descriptionOpen ? "hidden" : "visible";
  const display = hovered || descriptionOpen ? "flex" : "none";

  const clickHandler = useCallback(() => {
    if (!data?.id) {
      return;
    }

    dispatch(openDrawerForTaskByIdThunk(data?.id));
  }, [dispatch, data?.id]);

  const editModeOpenHandler = useCallback((e: SyntheticEvent) => {
    setNameEditMode(true);
    e.stopPropagation();
  }, []);

  if (!data) {
    return null;
  }

  return (
    <Stack
      px={1}
      ref={ref}
      spacing={0.5}
      height="100%"
      direction="row"
      alignItems="center"
      onClick={canEditTask && !nameEditMode ? clickHandler : undefined}
      sx={{ cursor: canEditTask ? "pointer" : undefined }}
    >
      {nameEditMode ? (
        <NameCellRendererEditMode
          name={data?.name}
          id={data?.id}
          setNameEditMode={setNameEditMode}
        />
      ) : (
        <Fragment>
          <TypographyWithTooltip
            variant="body2"
            title={data.name}
            color="primary.main"
            sx={{ textDecoration: "underline" }}
          />

          <CopyIconButton
            data={`${window.location.origin}/task-mgmt?taskId=${data?.id}`}
            TooltipProps={{ placement: "top", title: "Copy Link" }}
            sx={{
              visibility,
              display,
            }}
          >
            <LinkIcon fontSize="small" />
          </CopyIconButton>

          <Tooltip placement="top" arrow title="Rename">
            <IconButton
              size="small"
              onClick={editModeOpenHandler}
              sx={{
                visibility,
                display,
              }}
            >
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <CopyIconButton
            data={data.name}
            visible={hovered}
            TooltipProps={{ placement: "top", title: "Copy Name" }}
            sx={{
              visibility,
              display,
            }}
          />

          {data.description && (
            <NameCellDescription
              hovered={hovered}
              open={descriptionOpen}
              anchor={descriptionAnchor}
              openMenu={descriptionOpenMenu}
              description={data.description}
              closeMenu={descriptionCloseMenu}
            />
          )}
        </Fragment>
      )}
    </Stack>
  );
};
