import { FC } from "react";
import { Stack } from "@mui/material";
import { AwsOverviewEffectiveSavingsRate } from "./components/AwsOverviewEffectiveSavingsRate";
import { AwsCommitmentsOverviewMetadataLeftSide } from "./components/AwsCommitmentsOverviewMetadataLeftSide";
import { AwsCommitmentsOverviewMetadataRightSide } from "./components/AwsCommitmentsOverviewMetadataRightSide";

export const CommitmentsOverviewMetadata: FC = () => {
  return (
    <Stack
      spacing={1}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <Stack flex={1}>
        <AwsCommitmentsOverviewMetadataLeftSide />
      </Stack>
      <Stack flex={1}>
        <AwsOverviewEffectiveSavingsRate />
      </Stack>
      <Stack flex={1}>
        <AwsCommitmentsOverviewMetadataRightSide />
      </Stack>
    </Stack>
  );
};
