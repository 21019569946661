import { costAnomaliesSetupWidgetDataResponseSelector } from "./costAnomaliesSetupWidgetDataResponseSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridTotal } from "../../../../../../services/cloudchipr.api";

export const costAnomaliesSetupWidgetDataTotalSelector = (
  state: RootState,
): ResourceExplorerGridTotal[] | undefined => {
  const response = costAnomaliesSetupWidgetDataResponseSelector(state);

  return response?.data?.total;
};
