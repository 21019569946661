import { FC } from "react";
import { Container, Stack, Typography } from "@mui/material";
import { CommunicationIntegrationsGridView } from "./grid-view/CommunicationIntegrationsGridView";

export const CommunicationIntegrations: FC = () => {
  return (
    <Container sx={{ p: 2 }}>
      <Stack pl={4}>
        <Typography variant="h6" fontWeight="medium" pb={1}>
          Communications
        </Typography>
        <CommunicationIntegrationsGridView />
      </Stack>
    </Container>
  );
};
