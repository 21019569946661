import { billingExplorerWidgetDataByWidgetIdSelector } from "./billingExplorerWidgetDataByWidgetIdSelector";
import { billingExplorerWidgetFrequencySelector } from "./billingExplorerWidgetFrequencySelector";
import { RootState } from "../../../../../store";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { convertCostToPercent } from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/convertCostToPercent";
import {
  FormatedData,
  generateCombinatedChartData,
} from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/generateCombinatedChartData";
import { organisationQuarterStartMonthSelector } from "../../../../../common/selectors/org-current-settings/organisationQuarterStartMonthSelector";

export const billingExplorerWidgetCombinedChartDataSelector = (
  state: RootState,
  widgetId: string,
): FormatedData | null => {
  const data = billingExplorerWidgetDataByWidgetIdSelector(
    state,
    widgetId,
  )?.data;

  const frequency = billingExplorerWidgetFrequencySelector(state, widgetId);

  const quarterStartMonth = organisationQuarterStartMonthSelector(state);

  if (!data || !frequency) {
    return null;
  }

  const formatedData = formatResourceExplorerChartData(
    data,
    frequency,
    quarterStartMonth,
  );
  const percentData = convertCostToPercent(formatedData);

  return generateCombinatedChartData(formatedData, percentData);
};
