import { billingExplorerWidgetByIdSelector } from "./billingExplorerWidgetByIdSelector";
import { RootState } from "../../../../../store";
import { WidgetCostBreakdownVisualization } from "../../../../../../services/cloudchipr.api";

export const billingExplorerWidgetVisualizationSelector = (
  state: RootState,
  widgetId: string,
): WidgetCostBreakdownVisualization | undefined => {
  const data = billingExplorerWidgetByIdSelector(state, widgetId);
  if (!data) {
    return;
  }
  return data?.visualization;
};
