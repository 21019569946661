import { FC, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { AnalyticsOutlined } from "@mui/icons-material";
import {
  ResourceDetailsGeneralContentK8SViewSelect,
  UtilizationOrReservation,
} from "./ResourceDetailsGeneralContentK8SViewSelect";
import { ResourceDetailsGeneralContentK8SUsageReservation } from "./ResourceDetailsGeneralContentK8SUsageReservation";
import { ResourceDetailsGeneralContentK8SUsageUtilization } from "./ResourceDetailsGeneralContentK8SUsageUtilization";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { resourceDetailsTargetResourceSelector } from "../../../../../../../../../store/resource-details/selectors/target-resource/resourceDetailsTargetResourceSelector";
import { ResourceDetailsContentCollapsableCard } from "../../../ResourceDetailsContentCollapsableCard";
import { kubernetesResourcesHasMetricFilter } from "../../../../../../../../../store/kubernetes-live-usage-mgmt/thunks/resources/utils/constants";

export const ResourceDetailsGeneralContentK8SUsage: FC = () => {
  const [view, setView] = useState<UtilizationOrReservation>("reservation");

  const resource = useAppSelector(resourceDetailsTargetResourceSelector);

  const resourceType = resource?.resourceType;

  useEffect(() => {
    setView(resourceType === "kubernetes_node" ? "reservation" : "utilization");
  }, [resourceType]);

  if (resourceType && !kubernetesResourcesHasMetricFilter.has(resourceType)) {
    return null;
  }

  return (
    <ResourceDetailsContentCollapsableCard
      title="Usage"
      Icon={<AnalyticsOutlined fontSize="small" />}
    >
      <Stack spacing={1}>
        {resourceType === "kubernetes_node" && (
          <ResourceDetailsGeneralContentK8SViewSelect
            view={view}
            setView={setView}
          />
        )}

        {view === "reservation" && (
          <ResourceDetailsGeneralContentK8SUsageReservation />
        )}

        {view === "utilization" && (
          <ResourceDetailsGeneralContentK8SUsageUtilization />
        )}
      </Stack>
    </ResourceDetailsContentCollapsableCard>
  );
};
