import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { ReservationsMetadata } from "./components/metadata/ReservationsMetadata";
import { ReservationsContent } from "./components/content/ReservationsContent";
import { ReservationsDrawers } from "./components/drawers/ReservationsDrawers";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { commitmentsCurrentOrgIdSelector } from "../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { getReservationsThunk } from "../../../../store/commitments/thunks/reservations/getReservationsThunk";

export const Reservations: FC = () => {
  const dispatch = useAppDispatch();

  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);

  useEffect(() => {
    dispatch(getReservationsThunk());
  }, [dispatch, orgId]);

  return (
    <Stack spacing={1} flex={1}>
      <ReservationsMetadata />
      <ReservationsContent />

      <ReservationsDrawers />
    </Stack>
  );
};
