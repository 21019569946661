import { tasksListGetLoadingSelector } from "./tasksListGetLoadingSelector";
import { RootState } from "../../../store";
import { tasksViewsHierarchyLoadingSelector } from "../views-hierarchy/loadings/tasksViewsHierarchyLoadingSelector";

export const taskManagementPageLoadingSelector = (state: RootState) => {
  const tasksListLoading = tasksListGetLoadingSelector(state);
  const tasksHierarchyLoading = tasksViewsHierarchyLoadingSelector(state);

  return tasksHierarchyLoading || tasksListLoading;
};
