import { FC, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { ResourceDetailsMetricsContentFrequencySelection } from "./ResourceDetailsMetricsContentFrequencySelection";
import { ResourceDetailsMetricsContentMetricCard } from "./ResourceDetailsMetricsContentMetricCard";
import { ResourceDetailsMetricsContentEmptyState } from "./ResourceDetailsMetricsContentEmptyState";
import { ResourceMetrics } from "../../../../../../../../services/cloudchipr.api";
import { MetricKey, MetricPeriods } from "../../../../utils/types/metrics";

interface ResourceDetailsMetricsContentProps {
  data?: ResourceMetrics;
  loading: boolean;
}

export const ResourceDetailsMetricsContent: FC<
  ResourceDetailsMetricsContentProps
> = ({ data, loading }) => {
  const [period, setPeriod] = useState<MetricPeriods>("7d");

  const entries = useMemo(() => Object.entries(data ?? {}), [data]);

  return (
    <Stack spacing={1.5}>
      <ResourceDetailsMetricsContentFrequencySelection
        period={period}
        setPeriod={setPeriod}
      />

      <ResourceDetailsMetricsContentEmptyState
        loading={loading}
        dataExists={!!entries?.length}
      />

      {data && (
        <Box sx={gridSx}>
          {entries?.map(([metricKey, metrics]) => {
            return (
              <ResourceDetailsMetricsContentMetricCard
                key={metricKey}
                loading={loading}
                metrics={metrics}
                period={period}
                metricKey={metricKey as MetricKey}
              />
            );
          })}
        </Box>
      )}
    </Stack>
  );
};

const gridSx = {
  gap: 1.5,
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(480px, 1fr))",
};
