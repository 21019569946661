import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { azureUtilizationAndCoverageDetailsDataSelector } from "./data/azureUtilizationAndCoverageDetailsDataSelector";
import { CommitmentDateGranularDataPoint } from "../../../../../../services/cloudchipr.api";

export const azureUtilizationAndCoverageDetailsGranularDataGroupedByDateSelector =
  createDraftSafeSelector(
    [azureUtilizationAndCoverageDetailsDataSelector],
    (data): Record<string, CommitmentDateGranularDataPoint> | undefined => {
      return data?.data?.reduce(
        (acc, data) => {
          acc[data.date] = data;

          return acc;
        },
        {} as Record<string, CommitmentDateGranularDataPoint>,
      );
    },
  );
