import { Fragment } from "react";
import { Button, LinearProgress, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link as RouterLink } from "react-router-dom";
import { IntegrationsList } from "./components/integrations-list/IntegrationsList";
import { PageHeader } from "../common/PageHeader";
import { useAppAbility } from "../../../services/permissions";
import { useGetUsersMeIntegrationsQuery } from "../../../services/cloudchipr.api";
import { getUserPermissionInfoText } from "../utils/helpers/getUserPermissionInfoText";

export const Integrations = () => {
  const { cannot } = useAppAbility();
  const canNotCreateIntegration = cannot("create", "integration");
  const { data, isLoading: loading } = useGetUsersMeIntegrationsQuery({});

  const userPermissionTooltipText = canNotCreateIntegration
    ? getUserPermissionInfoText("Create Integration")
    : "";

  return (
    <Fragment>
      <PageHeader
        title="Integrations"
        actions={
          !!data?.length && (
            <Tooltip title={userPermissionTooltipText}>
              <span>
                <Button
                  variant="contained"
                  disabled={canNotCreateIntegration}
                  color="primary"
                  component={RouterLink}
                  to="/integrations/create"
                >
                  <AddIcon sx={{ mr: 1 }} /> create INTEGRATION
                </Button>
              </span>
            </Tooltip>
          )
        }
      />

      {loading && <LinearProgress />}

      <IntegrationsList />
    </Fragment>
  );
};
