import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerWidgetDataSelector } from "../resource-explorer-widget/resourceExplorerWidgetDataSelector";
import { resourceExplorerDataPointSelector } from "../data/resourceExplorerDataPointSelector";
import { organisationQuarterStartMonthSelector } from "../../../../common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { billingExplorerChartDataLabelsGenerator } from "../../../../../components/pages/common/billing-explorer/utils/billingExplorerChartDataLabelsGenerator";

export const resourceExplorerChartLabelsSelector = createDraftSafeSelector(
  [
    resourceExplorerWidgetDataSelector,
    resourceExplorerDataPointSelector,
    organisationQuarterStartMonthSelector,
  ],
  (widgetData, dataPoint, quarterStartMonth): Record<string, string> | null => {
    const data = widgetData?.data;

    if (!data) {
      return null;
    }

    return billingExplorerChartDataLabelsGenerator(
      data,
      dataPoint,
      quarterStartMonth,
    );
  },
);
