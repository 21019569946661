import { liveUsageMgmtResourceTypeDataResourcesSelector } from "./liveUsageMgmtResourceTypeDataResourcesSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { ResourceDataType } from "../../utils/types/types";

export const liveUsageMgmtResourceTypeDataResourceByIdSelector = (
  state: RootState,
  resourceType: ResourceType,
  resourceId: string,
): ResourceDataType | null => {
  const resources = liveUsageMgmtResourceTypeDataResourcesSelector(
    state,
    resourceType,
  );

  return (
    resources?.find(
      (resource) =>
        resource?.resource?.provider_unique_identifier === resourceId,
    ) ?? null
  );
};
