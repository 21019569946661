import { kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector } from "./kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { kubernetesLiveUsageMgmtResourceTypeDataSelector } from "./kubernetesLiveUsageMgmtResourceTypeDataSelector";
import { kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector } from "../store-selectors/kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { KubernetesResourceDataType } from "../../utils/types/types";
import { getKubernetesResourceTypeDataWithoutFiltersFixedCacheKey } from "../../thunks/resources/getKubernetesLiveUsageMgmtResourcesWithOutFiltersByResourceTypeThunk";

export const kubernetesLiveUsageMgmtResourceTypeDataResourcesSelector = (
  state: RootState,
  resourceType: ResourceType,
): KubernetesResourceDataType[] | null => {
  const withoutFilters =
    kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector(
      state,
      resourceType,
    );

  const filterTemplatesEnabled =
    kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector(state);

  const fixedCacheKey =
    withoutFilters || !filterTemplatesEnabled
      ? getKubernetesResourceTypeDataWithoutFiltersFixedCacheKey(resourceType)
      : resourceType;

  return (
    kubernetesLiveUsageMgmtResourceTypeDataSelector(state, fixedCacheKey)
      ?.resources || null
  );
};
