import { FC, useCallback } from "react";
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import { ProviderOption } from "./components/ProviderOption";
import { goToNextStep } from "../../../../store/account-setup/accountSetupSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { providerSelector } from "../../../../store/account-setup/selectors/providerSelector";
import { getProviderName } from "../../../../utils/helpers/providers/getProviderName";
import { ProviderIcon } from "../../../common/ProviderIcon";

export const ProvidersStep: FC = () => {
  const dispatch = useAppDispatch();
  const provider = useAppSelector(providerSelector);
  const nextHandler = useCallback(() => dispatch(goToNextStep()), [dispatch]);

  return (
    <div>
      <ProviderOption
        provider="aws"
        title={getProviderName("aws", { title: true })}
        description="You can connect both AWS Management and sub-accounts by adding read-only or read-write IAM roles. Cloudchipr will try to collect cost and usage data from the linked accounts automatically if Management account is connected."
        selected={provider === "aws"}
        icon={<ProviderIcon provider="aws" width="44" />}
      />
      <ProviderOption
        provider="gcp"
        title={getProviderName("gcp", { title: true })}
        description="You can connect GCP Organization or Project by adding read-only or read-write IAM roles. In the case of connecting an Organization, Cloudchipr will attempt to add all projects under that organization."
        selected={provider === "gcp"}
        icon={<ProviderIcon provider="gcp" width="44" />}
      />
      <ProviderOption
        provider="azure"
        title={getProviderName("azure", { title: true })}
        description="You can connect Azure Subscriptions through use of an Active Directory Service Principal. We will guide you through the process of creating a Service Principal and assigning corresponding access roles."
        selected={provider === "azure"}
        icon={<ProviderIcon provider="azure" width="44" />}
      />

      <Stack mt={4} alignItems="end" justifyContent="end">
        <Button variant="contained" color="primary" onClick={nextHandler}>
          Next
        </Button>
      </Stack>
    </div>
  );
};
