import { resourceExplorerPossibleGroupingsFormattedDataSelector } from "./resourceExplorerPossibleGroupingsFormattedDataSelector";
import { RootState } from "../../../store";

export const resourceExplorerGroupingPossibleGroupingsSelector = (
  state: RootState,
) => {
  const formattedData =
    resourceExplorerPossibleGroupingsFormattedDataSelector(state);

  return formattedData?.groupingPossibleGroupings;
};
