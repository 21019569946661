import { FC, MouseEventHandler, useCallback } from "react";
import { Box, IconButton, Link, Stack, Tooltip } from "@mui/material";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import { PlayCircleOutlined } from "@mui/icons-material";
import { useFlag } from "@unleash/proxy-client-react";
import { RecommendationLinkIcon } from "./RecommendationLinkIcon";
import { useHover } from "../../../../../../../../../../../utils/hooks/useHover.hook";
import { CopyIconButton } from "../../../../../../../../../../common/CopyIconButton";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import {
  Recommendations,
  ResourceType,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { CloudProviderType } from "../../../../../../../../../../common/types/types";
import { getProviderName } from "../../../../../../../../../../../utils/helpers/providers/getProviderName";
import { generateLiveResourcesPath } from "../../../../../../../../../accounts-group/utils/helpers/generateLiveResourcesPath";
import { setLiveUsageMgmtResourceDetailsDrawerResourceDataThunk } from "../../../../../../../../../../../store/live-usage-mgmt/thunks/resources/setLiveUsageMgmtResourceDetailsDrawerResourceDataThunk";
import { useAppDispatch } from "../../../../../../../../../../../store/hooks";
import { setK8SLiveUsageMgmtResourceDetailsDrawerResourceDataThunk } from "../../../../../../../../../../../store/kubernetes-live-usage-mgmt/thunks/resources/setK8SLiveUsageMgmtResourceDetailsDrawerResourceDataThunk";

export interface IdentifierCellProps {
  data: string;
  providerLink?: string;
  resourceType?: ResourceType;
  rightsizingRecommendations?: Recommendations["off_hours_recommendations"];
  offHoursRecommendations?: Recommendations["off_hours_recommendations"];
  accountId?: string;
  resourceId?: string;
  provider?: CloudProviderType;
  resourceExists?: boolean;
  isKubernetes?: boolean;
}

export const IdentifierCell: FC<IdentifierCellProps> = ({
  data,
  providerLink,
  resourceType,
  rightsizingRecommendations,
  offHoursRecommendations,
  accountId,
  provider,
  resourceExists,
  resourceId,
  isKubernetes,
}) => {
  const dispatch = useAppDispatch();
  const enableLiveUsageResourceDetailsDrawer = useFlag(
    "EnableLiveUsageResourceDetailsDrawer",
  );

  const { ref, hovered } = useHover();

  const liveResourcesLink =
    resourceExists &&
    provider &&
    generateLiveResourcesPath({
      provider,
      activeTab: "live-resources",
      accountIds: accountId,
      resourceType: "rds",
      resourceIds: data,
    });

  const identifierClickHandler: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();

      if (!resourceType || !resourceId) {
        return;
      }

      const thunk = isKubernetes
        ? setK8SLiveUsageMgmtResourceDetailsDrawerResourceDataThunk
        : setLiveUsageMgmtResourceDetailsDrawerResourceDataThunk;

      dispatch(thunk({ resourceId, resourceType }));
    },
    [dispatch, resourceType, isKubernetes, resourceId],
  );

  const clickableContent = (
    <TypographyWithTooltip
      title={data || "-"}
      fontSize="inherit"
      color="primary"
      onClick={identifierClickHandler}
      sx={{ py: 0.75, flex: 1, cursor: "pointer", minWidth: 50 }}
    />
  );

  if (!data) {
    return resourceId && enableLiveUsageResourceDetailsDrawer ? (
      clickableContent
    ) : (
      <TypographyWithTooltip
        title={data}
        fontSize="inherit"
        sx={{ py: 0.75, flex: 1 }}
      />
    );
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      ref={ref}
    >
      {resourceExists !== undefined && (
        <Tooltip
          arrow
          placement="top"
          title={`DB Status: ${resourceExists ? "Active" : "Deleted"}`}
        >
          <CircleIcon
            sx={{
              color: resourceExists ? "success.light" : "grey.400",
              fontSize: 12,
              mr: 0.5,
            }}
          />
        </Tooltip>
      )}

      {resourceId && enableLiveUsageResourceDetailsDrawer ? (
        clickableContent
      ) : (
        <TypographyWithTooltip
          title={data}
          fontSize="inherit"
          sx={{ py: 0.75, flex: 1 }}
        />
      )}

      <Stack direction="row">
        <Box
          sx={{
            display: hovered ? "flex" : "none",
            visibility: hovered ? "visible" : "hidden",
          }}
        >
          <CopyIconButton data={data} visible={hovered} sx={{ ml: 0.5 }} />

          {liveResourcesLink && (
            <Tooltip title="View in Live Resources" arrow placement="top">
              <IconButton
                size="small"
                component={Link}
                href={liveResourcesLink}
                target="_blank"
              >
                <PlayCircleOutlined fontSize="small" color="action" />
              </IconButton>
            </Tooltip>
          )}

          {providerLink && (
            <Tooltip
              arrow
              placement="top"
              title={
                provider
                  ? `View in ${getProviderName(provider, { title: true })}`
                  : ""
              }
            >
              <IconButton
                size="small"
                component={Link}
                href={providerLink || ""}
                target="_blank"
              >
                <CloudOutlinedIcon fontSize="small" color="action" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <RecommendationLinkIcon
          resourceType={resourceType}
          accountId={accountId}
          rightsizingRecommendations={rightsizingRecommendations}
          offHoursRecommendations={offHoursRecommendations}
        />
      </Stack>
    </Stack>
  );
};
