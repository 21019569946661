import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { BillingExplorerWidgetViewTypeSelect } from "../../../components/side-bar/BillingExplorerWidgetViewTypeSelect";
import { WidgetConfigSection } from "../../../../common/widget-configurations/WidgetConfigSection";
import VisualizationIcon from "../../../../../../../../../../../assets/images/icons/visualization_icon.svg";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";
import { WidgetFrequencySelect } from "../../../../common/toolbar/WidgetFrequencySelect";
import { billingExplorerSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { billingExplorerFrequencyChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerFrequencyChangeThunk";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import {
  billingExplorerWidgetVisualizationDetailsByChartType,
  billingExplorerWidgetVisualizationDetailsComponentByType,
} from "../../../../../../../widgets/billing-explorer-widget/utils/constants/constants";

export const BillingExplorerWidgetVisualizationSection: FC = () => {
  const dispatch = useAppDispatch();

  const chartType = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("chart_type"),
  );
  const viewId = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("viewId"),
  );
  const viewType = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("chart_type"),
  );
  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );
  const frequency = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("frequency"),
  );

  const frequencyChangeHandler = useCallback(
    (e: any) => {
      dispatch(billingExplorerFrequencyChangeThunk(e.target.value));
    },
    [dispatch],
  );

  return (
    <WidgetConfigSection
      initialExpanded
      title="Visualization"
      icon={<img src={VisualizationIcon} alt="Visualization Icon" />}
    >
      <Stack spacing={1.5}>
        <BillingExplorerWidgetViewTypeSelect />

        <WidgetFrequencySelect
          frequency={frequency}
          onChange={frequencyChangeHandler}
          disabled={
            ["pie", "numeral", "table"].includes(viewType) || viewGettingLoading
          }
        />

        {billingExplorerWidgetVisualizationDetailsByChartType
          .get(chartType)
          ?.map((type) => {
            const Component =
              billingExplorerWidgetVisualizationDetailsComponentByType.get(
                type,
              );

            if (!Component) {
              return;
            }

            return <Component key={type} />;
          })}
      </Stack>
    </WidgetConfigSection>
  );
};
