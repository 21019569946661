import { RootState } from "../../../../store";
import { billingSummaryWidgetEditChangesExistSelector } from "../../widgets/billing-summary/billingSummaryWidgetEditChangesExistSelector";
import { commitmentUtilizationWidgetEditChangesExistSelector } from "../../widgets/commitment-utilization/setup/commitmentUtilizationWidgetEditChangesExistSelector";
import { billingExplorerWidgetEditChangesExistSelector } from "../../widgets/billing-explorer-widget/setup/billingExplorerWidgetEditChangesExistSelector";
import { costAnomaliesWidgetEditChangesExistSelector } from "../../widgets/cost-anomalies/costAnomaliesWidgetEditChangesExistSelector";
import { WidgetType } from "../../../../../services/cloudchipr.api";
import { coverageWidgetEditChangesExistSelector } from "../../widgets/coverage/setup/coverageWidgetEditChangesExistSelector";

export const widgetEditChangesExistSelector = (
  state: RootState,
  widgetType: WidgetType,
) => {
  if (widgetType === "cost_and_usage_summary") {
    return billingSummaryWidgetEditChangesExistSelector(state);
  }

  if (widgetType === "commitments_utilization") {
    return commitmentUtilizationWidgetEditChangesExistSelector(state);
  }

  if (widgetType === "cost_breakdown") {
    return billingExplorerWidgetEditChangesExistSelector(state);
  }

  if (widgetType === "largest_cost_changes") {
    return costAnomaliesWidgetEditChangesExistSelector(state);
  }

  if (widgetType === "coverage") {
    return coverageWidgetEditChangesExistSelector(state);
  }

  return false;
};
