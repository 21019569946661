import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import {
  cloudChiprApi,
  PostUsersMeOrganisationsCurrentTaskViewsApiArg,
  View,
} from "../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { getTasksViewsHierarchyThunk } from "../getTasksViewsHierarchyThunk";
import { createTaskViewFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

interface CreateSavingsTaskViewThunkArgs {
  body: PostUsersMeOrganisationsCurrentTaskViewsApiArg["body"];
  disableSnackbar?: boolean;
}

export const createTasksViewThunk = createAsyncThunk(
  "taskManagement/createTasksView",
  async (
    { body, disableSnackbar }: CreateSavingsTaskViewThunkArgs,
    { dispatch },
  ): Promise<View | undefined> => {
    const { postUsersMeOrganisationsCurrentTaskViews } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentTaskViews.initiate(
          { body },
          { fixedCacheKey: createTaskViewFixedCacheKey },
        ),
      ).unwrap();

      if (!disableSnackbar) {
        const snackbarId: SnackbarKey = enqueueSnackbar(
          "View successfully created.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }

      dispatch(getTasksViewsHierarchyThunk());
      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
      return;
    }
  },
);
