import { ListItemText, Stack } from "@mui/material";
import { getTagKeyGroupingOption } from "../tag-keys/getTagKeyGroupingOption";
import { GroupingOptionIcon } from "../../GroupingOptionIcon";
import { GroupingAvailableProviders } from "../../GroupingAvailableProviders";
import { DropdownSelectOption } from "../../../../../../common/select/dropdown-select/utils/types/types";
import {
  GroupingChangeHandler,
  ResourceExplorerGroupedPossibleGroupings,
} from "../../../utils/types/types";
import { ResourceExplorerGroupingNullable } from "../../../../../../../services/cloudchipr.api";

export const getFlatGroupingOptions = (
  groupedPossibleGroupings: ResourceExplorerGroupedPossibleGroupings,
  parentLabel: string,
  value: ResourceExplorerGroupingNullable,
  onChange: GroupingChangeHandler,
  onClose: () => void,
  groupValues?: string[],
  hiddenGroupings?: ResourceExplorerGroupingNullable[],
): DropdownSelectOption[] => {
  return groupedPossibleGroupings?.[parentLabel]
    ?.filter((item) => !hiddenGroupings?.includes(item.key))
    ?.map((grouping) => {
      const label = grouping?.items[0]?.label?.singular;
      const groupingKey = grouping.key;

      if (groupingKey === "tag_key") {
        return getTagKeyGroupingOption(
          value,
          label ?? null,
          onChange,
          onClose,
          groupValues,
        );
      }

      return {
        value: groupingKey,
        rawValue: { search: `${groupingKey} ${label}` },
        label: (
          <Stack direction="row" spacing={1} alignItems="center">
            <GroupingOptionIcon grouping={groupingKey} />
            <ListItemText
              primary={label}
              primaryTypographyProps={{
                sx: { whiteSpace: "nowrap", width: "fit-content" },
              }}
              sx={{ flexGrow: 0 }}
            />
            <GroupingAvailableProviders grouping={groupingKey} />
          </Stack>
        ),
      };
    });
};
