import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { WidgetContentCostsCard } from "../../common/WidgetContentCostsCard";
import {
  Dates,
  GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse,
  WidgetCommitmentsUtilizationAggregation,
} from "../../../../../../../services/cloudchipr.api";
import { getTrendTooltipTitle } from "../../../../utils/helpers/trend-tooltip-title/getTrendTooltipTitle";
import { calculateFromToByDatesData } from "../../../../../../common/date-range-picker/utils/helpers/calculateFromToByDatesData";

interface CommitmentUtilizationWidgetCostsProps {
  aggregation?: WidgetCommitmentsUtilizationAggregation;
  data?: GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse;
  dates?: Dates;
  quarterStartMonth?: number;
}

export const CommitmentUtilizationWidgetCosts: FC<
  CommitmentUtilizationWidgetCostsProps
> = ({ aggregation, data, dates, quarterStartMonth }) => {
  const { toDate, fromDate } = useMemo(
    () => calculateFromToByDatesData(dates ?? {}, quarterStartMonth),
    [dates, quarterStartMonth],
  );

  const trendTooltipTitle = useMemo(
    () =>
      getTrendTooltipTitle(
        data?.current_period_date_details?.date_from,
        data?.current_period_date_details?.date_to,
        data?.previous_period_date_details?.date_from,
        data?.previous_period_date_details?.date_to,
        undefined,
        dates?.label,
      ),
    [
      data?.current_period_date_details,
      data?.previous_period_date_details,
      dates?.label,
    ],
  );

  return (
    <Stack direction="row" padding={2} spacing={2}>
      {aggregation?.utilization && (
        <WidgetContentCostsCard
          title="Utilization"
          tooltipTitle={`Commitments Utilization from ${fromDate} to ${toDate}`}
          cost={data?.utilization?.amount}
          percent={data?.utilization?.percentage}
          trend={Number(data?.utilization_trend)}
          trendTooltipTitle={trendTooltipTitle}
        />
      )}

      {aggregation?.commitments && (
        <WidgetContentCostsCard
          title="Commitments"
          tooltipTitle={`Commitments from ${fromDate} to ${toDate}`}
          cost={data?.commitments}
          trend={Number(data?.commitments_trend)}
          trendTooltipTitle={trendTooltipTitle}
        />
      )}

      {aggregation?.net_savings && (
        <WidgetContentCostsCard
          title="Net Savings"
          tooltipTitle={netSavingsTooltipTitle}
          cost={data?.net_savings}
          trend={Number(data?.net_savings_trend)}
          trendTooltipTitle={trendTooltipTitle}
        />
      )}
    </Stack>
  );
};

const netSavingsTooltipTitle =
  "Net Savings from commitments for the selected date range. Calculated by comparing the usage cost covered by Commitments to the public On-Demand cost.";
