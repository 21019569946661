import {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Box, Card, MenuItem, Stack } from "@mui/material";
import { SuggestionProps } from "@tiptap/suggestion";
import { EditorSuggestion } from "../utils/types/types";

export const MentionList: FC<SuggestionProps<EditorSuggestion>> = forwardRef(
  (props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = (index: number) => {
      const item = props.items[index];

      if (item) {
        props.command({ id: item.id, label: item.label });
      }
    };

    const upHandler = () => {
      setSelectedIndex(
        (selectedIndex + props.items.length - 1) % props.items.length,
      );
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [props.items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        if (event.key === "ArrowUp") {
          upHandler();
          return true;
        }

        if (event.key === "ArrowDown") {
          downHandler();
          return true;
        }

        if (event.key === "Enter") {
          enterHandler();
          return true;
        }

        return false;
      },
    }));

    return (
      <Card variant="elevation" elevation={4}>
        <Stack>
          {props?.items?.length ? (
            props.items.map((suggestion, index) => (
              <MenuItem
                key={suggestion.id}
                onClick={() => selectItem(index)}
                selected={index === selectedIndex}
              >
                {suggestion.label}
              </MenuItem>
            ))
          ) : (
            <Box className="item">No result</Box>
          )}
        </Stack>
      </Card>
    );
  },
);
