import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { billingExplorerWidgetSetupCostsSelector } from "./billingExplorerWidgetSetupCostsSelector";

export const billingExplorerWidgetSetupTotalCostSelector =
  createDraftSafeSelector(
    [billingExplorerWidgetSetupCostsSelector],
    (costs) => {
      return costs.total;
    },
  );
