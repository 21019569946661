import { FC } from "react";
import { Stack } from "@mui/material";
import { AskAICollapsableConversationItem } from "./collapsed-conversation/AskAICollapsableConversationItem";
import { useAppSelector } from "../../../../../../store/hooks";
import { chunkedAskAiConversationsSelector } from "../../../../../../store/ask-ai/selectors/chunkedAskAiConversationsSelector";

export const AskAiConversation: FC = () => {
  const chunkedConversation = useAppSelector(chunkedAskAiConversationsSelector);

  return (
    <Stack px={1.5} spacing={1.5} pb={1}>
      {chunkedConversation?.map((item, index) => {
        return (
          <AskAICollapsableConversationItem
            key={index + item?.[0]?.text}
            data={item}
            index={index}
            totalCount={chunkedConversation.length}
            collapsed={
              index < chunkedConversation?.length &&
              chunkedConversation?.length > 1
            }
          />
        );
      })}
    </Stack>
  );
};
