import { commitmentsDetailsDrawerTargetIdSelector } from "./commitmentsDetailsDrawerTargetIdSelector";
import { commitmentsDetailsDrawerTargetTypeSelector } from "./commitmentsDetailsDrawerTargetTypeSelector";
import { RootState } from "../../../../store";

export const commitmentsDetailsDrawerOpenSelector = (state: RootState) => {
  const targetId = commitmentsDetailsDrawerTargetIdSelector(state);
  const targetType = commitmentsDetailsDrawerTargetTypeSelector(state);

  return !!(targetId && targetType);
};
