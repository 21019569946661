import { FC } from "react";
import { UtilizationAndCoverageDataViewTabs } from "./components/UtilizationAndCoverageDataViewTabs";
import { savingsPlansTableColumnsDefs } from "./columns/savingsPlansTableColumnsDefs";
import { useAppSelector } from "../../../../../../../store/hooks";
import { savingsPlansDataSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/savingsPlansDataSelector";
import { savingsPlansForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/savingsPlansForTableLoadingSelector";
import { CommitmentsAGGridToolbar } from "../../../../common/components/data-grid/CommitmentsAGGridToolbar";
import {
  CommitmentsAgGrid,
  CommitmentsToolbar,
} from "../../../../common/components/CommitmentsAgGrid";

export const SavingsPlansDataGrid: FC = () => {
  const data = useAppSelector(savingsPlansDataSelector);
  const loading = useAppSelector(savingsPlansForTableLoadingSelector);

  return (
    <CommitmentsAgGrid
      Toolbar={toolbar}
      loading={loading}
      columnDefs={savingsPlansTableColumnsDefs}
      data={data}
      grouping="commitment"
      localStorageUniqueKey="savingsPlanDataGrid"
    />
  );
};

const toolbar: CommitmentsToolbar = ({ gridApi }) => (
  <CommitmentsAGGridToolbar gridApi={gridApi}>
    <UtilizationAndCoverageDataViewTabs />
  </CommitmentsAGGridToolbar>
);
