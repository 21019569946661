import { FC, Fragment, useCallback } from "react";
import { IconButton, Popover, Tooltip } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import { ResourceDetailsLoading } from "./ResourceDetailsLoading";
import { ResourceTagsTable } from "./ResourceTagsTable";
import { ProviderType } from "../../../../../../../../../../../../services/cloudchipr.api";
import { tagLabels } from "../../../../../../../utils/constants/tagLabels";
import { resourceExplorerSingleResourceDataLoadingSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/resource-explorer-single-resource-data/resourceExplorerSingleResourceDataLoadingSelector";
import { RootState } from "../../../../../../../../../../../../store/store";
import { resourceExplorerSingleResourceDataDataSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/resource-explorer-single-resource-data/resourceExplorerSingleResourceDataDataSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../store/hooks";
import { getResourceExplorerSingleResourceDataThunk } from "../../../../../../../../../../../../store/resource-explorer/thunks/resource-explorer/getResourceExplorerSingleResourceDataThunk";
import { capitalizeString } from "../../../../../../../../../../../../utils/helpers/capitalizeString";
import { useMenuHook } from "../../../../../../../../../../../../utils/hooks/useMenu.hook";

interface ResourceTagsProps {
  accountId: string;
  resourceId: string;
  provider: ProviderType;
}

export const ResourceTags: FC<ResourceTagsProps> = ({
  resourceId,
  accountId,
  provider,
}) => {
  const dispatch = useAppDispatch();
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const selector = useCallback(
    (state: RootState) => {
      return {
        isLoading: resourceExplorerSingleResourceDataLoadingSelector(
          state,
          provider,
          accountId,
          resourceId,
        ),
        data: resourceExplorerSingleResourceDataDataSelector(
          state,
          provider,
          accountId,
          resourceId,
        ),
      };
    },
    [resourceId, accountId, provider],
  );

  const { isLoading, data } = useAppSelector(selector);

  useEffectOnceWhen(() => {
    dispatch(
      getResourceExplorerSingleResourceDataThunk({
        provider,
        accountId,
        resourceId,
      }),
    );
  }, open);

  if (!accountId || !resourceId) {
    return null;
  }

  return (
    <Fragment>
      <Tooltip
        arrow
        placement="top"
        title={capitalizeString(tagLabels.get(provider) ?? "") ?? "Tags"}
      >
        <IconButton size="small" onClick={openMenu}>
          <DiscountOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      {open && (
        <Popover
          open={open}
          anchorEl={anchor}
          onClose={closeMenu}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          {isLoading ? (
            <ResourceDetailsLoading />
          ) : (
            <ResourceTagsTable tags={data?.tags ?? []} provider={provider} />
          )}
        </Popover>
      )}
    </Fragment>
  );
};
