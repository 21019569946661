import { FC, Fragment } from "react";
import { Typography } from "@mui/material";
import { TaskActivityWrapper } from "./TaskActivityWrapper";
import { CommentUser } from "../../../../../../../../task-management/components/grid/cell-renderers/comment-cell/components/CommentUser";
import { ActivityComponentProps } from "../../utils/types/types";

interface DueDateActivityProps extends ActivityComponentProps {
  updatedDueDate?: string;
  prevDueDate?: string;
}

export const DueDateActivity: FC<DueDateActivityProps> = ({
  updatedDueDate,
  prevDueDate,
  createdAt,
  createdBy,
}) => {
  let additionalText = (
    <Fragment>
      changed due date from{" "}
      <Typography variant="inherit" component="span" fontWeight="medium">
        {prevDueDate}
      </Typography>{" "}
      to{" "}
      <Typography variant="inherit" component="span" fontWeight="medium">
        {updatedDueDate}
      </Typography>
    </Fragment>
  );

  if (!updatedDueDate) {
    additionalText = (
      <Fragment>
        removed due date{" "}
        <Typography variant="inherit" component="span" fontWeight="medium">
          {prevDueDate}
        </Typography>
      </Fragment>
    );
  }

  if (!prevDueDate) {
    additionalText = (
      <Fragment>
        set due date to{" "}
        <Typography variant="inherit" component="span" fontWeight="medium">
          {updatedDueDate}
        </Typography>
      </Fragment>
    );
  }

  return (
    <TaskActivityWrapper
      content={<CommentUser user={createdBy} additionalText={additionalText} />}
      createdAt={createdAt}
    />
  );
};
