import { FC, useMemo } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { useLocation } from "react-router-dom";
import { DatasetLinkedOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { CommitmentsNavigationItem } from "./CommitmentsNavigationItem";
import { AppNavItemWrapper } from "../../app-nav-components/AppNavItemWrapper";
import { useAppSelector } from "../../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { commitmentsNavigationItemsSelector } from "../../../../../store/commitments/selectors/common/commitmentsNavigationItemsSelector";
import { useGetUsersMeOrganisationsCurrentProviderOrganisationsQuery } from "../../../../../services/cloudchipr.api";

export const CommitmentsNavigation: FC = () => {
  const enableReservedInstances = useFlag("EnableReservedInstances");
  const enableAzureCommitments = useFlag("EnableAzureCommitments");

  useGetUsersMeOrganisationsCurrentProviderOrganisationsQuery(undefined);

  const navItems = useAppSelector(commitmentsNavigationItemsSelector);

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  const location = useLocation();
  const selected = location.pathname.startsWith("/commitments");

  const filteredNavItems = useMemo(() => {
    if (enableAzureCommitments) {
      return navItems;
    } else {
      return navItems.filter((item) => item.provider === "aws");
    }
  }, [navItems, enableAzureCommitments]);

  if (!filteredNavItems) {
    return null;
  }

  const firstItem = filteredNavItems.at(0);
  const firstOrgId = firstItem?.orgId ?? "";
  const firstProvider = firstItem?.provider ?? "";

  return (
    <AppNavItemWrapper
      primary={
        !filteredNavItems.length ? (
          <Tooltip title="Have no Commitments" arrow>
            <div>Commitments</div>
          </Tooltip>
        ) : (
          "Commitments"
        )
      }
      Icon={DatasetLinkedOutlined}
      selected={selected}
      navCollapsed={navigationCollapsed}
      disabled={!filteredNavItems.length}
      to={
        enableReservedInstances
          ? `/commitments/${firstProvider}/${firstOrgId}`
          : `/commitments/utilization-and-coverage/${firstOrgId}`
      }
    >
      {filteredNavItems.length > 1
        ? filteredNavItems.map((props) => (
            <CommitmentsNavigationItem
              {...props}
              key={props.orgId + props.provider}
            />
          ))
        : null}
    </AppNavItemWrapper>
  );
};
