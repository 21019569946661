import { ColDef } from "ag-grid-community";
import {
  SavingsPlanRecommendation,
  SavingsPlanType,
} from "../../../../../../services/cloudchipr.api";
import { commitmentsRecommendationsGridColumnDefs } from "../../components/content/recommendations/data-grid/commitmentsRecommendationsGridColumnDefs";

export const filterRecommendationsColumns = (
  orgExists: boolean,
  savingsPlanTypes?: SavingsPlanType[],
): ColDef<SavingsPlanRecommendation>[] => {
  let columns = commitmentsRecommendationsGridColumnDefs;

  if (orgExists) {
    columns = columns.filter((column) => column.field !== "account");
  }

  if (savingsPlanTypes?.includes("ec2_instance")) {
    return columns;
  }

  return columns.filter(
    (column) => !["instance_family", "region"].includes(column.field ?? ""),
  );
};
