import { RootState } from "../../../store";
import { resourceDetailsSliceSelector } from "../resourceDetailsSliceSelector";
import { ResourceDetailsInterface } from "../../utils/types/common";

export const resourceDetailsBillingProcessesSelector = (
  state: RootState,
): ResourceDetailsInterface["billingDataProcesses"] => {
  const slice = resourceDetailsSliceSelector(state);

  return slice.billingDataProcesses;
};
