import { FC } from "react";
import { BillingExplorerWidgetChartView } from "./chart/BillingExplorerWidgetChartView";
import { BillingExplorerWidgetTableView } from "./chart/table/BillingExplorerWidgetTableView";
import { BillingExplorerWidgetNumeralView } from "./chart/numeral/BillingExplorerWidgetNumeralView";
import { useAppSelector } from "../../../../../../../store/hooks";
import { DashboardEmptyWidgetState } from "../../common/DashboardEmptyWidgetState";
import { ChartType } from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { DashboardNotFoundWidget } from "../../common/DashboardNotFoundWidget";
import { billingExplorerWidgetNotFoundSelector } from "../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetNotFoundSelector";
import { billingExplorerWidgetByIdSelector } from "../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetByIdSelector";

interface BillingExplorerWidgetContentProps {
  widgetId: string;
  viewName?: string;
}

export const BillingExplorerWidgetContent: FC<
  BillingExplorerWidgetContentProps
> = ({ widgetId, viewName }) => {
  const widgetDataNotFound = useAppSelector((state) =>
    billingExplorerWidgetNotFoundSelector(state, widgetId),
  );

  const widget = useAppSelector((state) =>
    billingExplorerWidgetByIdSelector(state, widgetId),
  );

  if (widgetDataNotFound) {
    return (
      <DashboardNotFoundWidget name={viewName ?? ""} label="billing report" />
    );
  }

  if (!widget) {
    return <DashboardEmptyWidgetState infoCards={infoCards} />;
  }

  if (widget.chart_type === "table") {
    return (
      <BillingExplorerWidgetTableView
        widgetId={widgetId}
        dateLabel={widget.dates?.label}
      />
    );
  }

  if (widget.chart_type === "numeral") {
    return (
      <BillingExplorerWidgetNumeralView
        widgetId={widgetId}
        dateLabel={widget.dates?.label}
      />
    );
  }

  return (
    <BillingExplorerWidgetChartView
      widgetId={widgetId}
      dateDataPoint={widget.date_granularity}
      dateLabel={widget.dates?.label}
      chartType={widget.chart_type as ChartType}
    />
  );
};

const infoCards = [
  {
    value: "-",
    title: "Total Cost",
  },
  {
    value: "-",
    title: "Filtered Resources",
  },
];
