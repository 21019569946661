import { capitalizeString } from "../capitalizeString";
import { ResourceExplorerFilterProvider } from "../../../services/cloudchipr.api";

const providerNames: Map<ResourceExplorerFilterProvider, string> = new Map([
  ["aws", "account"],
  ["gcp", "project"],
  ["azure", "subscription"],
  ["confluent", "account"],
  ["kubernetes", "cluster"],
  ["dimensions", "category"],
  ["eks", "cluster"],
  ["global", "item"],
  ["mongo", "cluster"],
  ["snowflake", "account"],
  ["datadog", "account"],
]);

const providerTitles: Map<ResourceExplorerFilterProvider, string> = new Map([
  ["aws", "AWS"],
  ["gcp", "GCP"],
  ["azure", "Azure"],
  ["confluent", "Confluent"],
  ["kubernetes", "Kubernetes"],
  ["global", "Global"],
  ["mongo", "MongoDB"],
  ["dimensions", "Dimensions"],
  ["eks", "EKS"],
  ["snowflake", "Snowflake"],
  ["datadog", "Datadog"],
]);

export const getProviderName = (
  provider: ResourceExplorerFilterProvider,
  options?: {
    title?: boolean;
    plural?: boolean;
    nameAndTitle?: boolean;
    capitalize?: boolean;
    uppercase?: boolean;
  },
) => {
  let providerName = providerNames.get(provider) ?? "";

  if (options?.title) {
    providerName = providerTitles.get(provider) ?? "";
  }

  if (options?.capitalize) {
    providerName = capitalizeString(providerName);
  }
  if (options?.plural) {
    providerName = providerName + "s";
  }
  if (options?.nameAndTitle) {
    providerName = `${providerTitles.get(provider)} ${providerName}`;
  }
  if (options?.uppercase) {
    providerName = providerName.toUpperCase();
  }

  return providerName;
};
