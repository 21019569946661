import { FC, useCallback } from "react";
import {
  capitalize,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
} from "@mui/material";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import FunctionsIcon from "@mui/icons-material/Functions";
import { useAppDispatch } from "../../../../../../../../../store/hooks";
import { ResourceExplorerChartScale } from "../../../../../../../../../store/resource-explorer/utils/types/resourceExplorer";
import { setBillingExplorerChartSettings } from "../../../../../../../../../store/resource-explorer/resourceExplorerSlice";

interface BillingExplorerChartScaleSelectProps {
  scale: ResourceExplorerChartScale;
}

export const BillingExplorerChartScaleSelect: FC<
  BillingExplorerChartScaleSelectProps
> = ({ scale }) => {
  const dispatch = useAppDispatch();

  const changeHandler = useCallback(
    (event: SelectChangeEvent<"linear" | "logarithmic">) => {
      const val = event.target.value as ResourceExplorerChartScale;

      if (!val) {
        return null;
      }

      dispatch(setBillingExplorerChartSettings({ chartScale: val }));
    },
    [dispatch],
  );

  return (
    <FormControl size="small" fullWidth>
      <InputLabel>Scale</InputLabel>

      <Select
        value={scale}
        label="Scale"
        size="small"
        sx={selectStyles}
        MenuProps={selectMenuProps}
        onChange={changeHandler}
        startAdornment={icons[scale]}
        renderValue={(value) => (
          <Typography pl={1}>{capitalize(value)}</Typography>
        )}
      >
        <MenuItem value="linear">
          <ListItemIcon>{icons.linear}</ListItemIcon>
          <ListItemText>Linear</ListItemText>
        </MenuItem>

        <MenuItem value="logarithmic">
          <ListItemIcon>{icons.logarithmic}</ListItemIcon>
          <ListItemText>Logarithmic</ListItemText>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const icons = {
  linear: <StackedLineChartIcon fontSize="small" color="action" />,
  logarithmic: <FunctionsIcon fontSize="small" color="action" />,
};

const selectMenuProps: SelectProps["MenuProps"] = {
  anchorOrigin: { vertical: "bottom", horizontal: "left" },
  transformOrigin: { vertical: 5, horizontal: "left" },
};

const selectStyles = {
  bgcolor: "white",
  "& .MuiSelect-select": { px: 0.5 },
};
