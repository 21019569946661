import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Typography } from "@mui/material";
import { ReservationsCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { money } from "../../../../../../../../../../../utils/numeral/money";

export const netSavingsColumnDef: ColDef<ReservationsCoverageDataWithId> = {
  flex: 1.5,
  field: "net_savings",
  headerName: "Net Savings",
  minWidth: 200,
  cellRenderer: ({
    data,
  }: CustomCellRendererProps<ReservationsCoverageDataWithId>) => (
    <Typography variant="body2">{money(data?.net_savings ?? 0)}</Typography>
  ),
  valueGetter: ({ data }) => money(data?.net_savings ?? 0),
};
