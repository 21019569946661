import { FC, useCallback } from "react";
import { CalendarTodayOutlined } from "@mui/icons-material";
import { DatePicker, DatePickerSlotProps } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { Box } from "@mui/material";
import { FloatingActionButton } from "./FloatingActionButton";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { taskManagementUpdateSelectedTasksThunk } from "../../../../../../store/task-management/thunks/actions/taskManagementUpdateSelectedTasksThunk";
import { useAppDispatch } from "../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../services/permissions";

export const FloatingDueDateSelect: FC = () => {
  const dispatch = useAppDispatch();
  const { open, openMenu, closeMenu, anchor } = useMenuHook();

  const { can } = useAppAbility();
  const canEditTask = can("edit", "task");

  const changeHandler = useCallback(
    (date: Moment | null) => {
      const updatedDate = date
        ? formatDate(date, { type: "yearMonthDay" })
        : null;

      dispatch(
        taskManagementUpdateSelectedTasksThunk({
          due_date: { value: updatedDate },
        }),
      );

      closeMenu();
    },
    [closeMenu, dispatch],
  );

  return (
    <Box>
      <FloatingActionButton
        icon={CalendarTodayOutlined}
        onClick={openMenu}
        disabled={!canEditTask}
      >
        Due Date
      </FloatingActionButton>

      <DatePicker
        open={open}
        onClose={closeMenu}
        value={null}
        onChange={changeHandler}
        slotProps={{
          textField: textFieldProps,
          popper: {
            anchorEl: anchor,
            ...popperProps,
          },
        }}
      />
    </Box>
  );
};

const textFieldProps: DatePickerSlotProps<Moment, false>["textField"] = {
  error: false,
  size: "small",
  variant: "standard",
  sx: { width: 0, height: 0, p: 0 },
  InputProps: { endAdornment: null },
};

const popperProps: DatePickerSlotProps<Moment, false>["popper"] = {
  placement: "top-start",
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, 14],
      },
    },
  ],
};
