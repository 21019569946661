import { FC, Fragment, useCallback } from "react";
import { CoverageWidgetSetupSidebar } from "./components/sidebar/CoverageWidgetSetupSidebar";
import { CoverageWidgetSetupContent } from "./components/content/CoverageWidgetSetupContent";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { coverageWidgetSetupSelector } from "../../../../../../../../store/dashboards/selectors/setups/coverage/coverageWidgetSetupSelector";
import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import { coverageSubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/coverage/coverageSubmitButtonDisabledSelector";
import { buildCoverageWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/coverage-widget/actions/buildCoverageWidgetThunk";

interface CoverageWidgetSetupProps {
  onClose(): void;
  onSubmit(): void;
}

export const CoverageWidgetSetup: FC<CoverageWidgetSetupProps> = ({
  onClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();
  const setup = useAppSelector(coverageWidgetSetupSelector);

  const disabled = useAppSelector(coverageSubmitButtonDisabledSelector);

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(buildCoverageWidgetThunk()).unwrap();
    onSubmit();

    if (responseId && !setup?.id) {
      scrollPageToBottom();
    }
  }, [onSubmit, dispatch, setup?.id]);

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />
      <WidgetSetupLayout
        empty={
          !setup?.providerOrganisationId
            ? "Select Organization to get started."
            : ""
        }
        appBar={<CoverageWidgetSetupSidebar />}
      >
        <CoverageWidgetSetupContent />
      </WidgetSetupLayout>
    </Fragment>
  );
};
