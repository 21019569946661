import { FC, useCallback, useEffect, useState } from "react";
import { LiveUsageMgmtNavItemMain } from "../../../../live-usage-mgmt/components/navigation/components/sidebar-navigation/components/LiveUsageMgmtNavItemMain";
import {
  LiveUsageNavigationResourceType,
  useLiveUsageMgmtPageNavigate,
} from "../../../../live-usage-mgmt/components/navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { ResourceGroupType } from "../../../../../../utils/constants/resources/resourceGroupsByProvider";
import { LiveUsageResourceNavItemCollapsed } from "../../../../live-usage-mgmt/components/navigation/components/sidebar-navigation/components/LiveUsageResourceNavItemCollapsed";
import { useAppSelector } from "../../../../../../store/hooks";
import { kubernetesLiveUsageMgmtResourceTypeDataSpendingSelector } from "../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtResourceTypeDataSpendingSelector";
import { kubernetesLiveUsageMgmtResourceTypeDataFilteredSpendingSelector } from "../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtResourceTypeDataFilteredSpendingSelector";
import { kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector";

interface KubernetesLiveUsageResourceNavItemProps extends ResourceGroupType {
  selectedResourceType?: LiveUsageNavigationResourceType;
  sidebarCollapsed?: boolean;
  hideCosts?: boolean;
}

export const KubernetesLiveUsageResourceNavItem: FC<
  KubernetesLiveUsageResourceNavItemProps
> = ({
  expandable,
  actionableResourceTypes,
  value,
  selectedResourceType,
  sidebarCollapsed,
  hideCosts,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleNavigationClick = useLiveUsageMgmtPageNavigate();
  const filteredCost = useAppSelector((state) =>
    kubernetesLiveUsageMgmtResourceTypeDataFilteredSpendingSelector(
      state,
      value,
    ),
  );
  const totalCost = useAppSelector((state) =>
    kubernetesLiveUsageMgmtResourceTypeDataSpendingSelector(state, value),
  );

  const filterTemplatesEnabled = useAppSelector(
    kubernetesLiveUsageMgmtFilterTemplatesEnabledSelector,
  );
  const nestedSelected = actionableResourceTypes.some(
    (resourceType) => resourceType === selectedResourceType,
  );

  const mainSelected =
    (!expandable || (expandable && !expanded)) && nestedSelected;

  const toggleExpanded = useCallback(() => {
    setExpanded((e) => !e);
  }, []);

  const handleMainItemClick = useCallback(() => {
    toggleExpanded();

    if (!expanded) {
      handleNavigationClick(value);
    }
  }, [toggleExpanded, expanded, value, handleNavigationClick]);

  useEffect(() => {
    if (nestedSelected) {
      setExpanded(true);
    }
  }, [nestedSelected]);

  if (sidebarCollapsed) {
    return (
      <LiveUsageResourceNavItemCollapsed
        resourceType={value}
        selected={nestedSelected}
        provider="kubernetes"
        expandable={expandable}
        actionableResourceTypes={actionableResourceTypes}
        selectedResourceType={selectedResourceType}
        parentFilteredCost={filteredCost}
        parentTotalCost={totalCost}
        filterTemplatesEnabled={filterTemplatesEnabled}
      />
    );
  }

  return (
    <LiveUsageMgmtNavItemMain
      resourceType={value}
      provider="kubernetes"
      selected={mainSelected}
      expandable={expandable}
      expanded={expanded}
      onClick={handleMainItemClick}
      hideCosts={hideCosts}
      parentFilteredCost={filteredCost}
      parentTotalCost={totalCost}
      filterTemplatesEnabled={filterTemplatesEnabled}
    />
  );
};
