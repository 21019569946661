import { FC } from "react";
import { useLocation } from "react-router-dom";
import { ScheduleSendOutlined } from "@mui/icons-material";
import { AppNavItemWrapper } from "../app-nav-components/AppNavItemWrapper";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";

export const ReportsNavigation: FC = () => {
  const location = useLocation();

  const reportsSelected = location.pathname.startsWith("/subscriptions");

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  return (
    <AppNavItemWrapper
      divider
      to="/subscriptions"
      primary="Subscriptions"
      Icon={ScheduleSendOutlined}
      navCollapsed={navigationCollapsed}
      selected={reportsSelected}
    />
  );
};
