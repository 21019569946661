import { FC, useCallback, useEffect } from "react";
import { Button, Stack } from "@mui/material";
import { AddViewInHierarchyDialog } from "./adding-view/AddViewInHierarchyDialog";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";
import { View } from "../../../../../services/cloudchipr.api";

interface ViewsHierarchySaveAsNewSnackBarProps {
  loading: boolean;
  onClose(): void;
  currentView?: View;
  onSubmit(viewName: string): Promise<any>;
}

export const ViewsHierarchySaveAsNewSnackBar: FC<
  ViewsHierarchySaveAsNewSnackBarProps
> = ({ loading, currentView, onSubmit, onClose }) => {
  const { open, openDialog, closeDialog } = useDialog();

  const isProtected = currentView?.protection_details?.is_protected;
  const currentViewId = currentView?.id;

  const closeHandler = useCallback(() => {
    onClose();
    closeDialog();
  }, [onClose, closeDialog]);

  useEffect(() => {
    if (currentViewId && !isProtected) {
      closeHandler();
    }
  }, [currentViewId, isProtected, closeHandler]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      spacing={1}
    >
      <Button
        variant="text"
        onClick={onClose}
        size="small"
        color="tertiary"
        sx={buttonStyles}
      >
        Close
      </Button>
      <Button
        variant="outlined"
        sx={buttonStyles}
        onClick={openDialog}
        size="small"
      >
        Save As New View
      </Button>

      <AddViewInHierarchyDialog
        open={open}
        loading={loading}
        onSubmit={onSubmit}
        onClose={closeHandler}
      />
    </Stack>
  );
};

const buttonStyles = { textTransform: "none" };
