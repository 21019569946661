import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceDetailsResourceBillingChartLabelsSelector } from "./resourceDetailsResourceBillingChartLabelsSelector";
import { resourceDetailsGeneralTabBillingDataSelector } from "./resourceDetailsGeneralTabBillingDataSelector";
import { HighchartsChartDataType } from "../../../../../storybook/c8r-highcharts/common/utils/types/common";
import { billingExplorerChartDataGenerator } from "../../../../../components/pages/common/billing-explorer/utils/billingExplorerChartDataGenerator";

export const resourceDetailsResourceBillingChartDataSelector =
  createDraftSafeSelector(
    [
      resourceDetailsGeneralTabBillingDataSelector,
      resourceDetailsResourceBillingChartLabelsSelector,
    ],
    (data, labels): HighchartsChartDataType | null => {
      const chartData = data?.data;

      if (!chartData || !labels) {
        return null;
      }

      return billingExplorerChartDataGenerator(chartData, labels);
    },
  );
