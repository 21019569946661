import { kubernetesClustersSelector } from "./kubernetesClustersSelector";
import { RootState } from "../../../store";
import { KubernetesCluster } from "../../../../services/cloudchipr.api";

export const kubernetesClustersCashesSelector = (state: RootState) => {
  const clusters = kubernetesClustersSelector(state);

  if (!clusters) {
    return null;
  }

  return {
    idleMonthlyCost: costCombiner(clusters, "idle_monthly_cost"),
    totalMonthlyCost: costCombiner(clusters, "total_monthly_cost"),
  };
};

const costCombiner = (
  connectedAccounts: KubernetesCluster[] | null,
  key: "idle_monthly_cost" | "total_monthly_cost",
): number => {
  return connectedAccounts?.reduce((acc, cur) => acc + cur[key], 0) ?? 0;
};
