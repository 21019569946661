import { FC, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import { useAppNavOverlayContext } from "../../../utils/useAppNavOverlay.context";
import { NestedNavItem } from "../../app-nav-components/nested-nav-item/NestedNavItem";

interface AutomationsNavigationItemProps {
  type: "workflow" | "off-hours";
}

export const AutomationsNavigationItem: FC<AutomationsNavigationItemProps> = ({
  type,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { onClose } = useAppNavOverlayContext();

  const handleButtonClick = useCallback(() => {
    onClose?.();
    navigate(`/automations/${type}`);
  }, [onClose, navigate, type]);

  return (
    <NestedNavItem
      nested
      primary={labelsByType[type]}
      Icon={iconsByType[type]}
      onButtonClick={handleButtonClick}
      selected={location.pathname.startsWith(`/automations/${type}`)}
    />
  );
};

const iconsByType = {
  workflow: AccountTreeOutlinedIcon,
  "off-hours": HistoryToggleOffOutlinedIcon,
};

const labelsByType = {
  workflow: "Workflows",
  "off-hours": "Off Hours",
};
