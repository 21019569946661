import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

import { kubernetesLiveUsageMgmtViewWithoutFiltersResourceTypes } from "../store-selectors/kubernetesLiveUsageMgmtViewWithoutFiltersResourceTypes";

export const kubernetesLiveUsageMgmtResourceViewIsWithoutFiltersSelector = (
  state: RootState,
  resourceType: ResourceType,
): boolean => {
  const viewWithoutFiltersResourceTypes =
    kubernetesLiveUsageMgmtViewWithoutFiltersResourceTypes(state);

  return !!viewWithoutFiltersResourceTypes[resourceType];
};
