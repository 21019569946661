import { FC } from "react";
import { useToggle } from "rooks";
import { Box, Collapse } from "@mui/material";
import { NotificationCustomMessagePreviewHeader } from "./preview/NotificationCustomMessagePreviewHeader";
import { NotificationCustomMessagePreviewContent } from "./preview/NotificationCustomMessagePreviewContent";
import { NotificationCustomMessageDialog } from "./dialog/NotificationCustomMessageDialog";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { NestedIntegration } from "../../../../../../services/cloudchipr.api";
import {
  NotificationCustomMessageContent,
  NotificationCustomMessageSlackMentionsConfig,
} from "../../utils/types/types";

export type NotificationCustomMessageConfig = {
  preview: NotificationCustomMessageContent;
  dialogContentProps: NotificationCustomMessageContent;
};

interface NotificationCustomMessageDialogProps
  extends NotificationCustomMessageConfig {
  type: NestedIntegration["type"];
  message: string;
  onMessageChange(message: string): void;
  mention?: NotificationCustomMessageSlackMentionsConfig;
}

export const NotificationCustomMessage: FC<
  NotificationCustomMessageDialogProps
> = ({
  type,
  preview,
  message,
  dialogContentProps,
  onMessageChange,
  mention,
}) => {
  const [collapsed, toggleCollapsed] = useToggle();
  const { open, openDialog, closeDialog } = useDialog();

  if (type === "webhook") {
    return null;
  }

  return (
    <Box bgcolor="grey.100" p={1} borderRadius={1}>
      <NotificationCustomMessagePreviewHeader
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        onEdit={openDialog}
      />

      <Collapse in={collapsed}>
        <NotificationCustomMessagePreviewContent
          {...preview}
          type={type}
          message={message}
          mentions={mention?.selected}
        />
      </Collapse>

      <NotificationCustomMessageDialog
        open={open}
        type={type}
        message={message}
        mention={mention}
        onClose={closeDialog}
        onMessageChange={onMessageChange}
        dialogContentProps={dialogContentProps}
      />
    </Box>
  );
};
