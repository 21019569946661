import { FC, ReactNode } from "react";
import { Stack } from "@mui/material";
import { WidgetCardHeaderActionRow } from "./WidgetCardHeaderActionRow";
import { WidgetCardHeaderSubHeader } from "./components/WidgetCardHeaderSubHeader";
import { WidgetType } from "../../../../../../../services/cloudchipr.api";
import { WidgetTitleProps } from "../../../../utils/types/types";

interface WidgetCardHeaderProps {
  hovered: boolean;
  widgetId: string;
  widgetType: WidgetType;
  widgetTitleProps: WidgetTitleProps | null;
  children?: ReactNode;
}

export const WidgetCardHeader: FC<WidgetCardHeaderProps> = ({
  widgetId,
  widgetTitleProps,
  widgetType,
  hovered,
  children,
}) => {
  return (
    <Stack pl={2} pr={0} py={1} borderBottom={1} sx={widgetCardHeaderStyles}>
      <WidgetCardHeaderActionRow
        widgetId={widgetId}
        hovered={hovered}
        widgetType={widgetType}
        widgetTitleProps={widgetTitleProps}
      />

      {children ?? (
        <WidgetCardHeaderSubHeader
          widgetId={widgetId}
          widgetType={widgetType}
          widgetTitleProps={widgetTitleProps}
          hovered={hovered}
        />
      )}
    </Stack>
  );
};

const widgetCardHeaderStyles = {
  borderBottomColor: "grey.300",
  position: "sticky",
  top: 0,
  bgcolor: "white",
  zIndex: 2,
};
