import { providerOrganisationsGroupedByProviderAndOrgSelector } from "../organisations/providerOrganisationsGroupedByProviderAndOrgSelector";
import { RootState } from "../../../store";
import {
  noOrgProviderOrganisationName,
  noOrgProviderOrganisationOrgId,
} from "../organisations/utils/constants";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../utils/helpers/providers/getProviderName";

export const commitmentsNavigationItemsSelector = (state: RootState) => {
  const providerOrganisationsGroupedByProviderAndOrg =
    providerOrganisationsGroupedByProviderAndOrgSelector(state);

  const items: {
    provider: ProviderType;
    org: string;
    orgId: string;
  }[] = [];

  if (providerOrganisationsGroupedByProviderAndOrg) {
    Object.entries(providerOrganisationsGroupedByProviderAndOrg).forEach(
      ([key, providerOrganisationGroupedByOrg]) => {
        const provider = key as ProviderType;
        const organizations = Object.keys(providerOrganisationGroupedByOrg);

        if (organizations.length > 1) {
          items.push(
            ...organizations.map((org) => {
              const orgName =
                org === noOrgProviderOrganisationOrgId
                  ? noOrgProviderOrganisationName.get(provider)!
                  : org;

              return {
                provider,
                org: orgName,
                orgId: org,
              };
            }),
          );
        } else {
          const singleOrgId =
            Object.keys(providerOrganisationGroupedByOrg).at(0) ??
            noOrgProviderOrganisationOrgId;

          const tail =
            singleOrgId === noOrgProviderOrganisationOrgId
              ? ""
              : `(${singleOrgId})`;

          items.push({
            provider,
            org: `${getProviderName(provider, {
              capitalize: true,
              title: true,
            })} ${tail}`,
            orgId: singleOrgId,
          });
        }
      },
    );
  }

  return items;
};
