import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { commitmentsTypeTabSelector } from "../../selectors/common/commitmentsTypeTabSelector";
import { commitmentsCurrentOrgProviderSelector } from "../../selectors/common/commitmentsCurrentOrgProviderSelector";
import { SavingsPlanCoverageGrouping } from "../../../../services/cloudchipr.api";
import { setCommitmentsDetailsDrawerCoverageGrouping } from "../../commitmentsSlice";
import { getReservationDetailsThunk } from "../getReservationDetailsThunk";
import { getUtilizationAndCoverageDetailsThunk } from "../getUtilizationAndCoverageDetailsThunk";
import { getAzureReservationDetailsThunk } from "../azure/reservations/getAzureReservationDetailsThunk";
import { getAzureUtilizationAndCoverageDetailsThunk } from "../azure/getAzureUtilizationAndCoverageDetailsThunk";

export const changeCommitmentDrawerDataGridGroupingThunk = createAsyncThunk(
  "commitments/changeCommitmentDrawerDataGridGroupingThunk",
  async (grouping: SavingsPlanCoverageGrouping, { dispatch, getState }) => {
    const state = getState() as RootState;
    const activeTab = commitmentsTypeTabSelector(state);
    const provider = commitmentsCurrentOrgProviderSelector(state);

    dispatch(setCommitmentsDetailsDrawerCoverageGrouping(grouping));

    if (activeTab === "reservation") {
      if (provider === "azure") {
        dispatch(getAzureReservationDetailsThunk());
      } else {
        dispatch(getReservationDetailsThunk());
      }
    } else {
      if (provider === "azure") {
        dispatch(getAzureUtilizationAndCoverageDetailsThunk());
      } else {
        dispatch(getUtilizationAndCoverageDetailsThunk());
      }
    }
  },
);
