import { FC } from "react";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { ResourceExplorerGroupingNullable } from "../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";

interface AlertsCostAnomalyDataGridGroupingCellProps {
  grouping: ResourceExplorerGroupingNullable;
}

export const AlertsCostAnomalyDataGridGroupingCell: FC<
  AlertsCostAnomalyDataGridGroupingCellProps
> = ({ grouping }) => {
  const groupingLabel = useAppSelector((state) =>
    resourceExplorerGroupingLabelByGroupingSelector(state, grouping),
  );

  return (
    <TypographyWithTooltip variant="inherit" title={groupingLabel?.singular} />
  );
};
