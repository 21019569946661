import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCostAnomaliesWidgetDataBySetupThunk } from "../data-get/getCostAnomaliesWidgetDataBySetupThunk";
import { RootState } from "../../../../../store";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { costAnomaliesWidgetByIdSelector } from "../../../../selectors/widgets/cost-anomalies/costAnomaliesWidgetByIdSelector";
import { CostAnomaliesSetupType } from "../../../../utils/types/setups/costAnomaliesSetupType";

interface EdiCostAnomaliesWidgetArgs {
  widgetId: string;
  doNotSetWidgetType?: boolean;
}

export const editCostAnomaliesWidgetThunk = createAsyncThunk(
  "dashboards/editCostAnomaliesWidget",
  (
    { widgetId, doNotSetWidgetType }: EdiCostAnomaliesWidgetArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = costAnomaliesWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    dispatch(
      setWidgetSetup({
        widgetType: doNotSetWidgetType ? undefined : "largest_cost_changes",
        id: widget.id,
        name: widget.name,
        dates: widget.dates,
        grouping: widget.grouping,
        group_values: widget.group_values,
        viewId: widget.view_id,
        priceDirection: widget.price_direction,
        sortingBy: widget.sort_by,
        itemsCount: widget.items_count,
        minPercentChange: {
          value: widget.min_percentage_change,
          state: !!widget.min_percentage_change,
        },
        minCostChange: {
          value: widget.min_cost_change,
          state: !!widget.min_cost_change,
        },
      } as CostAnomaliesSetupType),
    );

    dispatch(getCostAnomaliesWidgetDataBySetupThunk());
  },
);
