import { FC } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { costAnomaliesSetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/costAnomaliesSetupPropertyByKeySelector";
import { costAnomaliesSetupWidgetTitleSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/costAnomaliesSetupWidgetTitleSelector";
import { costAnomaliesSetupPrevPeriodCostDateDetailsSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-anomalies/data/costAnomaliesSetupPrevPeriodCostDateDetailsSelector";
import { WidgetSourceInfo } from "../../../../../../widgets/common/widget-header/source-and-name/WidgetSourceInfo";
import { CostAnomaliesWidgetCardHeaderDate } from "../../../../../../widgets/cost-anomalies/components/header/CostAnomaliesWidgetCardHeaderDate";

export const CostAnomaliesWidgetSetupHeader: FC = () => {
  const name = useAppSelector(costAnomaliesSetupPropertyByKeySelector("name"));

  const prevDateDetails = useAppSelector(
    costAnomaliesSetupPrevPeriodCostDateDetailsSelector,
  );

  const widgetTitleProps = useAppSelector(
    costAnomaliesSetupWidgetTitleSelector,
  );

  return (
    <Stack direction="row">
      <Stack px={2} py={1}>
        <Typography variant="body1" fontWeight="bold" minHeight={24}>
          {name}
        </Typography>

        <Stack direction="row" alignItems="center" spacing={0.5}>
          <WidgetSourceInfo {...widgetTitleProps} hideDates>
            <CostAnomaliesWidgetCardHeaderDate
              prevDate={prevDateDetails}
              date={widgetTitleProps.dates}
            />
          </WidgetSourceInfo>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  );
};
