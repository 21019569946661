import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceDetailsCostsTabBillingChartLabelsSelector } from "./resourceDetailsCostsTabBillingChartLabelsSelector";
import { resourceDetailsCostsTabBillingDataSelector } from "./resourceDetailsCostsTabBillingDataSelector";
import { HighchartsChartDataType } from "../../../../../storybook/c8r-highcharts/common/utils/types/common";
import { billingExplorerChartDataGenerator } from "../../../../../components/pages/common/billing-explorer/utils/billingExplorerChartDataGenerator";

export const resourceDetailsCostsTabBillingChartDataSelector =
  createDraftSafeSelector(
    [
      resourceDetailsCostsTabBillingDataSelector,
      resourceDetailsCostsTabBillingChartLabelsSelector,
    ],
    (data, labels): HighchartsChartDataType | null => {
      const chartData = data?.data;

      if (!chartData || !labels) {
        return null;
      }

      return billingExplorerChartDataGenerator(chartData, labels);
    },
  );
