import { FC } from "react";
import { TabPanel } from "@mui/lab";
import { Stack } from "@mui/material";
import { AzureReservations } from "./reservations/AzureReservations";
import { AzureUtilizationAndCoverage } from "./utilization-and-coverage/AzureUtilizationAndCoverage";
import { AzureCommitmentsOverview } from "./overview/AzureCommitmentsOverview";

export const AzureCommitments: FC = () => {
  return (
    <Stack p={1} flex={1}>
      <TabPanel value="reservation" sx={{ p: 0, flex: 1 }}>
        <Stack height="100%">
          <AzureReservations />
        </Stack>
      </TabPanel>
      <TabPanel value="savings_plan" sx={{ p: 0, flex: 1 }}>
        <Stack height="100%">
          <AzureUtilizationAndCoverage />
        </Stack>
      </TabPanel>
      <TabPanel value="overview" sx={{ p: 0 }}>
        <AzureCommitmentsOverview />
      </TabPanel>
    </Stack>
  );
};
