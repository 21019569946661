import { FC } from "react";
import { Stack, Typography } from "@mui/material";

interface AlertsTabLabelProps {
  count: number;
  label: string;
  active: boolean;
}

export const BudgetsAndAlertsTabLabel: FC<AlertsTabLabelProps> = ({
  count,
  label,
  active,
}) => {
  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="inherit">{label}</Typography>
      <Stack
        ml={1}
        width={24}
        height={24}
        borderRadius={10}
        justifyContent="center"
        bgcolor={active ? "primary.light" : "grey.100"}
      >
        <Typography variant="inherit" fontSize={12}>
          {count}
        </Typography>
      </Stack>
    </Stack>
  );
};
