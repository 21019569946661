import { FC } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";

interface CommentEditActionProps {
  onClick(): void;
}

export const CommentEditAction: FC<CommentEditActionProps> = ({ onClick }) => {
  return (
    <Tooltip arrow title="Edit" placement="top">
      <IconButton size="small" sx={buttonStyles} onClick={onClick}>
        <EditOutlined sx={iconStyles} />
      </IconButton>
    </Tooltip>
  );
};

const buttonStyles = { p: 0.5 };
const iconStyles = { fontSize: 16 };
