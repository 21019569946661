import { FC, useCallback, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { KubernetesClustersPageBody } from "./content/KubernetesClustersPageBody";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { accountsPageTabSearchParam } from "../../../../live-usage-mgmt/utils/constants/searchParams";
import { AccountsPageTabs } from "../../../utils/types/types";
import { kubernetesClustersGroupedByOrgSelector } from "../../../../../../store/accounts/selectors/kubernetes/kubernetesClustersGroupedByOrgSelector";
import { RootState } from "../../../../../../store/store";
import { setAccountsProviderOrganisationId } from "../../../../../../store/accounts/accountsSlice";
import { noOrgAccountOrgId } from "../../../utils/helpers/noOrganizationConstants";
import { filteredClustersConvertedForClustersPageByOrgIdSelector } from "../../../../../../store/accounts/selectors/kubernetes/filteredClustersConvertedForClustersPageByOrgIdSelector";
import { generateLiveResourcesPath } from "../../../utils/helpers/generateLiveResourcesPath";

export const KubernetesClusters: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const tabValue = searchParams.get(
    accountsPageTabSearchParam,
  ) as AccountsPageTabs;

  const { orgId } = useParams<{
    orgId: string;
  }>();

  const kubernetesClustersGroupedByOrg = useAppSelector(
    kubernetesClustersGroupedByOrgSelector,
  );

  const orgIdIsWrong = !!(orgId && !kubernetesClustersGroupedByOrg?.[orgId]);

  const clustersSelector = useCallback((state: RootState) => {
    return filteredClustersConvertedForClustersPageByOrgIdSelector(state);
  }, []);
  const clusters = useAppSelector(clustersSelector);

  useEffect(() => {
    if (!orgIdIsWrong || !kubernetesClustersGroupedByOrg) {
      return;
    }

    const firstOrgId = noOrgAccountOrgId;

    if (!firstOrgId) {
      return;
    }

    navigate(
      generateLiveResourcesPath({ provider: "kubernetes", orgId: firstOrgId }),
    );
  }, [orgIdIsWrong, navigate, kubernetesClustersGroupedByOrg]);

  useEffect(() => {
    if (!orgId) {
      return;
    }

    dispatch(setAccountsProviderOrganisationId(orgId));
  }, [orgId, dispatch]);

  if (!orgId || !clusters) {
    return null;
  }

  if (!clusters || !tabValue) {
    return null;
  }

  return (
    <KubernetesClustersPageBody
      clusters={clusters}
      activeTab={tabValue}
      key={`${orgId}${tabValue}`}
    />
  );
};
