import { FC, useCallback } from "react";
import { CommitmentUtilizationDisabledActions } from "./disabled-actions/CommitmentUtilizationDisabledActions";
import { WidgetOptionCard } from "../../WidgetOptionCard";
import Cover from "../../../../utils/assets/images/widget-covers/commitment_utilization_widget_cover.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { initiateCommitmentUtilizationWidgetThunk } from "../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/initiateCommitmentUtilizationWidgetThunk";
import { dashboardsPossibleWidgetStatusByWidgetTypeSelector } from "../../../../../../../store/dashboards/selectors/widget-options/dashboardsPossibleWidgetStatusByWidgetTypeSelector";
import { dashboardWidgetLabels } from "../../../../utils/constants/constants";

export const CommitmentUtilizationWidgetCard: FC = () => {
  const dispatch = useAppDispatch();

  const clickHandler = useCallback(() => {
    dispatch(initiateCommitmentUtilizationWidgetThunk());
  }, [dispatch]);

  const status = useAppSelector((state) =>
    dashboardsPossibleWidgetStatusByWidgetTypeSelector(
      state,
      "commitments_utilization",
    ),
  );

  return (
    <WidgetOptionCard
      onClick={clickHandler}
      title={dashboardWidgetLabels.commitments_utilization ?? ""}
      description="Track coverage and utilization of Reservations(RIs) and Saving Plans (SPs)"
      disabledAction={status ? <CommitmentUtilizationDisabledActions /> : null}
    >
      <img src={Cover} alt="ConstAndUsageCard" />
    </WidgetOptionCard>
  );
};
