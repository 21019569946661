import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import EmptyImage from "../../../../../../../../../../../../assets/images/live_filtered_resources_empty_state.png";

interface LiveFilteredResourcesEmptyStateProps {
  description: string;
}
export const LiveFilteredResourcesEmptyState: FC<
  LiveFilteredResourcesEmptyStateProps
> = ({ description }) => {
  return (
    <Stack spacing={4} alignItems="center" pt={10}>
      <img src={EmptyImage} alt="Empty Underutilized Resources" />
      <Typography
        variant="h6"
        color="text.secondary"
        textAlign="center"
        width={640}
        fontWeight="normal"
      >
        {description}
      </Typography>
    </Stack>
  );
};
