import { azureOverviewDataSelector } from "./azureOverviewDataSelector";
import { RootState } from "../../../../store";
import { DateDetails } from "../../../../../services/cloudchipr.api";

export const azureOverviewPreviousDateDetailsSelector = (
  state: RootState,
): DateDetails | null => {
  const data = azureOverviewDataSelector(state);

  return data?.previous_period_dates ?? null;
};
