import { billingExplorerWidgetAggregationsSelector } from "./billingExplorerWidgetAggregationsSelector";
import { RootState } from "../../../../../store";
import { WidgetCostBreakdownAggregation } from "../../../../../../services/cloudchipr.api";

export const billingExplorerWidgetHasAggregationSelector = (
  state: RootState,
  widgetId: string,
) => {
  const aggregations = billingExplorerWidgetAggregationsSelector(
    state,
    widgetId,
  );

  return Object.keys(aggregations ?? {}).some(
    (key) => !!aggregations?.[key as keyof WidgetCostBreakdownAggregation],
  );
};
