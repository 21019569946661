import { costAnomaliesSetupSelector } from "./costAnomaliesSetupSelector";
import { RootState } from "../../../../store";
import { CostAnomaliesSetupType } from "../../../utils/types/setups/costAnomaliesSetupType";

export const costAnomaliesSetupPropertyByKeySelector =
  (key: keyof CostAnomaliesSetupType) =>
  (state: RootState): any => {
    const setup = costAnomaliesSetupSelector(state);

    if (!setup) {
      return null;
    }

    return setup[key];
  };
