import { StateUpdatedEvent } from "ag-grid-community";

export const agGridDisabledSourcesForStateUpdate: StateUpdatedEvent["sources"] =
  [
    "gridInitializing",
    "focusedCell",
    "scroll",
    "rowSelection",
    "rowGroupExpansion",
    "pagination",
  ];
