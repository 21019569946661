import { FC } from "react";
import { ColDef } from "ag-grid-community";
import { AgDataGrid } from "../../../../../../../../ag-grid/AgDataGrid";

interface TaskTargetDataGridProps {
  rowData: any[];
  columnDefs: ColDef[];
}

export const TaskTargetDataGrid: FC<TaskTargetDataGridProps> = ({
  rowData,
  columnDefs,
}) => {
  return (
    <AgDataGrid
      rowHeight={60}
      rowData={rowData}
      suppressContextMenu
      columnDefs={columnDefs}
      wrapperStyles={wrapperStyles}
      defaultColDef={defaultColDef}
    />
  );
};

const defaultColDef = { suppressHeaderMenuButton: true, minWidth: 200 };

const wrapperStyles = {
  border: 1,
  borderRadius: 2,
  borderColor: "grey.300",
};
