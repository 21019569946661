import moment from "moment";
import { formatDateByFrequencyType } from "../../../../../utils/helpers/date-time/datetime-format";
import {
  DateDataPoint,
  ResourceExplorerChartData,
} from "../../../../../services/cloudchipr.api";

export const billingExplorerChartDataLabelsGenerator = (
  data: ResourceExplorerChartData[],
  dataPoint?: DateDataPoint,
  quarterStartMonth?: number,
) => {
  return data.reduce(
    (acc, item) => {
      const date = item.date;

      if (!date) {
        return acc;
      }

      const formatted = dataPoint
        ? formatDateByFrequencyType(dataPoint, date, quarterStartMonth)
        : date;

      const isDateValid =
        dataPoint === "weekly" || dataPoint === "quarterly"
          ? !!formatted
          : moment(formatted).isValid();

      const label = isDateValid ? formatted : date;

      acc[label] = date;

      return acc;
    },
    {} as Record<string, string>,
  );
};
