import { FC, Fragment, memo, useCallback, useMemo } from "react";
import { FormikHandlers } from "formik";
import { Stack, Typography } from "@mui/material";
import {
  AutocompleteFilterBody,
  AutocompleteFilterBodyProps,
} from "../AutocompleteFilterBody";
import {
  FilterItem,
  Operator,
  useGetV2UsersMeOrganisationsCurrentUsersQuery,
} from "../../../../../../../../services/cloudchipr.api";
import { FilterItemOperatorType } from "../../../FilterItemOperatorType";
import { DropdownSelectOption } from "../../../../../../../common/select/dropdown-select/utils/types/types";

interface ProtectedByFilterProps extends AutocompleteFilterBodyProps {
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
}

export const ProtectedByFilter: FC<ProtectedByFilterProps> = memo(
  ({ operators, filter, onChange, ...props }) => {
    const { data, isLoading } = useGetV2UsersMeOrganisationsCurrentUsersQuery({
      statuses: "active,deleted",
    });

    const emails = useMemo(() => {
      return (
        data
          ?.filter((user) => !!(user.email || user.name))
          ?.sort((user) => (user.status === "deleted" ? 1 : -1))
          ?.map((user) => {
            return {
              value: user.id,
              label: user.id,
            };
          }) ?? []
      );
    }, [data]);

    const renderOption = useCallback(
      ({ value, label }: DropdownSelectOption) => {
        const user = data?.find(({ id }) => id === value);

        if (!user) {
          return label;
        }

        return (
          <Stack>
            <Typography variant="body2">{user.email || user.name}</Typography>
            {user.status === "deleted" && (
              <Typography color="text.secondary" fontSize="small">
                Deleted user
              </Typography>
            )}
          </Stack>
        );
      },
      [data],
    );

    const formatSelectedLabel = useCallback(
      (id: any) => {
        const user = data?.find((user) => id === user.id);

        return user?.email ?? id;
      },
      [data],
    );

    const filterFn = useCallback(
      ({ value }: DropdownSelectOption, keyword: string) => {
        const user = data?.find((user) => value === user.id);

        const aim = `${user?.email} ${user?.name} ${user?.role} ${user?.status}`;

        return aim.includes(keyword?.trim());
      },
      [data],
    );

    return (
      <Fragment>
        <FilterItemOperatorType
          onChange={onChange}
          options={operators}
          value={filter.operator || "in"}
        />

        <AutocompleteFilterBody
          {...props}
          options={emails ?? []}
          filterFn={filterFn}
          isLoading={isLoading}
          renderOption={renderOption}
          label="Select Cloudchipr Users"
          formatSelectedLabel={formatSelectedLabel}
          selectedValue={Array.isArray(filter?.value) ? filter.value : []}
        />
      </Fragment>
    );
  },
);
