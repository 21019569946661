import { FC } from "react";
import { ResourceDetailsGeneralContentGrid } from "./ResourceDetailsGeneralContentGrid";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { resourceDetailsResourceBillingChartDataSelector } from "../../../../../../../../store/resource-details/selectors/general-tab/chart-data/resourceDetailsResourceBillingChartDataSelector";
import { ResourceDetailsCostsChart } from "../../common/ResourceDetailsCostsChart";
import { resourceDetailsChartTypeSelector } from "../../../../../../../../store/resource-details/selectors/options/resourceDetailsChartTypeSelector";
import { resourceDetailsGeneralTabProcessKey } from "../../../../../../../../store/resource-details/utils/constants/processesKeys";

export const ResourceDetailsGeneralContentChart: FC = () => {
  const chartType = useAppSelector((state) =>
    resourceDetailsChartTypeSelector(
      state,
      resourceDetailsGeneralTabProcessKey,
    ),
  );
  const chartData = useAppSelector(
    resourceDetailsResourceBillingChartDataSelector,
  );

  if (chartType === "table") {
    return <ResourceDetailsGeneralContentGrid />;
  }

  return (
    <ResourceDetailsCostsChart chartType={chartType} chartData={chartData} />
  );
};
