import { FC, useCallback } from "react";
import { LiveResourceFiltersNavigationItem } from "./LiveResourceFiltersNavigationItem";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";
import { ResourceType } from "../../../../../services/cloudchipr.api";

interface LiveResourceFiltersNavigationItemByResourceTypeProps {
  resourceType: ResourceType;
  active: boolean;
  setActiveResourceType(rt: ResourceType): void;
  filtersCount?: number;
}

export const LiveResourceFiltersNavigationItemByResourceType: FC<
  LiveResourceFiltersNavigationItemByResourceTypeProps
> = ({ resourceType, active, setActiveResourceType, filtersCount }) => {
  const handleClick = useCallback(() => {
    setActiveResourceType(resourceType);
  }, [resourceType, setActiveResourceType]);

  return (
    <LiveResourceFiltersNavigationItem
      active={active}
      onClick={handleClick}
      title={getResourceTypeName(resourceType, { type: "long" })}
      filtersCount={filtersCount ?? 0}
    />
  );
};
