import { MetricsFrequenciesKeysType } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/types/metricsFrequenciesType";
import { KubernetesConvertorFnsType } from "../types";
import { getMetricsTypeAndOptionById } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/constants";
import { getMetricsByType } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/metrics-cells/utils/helpers/getMetricsByType";
import { metricsChartValueGenerator } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/valueGenerators/metricsChartValueGenerator";
import { metricsUnit } from "../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/metrics-cells/utils/constants";
import {
  ComputeInlineMetrics,
  DatabaseInlineMetrics,
  DataStorageInlineMetrics,
} from "../../../../../../../../../../../services/cloudchipr.api";

export const getKubernetesMetricsGeneratorFn = (
  frequencyKey: MetricsFrequenciesKeysType,
): KubernetesConvertorFnsType => {
  const { type, option } = getMetricsTypeAndOptionById.get(frequencyKey) ?? {};

  return (key, resource, options) => {
    if (!type || !option) {
      return "N/A";
    }

    const { metricsFrequencies } = options ?? {};
    const duration = metricsFrequencies?.[frequencyKey] ?? "";
    const data = (
      key in resource ? resource[key] : undefined
    ) as MetricDataType;

    if (!data) {
      return "N/A";
    }

    const metrics = getMetricsByType(data, type);
    const value = metricsChartValueGenerator(duration, option, metrics);

    if (option === "sum") {
      return value;
    }

    return value === "N/A" ? value : `${value} ${metricsUnit[type]}`;
  };
};

type MetricDataType =
  | DatabaseInlineMetrics
  | DataStorageInlineMetrics
  | ComputeInlineMetrics
  | undefined;
