import { FC, Fragment, useCallback } from "react";
import { Alert, FormHelperText, Stack, Typography } from "@mui/material";
import { SlackConversationSelect } from "./conversation-selection/SlackConversationSelect";
import { SlackDefaultConversationSelect } from "./default-conversation-selection/SlackDefaultConversationSelect";
import { SlackConversationResponse } from "../../../../../../../../services/cloudchipr.api";

interface SlackAppConnectionEditProps {
  id: string;
  name: string;
  selectedConversations?: SlackConversationResponse[] | null;
  defaultConversation?: SlackConversationResponse | null;
  setFieldValue(key: string, value: any): void;
}

export const SlackAppConnectionEdit: FC<SlackAppConnectionEditProps> = ({
  id,
  name,
  setFieldValue,
  defaultConversation,
  selectedConversations,
}) => {
  const conversationsChangeHandler = useCallback(
    (conversations: SlackConversationResponse[]) => {
      if (
        defaultConversation?.id &&
        !conversations.some(
          (conversation) => conversation.id === defaultConversation?.id,
        )
      ) {
        setFieldValue("conversation", null);
      }

      setFieldValue("conversations", conversations);
    },
    [setFieldValue, defaultConversation],
  );

  const defaultConversationChangeHandler = useCallback(
    (conversation: SlackConversationResponse) => {
      setFieldValue("conversation", conversation);
    },
    [setFieldValue],
  );

  return (
    <Fragment>
      <Stack bgcolor="grey.100" p={1} borderRadius={2}>
        <Typography variant="caption" color="text.secondary">
          Workspace
        </Typography>

        {name}
      </Stack>

      <Stack>
        <SlackConversationSelect
          id={id}
          onChange={conversationsChangeHandler}
          selectedConversations={selectedConversations}
        />
        <FormHelperText>
          Select Slack conversations to use for notifications and alerts. You
          can always modify it.
        </FormHelperText>

        <Alert severity="info" sx={{ mt: 1 }}>
          To use{" "}
          <Typography fontWeight="bold" component="span" fontSize="inherit">
            private channels
          </Typography>
          , you need to go into each private channel in Slack and add the
          <Typography fontWeight="bold" component="span" fontSize="inherit">
            {" "}
            @cloudchipr
          </Typography>{" "}
          app.
        </Alert>
      </Stack>

      <SlackDefaultConversationSelect
        integrationId={id}
        options={selectedConversations}
        selectedValue={defaultConversation?.id}
        onChange={defaultConversationChangeHandler}
      />
    </Fragment>
  );
};
