import { IntegrationErrors } from "../../../../../../components/pages/automations/workflows/single-workflow/form/actions-section/grace-period/notifications/GracePeriodNotifications";

export const automationNotificationsErrorsGenerator = (
  errors: Record<string, string>,
) => {
  const notificationsErrors: IntegrationErrors[] = [];

  Object.keys(errors).forEach((key) => {
    const isNotificationError =
      key.startsWith("notifications") &&
      (key.endsWith("to") || key.endsWith("conversations"));

    if (!isNotificationError) {
      return;
    }

    const neededPart = key.split("[")?.at(1)?.split("]");
    const index = neededPart?.at(0);
    const type = neededPart?.at(1)?.split(".")?.at(1) as "to" | "conversations";

    if (type && index) {
      notificationsErrors[+index] = {
        [type]: errors[key],
      };
    }
  });

  return notificationsErrors;
};
