import { azureSavingsPlanSelectedCoverageDataSelector } from "./azureSavingsPlanSelectedCoverageDataSelector";
import { RootState } from "../../../../../../store";

export const azureSavingsPlanSelectedCoverageDataAccountNameAndIdSelector = (
  state: RootState,
): string => {
  const data = azureSavingsPlanSelectedCoverageDataSelector(state);

  return `${data?.account?.name} (${data?.account?.provider_account_id})`;
};
