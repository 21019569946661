import { FC } from "react";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { AppNavItemWrapper } from "../app-nav-components/AppNavItemWrapper";

export const ExecutionsLogsNavigation: FC = () => {
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  return (
    <AppNavItemWrapper
      divider
      to="/execution-log"
      primary="Execution Logs"
      Icon={DescriptionOutlinedIcon}
      navCollapsed={navigationCollapsed}
    />
  );
};
