import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { UniqueIdentifier } from "@dnd-kit/core";

import { TreeItemProps } from "./TreeItem";
import { SortableTreeItem } from "./SortableTreeItem";
import { TreeItems, TreeItemDraggingData } from "./utils/types";
import { flattenSortableTreeItems } from "./utils/helpers/flattenSortableTreeItems";
import { getSortableTreeItemProjection } from "./utils/helpers/getSortableTreeItemProjection";

export type TreeItemExtendedProps = Pick<
  TreeItemProps,
  | "renderFolderAction"
  | "renderItemAction"
  | "getNavigateTo"
  | "icon"
  | "rightIcon"
  | "emptyText"
  | "onTreeItemClick"
>;

interface SortableTreeContentProps extends TreeItemExtendedProps {
  items?: TreeItems;
  indentationWidth?: number;
  offsetLeft: number;
  overData: TreeItemDraggingData | null;
  activeData: TreeItemDraggingData | null;
  expandedItems: Record<UniqueIdentifier, boolean>;
  setExpandedItems: Dispatch<SetStateAction<Record<UniqueIdentifier, boolean>>>;
  disabled?: boolean;
}

export const SortableTreeContent: FC<SortableTreeContentProps> = ({
  items,
  indentationWidth = 20,
  renderFolderAction,
  renderItemAction,
  getNavigateTo,
  offsetLeft,
  overData,
  activeData,
  expandedItems,
  setExpandedItems,
  icon,
  rightIcon,
  disabled,
  emptyText,
  onTreeItemClick,
}) => {
  const flattenedItems = useMemo(() => {
    return flattenSortableTreeItems(items ?? []);
  }, [items]);

  const projected = useMemo(() => {
    if (overData) {
      return getSortableTreeItemProjection({
        items: flattenedItems,
        dragOffset: offsetLeft,
        indentationWidth,
        overData,
        activeData,
      });
    }
    return null;
  }, [activeData, flattenedItems, offsetLeft, overData, indentationWidth]);

  const collapseFolderHandler = useCallback(
    (id: UniqueIdentifier) => {
      setExpandedItems((prevValue) => {
        if (!prevValue) {
          return prevValue;
        }

        return {
          ...prevValue,
          [id]: !prevValue[id],
        };
      });
    },
    [setExpandedItems],
  );

  return (
    <Fragment>
      {flattenedItems?.map(
        ({
          id,
          items,
          depth,
          name,
          type,
          disabled: disabledItem,
          parentId,
          protectedBy,
          protectionDetails,
        }) => {
          if (
            parentId &&
            (!expandedItems?.[parentId] || activeData?.id === parentId)
          ) {
            return null;
          }
          return (
            <SortableTreeItem
              renderFolderAction={renderFolderAction}
              renderItemAction={renderItemAction}
              key={id}
              id={id}
              type={type}
              name={name}
              parentId={parentId}
              disabled={disabled || disabledItem}
              depth={
                id === activeData?.id && projected ? projected.depth : depth
              }
              items={items}
              indentationWidth={indentationWidth}
              collapsed={!!expandedItems?.[id]}
              onCollapse={collapseFolderHandler}
              getNavigateTo={getNavigateTo}
              icon={icon}
              protectedBy={protectedBy}
              protectionDetails={protectionDetails}
              rightIcon={rightIcon}
              emptyText={emptyText}
              onTreeItemClick={onTreeItemClick}
            />
          );
        },
      )}
    </Fragment>
  );
};
