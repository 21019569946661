import { FC, ReactNode } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  PaletteColor,
  PaletteColorOptions,
} from "@mui/material/styles/createPalette";
import { indigo, grey } from "@mui/material/colors";
import { muiTabs } from "./components/tabs/muiTabs";
import { muiAlert } from "./components/alert/muiAlert";
import { muiCard } from "./components/card/muiCard";
import { muiButton } from "./components/button/muiButton";
import { muiTextField } from "./components/text-field/text-field";
import { muiFormControl } from "./components/form-control/form-control";
import { muiAutocomplete } from "./components/autocomplete/autocomplete";
import { muiChip } from "./components/chip/muiChip";
import { muiStep } from "./components/stepper/muiStep";
import { muiTypography } from "./components/typography/muiTypography";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { themeBreakpointsValues } from "./constants/breakpoints";
import { muiTab } from "./components/tab/muiTab";

declare module "@mui/material/styles" {
  // todo: check palette colors with the design team
  interface Palette {
    tertiary: PaletteColor;
    primaryLight: PaletteColor;
  }

  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
    primaryLight?: PaletteColorOptions;
  }

  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: indigo["500"],
      light: indigo["50"],
    },
    tertiary: {
      main: grey["600"],
    },
    primaryLight: {
      main: indigo["50"],
    },
  },
  breakpoints: {
    values: themeBreakpointsValues,
  },
  components: {
    MuiAlert: muiAlert,
    MuiCard: muiCard,
    MuiStep: muiStep,
    MuiTabs: muiTabs,
    MuiButton: muiButton,
    MuiTextField: muiTextField,
    MuiChip: muiChip,
    MuiFormControl: muiFormControl,
    MuiAutocomplete: muiAutocomplete,
    MuiTypography: muiTypography,
    MuiTab: muiTab,
  },
});

export const CloudChiprTheme: FC<{ children?: ReactNode }> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
