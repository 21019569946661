import { FC } from "react";
import { Skeleton, Stack } from "@mui/material";
import { MultiTypeHighcharts } from "../../../../../../../../storybook/c8r-highcharts/MultiTypeHighcharts";
import { HighchartsProvider } from "../../../../../../../../storybook/c8r-highcharts/common/components/HighchartsProvider";

interface ResourceDetailsMetricsContentEmptyStateProps {
  loading: boolean;
  dataExists: boolean;
}

export const ResourceDetailsMetricsContentEmptyState: FC<
  ResourceDetailsMetricsContentEmptyStateProps
> = ({ dataExists, loading }) => {
  if (dataExists) {
    return null;
  }

  if (loading) {
    return (
      <Stack spacing={1}>
        <Skeleton height={232} width="100%" />
        <Skeleton height={232} width="100%" />
        <Skeleton height={232} width="100%" />
      </Stack>
    );
  }

  return (
    <HighchartsProvider>
      <MultiTypeHighcharts
        chartType="area"
        noDataMessage="No Metrics Data Available"
        showTotalValueInTooltip={false}
        chartData={{ series: [], categories: [] }}
      />
    </HighchartsProvider>
  );
};
