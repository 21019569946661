import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { kubernetesLiveUsageMgmtAppliedFiltersSelector } from "./kubernetesLiveUsageMgmtAppliedFiltersSelector";

export const kubernetesLiveUsageMgmtAppliedAllFiltersCountSelector =
  createDraftSafeSelector(
    [kubernetesLiveUsageMgmtAppliedFiltersSelector],
    (filters) => {
      return Object.values(filters).reduce((acc, filter) => {
        return acc + filter.filter_items.length;
      }, 0);
    },
  );
