import { ColDef } from "ag-grid-community";

export const keyInsensitiveComparatorFn: ColDef["comparator"] = (
  valueA,
  valueB,
) => {
  if (typeof valueA !== "string") {
    return 1;
  }
  if (typeof valueB !== "string") {
    return -1;
  }

  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};
