import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { FC, Fragment, useCallback } from "react";
import { grey } from "@mui/material/colors";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";
import { ProviderIcon } from "../../../../common/ProviderIcon";
import {
  Account,
  KubernetesCluster,
  ProviderType,
} from "../../../../../services/cloudchipr.api";
import { useLiveUsageNavigationHook } from "../../../../app-navigation/utils/hooks/useLiveUsageNavigation.hook";

interface AccountsHeaderProviderSelectItemProps {
  provider: ProviderType;
  accounts: (Account | KubernetesCluster)[];
  organizations: string[];
  onClose(): void;
}

export const AccountsHeaderProviderSelectItem: FC<
  AccountsHeaderProviderSelectItemProps
> = ({ provider, organizations, accounts, onClose }) => {
  const navigateHandler = useLiveUsageNavigationHook(provider);

  const clickHandler = useCallback(() => {
    navigateHandler();
    onClose();
  }, [onClose, navigateHandler]);

  return (
    <MenuItem value={provider} onClick={clickHandler}>
      <Stack direction="row" spacing={1} alignItems="center">
        <ProviderIcon provider={provider} />

        <Stack direction="column" spacing={0.25}>
          <Typography variant="body2" lineHeight={1.1}>
            {getProviderName(provider, {
              nameAndTitle: true,
              capitalize: true,
              plural: true,
            })}
          </Typography>

          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography
              variant="caption"
              lineHeight={1.1}
              color="text.secondary"
            >
              {accounts.length}{" "}
              {getProviderName(provider, { plural: accounts.length > 1 })}
            </Typography>

            {organizations.length > 1 && (
              <Fragment>
                <Box component="span" style={dotStyles} />
                <Typography
                  variant="caption"
                  lineHeight={1.1}
                  color="text.secondary"
                >
                  {organizations.length} orgs
                </Typography>
              </Fragment>
            )}
          </Stack>
        </Stack>
      </Stack>
    </MenuItem>
  );
};

const dotStyles = {
  display: "inline-block",
  width: 5,
  height: 5,
  borderRadius: "50%",
  backgroundColor: grey[300],
};
