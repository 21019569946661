import { FC } from "react";
import { Stack } from "@mui/material";
import { WidgetCardHeaderEditButton } from "./WidgetCardHeaderEditButton";
import { WidgetSourceInfo } from "../source-and-name/WidgetSourceInfo";
import { WidgetType } from "../../../../../../../../services/cloudchipr.api";
import { WidgetTitleProps } from "../../../../../utils/types/types";

interface WidgetCardHeaderSubHeaderProps {
  hovered: boolean;
  widgetId: string;
  widgetType: WidgetType;
  widgetTitleProps: WidgetTitleProps | null;
}

export const WidgetCardHeaderSubHeader: FC<WidgetCardHeaderSubHeaderProps> = ({
  widgetId,
  widgetTitleProps,
  widgetType,
  hovered,
}) => {
  return (
    <Stack spacing={1} direction="row" overflow="hidden">
      {widgetTitleProps && <WidgetSourceInfo {...widgetTitleProps} />}

      <WidgetCardHeaderEditButton
        widgetId={widgetId}
        widgetType={widgetType}
        hovered={hovered}
      />
    </Stack>
  );
};
