import { FC } from "react";
import { Stack, Table, TableBody, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../../../store/hooks";
import { TagCell } from "../../../../../../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { money } from "../../../../../../../../../../../../utils/numeral/money";
import { resourceIdentifierLabels } from "../../../../../../../../../../../../utils/constants/resources/resourceTypeNames/resourceIdentifierLabels";
import { getResourceTypeName } from "../../../../../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";
import { AccountInfoData } from "../../../../../../../../../../../common/AccountInfoData";
import { ProviderIcon } from "../../../../../../../../../../../common/ProviderIcon";
import { getProviderName } from "../../../../../../../../../../../../utils/helpers/providers/getProviderName";
import { taskManagementTargetOpportunitiesSelector } from "../../../../../../../../../../../../store/task-management/selectors/form/target/opportunities/taskManagementTargetOpportunitiesSelector";
import { TaskTargetTableRegionRow } from "../../common/TaskTargetTableRegionRow";
import { TaskTargetTableIdentifierRow } from "../../common/TaskTargetTableIdentifierRow";
import { TaskSingleTargetTableRow } from "../../common/TaskSingleTargetTableRow";
import { opportunityTypeTitlesByActionType } from "../../../../../../../../../../savings-opportunities/utils/constants/common";
import { SavingsOpportunityImplementationEffort } from "../../../../../../../../../../savings-opportunities/components/SavingsOpportunityImplementationEffort";

export const TargetSingleOpportunity: FC = () => {
  const opportunities = useAppSelector(
    taskManagementTargetOpportunitiesSelector,
  );
  const targetOpportunity = opportunities?.at(0);
  const edit = useAppSelector(isTaskManagementDrawerEditModeSelector);

  if (!targetOpportunity?.resource_type) {
    return null;
  }

  return (
    <Table sx={{ tableLayout: "fixed" }}>
      <TableBody>
        <TaskSingleTargetTableRow
          highlight
          label={
            resourceIdentifierLabels.get(targetOpportunity.resource_type) ??
            "Name"
          }
        >
          <TaskTargetTableIdentifierRow
            cloudLink={edit}
            liveUsageLink={edit}
            target={targetOpportunity}
          />
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Opportunity Type" highlight>
          {opportunityTypeTitlesByActionType[targetOpportunity.action_type]}
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Service">
          {getResourceTypeName(targetOpportunity.resource_type, {
            singular: true,
            type: "long",
          })}
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Description" highlight>
          {targetOpportunity.description}
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Current Type">
          {targetOpportunity.current_type}
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Recommended Type" highlight>
          {targetOpportunity.recommended_type}
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Account">
          <AccountInfoData
            nameIdCopiable
            nameTypographyProps={{ variant: "body2" }}
            idTypographyProps={{ variant: "tiny" }}
            accountId={targetOpportunity.account.provider_account_id}
            accountName={targetOpportunity.account.provider_account_name}
          />
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Cloud Provider" highlight>
          <Stack direction="row" spacing={0.5}>
            <ProviderIcon provider={targetOpportunity.account.provider} />
            <Typography variant="body2">
              {getProviderName(targetOpportunity.account.provider, {
                title: true,
              })}
            </Typography>
          </Stack>
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Current Monthly Price">
          {money(targetOpportunity.price_per_month)}
        </TaskSingleTargetTableRow>
        <TaskSingleTargetTableRow label="Estimated Monthly Price" highlight>
          {money(targetOpportunity.estimated_monthly_price)}
        </TaskSingleTargetTableRow>
        <TaskSingleTargetTableRow label="Monthly Savings">
          {money(targetOpportunity.monthly_savings)}
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Implementation Effort" highlight>
          <SavingsOpportunityImplementationEffort
            effort={targetOpportunity.implementation_effort}
          />
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Based On Past">
          <Typography variant="body2">
            {targetOpportunity.based_on_past} days
          </Typography>
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Region" highlight>
          <TaskTargetTableRegionRow region={targetOpportunity.region} />
        </TaskSingleTargetTableRow>

        <TaskSingleTargetTableRow label="Tags">
          {targetOpportunity.tags?.length ? (
            <TagCell tags={targetOpportunity.tags} />
          ) : (
            "-"
          )}
        </TaskSingleTargetTableRow>
      </TableBody>
    </Table>
  );
};
