import { FC, Fragment } from "react";
import { Stack, Typography } from "@mui/material";
import { grey, red } from "@mui/material/colors";

import { money } from "../../../../../../../../utils/numeral/money";
import { PieChart } from "../../../../../../../../storybook/charts/pie-chart/PieChart";
import { percentage } from "../../../../../../../../storybook/charts/utils/helpers/percentage";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { AccountCardLabelV2 } from "../../../../accounts/account-card/AccountCardLabelV2";
import { LastUpdateDate } from "../../../../accounts/account-card/LastUpdateDate";
import { NullableDate } from "../../../../../../../../services/cloudchipr.api";

interface KubernetesClusterCardContentProps {
  total_monthly_cost: number;
  idle_monthly_cost: number;
  reserved_monthly_cost: number;
  changeAccountInfoDrawerState?: (state: boolean) => void;
  last_updated_date: NullableDate;
}

export const KubernetesClusterCardContent: FC<
  KubernetesClusterCardContentProps
> = ({
  total_monthly_cost,
  idle_monthly_cost,
  reserved_monthly_cost,
  last_updated_date,
}) => {
  const isEmpty = !idle_monthly_cost && !reserved_monthly_cost;

  return (
    <Fragment>
      <Stack direction="row" justifyContent="space-between" pb={2}>
        <Stack direction="column">
          <Typography fontWeight="bold" color="text.primary" fontSize={14}>
            Live tracked resources
          </Typography>
          <LastUpdateDate
            date={last_updated_date}
            sx={{
              color: "text.secondary",
            }}
          />
        </Stack>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={5}
      >
        <PieChart
          partial={idle_monthly_cost}
          total={total_monthly_cost}
          innerRadius={44}
          empty={isEmpty}
          colors={{
            total: grey["400"],
            partial: red[500],
          }}
          fullAngle
          paddingAngle={1}
          content={
            <Stack alignItems="center">
              <TypographyWithTooltip
                title={percentage(idle_monthly_cost, total_monthly_cost)}
                variant="h6"
                fontWeight="bold"
              />
              <Typography
                variant="caption"
                color="text.secondary"
                fontWeight="medium"
              >
                Idle
              </Typography>
            </Stack>
          }
        />
        {isEmpty ? (
          <Typography
            variant="body2"
            fontWeight="medium"
            textAlign="center"
            color="text.secondary"
          >
            No Resources Found
          </Typography>
        ) : (
          <Stack spacing={1.5}>
            <AccountCardLabelV2
              label="Idle Resources"
              value={money(idle_monthly_cost)}
              color="error"
            />
            <AccountCardLabelV2
              label="Reserved Resources"
              value={money(reserved_monthly_cost)}
              color="disabled"
            />
            <AccountCardLabelV2
              label="Total"
              value={money(total_monthly_cost)}
              color={null}
            />
          </Stack>
        )}
      </Stack>
    </Fragment>
  );
};
