import { RootState } from "../../../store";
import { resourceDetailsBillingProcessesSelector } from "../processes/resourceDetailsBillingProcessesSelector";

export const resourceDetailsGroupBySelector = (
  state: RootState,
  processKey: string,
) => {
  const processes = resourceDetailsBillingProcessesSelector(state);

  return processes[processKey]?.groupBy;
};
