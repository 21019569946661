import { billingExplorerWidgetDataByWidgetIdSelector } from "./billingExplorerWidgetDataByWidgetIdSelector";
import { billingExplorerWidgetFrequencySelector } from "./billingExplorerWidgetFrequencySelector";
import { RootState } from "../../../../../store";
import { WidgetCosts } from "../../../../utils/types/types";
import { generateBillingExplorerWidgetCosts } from "../../../../utils/helpers/billing-explorer/generateBillingExplorerWidgetCosts";

export const billingExplorerWidgetCostsSelector = (
  state: RootState,
  widgetId: string,
): WidgetCosts | undefined => {
  const widgetData = billingExplorerWidgetDataByWidgetIdSelector(
    state,
    widgetId,
  );
  const frequency = billingExplorerWidgetFrequencySelector(state, widgetId);

  return generateBillingExplorerWidgetCosts(widgetData, frequency);
};
