import { useCallback, useMemo } from "react";
import {
  useGetUsersMeCurrentPreferenceByKeyQuery,
  usePatchUsersMeCurrentPreferenceByKeyMutation,
} from "../../../../../services/cloudchipr.api";
import { ResourceExplorerChartType } from "../../../../../store/resource-explorer/utils/types/resourceExplorer";
import { ResourceExplorerChartBase } from "../../components/resource-explorer-card/components/resource-explorer-chart/utils/types";

export const getResourceExplorerPreferencesKey = (viewId: string) => {
  return `c8r:ResourceExplorer:${viewId}`;
};
export function useResourceExplorerPreferences(viewId: string) {
  const key = getResourceExplorerPreferencesKey(viewId);
  const [updatePreferences] = usePatchUsersMeCurrentPreferenceByKeyMutation();

  const { data, refetch } = useGetUsersMeCurrentPreferenceByKeyQuery({ key });

  const preferences = useMemo(() => {
    return (data?.value ? JSON.parse(data?.value) : {}) as PreferencesType;
  }, [data]);

  const changeHandler = useCallback(
    async (data: PreferencesType) => {
      const value = JSON.stringify(data);

      await updatePreferences({ key, body: { value } });

      refetch();
    },
    [key, refetch, updatePreferences],
  );

  const changeChartPreferences = useCallback(
    (key: string, value: any) => {
      changeHandler({
        ...preferences,
        chart: { ...(preferences.chart ?? {}), [key]: value },
      });
    },
    [preferences, changeHandler],
  );

  return {
    chartPreferences: preferences?.chart,
    changeChartPreferences,
  };
}

type PreferencesType = {
  chart: { type: ResourceExplorerChartType; base: ResourceExplorerChartBase };
};
