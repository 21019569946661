import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { PrevPeriodCostColumnHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/PrevPeriodCostColumnHeaderCell";
import { billingExplorerWidgetPrevTotalCostSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetPrevTotalCostSelector";

interface BillingExplorerWidgetTableViewPrevPeriodTotalCostHeaderCellProps {
  widgetId: string;
}
export const BillingExplorerWidgetTablePrevPeriodTotalCostHeaderCell: FC<
  BillingExplorerWidgetTableViewPrevPeriodTotalCostHeaderCellProps
> = ({ widgetId }) => {
  const previousPeriodTotal = useAppSelector((state) =>
    billingExplorerWidgetPrevTotalCostSelector(state, widgetId),
  );

  return (
    <PrevPeriodCostColumnHeaderCell
      sum={previousPeriodTotal?.cost ?? null}
      dateDetails={previousPeriodTotal?.dateDetails}
    />
  );
};
