import { FC } from "react";
import { Card, Stack, Typography } from "@mui/material";
import { CommunicationIntegrationsCountChip } from "./CommunicationIntegrationsCountChip";
import { IntegrationLogo } from "../../../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import { integrationDetails } from "../../../common/utils/constants/integrationDetails";
import { CommunicationIntegrationConnectionType } from "../../../common/utils/types/common";
import { useHover } from "../../../../../../../../utils/hooks/useHover.hook";
import { IntegrationType } from "../../../../../../../../store/integrations/integrationsSlice";

interface CommunicationIntegrationCardProps {
  type: IntegrationType;
  connections: number;
}

export const CommunicationIntegrationCard: FC<
  CommunicationIntegrationCardProps
> = ({ type, connections }) => {
  const { ref, hovered } = useHover();

  const details = integrationDetails[type];

  return (
    <Card
      variant="outlined"
      sx={{
        px: 2,
        py: 2.5,
        position: "relative",
        borderRadius: 3,
        flex: 1,
        boxShadow: (theme) => {
          return hovered ? theme.shadows[5] : theme.shadows[0];
        },
      }}
      ref={ref}
    >
      <Stack spacing={1}>
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="space-between"
        >
          <Stack spacing={1}>
            <IntegrationLogo variant="colored" type={type} width={40} />
            <Typography fontWeight="500">{details.label}</Typography>
          </Stack>
          {!!connections && (
            <CommunicationIntegrationsCountChip
              connectionType={
                details.connectionType as CommunicationIntegrationConnectionType
              }
              connections={connections}
            />
          )}
        </Stack>
        <Typography
          variant="body2"
          overflow="hidden"
          sx={{
            // Limits text to 2 lines
            maxHeight: "2.5rem",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {details.description}
        </Typography>
      </Stack>
    </Card>
  );
};
