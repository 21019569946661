import { FC, Fragment, useCallback, useState } from "react";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  PopoverOrigin,
} from "@mui/material";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { BillingExplorerCreateFolderOrReportDialog } from "./BillingExplorerCreateFolderOrReportDialog";
import {
  NavigationHierarchyItemType,
  NavigationItemsVisibilityType,
} from "../../../../../../store/navigation/utils/types";

interface BillingExplorerNavigationAddActionMenuProps {
  open: boolean;
  onCloseMenu(): void;
  anchorEl: MenuProps["anchorEl"];
  anchorOrigin?: PopoverOrigin;
  redirectAfterCreate?: boolean;
  transformOrigin?: PopoverOrigin;
  visibility?: NavigationItemsVisibilityType;
}

export const BillingExplorerNavigationAddActionMenu: FC<
  BillingExplorerNavigationAddActionMenuProps
> = ({
  open,
  onCloseMenu,
  anchorEl,
  visibility,
  anchorOrigin,
  transformOrigin,
  redirectAfterCreate,
}) => {
  const [dialogType, setDialogType] = useState<
    NavigationHierarchyItemType | undefined
  >();

  const viewClickHandler = useCallback(() => {
    setDialogType("item");
  }, []);

  const folderClickHandler = useCallback(() => {
    setDialogType("folder");
  }, []);

  const closeDialog = useCallback(() => {
    setDialogType(undefined);
    onCloseMenu();
  }, [onCloseMenu]);

  return (
    <Fragment>
      <Menu
        open={open}
        onClose={onCloseMenu}
        anchorEl={anchorEl}
        disablePortal={false}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <MenuItem onClick={viewClickHandler}>
          <ListItemIcon>
            <InsertChartOutlinedOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Report</ListItemText>
        </MenuItem>

        <MenuItem onClick={folderClickHandler}>
          <ListItemIcon>
            <FolderOpenIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Folder</ListItemText>
        </MenuItem>
      </Menu>

      {dialogType && (
        <BillingExplorerCreateFolderOrReportDialog
          onClose={closeDialog}
          type={dialogType}
          visibility={visibility}
          redirectAfterCreate={redirectAfterCreate}
        />
      )}
    </Fragment>
  );
};
