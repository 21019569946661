import { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { CommitmentsDateRangeSelector } from "../options/date-range/CommitmentsDateRangeSelector";
import { commitmentsDetailsDrawerDatesSelector } from "../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerDatesSelector";
import { Dates } from "../../../../../../services/cloudchipr.api";
import { changeCommitmentDrawerDateGranularityThunk } from "../../../../../../store/commitments/thunks/common/changeCommitmentDrawerDateRangeThunk";

export const CommitmentsDrawerDateRange: FC = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(commitmentsDetailsDrawerDatesSelector);

  const dateChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(changeCommitmentDrawerDateGranularityThunk(dates));
    },
    [dispatch],
  );

  return (
    <CommitmentsDateRangeSelector dates={dates} onChange={dateChangeHandler} />
  );
};
