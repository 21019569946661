import { FC, Fragment, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import moment from "moment";
import { StartDateSelection } from "./StartDateSelection";
import {
  BudgetPeriod,
  Threshold,
} from "../../../../../../../../../../services/cloudchipr.api";
import { capitalizeString } from "../../../../../../../../../../utils/helpers/capitalizeString";
import { budgetPeriods } from "../utils/constants/date-selection";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { isCurrentBudgetEditingSelector } from "../../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/isCurrentBudgetEditingSelector";
import { setCurrentBudgetData } from "../../../../../../../../../../store/budgets/budgetsSlice";
import { getResourceExplorerDataByBudgetThunk } from "../../../../../../../../../../store/budgets/thunks/getResourceExplorerDataByBudgetThunk";
import { formatDate } from "../../../../../../../../../../utils/helpers/date-time/datetime-format";

interface PeriodAndDateSelectionProps {
  period: BudgetPeriod;
  startDate: string;
  setFieldValue(key: string, val: string): void;
  thresholds: Threshold[];
  onThresholdChange(value: Threshold[]): void;
}

export const PeriodAndDateSelection: FC<PeriodAndDateSelectionProps> = ({
  period,
  startDate,
  setFieldValue,
  thresholds,
  onThresholdChange,
}) => {
  const dispatch = useAppDispatch();
  const isEdit = useAppSelector(isCurrentBudgetEditingSelector);

  const startDateChaneHandler = useCallback(
    (startDate: string, p?: BudgetPeriod) => {
      let dateObj = moment(startDate);
      if (p !== "daily" && period !== "daily") {
        dateObj = dateObj.startOf("month");
      }

      const date = formatDate(dateObj);

      setFieldValue("startDate", date);
      dispatch(setCurrentBudgetData({ start_date: date }));
    },
    [setFieldValue, dispatch, period],
  );

  const periodChangeHandler = useCallback(
    (event: any) => {
      const period = event.target.value;
      setFieldValue("period", period);
      dispatch(setCurrentBudgetData({ period }));

      let startDate = moment().format();

      if (period === "quarterly") {
        startDate = moment()
          .quarter(moment().quarter())
          .startOf("quarter")
          .format();
      }

      if (period === "daily" || period === "annually") {
        const updatedThresholds = thresholds.map(
          (threshold): Threshold => ({
            ...threshold,
            trigger: "actual",
          }),
        );
        onThresholdChange(updatedThresholds);
      }

      dispatch(getResourceExplorerDataByBudgetThunk());

      startDateChaneHandler(startDate, period);
    },
    [
      dispatch,
      startDateChaneHandler,
      setFieldValue,
      thresholds,
      onThresholdChange,
    ],
  );

  return (
    <Fragment>
      <FormControl size="small">
        <InputLabel>Period</InputLabel>
        <Select
          fullWidth
          value={period}
          disabled={isEdit}
          onChange={periodChangeHandler}
          renderValue={(val) => capitalizeString(val)}
          label="Period"
        >
          {budgetPeriods.map((period) => {
            return (
              <MenuItem key={period} value={period}>
                <ListItemText>{capitalizeString(period)}</ListItemText>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Stack direction="row">
        <StartDateSelection
          period={period}
          startDate={startDate}
          onDateChange={startDateChaneHandler}
        />
      </Stack>
    </Fragment>
  );
};
