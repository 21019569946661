import { FC } from "react";
import { Chip } from "@mui/material";

interface DynamoDbBackupTableStatusCellProps {
  data: string;
}

export const DynamoDbBackupTableStatusCell: FC<
  DynamoDbBackupTableStatusCellProps
> = ({ data }) => {
  if (!data) {
    return "-";
  }

  const label =
    data?.toLowerCase() === "active" ? "available" : data?.toLowerCase();

  return (
    <Chip
      sx={{ textTransform: "capitalize" }}
      size="small"
      label={label}
      color={colors[label] ?? "default"}
      variant="outlined"
    />
  );
};

const colors: Record<string, "default" | "success"> = {
  available: "success",
  deleted: "default",
};
