import { FC, useCallback, useState } from "react";
import { Stack, Tooltip } from "@mui/material";
import { GridApi } from "ag-grid-community";
import { BudgetAndAlertsCsvExport } from "./BudgetAndAlertsCsvExport";
import { CreateBudgetOrAlertButton } from "../../CreateBudgetOrAlertButton";
import { useAppAbility } from "../../../../../../services/permissions";
import { useAppSelector } from "../../../../../../store/hooks";
import { alertsActiveTabSelector } from "../../../../../../store/alerts/selectors/alertsActiveTabSelector";
import { getUserPermissionInfoText } from "../../../../utils/helpers/getUserPermissionInfoText";
import { AlertTabType } from "../../../../../../store/alerts/utils/types/types";
import { GlobalFilter } from "../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { AGGridColumnsToolPanel } from "../../../../common/ag-grid/components/AGGridColumnsToolPanel";
import { AgGridAllFiltersChip } from "../../../../common/ag-grid/components/filters/AgGridAllFiltersChip";

interface AlertsAgGridToolbarProps {
  gridApi: GridApi | null;
}

export const BudgetAndAlertsAgGridToolbar: FC<AlertsAgGridToolbarProps> = ({
  gridApi,
}) => {
  const { cannot } = useAppAbility();
  const cannotCreateAlert = cannot("create", "alert");
  const cannotCreateBudget = cannot("create", "budget");
  const alertsActiveTab = useAppSelector(alertsActiveTabSelector);
  const [searchValue, setSearchValue] = useState("");

  const userPermissionTooltipText = cannotCreateAlert
    ? getUserPermissionInfoText(
        alertsActiveTab === "budget" ? "Create Budget" : "Create Alerts",
      )
    : "";

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridApi?.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [gridApi],
  );

  return (
    <Stack
      py={2.5}
      px={1.5}
      direction="row"
      bgcolor="white"
      borderBottom={1}
      position="sticky"
      borderColor="grey.300"
      justifyContent="space-between"
    >
      <span>
        <AgGridAllFiltersChip gridApi={gridApi} />
      </span>

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={2}
      >
        <Tooltip title={userPermissionTooltipText}>
          <span>
            <CreateBudgetOrAlertButton
              variant="outlined"
              disabled={
                alertsActiveTab === "budget"
                  ? cannotCreateBudget
                  : cannotCreateAlert
              }
            >
              Create {alertTypes[alertsActiveTab]}
            </CreateBudgetOrAlertButton>
          </span>
        </Tooltip>

        <GlobalFilter
          globalFilter={searchValue}
          setGlobalFilter={searchChangeHandler}
          size="xsmall"
          sx={{ width: 260 }}
        />

        <BudgetAndAlertsCsvExport gridApi={gridApi} />

        <AGGridColumnsToolPanel gridApi={gridApi} />
      </Stack>
    </Stack>
  );
};

const alertTypes: Record<AlertTabType, string> = {
  budget: "Budget",
  costAnomaly: "Anomaly Alert",
  utilization: "Commitment Alert",
};
