import { ColDef } from "ag-grid-community";

export const budgetsThresholdSortingFn: ColDef["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const current = nodeA?.data?.thresholds.at(0)?.percent;
  const next = nodeB?.data?.thresholds.at(0)?.percent;

  if (!current) return 1;
  if (!next) return -1;

  return current - next;
};
