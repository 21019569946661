import { FC, Fragment } from "react";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import {
  formatSecondsToDays,
  formatSecondsToFormattedDate,
} from "../utils/helpers/formatSecondsToFormattedDate";
import { TooltipText } from "../../../../../../../../../common/TooltipText";
import { oneDayInSeconds } from "../utils/constants/constants";

interface SecondsToHoursCellProps {
  data?: number;
}

export const SecondsToFormattedDateCell: FC<SecondsToHoursCellProps> = ({
  data,
}) => {
  if (typeof data !== "number") {
    return <Fragment>-</Fragment>;
  }

  if (data >= oneDayInSeconds * 30) {
    return (
      <TooltipText tooltipTitle={formatSecondsToDays(data)} variant="body2">
        {formatSecondsToFormattedDate(data)}
      </TooltipText>
    );
  }

  return (
    <TypographyWithTooltip
      title={formatSecondsToFormattedDate(data)}
      variant="body2"
    />
  );
};
