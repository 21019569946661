import { FC, useCallback } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { LiveUsageMgmtNavItemStats } from "./LiveUsageMgmtNavItemStats";
import { IconByResourceType } from "../../../../../../common/IconByResourceType";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { useAppSelector } from "../../../../../../../../store/hooks";
import {
  ProviderType,
  ResourceType,
} from "../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { useHover } from "../../../../../../../../utils/hooks/useHover.hook";
import { liveUsageMgmtResourceGroupEmsSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-group-data/liveUsageMgmtResourceGroupEmsSelector";
import { liveUsageMgmtResourceGroupSpendingSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-group-data/liveUsageMgmtResourceGroupSpendingSelector";
import { useLiveUsageMgmtPageNavigate } from "../../../utils/hooks/useLiveUsageMgmtPageNavigate.hook";

interface LiveUsageMgmtNavItemMainProps {
  resourceType: ResourceType;
  provider: ProviderType;
  selected?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  clickable?: boolean;
  onClick?(): void;
  hideCosts?: boolean;
  parentTotalCost: number;
  parentFilteredCost: number;
  filterTemplatesEnabled: boolean;
}

export const LiveUsageMgmtNavItemMain: FC<LiveUsageMgmtNavItemMainProps> = ({
  resourceType,
  provider,
  selected,
  expandable,
  expanded,
  clickable = true,
  onClick,
  hideCosts,
  parentTotalCost,
  parentFilteredCost,
  filterTemplatesEnabled,
}) => {
  const handlePageNavigation = useLiveUsageMgmtPageNavigate();
  const { ref, hovered } = useHover();

  const handleMainItemClick = useCallback(() => {
    if (expandable) {
      onClick?.();
    } else {
      handlePageNavigation(resourceType);
    }
  }, [expandable, onClick, resourceType, handlePageNavigation]);

  const ems = useAppSelector((state) =>
    liveUsageMgmtResourceGroupEmsSelector(state, provider, resourceType),
  );

  const total = useAppSelector((state) =>
    liveUsageMgmtResourceGroupSpendingSelector(state, provider, resourceType),
  );

  return (
    <ListItem sx={listItemSx}>
      <ListItemButton
        sx={{
          ...listItemButtonSx,
          bgcolor: clickable ? "grey.50" : undefined,
          pointerEvents: !clickable ? "none" : undefined,
        }}
        selected={selected}
        onClick={handleMainItemClick}
        ref={ref}
      >
        <ListItemIcon sx={listItemIconSx}>
          {expandable && hovered ? (
            <ArrowDropDownIcon
              sx={{ transform: expanded ? "rotate(0deg)" : "rotate(-90deg)" }}
            />
          ) : (
            <IconByResourceType
              resourceType={resourceType}
              primary={selected}
            />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <TypographyWithTooltip
                variant="subtitle2"
                title={getResourceTypeName(resourceType, {
                  service: true,
                  singular: provider !== "kubernetes",
                  type: "abbreviation",
                })}
              />
              {!hideCosts && (
                <LiveUsageMgmtNavItemStats
                  ems={parentFilteredCost}
                  total={parentTotalCost}
                  filterTemplatesEnabled={filterTemplatesEnabled}
                />
              )}
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

const listItemSx = { px: 1, py: 0.25 };
const listItemButtonSx = { borderRadius: 2, pl: 1 };
const listItemIconSx = { minWidth: 32 };
