import { FC, useLayoutEffect, useState } from "react";
import { Card, Stack } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { AuthLessDashboardHeader } from "./AuthLessDashboardHeader";
import { EmptyDashboard } from "../../pages/dashboard/components/corner-components/EmptyDashboard";
import {
  DashboardWidgetCostAndUsageSummary,
  DashboardWidgetCostBreakdown,
  DashboardWidgetLargestCostChanges,
  Dates,
  WidgetType,
} from "../../../services/cloudchipr.api";
import { widgetSetupContentChartHeight } from "../../pages/dashboard/utils/constants/stylings";
import { useGetDashboardsByDashboardIdQuery } from "../../../services/cloudchipr-authless.api";
import { storageKeyForApiKey } from "../../../services/empty.authless.api";
import { DateRangePicker } from "../../common/date-range-picker/DateRangePicker";
import { UnauthorizedDashboardBillingExplorerWidgetWrapper } from "../common/components/billing-explorer-widget/UnauthorizedDashboardBillingExplorerWidgetWrapper";
import { UnauthorizedCostAnomaliesWidgetWrapper } from "../common/components/cost-anomalies/UnauthorizedCostAnomaliesWidgetWrapper";
import { UnauthorizedDashboardBillingSummaryWidgetWrapper } from "../common/components/billing-summary/UnauthorizedDashboardBillingSummaryWidgetWrapper";

export const DashboardUnauthorizedView: FC = () => {
  const { dashboardId } = useParams<{
    dashboardId: string;
  }>();
  const [searchParams] = useSearchParams();
  const apiKey = searchParams.get("api_key") ?? null;
  const categoryId = searchParams.get("category_id") ?? undefined;

  const [dates, setDates] = useState<Dates | null>(null);

  const { data, isLoading } = useGetDashboardsByDashboardIdQuery(
    { dashboardId: dashboardId ?? "" },
    { skip: !dashboardId || !apiKey },
  );

  const widgetsData = data?.widgets as (
    | DashboardWidgetCostBreakdown
    | DashboardWidgetCostAndUsageSummary
    | DashboardWidgetLargestCostChanges
  )[];

  useLayoutEffect(() => {
    if (!apiKey) {
      return;
    }

    sessionStorage.setItem(storageKeyForApiKey, apiKey);
  }, [apiKey]);

  if (!widgetsData?.length && !isLoading) {
    return <EmptyDashboard hideAddButton />;
  }

  return (
    <Stack bgcolor="grey.100" height="100vh" overflow="auto" spacing={2} pb={2}>
      <AuthLessDashboardHeader name={data?.name}>
        <DateRangePicker
          highlightToday
          selectedDates={dates ?? {}}
          onDateSelect={setDates}
        />
      </AuthLessDashboardHeader>

      <Stack px={2} spacing={2}>
        {widgetsData?.map((widget) => {
          const Component = widgetsComponents[widget.type];

          if (!Component) {
            return null;
          }

          return (
            <Card
              key={widget.id}
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                height: widgetSetupContentChartHeight,
              }}
            >
              <Component
                {...widget}
                dates={dates ?? widget.dates}
                overrideQueryDates={dates ?? {}}
                categoryId={categoryId}
              />
            </Card>
          );
        })}
      </Stack>
    </Stack>
  );
};

const widgetsComponents: Partial<Record<WidgetType, FC<any>>> = {
  cost_breakdown: UnauthorizedDashboardBillingExplorerWidgetWrapper,
  cost_and_usage_summary: UnauthorizedDashboardBillingSummaryWidgetWrapper,
  largest_cost_changes: UnauthorizedCostAnomaliesWidgetWrapper,
};
