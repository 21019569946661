import { capitalize } from "@mui/material";

export const getLabelByBillingSourceType = (
  sourceType: "view" | "dashboard",
  capitalized?: boolean,
): string => {
  let label: string = sourceType;

  if (sourceType === "view") {
    label = "report";
  }

  return capitalized ? capitalize(label) : label;
};
