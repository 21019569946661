import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { useDidMount } from "rooks";
import { TextWidgetCardHeader } from "./components/TextWidgetCardHeader";
import { WidgetLoadingState } from "../common/WidgetLoadingState";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { getTextWidgetDataByWidgetIdThunk } from "../../../../../../store/dashboards/thunks/widgets/text-widget/getTextWidgetDataByWidgetIdThunk";
import { textWidgetByWidgetIdLoadingSelector } from "../../../../../../store/dashboards/selectors/widgets/text-widget/widget-view/textWidgetByWidgetIdLoadingSelector";
import { Editor } from "../../../../../../storybook/editor/Editor";
import { textWidgetDataByIdSelector } from "../../../../../../store/dashboards/selectors/widgets/text-widget/widget-view/textWidgetDataByIdSelector";

interface DashboardTextWidgetProps {
  widgetId: string;
}

export const DashboardTextWidget: FC<DashboardTextWidgetProps> = ({
  widgetId,
}) => {
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();
  const widgetData = useAppSelector((state) =>
    textWidgetDataByIdSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    textWidgetByWidgetIdLoadingSelector(state, widgetId),
  );

  useDidMount(() => {
    dispatch(getTextWidgetDataByWidgetIdThunk(widgetId));
  });

  return (
    <Stack ref={ref} flexGrow={1} overflow={loading ? "hidden" : "auto"}>
      {loading ? (
        <WidgetLoadingState />
      ) : (
        <Fragment>
          <TextWidgetCardHeader widgetId={widgetId} hovered={hovered} />

          <Stack height="100%" tabIndex={0} p={2} pt={0} overflow={"hidden"}>
            <Editor
              key={widgetData?.text ?? ""}
              minHeight={140}
              content={widgetData?.text ?? ""}
              editable={false}
            />
          </Stack>
        </Fragment>
      )}
    </Stack>
  );
};
