import { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { ResourceFilters } from "../../../../../services/cloudchipr.api";
import { SetRefsFromKey } from "../../../../../utils/hooks/useRefs";
import { FiltersDialogContent } from "../../../../pages/common/filters/filters-selection/filters-modal/FiltersDialogContent";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { liveUsageMgmtApplyingFilterTemplateFiltersByResourceTypeSelector } from "../../../../../store/live-usage-mgmt/selectors/liveUsageMgmtApplyingFilterTemplateFiltersByResourceTypeSelector";
import { liveUsageMgmtAccountIdsSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";
import {
  setLiveUsageMgmtAppliedFilters,
  setLiveUsageMgmtFilterChangedManually,
  setLiveUsageMgmtViewWithoutFiltersResourceTypes,
} from "../../../../../store/live-usage-mgmt/liveUsageMgmtSlice";
import { getLiveUsageMgmtResourcesWithFiltersByProviderThunk } from "../../../../../store/live-usage-mgmt/thunks/resources/getLiveUsageMgmtResourcesWithFiltersByProviderThunk";
import { isDeepEqual } from "../../../../../utils/is-deep-equal";
import { resourceTypeFilterSubmitHandlerKeyGenerator } from "../../utils/helpers/resourceTypeFilterSubmitHandlerKeyGenerator";

interface LiveResourceFiltersContentCardProps {
  filter: ResourceFilters;
  setRefFromKey: SetRefsFromKey;
  onClose(): void;
}

export const LiveResourceFiltersContentCard: FC<
  LiveResourceFiltersContentCardProps
> = ({ filter, setRefFromKey, onClose }) => {
  const dispatch = useAppDispatch();

  const resourceType = filter.type;

  const accountIds = useAppSelector(liveUsageMgmtAccountIdsSelector);
  const defaultFilters = useAppSelector((state) =>
    liveUsageMgmtApplyingFilterTemplateFiltersByResourceTypeSelector(
      state,
      resourceType,
    ),
  );

  const submitHandlerInitializer = useCallback(
    (submitFormFn: () => void) => {
      const key = resourceTypeFilterSubmitHandlerKeyGenerator(resourceType);

      setRefFromKey(key)(submitFormFn);
    },
    [setRefFromKey, resourceType],
  );

  const submitHandler = useCallback(
    (values: ResourceFilters) => {
      const filtersEqual = isDeepEqual(filter, values);

      if (!accountIds || filtersEqual) {
        return;
      }

      dispatch(setLiveUsageMgmtAppliedFilters(values));
      dispatch(setLiveUsageMgmtFilterChangedManually());
      dispatch(
        setLiveUsageMgmtViewWithoutFiltersResourceTypes({
          resourceType,
          value: false,
        }),
      );

      dispatch(
        getLiveUsageMgmtResourcesWithFiltersByProviderThunk(resourceType),
      );
    },
    [dispatch, accountIds, resourceType, filter],
  );

  if (!defaultFilters || !accountIds) {
    return null;
  }

  return (
    <Stack bgcolor="grey.50" borderRadius={2}>
      <Typography fontWeight="bold" pl={2.5} pt={1.5}>
        {getResourceTypeName(resourceType, { type: "long" })}
      </Typography>

      <FiltersDialogContent
        unifiedFilters
        filters={filter}
        onSubmit={submitHandler}
        onClose={onClose}
        onSubmitCallback={submitHandlerInitializer}
        accountIds={accountIds}
        defaultFilters={defaultFilters}
        resourceType={resourceType}
      />
    </Stack>
  );
};
