import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { WidgetViewTypeSelect } from "../../../common/WidgetViewTypeSelect";
import { billingExplorerViewTypeChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerViewTypeChangeThunk";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";

export const BillingExplorerWidgetViewTypeSelect: FC = () => {
  const dispatch = useAppDispatch();
  const viewType = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("chart_type"),
  );

  const changeHandler = useCallback(
    (e: any) => {
      const type = e.target.value;

      dispatch(billingExplorerViewTypeChangeThunk(type));
    },
    [dispatch],
  );

  return (
    <WidgetViewTypeSelect
      viewType={viewType}
      options={["bar", "stack", "line", "pie", "table", "numeral"]}
      onChange={changeHandler}
    />
  );
};
