import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Button, Link, Typography } from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { SavingsPlanUtilizationRecommendation } from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";
import { CommitmentsProgressBar } from "../../../../../common/components/CommitmentsProgressBar";
import { DatesCell } from "../../../../../common/components/data-grid/cells/DatesCell";

export const savingsPlanDrawerRecommendationsGridColumnDefs: ColDef<SavingsPlanUtilizationRecommendation>[] =
  [
    {
      field: "current_hourly_commitment",
      lockVisible: true,
      suppressColumnsToolPanel: true,
      headerName: "Current Cost",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanUtilizationRecommendation>) => (
        <Typography variant="body2">
          {money(data?.current_hourly_commitment ?? 0)}{" "}
          <Typography variant="inherit" component="span" color="text.secondary">
            /hour
          </Typography>
        </Typography>
      ),
      valueGetter: ({ data }) => money(data?.current_hourly_commitment ?? 0),
    },
    {
      minWidth: 170,
      field: "recommended_hourly_commitment",
      headerName: "Recommended Cost",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanUtilizationRecommendation>) => (
        <Typography variant="body2">
          {money(data?.recommended_hourly_commitment ?? 0)}{" "}
          <Typography variant="inherit" component="span" color="text.secondary">
            /hour
          </Typography>
        </Typography>
      ),
      valueGetter: ({ data }) =>
        money(data?.recommended_hourly_commitment ?? 0),
    },
    {
      minWidth: 220,
      field: "current_utilization",
      headerName: "Current Utilization",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanUtilizationRecommendation>) => (
        <CommitmentsProgressBar value={data?.current_utilization ?? 0} />
      ),
    },
    {
      minWidth: 220,
      field: "estimated_utilization",
      headerName: "Estimated Utilization",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanUtilizationRecommendation>) => (
        <CommitmentsProgressBar value={data?.estimated_utilization ?? 0} />
      ),
    },
    {
      minWidth: 240,
      field: "savings_plan_end_date",
      headerName: "Current Commitment End Date",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanUtilizationRecommendation>) => (
        <DatesCell date={data?.savings_plan_end_date ?? ""} />
      ),
    },
    {
      minWidth: 220,
      field: "recommended_start_date",
      headerName: "Recommended Start Date",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanUtilizationRecommendation>) => (
        <DatesCell date={data?.recommended_start_date ?? ""} />
      ),
    },
    {
      minWidth: 220,
      field: "estimated_monthly_savings",
      headerName: "Estimated Monthly Savings",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanUtilizationRecommendation>) => (
        <Typography variant="body2" color="success.light">
          {money(data?.estimated_monthly_savings ?? 0)}
        </Typography>
      ),
      valueGetter: ({ data }) => money(data?.estimated_monthly_savings ?? 0),
    },
    {
      width: 200,
      resizable: false,
      pinned: "right",
      lockPinned: true,
      lockVisible: true,
      sortable: false,
      filter: false,
      headerName: "",
      field: "link",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<SavingsPlanUtilizationRecommendation>) => (
        <Button
          component={Link}
          href={data?.link ?? ""}
          target="_blank"
          variant="outlined"
          endIcon={<OpenInNewOutlinedIcon />}
          size="small"
        >
          RENEW
        </Button>
      ),
    },
  ];
