import { RootState } from "../../../../store";
import { resourceExplorerStateSelector } from "../../resourceExplorerStateSelector";

export const billingExplorerExplainWithAiOpenSelector = (
  state: RootState,
): boolean => {
  return (
    resourceExplorerStateSelector(state).sideBarPanelContentType ===
    "explainWithAI"
  );
};
