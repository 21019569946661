import { FC, useCallback } from "react";
import { IconButton, Tooltip } from "@mui/material";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { taskManagementInitiateEmptyTaskThunk } from "../../../../../../store/task-management/thunks/form/taskManagementInitiateEmptyTaskThunk";
import { setTaskManagementTaskTarget } from "../../../../../../store/task-management/taskManagementSlice";
import { savingsOpportunitiesSelectedItemsSelector } from "../../../../../../store/savings-opportunities/selectors/selection/savingsOpportunitiesSelectedItemsSelector";
import { OpportunityTarget } from "../../../../../../services/cloudchipr.api";

export const SavingsOpportunitiesCreateTaskToolbarAction: FC = () => {
  const dispatch = useAppDispatch();
  const opportunities = useAppSelector(
    savingsOpportunitiesSelectedItemsSelector,
  );

  const clickHandler = useCallback(async () => {
    const firstOpportunity =
      opportunities?.length === 1 ? opportunities?.at(0) : undefined;

    await dispatch(
      taskManagementInitiateEmptyTaskThunk(
        firstOpportunity
          ? { description: firstOpportunity.description }
          : undefined,
      ),
    );

    dispatch(
      setTaskManagementTaskTarget({
        type: "opportunity",
        opportunities:
          opportunities?.map((opportunity) => {
            return {
              ...opportunity,
              state: null,
              cloud_provider: opportunity.account.provider,
              provider_identifier: opportunity.resource_id,
              current_type: opportunity.resource_type,
              resource_type: opportunity.service,
              price_per_month: opportunity.current_monthly_price,
              live_resource_link_response: null,
            } as OpportunityTarget;
          }) ?? [],
      }),
    );
  }, [dispatch, opportunities]);

  const disabled = !opportunities?.length;

  return (
    <Tooltip
      arrow
      placement="top"
      title={`${disabled ? "Select rows to " : ""}Create Task`}
    >
      <span>
        <IconButton size="small" onClick={clickHandler} disabled={disabled}>
          <PostAddOutlinedIcon
            color={disabled ? "disabled" : "primary"}
            fontSize="small"
          />
        </IconButton>
      </span>
    </Tooltip>
  );
};
