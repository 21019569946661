import { FC } from "react";
import EmailLogo from "./email.svg";
import GreyEmailLogo from "./email_grey.svg";

interface EmailIconProps {
  variant?: "colored" | "grey";
  disabled?: boolean;
  width?: number;
}

export const EmailIcon: FC<EmailIconProps> = ({ variant, disabled, width }) => {
  return (
    <img
      src={variant ? GreyEmailLogo : EmailLogo}
      style={{ filter: `grayscale(${disabled ? 1 : 0})` }}
      alt="Email"
      width={width}
    />
  );
};

export default EmailIcon;
