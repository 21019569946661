import { FC } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { Box } from "@mui/material";
import { Task } from "../../../../../../services/cloudchipr.api";
import { TaskTargetTableIdentifierRow } from "../../../../common/task-management/components/form/content/details-section/components/target-section/common/TaskTargetTableIdentifierRow";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";

export const ResourceIdCellRenderer: FC<CustomCellRendererProps<Task>> = ({
  data,
}) => {
  const { ref, hovered } = useHover();

  const target = data?.target;

  if (!target) {
    return null;
  }

  return (
    <Box ref={ref}>
      <TaskTargetTableIdentifierRow
        target={target}
        cloudLink={hovered}
        copyButton={hovered}
        liveUsageLink={hovered}
        typographyProps={{ fontWeight: "normal" }}
      />
    </Box>
  );
};
