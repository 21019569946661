import { FC, ReactNode, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useFlag } from "@unleash/proxy-client-react";
import { useAppAbility } from "../../../../../../services/permissions";
import { getUserPermissionInfoText } from "../../../../utils/helpers/getUserPermissionInfoText";
import { useAppDispatch } from "../../../../../../store/hooks";
import {
  IntegrationType,
  setActiveIntegration,
} from "../../../../../../store/integrations/integrationsSlice";

interface CreateIntegrationButtonProps {
  type: IntegrationType;
  integrationLabel: string;
  variant?: "outlined" | "contained";
  withStartIcon?: boolean;
  children?: ReactNode;
}

export const CreateIntegrationButton: FC<CreateIntegrationButtonProps> = ({
  type,
  integrationLabel,
  variant,
  withStartIcon,
  children,
}) => {
  const { cannot } = useAppAbility();
  const canNotCreateIntegration = cannot("create", "integration");

  const enableRedesignedIntegrations = useFlag("EnableRedesignedIntegrations");

  const dispatch = useAppDispatch();

  const createHandler = useCallback(() => {
    dispatch(setActiveIntegration({ type, id: null }));
  }, [dispatch, type]);

  const userPermissionTooltipText = canNotCreateIntegration
    ? getUserPermissionInfoText(`Connect ${integrationLabel}`)
    : "";

  return (
    <Tooltip title={userPermissionTooltipText}>
      <span>
        {enableRedesignedIntegrations ? (
          <Button
            variant={variant ?? "outlined"}
            disabled={canNotCreateIntegration}
            color="primary"
            sx={{ textTransform: "none" }}
            startIcon={withStartIcon ? <AddIcon /> : ""}
            onClick={createHandler}
          >
            {children}
          </Button>
        ) : (
          <Button
            variant={variant ?? "outlined"}
            disabled={canNotCreateIntegration}
            color="primary"
            component={RouterLink}
            to="/integrations/create"
            sx={{ textTransform: "none" }}
            startIcon={withStartIcon ? <AddIcon /> : ""}
          >
            {children}
          </Button>
        )}
      </span>
    </Tooltip>
  );
};
