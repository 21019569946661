import { resourceDetailsResourceBillingDataTotalCostSelector } from "./resourceDetailsResourceBillingDataTotalCostSelector";
import { resourceDetailsResourceBillingDataPreviousPeriodTotalCostSelector } from "./resourceDetailsResourceBillingDataPreviousPeriodTotalCostSelector";
import { generateBillingExplorerTotalCostTrend } from "../../../../resource-explorer/utils/helpers/generateBillingExplorerTotalCostTrend";
import { RootState } from "../../../../store";

export const resourceDetailsResourceBillingDataTotalTrendSelector = (
  state: RootState,
) => {
  const totalCost = resourceDetailsResourceBillingDataTotalCostSelector(state);
  const previousPeriodCost =
    resourceDetailsResourceBillingDataPreviousPeriodTotalCostSelector(state);

  return generateBillingExplorerTotalCostTrend(
    totalCost,
    previousPeriodCost ?? 0,
  );
};
