import { liveUsageMgmtResourceTypeDataResourcesSelector } from "./liveUsageMgmtResourceTypeDataResourcesSelector";
import { RootState } from "../../../store";
import { ResourceType, TaskStatus } from "../../../../services/cloudchipr.api";
import { MetricsFrequenciesType } from "../../../../components/pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/types/metricsFrequenciesType";
import { generateExportedResourceData } from "../../../../components/pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateExportedResourceData";

export const liveUsageMgmtGenerateExportedResourceData = (
  state: RootState,
  resourceType: ResourceType,
  disabledColumns: string[] = [],
  metricsFrequencies?: MetricsFrequenciesType,
  taskStatuses?: TaskStatus[],
) => {
  const resourceData = liveUsageMgmtResourceTypeDataResourcesSelector(
    state,
    resourceType,
  );

  if (!resourceData) {
    return null;
  }

  return generateExportedResourceData(
    resourceType,
    resourceData,
    disabledColumns,
    {
      includeNestedRows: true,
      metricsFrequencies,
      taskStatuses,
    },
  );
};
