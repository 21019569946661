import { FC, Fragment, useCallback } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { BillingExplorerWidgetSetupDateRangeSelect } from "../components/side-bar/BillingExplorerWidgetSetupDateRangeSelect";
import { BillingExplorerWidgetSetupGroupingSelect } from "../components/side-bar/BillingExplorerWidgetSetupGroupingSelect";
import { BillingExplorerWidgetViewTypeSelect } from "../components/side-bar/BillingExplorerWidgetViewTypeSelect";
import { WidgetsViewsHierarchySelect } from "../../common/widgets-views-hierarchy-select/WidgetsViewsHierarchySelect";
import { WidgetForecastSelect } from "../../common/toolbar/WidgetForecastSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { billingExplorerSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { billingExplorerSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupVisualizationPropertyByKeySelector";
import { BillingExplorerWidgetConfigurations } from "../configurations/BillingExplorerWidgetConfigurations";
import { billingExplorerWidgetSetupForecastedOptionsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/setup/billingExplorerWidgetSetupForecastedOptionsSelector";
import { billingExplorerForecastChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerForecastChangeThunk";
import { billingExplorerREViewChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerREViewChangeThunk";

export const BillingExplorerWidgetSetupSidebar: FC = () => {
  const dispatch = useAppDispatch();
  const enableWidgetsSideBarConfigurations = useFlag(
    "EnableWidgetsSideBarConfigurations",
  );

  const viewId = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("viewId"),
  );

  const viewType = useAppSelector(
    billingExplorerSetupVisualizationPropertyByKeySelector("chart_type"),
  );
  const forecastOption = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("forecastOption"),
  );

  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );

  const forecastedCostsOptions = useAppSelector(
    billingExplorerWidgetSetupForecastedOptionsSelector,
  );

  const forecastChangeHandler = useCallback(
    (event: any) => {
      dispatch(billingExplorerForecastChangeThunk(event.target.value));
    },
    [dispatch],
  );

  const viewChangeHandler = useCallback(
    (id: string) => {
      dispatch(billingExplorerREViewChangeThunk(id));
    },
    [dispatch],
  );

  if (!viewType) {
    return null;
  }

  return (
    <Fragment>
      <WidgetsViewsHierarchySelect
        selectedItemId={viewId}
        onChange={viewChangeHandler}
      />

      <BillingExplorerWidgetSetupGroupingSelect disabled={viewGettingLoading} />
      <BillingExplorerWidgetSetupDateRangeSelect />

      {enableWidgetsSideBarConfigurations ? (
        <BillingExplorerWidgetConfigurations />
      ) : (
        <Fragment>
          <BillingExplorerWidgetViewTypeSelect />
          <WidgetForecastSelect
            forecast={forecastOption}
            onChange={forecastChangeHandler}
            options={forecastedCostsOptions}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
