import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { widgetViewNameByViewIdIdSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/common/widgetViewNameByViewIdIdSelector";
import { WidgetSetupSubHeader } from "../../common/WidgetSetupSubHeader";
import { billingExplorerSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { billingExplorerWidgetSetupSelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerWidgetSetupSelector";
import { billingExplorerWidgetSetupBillingExportUrlSelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerWidgetSetupBillingExportUrlSelector";

export const BillingExplorerWidgetSetupSubHeader: FC = () => {
  const viewId = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("viewId"),
  );
  const widgetSetup = useAppSelector(billingExplorerWidgetSetupSelector);
  const viewName = useAppSelector((state) =>
    widgetViewNameByViewIdIdSelector(state, viewId),
  );
  const billingExplorerUrl = useAppSelector(
    billingExplorerWidgetSetupBillingExportUrlSelector,
  );

  return (
    <WidgetSetupSubHeader
      name={widgetSetup?.name ?? ""}
      dates={widgetSetup?.dates ?? {}}
      title={viewName ?? ""}
      url={billingExplorerUrl}
      groupBy={widgetSetup?.grouping}
      groupValues={widgetSetup?.group_values ?? undefined}
    />
  );
};
