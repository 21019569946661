import { costAnomaliesWidgetByIdSelector } from "./costAnomaliesWidgetByIdSelector";
import { costAnomaliesWidgetNotFoundSelector } from "./costAnomaliesWidgetNotFoundSelector";
import { RootState } from "../../../../store";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { objectToUrl } from "../../../../../components/utils/helpers/dataToUrlToDataConvertors";
import { WidgetTitleProps } from "../../../../../components/pages/dashboard/utils/types/types";
import { resourceExplorerGroupingLabelByGroupingSelector } from "../../../../resource-explorer/selectors/possible-groupings/resourceExplorerGroupingLabelByGroupingSelector";

export const costAnomaliesWidgetTitlePropsSelector = (
  state: RootState,
  widgetId: string,
): WidgetTitleProps | null => {
  const widget = costAnomaliesWidgetByIdSelector(state, widgetId);

  if (!widget) {
    return null;
  }
  const groupByLabel = resourceExplorerGroupingLabelByGroupingSelector(
    state,
    widget.grouping,
  );

  const viewName = resourceExplorerViewNameByViewIdSelector(
    state,
    widget.view_id,
  );

  const viewNotFound = costAnomaliesWidgetNotFoundSelector(state, widgetId);

  const path = objectToUrl([
    {
      groupedBy: widget.grouping,
      groupValues: widget.group_values,
      dates: widget.dates,
      filterTree: undefined,
    },
  ]);

  return {
    groupBy: widget.grouping,
    groupByLabel: groupByLabel,
    groupValues: widget.group_values,
    widgetName: widget.name,
    dates: widget.dates,
    sourceData: {
      title: viewNotFound || !viewName ? "" : viewName,
      url: `/resource-explorer/${widget.view_id}?path=${path}`,
    },
  };
};
