import { FC, useMemo } from "react";
import { Divider, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import { useEffectOnceWhen } from "rooks";
import { InternalResourceExplorerTotalViewDataGrid } from "./InternalResourceExplorerTotalViewDataGrid";
import { InternalResourceExplorerChart } from "./InternalResourceExplorerChart";
import { InternalResourceExplorerMetadata } from "./InternalResourceExplorerMetadata";
import { MultiTypeChartProvider } from "../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { formatResourceExplorerChartData } from "../../../../store/resource-explorer/utils/helpers/formatResourceExplorerChartData";
import {
  ResourceExplorer,
  ResourceExplorerWidget,
} from "../../../../services/cloudchipr.api";
import { setGlobalVarReadyWithTimeout } from "../../common/utils/helpers/setGlobalVarReadyWithTimeout";

interface InternalResourceExplorerCardProps {
  view: ResourceExplorer;
  viewData?: ResourceExplorerWidget;
  organisationId: string;
}

export const InternalResourceExplorerCard: FC<
  InternalResourceExplorerCardProps
> = ({ view, viewData, organisationId }) => {
  const chartData = useMemo(
    () =>
      formatResourceExplorerChartData(
        viewData?.data ?? [],
        view.data.data_point,
      ),
    [viewData?.data, view.data.data_point],
  );

  useEffectOnceWhen(() => {
    setGlobalVarReadyWithTimeout();
  }, !!chartData.length);

  if (!viewData) {
    return null;
  }

  return (
    <Stack bgcolor="white" p={2} flexGrow={1}>
      <InternalResourceExplorerMetadata viewData={viewData} view={view} />

      <MultiTypeChartProvider
        value={{
          initialChartType: "stack",
          data: chartData ?? [],
        }}
      >
        <InternalResourceExplorerChart
          budget={view.budget}
          showBudgetLine={!!view.data.show_budget_line}
          dataPoint={view.data.data_point}
        />

        <Stack direction="row" alignItems="center" my={2}>
          <Divider sx={{ flexGrow: 1 }} />

          <ToggleButtonGroup
            exclusive
            size="small"
            sx={{
              "& .Mui-selected svg": {
                color: "primary.main",
              },
            }}
            value="chart_grid"
          >
            <ToggleButton
              value="chart_grid"
              sx={{ borderRadius: "8px 0 0 8px" }}
            >
              <ViewAgendaIcon />
            </ToggleButton>

            <ToggleButton value="chart">
              <VideoLabelIcon />
            </ToggleButton>

            <ToggleButton value="grid" sx={{ borderRadius: "0 8px 8px 0" }}>
              <VideoLabelIcon sx={{ transform: "rotate(180deg)" }} />
            </ToggleButton>
          </ToggleButtonGroup>

          <Divider sx={{ flexGrow: 1 }} />
        </Stack>

        <InternalResourceExplorerTotalViewDataGrid
          groupBy={view.data.grouping}
          data={viewData}
          organisationId={organisationId}
        />
      </MultiTypeChartProvider>
    </Stack>
  );
};
