import { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { useDidMount } from "rooks";
import { ReservationCoverageDrawerRecommendationsGridToolbar } from "./ReservationCoverageDrawerRecommendationsGridToolbar";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { CommitmentsDrawerContent } from "../../../../../common/components/drawer/CommitmentsDrawerContent";
import { reservationsRecommendationsFilteredDataSelector } from "../../../../../../../../store/commitments/selectors/recommendations/reservations/reservationsRecommendationsFilteredDataSelector";
import { reservationsRecommendationsDataLengthSelector } from "../../../../../../../../store/commitments/selectors/recommendations/reservations/reservationsRecommendationsDataLengthSelector";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { initiateReservationCoverageDrawerThunk } from "../../../../../../../../store/commitments/thunks/recommendations/reservations/initiateReservationCoverageDrawerThunk";

import { reservationsRecommendationsGridColumnsDefs } from "../../../content/recomendations/data-grid/reservationsRecommendationsGridColumnsDefs";
import {
  CommitmentsAgGrid,
  CommitmentsToolbar,
} from "../../../../../common/components/CommitmentsAgGrid";

export const ReservationCoverageDrawerRecommendationsSection: FC = () => {
  const dispatch = useAppDispatch();

  const enableReservationRecommendation = useFlag(
    "EnableReservationRecommendation",
  );
  const notFilteredDataLength = useAppSelector(
    reservationsRecommendationsDataLengthSelector,
  );
  const data = useAppSelector(reservationsRecommendationsFilteredDataSelector);

  const targetType = useAppSelector(commitmentsDetailsDrawerTargetTypeSelector);

  const toolbar: CommitmentsToolbar = useCallback(
    (props) => (
      <ReservationCoverageDrawerRecommendationsGridToolbar
        targetType={targetType}
        {...props}
      />
    ),
    [targetType],
  );

  useDidMount(() => {
    dispatch(initiateReservationCoverageDrawerThunk());
  });

  if (!enableReservationRecommendation) {
    return null;
  }

  return (
    <CommitmentsDrawerContent title="Recommendations" icon="recommendation">
      {!notFilteredDataLength ? (
        <Stack alignItems="center" p={3}>
          <Typography
            variant="subtitle1"
            fontWeight="medium"
            color="text.secondary"
          >
            No Recommendations
          </Typography>
        </Stack>
      ) : (
        <CommitmentsAgGrid
          columnDefs={reservationsRecommendationsGridColumnsDefs}
          data={data}
          Toolbar={toolbar}
          localStorageUniqueKey="ReservationCoverageDrawerRecommendationsSection"
          overrideDefaultColDef={defaultColDef}
        />
      )}
    </CommitmentsDrawerContent>
  );
};

const defaultColDef = { filter: false };
