import { FC } from "react";
import { IconButton, Link, Stack, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import { PlayCircleOutlined } from "@mui/icons-material";
import { generateLiveResourcesPath } from "../../../../accounts-group/utils/helpers/generateLiveResourcesPath";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import { ProviderIcon } from "../../../../../common/ProviderIcon";
import { resourceDetailsTargetResourceSelector } from "../../../../../../store/resource-details/selectors/target-resource/resourceDetailsTargetResourceSelector";
import { useAppSelector } from "../../../../../../store/hooks";
import { TypographyWithCopy } from "../../../../../common/TypographyWithCopy";

interface ResourceDetailsHeaderActionsBarProps {
  onClose(): void;
}

export const ResourceDetailsHeaderActionsBar: FC<
  ResourceDetailsHeaderActionsBarProps
> = ({ onClose }) => {
  const resource = useAppSelector(resourceDetailsTargetResourceSelector);

  if (!resource) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="space-between" px={1.5} py={1}>
      <Stack direction="row" spacing={0.5} alignItems="center" maxWidth="80%">
        <ProviderIcon provider={resource?.provider} />

        <TypographyWithCopy
          value={resource.name}
          variant="body1"
          fontWeight="medium"
        />

        {resource.link && (
          <Tooltip
            arrow
            placement="top"
            title={`View in ${getProviderName(resource.provider, { title: true })}`}
          >
            <IconButton
              size="small"
              target="_blank"
              to={resource.link}
              component={RouterLink}
            >
              <CloudOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}

        {!resource.providerMetadata?.k8s && (
          <Tooltip title="View in Live Resources." arrow placement="top">
            <IconButton
              size="small"
              component={Link}
              href={generateLiveResourcesPath({
                provider: resource.provider,
                activeTab: "live-resources",
                accountIds: resource.accountId,
                resourceType: resource.resourceType,
                resourceIds: resource.provider_unique_identifier,
              })}
              target="_blank"
            >
              <PlayCircleOutlined fontSize="small" color="action" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <Stack direction="row" spacing={0.5} alignItems="center"></Stack>

        <IconButton size="small" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
