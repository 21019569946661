import { FC, Fragment } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { yellow } from "@mui/material/colors";
import { EmailIntegrationGracePeriodWorkflowInfo } from "./EmailIntegrationGracePeriodWorkflowInfo";
import { SlackIntegrationGracePeriodMessageDescription } from "./SlackIntegrationGracePeriodMessageDescription";
import { SlackIntegrationGracePeriodMessageWorkflowInfo } from "./SlackIntegrationGracePeriodMessageWorkflowInfo";
import { NestedIntegration } from "../../../../../../../../../../services/cloudchipr.api";

interface GracePeriodNotificationCustomMessageBeforeMessageComponentProps {
  type: NestedIntegration["type"];
}

export const GracePeriodNotificationCustomMessageBeforeMessageComponent: FC<
  GracePeriodNotificationCustomMessageBeforeMessageComponentProps
> = ({ type }) => {
  if (type === "email") {
    return (
      <Fragment>
        <Typography variant="body1">
          Subject:{" "}
          <Typography component="span" fontWeight="medium">
            Actions will be performed on {`{{action date}}`} by{" "}
            {`{{workflow name}}`}
          </Typography>
        </Typography>

        <Divider />
        <EmailIntegrationGracePeriodWorkflowInfo />

        <Typography variant="body1">
          In case you need to keep any of the attached resources, please go to
          the
          <Typography component="span" fontWeight="medium">
            {` {{workflow name}} `}
          </Typography>
          workflow page to review and protect them.
        </Typography>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Stack spacing={1} direction="row">
        <NotificationsIcon fontSize="small" sx={{ color: yellow["700"] }} />
        <Typography variant="body1" fontWeight="medium">
          Actions will be performed after Grace Period expires.
        </Typography>
      </Stack>

      <SlackIntegrationGracePeriodMessageDescription />
      <SlackIntegrationGracePeriodMessageWorkflowInfo />
      <Typography fontStyle="italic" variant="caption">
        *This link is private. Please keep it confidential and do not share.
      </Typography>
    </Fragment>
  );
};
