import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { getCoverageWidgetCoverageInnerHeader } from "./getCoverageWidgetCoverageInnerHeader";
import {
  CoverageGrouping,
  CoverageGroupResponse,
  Dates,
  WidgetCoverageVisualization,
} from "../../../../../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../../../../../common/TypographyWithTooltip";
import { coverageGroupByTitleByGrouping } from "../../../../utils/constants/constants";
import { CommitmentsProgressBar } from "../../../../../../../../../../commtiments/common/components/CommitmentsProgressBar";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { money } from "../../../../../../../../../../../../utils/numeral/money";
import { calculateFromToByDatesData } from "../../../../../../../../../../../common/date-range-picker/utils/helpers/calculateFromToByDatesData";

export const getCoverageTableViewColumnDefs = (
  grouping: CoverageGrouping,
  visualization?: WidgetCoverageVisualization,
  dates?: Dates,
  quarterStartMonth?: number,
): ColDef<CoverageGroupResponse>[] => {
  const { toDate, fromDate } = calculateFromToByDatesData(
    dates ?? {},
    quarterStartMonth,
  );

  const columns: ColDef<CoverageGroupResponse>[] = [
    {
      field: "name",
      minWidth: 100,
      flex: 5,
      unSortIcon: true,
      cellStyle: { paddingLeft: 16 },
      suppressColumnsToolPanel: true,
      headerName: coverageGroupByTitleByGrouping.get(grouping),
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<CoverageGroupResponse>) => (
        <TypographyWithTooltip
          title={
            typeof data?.name === "string"
              ? data?.name
              : (data?.name?.name ?? data?.name?.provider_account_id ?? "")
          }
          variant="caption"
        />
      ),
      valueGetter: ({ data }) =>
        typeof data?.name === "string"
          ? data?.name
          : (data?.name?.name ?? data?.name?.provider_account_id ?? ""),
    },
  ];

  if (visualization?.coverage) {
    columns.push({
      field: "coverage.percentage",
      minWidth: 100,
      flex: 5,
      unSortIcon: true,
      headerComponentParams: {
        innerHeaderComponent: getCoverageWidgetCoverageInnerHeader(
          `Coverage from ${fromDate} to ${toDate}`,
        ),
      },
      headerName: "Coverage",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<CoverageGroupResponse>) => (
        <CommitmentsProgressBar
          value={data?.coverage?.percentage ?? 0}
          overrideColor="#2196F3"
        />
      ),
    });
  }

  if (visualization?.covered) {
    columns.push({
      field: "coverage.amount",
      minWidth: 100,
      flex: 5,
      unSortIcon: true,
      headerComponentParams: {
        innerHeaderComponent: getCoverageWidgetCoverageInnerHeader(
          `Covered spend from ${fromDate} to ${toDate}`,
        ),
      },
      headerName: "Covered",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<CoverageGroupResponse>) => (
        <TypographyWithTooltip
          title={money(data?.coverage?.amount ?? 0)}
          variant="caption"
        />
      ),
    });
  }

  if (visualization?.not_covered) {
    columns.push({
      field: "on_demand.amount",
      minWidth: 100,
      flex: 5,
      unSortIcon: true,
      headerComponentParams: {
        innerHeaderComponent: getCoverageWidgetCoverageInnerHeader(
          `Not Covered from ${fromDate} to ${toDate}`,
        ),
      },
      headerName: "Not Covered",
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<CoverageGroupResponse>) => (
        <TypographyWithTooltip
          title={money(data?.on_demand?.amount ?? 0)}
          variant="caption"
        />
      ),
    });
  }

  columns.push(agGridEmptyColumnToFitEmptySpace);

  return columns;
};
