import { coverageSetupVisualizationSelector } from "./coverageSetupVisualizationSelector";
import { RootState } from "../../../../../store";

export const coverageSetupVisualizationChartBaseSelector = (
  state: RootState,
) => {
  return (
    coverageSetupVisualizationSelector(state)?.chart_base_type ?? "percent"
  );
};
