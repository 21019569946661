import { generateLabelByPeriod } from "./period/generateLabelByPeriod";
import { generateFromToByPeriod } from "./period/generateFromToByPeriod";
import { Dates } from "../../../../../services/cloudchipr.api";
import { PreDefinedRanges } from "../constants/types";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";

interface GenerateDateMetaDataByDatesArgs {
  ranges: PreDefinedRanges;
  dates?: Dates;
}
export const generateDateMetaDataByDates = ({
  ranges,
  dates,
}: GenerateDateMetaDataByDatesArgs) => {
  if (!dates) {
    return {
      label: "",
      tooltip: "",
    };
  }
  const selectedLabel = dates.label;
  const selectedRange = selectedLabel ? ranges[selectedLabel] : null;
  const dateLabelFromPeriod = generateLabelByPeriod(dates);

  let fromDate = "";
  let toDate = "";

  if (selectedRange?.dateFrom && selectedRange?.dateTo) {
    fromDate = selectedRange?.dateFrom;
    toDate = selectedRange?.dateTo;
  } else if (dates.unit && dates.value && dates.type) {
    const { to, from } = generateFromToByPeriod(
      dates.value,
      dates.type,
      dates.unit,
    );

    fromDate = from;
    toDate = to;
  } else {
    fromDate = dates?.from ?? "";
    toDate = dates?.to ?? "";
  }

  const fromToDates = `${formatDate(fromDate, {
    type: "date",
  })} - ${formatDate(toDate, { type: "date" })}`;

  const withTooltip = !dates?.from && !dates?.to && fromDate && toDate;

  return {
    label: selectedRange?.label || dateLabelFromPeriod || fromToDates,
    tooltip: withTooltip ? fromToDates : "",
  };
};
