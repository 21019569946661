import { FC } from "react";
import { FormikHandlers, FormikHelpers } from "formik";
import { KubernetesCommonFilters } from "./KubernetesCommonFilters";
import { resourceTypeWithOperatorAutoCompete } from "./filter-by-type/utils/constants";
import { KubernetesTagFilter } from "./filter-by-type/tag-filter/KubernetesTagFilter";
import {
  FilterItem,
  FilterItemTemplate,
  FilterItemWithType,
  ResourceType,
} from "../../../../../../services/cloudchipr.api";
import { statisticsFilterTypes } from "../../utils/constants/filter-types/statistics-filter-types";
import { getStatisticsFilterMinMax } from "../../filters-selection/utils/helpers/getStatisticsFilterMinMax";
import { StatisticsFilter } from "../../filters-selection/filter-by-type/statistic-filter/StatisticsFilter";
import { durationFilterTypes } from "../../utils/constants/filter-types/duration-filter-types";
import { RelativeTimeFilter } from "../../filters-selection/filter-by-type/RelativeTimeFilter";
import { dateFilterTypes } from "../../utils/constants/filter-types/date-filter-types";
import { DateSelectionFilter } from "../../filters-selection/filter-by-type/DateSelectionFilter";
import { SimpleValueFilter } from "../../filters-selection/filter-by-type/SimpleValueFilter";

interface KubernetesFilterBodyByTypeProps {
  error?: any;
  accountIds?: string[];
  currentFilter: FilterItemWithType;
  resourceType: ResourceType;
  filters: FilterItemTemplate[];
  filterTemplate: FilterItemTemplate;
  onChange: FormikHandlers["handleChange"];
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
}

export const KubernetesFilterBodyByType: FC<
  KubernetesFilterBodyByTypeProps
> = ({
  filters,
  currentFilter,
  filterTemplate,
  resourceType,
  accountIds,
  onChange,
  setFieldValue,
  error,
}) => {
  const filterType = currentFilter.filter.key;

  const filter = currentFilter.filter;
  if (statisticsFilterTypes.has(filterType)) {
    const { min, max } = getStatisticsFilterMinMax(filterTemplate);
    return (
      <StatisticsFilter
        min={min}
        max={max}
        error={error}
        onChange={onChange}
        filter={filter}
        resourceType={resourceType}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
        filterType={filterType}
      />
    );
  }

  if (durationFilterTypes.has(filterType)) {
    return (
      <RelativeTimeFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (dateFilterTypes.has(filterType)) {
    return (
      <DateSelectionFilter
        error={error}
        onChange={onChange}
        filter={filter}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (resourceTypeWithOperatorAutoCompete.has(filterType)) {
    return (
      <KubernetesCommonFilters
        error={error}
        onChange={onChange}
        filter={filter}
        accountIds={accountIds}
        resourceType={resourceType}
        setFieldValue={setFieldValue}
        operators={filterTemplate.operators}
      />
    );
  }

  if (filterType === "tag") {
    return (
      <KubernetesTagFilter
        error={error}
        onChange={onChange}
        setFieldValue={setFieldValue}
        filter={filter}
        operators={filterTemplate.operators}
        accountIds={accountIds}
        resourceType={resourceType}
      />
    );
  }

  return (
    <SimpleValueFilter
      error={error}
      onChange={onChange}
      filter={filter}
      operators={filterTemplate.operators}
      type={filterTemplate.value_type === "number" ? "number" : "text"}
    />
  );
};
