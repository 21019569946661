import { useCallback } from "react";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { useLocalStorageHook } from "../../../../utils/hooks/useLocalStorage.hook";

export const useLiveUsageLastVisitedOrgHook = (provider: ProviderType) => {
  const key = `c8r:liveUsageLastVisitedOrg:${provider}`;

  const { getItem, setItem } = useLocalStorageHook();

  const saveOrgId = useCallback(
    (orgId: string) => {
      setItem(key, orgId);
    },
    [setItem, key],
  );

  const getOrgId = useCallback(() => {
    return getItem(key);
  }, [key, getItem]);

  return {
    getOrgId,
    saveOrgId,
  };
};
