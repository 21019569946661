import { createAsyncThunk } from "@reduxjs/toolkit";
import { askAiThunk } from "./askAiThunk";
import { askAiConversationSelector } from "../selectors/store-selectors/askAiConversationSelector";
import { RootState } from "../../store";
import { setAskAiConversation } from "../askAiSlice";

export const sendAskAiQuestionThunk = createAsyncThunk(
  "ask-ai/sendAskAiQuestion",
  async (question: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const conversation = askAiConversationSelector(state);

    dispatch(
      setAskAiConversation([
        ...conversation,
        {
          type: "question",
          text: question,
        },
      ]),
    );
    dispatch(askAiThunk(question));
  },
);
