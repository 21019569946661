import { FC, MouseEvent as ReactMouseEvent } from "react";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Stack } from "@mui/material";

interface AskAIDragHandlerProps {
  onMouseDown(event: ReactMouseEvent): void;
}

export const AskAIDragHandler: FC<AskAIDragHandlerProps> = ({
  onMouseDown,
}) => {
  return (
    <Stack
      left={0}
      width={12}
      position="sticky"
      alignItems="center"
      justifyContent="center"
      onMouseDown={onMouseDown}
      sx={{ cursor: "ew-resize" }}
    >
      <DragHandleIcon sx={{ transform: "rotate(90deg)" }} fontSize="small" />
    </Stack>
  );
};
