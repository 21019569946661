import { FC, useCallback } from "react";
import { Box, Button } from "@mui/material";

export type UtilizationOrReservation = "reservation" | "utilization";

interface ResourceDetailsGeneralContentK8SViewSelectProps {
  view: UtilizationOrReservation;
  setView(v: UtilizationOrReservation): void;
}

export const ResourceDetailsGeneralContentK8SViewSelect: FC<
  ResourceDetailsGeneralContentK8SViewSelectProps
> = ({ view, setView }) => {
  const getButtonClickHandler = useCallback(
    (newView: typeof view) => () => {
      setView(newView);
    },
    [setView],
  );

  return (
    <Box
      bgcolor="grey.200"
      border={2}
      borderColor="grey.200"
      width="fit-content"
      borderRadius={1}
      overflow="hidden"
    >
      <Button
        size="small"
        sx={{
          px: 4,
          textTransform: "none",
          bgcolor: view === "reservation" ? "white" : undefined,
        }}
        onClick={getButtonClickHandler("reservation")}
      >
        Reservation
      </Button>
      <Button
        size="small"
        sx={{
          px: 4,
          textTransform: "none",
          bgcolor: view === "utilization" ? "white" : undefined,
        }}
        onClick={getButtonClickHandler("utilization")}
      >
        Utilization
      </Button>
    </Box>
  );
};
