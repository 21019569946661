import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { commitmentsDatesSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { generateFromToFromDates } from "../../../../utilization-and-coverage/utils/helpers/generateFromToFromDates";
import { CommitmentsPageHeaderMetadataCard } from "../../../../common/components/CommitmentsPageHeaderMetadataCard";
import { money } from "../../../../../../../utils/numeral/money";
import { azureReservationsNetSavingsSelector } from "../../../../../../../store/commitments/selectors/azure/reservations/data/totals/azureReservationsNetSavingsSelector";
import { azureReservationsRealCostSelector } from "../../../../../../../store/commitments/selectors/azure/reservations/data/totals/azureReservationsRealCostSelector";
import { azureReservationsForGranularLoadingSelector } from "../../../../../../../store/commitments/selectors/azure/reservations/data/loading/azureReservationsForGranularLoadingSelector";
import { azureReservationsGranularDataUncoveredUsageSelector } from "../../../../../../../store/commitments/selectors/azure/reservations/data/granular/totals/azureReservationsGranularDataUncoveredUsageSelector";

export const AzureReservationsMetadata: FC = () => {
  const netSavings = useAppSelector(azureReservationsNetSavingsSelector);
  const realCost = useAppSelector(azureReservationsRealCostSelector);
  const loading = useAppSelector(azureReservationsForGranularLoadingSelector);
  const uncoveredUsage = useAppSelector(
    azureReservationsGranularDataUncoveredUsageSelector,
  );

  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dates = useAppSelector(commitmentsDatesSelector);
  const datesFromTo = useMemo(() => {
    return generateFromToFromDates(dates, quarterStartMonth);
  }, [dates, quarterStartMonth]);

  return (
    <Stack
      gap={1}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <Stack flex={1}>
        <CommitmentsPageHeaderMetadataCard
          primary="Real Cost"
          loading={loading}
          value={money(realCost)}
          tooltip={`Total costs paid for all resources eligible for Reserved Instances including RI fees.`}
        />
      </Stack>
      <Stack flex={1}>
        <CommitmentsPageHeaderMetadataCard
          primary="Not Covered (On-Demand)"
          loading={loading}
          value={money(uncoveredUsage)}
          tooltip={`Eligible On-Demand spend not covered by Reserved Instances for date range ${datesFromTo}.`}
        />
      </Stack>
      <Stack flex={1}>
        <CommitmentsPageHeaderMetadataCard
          primary="Net Savings"
          loading={loading}
          value={money(netSavings)}
          tooltip={`Total net savings from commitments for the date range ${datesFromTo}. Calculated by comparing the usage cost covered by Reserved Instances to the public On-Demand cost.`}
        />
      </Stack>
    </Stack>
  );
};
