import { resourceExplorerInternalPossibleGroupingsFormattedDataSelector } from "./resourceExplorerInternalPossibleGroupingsFormattedDataSelector";
import { RootState } from "../../../store";
import { ResourceExplorerGrouping } from "../../../../services/cloudchipr.api";

export const resourceExplorerInternalPossibleGroupingKeysSelector = (
  state: RootState,
  organisationId: string,
): ResourceExplorerGrouping[] | undefined => {
  const formattedData =
    resourceExplorerInternalPossibleGroupingsFormattedDataSelector(
      state,
      organisationId,
    );
  if (formattedData?.groupingProviderMap) {
    return Object.keys(
      formattedData.groupingProviderMap,
    ) as ResourceExplorerGrouping[];
  }
};
