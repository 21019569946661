import { FC, useCallback, useMemo } from "react";
import { WidgetWithREViewDisabledAction } from "./disabled-actions/WidgetWithREViewDisabledAction";
import { WidgetOptionCard } from "../../WidgetOptionCard";
import Cover from "../../../../utils/assets/images/widget-covers/cost_breakdown.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { isBillingAvailableSelector } from "../../../../../../../store/common/selectors/billing-status/isBillingAvailableSelector";
import { defaultResourceExplorerViewIdSelector } from "../../../../../../../store/resource-explorer/selectors/all-resource-explorers/defaultResourceExplorerViewIdSelector";
import { initiateBillingExplorerWidgetThunk } from "../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/initiateBillingExplorerWidgetThunk";

export const BillingExplorerWidgetCard: FC = () => {
  const dispatch = useAppDispatch();

  const isResourceExplorerAvailable = useAppSelector(
    isBillingAvailableSelector,
  );

  const defaultViewId = useAppSelector(defaultResourceExplorerViewIdSelector);

  const clickHandler = useCallback(() => {
    dispatch(initiateBillingExplorerWidgetThunk());
  }, [dispatch]);

  const disabledAction = useMemo(() => {
    if (!isResourceExplorerAvailable) {
      return (
        <WidgetWithREViewDisabledAction
          viewId={defaultViewId}
          available={isResourceExplorerAvailable}
        />
      );
    }
  }, [isResourceExplorerAvailable, defaultViewId]);

  return (
    <WidgetOptionCard
      title="Billing Explorer"
      description="View costs by Accounts, Services, Regions, Product Families, and Cloud Providers."
      onClick={clickHandler}
      disabledAction={disabledAction}
    >
      <img src={Cover} alt="ConstAndUsageCard" />
    </WidgetOptionCard>
  );
};
