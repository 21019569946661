import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../store/hooks";
import { azureSavingsPlansGranularRealCostSelector } from "../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/granular/totals/azureSavingsPlansGranularRealCostSelector";
import { azureSavingsPlansForGranularLoadingSelector } from "../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/loading/azureSavingsPlansForGranularLoadingSelector";
import { azureSavingsPlansGranularDataUncoveredUsageSelector } from "../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/granular/totals/azureSavingsPlansGranularDataUncoveredUsageSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { commitmentsDatesSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { generateFromToFromDates } from "../../../../utilization-and-coverage/utils/helpers/generateFromToFromDates";
import { CommitmentsPageHeaderMetadataCard } from "../../../../common/components/CommitmentsPageHeaderMetadataCard";
import { money } from "../../../../../../../utils/numeral/money";
import { azureSavingsPlansNetSavingsSelector } from "../../../../../../../store/commitments/selectors/azure/coverage-and-utilization/data/totals/azureSavingsPlansNetSavingsSelector";

export const AzureUtilizationAndCoverageMetadata: FC = () => {
  const netSavings = useAppSelector(azureSavingsPlansNetSavingsSelector);
  const realCost = useAppSelector(azureSavingsPlansGranularRealCostSelector);
  const loading = useAppSelector(azureSavingsPlansForGranularLoadingSelector);
  const uncoveredUsage = useAppSelector(
    azureSavingsPlansGranularDataUncoveredUsageSelector,
  );

  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dates = useAppSelector(commitmentsDatesSelector);
  const datesFromTo = useMemo(() => {
    return generateFromToFromDates(dates, quarterStartMonth);
  }, [dates, quarterStartMonth]);

  return (
    <Stack
      gap={1}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <Stack flex={1}>
        <CommitmentsPageHeaderMetadataCard
          primary="Real Cost"
          loading={loading}
          value={money(realCost)}
          tooltip={`Total costs paid for all resources eligible for Savings Plans including Savings Plan Recurring Fees.`}
        />
      </Stack>
      <Stack flex={1}>
        <CommitmentsPageHeaderMetadataCard
          primary="Not Covered (On-Demand)"
          loading={loading}
          value={money(uncoveredUsage)}
          tooltip={`Eligible On-Demand spend not covered by Savings Plans for date range ${datesFromTo}.`}
        />
      </Stack>

      <Stack flex={1}>
        <CommitmentsPageHeaderMetadataCard
          primary="Net Savings"
          loading={loading}
          value={money(netSavings)}
          tooltip={`Total net savings from commitments for the date range ${datesFromTo}. Calculated by comparing the usage cost covered by Savings Plans to the public On-Demand cost.`}
        />
      </Stack>
    </Stack>
  );
};
