import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { accountsByProviderSelector } from "./accountsByProviderSelector";

export const orgsByProviderSelector = createDraftSafeSelector(
  [accountsByProviderSelector],
  (accountsByOrg) => {
    return Object.entries(accountsByOrg || {}).map(([orgId, accounts]) => ({
      orgId,
      accounts: accounts.filter((account) => account.status === "connected"),
    }));
  },
);
