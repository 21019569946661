import { FC, MouseEvent } from "react";
import { Chip } from "@mui/material";
import { DPYear, useContextYearsPropGetters } from "@rehookify/datepicker";

interface DateRangeCalendarYearProps {
  dpYear: DPYear;
  onSelect(): void;
  setOffsetDate(date?: Date): void;
}

export const DateRangeCalendarYear: FC<DateRangeCalendarYearProps> = ({
  dpYear,
  onSelect,
  setOffsetDate,
}) => {
  const { yearButton } = useContextYearsPropGetters();

  const buttonProps = yearButton(dpYear, {
    onClick(_?: MouseEvent<HTMLElement>, date?: Date) {
      onSelect();
      setOffsetDate(date);
    },
  });

  return (
    <Chip
      label={dpYear.year}
      size="small"
      color="primary"
      {...buttonProps}
      sx={{
        border: "none",
        color: dpYear.selected ? "primary" : "text.secondary",
      }}
      variant={dpYear.selected ? "filled" : "outlined"}
    />
  );
};
