import { ResourceExplorerFilterProvider } from "../../../../../../services/cloudchipr.api";
import { GroupingProviderMap } from "../../../../../../store/resource-explorer/selectors/possible-groupings/resourceExplorerPossibleGroupingsFormattedDataSelector";

export const getResourceExplorerGroupingProviders = (
  providers: ResourceExplorerFilterProvider[],
  groupingProviderMap: GroupingProviderMap,
) => {
  const accountProvidersLength = groupingProviderMap?.["account"]?.length ?? 0;

  if (
    providers.length < accountProvidersLength &&
    !providers.includes("global")
  ) {
    return providers;
  }
  return [];
};
