import { RootState } from "../../../../../store";
import { DashboardWidgetCoverage } from "../../../../../../services/cloudchipr.api";
import { widgetByIdSelector } from "../../common/widgetByIdSelector";

export const coverageWidgetByIdSelector = (
  state: RootState,
  widgetId?: string,
): DashboardWidgetCoverage | undefined => {
  if (!widgetId) {
    return;
  }

  return widgetByIdSelector(state, widgetId) as DashboardWidgetCoverage;
};
