import { billingSummaryWidgetByIdSelector } from "./billingSummaryWidgetByIdSelector";
import { billingSummaryWidgetNotFoundSelector } from "./billingSummaryWidgetNotFoundSelector";
import { billingSummaryWidgetBillingExplorerUrlSelector } from "./billingSummaryWidgetBillingExplorerUrlSelector";
import { RootState } from "../../../../store";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { WidgetTitleProps } from "../../../../../components/pages/dashboard/utils/types/types";

export const billingSummaryWidgetTitlePropsSelector = (
  state: RootState,
  widgetId: string,
): WidgetTitleProps | null => {
  const widget = billingSummaryWidgetByIdSelector(state, widgetId);

  if (!widget) {
    return null;
  }

  const viewName = resourceExplorerViewNameByViewIdSelector(
    state,
    widget.view_id,
  );

  const viewNotFound = billingSummaryWidgetNotFoundSelector(state, widgetId);
  const url = billingSummaryWidgetBillingExplorerUrlSelector(state, widgetId);

  return {
    widgetName: widget.name,
    dates: widget.dates,
    sourceData: {
      title: viewNotFound || !viewName ? "" : viewName,
      url,
    },
  };
};
