import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { LiveUsageNavigationResourceType } from "../../../live-usage-mgmt/components/navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { useAppSelector } from "../../../../../store/hooks";
import { LiveResourceFilters } from "../../../../common/resource-filters/LiveResourceFilters";
import { kubernetesLiveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector } from "../../../../../store/kubernetes-live-usage-mgmt/selectors/kubernetesLiveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector";
import { kubernetesLiveUsageMgmtAppliedAllFiltersCountSelector } from "../../../../../store/kubernetes-live-usage-mgmt/selectors/store-selectors/kubernetesLiveUsageMgmtAppliedAllFiltersCountSelector";
import { KubernetesLiveResourceFiltersContent } from "../resource-filters/KubernetesLiveResourceFiltersContent";
import { LiveResourceFiltersTrigger } from "../../../../common/resource-filters/utils/types/types";

interface KubernetesLiveUsageResourceFilterProps {
  resourceType: LiveUsageNavigationResourceType;
}

export const KubernetesLiveUsageResourceFilter: FC<
  KubernetesLiveUsageResourceFilterProps
> = ({ resourceType }) => {
  const filtersCount = useAppSelector(
    kubernetesLiveUsageMgmtAppliedAllFiltersCountSelector,
  );
  const resourceTypeFiltersCountMap = useAppSelector(
    kubernetesLiveUsageMgmtAppliedFiltersCountByResourceTypeMapSelector,
  );

  return (
    <LiveResourceFilters
      singleResource
      provider="kubernetes"
      filtersCount={filtersCount}
      popoverStaticHeight="unset"
      resourceType={resourceType}
      TriggerComponent={ResourceFiltersTextViewSourceButton}
      resourceTypeFiltersCountMap={resourceTypeFiltersCountMap}
      FiltersContent={KubernetesLiveResourceFiltersContent}
    />
  );
};

const ResourceFiltersTextViewSourceButton: LiveResourceFiltersTrigger = ({
  onClick,
}) => {
  return (
    <Stack
      direction="row"
      spacing={0.5}
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      <FilterAltIcon fontSize="small" color="primary" />
      <Typography variant="body2" color="primary" fontWeight="medium">
        Filtered Results:
      </Typography>
    </Stack>
  );
};
