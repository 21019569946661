import { CommitmentDateGranularDataPoint } from "../../../../../../../services/cloudchipr.api";
import { azureReservationsDataForGranularSelector } from "../azureReservationsDataForGranularSelector";
import { RootState } from "../../../../../../store";

export const azureReservationsGranularDataSelector = (
  state: RootState,
): CommitmentDateGranularDataPoint[] | undefined => {
  const data = azureReservationsDataForGranularSelector(state);

  return data?.date_granular_data?.data;
};
