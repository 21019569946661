import { FC } from "react";
import { ToolbarHeadingCommandSelector } from "./heading/ToolbarHeadingCommandSelector";
import { ToolbarTextAlignCommandSelector } from "./textAlign/ToolbarTextAlignCommandSelector";
import { ToolbarLinkCommandButton } from "./ToolbarLinkCommandButton";
import { ToolbarColorCommandSelector } from "./ToolbarColorCommandSelector";
import { ToolbarCommandButton } from "./ToolbarCommandButton";
import { commandIconsByCommandName } from "../../utils/constants/icons";
import { simpleCommandByCommandName } from "../../utils/constants/constants";
import { CommandName } from "../../utils/types/types";

interface ToolbarCommandByCommandNameProps {
  commandName: CommandName;
}

export const ToolbarCommandByCommandName: FC<
  ToolbarCommandByCommandNameProps
> = ({ commandName }) => {
  const icon = commandIconsByCommandName.get(commandName);
  const command = simpleCommandByCommandName.get(commandName);

  switch (commandName) {
    case "heading":
      return <ToolbarHeadingCommandSelector />;
    case "textAlign":
      return <ToolbarTextAlignCommandSelector />;
    case "link":
      return <ToolbarLinkCommandButton />;
    case "color":
      return <ToolbarColorCommandSelector />;
    default:
      if (!command || !icon) {
        return null;
      }

      return (
        <ToolbarCommandButton
          disableWhenActive
          commandName={commandName}
          command={command}
          Icon={icon}
        />
      );
  }
};
