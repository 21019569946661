import { FC, useCallback } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { useDispatch } from "react-redux";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import {
  setReportsData,
  toggleReportsOpenDrawer,
} from "../../../../../../../store/reports/reportsSlice";
import { ReportsTableData } from "../../../../../../../store/reports/utils/types/types";
import { formatNotificationsFromSlackToHtml } from "../../../../../../utils/helpers/slack-formatters/formatNotificationsFromSlackToHtml";

export const ReportsNameCell: FC<CustomCellRendererProps<ReportsTableData>> = ({
  data,
}) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    if (!data) {
      return;
    }

    dispatch(
      setReportsData({
        ...data,
        notifications: formatNotificationsFromSlackToHtml(data.notifications),
      }),
    );
    dispatch(toggleReportsOpenDrawer());
  }, [dispatch, data]);

  return (
    <TypographyWithTooltip
      fontWeight="regular"
      color="primary"
      onClick={handleClick}
      sx={{ textDecoration: "underline", cursor: "pointer" }}
      variant="body2"
      title={data?.name}
    />
  );
};
