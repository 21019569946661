import { FC, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useCurrentEditor } from "@tiptap/react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { sendTaskCommentThunk } from "../../../../../../../../../store/task-management/thunks/activities/comments/sendTaskCommentThunk";
import { sendTaskCommentLoadingSelector } from "../../../../../../../../../store/task-management/selectors/activiities/comments/sendTaskCommentLoadingSelector";

interface CommentEditorActionsProps {
  taskId: string;
  comment: string;
  commentId?: string;
  onCancel?(): void;
  onSend?(): void;
}

export const CommentEditorActions: FC<CommentEditorActionsProps> = ({
  taskId,
  comment,
  commentId,
  onCancel,
  onSend,
}) => {
  const dispatch = useAppDispatch();
  const { editor } = useCurrentEditor();

  const loading = useAppSelector(sendTaskCommentLoadingSelector);

  const sendHandler = useCallback(() => {
    onSend?.();
    dispatch(sendTaskCommentThunk({ taskId, comment, commentId }));
  }, [dispatch, taskId, comment, commentId, onSend]);

  return (
    <Stack direction="row" spacing={1}>
      {onCancel && (
        <Button
          variant="text"
          onClick={onCancel}
          color="tertiary"
          size="small"
          sx={buttonStyles}
        >
          Cancel
        </Button>
      )}
      <LoadingButton
        onClick={sendHandler}
        variant="contained"
        size="small"
        sx={buttonStyles}
        disabled={!!editor?.isEmpty}
        loading={loading}
      >
        {commentId ? "Save" : "Send"}
      </LoadingButton>
    </Stack>
  );
};

const buttonStyles = { textTransform: "none" };
