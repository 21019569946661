import { grey } from "@mui/material/colors";
import { mentionTagClassName } from "./constants";

const mentionStyles = {
  [`& .${mentionTagClassName}`]: {
    p: 0.25,
    color: "primary.main",
    borderRadius: "0.4rem",
    boxDecorationBreak: "clone",
    backgroundColor: "primary.light",
  },
};

const tipTapStyles = {
  "& .tiptap": {
    p: 1,
    pt: 0,
    outline: "none",
    height: "100%",
    overflow: "auto",

    "& p.is-editor-empty:first-of-type::before": {
      height: 0,
      float: "left",
      color: grey[500],
      pointerEvents: "none",
      content: "attr(data-placeholder)",
    },
  },
};

const listsStyles = {
  ul: { py: 0, px: 1, m: 1 },
  ol: { py: 0, px: 1, m: 1 },
  li: { my: 0.25, mx: 0 },
};

const codeStyles = {
  code: {
    backgroundColor: "grey.300",
    color: "black",
    borderRadius: "0.4rem",
    fontSize: 14,
    p: 0.25,
  },

  "pre code": {
    background: "none",
    color: "inherit",
    fontSize: 14,
    padding: "0",
  },
};

export const editorDefaultStyles = {
  border: 1,
  overflow: "auto",
  borderRadius: 2,
  minHeight: 300,
  borderColor: "grey.300",
  height: "100%",
  fontSize: 14,

  pre: {
    my: 1,
    px: 1,
    py: 0.75,
    color: "white",
    borderRadius: 1,
    background: "black",
    fontFamily: "'JetBrainsMono', monospace",
  },

  blockquote: {
    my: 1,
    mx: 0,
    pl: 1,
    borderLeft: "3px solid grey",
  },

  hr: {
    my: 2,
    mx: 0,
    border: "none",
    borderTop: "1px solid grey",
  },

  ...tipTapStyles,
  ...mentionStyles,
  ...listsStyles,
  ...codeStyles,
};
