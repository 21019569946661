import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { FC, useCallback } from "react";
import { Box } from "@mui/material";
import { Responsive, WidthProvider, Layout, Layouts } from "react-grid-layout";
import { useFlag } from "@unleash/proxy-client-react";
import { EmptyDashboard } from "./corner-components/EmptyDashboard";
import { DashboardBillingSummaryWidget } from "./widgets/billing-summary/DashboardBillingSummaryWidget";
import { DashboardLayoutAddingWidgetCardButton } from "./adding-widget/DashboardLayoutAddingWidgetCardButton";
import { WidgetWrapper } from "./WidgetWrapper";
import { CostAnomaliesWidget } from "./widgets/cost-anomalies/CostAnomaliesWidget";
import { DashboardBillingExplorerWidget } from "./widgets/billing-explorer-widget/DashboardBillingExplorerWidget";
import { DashboardCommitmentUtilizationWidget } from "./widgets/commitment-utlization/DashboardCommitmentUtilizationWidget";
import { DashboardTextWidget } from "./widgets/text-widget/DashboardTextWidget";
import { DashboardCoverageWidget } from "./widgets/coverage/DashboardCoverageWidget";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { WidgetType } from "../../../../services/cloudchipr.api";
import { widgetDefaultSize } from "../utils/constants/stylings";
import { currentDashboardLayoutSelector } from "../../../../store/dashboards/selectors/dashboard/currentDashboardLayoutSelector";
import { updateDashboardByIdLayoutThunk } from "../../../../store/dashboards/thunks/dashboard/updateDashboardByIdLayoutThunk";
import {
  draggableHandleClassnames,
  reactGridLayoutBreakpoints,
  reactGridLayoutCols,
  reactGridLayoutMargin,
  reactGridLayoutStyles,
} from "../utils/constants/reactGridLayout";
import { dashboardByIdDataSelector } from "../../../../store/dashboards/selectors/dashboard/dashboardByIdDataSelector";
import { dashboardByIdLoadingSelector } from "../../../../store/dashboards/selectors/dashboard/loadings/dashboardByIdLoadingSelector";
import { dashboardHierarchyVisibilitiesLoadingSelector } from "../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/dashboardHierarchyVisibilitiesLoadingSelector";
import { currentDashboardProtectedSelector } from "../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/currentDashboardProtectedSelector";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface DashboardLayoutProps {
  dashboardId: string;
}

export const DashboardLayout: FC<DashboardLayoutProps> = ({ dashboardId }) => {
  const dispatch = useAppDispatch();
  const isProtected = useAppSelector(currentDashboardProtectedSelector);
  const dashboard = useAppSelector((state) =>
    dashboardByIdDataSelector(state, dashboardId),
  );
  const loading = useAppSelector((state) =>
    dashboardByIdLoadingSelector(state, dashboardId),
  );
  const widgetsData = dashboard?.widgets;
  const enableCoverageWidget = useFlag("EnableCoverageWidget");

  const layouts = useAppSelector(currentDashboardLayoutSelector);
  const dashboardLoading = useAppSelector(
    dashboardHierarchyVisibilitiesLoadingSelector,
  );

  const handleLayoutChange = useCallback(
    (_: Layout[], newLayout: Layouts) => {
      if (dashboardId && !isProtected) {
        dispatch(updateDashboardByIdLayoutThunk({ dashboardId, newLayout }));
      }
    },
    [dispatch, dashboardId, isProtected],
  );

  if (!widgetsData?.length && !loading) {
    return <EmptyDashboard />;
  }

  if (!layouts || dashboardLoading) {
    return null;
  }

  return (
    <Box pb={3} sx={reactGridLayoutStyles}>
      <ResponsiveGridLayout
        isDraggable={!isProtected}
        isResizable={!isProtected}
        layouts={layouts}
        className="layout"
        cols={reactGridLayoutCols}
        margin={reactGridLayoutMargin}
        onLayoutChange={handleLayoutChange}
        rowHeight={widgetDefaultSize}
        breakpoints={reactGridLayoutBreakpoints}
        draggableHandle={draggableHandleClassnames.wrapper}
      >
        {widgetsData?.map((widget) => {
          const Component = widgetsComponents[widget.type];

          if (!Component) {
            return null;
          }

          if (widget.type === "coverage" && !enableCoverageWidget) {
            return null;
          }

          return (
            <WidgetWrapper
              key={widget.id}
              widgetId={widget.id}
              isProtected={isProtected}
            >
              <Component widgetId={widget.id} />
            </WidgetWrapper>
          );
        })}
      </ResponsiveGridLayout>

      <DashboardLayoutAddingWidgetCardButton />
    </Box>
  );
};

const widgetsComponents: Partial<Record<WidgetType, FC<any>>> = {
  cost_breakdown: DashboardBillingExplorerWidget,
  cost_and_usage_summary: DashboardBillingSummaryWidget,
  largest_cost_changes: CostAnomaliesWidget,
  commitments_utilization: DashboardCommitmentUtilizationWidget,
  text: DashboardTextWidget,
  coverage: DashboardCoverageWidget,
};
