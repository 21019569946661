import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

export const getKubernetesClustersThunk = createAsyncThunk(
  "accounts/getKubernetesClusters",
  async (_, { dispatch }) => {
    await dispatch(
      cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentKubernetesClusters.initiate(
        undefined,
        { forceRefetch: true },
      ),
    );
  },
);
