import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { createTaskViewFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentTaskViews.select(
    createTaskViewFixedCacheKey,
  );

export const createTasksViewLoadingSelector = (state: RootState) => {
  return endpoint(state)?.isLoading;
};
