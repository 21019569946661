import { resourceExplorerPossibleGroupingsFormattedDataSelector } from "./resourceExplorerPossibleGroupingsFormattedDataSelector";
import { RootState } from "../../../store";
import {
  ResourceExplorerFilterProvider,
  ResourceExplorerGroupingNullable,
} from "../../../../services/cloudchipr.api";
import { resourceExplorerHasNoParentKey } from "../../../../components/pages/common/resource-explorer-grouping-selector-v2/utils/constants/constants";
import { getResourceExplorerGroupingProviders } from "../../../../components/pages/common/resource-explorer-grouping-selector-v2/utils/helpers/getResourceExplorerGroupingProviders";

export const resourceExplorerGroupingsWithNoParentProvidersByGroupingSelector =
  (
    state: RootState,
    grouping: ResourceExplorerGroupingNullable,
  ): ResourceExplorerFilterProvider[] | undefined => {
    if (!grouping) {
      return;
    }
    const formattedData =
      resourceExplorerPossibleGroupingsFormattedDataSelector(state);

    if (!formattedData) {
      return;
    }

    const providers =
      formattedData.groupingParentLabel[grouping] ===
      resourceExplorerHasNoParentKey
        ? formattedData?.groupingProviderMap?.[grouping]
        : [];

    if (formattedData?.groupingProviderMap?.[grouping]) {
      return getResourceExplorerGroupingProviders(
        providers,
        formattedData?.groupingProviderMap,
      );
    }
  };
