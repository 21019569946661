import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { NestedNavItem } from "../../app-nav-components/nested-nav-item/NestedNavItem";
import AWSSvgIcon from "../../../../../assets/images/logos/providers/AWSSvgIcon";
import GCPSvgIcon from "../../../../../assets/images/logos/providers/GCPSvgIcon";
import AzureSvgIcon from "../../../../../assets/images/logos/providers/AzureSvgIcon";
import { useAppNavOverlayContext } from "../../../utils/useAppNavOverlay.context";

interface CommitmentsNavigationItemProps {
  provider: ProviderType;
  org: string;
  orgId: string;
}

export const CommitmentsNavigationItem: FC<CommitmentsNavigationItemProps> = ({
  provider,
  org,
  orgId,
}) => {
  const navigate = useNavigate();
  const { onClose } = useAppNavOverlayContext();

  const path = `/commitments/${provider}/${orgId}`;

  const selected = location.pathname.startsWith(path);

  const buttonClickHandler = useCallback(() => {
    navigate(path);
    onClose?.();
  }, [navigate, onClose, path]);

  return (
    <NestedNavItem
      nested
      onButtonClick={buttonClickHandler}
      key={path}
      primary={org}
      Icon={providerIconsMap.get(provider)}
      selected={selected}
    />
  );
};

const providerIconsMap = new Map<ProviderType, any>([
  ["aws", AWSSvgIcon],
  ["gcp", GCPSvgIcon],
  ["azure", AzureSvgIcon],
]);
