import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SavingsOpportunitiesSliceInitialState = {
  views: { currentViewId: string };
  selection: {
    selectedRowsIds: string[];
  };
};

const initialState: SavingsOpportunitiesSliceInitialState = {
  views: { currentViewId: "" },
  selection: {
    selectedRowsIds: [],
  },
};

export const savingsOpportunitiesSlice = createSlice({
  name: "savingsOpportunities",
  initialState,
  reducers: {
    setSavingsOpportunitiesCurrentViewId: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.views.currentViewId = action.payload;
    },
    setSavingsOpportunitiesSelectedRowsIds: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.selection.selectedRowsIds = action.payload;
    },
  },
});

export const {
  setSavingsOpportunitiesCurrentViewId,
  setSavingsOpportunitiesSelectedRowsIds,
} = savingsOpportunitiesSlice.actions;

export default savingsOpportunitiesSlice.reducer;
