import { RootState } from "../../../../../../store";
import { AzureCommitmentUtilizationMetadata } from "../../../../../../../services/cloudchipr.api";
import { azureReservationDetailsDataSelector } from "../data/azureReservationDetailsDataSelector";

export const azureReservationDetailsMetaDataSelector = (
  state: RootState,
): AzureCommitmentUtilizationMetadata | undefined => {
  return azureReservationDetailsDataSelector(state)
    ?.metadata as AzureCommitmentUtilizationMetadata;
};
