import { FC } from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { NavigationItemsVisibilityType } from "../../../../store/navigation/utils/types";
import { TreeItems } from "../sortable-tree/utils/types";
import { ReFolderV2 } from "../../../../services/cloudchipr.api";
import { HierarchyItemMoveActionItemProps } from "../../utils/types/hierarchy";

interface HierarchyItemMoveActionSectionProps {
  hideTitle?: boolean;
  inFolder: boolean;
  currentItemName: string;
  differentVisibilities: boolean;
  onMove(folderId?: string): void;
  visibility: NavigationItemsVisibilityType;
  HierarchyItemMoveActionItem: FC<HierarchyItemMoveActionItemProps>;
  folders?: ReFolderV2[] | TreeItems;
}

export const HierarchyItemMoveActionSection: FC<
  HierarchyItemMoveActionSectionProps
> = ({
  onMove,
  folders,
  inFolder,
  hideTitle,
  visibility,
  currentItemName,
  differentVisibilities,
  HierarchyItemMoveActionItem,
}) => {
  return (
    <Box>
      {!hideTitle && (
        <Typography variant="subtitle2" color="text.secondary" pl={1} py={0.5}>
          {visibility === "visible_to_everyone"
            ? "Visible to everyone"
            : "Visible only to me"}
        </Typography>
      )}

      {(inFolder || differentVisibilities) && (
        <HierarchyItemMoveActionItem
          root
          name="Root"
          onClick={onMove}
          visibility={visibility}
          paddingLeft={hideTitle ? 1 : 1.5}
          currentItemName={currentItemName}
          differentVisibilities={differentVisibilities}
        />
      )}

      {folders?.map((folder) => {
        return (
          <HierarchyItemMoveActionItem
            id={folder.id}
            key={folder.id}
            onClick={onMove}
            name={folder.name}
            visibility={visibility}
            paddingLeft={hideTitle ? 2.5 : 3}
            currentItemName={currentItemName}
            differentVisibilities={differentVisibilities}
          />
        );
      })}

      {!(inFolder || differentVisibilities) && !folders?.length && (
        <Typography variant="caption" pl={3}>
          No Folders
        </Typography>
      )}
    </Box>
  );
};
