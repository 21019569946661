import { v4 as uuid } from "uuid";

import {
  AzureCommitmentCoverageData,
  ReservationCoverageData,
  SavingsPlanCoverageData,
} from "../../../../services/cloudchipr.api";

export const addIdInCoverageData = <
  T extends
    | SavingsPlanCoverageData
    | ReservationCoverageData
    | AzureCommitmentCoverageData,
>(
  data?: T[],
): (T & { id: string })[] | undefined => {
  return data?.map((item) => ({
    ...item,
    id: uuid(),
  }));
};
