import { FC, useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { ResourceType } from "../../../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../store/hooks";
import { liveUsageMgmtResourceByIdAndTypeSelector } from "../../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceByIdAndTypeSelector";
import { filterOutResourcesThunk } from "../../../../../../../../../../../../store/live-usage-mgmt/thunks/filterOutResourcesThunk";
import { getFirstParentInHierarchyResourceType } from "../../../../../../../../../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";

interface FilterOutResourceActionItemProps {
  resourceId: string;
  resourceType: ResourceType;
  onClose(): void;
}

export const FilterOutResourceActionItem: FC<
  FilterOutResourceActionItemProps
> = ({ resourceId, resourceType, onClose }) => {
  const dispatch = useAppDispatch();
  const resource = useAppSelector((state) =>
    liveUsageMgmtResourceByIdAndTypeSelector(state, resourceType, resourceId),
  );
  const parentResourceType =
    getFirstParentInHierarchyResourceType(resourceType);

  const clickHandler = useCallback(() => {
    if (!resource) {
      return;
    }

    dispatch(
      filterOutResourcesThunk({
        resourceType: parentResourceType ?? resourceType,
        selectedResources: [resource],
      }),
    );
    onClose();
  }, [dispatch, resource, parentResourceType, resourceType, onClose]);

  return (
    <MenuItem dense onClick={clickHandler}>
      <ListItemIcon>
        <FilterAltOffIcon />
      </ListItemIcon>

      <ListItemText>Filter Out</ListItemText>
    </MenuItem>
  );
};
