import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import { blue } from "@mui/material/colors";

const KubernetesDeploymentSvgIcon = ({
  primary,
}: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? blue["700"] : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3783 1.64656C11.5446 1.55902 11.7372 1.51087 11.9298 1.50211C12.1706 1.48898 12.4113 1.53713 12.6258 1.64656L20.1504 5.23157C20.5444 5.42417 20.8333 5.78311 20.9296 6.20333L22.7899 14.2794C22.8862 14.6997 22.7768 15.1461 22.5141 15.4788L17.3052 21.9616C17.0294 22.2943 16.6092 22.5 16.1802 22.5H7.82831C7.39496 22.5 6.97473 22.2943 6.69896 21.9616L1.48998 15.4919C1.4681 15.4438 1.44183 15.4088 1.40681 15.3738C1.20108 15.0498 1.13104 14.6515 1.21421 14.2794L3.07456 6.20333C3.17086 5.78311 3.45976 5.42417 3.85372 5.23157L11.3783 1.64656ZM11.4177 2.26368C11.5753 2.18489 11.7547 2.14112 11.9342 2.13236C12.1618 2.11923 12.3851 2.163 12.5908 2.26368L19.6601 5.63858C20.0322 5.81805 20.3036 6.1551 20.3955 6.55343L22.1421 14.1437C22.234 14.5376 22.1333 14.9535 21.8838 15.273L16.99 21.3618C16.7273 21.677 16.3334 21.8696 15.9263 21.8696H8.0778C7.67072 21.8696 7.27677 21.677 7.01851 21.3618L2.12031 15.2818C2.09842 15.238 2.07654 15.203 2.04152 15.168C1.8533 14.8659 1.78326 14.4938 1.86205 14.1437L3.61297 6.55343C3.70052 6.1551 3.9719 5.81805 4.34397 5.63858L11.4177 2.26368Z"
          fill={color}
        />
        <path
          d="M11.8773 7.03064L11.6015 7.03939C8.61623 7.21448 6.33566 9.76645 6.49324 12.7518C6.65082 15.7371 9.18966 18.0352 12.1794 17.8951L12.0743 15.7415C10.2708 15.829 8.73879 14.4414 8.64249 12.638C8.54619 10.8345 9.92504 9.2937 11.7285 9.18864C13.4838 9.08359 15.0027 10.3836 15.1822 12.1171H13.2912L14.9983 16.2974L18.9423 12.1171H17.2439L17.3446 12.1127C17.1563 9.22366 14.7444 7.00437 11.8773 7.03064Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

KubernetesDeploymentSvgIcon.muiName = "KubernetesDeploymentSvgIcon";

export default KubernetesDeploymentSvgIcon;
