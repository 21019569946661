import { FC } from "react";
import { useAppSelector } from "../../../../../../store/hooks";
import { azureOverviewCommitmentsSelector } from "../../../../../../store/commitments/selectors/azure/overview/azureOverviewCommitmentsSelector";
import { azureOverviewLoadingSelector } from "../../../../../../store/commitments/selectors/azure/overview/loading/azureOverviewLoadingSelector";
import { CommitmentsOverviewCommitments } from "../../../overview/content/components/common/CommitmentsOverviewCommitments";

export const AzureCommitmentsOverviewCommitments: FC = () => {
  const commitments = useAppSelector(azureOverviewCommitmentsSelector);
  const loading = useAppSelector(azureOverviewLoadingSelector);

  return (
    <CommitmentsOverviewCommitments data={commitments} loading={loading} />
  );
};
