import { resourceExplorerGroupingLabelsSelector } from "./resourceExplorerGroupingLabelsSelector";
import { RootState } from "../../../store";
import {
  ResourceExplorerGroupingLabel,
  ResourceExplorerGroupingNullable,
} from "../../../../services/cloudchipr.api";

export const resourceExplorerGroupingLabelByGroupingSelector = (
  state: RootState,
  grouping?: ResourceExplorerGroupingNullable,
): ResourceExplorerGroupingLabel | undefined => {
  if (!grouping) {
    return;
  }
  const groupingLabels = resourceExplorerGroupingLabelsSelector(state);

  return grouping ? groupingLabels?.[grouping] : undefined;
};
