import { generateBillingSummaryFilterTypeData } from "./generateBillingSummaryFilterTypeData";
import {
  ForecastedCostRe,
  GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryApiResponse,
  GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdApiResponse,
} from "../../../../../../../services/cloudchipr.api";
import { BillingSummaryDataGridType } from "../../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/billing-summary/content/utils/types";
import { calculateTotalCostTrend } from "../../../../../../../components/pages/utils/helpers/calculateTotalCostTrend";
import { billingSummaryTitlesByFilter } from "../constants/billingSummaryTitlesByFilter";

export const generateBillingSummaryDataGridData = (
  data:
    | GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryApiResponse
    | GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdApiResponse,
): BillingSummaryDataGridType[] => {
  const billingSummaryDataGridData: BillingSummaryDataGridType[] = [];

  billingSummaryDataGridData.push(
    generateBillingSummaryFilterTypeData({
      billingSummaryFilterType: "total_cost",
      label: billingSummaryTitlesByFilter.get("total_cost") ?? "",
      cost: data.total_cost,
      trend: data.previous_period_total_cost
        ? calculateTotalCostTrend(
            data.total_cost ?? 0,
            data.previous_period_total_cost ?? 0,
          )
        : null,
      currentDatesFrom: data.total_cost_date_details.from_date,
      currentDatesTo: data.total_cost_date_details.to_date,
      previousDatesFrom: data.previous_period_cost_date_details.from_date,
      previousDatesTo: data.previous_period_cost_date_details.to_date,
    }),
  );

  billingSummaryDataGridData.push(
    generateBillingSummaryFilterTypeData({
      billingSummaryFilterType: "previous_period_total_cost",
      label:
        billingSummaryTitlesByFilter.get("previous_period_total_cost") ?? "",
      cost: data.previous_period_total_cost,
      trend: null,
    }),
  );

  if (data.average_monthly_cost_details.average_monthly_cost !== null) {
    billingSummaryDataGridData.push(
      generateBillingSummaryFilterTypeData({
        billingSummaryFilterType: "average_monthly_cost_details",
        label:
          billingSummaryTitlesByFilter.get("average_monthly_cost_details") ??
          "",
        cost: data.average_monthly_cost_details.average_monthly_cost,
        trend: data.average_monthly_cost_details.trend,
        currentDatesFrom: data.average_monthly_cost_details.from_date,
        currentDatesTo: data.average_monthly_cost_details.to_date,
        previousDatesFrom: data.previous_period_cost_date_details.from_date,
        previousDatesTo: data.previous_period_cost_date_details.to_date,
      }),
    );
  }

  if (data.average_daily_cost_details.average_daily_cost !== null) {
    billingSummaryDataGridData.push(
      generateBillingSummaryFilterTypeData({
        billingSummaryFilterType: "average_daily_cost_details",
        label:
          billingSummaryTitlesByFilter.get("average_daily_cost_details") ?? "",
        cost: data.average_daily_cost_details.average_daily_cost,
        trend: data.average_daily_cost_details.trend,
        currentDatesFrom: data.average_daily_cost_details.from_date,
        currentDatesTo: data.average_daily_cost_details.to_date,
        previousDatesFrom: data.previous_period_cost_date_details.from_date,
        previousDatesTo: data.previous_period_cost_date_details.to_date,
      }),
    );
  }
  billingSummaryDataGridData.push(
    ...generateForecastedCostsData(data?.forecasted_costs),
  );

  billingSummaryDataGridData.push(
    generateBillingSummaryFilterTypeData({
      billingSummaryFilterType: "live_usage_total_cost",
      label: billingSummaryTitlesByFilter.get("live_usage_total_cost") ?? "",
      cost: data.live_usage_total_cost,
      trend: null,
    }),
  );

  return billingSummaryDataGridData;
};

const generateForecastedCostsData = (
  forecastedCosts?: ForecastedCostRe,
): BillingSummaryDataGridType[] => {
  const monthEndForecast = forecastedCosts?.find(
    (item) => item.forecast_option === "month",
  );
  const quarterEndForecast = forecastedCosts?.find(
    (item) => item.forecast_option === "quarter",
  );

  return [
    generateBillingSummaryFilterTypeData({
      billingSummaryFilterType: "forecasted_month_end_cost",
      label:
        billingSummaryTitlesByFilter.get("forecasted_month_end_cost") ?? "",
      cost: monthEndForecast?.cost ?? null,
      trend: monthEndForecast?.trend,
      currentDatesFrom: monthEndForecast?.date_from,
      currentDatesTo: monthEndForecast?.date_to,
      previousDatesFrom: monthEndForecast?.previous_period_from,
      previousDatesTo: monthEndForecast?.previous_period_to,
    }),
    generateBillingSummaryFilterTypeData({
      billingSummaryFilterType: "forecasted_quarter_end_cost",
      label:
        billingSummaryTitlesByFilter.get("forecasted_quarter_end_cost") ?? "",
      cost: quarterEndForecast?.cost ?? null,
      trend: quarterEndForecast?.trend,
      currentDatesFrom: quarterEndForecast?.date_from,
      currentDatesTo: quarterEndForecast?.date_to,
      previousDatesFrom: quarterEndForecast?.previous_period_from,
      previousDatesTo: quarterEndForecast?.previous_period_to,
    }),
  ];
};
