import { FC } from "react";
import { ResourceDetailsCostsTable } from "../common/table/ResourceDetailsCostsTable";
import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceDetailsGroupBySelector } from "../../../../../../../store/resource-details/selectors/options/resourceDetailsGroupBySelector";
import { resourceDetailsCostsTabBillingGranularDataSelector } from "../../../../../../../store/resource-details/selectors/costs-tab/grid-data/resourceDetailsCostsTabBillingGranularDataSelector";
import { resourceDetailsCostsTabBillingGridDataSelector } from "../../../../../../../store/resource-details/selectors/costs-tab/grid-data/resourceDetailsCostsTabBillingGridDataSelector";

interface ResourceDetailsCostsContentGridProps {
  processKey: string;
}

export const ResourceDetailsCostsContentGrid: FC<
  ResourceDetailsCostsContentGridProps
> = ({ processKey }) => {
  const data = useAppSelector((state) =>
    resourceDetailsCostsTabBillingGranularDataSelector(state, processKey),
  );
  const gridData = useAppSelector((state) =>
    resourceDetailsCostsTabBillingGridDataSelector(state, processKey),
  );
  const groupBy = useAppSelector((state) =>
    resourceDetailsGroupBySelector(state, processKey),
  );

  return (
    <ResourceDetailsCostsTable
      groupBy={groupBy}
      gridData={gridData}
      columns={data?.columns}
    />
  );
};
