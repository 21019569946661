import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { kubernetesLiveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector } from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector";
import {
  IntegrationType,
  IntegrationTypeWithoutWebhook,
} from "../../../../../../../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import { initiateCurrentIntegration } from "../../../../../../../../../../store/integrations/integrationsSlice";
import { integrationOptions } from "../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/constants/constants";
import { LiveUsageMgmtToolbarRowIntegrationAction } from "../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/toolbar/actions/LiveUsageMgmtToolbarRowIntegrationAction";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";

interface KubernetesLiveUsageMgmtToolbarRowActionsProps {
  resourceType: ResourceType;
}

export const KubernetesLiveUsageMgmtToolbarRowActions: FC<
  KubernetesLiveUsageMgmtToolbarRowActionsProps
> = ({ resourceType }) => {
  const dispatch = useAppDispatch();
  const resourceCleanActionsButtonDisabledReason = useAppSelector((state) =>
    kubernetesLiveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector(
      state,
      resourceType,
    ),
  );

  const integrationIconClickHandler = useCallback(
    (type: IntegrationType) => {
      dispatch(
        initiateCurrentIntegration({
          type,
          resourceType,
          provider: "kubernetes",
        }),
      );
    },
    [dispatch, resourceType],
  );

  return (
    <Stack
      bgcolor="grey.100"
      spacing={0.5}
      direction="row"
      p={0.5}
      alignItems="center"
      borderRadius={2}
    >
      {integrationOptions.slice(1).map(({ type }) => {
        return (
          <LiveUsageMgmtToolbarRowIntegrationAction
            key={type}
            type={type as IntegrationTypeWithoutWebhook}
            onClick={integrationIconClickHandler}
            disabled={!!resourceCleanActionsButtonDisabledReason}
          />
        );
      })}
    </Stack>
  );
};
