import { FC } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { useDidMount, useEffectOnceWhen } from "rooks";
import { InternalResourceExplorerToolbar } from "./components/InternalResourceExplorerToolbar";
import { InternalResourceExplorerCardWrapper } from "./components/InternalResourceExplorerCardWrapper";
import { useGetResourceExplorerViewsByResourceExplorerViewIdFromAccountServiceQuery } from "../../../services/cloudchipr-account-service.api";
import { InternalPageHeader } from "../internal-pages-common/components/InternalPageHeader";
import { setGlobalVarReadyWithTimeout } from "../common/utils/helpers/setGlobalVarReadyWithTimeout";
import { useAppDispatch } from "../../../store/hooks";
import { initiateInternalResourceExplorerThunk } from "../../../store/resource-explorer/thunks/internal/initiateInternalResourceExplorerThunk";

export const InternalResourceExplorer: FC = () => {
  const { viewId } = useParams<{
    viewId: string;
  }>();
  const [searchParams] = useSearchParams();
  const organisationId = searchParams.get("organisation_id") ?? null;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const {
    data: view,
    isError,
    isUninitialized,
    isLoading,
  } = useGetResourceExplorerViewsByResourceExplorerViewIdFromAccountServiceQuery(
    {
      resourceExplorerViewId: viewId ?? "",
      organisationId: organisationId ?? "",
    },
    { refetchOnMountOrArgChange: true, skip: !organisationId },
  );

  useEffectOnceWhen(
    () => {
      setGlobalVarReadyWithTimeout();
    },
    (!view || !organisationId) && !isUninitialized && !isLoading,
  );

  useDidMount(() => {
    if (organisationId) {
      dispatch(initiateInternalResourceExplorerThunk(organisationId));
    }
  });

  if (isError || !organisationId) {
    navigate("/resource-explorer", { replace: true });
  }

  if (!view || !organisationId) {
    return null;
  }

  return (
    <Stack>
      <InternalPageHeader name={view?.name ?? ""} type="view" />
      <InternalResourceExplorerToolbar
        filterTree={view?.filter_tree}
        groupBy={view?.data?.grouping ?? "service"}
        groupValues={view?.data?.group_values}
        dates={view?.data?.dates}
        organisationId={organisationId}
      />
      <InternalResourceExplorerCardWrapper
        view={view}
        organisationId={organisationId}
      />
    </Stack>
  );
};
