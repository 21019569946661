import { taskManagementTargetSelector } from "./taskManagementTargetSelector";
import { taskManagementTargetResourcesForPayloadSelector } from "../resources/taskManagementTargetResourcesForPayloadSelector";
import { taskManagementTargetOpportunitiesForPayloadSelector } from "../opportunities/taskManagementTargetOpportunitiesForPayloadSelector";
import { RootState } from "../../../../../store";

export const taskManagementTargetDataForPayloadSelector = (
  state: RootState,
) => {
  const target = taskManagementTargetSelector(state);
  const targetResources =
    taskManagementTargetResourcesForPayloadSelector(state);
  const targetOpportunities =
    taskManagementTargetOpportunitiesForPayloadSelector(state);

  if (target?.type === "resource") {
    return {
      resources: targetResources,
    };
  }

  if (target?.type === "opportunity") {
    return {
      ids: targetOpportunities,
    };
  }
};
