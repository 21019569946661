import { FC, ReactNode, useCallback } from "react";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";
import { Skeleton, Theme, Tooltip } from "@mui/material";
import Stack from "@mui/material/Stack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface InfoCardProps {
  value?: number | string;
  title: string;
  titleSx?: SxProps<Theme>;
  valueSx?: SxProps<Theme>;
  tooltip?: string;
  extra?: ReactNode;
  url?: string;
  loading?: boolean;
}

export const InfoCard: FC<InfoCardProps> = ({
  value,
  title,
  titleSx,
  valueSx,
  tooltip,
  extra,
  url,
  loading,
}) => {
  const clickHandler = useCallback(() => {
    if (!url) {
      return;
    }

    window.open(url, "_blank");
  }, [url]);

  return (
    <Stack justifyContent="flex-start" height="100%" py={1}>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        height={metaDataTitleHeight}
      >
        <Typography
          variant="subtitle2"
          color="text.secondary"
          fontWeight="regular"
          whiteSpace="nowrap"
          sx={{ ...titleSx }}
        >
          {title ?? "-"}
        </Typography>

        {tooltip && (
          <Tooltip arrow placement="top" title={tooltip}>
            <InfoOutlinedIcon color="action" sx={{ fontSize: 16 }} />
          </Tooltip>
        )}
      </Stack>

      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        {loading ? (
          <Skeleton variant="text" width={100} />
        ) : (
          <Typography
            color="text.primary"
            variant="h6"
            whiteSpace="nowrap"
            onClick={clickHandler}
            sx={{ ...valueSx, ...(url ? urlStyles : {}) }}
          >
            {value ?? "-"}
          </Typography>
        )}

        {extra}
      </Stack>
    </Stack>
  );
};

const urlStyles = {
  cursor: "pointer",
  textDecoration: "underline",
  color: "primary.main",
  textDecorationThickness: 1,
};
export const metaDataTitleHeight = 28;
