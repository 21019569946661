import { createAsyncThunk } from "@reduxjs/toolkit";
import { getKubernetesLiveUsageMgmtResourcesWithFiltersThunk } from "./getKubernetesLiveUsageMgmtResourcesWithFiltersThunk";
import { resourceTypesByProvider } from "../../../../utils/helpers/providers/resourceTypesByProvider";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../components/pages/live-usage-mgmt/utils/helpers/getIsResourceTypeHiddenByFeatureToggle";

export const getKubernetesLiveUsageMgmtAllResourceTypesDataThunk =
  createAsyncThunk(
    "liveUsageMgmt/getKubernetesLiveUsageMgmtAllResourceTypesData",
    async (_: void, { dispatch }) => {
      const resourceTypes = resourceTypesByProvider("kubernetes");
      resourceTypes?.forEach((resourceType) => {
        if (getIsResourceTypeHiddenByFeatureToggle(resourceType)) {
          return;
        }

        dispatch(
          getKubernetesLiveUsageMgmtResourcesWithFiltersThunk(resourceType),
        );
      });
    },
  );
