import { FC } from "react";
import { CreateIntegrationButton } from "../../../common/components/CreateIntegrationButton";
import { integrationDetails } from "../../common/utils/constants/integrationDetails";

interface JiraIntegrationConnectButtonProps {
  withStartIcon?: boolean;
  variant?: "outlined" | "contained";
}

export const JiraIntegrationConnectButton: FC<
  JiraIntegrationConnectButtonProps
> = ({ withStartIcon, variant }) => {
  const { label } = integrationDetails["jira"];

  return (
    <CreateIntegrationButton
      type="jira"
      withStartIcon={withStartIcon}
      integrationLabel={label}
      variant={variant}
    >
      Connect
    </CreateIntegrationButton>
  );
};
