import { ResourceExplorerFilterSchema } from "../../../../../services/cloudchipr.api";

export const formatBillingExplorerSummaryDataGettingPayload = (
  payload: ResourceExplorerFilterSchema,
) => {
  const { dates, ...rest } = payload;

  const filteredDates = {
    label: dates?.label ?? "",
    value: dates?.value !== undefined ? dates.value : null,
    from: dates?.from ?? null,
    to: dates?.to ?? null,
    type: dates?.type ?? null,
    unit: dates?.unit ?? null,
  };
  const obj = { ...rest, dates: filteredDates };
  return sortObjectByItKeys(obj ?? {});
};

function sortObjectByItKeys<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.map(sortObjectByItKeys) as unknown as T;
  } else if (obj && typeof obj === "object") {
    return Object.keys(obj)
      .sort((a, b) => a.localeCompare(b))
      .reduce((acc, key) => {
        (acc as any)[key] = sortObjectByItKeys((obj as any)[key]);
        return acc;
      }, {} as T);
  }
  return obj;
}
