import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  PatchUsersMeOrganisationsCurrentOpportunityViewsByViewIdApiArg,
} from "../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { updateSavingsOpportunityViewFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";
import { getSavingsOpportunitiesViewsHierarchyThunk } from "../getSavingsOpportunitiesViewsHierarchyThunk";

export const updateSavingsOpportunityViewThunk = createAsyncThunk(
  "savingsOpportunities/updateSavingsOpportunityView",
  async (
    args: PatchUsersMeOrganisationsCurrentOpportunityViewsByViewIdApiArg,
    { dispatch },
  ) => {
    const { patchUsersMeOrganisationsCurrentOpportunityViewsByViewId } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        patchUsersMeOrganisationsCurrentOpportunityViewsByViewId.initiate(
          args,
          {
            fixedCacheKey: updateSavingsOpportunityViewFixedCacheKey,
          },
        ),
      ).unwrap();

      dispatch(getSavingsOpportunitiesViewsHierarchyThunk());

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
