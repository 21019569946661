import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { BillingExplorerChartSettingsPanelHeader } from "./BillingExplorerChartSettingsPanelHeader";
import { BillingExplorerChartScaleSelect } from "./BillingExplorerChartScaleSelect";
import { BillingExplorerChartSettingSwitcher } from "./BillingExplorerChartSettingSwitcher";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { resourceExplorerChartSettingsSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/settings/resourceExplorerChartSettingsSelector";

interface BillingExplorerChartSettingsDrawerProps {
  onClose(): void;
}

export const BillingExplorerChartSettingsPanel: FC<
  BillingExplorerChartSettingsDrawerProps
> = ({ onClose }) => {
  const settings = useAppSelector(resourceExplorerChartSettingsSelector);

  return (
    <Fragment>
      <BillingExplorerChartSettingsPanelHeader onClose={onClose} />

      <Stack p={1.5}>
        <BillingExplorerChartScaleSelect scale={settings.chartScale} />

        <BillingExplorerChartSettingSwitcher
          type="showLabels"
          checked={settings.showLabels}
        />

        <BillingExplorerChartSettingSwitcher
          type="showLegends"
          checked={settings.showLegends}
        />
      </Stack>
    </Fragment>
  );
};
