import { billingExplorerSummaryAnomaliesFormattedDataSelector } from "./billingExplorerSummaryAnomaliesFormattedDataSelector";
import { RootState } from "../../../store";

export const billingExplorerSummaryAnomaliesCountSelector = (
  state: RootState,
  index: number,
): number | undefined => {
  const formattedData = billingExplorerSummaryAnomaliesFormattedDataSelector(
    state,
    index,
  );

  if (!formattedData) {
    return;
  }
  return formattedData.totalCount;
};
