import { FC } from "react";
import { JiraHelperVideo } from "../../../jira/components/JiraHelperVideo";
import { IntegrationType } from "../../../../../../../../store/integrations/integrationsSlice";

interface HelperGuideByTypeProps {
  type: IntegrationType;
}

export const HelperGuideByType: FC<HelperGuideByTypeProps> = ({ type }) => {
  if (type === "jira") {
    return <JiraHelperVideo />;
  }
  return null;
};
