import { FC, memo } from "react";
import { Stack } from "@mui/material";
import { KubernetesClusterSortBy } from "./KubernetesClusterSortBy";
import { AccountsSearch } from "../../../accounts/toolbar/AccountsSearch";
import { AccountsViewToggle } from "../../../accounts/toolbar/AccountsViewToggle";
import { AccountsPageTabs } from "../../../../utils/types/types";

interface KubernetesClustersToolbarProps {
  tabValue: AccountsPageTabs;
}

export const KubernetesClustersToolbar: FC<KubernetesClustersToolbarProps> =
  memo(({ tabValue }) => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          <AccountsSearch />
        </Stack>

        <Stack direction="row" spacing={1}>
          <KubernetesClusterSortBy tabValue={tabValue} />
          <AccountsViewToggle tabValue={tabValue} provider="kubernetes" />
        </Stack>
      </Stack>
    );
  });
