import { FC, useCallback } from "react";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { DeleteConfirmationDialog } from "../../../../../../../../common/modals/DeleteConfirmationDialog";
import { useDeleteUsersMeOrganisationsCurrentTasksByTaskIdCommentsAndCommentIdMutation } from "../../../../../../../../../services/cloudchipr.api";
import { getTaskActivitiesThunk } from "../../../../../../../../../store/task-management/thunks/activities/getTaskActivitiesThunk";
import { taskManagementGetTasksThunk } from "../../../../../../../../../store/task-management/thunks/actions/taskManagementGetTasksThunk";
import { enqueueSnackbarErrorAlert } from "../../../../../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface CommentDeleteActionDialogProps {
  open: boolean;
  commentId: string;
  taskId: string;
  closeDialog(): void;
}

export const CommentDeleteActionDialog: FC<CommentDeleteActionDialogProps> = ({
  open,
  commentId,
  taskId,
  closeDialog,
}) => {
  const [triggerComment, { isLoading }] =
    useDeleteUsersMeOrganisationsCurrentTasksByTaskIdCommentsAndCommentIdMutation();
  const dispatch = useDispatch();

  const deleteHandler = useCallback(async () => {
    try {
      await triggerComment({ taskId, commentId });
      dispatch(getTaskActivitiesThunk(taskId));
      dispatch(taskManagementGetTasksThunk());
      closeDialog();

      enqueueSnackbar("Comment deleted.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  }, [triggerComment, dispatch, commentId, taskId, closeDialog]);

  return (
    <DeleteConfirmationDialog
      open={open}
      onClose={closeDialog}
      onDelete={deleteHandler}
      loading={isLoading}
      title="Delete Comment?"
      content={
        <Typography>Please confirm if you want to delete comment.</Typography>
      }
    />
  );
};
