import { FC } from "react";
import { Box, Button, Stack } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { AskAICollapsableConversationItemBodyLoading } from "./AskAICollapsableConversationItemBodyLoading";
import { useAppSelector } from "../../../../../../../store/hooks";
import { askAiLoadingSelector } from "../../../../../../../store/ask-ai/selectors/askAiLoadingSelector";

interface AskAICollapsableConversationItemHeaderProps {
  question: string;
  onCollapse(): void;
  collapsed: boolean;
  index: number;
  totalCount: number;
}

export const AskAICollapsableConversationItemHeader: FC<
  AskAICollapsableConversationItemHeaderProps
> = ({ question, onCollapse, collapsed, index, totalCount }) => {
  const loading = useAppSelector(askAiLoadingSelector);

  const showLoading = loading && index === totalCount - 1;
  return (
    <Stack spacing={0.5}>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        py={1}
      >
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Box>
            <Stack
              width={32}
              height={32}
              borderRadius="50%"
              bgcolor="white"
              alignItems="center"
              justifyContent="center"
            >
              <QuestionMarkIcon color="primary" fontWeight="small" />
            </Stack>
          </Box>

          <Stack
            overflow="auto"
            dangerouslySetInnerHTML={{ __html: question ?? "" }}
          />
        </Stack>

        {!showLoading && (
          <Button onClick={onCollapse} size="small">
            {collapsed ? "hide" : "show"}
          </Button>
        )}
      </Stack>
      {showLoading && (
        <Box pb={1}>
          <AskAICollapsableConversationItemBodyLoading />
        </Box>
      )}
    </Stack>
  );
};
