import { CustomCellRendererProps } from "ag-grid-react";
import { ColDef, ColDefField } from "ag-grid-community";
import { AlertsDataGridNameCell } from "../AlertsDataGridNameCell";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../store/alerts/utils/types/types";
import { isCostAnomalyAlertsDataGridDataType } from "../../cost-anomaly/utils/helpers/isCostAnomalyAlertsDataGridDataType";

export const getAlertsNameColumnDef = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(): ColDef<T> => ({
  field: "name" as ColDefField<T>,
  headerName: "Alert Name",
  suppressKeyboardEvent: () => true,
  lockVisible: true,
  pinned: "left",
  lockPinned: true,
  suppressColumnsToolPanel: true,
  minWidth: 150,
  width: 190,
  cellRenderer: ({ data }: CustomCellRendererProps<T>) => {
    if (!data) {
      return null;
    }

    const isCostAnomaly = isCostAnomalyAlertsDataGridDataType(data);

    return (
      <AlertsDataGridNameCell
        alertId={data.id}
        alertName={data.name}
        alertType={isCostAnomaly ? "costAnomaly" : "utilization"}
        disabled={isCostAnomaly ? !data.viewName : undefined}
        disabledTooltipTitle="The connected Billing Reports has been deleted."
        orgId={isCostAnomaly ? undefined : data.provider_organisation_id}
      />
    );
  },
});
