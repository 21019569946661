import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { WidgetTotalsSummary } from "../../common/WidgetTotalsSummary";
import { DateLabelNullable } from "../../../../../../../services/cloudchipr.api";
import { billingExplorerWidgetCostsSelector } from "../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetCostsSelector";
import { billingExplorerWidgetVisualizationSelector } from "../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetVisualizationSelector";
import { billingExplorerWidgetFrequencySelector } from "../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetFrequencySelector";
import { billingExplorerWidgetForecastOptionSelector } from "../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetForecastOptionSelector";
import { billingExplorerWidgetAggregationsSelector } from "../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetAggregationsSelector";
import { billingExplorerWidgetBillingExportUrlSelector } from "../../../../../../../store/dashboards/selectors/widgets/billing-explorer-widget/widget-view/billingExplorerWidgetBillingExportUrlSelector";

interface BillingExplorerWidgetContentCostsProps {
  widgetId: string;
  dateLabel?: DateLabelNullable;
}

export const BillingExplorerWidgetContentCosts: FC<
  BillingExplorerWidgetContentCostsProps
> = ({ widgetId, dateLabel }) => {
  const costsData = useAppSelector((state) =>
    billingExplorerWidgetCostsSelector(state, widgetId),
  );

  const visualisations = useAppSelector((state) =>
    billingExplorerWidgetVisualizationSelector(state, widgetId),
  );

  const frequency = useAppSelector((state) =>
    billingExplorerWidgetFrequencySelector(state, widgetId),
  );

  const forecastOption = useAppSelector((state) =>
    billingExplorerWidgetForecastOptionSelector(state, widgetId),
  );

  const aggregations = useAppSelector((state) =>
    billingExplorerWidgetAggregationsSelector(state, widgetId),
  );
  const url = useAppSelector((state) =>
    billingExplorerWidgetBillingExportUrlSelector(state, widgetId),
  );

  if (!costsData || !frequency || !forecastOption) {
    return null;
  }

  return (
    <WidgetTotalsSummary
      frequency={frequency}
      costsData={costsData}
      forecastOption={forecastOption}
      dateLabel={dateLabel}
      aggregations={aggregations}
      billingExplorerUrl={url}
      showTrends={visualisations?.trend}
    />
  );
};
