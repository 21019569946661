import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { billingExplorerSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { TrendType } from "../../../../../../../../../../../services/cloudchipr.api";
import { WidgetSetupTrendSettingSelect } from "../../../../common/toolbar/WidgetSetupTrendSettingSelect";
import { billingExplorerTrendTypeChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerTrendTypeChangeThunk";

export const BillingExplorerWidgetAdvancedSettingsTrendSetting: FC = () => {
  const dispatch = useAppDispatch();

  const trendType = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("trendType"),
  );

  const handleTrendTypeChange = useCallback(
    (trend: TrendType) => {
      dispatch(billingExplorerTrendTypeChangeThunk(trend));
    },
    [dispatch],
  );

  return (
    <WidgetSetupTrendSettingSelect
      trendType={trendType}
      onTrendTypeChange={handleTrendTypeChange}
    />
  );
};
