import { FC, useCallback } from "react";
import { WorkflowNotificationCustomMessageAfterMessageComponent } from "./custom-message-components/WorkflowNotificationCustomMessageAfterMessageComponent";
import { WorkflowNotificationNotifySectionCustomMessageBeforeMessageComponent } from "./custom-message-components/WorkflowNotificationNotifySectionCustomMessageBeforeMessageComponent";
import { WorkflowNotificationCleanSectionCustomMessageBeforeMessageComponent } from "./custom-message-components/WorkflowNotificationCleanSectionCustomMessageBeforeMessageComponent";
import { CollapseSwitcher } from "../CollapseSwitcher";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import {
  setAutomationData,
  setWorkflowSpecificData,
} from "../../../../../../../../store/automations/automationsSlice";
import { workflowActionSelector } from "../../../../../../../../store/automations/selectros/workflow/workflowActionSelector";
import { automationHasAccountIdsSelectedSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationHasAccountIdsSelectedSelector";
import { AutomationNotifications } from "../../../../../common/components/form/AutomationNotifications";
import { NotificationCustomMessageConfig } from "../../../../../../common/notification-selection/components/custom-message/NotificationCustomMessage";

export const WorkflowNotificationsContent: FC = () => {
  const dispatch = useAppDispatch();
  const action = useAppSelector(workflowActionSelector);
  const accountIdsSelected = useAppSelector(
    automationHasAccountIdsSelectedSelector,
  );
  const switcher = action !== "notify";

  const collapseHandler = useCallback(
    (collapsed: boolean) => {
      dispatch(
        setWorkflowSpecificData({
          action: collapsed ? "clean_and_notify" : "clean",
        }),
      );
      dispatch(setAutomationData({ emails: null, notifications: null }));
    },
    [dispatch],
  );

  const content = (
    <AutomationNotifications
      customMessageConfigs={action === "notify" ? notifyConfig : cleanConfig}
    />
  );

  if (!switcher) {
    return content;
  }

  return (
    <CollapseSwitcher
      disabled={!accountIdsSelected}
      onCollapse={collapseHandler}
      title="Action Notification"
      checkedOverride={action === "clean_and_notify"}
      info="When enabled, Cloudchipr will send detailed list of resources after taking actions."
    >
      {content}
    </CollapseSwitcher>
  );
};

const notifyConfig: NotificationCustomMessageConfig = {
  preview: {
    BeforeMessage:
      WorkflowNotificationNotifySectionCustomMessageBeforeMessageComponent,
    AfterMessage: WorkflowNotificationCustomMessageAfterMessageComponent,
  },
  dialogContentProps: {
    BeforeMessage:
      WorkflowNotificationNotifySectionCustomMessageBeforeMessageComponent,
    AfterMessage: WorkflowNotificationCustomMessageAfterMessageComponent,
  },
};
const cleanConfig: NotificationCustomMessageConfig = {
  preview: {
    BeforeMessage:
      WorkflowNotificationCleanSectionCustomMessageBeforeMessageComponent,
    AfterMessage: WorkflowNotificationCustomMessageAfterMessageComponent,
  },
  dialogContentProps: {
    BeforeMessage:
      WorkflowNotificationCleanSectionCustomMessageBeforeMessageComponent,
    AfterMessage: WorkflowNotificationCustomMessageAfterMessageComponent,
  },
};
