import { money } from "../../../../utils/numeral/money";
import { ResourceAdditionalDetailsType } from "../../../resource-details/utils/types/common";
import { convertByteToSize } from "../../../../components/utils/helpers/convertByteToSize";

export const generateAdditionalDetailsForResourceDrawer = (
  data: Record<string, string | number>,
) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (value) {
      if (key === "CPU Reservation") {
        acc.push({ title: key, value: `${value} Core` });
        return acc;
      }

      if (key === "Memory Reservation") {
        acc.push({ title: key, value: convertByteToSize(value) });
        return acc;
      }

      const isMoney = key === "Monthly Price";

      acc.push({ title: key, value: isMoney ? money(value) : `${value}` });
    }

    return acc;
  }, [] as ResourceAdditionalDetailsType[]);
};
