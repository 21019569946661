import { createAsyncThunk } from "@reduxjs/toolkit";
import { TrendType } from "../../../../../../services/cloudchipr.api";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { billingExplorerWidgetSetupSelector } from "../../../../selectors/setups/billing-explorer/billingExplorerWidgetSetupSelector";
import { getBillingExplorerWidgetSetupDataThunk } from "../getBillingExplorerWidgetSetupDataThunk";

export const billingExplorerTrendTypeChangeThunk = createAsyncThunk(
  "dashboards/billingExplorerWidgetTrendTypeChange",
  async (trendType: TrendType, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = billingExplorerWidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }
    dispatch(
      setWidgetSetup({
        ...existingSetup,
        trendType,
      }),
    );

    dispatch(getBillingExplorerWidgetSetupDataThunk());
  },
);
