import { FC, useCallback } from "react";
import { CreateEmailResourcesTableV2 } from "./components/resources-table/CreateEmailResourcesTableV2";
import { CreateEmailMessageDialogV2 } from "./CreateEmailMessageDialogV2";
import {
  ProviderType,
  ResourceType,
} from "../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../store/hooks";
import { emailMessageSelectorV2 } from "../../../../../store/integrations/selectors/email/emailMessageSelectorV2";
import { kubernetesEmailMessageSelectorV2 } from "../../../../../store/integrations/selectors/kubernetes/email/kubernetesEmailMessageSelectorV2";
import { KubernetesCreateEmailResourcesTable } from "../kubernetes/email/KubernetesCreateEmailResourcesTable";
import { RootState } from "../../../../../store/store";
import { liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector } from "../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector";
import { kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector } from "../../../../../store/kubernetes-live-usage-mgmt/selectors/resource-type-data/kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector";

interface CreateEmailMessageDialogWrapperProps {
  resourceType: ResourceType;
  onClose(): void;
  provider?: ProviderType | null;
}

export const CreateEmailMessageDialogWrapper: FC<
  CreateEmailMessageDialogWrapperProps
> = ({ resourceType, onClose, provider }) => {
  const providerMessage = useAppSelector((state) => {
    return emailMessageSelectorV2(state, resourceType);
  });

  const kubernetesMessage = useAppSelector((state) => {
    return kubernetesEmailMessageSelectorV2(state, resourceType);
  });

  const providerCsvSelector = useCallback(
    (state: RootState) =>
      liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector(
        state,
        resourceType,
      ),
    [resourceType],
  );
  const kubernetesCsvSelector = useCallback(
    (state: RootState) =>
      kubernetesLiveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector(
        state,
        resourceType,
      ),
    [resourceType],
  );
  const liveUsageMgmtResourcesCsv = useAppSelector(providerCsvSelector);
  const kubernetesLiveUsageMgmtResourcesCsv = useAppSelector(
    kubernetesCsvSelector,
  );

  if (provider === "kubernetes") {
    return (
      <CreateEmailMessageDialogV2
        onClose={onClose}
        resourceType={resourceType}
        message={kubernetesMessage}
        Table={KubernetesCreateEmailResourcesTable}
        csvData={kubernetesLiveUsageMgmtResourcesCsv}
      />
    );
  }

  return (
    <CreateEmailMessageDialogV2
      onClose={onClose}
      resourceType={resourceType}
      message={providerMessage}
      Table={CreateEmailResourcesTableV2}
      csvData={liveUsageMgmtResourcesCsv}
    />
  );
};
