import { ColDef, ColDefField } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../store/alerts/utils/types/types";
import { UserActivityCell } from "../../../../../../common/data-grid-cells/UserActivityCell";
import { budgetAndAlertsModifiedByValueGetter } from "../utils/helpers/valueGetters/budgetAndAlertsModifiedByValueGetter";
import { budgetAndAlertsModifiedByFilterValueGetter } from "../utils/helpers/filterValueGetters/budgetAndAlertsModifiedByFilterValueGetter";

export const getAlertsModifiedByColumnDef = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(): ColDef<T> => ({
  field: "modified_by" as ColDefField<T>,
  headerName: "Modified By",
  minWidth: 150,
  width: 200,
  cellRenderer: ({ data }: CustomCellRendererProps<T>) => {
    if (!data) {
      return;
    }

    return (
      <UserActivityCell
        date={data.modified_at ?? ""}
        name={data.modified_by?.name ?? data.modified_by?.email ?? ""}
        deleted={data.modified_by?.status === "deleted"}
        profile={data.modified_by?.profile}
      />
    );
  },
  filterValueGetter: budgetAndAlertsModifiedByFilterValueGetter,
  valueGetter: budgetAndAlertsModifiedByValueGetter,
});
