import { Grid, Link } from "@mui/material";
import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { CommunicationIntegrationCard } from "./components/communication-integration-card/CommunicationIntegrationCard";
import { useGetUsersMeIntegrationsQuery } from "../../../../../../services/cloudchipr.api";
import { getConnectionCounts } from "../common/utils/helpers/getConnectionCounts";

export const CommunicationIntegrationsGridView = () => {
  const { data: integrations } = useGetUsersMeIntegrationsQuery({});

  const integrationItems = useMemo(
    () => getConnectionCounts(integrations),
    [integrations],
  );

  return (
    <Grid
      container
      spacing={2}
      pr={4}
      pb={2}
      columns={{ xs: 2, lg: 3, xl: 3, xxl: 4 }}
    >
      {integrationItems?.map((item) => {
        return (
          <Grid item xs={1} key={item.type}>
            <Link
              underline="none"
              color="inherit"
              component={RouterLink}
              to={`/integrations/${item.type}`}
            >
              <CommunicationIntegrationCard
                key={item.type}
                type={item.type}
                connections={item.connections}
              />
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
};
