import { FC } from "react";
import {
  EmbededKubernetesCluster,
  ProviderType,
  ResourceType,
} from "../../../../../../../../../../services/cloudchipr.api";
import { AccountInfoData } from "../../../../../../../../../common/AccountInfoData";

interface ClusterCellProps {
  data: EmbededKubernetesCluster;
  providerLink?: string;
  resourceType?: ResourceType;
  linkTooltipTitle?: string;
  liveUsageIcon?: boolean;
}
export const ClusterCell: FC<ClusterCellProps> = ({
  data,
  providerLink,
  linkTooltipTitle,
  liveUsageIcon,
}) => {
  return (
    <AccountInfoData
      liveUsageIcon={liveUsageIcon}
      provider={data.provider}
      accountName={data.name ?? data.provider_id}
      // link={generateLiveResourcesPath({
      //   provider: data.provider,
      //   accountIds: data.id,
      //   resourceType: clusterResourceTypeByProvider?.[data.provider],
      // })}
      providerLink={providerLink}
      linkTooltipTitle={linkTooltipTitle}
      nameTypographyProps={{ fontSize: 14 }}
    />
  );
};

const clusterResourceTypeByProvider: Partial<
  Record<ProviderType, ResourceType>
> = {
  aws: "eks",
  azure: "az_aks",
  gcp: "gke",
};
