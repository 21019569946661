import { createAsyncThunk } from "@reduxjs/toolkit";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { textWidgetSetupDefaultData } from "../../../utils/constants/textWidgetSetupDefaultData";

export const initiateTextWidgetThunk = createAsyncThunk(
  "dashboard/initiateTextWidget",
  (_, { dispatch }) => {
    dispatch(setWidgetSetup(textWidgetSetupDefaultData));
  },
);
