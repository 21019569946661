import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { Typography } from "@mui/material";
import { AzureSavingsPlanCoverageDataWithId } from "../../../../../../../../../common/utils/types";
import { money } from "../../../../../../../../../../../../utils/numeral/money";

export const azureUncoveredUsageColumnDef: ColDef<AzureSavingsPlanCoverageDataWithId> =
  {
    flex: 1.5,
    field: "uncovered_usage",
    minWidth: 200,
    headerName: "Not Covered (On-Demand)",
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<AzureSavingsPlanCoverageDataWithId>) => (
      <Typography variant="body2">
        {money(data?.uncovered_usage ?? 0)}
      </Typography>
    ),
    valueGetter: ({ data }) => money(data?.uncovered_usage ?? 0),
  };
