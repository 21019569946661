import { RootState } from "../../../../../../store";
import { azureSavingsPlansDataSelector } from "../../data/azureSavingsPlansDataSelector";
import { commitmentsDetailsDrawerTargetIdSelector } from "../../../../common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";
import { AzureSavingsPlan } from "../../../../../../../services/cloudchipr.api";

export const azureSelectedSavingsPlanDataSelector = (
  state: RootState,
): AzureSavingsPlan | undefined => {
  const savingsPlans = azureSavingsPlansDataSelector(state);
  const savingsPlanId = commitmentsDetailsDrawerTargetIdSelector(state);

  return savingsPlans?.find((sp) => sp.provider_unique_id === savingsPlanId);
};
