import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getBillingExplorerAnomalyByIdThunk = createAsyncThunk(
  "resourceExplorer/getBillingExplorerAnomalyById",
  async (id: string, { dispatch, getState }) => {
    const { getUsersMeOrganisationsCurrentResourceExplorerAnomalyByAnomalyId } =
      cloudChiprApi.endpoints;

    let attempt = 0;

    while (attempt < MAX_RETRIES) {
      attempt++;

      try {
        const response = await dispatch(
          getUsersMeOrganisationsCurrentResourceExplorerAnomalyByAnomalyId.initiate(
            { anomalyId: id },
          ),
        ).unwrap();

        if (response?.summary) {
          return response;
        }
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    }
  },
);
const MAX_RETRIES = 3;
