import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { addRegionsToFilters } from "../../../../utils/helpers/addRegionsToFilters";
import { liveUsageMgmtRegionsSelector } from "../../selectors/store-selectors/liveUsageMgmtRegionsSelector";
import { liveUsageMgmtAccountIdsSelector } from "../../selectors/store-selectors/liveUsageMgmtAccountIdsSelector";

export const getResourceTypeDataWithoutFiltersFixedCacheKey = (
  type: ResourceType,
) => {
  return `postUsersMeV2OrganisationsCurrentByTypeResources-${type}-without-filters`;
};

export const getLiveUsageMgmtResourcesWithOutFiltersByProviderThunk =
  createAsyncThunk(
    "liveUsageMgmt/getLiveUsageMgmtResourcesWithOutFiltersByProvider",
    async (resourceType: ResourceType, { dispatch, getState }) => {
      const state = getState() as RootState;
      const accountIds = liveUsageMgmtAccountIdsSelector(state);
      const regions = liveUsageMgmtRegionsSelector(state);

      const type =
        getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;

      if (!accountIds) {
        return;
      }

      const appliedFilter = addRegionsToFilters(regions, {
        type: resourceType,
        filter_items: [],
        filter_groups: [],
        operators: [],
      });

      dispatch(
        cloudChiprApi.endpoints.postUsersMeV2OrganisationsCurrentByTypeResources.initiate(
          {
            type: type,
            body: {
              account_ids: accountIds ?? [],
              filter: appliedFilter,
            },
          },
          {
            fixedCacheKey: getResourceTypeDataWithoutFiltersFixedCacheKey(type),
          },
        ),
      );
    },
  );
