export const getQuarterNameByQuarterStartMonth = (
  month: number,
  quarterStartMonth?: number,
) => {
  if (!quarterStartMonth) {
    return;
  }

  const monthIndex = (month - quarterStartMonth + 12) % 12;
  const quarter = Math.floor(monthIndex / 3) + 1;

  return `Q${quarter}`;
};
