import { FC, Fragment } from "react";
import { Typography } from "@mui/material";
import numeral from "numeral";
import { money } from "../../../../../../../../../../utils/numeral/money";

interface DbPriceRatioCellProps {
  dbPrice?: number | null;
  dbPriceRatio?: number | null;
}

export const DbPriceRatioCell: FC<DbPriceRatioCellProps> = ({
  dbPrice,
  dbPriceRatio,
}) => {
  const hasDbPrice = dbPrice !== undefined && dbPrice !== null;
  const hasDbPriceRatio = dbPriceRatio !== undefined && dbPriceRatio !== null;

  if (!hasDbPrice && !hasDbPriceRatio) {
    return "-";
  }

  const priceRatio = numeral(dbPriceRatio ?? 0).format("0.00");

  return (
    <Fragment>
      <Typography variant="body2">
        {hasDbPriceRatio ? `${priceRatio}%` : "-"}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {hasDbPrice ? `DB Price: ${money(dbPrice)}` : "-"}
      </Typography>
    </Fragment>
  );
};
