import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";
import {
  ColDef,
  GridApi,
  GridState,
  SelectionChangedEvent,
} from "ag-grid-community";
import { RowSelectionOptions } from "ag-grid-community/dist/types/src/entities/gridOptions";
import { savingsOpportunitiesTableColumns } from "./savingsOpportunitiesTableColumns";
import { AutoGroupColumnDefInnerRenderer } from "./cell-renderers/AutoGroupColumnDefInnerRenderer";
import { AgDataGrid } from "../../common/ag-grid/AgDataGrid";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { savingsOpportunityCurrentViewConfigSelector } from "../../../../store/savings-opportunities/selectors/views-hierarchy/current-view/savingsOpportunityCurrentViewConfigSelector";
import { savingsOpportunityCurrentViewSelector } from "../../../../store/savings-opportunities/selectors/views-hierarchy/current-view/savingsOpportunityCurrentViewSelector";
import { useSavingsOpportunitiesViewsHierarchySaveHook } from "../utils/hooks/useSavingsOpportunitiesViewsHierarchySave.hook";
import { setSavingsOpportunitiesSelectedRowsIds } from "../../../../store/savings-opportunities/savingsOpportunitiesSlice";
import { getAGGridAllSelectedRows } from "../../common/ag-grid/utils/helpers/getSelectedRowsFromApi";
import { SavingsOpportunity } from "../../../../services/cloudchipr.api";
import { savingsOpportunitiesTemporaryFilterModelFixingFns } from "../utils/helpers/oldFilterModelHandlers";

interface SavingsOpportunitiesDataGridProps {
  data?: SavingsOpportunity[];
  gridApi: GridApi | null;
  setGridApi: Dispatch<SetStateAction<GridApi | null>>;
}

export const SavingsOpportunitiesDataGrid: FC<
  SavingsOpportunitiesDataGridProps
> = ({ data, setGridApi, gridApi }) => {
  const dispatch = useAppDispatch();

  const viewId = useAppSelector(savingsOpportunityCurrentViewSelector)?.id;
  const config = useAppSelector(savingsOpportunityCurrentViewConfigSelector);

  const { onStateDebouncedUpdate } =
    useSavingsOpportunitiesViewsHierarchySaveHook(gridApi);

  const configs = useMemo(() => {
    const parsed = JSON.parse(config?.gridConfig ?? "{}") as GridState;

    // added temporary as serverSideRowFilterModel can't work with current,
    const filterModel = parsed?.filter?.filterModel;
    if (
      filterModel &&
      savingsOpportunitiesTemporaryFilterModelFixingFns.isOldFilterModel(
        filterModel,
      )
    ) {
      return {
        ...parsed,
        filter: {
          ...parsed.filter,
          filterModel:
            savingsOpportunitiesTemporaryFilterModelFixingFns.convertToMultiFilter(
              filterModel,
            ),
        },
      };
    }

    return parsed;
  }, [config]);

  const rowSelectHandler = useCallback(
    ({ api }: SelectionChangedEvent) => {
      const selectedRows = getAGGridAllSelectedRows(api);

      dispatch(
        setSavingsOpportunitiesSelectedRowsIds(
          selectedRows.map((row) => row.id),
        ),
      );
    },
    [dispatch],
  );

  const getChildCount = useCallback((data: any) => data.count, []);

  return (
    <AgDataGrid
      key={viewId}
      rowHeight={55}
      rowData={data}
      rowGroupPanelShow="always"
      gridApiSetter={setGridApi}
      rowSelection={rowSelection}
      wrapperStyles={wrapperStyles}
      defaultColDef={defaultColDef}
      getChildCount={getChildCount}
      autoGroupColumnDef={autoGroupColumnDef}
      onSelectionChanged={rowSelectHandler}
      onStateUpdated={onStateDebouncedUpdate}
      initialState={viewId ? configs : undefined}
      columnDefs={savingsOpportunitiesTableColumns}
      configToRememberStateInStorage={
        viewId ? undefined : configToRememberStateInStorage
      }
    />
  );
};

const configToRememberStateInStorage = {
  uniqueNameAsKey: "all-savings-opportunities-view",
};

const wrapperStyles = {
  ".ag-cell-wrapper": { width: "100%", height: "100%", alignItems: "center" },
};

const rowSelection: RowSelectionOptions = {
  mode: "multiRow",
  selectAll: "filtered",
  hideDisabledCheckboxes: true,
  checkboxLocation: "autoGroupColumn",
  groupSelects: "filteredDescendants",
  isRowSelectable: (row) => !row.group,
};

const defaultColDef: ColDef = {
  minWidth: 150,
  unSortIcon: true,
  enableRowGroup: true,
  filter: "agMultiColumnFilter",
  loadingCellRenderer: () => (
    <div className="p-2 animate-pulse bg-gray-200 rounded-md w-full h-4">
      --------------------------------
    </div>
  ),
};

const autoGroupColumnDef: ColDef = {
  minWidth: 250,
  pinned: "left",
  sortable: false,
  enableRowGroup: false,
  suppressMovable: true,
  headerName: "Grouped by",
  cellRendererParams: { innerRenderer: AutoGroupColumnDefInnerRenderer },
};
