import { GroupingByTagKeyOption } from "./GroupingByTagKeyOption";
import { DropdownSelectOption } from "../../../../../../common/select/dropdown-select/utils/types/types";
import { GroupingChangeHandler } from "../../../utils/types/types";
import { ResourceExplorerGroupingNullable } from "../../../../../../../services/cloudchipr.api";

export const getTagKeyGroupingOption = (
  value: ResourceExplorerGroupingNullable,
  label: string | null,
  onChange: GroupingChangeHandler,
  onClose: () => void,
  groupValues?: string[],
): DropdownSelectOption => ({
  value: "tag_key",
  disableSelection: true,
  rawValue: { search: `tags tag key ${label}` },
  label: (
    <GroupingByTagKeyOption
      onChange={onChange}
      onClose={onClose}
      values={value === "tag_key" ? groupValues : []}
      label={label ?? ""}
    />
  ),
});
