import { FC, Fragment, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import { AccountAddButton } from "./AccountAddButton";
import { ActiveAccountsEmptyTemplate } from "./ActiveAccountsEmptyTemplate";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import AwsEmptyStateImage from "../../../../../assets/images/empty_aws_accounts.png";
import GcpEmptyStateImage from "../../../../../assets/images/emtpy_gcp_accounts.png";
import AzureEmptyStateImage from "../../../../../assets/images/empty_azure_accounts.png";
import KubernetesEmptyStateImage from "../../../../../assets/images/empty_kubernetes_clusters.png";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";

const images = {
  aws: AwsEmptyStateImage,
  gcp: GcpEmptyStateImage,
  azure: AzureEmptyStateImage,
  kubernetes: KubernetesEmptyStateImage,
};

interface AccountsEmptyTemplateProps {
  provider: ProviderType;
  onlyInActive: boolean;
  actionButton?: ReactNode;
}

export const AccountsEmptyTemplate: FC<AccountsEmptyTemplateProps> = ({
  provider,
  onlyInActive,
  actionButton,
}) => {
  return (
    <Stack justifyContent="center" alignItems="center" mt={12}>
      {onlyInActive ? (
        <ActiveAccountsEmptyTemplate provider={provider} />
      ) : (
        <Fragment>
          <img src={images[provider]} alt="EmptyStateImage" />

          <Typography
            component="h5"
            fontWeight="bold"
            variant="h5"
            textAlign="center"
            mt={6}
          >
            It’s Time to add your first{" "}
            {getProviderName(provider, { nameAndTitle: true })}!
          </Typography>

          <Typography
            mt={2}
            mb={6}
            variant="h6"
            maxWidth={600}
            color="text.secondary"
            textAlign="center"
            fontWeight="normal"
          >
            Currently, you don’t have any{" "}
            {getProviderName(provider, {
              nameAndTitle: true,
              plural: true,
              capitalize: true,
            })}{" "}
            connected to Cloudchipr, please click on Add{" "}
            {getProviderName(provider, {
              capitalize: true,
            })}{" "}
            in order to start saving with Cloudchipr.
          </Typography>
        </Fragment>
      )}
      {actionButton ?? <AccountAddButton provider={provider} />}
    </Stack>
  );
};
