import { Row } from "@tanstack/react-table";
import { ReMixedVisibilitiesItemType } from "../../../../resource-explorer-all-views/components/table/utils/types/types";
import { DashboardMixedVisibilitiesItemType } from "../../../../dashboard-all-items/components/table/utils/types/types";

export const hierarchyAllItemsCreatedBySortingFn = <
  T extends ReMixedVisibilitiesItemType | DashboardMixedVisibilitiesItemType,
>(
  currentRow: Row<T>,
  nextRow: Row<T>,
) => {
  const currentName = currentRow.original.created_by?.name;
  const nextName = nextRow.original.created_by?.name;

  const currentValue = (
    currentName ??
    currentRow.original.created_by?.email ??
    ""
  ).toLowerCase();
  const nextValue = (
    nextName ??
    nextRow.original.created_by?.email ??
    ""
  ).toLowerCase();

  return currentValue > nextValue ? 1 : -1;
};
