import { FC, Fragment, memo, useMemo } from "react";
import { FormikHandlers } from "formik";
import {
  FilterItem,
  Operator,
  ResourceType,
  useGetResourceTypesByTypeAndFilterKeyTypeQuery,
} from "../../../../../../../../services/cloudchipr.api";
import {
  AutocompleteFilterBody,
  AutocompleteFilterBodyProps,
} from "../AutocompleteFilterBody";
import { FilterItemOperatorType } from "../../../FilterItemOperatorType";

interface PublicAccessFilterProps extends AutocompleteFilterBodyProps {
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
  resourceType: ResourceType;
}

export const PublicAccessFilter: FC<PublicAccessFilterProps> = memo(
  ({ operators, filter, onChange, resourceType, setFieldValue, ...props }) => {
    const { data, isLoading } = useGetResourceTypesByTypeAndFilterKeyTypeQuery({
      type: resourceType,
      filterKeyType: "public_access",
    });

    const renderOptions = useMemo(() => {
      return data?.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
    }, [data]);

    return (
      <Fragment>
        <FilterItemOperatorType
          onChange={onChange}
          options={operators}
          value={filter.operator || "in"}
        />

        <AutocompleteFilterBody
          {...props}
          options={renderOptions ?? []}
          isLoading={isLoading}
          label="Public Access"
          selectedValue={Array.isArray(filter?.value) ? filter.value : []}
          setFieldValue={setFieldValue}
        />
      </Fragment>
    );
  },
);
