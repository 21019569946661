import { FC, useCallback, ChangeEvent, ReactNode } from "react";
import {
  Stack,
  Switch,
  SwitchProps,
  Typography,
  TypographyProps,
} from "@mui/material";

interface SwitchWithTypographyProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  title: ReactNode;
  typographyProps?: TypographyProps;
  switchProps?: SwitchProps;
}

export const SwitchWithTypography: FC<SwitchWithTypographyProps> = ({
  checked,
  onChange,
  title,
  typographyProps,
  switchProps,
}) => {
  const handleChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange(checked);
    },
    [onChange],
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Switch
        checked={checked}
        onChange={handleChange}
        size="small"
        {...switchProps}
      />
      <Typography variant="inherit" {...typographyProps}>
        {title}
      </Typography>
    </Stack>
  );
};
