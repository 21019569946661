import { FC, Fragment, memo, useCallback, useMemo } from "react";
import { FormikHandlers } from "formik";
import { AutocompleteFilterBodyProps } from "../AutocompleteFilterBody";
import {
  FilterItem,
  Operator,
  useGetUsersMeNatGatewayIpAddressesQuery,
} from "../../../../../../../../services/cloudchipr.api";
import { FilterItemOperatorType } from "../../../FilterItemOperatorType";
import { AutocompleteSelect } from "../../../../../../../common/select/autocomplete-select/AutocompleteSelect";
import { DropdownSelectOption } from "../../../../../../../common/select/dropdown-select/utils/types/types";

interface IpAddressesFilterProps extends AutocompleteFilterBodyProps {
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
}

export const IpAddressesFilter: FC<IpAddressesFilterProps> = memo(
  ({ operators, filter, onChange, setFieldValue, error, ...props }) => {
    const { data, isLoading } = useGetUsersMeNatGatewayIpAddressesQuery();

    const onSelectChange = useCallback(
      (values: string[]) => setFieldValue("value", values),
      [setFieldValue],
    );

    const options = useMemo(() => {
      if (!data) {
        return [];
      }
      const combinedData = data?.reduce(
        (result, item) => {
          const newItem: DropdownSelectOption = {
            value: item.ip,
            label: item.ip,
            groupName:
              item.type === "Public"
                ? "PUBLIC IPv4 ADDRESSES"
                : "PRIVATE IPv4 ADDRESSES",
          };
          if (item.type === "Public") {
            result.public.push(newItem);
          } else {
            result.private.push(newItem);
          }

          return result;
        },
        { public: [], private: [] } as {
          public: DropdownSelectOption[];
          private: DropdownSelectOption[];
        },
      );

      return combinedData.public.concat(combinedData.private);
    }, [data]);

    return (
      <Fragment>
        <FilterItemOperatorType
          onChange={onChange}
          options={operators}
          value={filter.operator || "in"}
        />

        <AutocompleteSelect
          {...props}
          onChange={onSelectChange}
          error={!!error}
          size="xsmall"
          allSelectedLabel="Selected"
          optionsLoading={isLoading}
          placeholder="IPv4 addresses"
          label="IPv4 addresses"
          options={options}
          values={Array.isArray(filter?.value) ? filter.value : []}
        />
      </Fragment>
    );
  },
);
