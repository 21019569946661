import { BillingSummarySetupType } from "../types/setups/billingSummarySetupType";

export const billingSummaryDefaultFilters: BillingSummaryWidgetFilterType[] = [
  "total_cost",
  "previous_period_total_cost",
  "average_monthly_cost_details",
  "average_daily_cost_details",
  "forecasted_month_end_cost",
  "forecasted_quarter_end_cost",
  "live_usage_total_cost",
];

export const billingSummarySetupDefaultData: BillingSummarySetupType = {
  widgetType: "cost_and_usage_summary",
  name: "Billing Summary",
  viewId: undefined,
  dates: { from: null, to: null, label: "past_fourteen_days" },
  filter: billingSummaryDefaultFilters,
  grouping: "service",
};

export type BillingSummaryWidgetFilterType =
  | "total_cost"
  | "previous_period_total_cost"
  | "average_monthly_cost_details"
  | "average_daily_cost_details"
  | "forecasted_month_end_cost"
  | "forecasted_quarter_end_cost"
  | "live_usage_total_cost";
