import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  PatchUsersMeOrganisationsCurrentTaskViewsByViewIdApiArg,
} from "../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { getTasksViewsHierarchyThunk } from "../getTasksViewsHierarchyThunk";
import { updateTaskViewFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

export const updateTasksViewThunk = createAsyncThunk(
  "taskManagement/updateSavingsTaskView",
  async (
    args: PatchUsersMeOrganisationsCurrentTaskViewsByViewIdApiArg,
    { dispatch },
  ) => {
    const { patchUsersMeOrganisationsCurrentTaskViewsByViewId } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        patchUsersMeOrganisationsCurrentTaskViewsByViewId.initiate(args, {
          fixedCacheKey: updateTaskViewFixedCacheKey,
        }),
      ).unwrap();

      dispatch(getTasksViewsHierarchyThunk());

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
