import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../../../services/cloudchipr.api";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { getUtilizationAndCoverageDrawerDynamicHeaderCellTitles } from "../../../../../../../utils/helpers/getUtilizationAndCoverageDrawerDynamicHeaderCellTitles";
import { CommitmentsProgressBar } from "../../../../../../../../common/components/CommitmentsProgressBar";

export const getCoverageColumnDef = (
  grouping: SavingsPlanCoverageGrouping,
): ColDef<SavingsPlanCoverageDataWithId> => {
  const headerName = getUtilizationAndCoverageDrawerDynamicHeaderCellTitles(
    "coverage",
    grouping,
  )?.coverageCell;

  return {
    flex: 1.5,
    field: "coverage",
    minWidth: 100,
    headerName,
    cellRenderer: ({
      data,
    }: CustomCellRendererProps<SavingsPlanCoverageDataWithId>) => (
      <CommitmentsProgressBar
        value={data?.coverage ?? 0}
        overrideColor="info.light"
      />
    ),
  };
};
