import { getBillingExplorerSummarySelector } from "./api-select/getBillingExplorerSummarySelector";
import { RootState } from "../../../store";
import { AnExplanationObjectForASpecificCloudProvider } from "../../../../services/cloudchipr.api";

export const billingExplorerSummaryAnomaliesDataByKeySelector = (
  state: RootState,
  key: string,
): AnExplanationObjectForASpecificCloudProvider[] | undefined => {
  return getBillingExplorerSummarySelector(key)(state)?.data?.anomalies;
};
