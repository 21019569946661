import { KubernetesResourceItemType } from "../../../../../../../../../../store/kubernetes-live-usage-mgmt/utils/types/types";
import { MetricsFrequenciesType } from "../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/types/metricsFrequenciesType";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { generateMetricsCsvKey } from "../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateMetricsCsvKey";

export const generateKubernetesNonPrimitiveHeaderKey = (
  column: ColumnSetupType<KubernetesResourceItemType>,
  metricsFrequencies?: MetricsFrequenciesType,
): string => {
  if (
    column.accessorKey ===
    ("inline_metrics" as keyof KubernetesResourceItemType)
  ) {
    return generateMetricsCsvKey(
      column.meta?.headerTitle,
      metricsFrequencies,
      column.id,
    );
  }

  return column.meta?.headerTitle ?? column.accessorKey ?? "";
};
