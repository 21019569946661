import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { utilizationAlertTypeValueGetter } from "./utils/valueGetters/utilizationAlertTypeValueGetter";
import { UtilizationAlertDataGridDataType } from "../../../../../../store/alerts/utils/types/types";
import { getAlertsNameColumnDef } from "../common/columnDefs/alertsNameColumnDef";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { utilizationAlertTypeTitles } from "../../drawer/components/utilization/utils/constants/constants";
import { getAlertsThresholdColumnDef } from "../common/columnDefs/alertsThresholdColumnDef";
import { getAlertsCreatedByColumnDef } from "../common/columnDefs/alertsCreatedByColumnDef";
import { getAlertsModifiedByColumnDef } from "../common/columnDefs/alertsModifiedByColumnDef";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { getAlertsNotificationMethodColumnDef } from "../common/columnDefs/alertsNotificationMethodColumnDef";
import { getAlertsActionsColumnDef } from "../common/columnDefs/alertsActionsColumnDef";

const alertsNameColumnDef =
  getAlertsNameColumnDef<UtilizationAlertDataGridDataType>();
const alertsThresholdColumnDef =
  getAlertsThresholdColumnDef<UtilizationAlertDataGridDataType>();
const alertsCreatedByColumnDef =
  getAlertsCreatedByColumnDef<UtilizationAlertDataGridDataType>();
const alertsModifiedByColumnDef =
  getAlertsModifiedByColumnDef<UtilizationAlertDataGridDataType>();
const alertsNotificationMethodColumnDef =
  getAlertsNotificationMethodColumnDef<UtilizationAlertDataGridDataType>();
const alertsActionsColumnDef =
  getAlertsActionsColumnDef<UtilizationAlertDataGridDataType>();

export const alertsUtilizationGridColumnDefs: ColDef<UtilizationAlertDataGridDataType>[] =
  [
    alertsNameColumnDef,
    {
      field: "based_on",
      headerName: "Alert Type",
      minWidth: 150,
      width: 230,
      cellRenderer: ({
        data,
      }: CustomCellRendererProps<UtilizationAlertDataGridDataType>) => {
        if (!data) {
          return;
        }

        return (
          <TypographyWithTooltip
            variant="inherit"
            title={utilizationAlertTypeTitles.get(data.based_on)}
          />
        );
      },
      valueGetter: utilizationAlertTypeValueGetter,
    },
    alertsThresholdColumnDef,
    alertsCreatedByColumnDef,
    alertsModifiedByColumnDef,
    alertsNotificationMethodColumnDef,
    agGridEmptyColumnToFitEmptySpace,
    alertsActionsColumnDef,
  ];
