import { FC } from "react";
import { Stack } from "@mui/material";
import { BillingSummaryVisualizationSwitch } from "./BillingSummaryVisualizationSwitch";
import VisualizationIcon from "../../../../../../../../../assets/images/icons/visualization_icon.svg";
import { WidgetConfigSection } from "../../common/widget-configurations/WidgetConfigSection";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import {
  billingSummaryDefaultFilters,
  BillingSummaryWidgetFilterType,
} from "../../../../../../../../../store/dashboards/utils/constants/billingSummarySetupDefaultData";
import { billingSummarySetupWidgetNoMonthlyAverageSelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-summary/data/billingSummarySetupWidgetNoMonthlyAverageSelector";
import { billingSummarySetupWidgetNoDailyAverageSelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-summary/data/billingSummarySetupWidgetNoDailyAverageSelector";
import { billingSummarySumSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/billing-summary/billingSummarySumSetupPropertyByKeySelector";

export const BillingSummaryVisualizationSection: FC = () => {
  const currentFilter = useAppSelector(
    billingSummarySumSetupPropertyByKeySelector("filter"),
  );
  const noMonthlyAverage = useAppSelector(
    billingSummarySetupWidgetNoMonthlyAverageSelector,
  );
  const noDailyAverage = useAppSelector(
    billingSummarySetupWidgetNoDailyAverageSelector,
  );

  return (
    <WidgetConfigSection
      initialExpanded
      title="Visualization"
      icon={<img src={VisualizationIcon} alt="Visualization Icon" />}
    >
      <Stack spacing={1.5}>
        {billingSummaryDefaultFilters.map(
          (filter: BillingSummaryWidgetFilterType) => {
            if (filter === "average_daily_cost_details" && noDailyAverage) {
              return null;
            }

            if (filter === "average_monthly_cost_details" && noMonthlyAverage) {
              return null;
            }

            return (
              <BillingSummaryVisualizationSwitch
                key={filter}
                filter={filter}
                checked={!!currentFilter?.includes(filter)}
              />
            );
          },
        )}
      </Stack>
    </WidgetConfigSection>
  );
};
