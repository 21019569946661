import { grey } from "@mui/material/colors";
import { XAxisLabelsOptions, XAxisOptions } from "highcharts";

export const chartXAxisLabels: XAxisLabelsOptions = {
  rotation: 0,
  style: { fontSize: "11px", fontWeight: "500" },
};

export const chartXAxisConfigs: XAxisOptions = {
  crosshair: true,
  lineColor: grey[300],
  gridLineDashStyle: "Dash",
};
