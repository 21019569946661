import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { azureUtilizationAndCoverageDetailsDataSelector } from "./azureUtilizationAndCoverageDetailsDataSelector";
import { AzureSavingsPlanCoverageDataWithId } from "../../../../../../../components/pages/commtiments/common/utils/types";
import { addIdInCoverageData } from "../../../../../utils/helpers/addIdInCoverageData";
import { AzureCommitmentCoverageData } from "../../../../../../../services/cloudchipr.api";

export const azureUtilizationAndCoverageDetailsCoverageDataSelector =
  createDraftSafeSelector(
    [azureUtilizationAndCoverageDetailsDataSelector],
    (data): AzureSavingsPlanCoverageDataWithId[] | undefined => {
      return addIdInCoverageData<AzureCommitmentCoverageData>(
        data?.coverage_data,
      );
    },
  );
