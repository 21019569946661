import { FC, useCallback, useMemo } from "react";
import { PopoverProps, Skeleton, Stack } from "@mui/material";
import { DropdownHeader } from "./DropdownHeader";
import { LiveUsageMgmtSelectedAccountNavigationTriggerComponent } from "./LiveUsageMgmtSelectedAccountNavigationTriggerComponent";
import { isEqualArrays } from "./utils/helpers/isEqualArrays";
import { AccountOptionLabel } from "../../../../../../common/account-select/components/AccountOptionLabel";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { SIDEBAR_WIDTH } from "../../../../../../../utils/constants/style-sizes";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import { accountOptionFilterFn } from "../../../../../../common/account-select/utils/helpers/accountOptionFilterFn";
import { liveUsageMgmtAccountIdsSelector } from "../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveUsageMgmtProviderSelector } from "../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtAccountsChangeThunk } from "../../../../../../../store/live-usage-mgmt/thunks/liveUsageMgmtAccountsChangeThunk";
import { generateLiveResourcesPath } from "../../../../../accounts-group/utils/helpers/generateLiveResourcesPath";
import { allAccountsGroupedByOrgAndProviderSelector } from "../../../../../../../store/live-usage-mgmt/selectors/accounts-data/allAccountsGroupedByOrgAndProviderSelector";

export const LiveUsageMgmtSelectedAccountNavigation: FC = () => {
  const appliedAccountIds = useAppSelector(liveUsageMgmtAccountIdsSelector);

  const dispatch = useAppDispatch();
  const groupedAllAccounts = useAppSelector(
    allAccountsGroupedByOrgAndProviderSelector,
  );
  const provider = useAppSelector(liveUsageMgmtProviderSelector);

  const options = useMemo(() => {
    return groupedAllAccounts?.map((option) => {
      let listItemHeight = 60;

      if (
        option.providerAccountId &&
        !option.label.includes(option.providerAccountId)
      ) {
        listItemHeight += 20;
      }

      return {
        value: option.value,
        rawValue: option,
        listItemHeight,
        groupName: option?.orgId,
        label: (
          <AccountOptionLabel
            option={{ ...option, orgId: option.orgId }}
            showOrgId
            showStatus={option.status === "deactivated"}
            showAccountId={!option.label && !option.orgId}
          />
        ),
      };
    });
  }, [groupedAllAccounts]);

  const accountChangeHandler = useCallback(
    async (accountIds: string[]) => {
      if (
        !provider ||
        !options ||
        isEqualArrays(appliedAccountIds ?? [], accountIds)
      ) {
        return;
      }

      if (!accountIds.length) {
        const allAccountIds =
          options?.reduce((result, item) => {
            result.push(item.value);
            return result;
          }, [] as string[]) ?? [];

        window.history.replaceState(
          {},
          "",
          generateLiveResourcesPath({
            provider,
            activeTab: "live-resources",
            accountIds: allAccountIds.join(","),
          }),
        );

        dispatch(
          liveUsageMgmtAccountsChangeThunk({
            accountIds: allAccountIds,
            provider,
          }),
        );
      } else {
        window.history.replaceState(
          {},
          "",
          generateLiveResourcesPath({
            provider,
            activeTab: "live-resources",
            accountIds: accountIds.join(","),
          }),
        );
        dispatch(
          liveUsageMgmtAccountsChangeThunk({
            accountIds,
            provider,
          }),
        );
      }
    },
    [provider, dispatch, options, appliedAccountIds],
  );

  if (!appliedAccountIds || !provider) {
    return <Skeleton variant="text" height={100} width={SIDEBAR_WIDTH - 20} />;
  }

  return (
    <Stack
      p={0.5}
      width={280}
      minWidth={280}
      borderRight={1}
      height="100%"
      borderColor="grey.300"
      justifyContent="center"
    >
      <DropdownSelect
        label="Accounts"
        listWidth={400}
        options={options ?? []}
        filterFn={accountOptionFilterFn}
        PopoverProps={popoverProps}
        submitHandlerOnClose={accountChangeHandler}
        DropdownHeader={DropdownHeader}
        values={appliedAccountIds ?? []}
        TriggerComponent={
          LiveUsageMgmtSelectedAccountNavigationTriggerComponent
        }
        disableEmptySelectionList
        lastSelectedOptionDisabled
      />
    </Stack>
  );
};

const popoverProps = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
} as PopoverProps;
