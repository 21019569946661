import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { billingExplorerGroupingChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/billing-explorer-widget/setup-change/billingExplorerGroupingChangeThunk";
import { ResourceExplorerGroupingNullable } from "../../../../../../../../../../services/cloudchipr.api";
import { billingExplorerSetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/billing-explorer/billingExplorerSetupPropertyByKeySelector";
import { GroupingSelector } from "../../../../../../../../common/resource-explorer-grouping-selector-v2/GroupingSelector";

interface BillingExplorerWidgetSetupGroupingSelectProps {
  disabled?: boolean;
}

export const BillingExplorerWidgetSetupGroupingSelect: FC<
  BillingExplorerWidgetSetupGroupingSelectProps
> = ({ disabled }) => {
  const dispatch = useAppDispatch();

  const groupBy = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("grouping"),
  );
  const groupValues = useAppSelector(
    billingExplorerSetupPropertyByKeySelector("group_values"),
  );

  const groupingChangeHandler = useCallback(
    (
      newGroupBy: ResourceExplorerGroupingNullable,
      newGroupValues?: string[],
    ) => {
      const groupByIsNotChanged = groupBy === newGroupBy;

      if (groupByIsNotChanged && !newGroupValues) {
        return;
      }

      dispatch(
        billingExplorerGroupingChangeThunk({
          grouping: newGroupBy,
          groupValues: newGroupValues,
        }),
      );
    },
    [dispatch, groupBy],
  );

  return (
    <GroupingSelector
      value={groupBy}
      disabled={disabled}
      groupValues={groupValues}
      hiddenGroupings={["resource"]}
      onChange={groupingChangeHandler}
    />
  );
};
