import { resourceExplorerPossibleGroupingsFormattedDataSelector } from "./resourceExplorerPossibleGroupingsFormattedDataSelector";
import { RootState } from "../../../store";
import { ResourceExplorerGroupedPossibleGroupings } from "../../../../components/pages/common/resource-explorer-grouping-selector-v2/utils/types/types";

export const resourceExplorerGroupedPossibleGroupingsSelector = (
  state: RootState,
): ResourceExplorerGroupedPossibleGroupings | undefined => {
  const formattedData =
    resourceExplorerPossibleGroupingsFormattedDataSelector(state);

  return formattedData?.groupings;
};
