import { SavingsOpportunity } from "../../../../services/cloudchipr.api";

export const getSavingsOpportunitiesMonthlySavings = (
  data: SavingsOpportunity[] | undefined,
) => {
  if (!data) {
    return 0;
  }

  const costs = data.reduce(
    (acc, item) => {
      const uniqueKey = `${item.action_type}_${item.resource_id}`;

      const prevCost = acc[uniqueKey] ?? 0;
      const currentCost = item.monthly_savings ?? 0;

      acc[uniqueKey] = Math.max(prevCost, currentCost);

      return acc;
    },
    {} as Record<string, number>,
  );

  return Object.values(costs).reduce((sum, cost) => sum + cost, 0);
};
